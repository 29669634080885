import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';
import { RouterModule }             from '@angular/router';
import { SharedModule }             from '../shared/shared.module';

import { ItemService }           from '../services/item.service';
import { WatchListComponent }       from './watch-list.component';
import { WatchListHeaderComponent } from './watch-list-header.component';
import { WatchListService }         from './watch-list.service';

@NgModule({
    imports:      [
        CommonModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        WatchListComponent,
        WatchListHeaderComponent
    ],
    exports:      [
        WatchListComponent,
        CommonModule,
    ],
    providers:    [ WatchListService, ItemService ]
})
export class WatchListModule { }
