import {
    Component, OnInit,
    ElementRef, OnDestroy
} from '@angular/core';
import {Observable, Subject} from 'rxjs/Rx';

import { CarouselService }          from './carousel.service';
import { DOMElement }               from '../interfaces/misc.interface';
import {GoogleAnalyticsService} from '../services/google-analytics.service';
import AppValues from '../common/app.values';
import {ErrorInterface} from '../services/error.service';
import {AdsListResponseBody} from '../../../swagger-gen__output_dir/model/adsListResponseBody';
import {Advertisement} from '../../../swagger-gen__output_dir/model/advertisement';


@Component({
    selector: 'carousel',
    template: `
        <div class="carousel__container">
            <div class="carousel__item">
                <img *ngIf="slide" src="{{ slide.ad_image_url }}" (click)="onClick(slide)" [attr.data-test-id]="'carouselImgUrl_' + counter" class="carousel__image">
            </div>
        </div>
    `
})
export class CarouselComponent implements OnInit, OnDestroy {

    public carousel:   AdsListResponseBody;
    public counter     = 0;
    public slide:      Advertisement;

    private ANIMTIME    = 900;
    private timerId     = null;

    private componentDestroyed$:    Subject<boolean> = new Subject();
    private elem:                   DOMElement;

    constructor(
        private carouselService:        CarouselService,
        private elementRef:             ElementRef,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.elem = this.elementRef.nativeElement;
    }


    public ngOnInit(): void {
        this.carouselService.getSlides()
            .takeUntil(this.componentDestroyed$)
            .subscribe((res: AdsListResponseBody) => {
                    this.carousel = res;

                    if (Object.keys(res).length) {
                        this.run();
                    }
                }, (err: ErrorInterface) => Observable.throw(err));
    }


    public onClick(ad: Advertisement): void {
        this.carouselService.asdClick(ad.seller);
        this.googleAnalyticsService.handleSelectContent(ad);
    }


    private run(): void {
        let delay = (this.carousel.ads_time + 1) * AppValues.THOUSAND;
        let animdelay = delay - this.ANIMTIME;

        this.renderSlide(animdelay);

        this.timerId = setInterval(() => this.renderSlide(animdelay), delay);

    }

    public renderSlide(animdelay: number): void {
        const slides = this.carousel.ads;
        const slidesNumber = slides.length;
        let carouselh = this.elem.querySelector('.carousel__item');

        carouselh.className = 'carousel__item carousel__fadeIn';
            if (++this.counter === slidesNumber) {
                this.counter = 0;
            }

            this.slide = slides[this.counter];
            // this.googleAnalyticsService.handleViewPromotion(this.slide);

            setTimeout(() => {
                carouselh.className = 'carousel__item carousel__fadeOut';

            }, animdelay);
    }
    public ngOnDestroy(): void {
        clearInterval(this.timerId);
    }
}
