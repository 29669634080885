import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/switchMap';

import { DataService } from '../services/data.service';
import { ErrorInterface, ErrorService } from '../services/error.service';
import { ModalService } from '../modal/modal.service';
import { Subject } from 'rxjs/Subject';
import { UserService } from '../user/user.service';
import { UserSessionInterface } from '../interfaces/user.interface';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { TranslateService } from '@ngx-translate/core';
import AppValues from '../common/app.values';
import { getErrorType } from '../services/error.helpers';


@Injectable()
export class InventoryListService {
    /**
     * @desc Fetches inventory list data via DataService. Navigates
     * to Product Editor's page.
     */

    public isCategory: boolean;
    public id: string;
    public items: SellingItemResponseBody[];
    public list: SellingItemResponseBody[];
    public itemsEvent$: ReplaySubject<SellingItemResponseBody[]> = new ReplaySubject();

    // tslint:disable-next-line:no-any
    private dialogsTimer: number | any;
    private userActionEvent$: Subject<SellingItemResponseBody> = new Subject();
    private startedPagerDataChunk:  SellingItemResponseBody[];

    public constructor(
        private dataService: DataService,
        private errorService: ErrorService,
        private modalService: ModalService,
        private userService: UserService,
        private translate: TranslateService,
    ) {
        this.userService.userAuthEvent$
            .subscribe((user: UserSessionInterface) => this.list = []);
    }

    /**
     * Fires dialog polling requests in 10-second interval
     */
    public startPolling(isCategory: boolean): void {
        this.id         = this.userService.getUserSession().ID;
        this.isCategory   = isCategory;

        if (!this.id) {
            this._retrieveInventoryList();
        }

        if (this.isCategory) {
            this.dialogsTimer = setInterval(this._retrieveInventoryList.bind(this), 10000);
        } else { this.dialogsTimer && clearInterval(this.dialogsTimer); }
    }


    /**
     * @desc Checks if the list has already been fetched. If so, returns
     * Observable of the list data, otherwise calls #fetchList.
     * @returns {Observable<{}>}
     */
    public getList(sheet?: boolean): Observable<SellingItemResponseBody[]> {
        const id: string = this.userService.getUserSession().ID;

        const _list: number = this.list && this.list.length;

        if (!id) { return Observable.of([]); }

        if ( sheet ) {
            return _list
                ? Observable.of(this.list)
                : Observable.of([]);
        } else {
            return _list
                ? Observable.of(this.list)
                : this.fetchList(id);
        }
    }

    /**
     * Returns items.
     * @returns {SellingItemResponseBody[]}
     */
    public getCurrentInventoryItems(): SellingItemResponseBody[] { return this.list; }

    public restoreList(): void {
        this.list = [];
    }
    /**
     * Fetches list.
     * @emits itemsEvent$
     * @private
     */
    public _retrieveInventoryList(): void {
        const token: string = this.userService.getUserSession().token;

        if (!token) {
            this.dialogsTimer && clearInterval(this.dialogsTimer);
            return;
        }

        this.dataService.getData(`user_inventory?user_id=${this.id}`, {token})
            .subscribe(
                (res: SellingItemResponseBody[]) => {
                    this.list = res;

                    this.setInventItems(this.list);
                },
                (err: ErrorInterface) => {
                    this.checkFoundItems(err);
                },
            );
    }

    public _stopPolling(): void {
        clearInterval(this.dialogsTimer);
        return;
    }


    /**
     * Returns itemsEvent$.
     * @returns {Observable<SellingItemResponseBody[]>}
     */
    public getInventItems(): Observable<SellingItemResponseBody[]> {
        return this.itemsEvent$.asObservable();
    }

    public setInventItems(items: SellingItemResponseBody[]): void {
        this.itemsEvent$.next(items);
    }


    /**
     * @desc Fetches inventory list data via DataService. Shows/hide spinner.
     * @param id
     * @returns {Observable<R|T>}
     * @private
     */
    public fetchList(id: string): Observable<SellingItemResponseBody[]> {
        const token: string = this.userService.getUserSession().token;

        this.modalService.showSpinner();
        if ( id && token) {

            return this.dataService.getData(`user_inventory?user_id=${id}`, {token})
                .do((res: SellingItemResponseBody[]) => {
                    if (this.modalService.modalType === 'spinner') {
                        this.modalService.close();
                    }
                    this.list = res;

                    return Observable.of(this.list);
                })
                .catch((err: ErrorInterface) => {
                    this.userActionEvent$.error({status: err.status, statusText: getErrorType(err)});
                    this.modalService.close();
                    this.list = [];
                    return Observable.of(this.list);
                });
        }

        return Observable.of([]);
    }

    public checkFoundItems(err: ErrorInterface): SellingItemResponseBody[] {
       if (err.status !== 403 && err.status !== 404) {
           this.errorService.handleError(err);
           this._stopPolling();
       } else {
            this.modalService.close();
            return this.list = [];
        }
    }


    public addNewItem(item: SellingItemResponseBody): void {
        this.list.unshift(item);
    }


    public removeItemDialog(): Observable<boolean> {
        return Observable
            .fromPromise(
                this.modalService
                    .warning({
                        title: this.translate.instant('inventoryList.removeProduct.modal.title'),
                        message: this.translate.instant('inventoryList.removeProduct.modal.message'),
                        yesButtonText: this.translate.instant('inventoryList.removeProduct.modal.yesButton'),
                        noButtonText: this.translate.instant('inventoryList.removeProduct.modal.noButton'),
                        reverseButtons: true,
                    }),
            ).switchMap((action: boolean) => Observable.of(action));
    }

    public removeProduct(action: boolean, item: SellingItemResponseBody): Observable<SellingItemResponseBody[]> {
        return action ? this.removeItemRequest(item) : Observable.of(this.list);
    }


    public removeItemRequest(item: SellingItemResponseBody): Observable<SellingItemResponseBody[]> {

        this.modalService.showSpinner();

        return this.dataService.postData('delete_item', { item_id: item.ID }, {token: this.userService.getUserSession().token})
            .map(() => {
                this.modalService.close();
                this._pickItemOut(item);

                return this.list;
            })

            .catch((err: ErrorInterface) => this.errorService.handleError(err));
    }

    public getItemRemovalConfirmation(): Observable<SellingItemResponseBody> {
        return this.userActionEvent$.asObservable();
    }

    public setStartedPagerDataChunk(startedPagerDataChunk: SellingItemResponseBody[]): void {
       this.startedPagerDataChunk = AppValues.deepCopy(startedPagerDataChunk);
    }
    public getStartedPagerDataChunk(): SellingItemResponseBody[] {
        return this.startedPagerDataChunk;
    }

    private _pickItemOut(item: SellingItemResponseBody): void {
        const index: number = this.list.findIndex((_item: SellingItemResponseBody) => _item.ID === item.ID);

        if (index >= 0) {
            this.list.splice(index, 1);
        }
    }
}
