import {
    AfterViewInit,
    Component, OnDestroy,
    OnInit
} from '@angular/core';
import { Subject }                  from 'rxjs';
import { Location }                 from '@angular/common';

import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';

import { AddressInterface, UserModel } from '../interfaces/user.interface';
import { ChatService }              from '../chat/chat.service';
import { CenterLocationInterface }  from '../interfaces/location.interface';
import { AppSearchService } from '../search/search.service';
import { ShopperDetailsService }    from './shopper-details.service';
import { ModalService }             from '../modal/modal.service';
import { AppReviewsService }           from '../reviews/reviews.service';
import { UserService }              from '../user/user.service';
import AppValues from '../common/app.values';
import { GoogleMapService } from '../services/google-map.service';
import { Observable } from 'rxjs/Rx';
import { ErrorInterface } from '../services/error.service';
import { Address } from '../../../swagger-gen__output_dir';
import { TranslateService } from '@ngx-translate/core';
import { SearchType, UrlBySearchNameInterface } from "../interfaces/search.interface";
import { normalizeUrl } from "../common/app.helpers";

@Component({
    selector:   'shopper-details',
    styleUrls:  ['./shopper-details.sass'],

    template:   `
        <div *ngIf="shopper" class="component">
            <div class="shopper-details">
                <header class="app-header">
                    <nav class="app-header__container shopper-details__header">

                        <span class="navbar__left">
                            <button (click)="backClick()" [attr.data-test-id]="'searchResultsLink'" type="button"
                               class="nav__link"><i class="icon-custom_back_icon"></i></button>
                        </span>

                        <div class="navbar__center">
                            <h4>{{ "shopper.details.seller" | translate }}</h4>
                        </div>

                        <button (click)="toggleFavListStatus()" data-test-id="toggleFLStatus" class="navbar__right" type="button">
                            <span data-test-id="addToFL" *ngIf="!shopper['shopperIsInFavList']"
                                  class="icon-favorites_filled"></span>

                            <span data-test-id="removeFromFL" *ngIf="shopper['shopperIsInFavList']"
                                  class="icon-favorites_filled active"></span>

                        </button>
                    </nav>
                </header>
                <div class="shopper-details__container">
                    <div class="shopper-details__img">
                        <agm-map
                            [latitude]='shopper.latitude'
                            [longitude]='shopper.longitude'
                            [zoom]='zoom'
                            [scrollwheel]='false'
                            [streetViewControl]='false'
                            [disableDefaultUI]='true'
                            [disableDoubleClickZoom]='true'
                            [mapDraggable]='false'
                            [zoomControl]='false'>

                            <agm-marker
                                (markerClick)="onMarkerClick(shopper)"
                                [latitude]='shopper.latitude'
                                [longitude]='shopper.longitude'
                                [markerDraggable]="false" [iconUrl]='shopperUrl'>
                            </agm-marker>

                        </agm-map>
                    </div>
                    <button type="button" class="shopper-details__content-user" (click)="showSellerReviews(shopper.ID, shopper.title, shopper.imageURL)">
                        <div *ngIf="!shopper.imageURL" class="image_col">
                            <span class="sprite sprite-no_image_icon" data-test-id="shopperImage"></span>
                        </div>
                        <div class="container-img">
                            <img *ngIf="shopper.imageURL" [src]="shopper.imageURL" data-test-id="shopperImage" alt="shopper profile url">
                        </div>
                        <div class="content_col">
                            <h4 data-test-id="ShopperName">
                                {{ shopper.title || shopper.firstName + ' ' + shopper.lastName}}
                            </h4>
                            <div class="shopper-details__reviews">
                                <rating [attr.data-test-id]="'ShopperRate'" [rating]="shopper.rate" [float]="true"
                                        [readonly]="true"></rating>
                                <p data-test-id="detailRate">( {{ shopper.voted }} )</p>
                            </div>
                        </div>
                    </button>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-phone"></span>
                        </div>

                        <h4 data-test-id="ShopperPhone">
                            <a *ngIf="!primaryShopperAddress.phone">{{ "shopper.details.phone.not.specified" | translate }}</a>
                            <a *ngIf="primaryShopperAddress.phone"
                               href="tel:{{ primaryShopperAddress.phone}}">{{ formatPhoneNumber(primaryShopperAddress) }}</a>
                        </h4>
                    </div>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-location"></span>
                        </div>

                        <h4 *ngIf="primaryShopperAddress"
                            data-test-id="ShopperAddress"
                            (click)="formatAndGoToAddress()">
                            <a>{{ commaSeparatedShopperAddress }}</a>
                        </h4>

                        <h4 *ngIf="shopper.addresses.length == 0"
                            data-test-id="ShopperAddress">
                            <a>{{ "shopper.details.not.specified" | translate }}</a>
                        </h4>
                    </div>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-globe"></span>
                        </div>

                        <h4 data-test-id="ShopperWebPage" (click)="goToAddress(shopper.webPage, 'webPage')">
                            <a>{{ shopper.webPage || 'Web page is not specified.'}}</a>
                        </h4>
                    </div>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-at"></span>
                        </div>

                        <h4 data-test-id="ShopperMail" (click)="goToAddress(shopper.email, 'mail')">
                            <a>{{ shopper.email || 'Email is not specified.'}}</a>
                        </h4>
                    </div>
                </div>
            </div>

            <div class="product-actions app__footer ">
                <footer class="details__container__btn-group shopper-details__footer">
                    <button data-test-id="goToChat" (click)="goToDialog()" class="chat-shopper" type="button">
                        {{ "shopper.details.contact.seller" | translate }}
                    </button>
                    <button data-test-id="searchForShopper" (click)="searchFor(shopper.ID)" class="shopper-items" type="button">
                        {{ "shopper.details.items.for.sale" | translate }}
                    </button>
                </footer>
            </div>
        </div>
    `
})

export class ShopperDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

    href: string;
    shopper: UserModel;
    text: string;
    location: Location;
    shopperUrl = '../assets/images/map_pin_icon.png';
    userLocation: CenterLocationInterface;
    zoom = 12;
    public _w: any;
    commaSeparatedShopperAddress: string;
    primaryShopperAddress: Address;

    private componentDestroyed$: Subject<boolean> = new Subject();

    constructor(private chatService: ChatService,
                private googleMapService: GoogleMapService,
                private searchService: AppSearchService,
                private shopperDetailsService: ShopperDetailsService,
                private reviewsService: AppReviewsService,
                private modalService: ModalService,
                private userService: UserService,
                private translate:   TranslateService,
                location: Location) {
        this.location = location;
        this._w = window;
    }

    public ngOnInit(): void {
        this.shopper = this.shopperDetailsService.getShopper();
        this.primaryShopperAddress = AppValues.getPrimaryAddress(this.shopper.addresses);

        this.commaSeparatedShopperAddress = AppValues.getStringAddress(this.primaryShopperAddress, ', ');
        this.chatService.init();

        const urlBySearchMapName: UrlBySearchNameInterface = this.searchService.getUrlBySearchName(this.shopper.login, '/map-search',  SearchType.Seller);
        this.searchService.addSearchDataToLocalCachingByUrl({url: urlBySearchMapName.fullUrl, searchType: SearchType.Seller, searchResult: {sellers: [this.shopper]}});

        this._setListStatus();
    }

    public ngAfterViewInit(): void {
        let agm_container = document.getElementsByClassName("agm-map-container-inner")[0];

        if (agm_container) {
            agm_container.setAttribute("data-test-id", "content-map");
        }
    }

    public formatAndGoToAddress(): void {
        this.goToAddress(AppValues.getStringAddress(this.primaryShopperAddress), 'address');
    }

    /**
     * @desc Injects additional keys to Item object (locally) to mark their status
     * towards Favorite and Watch lists.
     * @private
     */
    private _setListStatus(): void {
        const user = this.userService.getUser();

        if (!user.favList.users) return;

        this.shopper['shopperIsInFavList'] = user.favList.users.indexOf(this.shopper.ID) >= 0;
    }

    /**
     * format phone number by phone and country for View
     * @param {AddressInterface} address
     * @return {string}
     */
    public formatPhoneNumber(address: AddressInterface): string {
        if (address.phone) {
            return AppValues.formatPhoneNumber(address.phone);
        } else return '';
    }

    public searchFor(id: string): void {
        this.shopperDetailsService.searchFor(id);
    }

    public goToAddress(item: string, type: string): void {
        if (item) {

            const textObj = {
                'webPage': [
                    `Open '${item}'?`,
                    'Open',
                ],
                'mail': [
                    `Send mail to '${item}' ?`,
                    'Send',
                ],
                'address': [
                    `Open '${item}' in maps?`,
                    'Open'
                ]
            };

            this.modalService
                .warning({
                    message: textObj[type][0],
                    yesButtonText: textObj[type][1],
                    noButtonText: 'Cancel',
                })
                .then((action: boolean) => action && this.send(item, type));
        } else return;
    }

    private send(item, type): void {
        const textObj = {
            'webPage': `${item}`,
            'mail': `mailto: ${item}`
        };

        if (type === 'address') this.goToGoogleMaps(this.shopper.latitude, this.shopper.longitude, item);

        if (type !== 'address') {
            this.href = normalizeUrl(textObj[type]);

            if (type === 'webPage') this._w.open(this.href);
            else this._w.location.assign(this.href);
        }

        if (!textObj[type] || textObj[type] === "") return;

    }

    /**
     * @todo after Chat Module is ready
     */
    public goToDialog(): void {
        if (this.userService.getUser().ID === this.shopper.ID) {
            this.modalService.error({
                    title: this.translate.instant("modal.error.title"),
                    message: this.translate.instant("dialogs.error.dialogWithYourself"),
                    noButtonText: this.translate.instant("common.cancel"),
            });
            return;
        }

        if (!this.userService.isGuestMode) {
            this.chatService.goToDialog(this.shopper.ID);
        }
    }

    /**
     * Toggles inFavList status.
     */
    public toggleFavListStatus(): void {
        if (this.userService.isGuestMode) {
            return;
        }

        this.shopperDetailsService.toggleFavListStatus()
            .takeUntil(this.componentDestroyed$)
            .subscribe((res) => this.shopper['shopperIsInFavList'] = !this.shopper['shopperIsInFavList'], (err) => null);
    }

    /**
     * @todo after Reviews module is ready
     */
    public showSellerReviews(ID: string, title: string, url: string): void {
        if (!ID) return;


        this.reviewsService.getUserReviewsAndRedirect({buyerObj: {ID, title, url}, isCreating: false})
            .takeUntil(this.componentDestroyed$)
            .subscribe( (res) => null, (err: ErrorInterface) => Observable.throw(err));
    }

    public backClick(): void {
        // this.searchService.setFalseMarketFlag('users', false);
        this.location.back();
    }

    /**
     * Open in new tab Google Map by URL
     * Open map by coord and description:
     * @param {number} shopper_latitude
     * @param {number} shopper_longitude
     * @param {Address | string} nameAddress
     */
    public goToGoogleMaps(shopper_latitude: number, shopper_longitude: number, nameAddress?: Address|string): void {
        // Deprecated based on the testing meeting 8/12
        // let coordinates: CenterLocationInterface;
        // const userGeolocation = this.searchService.userGeolocation();
        // const defaultUserGeolocation = this.userService.getUser().loc.coordinates;
        //
        // userGeolocation ? (coordinates = userGeolocation) : (coordinates = defaultUserGeolocation);

        let coorditate_to = {latitude: shopper_latitude, longitude: shopper_longitude };

        if (nameAddress) {
            coorditate_to = Object.assign(coorditate_to, {nameAddress});
        }

        this.googleMapService.goToGoogleMaps(
            {
                to: coorditate_to,
            }
        );
    }

    public onMarkerClick(shopper: UserModel): void {
        if (shopper.addresses.length !== 0) {
            return this.goToGoogleMaps(shopper.latitude, shopper.longitude, this.primaryShopperAddress);
        }  else {
            return this.goToGoogleMaps(shopper.latitude, shopper.longitude);
        }
    }

    public ngOnDestroy(): void {
        this.chatService.stopPolling();
    }

}
