import { OnInit, ComponentRef, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Location } from '@angular/common';
import { ReviewProductInterface, SelectedOrder } from './review.interface';
import { AppReviewsService } from './reviews.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from "../modal/modal.service";
import { Review } from '../../../swagger-gen__output_dir';
import { CreateReviewBuyerComponent } from './create-review/create-review-seller';
import { CreateReviewProductComponent } from './create-review/create-review-product';
import { AppFooterService } from '../app-footer/app-footer.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
var CreateReviewComponent = /** @class */ (function () {
    function CreateReviewComponent(componentFactoryResolver, fb, reviewsService, modalService, footerService, translate, router, location) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.fb = fb;
        this.reviewsService = reviewsService;
        this.modalService = modalService;
        this.footerService = footerService;
        this.translate = translate;
        this.router = router;
        this.textareaLength = 200;
        this.mappings = {
            'product': {
                instance: CreateReviewProductComponent,
                title: 'Product',
            },
            'buyer': {
                instance: CreateReviewBuyerComponent,
                title: 'Buyer',
            },
        };
        this.location = location;
    }
    CreateReviewComponent.prototype.ngOnInit = function () {
        this.buildForm();
        this.selected_order = this.reviewsService.selectedOrder;
        this.checkCustomer();
        this.renderComponent();
    };
    /**
     * Creates the CreateReview Component.
     * @desc Starts from clearing the previous component, chooses a proper class according to the
     * desired type, renders chosen class and inject the context into it.
     * @private
     */
    CreateReviewComponent.prototype.renderComponent = function () {
        this.container.remove();
        var component = this.instanceCreateReview.instance;
        var factory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef = this.container.createComponent(factory);
        var instance = this.componentRef.instance;
        instance.context = Object.assign({ callback: this._executeCommand.bind(this) }, { selected_order: this.selected_order }, { product_review_list: this.product_review_list }, { user_review_list: this.user_review_list }, { reviewForm: this.reviewForm }, { title: this.instanceCreateReview.title }, { textareaLength: this.textareaLength });
    };
    /**
     * Dispatches the Create Reviews Component commands.
     * @param commandObject
     * @private
     */
    CreateReviewComponent.prototype._executeCommand = function (commandObject) {
        this[Object.keys(commandObject)[0]](Object.values(commandObject)[0]);
    };
    Object.defineProperty(CreateReviewComponent.prototype, "instanceCreateReview", {
        /**
         * Table 6.2.2. Users permissions according to their role
         * Buyer can add review on Product
         * Seller can add review on Buyer
         * @return {any}
         */
        get: function () {
            if (this.isSeller) {
                return this.mappings.buyer;
            }
            else {
                return this.mappings.product;
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @desc Checks the form validity. If valid, composes address object to
     * pass it to the ReviewService.
     */
    CreateReviewComponent.prototype.createReviewSeller = function () {
        this.checkAndSendReview(this.reviewsService.createBuyerReview(this.reviewBuyerBody));
    };
    CreateReviewComponent.prototype.createReviewProduct = function () {
        this.checkAndSendReview(this.reviewsService.createProductReview(this.reviewProductBody));
    };
    CreateReviewComponent.prototype.checkAndSendReview = function (createReviewMethod) {
        var _this = this;
        if (Number(this.reviewForm.value.stars) === 0) {
            this.showAlert(this.translate.instant('createReview.stars.empty'));
            return;
        }
        if (this.reviewForm.value.text.trim() !== '') {
            this.modalService.showSpinner();
            createReviewMethod.subscribe(function (review) {
                _this.modalService.close();
                _this.reviewsService.listFilterTabs.forEach(function (filter, n) {
                    _this.reviewsService.listFilterTabs[n].active = false;
                    if (filter.value === 'purchases') {
                        _this.reviewsService.listFilterTabs[n].active = true;
                    }
                });
                _this.router.navigate(['/reviews']);
            }, function (err) {
                _this.showAlert(err);
            });
        }
    };
    Object.defineProperty(CreateReviewComponent.prototype, "reviewProductBody", {
        get: function () {
            var v = this.reviewForm.value;
            return {
                stars: v.stars,
                text: v.text.trim(),
                order_element_id: this.selected_order.order_element_id,
                product_id: this.selected_order.ID
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateReviewComponent.prototype, "reviewBuyerBody", {
        get: function () {
            var v = this.reviewForm.value;
            return {
                stars: v.stars,
                text: v.text.trim(),
                order_element_id: this.selected_order.order_element_id,
                buyer_id: this.selected_order.ID
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateReviewComponent.prototype, "isSeller", {
        /**
         * Table 6.2.2. Users permissions according to their role
         * @return {boolean}
         */
        get: function () {
            return this.footerService.isSellerMode();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateReviewComponent.prototype, "isBuyer", {
        get: function () {
            return !this.footerService.isSellerMode();
        },
        enumerable: true,
        configurable: true
    });
    /** * **/
    /**
     * Builds the form group.
     * @desc Standard fields presetting and validators appointment.
     */
    CreateReviewComponent.prototype.buildForm = function () {
        this.reviewForm = this.fb.group({
            'text': [
                '', [Validators.required, Validators.maxLength(this.textareaLength), Validators.minLength(0)]
            ],
            'stars': [
                '', [Validators.required]
            ]
        });
    };
    CreateReviewComponent.prototype.checkCustomer = function () {
        if (this.isSeller) {
            this.user_review_list = this.reviewsService.getUserReviews;
        }
        if (this.isBuyer) {
            this.product_review_list = this.reviewsService.getProductReviews;
        }
    };
    CreateReviewComponent.prototype.showAlert = function (message) {
        this.modalService.error({ title: this.translate.instant('review.alert.title'), message: message, yesButtonText: this.translate.instant('review.alert.confirm') });
    };
    return CreateReviewComponent;
}());
export { CreateReviewComponent };
