
        <div [class]="impersonation === true ? 'app__container impersonation' : 'app__container'">
            <modal data-test-id="modal"></modal>
            <cache-notification-component data-test-id="cache-notification-component"></cache-notification-component>
            <unsupported-device-component data-test-id="unsupported-device"></unsupported-device-component>

            <div class="main__container">
                <app-header [class]="app_header__width" [cart_icon]="cart_icon" [order_icon]="order_icon" [counts]="counts" [impersonation]="impersonation" [userNameImpersonation]="userNameImpersonation" [userName]="userName" [type]="type"></app-header>
                <router-outlet></router-outlet>
            </div>

            <app-footer
                *ngIf="!hideFooter"
                [hidden]="isSearchActivated"
                [type]="type"
            ></app-footer>
        </div>
    