/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./goods-nav-header.component";
var styles_GoodsNavHeaderComponent = [];
var RenderType_GoodsNavHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GoodsNavHeaderComponent, data: {} });
export { RenderType_GoodsNavHeaderComponent as RenderType_GoodsNavHeaderComponent };
export function View_GoodsNavHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "nav", [["class", "app-header__container"], ["role", "goods-nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [["class", "nav__link"], ["data-test-id", "shoppingLink"], ["routerLink", "/shopping-list"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "navbar__center"], ["role", "goods-nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 0, "div", [["class", "navbar__right"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/shopping-list"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4).target; var currVal_1 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.categoryName; _ck(_v, 8, 0, currVal_3); }); }
export function View_GoodsNavHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "goods-nav-header", [], null, null, null, View_GoodsNavHeaderComponent_0, RenderType_GoodsNavHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i3.GoodsNavHeaderComponent, [], null, null)], null, null); }
var GoodsNavHeaderComponentNgFactory = i0.ɵccf("goods-nav-header", i3.GoodsNavHeaderComponent, View_GoodsNavHeaderComponent_Host_0, { categoryName: "categoryName" }, {}, []);
export { GoodsNavHeaderComponentNgFactory as GoodsNavHeaderComponentNgFactory };
