import { Component,
    EventEmitter, Input,
    OnInit, Output }        from '@angular/core';

import { FoundDialog } from '../../../swagger-gen__output_dir';
import AppValues from '../common/app.values';

@Component({
    selector:   'dialogs-list-item',
    styleUrls:  ['chat.sass'],

    // animations: [slideInOutAnimation],
    // host:       { '[@slideInOutAnimation]': '' },

    template:   `
        <div
            class="dialog-list__item"
            [attr.data-test-id]="'dialog-container_'+id"
            (click)="showDialog()"
        >
            <div class="box box-left">
                <div
                   class="dialog-name"
                   [attr.data-test-id]="'dialog-name_'+id"
                >
                    {{ dialog.interlocutor_title }}
                </div>
                <div
                   class="last-message"
                   [attr.data-test-id]="'last-message_'+id"
                >
                   {{ dialog.last_message }}
                </div>
            </div>

            <div class="box box-right">
                <h4
                   class="time-last-message"
                   [attr.data-test-id]="'time-last-message_'+id"
                >
                    {{ dialog.last_message_date * THOUSAND | date:format }}
                </h4>

                <div class="relative unread_messages_count">
                    <div *ngIf="dialog.unread_messages_count" class="badge" [attr.aria-labelledby]="'There are ' + dialog.unread_messages_count + ' unread messages'">
                        <span [attr.data-test-id]="'dialog_unread_message_count_'+id" class="relative">{{dialog.unread_messages_count}}</span>
                    </div>
                </div>

                <span class="remove-button"
                      [attr.data-test-id]="'removeItem_'+id"
                      (click)="removeDialog($event)">

                <i class="icon-trash-empty"></i>
            </span>
            </div>
        </div>
    `
})
export class DialogsListItemComponent implements OnInit {
    /**
     * Represents a dialog in the dialogs list.
     */
    @Input()    dialog:         FoundDialog;
    @Input()    id:             string;
    @Output()   removeEvent$    = new EventEmitter();
    @Output()   showEvent$      = new EventEmitter();

    format:     string;
    THOUSAND    = 1000;

    /**
     * Sets date format differently for today's messages and yester.
     */
    ngOnInit() {
        const today = new Date().setHours(0, 0, 0, 0) / this.THOUSAND;

        this.format = this.dialog.last_message_date < today ? AppValues.allNumericDatePattern : AppValues.timePattern;
    }


    /**
     * @emits showEvent$ on component click.
     */
    showDialog(): void {
        this.showEvent$.emit();
    }


    /**
     * @emits removeEvent$ on delete.
     * @param event
     */
    removeDialog(event: MouseEvent): void {
        event.stopPropagation();
        this.removeEvent$.emit();
    }

}
