import { Observable }           from 'rxjs/Observable';
import { Router }               from '@angular/router';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';

import { DataService }          from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import { ImageUploadService }   from '../services/image-upload.service';
import { ModalService }         from '../modal/modal.service';
import { CategoryBasicInfo }    from '../interfaces/category.interface';
import { UnitInterface }        from '../interfaces/unit.interface';
import { UserService }          from '../user/user.service';
import AppValues from '../common/app.values';
import {
    SellingItemRequestBody
} from '../../../swagger-gen__output_dir/model/sellingItemRequestBody';
import {
    SellingItemResponseBody
} from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { ItemToUpdate } from '../../../swagger-gen__output_dir/model/itemToUpdate';
import {ItemToCreate} from '../../../swagger-gen__output_dir/model/itemToCreate';
import { tap } from 'rxjs/operators';


export abstract class ProductEditorAbstractClass {
    protected item: SellingItemResponseBody;
    protected units: UnitInterface[];
    protected isCreatingItem: boolean;
    protected categoryObject: CategoryBasicInfo;
    protected defaultShippingTerms = 'Contact Seller for shipping details';

    private ONE_HOUR = 3600;

    constructor(protected dataService: DataService,
                protected errorService: ErrorService,
                protected imageUploadService: ImageUploadService,
                protected modalService: ModalService,
                protected router: Router,
                protected userService: UserService) {
    }


    abstract _onCreateItemSuccess(): void;


    /**
     * @desc Posts a product item template. On response creates item object and navigates
     * to the product editor.
     * @param {CategoryBasicInfo} obj
     */
    createItem(obj: CategoryBasicInfo): void {
        this.item = this._createBody({obj, blank: true}).item;
        this.item.events = [];
        this.categoryObject = obj;

        this._onCreateItemSuccess();
    }

    /**
     * isCreatingItem value need for create or update item
     * isCreatingItem is boolean
     * @param {string} type
     */
    set_is_creating_item(type: string) {
        this.isCreatingItem = (type === 'newItem');
    }


    _sendCreateRequest(body: any) {
        const token = this.userService.getUserSession().token;
        if (!token) return;

        this.modalService.showSpinner();

        return this.dataService.postData('selling_item', body, {token})
            .catch((err: ErrorInterface) => this.errorService.handleError(err));
    }


    /**
     * @desc Creates a product object template by given subcategoryEntryID and subcategoryEntryName.
     * @param {CategoryBasicInfo} obj
     * @param {ItemToCreate} item
     * @return {SellingItemRequestBody}
     * @private
     */
    _createBody(params: {obj: CategoryBasicInfo, item?: ItemToCreate, blank?: boolean}): SellingItemRequestBody {
        const timeNow = AppValues.getDateNow();
        const user  = this.userService.getUser();
        params.item = params.item ? params.item : {} as any;

        return {
            subcategory_entry_id:             params.obj.subcategoryEntryID,
            item: {
                saleIsOn:                     typeof params.item.saleIsOn !== 'undefined' ? params.item.saleIsOn : false,
                description:                  params.item.description || '',
                title:                        params.item.title || '',
                salePrice:                    params.item.salePrice || 0,
                deliveryOffered:              typeof params.item.deliveryOffered !== 'undefined' ? params.item.deliveryOffered : false,
                productionDate:               params.item.productionDate ? params.item.productionDate : null,
                subcategoryEntryID:           params.obj.subcategoryEntryID,
                price:                        params.item.price || 0,
                sellerPictureURL:             params.item.sellerPictureURL || user.imageURL || '',
                shipping_terms:               params.item.shipping_terms || (params.blank ? '' : this.defaultShippingTerms),
                nonGMO:                       typeof params.item.nonGMO !== 'undefined' ? params.item.nonGMO : false,
                validTill:                    params.item.validTill || timeNow + this.ONE_HOUR,
                valitFrom:                    params.item.valitFrom || timeNow,
                qtyUnitName:                  params.item.qtyUnitName || '',
                organic_certificate_image:    params.item.organic_certificate_image || '',
                photoURL:                     params.item.photoURL || '',
                saleDateFrom:                 params.item.saleDateFrom || timeNow,
                saleDateTill:                 params.item.saleDateTill || timeNow + this.ONE_HOUR,
                marketPickOffered:            typeof params.item.marketPickOffered !== 'undefined' ? params.item.marketPickOffered : false,
                qtyUnitID:                    params.item.qtyUnitID || '',
                sellerID:                     user.ID,
                saleUnitID:                   params.item.saleUnitID || '',
                nonGMO_certificate_image:     params.item.nonGMO_certificate_image || '',
                originCountry:                params.item.originCountry || 'United States',
                subcategoryEntryName:         params.obj.descriptor,
                priceUnitsName:               params.item.priceUnitsName || '',
                draft:                        typeof params.item.draft !== 'undefined' ? params.item.draft : true,
                organic:                      typeof params.item.organic !== 'undefined' ? params.item.organic : false,
                qty:                          params.item.qty || 0,
                priceUnitsID:                 params.item.priceUnitsID || '',
                saleUnitName:                 params.item.saleUnitName || '',
                sellerName:                   user.title,
                productNo:                    params.item.productNo || ''
            }
        };
    }


    /**
     * Calls the DataService with `update item` request.
     * @param item
     * @returns {any}
     */
    updateItem(item: ItemToCreate | SellingItemResponseBody): Observable<SellingItemResponseBody> {
        const userSession = this.userService.getUserSession();

        if (!userSession.token) return Observable.of();

        this.modalService.showSpinner();

        item = this.checkCertificateImage(item);

        if ( this.isCreatingItem ) {
            // @ts-ignore
            return this._sendCreateRequest(this._createBody({obj: this.categoryObject, item: item as ItemToCreate}))
                .do((_item: SellingItemResponseBody) => {
                    this.item = _item;

                    // TODO: remove after server updates
                    this.item.inFavList = false;
                    this.item.inWatchList = false;

                    this._onCreateItemSuccess();

                    return this.item;
                }).catch((err: string) => {
                    return Observable.throwError(err);
                });
        } else {
            if (!item.shipping_terms) {
                item.shipping_terms = this.defaultShippingTerms;
            }

            item = itemToUpdateBody(item);

            // @ts-ignore
            return this.dataService.postData('update_item', item as SellingItemResponseBody, {token: userSession.token})
                .do((_item: SellingItemResponseBody) => {
                    this.item = _item;
                })
                .catch((err: ErrorInterface) => {
                    return this.errorService.handleError(err);
                });
        }
    }

    private checkCertificateImage(item: ItemToCreate | SellingItemResponseBody): ItemToCreate | SellingItemResponseBody {
        if (!item.organic_certificate_image) {
            item.organic = false;
        }
        if (!item.nonGMO_certificate_image) {
            item.nonGMO = false;
        }

        return item;
    }


    /**
     * Fetches the units of measure data object.
     * @returns {Observable<R|T>}
     */
    getUnits() {
        return this.units
            ? Observable.of(this.units)
            : this.dataService.getData('units')
                .do((units: UnitInterface[]) => this.units = units)
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
    }


    /**
     * Displays a warning message.
     * @param msg
     */
    showWarningMessage(msg: string) {
        this.modalService.warning({
            title: 'Alert:',
            message: msg,
            yesButtonText: 'Close',
        });
    }


    /**
     * @desc Delegates image uploading to ImageUploadService. Processes spinner and error handling.
     * @param img
     * @returns {Observable<R>}
     */
    uploadImg(img: Blob): Observable<{}> {
        this.modalService.showSpinner();

        return this.imageUploadService.uploadImg(img, this.userService.getUserSession().token)
            .do(() => this.modalService.close())

            .catch((err: ErrorInterface | any) => this.errorService.handleError(err));
    }
}

export function itemToUpdateBody(item: SellingItemResponseBody): ItemToUpdate {
    return {
        "ID": item.ID,
        "deliveryOffered": item.deliveryOffered,
        "description": item.description,
        "draft": item.draft,
        "dynamic_link": item.dynamic_link,
        "inFavList": item.inFavList,
        "inWatchList": item.inWatchList,
        "marketPickOffered": item.marketPickOffered,
        "nonGMO": item.nonGMO,
        "nonGMO_certificate_image": item.nonGMO_certificate_image,
        "organic": item.organic,
        "organic_certificate_image": item.organic_certificate_image,
        "originCountry": item.originCountry,
        "photoURL": item.photoURL,
        "price": item.price,
        "priceUnitsID": item.priceUnitsID,
        "priceUnitsName": item.priceUnitsName,
        "productNo": item.productNo,
        "productionDate": item.productionDate,
        "qty": item.qty,
        "qtyUnitID": item.qtyUnitID,
        "qtyUnitName": item.qtyUnitName,
        "rate": item.rate,
        "saleDateFrom": item.saleDateFrom,
        "saleDateTill": item.saleDateTill,
        "saleIsOn": item.saleIsOn,
        "salePrice": item.salePrice,
        "saleUnitID": item.saleUnitID,
        "saleUnitName": item.saleUnitName,
        "sellerID": item.sellerID,
        "sellerName": item.sellerName,
        "sellerPictureURL": item.sellerPictureURL,
        "shipping_terms": item.shipping_terms,
        "subcategoryEntryID": item.subcategoryEntryID,
        "subcategoryEntryName": item.subcategoryEntryName,
        "title": item.title,
        "validTill": item.validTill,
        "valitFrom": item.valitFrom
    };
}
