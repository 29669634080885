import { Component,
    EventEmitter, Input,
    Output }                from '@angular/core';

@Component({
    selector:   'rect-switch',
    styleUrls:  ['rect-switch.sass'],
    template:   `
        <div class="onoffswitch" [ngClass]="{'inactive': disabled }">
            <input type="checkbox" data-test-id="detailSwitchAlert" name="onoffswitch" class="onoffswitch-checkbox"
                    [id]="_id"
                    [checked]="state"
                    (change)="onChange($event)">
            
            <label tabindex="0" class="onoffswitch-label" [for]="_id" [attr.data-test-id]="dataTestId">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
            </label>
        </div>
    `
})

export class RectSwitchComponent {
    @Input()    dataTestId:     string;
    @Input()    disabled:       any;
    @Input()    state:          boolean;
    @Output()   stateEvent$:    EventEmitter<boolean> = new EventEmitter<boolean>();



    _id = Math.random().toString(36).substr(2);



    onChange(event: Event) {
        !this.disabled && this.stateEvent$.emit(event.target['checked']);
    }

}
