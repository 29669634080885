import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgModule, ModuleWithProviders }    from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {PaymentComponent} from './payment-component';
import {HttpLoaderFactory} from '../app.module';
import {ClientPaymentService} from './payment.service';
import {SuccessOnboardingComponent} from './success-onboarding';
import {RouterModule} from "@angular/router";
import {HttpClient} from "@angular/common/http";



@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        PaymentComponent,
        SuccessOnboardingComponent
    ],
    exports: [
        PaymentComponent,
        SuccessOnboardingComponent
    ]
})
export class PaymentModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: PaymentModule,
            providers: [ClientPaymentService]
        };
    }
}
