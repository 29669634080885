import {
    Component, OnInit,
    ViewChild, ViewContainerRef,
    ComponentFactoryResolver,
    ComponentRef
}                                   from '@angular/core';

import {CacheNotificationService} from './cache-notification.service';
import {PrivacyPolicyComponent} from './privacy-policy.component';
import {BlankComponent} from '../modal/blank.component';
import {
    CacheNotificationDynamicAbstractClass
} from './dynamic-abstract-class';
import {ComponentFactory} from '@angular/core/src/linker/component_factory';
import {CacheNotificationCommandInterface} from "./cache-notification.interface";


@Component({
    selector:   'cache-notification-component',
    template:   `
        <div [class]="modalContainerClass" #container tabindex="1"
                [ngClass]="{'modal__invisible': !isVisible}">
        </div>
    `
})

export class CacheNotificationComponent implements OnInit {
    @ViewChild('container', { read: ViewContainerRef })

    public container:           ViewContainerRef;
    public isVisible:           boolean;
    private componentRef:       ComponentRef<{}>;
    public modalContainerClass: string = 'cache-modal__container';


    constructor(
        private componentFactoryResolver:   ComponentFactoryResolver,
        private cacheNotificationService:   CacheNotificationService,
    ) { }



    public ngOnInit(): void {
        this.cacheNotificationService.getCommand()
            .subscribe((command: CacheNotificationCommandInterface) => this._execute(command));
    }

    private _execute(command: CacheNotificationCommandInterface): void {
        this.cacheNotificationService.modalType = command.component;

        this.close();

        if (command.context.modalContainerClass) {
            this.modalContainerClass = command.context.modalContainerClass;
        }

        if (command.component && command.component !== BlankComponent) {
            this._renderModal(command);
        }
    }

    private _renderModal(command: CacheNotificationCommandInterface): void {
        let componentType: typeof BlankComponent | typeof PrivacyPolicyComponent = command.component;

        let factory: ComponentFactory<BlankComponent | PrivacyPolicyComponent> = this.componentFactoryResolver.resolveComponentFactory(componentType);
        this.componentRef   = this.container.createComponent(factory);

        let instance: CacheNotificationDynamicAbstractClass = <CacheNotificationDynamicAbstractClass> this.componentRef.instance;
        instance.context    = Object.assign(command.context, {_close: this.close.bind(this)});

        this._handleKBD();
    }

    /**
     * work with Keyboard Input element
     * @private
     */
    private _handleKBD(): void {
        this.isVisible = true;
        document.addEventListener('keydown', this._processKDB.bind(this));
    }

    /**
     * close modal and remove container
     * @private
     */
    public close(): void {
        this.isVisible = false;
        this.container.remove();

        document.removeEventListener('keydown', this._processKDB.bind(this));
    }

    /**
     * work with Keyboard Input element
     * @param event
     * @private
     */
    private _processKDB(event: TouchEvent): void {
        if (event.keyCode === 9) {
            setTimeout(() => this.container.element.nativeElement.focus());
        }
    }

}
