import {Injectable, Injector} from '@angular/core';
import { Observable }   from 'rxjs/Observable';
import {Subject, Subscriber} from 'rxjs';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

import { CategoryInterface,
    NavTreeInterface,
    SubcategoryInterface }      from '../interfaces/category.interface';

import { DataService }          from '../services/data.service';
import { ErrorInterface, ErrorService } from '../services/error.service';
import { GoodsNavService }      from '../goods-nav/goods-nav.service';
import { ModalService }         from '../modal/modal.service';
import { UserService }          from '../user/user.service';
import { ItemService } from '../services/item.service';
import AppValues from '../common/app.values';
import {
    ConcreteNavigationTreeCaching, ConcreteShortNavigationTreeCaching,
    CreatorLocalCaching,
    LocalCaching, LocalCachingCreatorMapping
} from '../local-caching/local-caching-factory';
import {CountersService} from '../../../swagger-gen__output_dir/api/counters.service';
import {GetCounterResponse} from '../../../swagger-gen__output_dir/model/getCounterResponse';
import {Caretaker, Originator} from '../services/memento.service';
import {AppFooterMappingInterface} from './app-footer.interface';

export enum UserMode {
    buyerMode = 'buyer',
    sellerMode = 'seller'
}
export enum CategoryTreeTypes {
    shortNavTree= 'get_short_navigation_tree',
    fullNavTree = 'get_navigation_tree'
}
@Injectable()
export class AppFooterService {
    /**
     * Serves interactions for the AppFooter and the included components.
     */
    public accessLevel:            number;
    public categories:             CategoryInterface[];
    public mode:                   UserMode  = UserMode.buyerMode;
    public counters:               GetCounterResponse;
    public modeOriginator:         Originator;
    public modeCaretaker:          Caretaker;

    private modeChanges$: Subject<string> = new Subject<string>();
    private navigationTreeCategories: CategoryInterface[] = [];
    private shortNavigationTreeCategories: CategoryInterface[] = [];

    /** For using LocalCaching class
    * instance fields - Creators subclasses typically provide an implementation of this method.
    * categories fields:
    * get_navigation_tree - contained in all categories contained in the proposals
    * and with which they can create products. Using for seller mode.
    * get_short_navigation_tree - only sub categories with which products have already
    * been created are saved. Using for shopper mode.
    **/
    private appFooterMapping: AppFooterMappingInterface = {
        get_navigation_tree: {
            instance: LocalCachingCreatorMapping.ConcreteNavigationTreeCaching,
            categories: this.navigationTreeCategories
        },
        get_short_navigation_tree: {
            instance: LocalCachingCreatorMapping.ConcreteShortNavigationTreeCaching,
            categories: this.shortNavigationTreeCategories
        }
    };

    public constructor(
        private dataService:                DataService,
        private countersService:            CountersService,
        private errorService:               ErrorService,
        private goodsNavService:            GoodsNavService,
        private modalService:               ModalService,
        private itemService:                ItemService,
        private localCaching:               LocalCaching,
        private injector:                   Injector,
    ) {
        this.subscribeOnUserAuthEvent();

        this.modeOriginator = new Originator(this.mode);
        this.modeCaretaker = new Caretaker(this.modeOriginator);
    }

    public get user_service(): UserService {
        return this.injector.get(UserService);
    }

    /**
     * save Mode into Momento
     * @param {string} mode
     */
    public setModeOriginator(mode: string): void {
        this.modeOriginator.setState(mode);
        this.modeCaretaker.backup();
    }

    public removeModeOriginator(): void {
        this.modeCaretaker.undo();
    }

    /**
     * return new Observable wrapper to AppFooterComponent's constructor
     * @return {Observable<null>}
     */
    public getUsersData(): Observable<null> {
        return new Observable((observer: Subscriber<null>) => {
            this.user_service.userAuthEvent$
                .subscribe(() => {
                    this.setMode(UserMode.buyerMode);
                    observer.next();
                    observer.complete();
                });
        });
    }


    /**
     * @desc Returns subscription on user mode changes as Observable
     * @return {Observable<string>}
     */
    public get onModeChanges(): Observable<string> {
       return this.modeChanges$.asObservable();
    }


    /**
     * Delegates call to GoodsNavService.
     * @param category
     * @param isSeller
     * @param isSheet
     */
    public showNavigation(category: CategoryInterface, isSeller: boolean, isSheet?: boolean): void {
        this.goodsNavService.showNavigation(category, isSeller, isSheet);
    }


    /**
     * Setter for AppFooter mode (seller\buyer).
     * @returns {string}
     */
    public getMode(): UserMode { return this.mode; }

    public isSellerMode(): boolean {
        return this.getMode() === UserMode.sellerMode;
    }

    public getAccessLevel(): number {
        this.accessLevel = this.user_service.getUser().accessLevel;

        return this.accessLevel;
    }


    /**
     * Getter for AppFooter mode (seller\buyer).
     * @param mode
     */
    public setMode(mode: UserMode): UserMode {
        const _accessLevel: number = this.getAccessLevel();
        this.mode = _accessLevel > 1 ? mode : UserMode.buyerMode;

        this.modeChanges$.next(this.mode);

        return this.mode;
    }


    public getCounts(): Observable<{}> | Observable<GetCounterResponse> {
        const token: string = this.user_service.getUserSession().token;

        if (token) {
            return this.countersService.getCountersForUserGet(token)
                .do((counters: GetCounterResponse) => {
                    this.counters = counters;
                    return counters;
                })
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({});
    }


    /**
     * @desc Decides to query for short or full navigation tree data
     * via DataService. Sorts fetched subcategories and their entries,
     * calling #_sortResponse.
     * @returns {Observable<R|T>}
     * @param isFullNavTree
     */
    public getNavTree(isFullNavTree?: boolean): Observable<CategoryInterface[] | ErrorInterface> {
        const url: CategoryTreeTypes = (isFullNavTree)
            ? CategoryTreeTypes.fullNavTree
            : CategoryTreeTypes.shortNavTree;

        return this.setExistingCategory(this.appFooterMapping[url].instance, url);
    }

    public getCategories(url: CategoryTreeTypes): Observable<CategoryInterface[]> {
        const token: string = this.user_service.getToken();

        if (!token) { return Observable.of([]); }

        return this.dataService.getData(url, {token})
            .map((response: NavTreeInterface) => {
                this.categories = this._sortResponse(response['categories']);
                this.itemService.setCategory(this.categories);

                this.localCaching.setCache(this.appFooterMapping[url].instance, this.categories);
                this.appFooterMapping[url].categories = this.categories;

                return this.categories;
            })
            .catch((err: ErrorInterface) => this.errorService.handleError(err));
    }

    public clearShortNavigationTree(): void {
        this.appFooterMapping[CategoryTreeTypes.shortNavTree].categories = [];
        this.localCaching.clearAllCache(this.appFooterMapping[CategoryTreeTypes.shortNavTree].instance);
    }

    private setExistingCategory(creatorName: LocalCachingCreatorMapping, url: CategoryTreeTypes): Observable<CategoryInterface[]> {

        if (url === CategoryTreeTypes.shortNavTree) {
            return this.getCategories(url);
        }
        if (url === CategoryTreeTypes.fullNavTree) {

            if (this.appFooterMapping[url].categories.length === 0) {
                return this.getFullNavTree(creatorName);

            } else {
                this.categories = this.appFooterMapping[url].categories;
                return Observable.of(this.categories);
            }
        }
    }

    private getFullNavTree(creatorName: LocalCachingCreatorMapping): Observable<CategoryInterface[]> {

        if (this.localCaching.isExistCache(creatorName)) {
            this.categories = this.localCaching.getOneCache(creatorName, []);
            return Observable.of(this.categories);

        } else {
            return this.getCategories(CategoryTreeTypes.fullNavTree);
        }
    }


    public getCachedTree(): CategoryInterface[] {
        return this.categories;
    }


    /**
     * @desc Iterating over each of the category, sorts containing
     *       subcategories and their entries by their names alphabetically.
     *
     *       According to meeting solution on 4/28/2020, we should sort
     *       just sub categories and sub categories entries.
     *       Categories must be unsorted!
     * @param categories
     * @returns {CategoryInterface[]}
     * @private
     */
    private _sortResponse(categories: CategoryInterface[]): CategoryInterface[] {
        categories.forEach((cat: CategoryInterface) => {
            AppValues.sortItems(cat.sub_categories, 'sub_category_name');

            cat.sub_categories.forEach((sc: SubcategoryInterface) => {
                AppValues.sortItems(sc.sub_category_entries, 'subcategory_entry_name');
            });

        });

        return categories;
    }


    /**
     * @desc Subscribes on UserAuthEvent$
     * @private
     */
   private subscribeOnUserAuthEvent():  void {
      this.user_service.userAuthEvent$
         .subscribe(() => {
             this.setMode(UserMode.buyerMode);
         });
   }
}
