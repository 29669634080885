import { Injectable }               from '@angular/core';
import { Observable }               from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/throw';

import { DataService }              from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import { ModalService }             from '../modal/modal.service';
import { WatchListItemInterface }   from './watch-list.interface';
import { UserService }              from '../user/user.service';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {TranslateService} from "@ngx-translate/core";


@Injectable()
export class WatchListService {

   list:                            WatchListItemInterface;

    constructor(
        private dataService:        DataService,
        private errorService:       ErrorService,
        private modalService:       ModalService,
        private translate:          TranslateService,
        private userService:        UserService,
    ) { }


    public getList(isVisibleSpinner: boolean = true): Observable<WatchListItemInterface | {}> {
        let token: string = this.userService.getUserSession().token;

        if (token) {
            isVisibleSpinner && this.modalService.showSpinner();

            return this.dataService.getData('watch_list', {token})
                .do((res: WatchListItemInterface) => {
                    this.list = res;
                    isVisibleSpinner && this.modalService.close();
                })
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({});
    }


    public toggleWatchListStatus(itemID): Observable<WatchListItemInterface | {}> {
        return this.isInWatchList(itemID)
            .switchMap((isInList: boolean) => isInList
                ? this.removeItem(itemID)
                : this.addItem(itemID)
            );
    }


    public addItem(id: string): Observable<WatchListItemInterface | {}>  {
        const obj   = { items_ids: [id] };
        const user  = this.userService.getUser();
        const list  = user.watchList;

        list.push(id);

        return this._editList('add_to_wl', obj, list);
    }


    public removeItem(id: string): Observable<WatchListItemInterface | {}> {
        const obj   = { items_ids: [id] };
        const user  = this.userService.getUser();
        const list  = user.watchList;

        list.splice(list.indexOf(id), 1);

        return this._editList('delete_from_wl', obj, list);
    }


    private _editList(url: string, item: {}, list: {}): Observable<WatchListItemInterface | {}> {
        const token = this.userService.getUserSession().token;
        const user  = this.userService.getUser();

        if (token) {
            this.modalService.showSpinner();

            return this.dataService.postData(url, item, {token})
                .do((_list: WatchListItemInterface) => {
                    this.modalService.close();

                    this.userService.updateUser( Object.assign(user, {watchList: list}) );
                    this.list = _list;
                })
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({});
    }



    public isInWatchList(itemID: string, isVisibleSpinner: boolean = true): Observable<boolean> {
        return this.getList(isVisibleSpinner).map((list: WatchListItemInterface) => {
            if (!list.items) return false;

            return list.items.findIndex((item: SellingItemResponseBody) => item.ID === itemID) >= 0;
        });
    }


    public removingItemFromWatchList(): Promise<boolean> {
        return this.modalService.warning({
            title: this.translate.instant("removingItemFromWatchList.modal.title"),
            message: this.translate.instant("removingItemFromWatchList.modal.message"),
            yesButtonText: this.translate.instant("removingItemFromWatchList.modal.yes"),
            noButtonText: this.translate.instant("removingItemFromWatchList.modal.no"),
            reverseButtons: true,
        }).then((action: boolean) => action );
    }

}
