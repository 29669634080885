import {Injectable} from '@angular/core';

export interface CookieOptions {
    expires?: any;
    path?: string;
    domain?: string;
    secure?: string;
}

@Injectable()
export class CookieService {

    public getAllCookies(): Array<string> {
        return document.cookie.split(";");
    }
    public getAllMatchingCookiesName(text: string): Array<string> {
        const allCookies = this.getAllCookies();

        let matchingCookies: Array<string> = new Array;
        allCookies.forEach((c: string) => {
            if (c.includes(text)) {
                matchingCookies.push(c.trim().split('=')[0]);
            }
        });
        return matchingCookies;
    }

    public getCookie(name: string): string {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let result: string;

        for (let i = 0; i < caLen; i += 1) {
            result = ca[i].replace(/^\s+/g, '');
            if (result.indexOf(cookieName) === 0) {
                return result.substring(cookieName.length, result.length);
            }
        }
        return '';
    }

    public setCookie(name: string, value: any, options?: CookieOptions): void {
        options = options || {'path': '/'}; // If we use the option path '/' - then this cookie data will be enable for all application

        let expires = options.expires;

        if (typeof expires === 'number' && expires) {
            let d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }

        let updatedCookie = name + '=' + value;

        for (let propName in options) {
            updatedCookie += '; ' + propName;
            let propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += '=' + propValue;
            }
        }

        document.cookie = updatedCookie;
    }

    public deleteCookie(name: string): void {
        this.setCookie(name, '', {
            expires: -1
        });
    }

    public deleteAllCookies(): void {
        let cookies = this.getAllCookies();

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            this.deleteCookie(name);
        }
    }
}
