/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./modal-category-selector.component";
var styles_ModalCategorySelectorComponent = [];
var RenderType_ModalCategorySelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalCategorySelectorComponent, data: {} });
export { RenderType_ModalCategorySelectorComponent as RenderType_ModalCategorySelectorComponent };
function View_ModalCategorySelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "select-category text-center"]], [[4, "color", null], [1, "data-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showCategory(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0], [1, "data-test-id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.category_name_text_color; var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.ID, ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.category_image, ""); var currVal_3 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.category_name, ""); var currVal_4 = _v.context.$implicit.category_name; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.category_name; _ck(_v, 3, 0, currVal_5); }); }
export function View_ModalCategorySelectorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "modal__content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.context._close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "modal__categories"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalCategorySelectorComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context.data; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ModalCategorySelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "modal-dialog", [], null, null, null, View_ModalCategorySelectorComponent_0, RenderType_ModalCategorySelectorComponent)), i0.ɵdid(1, 49152, null, 0, i2.ModalCategorySelectorComponent, [], null, null)], null, null); }
var ModalCategorySelectorComponentNgFactory = i0.ɵccf("modal-dialog", i2.ModalCategorySelectorComponent, View_ModalCategorySelectorComponent_Host_0, {}, {}, []);
export { ModalCategorySelectorComponentNgFactory as ModalCategorySelectorComponentNgFactory };
