/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./cache-notification.component";
import * as i3 from "./cache-notification.service";
var styles_CacheNotificationComponent = [];
var RenderType_CacheNotificationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CacheNotificationComponent, data: {} });
export { RenderType_CacheNotificationComponent as RenderType_CacheNotificationComponent };
export function View_CacheNotificationComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { container: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["container", 1]], null, 2, "div", [["tabindex", "1"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "modal__invisible": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalContainerClass; var currVal_1 = _ck(_v, 3, 0, !_co.isVisible); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CacheNotificationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cache-notification-component", [], null, null, null, View_CacheNotificationComponent_0, RenderType_CacheNotificationComponent)), i0.ɵdid(1, 114688, null, 0, i2.CacheNotificationComponent, [i0.ComponentFactoryResolver, i3.CacheNotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CacheNotificationComponentNgFactory = i0.ɵccf("cache-notification-component", i2.CacheNotificationComponent, View_CacheNotificationComponent_Host_0, {}, {}, []);
export { CacheNotificationComponentNgFactory as CacheNotificationComponentNgFactory };
