import { Injectable }   from '@angular/core';
import { Observable, Subscriber }   from 'rxjs';
import { Router } from '@angular/router';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';

import { ProductDetailsService }    from '../product-details/product-details.service';
import {AppSearchService} from '../search/search.service';
import { GeoLocationService }       from '../services/geolocation.service';
import { CenterLocationInterface } from '../interfaces/location.interface';
import {ErrorInterface, ErrorService} from '../services/error.service';
import {AdvertisementService} from '../../../swagger-gen__output_dir/api/advertisement.service';
import {AdsListResponseBody} from '../../../swagger-gen__output_dir/model/adsListResponseBody';
import {Users} from '../../../swagger-gen__output_dir/model/users';
import { SearchType } from '../interfaces/search.interface';

@Injectable()
export class CarouselService {

    public carousel:   AdsListResponseBody;

    constructor(
        private errorService: ErrorService,
        private productService: ProductDetailsService,
        private searchService: AppSearchService,
        private geoLocationService: GeoLocationService,
        private advertisementService: AdvertisementService,
        private router: Router
    ) {
        this.geoLocationService.locationEvent$.subscribe((res) => {
            this.searchService.userLocation = res;
        });
    }


    public getSlides(): Observable<AdsListResponseBody | ErrorInterface> {
        return this.carousel
            ?   Observable.of(this.carousel)
            :   this.advertisementService.adsListGet()
                    .map((res: AdsListResponseBody) => this.carousel = res)
                    .catch((err: ErrorInterface) => this.errorService.handleError(err));
    }


    /**
     * @desc Processes the click on asd and navigate
     *       to the Seller's full list of products.
     * @param {User} user
     * @returns {void}
     */
    public asdClick(user: Users): void {
            this.productService.setProductDetailsFrom('ads');

            this.searchService.search(user.ID, SearchType.Item, { isNew: true })
               .subscribe(() => {
                   const title_name = `${user.title.trim()}'s items`;
                   this.searchService.is_key_search = false;
                   this.router.navigate(['/search'], { queryParams: { key: title_name } });
               });
    }


    /**
     * @desc Gets the coordinates of the device from the browser.
     * @returns {Observable<boolean>}
     */
    private getGeolocation(): Observable<boolean> {
        return new Observable((observer: Subscriber<boolean>) => {
           this.geoLocationService.getLocation((geo: Position) => {
              this.setUserLocation(observer, geo.coords);
           }, () => {
               this.geoLocationService.ipLookUp()
                   .subscribe((res: any) => {
                       this.setUserLocation(observer, res);
                   }, () => {
                       this.setUserLocation(
                          observer,
                          this.geoLocationService.getDefaultUserLocation()
                       );
                   });
           });
        });
    }


    /**
     * @desc Saves user location for search service and emit
     *       observer's next() callback with 'true' value.
     * @param {Subscriber<boolean>} observer
     * @param {CenterLocationInterface} coords
     * @returns {void}
     */
    private setUserLocation(
      observer: Subscriber<boolean>,
      coords: CenterLocationInterface
    ): void {
        this.searchService.userLocation = coords;
        observer.next(true);
        observer.complete();
    }

}
