import {Injectable, Injector} from '@angular/core';
import {CartService} from '../cart/cart.service';
import {
    PaymentIntentResponse, paymentRequest, StripeInterface,
    StripeCanMakePaymentResponse, elements, StripeError, StripeOptions,
} from './stripe.interface';
import {ModalService} from '../modal/modal.service';
import {ICustomWindow, WindowRefService} from '../services/window-ref.service';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {PaymentView} from '../../../swagger-gen__output_dir/model/paymentView';
import AppValues from '../common/app.values';
import {ErrorInterface, ErrorService} from '../services/error.service';
import {PaymentsService} from '../../../swagger-gen__output_dir/api/payments.service';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {PayeeAccountView} from '../../../swagger-gen__output_dir/model/payeeAccountView';
import {ExternalLinkView} from '../../../swagger-gen__output_dir/model/externalLinkView';
import {UserService} from '../user/user.service';
import {UnsupportedDeviceService} from '../unsupported-info/unsupported-device.service';
import {ShoppingCartResponseBody} from "../../../swagger-gen__output_dir";
import { getErrorType } from '../services/error.helpers';

@Injectable()
export class ClientPaymentService {
    public _window: ICustomWindow;

    private stripe: StripeInterface;
    private paymentRequest: paymentRequest.StripePaymentRequest;

    private canMakePaymentSubject: Subject<StripeCanMakePaymentResponse>  = new Subject<StripeCanMakePaymentResponse>();
    private successPaymentSubject: Subject<PaymentIntentResponse> = new Subject<PaymentIntentResponse>();
    private errorPaymentSubject: Subject<StripeError> = new Subject<StripeError>();
    private showModalUnsupportedAccountSubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private modalService:           ModalService,
        private injector:               Injector,
        private paymentsApiService:     PaymentsService,
        private windowRef:              WindowRefService,
        private translate:              TranslateService,
    ) {
        this._window = windowRef.nativeWindow();
        this.stripe = (this._window as any).Stripe(environment.STRIPE_PUBLISHABLE_KEY, {locale: 'en'});

        this.changePaymentRequest(this.cartService.total);
    }

    public get stripePayment(): StripeInterface {
        return this.stripe;
    }
    public get stripePaymentRequest(): paymentRequest.StripePaymentRequest{
        return this.paymentRequest;
    }

    public get totalAmount(): number {
        return this.cartService.total;
    }

    private get cartService(): CartService {
        return this.injector.get(CartService);
    }
    private get userService(): UserService {
        return this.injector.get(UserService);
    }
    private get unsupportedDeviceService(): UnsupportedDeviceService {
        return this.injector.get(UnsupportedDeviceService);
    }

    public paymentRequestIntent(): Observable<PaymentView> {
        return this.paymentsApiService.paymentsPaymentPost(this.userService.getToken(), AppValues.getDateNowISO8601());
    }
    public paymentsLoginLink(): Observable<ExternalLinkView> {
        return this.paymentsApiService.paymentsLoginLinkGet(this.userService.getToken());
    }

    public createPaymentRequest(amount: number = 1): void {
        this.paymentRequest = this.stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Total',
                amount: amount,
            },
            requestPayerName: true,
            requestPayerEmail: false,
        });
    }
    public updatePaymentRequest(amount: number = 1): void {
        this.paymentRequest.update({
            currency: 'usd',
            total: {
                label: 'Total',
                amount: amount,
            },
        });
    }
    public changePaymentRequest(amount: number = 1): void {
        if (this.paymentRequest && amount !== 1) {
            this.updatePaymentRequest(amount);
        } else {
            this.createPaymentRequest(amount);

            this.paymentRequest.canMakePayment()
            .then((canMakePayment: { applePay: boolean, googlePay: boolean }) => {
                this.canMakePaymentSubject.next(canMakePayment);
            });
        }
    }

    /**
     * @desc Callback when a payment method is created.
     */
    public paymentMethod(): void {
        this.paymentRequest.on('paymentmethod', (eventPaymentMethod: paymentRequest.StripePaymentMethodPaymentResponse) => {
            this.confirmCardPaymentIntent(eventPaymentMethod);
        });
    }

    /**
     * @description Confirm the PaymentIntent with
     * the payment method returned from the payment request.
     * @param {paymentRequest.StripePaymentMethodPaymentResponse} eventPaymentMethod
     * @private
     */
    private confirmCardPaymentIntent(eventPaymentMethod: paymentRequest.StripePaymentMethodPaymentResponse): void {
        this.stripe.confirmCardPayment(
            this.cartService.clientToken, {
                payment_method: eventPaymentMethod.paymentMethod.id,
            }
        ).then((paymentIntent: PaymentIntentResponse) => {

            if (paymentIntent.error) {
                // Report to the browser that the payment failed.
                eventPaymentMethod.complete('fail');

                this.errorPaymentModal(paymentIntent.error);
            } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                eventPaymentMethod.complete('success');

                this.paymentFlowWith3DSecure(paymentIntent);
            }
        });
    }

    /**
     * @desc Stripe.js handle the rest of the payment flow, including 3D Secure if needed.
     * @param {PaymentIntentResponse} paymentIntent
     */
    private paymentFlowWith3DSecure(paymentIntent: PaymentIntentResponse): void {
        if (paymentIntent.paymentIntent.status === "requires_action") {
            this.stripe.confirmCardPayment(
                this.cartService.clientToken
            ).then((paymentResponse: PaymentIntentResponse) => {
                if (paymentResponse.error) {
                    this.errorPaymentModal(paymentResponse.error);
                } else {
                    this.successPaymentModal(paymentResponse);
                }
            });
        } else {
            // The payment has succeeded.
            this.successPaymentModal(paymentIntent);
        }
    }

    public onCanMakePaymentChanges(): Observable<StripeCanMakePaymentResponse> {
       return this.canMakePaymentSubject.asObservable();
    }
    public onSuccessPaymentChanges(): Observable<PaymentIntentResponse> {
       return this.successPaymentSubject.asObservable();
    }
    public onShowModalUnsupportedAccountChanges(): Observable<boolean> {
        return this.showModalUnsupportedAccountSubject.asObservable();
    }
    public changePaymentResponse(paymentResponse: PaymentIntentResponse): void {
        this.successPaymentSubject.next(paymentResponse);
    }
    public changeShowStatusModalUnsupportedAccount(isShow: boolean): void {
        this.showModalUnsupportedAccountSubject.next(isShow);
    }
    public onErrorPaymentChanges(): Observable<StripeError> {
       return this.errorPaymentSubject.asObservable();
    }
    public changeErrorPayment(error: StripeError) {
        this.errorPaymentSubject.next(error);
    }
    /**
     * Payment with Card Element
     * @param {elements.Element} card
     * @param {string} name
     */
    public confirmCardPayment(card: elements.Element, name: string): void {
        this.stripe.confirmCardPayment(this.cartService.clientToken, {
            payment_method: {
                card: card,
                billing_details: {
                    name
                },
            },
        }).then((result: PaymentIntentResponse) => {
            if (result.error) {
                this.errorPaymentModal(result.error);
            } else {
                this.successPaymentModal(result);
            }
        });
    }

    /**
     * @desc Get information about the status of the payee's account
     * to notify the merchant about the need to create and / or fill out the payee's account.
     * The user will be recognized by the authorization data.
     * If successful, the PayeeAccountView object will be returned.
     */
    public unsupportedAccount(): void {
        this.paymentsApiService.paymentsPayeeAccountGet(this.userService.getToken()).subscribe((payeeAccountView: PayeeAccountView) => {
            if (
                payeeAccountView.status === PayeeAccountView.StatusEnum.NotOnboarded || 
                payeeAccountView.status === PayeeAccountView.StatusEnum.NotCreated
            ) {
                this.changeShowStatusModalUnsupportedAccount(true);
                this.createOnboardingLink(this.userService.isImpersonation);
            }
        }, (err: ErrorInterface) => {
            this.changeShowStatusModalUnsupportedAccount(false);
        });
    }

    /**
     * @desc UIR_PROFILE1 System shall show badge on Profile and Inventory list pages
     * of Buyer&Seller BiziBAZA account if Stripe account is in NOT_ABLE_TO_RECEIVE_TRANSFERS state.
     * Table 2.4.9 - Profile badge text for not Stripe account that is in NOT_ABLE_TO_RECEIVE_TRANSFERS state
     */
    public checkStatusOfStripeAccount(): void {
        this.paymentsApiService.paymentsPayeeAccountGet(this.userService.getToken()).subscribe((payeeAccountView: PayeeAccountView) => {
            if (payeeAccountView.status !== PayeeAccountView.StatusEnum.Enabled) {
                this.unsupportedDeviceService.unsupportedAccount(
                    {
                        htmlText: this.translate.instant('stripe.statusAccount.message'),
                        modalContainerClass: 'top-modal__container',
                        modalContentClass: 'top-modal__content'
                    });
            }
        }, (err: ErrorInterface) => err);
    }

    /**
     * @desc System shall show badge on Shopping list page
     * after login for Buyer&Seller BiziBAZA account
     * if Stripe account is in NOT_CREATED or NOT_ONBOARDED state.
     *
     * Create a payee account, if it has not been created yet,
     * and get a hyperlink to be redirected to fill it out.
     * The user will be recognized by the authorization data.
     * On success, an OnboardingLinkView object will be returned.
     * @private
     */
    private createOnboardingLink(isAdmin: boolean): void {
        this.unsupportedDeviceService.unsupportedAccount(
            {
                message: this.translate.instant('paymentsPayeeAccount.modal.message'),
                yesButtonText: this.translate.instant('paymentsPayeeAccount.modal.yesButton'),
                noButtonText: this.translate.instant(isAdmin ? 'settings.stop.impersonating' : 'paymentsPayeeAccount.modal.logoutButton'),
                modalContentClass: "modal__content__unsupported-account"
            }).then((action:  boolean | string) => {
            if (action) {

                this.paymentsApiService.paymentsPayeeAccountPost(this.userService.getToken())
                    .subscribe((payeeAccount: ExternalLinkView) => {
                       this.reCreateOnboardingModal(isAdmin);
                       this._window.open(payeeAccount.link, "_self");
                    }, (err: ErrorInterface) => this.showModalError(getErrorType(err)));

            } else {
                this.userService.logout().subscribe((res: {}) => res);
            }
        });
    }

    private showModalError(errorText: string): void {
        this.modalService.error(
            {
                title: this.translate.instant('common.alert.title'),
                message: errorText,
                yesButtonText: this.translate.instant('common.ok')
            });
    }

    private reCreateOnboardingModal(isAdmin: boolean): void {
        this.createOnboardingLink(isAdmin);
    }

    private successPaymentModal(successPayment: PaymentIntentResponse): void {
        this.modalService.success(
            {
                title: this.translate.instant('payments.stripe.successPaymentModal.title'),
                message: this.translate.instant('payments.stripe.successPaymentModal.message'),
                yesButtonText: this.translate.instant('payments.stripe.successPaymentModal.yesButton')
            }).then((action: boolean) => {
               this.changePaymentResponseAndRefreshCart(successPayment);
        });
    }
    // TODO Need add tests after demo 30.07.2021
    private changePaymentResponseAndRefreshCart(successPayment: PaymentIntentResponse): void {
        this.cartService.getCart().subscribe((shoppingCart: ShoppingCartResponseBody) => {
            this.cartService.resetAndRefreshAbandonedNotify();
            this.changePaymentResponse(successPayment);
        });
    }

    private errorPaymentModal(error: StripeError): void {
        this.modalService.warning(
            {
                title: this.translate.instant('payments.stripe.errorPaymentModal.title'),
                message: error.message,
                yesButtonText: this.translate.instant('payments.stripe.errorPaymentModal.yesButton')
            });
        this.changeErrorPayment(error);
    }

    // At the time of the prototype
    // public createToken(card: elements.Element): Promise<TokenResponse> {
    //     return this.payment.createToken(card).then((result: TokenResponse) => {
    //         console.log(JSON.stringify(result));
    //         if (result.token) {
    //             // Use the token to create a charge or a customer
    //             // https://stripe.com/docs/charges
    //             return result;
    //         } else if (result.error) {
    //             // StripeError creating the token
    //             this.errorPaymentModal(result.error);
    //             console.log(result.error.message);
    //         }
    //     });
    // }
}
