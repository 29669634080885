import { Component, OnInit }            from '@angular/core';

import {CenterLocationInterface, LocationInterface} from '../interfaces/location.interface';
import { MapSearchService }             from './map-search.service';
import { MarketInterface }              from '../interfaces/market.interface';
import { MarketService }                from '../market/market.service';
import { ShopperDetailsService }        from '../shopper-details/shopper-details.service';
import { UserModel }                    from '../interfaces/user.interface';
import { AppSearchService } from '../search/search.service';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { CachedSearchResultInterface, SearchResponseInterface } from '../interfaces/search.interface';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';


@Component({
    selector:   'map-search',

    template:   `
        <div class="component map-component">
            <div class="map-search__legend">
                <span>
                    <img src="../../assets/images/map_pin_market_icon.png"/>
                    <i>{{ "map.search.market" | translate }}</i>
                </span>
                <span>
                    <img src="../../assets/images/map_pin_user_icon.png"/>
                    <i>{{ "map.search.seller" | translate }}</i>
                </span>
            </div>
            <map-search-header [type]="data.type" [searchName]="searchNameForTitle" (locateEvent$)="locate()"></map-search-header>
            <map-board [displayLocation]="displayLocation" [data]="data"
                (showDetailsEvent$)="showDetails($event)"
                (positionChangeEvent$)="fetchMore($event)"></map-board>
        </div>
    `
})

/**
 * A wrapper component around the map board and the page header.
 */
export class MapSearchComponent extends UnsubscribeOnDestroyAbsctractClass implements OnInit {

    searchNameForTitle: string;
    data: {
        markets:        MarketInterface[];
        position:       {};
        saleItems:      SellingItemResponseBody[];
        sellers:        UserModel[];
        type:           string;
        userLocation:   CenterLocationInterface;
    };
    displayLocation:    CenterLocationInterface;

    constructor(
        private mapSearchService:       MapSearchService,
        private marketService:          MarketService,
        private shopperDetailsService:  ShopperDetailsService,
        private searchService:          AppSearchService,
    ) {
        super();
    }


    /**
     * Gets prefetched search results from the Search service.
     */
    public ngOnInit(): void {
        const cachedData: CachedSearchResultInterface = this.searchService.cachedData();

        this.data = this.mapSearchService.getData() as any;
        this.displayLocation = this.data.position['center'];
        this.searchNameForTitle = this.mapSearchService.getSearchName();

        if (!Object.keys(cachedData).length) {
            this.searchService.results = {markets: this.data.markets, sellers: this.data.sellers} as any;
            this.searchService.addToLocalCaching();
        } else {
            this.updateDataOfMap(cachedData.data);
        }
    }

    /**
     * Sets current location equal to one, given by user profile.
     */
    locate(): void {
        this.displayLocation = this.searchService.userGeolocation();
    }


    /**
     * Redirects 'show details' query to a specific service and saves the current map position.
     * @param obj
     */
    showDetails(obj): void {
        this.mapSearchService.savePosition(obj['currentPosition']);

        obj.type === 'market'
            ? this.marketService.showDetails(obj.data)
            : this.shopperDetailsService.showDetails(obj.ID);
    }


    /**
     * Pagination for markers.
     */
    fetchMore(currentPosition:  LocationInterface) {
        this.displayLocation = currentPosition.center;
        this.data.position['center'] = currentPosition.center;

        this.data.position['center'].longitude = this.mapSearchService.getValidLongitude(currentPosition.center.longitude);
        this.data.position['center'].latitude = this.mapSearchService.getValidLatitude(currentPosition.center.latitude);

        if (currentPosition.zoom) this.data.position['zoom'] = currentPosition.zoom;

        this.trackSubscription(
            this.mapSearchService.fetchMore(currentPosition.center)
                .subscribe((searchResponse: SearchResponseInterface) => 
                    this.updateDataOfMap(searchResponse)
            ),
        );
    }

    private updateDataOfMap(searchResponse: SearchResponseInterface): void {
        this.searchService.results = searchResponse;
        this.data.markets = Object.assign(this.data.markets, searchResponse.markets);
        this.data.sellers = Object.assign(this.data.sellers, searchResponse.sellers);
    }

}
