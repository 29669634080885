var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DynamicComponent } from './dynamic.component';
var ModalFilterComponent = /** @class */ (function (_super) {
    __extends(ModalFilterComponent, _super);
    function ModalFilterComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.boundingClientRect = {
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0,
            x: 0, y: 0,
        };
        return _this;
    }
    ModalFilterComponent.prototype.getElementPosition = function () {
        if (this.context) {
            if (this.context.idFilterButton) {
                return document.querySelector('[data-test-id="' + this.context.idFilterButton + '"]').getBoundingClientRect();
            }
            if (this.context.position) {
                return this.context.position;
            }
        }
        else {
            return this.boundingClientRect;
        }
    };
    ModalFilterComponent.prototype.hideAll = function () {
        this.context && this.context._close();
    };
    ModalFilterComponent.prototype.onSortChange = function (action, locationBack) {
        if (this.context) {
            this.context._close(locationBack);
            this.context.handler(action);
        }
    };
    ModalFilterComponent.prototype.isArrays = function (context) {
        return context.filter_data
            && context.filter_data.length > 1
            && context.filter_data[0].length;
    };
    ModalFilterComponent.prototype.positionStartModal = function () {
        return {
            'top': this.getElementPosition().top + this.getElementPosition().height + 'px',
        };
    };
    ModalFilterComponent.prototype.styleFilterModal = function () {
        var styleModal = {};
        if (this.context) {
            if (this.context.style && this.context.style.isMinContent) {
                styleModal['max-width'] = 'min-content';
            }
            if (window.innerWidth < 568) {
                styleModal['max-width'] = '91%';
            }
            if (this.context.style.width) {
                styleModal['width'] = this.context.style.width;
            }
            if (this.context.idFilterButton) {
                var borderRadius = this.context.style.borderRadius;
                styleModal['border-radius'] = borderRadius + ' 0 ' + borderRadius + ' ' + borderRadius;
                var modal_dialog = document.getElementsByClassName('modal__dialog')[0];
                if (modal_dialog && modal_dialog['offsetWidth'] !== 0) {
                    styleModal['position'] = 'absolute';
                    styleModal['right'] = this.getElementPosition().right - modal_dialog['offsetWidth'] + 4 + 'px';
                }
            }
            if (this.context.style.width) {
                styleModal['position'] = 'absolute';
                styleModal['left'] = this.getElementPosition().right - Number(this.context.style.width.split('px')[0]) + 'px';
            }
        }
        return styleModal;
    };
    ModalFilterComponent.prototype.closeButtonPosition = function () {
        var borderRadius = this.context ? this.context.style.borderRadius : 0;
        return {
            'top': this.getElementPosition().top + 'px',
            'width': this.getElementPosition().width - 2 + 'px',
            'left': this.getElementPosition().left + 'px',
            'height': this.getElementPosition().height + 'px',
            'border-radius': borderRadius + ' ' + borderRadius + ' 0px 0px'
        };
    };
    return ModalFilterComponent;
}(DynamicComponent));
export { ModalFilterComponent };
