import { AfterViewInit, ElementRef, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { Observable } from "rxjs/Observable";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../environments/environment";
import AppValues from "../common/app.values";
import { ModalService } from "../modal/modal.service";
import { GeoCoderService } from "../services/geocoder.service";
import { GeoLocationService } from "../services/geolocation.service";
import { GoogleAnalyticsService } from "../services/google-analytics.service";
import { antiPatternMatcher, duplicate, duplicateLogin, duplicatePassword } from "./helpers/_helpers";
import { UserService } from "./user.service";
import { DomSanitizer } from "@angular/platform-browser";
import { getErrorType } from "../services/error.helpers";
var SignupComponent = /** @class */ (function () {
    function SignupComponent(fb, elementRef, geoLocationService, geoCoderService, googleAnalyticsService, modalService, userService, sanitizer, translate) {
        this.fb = fb;
        this.elementRef = elementRef;
        this.geoLocationService = geoLocationService;
        this.geoCoderService = geoCoderService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.modalService = modalService;
        this.userService = userService;
        this.sanitizer = sanitizer;
        this.translate = translate;
        this.accessLevel = 2;
        this.formErrors = {
            'firstName': '',
            'lastName': '',
            'title': '',
            'login': '',
            'login2': '',
            'password': '',
            'password2': '',
            'email': '',
            'phone': '',
            'address1': '',
            'address2': '',
            'city': '',
            'state': '',
            'postCode': '',
            'country': '',
            'checkboxTerms': '',
            'checkboxYearsOld': ''
        };
        this.checkingAnaliticsObj = {};
        this.componentDestroyed$ = new Subject();
        this.countries = AppValues.countries;
        this.countryErr = false;
        this.defaultCountry = 'United States';
        this.firstCountryChanging = false;
        this.location = { longitude: 0, latitude: 0 };
        this.modalSubject = new Subject();
        this.states = AppValues.states;
        this.stateErr = false;
        this.isSuccess = false;
        this.signupSuccessText = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant('signup.successReport.body.text'));
        this.termConditionsLink = environment.apiUrl.split('/api/v1/')[0] + "/terms-and-conditions";
        this.primevalItem = new Subject();
        this.primevalItem$ = this.primevalItem.asObservable();
        this.change_item = false;
    }
    SignupComponent.prototype.setSignupFormValidationMessages = function () {
        this.validationMessages = {
            firstName: {
                required: this.translate.instant('signup.firstName.required'),
                pattern: this.translate.instant('signup.firstName.pattern'),
            },
            lastName: {
                required: this.translate.instant('signup.lastName.required'),
                pattern: this.translate.instant('signup.lastName.pattern'),
            },
            title: {
                pattern: this.translate.instant('signup.title.pattern'),
                placeholder: this.isSellerType ? this.translate.instant("signup.title.seller.placeholder") : this.translate.instant("signup.title.buyer.placeholder"),
                maxlength: this.translate.instant('signup.title.error.maxlength', { userTypeName: AppValues.getPlaceholderCompanyBuyerName(this.accessLevel) })
            },
            login: {
                required: this.translate.instant('signup.login.required'),
                pattern: this.translate.instant("signup.login.hint"),
                maxlength: this.translate.instant("signup.login.hint"),
                used: this.translate.instant('signup.error.loginIsAlreadyInUse'),
                mismatched: this.translate.instant('signup.login.mismatched'),
                placeholder: this.translate.instant("signup.login.placeholder")
            },
            login2: {
                required: this.translate.instant('signup.login2.required'),
                pattern: this.translate.instant("signup.login.hint"),
                maxlength: this.translate.instant("signup.login.hint"),
                used: this.translate.instant('signup.error.loginIsAlreadyInUse'),
                mismatched: this.translate.instant('signup.login.mismatched'),
                placeholder: this.translate.instant("signup.login2.placeholder")
            },
            password: {
                required: this.translate.instant("signup.password.error.required"),
                pattern: this.translate.instant("signup.hint.password"),
                mismatched: this.translate.instant("password.error.notMatch")
            },
            password2: {
                required: this.translate.instant("signup.password2.error.required"),
                pattern: this.translate.instant("signup.hint.password"),
                mismatched: this.translate.instant('password.error.notMatch')
            },
            email: {
                required: this.translate.instant('signup.email.error.required'),
                pattern: this.translate.instant('signup.hint.email'),
                used: this.translate.instant('signup.email.error.used')
            },
            phone: {
                required: this.translate.instant('signup.phone.required'),
                pattern: this.translate.instant('signup.phone.pattern'),
            },
            address1: {
                required: this.translate.instant('signup.address1.required'),
                pattern: this.translate.instant('signup.address1.pattern'),
            },
            address2: {
                pattern: this.translate.instant('signup.address2.pattern')
            },
            city: {
                required: this.translate.instant('signup.city.required'),
                pattern: this.translate.instant('signup.city.pattern')
            },
            state: {
                required: this.translate.instant('signup.state.required'),
                pattern: this.translate.instant('signup.state.pattern')
            },
            postCode: {
                required: this.translate.instant("signup.error.postalCode.required"),
                pattern: this.translate.instant('signup.postCode.pattern')
            },
            country: {
                required: this.translate.instant('signup.country.required'),
                pattern: this.translate.instant('signup.country.pattern')
            },
            checkboxTerms: {
                required: this.translate.instant('signup.checkboxTerms.required')
            },
            checkboxYearsOld: {
                required: this.translate.instant('signup.checkboxYearsOld.required')
            }
        };
    };
    SignupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setSignupFormValidationMessages();
        this.buildForm();
        this.countryValue = this.signupForm.controls['country'].value;
        this.elem = this.elementRef.nativeElement;
        this.geoLocationService.locationEvent$.subscribe(function (res) {
            _this.location = res;
        });
        this.primevalItem$.subscribe(function (item) {
            _this.checkObjectChanges(item, _this.formValues);
        });
        this.formValues = this.userControlsValue;
        this.set_changed_item(this.formValues);
        setTimeout(function () { return _this.setSignupFormValidationMessages(); }, 1);
    };
    Object.defineProperty(SignupComponent.prototype, "userControlsValue", {
        get: function () {
            return this.adapterUserValue(this.signupForm.controls);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignupComponent.prototype, "isSellerType", {
        get: function () {
            return this.accessLevel === 2;
        },
        enumerable: true,
        configurable: true
    });
    SignupComponent.prototype.adapterUserValue = function (controls) {
        var signupForm = new Object;
        for (var key in controls) {
            signupForm[key] = controls[key].value;
        }
        return signupForm;
    };
    SignupComponent.prototype.ngAfterViewInit = function () {
        var el = this.elem;
        var inp = el.querySelector('input[name="firstName"]');
        inp && inp.focus();
        this.containerElem = el.querySelector('.signup__container');
        this.headerElem = el.querySelector('.signup__header');
        // prettifying the selects
        this.countrySelect = el.querySelector('select[name="country"]');
        this.stateSelect = el.querySelector('select[name="state"]');
        var o = this.countrySelect.querySelectorAll('option');
        var s = this.stateSelect.querySelectorAll('option');
        if (s) {
            o[0].setAttribute('disabled', true);
            o[0].setAttribute('selected', true);
            s[0].setAttribute('disabled', true);
            s[0].setAttribute('selected', true);
        }
    };
    SignupComponent.prototype._signupAdditionalGA = function (data) {
        if (data) {
            for (var key in this.signupForm.controls) {
                if (this.checkingAnaliticsObj[key] === '')
                    this.checkingAnaliticsObj[key] = data[key];
                if (this.checkingAnaliticsObj[key] && this.checkingAnaliticsObj[key] !== '') {
                    this._sendGAEvents(key);
                }
            }
        }
        else
            this._sendGAEvents('countryIsNotUSA');
    };
    SignupComponent.prototype._sendGAEvents = function (key) {
        this.googleAnalyticsService.handleEvent('signup_additional', 'signup', key);
        this.googleAnalyticsService.handleVirtualPage('signup_field_' + key);
        delete this.checkingAnaliticsObj[key];
    };
    SignupComponent.prototype.updatePasswordField = function (event, formControlName) {
        var someFormControl = this.signupForm.get(formControlName);
        someFormControl.setValue(event.target['value']);
        this.signupForm.controls[formControlName].updateValueAndValidity();
        this.blurField(event, formControlName);
        if (event.target['value']) {
            someFormControl.markAsDirty();
            someFormControl.markAsTouched();
        }
        else {
            someFormControl.markAsPristine();
            someFormControl.markAsUntouched();
        }
    };
    SignupComponent.prototype.buildForm = function () {
        var _this = this;
        this.signupForm = this.fb.group({
            'firstName': [
                '', [Validators.required, Validators.pattern(AppValues.namePattern), Validators.minLength(1), Validators.maxLength(20)]
            ],
            'lastName': [
                '', [Validators.required, Validators.pattern(AppValues.namePattern), Validators.minLength(1), Validators.maxLength(20)]
            ],
            'title': [
                '',
                [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(100)
                ]
            ],
            'login': [
                '', [Validators.required, Validators.pattern(AppValues.loginPattern), /* antiPatternMatcher(AppValues.charsAntiPattern), */ Validators.minLength(1), Validators.maxLength(100)]
            ],
            'login2': [
                '', [Validators.required, Validators.pattern(AppValues.loginPattern), /* antiPatternMatcher(AppValues.charsAntiPattern), */ Validators.minLength(1), duplicateLogin, Validators.maxLength(100)]
            ],
            'password': [
                '', [Validators.required, Validators.pattern(AppValues.passwordPattern)]
            ],
            'password2': [
                '', [Validators.required, Validators.pattern(AppValues.passwordPattern), duplicatePassword]
            ],
            'email': [
                '', [Validators.required, Validators.pattern(AppValues.emailPattern),]
            ],
            'phone': [
                '', [Validators.required, Validators.pattern(AppValues.phonePattern)]
            ],
            'address1': [
                '', [Validators.required, antiPatternMatcher(AppValues.charsAntiPattern), Validators.minLength(6), Validators.maxLength(100)]
            ],
            'address2': [
                '', [antiPatternMatcher(AppValues.charsAntiPattern), Validators.maxLength(100)]
            ],
            'city': [
                '', [Validators.required, Validators.pattern(AppValues.cityPattern), Validators.minLength(2)]
            ],
            'state': [
                '', [Validators.pattern(AppValues.statePattern)]
            ],
            'postCode': [
                '', [Validators.required, Validators.pattern(AppValues.codePattern), Validators.minLength(2)]
            ],
            // country validation declined to work. reason unknown
            // Performed custom, see `onCountryChange()`
            'country': [
                this.defaultCountry,
            ],
            'checkboxTerms': [
                '', [Validators.required]
            ],
            'checkboxYearsOld': [
                '', [Validators.required]
            ]
        });
        this.busy = true;
        if (this.signupForm.controls) {
            for (var key in this.signupForm.controls) {
                this.checkingAnaliticsObj[key] = '';
            }
        }
        this.signupForm.valueChanges
            .subscribe(function (data) {
            _this.onValueChanged(data);
            _this.set_changed_item(data);
        });
        this.onValueChanged(); // (re)set validation messages now
    };
    SignupComponent.prototype.blurField = function (event, name) {
        var new_value = event.currentTarget['value'].trim();
        event.currentTarget['value'] = new_value;
        event.preventDefault();
        this.signupForm.controls[name].setValue(new_value);
        this.signupForm.controls[name].markAsTouched();
        if (name === 'login' || name === 'login2') {
            this.checkValidityLogin();
        }
        var new_data = this.signupForm.value;
        new_data[name] = new_value;
        this.onValueChanged(new_data);
    };
    SignupComponent.prototype.checkValidityLogin = function () {
        if (this.signupForm.controls['login'].dirty
            && this.signupForm.controls['login2'].dirty) {
            this.signupForm.controls['login'].updateValueAndValidity();
            this.signupForm.controls['login2'].updateValueAndValidity();
        }
    };
    SignupComponent.prototype.onCountryChange = function (e) {
        var c = this.signupForm.value.country;
        this.countrySelect.classList.remove('error');
        this.countryErr = false;
        this.formErrors.country = '';
        /** Will be call if its a first (and once) changing country field **/
        if (this.firstCountryChanging === false && this.countryValue === this.defaultCountry && this.countryValue !== c) {
            this.firstCountryChanging = true;
            this.checkingAnaliticsObj['countryIsNotUSA'] = c;
            this._signupAdditionalGA();
        }
        /** **/
        if (c === 'Country') {
            this.countryErr = true;
            this.formErrors.country = 'Country required!';
            this.countrySelect.classList.add('error');
        }
    };
    SignupComponent.prototype.onStateChange = function (e) {
        var s = this.signupForm.value.state;
        this.stateSelect.classList.remove('error');
        this.stateErr = false;
        this.formErrors.state = '';
        if (s === 'State') {
            this.stateErr = true;
            this.formErrors.state = 'Country required!';
            this.stateSelect.classList.add('error');
        }
    };
    SignupComponent.prototype.onValueChanged = function (data) {
        if (!this.signupForm) {
            return;
        }
        if (this.signupForm && data) {
            duplicate(this.signupForm.controls);
            this._signupAdditionalGA(data);
            this.busy = false;
        }
        this.warningMsg = '';
        var form = this.signupForm;
        for (var field in this.formErrors) {
            if (field === 'country' || field === 'state')
                continue;
            // clear previous error message (if any)
            this.formErrors[field] = '';
            var control = form.get(field);
            if (control && control.dirty && !control.valid) {
                var messages = this.validationMessages[field];
                for (var key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    }; // eofn
    SignupComponent.prototype.onSubmit = function (event) {
        if (event) {
            event.preventDefault();
        }
        this.modalService.showSpinner();
        this._hideSpinner();
        this.validateManually();
    };
    SignupComponent.prototype.switchAccessLevel = function (type) {
        switch (type) {
            case 'buyer':
                this.accessLevel = 1;
                break;
            case 'seller':
                this.accessLevel = 2;
                break;
            default:
                this.accessLevel = 2;
        }
        this.setSignupFormValidationMessages();
    };
    SignupComponent.prototype.validateManually = function () {
        var _this = this;
        var x = this.signupForm.value;
        var get_form = document.querySelectorAll('form')[0];
        var error_field_count = 0;
        var error_field_name = '';
        this.modalService.showSpinner();
        for (var i = 0; i < get_form.length; i++) {
            var name_field = this.signupForm.controls[get_form[i].name];
            if (name_field && (name_field.errors && Object.keys(name_field.errors).length !== 0) && (error_field_count === 0)) {
                error_field_name = get_form[i].name;
                error_field_count++;
            }
        }
        if (error_field_name !== '') {
            this.toggleWarning(error_field_name);
            return;
        }
        else {
            var user_1 = {
                login: x.login,
                password: x.password,
                firstName: x.firstName,
                lastName: x.lastName,
                latitude: this.location.latitude || 0,
                longitude: this.location.longitude || 0,
                accessLevel: this.accessLevel,
                email: x.email.trim(),
                title: x.title.trim(),
                webPage: x.webPage || '',
                imageURL: '',
                addresses: [
                    {
                        firstName: x.firstName,
                        lastName: x.lastName,
                        address1: x.address1.trim(),
                        address2: x.address2.trim(),
                        city: x.city,
                        postCode: x.postCode,
                        country: x.country,
                        isPrimary: true,
                        state: x.state,
                        phone: x.phone
                    }
                ]
            };
            this.addressToCoordinates(this.geoCoderService.getFullAddressString(user_1.addresses[0])).subscribe(function (centerLocation) {
                _this.prepareUser(user_1, centerLocation);
            }, function (errConvert) {
                if (!_this.location) {
                    return new Observable(function (observer) {
                        _this.geoLocationService.geoLocationObserver().subscribe(function (userLocation) {
                            _this.location = userLocation;
                            _this.prepareUser(user_1, userLocation);
                        }, function (errorLocation) { return _this.modalService.error({ message: errorLocation }); });
                    });
                }
                else {
                    _this.prepareUser(user_1, _this.location);
                }
            });
        }
    };
    SignupComponent.prototype.prepareUser = function (user, userLocation) {
        user.latitude = userLocation.latitude;
        user.longitude = userLocation.longitude;
        this.submitUser(user);
    };
    SignupComponent.prototype.addressToCoordinates = function (address) {
        var _this = this;
        return new Observable(function (observer) {
            _this.geocodeAddress(address)
                .subscribe(function (location) {
                _this.location = location;
                observer.next(location);
            }, function (err) { return observer.error(err); });
        });
    };
    SignupComponent.prototype.geocodeAddress = function (address) {
        var _this = this;
        return new Observable(function (observer) {
            _this.geoCoderService.geocodeAddress(address).subscribe(function (res) {
                _this.location = res;
                return observer.next(res);
            }, function (err) { return observer.error(err); });
        });
    };
    // TODO need types
    SignupComponent.prototype.submitUser = function (user) {
        var _this = this;
        this.userService
            .signup(user)
            .takeUntil(this.componentDestroyed$)
            .subscribe(function (response) {
            _this._hideSpinner();
            _this.isSuccess = true;
        }, function (err) {
            _this._hideSpinner();
            _this.userService._reportSignupError({ status: err.status, statusText: getErrorType(err) });
            _this.toggleWarning(err.status, getErrorType(err) || JSON.parse(err['_body']).error);
        });
    };
    SignupComponent.prototype._hideSpinner = function () {
        this.modalService.close();
    };
    SignupComponent.prototype.toggleWarning = function (key, msg) {
        var _this = this;
        this.onCountryChange(null);
        this.onStateChange(null);
        (key || msg) && this._checkErrorMethod(key, msg);
        this.modalService.error({
            title: this.translate.instant('signup.error.title'),
            message: this.warningMsg,
            yesButtonText: 'Close',
        }).then(function (action) {
            if (/(?:PayPal)/i.test(msg)) {
                _this.elem.querySelector('input[name="ppEmail"]').focus();
            }
            return action;
        });
    };
    SignupComponent.prototype._checkErrorMethod = function (key, msg) {
        var x = this.signupForm.value;
        if (typeof key === 'number') {
            switch (key) {
                case 0:
                    this.warningMsg = 'Check the Internet connection';
                    break;
                case 400:
                    this.warningMsg = msg;
                    break;
                case 500:
                    this.warningMsg = msg;
                    break;
                case 501:
                    this.warningMsg = this.validationMessages.login.used;
                    this.formErrors.login = this.warningMsg;
                    break;
                case 503:
                    this.warningMsg = this.validationMessages.email.used;
                    this.formErrors.email = this.warningMsg;
                    break;
                default:
                    return;
            }
        }
        else if (typeof key === 'string') {
            var name_field = this.signupForm.controls[key];
            var error_type = Object.keys(name_field.errors)[0];
            switch (key) {
                case 'title':
                    this.warningMsg = error_type === 'required'
                        ? AppValues.getPlaceholderCompanyBuyerName(this.accessLevel) + " is required."
                        : this.validationMessages.title[error_type];
                    this.formErrors.title = this.warningMsg;
                    break;
                case 'email':
                    this.warningMsg = this.validationMessages.email[error_type];
                    this.formErrors.email = this.warningMsg;
                    break;
                case 'login':
                case 'login2':
                    this.warningMsg = this.validationMessages[key][error_type];
                    if (x.login && !x.login2) {
                        this.warningMsg = this.validationMessages['login2'].required;
                    }
                    else if (x.login2 && !x.login) {
                        this.warningMsg = this.validationMessages['login'].required;
                    }
                    else if (error_type === 'mismatched') {
                        x.login = '';
                        x.login2 = '';
                    }
                    break;
                case 'password':
                case 'password2':
                    this.warningMsg = this.validationMessages[key][error_type];
                    if (x.password && !x.password2) {
                        this.warningMsg = this.validationMessages['password2'].required;
                    }
                    else if (x.password2 && !x.password) {
                        this.warningMsg = this.validationMessages['password'].required;
                    }
                    else if (error_type === 'mismatched') {
                        x.password = '';
                        x.password2 = '';
                    }
                    break;
                case 'checkboxTerms':
                case 'checkboxYearsOld':
                    var value = this.signupForm.controls[key].value;
                    if (value === false || value === '') {
                        this.warningMsg = this.validationMessages[key].required;
                    }
                    break;
                case 'other':
                    this.warningMsg = this.translate.instant('signup.error.fields.message');
                    break;
                default:
                    if (key)
                        this.warningMsg = this.validationMessages[key][error_type];
                    return;
            }
        }
    };
    SignupComponent.prototype.canDeactivate = function () {
        var _this = this;
        if (this.isSuccess)
            return true;
        if (this.signupForm) {
            if (this.change_item) {
                this.modalService
                    .warning({
                    title: this.translate.instant('signup.error.cancel.title'),
                    message: this.translate.instant('signup.error.cancel.message'),
                    yesButtonText: this.translate.instant('signup.error.cancel.confirm'),
                    noButtonText: this.translate.instant('signup.error.cancel.reject'),
                    reverseButtons: true,
                })
                    .then(function (action) { return _this.modalSubject.next(action); });
                return this.modalSubject.asObservable();
            }
        }
        return true;
    };
    SignupComponent.prototype.checkObjectChanges = function (object1, object2) {
        if (AppValues.deepEqual(object1, object2)) {
            this.change_item = false;
        }
        else
            this.change_item = true;
    };
    SignupComponent.prototype.set_changed_item = function (item) {
        this.primevalItem.next(item);
    };
    SignupComponent.prototype.clickAnyButtons = function (name) {
        this.googleAnalyticsService.handleClickButton(name + '_pressed', 'sign_up', 'click');
    };
    return SignupComponent;
}());
export { SignupComponent };
