import { OnDestroy } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { SubSink } from "subsink";
import { subjectsComplete } from "../../common/app.helpers";

export class UnsubscribeOnDestroyAbsctractClass implements OnDestroy {
    private subs: SubSink = new SubSink();
    protected subjects$: Subject<any>[] = [];
    
    public ngOnDestroy(): void {
        this.subs.unsubscribe();
        subjectsComplete(this.subjects$);
    }
    
    protected trackSubscription(subscription: Subscription): void { this.subs.add(subscription); }
    
    protected trackSubscriptions(...subscriptions: Subscription[]) { this.subs.add(...subscriptions); }
}
