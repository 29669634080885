
        <div class="bp__editor">
            <!-- <upload *ngIf="isBlank" [createNewBizipix]="createNewBizipix" (fileUploadEvent$)="onImageUpload($event)"></upload>-->

            <input
                #fileInput
                type="file"
                accept="imageTypes"
                data-test-id="uploadImageBtn"
                (change)="
                    onImageUpload({
                        payload: $event.target['files'][0],
                        currentInput: $event.currentTarget
                    })
                "
                style="display: none"
            />

            <item-picker
                *ngIf="isPickerMode"
                (itemSelectEvent$)="onItemSelect($event)"
            ></item-picker>

            <editor-menu
                (editorEvent$)="onEditorEvent($event)"
                [ngClass]="{ hidden: isPickerMode }"
            ></editor-menu>

            <bizipix-menu
                *ngIf="currentBizipix"
                class="bizipix-menu"
                [ngClass]="{ hidden: isPickerMode }"
                [type]="editable"
                [currentBizipix]="currentBizipix"
                [imageData]="bizipixesData"
                (selectedBizipixEvent$)="
                    addOrChangeBizipixStrategyEvent($event)
                "
                (createNewBizipixEvent$)="
                    addOrChangeBizipixStrategyEvent($event)
                "
            ></bizipix-menu>

            <bizipix-board
                *ngIf="!isReloading && currentBizipix"
                [currentBizipixBoard]="currentBizipix"
                [isFirstLoadingIsComplete]="isFirstLoadingIsComplete"
                [ngClass]="{ hidden: isPickerMode }"
                (boardEvent$)="onBoardEvent($event)"
                [uploadNewBizipix]="uploadNewBizipix"
                (changeBizipixInEditorEvent$)="
                    changeCurrentBizipixEvent($event)
                "
            ></bizipix-board>

            <div *ngIf="!currentBizipix" class="bp__container">
                <img
                    [src]="getInstructionImage()"
                    data-test-id="bizipixInstraction"
                    draggable="false"
                    alt="image placeholder"
                />
            </div>
        </div>
    