
        <div class="component">
            <shopping-header
                *ngIf="!isSearchActivated"
                (sortOrderByNameEvent$)="sortOrderByDescriptor()"
            ></shopping-header>

            <div
                class="component__container"
                [class.shopping-list__container_search-activated]="isSearchActivated"
                [class.shopping-list__container]="!isSearchActivated"
                #scrollContainer
            >
                <div>
                   <app-custom-item-suggestions
                      [isEditing]="isEditing"
                      (controlStateChange$)="onInputChange($event)"
                      (selectSuggestion$)="addSuggestionItem($event)"
                      (useEnteredValue$)="addCustomItem($event)"
                      (emitEnteredValue$)="checkIsWelcomeVisible($event)"
                      (resetControlState$)="resetInput()"
                   ></app-custom-item-suggestions>
                </div>
                <div [hidden]="isSearchActivated">
                   <sub-list
                       [index]="0"
                       [list]="activeList"
                       [className]="'shopping-list__custom'"
                       (itemUpdateEvent$)="updatePositionItem($event, inactiveList)"
                       (listUpdateEvent$)="updatePositionItem($event, inactiveList)"
                       (startRenaming$)="onStartRenaming($event)">
                   </sub-list>
                   <sub-list
                       class="inactive"
                       [className]="'shopping-list__custom inactive'"
                       [index]="1"
                       [list]="inactiveList"
                       (itemUpdateEvent$)="updatePositionItem($event, activeList)"
                       (listUpdateEvent$)="updatePositionItem($event, activeList)"
                       (startRenaming$)="onStartRenaming($event)">
                   </sub-list>
                </div>
                <div *ngIf="isWelcomeVisible" class="welcome" data-test-id="welcome-block">
                    <img src="../../assets/images/mobile_greeting_message.svg" alt="Welcome to BiziBAZA&reg;! Shopping for Life&reg;">
                </div>
            </div>
        </div>
    