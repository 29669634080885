
        <form [class]="'cart-item ' + (data.isItemWasChange ? 'backgroundYellow' : 'backgroundWhite')" #cartItemForm="ngForm" [attr.data-test-id]="'cartItem_' + testIndex">

            <div class="cart-item__header">
                <div
                   *ngIf="isExpired"
                   class="marker__expired"
                   [attr.data-test-id]="'expiredLabel_' + testIndex"
                ></div>

                <div class="cart-item__img-container">
                    <span *ngIf="!data.item.photoURL" [attr.data-test-id]="'cartItemImage_'+ testIndex"
                          class="sprite sprite-no_image_icon"></span>
                    <img *ngIf="data.item.photoURL" [attr.data-test-id]="'cartItemImage_'+ testIndex"
                         [src]="data.item.photoURL">
                </div>

                <div class="cart-item__title-container">
                    <div>
                        <h4 class="cart-item__title" (click)="showProductDetails(data.item.ID)"
                            [attr.data-test-id]="'cartItemTitle_' + testIndex">{{data.item.title}}</h4>
                        <span class="cart-item__productno"
                              [attr.data-test-id]="'cartItemProductNo_'+ testIndex">Item#{{data.item.productNo}}</span>
                        <h5 class="cart-item__seller-name" (click)="showSellerDetails(data.item.sellerID)"
                            [attr.data-test-id]="'cartItemSellerName_' + testIndex">{{data.item.sellerName}}</h5>
                    </div>
                    <h4 class="cart-item__price" [attr.data-test-id]="'cartItemPrice_' + testIndex">
                        {{ data.item['isItemPrice'] / 100 | currency:'USD'}} / {{ data.item['isItemUnitsName'] }}
                    </h4>
                </div>
            </div>

            <div class="cart-item__quantity">
                <div class="cart-item__qnt">
                    <h4>{{ 'shoppingCart.item.quantity' | translate }}</h4>

                    <div class="special-input-block">

                        <button [attr.data-test-id]="'cartItemQntDec_'+testIndex" (click)="decrement()">-</button>
                        <input class="input-result" type="text" name="quantity"
                               min="0" max="{{data.item.qty}}" minLength="1"
                               [attr.data-test-id]="'cartItemQnt_'+testIndex" [value]="quantity | number"
                               (blur)="onInput($event.target)"
                               (paste)="false"
                               (keypress)="keyPress($event)">
                        <button [attr.data-test-id]="'cartItemQntInc_'+testIndex" (click)="increment()">+</button>

                    </div>
                </div>
                <div class="cart-item__subtotal">
                    <h4>{{ 'common.SubTotal' | translate }}:</h4>
                    <div>
                        <p *ngIf="subtotal" [attr.data-test-id]="'cartItemSubtotal_'+ testIndex">
                            {{ subtotal / 100 | currency:'USD' }}</p>
                        <p *ngIf="!subtotal">--</p>
                    </div>
                </div>
                <div class="cart-item__expires">
                    <h4>{{ 'shoppingCart.item.expiresIn' | translate }}</h4>
                    <span [attr.data-test-id]="'cartItemExpiresIn_'+ testIndex" class="expire-time">
                        <i class="icon-clock_icon_shopping_cart"></i>
                        <p [ngClass]="expiresIn==='00:00' ? 'warning' : 'okay'">{{expiresIn}}</p>
                    </span>
                </div>
                <span *ngIf="expiresIn==='00:00'" class="info__msg error">{{ 'shoppingCart.item.expiresIn.tips' | translate }}</span>

                <div class="cart-item__btn-block">
                    <button (click)="remove()" class="button__lightgreen cart-remove"
                            [disabled]="busyRemoving"
                            [attr.data-test-id]="'cartRemoveItem_' + testIndex">
                        <i class="fa fa-remove"></i>{{ 'shoppingCart.item.removeFromCartButton' | translate }}
                    </button>
                    <button *ngIf="expiresIn==='00:00'" (click)="refresh()" class="button__lightgreen buy"
                            role="refresh" [attr.data-test-id]="'cartRefreshItem_' + testIndex">
                        <i class="fa fa-refresh"></i>{{ 'shoppingCart.item.refreshStatusButton' | translate }}
                    </button>
                </div>
            </div>

            <div class="cart-item__delivery">
                <ul class="cart-item__delivery-choice radiobtn--block">
                    <li (click)="onDeliveryToggle(US_DELIVERY, $event)"
                        [ngClass]="!data.item.deliveryOffered && 'cart-item__delivery-choice__disabled'"
                        [attr.data-test-id]="'cartItemShipping_' + testIndex">
                        <div>
                            <input name="deliveryOffered" type="radio" value="true"
                                   [id]="US_DELIVERY+'_'+testIndex"
                                   [checked]="deliveryOffered === US_DELIVERY">

                            <div class="check"></div>
                        </div>
                        <label [for]="US_DELIVERY">
                            <p>{{ 'common.delivery' | translate }}</p>
                            <small [attr.data-test-id]="'shipping_terms_info_' + testIndex">
                                {{data.item.shipping_terms || 'product.shippingTerms' | translate}}
                            </small>
                        </label>
                    </li>
                    <li (click)="onDeliveryToggle(PICK_UP, $event)"
                        [ngClass]="!data.item.marketPickOffered && 'cart-item__delivery-choice__disabled'"
                        [attr.data-test-id]="'cartItemPickUp_' + testIndex">
                        <div>
                            <input name="deliveryOffered" type="radio" value="false"
                                   [id]="PICK_UP+'_'+testIndex"
                                   [checked]="deliveryOffered === PICK_UP">

                            <div class="check"></div>
                        </div>
                        <label [for]="PICK_UP">
                            <p>{{ 'common.pickUp' | translate }}</p>
                            <small [attr.data-test-id]="'pickUpAddress_' + testIndex">{{ pickUpAddress }}</small>
                        </label>

                    </li>
                </ul>
            </div>

            <div class="cart-item__total">
                <div class="cart-item__shippingtotal">
                    <h4>{{ 'shoppingCart.totalBlock.shipping' | translate }}</h4>
                    <div>
                        <p *ngIf="shippingPrice" [attr.data-test-id]="'cartItemShippingPrice_'+ testIndex">
                            {{ shippingPrice | currency:'USD' }}</p>
                        <p *ngIf="!shippingPrice">{{ 0 | currency:'USD' }}</p>
                    </div>
                </div>
                <div class="cart-item__total-total">
                    <h4>{{ 'shoppingCart.totalBlock.totalCost' | translate }}</h4>
                    <div>
                        <p *ngIf="totalCost" [attr.data-test-id]="'cartItemTotalCost_'+ testIndex">
                            {{totalCost / 100 | currency:'USD' }}</p>
                        <p *ngIf="!totalCost">--</p>
                    </div>
                </div>
            </div>

        </form>
    