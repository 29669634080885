import { Injectable, Type } from '@angular/core';

import { isoCountries } from './isoCountries.values';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import 'moment-timezone';
import { Address, PickUpAddress } from '../../../swagger-gen__output_dir';
import parseInputFloat from './parseInputFloat';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { NumberUSFormat } from '../interfaces/price-value.interface';
const linkify = require('linkifyjs');
require('linkifyjs/plugins/hashtag')(linkify); // optional
const linkifyHtml = require('linkifyjs/html');

@Injectable()
export default class AppValues {

    // name: ^[a-zA-Z]+(([\'\,\.\- ][a-zA-Z ])?[a-zA-Z]*)*$
    public static namePattern:      RegExp = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-]+$/u;
    public static titlePattern:     RegExp = new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð0-9- ,.#:?=|]+[^\s]$/);
    public static profileTitlePattern:     RegExp = new RegExp(/^(.{1,100})$/);
    public static loginPattern:     RegExp = /^[^\s][a-zA-Z0-9-@#$%&_]+[^\s]$/u;

    // [8-100] characters; min 1 uppercase alphabetical; min 1 lowercase alphabetical; min 1 numerical.
    public static passwordPattern:  RegExp = /^(?=.*[a-z!><\/\\{}\[\]'"`~,;:@#\$%\^\&\?\|*\)\(+=._-])(?=.*[A-Z!><\/\\{}\[\]'"`~,;:@#\$%\^\&\?\|*\)\(+=._-])(?=.*\d)[a-zA-Z\d!><\/\\{}\[\]'"`~,;:@#\$%\^\&\?\|*)\(+=._-]{8,100}$/;

    // public static emailPattern:     RegExp = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i;
    public static spaceTrimPattern: RegExp = /^[^\s][\w\W\d\D]+[^\s]$/;
    public static emailPattern:     RegExp = /^([a-zA-Z0-9_\-\.]+)(\+?)([a-zA-Z0-9]*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i;
    public static phonePattern:     RegExp = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*[^\s]$/;
    public static cityPattern:      RegExp = /^[^\s][a-zA-Z]+[\. - ']?(?:[\s-][a-zA-Z0-9]+)*[^\s]$/;
    public static statePattern:     RegExp = /^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$/;
    public static codePattern:      RegExp = /^[a-zA-Z\d][a-zA-Z\d\- ]*[a-zA-Z\d]$/;
    public static charsAntiPattern: RegExp = /[!@$%^&*_;)(<>\{\}"`+±§~]+/;
    public static charsPattern: RegExp = /^.{0,100}$/;
    public static titleAntiPattern: RegExp = /["^*;<>\{\}`±§~]+/;
    // public static webPagePattern:   RegExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    public static webPagePattern:   RegExp = /^((http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,254}\.[a-z]{2,6}(\b([-a-zA-Z0-9@:%_\+.~#?&/=]*$))$)/mi;
    public static httpPattern: RegExp = new RegExp(/^(http:\/\/)|(https:\/\/)/i);


    public static latitudePattern:  RegExp = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
    public static longitudePattern: RegExp = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

    public static pricePattern:     RegExp = /^\d*\.?\d{0,2}$/;
    public static onlyNumberPattern: RegExp = /^[0-9]{1,}([,][0-9]{1,})?([.][0-9]{1,})?/;


    public static countries: string[] = ['United States', 'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Anguilla', 'Antigua & Barbuda', 'Argentina', 'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas'
        , 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bermuda', 'Bhutan', 'Bolivia', 'Bosnia & Herzegovina', 'Botswana', 'Brazil', 'British Virgin Islands'
        , 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde', 'Cayman Islands', 'Chad', 'Chile', 'China', 'Colombia', 'Congo', 'Cook Islands', 'Costa Rica'
        , 'Cote D Ivoire', 'Croatia', 'Cruise Ship', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea'
        , 'Estonia', 'Ethiopia', 'Falkland Islands', 'Faroe Islands', 'Fiji', 'Finland', 'France', 'French Polynesia', 'French West Indies', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana'
        , 'Gibraltar', 'Greece', 'Greenland', 'Grenada', 'Guam', 'Guatemala', 'Guernsey', 'Guinea', 'Guinea Bissau', 'Guyana', 'Haiti', 'Honduras', 'Hong Kong', 'Hungary', 'Iceland', 'India'
        , 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Isle of Man', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya', 'Kuwait', 'Kyrgyz Republic', 'Laos', 'Latvia'
        , 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macau', 'Macedonia', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Mauritania'
        , 'Mauritius', 'Mexico', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Montserrat', 'Morocco', 'Mozambique', 'Namibia', 'Nepal', 'Netherlands', 'Netherlands Antilles', 'New Caledonia'
        , 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Norway', 'Oman', 'Pakistan', 'Palestine', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal'
        , 'Puerto Rico', 'Qatar', 'Reunion', 'Romania', 'Russia', 'Rwanda', 'Saint Pierre & Miquelon', 'Samoa', 'San Marino', 'Satellite', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles'
        , 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'South Africa', 'South Korea', 'Spain', 'Sri Lanka', 'St Kitts & Nevis', 'St Lucia', 'St Vincent', 'St. Lucia', 'Sudan'
        , 'Suriname', 'Swaziland', 'Sweden', 'Switzerland', 'Syria', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Timor L\'Este', 'Togo', 'Tonga', 'Trinidad & Tobago', 'Tunisia'
        , 'Turkey', 'Turkmenistan', 'Turks & Caicos', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States Minor Outlying Islands', 'Uruguay', 'Uzbekistan', 'Venezuela', 'Vietnam', 'Virgin Islands (US)'
        , 'Yemen', 'Zambia', 'Zimbabwe'];

    public static isoCountries: { [key: string]: string } = isoCountries;
    public static states: string[] = ['State', 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];

    public static HUNDRED: number = 100;
    public static THOUSAND: number = 1000;
    public static ONE_HOUR: number = 3600;
    public static FIFTY_NINE_MIN: number = 3540;
    public static SIXTY_ONE_MIN: number = 3660;
    public static MIN_MESSAGE_CHARACTERS: number = 1;
    public static MAX_MESSAGE_CHARACTERS: number = 1000;

    public static PRODUCT_QTY_MAX: number = 1000000;

    public static DEFAULT_MAP_ZOOM: number = 11;
    public static MINIMUM_MAP_ZOOM: number = 2;
    public static MAXIMUM_MAP_ZOOM: number = 22;
    public static SCREEN_DEFAULT_HEIGHT: number = 568;
    public static SCREEN_DEFAULT_WIDTH: number = 368;
    public static MAP_DEFAULT_DIMENSION: number = 256;

    public static MINIMUM_EMAIL_LENGTH: number = 6;
    public static MAXIMUM_EMAIL_LENGTH: number = 254;
    public static MINIMUM_WEBPAGE_LENGTH: number = 4;
    public static MAXIMUM_WEBPAGE_LENGTH: number = 254;

    public static LONG_POPUP_TEXT_LENGTH: number = 11;

    public static PICK_UP: string = 'Pick Up';
    public static US_DELIVERY: string = 'US Delivery';
    public static company_name_header: string = 'Company Name';
    public static buyer_name_header: string = 'Buyer Name';

    public static fullDatePattern: string = 'MMMM dd, yyyy';
    public static momentFullDatePattern: string = 'MMMM DD, YYYY';
    public static allNumericDatePattern: string = 'MM/dd/yyyy';
    public static timePattern: string = 'h:mm a';
    public static spreadDateTimePattern: string = 'MMM dd/yyyy h:mm a';
    public static fullDateTimePattern: string = 'MMMM dd, yyyy h:mm a';
    public static spreadsheetDate: string = 'MM/DD/YYYY, hh:mm A';
    public static supported_browsers: string = 'Google Chrome';
    public static startDate: string = '01/01/1970';
    public static imageTypes: string = '.jpg, .jpeg, .png, .tif, .gif, .bmp, .heic, .heif, .svg, .webP';

    // Maximum size of the file is 5 MB (in bytes)
    public static allowable_upload_image_size_ios: number = 5000000;
    public static allowable_upload_image_size: number = 5242880;

    public static _pad(n: number): number | string {
        return +n < 10 ? '0' + n : n;
    }

    public static NumberUSFormat(params: {value: string, qty?: number, minimumFractionDigits?: number, maximumFractionDigits?: number, maxNumber?: number, minimumValue?: string, defaultValue?: string }): NumberUSFormat {
        params.minimumValue = !params.minimumValue ? '0' : params.minimumValue;

        params.value = AppValues.removeMinusSymbolFromNumberFormat(params.value);

        const quantity: string = parseInputFloat(params.value);

        // With custom settings, forcing a "US" locale to guarantee commas in output
        const nfObject: Intl.NumberFormat = new Intl.NumberFormat();

        if (params.qty && params.maxNumber && Number(quantity) > params.maxNumber) {
            return { num: params.qty, formatedNumber: nfObject.format(+params.qty.toFixed(2))};
        }
        // if input exceeds available qty, show user warning
        if (params.qty && Number(quantity) > params.qty) {
            return { num: params.qty, formatedNumber: String(params.qty.toFixed(2))};

        } else if (+quantity < 0) {
            return { num: 0, formatedNumber: params.defaultValue || params.minimumValue};

        } else {
            if ( String(quantity) === String(0 || '') ) {
                return { num: 0, formatedNumber: params.defaultValue || ''};
            } else {
                const qnty_num: number = +quantity;

                return { num: qnty_num, formatedNumber: `${quantity}` };
            }
        }
    }

    public static removeMinusSymbolFromNumberFormat(value: string): string {
        const prvvalue: string = value;

        value = value.replace('-', '');
        if (prvvalue.indexOf('-') === 0) {
            value = '-' + value;
        }

        return value;
    }

    public static formatPhoneNumber(phone: string): string {
        const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

        try {
            const number = phoneUtil.parseAndKeepRawInput(phone, 'US');
            return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
        } catch (NumberParseException) {
            return phone;
        }
    }

    public static getCountryName(countryCode: string): string {
        if (isoCountries.hasOwnProperty(countryCode)) {
            return isoCountries[countryCode];
        } else {
            return countryCode;
        }
    }

    /**
     * @desc gets current datetime and convert the given date
     * object’s contents into a string in ISO format (ISO 8601)
     * "2014-09-08T08:02:17-05:00"
     * @returns {string}
     */
    public static getDateNowISO8601(): string {
        return moment().format();
    }

    public static momentConvertUTCDateToLocalDate(date: Date, format?: string): string {
        const newdate: string = moment(date).format();
        return moment.utc(newdate).local().format(format);
    }

    /**
     * Using for ISO format date from server '2021-02-26 00:13:11.959000'
     * This method converts the date from the format ISO used to the format UTC
     * and then to the local format by displaying it in the specified pattern
     * @param {Date} date new Date('2021-02-26 00:13:11.959000')
     * @param {string} format 'MMMM DD, YYYY'
     * @return {string}
     */
     public static momentConverDatetoLocalWithFormat(date: Date, format?: string): string {
         const stringDate: string = new Date(date).toString();
         const UTCStringDate: string = stringDate.split('.')[0] ;
         const ISOStringDate: string = new Date(UTCStringDate).toISOString();

         return moment(ISOStringDate).format(format);
     }

    /**
     * @desc gets current datetime
     * @returns {number}
     */
    public static getDateNow(): number {
       return Math.round(new Date().getTime() / 1000);
    }

    /**
     * @desc gets date or dateNow in dd/mm/yyyy format
     * @param {string | number} date (for example '01/01/1970', or '0' in milliseconds)
     * @returns {string}
     */
    public static getDateString(date?: string | number): string {
        return new Date(date ? date : Date.now()).toLocaleString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).split(',')[0];
    }


    /**
     * @desc Converts date in string format to timestamp
     * @returns {number}
     */
    public static convertStringToTimestamp(date: string): number {
       return Math.round(new Date(date).getTime() / 1000);
    }

    /**
     * @desc Convert UTC date time to local date time
     * @param {Date} date (for example new Date(date_string_you_received))
     * @return {Date}
     */
    public static convertUTCDateToLocalDate(date: Date): Date {
        const newDate: Date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        const offset: number = date.getTimezoneOffset() / 60;
        const hours: number = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }

    public static convertDateStringToISOAndToLocalDate(date: string | Date): Date {
        const isoDate = (date + '.000Z').replace(' ', 'T');

        return new Date(isoDate);
    }

    /**
     * @desc Formats a date value according to locale rules.
     * for more: https://angular.io/api/common/DatePipe#description
     * @param date
     * @param {string} pattern
     * @return {string}
     */
    public static datePipeTransform(date: Date | string, pattern?: string): string {
        const datePipe: DatePipe = new DatePipe('en-US');

        return datePipe.transform(date, pattern || AppValues.fullDateTimePattern);
    }

    /**
     * Text or placeholder for templates
     * in BD 0: admin, 1: buyer, 2: seller
     * @param {number} accessLevel
     * @return {string}
     */
    public static getPlaceholderCompanyBuyerName(accessLevel: number): string {
        return (accessLevel === 2) ? AppValues.company_name_header : AppValues.buyer_name_header;
    }

    public static getStringAddress(address: PickUpAddress, split: string = ' '): string {
        const address1: string = (address.address1) ? address.address1 + split : '';
        const address2: string = (address.address2) ? address.address2 + split : '';
        const city: string = (address.city) ? address.city + split : '';
        const state: string = (address.state) ? address.state + split : '';
        const postCode: string = (address.postCode) ? address.postCode : '';

        const preparedAddress: string = address1 + address2 + city + state + postCode;

        if (address.provider_name) {
            return `${address.provider_name} (${preparedAddress})`;
        }

        return preparedAddress;
    }

    public static parseSpecialCharacter(text: string): string {
         return text.replace(/&/g, '&amp;').replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
    }

    public static urlify(text: string): string {
        return text ? linkifyHtml(text, {target: '_blank', linkClass: 'link-blue'}) : text;
    }

    /**
     * Makes deep copy of item without binding to its memory link
     * @param {T} item
     * @returns {T}
     */
    public static deepCopy<T>(item: T): T {
       let copy;

       if (null === item || 'object' !== typeof item) {
          return item;
       }

       if (item instanceof Array) {
           copy = [];
           item.forEach((obj) => {
              copy.push(this.deepCopy(obj));
           });
           return copy;
       }

       if (item instanceof Object) {
           copy = {};
           for (const attr in item) {
               if (item.hasOwnProperty(attr)) {
                  copy[attr] = this.deepCopy(item[attr]);
               }
           }
           return copy;
       }

       throw new Error('Unable to copy obj! Its type isn\'t supported.');
    }


    public static sortItems<T>(data: T[], propName: string) {
       data.sort((itemA, itemB) => itemA[propName].localeCompare(itemB[propName]));
    }

    public static isObjectEquals(object1: {}, object2: {}): boolean {
        return JSON.stringify(object1) === JSON.stringify(object2);
    }

    public static isEmpty(obj): boolean {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    public static getPrimaryAddress(addresses: Address[]): Address {
        let primaryAddress: Address = {};

        // For guest user addresses: []
        if (addresses.length) {
            primaryAddress = addresses.find((addr: Address) => addr.isPrimary);

            if (!primaryAddress) {
                primaryAddress = addresses[0];
            }
        }
        return primaryAddress;
    }

    public static deepEqual(a, b) {
        if (a === b) {
            return true;
        }

        if (a === null || typeof(a) !== 'object' ||
            b === null || typeof(b) !== 'object') {
            return false;
        }

        let propertiesInA = 0, propertiesInB = 0;
        for (const property in a) {
            propertiesInA += 1;
        }
        for (const property in b) {
            propertiesInB += 1;

            if (!(property in a) || !this.deepEqual(a[property], b[property])) {
                return false;
            }
        }

        return propertiesInA === propertiesInB;
    }

    public static isDeepEqualArraysOfObj<T>(ArrayA: T[], ArrayB: T[]): boolean {
        if (typeof ArrayA !== typeof ArrayB || ArrayA.length !== ArrayB.length) {
            return false;
        }

        const checkChangingArray: boolean[] = new Array();

        ArrayA.forEach((objA: T, indexA: number) => {
            checkChangingArray.push(AppValues.deepEqual(ArrayA[indexA], ArrayB[indexA]));
        });

        return !checkChangingArray.some((isChanging: boolean) => !isChanging);
    }

    public static currentBrowserName(): string {
        const windowUserAgent: (regexp) => boolean = ((regexp) => {
            return regexp.test(window.navigator.userAgent);
        });
        switch (true) {
            case windowUserAgent(/edg/i):
                return 'Microsoft Edge';
            case windowUserAgent(/trident/i):
                return 'Microsoft Internet Explorer';
            case windowUserAgent(/firefox|fxios/i):
                return 'Mozilla Firefox';
            case windowUserAgent(/opr\//i):
                return 'Opera';
            case windowUserAgent(/ucbrowser/i):
                return 'UC Browser';
            case windowUserAgent(/samsungbrowser/i):
                return 'Samsung Browser';
            case windowUserAgent(/chrome|chromium|crios/i):
                return 'Google Chrome';
            case windowUserAgent(/safari/i):
                return 'Apple Safari';
            default:
                return 'Other';
        }
    }
}
