<div class="component">
    <div *ngIf="isSuccess" id="successSignUp">
        <header class="app-header">

            <nav class="app-header__container" role="successSignUp">
                <div class="navbar__left">
                   <button [routerLink]="'/login'"
                      data-test-id="backLink" type="button"
                      class="nav__link"><i class="icon-custom_back_icon"></i></button>
                </div>

                <h4 class="navbar__center text-center" role="login">
                    {{ "signup.successReport.header.text" | translate }}
                </h4>

                <div class="navbar__right" role="login"></div>
            </nav>

        </header>
        <div class="component__container">
            <p 
                data-test-id="successReport"
                class="login__warning" 
                appInnerHtmlClickHandler
                [innerHTML]="signupSuccessText"
            ></p>
        </div>
    </div>
    <div *ngIf="!isSuccess">
        <user-header></user-header>
       <div multiKey (fillFields$)="fillFields()" class="signup__container">

           <div multiKey class="signup__main">

               <p class="signup__info text-center" data-test-id="welcome">{{ "signup.welcome.text" | translate }}</p>

               <switch (type)="switchAccessLevel($event)" data-test-id="userTypeSwitch"></switch>

               <form [formGroup]="signupForm" (ngSubmit)="onSubmit($event)">

                   <div class="signup__container__form">

                       <div class="signup__content">


                           <label class="input__label clearfix">
                               <input [ngClass]="{ error: formErrors.firstName }" class="input__text" type="text"
                                      data-test-id="firstNameInp" (blur)="blurField($event, $event.target.name)"
                                      placeholder="First Name" name="firstName" required autofocus
                                      formControlName="firstName">
                           </label>

                           <label class="input__label clearfix">
                               <input [ngClass]="{ error: formErrors.lastName }" class="input__text" type="text"
                                      data-test-id="lastNameInp" (blur)="blurField($event, $event.target.name)"
                                      placeholder="Last Name" name="lastName" required autofocus
                                      formControlName="lastName">
                           </label>

                           <label class="input__label clearfix">
                               <input
                                   [ngClass]="{ error: formErrors.title }"
                                   class="input__text"
                                   type="text" (blur)="blurField($event, $event.target.name)"
                                   data-test-id="titleInp"
                                   [placeholder]="validationMessages.title.placeholder"
                                   name="title"
                                   required
                                   autofocus
                                   formControlName="title"
                               >
                           </label>

                           <label class="input__label clearfix">
                               <input [ngClass]="{ error: formErrors.login }" class="input__text" type="text"
                                      [placeholder]="validationMessages.login.placeholder" (blur)="blurField($event, $event.target.name)"
                                      data-test-id="loginInp" autocapitalize="none"
                                      name="login" required autofocus formControlName="login">
                           </label>

                           <label class="input__label clearfix">
                               <input [ngClass]="{ error: formErrors.login2 }" class="input__text" type="text"
                                      [placeholder]="validationMessages.login2.placeholder" (blur)="blurField($event, $event.target.name)"
                                      data-test-id="login2Inp" autocapitalize="none"
                                      name="login2" required autofocus formControlName="login2">
                           </label>

                           <p class="input__info">{{ "signup.loginField.inputInfo.text" | translate }}</p>


                           <password-field [nameInput]="'password'" [dataTestIdInput]="'passwordInp'"
                                           [isPassErr]="formErrors.password"
                                           [classShowPasswordPosition]="'signup__info__showPasswordPosition'"
                                           (keyUpField)="updatePasswordField($event, 'password')"
                                           (bluredField)="updatePasswordField($event, 'password')"></password-field>

                           <password-field [nameInput]="'password2'" [dataTestIdInput]="'password2Inp'"
                                           [placeholderInput]=" 'common.repeatPassword.placeholderInput' | translate"
                                           [isPassErr]="formErrors.password2"
                                           [classShowPasswordPosition]="'signup__info__showPasswordPosition'"
                                           (keyUpField)="updatePasswordField($event, 'password2')"
                                           (bluredField)="updatePasswordField($event, 'password2')"></password-field>

                           <p class="input__info">{{ "signup.hint.password" | translate }}</p>

                           <label class="input__label clearfix">
                               <input [ngClass]="{ error: formErrors.email }" class="input__text" type="text"
                                      placeholder="Email" (blur)="blurField($event, $event.target.name)"
                                      data-test-id="emailInp" autofocus trim="true"
                                      name="email" required formControlName="email">
                           </label>

                           <label class="input__label clearfix">
                               <input [ngClass]="{ error: formErrors.phone }" class="input__text" type="tel"
                                      data-test-id="phoneInp" (blur)="blurField($event, $event.target.name)"
                                      placeholder="Phone" name="phone" required formControlName="phone">
                           </label>

                           <label class="input__label clearfix">
                               <input [ngClass]="{ error: formErrors.address1 }" class="input__text" type="text"
                                      data-test-id="address1Inp" (blur)="blurField($event, $event.target.name)"
                                      [placeholder]="'common.addressLine1.placeholder' | translate" name="address1"
                                      required formControlName="address1">
                           </label>

                           <label class="input__label clearfix">
                               <input [ngClass]="{ error: formErrors.address2 }" class="input__text" type="text"
                                      data-test-id="address2Inp" (blur)="blurField($event, $event.target.name)"
                                      [placeholder]="'common.addressLine2.placeholder' | translate" name="address2"
                                      formControlName="address2">
                           </label>

                           <label class="input__label clearfix">
                               <input [ngClass]="{ error: formErrors.city }" class="input__text" type="text"
                                      placeholder="City"
                                      data-test-id="cityInp" (blur)="blurField($event, $event.target.name)"
                                      name="city" required formControlName="city">
                           </label>

                           <label class="input__label input__select-style clearfix">
                               <select class="state" (click)="onStateChange($event)" (change)="onStateChange($event)"
                                       name="state"
                                       data-test-id="stateSelect"
                                       required formControlName="state">
                                   <option *ngFor="let s of states" [ngValue]="s" class="state">{{s}}</option>
                               </select>
                           </label>

                           <label class="input__label clearfix">
                               <input [ngClass]="{ error: formErrors.postCode }" class="input__text" type="text"
                                      placeholder="Postal code" (blur)="blurField($event, $event.target.name)"
                                      data-test-id="codeInp"
                                      name="postCode" required formControlName="postCode">
                           </label>

                           <label class="input__label input__select-style clearfix">
                               <select class="" (click)="onCountryChange($event)" (change)="onCountryChange($event)"
                                       name="country"
                                       data-test-id="countrySelect"
                                       required formControlName="country">
                                   <option [ngValue]="" selected disabled hidden>Country</option>
                                   <option *ngFor="let c of countries" [ngValue]="c">{{c}}</option>
                               </select>
                           </label>

                           <label class="signup__agree">
                               {{ "signup.agreeTermsAndConditionsField.text_1" | translate }} <a data-test-id="termsBtn"
                                                  target="_blank"
                                                  href={{termConditionsLink}}
                                                  name="terms_conditions_link">{{ "signup.agreeTermsAndConditionsField.text_2" | translate }}
                               </a>.
                               <input data-test-id="checkboxTerms" [ngClass]="{ error: formErrors.checkboxTerms }"
                                      type="checkbox" name="checkboxTerms" formControlName="checkboxTerms" required>
                               <span class="checkmark"></span>
                           </label>
                           <label class="signup__agree">
                               {{ "signup.agreeYearsOld.text" | translate }}
                               <input data-test-id="checkboxYearsOld"
                                      [ngClass]="{ error: formErrors.checkboxYearsOld }"
                                      type="checkbox" name="checkboxYearsOld" formControlName="checkboxYearsOld"
                                      required>
                               <span class="checkmark"></span>
                           </label>
                       </div>
                   </div>
                   <button class="button__lightgreen signup__button" type="submit" name="signup_done_button"
                           data-test-id="signupBtn" (click)="clickAnyButtons($event.target.name)"
                           [disabled]="busy">
                       {{ "signup.submitButton" | translate }}
                   </button>

               </form>
           </div>
       </div>
    </div>
</div>
