import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'success-onboarding',
    template: `
        <div>
            <header class="app-header">
                <nav class="app-header__container" role="success-onboarding">

                    <div class="navbar__left">
                        <a routerLink="/shopping-list" data-test-id="shoppingLink"
                           class="nav__link"><i class="icon-custom_back_icon"></i></a>
                    </div>
                    <div class="navbar__center">
                        <h4 data-test-id="stripeOnboardingPageHeaderTitle">{{ 'stripeOnboardingPage.header.title' | translate }}</h4>
                    </div>
                    <div class="navbar__right">
                    </div>
                </nav>
            </header>
            <main class="component">
                <div class="success-onboarding__container-block">
                    <div class="success-onboarding__container-block__image-block">
                        <img class="success-onboarding__container-block__image-block__stripe" src="../../assets/images/stripe_wordmark_slate_lg.png" alt="stripe wordmark"/>
                        <img src="../../assets/images/successIcon@2x.png" alt="success icon">
                    </div>
                    <h4 data-test-id="stripeOnboardingPageMainText">{{ 'stripeOnboardingPage.main.text' | translate }}</h4>
                </div>
            </main>
        </div>
    `
})

export class SuccessOnboardingComponent {
    constructor(
        public translate: TranslateService
    ) {
    }
}
