
        <div class="modal__content" (click)="this.context._close()">
            <div class="modal__categories">
                <div class="select-category text-center"
                     *ngFor="let category of context.data"
                     (click)="showCategory(category)"
                     [style.color]="category.category_name_text_color"
                     attr.data-id="{{category.ID}}">

                    <img src="{{category.category_image}}" alt="{{category.category_name}}"
                         [attr.data-test-id]="category.category_name">

                    <h4>{{category.category_name}}</h4>

                </div>
            </div>
        </div>
    