var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter, OnChanges, SimpleChanges, ElementRef, } from '@angular/core';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { ShoppingListService } from '../shopping-list.service';
import { UnsubscribeOnDestroyAbsctractClass } from '../../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
var CustomItemSuggestionsComponent = /** @class */ (function (_super) {
    __extends(CustomItemSuggestionsComponent, _super);
    function CustomItemSuggestionsComponent(service, elementRef) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.elementRef = elementRef;
        _this.controlStateChange$ = new EventEmitter();
        _this.selectSuggestion$ = new EventEmitter();
        _this.useEnteredValue$ = new EventEmitter();
        _this.emitEnteredValue$ = new EventEmitter();
        _this.resetControlState$ = new EventEmitter();
        _this.isDelayPassed = false;
        _this.isControlActivated = false;
        _this.isDataLoading = false;
        _this.suggestions = [];
        return _this;
    }
    CustomItemSuggestionsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (!changes.isEditing.previousValue
            && changes.isEditing.currentValue) {
            setTimeout(function () {
                _this.setFocusOnControl();
            });
        }
    };
    CustomItemSuggestionsComponent.prototype.ngOnInit = function () {
        this.initControl();
    };
    /**
     * Needed so that the Shopping-header component has time to hide itself.
     */
    CustomItemSuggestionsComponent.prototype.setDelay = function () {
        var _this = this;
        !this.isDelayPassed
            && setTimeout(function () { return _this.isDelayPassed = true; }, 500);
        this.controlStateChange$.emit(true);
    };
    /**
     * @desc Calls when item from suggestions list was clicked.
     * @param {SubcategoryInterface} suggestion
     */
    CustomItemSuggestionsComponent.prototype.onSelectSuggestion = function (suggestion) {
        this.selectSuggestion$.emit(suggestion);
        this.onFinish();
    };
    /**
     * @desc Calls when 'document:click' or 'keyup.enter' event was emitted.
     * @param {any} event
     */
    CustomItemSuggestionsComponent.prototype.onAddCustomItem = function (event) {
        var value = this.onFinish();
        value && this.useEnteredValue$.emit(value);
        event && this.eventHandler(event);
    };
    /**
     * Calls when 'blur' event was emitted.
     * @desc In case the suggestions list is empty and
     *       input value isn't empty - adds cutom item.
     * @param {any} event
     */
    CustomItemSuggestionsComponent.prototype.onBlur = function (event) {
        !this.suggestions.length && this.itemTitleControl.value.trim()
            && this.onAddCustomItem(event);
    };
    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {SubcategoryInterface} item
     * @return {string}
     */
    CustomItemSuggestionsComponent.prototype.trackByID = function (index, item) {
        return item.ID;
    };
    /**
     * @desc Calls when OnChanges hook was emitted and
     *       if isEditing value was changed from false to true.
     * @private
     */
    CustomItemSuggestionsComponent.prototype.setFocusOnControl = function () {
        this.itemTitleControl.setValue(this.service.getCachedItem());
        var elem = this.elementRef.nativeElement.querySelector('.input__text');
        elem && elem.focus();
    };
    /**
     * @desc Calls blur event manually on input when
     *       if isEditing value was changed to false.
     * @private
     */
    CustomItemSuggestionsComponent.prototype.setBlurOnControl = function () {
        var elem = this.elementRef.nativeElement.querySelector('.input__text');
        elem && elem.blur();
    };
    /**
     * @desc Initialises itemTitleControl.
     *       Subscribes on its value changes.
     * @private
     */
    CustomItemSuggestionsComponent.prototype.initControl = function () {
        var _this = this;
        this.service.getAllSuggestionsForShoppingList();
        this.itemTitleControl = new FormControl('');
        this.trackSubscription(this.itemTitleControl.valueChanges
            .pipe(distinctUntilChanged()).subscribe(function () {
            _this.isControlActivated = true;
            _this.showSuggestions();
        }));
    };
    /**
     * @desc Gets input text and checks item suggestions
     *       for shopping list by typed keyword.
     *       In case no suggestions: shows 'Item coming soon!' title.
     * @private
     */
    CustomItemSuggestionsComponent.prototype.showSuggestions = function () {
        var itemName = this.itemTitleControl.value.trim();
        this.emitEnteredValue$.emit(itemName);
        if (!itemName) {
            this.suggestions = [];
            this.itemTitleControl.setValue('');
            this.isControlActivated = false;
            return;
        }
        this.suggestions = this.service.fetchSubcategories(itemName);
    };
    /**
     * @desc Resets control value and suggestions list.
     *       Deactivates all boolean properties
     *       and notifies subscribers about that.
     * @returns {string}
     * @private
     */
    CustomItemSuggestionsComponent.prototype.onFinish = function () {
        var value = this.itemTitleControl.value.trim();
        this.itemTitleControl.setValue('');
        this.suggestions = [];
        this.isControlActivated = false;
        this.isDelayPassed = false;
        this.setBlurOnControl();
        this.controlStateChange$.emit(false);
        this.resetControlState$.emit(null);
        this.emitEnteredValue$.emit(value);
        return value.trim();
    };
    CustomItemSuggestionsComponent.prototype.eventHandler = function (event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    };
    return CustomItemSuggestionsComponent;
}(UnsubscribeOnDestroyAbsctractClass));
export { CustomItemSuggestionsComponent };
