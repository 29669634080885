import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';
import {Subject} from 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';

import {DataService} from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import {CenterLocationInterface, LocationInterface, ScreenSizeInterface} from '../interfaces/location.interface';
import {MarketInterface} from '../interfaces/market.interface';
import {ModalService} from '../modal/modal.service';
import {AppSearchService} from '../search/search.service';
import {
    MapSearchResultsContent,
    RequestTypesDictionary,
    SearchDataInterface,
    SearchResponseInterface,
    SearchResultInterface,
    SearchType
} from '../interfaces/search.interface';
import {UserModel, UserSessionInterface} from '../interfaces/user.interface';
import {UserService} from '../user/user.service';
import {GeoLocationService} from '../services/geolocation.service';
import {CacheInterface, LocalCaching, LocalCachingCreatorMapping} from '../local-caching/local-caching-factory';
import {Subscription} from "rxjs/Subscription";
import {WindowRefService} from "../services/window-ref.service";
import AppValues from '../common/app.values';
import { SellingItemResponseBody, Event } from '../../../swagger-gen__output_dir';

@Injectable()
/**
 * Serves interactions for Map Search feature.
 */
export class MapSearchService {
    public defaultUserLocation: CenterLocationInterface;
    public markets:            MarketInterface[];
    public position:           LocationInterface;
    public searchResult:       SearchResultInterface;
    public searchName:         string;
    public sellers:            UserModel[];
    public userLocation:       CenterLocationInterface;
    public zoom:               number;

    public get centerLocationChanges(): Observable<LocationInterface> {
        return this.centerLocationChanges$.asObservable();
    }

    private dataSubject         = new Subject<{}>();
    private page:               number;
    private userSession:        UserSessionInterface = {};
    private segmentCenterLocation: CenterLocationInterface;
    private centerLocationChanges$: Subject<LocationInterface> = new Subject();
    private querySearchUrl: string;

    private requestTypesDictionary: { [key: string]: () => string } = {
        sale: () => RequestTypesDictionary.Sale,
        item: () => RequestTypesDictionary.Item,
        active: () => RequestTypesDictionary.Active,
        event: () => RequestTypesDictionary.Event,
        market: () => RequestTypesDictionary.Market,
        user: () => RequestTypesDictionary.User,
        seller: () => RequestTypesDictionary.Seller,
    };

    constructor(
        private dataService:            DataService,
        private errorService:           ErrorService,
        private geoLocationService:     GeoLocationService,
        private modalService:           ModalService,
        private router:                 Router,
        private searchService:          AppSearchService,
        private userService:            UserService,
        private localCaching:           LocalCaching,
        private windowRefService:       WindowRefService
    ) { }

    /**
     * * Gets prefetched search results from Search service, gets current location and location
     * from user's profile.
     * @param keyWord (from Search Component)
     * @param headertextSearchName (from Search Header Component)
     * @param isNotCached
     * @param isMarket
     */
    public showMap(keyWord: string, headertextSearchName: string = '', isNotCached: boolean = false, isMarket: boolean = false): void {
        this.modalService.showSpinner();
        this._reset();

        this.searchName = headertextSearchName || keyWord;
        if (isMarket) {
            this.searchResult.type = SearchType.Market;
        }
        const userCoord = this.searchService.userGeolocation();

        this.userLocation = {
            longitude:  userCoord.longitude,
            latitude:   userCoord.latitude
        };

        this.search({isComponentInit: true, isNotCached});
    }

    public resetCenterLocation(): void {
        this.segmentCenterLocation = null;
    }

    /**
     * Method return SearchName for Map-search-header
     * @returns {string}
     */
    public getSearchName(): string {
        return this.searchName;
    }

    /**
     * Returns search data object.
     * @returns {{}}
     */
    public getData(): {} {
        return {
            markets:        this.markets,
            sellers:        this.sellers,
            type:           this.searchResult.type,
            userLocation:   this.userLocation,
            position:       this.position
        };
    }

    /**
     * Saves map position: center and zoom.
     * @param {{}} position
     */
    public savePosition(position: LocationInterface): void {
        this.position = position;
    }

    /**
     * Returns Observable of search query results. It is for pagination.
     * @returns {Observable<{}>}
     */
    public fetchMore(displayLocation?: CenterLocationInterface): Observable<{}> {
        this.search({isComponentInit: false, newCoords: displayLocation});

        return this.dataSubject.asObservable();
    }

    private _reset(): void {
        this.page = 0;
        this.markets = [];
        this.sellers = [];

        this.searchResult = this.searchService.getResults();
    }

    /**
     * Returns Array with coordinates of sellers and markets center locations.
     * @returns {CenterLocationInterface[]}
     */
    private calculateMinMaxLocationValues(): CenterLocationInterface[] {
        const coordsArray: CenterLocationInterface[] = [];
        this.markets && this.markets.forEach(
            (market: MarketInterface) => 
                coordsArray.push(this.createCenterLocation(market.market_latitude, market.market_longitude)
            ),
        );
        this.sellers && this.sellers.forEach(
            (seller: UserModel) => 
                coordsArray.push(this.createCenterLocation(seller.latitude, seller.longitude)
            ),
        );
        return coordsArray;
    }

    /**
     * Returns Object with coordinates.
     * @param {number} lat
     * @param {number} lng
     * @returns {CenterLocationInterface}
     */
    private createCenterLocation(lat: number, lng: number): CenterLocationInterface {
        return { lat, lng };
    }


    /**
     * Returns Object with coordinates between several CenterLocation points.
     * @param {CenterLocationInterface[]} points
     * @returns {CenterLocationInterface}
     */
    private createSegmentCoordsArray(points: CenterLocationInterface[]): number[] {
        let minLat: number = 0;
        let minLng: number = 0;
        let maxLat: number = 0;
        let maxLng: number = 0;

        points.forEach((point: CenterLocationInterface) => {
            if (!maxLat || point.lat >= maxLat) {
                maxLat = point.lat;
            }
            if (!minLat || point.lat <= minLat) {
                minLat = point.lat;
            }
            if (!maxLng || point.lng >= maxLng) {
                maxLng = point.lng;
            }
            if (!minLng || point.lng <= minLng) {
                minLng = point.lng;
            }
        });
        
        return [minLat, minLng, maxLat, maxLng];
    }

    private findCenterOfLongestSegment(coords: number[]): CenterLocationInterface {
        return {
            latitude: (coords[0] + coords[2]) / 2,
            longitude: (coords[1] + coords[3]) / 2,
        };
    } 

    /**
     * convert radius from Miles to Meters (for next requests)
     * @param {number} radiusInMi
     * @returns {number}
     * @private
     */
    private _calculateRadius(radiusInMi: number) {
        return Math.round(radiusInMi / 0.00062137);
    }

    /**
     * Defines request type. By default set up 'search_nearby'.
     * @param {string} type
     * @private
     */
    private getRequestType(type: string): string {
        return this.requestTypesDictionary[type]
            ? this.requestTypesDictionary[type]()
            : `search_nearby`;
    }

    /**
     * Composes basic part of query.
     * @param {SearchResultInterface} results
     * @private
     * @returns {string} url query string
     */
    private getKeywordQuery(results: SearchResultInterface): string {
        const requestType = this.getRequestType(results.type);
        let query = `${requestType}?search_query=${results.keyWord}&`;

        if (requestType === RequestTypesDictionary.Item && results.type !== SearchType.Active) {
            return `${requestType}?search_query=${results.keyWord}&type=${results.type}&`;
        }

        if (results.type === SearchType.Active) {
            return `${requestType}?entry_id=${results.entryId}`;
        }

        return query;
    }

    private getSearchResultMap(): SearchResultInterface {
        let cached: CacheInterface[] = this.localCaching.getAllCache(LocalCachingCreatorMapping.ConcreteSearchCaching);
        let cache: CacheInterface;

        cached.forEach((c: CacheInterface) => {
            if (c['url'] === this.windowRefService.nativeWindow().location.href) {
                cache = c;
            }
        });

        if (cached && cache) {
            this.searchService.cachedData();
            return this.searchService.getResults(cache.data['type']);
        } else {
            return this.searchService.getResults();
        }
    }

    /**
     * Chooses a search query string according to the query type. Delegates query call.
     * @param data
     * @private
     */
    private search(data: SearchDataInterface): void {
        data.page = data.page ? data.page : 0;
        let baseQuery: string, radius: number;
        let searchResult: SearchResultInterface;

        if (!data.isNotCached) {
            searchResult = this.getSearchResultMap();
        } else {
            this._openMapSearchComponent(true);
            return;
        }

        this.setNewCoordinates(data.newCoords);

        if (!searchResult.keyWord) {
            data.page = 0;
            radius = this._calculateRadius(100);
            baseQuery = `search_nearby?`;
        } else {
            this.searchMapByKeywordHandler(data);
            radius = this._calculateRadius(999999);
            baseQuery = this.getKeywordQuery(searchResult);
        }
        
        const queryUrl: string = this.concatSearchQueryUrl(baseQuery, radius, data.page, !!searchResult.entryId);
        
        if (this.querySearchUrl !== queryUrl) {
            this.querySearchUrl = queryUrl;
            this._fetch(
                queryUrl,
                data.isComponentInit
            );
        }
    }

    private concatSearchQueryUrl(baseQuery: string, radius: number, page: number, isSearchByEntryId: boolean): string {
        let responseUrl: string = baseQuery;
        if (!isSearchByEntryId) {
            responseUrl = `${responseUrl}latitude=${this.userLocation.latitude}&longitude=${
                this.userLocation.longitude}&radius=${radius}`;
        }
        return `${responseUrl}&page=${page}&count=100`
    }

    private searchMapByKeywordHandler(data: SearchDataInterface): void {
        const minMaxLocationValues: CenterLocationInterface[] = this.calculateMinMaxLocationValues();

        if (minMaxLocationValues.length > 0) {
            const segmentCoordsArray: number[] = this.createSegmentCoordsArray(minMaxLocationValues);
            
            if (
                !this.segmentCenterLocation || 
                (data.newCoords && 
                this.position.center.latitude !== data.newCoords.latitude && 
                this.position.center.longitude !== data.newCoords.longitude)
            ) {
                if (!this.segmentCenterLocation) {
                    this.segmentCenterLocation = this.findCenterOfLongestSegment(segmentCoordsArray);
                    data.newCoords = this.segmentCenterLocation;
                }
                this.userLocation = data.newCoords;
                const newPosition: LocationInterface = { center: data.newCoords, zoom: this.calculateZoomLevel(segmentCoordsArray) };
                
                this.savePosition(newPosition);
                this.emitCenterLocationChanges(newPosition);
            }
        }
    }

    private emitCenterLocationChanges(newPosition: LocationInterface): void {
        this.centerLocationChanges$.next(newPosition);
    }

    /**
    * calculate zoom level between corner points of rectangle of all search result markers
    * @param {number[]} bounds
    * @return {number} zoom level
    * @private
    */
    private calculateZoomLevel(bounds: number[]): number {
        const mapDim: ScreenSizeInterface = { 
            height: window.innerHeight, 
            width: this.calculateMapDimensionWidth()
        };
        const worldDim: ScreenSizeInterface = { 
            height: AppValues.MAP_DEFAULT_DIMENSION, 
            width: AppValues.MAP_DEFAULT_DIMENSION 
        };
        const zoomMin: number = AppValues.MINIMUM_MAP_ZOOM;
        const zoomMax: number = AppValues.MAXIMUM_MAP_ZOOM;

        const latFraction: number = (this.calculateRadians(bounds[2]) - this.calculateRadians(bounds[0])) / Math.PI;

        const lngDiff: number = bounds[3] - bounds[1];
        const lngFraction: number = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

        const latZoom: number = this.calculateZoom(mapDim.height, worldDim.height, latFraction);
        const lngZoom: number = this.calculateZoom(mapDim.width, worldDim.width, lngFraction);
        const finalZoom: number = Math.min(latZoom, lngZoom, zoomMax)
        return Math.max(finalZoom, zoomMin);
    }

    
    /**
    * Choose map width depending on responsive app width 
    * between max app width and window width for phones
    * @return {number} width in px
    * @private
    */
    private calculateMapDimensionWidth(): number {
        return window.innerWidth <= AppValues.SCREEN_DEFAULT_WIDTH 
                ? window.innerWidth 
                : AppValues.SCREEN_DEFAULT_WIDTH;
    }

    /**
    * convert value of lattitude to radians value
    * @param {number} value
    * @return {number} in radians
    * @private
    */
    private calculateRadians(value: number): number {
        const sin: number = Math.sin(value * Math.PI / 180);
        const radX2: number = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    /**
    * calculate necessary zoom level to display all markers 
    * depending on window dimension
    * @param {number} mapPx window height or width 
    * @param {number} worldPx default google map world dimension 
    * @param {number} fraction This makes the calculation of the 
    * fractions for the bounds more complicated 
    * for latitude than for longitude. I used a formula from Wikipedia 
    * to calculate the latitude fraction
    * https://en.wikipedia.org/wiki/Mercator_projection
    * @return {number} zoom level
    * @private
    */
    private calculateZoom(mapPx: number, worldPx: number, fraction: number): number {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    /**
     * if there is a change in coordinates - write new coordinates
     * @param {CenterLocationInterface} newCoords
     * @private
     */
    public setNewCoordinates(newCoords?: CenterLocationInterface): void {
        const userCoord: CenterLocationInterface = newCoords
            ? newCoords
            : this.searchService.getResults().userLocation;

        this.userLocation = {
            longitude:  userCoord.longitude,
            latitude:   userCoord.latitude
        };
    }

    /**
     * Makes search query via Data service.
     * @param query
     * @param isComponentInit
     * @private
     */
    private _fetch(query: string, isComponentInit: boolean): void {
        if (this.searchResult.type === 'seller') {
            this.fetchResults({ isComponentInit, query, onlySellers: true });
            return;
        }

        if (this.searchResult.type === 'market') {
            this.fetchResults({ isComponentInit, query, onlyMarkets: true });
            return;
        }

        this._requestGetData({ isComponentInit, query });
    }

    /**
     * Handles function call depend on if keyword is empty or not.
     * @desc For 'seller' and 'market' type in case not empty keyword
     *       calls _handleResults() method, since the list of displayed
     *       markets and sellers doesn't change and not depend on user location.
     *       Otherwise - fetches new data.
     * @param {MapSearchResultsContent} content
     * @private
     */
    private fetchResults(content: MapSearchResultsContent): void {
        this.searchResult = this.searchService.getResults();
        if (this.searchResult.keyWord) {
            return this._handleResults(this.searchResult.data, content);
        }

        this._requestGetData(content);
    }

    /**
     * Request-method for receive items (item, market or seller type's) for map
     * @param {MapSearchResultsContent} content
     * @returns {Subscription}
     * @private
     */
    private _requestGetData(content: MapSearchResultsContent): Subscription {
        this.userSession = this.userService.getUserSession();
        const token = this.userSession.token;

        return this.dataService.getData(content.query, { token })
            .subscribe(
                (res: SearchResponseInterface) => this._handleResults(res, content),
                (err: ErrorInterface) => this.errorService.handleError(err)
            );
    }

    /**
     * Request-method for receive users from usersIds for displaying on the map
     * @param {string[]} usersIds
     * @returns {Subscription}
     * @private
     */
    private _requestGetSellers(usersIds: string[]): Observable<void> {
        this.userSession = this.userService.getUserSession();
        const token: string = this.userSession.token;
        const response$: Subject<void> = new Subject();

        this.dataService.postData(`user_by_ids`, {users_ids: usersIds}, {token})
            .subscribe(
                (res: SearchResponseInterface) => {
                    this.sellers = res.users;
                    response$.next();
                },
                (err: ErrorInterface) => {
                    this.errorService.handleError(err);
                    response$.next();
                },
            );
        return response$.asObservable();
    }

    /**
     * @desc When search result is empty, shows 'no results' warning the first query; otherwise simply
     * hides the spinner. Enriches existing result with the fresh one. Redirects to the map page pn the first query.
     * @emits dataSubject event on further result chunks (pagination).
     * @param {SearchResponseInterface} res
     * @param {MapSearchResultsContent} content
     * @private
     */
    private _handleResults(
        res: SearchResponseInterface,
        content: MapSearchResultsContent
    ): void {
        res.sellers = content.onlyMarkets ? [] : res.sellers;
        res.markets = content.onlySellers ? [] : res.markets;

        if (res.items) {
            this._searchByCategoryHandler(res, content);
        } else if (res.nearest_items) {
            this._searchByNearestItemsHandler(res, content);
        } else {
            this._searchByKeywordHandler(res, content);
        }
    }

    private _searchByCategoryHandler(
        res: SearchResponseInterface,
        content: MapSearchResultsContent
    ): void {
        const sellersId: string[] = [];
        res.items.forEach(
           (item: SellingItemResponseBody) => {
                if (!sellersId.includes(item.sellerID) && item.events.length === 0) {
                    sellersId.push(item.sellerID);
                }
                item.events.forEach((event: Event) => 
                    this.markets.push({...event.item.market} as unknown as MarketInterface),
                );
           }
        );
        this._requestGetSellers(sellersId).subscribe(() => 
            this._openMapHandler(content.isComponentInit),
        );
    }

    private _searchByNearestItemsHandler(res: any, content: MapSearchResultsContent): void {
        res.nearest_items.forEach(
           item => this.markets.push(item.market),
        );
        this._openMapHandler(content.isComponentInit);
    }

    private _searchByKeywordHandler(res: any, content: MapSearchResultsContent): void {
        this.markets = this.concatList(this.markets, res, 'markets');
        this.sellers = this.concatList(this.sellers, res, 'sellers');
        this._openMapHandler(content.isComponentInit);
    }

    private _openMapHandler(isComponentInit: boolean): void {
        this._openMapSearchComponent(isComponentInit);
        this.dataSubject.next({ markets: this.markets, sellers: this.sellers });
    }

    /**
     * Removes null and undefined objects from list.
     * Merges cleared list and original.
     * Removes repeated items from merged list.
     * @param {T[]} list
     * @param res
     * @param {string} type
     * @returns {T[]}
     * @private
     */
    private concatList<T>(list: T[], res, type: string): T[] {
        if (!Array.isArray(list)) return [];

        const cleanList: T[] = res[type].filter((obj: T) => {
            return obj !== null && obj !== undefined && typeof obj === 'object'
               && (obj as any).toString() === '[object Object]';
        });

        return list.concat(cleanList).filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj['ID']).indexOf(obj['ID']) === pos;
        });
    }

    /**
     * set currently geoPosition and go to map-search page
     * @private
     */
    private _openMapSearchComponent(isComponentInit: boolean): void {
        const currentCoord: CenterLocationInterface = this.userLocation
            ? this.userLocation
            : this._getDefaultUserLocation();

        this.savePosition({ center: currentCoord, zoom: this.position ? this.position.zoom : AppValues.DEFAULT_MAP_ZOOM });
        this.zoom = this.position.zoom;

        this.modalService.close();
        isComponentInit && this.router.navigate(['/map-search'], {queryParams: {[this.searchResult.type]: this.searchResult.keyWord}});
    }

    /**
     * Method for receive user coordinates from server
     * (coordinates specified when registering the user)
     * @returns {CenterLocationInterface}
     * @private
     */
    private _getDefaultUserLocation(): CenterLocationInterface {
        return this.defaultUserLocation
            = this.geoLocationService.getDefaultUserLocation();
    }

    public getValidLongitude(longitude: number): number {
        return this.geoLocationService.getValidLongitude(longitude);
    }

    public getValidLatitude(latitude: number): number {
        return this.geoLocationService.getValidLatitude(latitude);
    }
}
