import AppValues from '../common/app.values';
import {Review} from '../../../swagger-gen__output_dir';
import {OrderForReviews} from '../../../swagger-gen__output_dir/model/orderForReviews';
import {OrderForReviewListInterface, ReviewListInterface} from './review.interface';

export class AdapterReview {

    static convertReviewList(reviews: Array<Review>): Array<ReviewListInterface> {
        return reviews.map((review: Review) => {
            const datetime: string = AppValues.momentConvertUTCDateToLocalDate(new Date(review.create_date * AppValues.THOUSAND), AppValues.momentFullDatePattern);

            return Object.assign(review, {datetime});
        });
    }

    static convertOrderForReviewList(reviews: Array<OrderForReviews>): Array<OrderForReviewListInterface> {
        return reviews.map((review: OrderForReviews) => {
            const datetime: string = AppValues.momentConverDatetoLocalWithFormat(new Date(review.order_datetime), AppValues.momentFullDatePattern);

            return Object.assign(review, {datetime});
        });
    }

}
