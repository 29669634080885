
        <header class="app-header">
            <nav class="app-header__container" role="product-editor">
                <div class="navbar__left">
                    
                    <a class="nav__link" data-test-id="cancelLink" (click)="closeEvent$.emit()">  
                        <i class="icon-custom_back_icon"></i>
                        <p>{{ "product.editor.header.cancel" | translate }}</p>
                    </a>
                    
                </div>

                <div *ngIf="title" class="navbar__center">
                    <h4>{{ title }}</h4>
                </div>
                
                <!--SAVE-->
                <div class="navbar__right">
                    <a class="nav__link" data-test-id="saveBtn" (click)="saveEvent$.emit()">
                        <p>{{ "product.editor.header.save" | translate }}</p>
                        <i class="icon-ok"></i>
                    </a>
                </div>
                <!-- ----------- -->
            </nav>
        </header>
    