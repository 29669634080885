/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../product-editor.sass.shim.ngstyle";
import * as i1 from "../../../assets/styles/sprite.sass.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/rect-switch/rect-switch.component.ngfactory";
import * as i5 from "../../shared/rect-switch/rect-switch.component";
import * as i6 from "./certificate.component";
import * as i7 from "../../modal/modal.service";
import * as i8 from "@ngx-translate/core";
var styles_CertificateComponent = [i0.styles, i1.styles];
var RenderType_CertificateComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_CertificateComponent, data: {} });
export { RenderType_CertificateComponent as RenderType_CertificateComponent };
function View_CertificateComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "div", [["data-test-id", "showCertificate"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showCertificate(_co.imageUrl) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "div", [["class", "sprite sprite-certificate_icon_2x"]], null, null, null, null, null))], null, null); }
export function View_CertificateComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 7, "div", [["class", "product-editor__switch-field"], ["role", "certificate"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), i2.ɵncd(null, 0), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_CertificateComponent_1)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(5, 0, null, null, 0, "input", [["accept", ".jpg, .jpeg, .png, .tif, .gif, .bmp,  .heic, .heif"], ["data-test-id", "fileInp"], ["name", "photo"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onImgUpload($event.currentTarget) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 1, "rect-switch", [["data-test-id", "switch"]], null, [[null, "stateEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("stateEvent$" === en)) {
        var pd_0 = (_co.changeStatus($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_RectSwitchComponent_0, i4.RenderType_RectSwitchComponent)), i2.ɵdid(7, 49152, null, 0, i5.RectSwitchComponent, [], { disabled: [0, "disabled"], state: [1, "state"] }, { stateEvent$: "stateEvent$" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isStatus; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.disabled; var currVal_3 = _co.isStatus; _ck(_v, 7, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataTestId; _ck(_v, 0, 0, currVal_0); }); }
export function View_CertificateComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "certificate", [], null, null, null, View_CertificateComponent_0, RenderType_CertificateComponent)), i2.ɵdid(1, 49152, null, 0, i6.CertificateComponent, [i2.ElementRef, i7.ModalService, i8.TranslateService], null, null)], null, null); }
var CertificateComponentNgFactory = i2.ɵccf("certificate", i6.CertificateComponent, View_CertificateComponent_Host_0, { dataTestId: "dataTestId", disabled: "disabled", isStatus: "isStatus", imageUrl: "imageUrl" }, { changeEvent$: "changeEvent$" }, ["*"]);
export { CertificateComponentNgFactory as CertificateComponentNgFactory };
