/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accordion.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./accordion.component";
var styles_AccordionComponent = [i0.styles];
var RenderType_AccordionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccordionComponent, data: {} });
export { RenderType_AccordionComponent as RenderType_AccordionComponent };
function View_AccordionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "accordion__body"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_AccordionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "accordion"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "accordion__header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.opened = !_co.opened) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "accordion__header__arrow"]], [[1, "data-test-id", 0]], null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "accordion__header__arrow__opened": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "accordion__header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "accordion__header__label"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccordionComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "accordion__header__arrow"; var currVal_2 = _ck(_v, 4, 0, _co.opened); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_6 = _co.opened; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("switchItem_" + _co.indexOfItem); _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.title; _ck(_v, 6, 0, currVal_3); var currVal_4 = ("minMarketDistance_" + _co.indexOfItem); _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.label; _ck(_v, 8, 0, currVal_5); }); }
export function View_AccordionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "accordion", [], null, null, null, View_AccordionComponent_0, RenderType_AccordionComponent)), i1.ɵdid(1, 49152, null, 0, i3.AccordionComponent, [], null, null)], null, null); }
var AccordionComponentNgFactory = i1.ɵccf("accordion", i3.AccordionComponent, View_AccordionComponent_Host_0, { indexOfItem: "indexOfItem", opened: "opened", title: "title", label: "label" }, { toggle: "toggle" }, ["*"]);
export { AccordionComponentNgFactory as AccordionComponentNgFactory };
