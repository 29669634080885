var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import 'rxjs/add/operator/switchMap';
import { ItemService } from '../services/item.service';
import { ModalService } from '../modal/modal.service';
import parseInputFloat from '../common/parseInputFloat';
import { UserService } from '../user/user.service';
import { ShopperDetailsService } from '../shopper-details/shopper-details.service';
import { ProductDetailsService } from '../product-details/product-details.service';
import { TranslateService } from '@ngx-translate/core';
import AppValues from '../common/app.values';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
var CartItemComponent = /** @class */ (function (_super) {
    __extends(CartItemComponent, _super);
    function CartItemComponent(translate, elementRef, itemService, modalService, userService, shopperDetailsService, productDetailsService, changeDetector) {
        var _this = _super.call(this) || this;
        _this.translate = translate;
        _this.elementRef = elementRef;
        _this.itemService = itemService;
        _this.modalService = modalService;
        _this.userService = userService;
        _this.shopperDetailsService = shopperDetailsService;
        _this.productDetailsService = productDetailsService;
        _this.changeDetector = changeDetector;
        _this.itemChange$ = new EventEmitter();
        _this.deliveryChange$ = new EventEmitter();
        _this.removeItem$ = new EventEmitter();
        _this.US_DELIVERY = AppValues.US_DELIVERY;
        _this.PICK_UP = AppValues.PICK_UP;
        _this.busyRemoving = false;
        _this.deliveryOffered = '';
        _this.shippingPrice = 0;
        _this.touched = false;
        _this.timeout = null;
        _this.pickUpAddress = '';
        _this.isExpired = false;
        _this.translate = translate;
        return _this;
    }
    CartItemComponent.prototype.ngAfterContentChecked = function () {
        this.changeDetector.detectChanges();
    };
    CartItemComponent.prototype.ngOnInit = function () {
        this.deliveryOffered = this.data.delivery ? this.data.delivery.method : '';
        this.quantity = this.data.quantity;
        this._checkItemFn(this.data['item']);
        this._calculate({ oninit: true });
        if (this.data.item) {
            this.deliveryMethodID = {
                shipping: 'shipping_' + this.data.item.ID,
                pickUp: 'deliveryPickUp_' + this.data.item.ID
            };
            this.pickUpAddress = this.getPickUpAddress(this.data);
            this.isExpired = this.itemService.isExpiredFn(this.data.item);
        }
    };
    CartItemComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        clearInterval(this.interval);
    };
    CartItemComponent.prototype.ngAfterViewInit = function () {
        this.qtyInput = this.elementRef.nativeElement.querySelector('.input-result');
    };
    CartItemComponent.prototype.increment = function () {
        var _this = this;
        var availableEventTotalQty = this.itemService.availableEventTotalQty(this.data.item, this.quantity);
        if (this.userService.isGuestMode
            || this.quantity === this.data.item.qty
            || (availableEventTotalQty.isProductWithEvents && this.quantity >= availableEventTotalQty.available_qty)) {
            this.showQuantityError(this.data.item.qty);
            return;
        }
        this.quantity += 1;
        this.emitItemChange();
        this.changeWithTimeOut(String(this.quantity)).then(function (val) {
            _this.qtyInput.value = val;
        });
    };
    CartItemComponent.prototype.decrement = function () {
        var _this = this;
        if (this.userService.isGuestMode || this.quantity <= 1) {
            return;
        }
        this.quantity -= 1;
        this.emitItemChange();
        this.changeWithTimeOut(String(this.quantity)).then(function (val) {
            _this.qtyInput.value = val;
        });
    };
    /**
     * if option = true => deliveryOffered
     * if option = false => marketPickOffered
     * @param {boolean} option
     * @param {MouseEvent} event
     */
    CartItemComponent.prototype.onDeliveryToggle = function (option, event) {
        event.preventDefault();
        if (option === this.US_DELIVERY && this.data.item.deliveryOffered
            || option === this.PICK_UP && this.data.item.marketPickOffered) {
            this.changeDeliveryMethod(option);
        }
    };
    CartItemComponent.prototype.changeDeliveryMethod = function (option) {
        if (!this.data.delivery) {
            this.data.delivery = {
                method: ''
            };
        }
        this.data.delivery.method = option;
        this.deliveryOffered = option;
        this.deliveryChange$.emit({ data: this.data, shipping: option });
    };
    CartItemComponent.prototype.showProductDetails = function (id) {
        this.productDetailsService.setProductDetailsFrom('cart');
        return this.productDetailsService.showProductDetails(id).toPromise().then(function (res) { return res; }, function (err) { return err; });
    };
    CartItemComponent.prototype.showSellerDetails = function (id) {
        this.shopperDetailsService.showDetails(id);
    };
    /**
     * @desc check if this product is on sale
     * and set price and UnitsName
     * @param {SellingItemResponseBody | any} item
     * @returns {boolean}
     */
    CartItemComponent.prototype._checkItemFn = function (item) {
        item['isInOnSale'] = this.itemService.isSaleFn(item);
        item['isItemPrice'] = this.itemService.itemPriceFn(item);
        item['isItemUnitsName'] = this.itemService.priceUnitsNameFn(item);
    };
    CartItemComponent.prototype._calculate = function (params) {
        this.touched = !params.oninit;
        this.subtotal = +this.quantity * this.data['item']['isItemPrice'];
        this.totalCost = this.deliveryOffered ? this.subtotal + this.shippingPrice : this.subtotal;
        if (this.subtotal === 0) {
            this.totalCost = 0;
        }
        this.emitItemChange(params.withoutRequest);
    };
    CartItemComponent.prototype.remove = function () {
        this.busyRemoving = true;
        this.removeItem$.emit(this.data);
    };
    CartItemComponent.prototype.refresh = function () {
        this.touched = true;
        this.emitItemChange();
    };
    CartItemComponent.prototype.emitItemChange = function (withoutRequest) {
        this.itemChange$.emit({
            ID: this.data.item.ID,
            product: this.data.item,
            itemTotal: this.totalCost,
            quantity: Number(this.quantity),
            shipping: this.deliveryOffered,
            touched: this.touched,
            withoutRequest: withoutRequest
        });
    };
    /**
     * @desc  handle quantity choice
     * @param {HTMLInputElement} input
     */
    CartItemComponent.prototype.onInput = function (input) {
        this.changeWithTimeOut(input.value).then(function (val) {
            input.value = val;
        });
    };
    CartItemComponent.prototype.changeWithTimeOut = function (value) {
        var _this = this;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        return new Promise(function (resolve) {
            _this.timeout = setTimeout(function () {
                resolve(_this.changeInputDate(value));
            }, 500);
        });
    };
    CartItemComponent.prototype.changeInputDate = function (value) {
        if (this.userService.isGuestMode) {
            return this.quantity.toString();
        }
        var formatedValue = this.calculateQty(value);
        this.quantity = formatedValue.num;
        this._calculate({ oninit: false });
        this.emitItemChange();
        return formatedValue.formatedNumber;
    };
    /**
     * @desc  prevents producing any values outside regex on keys press
     * @param {KeyboardEvent} event
     */
    CartItemComponent.prototype.keyPress = function (event) {
        var pattern = /[0-9]/;
        var inputChar = String.fromCharCode((event).charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    };
    /**
     * @desc Handle shipping choice
     * @param {Event} event
     */
    CartItemComponent.prototype.onChange = function (event) {
        this._calculate({ withoutRequest: true });
    };
    CartItemComponent.prototype.formatPickUpAddress = function (address, empty_address) {
        if (empty_address === void 0) { empty_address = ''; }
        if (!AppValues.isEmpty(address)) {
            return AppValues.getStringAddress(address, ', ');
        }
        else {
            return empty_address;
        }
    };
    CartItemComponent.prototype.getPickUpAddress = function (data) {
        if (data.item.market) {
            return 'Market: ' + data.item.market.market_title
                + ' (' + this.formatPickUpAddress(data.item.market.address) + ')';
        }
        else {
            return (!AppValues.isEmpty(data.seller_primary_address)) ? 'Seller: ' + this.formatPickUpAddress(data.seller_primary_address) : 'Seller: ';
        }
    };
    /**
     * @desc Calculates product's quantity based on the
     *       entered and this product available quantity.
     * @param {string} quantity
     * @private
     * @returns {number}
     */
    CartItemComponent.prototype.calculateQty = function (quantity) {
        if (Number(parseInputFloat(quantity)) <= 1 || quantity === '') {
            return { num: 1, formatedNumber: '1' };
        }
        quantity = AppValues.removeMinusSymbolFromNumberFormat(quantity);
        quantity = quantity.split('.')[0];
        var qty = Math.round(Number(parseInputFloat(quantity)));
        var availableQty = this.data.item['current_quantity'];
        var qtyForReserve = qty - this.quantity;
        var available_qty = this.quantity + availableQty;
        var availableEventTotalQty = this.itemService.availableEventTotalQty(this.data.item, qty);
        if (availableEventTotalQty.isProductWithEvents) {
            available_qty = availableEventTotalQty.available_qty;
        }
        if (qty > this.quantity && qtyForReserve > availableQty) {
            this.showQuantityError(available_qty);
            return AppValues.NumberUSFormat({
                value: available_qty.toLocaleString('en-US'),
                qty: available_qty,
            });
        }
        return AppValues.NumberUSFormat({
            value: quantity,
            qty: available_qty,
        });
    };
    CartItemComponent.prototype.showQuantityError = function (available_qty) {
        this.modalService.error({
            title: this.translate.instant("modal.error.title"),
            message: this.translate.instant("shoppingCart.currentQuantity.error.message", { available_qty: available_qty }),
            yesButtonText: 'Close',
        });
    };
    return CartItemComponent;
}(UnsubscribeOnDestroyAbsctractClass));
export { CartItemComponent };
