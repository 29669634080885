/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./carousel.component";
import * as i3 from "./carousel.service";
import * as i4 from "../services/google-analytics.service";
var styles_CarouselComponent = [];
var RenderType_CarouselComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CarouselComponent, data: {} });
export { RenderType_CarouselComponent as RenderType_CarouselComponent };
function View_CarouselComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "carousel__image"]], [[8, "src", 4], [1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_co.slide) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.slide.ad_image_url, ""); var currVal_1 = ("carouselImgUrl_" + _co.counter); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CarouselComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "carousel__container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "carousel__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CarouselComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.slide; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CarouselComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "carousel", [], null, null, null, View_CarouselComponent_0, RenderType_CarouselComponent)), i0.ɵdid(1, 245760, null, 0, i2.CarouselComponent, [i3.CarouselService, i0.ElementRef, i4.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarouselComponentNgFactory = i0.ɵccf("carousel", i2.CarouselComponent, View_CarouselComponent_Host_0, {}, {}, []);
export { CarouselComponentNgFactory as CarouselComponentNgFactory };
