/**
 * BiziBaza app
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AppliedCouponView } from '../model/appliedCouponView';
import { ApplyCouponRequestBody } from '../model/applyCouponRequestBody';
import { CommonErrorResonse } from '../model/commonErrorResonse';
import { Coupon } from '../model/coupon';
import { EmptyDict } from '../model/emptyDict';
import { GetCouponUsageReportResponse } from '../model/getCouponUsageReportResponse';
import { GetGeneratedCouponCodeResponse } from '../model/getGeneratedCouponCodeResponse';
import { GetListOfAllCoupons } from '../model/getListOfAllCoupons';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CouponsService {

    protected basePath = 'https://dev-backend.bizibaza.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Discards applied coupon.
     * 
     * @param Token current session token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public applyCouponDelete(Token: string, observe?: 'body', reportProgress?: boolean): Observable<EmptyDict>;
    public applyCouponDelete(Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmptyDict>>;
    public applyCouponDelete(Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmptyDict>>;
    public applyCouponDelete(Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling applyCouponDelete.');
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<EmptyDict>(`${this.basePath}/apply_coupon`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if coupon can be applied
     * 
     * @param body coupon code to check
     * @param Token current session token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public applyCouponPost(body: ApplyCouponRequestBody, Token: string, observe?: 'body', reportProgress?: boolean): Observable<AppliedCouponView>;
    public applyCouponPost(body: ApplyCouponRequestBody, Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppliedCouponView>>;
    public applyCouponPost(body: ApplyCouponRequestBody, Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppliedCouponView>>;
    public applyCouponPost(body: ApplyCouponRequestBody, Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling applyCouponPost.');
        }

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling applyCouponPost.');
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AppliedCouponView>(`${this.basePath}/apply_coupon`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Here is how admin delete coupons
     * 
     * @param coupon_code coupon code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public couponsDelete(coupon_code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public couponsDelete(coupon_code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public couponsDelete(coupon_code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public couponsDelete(coupon_code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (coupon_code === null || coupon_code === undefined) {
            throw new Error('Required parameter coupon_code was null or undefined when calling couponsDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (coupon_code !== undefined && coupon_code !== null) {
            queryParameters = queryParameters.set('coupon_code', <any>coupon_code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/coupons`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of all coupons.
     * 
     * @param count amount of coupons which needs to be returned
     * @param offset offset
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public couponsGet(count: string, offset: string, observe?: 'body', reportProgress?: boolean): Observable<GetListOfAllCoupons>;
    public couponsGet(count: string, offset: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetListOfAllCoupons>>;
    public couponsGet(count: string, offset: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetListOfAllCoupons>>;
    public couponsGet(count: string, offset: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (count === null || count === undefined) {
            throw new Error('Required parameter count was null or undefined when calling couponsGet.');
        }

        if (offset === null || offset === undefined) {
            throw new Error('Required parameter offset was null or undefined when calling couponsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GetListOfAllCoupons>(`${this.basePath}/coupons`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Here is how admin create coupon
     * 
     * @param body coupon dictionary
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public couponsPost(body: Coupon, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public couponsPost(body: Coupon, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public couponsPost(body: Coupon, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public couponsPost(body: Coupon, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling couponsPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/coupons`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Here is how admin edits coupon
     * 
     * @param body coupon dictionary
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public couponsPut(body: Coupon, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public couponsPut(body: Coupon, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public couponsPut(body: Coupon, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public couponsPut(body: Coupon, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling couponsPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/coupons`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get unique coupon code.
     * 
     * @param body empty dictionary
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCouponCodePost(body: any, observe?: 'body', reportProgress?: boolean): Observable<GetGeneratedCouponCodeResponse>;
    public getCouponCodePost(body: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGeneratedCouponCodeResponse>>;
    public getCouponCodePost(body: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGeneratedCouponCodeResponse>>;
    public getCouponCodePost(body: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getCouponCodePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GetGeneratedCouponCodeResponse>(`${this.basePath}/get_coupon_code`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get coupon usage report.
     * 
     * @param count amount of coupons which needs to be returned
     * @param offset offset
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCouponUsageReportGet(count: number, offset: number, observe?: 'body', reportProgress?: boolean): Observable<GetCouponUsageReportResponse>;
    public getCouponUsageReportGet(count: number, offset: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetCouponUsageReportResponse>>;
    public getCouponUsageReportGet(count: number, offset: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetCouponUsageReportResponse>>;
    public getCouponUsageReportGet(count: number, offset: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (count === null || count === undefined) {
            throw new Error('Required parameter count was null or undefined when calling getCouponUsageReportGet.');
        }

        if (offset === null || offset === undefined) {
            throw new Error('Required parameter offset was null or undefined when calling getCouponUsageReportGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GetCouponUsageReportResponse>(`${this.basePath}/get_coupon_usage_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
