import { Injectable }               from '@angular/core';
import { Observable }               from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

import { DataService }              from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import { FavoriteListInterface}     from './favorite-list.interface';
import { ModalService }             from '../modal/modal.service';
import { UserService }              from '../user/user.service';
import AppValues from '../common/app.values';



@Injectable()
export class FavoriteListService {

    list: FavoriteListInterface;

    constructor(
        private dataService:    DataService,
        private errorService:   ErrorService,
        private modalService:   ModalService,
        private userService:    UserService
    ) { }


    getList() {
        let token = this.userService.getUserSession().token;

        this.modalService.showSpinner();

        if (token) {
            return this.dataService.getData('favorite_list', {token})
                .map((res: FavoriteListInterface) => {
                    this.modalService.close();

                    this.setList(res);
                    return this.list;
                })
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({});
    }

    private getUser() {
        return AppValues.deepCopy(this.userService.getUser());
    }

    setList(list: FavoriteListInterface) {
        this.list = list;
    }

    addItem(id: string) {
        // @ts-ignore
        const list: any = this.getUser().favList.items;

        return this.addToList(id, list, 'items');
    }

    addUser(id: string) {
        // @ts-ignore
        const list: any  = this.getUser().favList.users;

        return this.addToList(id, list, 'users');
    }

    addToList(id: string, list: string[], field: 'items' | 'users') {
        const obj = { items_ids: [id],
                      users_ids: [id] };

        list.push(id);

        return this._editList('add_to_fl', obj, list, field);
    }

    removeItem(id: string) {
        // @ts-ignore
        const list: any  = this.getUser().favList.items;

        return this.removeFromList(id, list, 'items');
    }

    removeUser(id: string) {
        // @ts-ignore
        const list: any  = this.getUser().favList.users;

        return this.removeFromList(id, list, 'users');
    }

    removeFromList(id: string, list: string[], field: 'items' | 'users') {
        const obj = { items_ids: [id],
                      users_ids: [id] };

        list.splice(list.indexOf(id), 1);

        return this._editList('delete_from_fl', obj, list, field);
    }


    _editList(url: string, item: {}, list: string[], field: 'items' | 'users') {
        const token = this.userService.getUserSession().token;
        const user  = this.userService.getUser();

        if (token) {
            this.modalService.showSpinner();

            return this.dataService.postData(url, item, {token})
                .do((_list: FavoriteListInterface) => {
                    this.modalService.close();

                    user.favList = Object.assign(user.favList, {[field]: list});

                    this.userService.updateUser( user );
                    this.list = _list;
                })
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({});
    }

}
