import {OnInit} from '@angular/core';
import {CacheNotificationModalArgumentsComponent} from "./cache-notification.interface";


export abstract class CacheNotificationDynamicAbstractClass implements OnInit {
    public context: CacheNotificationModalArgumentsComponent;
    public modalContentClass: string = 'cache-modal__content';


    public ngOnInit(): void {
        if (this.context && this.context.modalContentClass) {
            this.modalContentClass = this.context.modalContentClass;
        }
    }
}
