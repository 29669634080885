/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./shopping-header.component.ngfactory";
import * as i2 from "./shopping-header.component";
import * as i3 from "@angular/router";
import * as i4 from "../search/search.service";
import * as i5 from "../local-caching/local-caching-factory";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/common";
import * as i8 from "./custom-item-suggestions/custom-item-suggestions.component.ngfactory";
import * as i9 from "./custom-item-suggestions/custom-item-suggestions.component";
import * as i10 from "./shopping-list.service";
import * as i11 from "./sub-list.component.ngfactory";
import * as i12 from "./sub-list.component";
import * as i13 from "./shopping-list.component";
import * as i14 from "../services/error.service";
var styles_ShoppingListComponent = [];
var RenderType_ShoppingListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShoppingListComponent, data: {} });
export { RenderType_ShoppingListComponent as RenderType_ShoppingListComponent };
function View_ShoppingListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "shopping-header", [], null, [[null, "sortOrderByNameEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sortOrderByNameEvent$" === en)) {
        var pd_0 = (_co.sortOrderByDescriptor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ShoppingHeaderComponent_0, i1.RenderType_ShoppingHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.ShoppingHeaderComponent, [i3.Router, i4.AppSearchService, i5.LocalCaching, i6.TranslateService], null, { sortOrderByNameEvent$: "sortOrderByNameEvent$" })], null, null); }
function View_ShoppingListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "welcome"], ["data-test-id", "welcome-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", "Welcome to BiziBAZA\u00AE! Shopping for Life\u00AE"], ["src", "../../assets/images/mobile_greeting_message.svg"]], null, null, null, null, null))], null, null); }
export function View_ShoppingListComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { scrollContainer: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "component"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShoppingListComponent_1)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, [[1, 0], ["scrollContainer", 1]], null, 10, "div", [["class", "component__container"]], [[2, "shopping-list__container_search-activated", null], [2, "shopping-list__container", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-custom-item-suggestions", [], null, [[null, "controlStateChange$"], [null, "selectSuggestion$"], [null, "useEnteredValue$"], [null, "emitEnteredValue$"], [null, "resetControlState$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("controlStateChange$" === en)) {
        var pd_0 = (_co.onInputChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectSuggestion$" === en)) {
        var pd_1 = (_co.addSuggestionItem($event) !== false);
        ad = (pd_1 && ad);
    } if (("useEnteredValue$" === en)) {
        var pd_2 = (_co.addCustomItem($event) !== false);
        ad = (pd_2 && ad);
    } if (("emitEnteredValue$" === en)) {
        var pd_3 = (_co.checkIsWelcomeVisible($event) !== false);
        ad = (pd_3 && ad);
    } if (("resetControlState$" === en)) {
        var pd_4 = (_co.resetInput() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i8.View_CustomItemSuggestionsComponent_0, i8.RenderType_CustomItemSuggestionsComponent)), i0.ɵdid(7, 770048, null, 0, i9.CustomItemSuggestionsComponent, [i10.ShoppingListService, i0.ElementRef], { isEditing: [0, "isEditing"] }, { controlStateChange$: "controlStateChange$", selectSuggestion$: "selectSuggestion$", useEnteredValue$: "useEnteredValue$", emitEnteredValue$: "emitEnteredValue$", resetControlState$: "resetControlState$" }), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "sub-list", [], null, [[null, "itemUpdateEvent$"], [null, "listUpdateEvent$"], [null, "startRenaming$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemUpdateEvent$" === en)) {
        var pd_0 = (_co.updatePositionItem($event, _co.inactiveList) !== false);
        ad = (pd_0 && ad);
    } if (("listUpdateEvent$" === en)) {
        var pd_1 = (_co.updatePositionItem($event, _co.inactiveList) !== false);
        ad = (pd_1 && ad);
    } if (("startRenaming$" === en)) {
        var pd_2 = (_co.onStartRenaming($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_SubListComponent_0, i11.RenderType_SubListComponent)), i0.ɵdid(10, 49152, null, 0, i12.SubListComponent, [i4.AppSearchService, i10.ShoppingListService], { className: [0, "className"], list: [1, "list"], index: [2, "index"] }, { itemUpdateEvent$: "itemUpdateEvent$", listUpdateEvent$: "listUpdateEvent$", startRenaming$: "startRenaming$" }), (_l()(), i0.ɵeld(11, 0, null, null, 1, "sub-list", [["class", "inactive"]], null, [[null, "itemUpdateEvent$"], [null, "listUpdateEvent$"], [null, "startRenaming$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemUpdateEvent$" === en)) {
        var pd_0 = (_co.updatePositionItem($event, _co.activeList) !== false);
        ad = (pd_0 && ad);
    } if (("listUpdateEvent$" === en)) {
        var pd_1 = (_co.updatePositionItem($event, _co.activeList) !== false);
        ad = (pd_1 && ad);
    } if (("startRenaming$" === en)) {
        var pd_2 = (_co.onStartRenaming($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_SubListComponent_0, i11.RenderType_SubListComponent)), i0.ɵdid(12, 49152, null, 0, i12.SubListComponent, [i4.AppSearchService, i10.ShoppingListService], { className: [0, "className"], list: [1, "list"], index: [2, "index"] }, { itemUpdateEvent$: "itemUpdateEvent$", listUpdateEvent$: "listUpdateEvent$", startRenaming$: "startRenaming$" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShoppingListComponent_2)), i0.ɵdid(14, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isSearchActivated; _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.isEditing; _ck(_v, 7, 0, currVal_3); var currVal_5 = "shopping-list__custom"; var currVal_6 = _co.activeList; var currVal_7 = 0; _ck(_v, 10, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = "shopping-list__custom inactive"; var currVal_9 = _co.inactiveList; var currVal_10 = 1; _ck(_v, 12, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.isWelcomeVisible; _ck(_v, 14, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isSearchActivated; var currVal_2 = !_co.isSearchActivated; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = _co.isSearchActivated; _ck(_v, 8, 0, currVal_4); }); }
export function View_ShoppingListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "shopping-list", [], null, null, null, View_ShoppingListComponent_0, RenderType_ShoppingListComponent)), i0.ɵdid(1, 245760, null, 0, i13.ShoppingListComponent, [i10.ShoppingListService, i14.ErrorService, i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShoppingListComponentNgFactory = i0.ɵccf("shopping-list", i13.ShoppingListComponent, View_ShoppingListComponent_Host_0, {}, {}, []);
export { ShoppingListComponentNgFactory as ShoppingListComponentNgFactory };
