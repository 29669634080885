import {Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../user/user.service';
import {environment} from '../../environments/environment';
import {AppFooterService, UserMode} from '../app-footer/app-footer.service';
import {GetCounterResponse} from '../../../swagger-gen__output_dir/model/getCounterResponse';
import {AppReviewsService} from '../reviews/reviews.service';
import {AppSettings} from '../common/app.settings';


@Component({
    selector: 'settings',
    styleUrls:  ['settings.sass'],
    template: `
        <div class="component settings-component">
            <div>
                <settings-header></settings-header>
                <div class="settings__container">
                <div class="settings_info-header">
                    <div class="info-header__left">
                    </div>
                    <div class="info-header__center">
                        <div class="info-header__center__column">
                            <div class="badge-container__left">
                                <div class="badge-container__inner" *ngIf="!guest && isSellerAccessOfUser">
                                    <div class="badge seller-badge" (click)="checkReviews(true)">
                                        <span data-test-id="all_reviews_on_seller_products_count" class="relative">{{counters.all_reviews_on_seller_products_count}}</span>
                                    </div>
                                    <p>{{ "settings.reviewed.seller" | translate }}</p>
                                </div>
                            </div>

                            <div><img src="../../assets/images/svg_icons/positive_man_icon.svg"/></div>

                            <div class="badge-container__right">
                                <div class="badge-container__inner" *ngIf="!guest">
                                    <div class="badge" (click)="checkReviews(false)">
                                        <span data-test-id="all_reviews_on_buyer_count" class="relative">{{counters.all_reviews_on_buyer_count}}</span>
                                    </div>
                                    <p>{{ "settings.reviewed.buyer" | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <p data-test-id="settingsLoginName" class="info-header__center__column__login">{{loginName}}</p>

                    </div>
                    <div class="info-header__right">
                        <span class="info-header__right__container">
                            <a
                                class="icon-attention-alt-1"
                                data-test-id="goToAbout"
                                (click)="goTo('bizibazaAboutLink')"
                            ></a>
                        </span>
                    </div>
                </div>

                <section *ngIf="!guest" class="password" (click)="updateProfile()" data-test-id="goToProfile">
                    {{ "settings.update.profile" | translate }}
                    <i class="icon-right-open"></i>
                </section>

                <section *ngIf="!guest" class="password" (click)="changePassword()" data-test-id="goToChangePassword">
                    {{ "settings.change.password" | translate }}
                    <i class="icon-right-open"></i>
                </section>

                <a (click)="goTo('bizibazaFaqLink')" data-test-id="goToFAQ">
                    <section>
                        {{ "settings.header.faq" | translate }}
                    </section>
                </a>
                <a href="mailto:feedback@BiziBAZA.com" data-test-id="sendFeedback">
                    <section>
                        {{ "settings.send.feedback" | translate }}
                    </section>
                </a>
                <a *ngIf="!guest && !admin" (click)="deleteAccount($event)" data-test-id="deleteAccount">
                    <section>
                        {{ "settings.delete.account" | translate }}
                    </section>
                </a>              
                <section *ngIf="!impersonationLogOut && !guest" (click)="logOut($event)" data-test-id="logout">
                    {{ "settings.text.logout" | translate }}
                </section>
                <section *ngIf="impersonationLogOut" (click)="logOut($event)" data-test-id="logout">
                    {{ "settings.stop.impersonating" | translate }}
                </section>
            </div>
            </div>

            <p class="app-version">{{ "settings.text.version" | translate }} {{version}}</p>
        </div>
    `
})

export class SettingsComponent implements OnInit {

    public version: string;
    public impersonationLogOut = false;
    public guest: boolean;
    public admin: boolean;
    public loginName: string;

    private bizibazaAboutLink = '';
    private bizibazaFaqLink = '';

    private userMode: string;

    constructor(
        private router:             Router,
        private userService:        UserService,
        private injector:           Injector,
    ) { }

    ngOnInit(): void {
        this.version = AppSettings.APP_VERSION();

        let type = this.userService.typeOfUser();
        const baseUrl = environment.apiUrl.split('/api/v1/')[0];
        if (Object.keys(this.userService.impersonation).length !== 0) {
            this.impersonationLogOut = true;
        }
        this.guest = type === 'guest';
        this.admin = this.userService.isAdminMode();
        this.loginName = this.userService.loginName;

        this.bizibazaAboutLink = `${baseUrl}/about-us`;
        this.bizibazaFaqLink = `${baseUrl}/faq`;

        this.mementoMode();
    }


    mementoMode(): void {
        let history = this.injector.get(AppFooterService).modeCaretaker.showHistory();

        if (history.length >= 1) {
            this.injector.get(AppFooterService).removeModeOriginator();
        } else {
            this.injector.get(AppFooterService).setModeOriginator(this.injector.get(AppFooterService).getMode());
        }

        this.userMode = this.injector.get(AppFooterService).getMode();
    }

    changePassword() {
        this.router.navigate(['/change-password']);
    }

    updateProfile() {
        this.router.navigate(['/profile']);
    }

    checkReviews(isSellerReview: boolean) {
        if (!this.userService.isGuestMode) {
            if (isSellerReview) {
                this.injector.get(AppFooterService).setMode(UserMode.sellerMode);
            } else {
                this.injector.get(AppFooterService).setMode(UserMode.buyerMode);
            }

            this.appReviewsService.isOpenedReviewFromSettings = true;
            this.router.navigate(['/reviews']);
        }
    }

    logOut(event: MouseEvent): void {
        this.userService.logoutModal(event);
    }

    deleteAccount(event: MouseEvent): void {
        this.userService.deleteAccountModal(event);
    }

    public goTo(location: string): void {
       window.open(this[location], '_blank');
    }

    public get counters(): GetCounterResponse {
        return this.injector.get(AppFooterService).counters;
    }
    public get appReviewsService(): AppReviewsService {
        return this.injector.get(AppReviewsService);
    }

    public get isSellerAccessOfUser(): boolean {
        return this.userService.isSellerMode();
    }

    /* Feature was deleted
    logOutFromAll(event: MouseEvent) {
        this.userService.logoutFromAll(event);
    }
    */
}
