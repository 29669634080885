import {OrderForReviews, Review} from '../../../swagger-gen__output_dir';

export interface ReviewListInterface extends Review {
    datetime: string;
}
export interface OrderForReviewListInterface extends OrderForReviews {
    datetime: string;
}
export interface ReviewInterfaceItem {
    ID:             string;
    create_date:    number;
    sender_name:    string;
    sender_id:      string;
    text:           string;
    stars:          number;
    item_id:        string;
    seller_id:      string;
    is_item_review: boolean;
    buyer_id:       string;

}

export interface CheckAccessToSendReviewInterface {
    flag:           boolean;
    description:    string;
}

export interface SelectedOrder {
    ID: string;
    title: string;
    url: string;
    order_element_id?: string;
}

export interface ReviewProductInterface {
    product_review: Review[];
}

export interface GetItemReviewsInterface {
    count: number;
    reviews: Review[];
}

export interface ReviewOptionsInterface {
    sortBy:    ModalFilterArguments;
}

export enum ModalFilterArguments {
    all = 'all',
    positive = 'positive',
    neutral = 'neutral',
    negative = 'negative',
    addReview = 'addReview',
    reviewed = 'reviewed',
    purchases = 'purchases',
    written = 'written',
    received = 'received',
    sellerReviews = 'seller_reviews',
    buyerReviews = 'buyer_reviews',
    product = 'product'
}
export interface ModalFilterArgumentInterface {
    value: ModalFilterArguments;
    title: string;
    active: boolean;
}
