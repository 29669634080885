import { OnInit, EventEmitter, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { ModalService } from '../modal/modal.service';
import { ItemReviewUrl } from './reviews.component';
import { AppFooterService } from '../app-footer/app-footer.service';
import { Router } from '@angular/router';
import { AppReviewsService } from './reviews.service';
import { Subject } from "rxjs";
import { ModalFilterArguments } from "./review.interface";
var ReviewsHeaderComponent = /** @class */ (function () {
    function ReviewsHeaderComponent(modalService, injector, router, location) {
        this.modalService = modalService;
        this.injector = injector;
        this.router = router;
        this.changeEvent$ = new EventEmitter();
        this.backToHomeEvent$ = new EventEmitter();
        this.submit = new EventEmitter();
        this.goToCreateReview$ = new EventEmitter();
        this.reviewsAllFiltersSubject = new Subject();
        this._w = window;
        this.location = location;
    }
    ReviewsHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.locationSearch = decodeURIComponent(this._w.location.pathname);
        this.itemTitle = new ItemReviewUrl().getItemTitle();
        this.title = new ItemReviewUrl().getTitle(this.userTitle);
        this.reviewsAllFiltersSubject.subscribe(function (reviewsAllFilters) {
            if (reviewsAllFilters && reviewsAllFilters.length) {
                _this.reviewsFilterList = reviewsAllFilters[0];
                _this.reviewsRaitingFilter = reviewsAllFilters[1];
            }
        });
        if (this.reviewsAllFilters && this.reviewsAllFilters.length) {
            var isPurchaseActive = this.reviewsAllFilters[0].findIndex(function (f) { return f.value === 'purchases' && f.active; }) > -1;
            this.reviewsFilterList = this.reviewsAllFilters[0];
            this.reviewsRaitingFilter = this.reviewsAllFilters[1];
            isPurchaseActive && this.toggleListState(ModalFilterArguments.purchases);
        }
    };
    Object.defineProperty(ReviewsHeaderComponent.prototype, "app_footer_service", {
        get: function () {
            return this.injector.get(AppFooterService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReviewsHeaderComponent.prototype, "reviews_service", {
        get: function () {
            return this.injector.get(AppReviewsService);
        },
        enumerable: true,
        configurable: true
    });
    ReviewsHeaderComponent.prototype.backToHome = function () {
        if (this.location.path() === '/reviews' && this.reviews_service.isFromSettings) {
            this.reviews_service.isOpenedReviewFromSettings = false;
            this.backToHomeEvent$.emit(null);
            this.location.back();
            return;
        }
        if (this.location.path().includes('reviews-item') || this.location.path().includes('create-review')) {
            this.location.back();
            return;
        }
        if (!this.app_footer_service.isSellerMode()) {
            this.navigateTo('/shopping-list');
        }
        else {
            this.navigateTo('/inventory-list');
        }
    };
    ReviewsHeaderComponent.prototype.navigateTo = function (path) {
        this.backToHomeEvent$.emit(null);
        this.router.navigate([path]);
    };
    ReviewsHeaderComponent.prototype.showFilter = function () {
        var _this = this;
        var modal_params = {
            idFilterButton: 'showFilter',
            filter_data: [this.reviewsRaitingFilter],
            style: { borderRadius: '3px', width: '175px' }
        };
        this.modalService.filter_modal(modal_params).then(function (value) {
            _this.changeEvent$.emit({ sortBy: value });
        });
    };
    ReviewsHeaderComponent.prototype.toggleListState = function (type) {
        this.changeEvent$.emit({ sortBy: type });
    };
    /**
     * passage to the Request Creation page, a query that checks the ability to leave a comment (this.access.flag),
     * if product was not purchased then show warning
     */
    ReviewsHeaderComponent.prototype.goToCreateReview = function () {
        this.goToCreateReview$.emit(null);
    };
    ReviewsHeaderComponent.prototype.onDone = function () {
        if (!this.disabled) {
            this.submit.emit(null);
        }
    };
    return ReviewsHeaderComponent;
}());
export { ReviewsHeaderComponent };
