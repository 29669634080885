var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Injector, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { ModalService } from '../modal/modal.service';
import { UserService } from '../user/user.service';
import { CookieService } from '../services/cookie.service';
import AppValues from '../common/app.values';
import { StorageService } from '../services/storage.service';
import { CouponsService } from '../../../swagger-gen__output_dir/api/coupons.service';
import { TranslateService } from '@ngx-translate/core';
import { ShoppingCartService } from '../../../swagger-gen__output_dir/api/shoppingCart.service';
import { CountersService, OrdersService, } from '../../../swagger-gen__output_dir';
import { AbandonedProduct } from './abandonedcart.class';
import { ClientPaymentService } from '../payment/payment.service';
import { ItemService } from '../services/item.service';
import { TabStatus } from '../services/check-tab-status';
import { getErrorType } from '../services/error.helpers';
var CartService = /** @class */ (function () {
    function CartService(countersService, shoppingCartService, dataService, errorService, googleAnalyticsService, modalService, router, cookieService, injector, storageService, ordersService, couponsService, translate, abandonedProductClass) {
        var _this = this;
        this.countersService = countersService;
        this.shoppingCartService = shoppingCartService;
        this.dataService = dataService;
        this.errorService = errorService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.modalService = modalService;
        this.router = router;
        this.cookieService = cookieService;
        this.injector = injector;
        this.storageService = storageService;
        this.ordersService = ordersService;
        this.couponsService = couponsService;
        this.translate = translate;
        this.abandonedProductClass = abandonedProductClass;
        this.purchaseSubject = new Subject();
        this.paymentViewSubject = new Subject();
        this.totalSubject = new Subject();
        this.userSession = {};
        this.abandonedItemsIDs = new Array;
        this.cartMemento = [];
        this.countsChanges$ = new Subject();
        this.clientToken = '';
        this.selectNextViewSubject = new Subject();
        this.confirmationCartSubject = new Subject();
        this.windowLocation = window.location;
        this.user_service.onResetAbandonedItemsSubjectChanges().subscribe(function () {
            _this.resetAbandonedItems();
        });
        document.addEventListener('visibilitychange', function () {
            if (document.visibilityState === TabStatus.hidden) {
                _this.abandonedProductClass.changeTabStatus(TabStatus.hidden);
                if (_this.cart) {
                    _this.abandonedProductClass.updateAbandonedProducts(_this.cart.items, true);
                }
                _this.abandonedNotify();
            }
            if (document.visibilityState === TabStatus.visible) {
                _this.abandonedProductClass.changeTabStatus(TabStatus.visible);
            }
        });
    }
    Object.defineProperty(CartService.prototype, "countsChanges", {
        get: function () {
            return this.countsChanges$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CartService.prototype.changeCounts = function (token) {
        var _this = this;
        this.countersService.getCountersForUserGet(token)
            .subscribe(function (counters) {
            return _this.countsChanges$.next(counters);
        }, function (err) { return _this.errorService.handleError(err); });
    };
    CartService.prototype.onConfirmationShoppingCartAndBuy = function (payData) {
        var _this = this;
        this.setBuyNowCheck(payData.isPayment);
        // the 1st step of purchasing transaction -- Confirmation
        if (!this.isValidCart()) {
            return;
        }
        this.confirmationShoppingCart().subscribe(function (shoppingCart) {
            _this.calculateTotalAmount(shoppingCart);
            _this._successPurchaseHandleEvent('shopping_cart_confirmation', 'user_id: ' + shoppingCart.elements.user_id);
            if (payData.isPayment) {
                _this.payment_service.paymentRequestIntent()
                    .subscribe(function (paymentView) {
                    _this.clientToken = paymentView.operation_key;
                    _this._successPurchaseHandleEvent('create_stripe_payment', 'with pay_key: ' + paymentView.id);
                    _this.setPaymentTokenAndKey(paymentView.id);
                    _this.paymentViewSubject.next();
                    _this.confirmationCart(__assign({ shoppingCart: shoppingCart }, payData));
                }, function (err) { return _this._handlePurchaseError(err, 'create_stripe_payment'); });
            }
            else {
                _this.confirmationCart(__assign({ shoppingCart: shoppingCart }, payData));
            }
            return shoppingCart;
        }, function (err) {
            _this._handlePurchaseError(err, 'shopping_cart_confirmation');
        });
    };
    CartService.prototype.confirmationCart = function (payData) {
        this.confirmationCartSubject.next(payData.shoppingCart);
    };
    Object.defineProperty(CartService.prototype, "onChangePaymentView", {
        get: function () {
            return this.paymentViewSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CartService.prototype, "onChangeTotalAmount", {
        get: function () {
            return this.totalSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CartService.prototype, "onSelectNextView", {
        get: function () {
            return this.selectNextViewSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CartService.prototype, "onChangeCartConfirmationResponse", {
        get: function () {
            return this.confirmationCartSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CartService.prototype, "isEmptyCart", {
        get: function () {
            return !this.cart.items.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CartService.prototype, "isCartWithoutDelivery", {
        get: function () {
            return this.cart.items.some(function (item) { return item.delivery === null; });
        },
        enumerable: true,
        configurable: true
    });
    CartService.prototype.createDeliveryObj = function (method, delivery_address) {
        return delivery_address ? { method: method, delivery_address: delivery_address } : { method: method };
    };
    CartService.prototype.confirmationShoppingCart = function () {
        var _this = this;
        var confirmArr = [];
        this.userSession = this.user_service.getUserSession();
        var token = this.userSession.token;
        var items = this.cart.items;
        items.forEach(function (item) {
            if (item.delivery !== null) {
                var sc_element = {
                    ID: item.ID,
                    delivery: {
                        method: item.delivery.method,
                    },
                };
                if (item.delivery.method === AppValues.US_DELIVERY) {
                    sc_element.delivery.delivery_address = _this.user_service.primaryAddress();
                }
                confirmArr.push(sc_element);
            }
        });
        if (token) {
            var data_1 = { sc_elements: confirmArr };
            return new Observable(function (observer) {
                _this.shoppingCartService.shoppingCartConfirmationPost(data_1, token)
                    .subscribe(function (res) {
                    observer.next(res);
                }, function (err) {
                    observer.error(err);
                    _this.errorService.handleError(err);
                    observer.complete();
                });
            });
        }
    };
    CartService.prototype.setBuyNowCheck = function (buy_now) {
        this.buy_now_flag = buy_now;
    };
    CartService.prototype.isValidCart = function () {
        var items = this.cart.items;
        var message = '';
        // are there any expired goods?
        if (~items.findIndex(function (item) { return item['_expiresInFormatted'] === '00:00'; })) {
            message = this.translate.instant('shoppingCart.error.expiredOrderItems');
            // are there any zero-quantity items?
        }
        else if (~items.findIndex(function (item) { return item['quantity'] === 0; })) {
            message = this.translate.instant('shoppingCart.error.zeroQtyItem');
        }
        if (message) {
            this.modalService.error({
                title: this.translate.instant('common.alert.title'),
                message: message,
                yesButtonText: this.translate.instant('modal.warning.yesButton'),
            });
            return false;
        }
        return true;
    };
    CartService.prototype._handlePurchaseError = function (err, event_action) {
        event_action && this._errorPurchaseHandleEvent(event_action, err);
        this.purchaseSubject.next(null);
        this.modalService.error({
            title: this.translate.instant('shoppingCart.createPaypalPayment.error.title'),
            message: getErrorType(err),
            yesButtonText: this.translate.instant('shoppingCart.createPaypalPayment.error.yesButtonText'),
        });
    };
    CartService.prototype.getPurchaseNotification = function () {
        return this.purchaseSubject.asObservable();
    };
    CartService.prototype.getCart = function () {
        var _this = this;
        this.userSession = this.user_service.getUserSession();
        var token = this.userSession.token;
        if (token) {
            this.modalService.showSpinner();
            return this.shoppingCartService.shoppingCartGet(token)
                .do(function (res) {
                _this.cart = res;
                _this.modalService.close();
                return _this.cart;
            }, function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({});
    };
    CartService.prototype.setPaymentTokenAndKey = function (pay_key) {
        this.cookieService.setCookie('pay_key', pay_key);
        this.cookieService.setCookie('payment_token', this.userSession.token);
        this.storageService.setPurchaseMemory(this.user_service.getToken());
    };
    CartService.prototype.getReport = function () {
        var _this = this;
        var payment_token = this.cookieService.getCookie('payment_token');
        var pay_key = this.cookieService.getCookie('pay_key');
        if (!payment_token && !pay_key) {
            this.modalService.success({
                title: 'Thanks for using our service!',
                yesButtonText: 'Close',
            }).then(function () { return _this.router.navigate(['/']); });
            return Observable.of({});
        }
        return this.ordersService.getPaymentNotificationGet(pay_key, payment_token)
            .do(function () {
            setTimeout(function () { return _this.router.navigate(['/cart-report']); });
        })
            .catch(function (err) {
            _this._errorPurchaseHandleEvent('get_payment_notification', err);
            return _this.errorService.handleError(err);
        });
    };
    /**
     * Preparing data before send GA marker
     * @param {PaymentReportInterface} reports
     * @private
     */
    CartService.prototype.checkout_from_buy_now = function (reports) {
        var payment_token = this.cookieService.getCookie('payment_token');
        if (this.buy_now_flag === true) {
            this._successPurchaseHandleEvent('success_checkout_from_buy_now', 'with payment_token: ' + payment_token);
        }
        else {
            this._successPurchaseHandleEvent('success_checkout_from_shopping_cart', 'with payment_token: ' + payment_token);
        }
        /** Success Checkout (any type: from shopping cart or buy now) **/
        this.googleAnalyticsService.handleEvent('success_checkout', 'purchase', 'open_cart_report');
        this._successPurchaseHandleEvent('get_payment_notification', 'with payment_token: ' + payment_token);
        this.googleAnalyticsService.handlePurchase(reports);
        /** **/
        this.setBuyNowCheck(false);
        /*** Remove payment data from Cookies **/
        this.cookieService.deleteCookie('pay_key');
        this.cookieService.deleteCookie('payment_token');
        /** **/
    };
    CartService.prototype._successPurchaseHandleEvent = function (event_name, event_label) {
        this.googleAnalyticsService.handleEvent(event_name, 'purchase', 'success ' + event_label);
    };
    CartService.prototype._errorPurchaseHandleEvent = function (event_action, err) {
        var event_label = '';
        if (err.status) {
            event_label = err.status + ' ' + getErrorType(err);
        }
        else {
            event_label = err;
        }
        this.googleAnalyticsService.handleErrorEvent(event_action, 'purchase', 'error ' + event_label);
    };
    CartService.prototype.addItem = function (cartData) {
        var _this = this;
        var user = this.user_service.getUserSession();
        if (!cartData.shoppingCartElements) {
            return;
        }
        else if (user.ID === cartData.sellerID) {
            return this.errorService.handleError({ status: undefined, statusText: 'You cannot buy from yourself!' });
        }
        var body = { items: cartData.shoppingCartElements };
        return this.shoppingCartService.addItemToScPost(body, user.token)
            .do(function (res) {
            _this.purchaseSubject.next(res);
            _this._abandonedItems(res.elements.sc_elements);
            _this.cart.items = res.elements.sc_elements;
            if (cartData.showNotification) {
                _this.showNotification(_this.translate.instant('product.details.addToShoppingCart.success.body'), cartData.isLocationBack);
            }
        }, function (err) {
            _this.purchaseSubject.next(null);
            return _this.errorService.handleError(err).subscribe(function () { return null; }, function (errorText) {
                if (err.status !== 401) {
                    _this.modalService.warning({ title: _this.translate.instant('addItemToScPost.error.modal.title'), message: errorText, yesButtonText: _this.translate.instant('addItemToScPost.error.modal.button') });
                }
                return err;
            });
        });
    };
    CartService.prototype.removeItem = function (ID, isLocationBack) {
        var _this = this;
        if (isLocationBack === void 0) { isLocationBack = true; }
        if (!ID) {
            return;
        }
        var body = { ids: [ID] };
        var token = this.user_service.getUserSession().token;
        return this.dataService.postData('delete_item_from_sc', body, { token: token })
            .map(function () {
            _this.abandonedItemsIDs = _this.abandonedProductClass.removeOneAbandonedItem(ID, _this.abandonedItemsIDs);
            _this.showNotification(_this.translate.instant('product.details.removeFromShoppingCart.success.body'), isLocationBack);
            return {};
        }).catch(function (err) {
            return _this.errorService.handleError(err);
        });
    };
    CartService.prototype._abandonedItems = function (items) {
        var _this = this;
        this.editAbandonedItemArray(items);
        this.abandonedItemsIDs = this.abandonedProductClass.createrOfAbandonedItemsIDs();
        this.abandonedNotifyTimer = setInterval(function () { return _this.abandonedNotify(); }, 5000);
        this.abandonedBlinkTabTimer = setInterval(function () { return _this.abandonedItemsIDs.length !== 0 && _this.abandonedProductClass.abandonedItemsTabNotify(); }, 500);
    };
    CartService.prototype.abandonedNotify = function () {
        if (this.cart) {
            this.editAbandonedItemArray(this.cart.items);
            this.abandonedItemsIDs = this.abandonedProductClass.createrOfAbandonedItemsIDs();
            if (this.abandonedItemsIDs.length !== 0) {
                this.checkExpirationOfProduct();
            }
        }
    };
    CartService.prototype.resetAndRefreshAbandonedNotify = function () {
        this.resetAbandonedItems();
        this.abandonedNotify();
    };
    CartService.prototype.resetAbandonedItems = function () {
        this.abandonedItemsIDs = [];
        this.stopAbandonedTimer();
    };
    CartService.prototype.editAbandonedItemArray = function (items) {
        if (this.abandonedItemsIDs.length === 0 && typeof items === 'object') {
            this._addItemToAbandonedArr(items);
        }
        else {
            if (typeof items === 'string') {
                this.abandonedItemsIDs = this.abandonedProductClass.removeOneAbandonedItem(items, this.abandonedItemsIDs);
            }
        }
    };
    CartService.prototype._addItemToAbandonedArr = function (items) {
        this.abandonedItemsIDs = this.abandonedProductClass.updateAbandonedProducts(items);
    };
    CartService.prototype.checkExpirationOfProduct = function () {
        var _this = this;
        this.abandonedItemsIDs.forEach(function (id) {
            _this.abandonedProductClass.checkExpirationOfProduct(id, _this.windowLocation).subscribe(function (isShowNotiication) {
                if (isShowNotiication) {
                    _this.abandonedItemsIDs = _this.abandonedProductClass.removeOneAbandonedItem(id, _this.abandonedItemsIDs);
                }
            });
        });
    };
    Object.defineProperty(CartService.prototype, "user_service", {
        get: function () {
            return this.injector.get(UserService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CartService.prototype, "payment_service", {
        get: function () {
            return this.injector.get(ClientPaymentService);
        },
        enumerable: true,
        configurable: true
    });
    CartService.prototype.showNotification = function (messageText, locationBack) {
        if (locationBack === void 0) { locationBack = true; }
        this.modalService.success({
            title: this.translate.instant('product.details.addToShoppingCart.success.title'),
            message: messageText,
            yesButtonText: this.translate.instant('product.details.addToShoppingCart.success.btn.ok'),
            locationBack: locationBack,
        });
    };
    Object.defineProperty(CartService.prototype, "getCartMemento", {
        get: function () {
            return this.cartMemento;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CartService.prototype, "setCartMemento", {
        set: function (cart) {
            this.cartMemento = cart;
        },
        enumerable: true,
        configurable: true
    });
    CartService.prototype.changingOfCartItemPrices = function (cartOld, cartNew) {
        var _this = this;
        var cart = cartNew;
        cartNew.forEach(function (itemNew, index) {
            cartOld.forEach(function (itemOld) {
                if (itemNew.ID === itemOld.ID) {
                    itemNew.isItemWasChange = _this.itemService.itemPriceFn(itemNew.item) !== _this.itemService.itemPriceFn(itemOld.item);
                }
                cart[index] = itemNew;
            });
        });
        return cart;
    };
    CartService.prototype.isChangingOfCartItemPrices = function (cart) {
        return cart.items.some(function (item) { return item.isItemWasChange; });
    };
    CartService.prototype.initAdditionalCartItemParams = function (cart, isExpiredOrderTime) {
        cart.items.map(function (item) {
            item.isItemWasChange = false;
            if (isExpiredOrderTime) {
                item['_expiresInFormatted'] = '10:00';
            }
        });
        return cart;
    };
    CartService.prototype.save = function (part) {
        var _this = this;
        var token = this.user_service.getUserSession().token;
        if (!token) {
            return;
        }
        return this.shoppingCartService.updateAddinfoFromScPost(part, token)
            .do(function (shoppingCart) {
            _this._abandonedItems(shoppingCart.elements.sc_elements);
            return shoppingCart;
        }, function (err) { return _this.errorService.handleError(err); });
    };
    CartService.prototype.getItemID = function (id) {
        var item = this.cart.items.find(function (i) { return id === i.item.ID; });
        return item ? item.ID : '';
    };
    CartService.prototype.applyCoupon = function (coupon_code) {
        var _this = this;
        if (this.user_service.getUserSession().token) {
            return this.couponsService.applyCouponPost({ coupon_code: coupon_code }, this.user_service.getUserSession().token)
                .do(function (res) { return null; }, function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({});
    };
    CartService.prototype.applyCouponDelete = function () {
        var _this = this;
        if (this.user_service.getUserSession().token) {
            return this.couponsService.applyCouponDelete(this.user_service.getUserSession().token)
                .map(function (res) { return res; })
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({});
    };
    CartService.prototype.stopAbandonedTimer = function () {
        this.abandonedNotifyTimer && clearInterval(this.abandonedNotifyTimer);
        this.abandonedBlinkTabTimer && clearInterval(this.abandonedBlinkTabTimer);
        this.abandonedProductClass.removeAllAbandonedItemsFromStage();
    };
    Object.defineProperty(CartService.prototype, "itemService", {
        get: function () {
            return this.injector.get(ItemService);
        },
        enumerable: true,
        configurable: true
    });
    CartService.prototype.calculateTotalAmount = function (shoppingCart) {
        var _this = this;
        var prices = [0];
        if (shoppingCart.elements.sc_elements.length) {
            prices = shoppingCart.elements.sc_elements.map(function (element) {
                return element.quantity * _this.itemService.itemPriceFn(element.item);
            });
        }
        if (!this.cart.discount) {
            this.setTotal(prices.reduce(function (a, b) { return a + b; }));
        }
        else {
            this.setTotal(this.cart.discount.total);
        }
    };
    CartService.prototype.setTotal = function (totalAmount) {
        this.totalAmount = totalAmount;
        this.totalSubject.next(totalAmount);
    };
    Object.defineProperty(CartService.prototype, "total", {
        get: function () {
            return this.totalAmount;
        },
        enumerable: true,
        configurable: true
    });
    CartService.prototype.ngOnDestroy = function () {
        if (this.paymentRequestIntentSubscribe) {
            this.paymentRequestIntentSubscribe.unsubscribe();
        }
    };
    return CartService;
}());
export { CartService };
