import { Component }        from '@angular/core';
import { RedirectService }  from '../services/redirect.service';


@Component({
    selector:   'favorite-list-header',
    styleUrls:  ['../../assets/styles/app-header.sass'],
    template:   `
        <header class="app-header wf-list-header">
            <nav class="app-header__container" role="fav">
                
                <div class="navbar__left">
                    <a (click)="goBack()" data-test-id="shoppingLink" 
                       class="nav__link"><i class="icon-custom_back_icon"></i></a>
                </div>

                <div class="navbar__center" role="fav">
                    <h4>{{ "favorite.list.favorites" | translate }}</h4>
                </div>

                <div class="navbar__right" role="fav"></div>
            </nav>
        </header>
    `
})

export class FavoriteListHeaderComponent {
    constructor(
        private redirectService:    RedirectService,
    ) { }



    goBack() {
        this.redirectService.goBack();
    }
}
