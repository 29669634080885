import {Component} from '@angular/core';
import {ReviewInterfaceItem} from '../review.interface';
import {ModalService} from '../../modal/modal.service';
import {AppReviewsService} from '../reviews.service';
import {ReviewsAbstractClass} from './reviews-abstract.class';
import AppValues from '../../common/app.values';
import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {AppFooterService} from '../../app-footer/app-footer.service';

@Component({
    selector: 'reviews-list-products',
    styleUrls: ['.././reviews.sass'],
    template: `
        <ul *ngIf="filteredItems" class="search__container" id="custom-container">
            <li class="review-list__product review-list" *ngFor="let review of filteredItems; trackBy: trackByID; let i =  index;"
                [attr.data-test-id]="'reviewItem_'+i" [id]="review.order_element.ID">
                <img *ngIf="review.stars >= 4" [attr.data-test-id]="'like-review_'+i"
                     src="../../../assets/images/svg_icons/positive.svg"/>
                <img *ngIf="review.stars < 4 && review.stars > 2" [attr.data-test-id]="'neutralReview_'+i"
                     src="../../../assets/images/svg_icons/neutral.svg"/>
                <img *ngIf="review.stars <= 2" [attr.data-test-id]="'dislike-review_'+i"
                     src="../../../assets/images/svg_icons/negative.svg"/>

                <div class="review-product__container-info">

                    <div class="first-product-info-row">
                        <div>
                            <p class="review-author" [attr.data-test-id]="'productTitle_'+i">{{review.sender_name}}</p>
                        </div>
                    </div>

                    <div class="second-product-info-row">
                        <p class="review-comment" [attr.data-test-id]="'comment_'+i">{{review.text}}</p>
                        <p class="product-date" [attr.data-test-id]="'productDate_'+i">
                            {{review.create_date * THOUSAND | date: fullDatePattern }}</p>
                    </div>
                
                </div>
            </li>
        </ul>
    `
})

export class ReviewsListProductsComponent extends ReviewsAbstractClass {

    public THOUSAND: number = AppValues.THOUSAND;
    public fullDatePattern: string = AppValues.fullDatePattern;

    constructor(modalService:       ModalService,
                footerService:      AppFooterService,
                reviewsService:     AppReviewsService,
                scrollToService:    ScrollToService) {
        super(modalService, reviewsService, scrollToService, footerService);
    }
}
