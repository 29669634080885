
        <div class="modal__content" (click)="hideAll()">
            <div [ngClass]="context.bigTextBlock ? 'modal__bigtest-dialog' : 'modal__dialog'">
                
                <h2 class="modal__header" data-test-id="modalHeader"
                    [ngClass]="{
                        'modal__header-ok':         context.status === 'ok',
                        'modal__header-warning':    context.status === 'warning',
                        'modal__header-error':      context.status === 'error',
                        'modal__header-black':      context.status === 'dialog_question'
                    }">{{context.title}}</h2>

                <p *ngIf="context.message" class="modal__msg" data-test-id="modalMessage">{{context.message}}</p>
                <div *ngIf="context.bigTextBlock === 'TermsAndConditions'" class="modal__bigtext-block" data-test-id="modalBigTextBlock">
                   
  <div class="modal__bigtext-block__container terms">
    <h2 class="terms__title-first">BiziBAZA&reg; PRIVACY POLICY</h2>
    <h2 class="terms__title-second m-12">EFFECTIVE DATE: OCTOBER 1, 2018</h2>

    <p>We are 111TECHS LLC, a company registered in the state of California, in United States of America, and we strive
        to deliver the best purchasing experience available. This site is developed and maintained by 111TECHS LLC, or
        subsidiaries and affiliates (hereinafter collectively &ldquo;111TECHS&rdquo; &ldquo;BiziBAZA&rdquo; &ldquo;we&rdquo;
        or &ldquo;us&rdquo;), acting as a data controller for the purpose of applicable data protection law. When you
        visit our website, mobile apps, official social media sites, or other online properties, including any owned,
        controlled, or operated by a 111TECHS subsidiary or affiliate, (collectively &ldquo;sites,&rdquo;), we (and some
        of our business and advertising partners) may ask for information about you and also create data about your use
        of our sites or apps. This Privacy Policy tells you what information we collect, what we do with it, and the
        options you have regarding the information you and/or we share and why.</p>
    <p>This Privacy Policy does not apply to information about you collected by any third party, including through any
        application or content (including advertising) that may link to or be accessible on our sites or apps, which
        shall instead be governed by such third party&rsquo;s terms and policies.</p>
    <p>This Privacy Policy remains in full force and effect even if your use of or participation in any particular
        service, feature, function or promotional activity on our sites or apps terminates, expires, ceases, is
        suspended or deactivated for any reason. We may retain your information even after we have ceased providing
        services to you, but where possible we make efforts to ensure your information is fully anonymized. Information
        collected will not be stored for longer than is necessary for the purposes described in this Privacy Policy, or
        to otherwise meet legal requirements.</p>
    <p><b>If you do not wish for us to process your personal data in the ways outlined in this Privacy Policy, then
        please do not access or use our sites or apps. Where we need a specific consent to a particular form of
        processing, such as the collection of information about your location or the use of your information for certain
        marketing, we may get your consent by other means, such as via your account settings, or from the settings you
        have chosen on your browser or device.</b></p>
    <p>If you have any questions or issues regarding our Privacy Policy, please contact us via email at&nbsp;support@bizibaza.com&nbsp;or
        via postal mail to: Privacy Team, BiziBAZA&reg; 3527 Mt. Diablo Blvd #129., Lafayette, CA 94549.</p>

    <h2 class="terms__title-second">SUMMARY</h2>
    <p>For your convenience, we have provided a brief summary of our Privacy Policy. Each section of the summary
        contains links to more detailed information in the full Privacy Policy, below.</p>

    <div class="terms__table">
        <div class="terms__table__row">
            <h2 class="terms__table__title terms__title-second">WHAT INFORMATION WE COLLECT AND HOW IT IS COLLECTED</h2>
            <div class="terms__table__list">
                <p>BiziBAZA collects information about you when you interact with our sites or apps, including:</p>

                <ul class="c13 lst-kix_list_1-0 start">
                    <li class="c0"><span class="c1">We collect information you provide us when you register for an account.</span>
                    </li>
                </ul>
                <ul class="c13 lst-kix_list_1-1 start">
                    <li class="c3 c17"><span class="c1">If you sign in by email we will store your email address.</span>
                    </li>
                    <li class="c3 c17"><span class="c1">If you create a BiziBAZA account using a social network to log in, or connect with Facebook or another social network via the BiziBAZA app, we may also receive some information about you from that network (e.g., your Facebook ID, name, profile picture, gender, friend list, birthdate, email address and Facebook registration date).</span>
                    </li>
                </ul>
                <ul class="c13 lst-kix_list_1-0">
                    <li class="c0"><span class="c1">If you provide us with consent, we will collect your location to provide additional features and functionality.</span>
                    </li>
                    <li class="c0"><span class="c1">We automatically receive and track certain information about your computer or mobile device when you visit our sites or apps, including through the use of </span><span
                        class="c2"><a class="c26"
                                      href="https://www.shazam.com/terms#pp5">cookies</a>.</span>
                    </li>
                    <li class="c0"><span class="c1">We record logs about how you use BiziBAZA (e.g. what features you use and what items purchased)</span>
                    </li>
                </ul>
                <p class="c3"><span class="c1">For more detailed information on what information we collect and how it is collected, please go </span>
                    <a class="c2" target="_blank" href="#">here</a><span class="c1">.</span></p>
            </div>
        </div>
        <div class="terms__table__row">
            <h2 class="terms__table__title terms__title-second">HOW WE USE YOUR INFORMATION</h2>
            <div class="terms__table__list">
                <p>BiziBAZA uses the information collected about you to:</p>

                <ul class="c13 lst-kix_list_2-0 start">
                    <li class="c0"><span class="c1">Provide you with our services and personalize them.</span></li>
                    <li class="c0"><span class="c1">Manage and improve the services and our internal operations.</span>
                    </li>
                    <li class="c0"><span
                        class="c1">Protect our rights, enforce our policies, and comply with laws.</span></li>
                    <li class="c0"><span class="c1">If you provide us with consent, we will send you offers we believe may be of interest to you.</span>
                    </li>
                </ul>
                <p class="c3"><span class="c1">For more detailed information on what information we collect and how it is collected, please go </span>
                    <span class="c2">
                        <a class="c26" target="_blank"
                                        href="https://www.shazam.com/terms#pp2">here</a>
                        <span class="c1">.</span></span></p>

            </div>
        </div>
        <div class="terms__table__row">
            <h2 class="terms__table__title terms__title-second">WHEN AND HOW WE SHARE YOUR INFORMATION</h2>
            <div class="terms__table__list">
                <p>BiziBAZA may share your information (and/or device identifiers) with third parties, including:</p>
                <ul class="c13 lst-kix_list_3-0 start">
                    <li class="c0"><span class="c1">Service providers who perform services and functions on our behalf to help deliver the services and communicate with you.</span>
                    </li>
                    <li class="c0"><span class="c1">Third parties including advertisers and partners, some of whom may use your information for the purpose of providing you with interest-based advertisements.</span>
                    </li>
                    <li class="c0"><span class="c1">Other BiziBAZA users and your social network, based on your settings on those networks.</span>
                    </li>
                    <li class="c0"><span class="c1">In order to comply with laws or valid legal requests for information such as a subpoena or search warrant.</span>
                    </li>
                </ul>
                <p class="c3"><span class="c1">For more detailed information on when and how we share your information, please go </span>
                    <a class="c2" target="_blank" href="#">here</a><span class="c1">.</span></p>
            </div>
        </div>
        <div class="terms__table__row">
            <h2 class="terms__table__title terms__title-second">YOUR CHOICES AND CONTROL OVER YOUR INFORMATION</h2>
            <div class="terms__table__list">
                <p>BiziBAZA provides multiple methods for you to exercise control and choices over the information we
                    collect from you, including the ability to update or delete information or limit cookies or
                    advertising, by:</p>
                <ul class="c13 lst-kix_list_4-0 start">
                    <li class="c0"><span class="c1">Updating your account through the sites.</span></li>
                    <li class="c0"><span class="c1">Contacting us directly.</span></li>
                    <li class="c0"><span class="c1">Controlling the settings on your browser or mobile device.</span>
                    </li>
                </ul>
                <p class="c3"><span class="c1">For more detailed information on how to exercise control and choices, please go </span>
                    <a class="c2" target="_blank" href="#">here</a><span class="c1">&nbsp;and </span>
                    <a class="c2" target="_blank" href="#">here</a><span class="c1">.</span></p>
            </div>
        </div>
        <div class="terms__table__row">
            <h2 class="terms__table__title terms__title-second">HOW WE PROTECT YOUR INFORMATION</h2>
            <div class="terms__table__list">
                <p>BiziBAZA has implemented technical, organizational, administrative, and physical security measures
                    that are designed to protect your information from unauthorized accidental or unlawful access,
                    disclosure, use and modification.</p>
                <p class="c3"><span class="c1">For more detailed information on how we protect your information, please go </span>
                    <a class="c2" target="_blank" href="#">here</a><span class="c1">.</span></p>
            </div>
        </div>
        <div class="terms__table__row">
            <h2 class="terms__table__title terms__title-second">HOW TO CONTACT US</h2>
            <div class="terms__table__list">
                <p>BiziBAZA is happy to answer any questions you may have regarding this Privacy Policy. Please contact
                    us at:</p>
                <ul class="c13 lst-kix_list_5-0 start">
                    <li class="c0"><span class="c2"><a target="_blank" href="mailto: support@bizibaza.com">support@bizibaza.com</a></span>
                    </li>
                    <li class="c0"><span class="c1">Privacy Team</span></li>
                    <li class="c0"><span class="c1">BiziBAZA&reg; </span><span
                        class="c7">3527 Mt. Diablo Blvd #129., </span></li>
                </ul>
                <ul class="c13 lst-kix_list_6-0 start">
                    <li class="c0"><span class="c7">Lafayette, CA 94549</span></li>
                    <li class="c0"><span class="c1">United States of America</span></li>
                </ul>
                <p class="c3"><span class="c1">For additional information on how to contact us, please go </span>
                    <a class="c2" target="_blank" href="#">here</a><span class="c1">.</span></p>
            </div>
        </div>
    </div>


    <p>If you want to jump to a particular section of the policy &ndash; click on one of the links below:</p>
    <ul class="c13 lst-kix_list_8-0 start nav">
        <li>1. What Information We Collect and How It Is Collected.</li>
        <li>2. How We Use Your Information.</li>
        <li>3. When and How We Share Your Information &ndash; Key Facts and How to Opt Out.</li>
        <li>4. Links to Other Websites.</li>
        <li>5. Use of Cookies.</li>
        <li>6. Over 18-Year-Olds Only.</li>
        <li>7. Your Choices and Control over Your Information: How to Access, Port or Delete Your Information (and a
            Special Note to California Residents).
        </li>
        <li>8. Promotions.</li>
        <li>9. How We Store and Protect Your Information: Data Retention, Security and Integrity.</li>
        <li>10. Changes to This BiziBAZA Privacy Policy.</li>
        <li>11. Overseas Data Transfer.</li>
        <li>12. Select Third-Party Partners.</li>
        <li>13. Contact Us.</li>
    </ul>
 
    <h2>1. What Information We Collect and How It Is Collected.</h2>
    <p class="c3"><span class="c1">BiziBAZA collects information about you when you interact with our sites or apps.</span></p>
    <p class="c3"><span class="c5">Information You Provide to Us</span><span class="c1">.&nbsp;Although some features of our sites or apps are available without creating an account, if you choose to register for an account to access additional BiziBAZA features we may request some information, including, but not limited to, your name and email address. We may also collect information about you when you participate in certain activities such as filling out a form to take advantage of offers, completing a survey, participating in a contest or promotion or to sign up to receive one of our newsletters, communicating with us via third-party social media sites, requesting customer support, or otherwise communicating with us.</span>
    </p>
    <p class="c3"><span class="c1">If you create a BiziBAZA account using a social network to log in, or connect with Facebook or another social network via the BiziBAZA app, we may also receive some information about you from that network (e.g., your Facebook ID, name, profile picture, gender, friend list, birthdate, email address and Facebook registration date).</span>
    </p>
    <p class="c3"><span class="c1">When you launch the BiziBAZA mobile application, we may ask to access your location to provide additional features and functionality, although sharing your location is not required. Your device&rsquo;s settings may provide you with location permissions settings. The default settings may vary from device to device and are subject to your specific device&rsquo;s functionality. We may also ask you for permission to send push notifications to your device.</span>
    </p>
    <p class="c3"><span class="c5">Information Collected Automatically</span><span class="c1">. We also automatically receive and track certain data about your computer or mobile device when you visit our sites or apps, including, but not limited to, your device type, IP address, software and language preference, browser, type of operating system, date and time of viewing, use of our features and purchasing history or preferences, and your taps and interests. We may also create a unique device or user ID for you so we can recognize you. In some cases or on some devices, we may automatically detect or infer your location using GPS, your IP address, watermarks and/or Bluetooth, but often your operating system will require you to grant us permission to access your GPS or Bluetooth settings; please refer to your device&rsquo;s system settings regarding location services.</span>
    </p>
    <p class="c3"><span class="c1">Some Internet browsers include the ability to transmit &ldquo;Do Not Track&rdquo; signals. Since uniform standards for &ldquo;Do Not Track&rdquo; signals have not been adopted, and there is currently disagreement concerning what, if anything, websites should do when they receive such signals, BiziBAZA does not process or respond to &ldquo;Do Not Track&rdquo; signals. If and when a final standard is established and accepted, we may reassess how to respond to these signals. We do, however, respect established industry opt-out mechanisms</span>
    </p>
    <p class="c3"><span class="c1">We may acquire information from other trusted sources to update or supplement the information you provided or we collected automatically. Local law may require that you authorize the third party to share your information with us before we can acquire it.</span>
    </p>
   
    <h2>2. How We Use Your Information</h2>
    <p class="c3"><span class="c1">When permitted by applicable law, we may use your information, including personal data, for the following purposes:</span>
    </p>
    <p class="c3"><span class="c1">i. to provide you with our services including, but not limited to, the display of customized content, integration with our partner apps, and targeted advertising both on websites and other apps/websites that we advertise through; </span>
    </p>
    <p class="c3"><span class="c1">ii. to communicate with you about BiziBAZA products and services, including sending marketing communications that we believe may be of interest to you, through electronic communications with your consent or where otherwise permitted by applicable law, and to provide assistance with customer service issues; </span>
    </p>
    <p class="c3"><span class="c1">iii. to provide you with an interactive social media experience that enables you to connect your social networking community to our sites or apps; </span>
    </p>
    <p class="c3"><span class="c1">iv. to determine when you link from our app to one of our partner apps or services, so that we can monitor the level of traffic that we generate for our partner apps or services; </span>
    </p>
    <p class="c3"><span class="c1">v. to manage BiziBAZA&rsquo;s everyday business needs, such as administration and improvement of the sites and ensure the technical functioning of our products and services;&nbsp; </span>
    </p>
    <p class="c3"><span class="c1">vi. to protect BiziBAZA&rsquo;s intellectual property, including its copyrights;&nbsp; </span>
    </p>
    <p class="c3"><span class="c1">vii. to enforce our Terms and Conditions and prevent fraud;&nbsp; </span></p>
    <p class="c3"><span class="c1">viii. to comply with our legal and regulatory obligations, requests from government bodies or courts, or to respond to litigation; </span>
    </p>
    <p class="c3"><span class="c1">ix. to enable us to use a third party to measure the usage of our services by our users, with your consent or where otherwise permitted by applicable law; and&nbsp; </span>
    </p>
    <p class="c3"><span class="c1">x. in any other way we may describe when you provide the information or in any way that you explicitly consent to.&nbsp; </span>
    </p>
    <p class="c3"><span class="c1">We may aggregate your data with other users&#39; data in order to create statistics about the general use of the sites or apps, which helps us to develop new products and services. We may also share this aggregated data with our business partners and third-party advertisers. This aggregated data cannot be used to identify you and cannot be otherwise traced back to you.</span>
    </p>
    <p class="c3"><span class="c1">We rely on the following legal bases, under data protection law, to process your personal data:</span>
    </p>
    <ul class="c13 lst-kix_list_10-0 start">
        <li class="c0"><span class="c1">where the processing is necessary to perform a contract with you, or take steps prior to entering into a contract with you.</span>
        </li>
        <li class="c0"><span class="c1">where we have obtained your consent (e.g. if you consent to receive certain marketing from us).</span>
        </li>
        <li class="c0"><span class="c1">where it is in our legitimate interests to maintain and promote our services. Some of examples of how we do this include:</span>
        </li>
    </ul>
    <ul class="c13 lst-kix_list_10-1 start">
        <li class="c3 c17"><span class="c1">Using your information to provide relevant and interesting content. We are always seeking to understand more about our customers in order to offer the best customer experience. We sometimes use information about you to tailor your view of the service, to make it more interesting and relevant in respect of the features and advertisements on view. You can opt out of advertising targeted to your interests at any time in the ways described in the sections below.</span>
        </li>
        <li class="c3 c17"><span class="c1">Conducting market research to improve the operation of the service and to make sure we show users content which they are after.</span>
        </li>
        <li class="c3 c17"><span class="c1">Using analytics technologies which show us when and how people interact with our service to make sure we continue to provide the best platform possible.</span>
        </li>
    </ul>
    <h2>3. When and How We Share Your Information &ndash; Key Facts and How to Opt Out.</h2>
    <p class="c3"><span class="c1">We may share your information, such as your device, IDFA/AAID, browser type, hashed email address, inferred age and gender, and location information (if you have consented to location access), with third parties including advertisers and partners, some of whom may use your data for the purposes of interest-based advertising, including demographic, behavioral, and geographic ad targeting or to provide localized services (with your prior permission or where otherwise permitted by applicable law).</span>
    </p>
    <p class="c3"><span class="c1">Your device may include an option to limit advertisement tracking. By enabling this feature, you may not see adverts personalized using your device&rsquo;s unique advertising identifier when accessing the BiziBAZA app. You may need to enable this setting, as the default settings may vary from device to device and are subject to your specific device&rsquo;s functionality.</span>
    </p>
    <p class="c3"><span class="c1">We also may share your information with third-party service providers who perform services and functions on our behalf to support our interactions with you, including providing our products and services, administering surveys or contests, or communicating with you.</span>
    </p>
    <p class="c3"><span class="c1">Additionally, some third parties (such as companies that advertise through our apps and website) may require you to provide your personal data in order to access their products, advertisements and offers. Any third party&rsquo;s use of your personal data is governed by their own privacy policy. Please consult the privacy policies of those third parties to determine how they collect and use your personal data.</span>
    </p>
    <p class="c3"><span class="c1">We may share your information with third parties, including law enforcement authorities, if we determine that doing so is appropriate and in accordance with applicable law to:</span>
    </p>
    <p class="c3"><span class="c1">i. Enforce our Terms and Conditions,</span></p>
    <p class="c3"><span class="c1">ii. Protect our intellectual property, services and legal rights,</span></p>
    <p class="c3"><span class="c1">iii. Prevent physical harm to the persons or property of others,</span></p>
    <p class="c3"><span class="c1">iv. Prevent fraud against BiziBAZA and others,</span></p>
    <p class="c3"><span class="c1">v. Support auditing, compliance, and corporate governance functions,</span></p>
    <p class="c3"><span class="c1">vi. Comply with subpoenas, court orders, or similar legal processes, including to law enforcement agencies, regulators and courts, and</span>
    </p>
    <p class="c3"><span class="c1">vii. Comply with any and all applicable laws.</span></p>
    <p class="c3"><span class="c1">If you choose to make a purchase (for example, a song), you will navigate away from the app and be directed to the third-party music source you selected. Any information you provide to such third party is provided by you directly to them, and is not received by BiziBAZA.</span>
    </p>
    <p class="c3"><span class="c5">Third-Party Advertisements and Analytics</span><span class="c1">.&nbsp;BiziBAZA is a free service. We can make it available to you this way through our support from advertisers. We think that the ads we offer are interesting and relevant, and to make them more relevant our third parties sometimes use your personal data to &lsquo;personalise&rsquo; ads.</span>
    </p>
    <p class="c3"><span class="c1">Consistent with your permission, third parties, including advertisers, may collect information, potentially including personal data, about your activities over time and across different websites when you use our sites or apps. Those third parties may also provide us with data collection, reporting, ad-response measurement, analytical information, and assist with delivery of relevant marketing messages and advertisements.</span>
    </p>
    <p class="c3"><span class="c1">Many third-party advertisers and some Web browsers and mobile devices allow you to opt out of third-party advertising. To learn more about interest-based advertising or to opt out of some types of interest-based advertising for participating ad networks, you can visit the Network Advertising Initiative (http://www.networkadvertising.org/choices/), the Digital Advertising Alliance (http://www.aboutads.info/choices/), or for users in the EU, the European Interactive Digital Advertising Alliance&rsquo;s Consumer Choice Page (http://www.youronlinechoices.eu). You may also opt out of tracking and receiving interest-based advertisements on your mobile device by some mobile advertising companies and other similar entities by downloading the App Choices app at http://www.aboutads.com/info/appchoices.&nbsp;</span>
    </p>
    <p class="c3"><span class="c5">Social Networks</span><span class="c1">.&nbsp;If you connect your BiziBAZA account to any social network, including, but not limited to, Facebook, Google+, your name, birthdate, email address, gender, friend list, profile picture, along with other elements of your social network profile that you have made available may be stored by BiziBAZA and used to personalize your user experience.</span>
    </p>
    <p class="c3"><span class="c5">Business Transfers and Corporate Changes</span><span class="c1">.&nbsp;In the event that ownership of BiziBAZA or an operator of one or more of the sites changes as a result of a merger, acquisition, or transfer to another company, your personal data may be transferred. If such a transfer results in a material change in the use of your personal data, then BiziBAZA will provide you with appropriate notice.</span>
    </p>
    <h2>4. Links to Other Websites.</h2>
    <p class="c3"><span class="c1">We may display links to third-party websites and apps or provide a result that links directly to a third-party website or app. Please note that even if we are affiliated with such third party, we do not control and cannot be held responsible for the content, activities, policies and practices of such websites and apps regarding your information, and those sites may use the information they collect from you consistent with their own privacy policies. You should always read the privacy policy of a website or app to find out more about how your information is collected and processed.</span>
    </p>
    <h2>5. Use of Cookies.</h2>
    <p class="c3"><span class="c1">Like most websites and mobile apps, we use cookies to track site usage, to send you advertisements and to customize your BiziBAZA experience. A cookie is a tiny text or graphics file that resides on your computer, mobile phone, or other device, and allows us and/or our business partners to recognize you as a user. For more detail on how we use cookies on our sites or apps, please see our Cookie Policy&nbsp;</span>
        <a class="c2" target="_blank" href="#">here</a><span class="c1">.</span></p>
    <p class="c3"><span class="c1">More information is available on the development of user profiles, the targeting of advertising and how to stop the use of &quot;targeting&quot; cookies. Please see&nbsp;http://www.youronlinechoices.eu/&nbsp;if you are located in Europe or&nbsp;http://www.aboutads.info/choices&nbsp;if in the United States. Many Internet browsers and mobile devices allow you to block the transmission of information, such as your IP address or location information, or to block the installation of cookies for that specific browser or device through its settings. Please note if you delete our cookies or disable future cookies, you may not be able to fully use the BiziBAZA sites, apps and features thereof. If you want to delete any cookies on your computer, please refer to the instructions for your file-management software to locate the file or directory that stores cookies. Your mobile operating system should also give you the option to manage your cookies and advertising preferences (this may be found in the &quot;settings&quot; function on your device).</span>
    </p>
    <h2>6. Over 18-Year-Olds Only.</h2>
    <p class="c3"><span class="c1">We take our responsibilities to the online community very seriously. Our sites or apps are general audience sites not directed at children under the age of 18. As such, if you are under the age of 18, we have to ask you not to use our sites or apps in any capacity. We do not knowingly collect data from anyone under the age of 18 and we do not direct any of our products or services at this age group. Any person who provides their personal data to BiziBAZA through its sites or apps represents that they are 18 years of age or older. If we become aware that we have inadvertently captured any personal data about a person who is under 18 years of age, we will take the appropriate steps to shut down the account of that person and promptly delete their information.</span>
    </p>
    <h2>7. Your Choices and Control over Your Information: How to Access, Port or Delete Your Information (and a Special Note to California Residents).</h2>
    <p class="c3"><span class="c1">You can request to access, port and delete your personal data at any time through the BiziBAZA sites or apps by using our automated self-service system. Please see the </span><span
        class="c2"><a class="c26" target="_blank" href="https://www.shazam.com/privacy">&lsquo;know your rights&rsquo;</a></span><span
        class="c1">&nbsp;page for more information.</span></p>
    <p class="c3"><span class="c1">Alternatively, or where your request cannot be completed using our automated system, you can send us an email at&nbsp;Support@bizibaza.com. Where your request cannot be completed using our automated system, we will respond to any requests to exercise your rights in accordance with applicable law. Please be aware, however, that there may be limitations to your rights and there may be circumstances where we are not able to comply with your request. For users in the EU, you also have the right to complain to your local supervisory authority for data protection, but we ask that in the first instance you contact us so we can deal with, and hopefully resolve, your query.</span>
    </p>
    <p class="c3"><span class="c5">What&rsquo;s deleted?</span><span class="c1">&nbsp;Your BiziBAZA account, your BiziBAZA history and any associated personal information, including your email address and, if you signed in with Facebook, our records of your Facebook ID and any personal data shared with us via the Facebook platform. Depending on your Facebook privacy settings, this could include your Public Profile, Email Address, Birthday, Friends List and Current City.<br></span>
    </p>
    <p class="c3"><span class="c5">What&rsquo;s not deleted?</span><span class="c1">&nbsp;Analytics logs about how you used BiziBAZA will not be erased, but once your account is deleted, these logs will be made anonymous and not attributable to you as an individual.</span>
    </p>
    <p class="c3"><span class="c5">How do I delete my account?</span><span class="c1">&nbsp;Click &ldquo;Delete my data&rdquo; and BiziBAZA will process your request within 30 days. Note that this action cannot be undone.</span>
    </p>
    <p class="c3"><span class="c5">E-Mail marketing</span><span class="c1">. We love to communicate with our customers and so, depending on your marketing preferences, we may use your personal data to send you marketing messages by email. Some of these emails may be tailored to you as described in this Privacy Policy.</span>
    </p>
    <p class="c3"><span class="c1">If you no longer want to receive marketing communications from us, you can opt out by clicking on the &ldquo;unsubscribe&rdquo; link at the bottom of an email, or by contacting us directly at&nbsp;support@bizibaza.com. Please note that even if you unsubscribe to commercial or marketing email messages, we may still communicate with you regarding your account or use of the services.</span>
    </p>
    <p class="c3"><span class="c5">Moving your data</span><span class="c1">. If you are located in the EU, you may have the right to have the personal data we use to provide the BiziBAZA service to you provided to you in a machine-readable format. This right enables you to move your personal data to another provider, for example, so that the other provider can continue to service your needs effectively. To exercise this right, please follow the link to the &lsquo;know your rights&rsquo; page where you can find further information.</span>
    </p>
    <p class="c3"><span class="c5">California Privacy Rights</span><span class="c1">.&nbsp;California Civil Code Section 1798.83 also permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we disclosed personal data (if any) for those third parties&rsquo; direct marketing purposes in the preceding calendar year and the categories of data disclosed to those third parties. To make any sort of access request, please email or write to us using our&nbsp;Contact Us&nbsp;information.</span>
    </p>
    <h2>8. Promotions.</h2>
    <p class="c3"><span class="c1">From time to time, we, our advertisers, suppliers or other parties may conduct promotions and other activities on, through or in connection with the Services, including, without limitation, contests and sweepstakes (collectively, &ldquo;Promotions&rdquo;). Those Promotions may have additional terms and/or rules or eligibility requirements that shall be posted or otherwise made available to you by us or the relevant third party, as applicable, in accordance with applicable law.</span>
    </p>
    <h2>9. How We Store and Protect Your Information: Data Retention, Security and Integrity.</h2>
    <p class="c3"><span class="c1">The security, integrity and confidentiality of your information are important to us. We have implemented technical, organizational and physical security measures that are designed to protect your information from unauthorized accidental or unlawful access, disclosure, use and modification. From time to time, we review our security procedures to consider appropriate new technology and methods. Please be aware, however, that, despite our best efforts, no security measures are perfect or impenetrable.</span>
    </p>
    <p class="c3"><span class="c1">We will keep your personal data for as long as we need it for the purposes set out above, and so this period will vary depending on your interactions with us. We may also keep a record of correspondence with you (for example if you have made a complaint about a product) for as long as is necessary to protect us from a legal claim. Where we no longer have a need to keep your information, we will delete it. Please note that where you unsubscribe from our marketing communications, we will keep a record of your email address to ensure we do not send you marketing emails in future.</span>
    </p>
    <h2>10. Changes to This BiziBAZA Privacy Policy.</h2>
    <p class="c3"><span class="c1">We ask that you read this Privacy Policy from time to time. BiziBAZA reserves the right to make changes to this BiziBAZA Privacy Policy. If we make material changes to this Privacy Policy that increase or alter our rights to use personal data we have previously collected about you, we will obtain your consent either through an email to your registered email address or by prominent posting on our sites or apps. By continuing to use our sites or apps, you signify your acceptance of the revised policy.</span>
    </p>
    <h2>11. Overseas Data Transfer.</h2>
    <p class="c3"><span class="c1">We use service providers and business partners based around the world. Your personal data may be transferred to, and stored at, a destination outside the European Economic Area (EEA). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. We will take all steps reasonably necessary to ensure that your personal data is treated securely and in accordance with this Privacy Policy.</span>
    </p>
    <p class="c3"><span class="c1">If we transfer personal data outside the EEA we will, as required by applicable law, ensure that your privacy rights are adequately protected by appropriate safeguards, in particular the European Commission&rsquo;s EU-US Privacy Shield and Model Contracts for the Transfer of Personal Data to Third Countries. Please contact us if you would like more information about these safeguards.</span>
    </p>
    <h2>12. Select Third-Party Partners.</h2>
    <p class="c3"><span class="c1">Per&nbsp;Section 3, &ldquo;Sharing Data With Third Parties &ndash; Key Facts and How to Opt Out,&rdquo;&nbsp;we may share your information with third-party service providers who perform services and functions on our behalf to support our interactions with you, including providing our products and services, processing your purchases, administering surveys or contests, hosting product reviews, or communicating with you.</span>
    </p>
    <h2>13. Contact Us</h2>
    <p class="c3"><span class="c1">If you have any questions or suggestions regarding our privacy policy, please contact us via email at support@bizibaza.com or via postal mail to:&nbsp;<br> Privacy Team<br>BiziBAZA<br></span><span
        class="c7">3527 Mt. Diablo Blvd #129., </span></p>
    <p class="c3"><span class="c7">Lafayette, CA 94549</span></p>
    <p class="c3"><span class="c1">United States of America</span></p>
    <p class="c3"><span class="c1">Copyright &copy; 2018 </span><span class="c5">111TECHS LLC</span><span class="c1">. All rights reserved.</span>
    </p>
    <p class="c3"><span class="c7">3527 Mt. Diablo Blvd #129., </span></p>
    <p class="c3"><span class="c7">Lafayette, CA 94549</span></p>
    <p class="c3"><span class="c1">United States of America</span></p>
    <!--<p class="c3"><span class="c2">back to top </span><span class="c6">&uarr;</span></p>-->
    <h2>COOKIE POLICY</h2>
    <p class="c3"><span class="c1">111TECHS LLC (&ldquo;BiziBAZA&rdquo;, &ldquo;111TECHS&rdquo; &ldquo;we&rdquo;, &rdquo;our&rdquo; or &ldquo;us&rdquo;) is committed to being transparent about the technologies it uses. This Cookie Policy explains what cookies are and how and why they may be stored on and accessed from your device when you visit our website, mobile app, official social media sites, or other online properties (together the &ldquo;sites&rdquo;). It should be read together with our </span><span
        class="c2">Privacy Policy</span><span class="c1">&nbsp;and our </span><span
        class="c2">Terms &amp; Conditions</span><span class="c1">.</span></p>
    <p class="c3"><span class="c1">By continuing browsing or using this site, you agree that we can store and access to cookies as described in this Cookie Policy.</span>
    </p>
    <h2>1. What are cookies?</h2>
    <p class="c3"><span class="c1">A cookie is a small text file that is stored on and accessed from your device when you visit one of our sites if you agree. This allows our site to recognize your device from those of other users or visitors of the site. You can find more information at http://www.allaboutcookies.org and http://www.youronlinechoices.eu.</span>
    </p>
    <h2>2. How do our sites use cookies</h2>
    <p class="c3"><span class="c1">Our sites use the following cookies for the following purposes:</span></p>
   <div class="terms__table">
        <div class="terms__table__row">
            <h2 class="terms__table__title terms__title-second">Cookie Type</h2>
            <h2 class="terms__table__list">Purpose</h2>
        </div>
        <div class="terms__table__row">
            <div class="terms__table__title terms__title-second">Strictly necessary</div>
            <div class="terms__table__list">These cookies are necessary to allow us to operate the BiziBAZA websites and apps as you have requested. These cookies, for example, let us recognize what type of subscriber you are and then provide you with the services to which you are entitled. They also include cookies that enable to increase the security of our sites or to remember your previous actions within the same browsing session.
            </div>
        </div>
         <div class="terms__table__row">
            <div class="terms__table__title terms__title-second">Analytical / Performance</div>
            <div class="terms__table__list">These cookies are used by us or our business partners to analyze how the BiziBAZA sites are used and how they are performing. These cookies do not let us identify users (as all user data is aggregated). They are also used to monitor when you link from our app to one of our partner apps so that we can check what levels of traffic we are generating for our partner apps.
            </div>
        </div>
        <div class="terms__table__row">
            <div class="terms__table__title terms__title-second">Functionality</div>
            <div class="terms__table__list">These cookies let us operate certain functions of the BiziBAZA websites and apps in line with the choices you make. These cookies mean that we can &quot;remember&quot; you in-between visits. For instance, we will recognize your user name and remind you how you customized our services.
            </div>
        </div>
        <div class="terms__table__row">
            <div class="terms__table__title terms__title-second">Targeting / Advertising</div>
            <div class="terms__table__list">These cookies are used by us and our business partners to send you advertisements when using the BiziBAZA websites and apps and to target those advertisements to you and your interests. In doing this our business partners may use the profiles they have developed of other apps and websites that you have previously visited. These cookies may also help us and our business partners regulate the advertisements you receive and measure their effectiveness. If we let our business partners place the cookies for advertising we do not give your name, email address or phone number to those business partners.
           </div>
        </div>
   </div>


    <h2>3. How do I refuse or withdraw my consent to the use of cookies?</h2>
    <p class="c3"><span class="c1">If you do not want cookies to be set on your device, you can adjust the setting of your Internet browser to reject the setting of all or some cookies and to alert you when a cookie is placed on your device. For further information about how to do so, please refer to your browser &lsquo;help&rsquo; / &lsquo;tool&rsquo; or &lsquo;edit&rsquo; section or see http://www.allaboutcookies.org . Please note that if you use your browser settings to block all cookies (including strictly necessary cookies) you may not be able to access or use all or parts or functionalities of this site.</span>
    </p>
    <p class="c3"><span class="c1">If you want to remove previously-stored cookies, you can manually delete the cookies at any time. However, this will not prevent this site from placing further cookies on your device unless and until you adjust your Internet browser setting as described above.</span>
    </p>
    <p class="c3"><span class="c1">For more information on the development of user-profiles and the use of targeting/advertising cookies, please see http://www.youronlinechoices.eu if you are located in Europe or http://www.aboutads.info/choices if in the United States.</span>
    </p>
    <h2>4. Contact us</h2>
    <p class="c3"><span class="c1">If you have any other questions about our cookie handling practices, please contact us at: </span>
    </p>
    <p class="c3"><span class="c1">Privacy Team</span></p>
    <p class="c3"><span class="c1">BiziBAZA<br></span><span class="c1">3527 Mt. Diablo Blvd #129., </span></p>
    <p class="c3"><span class="c1">Lafayette, CA 94549</span></p>
    <p class="c3"><span class="c1">United States of America</span></p>
    <p class="c3"><span class="c1">Or email us at:&nbsp;</span></p>
    <p class="c3"><span class="c2"><a class="c26" href="mailto:support@bizibaza.com">support@bizibaza.com</a></span></p>
    <!--<p class="c3"><span class="c2">back to top </span><span class="c6">&uarr;</span></p>-->
</div>



                </div>

                <div [ngClass]="{'modal__btns__row reverse-buttons': context.reverseButtons}">
                    <button [class]="context.lastButtonText || context.tooLastButtonText ? 'modal__btn modal__btn-first' :'modal__btn'"
                            data-test-id="yesButton" *ngIf="context.yesButtonText"
                            (click)="onClick(true, context.locationBack)">{{context.yesButtonText}}</button>
                    <button [class]="context.lastButtonText || context.tooLastButtonText ? 'modal__btn modal__btn-last' :'modal__btn'"
                            data-test-id="noButton"
                            *ngIf="context.noButtonText"
                            (click)="onClick(false)">{{context.noButtonText}}</button>
                    <button class="modal__btn modal__btn-last" data-test-id="lastButton"
                            *ngIf="context.lastButtonText"
                            (click)="onClick('last')">{{context.lastButtonText}}</button>
                    <button class="modal__btn modal__btn-last" data-test-id="tooLastButtonText"
                            *ngIf="context.tooLastButtonText"
                            (click)="onClick('tooLast')">{{context.tooLastButtonText}}</button>
                </div>
                
            </div>
        </div>
    