import { HttpClient }   from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import { ErrorService }     from './error.service';
import {CenterLocationInterface} from '../interfaces/location.interface';
import {UserService} from '../user/user.service';
import {Observable} from 'rxjs/Observable';
import {catchError, map, tap} from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';

export interface BrowserLocationInterface {
    coords: {
        longitude: number;
        latitude: number;
    };
}

@Injectable()
export class GeoLocationService {
    locationEvent$:         EventEmitter<CenterLocationInterface>;
    location: CenterLocationInterface;
    constructor(
        private http: HttpClient,
        private errorService: ErrorService,
        private translate: TranslateService,
        private userService: UserService ) {
        this.locationEvent$ = new EventEmitter();
    }

    getGeolocation() {
        this.getLocation(location => {
            const userLocation = this.getBrowserCoordinates(location);

            this.setLocation(userLocation);
        }, (error) => {

            this.ipLookUp().subscribe((res: CenterLocationInterface) => {
                this.setLocation(res);

            }, (err: any) => {
                this.setLocation(this.getDefaultUserLocation());
            });
        });
    }

    setLocation(location: CenterLocationInterface) {
        const userCoords: CenterLocationInterface = {
            longitude: 0,
            latitude: 0
        };
        if (typeof location !== 'undefined' && isNaN(location.latitude) && isNaN(location.longitude)) {
            this.location = userCoords;
        } else {
            this.location = location;
        }

        this.locationEvent$.emit(this.location);
    }


    geoLocationObserver(): Observable<CenterLocationInterface> {
        return new Observable(observer => {
            this.getLocation(location => {
                observer.next(this.getBrowserCoordinates(location));
            }, (error) => {

                this.ipLookUp().subscribe((res: CenterLocationInterface) => {
                    observer.next(res);

                }, (err: any) => {
                    observer.next(this.getDefaultUserLocation());
                });
            });
        });
    }

    getBrowserCoordinates(location: BrowserLocationInterface): CenterLocationInterface {
        if (typeof location === 'undefined' || location && !location.coords) {
            return {
                longitude: 0,
                latitude: 0
            };
        }
        return {
            longitude: location.coords.longitude,
            latitude: location.coords.latitude
        };
    }
    /**
     * getting the coordinates of the device from the browser
     * @param successCallback
     * @param errorCallback
     * @returns {any}
     */
    getLocation(successCallback, errorCallback): any {
        const options = {
            maximumAge: 30000,
            timeout: 5000,
            enableHighAccuracy: false
        };

        if (navigator.geolocation) {
            return navigator.geolocation
                .getCurrentPosition(successCallback, errorCallback, options);
        } else {
            return errorCallback(this.translate.instant('geolocation.error.support.browser'));
        }
    }

   /**
     * getting distance(miles) between the two coordinates
     * @param lat1
     * @param lon1
     * @param lat2
     * @param lon2
     * @returns {number}
     */
    getDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const radlat1 = Math.PI * lat1 / 180;
        const radlat2 = Math.PI * lat2 / 180;
        const theta = lon1 - lon2;
        const radtheta = Math.PI * theta / 180;
        const dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

        const distance = Math.round(Math.acos(dist) * 180 / Math.PI * 60 * 1.1515);

        return distance;
    }

    /**
     * Method for receive geolocation (coordinates) by the user,
     * if the browser did not provide the coordinates
     * @returns {Observable<any>}
     */
    ipLookUp() {
        return this.http
            .get('https://extreme-ip-lookup.com/csv/')
            .map((res: any) => {
                const parsed = res._body.split(',');

                const coords = {
                    longitude:  parseFloat(parsed[12]),
                    latitude:   parseFloat(parsed[11])
                };
                return coords;
            })
            .catch(this.errorService.handleHttpError);
    }


    /**
     * Method for receive user coordinates from server
     * (coordinates specified when registering the user)
     * @returns {CenterLocationInterface}
     */
    getDefaultUserLocation(): CenterLocationInterface {
        let coords: number[] = [0, 0];
        if (this.userService.isUserAvailable &&
            this.userService.getUser().loc &&
            typeof this.userService.getUser().loc !== 'undefined') {

            let userCoords: [number, number] = this.userService.getUser().loc.coords || this.userService.getUser().loc.coordinates;
            if (typeof userCoords !== 'undefined' && !isNaN(userCoords[0]) && !isNaN(userCoords[1])) {
                coords = userCoords;
            }
        }
        return {
            longitude: coords[0] || 0,
            latitude: coords[1] || 0
        };
    }

    /**
     * getValidLongitude and getValidLatitude method returns the coordinate
     * if valid and the maximum or minimum (closest) value of the coordinate
     * if the coordinate does not fall within the valid rang.
     * @param {number} longitude
     * @return {number}
     */
    public getValidLongitude(longitude: number): number {
        if (this.isLongitude(longitude)) {
            return longitude;
        }
        return this.getClosestLongitude(longitude);
    }
    public getValidLatitude(latitude: number): number {
        if (this.isLatitude(latitude)) {
            return latitude;
        }
        return this.getClosestLatitude(latitude);
    }

    /**
     * This method checks the validity of the coordinates.
     * -90  <= Latitude  >= 90
     * -180 <= Longitude >= 180
     *
     * The Number.isFinite() method determines whether the passed value is a finite number — that is,
     * it checks that a number is neither positive nor negative Infinity.
     * The Math.abs() function returns the absolute value of a number.
     * @param {number} num
     * @return {boolean}
     */
    public isLatitude(num: number): boolean {
        return isFinite(num) && Math.abs(num) <= 90;
    }

    public isLongitude(num: number): boolean {
        return isFinite(num) && Math.abs(num) <= 180;
    }

    /**
     * If the value (of Longitude) is greater than
     * the valid value for longitude,
     * then return the one closest to the valid value.
     * @param {number} goal
     * @return {number}
     */
    public getClosestLongitude(goal: number): number {
        const counts = [-180, 180];

        return this.getClosest(goal, counts);
    }

    /**
     * If the value (of Latitude) is greater than
     * the valid value for latitude,
     * then return the one closest to the valid value.
     * @param {number} goal
     * @return {number}
     */
    public getClosestLatitude(goal: number): number {
        const counts = [-90, 90];

        return this.getClosest(goal, counts);
    }

    /**
     * Find the closest value in array using reduce()
     * The easiest way to do this is to use Math. abs(),
     * so lets use that. With this function we check whether the absolute value of (b – 8) is less
     * than the absolute value of (a – 8) and then return the winner
     *
     * @param {number} goal
     * @param {number[]} counts
     * @return {number}
     */
    public getClosest(goal: number, counts: number[]): number {
        return counts.reduce((prev: number, curr: number) =>
            (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev)
        );
    }
}
