var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { ComponentRef, ComponentFactoryResolver, OnInit, ViewContainerRef, ElementRef, Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import { EventSearchComponent } from './search-lists/event-search.component';
import { GeoLocationService } from '../services/geolocation.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { ItemSearchComponent } from './search-lists/item-search.component';
import { MarketSearchComponent } from './search-lists/market-search.component';
import { SearchType, } from '../interfaces/search.interface';
import { AppSearchService } from './search.service';
import { ModalService } from '../modal/modal.service';
import { SellerSearchComponent } from './search-lists/user-search.component';
import { UsersListComponent } from './search-lists/users-list.component';
import { ItemService } from '../services/item.service';
import { ProductSearchListComponent } from './search-lists/product-search-list.component';
import { AppRouteValues } from "../common/app.route.values";
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { ProductDetailsService } from "../product-details/product-details.service";
var SearchStrategyComponent = /** @class */ (function (_super) {
    __extends(SearchStrategyComponent, _super);
    function SearchStrategyComponent(elementRef, componentFactoryResolver, geoLocationService, googleAnalyticsService, productDetailsService, injector, modalService, translate, searchService) {
        var _a, _b;
        var _this = _super.call(this) || this;
        _this.elementRef = elementRef;
        _this.componentFactoryResolver = componentFactoryResolver;
        _this.geoLocationService = geoLocationService;
        _this.googleAnalyticsService = googleAnalyticsService;
        _this.productDetailsService = productDetailsService;
        _this.injector = injector;
        _this.modalService = modalService;
        _this.translate = translate;
        _this.searchService = searchService;
        _this.mappings = (_a = {},
            _a[SearchType.Active] = ProductSearchListComponent,
            _a[SearchType.Event] = EventSearchComponent,
            _a[SearchType.Item] = ItemSearchComponent,
            _a[SearchType.Market] = MarketSearchComponent,
            _a[SearchType.Seller] = SellerSearchComponent,
            _a[SearchType.Sale] = ItemSearchComponent,
            _a[SearchType.User] = UsersListComponent,
            _a);
        _this.mappers = (_b = {},
            _b[SearchType.Active] = function (r) { return _this._mergeRusultItems(r.data.items); },
            _b[SearchType.Item] = function (r) {
                return {
                    nearest: _this._calcDistances(r.data.nearest_items, _this.searchService.userLocation),
                    other: _this._calcDistances(r.data.items, _this.searchService.userLocation)
                };
            },
            _b[SearchType.User] = function (r) { return r.data.users; },
            _b[SearchType.Seller] = function (r) { return r.data.sellers; },
            _b[SearchType.Sale] = function (r) {
                return {
                    nearest: _this._calcDistances(r.data.nearest_items, _this.searchService.userLocation),
                    other: _this._calcDistances(r.data.items, _this.searchService.userLocation)
                };
            },
            _b);
        _this._w = window; // todo: pagination
        return _this;
    }
    SearchStrategyComponent.prototype.ngOnInit = function () {
        this.local_pathname = decodeURIComponent(this._w.location.pathname);
        this.local_search = decodeURIComponent(this._w.location.search);
        if (!this.local_search) {
            this.searchService.is_key_search = true;
        }
        this.userLocationForSearch = this.searchService.userGeolocation();
        this._setData(this.local_pathname);
        this.is_key_search = this.searchService.is_key_search;
        this.searchService.addToLocalCaching();
        this.searchListFilter = this.filters;
        this.changeFilterArguments(this.searchResult.type);
    };
    Object.defineProperty(SearchStrategyComponent.prototype, "filters", {
        get: function () {
            var filters = new Array;
            filters.push(this.searchFilter);
            return filters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchStrategyComponent.prototype, "searchFilter", {
        get: function () {
            return [
                { value: SearchType.Item, title: this.translate.instant('Product'), active: true },
                { value: SearchType.Sale, title: this.translate.instant('Sale'), active: false },
                { value: SearchType.Seller, title: this.translate.instant('Seller'), active: false },
                { value: SearchType.Market, title: this.translate.instant('Market'), active: false },
            ];
        },
        enumerable: true,
        configurable: true
    });
    SearchStrategyComponent.prototype._setData = function (pathname) {
        var cachedData = Object.keys(this.searchService.cachedData());
        this._preparingData();
        if (!cachedData.length) {
            if (pathname === "/" + AppRouteValues.usersList) {
                this._resetSearchResult(SearchType.User);
            }
            else if (pathname === "/" + AppRouteValues.routeSearch) {
                this._search(this.searchResult.keyWord);
            }
            else {
                this._resetSearchResult(SearchType.Item);
            }
        }
        else {
            if (pathname === "/" + AppRouteValues.usersList) {
                this._setSearchType(SearchType.User);
                this._search(this.searchResult.keyWord);
            }
            else {
                var uniqItemFromCache = this.searchService.getUniqSearchItemFromCache(window.location.href);
                var keyWord = this.searchService.getLastSearchResult().keyWord || '';
                var typeOfSearch = this.searchService.getLastSearchResult().type || SearchType.Item;
                if (pathname === "/" + AppRouteValues.routeSearch && this.searchService.prevUrl.includes(AppRouteValues.routeProductDetails)) {
                    this.searchService.getLastSuccessSearchData();
                }
                if (!Object.keys(uniqItemFromCache).length && typeOfSearch === SearchType.Item) {
                    this._search(keyWord);
                    this.searchService.addToLocalCaching();
                }
                else {
                    this._preparingData();
                    this._renderResults();
                }
                this.searchService.getSearchFrom();
            }
        }
    };
    /**
     * Asynchronous method receive geolocation (coordinates) by the user and set in userLocation
     * @return {Promise<CenterLocationInterface>}
     */
    SearchStrategyComponent.prototype.getGeolocation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.modalService.showSpinner();
                        return [4 /*yield*/, this._preparingData()];
                    case 1:
                        _a.sent();
                        this.locationPromise = new Promise(function (resolve) {
                            _this.geoLocationService.getLocation(resolve, function (error) {
                                _this.trackSubscription(_this.geoLocationService.ipLookUp()
                                    .subscribe(function (res) { return _this._preparingResults(res); }, function (err) { return _this._preparingResults(_this.geoLocationService.getDefaultUserLocation()); }));
                            });
                        });
                        this.locationPromise.then(function (location) {
                            var userLocation = {
                                longitude: location['coords'].longitude,
                                latitude: location['coords'].latitude
                            };
                            _this._preparingResults(userLocation);
                        });
                        return [2 /*return*/, this.locationPromise];
                }
            });
        });
    };
    /**
     * get data from getResults and set into searchResult
     * @private
     */
    SearchStrategyComponent.prototype._preparingData = function () {
        this.searchResult = this.searchService.getResults();
    };
    /**
     * set user's coordinates and go to _renderResults's method
     * @param {{}} coords
     * @private
     */
    SearchStrategyComponent.prototype._preparingResults = function (coords) {
        this.searchService.setUserLocation(coords);
        if (Object.keys(this.searchResult.data).length) {
            this._renderResults();
        }
        this.modalService.close();
    };
    SearchStrategyComponent.prototype._renderResults = function () {
        this.container.remove();
        var type = this.searchResult.type;
        var component = this.mappings[type];
        var factory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef = this.container.createComponent(factory);
        var instance = this.componentRef.instance;
        instance.items = this._sortData(this._dataMapper(), this.sortBy);
        this._preparingAnalytic(this.searchResult);
        instance.search_from = this.searchService.search_from;
        this.searchService.addToLocalCaching();
        if (this.searchResult.keyWord !== '' && this.searchService.search_from && this.searchService.search_from !== '') {
            this.googleAnalyticsService.handleEvent('successful_search_by_' + this.searchService.search_from, 'search', 'search_from_' + this.searchService.search_from);
            this.googleAnalyticsService.handleVirtualPage('search_from_' + this.searchService.search_from + '_open');
        }
    };
    // TODO change request
    SearchStrategyComponent.prototype._dataMapper = function () {
        var r = this.searchResult;
        if (!Object.keys(r.data).length)
            return [];
        return this.mappers[r.type]
            ? this.mappers[r.type](r)
            : r.data.markets;
    };
    SearchStrategyComponent.prototype._resetSearchResult = function (type) {
        this.searchResult = Object.assign({
            foundNum: 0,
            keyWord: '',
            data: {},
            type: type
        });
    };
    SearchStrategyComponent.prototype._setSearchType = function (type) {
        this.searchResult = __assign({}, this.searchResult, { type: type });
    };
    SearchStrategyComponent.prototype.onOptionsChange = function (options) {
        if (options.map) {
            if (!this.searchService.userLocation) {
                this.getGeolocation().then(function (res) {
                    return;
                });
            }
            return;
        }
        this.container.remove();
        if (!options.sortBy && this.searchResult.type === SearchType.User) {
            this._search(options.keyWord);
            return;
        }
        if (options.type) {
            this.changeFilterArguments(options.type);
            this._setEmptyList(options.type);
        }
        if (options.keyWord) {
            this.searchService.is_key_search = true;
            this.googleAnalyticsService.handleForSearchByType('key_word_attempt', options.keyWord);
            this.searchResult.keyWord = options.keyWord;
            this._search(options.keyWord);
        }
        if (!options.type && options.keyWord === '') {
            this._setEmptyList(this.searchResult.type);
        }
        if (options.type && options.sortBy === 'unsorted') {
            this.sortBy = 'unsorted';
            var sortByElementRef = this.elementRef.nativeElement.querySelector('select[name="sortBy"]');
            if (sortByElementRef) {
                sortByElementRef.value = 'unsorted';
            }
        }
        else if (options.sortBy) {
            this._sortResult(options.sortBy);
        }
    };
    SearchStrategyComponent.prototype.changeFilterArguments = function (sortByValue) {
        var _this = this;
        this.searchListFilter.map(function (filters) {
            var reseted_filters = _this.resetFilterByValue(filters, sortByValue);
            return _this.setActiveFilterByValue(reseted_filters, sortByValue);
        });
    };
    SearchStrategyComponent.prototype.resetFilterByValue = function (filters, sortByValue) {
        filters.forEach(function (filter) {
            if (filter.value === sortByValue) {
                filters.forEach(function (f) {
                    f.active = false;
                });
            }
        });
        return filters;
    };
    SearchStrategyComponent.prototype.setActiveFilterByValue = function (filters, sortByValue) {
        filters.forEach(function (filter) {
            if (filter.value === sortByValue) {
                filter.active = true;
            }
        });
        return filters;
    };
    /**
     * Clears all previously results
     * @param {string} type
     * @private
     */
    SearchStrategyComponent.prototype._setEmptyList = function (type) {
        this._resetSearchResult(type);
        this.searchService.clearResults(this.searchResult.type);
    };
    SearchStrategyComponent.prototype._sortResult = function (sortBy) {
        this.sortBy = sortBy;
        this._renderResults();
    };
    /**
     * Method for returning array of parent items
     * @param {SearchResultItems[]} items
     * @returns {any[]}
     * @private
     */
    SearchStrategyComponent.prototype._mergeRusultItems = function (items) {
        var _this = this;
        var allItem;
        var itemsList = __spread(items);
        var parentItems = itemsList.filter(function (i) { return i.is_parent; });
        if (parentItems.length) {
            parentItems.forEach(function (item) {
                item.nestedMarkets = _this._getNestedMarkets(itemsList, item);
            });
            allItem = parentItems;
        }
        else
            allItem = items;
        return allItem;
    };
    SearchStrategyComponent.prototype._getNestedMarkets = function (items, parent) {
        var childMarkets = items.filter(function (i) { return parent.ID === i.parent_id; });
        var nestedMarkets = this._calcDistances(childMarkets, this.searchService.userLocation);
        return nestedMarkets.sort(function (a, b) {
            return a.distance - b.distance;
        });
    };
    SearchStrategyComponent.prototype._calcDistances = function (items, userLocation) {
        var _this = this;
        items.forEach(function (item) {
            if (item.loc) {
                item.distance = _this.geoLocationService.getDistance(userLocation.latitude, userLocation.longitude, item.loc.coordinates[1], item.loc.coordinates[0]);
            }
        });
        return items;
    };
    SearchStrategyComponent.prototype._sortData = function (data, sortBy) {
        if (Array.isArray(data)) {
            return data = (!sortBy)
                ? data
                : this._sortArr(this._priceForSorting(data), sortBy);
        }
        for (var key in data) {
            data[key] = this._sortArr(this._priceForSorting(data[key]), sortBy);
        }
        return data;
    };
    /**
     * for sorting Selling Item by price
     * @param {SellingItemResponseBody[]} data
     * @return {SellingItemResponseBody[]}
     * @private
     */
    SearchStrategyComponent.prototype._priceForSorting = function (data) {
        var _this = this;
        data.forEach(function (i) {
            if (_this.itemService.isSaleFn(i)) {
                i.price = i.salePrice;
            }
        });
        return data;
    };
    /**
     * sorting method by the user desire
     * @param {[{}]} arr
     * @param {string} sortBy
     * @returns {any}
     * @private
     */
    SearchStrategyComponent.prototype._sortArr = function (arr, sortBy) {
        var callback;
        if (!sortBy)
            return arr;
        switch (sortBy) {
            case 'rate':
                callback = function (a, b) { return b.rate - a.rate; };
                break;
            case 'ascending':
                callback = function (a, b) { return a.price - b.price; };
                break;
            case 'descending':
                callback = function (a, b) { return b.price - a.price; };
                break;
            default:
                callback = function () { return true; };
        }
        return arr.sort(callback).concat([]);
    };
    SearchStrategyComponent.prototype._search = function (keyWord) {
        var _this = this;
        this.trackSubscription(this.searchService.search(keyWord.trim(), this.searchResult.type, { isNew: true })
            .subscribe(function (res) {
            _this.googleAnalyticsService.handleForSearch(keyWord);
            _this.searchResult = res;
            _this._renderResults();
            _this.googleAnalyticsService.handleViewSearchResults(keyWord);
            _this.productDetailsService.setProductDetailsFrom('search_page');
        }, function (err) {
            _this._resetSearchResult(_this.searchResult.type);
            return Observable.throwError(err);
        }));
    };
    SearchStrategyComponent.prototype._preparingAnalytic = function (result) {
        if (result.foundNum === 0)
            return;
        this.searchService.getSearchFrom();
    };
    SearchStrategyComponent.prototype.isAdminMode = function () {
        return this.searchService.isAdminMode();
    };
    Object.defineProperty(SearchStrategyComponent.prototype, "itemService", {
        get: function () {
            return this.injector.get(ItemService);
        },
        enumerable: true,
        configurable: true
    });
    return SearchStrategyComponent;
}(UnsubscribeOnDestroyAbsctractClass));
export { SearchStrategyComponent };
// Will be useful for pagination
// fetchMore(offset: number) { return;
//     // this.searchService.search(this.keyWord, this.type, {offset} as any);
// }
// updateResults(res: SearchResultInterface|any) {
//     // this.noResults  = true;
//     // let res = this.searchService.getResults();
//
//     if (typeof res === 'undefined') {
//         this.keyWord        = '';
//         this.searchResult   = null;
//         this.type           = 'item';
//
//         return;
//     }
//
//     this.keyWord        = res.keyWord;
//     this.searchResult   = res.data;
//     this.type           = res.type;
//
//     if (Object.keys(res.data['items'] || {}).length ||
//         Object.keys(res.data['users'] || {}).length ||
//         Object.keys(res.data['markets'] || {}).length ||
//         Object.keys(res.data['nearest_items'] || {}).length) {
//         // this.noResults = false;
//     }
//
//     // this.numOfFound = (this.type === 'item')
//     //     ? this.searchResult.items.length + this.searchResult.nearest_items.length
//     //     : this.searchResult.markets.length;
// }
