/**
 * BiziBaza app
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PayeeAccount { 
    /**
     * Name of payment system
     */
    payment_system: PayeeAccount.PaymentSystemEnum;
    /**
     * Account ID in payment system
     */
    external_id: string;
}
export namespace PayeeAccount {
    export type PaymentSystemEnum = 'stripe' | 'fake';
    export const PaymentSystemEnum = {
        Stripe: 'stripe' as PaymentSystemEnum,
        Fake: 'fake' as PaymentSystemEnum
    };
}