/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-footer.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./category-selector.component";
import * as i4 from "./app-footer.service";
var styles_CategorySelectorComponent = [i0.styles];
var RenderType_CategorySelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategorySelectorComponent, data: {} });
export { RenderType_CategorySelectorComponent as RenderType_CategorySelectorComponent };
function View_CategorySelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "select-category text-center"]], [[4, "color", null], [1, "data-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showCategory(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0], [1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.category_name_text_color; var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.ID, ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.category_image, ""); var currVal_3 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.category_name, ""); var currVal_4 = _v.context.$implicit.category_name; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.category_name; _ck(_v, 3, 0, currVal_5); }); }
function View_CategorySelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "footer__popup footer__category"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "footer__popup__category"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategorySelectorComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categories; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CategorySelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategorySelectorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categories; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CategorySelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "category-selector", [], null, null, null, View_CategorySelectorComponent_0, RenderType_CategorySelectorComponent)), i1.ɵdid(1, 638976, null, 0, i3.CategorySelectorComponent, [i4.AppFooterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CategorySelectorComponentNgFactory = i1.ɵccf("category-selector", i3.CategorySelectorComponent, View_CategorySelectorComponent_Host_0, { mode: "mode" }, { showCategoryEvent$: "showCategoryEvent$" }, []);
export { CategorySelectorComponentNgFactory as CategorySelectorComponentNgFactory };
