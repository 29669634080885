import { Component }                    from '@angular/core';

import { ModalService }                 from '../../modal/modal.service';
import { ProductEditorParentClass }     from './product-editor-abstract.class';
import {CancelEditingAction, ProductEditorService} from '../product-editor.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector:       'existing-event-editor',
    styleUrls:      ['../product-editor.sass', '../../../assets/styles/sprite.sass'],
    templateUrl:    './product-editor.component.html'
})
/**
 * Represents editor for an existing event.
 * @extends ProductEditorParentClass.
 */
export class ExistingEventEditorComponent extends ProductEditorParentClass {

    translate: TranslateService;
    isEventEditor = true;

    constructor(
        modalService:           ModalService,
        productEditorService:   ProductEditorService,
        translate:              TranslateService,
    ) {
        super(modalService, productEditorService, translate);
    }

    createTitleText(): void {
        this.title = this.translate.instant("editor.title.event");
    }


    /**
     * Calls the callback with `exitEventEditor` command object.
     */
    onClose(): void {
        this.productEditorService.cancelEditing({cansave: true, event: true}).subscribe((action: CancelEditingAction) => {
            if (action.type === 'save')
               this.onSave();
            if (action.type === 'cancel')
                this.context.callback({ exitEventEditor: null });
        });
    }


    /**
     * Checks the quantities correspondence.
     * @returns {boolean}
     * @private
     */
    _checkQuantities() {
        let doesSatisfy = this.item.qty >= this.item.current_quantity;

        if (!doesSatisfy) this._showWarning(this.translate.instant('event.editor.warning.qty'));

        return doesSatisfy;
    }


    /**
     * Checks the event data and calls the callback with `saveEvent` command object.
     */
    onSave(): void {
        if (!this._isItemConsistent() || !this._checkQuantities())  return;

        this.context.callback({ updateEvent: this.item });
    }
}
