import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { EmailPageAbstractClass } from '../helpers/email-page.abstract.class';
import { ModalService } from '../../modal/modal.service';
import { UserService } from '../user.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'login-link',
  templateUrl: './login-link.component.html'
})
export class LoginLinkComponent  extends EmailPageAbstractClass {

    public email: string = '';
    protected successMsg = this.translate.instant("remindLogin.success");

    constructor(
        protected elementRef:       ElementRef,
        protected modalService:     ModalService,
        protected router:           Router,
        protected translate:        TranslateService,
        private userService:        UserService,
    ) {
        super(elementRef, modalService, router, translate);
    }

    public get descriptionText(): string {
        return this.translate.instant('forgotLogIn.descriptionText');
    }
    public get descriptionTextAboutLink(): string {
        return this.translate.instant('forgotLogIn.descriptionTextAboutLink');
    }

    _sendRequest(): Observable<any> {
        return this.userService.getTemporaryLoginLink(this.email.trim());
    }
}
