import { AfterViewInit, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { RedirectService } from './services/redirect.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { UserService } from './user/user.service';
import { CheckTabStatusService } from './services/check-tab-status';
import { AppFooterService } from './app-footer/app-footer.service';
import { ShoppingListService } from './shopping-list/shopping-list.service';
import { AfterBootService } from './after-boot/after-boot.service';
import { LocalCaching } from './local-caching/local-caching-factory';
import { Observable } from 'rxjs/Rx';
import { GeoLocationService } from './services/geolocation.service';
import { TranslateService } from '@ngx-translate/core';
import { OperationSystemService } from './services/operation.system.service';
import { AppReviewsService } from './reviews/reviews.service';
import { UnsupportedDeviceService } from './unsupported-info/unsupported-device.service';
import { AppSettings } from './common/app.settings';
import { ClientPaymentService } from './payment/payment.service';
import { AppRouteValues, createDirection } from "./common/app.route.values";
import { CacheNotificationService } from "./cache-notification/cache-notification.service";
var AppComponent = /** @class */ (function () {
    function AppComponent(redirectService, router, googleAnalyticsService, injector, shoppingListService, afterBootService, localCaching, operationSystem, translate, cacheNotificationService) {
        this.redirectService = redirectService;
        this.router = router;
        this.googleAnalyticsService = googleAnalyticsService;
        this.injector = injector;
        this.shoppingListService = shoppingListService;
        this.afterBootService = afterBootService;
        this.localCaching = localCaching;
        this.operationSystem = operationSystem;
        this.translate = translate;
        this.cacheNotificationService = cacheNotificationService;
        this.isSearchActivated = false;
        this.hideFooter = true;
        this.app_header__width = '';
        this.impersonation = false;
        this.type = 'guest';
        this.subscriptions$ = [];
        this.isUnsupportedAccount = false;
        this.routesExcludedFromCaching = [
            '/shopping-list',
            '/inventory-list',
            '/product-editor'
        ];
        this.checkStatusOfStripeAccountPaths = [
            '/inventory-list', 'profile'
        ];
        this.noFooterPaths = [
            '/about',
            '/faq',
            '/orders',
            '/addr-editor',
            '/bizipix-editor',
            '/bizipix-viewer',
            createDirection(AppRouteValues.routeCart),
            '/change-password',
            '/create-review',
            '/pending-orders',
            '/chat-room',
            '/dialogs',
            '/email-verification',
            '/goods-nav',
            '/history',
            '/inventory-nav',
            '/inventory-sheet',
            '/login',
            '/password-reset',
            '/login-link',
            '/product-details',
            '/product-editor',
            '/profile',
            createDirection(AppRouteValues.routeSettings),
            '/signup',
            '/success-onboarding',
            '/shopper-details',
            '/map-search',
            '/market',
            '/reviews-item',
        ];
        this.noHeaderGuestPaths = [
            '/bizipix-editor',
            '/bizipix-viewer'
        ];
        this.noPathsForCart = [
            // '/signup',
            // '/login',
            createDirection(AppRouteValues.routeCart)
        ];
    }
    /**
     * Prevent the use of the Landscaping app for mobile devices
     * @param event
     */
    AppComponent.prototype.onOrientationChange = function (event) {
        if (this.operationSystem.isAvailableBrowser()) {
            this.unsupported_device_service.unsupportedBrowser();
        }
        else {
            if (screen.availHeight < screen.availWidth
                && !this.isBizipixUrl() && this.operationSystem.isMobile()) {
                this.unsupported_device_service.unsupportedOrientation();
            }
            else {
                this.unsupported_device_service.close();
            }
            this.unsupportedAccount();
        }
    };
    AppComponent.prototype.ngOnInit = function () {
        // this.googleAnalyticsService.initGtag(gtag);
        var _this = this;
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use('en');
        window.console.info("%c BiziBAZA Web v" + AppSettings.APP_VERSION(), 'background: rgba(142,195,79,0.5); font-size: 16px');
        this.afterBootService.userServiceSave = this.user_service;
        this.geo_location_service.getGeolocation();
        this.subscribeOnSuggestionsSearchActivating();
        this.subscribeOnUserChanges();
        this._setGuestInfo();
        this._getNotificationCounts(this.router.url);
        this.shoppingListService.getAllSuggestionsForShoppingList();
        this.checkRoute(this.router.url, this.router.url);
        // Check status browser tab (open or close tab)
        setInterval(function () { return _this.tab_service.newVisabilityTabMethod(); }, 500);
        setTimeout(function () {
            _this.cacheNotificationService.privacyPolicy();
        }, 1000);
        this.router.events
            .filter(function (event) { return event instanceof NavigationEnd; })
            .subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.redirectService.impersonation_setter();
                _this._setGuestInfo(event.url);
                _this._checkUserStatus();
                _this.redirectService.createReviewLink();
                _this.redirectService.redirectingForLogedInUser(event.url);
                _this.checkStatusOfStripeAccount(event.url);
                _this._scenarioAfterRedirects(event);
                _this._getNotificationCounts(event.url);
                _this.onOrientationChange();
                _this.googleAnalyticsService.handleScreen(event.url);
                _this.googleAnalyticsService.handlePageViews(event.url);
            }
        });
        this.routeScenario();
        this.impersonationRouteScenario();
        this.user_service.userAuthEvent$.subscribe(function (user) {
            if (user)
                _this.unsupportedAccount();
        });
        this.user_service.onLoginGuestChanges.subscribe(function (user) {
            _this.unsupported_device_service.close();
        });
        this.payments_service.onShowModalUnsupportedAccountChanges().subscribe(function (showModal) {
            _this.isUnsupportedAccount = showModal;
        });
        this.user_service.onLogoutUserChanges().subscribe(function (isLogout) {
            _this.isUnsupportedAccount = !isLogout;
        });
    };
    /**
     * If the admin chose impersonate from the list of users,
     * and then returned to the users-list page,
     * there should be a redirect to the settings page
     * where you can log out from the impersonate state
     */
    AppComponent.prototype.impersonationRouteScenario = function () {
        var _this = this;
        this.router.events
            .filter(function (e) { return e instanceof RoutesRecognized; })
            .pairwise()
            .subscribe(function (event) {
            // previous url events[0].url
            // current  url events[1].url
            if (_this.user_service.isImpersonation && event[1].url === '/users-list' && event[0].url !== '/users-list') {
                _this.router.navigate([createDirection(AppRouteValues.routeSettings)]);
                return;
            }
        });
    };
    AppComponent.prototype.routeScenario = function () {
        var _this = this;
        this.router.events
            .filter(function (e) { return e instanceof RoutesRecognized; })
            .pairwise()
            .subscribe(function (events) {
            // previous url events[0].url
            // current  url events[1].url
            _this.reviewUrlActionScenario(events[0].url, events[1].url);
        });
    };
    AppComponent.prototype.reviewUrlActionScenario = function (previousUrl, currentUrl) {
        if (currentUrl === '/reviews') {
            if (previousUrl.includes('/product-details') || previousUrl === '/history' || previousUrl === createDirection(AppRouteValues.routeSettings)) {
                return;
            }
            else if (previousUrl.includes('reviews-item') || previousUrl.includes('create-review')) {
                this.reviews_service.selectPurchasesFilterTab();
            }
            else {
                this.reviews_service.backToHome();
            }
        }
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.afterBootService.handleResolver();
        setTimeout(function () { return _this.onOrientationChange(); }, 1000);
    };
    /**
     * @desc System shall show badge on Shopping list page after
     * login for Buyer&Seller BiziBAZA account
     * if Stripe account is in NOT_CREATED or NOT_ONBOARDED state.
     * @private
     */
    AppComponent.prototype.unsupportedAccount = function () {
        if (!this.user_service.isGuestUser && this.user_service.isSellerMode()) {
            this.payments_service.unsupportedAccount();
        }
        if (this.isUnsupportedAccount) {
            this.unsupported_device_service.close();
        }
    };
    /**
     * @desc UIR_PROFILE1 System shall show badge on Profile and Inventory list pages
     * of Buyer&Seller BiziBAZA account if Stripe account
     * is in NOT_ABLE_TO_RECEIVE_TRANSFERS state.
     * @param {string} url
     */
    AppComponent.prototype.checkStatusOfStripeAccount = function (url) {
        var _this = this;
        if (this.checkStatusOfStripeAccountPaths.some(function (item) { return url.toLowerCase().includes(item) && _this.user_service.isSellerMode(); })) {
            this.payments_service.checkStatusOfStripeAccount();
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions$.length > 0) {
            this.subscriptions$.forEach(function (subscription) {
                subscription.unsubscribe();
            });
        }
    };
    AppComponent.prototype._getNotificationCounts = function (url) {
        var _this = this;
        var hideCartForHeader;
        url && (hideCartForHeader = this.noPathsForCart.some(function (item) { return url.toLowerCase().includes(item); }));
        if (hideCartForHeader || !url) {
            this.cart_icon = false;
        }
        else {
            this.cart_icon = true;
            // @ts-ignore
            this.app_footer_service.getCounts()
                .subscribe(function (counts) {
                _this.counts = counts;
            }, function (err) { return Observable.throwError(err); });
        }
    };
    AppComponent.prototype._scenarioAfterRedirects = function (event) {
        this.checkRoute(event.url, event.urlAfterRedirects);
        // if (this.routesExcludedFromCaching.some(item => event.url.toLowerCase().includes(item))) {
        //     this.localCaching.clearAllCache(new ConcreteSearchCaching());
        // }
        if (event.url === "/shopping-list" && this.redirectService.link && this.redirectService.link !== '') {
            this.redirectService.dynamicLinktoProduct();
            var page = this.redirectService.link.split('/')[1];
            this.checkRoute(this.redirectService.link, page);
            this.redirectService.link = '';
            return;
        }
        this.app_header__width = (event.url === "/inventory-sheet") ? "app_header__width_full" : "";
    };
    Object.defineProperty(AppComponent.prototype, "user_service", {
        /***
         * @desc This function creates 'user service' property on your service.
         * @return {UserService}
         */
        get: function () {
            return this.injector.get(UserService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "tab_service", {
        get: function () {
            return this.injector.get(CheckTabStatusService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "app_footer_service", {
        get: function () {
            return this.injector.get(AppFooterService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "geo_location_service", {
        get: function () {
            return this.injector.get(GeoLocationService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "unsupported_device_service", {
        get: function () {
            return this.injector.get(UnsupportedDeviceService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "reviews_service", {
        get: function () {
            return this.injector.get(AppReviewsService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "payments_service", {
        get: function () {
            return this.injector.get(ClientPaymentService);
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.isBizipixUrl = function () {
        return (this.router.url === '/bizipix-editor' || this.router.url === '/bizipix-viewer');
    };
    AppComponent.prototype._checkUserStatus = function () {
        if (this.redirectService.impersonation_user) {
            var impersonation_exist = (Object.keys(this.redirectService.impersonation_user).length !== 0);
            var impersonation = (Object.keys(this.redirectService.impersonation).length !== 0);
            if (impersonation_exist && impersonation) {
                this.userNameImpersonation = this.redirectService.impersonation_user.firstName;
                this.impersonation = true;
            }
            else
                this.impersonation = false;
        }
    };
    AppComponent.prototype._setGuestInfo = function (url) {
        if (url && this.noHeaderGuestPaths.some(function (item) { return url.toLowerCase().includes(item); })) {
            this.userName = undefined;
            this.type = undefined;
            this.order_icon = false;
            return;
        }
        this.setUserInfo(this.user_service.getUser());
    };
    AppComponent.prototype.checkRoute = function (url, urlAfterRedirects) {
        if (this.redirectService.link && this.redirectService.link !== '' && url.split('/')[1] === 'product-details') {
            url = url.split('/')[1];
            url = '/' + url;
        }
        this.hideFooter = this.noFooterPaths.some(function (item) { return url.toLowerCase().includes(item); });
    };
    AppComponent.prototype.subscribeOnSuggestionsSearchActivating = function () {
        var _this = this;
        this.subscriptions$.push(this.shoppingListService.suggestionsSearchActivating
            .subscribe(function (value) {
            _this.isSearchActivated = value;
        }));
    };
    AppComponent.prototype.subscribeOnUserChanges = function () {
        var _this = this;
        this.setUserInfo(this.user_service.getUser());
        this.subscriptions$.push(this.user_service.onUserChanges
            .subscribe(function (user) {
            _this.setUserInfo(user);
        }));
    };
    AppComponent.prototype.setUserInfo = function (user) {
        if (!user.ID || user.accessLevel === 0) {
            this.userName = undefined;
            this.type = 'guest';
            this.order_icon = false;
            return;
        }
        this.userName = user.firstName;
        this.type = 'user';
        this.order_icon = true;
    };
    return AppComponent;
}());
export { AppComponent };
