import { Component,
    ElementRef, Input }             from '@angular/core';

import AppValues                    from '../../common/app.values';
import { ImgLoaderComponent }       from '../../shared/img-loader/img-loader.component';
import { ModalService }             from '../../modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import { isValidFileSize } from './user-platform.helpers';

@Component({
    selector:   'image-editor',
    styleUrls:  ['../product-editor.sass', '../../../assets/styles/sprite.sass'],

    template:   `
        <section class="product-editor__title">

            <div class="image-container product-img" data-test-id="uploadImg"
                 [ngClass]="{'background-image': imageUrl}"
                 (click)="!disabled && showUpload()"
                 [style.background-image]="'url('+imageUrl+')'">

                <span *ngIf="!imageUrl" role="productImg" data-test-id="anImage" class="sprite sprite-add_image_icon_2x"></span>
            </div>

            <label class="input__label clearfix" [attr.data-test-id]="dataTestId + 'InnerContainer'">
                <input class="input__text" type="text" [name]="placeholder"
                       [attr.data-test-id]="dataTestId"
                       [placeholder]="placeholder"
                       [value]="text"
                       [disabled]="disabled"
                       (blur)="finishRenaming({event: $event, message: message})"
                       (focus)="startRenaming({event: $event})">
            </label>

            <input name="photo" (change)="onImgUpload($event.currentTarget)" type="file" accept="imageTypes" data-test-id="fileInp">
            <span role="editable" style="display:none"></span>

        </section>
    `
})
export class ImageEditorComponent extends ImgLoaderComponent {
    @Input()    placeholder:    string;
    @Input()    dataTestId:     string;
    @Input()    disabled        = false;
    @Input()    message:        string;

    /**
     * Widget of a text input and image upload.
     * @extends ImgLoaderComponent.
     * @emits changeEvent$ when text changed or image is ready to upload.
     */
    fileInput:  HTMLInputElement;
    public imageTypes: string = AppValues.imageTypes;

    constructor(
        elementRef:     ElementRef,
        modalService:   ModalService,
        translate:      TranslateService,
    ) {
        super(elementRef, modalService, translate);
    }



    /**
     * Simulates click on the file input, to show browser file select dialog.
     */
    showUpload() {
        this.fileInput = this.elementRef.nativeElement.querySelector('input[name="photo"]');
        this.fileInput.click();
    }



    /**
     * Handles image upload.
     * @emits changeEvent$ on proper image file.
     */
    onImgUpload(event: EventTarget) {
        if (this.fileInput.files.length) {
            let file = this.fileInput.files[0];

            if (!isValidFileSize(file.size)) {
                this.modalService.error({
                    title: this.translate.instant('modal.error.uploadImage.title'),
                    message: this.translate.instant('modal.error.uploadImage.message'),
                    yesButtonText: this.translate.instant('modal.error.uploadImage.confirm'),
                });
                return false;
            }
            this.changeEvent$.emit({imageFile: file});

            setTimeout(() => {
                this.resetInput(event);
            });
        }
    }

    resetInput(event: EventTarget) {
        event['value'] = '';

        if (!/safari/i.test(navigator.userAgent)) {
            event['type'] = '';
            event['type'] = 'file';
        }
    }


    /**
     * Checks if the value doesn't contains inappropriate symbols
     * @private
     * @param value
     * @returns {boolean}
     */
    _isValid(value: string): boolean {
        return !AppValues.titleAntiPattern.test(value);
    }
}
