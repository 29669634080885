
        <div class="component">
            <cart-header 
                (googleAnalyticClickButtonsEvent$)="clickAnyButtons($event)"
                [count]="cart?.items?.length"
            ></cart-header>

            <div [class]="(cart && cart.items?.length) ? 'cart__container component__container' : 'empty-cart__container component__container'">

                <h3 *ngIf="(!cart || !cart.items || cart.items.length === 0)" class="empty-list">{{ "cart.text.empty" | translate }}</h3>

                <div *ngIf="cart && cart.items && cart.items.length" class="cart-item__container">
                    <cart-item
                        *ngFor="let item of cart.items; let i = index;"
                        [testIndex]="i"
                        [data]="item"
                        [expiresIn]="item['_expiresInFormatted']"
                        (itemChange$)="handleChange($event)"
                        (removeItem$)="removeItem($event)"
                        (deliveryChange$)="deliveryChange($event)">
                    </cart-item>
                    <div id="coupon-block" *ngIf="cart && cart.items && cart.items.length">
                        <div class="cart-item">
                            <div *ngIf="!isDisabledPayment" [class]="'cart-item__coupon ' + warning">
                                <input type="text" name="coupon" data-test-id="coupon-input"
                                       minlength="6" maxlength="30" [value]="coupon_code"
                                       (focus)="onFocus($event)"
                                       (keypress)="onKeyUpInput($event)"
                                       (input)="onKeyUpInput($event)" placeholder="Enter discount coupon code"
                                       (blur)="onBlurInput($event)"
                                       (paste)="true">
                                <div class="cart-item__coupon__actions" *ngIf="coupon_code.length > 0">
                                     <button (click)="removeCouponCode()"
                                        class="remove-coupon-code-button"
                                        data-test-id="RemoveCouponCode">
                                    <div class="icon-cancel-circled-2"></div>
                                    </button>
                                    <span data-test-id="coupon-warning">
                                        <i [class]="warning === 'valid' ? 'icon-ok' : ''"></i>
                                        <i [class]="warning === 'invalid' ? 'icon-attention-alt' : ''"></i>
                                        {{couponValidation}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="cart?.discount && cart?.discount?.seller_subtotal_discount_list">
                            <div class="cart-item" *ngFor="let discount of cart.discount.seller_subtotal_discount_list; let idx1 = index" [attr.data-test-id]="'cartCouponDiscount_'+ idx1">
                                <div class="cart-item__coupon-applied">
                                    <div class="cart-item__subtotal-with-coupon cart_coupon_bold_text">
                                        <h4>{{ "cart.text.seller" | translate }}</h4>
                                        <div class="cart-item__subtotal__sellerName">
                                            <p [attr.data-test-id]="'cartDiscountItemSellerName_'+ idx1">
                                                {{ discount.sellerName }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cart-item__coupon-applied">
                                    <div class="cart-item__discount-absolute-value">
                                        <h4>{{ "cart.text.seller.subtotal" | translate }}</h4>
                                        <div>
                                            <p [attr.data-test-id]="'cartDiscountItemSellerSubtotal_'+ idx1">
                                                {{discount.subtotal / 100 | currency:'USD' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cart-item__coupon-applied">
                                    <div class="cart-item__discount-absolute-value">
                                        <h4>{{ "cart.text.discount" | translate }}</h4>
                                        <div>
                                            <p [class]="discount.discount_absolute_value > 0 ? '' : 'warning'"
                                               [attr.data-test-id]="'cartDiscountItem_'+ idx1">
                                                {{discount.discount_absolute_value / 100 | currency:'USD' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="cart?.discount" class="cart-item cart_coupon_bold_text">
                            <div class="cart-item__coupon-applied">
                                <div class="cart-item__subtotal-with-coupon">
                                    <h4>{{ "cart.text.subtotal" | translate }}</h4>
                                    <div>
                                        <p [attr.data-test-id]="'cartItemSubtotalWithCoupon'">
                                            {{ cart?.discount?.subtotal / 100 | currency:'USD' }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="cart-item__coupon-applied">
                                <div class="cart-item__discount-absolute-value">
                                    <h4>{{ "cart.text.discount" | translate }}</h4>
                                    <div>
                                        <p [attr.data-test-id]="'cartItemDiscountAbsoluteValue'">
                                            {{cart?.discount?.discount_absolute_value / 100 | currency:'USD' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="cart-item__discount-information-msg" data-test-id="perSellerMinimumPriceRequirement">{{ 'shoppinCart.cells.perSellerMinimumPriceRequirement' | translate }}</h4>
                    </div>
                </div>
            </div>


            <div data-test-id="cart-footer" class="cart__footer" *ngIf="cart">

                <div class="cart__total">
                    <div>
                        <div class="cart__total__title">
                            <span class="box-left">{{ "cart.text.total" | translate }}</span>
                            <small data-test-id="numberOfItems">{{numberOfItems | number}} {{ "cart.text.items" | translate }}</small>
                        </div>

                        <h4 data-test-id="cartTotal">{{ cartService.total / 100 | currency:'USD'}}</h4>

                        <button
                            type="button"
                            class="navbar__right nav__link"
                            role="cart"
                            data-test-id="payBtnContainer"
                            (click)="onBuy()"
                            [disabled]="cart.items?.length === 0"
                        >
                            <i class="icon-pay_icon" data-test-id="payBtn"></i>
                        </button>
                    </div>

                </div>

             </div>
        </div>
    