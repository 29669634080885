import { Router } from '@angular/router';
import {AfterBootResolverStrategy, execute} from '../after-boot.strategy';
import {ModalService} from '../../modal/modal.service';
import {UserService} from '../../user/user.service';
import { TranslateService } from '@ngx-translate/core';

export class InvalidSessionResolver implements AfterBootResolverStrategy {
    constructor (private translate?: TranslateService) {}
    public message: string = this.translate ? this.translate.instant('deepLink.error.invalidSession') : '';

   public resolveWarning(
       service: ModalService,
       userService: UserService,
       router: Router
   ): boolean {
       execute(service, this.message, () => {
           userService && userService.consequenceAfterLogout();
       });

       return false;
   }
}
