
        <fieldset class="rating" [attr.data-test-id]="dataTestId + '__rating'"
                  [attr.aria-valuemin]="0"
                  [attr.aria-valuemax]="5"
                  [attr.aria-valuenow]="rate">
            <label [attr.data-test-id]="dataTestId + '__rating__5star'" [className]="rate > 4.5 ? 'rating-filled-star' : ''">
                <input type="radio" [checked]="rate > 4.5"/>
            </label>
            <label [attr.data-test-id]="dataTestId + '__rating__4star'" [class]="rate > 3.5 ? 'rating-filled-star' : ''">
                <input type="radio" [checked]="rate > 3.5"/>
            </label>
            <label [attr.data-test-id]="dataTestId + '__rating__3star'" [class]="rate > 2.5 ? 'rating-filled-star' : ''">
                <input type="radio" [checked]="rate > 2.5"/>
            </label>
            <label [attr.data-test-id]="dataTestId + '__rating__2star'" [class]="rate > 1.5 ? 'rating-filled-star' : ''">
                <input type="radio" [checked]="rate > 1.5"/>
            </label>
            <label [attr.data-test-id]="dataTestId + '__rating__1star'" [class]="rate > 0.5 ? 'rating-filled-star' : ''">
                <input type="radio" [checked]="rate > 0.5"/>
            </label>
        </fieldset>
    