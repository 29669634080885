/**
 * BiziBaza app
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddItemToSCRequestBody } from '../model/addItemToSCRequestBody';
import { AddItemToSCResponseBody } from '../model/addItemToSCResponseBody';
import { CommonErrorResonse } from '../model/commonErrorResonse';
import { RemoveShoppingCartElementsRequestBody } from '../model/removeShoppingCartElementsRequestBody';
import { ShoppingCartConfirmationRequestBody } from '../model/shoppingCartConfirmationRequestBody';
import { ShoppingCartConfirmationResponseBody } from '../model/shoppingCartConfirmationResponseBody';
import { ShoppingCartResponseBody } from '../model/shoppingCartResponseBody';
import { UpdateAddInfoFromSCRequestBody } from '../model/updateAddInfoFromSCRequestBody';
import { UpdateAddInfoFromSCResponseBody } from '../model/updateAddInfoFromSCResponseBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ShoppingCartService {

    protected basePath = 'https://dev-backend.bizibaza.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adds shopping cart elements.
     * 
     * @param body 
     * @param Token Session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addItemToScPost(body: AddItemToSCRequestBody, Token: string, observe?: 'body', reportProgress?: boolean): Observable<AddItemToSCResponseBody>;
    public addItemToScPost(body: AddItemToSCRequestBody, Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AddItemToSCResponseBody>>;
    public addItemToScPost(body: AddItemToSCRequestBody, Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AddItemToSCResponseBody>>;
    public addItemToScPost(body: AddItemToSCRequestBody, Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addItemToScPost.');
        }

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling addItemToScPost.');
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AddItemToSCResponseBody>(`${this.basePath}/add_item_to_sc`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes shopping cart elements from the shopping cart.
     * 
     * @param body List of unique identifiers of shopping cart elements.
     * @param Token Current session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteItemFromScPost(body: RemoveShoppingCartElementsRequestBody, Token: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteItemFromScPost(body: RemoveShoppingCartElementsRequestBody, Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteItemFromScPost(body: RemoveShoppingCartElementsRequestBody, Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteItemFromScPost(body: RemoveShoppingCartElementsRequestBody, Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deleteItemFromScPost.');
        }

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling deleteItemFromScPost.');
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/delete_item_from_sc`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Specifies delivery methods of shopping cart elements.
     * 
     * @param body 
     * @param Token Session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shoppingCartConfirmationPost(body: ShoppingCartConfirmationRequestBody, Token: string, observe?: 'body', reportProgress?: boolean): Observable<ShoppingCartConfirmationResponseBody>;
    public shoppingCartConfirmationPost(body: ShoppingCartConfirmationRequestBody, Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShoppingCartConfirmationResponseBody>>;
    public shoppingCartConfirmationPost(body: ShoppingCartConfirmationRequestBody, Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShoppingCartConfirmationResponseBody>>;
    public shoppingCartConfirmationPost(body: ShoppingCartConfirmationRequestBody, Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling shoppingCartConfirmationPost.');
        }

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling shoppingCartConfirmationPost.');
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ShoppingCartConfirmationResponseBody>(`${this.basePath}/shopping_cart_confirmation`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns buyer&#x27;s shopping cart.
     * 
     * @param Token current session token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shoppingCartGet(Token: string, observe?: 'body', reportProgress?: boolean): Observable<ShoppingCartResponseBody>;
    public shoppingCartGet(Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShoppingCartResponseBody>>;
    public shoppingCartGet(Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShoppingCartResponseBody>>;
    public shoppingCartGet(Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling shoppingCartGet.');
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ShoppingCartResponseBody>(`${this.basePath}/shopping_cart`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates shopping cart elements.
     * 
     * @param body 
     * @param Token Session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAddinfoFromScPost(body: UpdateAddInfoFromSCRequestBody, Token: string, observe?: 'body', reportProgress?: boolean): Observable<UpdateAddInfoFromSCResponseBody>;
    public updateAddinfoFromScPost(body: UpdateAddInfoFromSCRequestBody, Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateAddInfoFromSCResponseBody>>;
    public updateAddinfoFromScPost(body: UpdateAddInfoFromSCRequestBody, Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateAddInfoFromSCResponseBody>>;
    public updateAddinfoFromScPost(body: UpdateAddInfoFromSCRequestBody, Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAddinfoFromScPost.');
        }

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling updateAddinfoFromScPost.');
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UpdateAddInfoFromSCResponseBody>(`${this.basePath}/update_addinfo_from_sc`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
