///<reference path="bizipix-editor/bizipix-editor.component.ts"/>
import { Routes, RouterModule }     from '@angular/router';

import { AboutComponent }           from './settings/about.component';
import { FAQComponent }           from './settings/faq.component';
import { AddressEditorComponent }   from './profile/addr-editor.component';
import { AnalyticsComponent }       from './analytics/analytics.component';
import { BizipixEditorComponent }   from './bizipix-editor/bizipix-editor.component';
import { BizipixViewerComponent }   from './bizipix-viewer/bizipix-viewer.component';
import { CartComponent }            from './cart/cart.component';
import { CartReportComponent }      from './cart/cart-report.component';
import { CreateReviewComponent }    from './reviews/create-reviews.component';
import { DutyComponent }            from './analytics/duty.component';
import { ChatRoomComponent }        from './chat/chat-room.component';
import { DialogsListComponent }     from './chat/dialogs-list.component';
import { FavoriteListComponent }    from './favorite-list/favorite-list.component';
import {EmailVerificationComponent} from './user/email-verification.component';
import { GoodsNavComponent }        from './goods-nav/goods-nav.component';
import { HistoryComponent }         from './analytics/history.component';
import { InventoryListComponent }   from './inventory-list/inventory-list.component';
import { InventoryNavComponent }    from './goods-nav/inventory-nav.component';
import { LoginComponent }           from './user/login.component';
import { NotFoundComponent }        from './common/not-found.component';
import { PasswordComponent }        from './user/password.component';
import { PasswordResetComponent }   from './user/password-reset.component';
import { ProfileComponent }         from './profile/profile.component';
import { ProductEditorStrategyComponent }   from './product-editor/product-editor-strategy.component';
import { ProductDetailsComponent }  from './product-details/product-details.component';
import { ShopperDetailsComponent }  from './shopper-details/shopper-details.component';
import { SearchStrategyComponent }          from './search/search.component';
import { SettingsComponent }        from './settings/settings.component';
import { ShoppingListComponent }    from './shopping-list/shopping-list.component';
import { SignupComponent }          from './user/signup.component';
import { MapSearchComponent }          from './map-search/map-search.component';
import { MarketComponent }          from './market/market.component';
import { ReviewsComponent }         from './reviews/reviews.component';
import { WatchListComponent }       from './watch-list/watch-list.component';

import { AuthGuard }                from './services/auth-guard.service';
import { CanDeactivateGuard }       from './services/can-deactivate-guard.service';
import { LoginLinkComponent }       from './user/login-link/login-link.component';
import {SuccessOnboardingComponent} from './payment/success-onboarding';
import {PaymentComponent} from "./payment/payment-component";
import {AppRouteValues} from "./common/app.route.values";


export const routes: Routes = [
    {   path:           '',
        canActivate:    [AuthGuard],
        children:       [
            {   path:           '',
                pathMatch:      'full',
                // redirectTo:     '/inventory-sheet'
                redirectTo:     '/shopping-list'
            },
            {
                path:           'about',
                component:      AboutComponent,
            },
            {
                path:           'faq',
                component:      FAQComponent,
            },
            {
                path:           'addr-editor',
                component:      AddressEditorComponent,
                canDeactivate:  [CanDeactivateGuard]
            },
            {
                path:           'orders',
                component:      AnalyticsComponent,
                canDeactivate:  [CanDeactivateGuard]
            },
            {
                path:           'bizipix-editor',
                component:      BizipixEditorComponent,
            },
            {
                path:           'bizipix-viewer',
                component:      BizipixViewerComponent,
            },
            {
                path:           'create-review',
                component:      CreateReviewComponent
            },
            {
                path:           'pending-orders',
                component:      DutyComponent,
                canDeactivate:  [CanDeactivateGuard]
            },
            {
                path:           'change-password',
                component:      PasswordComponent
            },
            {
                path:           'history',
                component:      HistoryComponent,
                canDeactivate:  [CanDeactivateGuard]
            },
            {
                path:           'chat-room',
                component:      ChatRoomComponent
            },
            {
                path:           'dialogs',
                component:      DialogsListComponent
            },
            {   path:           'goods-nav',
                component:      GoodsNavComponent,
            },
            {
                path:           'favorites',
                component:      FavoriteListComponent
            },
            {
                path:           'inventory-list',
                component:      InventoryListComponent
            },
            {
                path:           'inventory-nav',
                component:      InventoryNavComponent
            },
            {
                path:           'inventory-sheet',
                loadChildren:   'app/inventory-sheet/inventory-sheet.module#InventorySheetModule'
            },
            {   path:           'map-search',
                component:      MapSearchComponent

            },
            {   path:           'market',
                component:      MarketComponent
            },
            {
                path:           'product-editor',
                component:      ProductEditorStrategyComponent,
            },
            {   path:           'shopper-details',
                component:      ShopperDetailsComponent
            },
            {
                path:           'profile',
                component:      ProfileComponent,
            },
            {
                path:           AppRouteValues.routePayment,
                component:      PaymentComponent
            },
            {
                path:           'search',
                component:      SearchStrategyComponent,
            },
            {   path:           'shopping-list',
                component:      ShoppingListComponent,
            },
            {   path:           'reviews',
                component:      ReviewsComponent,
            },
            {   path:           'reviews-item',
                component:      ReviewsComponent,
            },
            {
                path:           'users-list',
                component:      SearchStrategyComponent,
            },
            {   path:           'watchlist',
                component:      WatchListComponent,
            }
        ]
    },
    {   path:           'login',
        component:      LoginComponent,
    },
    {   path:           AppRouteValues.routeCartReport,
        component:      CartReportComponent
    },
    {   path:           AppRouteValues.routeSuccessOnboarding,
        component:      SuccessOnboardingComponent
    },
    {   path:           'product-details/:id',
        component:      ProductDetailsComponent
    },
    {
        path:           'email-verification',
        component:      EmailVerificationComponent
    },
    {
        path:           'password-reset',
        component:      PasswordResetComponent,
    },
    {   path:           'signup',
        component:      SignupComponent,
        canDeactivate:  [CanDeactivateGuard]
    },
    {
        path:           AppRouteValues.routeSettings,
        component:      SettingsComponent,
    },
    {
        path:           AppRouteValues.routeCart,
        component:      CartComponent,
    },
    {
        path: 'login-link',
        component: LoginLinkComponent
    },
    {   path:           'login-with-link',
        pathMatch:      'full',
        redirectTo:     '/shopping-list'
    },
    {   path:           '**',
        component:      NotFoundComponent
    }
];

export const appRoutingProviders: any[] = [];

export const routing = RouterModule.forRoot(routes, { useHash: true });
