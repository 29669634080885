import {Injectable, Injector} from '@angular/core';
import { Observable }   from 'rxjs/Observable';
import { Response }     from '@angular/http';
import 'rxjs/add/observable/throw';
import { Router }           from '@angular/router';
import { Location }         from '@angular/common';

import { ModalService }     from '../modal/modal.service';
import {
    AfterBootService
} from '../after-boot/after-boot.service';
import {TranslateService} from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { getErrorType } from './error.helpers';


@Injectable()
export class ErrorService {

    constructor(
        private modalService:       ModalService,
        private afterBootService:   AfterBootService,
        private translate:          TranslateService,
        private injector:           Injector
    ) { }


    static _extractPaypalMsg(_error: Response | any): string|boolean {
        let msg = _error['_body'];
        if (!msg || typeof msg !== 'string') return false;

        let idx = msg.lastIndexOf('PaypalError');

        return ~idx ? msg.substr(idx) : false;
    }



    handleHttpError(error: HttpErrorResponse) {
        // condition for '/change_password'
        let errorObj: ErrorInterface;
        let body_error_text: string;
        errorObj = {
            status: error.status,
            statusText: this._extractError(error) || getErrorType(error) || '',
        };

        if ( typeof error['_body'] !== "undefined" && getErrorType(error) !== '') {
            let body = JSON.parse(error['_body']);

            if (body && body.error !== '') {
                body_error_text = body.error;
                errorObj['body_error_text'] = body_error_text;
            }
        }

        if ( error.status === 0 ) errorObj.statusText = 'Check the Internet connection';

        let paypalError = ErrorService._extractPaypalMsg(error);

        return Observable.throwError(paypalError || errorObj);

    }


    public handleError(err: ErrorInterface): Observable<never> {
        let message: string = typeof err === 'string' ? err : getErrorType(err);
        if (message && message.includes('Guest User have no')) {
            message = this.unauthorizedMsg;
        }
        if ( err.status === 0 ) {
            message = 'Check the Internet connection';
            this.modalService.close();
            return Observable.throwError(getErrorType(err));
        }

        if (err.status === 403) {
           this.afterBootService.afterLoadingError = err;
           this.afterBootService.handleResolver();
           return Observable.throwError(getErrorType(err));
        }

        if (err.status === 401 || (typeof err === 'string' &&
            err === this.unauthorizedMsg)
        ) {
            this.unauthorizedModal();

            return Observable.throwError(getErrorType(err));
        }

        this.modalService.error({
            title:          'Error:',
            message:        message,
            yesButtonText:  'Close',
        });
        return Observable.throwError(getErrorType(err));
    }


    get unauthorizedMsg(): string {
        return this.translate.instant('unauthorized.message.body');
    }

    unauthorizedModal(): void {
        this.modalService.warning({
            title: this.translate.instant('unauthorized.message.title'),
            message: this.unauthorizedMsg,
            noButtonText: this.translate.instant('unauthorized.message.actionBtn'),
            yesButtonText: this.translate.instant('unauthorized.message.closeBtn'),
        }).then((action: boolean) => {
            !action && this.router.navigate(['/login']);
        });
    }


    handleWarning(err: string) {
        this.modalService.warning({
            title:          this.translate.instant('modal.alert.title'),
            message:        err,
            yesButtonText:  this.translate.instant('modal.alert.confirm'),
        });

        return Observable.throwError(err);
    }


        /***
     * @desc This function creates router property on your service.
     * @return {Router}
     */
    public get router(): Router {
        return this.injector.get(Router);
    }


    /***
     * @desc This function creates Location property on your service.
     * @return {Location}
     */
    public get Location(): Location {
        return this.injector.get(Location);
    }
    
    private _extractError(err: HttpErrorResponse): string {
        return err.error && err.error[Object.keys(err.error)[0]];
    }
}


export interface ErrorInterface {
   status:              number;
   statusText:          string;
   body_error_text?:    string;
}
