import { CommonModule }                     from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, ModuleWithProviders }    from '@angular/core';
import { RouterModule }                     from '@angular/router';

import { AgmCoreModule }                    from '@agm/core';
import { CertificateComponent }             from './additional/certificate.component';
import { EventService }                     from './event.service';
import { EventSectionComponent }            from './additional/event-section.component';
import { EventWidgetComponent }             from './additional/event-widget.component';
import { ExistingEventEditorComponent }     from './editor/existing-event-editor.component';
import { ExistingItemEditorComponent }      from './editor/existing-item-editor.component';
import { ImageEditorComponent }             from './additional/image-editor.component';
import { NewEventEditorComponent }          from './editor/new-event-editor.component';
import { NewItemEditorComponent }           from './editor/new-item-editor.component';
import { ProductEditorService }             from './product-editor.service';
import { ProductEditorStrategyComponent }   from './product-editor-strategy.component';
import { ProductEditorHeaderComponent }     from './product-editor-header.component';
import { QuantitySelectComponent }          from './additional/quantity-select.component';
import { SharedModule }                     from '../shared/shared.module';
import { TimePickerComponent }              from './additional/time-picker.component';
import { environment }                      from '../../environments/environment';
import {TextEditorComponent} from "./additional/text-editor.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,

        AgmCoreModule.forRoot({
            apiKey: environment.GM_API_KEY
        }),
        RouterModule,
        SharedModule,
    ],
    declarations: [
        CertificateComponent,
        EventSectionComponent,
        EventWidgetComponent,
        ExistingEventEditorComponent,
        ExistingItemEditorComponent,
        ImageEditorComponent,
        NewEventEditorComponent,
        NewItemEditorComponent,
        ProductEditorHeaderComponent,
        ProductEditorStrategyComponent,
        QuantitySelectComponent,
        TimePickerComponent,
        TextEditorComponent
    ],
    entryComponents: [
        ExistingEventEditorComponent,
        ExistingItemEditorComponent,
        NewEventEditorComponent,
        NewItemEditorComponent,
    ],
    exports: [
        ProductEditorStrategyComponent,
    ]
})
export class ProductEditorModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ProductEditorModule,
            providers: [
                EventService,
                ProductEditorService
            ]
        };
    }
}
