import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import { AppFooterService } from '../app-footer/app-footer.service';
import { ModalFilterArguments } from './review.interface';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { UserService } from '../user/user.service';
import { ReviewsService } from '../../../swagger-gen__output_dir';
import { ProductDetailsService } from '../product-details/product-details.service';
import { OrdersAppService } from '../analytics/orders.service';
var AppReviewsService = /** @class */ (function () {
    function AppReviewsService(appFooterService, dataService, reviewsServiceClient, errorService, router, userService, productDetailsService, ordersAppService) {
        this.appFooterService = appFooterService;
        this.dataService = dataService;
        this.reviewsServiceClient = reviewsServiceClient;
        this.errorService = errorService;
        this.router = router;
        this.userService = userService;
        this.productDetailsService = productDetailsService;
        this.ordersAppService = ordersAppService;
        this.listFilterTabs = [
            { value: ModalFilterArguments.received, title: 'Received', active: true },
            { value: ModalFilterArguments.written, title: 'Written', active: false },
            { value: ModalFilterArguments.purchases, title: 'Purchases', active: false }
        ];
        this.product_reviews = { product_review: [] };
        this.user_reviews = { seller_reviews: [], buyer_reviews: [] };
        this.isFromSettings = false;
        this.mode = this.appFooterService.getMode();
    }
    AppReviewsService.prototype.createProductReview = function (body) {
        var _this = this;
        var token = this.userService.getUserSession().token;
        if (!token)
            return;
        return new Observable(function (observer) {
            _this.checkAccessToSendReview(_this.userService.getUser().ID, body.product_id)
                .subscribe(function (access) {
                if (!access.flag) {
                    return Observable.throwError(_this.errorService.handleWarning(access.description));
                }
                else {
                    return _this.reviewsServiceClient.createReviewPost(body, token)
                        .subscribe(function (res) {
                        observer.next(res);
                    }, function (err) { return observer.error(_this.errorService.handleError(err)); });
                }
            });
        });
    };
    AppReviewsService.prototype.createBuyerReview = function (body) {
        var _this = this;
        var token = this.userService.getUserSession().token;
        if (!token)
            return;
        return new Observable(function (observer) {
            _this.checkAccessToSendReview(body.buyer_id)
                .subscribe(function (access) {
                if (!access.flag) {
                    return Observable.throwError(_this.errorService.handleWarning(access.description));
                }
                else {
                    return _this.reviewsServiceClient.buyerReviewPost(body, token)
                        .subscribe(function (res) {
                        observer.next(res);
                    }, function (err) { return observer.error(_this.errorService.handleError(err)); });
                }
            });
        });
    };
    AppReviewsService.prototype.linkToCreateReview = function (order_element_id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.getBuyerReviewRequest()
                .subscribe(function (list) {
                var existingOrder;
                var existingOrderElement;
                list.orders_to_review.forEach(function (order) {
                    order.elements.forEach(function (e) {
                        if (e.ID === order_element_id) {
                            existingOrder = order;
                            existingOrderElement = existingOrder.elements.find(function (el) { return el.ID === order_element_id; });
                        }
                    });
                });
                if (existingOrderElement && existingOrderElement.ID === order_element_id) {
                    if (existingOrderElement.is_reviewed) {
                        observer.error('is_possible_to_send_review.description');
                        observer.complete();
                    }
                    else {
                        _this.getItemReviewAndRedirect({
                            element: existingOrderElement,
                            buyer_info: existingOrder.buyer_info,
                            isCreating: true
                        }).subscribe(function (res) {
                            observer.next();
                            observer.complete();
                        }, function (error) { return observer.error(error); });
                    }
                }
                else {
                    observer.error('not.exist');
                    observer.complete();
                }
            }, function (error) { return observer.error(error); });
        });
    };
    AppReviewsService.prototype.getBuyerAllReview = function (buyerObj) {
        var _this = this;
        return this.getBuyerReviewRequest()
            .map(function (_list) {
            _this.list_of_reviews = _list;
            if (buyerObj) {
                _this.router.navigate(['/reviews-item'], { queryParams: { buy: buyerObj.buyer } });
            }
            return _this.list_of_reviews;
        })
            .catch(function (err) { return null; });
    };
    AppReviewsService.prototype.getSellerAllReview = function (buyerObj) {
        var _this = this;
        return this.getSellerReviewRequest()
            .map(function (_list) {
            _this.list_of_reviews = _list;
            if (buyerObj) {
                _this.router.navigate(['/reviews-item'], { queryParams: { sel: buyerObj.title } });
            }
            return _this.list_of_reviews;
        })
            .catch(function (err) { return null; });
    };
    /**
     * checks the ability to leave a comment, whether this product was purchased,
     * whether requests on this product were previously posted
     * @param {string} item_id
     * @param {string} buyer_id
     * @return {Observable<AccessToSendReviewResponse>}
     */
    AppReviewsService.prototype.checkAccessToSendReview = function (buyer_id, item_id) {
        var _this = this;
        var is_item_review;
        if (item_id) {
            is_item_review = 1;
        }
        else {
            is_item_review = 0;
        }
        return this.reviewsServiceClient.isPossibleToSendReviewGet(buyer_id, is_item_review, this.userService.getUserSession().token, item_id)
            .do(function (access) {
            return access;
        }).catch(function (err) {
            return _this.errorService.handleError(err);
        });
    };
    /**
     * FR_REVIEW_6
     * System shall notify user about new (unread) received reviews
     * @param {Review[]} reviews
     * @return {Observable<MarkReviewsAsReadResponseBody>}
     */
    AppReviewsService.prototype.markReviewsAsRead = function (reviews) {
        var _this = this;
        var reviewIDList = [];
        var token = this.userService.getUserSession().token;
        if (!token)
            return;
        reviews.forEach(function (review) {
            reviewIDList.push(review.ID);
        });
        this.reviewsServiceClient.markReviewsAsReadPost({ reviews: reviewIDList }, token)
            .subscribe(null, function (error) { return _this.errorService.handleError(error); });
    };
    /**
     * Get all reviews left for selected seller and navigate to reviews-item view
     * Using when user from shopper details page go to review-item
     * @return {Observable<GetUserReviewsResponseBody>}
     */
    AppReviewsService.prototype.getUserReviewsAndRedirect = function (params) {
        var _this = this;
        this.selectedStrategy(params);
        var token = this.userService.getUserSession().token;
        if (!token)
            return;
        return this.reviewsServiceClient.getUserReviewsGet(this.selected_order.ID, token)
            .map(function (reviews) {
            _this.setUserReviews = reviews;
            if (params.isCreating) {
                _this.router.navigate(['/create-review'], { queryParams: { buyer: _this.selected_order.title } });
            }
            else if (params.is_buyer) {
                _this.router.navigate(['/reviews-item'], { queryParams: { buy: _this.selected_order.title } });
            }
            else {
                _this.router.navigate(['/reviews-item'], { queryParams: { sel: _this.selected_order.title } });
            }
            return _this.user_reviews;
        }).catch(function (error) {
            return _this.errorService.handleError(error);
        });
    };
    /**
     * Get all reviews left for selected product and navigate to reviews-item view
     * Using when user from product details page go to review-item
     * @return {Observable<Review[]>}
     */
    AppReviewsService.prototype.getItemReviewAndRedirect = function (params) {
        var _this = this;
        this.selectedStrategy(params);
        return this.getItemReview(this.selected_order.ID)
            .map(function (list) {
            _this.setProductReviews = list;
            if (params.isCreating) {
                _this.router.navigate(['/create-review'], { queryParams: { product: _this.selected_order.title } });
            }
            else {
                _this.router.navigate(['/reviews-item'], { queryParams: { key: _this.selected_order.title } });
            }
            return _this.product_reviews;
        })
            .catch(function (err) { return _this.errorService.handleError(err); });
    };
    /**
     * All reviews left for specific product
     * Using when user from product details page go to review-item
     * @param {string} item_id
     * @return {Observable<GetItemReviewsInterface>}
     */
    AppReviewsService.prototype.getItemReview = function (item_id) {
        var token = this.userService.getUserSession().token;
        if (!token)
            return;
        return this.dataService.getData("get_item_review?item_id=" + item_id + "&offset=0&limit=120", { token: token });
    };
    /**
     * Gets reviews total
     * @param item_id
     * @returns reviews total
     */
    AppReviewsService.prototype.getReviewsTotal = function (item_id) {
        var _this = this;
        return this.getItemReview(item_id).map(function (review) {
            return review.reviews.length;
        })
            .catch(function (err) { return _this.errorService.handleError(err); });
    };
    AppReviewsService.prototype.getSellerReviewRequest = function () {
        var _this = this;
        var token = this.userService.getUserSession().token;
        if (!token)
            return Observable.of({});
        return this.reviewsServiceClient.getSellerReviewGet(token)
            .do(function (list) { return list; })
            .catch(function (err) { return _this.errorService.handleError(err); });
    };
    AppReviewsService.prototype.getBuyerReviewRequest = function () {
        var _this = this;
        var token = this.userService.getUserSession().token;
        if (!token)
            return Observable.of({});
        return this.reviewsServiceClient.buyerReviewGet(token)
            .do(function (list) { return list; })
            .catch(function (err) { return _this.errorService.handleError(err); });
    };
    AppReviewsService.prototype.showReviewList = function (buyerObj, isCreating) {
        return this.getItemReviewAndRedirect({ buyerObj: buyerObj, isCreating: isCreating });
        // Deprecated
        // return this.checkAccessToSendReview(buyerObj)
        //     .switchMap(() => this.getItemReview(buyerObj));
    };
    AppReviewsService.prototype.showProductDetails = function (id) {
        return this.productDetailsService.showProductDetails(id).toPromise().then(function (res) { return res; }, function (err) { return err; });
    };
    AppReviewsService.prototype.getListReviews = function () {
        return this.list_of_reviews;
    };
    AppReviewsService.prototype.getList = function () {
        return this.list_of_reviews;
    };
    Object.defineProperty(AppReviewsService.prototype, "setProductReviews", {
        set: function (review) {
            this.product_reviews = { product_review: review.reviews };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppReviewsService.prototype, "getProductReviews", {
        get: function () {
            return this.product_reviews;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppReviewsService.prototype, "selectedOrder", {
        get: function () {
            return this.selected_order;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppReviewsService.prototype, "setUserReviews", {
        set: function (reviews) {
            this.user_reviews = reviews;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppReviewsService.prototype, "getUserReviews", {
        get: function () {
            return this.user_reviews;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppReviewsService.prototype, "isOpenedReviewFromSettings", {
        set: function (isFromSettingsPage) {
            this.isFromSettings = isFromSettingsPage;
        },
        enumerable: true,
        configurable: true
    });
    AppReviewsService.prototype.selectedStrategy = function (params) {
        if (params.buyerObj) {
            this.setSelectedProduct(params.buyerObj);
        }
        if (params.element && params.buyer_info) {
            this.setSelectedOrder(params.element, params.buyer_info, params.buyer_id);
        }
    };
    AppReviewsService.prototype.setSelectedOrder = function (element, buyer_info, buyer_id) {
        if (this.appFooterService.isSellerMode()) {
            this.selected_order = {
                ID: buyer_id || buyer_info.ID,
                title: buyer_info.title,
                url: (buyer_info.imageURL || '../assets/images/cart/no_image_icon.png'),
                order_element_id: element.ID
            };
        }
        else {
            this.selected_order = {
                ID: element.item_info.current_item_id,
                title: element.item_info.title,
                url: (element.item_info.photoURL || '../assets/images/cart/no_image_icon.png'),
                order_element_id: element.ID || ''
            };
        }
    };
    AppReviewsService.prototype.setSelectedProduct = function (obj) {
        this.selected_order = {
            ID: obj.ID,
            title: obj.title,
            url: (obj.url || '../assets/images/cart/no_image_icon.png'),
            order_element_id: obj.order_element_id || ''
        };
    };
    AppReviewsService.prototype.goToSelectedOrderID = function (order_id) {
        this.ordersAppService.setSelectedOrderID = order_id;
        this.ordersAppService.isOpenedHistoryFromReview = true;
        this.router.navigate(['/history']);
    };
    AppReviewsService.prototype.backToHome = function () {
        this.selectFilterTab('received');
    };
    AppReviewsService.prototype.selectPurchasesFilterTab = function () {
        this.selectFilterTab('purchases');
    };
    AppReviewsService.prototype.selectFilterTab = function (filterName) {
        var _this = this;
        this.listFilterTabs.forEach(function (filter, n) {
            _this.listFilterTabs[n].active = false;
            if (filter.value === filterName) {
                _this.listFilterTabs[n].active = true;
            }
        });
    };
    return AppReviewsService;
}());
export { AppReviewsService };
