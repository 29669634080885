import {
    Component, EventEmitter,
    Input, Output, OnInit
} from '@angular/core';

import { ItemService }              from '../services/item.service';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';


@Component({
    selector:   'inventory-list-item',
    styleUrls:  ['./inventory-list.sass'],

    template:   `
        <li class="inventory-list__item"
            [attr.data-id]="item['ID']"
            [attr.data-test-id]="'inventoryItem-'+index"
            (click)="showItem(); $event.stopPropagation()">

            <div
               *ngIf="isSale"
               class="marker__sale"
               [attr.data-test-id]="'saleOnLabel_'+index"
            ></div>
            <div
                *ngIf="isSoldOut"
                class="marker__soldout"
                [attr.data-test-id]="'soldOutLabel_'+index"
            ></div>

            <div class="inventory-list__img">
                <span *ngIf="!item['photoURL']" class="sprite sprite-no_image_icon"></span>
                <img *ngIf="item['photoURL']" [src]="item['photoURL']" [attr.alt]="item['title'] || item['market_title'] + 'product image'">
            </div>

            <section [ngClass]="{'expired': isExpired}">
                <h3 class="title"
                    [attr.data-test-id]="'inventoryItemTitle_'+index">
                    {{item['title'] || item['market_title']}}
                </h3>

                <h4 class="currency" [attr.data-test-id]="'inventoryItemPrice_'+index">{{isPrice / 100 | currency:'USD'}} / {{isUnitsName}}</h4>
                <h4 class="quantity" [attr.data-test-id]="'inventoryItemQuantity_'+index">{{item['qty'] | number}} {{item['qtyUnitName']}}</h4>

                <div
                   *ngIf="item.draft"
                   class="marker__draft"
                   [attr.data-test-id]="'draftLabel_'+index"
                ></div>
                <div
                   *ngIf="isExpired"
                   class="sprite sprite-expired_2x"
                   [attr.data-test-id]="'expiredLabel_'+index"
                ></div>

            </section>

            <div class="inventory-list__remove" [attr.data-test-id]="'removeItem_'+index"
                    (click)="removeItem(); $event.stopPropagation()">

                <i class="icon-trash-empty"></i>
            </div>
        </li>
    `
})

export class InventoryListItemComponent implements OnInit {
    /**
     * @desc Renders inventory list item.
     * @emits showItemEvent$ on item click.
     */
    @Input()    item:               SellingItemResponseBody;
    @Input()    index:              number;

    @Output()   removeItemEvent$    = new EventEmitter();
    @Output()   showItemEvent$      = new EventEmitter();


    isExpired:              boolean;
    isSale:                 boolean;
    isSoldOut:              boolean;
    isPrice:                number;
    isUnitsName:            string;


    constructor(
        private itemService: ItemService
    ) { }

    /**
     * @desc Checks for sale and validity, set corresponding flags accordingly.
     */
    public ngOnInit(): void {
        setTimeout(() => {
          this.item && this._checkItemFn(this.item);
        });
    }

    /**
     * @desc check if this product is on sale
     * and set price and UnitsName
     * @param {SellingItemResponseBody | any} item
     * @returns {boolean}
     */
     _checkItemFn(item: SellingItemResponseBody | any) {
         this.isExpired   = this.itemService.isExpiredFn(item);
         this.isSale      = this.itemService.isSaleFn(item) && !this.isExpired
                               && item.current_quantity > 0;
         this.isPrice     = this.itemService.itemPriceFn(item);
         this.isUnitsName = this.itemService.priceUnitsNameFn(item);
         this.isSoldOut   = !item.current_quantity && !item.draft;
    }


    /** @emits on item click */
    showItem(): void {
        this.showItemEvent$.emit();
    }


    /** @emits on item devetion click */
    removeItem(): void {
        this.removeItemEvent$.emit(true);
    }

}
