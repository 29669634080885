
        <div class="me__container">

            <img class="me__img" [src]="imageUrl">

            <input class="me__text" role="editable"
                    data-test-id="text"
                    [placeholder]="placeholder"
                    [value]="text"
                    type="text" [ngClass]="{ none : !text }"
                    (blur)="finishRenaming({event: $event, isInput: true})"
                    (focus)="startRenaming({event: $event, isInput: true})"/>

        </div>
    