import { Observable } from 'rxjs/Rx';
import { Router, RoutesRecognized } from '@angular/router';
import 'rxjs/add/observable/throw';
import { AppSettings } from '../common/app.settings';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { ModalService } from '../modal/modal.service';
import { SearchType } from '../interfaces/search.interface';
import { UserService } from '../user/user.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { LocalCaching, LocalCachingCreatorMapping } from '../local-caching/local-caching-factory';
import { GeoLocationService } from '../services/geolocation.service';
import { SearchService } from '../../../swagger-gen__output_dir/api/search.service';
import { fixedEncodeURIComponent } from "../common/app.helpers";
import { TranslateService } from "@ngx-translate/core";
import { ProductDetailsService } from "../product-details/product-details.service";
var AppSearchService = /** @class */ (function () {
    function AppSearchService(dataService, googleAnalyticsService, errorService, modalService, productDetailsService, router, userService, localCaching, geoLocationService, translate, searchService) {
        var _this = this;
        this.dataService = dataService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.errorService = errorService;
        this.modalService = modalService;
        this.productDetailsService = productDetailsService;
        this.router = router;
        this.userService = userService;
        this.localCaching = localCaching;
        this.geoLocationService = geoLocationService;
        this.translate = translate;
        this.searchService = searchService;
        this.foundNum = 0;
        this.isNew = true;
        this.keyWord = '';
        this.results = {};
        this.type = SearchType.Item;
        this.isMarketList = false;
        this.prevUrl = '';
        this.count = AppSettings.SEARCH_COUNT;
        this.geoLocationService.locationEvent$.subscribe(function (res) {
            _this.setUserLocation(res);
        });
        this.router.events
            .filter(function (evt) { return evt instanceof RoutesRecognized; }).pairwise()
            .subscribe(function (events) {
            if (events[0].urlAfterRedirects && events[0].urlAfterRedirects !== '') {
                _this.prevUrl = events[0].urlAfterRedirects;
            }
        });
    }
    AppSearchService.prototype.cachedData = function () {
        var cached = this.localCaching.getOneCache(LocalCachingCreatorMapping.ConcreteSearchCaching, {});
        if (Object.keys(cached).length > 0) {
            this.setSearchDataFromCache(cached);
        }
        return cached;
    };
    AppSearchService.prototype.getLastSuccessSearchData = function () {
        var cachedList = this.localCaching.getAllCache(LocalCachingCreatorMapping.ConcreteSearchCaching);
        // @ts-ignore
        var cachedData = cachedList.filter(function (cachedSearchResult) { return cachedSearchResult.data['keyWord'] !== ''; })[0].data;
        this.setSearchDataFromCache(cachedData);
        return cachedData;
    };
    AppSearchService.prototype.setSearchDataFromCache = function (cachedData) {
        if (Object.keys(cachedData).length) {
            this.foundNum = cachedData.foundNum;
            this.keyWord = cachedData.keyWord;
            this.results = cachedData.data;
            this.type = cachedData.type;
            this.entryId = cachedData.entryId;
            this.isMarketList = cachedData.marketList;
            this.is_key_search = cachedData.is_key_search;
            this.userLocation = cachedData.userLocation;
        }
    };
    AppSearchService.prototype.getUniqSearchItemFromCache = function (url) {
        var cacheItems = this.localCaching.getAllCache(LocalCachingCreatorMapping.ConcreteSearchCaching);
        cacheItems.filter(function (cacheItem) { return cacheItem.url === url; });
        if (cacheItems) {
            return cacheItems[0].data;
        }
        return {};
    };
    AppSearchService.prototype.clearResults = function (type, isMarketList) {
        if (type === void 0) { type = SearchType.Item; }
        this.foundNum = 0;
        this.keyWord = '';
        this.entryId = '';
        this.results = {};
        this.isMarketList = isMarketList || false;
        this.type = type;
    };
    AppSearchService.prototype.getResults = function (type) {
        return {
            foundNum: this.foundNum,
            keyWord: this.keyWord,
            data: this.results,
            marketList: this.isMarketList,
            type: type || this.type,
            entryId: this.entryId,
            userLocation: this.userLocation,
            title: this.title
        };
    };
    AppSearchService.prototype.setTitle = function (title) {
        this.title = title;
    };
    AppSearchService.prototype.isAdminMode = function () {
        return this.userService.isAdminMode();
    };
    /**
     * get userLocation method
     * @returns {CenterLocationInterface}
     */
    AppSearchService.prototype.userGeolocation = function () {
        return this.userLocation;
    };
    AppSearchService.prototype.setUserLocation = function (coords) {
        this.userLocation = coords;
    };
    AppSearchService.prototype.setFalseMarketFlag = function (type, isMarketList) {
        this.isMarketList = isMarketList || false;
        this.type = type;
    };
    AppSearchService.prototype.setMarketProducts = function (marketProducts, marketName) {
        this.results = marketProducts;
        this.keyWord = marketName;
        this.type = SearchType.Active;
        this.isMarketList = false;
        this.isNew = true;
        this.is_key_search = false;
    };
    AppSearchService.prototype.getActiveItems = function (item) {
        var _this = this;
        this.results = {};
        this.keyWord = item.name;
        this.entryId = item.subcategory_entry_id;
        this.type = SearchType.Active;
        this.isMarketList = false;
        this.isNew = true;
        this.productDetailsService.setProductDetailsFrom('shopping_list');
        this.modalService.showSpinner();
        var token = this.userService.getUserSession().token;
        if (!token || !item)
            return Observable.of({});
        return this.dataService.getData("get_active_items?entry_id=" + item.subcategory_entry_id + "&page=1&count=" + this.count, { token: token })
            .map(function (res) {
            _this.is_key_search = false;
            return res;
        })
            .subscribe(function (res) {
            _this._handleSearchResult(res);
            var urlBySearchName = _this.getUrlBySearchName(item.sub_category_name || item.name);
            _this.addSearchDataToLocalCachingByUrl({ url: urlBySearchName.fullUrl });
            _this.router.navigate([urlBySearchName.currentUrl.root], urlBySearchName.currentUrl.navigationExtras);
        }, function (err) { return _this.errorService.handleError(err); });
    };
    AppSearchService.prototype.getUrlBySearchName = function (name, root, key) {
        if (root === void 0) { root = '/search'; }
        if (key === void 0) { key = 'key'; }
        var _a;
        var currentUrl = {
            root: root,
            navigationExtras: { queryParams: (_a = {}, _a[key] = name, _a) }
        };
        var newUrl = fixedEncodeURIComponent(name);
        return {
            fullUrl: window.location.origin + currentUrl.root + '?' + key + '=' + newUrl,
            currentUrl: currentUrl
        };
    };
    AppSearchService.prototype.addSearchDataToLocalCachingByUrl = function (data) {
        if (data.searchResult) {
            this._handleSearchResult(data.searchResult);
        }
        if (data.searchType) {
            this.type = data.searchType;
        }
        this.addToLocalCaching(data.url);
    };
    AppSearchService.prototype.getActiveItemsInMarket = function (market, results) {
        this.results = {};
        this.keyWord = market.market_title;
        this.type = SearchType.Active;
        this.isNew = true;
        this.isMarketList = true;
        var token = this.userService.getUserSession().token;
        if (!token || !market.ID)
            return Observable.of({});
        this.modalService.showSpinner();
        this._handleSearchResult(results);
        this.is_key_search = false;
        this.router.navigate(['/search'], { queryParams: { key: market.market_title } });
    };
    AppSearchService.prototype.setOptions = function (keyWord, type, options) {
        if (options === void 0) { options = {}; }
        if (options.isNew || keyWord !== this.keyWord || type !== this.type) {
            this.results = {};
            this.keyWord = keyWord;
            this.type = type;
            this.isMarketList = false;
        }
    };
    AppSearchService.prototype.search = function (keyWord, type, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.setOptions(keyWord, type, options);
        this.isNew = options.isNew;
        this.modalService.showSpinner();
        return this.searchRequestHelper(options['offset'])
            .map(function (res) { return _this._handleSearchResult(res); })
            .catch(function (err) {
            if (err.status === 400)
                err.statusText = _this.translate.instant('search.input.error');
            return _this.errorService.handleError(err);
        });
    };
    AppSearchService.prototype.searchRequestHelper = function (offset) {
        if (offset === void 0) { offset = 1; }
        var token = this.userService.getUserSession().token;
        var latitude = this.getLatitude();
        var longitude = this.getLongitude();
        if (this.type === SearchType.Event || this.type === SearchType.Market) {
            return this.searchService.searchMarketGet(token, this.keyWord, latitude, longitude, 1, this.count);
        }
        else {
            return this.dataService.getData(this._defineQuery(offset), { token: token });
        }
    };
    AppSearchService.prototype.selectBySearchType = function (init_search, result) {
        if (init_search === true && this.search_from && this.search_from !== '') {
            this.googleAnalyticsService.handleForSearch(result.keyWord);
            // if(this.is_key_search === true)
            this.googleAnalyticsService.handleForSearchByType(this.search_from, result.keyWord, result.foundNum);
        }
    };
    AppSearchService.prototype.getSearchFrom = function () {
        // window.location.search.indexOf('?key=') !== 0
        if (this.getResults().type !== SearchType.Active && window.location.pathname === '/search')
            this.search_from = 'key_word';
        else if (this.getResults().type === SearchType.Active && window.location.search.indexOf('?key=') === 0)
            this.search_from = 'shopping_list';
        else
            this.search_from = '';
    };
    /**
     * request generation
     * @param {number} offset
     * @returns {string}
     * @private
     */
    AppSearchService.prototype._defineQuery = function (offset) {
        if (offset === void 0) { offset = 1; }
        var latitude = this.getLatitude();
        var longitude = this.getLongitude();
        switch (this.type) {
            case SearchType.Seller:
                return "search?key_word=" + this.keyWord + "&type=" + this.type + "&lat=" + latitude + "&lon=" + longitude + "&count=" + this.count + "&offset=" + offset;
            case SearchType.User:
                return "search?key_word=" + this.keyWord + "&type=" + this.type + "&lat=0&lon=0&count=" + this.count + "&offset=" + offset;
            default:
                return "search?key_word=" + this.keyWord + "&type=" + this.type +
                    ("&lat=" + latitude + "&lon=" + longitude + "&count=" + this.count + "&offset=" + offset);
        }
    };
    AppSearchService.prototype.getLatitude = function () {
        return !this.userLocation ? this.userService.getUser().latitude : this.userLocation.latitude;
    };
    AppSearchService.prototype.getLongitude = function () {
        return !this.userLocation ? this.userService.getUser().longitude : this.userLocation.longitude;
    };
    AppSearchService.prototype._handleSearchResult = function (result) {
        this.modalService.close();
        // TODO: BIZ-2367: this._updateResult(this._filterZeroQty(result));
        this._updateResult(result);
        this.countSearchResult();
        var data_object = {
            foundNum: this.foundNum,
            keyWord: this.keyWord,
            data: this.results,
            type: this.type,
            marketList: this.isMarketList || false
        };
        this.selectBySearchType(true, data_object);
        return data_object;
    };
    AppSearchService.prototype.addToLocalCaching = function (currentUrl) {
        var data_object = {
            foundNum: this.foundNum,
            keyWord: this.keyWord,
            data: this.results,
            type: this.type,
            entryId: this.entryId,
            marketList: this.isMarketList || false,
            userLocation: this.userLocation,
            is_key_search: this.is_key_search,
            title: this.title
        };
        this.localCaching.setCache(LocalCachingCreatorMapping.ConcreteSearchCaching, data_object, currentUrl);
    };
    // tslint:disable-next-line: no-any
    AppSearchService.prototype.getLastSearchResult = function () {
        return this.localCaching.getOneCache(LocalCachingCreatorMapping.ConcreteSearchCaching, {});
    };
    AppSearchService.prototype._filterZeroQty = function (obj) {
        if (this.type !== 'item')
            return obj;
        for (var key in obj)
            if (obj.hasOwnProperty(key)) {
                obj[key] = obj[key].filter(function (item) { return item.current_quantity > 0; });
            }
        return obj;
    };
    AppSearchService.prototype.countSearchResult = function () {
        this.foundNum = 0;
        var obj = this.results;
        for (var key in obj)
            if (obj.hasOwnProperty(key)) {
                this.foundNum += obj[key].length;
            }
    };
    AppSearchService.prototype._updateResult = function (res) {
        var obj = this.results;
        // the search query is new
        if (this.isNew || !Object.keys(obj).length) {
            this.results = res;
            return;
        }
        // enrich searchResult with newly fetched
        for (var key in obj)
            if (obj.hasOwnProperty(key)) {
                obj[key] = obj[key].concat(res[key]);
            }
    };
    Object.defineProperty(AppSearchService.prototype, "isTypeActive", {
        get: function () {
            return this.type === 'active';
        },
        enumerable: true,
        configurable: true
    });
    return AppSearchService;
}());
export { AppSearchService };
