import { Injectable }           from '@angular/core';
import {AddressInterface} from '../interfaces/user.interface';
import AppValues from '../common/app.values';
import {Address} from '../../../swagger-gen__output_dir';

interface GoogleMapsCoordinatedInterface {
    latitude?: number;
    longitude?: number;
    nameAddress?: AddressInterface|string;
}

@Injectable()
export class GoogleMapService {

    public _w: any;
    private place_href  = "https://www.google.com/maps/place/";
    private dir_href    = "https://www.google.com/maps/dir/";

    constructor() {
        this._w = window;
    }


    /**
     * Open in new tab Google Map by URL
     * Open map by coord and description:
     * `https://www.google.com/maps/dir/?api=1&origin=` + coordinates.latitude + ',' + coordinates.longitude + `&destination=` + nameAddress;
     * Open map by address:
     * `https://www.google.com/maps/dir/Current+Location/`+ address
     * To make a route in google map with url and parameters between origin and destination:
     * `https://www.google.com/maps/dir/latitudeFrom,longitudeFrom/latitudeTo,longitudeTo`
     *
     * Create Google Maps links based on address or coordinates:
     * https://www.google.com/maps/place/coordinates.latitude + ',' + coordinates.longitude
     * https://www.google.com/maps/place/nameAddress
     *
     * @param {{to?: GoogleMapsCoordinatedInterface; from?: GoogleMapsCoordinatedInterface}} coordinates
     */
    goToGoogleMaps(coordinates: {to?: GoogleMapsCoordinatedInterface, from?: GoogleMapsCoordinatedInterface}): void {
        let href: string;

        if ((coordinates.to && !coordinates.from) || (coordinates.from && !coordinates.to)) {
           href = this.openPlaceInGoogleMaps(coordinates.to);
        } else if (coordinates.to && coordinates.from) {
            href = this.openDirationInGoogleMaps(coordinates.from, coordinates.to);
        }

        this._w.open(href);
    }

    private openPlaceInGoogleMaps(point: GoogleMapsCoordinatedInterface): string {
        if (typeof point.nameAddress === 'undefined' && this.isAvailableCoordinates(point)) {
            return this.place_href + point.latitude + "," + point.longitude;

        } else if (typeof point.nameAddress !== 'string') {
            let address = point.nameAddress.address1 + ' ' + point.nameAddress.city + ' ' + point.nameAddress.state + ' ' + point.nameAddress.postCode;
            return this.place_href + address;

        } else {
            return this.place_href + point.nameAddress;
        }
    }

    private openDirationInGoogleMaps(from: GoogleMapsCoordinatedInterface, to: GoogleMapsCoordinatedInterface): string {

        const pointFrom = this.getStringPoint(from);
        const pointTo   = this.getStringPoint(to);

        return this.getDirectAddress(pointFrom, pointTo);
    }

    private isAvailableCoordinates(point: GoogleMapsCoordinatedInterface): boolean {
        return typeof point.latitude !== 'undefined' && typeof point.longitude !== 'undefined';
    }

    private isObjectAddress(point: GoogleMapsCoordinatedInterface): boolean {
        return typeof point.nameAddress === 'object' && typeof point.nameAddress === 'object';
    }
    private isStringAddress(point: GoogleMapsCoordinatedInterface): boolean {
        return typeof point.nameAddress === 'string' && typeof point.nameAddress === 'string';
    }

    private getStringPoint(point: GoogleMapsCoordinatedInterface): string {

        if (this.isObjectAddress(point)) {
            return AppValues.getStringAddress(point.nameAddress as Address);
        } else if (this.isStringAddress(point) && point.nameAddress as string !== '') {
            return point.nameAddress as string;
        } else {
            return point.latitude + ',' + point.longitude;
        }
    }

    public getDirectAddress(from: string, to: string): string {
        return this.dir_href + from + '/' + to;
    }
}
