
        <section class="chat-list__timeblock">

            <p class="timeblock__time-messages"
               data-test-id="timeblock__time-messages_">{{messageGroup.date | date:formatFullDate}}</p>

            <div class="timeblock__list-items">

                <section class="timeblock__list-item" *ngFor="let msg of messageGroup.messages; trackBy: trackByID">

                    <div class="message__container"
                         [ngClass]="msg['isSentByMe'] ? 'msg-right' : 'msg-left'">

                        <div [ngClass]="msg['isSentByMe'] ? 'my-message' : 'interlocutor-message'">
                            <h5 class="time-message" data-test-id="time-message_">{{msg['dateHHmm']}}</h5>

                            <p class="text-message" data-test-id="text-message_" [innerHTML]="linkify(msg.text)"></p>

                        </div>

                    </div>

                </section>

            </div>
        </section>
    