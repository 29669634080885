import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';


import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';

import { DataService } from '../services/data.service';
import { ErrorInterface, ErrorService } from '../services/error.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { ModalService } from '../modal/modal.service';
import { UserService } from '../user/user.service';
import { UserSessionInterface } from '../interfaces/user.interface';
import { PaymentReportInterface } from './cart-report.interface';
import { CookieService } from '../services/cookie.service';
import AppValues from '../common/app.values';
import { StorageService } from '../services/storage.service';
import { PaymentNotification } from '../../../swagger-gen__output_dir/model/paymentNotification';
import { CouponsService } from '../../../swagger-gen__output_dir/api/coupons.service';
import { TranslateService } from '@ngx-translate/core';
import { ShoppingCartService } from '../../../swagger-gen__output_dir/api/shoppingCart.service';
import { ShoppingCartResponseBody } from '../../../swagger-gen__output_dir/model/shoppingCartResponseBody';
import { ShoppingCartElement } from '../../../swagger-gen__output_dir/model/shoppingCartElement';
import { ShoppingCartElementToSpecifyDeliveryMethod } from '../../../swagger-gen__output_dir/model/shoppingCartElementToSpecifyDeliveryMethod';
import { UpdateAddInfoFromSCResponseBody } from '../../../swagger-gen__output_dir/model/updateAddInfoFromSCResponseBody';
import { RemoveShoppingCartElementsRequestBody } from '../../../swagger-gen__output_dir/model/removeShoppingCartElementsRequestBody';
import { AddItemToSCRequestBody } from '../../../swagger-gen__output_dir/model/addItemToSCRequestBody';
import { AddItemToSCResponseBody } from '../../../swagger-gen__output_dir/model/addItemToSCResponseBody';
import { ShoppingCartElementToAdd } from '../../../swagger-gen__output_dir/model/shoppingCartElementToAdd';
import {
    Address,
    AppliedCouponView,
    CountersService,
    Delivery,
    GetCounterResponse,
    OrdersService,
    ShoppingCartConfirmationRequestBody,
    ShoppingCartConfirmationResponseBody,
} from '../../../swagger-gen__output_dir';
import { AbandonedProduct } from './abandonedcart.class';
import { ClientPaymentService } from '../payment/payment.service';
import { PaymentView } from '../../../swagger-gen__output_dir/model/paymentView';
import { ItemService } from '../services/item.service';
import { AppRouteValues } from '../common/app.route.values';
import { Subscription } from 'rxjs/Subscription';
import { TabStatus } from '../services/check-tab-status';
import Timer = NodeJS.Timer;
import { CartTemplateInterface, ShoppingCartElementTemplateInterface } from '../interfaces/cart.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { getErrorType } from '../services/error.helpers';

@Injectable()
export class CartService implements OnDestroy {

    public cart:                       ShoppingCartResponseBody;
    public windowLocation:             Location;

    private buy_now_flag:       boolean;
    private purchaseSubject:    Subject<{}> = new Subject<{}>();
    private paymentViewSubject: Subject<null> = new Subject<null>();
    private totalSubject:       Subject<number> = new Subject<number>();
    private userSession:        UserSessionInterface = {};
    private abandonedItemsIDs:  string[] = new Array;
    private totalAmount:        number;
    private cartMemento:        ShoppingCartElement[] = [];
    private countsChanges$:         Subject<GetCounterResponse> = new Subject();

    public abandonedNotifyTimer:    Timer;
    public abandonedBlinkTabTimer:  Timer;
    public clientToken:             string = '';
    public paymentRequestIntentSubscribe: Subscription;
    public selectNextViewSubject: Subject<AppRouteValues> = new Subject<AppRouteValues>();
    public confirmationCartSubject: Subject<ShoppingCartConfirmationResponseBody> = new Subject<ShoppingCartConfirmationResponseBody>();

    public get countsChanges(): Observable<GetCounterResponse> {
        return this.countsChanges$.asObservable();
    }

    public constructor(
        private countersService:        CountersService,
        private shoppingCartService:    ShoppingCartService,
        private dataService:            DataService,
        private errorService:           ErrorService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private modalService:           ModalService,
        private router:                 Router,
        private cookieService:          CookieService,
        private injector:               Injector,
        private storageService:         StorageService,
        private ordersService:          OrdersService,
        private couponsService:         CouponsService,
        private translate:              TranslateService,
        private abandonedProductClass:  AbandonedProduct,
    ) {
       this.windowLocation = window.location;

       this.user_service.onResetAbandonedItemsSubjectChanges().subscribe(() => {
           this.resetAbandonedItems();
       });

       document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === TabStatus.hidden) {
              this.abandonedProductClass.changeTabStatus(TabStatus.hidden);
              if (this.cart) {
                  this.abandonedProductClass.updateAbandonedProducts(this.cart.items, true);
              }
              this.abandonedNotify();
          }
          if (document.visibilityState === TabStatus.visible) {
              this.abandonedProductClass.changeTabStatus(TabStatus.visible);
          }
        });
    }

    public changeCounts(token: string): void {
        this.countersService.getCountersForUserGet(token)
            .subscribe((counters: GetCounterResponse) => {
                return this.countsChanges$.next(counters);
            }, (err: ErrorInterface) => this.errorService.handleError(err));
    }

    public onConfirmationShoppingCartAndBuy(payData: {isPayment: boolean, isPayFromCart: boolean}): void {
        this.setBuyNowCheck(payData.isPayment);
        // the 1st step of purchasing transaction -- Confirmation
        if (!this.isValidCart()) {
            return;
        }

        this.confirmationShoppingCart().subscribe((shoppingCart: ShoppingCartConfirmationResponseBody) => {
            this.calculateTotalAmount(shoppingCart);
            this._successPurchaseHandleEvent('shopping_cart_confirmation', 'user_id: ' + shoppingCart.elements.user_id);

            if (payData.isPayment) {
                this.payment_service.paymentRequestIntent()
                    .subscribe((paymentView: PaymentView) => {
                        this.clientToken = paymentView.operation_key;

                        this._successPurchaseHandleEvent('create_stripe_payment', 'with pay_key: ' + paymentView.id);
                        this.setPaymentTokenAndKey(paymentView.id);

                        this.paymentViewSubject.next();
                        this.confirmationCart({shoppingCart, ...payData});
                    }, (err: HttpErrorResponse) => this._handlePurchaseError(err, 'create_stripe_payment'));
            } else {
                this.confirmationCart({shoppingCart, ...payData});
            }
                return shoppingCart;
            },
            (err: ErrorInterface | any) => {
                this._handlePurchaseError(err, 'shopping_cart_confirmation');
            },
        );
    }

    public confirmationCart(payData: {isPayment: boolean, isPayFromCart: boolean, shoppingCart: ShoppingCartConfirmationResponseBody}): void {
        this.confirmationCartSubject.next(payData.shoppingCart);
    }
    public get onChangePaymentView(): Observable<null> {
       return this.paymentViewSubject.asObservable();
    }
    public get onChangeTotalAmount(): Observable<number> {
        return this.totalSubject.asObservable();
    }

    public get onSelectNextView(): Observable<AppRouteValues> {
       return this.selectNextViewSubject.asObservable();
    }

    public get onChangeCartConfirmationResponse(): Observable<ShoppingCartConfirmationResponseBody> {
        return this.confirmationCartSubject.asObservable();
    }

    public get isEmptyCart(): boolean {
        return !this.cart.items.length;
    }

    public get isCartWithoutDelivery(): boolean {
        return this.cart.items.some((item: ShoppingCartElement) => item.delivery === null);
    }

    public createDeliveryObj(method: string, delivery_address?: Address): Delivery {
        return delivery_address ? { method, delivery_address } : {method};
    }

    public confirmationShoppingCart(): Observable<ShoppingCartConfirmationResponseBody> {
        const confirmArr   = [];

        this.userSession   = this.user_service.getUserSession();
        const token        = this.userSession.token;

        const items        = this.cart.items;

        items.forEach((item: ShoppingCartElement) => {
            if (item.delivery !== null) {
                const sc_element: ShoppingCartElementToSpecifyDeliveryMethod = {
                    ID:                 item.ID,
                    delivery:           {
                        method: item.delivery.method,
                    },
                };

                if (item.delivery.method === AppValues.US_DELIVERY) {
                    sc_element.delivery.delivery_address = this.user_service.primaryAddress();
                }

                confirmArr.push(sc_element);
            }
        });


        if (token) {
            const data: ShoppingCartConfirmationRequestBody = {sc_elements: confirmArr};

            return new Observable((observer) => {
                this.shoppingCartService.shoppingCartConfirmationPost(data, token)
                    .subscribe(
                        (res: ShoppingCartConfirmationResponseBody) => {
                            observer.next(res);
                        }, (err: ErrorInterface | any) => {
                            observer.error(err);
                            this.errorService.handleError(err);
                            observer.complete();
                        },
                    );
            });
        }

    }

    public setBuyNowCheck(buy_now: boolean) {
        this.buy_now_flag = buy_now;
    }


    public isValidCart(): boolean {
        const items: ShoppingCartElement[]  = this.cart.items;
        let message: string = '';

        // are there any expired goods?
        if (~items.findIndex((item) => item['_expiresInFormatted'] === '00:00')) {
            message = this.translate.instant('shoppingCart.error.expiredOrderItems');

        // are there any zero-quantity items?
        } else if (~items.findIndex((item) => item['quantity'] === 0)) {
            message = this.translate.instant('shoppingCart.error.zeroQtyItem');
        }

        if (message) {
            this.modalService.error({
                title:          this.translate.instant('common.alert.title'),
                message,
                yesButtonText:  this.translate.instant('modal.warning.yesButton'),
            });

            return false;
        }

        return true;
    }


    private _handlePurchaseError(err: HttpErrorResponse, event_action?: string): void {
        event_action && this._errorPurchaseHandleEvent(event_action, err);

        this.purchaseSubject.next(null);

        this.modalService.error(
            {
                title: this.translate.instant('shoppingCart.createPaypalPayment.error.title'),
                message: getErrorType(err),
                yesButtonText: this.translate.instant('shoppingCart.createPaypalPayment.error.yesButtonText'),
            });
    }


    public getPurchaseNotification(): Observable<{}> {
        return this.purchaseSubject.asObservable();
    }


    public getCart(): Observable<ShoppingCartResponseBody | {}> {
        this.userSession = this.user_service.getUserSession();
        const token      = this.userSession.token;

        if (token) {
            this.modalService.showSpinner();

            return this.shoppingCartService.shoppingCartGet(token)
                .do((res: ShoppingCartResponseBody) => {
                    this.cart = res;
                    this.modalService.close();

                    return this.cart;
                }, (err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({});
    }


    public setPaymentTokenAndKey(pay_key: string): void {
        this.cookieService.setCookie('pay_key', pay_key);
        this.cookieService.setCookie('payment_token', this.userSession.token);

        this.storageService.setPurchaseMemory(this.user_service.getToken());
    }


    public getReport(): Observable<PaymentNotification|{}> {
        const payment_token = this.cookieService.getCookie('payment_token');
        const pay_key = this.cookieService.getCookie('pay_key');

        if (!payment_token && !pay_key) {
            this.modalService.success({
                title:          'Thanks for using our service!',
                yesButtonText:  'Close',
            }).then(() => this.router.navigate(['/']));

            return Observable.of({});
        }

        return this.ordersService.getPaymentNotificationGet(pay_key, payment_token)
            .do(() => {
                setTimeout(() => this.router.navigate(['/cart-report']));
            })
            .catch((err: ErrorInterface) => {
                this._errorPurchaseHandleEvent('get_payment_notification', err);

                return this.errorService.handleError(err);
            });
    }

    /**
     * Preparing data before send GA marker
     * @param {PaymentReportInterface} reports
     * @private
     */
    public checkout_from_buy_now(reports: PaymentNotification) {
        const payment_token = this.cookieService.getCookie('payment_token');

        if (this.buy_now_flag === true) {
           this._successPurchaseHandleEvent('success_checkout_from_buy_now', 'with payment_token: ' + payment_token);
        } else { this._successPurchaseHandleEvent('success_checkout_from_shopping_cart', 'with payment_token: ' + payment_token); }

        /** Success Checkout (any type: from shopping cart or buy now) **/
        this.googleAnalyticsService.handleEvent('success_checkout', 'purchase', 'open_cart_report');
        this._successPurchaseHandleEvent('get_payment_notification', 'with payment_token: ' + payment_token);
        this.googleAnalyticsService.handlePurchase(reports);
        /** **/

        this.setBuyNowCheck(false);

        /*** Remove payment data from Cookies **/
        this.cookieService.deleteCookie('pay_key');
        this.cookieService.deleteCookie('payment_token');
        /** **/
    }

    public _successPurchaseHandleEvent(event_name: string, event_label?: string) {
        this.googleAnalyticsService.handleEvent( event_name, 'purchase', 'success ' + event_label );
    }

    public _errorPurchaseHandleEvent(event_action: string, err: any) {
        let event_label = '';
        if ( err.status ) { event_label =  err.status + ' ' + getErrorType(err); } else { event_label =  err; }

        this.googleAnalyticsService.handleErrorEvent( event_action, 'purchase', 'error ' + event_label );
    }


    public addItem(cartData: {shoppingCartElements: ShoppingCartElementToAdd[], sellerID?: string, showNotification: boolean, isLocationBack: boolean}): Observable<AddItemToSCResponseBody> {
        const user = this.user_service.getUserSession();

        if (!cartData.shoppingCartElements) {
            return;

        } else if (user.ID === cartData.sellerID) {
            return this.errorService.handleError({status: undefined, statusText: 'You cannot buy from yourself!'});
        }

        const body: AddItemToSCRequestBody = {items: cartData.shoppingCartElements};

        return this.shoppingCartService.addItemToScPost(body, user.token)
            .do((res: AddItemToSCResponseBody) => {
                this.purchaseSubject.next(res);
                this._abandonedItems(res.elements.sc_elements);
                this.cart.items = res.elements.sc_elements;

                if (cartData.showNotification) {
                    this.showNotification(this.translate.instant('product.details.addToShoppingCart.success.body'), cartData.isLocationBack);
                }
            }, (err: ErrorInterface) => {
                this.purchaseSubject.next(null);
                return this.errorService.handleError(err).subscribe(() => null, (errorText: string) => {
                   if (err.status !== 401) {
                       this.modalService.warning({title: this.translate.instant('addItemToScPost.error.modal.title'), message: errorText, yesButtonText: this.translate.instant('addItemToScPost.error.modal.button')});
                    }
                    return err;
                });
            });
    }


    public removeItem(ID: string, isLocationBack: boolean = true): Observable<{} | ErrorInterface> {
        if (!ID) { return; }

        const body: RemoveShoppingCartElementsRequestBody  = { ids: [ ID ] };

        const token: string = this.user_service.getUserSession().token;

        return this.dataService.postData('delete_item_from_sc', body, { token })
        .map(() => {
            this.abandonedItemsIDs = this.abandonedProductClass.removeOneAbandonedItem(ID, this.abandonedItemsIDs);
            this.showNotification(this.translate.instant('product.details.removeFromShoppingCart.success.body'), isLocationBack);
            return {};
        }).catch((err: ErrorInterface) => {
          return this.errorService.handleError(err);
        });
    }


    private _abandonedItems(items: ShoppingCartElement[] | string): void {
        this.editAbandonedItemArray(items);

        this.abandonedItemsIDs = this.abandonedProductClass.createrOfAbandonedItemsIDs();

        this.abandonedNotifyTimer = setInterval(() => this.abandonedNotify(), 5000);
        this.abandonedBlinkTabTimer = setInterval(() => this.abandonedItemsIDs.length !== 0 && this.abandonedProductClass.abandonedItemsTabNotify(), 500);
    }

    public abandonedNotify(): void {
        if (this.cart) {
            this.editAbandonedItemArray(this.cart.items);
            this.abandonedItemsIDs = this.abandonedProductClass.createrOfAbandonedItemsIDs();

            if (this.abandonedItemsIDs.length !== 0) {
                this.checkExpirationOfProduct();
            }
        }
    }

    public resetAndRefreshAbandonedNotify(): void {
        this.resetAbandonedItems();
        this.abandonedNotify();
    }

    public resetAbandonedItems(): void {
        this.abandonedItemsIDs = [];
        this.stopAbandonedTimer();
    }


    private editAbandonedItemArray(items: ShoppingCartElement[] | string): void {
        if (this.abandonedItemsIDs.length === 0 && typeof items === 'object') {
            this._addItemToAbandonedArr(items);

        } else {
            if (typeof items === 'string') {
                this.abandonedItemsIDs = this.abandonedProductClass.removeOneAbandonedItem(items, this.abandonedItemsIDs);
            }
        }
    }


    private _addItemToAbandonedArr(items: ShoppingCartElement[]) {
        this.abandonedItemsIDs = this.abandonedProductClass.updateAbandonedProducts(items);
    }


    private checkExpirationOfProduct(): void {
        this.abandonedItemsIDs.forEach((id: string) => {
            this.abandonedProductClass.checkExpirationOfProduct(id, this.windowLocation).subscribe((isShowNotiication: boolean) => {
                if (isShowNotiication) {
                    this.abandonedItemsIDs = this.abandonedProductClass.removeOneAbandonedItem(id, this.abandonedItemsIDs);
                }
            });
        });
    }

    public get user_service(): UserService {
        return this.injector.get(UserService);
    }
    public get payment_service(): ClientPaymentService {
        return this.injector.get(ClientPaymentService);
    }


    private showNotification(messageText: string, locationBack: boolean = true): void {
        this.modalService.success({
            title:          this.translate.instant('product.details.addToShoppingCart.success.title'),
            message:        messageText,
            yesButtonText:  this.translate.instant('product.details.addToShoppingCart.success.btn.ok'),
            locationBack,
        });
    }


    public get getCartMemento(): ShoppingCartElement[] {
        return this.cartMemento;
    }
    public set setCartMemento(cart: ShoppingCartElement[]) {
        this.cartMemento = cart;
    }

    public changingOfCartItemPrices(cartOld: ShoppingCartElement[], cartNew: ShoppingCartElement[]): ShoppingCartElementTemplateInterface[] {
        const cart: ShoppingCartElementTemplateInterface[] = cartNew;
        cartNew.forEach((itemNew: ShoppingCartElement, index: number) => {
            cartOld.forEach((itemOld: ShoppingCartElement) => {
                if (itemNew.ID === itemOld.ID) {
                    (itemNew as ShoppingCartElementTemplateInterface).isItemWasChange = this.itemService.itemPriceFn(itemNew.item) !== this.itemService.itemPriceFn(itemOld.item);
                }
                cart[index] = itemNew;
            });
        });
       return cart;
    }
    public isChangingOfCartItemPrices(cart: CartTemplateInterface): boolean {
        return cart.items.some((item: ShoppingCartElementTemplateInterface) => item.isItemWasChange);
    }
    public initAdditionalCartItemParams(cart: CartTemplateInterface, isExpiredOrderTime?: boolean): CartTemplateInterface {
        cart.items.map((item: ShoppingCartElementTemplateInterface) => {
            item.isItemWasChange = false;
            if (isExpiredOrderTime) {
                item['_expiresInFormatted'] = '10:00';
            }
        });
        return cart;
    }

    public save(part: ShoppingCartResponseBody): Observable<UpdateAddInfoFromSCResponseBody> {
        const token = this.user_service.getUserSession().token;
        if (!token) { return; }

        return this.shoppingCartService.updateAddinfoFromScPost(part, token)
            .do((shoppingCart: UpdateAddInfoFromSCResponseBody) => {
                this._abandonedItems(shoppingCart.elements.sc_elements);
                return shoppingCart;
            }, (err: ErrorInterface) => this.errorService.handleError(err));
    }

    public getItemID(id: string): string {
        const item = this.cart.items.find((i: ShoppingCartElement) => id === i.item.ID);
        return item ? item.ID : '';
    }

    public applyCoupon(coupon_code: string): Observable<AppliedCouponView | {}> {
        if (this.user_service.getUserSession().token) {
            return this.couponsService.applyCouponPost({coupon_code}, this.user_service.getUserSession().token)
                .do((res) => null, (err: ErrorInterface) => this.errorService.handleError(err));
        }
        return Observable.of({});
    }

    public applyCouponDelete(): Observable<{}> {
        if (this.user_service.getUserSession().token) {
            return this.couponsService.applyCouponDelete(this.user_service.getUserSession().token)
                .map((res: {}) => res)
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }
        return Observable.of({});
    }

    public stopAbandonedTimer(): void {
        this.abandonedNotifyTimer && clearInterval(this.abandonedNotifyTimer);
        this.abandonedBlinkTabTimer && clearInterval(this.abandonedBlinkTabTimer);

        this.abandonedProductClass.removeAllAbandonedItemsFromStage();
    }

    public get itemService(): ItemService {
        return this.injector.get(ItemService);
    }

    public calculateTotalAmount(shoppingCart: ShoppingCartConfirmationResponseBody): void {
        let prices: number[] = [0];

        if (shoppingCart.elements.sc_elements.length) {
            prices = shoppingCart.elements.sc_elements.map((element: ShoppingCartElement) => {
                return element.quantity * this.itemService.itemPriceFn(element.item);
            });
        }

        if (!this.cart.discount) {
            this.setTotal(prices.reduce((a: number, b: number) => a + b));
        } else {
            this.setTotal(this.cart.discount.total);
        }
    }

    public setTotal(totalAmount: number) {
        this.totalAmount = totalAmount;
        this.totalSubject.next(totalAmount);
    }
    public get total(): number {
        return this.totalAmount;
    }
    public ngOnDestroy(): void {
        if (this.paymentRequestIntentSubscribe) {
            this.paymentRequestIntentSubscribe.unsubscribe();
        }
    }

}
