import { FormGroup, FormControl,
    AbstractControl, ValidatorFn }  from '@angular/forms';
import {CenterLocationInterface} from '../../interfaces/location.interface';
import {UserModel} from '../../interfaces/user.interface';


/*** Custom validators ***/
export function antiPatternMatcher(antiPattern: RegExp): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        return control.value.match(antiPattern) ? {pattern: true} : null;
    };
}


export function duplicateLogin(formGroup: FormControl): null | { mismatched: boolean } {
    let exactMatch: boolean = false;
    const formControl: FormGroup = (formGroup.root as FormGroup);

    if (!formControl || !formControl.controls)  return null;

    const login_value: string = formControl.controls['login'].value;
    const login_value2: string = formControl.controls['login2'].value;

    if (!login_value2 || login_value && login_value2 && login_value.toUpperCase() === login_value2.toUpperCase()) {
        exactMatch = true;
    }
    return exactMatch ? null : { mismatched: true };
}


export function duplicatePassword(formGroup: FormControl) {
    let exactMatch: boolean = false;
    const formControl: FormGroup = (formGroup.root as FormGroup);
    if (!formControl || !formControl.controls)  return null;

    const password_value: string = formControl.controls.password.value;
    const password_value2: string = formControl.controls.password2.value;

    if (!password_value2 || password_value && password_value2 && password_value === password_value2) {
        exactMatch = true
    }
    return exactMatch ? null : { mismatched: true };
}

export function duplicate(input: any) {

    if (input.password.value === input.password2['value'] &&
        input.password.value !== '' && input.password2['value'] !== '') {
        input.password.errors && input.password.errors.mismatched && delete input.password.errors.mismatched;
        input.password2['errors'] && input.password2['errors'].mismatched && delete input.password2['errors'].mismatched;

    }
    if (input.login.value === input.login2['value'] &&
        input.login.value !== '' && input.login2['value'] !== '') {
        input.login.errors && input.login.errors.mismatched && delete input.login.errors.mismatched;
        input.login2['errors'] && input.login2['errors'].mismatched && delete input.login2['errors'].mismatched;
    }
}
export function duplicateField(formGroup: FormControl, fieldName1: string, fieldName2: string): null | { mismatched: boolean } {
    let exactMatch: boolean = false;

    const FormControl = (formGroup.root as FormGroup);
    if (!FormControl || !FormControl.controls
        || !FormControl.controls[fieldName1] || !FormControl.controls[fieldName2]
        || !FormControl.controls[fieldName1].value || !FormControl.controls[fieldName2].value
    )  return null;

    if (FormControl.controls[fieldName1].value === FormControl.controls[fieldName2].value) {
        exactMatch = true;
    }

    return exactMatch ? null : { mismatched: true };
}

export function userNotNullFields(user: UserModel) {
    Object.keys(user).forEach((s: string) => {
       switch (s) {
           case 'webPage':
           case 'imageURL':
               if (user[s] == null){
                   user[s] = '';
               }
               break;
           default:
               break;
       }
    });

    return user;
}



/*** helpers ***/
export function getLocation(successCallback, errorCallback): any {
    const options = {
        maximumAge: 30000,
        timeout: 5000,
        enableHighAccuracy: false
    };

    if (navigator.geolocation) {
        return navigator.geolocation
            .getCurrentPosition(successCallback, errorCallback, options);
    } else {
        return errorCallback('Geolocation is not supported by this browser.');
    }
}

export function setGeolocation() {
        let locationPromise = new Promise<CenterLocationInterface>(resolve => {
            getLocation(resolve, (error) => {
                let msg;
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        msg = "User denied the request for Geolocation.";
                        break;
                    case error.POSITION_UNAVAILABLE:
                        msg = "Location information is unavailable.";
                        break;
                    case error.TIMEOUT:
                        msg = "The request to get user location timed out.";
                        break;
                    case error.UNKNOWN_ERROR:
                        msg = "An unknown error occurred.";
                        break;
                }

                this.errorService.handleError(msg);
            });
        });

        locationPromise.then(location => {
            let geoPosition = {
                longitude:  location['coords'].latitude,
                latitude:   location['coords'].longitude
            };
            return geoPosition;
        });
    }

// function geoSuccess(position) {
//     return Promise.resolve({
//         latitude:   position.coords.latitude,
//         longitude:  position.coords.longitude
//     });
// }
//
// function geoErr(err) {
//     return Promise.resolve(err);
// }


