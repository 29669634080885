import { Location } from "@angular/common";
import {
    AfterContentInit,
    Component,
    OnDestroy,
    OnInit
} from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Validators
} from "@angular/forms";

import {
    Subject,
    Subscription
} from "rxjs";
import { Observable } from "rxjs/Observable";

import { TranslateService } from "@ngx-translate/core";

import AppValues from "../common/app.values";
import { CenterLocationInterface } from "../interfaces/location.interface";
import { AddressInterface } from "../interfaces/user.interface";
import { ModalService } from "../modal/modal.service";
import {
    GeoCoderService,
    ReverseGeocodingAddressInterface
} from "../services/geocoder.service";
import { GeoLocationService } from "../services/geolocation.service";
import { antiPatternMatcher } from "../user/helpers/_helpers";
import { ProfileService } from "./profile.service";

import Timer = NodeJS.Timer;

@Component({
    selector:       'addr-editor',
    styleUrls:      ['profile.sass'],

    // animations:     [fadeInAnimation],
    // host:           { '[@fadeInAnimation]': '' },

    templateUrl:    './addr-editor.component.html'
})
export class AddressEditorComponent implements OnInit, AfterContentInit, OnDestroy {

    public addrForm:                   FormGroup;
    public countrySubscription:        Subscription;
    public addrObj:                    { addr: AddressInterface | null, isOnly: boolean };
    public countries                   = AppValues.countries;
    public isPrimary                   = false;
    public states                      = AppValues.states;
    public location:                   CenterLocationInterface = {
        longitude: 0,
        latitude: 0
    };
    public timeout: Timer              = null;

    private geocodingSubject: Subject <boolean> = new Subject<boolean>();
    private isInProgressGeoCoding:  boolean = false;
    private isDeactivate            = false;

    constructor(
        private fb:                         FormBuilder,
        private profileService:             ProfileService,
        private modalService:               ModalService,
        private geoLocationService:         GeoLocationService,
        private geoCoderService:            GeoCoderService,
        private translate:                  TranslateService,
        private locationCommon:             Location
    ) { }


    public ngOnInit(): void {
        this.buildForm();
        this.geocodingSubject.asObservable().subscribe((isSuccess: boolean) => {
            this.isInProgressGeoCoding = !isSuccess;
        });
    }


    public ngAfterContentInit(): void {
        this._setAddress();
    }

    public ngOnDestroy(): void {
        if (this.countrySubscription) {
            this.countrySubscription.unsubscribe();
        }
    }

    public locateMe(): void {
        if (!this.isInProgressGeoCoding) {
            this.geocodingSubject.next(false);

            this.modalService.showSpinner();

            this.hangeWithTimeOut().subscribe((l: ReverseGeocodingAddressInterface) => {
                this.modalService.close();
                this.geocodingSubject.next(true);
            }, (err: string) => {
                this.modalService.error({
                    title: this.translate.instant('common.alert.title'),
                    message: err,
                    noButtonText: this.translate.instant('common.ok')
                });
                this.geocodingSubject.next(true);
            });
        }
    }

    public blurField(event: Event): void {
        const controlName: string = event.target['name'];
        let controlValue: string = event.target['value'];
        controlValue = controlValue.trim();
        this.addrForm.controls[controlName].setValue(controlValue);
        this.addrForm.controls[controlName].markAsTouched();
    }

    private hangeWithTimeOut(): Observable<ReverseGeocodingAddressInterface> {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        return new Observable(observer => {
            this.timeout = setTimeout(() => {
                this.coordinatesToAddress()
                    .subscribe((location: ReverseGeocodingAddressInterface) => {
                        observer.next(location);
                    }, (err: string) => observer.error(err));
            }, 1000);
        });
    }

    private addressToCoordinates(address: string): Observable<CenterLocationInterface> {
        this.modalService.showSpinner();

        return new Observable(observer => {
            this.geocodeAddress(address)
                .subscribe((location: CenterLocationInterface) => {
                        this.location = location;
                        this.modalService.close();
                        observer.next(location);
                    }, (err: string) => {
                        observer.error(err);
                    }
                );
        });
    }

    private coordinatesToAddress(): Observable<ReverseGeocodingAddressInterface> {
        this.isDeactivate = false;


        return new Observable(observer => {
            this.reverseGeocodeAddress()
                .subscribe((location: ReverseGeocodingAddressInterface) => {
                        this.addrObj.addr = this.geoCoderService.getFormatGoogleAddress(this.addrObj.addr, location.address_components);

                        this.isDeactivate = true;

                        this.location = {
                            longitude: this.profileService.geoLocation.longitude,
                            latitude: this.profileService.geoLocation.latitude
                        };

                        this.addrObj.addr.firstName = this.addrForm.controls['firstName'].value;
                        this.addrObj.addr.lastName = this.addrForm.controls['lastName'].value;

                        this.addrForm.patchValue(this.addrObj.addr);
                        observer.next(location);

                    }, (err: string) => {
                        observer.error(err);
                    }
                );
        });
    }

    isDiffLocation(loc: CenterLocationInterface) {
        return (this.profileService.userProfile.longitude !== loc.longitude && this.profileService.userProfile.latitude !== loc.latitude) &&
            (this.profileService.geoLocation.longitude !== loc.longitude && this.profileService.geoLocation.latitude !== loc.latitude);
    }

    /**
     * Get coordinates (converted Address to Coordinates)
     * @param {string} address
     * @return {Observable<CenterLocationInterface>}
     */
    geocodeAddress(address: string): Observable<CenterLocationInterface> {
        return new Observable(observer => {
            this.geoCoderService.geocodeAddress(address).subscribe((res: CenterLocationInterface) => {
                this.profileService.profileLocation = res;

                return observer.next(res);
            }, (err: string) => observer.error(err));
        });
    }

    reverseGeocodeAddress(): Observable<ReverseGeocodingAddressInterface> {
      let location = this.geoLocationService.location;

      if (!location) {
          return new Observable(observer => {
              this.geoLocationService.geoLocationObserver().subscribe((userLocation: CenterLocationInterface) => {
                  this.geoCoderService.reverseGeocodingAddress(userLocation).subscribe(res => {
                      this.profileService.profileLocation = userLocation;

                      observer.next(res);
                  }, err => observer.error(err));
              }, err => observer.error(err));
          });
      } else {
          return new Observable(observer => {
              this.geoCoderService.reverseGeocodingAddress(location).subscribe(res => {
                  this.profileService.profileLocation = location;

                  observer.next(res);
              }, err => observer.error(err));
          });
      }
  }


    /**
     * @desc If the address fetched from ProfileService is substantial presets
     * the fields of the form and also presets @isPrimary checkbox.
     * @private
     */
    _setAddress() {
        this.addrObj = this.profileService.getAddress;
        const addr   = this.addrObj.addr;

        if (addr) {
            if (this.profileService.userProfile.addresses.length === 0 ) {
                this.isPrimary = true;
            } else {
                this.isPrimary = addr.isPrimary;
            }
            this.addrForm.patchValue(addr);
        }
    }

    private findInvalidControls() {
        const controls = this.addrForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
               document.getElementsByName(name)[0].classList.add('error');
            }
        }
    }


    /**
     * @desc Checks the form validity. If valid, composes address object to
     * pass it to the ProfileService.
     */
    onSubmit() {
        const x = this.addrForm.value;
        let controls = this.addrForm.controls;

        for (const name in controls) {
            this.addrForm.patchValue({[name]: controls[name].value.trim()});
        }

        if (!this._isValid()) {
            this.findInvalidControls();
            this._showWarning();
            return;
        }

        const address = {
            firstName:          x.firstName,
            lastName:           x.lastName,
            address1:           x.address1.trim(),
            address2:           x.address2.trim(),
            city:               x.city,
            state:              x.state,
            state_abbreviation: x.state,
            postCode:           x.postCode,
            phone:              x.phone,
            country:            x.country,
            isPrimary:          this.isPrimary,
        };

        this.isDeactivate = true;


        this.profileService.changeProfile(address, this.isPrimary);

        if (!AppValues.isObjectEquals(this.profileService.getAddress.addr, address)) {

            this.addressToCoordinates(this.geoCoderService.getFullAddressString(address))
                .subscribe((coordFromAddr: CenterLocationInterface) => {
                    this.profileService.submitAddress(address, coordFromAddr);

            }, (err: string) => err);
        } else {
            this.profileService.submitAddress(address);
        }
    }


    /**
     * Returns form validity.
     * @returns {boolean}
     * @private
     */
    _isValid(): boolean {
        return this.addrForm.valid;
    }


    /**
     * Shows modal error window.
     * @private
     */
    _showWarning() {
        this.modalService
            .error({
                title:          this.translate.instant('profile.editor.addr.warning.title'),
                message:        this.translate.instant('profile.editor.addr.warning.message'),
                yesButtonText:  this.translate.instant('profile.editor.addr.warning.confirm'),
            });
    }


    /**
     * @desc Returns true if the form is pristine or @isDeactivate flag
     * is truthy. Otherwise checks the fields for content. If the form is empty,
     * returns true, if not, shows modal warning dialog.
     */
    public checkChanging(): void {
        if (this.addrForm.pristine || this.isDeactivate) {
            this.locationCommon.back();
        } else {
            const value = this.addrForm.value;

            for (let key in value) if (value[key] !== '') {
                this.modalService
                    .warning({
                        title: this.translate.instant('profile.editor.cancel.warning.title'),
                        message: this.translate.instant('profile.editor.cancel.warning.message'),
                        yesButtonText: this.translate.instant('profile.editor.cancel.warning.confirm'),
                        noButtonText: this.translate.instant('profile.editor.cancel.warning.reject'),
                        reverseButtons: true,
                    })
                    .then((action: boolean) => {
                        if (action) {
                            this.isDeactivate = false;
                            this.locationCommon.back();
                        }
                    });
            }
        }
    }


    /**
     * Builds the form group and subscribes on change country field.
     * @desc Standard fields presetting and validators appointment.
     */
    buildForm(): void {
        this.addrForm = this.fb.group({
            'firstName': [
                '', [Validators.required, Validators.pattern(AppValues.namePattern), Validators.minLength(2), Validators.maxLength(20)]
            ],
            'lastName': [
                '', [Validators.required, Validators.pattern(AppValues.namePattern), Validators.minLength(2), Validators.maxLength(20)]
            ],
            'phone': [
                '', [Validators.required, Validators.pattern(AppValues.phonePattern)]
            ],
            'address1': [
                '', [Validators.required, antiPatternMatcher(AppValues.charsAntiPattern), Validators.minLength(6), Validators.maxLength(100)]
            ],
            'address2': [
                '', [antiPatternMatcher(AppValues.charsAntiPattern), Validators.maxLength(100)]
            ],
            'city': [
                '', [Validators.required, Validators.pattern(AppValues.cityPattern), Validators.minLength(2)]
            ],
            'state': [
                '', [Validators.pattern(AppValues.statePattern)]
            ],
            'postCode': [
                '', [Validators.required, Validators.pattern(AppValues.codePattern), Validators.minLength(2)]
            ],
            'country': [
                '', [Validators.required]
            ]
        });

        this.subscribeToCountryChange();
    }

    /**
     * Dynamically adds/removes required validator.
     */
    subscribeToCountryChange(): void {
        this.countrySubscription = this.addrForm.get('country').valueChanges.subscribe((country: string) => {
            const stateControl = this.addrForm.get('state');
            if (country && country !== 'United States') {
                this.addrForm.patchValue({state: ''});
                stateControl.setValidators([Validators.pattern(AppValues.statePattern)]);
                stateControl.setErrors(null);
            } else {
                if (stateControl.value === '') {
                    stateControl.setErrors({required: true});
                    stateControl.setValidators(Validators.required);
                }
            }
        });
    }

}
