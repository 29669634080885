import {Component, ElementRef, Injector, OnInit} from '@angular/core';
import { NgForm }                           from '@angular/forms';
import { Subject }                          from 'rxjs';

import { ModalService }                     from '../modal/modal.service';
import { UserService }                      from './user.service';
import { DOMElement }                       from '../interfaces/misc.interface';
import {GoogleAnalyticsService} from '../services/google-analytics.service';
import {Router} from '@angular/router';
import {ErrorInterface} from '../services/error.service';
import {UserLoginInterface} from '../interfaces/user.interface';
import {AppFooterService} from "../app-footer/app-footer.service";
import {TranslateService} from "@ngx-translate/core";
import { AppRouteValues } from '../common/app.route.values';
import { getErrorType } from '../services/error.helpers';
// import { fadeInAnimation }                  from '../common/animations/animations';

@Component({
    selector:   'login',
    // stylesUrs:  ['login.sass'],
    // animations: [fadeInAnimation],
    // host:       { '[@fadeInAnimation]': '' },
    template: `
        <div class="component">
            <user-header></user-header>

            <div class="component__container">
                <div class="form__container">

                    <form #loginForm="ngForm" (ngSubmit)="login($event)">

                        <div role="mainForm" class="login__container__content">
                            <figure class="login__container-logo">
                                <div class="login__logo">
                                <img height="58" width="232" src="assets/images/bizibaza-logo.svg" alt="bizibaza&reg;"> 
                                </div>
                            </figure>

                            <label class="input__label clearfix">
                                <input [ngClass]="{ error: loginErr && !username }"
                                       (keyup)="onInputTrimed($event, $event.target.name)"
                                       (blur)="bluredField($event, $event.target.name)"
                                       data-test-id="username" autocapitalize="none" autocomplete="username"
                                       class="input__text" type="text"
                                       [placeholder]="'login.placeholder' | translate" name="username" required autofocus [(ngModel)]="username">
                            </label>

                            <password-field [password]="password" [nameInput]="'password'"
                                            [dataTestIdInput]="'password'"
                                            [isPassErr]="passErr"
                                            (keyUpField)="onPasswordFieldBlurred($event)"
                                            (bluredField)="onPasswordFieldBlurred($event)"></password-field>

                            <checkbox-field [forInput]="'keepLogged'" [dataTestIdInput]="'keepLogged'"
                                            [isCheckboxON]="keepLogged" (onChangeField)="onChangeKeepLogin()"
                                            [textLabel]="'login.keepMeLoggedIn.text' | translate"></checkbox-field>

                            <button class="button__lightgreen login__button" type="submit"
                                    data-test-id="loginBtn"
                                    [disabled]="busy || !password || !username">
                                {{"loginForm.submitButton.text" | translate}}
                            </button>

                            <p>
                                {{ "signup.account.text" | translate }}
                                <button
                                    class="nav__link" type="button"
                                    [routerLink]="'/signup'"
                                    data-test-id="signupBtn"
                                >
                                    {{ "signup.account.link" | translate }}
                                </button>
                            </p>
                        </div>
                    </form>

                </div>
                <p class="resend-email">
                    <button
                        class="nav__link" type="button"
                        [routerLink]="'/email-verification'"
                        data-test-id="resendBtn"
                    >
                        {{ "forgotEmailVerification.button.text" | translate }}
                    </button>
                </p>
                <div class="login__footer">
                    <button
                        #loginLink
                        class="button__default"
                        [routerLink]="['/login-link']"
                        name="login_link_button"
                        data-test-id="loginLinkBtn" type="button"
                        (click)="onClickAnyButtons($event.target.name, 'login_link')"
                    >
                        {{ "forgotLogIn.button.text" | translate }}
                    </button>

                    <button
                        class="button__default"
                        [routerLink]="['/password-reset']"
                        name="forgot_password_button"
                        data-test-id="forgotBtn" type="button"
                        (click)="onClickAnyButtons($event.target.name, 'forgot_password')"
                        role="forgotLink"
                    >
                        {{ "forgotPassword.button.text" | translate }}
                    </button>
                </div>

            </div>
        </div>
    `
})

export class LoginComponent implements OnInit {

    public busy: boolean = false;
    public count_of_event:
        {
            username: number,
            password: number
        } = {
        username: 0,
        password: 0
    };
    public elem:               DOMElement;
    public keepLogged:         boolean = true;
    public loginErr:           boolean;
    public passErr:            boolean;
    public password:           string = '';
    public username:           string = '';
    public redirectUrl:        string;

    constructor(
        private elementRef:                 ElementRef,
        private googleAnalyticsService:     GoogleAnalyticsService,
        private modalService:               ModalService,
        private userService:                UserService,
        private injector:                   Injector,
        private translate:                  TranslateService
    ) { }


    ngOnInit() {
        this.elem = this.elementRef.nativeElement;
        this.redirectUrl = this.userService.getRedirectUrl;
    }

    public onChangeKeepLogin(): void {
        this.keepLogged = !this.keepLogged;
    }

    bluredField(event, name) {
        let obj_key = Object.keys(this.count_of_event);
        if ((this.username !== '' || this.password !== '') &&
            (event.currentTarget.form[0].value === this.username || event.currentTarget.form[1].value === this.password)) {
            for (let i = 0; i < obj_key.length; i++) {
                if (this.count_of_event[obj_key[i]] === 0) {
                    this.googleAnalyticsService.handleVirtualPage('filled_' + obj_key[i]);
                    this.count_of_event[obj_key[i]]++;
                }
            }
        } else if (event.currentTarget.value !== '' && (event.currentTarget.form[0] !== this.username || event.currentTarget.form[1] !== this.password)) {
            for (let i = 0; i < obj_key.length; i++) {
                if (obj_key[i] === name && this.count_of_event[name] === 0) {
                    this.googleAnalyticsService.handleVirtualPage('filled_' + name);
                    this.count_of_event[name]++;
                }
            }
        } else return;
    }


    public onInputTrimed(event: Event, name: string): void {
        this[name] = this[name].trim();
        this.bluredField(event, name);
    }
    public onClickAnyButtons(name: string, category: string): void {
       this.googleAnalyticsService.handleClickButton(name + '_pressed', category, 'click');
    }

    public login(event: Event): boolean {
        this.busy = true;
        this.resetFlags();

        this.modalService.showSpinner();

        let creds: UserLoginInterface = {
            login:      this.username,
            password:   this.password
        };

        this.googleAnalyticsService.handleClickUserLoginButton('login', 'login_button_pressed',  creds);

        this.userService.loggedUserFromComponentSetter(true);

        this.userService
            .login(creds, this.keepLogged)
            .subscribe(
                () => {
                    this.app_footer_service.clearShortNavigationTree();
                    this.app_footer_service.getNavTree(false).subscribe(() => {
                        this._hideSpinner();
                        this.router.navigate([this.getAfterLoginRedirectLink()]).then(() => {
                            this.userService.setRedirectUrl('');
                        })
                        .catch((err: ErrorInterface) => {
                            this.handleLoginError(err);
                        });    
                    });
                },
                (err: ErrorInterface) => {
                    this._hideSpinner();
                    this.handleLoginError(err);
                }
            );

        event && event.preventDefault();
        return false;
    }

    /**
     * @desc This function creates router property on your service.
     *
     * The problem is that Router can async load some routes.
     * This is why it needs Http.
     * Your Http depends on Router and Router depends on Http.
     * Angular injector is not able to create any of these services.
     * I had similar problems and one of the solutions can be injecting
     * Injector instead of service and getting service afterwards.
     * @return {Router}
     */
    public get router(): Router {
        return this.injector.get(Router);
    }
    public get app_footer_service(): AppFooterService {
        return this.injector.get(AppFooterService);
    }

    private getAfterLoginRedirectLink(): string {
        return `/${this.redirectUrl || AppRouteValues.shoppingList}`;
    }


    _hideSpinner() {
        this.busy   = false;
        this.modalService.close();
    }


    handleLoginError(err: ErrorInterface) {
        let message, errMsg = getErrorType(err);

        if (/^user/i.test(errMsg)) {
            message = `User ${this.username} doesn\'t exist`;

            this.loginErr = true;
            this.username = '';
            this.elem.querySelector('input[name="username"]').focus();

        } else if (/password/i.test(errMsg)) {
            this.modalService.error({
                title:          'Error:',
                message:        errMsg,
                yesButtonText:  'Close',
            });

            message = this.translate.instant('login.error.givenPasswordIsNotTheSameAsCurrentOne');

            this.passErr  = true;
            this.password = '';
            this.elem.querySelector('input[name="password"]').focus();
        }

        this.modalService.error({
            title:          this.translate.instant('login.error.unable.title'),
            message: message || errMsg,
            yesButtonText:  this.translate.instant('login.error.unable.confirm'),
        });
    }

    public onPasswordFieldBlurred(event: FocusEvent): void {
        this[event.target['name']] = event.target['value'];
        this.bluredField(event, event.target['name']);
    }

    private resetFlags(): void {
        this.loginErr       = false;
        this.passErr        = false;
    }
}
