var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef } from '@angular/core';
import AppValues from '../../common/app.values';
import { MediaEditorComponent } from '../media-editor/media-editor.component';
import { ModalService } from '../../modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { isValidFileSize } from '../../product-editor/additional/user-platform.helpers';
var ImgLoaderComponent = /** @class */ (function (_super) {
    __extends(ImgLoaderComponent, _super);
    function ImgLoaderComponent(elementRef, modalService, translate) {
        var _this = _super.call(this, elementRef, modalService, translate) || this;
        _this.imageTypes = AppValues.imageTypes;
        return _this;
    }
    /**
     * Simulates click on the file input, to show browser file select dialog.
     */
    ImgLoaderComponent.prototype.showUpload = function () {
        this.fileInput = this.elementRef.nativeElement.querySelector('input[name="photo"]');
        this.fileInput.click();
    };
    /**
     * Handles image upload.
     * @emits changeEvent$ on proper image file.
     */
    ImgLoaderComponent.prototype.onImgUpload = function (event) {
        var _this = this;
        if (this.fileInput.files.length) {
            var file = this.fileInput.files[0];
            if (!isValidFileSize(file.size)) {
                this.modalService.error({
                    title: this.translate.instant('modal.error.uploadImage.title'),
                    message: this.translate.instant('modal.error.uploadImage.message'),
                    yesButtonText: this.translate.instant('modal.error.uploadImage.confirm'),
                });
                return false;
            }
            this.changeEvent$.emit({ imageFile: file });
            setTimeout(function () {
                _this.resetInput(event);
            });
        }
    };
    ImgLoaderComponent.prototype.resetInput = function (event) {
        event['value'] = '';
        if (!/safari/i.test(navigator.userAgent)) {
            event['type'] = '';
            event['type'] = 'file';
        }
    };
    return ImgLoaderComponent;
}(MediaEditorComponent));
export { ImgLoaderComponent };
