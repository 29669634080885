<footer class="footer__container">

    <div class="footer__menu">
        <nav class="footer__nav">

                    <span class="footer__choice">
                        <button data-test-id="sellerBtn" type="button"
                                data-path="/inventory-list" role="seller"
                                [ngClass]="getMode() == userMode.sellerMode ? 'active' : ''"
                                (click)="changeStatus($event.currentTarget)">
                                <i class="icon-seller_tab_icon"></i>
                        </button>
                    </span>


            <span class="footer__category-btn">
                        <button
                            type="button"
                            [ngClass]="{'rotate':isCategoryShown}"
                            data-test-id="showCategoriesBtn"
                            (click)="showGoodsNav()"
                        ><i class="icon-new_item_icon"></i></button>
                    </span>


            <span class="footer__choice" data-test-id="buyerBtn">
                        <button data-path="/shopping-list" role="buyer" type="button"
                                [ngClass]="getMode() == userMode.buyerMode ? 'active' : ''"
                                (click)="changeStatus($event.currentTarget)">
                                <i class="icon-buyer_tab_icon"></i>
                       </button>
                    </span>


            <button class="footer__main-btn" data-test-id="footerMainBtn" type="button"
                    [ngClass]="{'rotate':isNavShown}" (click)="showMainMenu()">
                    <img width="21" height="21" [src]="'../../assets/images/svg_icons/menu.svg'" alt="home menu">
            </button>
        </nav>
    </div>

</footer>

<!--CATEGORIES-->
<div class="footer__popup-container" [ngClass]="{'visible':isNavShown}" (click)="hideAll()">
    <category-selector
        [mode]="mode"
        (showCategoryEvent$)="onModeChange($event)"></category-selector>
</div>

<!--MAIN MENU-->
<div class="footer__popup-container" (click)="hideAll()"
     [ngClass]="{'visible':isCategoryShown}">

    <div class="footer__popup">
        <nav class="popup-nav">

            <app-footer-menu [type]="type"
                             (menuEvent$)="goTo($event)"
                             [isAdminAccessLevel]="isAdminAccessLevel"
                             [mode]="mode"
                             [counts]="counts"></app-footer-menu>
        </nav>
    </div>
</div>
