/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./search-header.component.ngfactory";
import * as i3 from "./search-header.component";
import * as i4 from "@angular/common";
import * as i5 from "../map-search/map-search.service";
import * as i6 from "./search.service";
import * as i7 from "../modal/modal.service";
import * as i8 from "../product-editor/product-editor.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./search.component";
import * as i11 from "../services/geolocation.service";
import * as i12 from "../services/google-analytics.service";
import * as i13 from "../product-details/product-details.service";
var styles_SearchStrategyComponent = [i0.styles];
var RenderType_SearchStrategyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchStrategyComponent, data: {} });
export { RenderType_SearchStrategyComponent as RenderType_SearchStrategyComponent };
export function View_SearchStrategyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "search-header", [], null, [[null, "changeEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changeEvent$" === en)) {
        var pd_0 = (_co.onOptionsChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SearchHeaderComponent_0, i2.RenderType_SearchHeaderComponent)), i1.ɵdid(3, 4308992, null, 0, i3.SearchHeaderComponent, [i1.ElementRef, i4.Location, i5.MapSearchService, i6.AppSearchService, i7.ModalService, i8.ProductEditorService, i9.TranslateService], { searchResult: [0, "searchResult"], search_filter: [1, "search_filter"], is_key_search: [2, "is_key_search"], userLocation: [3, "userLocation"], isAdminMode: [4, "isAdminMode"] }, { changeEvent$: "changeEvent$" }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "search__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, [[1, 3], ["container", 1]], null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchResult; var currVal_1 = _co.searchListFilter; var currVal_2 = _co.is_key_search; var currVal_3 = _co.userLocationForSearch; var currVal_4 = _co.isAdminMode(); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_SearchStrategyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "search", [], null, null, null, View_SearchStrategyComponent_0, RenderType_SearchStrategyComponent)), i1.ɵdid(1, 245760, null, 0, i10.SearchStrategyComponent, [i1.ElementRef, i1.ComponentFactoryResolver, i11.GeoLocationService, i12.GoogleAnalyticsService, i13.ProductDetailsService, i1.Injector, i7.ModalService, i9.TranslateService, i6.AppSearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchStrategyComponentNgFactory = i1.ɵccf("search", i10.SearchStrategyComponent, View_SearchStrategyComponent_Host_0, {}, {}, []);
export { SearchStrategyComponentNgFactory as SearchStrategyComponentNgFactory };
