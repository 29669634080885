import {
    AfterContentChecked,
    Component, ElementRef, OnInit
} from '@angular/core';
import {
    FormGroup, FormControl,
    FormBuilder, Validators, AbstractControl
} from '@angular/forms';
import {Location} from '@angular/common';

import AppValues from '../common/app.values';
import {ModalService} from '../modal/modal.service';
import {UserService} from './user.service';
import {ErrorInterface} from '../services/error.service';
import {TranslateService} from '@ngx-translate/core';
import {duplicateField, duplicatePassword} from './helpers/_helpers';
import { getErrorType } from '../services/error.helpers';



@Component({
    selector: 'password',
    template: `
        <div class="component">
            <user-header (submit)="changePassword()" [disabled]="busyChange"></user-header>
            <div class="password__container">
                <form novalidate [formGroup]="passwordForm" class="text-center">

                    <password-field [nameInput]="'currentPass'"
                                    [dataTestIdInput]="'oldPassword'"
                                    [classLabel]="'password__label'" [classInput]="'password__input'"
                                    [autofocusField]="true"
                                    [placeholderInput]=" 'common.currentPassword.placeholderInput' | translate"
                                    [isPassErr]="formErrors['currentPass']"
                                    (bluredField)="updatePasswordField($event, 'currentPass')"></password-field>

                    <password-field [nameInput]="'newPass'"
                                    [dataTestIdInput]="'newPassword'" [placeholderInput]=" 'common.newPassword.placeholderInput' | translate"
                                    [classLabel]="'password__label'" [classInput]="'password__input'"
                                    [isPassErr]="formErrors['newPass']"
                                    (bluredField)="updatePasswordField($event, 'newPass')"></password-field>

                    <password-field [nameInput]="'repeatPass'"
                                    [dataTestIdInput]="'repeatPassword'" [placeholderInput]=" 'common.repeatPassword.placeholderInput' | translate"
                                    [classLabel]="'password__label'" [classInput]="'password__input'"
                                    [isPassErr]="formErrors['repeatPass']"
                                    (bluredField)="updatePasswordField($event, 'repeatPass')"></password-field>

                    <div class="password__warning error"
                         *ngIf="(passwordForm.controls['repeatPass'].invalid
                                    && (passwordForm.controls['repeatPass'].dirty
                                    || passwordForm.controls['repeatPass'].touched)) || (passwordForm.controls['newPass'].invalid
                                    && (passwordForm.controls['newPass'].dirty
                                    || passwordForm.controls['newPass'].touched))">

                        <small *ngIf="(passwordForm.controls['newPass'].value && passwordForm.controls['repeatPass'].value) && passwordForm.controls['newPass'].hasError('mismatched') || passwordForm.controls['repeatPass'].hasError('mismatched')"
                               data-test-id="errorNewPassword">
                            {{ 'password.error.notMatch' | translate }}
                        </small>
                    </div>

                    <p class="input__info">
                        {{ 'signup.hint.password' | translate }}
                    </p>

                    <button class="button__bizibazagreen password__container__change-password-button"
                            data-test-id="changePassword" (click)="changePassword()"
                            [disabled]="busyChange"
                            [ngClass]="{'disabled': busyChange}">
                        {{ 'changePassword.button.text' | translate }}
                    </button>
                </form>
            </div>
        </div>
    `,
})


export class PasswordComponent implements OnInit, AfterContentChecked {

    public formErrors:
        {[key: string]: boolean} = {
        newPass:    false,
        repeatPass: false
    };

    public busyChange: boolean = true;
    public passwordForm: FormGroup;

    private _canDeactivate: boolean = false;
    private warningMsg: string;

    constructor(private elementRef: ElementRef,
                private fb: FormBuilder,
                private modalService: ModalService,
                private userService: UserService,

                public translate: TranslateService,
                public location: Location) { }

    public ngAfterContentChecked(): void {
        if (this.passwordForm && (this.passwordForm.controls['currentPass'].value || this.passwordForm.controls['newPass'].value || this.passwordForm.controls['repeatPass'].value)) {
            this.busyChange = !this._isValid();
        }
    }

    public ngOnInit(): void {
        this.buildForm();
    }


    private buildForm(): void {
        this.passwordForm = this.fb.group({
            'currentPass': [
                '', [Validators.required]
            ],
            'newPass': [
                '', [Validators.required, Validators.pattern(AppValues.passwordPattern), this.checkDuplicateField]
            ],
            'repeatPass': [
                '',  [Validators.required, Validators.pattern(AppValues.passwordPattern), this.checkDuplicateField]
            ]
        });

        this.passwordForm.valueChanges
            .subscribe(data => this.onValueChanged(data));
    }

    private checkDuplicateField(passwordForm): null | { mismatched: boolean } {
        if (passwordForm) {
            return duplicateField(passwordForm, 'newPass', 'repeatPass');
        }
    }

    public updatePasswordField(event: Event, formControlName: string): void {
        let someFormControl: AbstractControl = this.passwordForm.get(formControlName);

        if (event.target['value']) {
            someFormControl.setValue(event.target['value']);
            someFormControl.markAsDirty();
            someFormControl.markAsTouched();

            this.passwordForm.controls[formControlName].updateValueAndValidity();
            this.onBlurField(event, formControlName);
        } else {
            someFormControl.markAsPristine();
            someFormControl.markAsUntouched();
        }
    }

    public onBlurField(event: Event, name: string): void {
        let new_value = event.currentTarget['value'].trim();
        event.currentTarget['value'] = new_value;
        event.preventDefault();

        this.passwordForm.controls[name].setValue(new_value);

        let formPassControls: string[] = ['newPass', 'repeatPass'];
        if (!formPassControls.find((control: string) => control === name)) {
            formPassControls = [name];
        }
        formPassControls.forEach((control: string) => {
            this.passwordForm.controls[name].markAsTouched();
            this.passwordForm.controls[control].updateValueAndValidity();

            let new_data = this.passwordForm.value;
            new_data[name] = new_value;
            this.onValueChanged(new_data);
        });
    }


    public onValueChanged(data?: any): void {
        if (!this.passwordForm) {
            return;
        }

        this.warningMsg = '';
        const form = this.passwordForm;

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = false;
            const control = form.get(field);

            if (control && control.dirty && !control.valid)
                for (const key in control.errors) {
                    this.formErrors[field] = true;
                }
        }
    }


    public changePassword(): boolean {
        if (this.busyChange) return;

        const p = this.passwordForm.value;

        this.busyChange = false;
        let body = {
            'old_password': p['currentPass'],
            'new_password': p['newPass']
        };

        this._canDeactivate = true;

        this.modalService.showSpinner();
        // @ts-ignore
        this.userService.changePassword(body)
            .subscribe((res) => {
                    this._successfully();
                },
                (err: ErrorInterface) => {
                    this.busyChange = true;
                    this._showErrorMsg(err);
                });

        return false;
    }


    /**
     * Returns form validity.
     * @returns {boolean}
     * @private
     */
    private _isValid(): boolean {
        return this.passwordForm.valid;
    }

    /**
     * Shows modal error window.
     * @private
     */
    private _successfully(): void {
        this.modalService.success({
            title: this.translate.instant("changePassword.success.title"),
            yesButtonText: this.translate.instant("common.ok"),
        }).then((action) => {
            this.modalService.close();
            this.location.back();
        });
    }

    private _showErrorMsg(err: ErrorInterface): void {
        this.modalService.error({
            title: this.translate.instant("changePassword.error.title"),
            message: getErrorType(err),
            yesButtonText: this.translate.instant("common.ok"),
        });
    }


}
