var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { ElementRef, Injector, OnInit } from '@angular/core';
import 'rxjs/add/operator/takeUntil';
import { ShoppingListService } from './shopping-list.service';
import { ErrorService } from '../services/error.service';
import { UserService } from '../user/user.service';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
var ShoppingListComponent = /** @class */ (function (_super) {
    __extends(ShoppingListComponent, _super);
    function ShoppingListComponent(shoppingListService, errorService, injector) {
        var _this = _super.call(this) || this;
        _this.shoppingListService = shoppingListService;
        _this.errorService = errorService;
        _this.injector = injector;
        _this.isSearchActivated = false;
        _this.isEditing = false;
        _this.isWelcomeVisible = true;
        _this.initialValue = '';
        _this.suggestions = [];
        _this.activeList = [];
        _this.inactiveList = [];
        _this.list = [];
        return _this;
    }
    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {ShoppingListItemInterface} item
     * @return {string}
     */
    ShoppingListComponent.prototype.trackByID = function (index, item) {
        return item.subcategory_entry_id;
    };
    ShoppingListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.shoppingListService.suggestionsSearchActivating.next(false);
        this.prepareShoppingList();
        this.user_service.onLoginGuestChanges.subscribe(function (user) {
            _this.prepareShoppingList();
        });
    };
    Object.defineProperty(ShoppingListComponent.prototype, "user_service", {
        /***
        * @desc This function creates 'user service' property on your service.
        * @return {UserService}
        */
        get: function () {
            return this.injector.get(UserService);
        },
        enumerable: true,
        configurable: true
    });
    ShoppingListComponent.prototype.prepareShoppingList = function () {
        var _this = this;
        this.trackSubscription(this.shoppingListService.getList()
            .subscribe(function (list) {
            _this.sortedList(list);
            _this.checkIsWelcomeVisible();
        }, function (err) { return _this.errorService.handleError(err); }));
    };
    ShoppingListComponent.prototype.onInputChange = function (value) {
        this.isSearchActivated = value;
        this.shoppingListService.suggestionsSearchActivating.next(value);
        this.checkIsWelcomeVisible();
    };
    ShoppingListComponent.prototype.onStartRenaming = function (item) {
        this.shoppingListService.setCachedItem(this.shoppingListService.getBasicCustomItem(item.name));
        this.isEditing = true;
    };
    ShoppingListComponent.prototype.sortedList = function (list) {
        this.list = this.shoppingListService.sortList(list);
        this.inactiveList = this.shoppingListService.getInactiveList();
        this.activeList = this.shoppingListService.getActiveList();
    };
    ShoppingListComponent.prototype.descriptorFieldToShoppingListItem = function (item) {
        if (item.is_custom) {
            if (!item.descriptor) {
                item.descriptor = item.name;
            }
            else {
                delete item.descriptor;
            }
        }
        return item;
    };
    ShoppingListComponent.prototype.localeCompareByDescriptor = function (list) {
        var _this = this;
        list.map(function (item) { return _this.descriptorFieldToShoppingListItem(item); });
        list.sort(function (itemA, itemB) {
            return itemA.descriptor.localeCompare(itemB.descriptor);
        });
        return list;
    };
    /**
     * If user wants sorting current list in alphabet order -
     * this method will help with this.
     * Method sorts separately active items
     * and inactive items in alphabet order.
     * Then union all new lists and updated shopping list with request.
     */
    ShoppingListComponent.prototype.sortOrderByDescriptor = function () {
        var _this = this;
        if (this.activeList && this.activeList.length) {
            this.activeList = this.localeCompareByDescriptor(this.activeList);
        }
        if (this.inactiveList && this.inactiveList.length) {
            this.inactiveList = this.localeCompareByDescriptor(this.inactiveList);
        }
        this.list = __spread(this.activeList, this.inactiveList);
        this.list.map(function (item) { return _this.descriptorFieldToShoppingListItem(item); });
        this.shoppingListService.updateShoppingList(this.list)
            .subscribe(function (res) { return _this.shoppingListService.setCachedList(res.elements); }, function (err) { return _this.errorService.handleError(err); });
    };
    /**
     * After drag & drop item - call this method
     * get from component active items and inactive items.
     * Then union all new lists and updated shopping list with request.
     * @param {ShoppingListItemInterface[]} list
     * @param {ShoppingListItemInterface[]} item
     */
    ShoppingListComponent.prototype.updatePositionItem = function (list, items) {
        var _this = this;
        this.sortedList(__spread(list, items));
        this.checkIsWelcomeVisible();
        this.shoppingListService.updateShoppingList(this.list)
            .subscribe(function (res) { return _this.shoppingListService.setCachedList(res.elements); }, function (err) { return _this.errorService.handleError(err); });
    };
    ShoppingListComponent.prototype.addCustomItem = function (value) {
        this.addItem(this.shoppingListService.getBasicCustomItem(value), value);
    };
    ShoppingListComponent.prototype.addSuggestionItem = function (suggestion) {
        var itemInList = this.list.find(function (item) { return item.subcategory_entry_id === suggestion.ID; });
        itemInList
            ? this.errorService.handleWarning("You have already added '" + suggestion.sub_category_name + "' to your shopping list")
            : this.addItem({
                name: suggestion.sub_category_name,
                subcategory_entry_id: suggestion.ID,
                is_active: true,
                is_custom: false,
                descriptor: suggestion.sub_category_name,
            });
    };
    ShoppingListComponent.prototype.resetInput = function () {
        this.initialValue = '';
        this.isEditing = false;
        this.shoppingListService.suggestionsSearchActivating.next(false);
    };
    ShoppingListComponent.prototype.checkIsWelcomeVisible = function (value) {
        if (value) {
            this.isWelcomeVisible = value.length === 0;
        }
        else if (!this.isSearchActivated) {
            this.isWelcomeVisible = __spread(this.list, this.inactiveList, this.activeList).length === 0;
        }
        else {
            this.isWelcomeVisible = false;
        }
    };
    /**
     * Should be called after adding new item to shopping list.
     * Scrolls shopping list from its last item to input field.
     */
    ShoppingListComponent.prototype.scrollToInputField = function () {
        this.scrollContainer.nativeElement.scrollIntoView();
    };
    ShoppingListComponent.prototype.addItem = function (item, keyword) {
        var _this = this;
        if (keyword === void 0) { keyword = ''; }
        var list = this.shoppingListService.addSuggestedItem(item, this.list, keyword);
        this.shoppingListService.updateShoppingList(list)
            .subscribe(function (res) {
            _this.sortedList(res.elements);
            _this.shoppingListService.setCachedItem(null);
            _this.scrollToInputField();
        }, function (err) { return _this.errorService.handleError(err); });
    };
    return ShoppingListComponent;
}(UnsubscribeOnDestroyAbsctractClass));
export { ShoppingListComponent };
