import { BrowserModule }                            from '@angular/platform-browser';
import { BrowserAnimationsModule }                  from '@angular/platform-browser/animations';
import { PathLocationStrategy, LocationStrategy }   from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule }         from '@angular/forms';
import { LOCALE_ID } from '@angular/core';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
// misc
import { AppSettings }                  from './common/app.settings';
import { AppMisc }                      from './common/app.misc';
import { routing, appRoutingProviders } from './app.routes';

// modules
import { AnalyticsModule }      from './analytics/analytics.module';
import { AppFooterModule }      from './app-footer/app-footer.module';
import { BizipixEditorModule }  from './bizipix-editor/bizipix-editor.module';
import { BizipixViewerModule }  from './bizipix-viewer/bizipix-viewer.module';
import { CartModule }           from './cart/cart.module';
import { ChatModule }           from './chat/chat.module';
import { EmailVerificationModule } from './email-verification/email-verification.module';
import { FavoriteListModule }   from './favorite-list/favorite-list.module';
import { InventoryListModule }  from './inventory-list/inventory-list.module';
import { ModalModule }          from './modal/modal.module';
import { ProfileModule }        from './profile/profile.module';
import { ProductDetailsModule } from './product-details/product-details.module';
import { ShopperDetailsModule } from './shopper-details/shopper-details.module';
import { ProductEditorModule }  from './product-editor/product-editor.module';
import { SearchModule }         from './search/search.module';
import { GoodsNavModule }       from './goods-nav/goods-nav.module';
import { SharedModule }         from './shared/shared.module';
import { SettingsModule }       from './settings/settings.module';
import { ShoppingListModule }   from './shopping-list/shopping-list.module';
import { MapSearchModule }      from './map-search/map-search.module';
import { MarketModule }         from './market/market.module';
import { ReviewsModule }        from './reviews/reviews.module';
import { WatchListModule }      from './watch-list/watch-list.module';
import { UserModule }           from './user/user.module';
import { HotTableModule }       from '@handsontable/angular';


// components
import { AppComponent }         from './app.component';
import { NotFoundComponent }    from './common/not-found.component';

// service
import { AuthGuard }                from './services/auth-guard.service';
import { CanDeactivateGuard }       from './services/can-deactivate-guard.service';
import { DataService }              from './services/data.service';
import { ErrorService }             from './services/error.service';
import { ImageUploadService }       from './services/image-upload.service';
import { GoogleAnalyticsService }   from './services/google-analytics.service';
import { RedirectService }          from './services/redirect.service';
import { StorageService }           from './services/storage.service';
import { UserService }              from './user/user.service';
import { AppLoadModule } from './app-load/app-load.module';
import { CookieService } from './services/cookie.service';
import { AppHeaderModule } from './app-header/app-header.module';
import { PushNotificationsService } from './services/push.notification.service';
import { CheckTabStatusService } from './services/check-tab-status';
import { TruncatePipe } from './services/truncatePipe.service';
import { AfterBootService } from './after-boot/after-boot.service';
import {Configuration, ConfigurationParameters, ApiModule, OrdersService} from '../../swagger-gen__output_dir';
import {SentryErrorHandler} from './services/sentry-error-handler';
import {GoogleMapService} from './services/google-map.service';
import {LocalCaching} from './local-caching/local-caching-factory';
import {GeoCoderService} from './services/geocoder.service';
import {GeoLocationService} from './services/geolocation.service';
import {OperationSystemService} from './services/operation.system.service';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ScrollToModule, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {UnsupportedDeviceModule} from './unsupported-info/unsupported-device.module';
import {AbandonedProduct} from './cart/abandonedcart.class';
import {PaymentModule} from './payment/payment.module';
import {WindowRefService} from './services/window-ref.service';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {ClientPaymentService} from "./payment/payment.service";
import {CartService} from "./cart/cart.service";
import {SortablejsModule} from "angular-sortablejs";
import {CacheNotificationModule} from "./cache-notification/cache-notification.module";
import {AngularDraggableModule} from "angular2-draggable";
import {environment} from "../environments/environment";
import {ServiceWorkerModule} from "@angular/service-worker";
import {ItemSearchComponent} from "./search/search-lists/item-search.component";
import {EventSearchComponent} from "./search/search-lists/event-search.component";
import {ItemSearchSublistComponent} from "./search/search-lists/item-search-sublist.component";
import {MarketSearchComponent} from "./search/search-lists/market-search.component";
import {SellerSearchComponent} from "./search/search-lists/user-search.component";
import {UsersListComponent} from "./search/search-lists/users-list.component";
import {ProductSearchListComponent} from "./search/search-lists/product-search-list.component";

// todo lazy loading for some modules

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: AppSettings.BASE_URL.slice(0, -1)
  };

  return new Configuration(params);
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// registerLocaleData(localeEn);

const Services = [
    AppSettings,
    AppMisc,
    AuthGuard,
    appRoutingProviders,
    { provide: LOCALE_ID, useValue: "en-CA" },

    CanDeactivateGuard,
    TruncatePipe,
    CookieService,
    LocalCaching,
    DataService,
    ErrorService,
    GoogleMapService,
    GeoCoderService,
    GeoLocationService,
    GoogleAnalyticsService,
    ImageUploadService,
    ClientPaymentService,
    CartService,
    RedirectService,
    StorageService,
    PushNotificationsService,
    CheckTabStatusService,
    UserService,
    AfterBootService,
    OperationSystemService,
    AbandonedProduct,
    WindowRefService,
    OrdersService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler }
];

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('service_worker.js', { enabled: environment.production }),
        ScrollToModule.forRoot(),
        routing,

        AppLoadModule,
        HotTableModule.forRoot(),
        AnalyticsModule.forRoot(),
        AppFooterModule.forRoot(),
        AppHeaderModule.forRoot(),
        BizipixEditorModule.forRoot(),
        BizipixViewerModule.forRoot(),
        AngularDraggableModule,
        PaymentModule,
        CartModule,
        CacheNotificationModule.forRoot(),
        CartModule.forRoot(),
        ChatModule.forRoot(),
        EmailVerificationModule,
        FavoriteListModule,
        GoodsNavModule.forRoot(),
        InventoryListModule.forRoot(),
        MapSearchModule,
        ModalModule.forRoot(),
        ProductDetailsModule.forRoot(),
        ShopperDetailsModule.forRoot(),
        ProductEditorModule.forRoot(),
        ProfileModule,
        SearchModule,
        SettingsModule,
        SharedModule,
        ShoppingListModule,
        MarketModule.forRoot(),
        ReviewsModule,
        UserModule.forRoot(),
        UnsupportedDeviceModule.forRoot(),
        WatchListModule,
        SortablejsModule.forRoot({ animation: 200 }),
        ApiModule.forRoot(apiConfigFactory),
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
    ],
    entryComponents: [
        EventSearchComponent,
        ItemSearchComponent,
        ItemSearchSublistComponent,
        MarketSearchComponent,
        SellerSearchComponent,
        UsersListComponent,
        ProductSearchListComponent,
    ],
    providers: [...Services],
    bootstrap: [AppComponent]
})

export class AppModule {
  public constructor(private translateService: TranslateService) {
    this.initializeTranslateService();
  }

  /**
   * @description Does translate service initialization by steps:
   *        1. Adds supported languages.
   *        2. Sets up the language that will be used as a fallback when a translation
   *          isn't found in the current language json.
   *        3. Tries to find the browser language inside an array of supported languages.
   *        4. In case it isn't available, changes the currently used lang to the browser one.
   */
  private initializeTranslateService(): void {
    this.translateService.addLangs(['en']);

    this.translateService.setDefaultLang('en');

    const langs: string[] = this.translateService.getLangs();
    if (langs.indexOf(this.translateService.getBrowserLang()) !== -1) {
      this.translateService.use(this.translateService.getBrowserLang());
    }
  }
}
