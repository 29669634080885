/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-editor.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./product-editor-strategy.component";
import * as i3 from "./event.service";
import * as i4 from "@angular/common";
import * as i5 from "./product-editor.service";
import * as i6 from "@ngx-translate/core";
var styles_ProductEditorStrategyComponent = [i0.styles];
var RenderType_ProductEditorStrategyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductEditorStrategyComponent, data: {} });
export { RenderType_ProductEditorStrategyComponent as RenderType_ProductEditorStrategyComponent };
export function View_ProductEditorStrategyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 16777216, [[1, 3], ["container", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_ProductEditorStrategyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "product-editor-strategy", [], null, null, null, View_ProductEditorStrategyComponent_0, RenderType_ProductEditorStrategyComponent)), i1.ɵdid(1, 114688, null, 0, i2.ProductEditorStrategyComponent, [i1.ComponentFactoryResolver, i3.EventService, i4.Location, i5.ProductEditorService, i6.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductEditorStrategyComponentNgFactory = i1.ɵccf("product-editor-strategy", i2.ProductEditorStrategyComponent, View_ProductEditorStrategyComponent_Host_0, {}, {}, []);
export { ProductEditorStrategyComponentNgFactory as ProductEditorStrategyComponentNgFactory };
