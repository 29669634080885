import { Component, EventEmitter,
    Input, Output }                 from '@angular/core';

import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';


@Component({
    selector:   'picker-block',
    styleUrls:  ['bizipix-editor.sass'],

    template:   `        
        <picker-cell 
                [data]="item"
                [index]="index"
                (clickEvent$)="onItemClick($event)" role="parentItem"></picker-cell>
        
        <accordion class="abstract-list__li__footer" 
            [indexOfItem]="index"
            [title]="item['nestedMarkets'].length + ' markets found, nearest in '" 
            [label]="item['nestedMarkets'][0].distance + 'mi'" 
            *ngIf="item['is_parent'] && item['nestedMarkets'] && item['nestedMarkets'].length">
            <market-table (clickEvent$)="onItemClick($event)" [indexOfItem]="index" [items]="item['nestedMarkets']"></market-table>
        </accordion>
    `
})


/**
 * A list for the parent item and its event clones.
 */
export class PickerBlockComponent {
    @Input()    item:               SellingItemResponseBody;
    @Input()    index:              number;

    @Output()   itemSelectEvent$    = new EventEmitter();


    /**
     * @emits itemSelectEvent$ on a child clickEvent$.
     * @param item
     */
    onItemClick(item: SellingItemResponseBody): void {
        this.itemSelectEvent$.emit(item);
    }

}
