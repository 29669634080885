import { NgModule, ModuleWithProviders }    from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { RouterModule }                     from '@angular/router';

import {AppHeaderComponent} from './app-header.component';
import {AppHeaderService} from './app-header.service';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
    ],
    declarations: [
        AppHeaderComponent,
    ],
    exports: [AppHeaderComponent]
})
export class AppHeaderModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppHeaderModule,
            providers: [AppHeaderService]
        };
    }
}
