/**
 * BiziBaza app
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PayeeAccountView { 
    /**
     * Status of payee account.
     */
    status: PayeeAccountView.StatusEnum;
}
export namespace PayeeAccountView {
    export type StatusEnum = 'not_created' | 'not_onboarded' | 'not_able_to_receive_transfers' | 'enabled';
    export const StatusEnum = {
        NotCreated: 'not_created' as StatusEnum,
        NotOnboarded: 'not_onboarded' as StatusEnum,
        NotAbleToReceiveTransfers: 'not_able_to_receive_transfers' as StatusEnum,
        Enabled: 'enabled' as StatusEnum
    };
}