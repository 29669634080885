import {EventEmitter, Injectable} from '@angular/core';
import {Observable, of, Subscriber} from 'rxjs';
import { Router }       from '@angular/router';
import 'rxjs/add/observable/throw';


import { UserService }                  from '../user/user.service';
import { DataService }                  from '../services/data.service';
import { FavoriteListService }          from '../favorite-list/favorite-list.service';
import { ModalService }                 from '../modal/modal.service';
import { PriceAlertInterface }          from '../interfaces/alert.interface';
import {
    ProductDetailsOptionsInterface,
    ProductDetailsViewInterface,
    ProductReport, ProductReports, ProductView
} from '../interfaces/product.interface';
import {ErrorInterface, ErrorService} from '../services/error.service';
import { WatchListService }             from '../watch-list/watch-list.service';
import {ItemService} from '../services/item.service';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {AdapterProductView} from './adapter-product-view';
import {UserModel} from "../interfaces/user.interface";
import { ProductAndPriceRequest } from '../interfaces/product-and-price-request.interface';
import { AppRouteValues, createDirection } from '../common/app.route.values';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { getErrorType } from '../services/error.helpers';

@Injectable()
export class ProductDetailsService {

    public options:                        ProductView = {};
    public getProductEvent$:               EventEmitter<{}>;

    public get productOptions(): ProductView {
        return this.options;
    }

    private set_product_details_from:       string;
    private item:                       SellingItemResponseBody;
    private priceAlert:                 PriceAlertInterface;
    private token:                      string;

    public constructor(
        private dataService:            DataService,
        private errorService:           ErrorService,
        private favoriteListService:    FavoriteListService,
        private modalService:           ModalService,
        private router:                 Router,
        private itemService:            ItemService,
        private watchListService:       WatchListService,
        private userService:            UserService,
        private translate:              TranslateService
    ) {
        this.getProductEvent$ = new EventEmitter();
        this.token = this.userService.getUserSession().token;
    }


    /**
     * @desc Fetches userSession data, product details of the item and its priceAlert object.
     * @param {string} id
     * @param options
     */
    // TODO Refactoring, depending on necessity of using withRedirect flag
    public showProductDetails(id: string, options?: ProductView, withRedirect: boolean = true): Observable<SellingItemResponseBody> {
        this.token = this.userService.getUserSession().token;
        if (!this.token || !id) { return; }

        this.setProductOptions(options);
        this.modalService.showSpinner();
        
        if (typeof options !== 'undefined' && options.item) {
            return Observable.of(this._setDataForProductDetails(id, options.item, withRedirect));
        } else {
            return new Observable((observer: Subscriber<SellingItemResponseBody>) => {
                this.getProduct(id).subscribe((response: SellingItemResponseBody) => {
                    observer.next(this._setDataForProductDetails(id, response, withRedirect));
                    observer.complete();
                }, (error: ErrorInterface) => {
                    observer.error(error);
                    observer.complete();
                });
            });
        }

    }

    public getProduct(productId: string): Observable<SellingItemResponseBody | ErrorInterface> {
        return this.dataService
                .getData(`get_item?item_id=${productId}`, {token: this.token})
                .catch((error: ErrorInterface) => {
                    const err: string = error.body_error_text || getErrorType(error);
                    return this.errorService.handleWarning(err);
                })
                .map((product: SellingItemResponseBody) => {
                    return product;
                });
    }

    public setProductOptions(options: ProductView): void {
        this.options = {...this.options, ...options};
    }

    private _setDataForProductDetails(id: string, item: SellingItemResponseBody, withRedirect: boolean) {
        const isExpired: boolean = this.itemService.isExpiredFn(item);

        if (Object.keys(item).length === 0) {
            this._showNotification(this.translate.instant('notification.product.notAvailable'));
            return;
        }
        if (item.draft === true || isExpired === true) {
            this._showNotification(this.translate.instant('notification.product.notLongerAvailable'));
            return;
        } else if (this.userService.getUser().accessLevel !== 0) {
            this._getPriceAlert(id, item, withRedirect);
        } else {
            return this._productAndPriceResult({priceObject: {}, item, id, withRedirect});
        }
    }

    private _getPriceAlert(id: string, item: SellingItemResponseBody, withRedirect: boolean): void {
         this.getProductPriceAlert(id).subscribe(
                (priceObject: PriceAlertInterface) => {
                    this._productAndPriceResult({priceObject, item, id, withRedirect});
                },
                 (err: ErrorInterface) => {
                     this.errorService.handleError(err);
                 }
            );
    }

    // tslint:disable-next-line:member-ordering
    public getProductPriceAlert(id: string): Observable<PriceAlertInterface> {
        return this.dataService.getData(
            `price_alert?item_id=${id}`,
            {token: this.userService.getUserSession().token})
            .do((priceAlert: PriceAlertInterface) => priceAlert)
            .catch((error: ErrorInterface) => this.errorService.handleError(error));
    }

    private _productAndPriceResult(data: ProductAndPriceRequest): SellingItemResponseBody {
        this.priceAlert = data.priceObject;
        this.item = data.item;
        this.getProductEvent$.emit(this.getItem());
        if (data.withRedirect) {
            this.router.navigate([createDirection(AppRouteValues.routeProductDetails), data.id]);
        }
        this.modalService.close();

        return this.item;
    }


    /**
     * @desc get Item
     * @returns {SellingItemResponseBody}
     */
    // tslint:disable-next-line:member-ordering
    public getItem(): ProductDetailsViewInterface | undefined {
        return this.item ? new AdapterProductView(this.item).initProduct() : undefined;
    }


    /**
     * @desc get price alert
     * @returns {PriceAlertInterface}
     */
    // tslint:disable-next-line:member-ordering
    public getPriceAlert(): PriceAlertInterface {
        return this.priceAlert;
    }

    // tslint:disable-next-line:member-ordering
    public isPriceAlertOn(priceAlert: PriceAlertInterface): boolean {
        return priceAlert && Object.keys(priceAlert).length !== 0 && priceAlert.is_active;
    }


    /**
     * @desc get options, if they are
     * @returns {ProductDetailsOptionsInterface | {}}
     */
    // tslint:disable-next-line:member-ordering
    public getOptions(): ProductDetailsOptionsInterface | {} {
        return this.options || {};
    }

    // tslint:disable-next-line:member-ordering
    public setProductDetailsFrom(productDetailsFrom: string): void {
        this.set_product_details_from = productDetailsFrom;
    }

    // tslint:disable-next-line:member-ordering
    public getProductDetailsFrom(): string {
        return this.set_product_details_from;
    }

    /**
     * @desc Check if the current user has reported this item before.
     * @param {string} itemID
     */
    // tslint:disable-next-line:member-ordering
    public isInReportList(itemID: string): Observable<any> {
        this.token = this.userService.getToken();
        if (this.token) {
            return this.dataService.getData(`report_by_item_id?item_id=${itemID}`, {token: this.token})
                .map((res: ProductReports) => {
                    const userId: string = this.userService.getUserSession().ID;
                    return res.reports.findIndex(
                       (item: ProductReport) => item.reporter_id === userId
                    ) >= 0;
                })
                .catch((err: ErrorInterface) => this.errorService.handleError(err)) as Observable<any>;
        }

        return of({});
    }


    /**
     * @desc Check if this product is in the WatchList
     * @param {string} itemID
     * @param isVisibleSpinner
     * @returns {Observable<boolean>}
     */
    public isInWatchList(itemID: string, isVisibleSpinner: boolean = true): Observable<boolean> {
        return this.watchListService.isInWatchList(itemID, isVisibleSpinner);
    }


    /**
     * @desc Checks if desired price alert value equals or is higher then current price;
     * if so calls to show user notification. Otherwise calls to set price alert, or remove
     * it on zero value.
     * @param price
     * @returns {Observable}
     */
    // tslint:disable-next-line:member-ordering
    public setPriceAlert(price?: number): Observable<{}> | Observable<never> {
        let api: string = 'set_price_alert';

        const itemPrice: number =  this.itemService.isSaleFn(this.item)  ? this.item.salePrice : this.item.price;

        if (typeof price === 'number' && price >= itemPrice) {
            return this.errorService.handleError({
                status: undefined,
                statusText: this.translate.instant('notification.product.alertValueHigher')
            });

        } else if (typeof price === 'number' && price <= 0) {
            return this.errorService.handleError({
                status: undefined,
                statusText: this.translate.instant('notification.product.alertValueZero')
            });
        }

        const body: { item_id: string; price: number } = {
            item_id:    this.item.ID,
            price
        };

        if (!price) {
            api = 'remove_price_alert';
            delete body.price;
        }
        return this.dataService.postData(api, body, { token: this.token })
            .map((response: {}) => response)
            .catch((err: ErrorInterface) => {
                this.errorService.handleError(err);
                return Observable.throwError(err);
            });
    }


    /**
     * @desc Delegates showing picture to ModalService.
     * @param url
     */
    // tslint:disable-next-line:member-ordering
    public showCertificate(url: string): void {
        this.modalService.showImage(url);
    }


    /**
     * @desc If the item is in FavList, calls remove method of
     * the service; otherwise calls add method. On success updates User (favList object)
     * and FavList model.
     * @returns {Observable<R|T>}
     */
    // tslint:disable-next-line:member-ordering
    public toggleFavListStatus(): Observable<{}> {
        const id: string = this.item.ID;
        const user: UserModel  = this.userService.getUser();
        // @ts-ignore
        const index = user.favList.items.indexOf(id);

        if (index > -1) {
            return this.favoriteListService.removeItem(id);

        } else {
            return this.favoriteListService.addItem(id);
        }
    }


    /**
     * @desc If the item is in WatchList, calls remove method,
     * otherwise calls add method. On success updates User (watchList object)
     * and WatchList model.
     * @returns {Observable<R|T>}
     */
    public toggleWatchListStatus(itemID) {
        return this.watchListService.toggleWatchListStatus(itemID);
    }


    /**
     * @desc Show modal window about some error
     * @private
     */
    public _showNotification(test: string) {
        this.modalService.error({
            title:          this.translate.instant('notification.someError.title'),
            message:        test,
            yesButtonText:  this.translate.instant('notification.someError.confirmButton'),
        });
    }


    public sendReport(itemID: string ) {
        if (!this.token) { return; } else {
            return this.dataService.postData('report_item', { 'item_id': itemID }, {token: this.token})
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }
    }

    public removingItemFromWatchList(): Promise<boolean> {
        return this.watchListService.removingItemFromWatchList();
    }
}
