var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PushNotificationsService } from '../services/push.notification.service';
import { AppFooterService } from '../app-footer/app-footer.service';
import { AppHeaderService } from './app-header.service';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { AppRouteValues, createDirection } from '../common/app.route.values';
var AppHeaderComponent = /** @class */ (function (_super) {
    __extends(AppHeaderComponent, _super);
    function AppHeaderComponent(injector, notificationService, appFooterService, headerService) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        _this.notificationService = notificationService;
        _this.appFooterService = appFooterService;
        _this.headerService = headerService;
        return _this;
    }
    AppHeaderComponent.prototype.ngOnInit = function () {
        this.notificationService.requestPermission();
        this.subscribeCountsChanges();
    };
    /**
     * @desc Navigates to specified url
     * @param {string} url
     */
    AppHeaderComponent.prototype.goTo = function (url) {
        this.router.navigate([url]);
    };
    Object.defineProperty(AppHeaderComponent.prototype, "isSeller", {
        get: function () {
            return this.appFooterService.getMode() === 'seller';
        },
        enumerable: true,
        configurable: true
    });
    AppHeaderComponent.prototype.createRootUrl = function () {
        var returnUrl = this.isSeller ? AppRouteValues.inventoryList : AppRouteValues.shoppingList;
        return createDirection(returnUrl);
    };
    Object.defineProperty(AppHeaderComponent.prototype, "router", {
        /**
         * @desc This function creates router property on your service.
         * @return {Router}
         */
        get: function () {
            return this.injector.get(Router);
        },
        enumerable: true,
        configurable: true
    });
    AppHeaderComponent.prototype.subscribeCountsChanges = function () {
        var _this = this;
        this.trackSubscription(this.headerService.countsChanges.subscribe(function (countsChanges) { return _this.counts = countsChanges; }));
    };
    return AppHeaderComponent;
}(UnsubscribeOnDestroyAbsctractClass));
export { AppHeaderComponent };
