<ul *ngIf="items" class="fav-watch-list__ul-items">
   <li class="fw-list__li text-center"
      [ngClass]="{'expired': (dateNow > item.validTill)}"
      *ngFor="let item of items; trackBy: trackByID; let i =  index;"
      [attr.data-test-id]="type+'ListPItem_'+i"
      attr.data-id="{{item.ID}}"
      (click)="showItemInfo(item.ID)"
   >
      <div
         *ngIf="item.isInOnSale && item.current_quantity"
         class="marker__sale"
         [attr.data-test-id]="'saleOnLabel_'+i"
      ></div>
      <div
          *ngIf="!item.current_quantity"
          class="marker__soldout"
          [attr.data-test-id]="'soldOutLabel_'+i"
      ></div>
       <div
           *ngIf="item.isExpired"
           class="marker__expired"
           [attr.data-test-id]="'expiredLabel_' + i"
       ></div>
      <div class="fw-list__img-container">
          <span
            *ngIf="!item.photoURL"
            [attr.data-test-id]="type+'ListPItemImage_'+i"
            class="sprite sprite-no_image_icon"
         ></span>
         <img
            *ngIf="item.photoURL"
            [attr.data-test-id]="type+'ListPItemImage_'+i"
            [src]="item.photoURL"
            [alt]="item.title"
         >
      </div>
      <div class="fw-list__product__container text-left">
         <h3
            class="abstract-list__product__title"
            [attr.data-test-id]="type+'ListPTitle_'+i"
            [ngClass]="{ expired : dateNow > item.validTill }"
         >
            {{item.title}}
         </h3>
            <div class="abstract-list__description">
               <h4 [attr.data-test-id]="type+'ListDescription_'+i">
                  {{ item.productPrice / 100 | currency:'USD'}} / {{item.productUnitsName}}
               </h4>
            </div>
            <div class="abstract-list__product-reviews">
               <rating
                  [attr.data-test-id]="type+'ListPRate_'+i"
                  [rating]="item.rate"
                  [float]="true"
                   [readonly]="true"
               ></rating>
            </div>
      </div>
      <span
         class="fw-list__item-controls"
         [attr.data-test-id]="type+'ListPRemoveItem_'+i"
         (click)="removeItem(item.ID);$event.stopPropagation()"
      >
         <i class="icon-trash-empty"></i>
      </span>
   </li>
</ul>
