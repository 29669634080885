import {
    Component, EventEmitter, Input,
    Output
} from '@angular/core';
import {Location} from '@angular/common';


@Component({
    selector:   'addr-editor-header',

    template:   `
        <header class="app-header">
            <nav class="app-header__container">
                <div class="navbar__left">
                    <a (click)="onBack()" class="nav__link" data-test-id="profileLink">
                        <span role="addr">{{ "addr.editor.header.cancel" | translate }}</span>
                    </a>
                </div>
    
                <div class="navbar__center" role="addr">
                    <h4>{{ "addr.editor.header.shipping.address" | translate }}</h4>
                </div>
    
                <div class="navbar__right">
                    <a class="nav__link" data-test-id="addNewAddressBtn"
                       (click)="onDone()">
    
                        <span role="addr">{{ "addr.editor.header.done" | translate }}</span>
                    </a>
                </div>
    
            </nav>
        </header>
    `
})

export class AddrEditorHeaderComponent {
    @Output() submit = new EventEmitter<null>();
    @Output() canDeactivate = new EventEmitter<null>();

    onDone() {
        this.submit.emit(null);
    }
    onBack() {
        this.canDeactivate.emit(null);
    }
}
