<div class="abstract-list__container">
    <h4 *ngIf="items && items.length && title" class="search-sublist__header text-center">{{title}}</h4>

    <ul *ngIf="items && items.length" class="abstract-list__ul">

        <li class="abstract-list__li text-center"
            [attr.data-id]="item['_id'] || item['ID']"
            [attr.data-market-name]="item['market_title']"
            [attr.data-test-id]="'abstractListProduct_'+i"
            *ngFor="let item of items; let i = index;">
            <div class="abstract-list__li__box" (click)="onClick(item)">
                <div
                   *ngIf="
                        (item['price'] || item['salePrice']) && isSaleFn(item)
                        && item['current_quantity']
                   " class="marker__sale"
                   [attr.data-test-id]="'saleOnLabel_'+i"
                ></div>
                <div
                   *ngIf="item['price'] && !item['current_quantity']"
                   class="marker__soldout"
                   [attr.data-test-id]="'soldOutLabel_'+i"
                ></div>

                <div class="abstract-list__img-container flex-column">
                    <div [attr.data-test-id]="'img-container_'+item.ID" class="abstract-list__img-holder" [ngClass]="{'img-height--custom': item.organic || item.nonGMO }">
                        <img
                            *ngIf="item['photoURL'] || item['market_picture_url'] || item['imageURL']"
                            [src]="item['photoURL'] || item['market_picture_url'] || item['imageURL']"
                        >
                        <div
                            *ngIf="!item['photoURL'] && !item['market_picture_url'] && !item['imageURL']"
                            class="sprite sprite-no_image_icon"
                        ></div>
                    </div>

                    <div class="abstract-list__product-bioanalysis">
                        <button type="button" class="label-button" *ngIf="item.organic" (click)="showCertificate($event, item.organic_certificate_image)">{{ "search.item.org" | translate }}</button>
                        <button type="button" class="label-button" *ngIf="item.nonGMO" (click)="showCertificate($event, item.nonGMO_certificate_image)">{{ "search.item.ng" | translate }}</button>
                    </div>
                </div>

                <div class="abstract-list__product__container text-left">
                    <h4 class="abstract-list__product__title"
                        [attr.data-test-id]="'abstractListProductTitle_'+i">
                        {{item['title'] || item['market_title'] || item['firstName'] + ' ' +item['lastName']}}
                    </h4>

                    <div *ngIf="item['price'] || item['salePrice']" class="abstract-list__description">
                        <div class="abstract-list__description__container">
                            <h4 class="currency">{{itemPriceFn(item) / 100 | currency:'USD'}}/{{priceUnitsNameFn(item)}}</h4>
                            <div>
                                <a 
                                class="link-green link-green--inline" 
                                href="/" 
                                data-test-id="seller-details"
                                (click)="showSellerDetails($event, item.sellerID)"
                                >{{item.sellerName}}</a>
                            </div>
                            <div class="abstract-list__description__box" *ngIf="!item['is_parent']">
                                <div class="abstract-list__description__box__distance"
                                     *ngIf="item['distance'] || item['distance'] === 0">
                                        {{item['distance']}} {{ "search.item.mi" | translate }}
                                </div>
                                <div class="abstract-list__description__box__market-title"
                                     *ngIf="item['market']">
                                     {{item['market'].market_title}}
                                </div>
                            </div>
                        </div>

                        <div class="controls-container">
                            <button 
                                (click)="item.isInCart ? removeFromCart($event, item) : addToCart($event, item)" 
                                class="action-btn" 
                                [ngClass]="{'active': item.isInCart}" 
                                type="button"
                                [disabled]="busyCart" 
                                [attr.data-test-id]="'detailAddToCart_'+item.ID">

                                <span *ngIf="!busyCart" class="icon-cart_icon"></span>
                                <span *ngIf="item.isInCart" class="icon-ok-1"></span>
                                <span *ngIf="busyCart"><i class="icon-spinner2"></i></span>
                            </button>
                            <button 
                                (click)="handleShoppingListEvent($event, item)"
                                class="action-btn" 
                                [ngClass]="{'active': isInSL(item.subcategoryEntryID)}" 
                                type="button"
                                [attr.data-test-id]="'addToShoppingList_'+item.ID">

                                <span class="icon-list-ordered"></span>
                                <span class="icon-ok-1"></span>
                                <span *ngIf="busyCart"><i class="icon-spinner2"></i></span>
                            </button>
                            <input [id]="'qtyInput_'+item['ID']+i" class="abstract-list__product__qty"
                                placeholder="QTY" type="text"
                                [attr.data-test-id]="'abstractListProductQty_'+item['ID']"
                                (click)="$event.stopPropagation()"
                                (input)="setQuantity($event.target, item.ID, item.current_quantity)"
                                (paste)="false"/>
                        </div>
                    </div>

                    <div class="abstract-list__product-reviews">
                        <rating-display [dataTestId]="'abstractListProduct_'+i" [rate]="item.rate"></rating-display>
                    </div>
                </div>

                <div class="abstract-list__product__open"><i class="icon-right-open"></i></div>

            </div>

            <accordion class="abstract-list__li__footer" [indexOfItem]="i"
                [title]="item['nestedMarkets'].length + ' markets found, nearest in '"
                [label]="item['nestedMarkets'][0].distance + 'mi'"
                *ngIf="item['is_parent'] && item['nestedMarkets'] && item['nestedMarkets'].length">
                <market-table
                [indexOfItem]="i"
                (clickEvent$)="onClick($event)"
                [items]="item['nestedMarkets']"></market-table>
            </accordion>
        </li>

    </ul>

</div>
