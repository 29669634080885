import { CommonModule }                     from '@angular/common';
import { NgModule, ModuleWithProviders }    from '@angular/core';
import { RouterModule }                     from '@angular/router';

import { GoodsNavService }                  from './goods-nav.service';
import { GoodsNavComponent }                from './goods-nav.component';
import { GoodsNavHeaderComponent }          from './goods-nav-header.component';
import { InventoryNavComponent }            from './inventory-nav.component';
import { InventoryNavHeaderComponent }      from './inventory-nav-header.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
    ],
    declarations: [
        GoodsNavComponent,
        GoodsNavHeaderComponent,
        InventoryNavComponent,
        InventoryNavHeaderComponent
    ],
    exports: [GoodsNavComponent]
})
export class GoodsNavModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule:   GoodsNavModule,
            providers:  [GoodsNavService]
        };
    }
}
