import { CommonModule }                     from '@angular/common';
import { FormsModule }                      from '@angular/forms';
import { ModuleWithProviders, NgModule }    from '@angular/core';
import { RouterModule }                     from '@angular/router';

import { OrdersAppService }             from './orders.service';
import { AnalyticsComponent }           from './analytics.component';
import { AnalyticsHeaderComponent }     from './analytics-header.component';
import { DutyComponent }                from './duty.component';
import { HistoryComponent }             from './history.component';
import { SharedModule }                 from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
        RouterModule,
        SharedModule,
        TranslateModule,
        CommonModule,
        FormsModule
    ],
    declarations: [
        AnalyticsComponent,
        AnalyticsHeaderComponent,
        DutyComponent,
        HistoryComponent,
    ],
    exports: [
        AnalyticsComponent,
    ]
})
export class AnalyticsModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: AnalyticsModule,
            providers: [OrdersAppService]
        };
    }
}
