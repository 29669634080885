import { Subject } from 'rxjs/Subject';
import { BlankComponent } from '../modal/blank.component';
import { UnsupportedOrientationComponent } from './unsupported-orientation.component';
import { UnsupportedModalStatusType } from './dynamic-abstract-class';
import { UnsupportedAccountComponent } from './unsupported-account';
import AppValues from "../common/app.values";
import { TranslateService } from "@ngx-translate/core";
var UnsupportedDeviceService = /** @class */ (function () {
    function UnsupportedDeviceService(translate) {
        this.translate = translate;
        this.showModal$ = new Subject();
        this.currentModalType = BlankComponent;
    }
    Object.defineProperty(UnsupportedDeviceService.prototype, "modalType", {
        /**
         * Getter for type of currently displayed modal.
         * @returns {string}
         */
        get: function () {
            return this.currentModalType;
        },
        /**
         * Setter for type of currently displayed modal.
         * @param type
         */
        set: function (component) {
            this.currentModalType = component;
        },
        enumerable: true,
        configurable: true
    });
    UnsupportedDeviceService.prototype.close = function () {
        this.showModal$.next({
            context: {},
            component: BlankComponent
        });
    };
    UnsupportedDeviceService.prototype.unsupportedOrientation = function (options) {
        var modalOptions = { status: UnsupportedModalStatusType.warning, component: UnsupportedOrientationComponent };
        return this._createModal(modalOptions, options);
    };
    UnsupportedDeviceService.prototype.unsupportedAccount = function (options) {
        var modalOptions = { status: UnsupportedModalStatusType.warning, component: UnsupportedAccountComponent };
        return this._createModal(modalOptions, options);
    };
    UnsupportedDeviceService.prototype.unsupportedBrowser = function (options) {
        var modalOptions = {
            status: UnsupportedModalStatusType.warning,
            component: UnsupportedOrientationComponent,
            message: this.translate.instant('unsupportedBrowser.message', { browser_name: AppValues.currentBrowserName(), supported_browsers_list: AppValues.supported_browsers })
        };
        return this._createModal(modalOptions, options);
    };
    UnsupportedDeviceService.prototype._createModal = function (context, options) {
        if (options) {
            context = Object.assign(context, options);
        }
        var component = context && context.component ? context.component : BlankComponent;
        this.showModal$.next({
            component: component,
            context: context
        });
        return new Promise(function (resolve) {
            context.handler = function (arg) { return resolve(arg); };
        });
    };
    UnsupportedDeviceService.prototype.getCommand = function () {
        return this.showModal$.asObservable();
    };
    return UnsupportedDeviceService;
}());
export { UnsupportedDeviceService };
