/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bizipix-editor.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./item-picker.component.ngfactory";
import * as i3 from "./item-picker.component";
import * as i4 from "./bizipix-editor.service";
import * as i5 from "../services/geolocation.service";
import * as i6 from "../modal/modal.service";
import * as i7 from "../services/item.service";
import * as i8 from "../shared/bizipix-menu/bizipix-menu.component.ngfactory";
import * as i9 from "@angular/common";
import * as i10 from "../shared/bizipix-menu/bizipix-menu.component";
import * as i11 from "./bizipix-board.component.ngfactory";
import * as i12 from "./bizipix-board.component";
import * as i13 from "./editor-menu.component.ngfactory";
import * as i14 from "./editor-menu.component";
import * as i15 from "./bizipix-editor.component";
import * as i16 from "@ngx-translate/core";
var styles_BizipixEditorComponent = [i0.styles];
var RenderType_BizipixEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BizipixEditorComponent, data: {} });
export { RenderType_BizipixEditorComponent as RenderType_BizipixEditorComponent };
function View_BizipixEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "item-picker", [], null, [[null, "itemSelectEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemSelectEvent$" === en)) {
        var pd_0 = (_co.onItemSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ItemPickerComponent_0, i2.RenderType_ItemPickerComponent)), i1.ɵdid(1, 245760, null, 0, i3.ItemPickerComponent, [i4.BizipixEditorService, i5.GeoLocationService, i6.ModalService, i7.ItemService], null, { itemSelectEvent$: "itemSelectEvent$" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_BizipixEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "bizipix-menu", [["class", "bizipix-menu"]], null, [[null, "selectedBizipixEvent$"], [null, "createNewBizipixEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedBizipixEvent$" === en)) {
        var pd_0 = (_co.addOrChangeBizipixStrategyEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("createNewBizipixEvent$" === en)) {
        var pd_1 = (_co.addOrChangeBizipixStrategyEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_BizipixMenuComponent_0, i8.RenderType_BizipixMenuComponent)), i1.ɵdid(1, 278528, null, 0, i9.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { hidden: 0 }), i1.ɵdid(3, 49152, null, 0, i10.BizipixMenuComponent, [], { currentBizipix: [0, "currentBizipix"], imageData: [1, "imageData"], type: [2, "type"] }, { selectedBizipixEvent$: "selectedBizipixEvent$", createNewBizipixEvent$: "createNewBizipixEvent$" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bizipix-menu"; var currVal_1 = _ck(_v, 2, 0, _co.isPickerMode); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.currentBizipix; var currVal_3 = _co.bizipixesData; var currVal_4 = _co.editable; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }, null); }
function View_BizipixEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "bizipix-board", [], null, [[null, "boardEvent$"], [null, "changeBizipixInEditorEvent$"], ["window", "orientationchange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:orientationchange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).resetView($event) !== false);
        ad = (pd_0 && ad);
    } if (("boardEvent$" === en)) {
        var pd_1 = (_co.onBoardEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("changeBizipixInEditorEvent$" === en)) {
        var pd_2 = (_co.changeCurrentBizipixEvent($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_BizipixBoardComponent_0, i11.RenderType_BizipixBoardComponent)), i1.ɵdid(1, 278528, null, 0, i9.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { hidden: 0 }), i1.ɵdid(3, 49152, [[1, 4]], 0, i12.BizipixBoardComponent, [], { currentBizipixBoard: [0, "currentBizipixBoard"], uploadNewBizipix: [1, "uploadNewBizipix"], isFirstLoadingIsComplete: [2, "isFirstLoadingIsComplete"] }, { boardEvent$: "boardEvent$", changeBizipixInEditorEvent$: "changeBizipixInEditorEvent$" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isPickerMode); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.currentBizipix; var currVal_2 = _co.uploadNewBizipix; var currVal_3 = _co.isFirstLoadingIsComplete; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, null); }
function View_BizipixEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "bp__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "image placeholder"], ["data-test-id", "bizipixInstraction"], ["draggable", "false"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getInstructionImage(); _ck(_v, 1, 0, currVal_0); }); }
export function View_BizipixEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { board: 0 }), i1.ɵqud(402653184, 2, { fileInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "bp__editor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[2, 0], ["fileInput", 1]], null, 0, "input", [["accept", "imageTypes"], ["data-test-id", "uploadImageBtn"], ["style", "display: none"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onImageUpload({ payload: $event.target["files"][0], currentInput: $event.currentTarget }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BizipixEditorComponent_1)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "editor-menu", [], null, [[null, "editorEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("editorEvent$" === en)) {
        var pd_0 = (_co.onEditorEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_EditorMenuComponent_0, i13.RenderType_EditorMenuComponent)), i1.ɵdid(7, 278528, null, 0, i9.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(8, { hidden: 0 }), i1.ɵdid(9, 49152, null, 0, i14.EditorMenuComponent, [], null, { editorEvent$: "editorEvent$" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BizipixEditorComponent_2)), i1.ɵdid(11, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BizipixEditorComponent_3)), i1.ɵdid(13, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BizipixEditorComponent_4)), i1.ɵdid(15, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isPickerMode; _ck(_v, 5, 0, currVal_0); var currVal_1 = _ck(_v, 8, 0, _co.isPickerMode); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.currentBizipix; _ck(_v, 11, 0, currVal_2); var currVal_3 = (!_co.isReloading && _co.currentBizipix); _ck(_v, 13, 0, currVal_3); var currVal_4 = !_co.currentBizipix; _ck(_v, 15, 0, currVal_4); }, null); }
export function View_BizipixEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bizipix-editor", [], null, null, null, View_BizipixEditorComponent_0, RenderType_BizipixEditorComponent)), i1.ɵdid(1, 245760, null, 0, i15.BizipixEditorComponent, [i4.BizipixEditorService, i6.ModalService, i7.ItemService, i16.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BizipixEditorComponentNgFactory = i1.ɵccf("bizipix-editor", i15.BizipixEditorComponent, View_BizipixEditorComponent_Host_0, {}, {}, []);
export { BizipixEditorComponentNgFactory as BizipixEditorComponentNgFactory };
