import {
    Component, OnInit, ComponentRef,
    ComponentFactoryResolver, ViewContainerRef, ViewChild
} from '@angular/core';
import { Location }                             from '@angular/common';

import {ReviewInterfaceItem, ReviewProductInterface, SelectedOrder} from './review.interface';
import {AppReviewsService}                      from './reviews.service';
import {FormBuilder, FormGroup,
    Validators}                                 from '@angular/forms';
import {ModalService}                           from "../modal/modal.service";
import {AccessToSendReviewResponse, Review} from '../../../swagger-gen__output_dir';
import {CreateReviewsAbstractClass} from './create-review/create-review-abstract.class';
import {CreateReviewBuyerComponent} from './create-review/create-review-seller';
import {CreateReviewProductComponent} from './create-review/create-review-product';
import {AppFooterService} from '../app-footer/app-footer.service';
import {GetUserReviewsResponseBody} from '../../../swagger-gen__output_dir/model/getUserReviewsResponseBody';
import {CreateReviewOnBuyerRequestBody} from '../../../swagger-gen__output_dir/model/createReviewOnBuyerRequestBody';
import {CreateReviewOnProductRequestBody} from '../../../swagger-gen__output_dir/model/createReviewOnProductRequestBody';
import {Observable} from 'rxjs/Observable';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {ModalFilterArgumentInterface} from '../interfaces/modal.interface';



@Component({
    selector: 'reviews',
    styleUrls:  ['reviews.sass'],
    template: `
        <div #container></div>
    `
})

export class CreateReviewComponent implements OnInit {
    public selected_order:         SelectedOrder;
    public locationReviews:        string;
    public location:               Location;
    public reviewForm:             FormGroup;
    public textareaLength          = 200;

    public product_review_list:             ReviewProductInterface;
    public user_review_list:                GetUserReviewsResponseBody;

    @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

    private componentRef:           ComponentRef<{}>;

    private mappings                = {
        'product':    {
            instance: CreateReviewProductComponent,
            title: 'Product',
        },
        'buyer':
        {
            instance: CreateReviewBuyerComponent,
            title: 'Buyer',
        },
    };

    constructor(
        private componentFactoryResolver:   ComponentFactoryResolver,
        private fb:                         FormBuilder,
        private reviewsService:             AppReviewsService,
        private modalService:               ModalService,
        private footerService:              AppFooterService,
        private translate:                  TranslateService,
        private router:                     Router,
        location:           Location,
    ) {
        this.location = location;
    }

    ngOnInit() {
        this.buildForm();

        this.selected_order = this.reviewsService.selectedOrder;

        this.checkCustomer();

        this.renderComponent();
    }

    /**
     * Creates the CreateReview Component.
     * @desc Starts from clearing the previous component, chooses a proper class according to the
     * desired type, renders chosen class and inject the context into it.
     * @private
     */
    private renderComponent(): void {
        this.container.remove();

        let component       = this.instanceCreateReview.instance;
        let factory         = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef   = this.container.createComponent(factory);

        let instance        = <CreateReviewsAbstractClass> this.componentRef.instance;

        instance.context = Object.assign(
            {callback: this._executeCommand.bind(this)},
            {selected_order: this.selected_order},
            {product_review_list: this.product_review_list},
            {user_review_list: this.user_review_list},
            {reviewForm: this.reviewForm},
            {title: this.instanceCreateReview.title},
            {textareaLength: this.textareaLength}
        );
    }
    /**
     * Dispatches the Create Reviews Component commands.
     * @param commandObject
     * @private
     */
    private _executeCommand(commandObject: {}): void {
        this[Object.keys(commandObject)[0]](Object.values(commandObject)[0]);
    }

    /**
     * Table 6.2.2. Users permissions according to their role
     * Buyer can add review on Product
     * Seller can add review on Buyer
     * @return {any}
     */
    get instanceCreateReview() {
        if (this.isSeller) {
            return this.mappings.buyer;
        } else {
            return this.mappings.product;
        }
    }
    /**
     * @desc Checks the form validity. If valid, composes address object to
     * pass it to the ReviewService.
     */
    public createReviewSeller(): void {
        this.checkAndSendReview(this.reviewsService.createBuyerReview(this.reviewBuyerBody));
    }
    public createReviewProduct(): void {
        this.checkAndSendReview(this.reviewsService.createProductReview(this.reviewProductBody));
    }

    checkAndSendReview(createReviewMethod: Observable<Review>): void {
        if (Number(this.reviewForm.value.stars) === 0) {
            this.showAlert(this.translate.instant('createReview.stars.empty'));
            return;
        }
        if (this.reviewForm.value.text.trim() !== '') {

            this.modalService.showSpinner();

            createReviewMethod.subscribe((review: Review) => {
                this.modalService.close();

                this.reviewsService.listFilterTabs.forEach((filter: ModalFilterArgumentInterface, n: number) => {
                    this.reviewsService.listFilterTabs[n].active = false;

                    if (filter.value === 'purchases') {
                        this.reviewsService.listFilterTabs[n].active = true;
                    }
                });

                this.router.navigate(['/reviews']);
            }, (err: string) => {
                this.showAlert(err);
            });
        }
    }

    get reviewProductBody(): CreateReviewOnProductRequestBody {
        const v = this.reviewForm.value;
        return {
            stars:              v.stars,
            text:               v.text.trim(),
            order_element_id:   this.selected_order.order_element_id,
            product_id:           this.selected_order.ID
        };
    }

    get reviewBuyerBody(): CreateReviewOnBuyerRequestBody {
        const v = this.reviewForm.value;
        return {
            stars:              v.stars,
            text:               v.text.trim(),
            order_element_id:   this.selected_order.order_element_id,
            buyer_id:           this.selected_order.ID
        };
    }

    /**
     * Table 6.2.2. Users permissions according to their role
     * @return {boolean}
     */
    get isSeller(): boolean {
        return this.footerService.isSellerMode();
    }
    get isBuyer(): boolean {
        return !this.footerService.isSellerMode();
    }
    /** * **/

    /**
     * Builds the form group.
     * @desc Standard fields presetting and validators appointment.
     */
    private buildForm(): void {
        this.reviewForm = this.fb.group({
            'text': [
                '', [Validators.required, Validators.maxLength(this.textareaLength), Validators.minLength(0)]
                ],
            'stars': [
                '', [Validators.required]
                ]
        });
    }

    private checkCustomer() {
        if (this.isSeller) {
            this.user_review_list = this.reviewsService.getUserReviews;
        }
        if (this.isBuyer) {
            this.product_review_list = this.reviewsService.getProductReviews;
        }
    }

    private showAlert(message: string): void {
        this.modalService.error({title: this.translate.instant('review.alert.title'), message: message, yesButtonText: this.translate.instant('review.alert.confirm')});
    }

}
