/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../map-search/map-search.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../rating-display/rating-display.component.ngfactory";
import * as i4 from "../rating-display/rating-display.component";
import * as i5 from "./map-info.component";
var styles_MapInfoComponent = [i0.styles];
var RenderType_MapInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapInfoComponent, data: {} });
export { RenderType_MapInfoComponent as RenderType_MapInfoComponent };
function View_MapInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["data-test-id", "marketSchedule"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["data-test-id", "timeDatesInfo"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timeDatesInfo; _ck(_v, 2, 0, currVal_0); }); }
function View_MapInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "market"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "title"], ["data-test-id", "marketTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapInfoComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co._w.location.pathname === "/map-search"); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.market_title; _ck(_v, 2, 0, currVal_0); }); }
function View_MapInfoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "rating-display", [], null, null, null, i3.View_RatingDisplayComponent_0, i3.RenderType_RatingDisplayComponent)), i1.ɵdid(2, 49152, null, 0, i4.RatingDisplayComponent, [], { rate: [0, "rate"], dataTestId: [1, "dataTestId"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.rate; var currVal_1 = "map-info__seller-rate"; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data.voted; _ck(_v, 4, 0, currVal_2); }); }
function View_MapInfoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "seller"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "image__container"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["data-test-id", "sellerTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapInfoComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co._w.location.pathname === "/map-search"); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.imageUrl) + ")"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 4, 0, currVal_1); }); }
export function View_MapInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "map-info__container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showDetails(_co.data.ID) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapInfoComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapInfoComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "sprite sprite-map_callout_arrow_2x"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "market"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.type === "seller"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_MapInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "map-info", [], null, null, null, View_MapInfoComponent_0, RenderType_MapInfoComponent)), i1.ɵdid(1, 114688, null, 0, i5.MapInfoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapInfoComponentNgFactory = i1.ɵccf("map-info", i5.MapInfoComponent, View_MapInfoComponent_Host_0, { data: "data", timeDatesInfo: "timeDatesInfo" }, { clickEvent$: "clickEvent$" }, []);
export { MapInfoComponentNgFactory as MapInfoComponentNgFactory };
