import { AfterViewInit, ElementRef, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AppFooterService, UserMode } from './app-footer.service';
import { AppSearchService } from '../search/search.service';
import { UserService } from '../user/user.service';
import { ModalService } from '../modal/modal.service';
import { Observable } from 'rxjs/Rx';
var AppFooterComponent = /** @class */ (function () {
    function AppFooterComponent(appFooterService, elementRef, router, injector, userService) {
        this.appFooterService = appFooterService;
        this.elementRef = elementRef;
        this.router = router;
        this.injector = injector;
        this.userService = userService;
        this.isCategoryShown = false;
        this.isNavShown = false;
        this.userMode = UserMode;
        this.subscribeOnAppFooterServiceChanges();
    }
    AppFooterComponent.prototype.ngAfterViewInit = function () {
        this.mainBtn = this.elementRef.nativeElement.querySelector('span[data-test-id="footerMainBtn"]');
    };
    AppFooterComponent.prototype._setValues = function (mode) {
        this.mode = mode || this.appFooterService.getMode();
        // const _accessLevel = this.appFooterService.getAccessLevel();
        // 3 its admin rights
        this.isAdminAccessLevel = this.userService.isAdminMode();
    };
    AppFooterComponent.prototype.getMode = function () {
        return this.appFooterService.getMode();
    };
    AppFooterComponent.prototype.changeStatus = function (elem) {
        if (!elem || elem['classList'].contains('active'))
            return;
        this.hideAll();
        this.mode = this.appFooterService.setMode(this.mode === UserMode.buyerMode ? UserMode.sellerMode : UserMode.buyerMode);
        this.injector.get(AppFooterService).setModeOriginator(this.injector.get(AppFooterService).getMode());
        // only seller allowed for inventory list
        if (this.mode === UserMode.buyerMode && elem['getAttribute']('data-path') === '/inventory-list')
            return;
        this.router.navigate([elem['getAttribute']('data-path')]);
    };
    AppFooterComponent.prototype.goTo = function (elem) {
        var tgt = elem.closest('button');
        if (!tgt)
            return;
        var name = tgt.getAttribute('data-test-id');
        if (this.userService.isGuestMode && name !== 'cart') {
            return;
        }
        this.hideAll();
        if (name === 'users-list')
            this.search_service.is_key_search = true;
        if (this.type === 'guest' && name === 'profile')
            return;
        else
            this.router.navigate(['/' + name]);
    };
    Object.defineProperty(AppFooterComponent.prototype, "search_service", {
        /***
         * @desc This function creates 'search service' property on your service.
         * @return {AppSearchService}
         */
        get: function () {
            return this.injector.get(AppSearchService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppFooterComponent.prototype, "modalService", {
        get: function () {
            return this.injector.get(ModalService);
        },
        enumerable: true,
        configurable: true
    });
    AppFooterComponent.prototype.showMainMenu = function () {
        var _this = this;
        this.counts = null;
        this.isNavShown = false;
        if (this.isCategoryShown) {
            this.isCategoryShown = false;
        }
        else {
            this.modalService.showSpinner();
            // @ts-ignore
            this.appFooterService.getCounts()
                .subscribe(function (counts) {
                _this.counts = counts;
                _this.isCategoryShown = true;
                _this.modalService.close();
            }, function (err) { return Observable.throw(err); });
        }
    };
    AppFooterComponent.prototype.showGoodsNav = function () {
        this.isCategoryShown = false;
        this.isNavShown = !this.isNavShown;
    };
    AppFooterComponent.prototype.onModeChange = function (category) {
        var _this = this;
        if (this.mode === UserMode.sellerMode) {
            this.showNavigation(category);
        }
        else {
            this.modalService.showSpinner();
            this.appFooterService.getNavTree()
                .subscribe(function (categoryList) {
                _this.modalService.close();
                categoryList = categoryList.filter(function (categoryItem) { return category.ID === categoryItem.ID; });
                _this.showNavigation(categoryList[0]);
            }, function (error) { return _this.modalService.close(); });
        }
    };
    AppFooterComponent.prototype.showNavigation = function (category) {
        this.hideAll();
        this.appFooterService.showNavigation(category, this.mode === UserMode.sellerMode);
    };
    AppFooterComponent.prototype.hideAll = function () {
        this.isCategoryShown = false;
        this.isNavShown = false;
    };
    /**
     * @desc Subscribes on AppFooter Service changes
     * @private
     */
    AppFooterComponent.prototype.subscribeOnAppFooterServiceChanges = function () {
        var _this = this;
        this._setValues();
        this.appFooterService.getUsersData().subscribe(function () {
            _this._setValues();
        });
        this.appFooterService.onModeChanges.subscribe(function (mode) {
            _this._setValues(mode);
        });
    };
    return AppFooterComponent;
}());
export { AppFooterComponent };
