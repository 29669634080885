var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/observable/throw';
import { UserService } from '../user/user.service';
import { DataService } from '../services/data.service';
import { FavoriteListService } from '../favorite-list/favorite-list.service';
import { ModalService } from '../modal/modal.service';
import { ErrorService } from '../services/error.service';
import { WatchListService } from '../watch-list/watch-list.service';
import { ItemService } from '../services/item.service';
import { AdapterProductView } from './adapter-product-view';
import { AppRouteValues, createDirection } from '../common/app.route.values';
import { TranslateService } from '@ngx-translate/core';
import { getErrorType } from '../services/error.helpers';
var ProductDetailsService = /** @class */ (function () {
    function ProductDetailsService(dataService, errorService, favoriteListService, modalService, router, itemService, watchListService, userService, translate) {
        this.dataService = dataService;
        this.errorService = errorService;
        this.favoriteListService = favoriteListService;
        this.modalService = modalService;
        this.router = router;
        this.itemService = itemService;
        this.watchListService = watchListService;
        this.userService = userService;
        this.translate = translate;
        this.options = {};
        this.getProductEvent$ = new EventEmitter();
        this.token = this.userService.getUserSession().token;
    }
    Object.defineProperty(ProductDetailsService.prototype, "productOptions", {
        get: function () {
            return this.options;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @desc Fetches userSession data, product details of the item and its priceAlert object.
     * @param {string} id
     * @param options
     */
    // TODO Refactoring, depending on necessity of using withRedirect flag
    ProductDetailsService.prototype.showProductDetails = function (id, options, withRedirect) {
        var _this = this;
        if (withRedirect === void 0) { withRedirect = true; }
        this.token = this.userService.getUserSession().token;
        if (!this.token || !id) {
            return;
        }
        this.setProductOptions(options);
        this.modalService.showSpinner();
        if (typeof options !== 'undefined' && options.item) {
            return Observable.of(this._setDataForProductDetails(id, options.item, withRedirect));
        }
        else {
            return new Observable(function (observer) {
                _this.getProduct(id).subscribe(function (response) {
                    observer.next(_this._setDataForProductDetails(id, response, withRedirect));
                    observer.complete();
                }, function (error) {
                    observer.error(error);
                    observer.complete();
                });
            });
        }
    };
    ProductDetailsService.prototype.getProduct = function (productId) {
        var _this = this;
        return this.dataService
            .getData("get_item?item_id=" + productId, { token: this.token })
            .catch(function (error) {
            var err = error.body_error_text || getErrorType(error);
            return _this.errorService.handleWarning(err);
        })
            .map(function (product) {
            return product;
        });
    };
    ProductDetailsService.prototype.setProductOptions = function (options) {
        this.options = __assign({}, this.options, options);
    };
    ProductDetailsService.prototype._setDataForProductDetails = function (id, item, withRedirect) {
        var isExpired = this.itemService.isExpiredFn(item);
        if (Object.keys(item).length === 0) {
            this._showNotification(this.translate.instant('notification.product.notAvailable'));
            return;
        }
        if (item.draft === true || isExpired === true) {
            this._showNotification(this.translate.instant('notification.product.notLongerAvailable'));
            return;
        }
        else if (this.userService.getUser().accessLevel !== 0) {
            this._getPriceAlert(id, item, withRedirect);
        }
        else {
            return this._productAndPriceResult({ priceObject: {}, item: item, id: id, withRedirect: withRedirect });
        }
    };
    ProductDetailsService.prototype._getPriceAlert = function (id, item, withRedirect) {
        var _this = this;
        this.getProductPriceAlert(id).subscribe(function (priceObject) {
            _this._productAndPriceResult({ priceObject: priceObject, item: item, id: id, withRedirect: withRedirect });
        }, function (err) {
            _this.errorService.handleError(err);
        });
    };
    // tslint:disable-next-line:member-ordering
    ProductDetailsService.prototype.getProductPriceAlert = function (id) {
        var _this = this;
        return this.dataService.getData("price_alert?item_id=" + id, { token: this.userService.getUserSession().token })
            .do(function (priceAlert) { return priceAlert; })
            .catch(function (error) { return _this.errorService.handleError(error); });
    };
    ProductDetailsService.prototype._productAndPriceResult = function (data) {
        this.priceAlert = data.priceObject;
        this.item = data.item;
        this.getProductEvent$.emit(this.getItem());
        if (data.withRedirect) {
            this.router.navigate([createDirection(AppRouteValues.routeProductDetails), data.id]);
        }
        this.modalService.close();
        return this.item;
    };
    /**
     * @desc get Item
     * @returns {SellingItemResponseBody}
     */
    // tslint:disable-next-line:member-ordering
    ProductDetailsService.prototype.getItem = function () {
        return this.item ? new AdapterProductView(this.item).initProduct() : undefined;
    };
    /**
     * @desc get price alert
     * @returns {PriceAlertInterface}
     */
    // tslint:disable-next-line:member-ordering
    ProductDetailsService.prototype.getPriceAlert = function () {
        return this.priceAlert;
    };
    // tslint:disable-next-line:member-ordering
    ProductDetailsService.prototype.isPriceAlertOn = function (priceAlert) {
        return priceAlert && Object.keys(priceAlert).length !== 0 && priceAlert.is_active;
    };
    /**
     * @desc get options, if they are
     * @returns {ProductDetailsOptionsInterface | {}}
     */
    // tslint:disable-next-line:member-ordering
    ProductDetailsService.prototype.getOptions = function () {
        return this.options || {};
    };
    // tslint:disable-next-line:member-ordering
    ProductDetailsService.prototype.setProductDetailsFrom = function (productDetailsFrom) {
        this.set_product_details_from = productDetailsFrom;
    };
    // tslint:disable-next-line:member-ordering
    ProductDetailsService.prototype.getProductDetailsFrom = function () {
        return this.set_product_details_from;
    };
    /**
     * @desc Check if the current user has reported this item before.
     * @param {string} itemID
     */
    // tslint:disable-next-line:member-ordering
    ProductDetailsService.prototype.isInReportList = function (itemID) {
        var _this = this;
        this.token = this.userService.getToken();
        if (this.token) {
            return this.dataService.getData("report_by_item_id?item_id=" + itemID, { token: this.token })
                .map(function (res) {
                var userId = _this.userService.getUserSession().ID;
                return res.reports.findIndex(function (item) { return item.reporter_id === userId; }) >= 0;
            })
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
        return of({});
    };
    /**
     * @desc Check if this product is in the WatchList
     * @param {string} itemID
     * @param isVisibleSpinner
     * @returns {Observable<boolean>}
     */
    ProductDetailsService.prototype.isInWatchList = function (itemID, isVisibleSpinner) {
        if (isVisibleSpinner === void 0) { isVisibleSpinner = true; }
        return this.watchListService.isInWatchList(itemID, isVisibleSpinner);
    };
    /**
     * @desc Checks if desired price alert value equals or is higher then current price;
     * if so calls to show user notification. Otherwise calls to set price alert, or remove
     * it on zero value.
     * @param price
     * @returns {Observable}
     */
    // tslint:disable-next-line:member-ordering
    ProductDetailsService.prototype.setPriceAlert = function (price) {
        var _this = this;
        var api = 'set_price_alert';
        var itemPrice = this.itemService.isSaleFn(this.item) ? this.item.salePrice : this.item.price;
        if (typeof price === 'number' && price >= itemPrice) {
            return this.errorService.handleError({
                status: undefined,
                statusText: this.translate.instant('notification.product.alertValueHigher')
            });
        }
        else if (typeof price === 'number' && price <= 0) {
            return this.errorService.handleError({
                status: undefined,
                statusText: this.translate.instant('notification.product.alertValueZero')
            });
        }
        var body = {
            item_id: this.item.ID,
            price: price
        };
        if (!price) {
            api = 'remove_price_alert';
            delete body.price;
        }
        return this.dataService.postData(api, body, { token: this.token })
            .map(function (response) { return response; })
            .catch(function (err) {
            _this.errorService.handleError(err);
            return Observable.throwError(err);
        });
    };
    /**
     * @desc Delegates showing picture to ModalService.
     * @param url
     */
    // tslint:disable-next-line:member-ordering
    ProductDetailsService.prototype.showCertificate = function (url) {
        this.modalService.showImage(url);
    };
    /**
     * @desc If the item is in FavList, calls remove method of
     * the service; otherwise calls add method. On success updates User (favList object)
     * and FavList model.
     * @returns {Observable<R|T>}
     */
    // tslint:disable-next-line:member-ordering
    ProductDetailsService.prototype.toggleFavListStatus = function () {
        var id = this.item.ID;
        var user = this.userService.getUser();
        // @ts-ignore
        var index = user.favList.items.indexOf(id);
        if (index > -1) {
            return this.favoriteListService.removeItem(id);
        }
        else {
            return this.favoriteListService.addItem(id);
        }
    };
    /**
     * @desc If the item is in WatchList, calls remove method,
     * otherwise calls add method. On success updates User (watchList object)
     * and WatchList model.
     * @returns {Observable<R|T>}
     */
    ProductDetailsService.prototype.toggleWatchListStatus = function (itemID) {
        return this.watchListService.toggleWatchListStatus(itemID);
    };
    /**
     * @desc Show modal window about some error
     * @private
     */
    ProductDetailsService.prototype._showNotification = function (test) {
        this.modalService.error({
            title: this.translate.instant('notification.someError.title'),
            message: test,
            yesButtonText: this.translate.instant('notification.someError.confirmButton'),
        });
    };
    ProductDetailsService.prototype.sendReport = function (itemID) {
        var _this = this;
        if (!this.token) {
            return;
        }
        else {
            return this.dataService.postData('report_item', { 'item_id': itemID }, { token: this.token })
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
    };
    ProductDetailsService.prototype.removingItemFromWatchList = function () {
        return this.watchListService.removingItemFromWatchList();
    };
    return ProductDetailsService;
}());
export { ProductDetailsService };
