import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

@Component({
  selector: 'accordion',
  template: `
    <div class="accordion">
        <div class="accordion__header" (click)="opened = !opened">
            <div class="accordion__header__arrow"
                [attr.data-test-id]="'switchItem_'+indexOfItem"
                [ngClass]="{'accordion__header__arrow__opened': opened}"></div>
            <span class="accordion__header__title">
                {{ title }}
            </span>
            <span class="accordion__header__label" [attr.data-test-id]="'minMarketDistance_'+indexOfItem">
                {{ label }}
            </span>
        </div>
        <div class="accordion__body" *ngIf="opened">
            <ng-content></ng-content>
        </div>
    </div>
  `,
  styleUrls: ['accordion.sass'],
})
export class AccordionComponent {
    @Input()    indexOfItem = Number;
    @Input()    opened = false;
    @Input()    title: string;
    @Input()    label: string;
    @Output()   toggle: EventEmitter<any> = new EventEmitter<any>();
}
