
        <div class="modal__content" data-test-id="modal_image_content">
            <div class="modal__img text-center">
                <img data-test-id="modal_image" [src]="context.message">
            </div>
            
            <div class="text-center">
                <button class="modal__btn" data-test-id="yesButton"
                        (click)="context._close()">{{ "modal.alert.confirm" | translate }}</button>
            </div>
        </div>
    