var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { EmailPageAbstractClass } from '../helpers/email-page.abstract.class';
import { ModalService } from '../../modal/modal.service';
import { UserService } from '../user.service';
import { TranslateService } from '@ngx-translate/core';
var LoginLinkComponent = /** @class */ (function (_super) {
    __extends(LoginLinkComponent, _super);
    function LoginLinkComponent(elementRef, modalService, router, translate, userService) {
        var _this = _super.call(this, elementRef, modalService, router, translate) || this;
        _this.elementRef = elementRef;
        _this.modalService = modalService;
        _this.router = router;
        _this.translate = translate;
        _this.userService = userService;
        _this.email = '';
        _this.successMsg = _this.translate.instant("remindLogin.success");
        return _this;
    }
    Object.defineProperty(LoginLinkComponent.prototype, "descriptionText", {
        get: function () {
            return this.translate.instant('forgotLogIn.descriptionText');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginLinkComponent.prototype, "descriptionTextAboutLink", {
        get: function () {
            return this.translate.instant('forgotLogIn.descriptionTextAboutLink');
        },
        enumerable: true,
        configurable: true
    });
    LoginLinkComponent.prototype._sendRequest = function () {
        return this.userService.getTemporaryLoginLink(this.email.trim());
    };
    return LoginLinkComponent;
}(EmailPageAbstractClass));
export { LoginLinkComponent };
