import {TranslateService} from '@ngx-translate/core';
import {NotificationAbandonedProduct, PushNotificationMessageI} from './cart-report.interface';
import {ShoppingCartElement} from '../../../swagger-gen__output_dir/model/shoppingCartElement';
import {CookieService} from '../services/cookie.service';
import {PushNotificationsService} from '../services/push.notification.service';
import {Injectable, Injector} from '@angular/core';
import AppValues from '../common/app.values';
import {CheckTabStatusService, TabStatus} from '../services/check-tab-status';
import {Observable} from "rxjs/Rx";

@Injectable()
export class AbandonedProduct {

    constructor (
        private injector:      Injector,
        private checkTabStatusService: CheckTabStatusService
    ) { }

    public createrOfAbandonedProductList(items: ShoppingCartElement[], isUpdateNow: boolean = false): Array<NotificationAbandonedProduct> {
        return items.map((product: ShoppingCartElement) => this.createrOfAbandonedProductObject({element: product, isUpdateNow}));
    }

    /**
     * AbandenedProductObject where notification times in minutes
     * @param data
     * @return {NotificationAbandonedProduct}
     */
    public createrOfAbandonedProductObject(data: {element: ShoppingCartElement, isUpdateNow?: boolean}): NotificationAbandonedProduct {
        const createTime: number = data.isUpdateNow ? AppValues.getDateNow() : data.element.addedAt;
        return {
            ID: data.element.ID,
            item: data.element.item,
            createTime: createTime,
            isShowNotification: false,
            notificationTime: [10]
        };
    }

    public updateAbandonedProducts(items: ShoppingCartElement[], isUpdateNow: boolean = false): Array<string> {
        const abandonedProductList: Array<NotificationAbandonedProduct> = this.createrOfAbandonedProductList(items, isUpdateNow);

        return abandonedProductList.map((notifyInfo: NotificationAbandonedProduct) => {
            this.cookieService.setCookie('abandonedItemCreateTime_' + notifyInfo.ID, notifyInfo.createTime);
            this.cookieService.setCookie('abandonedItemNotificationTime_' + notifyInfo.ID, notifyInfo.notificationTime.join(','));
            this.cookieService.setCookie('abandonedItemIsShowNotification_' + notifyInfo.ID, notifyInfo.isShowNotification);

            return notifyInfo.ID;
        });
    }
    public showNotificationAbandonedProduct(idProduct: string): void {
        this.cookieService.setCookie('abandonedItemIsShowNotification_' + idProduct, 'true');
    }
    public isShowNotificationAbandonedProduct(idProduct: string): boolean {
        const abandonedItemIsShowNotification: string = this.cookieService.getCookie('abandonedItemIsShowNotification_' + idProduct);
        return abandonedItemIsShowNotification === 'true';
    }


    public prepareParametersForPushNotification(windowLocation: Location, abandonedItemID: string): void {
        let PNMessages: Array<PushNotificationMessageI> = [{
            title: this.translate.instant("notification.local.abandonedCart.title"),
            alertContent: this.translate.instant("notification.local.abandonedCart.body"),
            navigateTo:  windowLocation.origin + '/cart',
            abandonedItemID: abandonedItemID
        }];
        this.pushNotificationService.generateNotification(PNMessages);
    }

    public createrOfAbandonedItemsIDs(): Array<string> {
        let abandonedItemsIDs: Array<string> = new Array;

        const allCookies = this.cookieService.getAllCookies();
        allCookies.forEach((c: string) => {
            if (c.includes('abandonedItemNotificationTime_')) {
                const id = c.split('_').pop().split('=')[0];
                abandonedItemsIDs.push(id);
            }
        });

        return abandonedItemsIDs;
    }

    public removeOneAbandonedItem(ID: string, abandonedItemsIDs: Array<string>): Array<string> {
        this.removeOneAbandonedItemFromStage(ID);

        return abandonedItemsIDs.filter((id: string) => id !== ID);
    }

    public removeOneAbandonedItemFromStage(ID: string): void {
        this.cookieService.deleteCookie('abandonedItemNotificationTime_' + ID);
        this.cookieService.deleteCookie('abandonedItemCreateTime_' + ID);
        this.cookieService.deleteCookie('abandonedItemIsShowNotification_' + ID);
    }

    public getAllAbandonedItemIDs(): Array<string> {
        return this.cookieService.getAllMatchingCookiesName('abandonedItem');
    }

    public removeAllAbandonedItemsFromStage(): void {
        this.getAllAbandonedItemIDs().forEach((c: string) => {
            this.removeOneAbandonedItemFromStage(c.split('_')[1]);
        });
    }

    /**
     * @param itemId
     * @param windowLocation
     */
    public checkExpirationOfProduct(itemId: string, windowLocation: Location): Observable<boolean> {
        let timestamp_now = AppValues.getDateNow();

        const abandonedItemNotificationTime: Array<string> = this.cookieService.getCookie('abandonedItemNotificationTime_' + itemId).split(',');

        if (abandonedItemNotificationTime.length) {
            abandonedItemNotificationTime.forEach((time: string) => {
                const abandonedItemCreateTime: number = parseInt(this.cookieService.getCookie('abandonedItemCreateTime_' + itemId), 0) || 0;
                const abandonedItemTime: number = new Date((timestamp_now - (abandonedItemCreateTime - 10)) * 1000).getMinutes();

                const isHiddenTab: boolean = this.checkTabStatusService.tabStatus === TabStatus.hidden;

                console.log((abandonedItemTime >= parseInt(time, 0)) + '; isHiddenTab: ' + isHiddenTab + '; isShowNotificationAbandonedProduct(itemId): ' + this.isShowNotificationAbandonedProduct(itemId));
                if (abandonedItemTime >= parseInt(time, 0) && isHiddenTab && !this.isShowNotificationAbandonedProduct(itemId)) {
                    this.showNotificationAbandonedProduct(itemId);
                    this.prepareParametersForPushNotification(windowLocation, itemId);

                    return Observable.of(true);
                }
                return Observable.of(false);
            });
        }
        return Observable.of(false);
    }

    public changeTabStatus(status: TabStatus): void {
        this.checkTabStatusService.timerHandler(status);
    }
    public get pushNotificationService(): PushNotificationsService {
        return this.injector.get(PushNotificationsService);
    }

    public get translate(): TranslateService {
        return this.injector.get(TranslateService);
    }

    public get cookieService(): CookieService {
        return this.injector.get(CookieService);
    }

    public abandonedItemsTabNotify(): void {
        this.checkTabStatusService.blinkTab(this.translate.instant("notification.local.abandonedCart.blinkingTab.text"));
    }
}
