/**
 * BiziBaza app
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AccessToSendReviewResponse } from '../model/accessToSendReviewResponse';
import { CommonErrorResonse } from '../model/commonErrorResonse';
import { CreateReviewOnBuyerRequestBody } from '../model/createReviewOnBuyerRequestBody';
import { CreateReviewOnProductRequestBody } from '../model/createReviewOnProductRequestBody';
import { GetUserReviewsResponseBody } from '../model/getUserReviewsResponseBody';
import { ItemReviewsResponseBody } from '../model/itemReviewsResponseBody';
import { MarkReviewsAsReadRequestBody } from '../model/markReviewsAsReadRequestBody';
import { MarkReviewsAsReadResponseBody } from '../model/markReviewsAsReadResponseBody';
import { Review } from '../model/review';
import { ReviewsResponseBody } from '../model/reviewsResponseBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ReviewsService {

    protected basePath = 'https://dev-backend.bizibaza.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Returns list of reviews on buyer.
     * 
     * @param token Current session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public buyerReviewGet(token: string, observe?: 'body', reportProgress?: boolean): Observable<ReviewsResponseBody>;
    public buyerReviewGet(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReviewsResponseBody>>;
    public buyerReviewGet(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReviewsResponseBody>>;
    public buyerReviewGet(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling buyerReviewGet.');
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ReviewsResponseBody>(`${this.basePath}/buyer_review`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates review on buyer.
     * 
     * @param body .
     * @param token Current session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public buyerReviewPost(body: CreateReviewOnBuyerRequestBody, token: string, observe?: 'body', reportProgress?: boolean): Observable<Review>;
    public buyerReviewPost(body: CreateReviewOnBuyerRequestBody, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Review>>;
    public buyerReviewPost(body: CreateReviewOnBuyerRequestBody, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Review>>;
    public buyerReviewPost(body: CreateReviewOnBuyerRequestBody, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling buyerReviewPost.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling buyerReviewPost.');
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Review>(`${this.basePath}/buyer_review`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates review on product.
     * 
     * @param body .
     * @param token Current session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createReviewPost(body: CreateReviewOnProductRequestBody, token: string, observe?: 'body', reportProgress?: boolean): Observable<Review>;
    public createReviewPost(body: CreateReviewOnProductRequestBody, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Review>>;
    public createReviewPost(body: CreateReviewOnProductRequestBody, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Review>>;
    public createReviewPost(body: CreateReviewOnProductRequestBody, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createReviewPost.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling createReviewPost.');
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Review>(`${this.basePath}/create_review`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns list of reviews on product.
     * 
     * @param item_id Product ID.
     * @param offset Pagination offset.
     * @param limit Pagination limit.
     * @param token Current session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getItemReviewGet(item_id: string, offset: number, limit: number, token: string, observe?: 'body', reportProgress?: boolean): Observable<ItemReviewsResponseBody>;
    public getItemReviewGet(item_id: string, offset: number, limit: number, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemReviewsResponseBody>>;
    public getItemReviewGet(item_id: string, offset: number, limit: number, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemReviewsResponseBody>>;
    public getItemReviewGet(item_id: string, offset: number, limit: number, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (item_id === null || item_id === undefined) {
            throw new Error('Required parameter item_id was null or undefined when calling getItemReviewGet.');
        }

        if (offset === null || offset === undefined) {
            throw new Error('Required parameter offset was null or undefined when calling getItemReviewGet.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling getItemReviewGet.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getItemReviewGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (item_id !== undefined && item_id !== null) {
            queryParameters = queryParameters.set('item_id', <any>item_id);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ItemReviewsResponseBody>(`${this.basePath}/get_item_review`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns list of reviews on seller&#x27;s products.
     * 
     * @param token Current session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSellerReviewGet(token: string, observe?: 'body', reportProgress?: boolean): Observable<ReviewsResponseBody>;
    public getSellerReviewGet(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReviewsResponseBody>>;
    public getSellerReviewGet(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReviewsResponseBody>>;
    public getSellerReviewGet(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getSellerReviewGet.');
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ReviewsResponseBody>(`${this.basePath}/get_seller_review`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns lists of user&#x27;s reviews.
     * 
     * @param user_id Unique identifier of user that has been reviewed.
     * @param token Current session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserReviewsGet(user_id: string, token: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserReviewsResponseBody>;
    public getUserReviewsGet(user_id: string, token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserReviewsResponseBody>>;
    public getUserReviewsGet(user_id: string, token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserReviewsResponseBody>>;
    public getUserReviewsGet(user_id: string, token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling getUserReviewsGet.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getUserReviewsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (user_id !== undefined && user_id !== null) {
            queryParameters = queryParameters.set('user_id', <any>user_id);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GetUserReviewsResponseBody>(`${this.basePath}/get_user_reviews`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * To check that is possible to send review (on product or buyer)
     * Returns ability to leave a comment, whether selected product was purchased whether requests on this product were previously posted
     * @param buyer_id uniq ID of buyer
     * @param is_item_review 1 (If need to check ability review on product) or 0 (If need check ability review on buyer)
     * @param token Current session token.
     * @param item_id uniq ID of product (If need to check ability review on product) or absent (If need to check ability review on buyer)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isPossibleToSendReviewGet(buyer_id: string, is_item_review: number, token: string, item_id?: string, observe?: 'body', reportProgress?: boolean): Observable<AccessToSendReviewResponse>;
    public isPossibleToSendReviewGet(buyer_id: string, is_item_review: number, token: string, item_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessToSendReviewResponse>>;
    public isPossibleToSendReviewGet(buyer_id: string, is_item_review: number, token: string, item_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessToSendReviewResponse>>;
    public isPossibleToSendReviewGet(buyer_id: string, is_item_review: number, token: string, item_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (buyer_id === null || buyer_id === undefined) {
            throw new Error('Required parameter buyer_id was null or undefined when calling isPossibleToSendReviewGet.');
        }

        if (is_item_review === null || is_item_review === undefined) {
            throw new Error('Required parameter is_item_review was null or undefined when calling isPossibleToSendReviewGet.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling isPossibleToSendReviewGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (item_id !== undefined && item_id !== null) {
            queryParameters = queryParameters.set('item_id', <any>item_id);
        }
        if (buyer_id !== undefined && buyer_id !== null) {
            queryParameters = queryParameters.set('buyer_id', <any>buyer_id);
        }
        if (is_item_review !== undefined && is_item_review !== null) {
            queryParameters = queryParameters.set('is_item_review', <any>is_item_review);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AccessToSendReviewResponse>(`${this.basePath}/is_possible_to_send_review`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Marks reviews as read.
     * 
     * @param body List of unique identifiers of reviews that should be marked as read.
     * @param Token current session token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public markReviewsAsReadPost(body: MarkReviewsAsReadRequestBody, Token: string, observe?: 'body', reportProgress?: boolean): Observable<MarkReviewsAsReadResponseBody>;
    public markReviewsAsReadPost(body: MarkReviewsAsReadRequestBody, Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MarkReviewsAsReadResponseBody>>;
    public markReviewsAsReadPost(body: MarkReviewsAsReadRequestBody, Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MarkReviewsAsReadResponseBody>>;
    public markReviewsAsReadPost(body: MarkReviewsAsReadRequestBody, Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling markReviewsAsReadPost.');
        }

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling markReviewsAsReadPost.');
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MarkReviewsAsReadResponseBody>(`${this.basePath}/mark_reviews_as_read`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
