import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../modal/modal.service';
import { StorageService } from '../services/storage.service';
import { CookieService } from '../services/cookie.service';
import { ExpiredLoginLinkResolver } from './after-boot-strategy/expired-login-link-resolver';
import { InvalidSessionResolver } from './after-boot-strategy/invalid-session-resolver';
import { WasLoggedInResolver } from './after-boot-strategy/was-logged-in-resolver';
var AfterBootService = /** @class */ (function () {
    function AfterBootService(modalService, storageService, cookieService, injector) {
        this.modalService = modalService;
        this.storageService = storageService;
        this.cookieService = cookieService;
        this.injector = injector;
        this.isLoginLink = false;
        this.wasUserLoggedIn = false;
        this.location = window.location;
    }
    Object.defineProperty(AfterBootService.prototype, "isAlreadyLoggedIn", {
        set: function (value) {
            this.wasUserLoggedIn = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AfterBootService.prototype, "isLoginLinkLaunch", {
        set: function (value) {
            this.isLoginLink = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AfterBootService.prototype, "userServiceSave", {
        set: function (service) {
            this.userService = service;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AfterBootService.prototype, "router", {
        /**
         * @desc This function creates router property on your service.
         * @return {Router}
         */
        get: function () {
            return this.injector.get(Router);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AfterBootService.prototype, "afterLoadingError", {
        set: function (error) {
            this.loadingError = error;
        },
        enumerable: true,
        configurable: true
    });
    AfterBootService.prototype.getStrategy = function () {
        if (this.isLoginLink && this.loadingError
            && this.loadingError.status === 403) {
            this.isLoginLinkLaunch = false;
            this.loadingError = null;
            return new ExpiredLoginLinkResolver();
        }
        if (this.loadingError && this.loadingError.status === 403) {
            this.loadingError = null;
            return new InvalidSessionResolver();
        }
        if (this.wasUserLoggedIn) {
            return new WasLoggedInResolver();
        }
    };
    // tslint:disable-next-line:member-ordering
    AfterBootService.prototype.handleResolver = function () {
        this.startegy = this.getStrategy();
        if (this.startegy) {
            this.isAlreadyLoggedIn = this.startegy.resolveWarning(this.modalService, this.userService, this.router, this.wasUserLoggedIn);
            this.startegy = null;
        }
        this.clearPurchaseState();
    };
    AfterBootService.prototype.clearPurchaseState = function () {
        if (this.location.pathname !== '/cart-report') {
            var path = this.storageService.get('path');
            path && this.router.navigate([path]);
            this.cookieService.deleteCookie('payment_token');
            this.cookieService.deleteCookie('pay_key');
        }
        this.storageService.removePurchaseMemory();
    };
    return AfterBootService;
}());
export { AfterBootService };
