import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges
} from '@angular/core';
import {Subject} from 'rxjs';

import {AppFooterService, UserMode} from './app-footer.service';
import {CategoryInterface} from '../interfaces/category.interface';
import {ErrorInterface} from '../services/error.service';
import {Observable} from 'rxjs/Rx';

@Component({
    selector:   'category-selector',
    styleUrls:  ['app-footer.sass'],

    template:   `
        <div class="footer__popup footer__category" *ngIf="categories">
            <div class="footer__popup__category">
                <div class="select-category text-center"
                     *ngFor="let category of categories"
                     (click)="showCategory(category)"
                     [style.color]="category.category_name_text_color"
                     attr.data-id="{{category.ID}}">

                    <img src="{{category.category_image}}" alt="{{category.category_name}}"
                         [attr.data-test-id]="category.category_name">

                    <h4>{{category.category_name}}</h4>

                </div>
            </div>
        </div>
    `
})


export class CategorySelectorComponent implements OnInit, OnChanges {
    /**
     * @desc Fetches navigation tree data and renders categories menu.
     * @listens to the mode input, which can be seller or buyer.
     * @emits showCategoryEvent$ on category title click.
     */
    @Input()    public mode:               UserMode;
    @Output()   public showCategoryEvent$: EventEmitter<CategoryInterface> = new EventEmitter<CategoryInterface>();

    public categories:                     CategoryInterface[];
    private componentDestroyed$:           Subject<boolean> = new Subject();


    public constructor(
        private appFooterService:   AppFooterService,
    ) { }


    public ngOnInit(): void {
        this.getCategories(this.mode);
    }

    /**
     * @desc On each change of the mode input, checks if it has changed.
     * If so, fetches navigation tree data via AppFooterService; full tree
     * for seller and sho rt one for buyer.
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        const mode: SimpleChange = changes.mode;

        if (mode.previousValue !== mode.currentValue) {
            this.getCategories(mode.currentValue);
        }
    }

    public getCategories(mode: UserMode): void {
        const isFullNavTree: boolean = mode === UserMode.sellerMode;

        this.appFooterService
            .getNavTree(isFullNavTree)
            .takeUntil(this.componentDestroyed$)
            .subscribe(
                (response: CategoryInterface[]) => {
                    this.categories = response;
                },
                (err: ErrorInterface) => Observable.throwError(err));
    }

    /**
     * @emits showCategoryEvent$ on category title click.
     * @param category
     */
    public showCategory(category: CategoryInterface): void {
        this.showCategoryEvent$.emit(category);
    }

}
