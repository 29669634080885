import { Subject } from 'rxjs/Subject';
import { BlankComponent } from '../modal/blank.component';
import { PrivacyPolicyComponent } from './privacy-policy.component';
import { StorageService } from "../services/storage.service";
var CacheNotificationService = /** @class */ (function () {
    function CacheNotificationService(storageService) {
        this.storageService = storageService;
        this.showModal$ = new Subject();
        this.currentModalType = BlankComponent;
    }
    Object.defineProperty(CacheNotificationService.prototype, "modalType", {
        /**
         * Getter for type of currently displayed modal.
         */
        get: function () {
            return this.currentModalType;
        },
        /**
         * Setter for type of currently displayed modal.
         * @param component
         */
        set: function (component) {
            this.currentModalType = component;
        },
        enumerable: true,
        configurable: true
    });
    CacheNotificationService.prototype.close = function () {
        this.showModal$.next({
            context: {},
            component: BlankComponent
        });
    };
    CacheNotificationService.prototype.privacyPolicy = function () {
        var _this = this;
        var modalOptions = { component: PrivacyPolicyComponent, context: {} };
        if (!this.storageService.get('isShowPrivacyPolicyNotification')) {
            return this._createModal(modalOptions).then(function (isClose) {
                _this.storageService.set('isShowPrivacyPolicyNotification', "true");
                return isClose;
            });
        }
    };
    CacheNotificationService.prototype._createModal = function (command) {
        var component = command && command.component ? command.component : BlankComponent;
        this.showModal$.next({
            component: component,
            context: command.context
        });
        return new Promise(function (resolve) {
            command.context.handler = function (arg) { return resolve(arg) || false; };
        });
    };
    CacheNotificationService.prototype.getCommand = function () {
        return this.showModal$.asObservable();
    };
    return CacheNotificationService;
}());
export { CacheNotificationService };
