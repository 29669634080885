import {Component, OnInit}              from '@angular/core';
import { Subject }                      from 'rxjs/Subject';

import { CartService }                  from './cart.service';
import { PaymentReportItemInterface }        from './cart-report.interface';
import AppValues from '../common/app.values';
import {PaymentNotification} from '../../../swagger-gen__output_dir/model/paymentNotification';
import {Order} from '../../../swagger-gen__output_dir/model/order';
import {Address} from '../../../swagger-gen__output_dir';
import {OrderElement} from '../../../swagger-gen__output_dir/model/orderElement';

@Component({
    selector: 'cart-report',
    template: `
        <cart-report-header></cart-report-header>
        <h3 *ngIf="reports && reports.elements?.length === 0" class="empty-list">
            {{ "header.cart.payment.expired" | translate }}
        </h3>
        <div *ngIf="reports && reports.elements?.length" class="cart-report__container">
            <div class="cart-report__header">
                <div class="cart-report__header__img-container">
                    <img src="../../assets/images/great_icon.png" alt="success"/>
                </div>
                <div class="cart-report__header-title">
                    <h3>{{ "header.cart.report.youAre" | translate }} <span style="white-space: nowrap;">{{ "header.cart.report.shopping" | translate }}</span></h3>
                </div>
            </div>
            <div class="cart-report__logo sprite sprite-bizi_baza_logo_2x"></div>
            <div class="cart-report__text">
                <p>{{ "cart.report.text.thanks" | translate }}</p>
                <p>{{ "cart.report.text.contact" | translate }}</p>
                <p>{{ "cart.report.again" | translate }}</p>
                <p>{{ "cart.report.team" | translate }}</p>
            </div>
            <div class="cart-report__sale-info">
                <div>
                    <h4>{{ "cart.report.sale" | translate }}</h4>
                    <h5 data-test-id="report_order_date"><span class="nowrap">{{order_date}}</span>&nbsp;<span class="nowrap">{{order_time}}</span></h5>
                </div>
                <div>
                    <h4>{{ "cart.report.buyer" | translate }}</h4>
                    <h5 class="buyer-name">{{reports.buyer_name || 'None'}}</h5>
                </div>
            </div>
            <div class="cart-report__items">
                <h3 class="cart-report__block-title">{{ "cart.report.items" | translate }}</h3>

                <ul class="cart-report__item__table">
                    <li *ngFor="let element of reports.elements; trackBy: trackByID; let idx1 = index"
                        class="cart-report__item__table__outer-list">
                        <div class="cart-report__item__table__elements"
                             *ngFor="let product of element.elements; let idx2 = index">
                            <div class="cart-report__colomn-img">
                                <span 
                                    *ngIf="product.item_info.photoURL.length === 0" 
                                    [attr.data-test-id]="'noimg-item_'+idx1+'-'+idx2"
                                    class="sprite sprite-no_image_icon"></span>
                                <img 
                                    *ngIf="product.item_info.photoURL.length > 0" 
                                    [attr.data-test-id]="'img-item_'+idx1+'-'+idx2"
                                    [src]="product.item_info.photoURL" alt="Product image"/>
                            </div>
                            <div class="cart-report__colomn">
                                <h4>{{ "cart.report.item.name" | translate }}</h4>
                                <h5>{{product.item_info.title}}</h5>
                                <h4 style="padding-top: 15px;">{{template_company_name}}:</h4>
                                <h5>{{product.seller_info.title}}</h5>
                                <h4 style="padding-top: 15px;">{{ "cart.report.order.code" | translate }}</h4>
                                <h5>{{element.order_id || 'None'}}</h5>
                            </div>
                            <div class="cart-report__colomn-qnt text-right">
                                <h4>{{ "cart.report.total.quantity" | translate }}</h4>
                                <h5 [attr.data-test-id]="'product-quantity-' + product.ID">{{product.qty | number}} {{ product.uom }}</h5>
                            </div>
                            <div class="cart-report__colomn-amount text-right">
                                <h4>{{ "cart.report.total.amount" | translate }}</h4>
                                <h5 [attr.data-test-id]="'cart-report-subtotalElem_' + idx2">{{product.price * product.qty / HUNDRED | currency:'USD':true}}</h5>
                            </div>
                            <div class="cart-report__colomn-address text-right">
                                <h4>{{ "cart.report.delivery.method" | translate }}</h4>
                                <div *ngIf="isPickUp(product.shipping_address)" class="buyer_information_duty">
                                    <h4 class="qnt-title">{{ "cart.report.delivery" | translate }}</h4>
                                    <h5 [attr.data-test-id]="'contactSeller_'+idx2"
                                        class="qnt">{{product.shipping_terms}}</h5>
                                    <h4 class="total-title">{{ "cart.report.seller.phone" | translate }}</h4>
                                    <h5 [attr.data-test-id]="'seller_primary_phone_'+idx2"
                                        class="total">{{ formatPhoneNumber(product.seller_primary_phone) }}</h5>
                                </div>
                                <div *ngIf="!isPickUp(product.shipping_address)" class="buyer_information_duty">
                                    <h4 class="qnt-title">{{ "cart.report.pickup" | translate }}</h4>
                                    <h5 [attr.data-test-id]="'pick_up_address_'+idx2"
                                        class="qnt">{{ getPickUpAddressString(product) }}</h5>
                                    <h4 class="total-title">{{ "cart.report.seller.phone" | translate }}</h4>
                                    <h5 [attr.data-test-id]="'seller_primary_phone_'+idx2"
                                        class="total">{{ formatPhoneNumber(product.seller_primary_phone) }}</h5>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="reports.discount !== 0" class="cart-report__total">
                <h3 class="cart-report__block-title">{{ "cart.report.saved" | translate }}</h3>
                <h3 class="cart-report__block-title text-right">{{reports.discount / HUNDRED | currency:'USD'}}</h3>
            </div>
            <div class="cart-report__total">
                <h3 class="cart-report__block-title">{{ "cart.report.purchase.total" | translate }}</h3>
                <h3 class="cart-report__block-title text-right">{{reports.price / HUNDRED | currency:'USD'}}</h3>
            </div>
        </div>
    `
})

export class CartReportComponent implements OnInit {

    public HUNDRED                 = AppValues.HUNDRED;
    public totalCost:              number;
    public reports:                PaymentNotification;
    public template_company_name   = AppValues.company_name_header;
    public order_date:             string;
    public order_time:             string;

    private componentDestroyed$:    Subject<boolean> = new Subject();

    constructor(
        private cartService: CartService,
    ) {}


    ngOnInit() {
        this.cartService.getReport()
            .takeUntil(this.componentDestroyed$)
            .subscribe((report: PaymentNotification) => {
                this.reports = report;

                // TODO Mock
                // this.reports = get_payment_notification1;

                if ( Object.keys(this.reports).length ) {
                    let date = AppValues.convertDateStringToISOAndToLocalDate(this.reports.order_date);

                    this.order_date = AppValues.datePipeTransform(date, AppValues.fullDatePattern);
                    this.order_time = AppValues.datePipeTransform(date, AppValues.timePattern);

                    this.cartService.checkout_from_buy_now(this.reports);

                    this.calculateTotal();
                }
            });
    }

    calculateTotal() {
        this.totalCost = 0;

        this.reports.elements.forEach((elem: Order) => {
            this.totalCost += elem.price / this.HUNDRED;
        });
    }

    formatPhoneNumber(phone: string) {
        return AppValues.formatPhoneNumber(phone);
    }

    getPickUpAddressString(element: OrderElement): string {
        if (!AppValues.isEmpty(element.pick_up_address)) {
            return AppValues.getStringAddress(element.pick_up_address, ', ');
        } else {
            return 'None';
        }
    }

    public isPickUp(seller_address: Address): boolean {
        return !AppValues.isEmpty(seller_address);
    }

    /**
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {PaymentReportItemInterface} item
     * @return {string}
     */
    trackByID(index: number, item: PaymentReportItemInterface): string {
        return item.ID;
    }
}
