import { Injectable, Injector } from '@angular/core';
import { Router }               from '@angular/router';
import { Observable, Subject }  from 'rxjs';

import { DataService }          from '../services/data.service';
import {
    ChatInterface
}                               from '../interfaces/dialog.interface';
import { ErrorInterface, ErrorService } from '../services/error.service';
import { ModalService }         from '../modal/modal.service';
import { UserService }          from '../user/user.service';
import { UserSessionInterface } from '../interfaces/user.interface';
import {
    GetDialogsService,
    ReadMessagesService,
    ReadMessagesResponseBody,
    GetDialogsResponseBody,
    Message,
    FoundDialog, MessagingService, Dialog
} from '../../../swagger-gen__output_dir';
import { HttpErrorResponse } from '@angular/common/http';
import {CreateMessageService} from '../../../swagger-gen__output_dir/api/createMessage.service';
import {CreateMessageRequestBody} from '../../../swagger-gen__output_dir/model/createMessageRequestBody';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ChatService {
    /**
     * Serves interactions for Messaging
     */
    chat_title:                 string[];
    customChatTitle:            string;
    dialog:                     ChatInterface;
    dialogID:                   string;
    dialogs:                    FoundDialog[];

    private chatTimer:          number | any;
    private currentChat:        Dialog;
    private chatEvent$:         Subject<Dialog> = new Subject<Dialog>();
    private dialogEvent$        = new Subject<ChatInterface>();
    private dialogsEvent$       = new Subject<FoundDialog[]>();
    private dialogsTimer:       number | any;
    private userSession:        UserSessionInterface;
    private searchKeyword:      string = '';

    constructor(
        private dataService:            DataService,
        private errorService:           ErrorService,
        private modalService:           ModalService,
        private router:                 Router,
        private injector:               Injector,
        private readMsgService:         ReadMessagesService,
        private msgFromDialogService:   MessagingService,
        private getDialogsService:      GetDialogsService,
        private createMessageService:   CreateMessageService,
        private translate:              TranslateService,
    ) { }

    /**
     * Starts polling for new dialogs after user has been logged
     */
    init(): void {
        this.userSession = this.user_service.getUserSession();

        this.getGialogInformation(this.userSession);
    }

    getGialogInformation(userSesion?: UserSessionInterface) {
        if (!userSesion) userSesion = this.user_service.getUserSession();

        const user = this.user_service.getUser();

        if (user && user.accessLevel !== 0) {
            userSesion.token && this._startPolling();
        } else this.stopPolling();
    }

    /**
     * Method for revers sorted by date
     * @param { FoundDialog[] } items
     */
    public sortItems(items:  FoundDialog[]) {
        return items.sort((itemA: FoundDialog, itemB: FoundDialog) => {
            return itemB.last_message_date - itemA.last_message_date;
        });
    }

    /***
     * @desc This function creates 'user service' property on your service.
     * @return {UserService}
     */
    public get user_service(): UserService {
        return this.injector.get(UserService);
    }

    public set searchChatKeyword(keyword) {
        this.searchKeyword = keyword;
    }

    public get searchKeywordText(): string {
        return this.searchKeyword;
    }
    public getCurrentChat$(): Observable<Dialog> {
        return this.chatEvent$.asObservable();
    }
    public setCurrentChat(chat: Dialog): void {
        this.currentChat = chat;
        this.chatEvent$.next(chat);
    }
    public getCurrentChat(): Dialog {
        return this.currentChat;
    }

    /**
     * @desc Gets a list of dialogs for which the dialog title
     *       or text of any message containes setted keyword
     * @param {string} keyword
     * @returns {Observable<FoundDialog[] | []>}
     */
    public searchByKeyword(keyword: string): Observable<FoundDialog[]> {
       const token = this.user_service.getToken();

       this.searchChatKeyword = keyword;

       if (!token) {
          return Observable.of([]);
       }

       return this.getDialogsService.getDialogsGet(token, keyword)
          .map((res: GetDialogsResponseBody) => {
              this.dialogs = this.sortItems(res.dialogs);
              return this.dialogs;
          })
          .catch((err: HttpErrorResponse) => {
              return this.errorService.handleHttpError(err);
          });
    }

    // Deprecated method
    //
    // chatForAnyGuestUser() {
    //     this.user_service.userAuthEvent$
    //         .subscribe((user: UserSessionInterface) => {
    //             this.userSession = user;
    //
    //             user.token && this._retrieveDialogs();
    //             this.dialog = { messages: [], dialogID: '' };
    //         });
    // }

    /**
     * Fires dialog polling requests in 10-second interval
     * @private
     */
    _startPolling(): void {
        this._retrieveDialogs();
        this.dialogsTimer = setInterval(
            this.userSession.token
                ? this._retrieveDialogs.bind(this)
                : false,
            10000
        );
    }

    /**
     * Stops dialog polling
     */
    clearDialogPolling(): void {
        clearInterval(this.chatTimer);
    }

    /**
     * Fetches dialogs.
     * @emits dialogsEvent$
     * @private
     */
    _retrieveDialogs(): void {
        const token = this.user_service.getToken();

        if (!token) {
            this.dialogsTimer && clearInterval(this.dialogsTimer);
            return;
        } else this._getDialogs(true);
    }

    _getDialogs(obs?: boolean) {
        if (!this.searchKeywordText) {
            if (obs) {
                this.getDialogsRequest();
            } else {
                return new Observable(observer => {
                    this.getDialogsRequest(observer);
                });
            }
        }
    }

    public getDialogsRequest(observer?: any): void | Observable<FoundDialog[]> {
        const token = this.user_service.getToken();
        this.dataService.getData('get_dialogs', {token})
            .subscribe(
                (dialogs: GetDialogsResponseBody) => {
                    this.dialogs = this.sortItems(dialogs.dialogs);
                    this.dialogsEvent$.next(dialogs.dialogs);

                    if (observer) {
                        observer.next(this.dialogs);
                        observer.complete();
                    }
                },
                (err: ErrorInterface) => {
                    if (!err.statusText) this.stopPolling();
                    else if (err.status === 403) {
                        this.errorService.handleError(err);
                        this.stopPolling();
                    } else err.statusText && this.errorService.handleError(err);

                    observer && observer.error(err);
                });
    }

    stopPolling() {
        clearInterval(this.dialogsTimer);
        return;
    }

    /**
     * Fetches messages of a certain dialog.
     * @param id
     * @returns {Observable<ReadMessagesResponseBody} | {}>}
     * @private
     */
    retrieveChat(id: string): Observable<{ messages: Message[] } | {}> {
        const token = this.userSession.token;

        if (!token) {
            return Observable.of({});
        }

        return this.readMsgService.readMessagesPost({ dialog_id: id }, token)
            .map((res: ReadMessagesResponseBody) => {
                return this._sortMessages(res.messages, id);
            })
            .catch((err: HttpErrorResponse) => {
                return this.errorService.handleHttpError(err);
            });
    }

    /**
     * @desc Assigns @isSentByMe field to each message in a dialog, so that the messages
     * could be marked as "mine/theirs" further with CSS styles.
     * @param messages
     * @param dialogID
     * @returns {ChatInterface}
     * @private
     */
    _sortMessages(messages: Message[], dialogID: string): ChatInterface {
        messages.forEach((msg: Message) =>
            msg['isSentByMe'] = msg.sender_id === this.userSession.ID
        );

        this.dialog = {
            messages,
            dialogID,
        };

        return this.dialog;
    }

    /**
     * Returns dialogEvent$.
     * @returns {Observable<ChatInterface>}
     */
    getDialog(): Observable<ChatInterface> {
        return this.dialogEvent$.asObservable();
    }

    /**
     * Returns dialogsEvent$.
     * @returns {Observable<FoundDialog[]>}
     */
    getDialogs(): Observable<FoundDialog[]> {
        return this.dialogsEvent$.asObservable();
    }

    /**
     * Returns a certain dialog.
     * @returns {ChatInterface}
     */
    getCurrentDialog(): ChatInterface {
        return this.dialog;
    }

    /**
     * Returns dialogs.
     * @returns {FoundDialog[]}
     */
    getCurrentDialogs(): FoundDialog[] {
        return this.dialogs;
    }

    /**
     * Navigates to the Chat room page and fetches a certain dialog. Starts chat polling.
     * @param {FoundDialog} dialog
     */
    showDialog(dialog: FoundDialog | Dialog): void {
        this.dialogID = dialog.ID;
        this.setCurrentChat(dialog as Dialog);
        this.modalService.showSpinner();

        this.retrieveChat(dialog.ID)
            .subscribe(
               () => {
                   this.modalService.close();
                   this.router.navigate(['/chat-room'], {
                      queryParams: {
                         interlocutor: dialog.interlocutor_title
                      }
                   });
               },
               (err: ErrorInterface) => {
                  this.modalService.close();
                  this.errorService.handleError(err);
               }
            );

        this.chatTimer = setInterval(() => {
            this.getMessagesFromDialog(dialog.ID)
                .subscribe(
                    (chat: ChatInterface) => this.dialogEvent$.next(chat),
                    (err: ErrorInterface) => this.errorService.handleError(err)
                );
        }, 5000);
    }

    /**
     * show messages in one of dialogs
     * @param {string} id
     * @return {Observable<{messages: Message[]} | {}>}
     */
    getMessagesFromDialog(id: string): Observable<{ messages: Message[] } | {}> {
        const token = this.userSession.token;

        if (!token) {
            this.chatTimer && clearInterval(this.chatTimer);
            return Observable.of({});
        }

        return this.msgFromDialogService.messageFromDialogGet(id, token)
            .map((res: ReadMessagesResponseBody) => {
                return this._sortMessages(res.messages, id);
            })
            .catch((err: HttpErrorResponse) => {
                return this.errorService.handleHttpError(err);
            });
    }

    /**
     * Shows a dialog if exists, otherwise calls to create a new one.
     * @param interlocutorID
     * @param chatTitle
     */
    goToDialog(interlocutorID: string): void {
        if (!this.dialogs && !Object.keys(this.dialogs).length) {
            this._getDialogs(true).subscribe(() => {
                this._goToDialog(interlocutorID);
            });
        } else this._goToDialog(interlocutorID);
    }

    _goToDialog(interlocutorID: string): void {
        const chat = this.dialogs.find((dialog: FoundDialog) => {
            return dialog.subscribers.some((id: string) => id === interlocutorID);
        });

        chat ? this.showDialog(chat) : this._createDialog(interlocutorID);
    }

    /**
     * Posts to create a new dialog. Calls to show just created dialog.
     * @param interlocutorID
     * @param title
     * @returns {any}
     * @private
     */
    _createDialog(interlocutorID: string): void {
        const token = this.userSession.token;

        if (!token) return;

        // Note: Title is not needed anymore. For dialog title use interlocutor_title.
        const body = {
            subscribers: [ interlocutorID, this.userSession.ID ],
            title: this.user_service.getUser().title
        };

        this.modalService.showSpinner();

        this.dataService.postData('create_dialog', body, { token })
            .subscribe(
                (dialog: FoundDialog) => {
                    this.dialogs.push(dialog);
                    this.showDialog(dialog);
                },
                (err: ErrorInterface) => this.errorService.handleError(err)
            );
    }

    /**
     * Confirms user choice in removing a dialog.
     * @param dialog_id
     */
    removeDialog(dialog_id: string): void {
        this.modalService
            .warning({
                title:          this.translate.instant('chatroom.removeDialogTitle'),
                yesButtonText:  this.translate.instant('chatroom.confirmDialog'),
                noButtonText:   this.translate.instant('chatroom.rejectDialog'),
                reverseButtons: true,
            })
            .then((action: boolean) => action && this._removeDialog(dialog_id));
    }

    /**
     * Posts to remove a dialog.
     * @param dialog_id
     * @returns {any}
     * @private
     */
    _removeDialog(dialog_id: string): void {
        const token = this.userSession.token;

        if (!token) return;

        this.modalService.showSpinner();

        this.dataService.postData('delete_dialog', { dialog_id }, { token })
            .subscribe(
                () => {
                    this.modalService.close();

                    const index = this.dialogs.findIndex(
                       (d: FoundDialog) => d.ID === dialog_id
                    );

                    this.dialogs.splice(index, 1);

                    this.dialogsEvent$.next(this.dialogs);
                },
                (err: ErrorInterface) => this.errorService.handleError(err)
            );
    }

    /**
     * Posts a new message to the dialog.
     * @param message_text
     * @param dialogID
     * @returns {Observable<ChatInterface | {}>}
     */
    public sendMessage(message_text: string, dialogID: string): Observable<ChatInterface | {}> {
        const token = this.userSession.token;

        if (!token) return Observable.of({});

        const that = this;
        const body: CreateMessageRequestBody = {
            to_dialog: this.dialogID,
            message_text
        };

        this.modalService.showSpinner();

        return this.createMessageService.sendMessagePost(body, token)
            .map((message: Message) => { // the context here unexpectedly gets missed

                const isExistMsg = that.dialog.messages.some((msg: Message) => msg.ID === message.ID);
                if (!isExistMsg) {
                    that.dialog.messages.push(message);
                }

                that.modalService.close();

                return that._sortMessages(that.dialog.messages, that.dialog.dialogID);
            })
            .catch((err: ErrorInterface) => {
                this.errorService.handleError({
                    status: err.status,
                    statusText: `${this.translate.instant('chatroom.errorSendMessage')} \n ${err.statusText}`
                });
                return Observable.throwError(err);
            });
    }
}
