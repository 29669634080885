/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/img-loader/img-loader.component.ngfactory";
import * as i3 from "../shared/img-loader/img-loader.component";
import * as i4 from "../modal/modal.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../shared/media-editor/media-editor.component.ngfactory";
import * as i7 from "../shared/media-editor/media-editor.component";
import * as i8 from "@angular/common";
import * as i9 from "./profile-header.component.ngfactory";
import * as i10 from "./profile-header.component";
import * as i11 from "./profile.component";
import * as i12 from "./profile.service";
import * as i13 from "../payment/payment.service";
var styles_ProfileComponent = [i0.styles];
var RenderType_ProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileComponent, data: {} });
export { RenderType_ProfileComponent as RenderType_ProfileComponent };
function View_ProfileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "profile__subheader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["data-test-id", "openStripeDashboard"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenStripeDashboard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "section", [["class", "profile__stripe-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "stripe wordmark"], ["src", "../../assets/images/stripe_wordmark_slate_lg.png"]], null, null, null, null, null))], null, null); }
function View_ProfileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "addr"], ["data-test-id", "profileAddressEdit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddressEdit(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "icon-location"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "addr__text clip"], ["data-test-id", "profileAddress1"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "icon-disclosure_indicator_icon"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.address1; _ck(_v, 3, 0, currVal_0); }); }
function View_ProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "profile__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img-loader", [["role", "title"]], null, [[null, "changeEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changeEvent$" === en)) {
        var pd_0 = (_co.onDataChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ImgLoaderComponent_0, i2.RenderType_ImgLoaderComponent)), i1.ɵdid(2, 49152, null, 0, i3.ImgLoaderComponent, [i1.ElementRef, i4.ModalService, i5.TranslateService], { imageUrl: [0, "imageUrl"], text: [1, "text"], regExp: [2, "regExp"] }, { changeEvent$: "changeEvent$" }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "profile__subheader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contacts"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "media-editor", [["role", "webPage"]], [[1, "data-test-id", 0]], [[null, "changeEvent$"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changeEvent$" === en)) {
        var pd_0 = (_co.onDataChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (_co.eventHandler($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_MediaEditorComponent_0, i6.RenderType_MediaEditorComponent)), i1.ɵdid(7, 49152, null, 0, i7.MediaEditorComponent, [i1.ElementRef, i4.ModalService, i5.TranslateService], { imageUrl: [0, "imageUrl"], text: [1, "text"], placeholder: [2, "placeholder"], regExp: [3, "regExp"], minMaxLength: [4, "minMaxLength"], isEmpty: [5, "isEmpty"], errorMessage: [6, "errorMessage"] }, { changeEvent$: "changeEvent$" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "media-editor", [["role", "email"]], [[1, "data-test-id", 0]], [[null, "changeEvent$"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changeEvent$" === en)) {
        var pd_0 = (_co.onDataChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (_co.eventHandler($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_MediaEditorComponent_0, i6.RenderType_MediaEditorComponent)), i1.ɵdid(9, 49152, null, 0, i7.MediaEditorComponent, [i1.ElementRef, i4.ModalService, i5.TranslateService], { imageUrl: [0, "imageUrl"], text: [1, "text"], regExp: [2, "regExp"], minMaxLength: [3, "minMaxLength"], errorMessage: [4, "errorMessage"] }, { changeEvent$: "changeEvent$" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileComponent_2)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "profile__subheader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileComponent_3)), i1.ɵdid(18, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "profile__add"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddressEdit(null, (0 - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "h4", [["data-test-id", "newAddress"]], null, null, null, null, null)), (_l()(), i1.ɵted(21, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.profile.imageURL || "../../assets/images/cart/no_image_icon.png"); var currVal_1 = (_co.profile.title || "--your title--"); var currVal_2 = _co.titlePattern; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = "../../assets/images/svg_icons/globe.svg"; var currVal_5 = _co.profile.webPage; var currVal_6 = "URL"; var currVal_7 = _co.webPagePattern; var currVal_8 = _co.webPageMinMaxLength; var currVal_9 = true; var currVal_10 = "address.error.invalidWebPageLineFieldValues"; _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_12 = "../../assets/images/svg_icons/at-symbol-svgrepo-com.svg"; var currVal_13 = _co.profile.email; var currVal_14 = _co.emailPattern; var currVal_15 = _co.emailMinMaxLength; var currVal_16 = "profile.editor.email.warning.message"; _ck(_v, 9, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_17 = _co.isSellerMode; _ck(_v, 11, 0, currVal_17); var currVal_19 = _co.profile.addresses; _ck(_v, 18, 0, currVal_19); }, function (_ck, _v) { var currVal_3 = "webpage"; _ck(_v, 6, 0, currVal_3); var currVal_11 = "email"; _ck(_v, 8, 0, currVal_11); var currVal_18 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("profile.addresses")); _ck(_v, 14, 0, currVal_18); var currVal_20 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("profile.add.new.address")); _ck(_v, 21, 0, currVal_20); }); }
export function View_ProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-header", [], null, null, null, i9.View_ProfileHeaderComponent_0, i9.RenderType_ProfileHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i10.ProfileHeaderComponent, [i8.Location], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile", [], null, null, null, View_ProfileComponent_0, RenderType_ProfileComponent)), i1.ɵdid(1, 245760, null, 0, i11.ProfileComponent, [i4.ModalService, i12.ProfileService, i13.ClientPaymentService, i1.Injector, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileComponentNgFactory = i1.ɵccf("profile", i11.ProfileComponent, View_ProfileComponent_Host_0, {}, {}, []);
export { ProfileComponentNgFactory as ProfileComponentNgFactory };
