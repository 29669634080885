
        <div class="editor-menu" data-test-id="tag">
            <div class="editor-submenu">
                <div class="icon-container icon-25" (click)="onClick('close')">
                    <div class="sprite sprite-close_shadow_2x" data-test-id="closeButton"></div>
                </div>
                <div class="icon-container icon-25" data-test-id="uploadButton" (click)="onClick('upload')">
                    <div class="sprite sprite-camers_shadow_2x"></div>
                </div>
                <div class="icon-container icon-25" data-test-id="removeButton" (click)="onClick('remove')">
                    <div class="sprite sprite-trash_shadow_2x"></div>
                </div>
            </div>
            
            <div class="editor-submenu">
                <div class="icon-container icon-25" data-test-id="createButton" (click)="onClick('create')">
                    <div class="sprite sprite-tag_shadow_2x"></div>
                </div>
                <div class="icon-container icon-25" data-test-id="removeTagButton" (click)="onClick('removeTags')">
                    <div class="sprite sprite-pencil_shadow_2x"></div>
                </div>
                <div class="icon-container icon-25" data-test-id="saveButton" (click)="onClick('save')">
                    <div class="sprite sprite-check_shadow_2x"></div>
                </div>
            </div>
            
        </div>
    