/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./settings-header.component.ngfactory";
import * as i3 from "./settings-header.component";
import * as i4 from "@angular/common";
import * as i5 from "./faq.component";
var styles_FAQComponent = [i0.styles];
var RenderType_FAQComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FAQComponent, data: {} });
export { RenderType_FAQComponent as RenderType_FAQComponent };
export function View_FAQComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "component about-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "settings-header", [], null, null, null, i2.View_SettingsHeaderComponent_0, i2.RenderType_SettingsHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.SettingsHeaderComponent, [i4.Location], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "iframe", [["class", "faq__container"], ["src", "https://docs.google.com/document/d/e/2PACX-1vS5hpHX3IJ5w1LFf1pkV-QYjp7LqQ-NSwLpHWzeeNgESjWu2w1s_kAal_SVdf2mMOy3PaVagRtWXV9T/pub?embedded=true"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_FAQComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "faq", [], null, null, null, View_FAQComponent_0, RenderType_FAQComponent)), i1.ɵdid(1, 49152, null, 0, i5.FAQComponent, [], null, null)], null, null); }
var FAQComponentNgFactory = i1.ɵccf("faq", i5.FAQComponent, View_FAQComponent_Host_0, {}, {}, []);
export { FAQComponentNgFactory as FAQComponentNgFactory };
