/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-footer.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./category-selector.component.ngfactory";
import * as i4 from "./category-selector.component";
import * as i5 from "./app-footer.service";
import * as i6 from "./app-footer-menu.component.ngfactory";
import * as i7 from "./app-footer-menu.component";
import * as i8 from "./app-footer.component";
import * as i9 from "@angular/router";
import * as i10 from "../user/user.service";
var styles_AppFooterComponent = [i0.styles];
var RenderType_AppFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppFooterComponent, data: {} });
export { RenderType_AppFooterComponent as RenderType_AppFooterComponent };
export function View_AppFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "footer", [["class", "footer__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "footer__menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "nav", [["class", "footer__nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "footer__choice"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["data-path", "/inventory-list"], ["data-test-id", "sellerBtn"], ["role", "seller"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeStatus($event.currentTarget) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "icon-seller_tab_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "span", [["class", "footer__category-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["data-test-id", "showCategoriesBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showGoodsNav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(10, { "rotate": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "icon-new_item_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "span", [["class", "footer__choice"], ["data-test-id", "buyerBtn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["data-path", "/shopping-list"], ["role", "buyer"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeStatus($event.currentTarget) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "icon-buyer_tab_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["class", "footer__main-btn"], ["data-test-id", "footerMainBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMainMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(18, { "rotate": 0 }), (_l()(), i1.ɵeld(19, 0, null, null, 0, "img", [["alt", "home menu"], ["height", "21"], ["width", "21"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 4, "div", [["class", "footer__popup-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(22, { "visible": 0 }), (_l()(), i1.ɵeld(23, 0, null, null, 1, "category-selector", [], null, [[null, "showCategoryEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showCategoryEvent$" === en)) {
        var pd_0 = (_co.onModeChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CategorySelectorComponent_0, i3.RenderType_CategorySelectorComponent)), i1.ɵdid(24, 638976, null, 0, i4.CategorySelectorComponent, [i5.AppFooterService], { mode: [0, "mode"] }, { showCategoryEvent$: "showCategoryEvent$" }), (_l()(), i1.ɵeld(25, 0, null, null, 6, "div", [["class", "footer__popup-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(27, { "visible": 0 }), (_l()(), i1.ɵeld(28, 0, null, null, 3, "div", [["class", "footer__popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 2, "nav", [["class", "popup-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "app-footer-menu", [], null, [[null, "menuEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("menuEvent$" === en)) {
        var pd_0 = (_co.goTo($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_AppFooterMenuComponent_0, i6.RenderType_AppFooterMenuComponent)), i1.ɵdid(31, 49152, null, 0, i7.AppFooterMenuComponent, [], { isAdminAccessLevel: [0, "isAdminAccessLevel"], counts: [1, "counts"], mode: [2, "mode"], type: [3, "type"] }, { menuEvent$: "menuEvent$" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.getMode() == _co.userMode.sellerMode) ? "active" : ""); _ck(_v, 5, 0, currVal_0); var currVal_1 = _ck(_v, 10, 0, _co.isCategoryShown); _ck(_v, 9, 0, currVal_1); var currVal_2 = ((_co.getMode() == _co.userMode.buyerMode) ? "active" : ""); _ck(_v, 14, 0, currVal_2); var currVal_3 = "footer__main-btn"; var currVal_4 = _ck(_v, 18, 0, _co.isNavShown); _ck(_v, 17, 0, currVal_3, currVal_4); var currVal_6 = "footer__popup-container"; var currVal_7 = _ck(_v, 22, 0, _co.isNavShown); _ck(_v, 21, 0, currVal_6, currVal_7); var currVal_8 = _co.mode; _ck(_v, 24, 0, currVal_8); var currVal_9 = "footer__popup-container"; var currVal_10 = _ck(_v, 27, 0, _co.isCategoryShown); _ck(_v, 26, 0, currVal_9, currVal_10); var currVal_11 = _co.isAdminAccessLevel; var currVal_12 = _co.counts; var currVal_13 = _co.mode; var currVal_14 = _co.type; _ck(_v, 31, 0, currVal_11, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_5 = "../../assets/images/svg_icons/menu.svg"; _ck(_v, 19, 0, currVal_5); }); }
export function View_AppFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_AppFooterComponent_0, RenderType_AppFooterComponent)), i1.ɵdid(1, 4243456, null, 0, i8.AppFooterComponent, [i5.AppFooterService, i1.ElementRef, i9.Router, i1.Injector, i10.UserService], null, null)], null, null); }
var AppFooterComponentNgFactory = i1.ɵccf("app-footer", i8.AppFooterComponent, View_AppFooterComponent_Host_0, { type: "type" }, {}, []);
export { AppFooterComponentNgFactory as AppFooterComponentNgFactory };
