
        <div class="component">
            <search-header [search_filter]="searchListFilter" [userLocation]="userLocationForSearch"
                           [is_key_search]="is_key_search" [searchResult]="searchResult"
                           [isAdminMode]="isAdminMode()"
                           (changeEvent$)="onOptionsChange($event)"></search-header>

            <div class="search__container">
                <div #container></div>
            </div>
        </div>
    