/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./map-search-header.component";
var styles_MapSearchHeaderComponent = [i0.styles];
var RenderType_MapSearchHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapSearchHeaderComponent, data: {} });
export { RenderType_MapSearchHeaderComponent as RenderType_MapSearchHeaderComponent };
function View_MapSearchHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [["data-test-id", "mapSearchHeaderTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("map.search.map")); _ck(_v, 1, 0, currVal_0); }); }
function View_MapSearchHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [["data-test-id", "marketsMapSearchHeaderTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("map.search.markets.map")); _ck(_v, 1, 0, currVal_0); }); }
function View_MapSearchHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [["data-test-id", "keywordMapSearchHeaderTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("map.search.map.for")); var currVal_1 = _co.searchName; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_MapSearchHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [["data-test-id", "sellersMapSearchHeaderTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("map.search.sellers.map")); _ck(_v, 1, 0, currVal_0); }); }
export function View_MapSearchHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "map-header__block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "nav", [["class", "app-header__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "nav__link"], ["data-test-id", "backLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "navbar__center map-search-header-navbar_center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapSearchHeaderComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapSearchHeaderComponent_2)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapSearchHeaderComponent_3)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapSearchHeaderComponent_4)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "navbar__right"], ["role", "map"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["data-test-id", "searchLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "icon-location-address"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.searchName === "") && ((_co.type === "item") || (_co.type === "sale"))); _ck(_v, 8, 0, currVal_0); var currVal_1 = ((_co.searchName === "") && ((_co.type === "market") || (_co.type === "event"))); _ck(_v, 10, 0, currVal_1); var currVal_2 = (_co.searchName !== ""); _ck(_v, 12, 0, currVal_2); var currVal_3 = ((_co.searchName === "") && ((_co.type === "user") || (_co.type === "seller"))); _ck(_v, 14, 0, currVal_3); }, null); }
export function View_MapSearchHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "map-search-header", [], null, null, null, View_MapSearchHeaderComponent_0, RenderType_MapSearchHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i4.MapSearchHeaderComponent, [i3.Location, i2.TranslateService], null, null)], null, null); }
var MapSearchHeaderComponentNgFactory = i1.ɵccf("map-search-header", i4.MapSearchHeaderComponent, View_MapSearchHeaderComponent_Host_0, { type: "type", searchName: "searchName" }, { locateEvent$: "locateEvent$" }, []);
export { MapSearchHeaderComponentNgFactory as MapSearchHeaderComponentNgFactory };
