import { AgmCoreModule }                    from '@agm/core';
import { CommonModule }                     from '@angular/common';
import { NgModule, ModuleWithProviders }    from '@angular/core';
import { RouterModule }                     from '@angular/router';

import { MarketService }                    from './market.service';
import { MarketComponent }                  from './market.component';
import { MarketHeaderComponent }            from './market-header.component';
import { SharedModule }                     from '../shared/shared.module';
import { environment }                      from '../../environments/environment';


@NgModule({
    imports: [
        AgmCoreModule.forRoot({
            apiKey: environment.GM_API_KEY
        }),
        CommonModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        MarketComponent,
        MarketHeaderComponent
    ],
    exports: [
        MarketComponent,
    ]
})
export class MarketModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: MarketModule,
            providers: [MarketService]
        };
    }
}
