var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalService } from '../../modal/modal.service';
import { ProductEditorParentClass } from './product-editor-abstract.class';
import { ProductEditorService } from '../product-editor.service';
import { TranslateService } from '@ngx-translate/core';
var NewItemEditorComponent = /** @class */ (function (_super) {
    __extends(NewItemEditorComponent, _super);
    function NewItemEditorComponent(modalService, productEditorService, translate) {
        var _this = _super.call(this, modalService, productEditorService, translate) || this;
        _this.isNewItem = true;
        return _this;
    }
    NewItemEditorComponent.prototype.createTitleText = function () {
        this.title = this.translate.instant("editor.title.product");
    };
    /**
     * Calls the service to cancel editing.
     */
    NewItemEditorComponent.prototype.onClose = function () {
        this.productEditorService.cancelEditing();
    };
    /**
     * If the parent's self-called method returns true, calls the callback with `saveItem` command object.
     */
    NewItemEditorComponent.prototype.onSave = function () {
        if (!_super.prototype.onSave.call(this))
            return;
        this.item.current_quantity = this.item.qty;
        var obj = { item: this.item };
        this.context.callback({ saveItem: obj });
    };
    return NewItemEditorComponent;
}(ProductEditorParentClass));
export { NewItemEditorComponent };
