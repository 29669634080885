import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/Subject';

import {OrdersAppService} from './orders.service';
import {AppFooterService} from '../app-footer/app-footer.service';
import {DutyItemInterface, FullOrderInterface, FullTransactionInterface} from './analytics.interface';
import AppValues from '../common/app.values';
import {ErrorInterface} from '../services/error.service';
import {ModalService} from '../modal/modal.service';
import {Address, OrderElement} from '../../../swagger-gen__output_dir';
import {Order} from '../../../swagger-gen__output_dir/model/order';
import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {ScrollToConfigOptionsTarget} from "@nicky-lenaers/ngx-scroll-to/src/app/modules/scroll-to/scroll-to-config.interface";
import { getErrorType } from '../services/error.helpers';


@Component({
    selector: 'orders',
    styleUrls: ['analytics.sass'],
    template: `
        <div class="component analytics-component" id="pending-orders">
            <analytics-header [analyticsUrl]="'/pending-orders'" (goBackEvent$)="goBack()"></analytics-header>
            <div class="analytics__container" id="custom-container">
                <h3 *ngIf="!orders?.length" class="empty-list">Empty</h3>
                <ul class="duties-list" *ngIf="orders">
                    <li [attr.data-test-id]="'dutyItem_'+idx1" [id]="'dutyItem_'+idx1"
                        *ngFor="let item of orders; let idx1 = index;"
                        (click)="_toggleVisibility(item, 'dutyItem_'+idx1)">
                        <div class="analytics-item">
                            <div class="duties-title">
                                <h5 [attr.data-test-id]="'date_'+idx1">Date:
                                    {{item.order_datetime_str}}</h5>
                                <h5 [attr.data-test-id]="'idOrder_nameItem_'+idx1">
                                    Order: {{item.elements[0].item_info.title}}
                                </h5>
                                <h5 [attr.data-test-id]="'idOrder_buyerNameItem_'+idx1" class="analytics__name">
                                    {{template_buyer_name}}: {{item.buyer_info.title}}
                                </h5>
                            </div>
                            <div class="analytics-delivery">
                                <span *ngIf="!item.is_completed" [attr.data-test-id]="'deliveryAllItems_'+idx1"
                                  (click)="$event.stopPropagation(); deliveryAllItems(item);"
                                  class="icon-crr_icon"></span>
                                <span *ngIf="item.is_completed" [attr.data-test-id]="'deliveryAllItems_'+idx1"
                                          class="analytics-details__shipping analytics-details__delivered">Delivered</span>
                            </div>
                            <div class="analytics-item__dir">
                                <i [attr.data-test-id]="'rightDirItem_'+idx1" class="icon-right-dir"
                                   [ngClass]="{'active': item['_isVisible']}"></i>
                            </div>
                            <div class="total-discount__block">
                                <div class="analytics-title">
                                <h5 [attr.data-test-id]="'TotalItem_'+idx1">Total: {{item.order_total_price / HUNDRED | currency: 'USD' :true}}</h5>
                                </div>
                                <div class="analytics-title analytics-item__discount">
                                    <h5 *ngIf="item.discount && item.discount !== 0" [attr.data-test-id]="'DiscountItem_'+idx1">Discount: {{item.discount / HUNDRED | currency: 'USD' :true}}</h5>
                                 </div>
                            </div>
                        </div>

                        <div *ngIf="item['_isVisible']" class="analytics-item__details">
                            <div *ngFor="let product of item.elements; let idx2 = index;">
                                <div class="analytics-details__name">
                                    <div class="analytics__img-container">
                                    <span *ngIf="!product.item_info.photoURL"
                                          [attr.data-test-id]="'imgItem_'+idx1+'_'+idx2"
                                          class="sprite sprite-no_image_icon"></span>
                                        <img *ngIf="product.item_info.photoURL"
                                             [attr.data-test-id]="'imgItem_'+idx1+'_'+idx2"
                                             [src]="product.item_info.photoURL"/>
                                    </div>

                                    <div class="analytics__product-details">
                                        <h4 [attr.data-test-id]="'nameItem_'+idx1+'_'+idx2" class="title">
                                            {{product.item_info.title}}</h4>
                                        <h5 class="analytics__product-details__price-by-uom">
                                            <strong [attr.data-test-id]="'costItem_'+idx1+'_'+idx2">
                                                {{product.price / HUNDRED | currency: 'USD' :true}}/{{product.uom}}
                                            </strong>
                                        </h5>
                                        <section>
                                            <h5 class="buyer">{{template_buyer_name}}: </h5>
                                            <h5 [attr.data-test-id]="'buyerNameItem_'+idx1+'_'+idx2"
                                                class="analytics__name">{{item.buyer_info.title}}</h5>
                                        </section>
                                        <h5 class="analytics__name"
                                            [attr.data-test-id]="'orderCodeItem_'+idx1 + '_' + idx2">
                                            Order code: {{item.order_id}}
                                        </h5>
                                    </div>

                                    <div class="analytics-details__buttons-block">
                                        <span *ngIf="!product.is_completed" [attr.data-test-id]="'deliveryItem_'+idx1+'_'+idx2"
                                          (click)="$event.stopPropagation(); deliveryOneItem(item.order_id, [product.ID]);"
                                          class="icon-crr_icon">
                                        </span>
                                        <span *ngIf="product.is_completed" [attr.data-test-id]="'deliveryItem_'+idx1+'_'+idx2"
                                          class="analytics-details__shipping analytics-details__delivered">Delivered</span>
                                        <i [attr.data-test-id]="'contactButton_'+idx1+'_'+idx2" (click)="contact(item.buyer_id, $event)"
                                                 class="analytics-details__buttons-block__message icon-message_icon"></i>
                                    </div>
                                </div>
                                <div class="analytics-details__qnt">

                                    <div class="buyer_information_duty">
                                        <p class="qnt-title" [attr.data-test-id]="'itemFrom_'+idx1 + '_' + idx2">From:</p>
                                        <p [attr.data-test-id]="'itemAddressFrom_'+idx1+'_'+idx2" class="qnt">
                                            {{ product.provider_address_name }}</p>
                                    </div>

                                    <p class="qnt-title">Quantity:</p>
                                    <p [attr.data-test-id]="'qntItem_'+idx1+'_'+idx2" class="qnt">{{product.qty | number}}</p>
                                    <p class="total-title">Total cost:</p>
                                    <p [attr.data-test-id]="'totalCostItem_'+idx1+'_'+idx2" class="total">
                                        <strong>{{(product.price * product.qty) / HUNDRED | currency: 'USD' :true}}</strong>
                                    </p>

                                    <div *ngIf="isPickUp(product.shipping_address)" class="buyer_information_duty">
                                        <p class="qnt-title">Delivery:</p>
                                        <div class="qnt">
                                            <p [attr.data-test-id]="'contactBuyer_'+idx1+'_'+idx2" class="qnt">{{product.shipping_terms}}</p>
                                        </div>

                                        <p class="total-title">Buyer phone:</p>
                                        <p [attr.data-test-id]="'buyer_primary_phone_'+idx1+'_'+idx2" class="total">
                                            {{ item.buyer_primary_phone }}</p>
                                    </div>
                                    <div *ngIf="!isPickUp(product.shipping_address)" class="buyer_information_duty">
                                        <p class="qnt-title">Pick up:</p>
                                        <p [attr.data-test-id]="'buyer_address_'+idx1+'_'+idx2" class="qnt">
                                            {{ product.pick_up_address_str }}</p>
                                        <p class="total-title">Buyer phone:</p>
                                        <p [attr.data-test-id]="'buyer_primary_phone_'+idx1+'_'+idx2" class="total">
                                            {{ item.buyer_primary_phone }}</p>
                                    </div>
                                    <!--<p class="market-title">Market:</p>
                                    <p [attr.data-test-id]="'marketItem_'+idx1" class="uty-wrapper__market">{{item.marker}}</p>-->

                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    `
})

export class DutyComponent implements OnInit, OnDestroy {

    orders: FullOrderInterface[];
    HUNDRED = 100;
    THOUSAND = 1000;
    mode: string;
    template_buyer_name = AppValues.buyer_name_header;
    location:   Location;

    private componentDestroyed$: Subject<boolean> = new Subject();

    constructor(private ordersService: OrdersAppService,
                private appFooterService: AppFooterService,
                private scrollToService: ScrollToService,
                private modalService: ModalService,
                private translate: TranslateService,
                location:             Location) {
        this.location = location;
        this.mode = this.appFooterService.getMode();
    }


    ngOnInit() {
        this.ordersService.getDialogList();

        if (this.mode === 'seller') {

            this.ordersService.getDuty()
                .takeUntil(this.componentDestroyed$)
                .subscribe((list: Order[]) => {
                    this.orders = this.ordersService.sortItems(this.orderTemplates(list));

                }, (err: ErrorInterface) => this.modalService.error({
                    title: 'Error:',
                    message: getErrorType(err),
                    yesButtonText: 'Close',
                }));
        }
    }

    private orderTemplates(orders: Order[]): FullOrderInterface[] {
        return new AdapteeOrders(orders, this.ordersService).orders;
    }

    public isPickUp(address: Address): boolean {
        return this.ordersService.isPickUp(address);
    }

    /**
     * @desc toggle visibility item
     * (click than expand, click again than collapse)
     * @param {FullOrderInterface} duty
     * @param {string} id
     * @private
     */
    _toggleVisibility(duty: FullOrderInterface, id: string) {
        this.orders.forEach((sc: FullOrderInterface) => {
            if (sc !== duty) sc['_isVisible'] = false;
        });
        duty['_isVisible'] = !duty['_isVisible'];

        setTimeout(() => {
            if (duty['_isVisible']) {
                this.triggerScrollTo(id);
            }
        });
    }

    private triggerScrollTo(id: string): void {
        const config: ScrollToConfigOptionsTarget = {
            "container": 'custom-container',
            "target": id,
            "duration": 650,
            "easing": 'easeInCubic',
            "offset": 0
        };

        this.scrollToService.scrollTo(config);
    }


    delivery(order_id: string, order_elements_ids: string[]): void {
        this.modalService.showSpinner();

        // @ts-ignore
        this.ordersService.deliveryElements(order_id, order_elements_ids)
            .subscribe((orders: Order[]) => {
                this.modalService.close();
                this.orders = this.ordersService.sortItems(this.orderTemplates(orders));
            }, (err: ErrorInterface) => err);
    }

    deliveryAllItems(order: FullOrderInterface): void {
        this.modalService.warning({
            title: this.translate.instant('pendingOrders.markDelivered.title'),
            message: this.translate.instant('pendingOrders.order.markDelivered.message'),
            yesButtonText: this.translate.instant('pendingOrders.markDelivered.yes'),
            noButtonText: this.translate.instant('pendingOrders.markDelivered.no'),
            reverseButtons: true,
        }).then((action: boolean) => {
            if (action) {
                const order_elements_ids = order.elements.map((e: OrderElement) => e.ID);

                this.delivery(order.order_id, order_elements_ids);
            }
        });
    }

    deliveryOneItem(order_id: string, order_elements_ids: string[]): void {
        this.modalService.warning({
            title: this.translate.instant('pendingOrders.markDelivered.title'),
            message: this.translate.instant('pendingOrders.item.markDelivered.message'),
            yesButtonText: this.translate.instant('pendingOrders.markDelivered.yes'),
            noButtonText: this.translate.instant('pendingOrders.markDelivered.no'),
            reverseButtons: true,
        }).then((action: boolean) => {
            if (action) {
                this.delivery(order_id, order_elements_ids);
            }
        });
    }

    contact(buyer_id: string, event: Event) {
        event.stopPropagation();

        this.ordersService.openChat(buyer_id);
    }

    goBack(): void {
        this.location.back();
    }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {DutyItemInterface} item
     * @return {string}
     */
    trackByID(index: number, item: DutyItemInterface): string {
        return item.duty_id;
    }

    ngOnDestroy() {
        this.ordersService.stopGetDialogPolling();
    }

}

export class AdapteeOrders {
    orders: FullOrderInterface[];
    constructor(orders: Order[], ordersService: OrdersAppService) {
        let adapteeOrders = [];
        adapteeOrders = orders.map((o: Order) => {
            o['order_datetime_str'] = AppValues.datePipeTransform(o.order_datetime.toString(), AppValues.fullDateTimePattern);
            o['order_total_price'] = ordersService.orderTotalPrice(o, o.discount);
            // l.order_discount = ordersService.orderDiscount(list);

            o.elements.forEach((t_e: OrderElement) => {
                t_e['pick_up_address_str'] = ordersService.formatPickUpAddress(t_e.pick_up_address);
                t_e['provider_address_name'] = ordersService.formatPickUpAddress(t_e.pick_up_address);
            });

            return o;
        });

        this.orders = adapteeOrders;
    }
}
