// import { Injectable }   from '@angular/core';
import { environment }  from '../../environments/environment';


// @Injectable()
export class AppSettings {
    public static AWS_UPLOAD_URL    = 'https://bizibazapics.s3.amazonaws.com/';
    public static DOMAIN_URL        = '/';
    public static BASE_URL          = environment['apiUrl'];

    public static spinnerUrl        = 'images/loading.gif';

    public static SEARCH_COUNT      = 100;

    public static APP_VERSION() {
        return (environment.envName === 'prod') ? environment.version : environment.version + ' (' + environment.fullEnvName + ')';
    }
    public static APP_LANDING_PAGE() {
        return environment.landingUrl;
    }
}
