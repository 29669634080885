import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';

import { BizipixBoardComponent } from './bizipix-board.component';
import {
    BizipixItemsInterface,
    BizipixTagInterface,
} from '../interfaces/bizipix.interface';
import { BizipixEditorService } from './bizipix-editor.service';
import { ModalService } from '../modal/modal.service';
import { Tag } from './tag.component';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { ItemService } from '../services/item.service';
import AppValues from '../common/app.values';
import { SearchResultItems } from '../interfaces/search.interface';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { ErrorInterface } from '../services/error.service';
import { isValidFileSize } from '../product-editor/additional/user-platform.helpers';

@Component({
    selector: 'bizipix-editor',
    styleUrls: ['bizipix-editor.sass'],

    template: `
        <div class="bp__editor">
            <!-- <upload *ngIf="isBlank" [createNewBizipix]="createNewBizipix" (fileUploadEvent$)="onImageUpload($event)"></upload>-->

            <input
                #fileInput
                type="file"
                accept="imageTypes"
                data-test-id="uploadImageBtn"
                (change)="
                    onImageUpload({
                        payload: $event.target['files'][0],
                        currentInput: $event.currentTarget
                    })
                "
                style="display: none"
            />

            <item-picker
                *ngIf="isPickerMode"
                (itemSelectEvent$)="onItemSelect($event)"
            ></item-picker>

            <editor-menu
                (editorEvent$)="onEditorEvent($event)"
                [ngClass]="{ hidden: isPickerMode }"
            ></editor-menu>

            <bizipix-menu
                *ngIf="currentBizipix"
                class="bizipix-menu"
                [ngClass]="{ hidden: isPickerMode }"
                [type]="editable"
                [currentBizipix]="currentBizipix"
                [imageData]="bizipixesData"
                (selectedBizipixEvent$)="
                    addOrChangeBizipixStrategyEvent($event)
                "
                (createNewBizipixEvent$)="
                    addOrChangeBizipixStrategyEvent($event)
                "
            ></bizipix-menu>

            <bizipix-board
                *ngIf="!isReloading && currentBizipix"
                [currentBizipixBoard]="currentBizipix"
                [isFirstLoadingIsComplete]="isFirstLoadingIsComplete"
                [ngClass]="{ hidden: isPickerMode }"
                (boardEvent$)="onBoardEvent($event)"
                [uploadNewBizipix]="uploadNewBizipix"
                (changeBizipixInEditorEvent$)="
                    changeCurrentBizipixEvent($event)
                "
            ></bizipix-board>

            <div *ngIf="!currentBizipix" class="bp__container">
                <img
                    [src]="getInstructionImage()"
                    data-test-id="bizipixInstraction"
                    draggable="false"
                    alt="image placeholder"
                />
            </div>
        </div>
    `,
})

/**
 * Editor for BiziPix feature.
 * @desc Contains the board (to move\resize\delete tags), the editor (to choose operation) menu,
 * the uploading menu (to choose the background picture), the item picker (to choose an item to bind\rebind
 * a tag to).
 */
export class BizipixEditorComponent
    extends UnsubscribeOnDestroyAbsctractClass
    implements OnInit {
    public bizipixesData: BizipixItemsInterface[];
    public currentBizipix: BizipixItemsInterface;
    public isChangedBizipix: boolean = false;
    public editable: boolean = true;
    public editedTag: BizipixTagInterface | Tag;
    public isBlank: boolean;
    public isFirstLoadingIsComplete: boolean;
    public isPickerMode: boolean;
    public isToggleRemoveTag: boolean = false;
    public isReloading: boolean;
    public uploadNewBizipix: boolean;
    public createNewBizipix: boolean;
    public selectedBixipix: BizipixItemsInterface;
    public resizeObservable$: Observable<Event>;
    public imageTypes: string = AppValues.imageTypes;

    @ViewChild(BizipixBoardComponent) public board: BizipixBoardComponent;

    @ViewChild('fileInput') private fileInput: ElementRef;

    private realtime: number;
    private timeout: boolean = false;
    private delta: number = 200;

    public constructor(
        private bizipixService: BizipixEditorService,
        private modalService: ModalService,
        private itemService: ItemService,
        private translate: TranslateService,
    ) {
        super();
    }

    /**
     * @desc Fetches BiziPix image data object from service. Shows the upload menu if
     * no object has been fetched.
     * @return void
     */
    public ngOnInit(): void {
        this._setOfInitialValues();
        this.isFirstLoadingIsComplete = true;

        this.selectedBixipix = AppValues.deepCopy(this.currentBizipix);

        this._checkResizeWindow();
    }

    public getInstructionImage(): string {
        if (screen.availHeight > screen.availWidth) {
            return '../assets/images/svg_icons/mobile_bizipix_svg.svg';
        } else {
            return '../assets/images/svg_icons/desktop_bizipix_svg.svg';
        }
    }

    public resizeEnd(self: BizipixEditorComponent): void {
        const data: number = new Date().getTime();
        if (data - self.realtime < self.delta) {
            setTimeout(() => self.resizeEnd(self), self.delta);
        } else {
            self.timeout = false;
            self.updateSelectedBizipix(self.currentBizipix);
            self.isReloading = false;
        }
    }

    /**
     * Called from BixipixEditorComponent (Output) decorator's selectedBizipixEvent$
     * Apply pattern strategy for a definition of necessary action
     * @desc Called when the user selects BiziPix. Setting current BiziPix.
     * If this pix has no changes (modified coordinates of tags or new tags), then change the pix to the one selected by the user.
     * And if there are changes on the current pixel, we call the method changeBizipixInEditor() and ask the user for confirmation of the save.
     * @param {{currentBizipix: BizipixItemsInterface; isAddNewBizipix?: boolean}} event
     */
    public addOrChangeBizipixStrategyEvent(event?: {
        currentBizipix: BizipixItemsInterface;
        isAddNewBizipix?: boolean;
    }): void {
        this.board.isFirstLoadingIsComplete = true;

        if (!this.isChangedBizipix && event.currentBizipix) {
            this.updateSelectedBizipix(event.currentBizipix);
        }
        if (this.isChangedBizipix) {
            this._changeBizipixInEditor(event);
        }
        if (event.isAddNewBizipix) {
            if ( this.bizipixesData.length < this.bizipixService.availableAmount) {
               this.fileInput.nativeElement.click();
               this.createNewBizipix = true;
            } else this.bizipixService.moreThanFiveBiziPixMessage();
        }
    }

    /**
     * Private Method for remove tagComponent and update view for current Bizipix
     * @param {BizipixItemsInterface} currentBizipix
     * @private
     */
    public updateSelectedBizipix(currentBizipix: BizipixItemsInterface): void {
        this._updateSelectedBizipixWithoutReset(currentBizipix, true);
        this.isFirstLoadingIsComplete = true;

        this._resetChangedBizipixValue();
    }


    /**
     * Set isChangedBizipix
     * @desc Called from BizipixMenuComponent (Output) decorator's changeCurrentBizipixEvent$
     * @param {{isChangedBizipix: boolean}} event
     */
    public changeCurrentBizipixEvent(event: {
        changedBizipix: boolean;
        updatedSelectedBixipix: BizipixItemsInterface;
    }): void {
        this.isChangedBizipix = event.changedBizipix;
    }

    /**
     * Called from UploadComponent (Output) decorator's fileUploadEvent$
     * @desc Checks for payload, which assumes to be a picture blob. If no payload returned, closes the editor.
     * Delegates to the service to upload the picture. On response delegates the service to create a
     * new BiziPix image if no, otherwise updates the image URL.
     * @param {{payload: Blob | boolean; currentInput: EventTarget}} event
     * @return void
     */
    public onImageUpload(event: {
        payload: Blob | boolean;
        currentInput: EventTarget;
    }): void {
        if (!event.payload) {
            this.isBlank &&
                !this.bizipixesData &&
                this.bizipixService.exitEditor(true);
            this.isBlank = false;

            return;
        }

        this.isBlank = false;
        if (typeof event.payload !== 'boolean' && event.payload.size && !isValidFileSize(event.payload.size)) {
            this.modalService.error({
                title: this.translate.instant('modal.error.uploadImage.title'),
                message: this.translate.instant('modal.error.uploadImage.message'),
                yesButtonText: this.translate.instant('modal.error.uploadImage.confirm'),
            });
            return;
        };

        this.bizipixService
            .uploadImg(event.payload as Blob)
            .switchMap((imageURL: string) => {
                // this.isReloading = true;
                return Observable.of(imageURL);
            })
            .subscribe(
                (imageURL: string) => {
                    this.isReloading = true;

                    if (imageURL) {
                        if (
                            this.createNewBizipix ||
                            !this.bizipixesData ||
                            !Object.keys(this.bizipixesData).length
                        ) {
                            return this.bizipixService
                                .createBizipix(imageURL)
                                .subscribe(
                                    (newBizpix: BizipixItemsInterface) => {
                                        this._resetChangedBizipixValue();
                                        this.currentBizipix = newBizpix;
                                        this._praparationForImageUpload(
                                            newBizpix,
                                            this.createNewBizipix,
                                        );
                                    },
                                );
                        } else {
                            this._resetChangedBizipixValue();
                            this.currentBizipix.image_url = imageURL;

                            this._praparationForImageUpload(
                                this.currentBizipix,
                                this.createNewBizipix,
                            );
                        }
                    } else {
                        this.isReloading = false;
                    }
                },
                (err: ErrorInterface) => (this.isReloading = false),
            );

        this.resetInput(event.currentInput);
    }

    /**
     * @desc Called when the user chooses an item via the item picker. If the chosen item is already bind
     * to some of the tags, calls the service to show a warning. Then calls to update existingItem tag or
     * create a new one.
     * @param item
     * @return void
     */
    public onItemSelect(item: SellingItemResponseBody | undefined): void {
        this.isPickerMode = false;

        if (!item) {
            return;
        }

        if (
            // tslint:disable-next-line:no-bitwise
            ~this.currentBizipix.tags.findIndex(
                (tag: BizipixTagInterface) => item.ID === tag.item_id,
            )
        ) {
            this.bizipixService.showCreationWarning();

            return;
        }

        this.isReloading = true;

        this.isChangedBizipix = true;
        this.editedTag ? this._updateItem(item) : this._createTag(item);

        this.editedTag = null;
        // this._updateSelectedBizipix(this.currentBizipix);
    }

    // ---------- Editor events-----------------------------------
    /**
     * Dispatches to the editor commands (events on the editor menu operations) by calling
     * the appropriate method.
     * @param param
     */
    public onEditorEvent(param: string): void {
        this[param]();
    }

    /**
     * Delegates to close the editor.
     */
    public close(): void {
        let params: {} = { changed: this.isChangedBizipix };

        if (this.isChangedBizipix && typeof this.board !== 'undefined') {
            params = Object.assign(params, {
                bizipix: this.board.getCurrentBizipix(),
            });
        }
        this.bizipixService.closeEditor(params);
    }

    /**
     * Sets the @isBlank attribute, responsible for rendering the upload menu.
     */
    public upload(): void {
        this.fileInput.nativeElement.click();
        this._changeBizipix();
    }

    /**
     * Sets the @isPickerMode attribute, responsible for rendering the item picker.
     */
    public create(): void {
        this.bizipixService
            .getActiveInventoryItems()
            .subscribe((items: SearchResultItems[]) => {
                if (!this.bizipixesData.length) {
                    this.bizipixEditorWarning(
                        'bizipix.editor.alert.noBiziPixImages',
                    );
                    return;
                }
                if (!items.length) {
                    this.bizipixEditorWarning(
                        'bizipix.editor.alert.noProductsAdded',
                    );
                    return;
                } else {
                    this.isPickerMode = true;
                }
            });
    }

    /**
     * Delegates to remove BiziPix.
     */
    public remove(): void {
        if (this.isBoardExist()) {
            this.bizipixService.removeBizipix().then((action: boolean) => {
                if (action) {
                    this.bizipixService
                        .removePix(this.currentBizipix)
                        .subscribe(() => {
                            this._setOfInitialValues();
                            this.updateSelectedBizipix(this.currentBizipix);
                            this.board.updateView(this.currentBizipix, true);
                        });
                }
            });
        } else {
            this.bizipixEditorWarning('bizipix.editor.alert.noBiziPixImages');
        }
    }

    /**
     * Calls #toggleRemoveTagMode of the board.
     */
    public removeTags(): void {
        if (this.isBoardExist()) {
            this.board.toggleRemoveTagMode(!this.isToggleRemoveTag);
        }
    }

    /**
     * Calls #save of the board.
     */
    public save(): void {
        this.isBoardExist() && this.board.save();
    }

    /**
     * Calls #preSave of the board.
     */
    public preSave(): void {
        this.isBoardExist() && this.board.preSave();
    }

    /**
     * Shows the picker and appoint the tag to be rebind.
     * @param tagData
     */
    public rebindItem(tagData: BizipixTagInterface): void {
        const newtag: BizipixTagInterface = this.adapter(tagData, 'TagContext');
        this.isPickerMode = true;
        this.editedTag = newtag;
    }


    // ----------- Board events ---------------------------
    /**
     * Dispatches the board commands (events) by calling the appropriate method.
     * @param event
     */
    // tslint:disable-next-line:no-any
    public onBoardEvent(event: any): void {
        this[Object.keys(event)[0]](Object.values(event)[0]);
    }

    /**
     * Delegates to the service to remove a tag. Switches off 'remove tag' mode
     * for all the TagComponent.
     * @param tagID
     */
    public removeTag(tagID: string): void {
        this.bizipixService.removeTag().then((action: boolean) => {
            if (action === true) {
                this.board.deleteTag(tagID);
                this.board.toggleRemoveTagMode(true);
                this.isChangedBizipix = true;
            }
        });
    }

    /**
     * Delegates from BiziPixBoard Component
     */
    public savePix(): void {
        this._saving();
    }

    /**
     * Delegates from BiziPixBoard Component
     */
    public preSavePix(): void {
        const presave: boolean = true;
        this._saving(presave);
    }

    private bizipixEditorWarning(term: string): void {
        this.modalService.warning({
            title: this.translate.instant('bizipix.editor.alert.title'),
            message: this.translate.instant(term),
            yesButtonText: this.translate.instant('bizipix.editor.alert.close'),
        });
    }

    private _checkResizeWindow(): void {
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.trackSubscription(
            this.resizeObservable$.subscribe((event: Event) => {
                this.isReloading = true;

                this.realtime = new Date().getTime();
                if (this.timeout === false) {
                    this.timeout = true;
                    setTimeout(() => this.resizeEnd(this), this.delta);
                }
            }),
        );
    }

    private _setOfInitialValues(): void {
        this.bizipixesData = this.bizipixService.getImageData();

        if (!this.bizipixesData || !Object.keys(this.bizipixesData).length) {
            this.isBlank = true;
        } else {
            this.currentBizipix = this.bizipixesData[0];
        }
    }

    /**
     * Actions after answer by changeBizipixInEditor modal
     * @param {{currentBizipix: BizipixItemsInterface; isAddNewBizipix?: boolean}} event
     * @private
     */
    private _changeBizipixInEditor(event?: {
        currentBizipix: BizipixItemsInterface;
        isAddNewBizipix?: boolean;
    }): void {
        this.bizipixService.changeBizipixInEditor().then((action: boolean) => {
            if (action === true) {
                this.selectedBixipix = AppValues.deepCopy(this.currentBizipix);
                if (event.currentBizipix && !event.isAddNewBizipix) {
                    this.board.save();

                    this.changeSelectedBixipix(event.currentBizipix);

                    this.setInitialBizipixesData();

                    this.updateSelectedBizipix(event.currentBizipix);
                }
                if (event.isAddNewBizipix) {
                    this.board.preSave();
                    this.createNewBizipix = true;
                }
            } else {
                if (
                    this.selectedBixipix &&
                    !AppValues.deepEqual(
                        this.selectedBixipix,
                        this.currentBizipix,
                    )
                ) {
                    // this._updateSelectedBizipix(this.selectedBixipix);

                    if (this.isBoardExist()) {
                        this.board.removeComponent();
                    }
                    this.bizipixWasChangedAndNotSaved(event.currentBizipix);
                    this.updateSelectedBizipix(event.currentBizipix);
                } else {
                    this.changeSelectedBixipix(event.currentBizipix);

                    this.setInitialBizipixesData();

                    this.updateSelectedBizipix(event.currentBizipix);
                }
            }
        });
    }

    private bizipixWasChangedAndNotSaved(
        currentBizipix: BizipixItemsInterface,
    ): void {
        this.board.resetView();

        const imageData: BizipixItemsInterface[] = this.changeImageDataSelectedBixipix();

        this.changeSelectedBixipix(currentBizipix);
        this.setInitialBizipixesData(this.selectedBixipix);

        this.isFirstLoadingIsComplete = false;
        this.isChangedBizipix = false;
        this.bizipixService.setImageData(imageData);
    }

    private changeImageDataSelectedBixipix(): BizipixItemsInterface[] {
        const imageData: BizipixItemsInterface[] = this.bizipixService.imageData;
        imageData.map((bp: BizipixItemsInterface) => {
            if (this.selectedBixipix && bp.ID === this.selectedBixipix.ID) {
                bp.tags = AppValues.deepCopy(this.selectedBixipix.tags);
            }
        });
        return imageData;
    }

    private changeSelectedBixipix(currentBizipix: BizipixItemsInterface): void {
        if (currentBizipix && this.currentBizipix.ID !== currentBizipix.ID) {
            this.selectedBixipix = AppValues.deepCopy(currentBizipix);
        }
    }

    /**
     * Set initial Bizipixed Data and current Bizipix
     * for reset values to initial state.
     */
    private setInitialBizipixesData(
        selectedBixiPix?: BizipixItemsInterface,
    ): void {
        const clonedBizipix: BizipixItemsInterface[] = AppValues.deepCopy(
            this.bizipixService.getClonedBizipix,
        );

        if (selectedBixiPix) {
            this.currentBizipix = AppValues.deepCopy(selectedBixiPix);
        }
        clonedBizipix.forEach((bizipix: BizipixItemsInterface) => {
            if (this.currentBizipix.ID === bizipix.ID) {
                this.currentBizipix = AppValues.deepCopy(bizipix);
            }
        });
        this.bizipixesData = [];
        this.bizipixesData = clonedBizipix;
    }

    private _resetChangedBizipixValue(): void {
        this.isChangedBizipix = false;
    }

    private _updateSelectedBizipixWithoutReset(
        currentBizipix: BizipixItemsInterface,
        isSetSelectedBizipix: boolean,
    ): void {
        if (this.isBoardExist()) {
            this.board.removeComponent();
        }
        this._setOfInitialValues();
        this.currentBizipix = currentBizipix;

        setTimeout(() => {
            if (isSetSelectedBizipix) {
                this.selectedBixipix = AppValues.deepCopy(currentBizipix);
            } else {
                this.board.updateView(this.currentBizipix);
            }
        });
    }

    /**
     * @desc the method set flags needed for changing status a bizipix
     * @private
     */
    private _changeBizipix(): void {
        this.isBlank = false;
        this.createNewBizipix = false;
    }

    private resetInput(event: EventTarget): void {
        event['value'] = '';

        if (!/safari/i.test(navigator.userAgent)) {
            event['type'] = '';
            event['type'] = 'file';
        }
    }

    private _praparationForImageUpload(
        newBizpix: BizipixItemsInterface,
        createNewBizipixEvent: boolean,
    ): void {
        this.isPickerMode = false;
        this._setStrategyForChangingBizipix(newBizpix, createNewBizipixEvent);
        setTimeout(() => {
            this.isReloading = false;
        });
    }

    /**
     * The method sets the flags and depending on this the necessary strategy (updating or creating) is selected
     * @param {BizipixItemsInterface} imageData
     * @param {boolean} createNewBizipix
     * @private
     */
    private _setStrategyForChangingBizipix(
        imageData: BizipixItemsInterface,
        createNewBizipix?: boolean,
    ): void {
        if (
            createNewBizipix === true ||
            !this.bizipixesData ||
            !Object.keys(this.bizipixesData).length
        ) {
            this.bizipixesData.push(imageData);
            this.isChangedBizipix = false;
            this.uploadNewBizipix = false;
        } else {
            this.isChangedBizipix = true;
            this.uploadNewBizipix = true;
        }
    }

    /**
     * Creates a new tag object.
     * @param item
     * @return void
     * @private
     */
    private _createTag(item: SellingItemResponseBody): void {
        this.currentBizipix.tags.push({
            item_title: item.title,
            item_price_units: this.itemService.priceUnitsNameFn(item),
            item_id: item.ID,
            item_price: this.itemService.itemPriceFn(item),
            ID: '',
            tag_position: {
                bottom_right_x: 0.65,
                bottom_right_y: 0.65,
                top_left_x: 0.35,
                top_left_y: 0.35,
            },
        } as BizipixTagInterface);

        this.prepareToUpdateBizipixView();
    }

    /**
     * Updates the tag object under edition.
     * @param item
     * @return void
     * @private
     */
    private _updateItem(item: SellingItemResponseBody): void {
        this.currentBizipix.tags.forEach((tag: BizipixTagInterface) => {
            if (this.editedTag.item_id === tag.item_id) {
                this.editedTag.item_id = item.ID;
                this.editedTag.item_title = item.title;
                this.editedTag.item_price = this.itemService.itemPriceFn(item);
                this.editedTag.item_price_units =
                    this.itemService.priceUnitsNameFn(item);

                tag = Object.assign(tag, this.editedTag);
            }
        });

        this.prepareToUpdateBizipixView();
    }

    private prepareToUpdateBizipixView(): void {
        this.isChangedBizipix = true;

        setTimeout(() => {
            this._updateSelectedBizipixWithoutReset(this.currentBizipix, false);
            this.isReloading = false;
        });
    }

    private adapter(
        tagData: BizipixTagInterface,
        type: string,
    ): BizipixTagInterface {
        if (tagData['callback'] || type === 'TagContext') {
            const newtag: BizipixTagInterface = new Tag(
                tagData.ID,
                tagData.item_id,
                tagData.item_title,
                tagData.item_price,
                tagData.item_price_units,
                tagData.tag_position,
            );
            return newtag;
        }
    }

    /**
     * @desc Delegates to the service to save the pix.
     * @param {boolean} presave
     * @private
     */
    private _saving(presave?: boolean): void {
        presave
            ? this.bizipixService.savePix(
                  this.board.getCurrentBizipix(),
                  presave,
              )
            : this.bizipixService.savePix(this.board.getCurrentBizipix());

        this._resetChangedBizipixValue();
        this.uploadNewBizipix = false;
    }

    private isBoardExist(): boolean {
        return typeof this.board !== 'undefined';
    }
// tslint:disable-next-line:max-file-line-count
}
