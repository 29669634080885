import { ActivatedRoute } from '@angular/router';
import { ElementRef, OnInit, OnDestroy, Injector } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { ChatService } from './chat.service';
import { Dialog, Message } from '../../../swagger-gen__output_dir';
import { ErrorService } from '../services/error.service';
import { RedirectService } from '../services/redirect.service';
import { ModalService } from '../modal/modal.service';
import AppValues from '../common/app.values';
import { UserService } from '../user/user.service';
import { TranslateService } from "@ngx-translate/core";
var ChatRoomComponent = /** @class */ (function () {
    function ChatRoomComponent(chatService, datePipe, elementRef, route, redirectService, errorService, modalService, injector, translate) {
        this.chatService = chatService;
        this.datePipe = datePipe;
        this.elementRef = elementRef;
        this.route = route;
        this.redirectService = redirectService;
        this.errorService = errorService;
        this.modalService = modalService;
        this.injector = injector;
        this.translate = translate;
        this.isSending = false;
        this.isValid = true;
        this.messageGroups = [];
        this.formatFullDate = AppValues.fullDatePattern;
        this.minMessageCharacters = AppValues.MIN_MESSAGE_CHARACTERS;
        this.maxMessageCharacters = AppValues.MAX_MESSAGE_CHARACTERS;
        this.componentDestroyed$ = new Subject();
        this.THOUSAND = 1000;
    }
    /**
     * @desc Calls to group messages, to scroll the page down. Gets the
     * current dialog from the service.
     */
    ChatRoomComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._groupMessages(this.chatService.getCurrentDialog());
        this._scrollDown();
        this.userInput = '';
        this.interlocutor = this.chatService.getCurrentChat().interlocutor_anonymized
            ? this.translate.instant('anonymized.user.name')
            : this.route.snapshot.queryParams['interlocutor'];
        this.chatService.getDialog()
            .takeUntil(this.componentDestroyed$)
            .subscribe(function (dialog) {
            _this._groupMessages(dialog);
            _this._scrollDown();
        });
        this.currentChat = this.chatService.getCurrentChat();
    };
    /**
     * List tracker. Watches list items by date.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {{messages: Message[]; date: string}} item
     * @return {string}
     */
    ChatRoomComponent.prototype.trackByDate = function (index, item) {
        return item.date;
    };
    /**
     * Send message on pressing CTR keyL+Enter
     * @param event KeyboardEvent
     */
    ChatRoomComponent.prototype.clickHandler = function (event) {
        if (this.isValid && event.keyCode === 13 && event.ctrlKey) {
            this.userInput = event.target['value'];
            this.sendMessage();
        }
    };
    ChatRoomComponent.prototype.changeEventHandler = function ($event) {
        this.userInput = $event[1];
    };
    /**
     * Delegates sending a new message.
     */
    ChatRoomComponent.prototype.sendMessage = function () {
        setTimeout(this.prepareAndSendMessage.bind(this));
    };
    ChatRoomComponent.prototype.checkIsValid = function (isValid) {
        this.isValid = isValid;
    };
    /**
     * @decs Sends a new message via the Service. Calls to group the messages and
     * to scroll the page down on response.
     * @private
     */
    ChatRoomComponent.prototype.prepareAndSendMessage = function () {
        var _this = this;
        if (!this.userInput)
            return;
        var dialog = this.addNewMessage(this.userInput);
        setTimeout(function () { return _this.updateDialog(dialog); });
        this.chatService.sendMessage(this.userInput.trim(), this.dialog.dialogID)
            .subscribe(function (chat) { return null; }, function (err) {
            _this.updateDialog(_this.removeNotSendingMessage(_this.dialog));
        });
    };
    ChatRoomComponent.prototype.updateDialog = function (dialog) {
        this.userInput = '';
        this._groupMessages(dialog);
        this.dialog = this.chatService._sortMessages(dialog.messages, dialog.dialogID);
        this._scrollDown();
    };
    ChatRoomComponent.prototype.removeNotSendingMessage = function (dialog) {
        dialog.messages.filter(function (message) { return message.ID === ''; });
        return dialog;
    };
    ChatRoomComponent.prototype.addNewMessage = function (message_text) {
        var dialog = this.dialog;
        var newMessage = {
            ID: "",
            date: Date.parse(new Date().toString()) / AppValues.THOUSAND,
            dialog_id: this.dialog.dialogID,
            read: true,
            sender_id: this.user_service.getUser().ID,
            sender_name: this.user_service.getUser().login,
            text: message_text.trim()
        };
        dialog.messages.push(newMessage);
        return dialog;
    };
    /**
     * Creates groups of messages by date.
     * @param dialog
     * @private
     */
    ChatRoomComponent.prototype._groupMessages = function (dialog) {
        var _this = this;
        this.messageGroups = [];
        this.dialog = dialog;
        var messages = this.dialog.messages;
        var currentDate;
        messages.forEach(function (msg) {
            _this._formatTime(msg);
            if (msg['dateYMMMD'] !== currentDate) {
                currentDate = msg['dateYMMMD'];
                var group = {
                    date: msg['dateYMMMD'],
                    messages: []
                };
                group.messages.push(msg);
                _this.messageGroups.push(group);
            }
            else {
                var index = _this.messageGroups.length - 1;
                _this.messageGroups[index].messages.push(msg);
            }
        });
    };
    /**
     * Scrolls the page down in order to let the user see the latest message,
     * which appears at the bottom.
     * @private
     */
    ChatRoomComponent.prototype._scrollDown = function () {
        var _this = this;
        setTimeout(function () {
            var elem = _this.elementRef.nativeElement.querySelector('.chat-list');
            elem.scrollTop = elem.scrollHeight;
        });
    };
    /**
     * Transforms date fields from unix to human format with the date pipe.
     * @param msg
     * @private
     */
    ChatRoomComponent.prototype._formatTime = function (msg) {
        var date = msg.date * this.THOUSAND;
        msg['dateYMMMD'] = this.datePipe.transform(date, AppValues.fullDatePattern);
        msg['dateHHmm'] = this.datePipe.transform(date, AppValues.timePattern);
    };
    ChatRoomComponent.prototype.goBack = function () {
        var _this = this;
        this.modalService.showSpinner();
        this.chatService.retrieveChat(this.dialog.dialogID)
            .subscribe(function () {
            _this.modalService.close();
            _this.redirectService.goBack();
        }, function (err) {
            _this.modalService.close();
            _this.errorService.handleError(err);
        });
    };
    Object.defineProperty(ChatRoomComponent.prototype, "user_service", {
        /***
         * @desc This function creates 'user service' property on your service.
         * @return {UserService}
         */
        get: function () {
            return this.injector.get(UserService);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Calls the service to stop the dialog polling when the page gets closed.
     */
    ChatRoomComponent.prototype.ngOnDestroy = function () {
        this.chatService.clearDialogPolling();
    };
    return ChatRoomComponent;
}());
export { ChatRoomComponent };
