import {
    Component, EventEmitter,
    Input, Output,
} from '@angular/core';


import { BizipixItemsInterface } from '../../interfaces/bizipix.interface';




@Component({
    selector:   'bizipix-menu',
    template:   `
            <div class="bizipix-menu__container" *ngIf="imageData && currentBizipix">
                <div class="bizipix-menu__container__addnewbizipix" *ngIf="type">
                    <button class="bizipix-menu__addnewbizipix icon-25" data-test-id="addBizipix" (click)="addNewBizipix($event)"></button>
                </div>


                <div class="bizipix-menu__image-container" *ngFor="let bizipix of imageData; trackBy: trackByID; let i = index;">
                    <img [attr.data-test-id]="'bizipix_' + i" [src]="bizipix.image_url" [class]="(currentBizipix.ID == bizipix.ID) ? 'current-bizipix' : ''"
                         draggable="false" (click)="selectBizipix($event, bizipix)" [alt]="'BiziPix ' + i">
                </div>
            </div>
    `
})


export class BizipixMenuComponent  {
    @Input()    currentBizipix:     BizipixItemsInterface;
    @Input()    imageData:          BizipixItemsInterface;
    @Input()    type:               boolean;

    @Output()   selectedBizipixEvent$  = new EventEmitter<any>();
    @Output()   createNewBizipixEvent$ = new EventEmitter<any>();

    selectBizipix(event: Event, bizipix: BizipixItemsInterface): void {
        if (this.currentBizipix.ID !== bizipix.ID) {
            this.selectedBizipixEvent$.emit({currentBizipix: bizipix});
        }
    }

    addNewBizipix(event: Event): void {
        this.createNewBizipixEvent$.emit({isAddNewBizipix: true});
    }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {BizipixItemsInterface} item
     * @return {string}
     */
    trackByID(index: number, item: BizipixItemsInterface): string {
        return item.ID;
    }

}
