/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./editor-menu.component";
var styles_EditorMenuComponent = ["[_nghost-%COMP%] { position: absolute; top: 0; left: 0; width: 100%; z-index: 1000;}"];
var RenderType_EditorMenuComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_EditorMenuComponent, data: {} });
export { RenderType_EditorMenuComponent as RenderType_EditorMenuComponent };
export function View_EditorMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "editor-menu"], ["data-test-id", "tag"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "editor-submenu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "icon-container icon-25"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick("close") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "sprite sprite-close_shadow_2x"], ["data-test-id", "closeButton"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "icon-container icon-25"], ["data-test-id", "uploadButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick("upload") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "sprite sprite-camers_shadow_2x"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "icon-container icon-25"], ["data-test-id", "removeButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick("remove") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "sprite sprite-trash_shadow_2x"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 6, "div", [["class", "editor-submenu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "icon-container icon-25"], ["data-test-id", "createButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick("create") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [["class", "sprite sprite-tag_shadow_2x"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "div", [["class", "icon-container icon-25"], ["data-test-id", "removeTagButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick("removeTags") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "div", [["class", "sprite sprite-pencil_shadow_2x"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "div", [["class", "icon-container icon-25"], ["data-test-id", "saveButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick("save") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "div", [["class", "sprite sprite-check_shadow_2x"]], null, null, null, null, null))], null, null); }
export function View_EditorMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "editor-menu", [], null, null, null, View_EditorMenuComponent_0, RenderType_EditorMenuComponent)), i0.ɵdid(1, 49152, null, 0, i1.EditorMenuComponent, [], null, null)], null, null); }
var EditorMenuComponentNgFactory = i0.ɵccf("editor-menu", i1.EditorMenuComponent, View_EditorMenuComponent_Host_0, {}, { editorEvent$: "editorEvent$" }, []);
export { EditorMenuComponentNgFactory as EditorMenuComponentNgFactory };
