
        <header class="app-header wf-list-header">
            <nav class="app-header__container" role="fav">
                
                <div class="navbar__left">
                    <a (click)="goBack()" data-test-id="shoppingLink" 
                       class="nav__link"><i class="icon-custom_back_icon"></i></a>
                </div>

                <div class="navbar__center" role="fav">
                    <h4>{{ "favorite.list.favorites" | translate }}</h4>
                </div>

                <div class="navbar__right" role="fav"></div>
            </nav>
        </header>
    