import {
    Component, ElementRef,
    Input, Output, EventEmitter
}                                   from '@angular/core';

import { ModalService }             from '../../modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { isValidFileSize } from './user-platform.helpers';
import AppValues from '../../common/app.values';



@Component({
    selector:   'certificate',
    styleUrls:  ['../product-editor.sass', '../../../assets/styles/sprite.sass'],

    template:   `
        <div class="product-editor__switch-field" role="certificate" [attr.data-test-id]="dataTestId">
            <h4>
                <ng-content></ng-content>
            </h4>

            <div *ngIf="isStatus" data-test-id="showCertificate"
                 (click)="showCertificate(imageUrl)">

                <div class="sprite sprite-certificate_icon_2x"></div>
            </div>

            <input name="photo" (change)="onImgUpload($event.currentTarget)" type="file" accept=".jpg, .jpeg, .png, .tif, .gif, .bmp,  .heic, .heif" data-test-id="fileInp">
            <rect-switch data-test-id="switch"
                         [disabled]="disabled"
                         [state]="isStatus"
                         (stateEvent$)="changeStatus($event)"></rect-switch>
        </div>
    `
})
export class CertificateComponent {
    /**
     * Displays / uploads a certificate image and status.
     * @desc If isStatus == true, shows switch state ON and displays the certificate image on icon click.
     * When the switch is turned OFF
     * @emits changeEvent$ of an empty object.
     */
    @Input()    dataTestId:     string;
    @Input()    disabled:       any;
    @Input()    isStatus:       boolean;
    @Input()    imageUrl:       string;
    @Output()   changeEvent$    = new EventEmitter<any>();

    fileInput:      HTMLInputElement;
    public imageTypes: string = AppValues.imageTypes;

    constructor(
        private elementRef:     ElementRef,
        private modalService:   ModalService,
        private translate:      TranslateService
    ) {}



    /**
     * Simulates click on the file input, to show browser file select dialog.
     */
    showUpload(): void {
        this.changeSwitchValue(false);
        this.fileInput = this.elementRef.nativeElement.querySelector('input[name="photo"]');
        this.fileInput.click();
    }


    /**
     * When switched to ON displays uploading, otherwise
     * @emits changeEvent$
     * @param status
     */
    changeStatus(status: boolean): void {
        status ? this.showUpload() : this.changeEvent$.emit({});
    }



    /**
     * Delegates displaying image to ModalService
     * @param url
     */
    showCertificate(url: string): void {
        this.modalService.showImage(url);
    }



    /**
     * Handles image upload.
     * @emits changeEvent$ on proper image file.
     */
    onImgUpload(event: EventTarget) {
        if (this.fileInput.files.length) {
            let file = this.fileInput.files[0];
            // let fileSize = file.size / 1024 / 1024; // in MB

            if (!isValidFileSize(file.size)) {
                this.modalService.error({
                    title: this.translate.instant('modal.error.uploadImage.title'),
                    message: this.translate.instant('modal.error.uploadImage.message'),
                    yesButtonText: this.translate.instant('modal.error.uploadImage.confirm'),
                });
                return false;
            }
            this.changeSwitchValue(true);

            this.changeEvent$.emit({imageFile: file});

            setTimeout(() => {
                this.resetInput(event);
            });
        }
    }

    resetInput(event: EventTarget) {
        event['value'] = '';

        if (!/safari/i.test(navigator.userAgent)) {
            event['type'] = '';
            event['type'] = 'file';
        }
    }

    changeSwitchValue(checked: boolean) {
        let element: HTMLElement = document.querySelectorAll('[data-test-id="' + this.dataTestId + '"] [data-test-id="detailSwitchAlert"]')[0] as HTMLElement;
        element['checked'] = checked;
    }

}
