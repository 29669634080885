var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import { Subject } from 'rxjs/Subject';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { ImageUploadService } from '../services/image-upload.service';
import { InventoryListService } from '../inventory-list/inventory-list.service';
import { ModalService } from '../modal/modal.service';
import { ProductEditorAbstractClass } from './product-editor.abstract.class';
import { ProductEditorType } from '../interfaces/product.interface';
import { AppSearchService } from '../search/search.service';
import { UserService } from '../user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { SearchType } from '../interfaces/search.interface';
var ProductEditorService = /** @class */ (function (_super) {
    __extends(ProductEditorService, _super);
    function ProductEditorService(dataService, errorService, imageUploadService, inventoryListService, modalService, router, searchService, userService, translate) {
        var _this = _super.call(this, dataService, errorService, imageUploadService, modalService, router, userService) || this;
        _this.dataService = dataService;
        _this.errorService = errorService;
        _this.imageUploadService = imageUploadService;
        _this.inventoryListService = inventoryListService;
        _this.modalService = modalService;
        _this.router = router;
        _this.searchService = searchService;
        _this.userService = userService;
        _this.translate = translate;
        _this.primevalItem = new Subject();
        // tslint:disable-next-line:member-ordering
        _this.primevalItem$ = _this.primevalItem.asObservable();
        _this.itemWasChanged = false;
        _this.typeActions = {
            save: function () {
                return Observable.of({ type: 'save' });
            },
            draft: function () {
                return Observable.of({ type: 'draft' });
            },
            cancel: function () {
                return Observable.of({ type: 'cancel' });
            },
        };
        return _this;
    }
    ProductEditorService.prototype._onCreateItemSuccess = function () {
        this.type = ProductEditorType.newItem;
        this._runItemSubject();
        this.router.navigate(['/product-editor']);
    };
    /**
     * Assigns user info to the item object.
     * @param item
     * @private
     */
    ProductEditorService.prototype._createItem = function (item) {
        this.type = ProductEditorType.newItem;
    };
    /**
     * @returns {SellingItemResponseBody}
     */
    ProductEditorService.prototype.getItemAndNewEvent = function () {
        return {
            item: this.item,
            newEvent: this.newEvent,
        };
    };
    /**
     * @desc Confirms user choice. Calls item removal for newly created and navigates to
     * the Inventory list on leave.
     */
    ProductEditorService.prototype.cancelEditing = function (param) {
        var _this = this;
        if (this.itemWasChanged) {
            if (param) {
                this.itemWasChanged = false;
                return Observable
                    .fromPromise(this.modalService
                    .warning(this.checkItemTypeForModal())).switchMap(function (action) {
                    if (action === 'last') {
                        return _this.typeActions['save']();
                    }
                    if (action && !param.event) {
                        _this.checkItemType();
                    }
                    if (!action) {
                        return _this.typeActions['draft']();
                    }
                    else {
                        return _this.typeActions['cancel']();
                    }
                });
            }
            else {
                this.modalService.warning(this.checkItemTypeForModal())
                    .then(function (action) {
                    action && _this.checkItemType();
                });
            }
        }
        else {
            if (!param) {
                this.checkItemType();
            }
            else if (param && param.event) {
                return this.typeActions['cancel']();
            }
            else {
                this.checkItemType();
                return this.typeActions['cancel']();
            }
        }
    };
    ProductEditorService.prototype._runInventoryList = function () {
        this.inventoryListService._retrieveInventoryList();
        this.router.navigate(['/inventory-list']);
    };
    ProductEditorService.prototype.checkObjectChanges = function (object1, object2) {
        this.change_item = !this.deepEqual(object1, object2);
    };
    Object.defineProperty(ProductEditorService.prototype, "change_item", {
        get: function () {
            return this.itemWasChanged;
        },
        set: function (changed) {
            this.itemWasChanged = changed;
        },
        enumerable: true,
        configurable: true
    });
    ProductEditorService.prototype.deepEqual = function (a, b) {
        if (a === b) {
            return true;
        }
        if (a === null || typeof (a) !== 'object' ||
            b === null || typeof (b) !== 'object') {
            return false;
        }
        var propertiesInA = 0;
        var propertiesInB = 0;
        for (var property in a) {
            propertiesInA += 1;
        }
        for (var property in b) {
            propertiesInB += 1;
            if (!(property in a) || !this.deepEqual(a[property], b[property])) {
                return false;
            }
        }
        return propertiesInA === propertiesInB;
    };
    /**
     * Calls on cancel item editing
     * @desc 1. If item's type is 'newItem' (template),
     *       removes this item from seller inventory list.
     *       2. Otherwise fetch seller's inventory list.
     * @private
     */
    ProductEditorService.prototype.checkItemType = function () {
        this.type === ProductEditorType.newItem
            ? this._deleteItem()
            : this._runInventoryList();
    };
    ProductEditorService.prototype.checkItemTypeForModal = function () {
        var modalArguments = {
            title: this.translate.instant('product.editor.alert.cancel.title'),
            message: this.translate.instant('product.editor.alert.cancel.message'),
            yesButtonText: this.translate.instant('product.editor.alert.cancel.action.discard'),
            noButtonText: this.translate.instant('product.editor.alert.cancel.action.no'),
        };
        if (this.type !== ProductEditorType.newItem) {
            Object.assign(modalArguments, { lastButtonText: this.translate.instant('product.editor.alert.cancel.action.save') });
        }
        return modalArguments;
    };
    /**
     * Posts to remove an item template form the database.
     * @private
     */
    ProductEditorService.prototype._deleteItem = function () {
        var _this = this;
        var token = this.userService.getUserSession().token;
        if (!this.item && !token) {
            return;
        }
        this.dataService.postData('delete_item', { item_id: this.item.ID }, { token: token })
            .subscribe(function () {
            _this.item = null;
            _this._runInventoryList();
        }, function (err) {
            _this._runInventoryList();
            _this.errorService.handleError(err);
        });
    };
    /**
     * Posts the item data.
     * @param {SaveProduct} obj
     * @returns {any}
     */
    ProductEditorService.prototype.save = function (obj) {
        var _this = this;
        this.updateItem(obj.item)
            .switchMap(function (res) {
            _this.newEvent = null;
            _this.item = res;
            _this.set_changed_item(res);
            return _this.inventoryListService.fetchList(_this.userService.getUserSession().ID);
        })
            .subscribe(function () { return _this._onSuccess(obj); }, function (err) {
            _this.modalService.error({
                title: _this.translate.instant('addItemToScPost.error.modal.title'),
                message: err,
                yesButtonText: _this.translate.instant('addItemToScPost.error.modal.button'),
            });
            return err;
        });
    };
    ProductEditorService.prototype.set_is_creating_item = function (type) {
        return _super.prototype.set_is_creating_item.call(this, type);
    };
    /**
     * Calls the DataService with `update item` request.
     * @param item
     * @returns {any}
     */
    ProductEditorService.prototype.updateItem = function (item) {
        this.newEvent = null;
        return _super.prototype.updateItem.call(this, item);
    };
    /**
     * Displays success messages on creation or update.
     * @private
     */
    ProductEditorService.prototype._onSuccess = function (obj) {
        var msg = this.type === ProductEditorType.newItem
            ? this.translate.instant('product.editor.item.success.create')
            : this.translate.instant('product.editor.item.success.update');
        this.showSuccessMessage({ msg: msg, obj: obj });
    };
    /**
     * Displays a success message.
     * @param msg
     */
    ProductEditorService.prototype.showSuccessMessage = function (prop) {
        var _this = this;
        this.modalService.success({
            title: 'Success:',
            message: prop.msg,
            yesButtonText: 'Close',
        }).then(function () {
            if (prop.obj && typeof prop.obj.event !== 'undefined') {
                prop.obj.callback();
                return;
            }
            if (!prop.remove) {
                _this._runInventoryList();
            }
        });
    };
    /**
     * Navigates to the Product editor page to edit the item.
     * @param item
     */
    ProductEditorService.prototype.showProductDetails = function (item) {
        this.item = item;
        this.type = ProductEditorType.existingItem;
        this._runItemSubject();
        this.router.navigate(['/product-editor']);
    };
    ProductEditorService.prototype._runItemSubject = function () {
        var _this = this;
        if (this.getType() === ProductEditorType.existingItem || ProductEditorType.newItem) {
            this.primevalItem$.subscribe(function (item) {
                _this.checkObjectChanges(item, _this.item);
            });
        }
    };
    ProductEditorService.prototype.set_changed_item = function (item) {
        this.primevalItem.next(item);
    };
    /**
     * Cancels event editing, returns to the item page.
     */
    ProductEditorService.prototype.cancelEventCreation = function () {
        this.newEvent = null;
        this.showProductDetails(this.item);
    };
    /**
     * Returns type, describing weather it is an event or an item, new or existing.
     * @returns {ProductEditorType}
     */
    ProductEditorService.prototype.getType = function () {
        return this.type;
    };
    /**
     * Redefines the item's events.
     * @param events
     */
    // updateItemEvents(events: EventInterface[]): void {
    //     this.item.events = events;
    // }
    /**
     * Calls the Search service to look for markets.
     * @todo the service signature will be changed when it is refactored.
     * @param item
     */
    ProductEditorService.prototype.searchForMarkets = function (item) {
        this.searchService.clearResults(SearchType.Event);
        this.searchService.is_key_search = true;
        this.router.navigate(['/search'], { queryParams: { key: 'event' } });
    };
    /**
     * Cooks a new item object for a certain Market and calls the editor.
     * @param market
     */
    ProductEditorService.prototype.createEvent = function (market) {
        if (this._isMarketDuplication(market.ID)) {
            return;
        }
        var item = Object.assign({}, this.item, {
            events: [],
            current_quantity: this._suggestCurrentQty(this.item.qty),
            loc: {
                coordinates: [
                    market.market_longitude,
                    market.market_latitude,
                ],
                type: 'Point',
            },
            market: market,
            valitFrom: this.item.valitFrom,
            validTill: this.item.validTill,
        });
        delete item.market_id;
        this.newEvent = {
            title: market.market_title,
            market_id: market.ID,
            item: item,
            item_id: '',
        };
        this.type = ProductEditorType.newEvent;
        this.router.navigate(['/product-editor']);
    };
    /**
     * Returns true if the item is already represented on the selected market.
     * @param id
     * @returns {boolean}
     * @private
     */
    ProductEditorService.prototype._isMarketDuplication = function (id) {
        if (~this.item.events.findIndex(function (event) { return event.market_id === id; })) {
            this.showWarningMessage('This item has already been presented on this market!');
            return true;
        }
        return false;
    };
    ProductEditorService.prototype._suggestCurrentQty = function (qty) {
        var engagedQty = this.item.events.reduce(function (sum, evt) {
            return sum + evt['item'].current_quantity;
        }, 0);
        return !engagedQty ? Math.floor(+qty / 2) : Math.floor((+this.item.qty - +engagedQty) / 2);
    };
    return ProductEditorService;
}(ProductEditorAbstractClass));
export { ProductEditorService };
