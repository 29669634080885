/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./settings-header.component";
var styles_SettingsHeaderComponent = [i0.styles];
var RenderType_SettingsHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsHeaderComponent, data: {} });
export { RenderType_SettingsHeaderComponent as RenderType_SettingsHeaderComponent };
function View_SettingsHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "icon-home"]], null, null, null, null, null))], null, null); }
function View_SettingsHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["role", "arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null))], null, null); }
function View_SettingsHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [["data-test-id", "settingsTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("settings.header.settings")); _ck(_v, 1, 0, currVal_0); }); }
function View_SettingsHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [["data-test-id", "aboutTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("settings.header.about")); _ck(_v, 1, 0, currVal_0); }); }
function View_SettingsHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [["data-test-id", "faqTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("settings.header.faq")); _ck(_v, 1, 0, currVal_0); }); }
export function View_SettingsHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "header", [["class", "app-header settings__app-header"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "about-header": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 14, "nav", [["class", "app-header__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["class", "nav__home-link"], ["data-test-id", "backLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.location.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsHeaderComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsHeaderComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "navbar__center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsHeaderComponent_3)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsHeaderComponent_4)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsHeaderComponent_5)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 0, "div", [["class", "navbar__right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "app-header settings__app-header"; var currVal_1 = _ck(_v, 2, 0, (_co.locationSettings === "/about")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.locationSettings === "/settings"); _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_co.locationSettings === "/about") || (_co.locationSettings === "/faq")); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.locationSettings === "/settings"); _ck(_v, 12, 0, currVal_4); var currVal_5 = (_co.locationSettings === "/about"); _ck(_v, 14, 0, currVal_5); var currVal_6 = (_co.locationSettings === "/faq"); _ck(_v, 16, 0, currVal_6); }, null); }
export function View_SettingsHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "settings-header", [], null, null, null, View_SettingsHeaderComponent_0, RenderType_SettingsHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.SettingsHeaderComponent, [i3.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsHeaderComponentNgFactory = i1.ɵccf("settings-header", i4.SettingsHeaderComponent, View_SettingsHeaderComponent_Host_0, {}, {}, []);
export { SettingsHeaderComponentNgFactory as SettingsHeaderComponentNgFactory };
