
        <div
            class="dialog-list__item"
            [attr.data-test-id]="'dialog-container_'+id"
            (click)="showDialog()"
        >
            <div class="box box-left">
                <div
                   class="dialog-name"
                   [attr.data-test-id]="'dialog-name_'+id"
                >
                    {{ dialog.interlocutor_title }}
                </div>
                <div
                   class="last-message"
                   [attr.data-test-id]="'last-message_'+id"
                >
                   {{ dialog.last_message }}
                </div>
            </div>

            <div class="box box-right">
                <h4
                   class="time-last-message"
                   [attr.data-test-id]="'time-last-message_'+id"
                >
                    {{ dialog.last_message_date * THOUSAND | date:format }}
                </h4>

                <div class="relative unread_messages_count">
                    <div *ngIf="dialog.unread_messages_count" class="badge" [attr.aria-labelledby]="'There are ' + dialog.unread_messages_count + ' unread messages'">
                        <span [attr.data-test-id]="'dialog_unread_message_count_'+id" class="relative">{{dialog.unread_messages_count}}</span>
                    </div>
                </div>

                <span class="remove-button"
                      [attr.data-test-id]="'removeItem_'+id"
                      (click)="removeDialog($event)">

                <i class="icon-trash-empty"></i>
            </span>
            </div>
        </div>
    