/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./goods-nav.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./goods-nav-header.component.ngfactory";
import * as i3 from "./goods-nav-header.component";
import * as i4 from "@angular/common";
import * as i5 from "./goods-nav.component";
import * as i6 from "./goods-nav.service";
import * as i7 from "../shopping-list/shopping-list.service";
var styles_GoodsNavComponent = [i0.styles];
var RenderType_GoodsNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GoodsNavComponent, data: {} });
export { RenderType_GoodsNavComponent as RenderType_GoodsNavComponent };
function View_GoodsNavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "goods-nav-header", [], null, null, null, i2.View_GoodsNavHeaderComponent_0, i2.RenderType_GoodsNavHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.GoodsNavHeaderComponent, [], { categoryName: [0, "categoryName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categoryName; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GoodsNavComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "entry__title"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleStatus(_v.context.$implicit, _co.subcats, $event, _v.parent.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "ok": 0, "busy": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_1 = "entry__title"; var currVal_2 = _ck(_v, 2, 0, _v.context.$implicit["_isInShoppingList"], _v.context.$implicit["_isBusy"]); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = ("entry_" + _v.context.index); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.subcategory_entry_name; _ck(_v, 4, 0, currVal_3); }); }
function View_GoodsNavComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "entry__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GoodsNavComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.sub_category_entries; var currVal_1 = _co.trackByID; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_GoodsNavComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["class", "subcat__title"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleVisibility(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "ok": 0, "busy": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "i", [["class", "icon-right-dir"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "active": 0 }), (_l()(), i1.ɵted(7, null, [" ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GoodsNavComponent_3)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = "subcat__title"; var currVal_2 = _ck(_v, 2, 0, _v.context.$implicit["_isInShoppingList"], _v.context.$implicit["_isBusy"]); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = "icon-right-dir"; var currVal_4 = _ck(_v, 6, 0, _v.context.$implicit._isVisible); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit._isVisible; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = ("subcategory_" + _v.context.index); _ck(_v, 0, 0, currVal_0); var currVal_5 = _v.context.$implicit.sub_category_name; _ck(_v, 7, 0, currVal_5); }); }
export function View_GoodsNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "component"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GoodsNavComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "component__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [["class", "subcat__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GoodsNavComponent_2)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categoryName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.subcats; var currVal_2 = _co.trackByID; _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_GoodsNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "goods-nav", [], null, null, null, View_GoodsNavComponent_0, RenderType_GoodsNavComponent)), i1.ɵdid(1, 114688, null, 0, i5.GoodsNavComponent, [i6.GoodsNavService, i7.ShoppingListService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoodsNavComponentNgFactory = i1.ɵccf("goods-nav", i5.GoodsNavComponent, View_GoodsNavComponent_Host_0, {}, {}, []);
export { GoodsNavComponentNgFactory as GoodsNavComponentNgFactory };
