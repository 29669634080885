
        <div class="layout">
            <div
                class="card"
                (click)="context.callback(context.item_id)"
                data-test-id="bizipixCard"
            >
                <header data-test-id="bizipixCardHeader">
                    {{ 'bizipix.viewer.title.buy' | translate }}
                </header>

                <main>
                    <div
                        class="price"
                        data-test-id="productPricePerUnit"
                        [ngClass]="{ 'text-small': isLongPriceText }"
                    >
                        <b
                            ><span
                                class="price__value"
                                data-test-id="productPrice"
                                >{{
                                    context.item_price | currency : 'USD'
                                }}</span
                            >/{{ context.item_price_units }}</b
                        >
                    </div>

                    <div class="title" data-test-id="productTitle">
                        {{ context.item_title }}
                    </div>
                </main>

                <footer></footer>
                <div class="e-box">
                    <span class="sprite sprite-e_2x"></span>
                </div>
            </div>
        </div>
    