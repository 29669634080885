import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ElementRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

import { ShoppingListService } from '../shopping-list.service';
import { SubcategoryInterface } from '../../interfaces/category.interface';
import { UnsubscribeOnDestroyAbsctractClass } from '../../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';

@Component({
  selector: 'app-custom-item-suggestions',
  templateUrl: './custom-item-suggestions.component.html',
  styleUrls: ['../shopping-list.sass']
})
export class CustomItemSuggestionsComponent extends UnsubscribeOnDestroyAbsctractClass
    implements OnChanges, OnInit {

    @Input() public isEditing: boolean;

    @Output() private controlStateChange$: EventEmitter<boolean>
       = new EventEmitter<boolean>();
    @Output() private selectSuggestion$: EventEmitter<SubcategoryInterface>
       = new EventEmitter<SubcategoryInterface>();
    @Output() private useEnteredValue$: EventEmitter<string>
      = new EventEmitter<string>();
    @Output() private emitEnteredValue$: EventEmitter<string>
         = new EventEmitter<string>();
    @Output() private resetControlState$: EventEmitter<null>
       = new EventEmitter<null>();

    public itemTitleControl: FormControl;
    public isDelayPassed = false;
    public isControlActivated = false;
    public isDataLoading = false;
    public suggestions: SubcategoryInterface[] = [];

    public constructor(
       private service: ShoppingListService,
       private elementRef: ElementRef
    ) {
      super();
    }

    public ngOnChanges(changes: SimpleChanges): void {
       if (!changes.isEditing.previousValue
            && changes.isEditing.currentValue) {
            setTimeout(() => {
                this.setFocusOnControl();
            });
       }
    }

    public ngOnInit(): void {
       this.initControl();
    }

    /**
     * Needed so that the Shopping-header component has time to hide itself.
     */
    public setDelay(): void {
       !this.isDelayPassed
          && setTimeout(() => this.isDelayPassed = true, 500);
       this.controlStateChange$.emit(true);
    }

    /**
     * @desc Calls when item from suggestions list was clicked.
     * @param {SubcategoryInterface} suggestion
     */
    public onSelectSuggestion(suggestion: SubcategoryInterface): void {
       this.selectSuggestion$.emit(suggestion);
       this.onFinish();
    }

    /**
     * @desc Calls when 'document:click' or 'keyup.enter' event was emitted.
     * @param {any} event
     */
    public onAddCustomItem(event?: any): void {
       const value = this.onFinish();
       value && this.useEnteredValue$.emit(value);

       event && this.eventHandler(event);
    }

    /**
     * Calls when 'blur' event was emitted.
     * @desc In case the suggestions list is empty and
     *       input value isn't empty - adds cutom item.
     * @param {any} event
     */
    public onBlur(event): void {
       !this.suggestions.length && this.itemTitleControl.value.trim()
          && this.onAddCustomItem(event);
    }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {SubcategoryInterface} item
     * @return {string}
     */
    public trackByID(index: number, item: SubcategoryInterface): string {
        return item.ID;
    }

    /**
     * @desc Calls when OnChanges hook was emitted and
     *       if isEditing value was changed from false to true.
     * @private
     */
    private setFocusOnControl(): void {
        this.itemTitleControl.setValue(this.service.getCachedItem());
        const elem = this.elementRef.nativeElement.querySelector('.input__text');
        elem && elem.focus();
    }

    /**
     * @desc Calls blur event manually on input when 
     *       if isEditing value was changed to false.
     * @private
     */
    private setBlurOnControl(): void {
        const elem: HTMLInputElement = this.elementRef.nativeElement.querySelector('.input__text');
        elem && elem.blur();
    }

    /**
     * @desc Initialises itemTitleControl.
     *       Subscribes on its value changes.
     * @private
     */
    private initControl(): void {
       this.service.getAllSuggestionsForShoppingList();

       this.itemTitleControl = new FormControl('');

       this.trackSubscription(
         this.itemTitleControl.valueChanges
          .pipe(distinctUntilChanged()).subscribe(() => {
             this.isControlActivated = true;
             this.showSuggestions();
          }),
       );
    }

    /**
     * @desc Gets input text and checks item suggestions
     *       for shopping list by typed keyword.
     *       In case no suggestions: shows 'Item coming soon!' title.
     * @private
     */
    public showSuggestions(): void {
       let itemName = this.itemTitleControl.value.trim();
       this.emitEnteredValue$.emit(itemName);
       if (!itemName) {
          this.suggestions = [];
          this.itemTitleControl.setValue('');
          this.isControlActivated = false;
          return;
       }

        this.suggestions = this.service.fetchSubcategories(itemName);
    }

    /**
     * @desc Resets control value and suggestions list.
     *       Deactivates all boolean properties
     *       and notifies subscribers about that.
     * @returns {string}
     * @private
     */
    private onFinish(): string {
       const value: string = this.itemTitleControl.value.trim();
       this.itemTitleControl.setValue('');
       this.suggestions = [];

       this.isControlActivated = false;
       this.isDelayPassed = false;
       this.setBlurOnControl();
       this.controlStateChange$.emit(false);
       this.resetControlState$.emit(null);
       this.emitEnteredValue$.emit(value);

       return value.trim();
    }

    private eventHandler(event) {
       if (event.keyCode === 13) {
          event.preventDefault();
          event.target.blur();
       }
    }
}
