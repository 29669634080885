import { Component } from '@angular/core';

@Component({
    template: `
        <div class="not-found__container">
            <h1>404</h1>
            <h2 class="text-right">Page not found</h2>
        </div>
    `
})
export class NotFoundComponent {}