var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CacheNotificationDynamicAbstractClass } from './dynamic-abstract-class';
import { AppSettings } from './../common/app.settings';
var PrivacyPolicyComponent = /** @class */ (function (_super) {
    __extends(PrivacyPolicyComponent, _super);
    function PrivacyPolicyComponent() {
        return _super.call(this) || this;
    }
    PrivacyPolicyComponent.prototype.privacyPolicyLink = function () {
        return AppSettings.APP_LANDING_PAGE() + '/terms-and-conditions';
    };
    PrivacyPolicyComponent.prototype.onClick = function (action, locationBack) {
        this.context._close && this.context._close(locationBack);
        this.context.handler && this.context.handler(action);
    };
    return PrivacyPolicyComponent;
}(CacheNotificationDynamicAbstractClass));
export { PrivacyPolicyComponent };
