import { Component, Input }     from '@angular/core';

import { Message } from '../../../swagger-gen__output_dir';
import AppValues from '../common/app.values';

@Component({
    selector:   'message-group',
    styleUrls:  ['chat.sass'],


    // animations: [slideInOutAnimation],
    // host:       { '[@slideInOutAnimation]': '' },

    template:   `
        <section class="chat-list__timeblock">

            <p class="timeblock__time-messages"
               data-test-id="timeblock__time-messages_">{{messageGroup.date | date:formatFullDate}}</p>

            <div class="timeblock__list-items">

                <section class="timeblock__list-item" *ngFor="let msg of messageGroup.messages; trackBy: trackByID">

                    <div class="message__container"
                         [ngClass]="msg['isSentByMe'] ? 'msg-right' : 'msg-left'">

                        <div [ngClass]="msg['isSentByMe'] ? 'my-message' : 'interlocutor-message'">
                            <h5 class="time-message" data-test-id="time-message_">{{msg['dateHHmm']}}</h5>

                            <p class="text-message" data-test-id="text-message_" [innerHTML]="linkify(msg.text)"></p>

                        </div>

                    </div>

                </section>

            </div>
        </section>
    `
})
export class MessageGroupComponent {
    /**
     * Renders messages related to the same date.
     */
    @Input() userID:        string;
    @Input() messageGroup:  {
        messages:   Message[];
        date:       string;
    };
    @Input() formatFullDate: string;


    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function.
     * @param {number} index
     * @param {Message} item
     * @return {string}
     */
    trackByID(index: number, item: Message): string {
        return item.ID;
    }

    public linkify(text: string): string {
        const htmlText: string = AppValues.parseSpecialCharacter(text);
        return AppValues.urlify(htmlText);
    }

}
