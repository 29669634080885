
        <div class="clearfix picker__cell" [attr.data-test-id]="'pickerCell_' + index" (click)="onClick()">

            <div class="left picker__img">
                <div *ngIf="!data['photoURL']" class="sprite sprite-no_image_icon"></div>
                <img *ngIf="data['photoURL']" src="{{data['photoURL']}}">
            </div>

            <section class="picker__info">
                <h3 class="title" data-test-id="pickerCellTitle">
                    {{data.title}}
                </h3>

                <h4 [attr.data-test-id]="'productPrice_' + index">{{productPrice / HUNDRED | currency:'USD'}}&nbsp;&nbsp;
                    <span *ngIf="title">({{title}})</span>
                </h4>

            </section>
        </div>
    