
        <header class="app-header">

            <nav class="app-header__container">
                <span class="navbar__left">
                    <a (click)="goBack()" data-test-id="dialogsLink" 
                       class="nav__link"><i class="icon-custom_back_icon"></i></a>
                </span>

                <div class="navbar__center" role="chat">
                    <h4 class="clip">{{title}}</h4>
                </div>

                <div class="navbar__right" role="chat">   
                    <div class="icon-user-circle-o"></div>
                </div>
            </nav>

        </header>
    