import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../modal/modal.service';
import { ClientPaymentService } from './payment.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { AppRouteValues, createDirection } from "../common/app.route.values";
var PaymentComponent = /** @class */ (function () {
    function PaymentComponent(formBuilder, modalService, paymentService, router, translate, location) {
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.paymentService = paymentService;
        this.router = router;
        this.translate = translate;
        this.cardHandler = this.handleChange.bind(this);
        this.isDisabledSubmitButton = true;
        this.isVisibleBlockGoogleApplePay = false;
        this.cardStyle = {
            base: {
                iconColor: '#9EBB4F',
                fontFamily: '"Avenir", sans-serif',
                lineHeight: '40px',
                fontWeight: 300,
                fontSize: '18px',
                '::placeholder': {
                    color: '#CFD7E0'
                }
            }
        };
        this.paymentRequestButtonStyle = {
            paymentRequestButton: {
                type: 'buy',
                theme: 'light-outline',
                height: '40px',
            }
        };
        this.location = location;
    }
    PaymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stripe = this.paymentService.stripePayment;
        this.amount = this.paymentService.totalAmount;
        this.cardBuilder();
        this.onSuccessPaymentChangeSubscription = this.paymentService.onSuccessPaymentChanges().subscribe(function (successPayment) {
            _this.router.navigate([createDirection(AppRouteValues.routeCartReport)]);
        });
        this.onErrorPaymentChangeSubscription = this.paymentService.onErrorPaymentChanges().subscribe(function (error) {
            _this.handleChange({ error: error });
        });
        this.paymentButtonBuilder();
    };
    PaymentComponent.prototype.cardBuilder = function () {
        this.payForm = this.formBuilder.group({
            cartName: ['', [Validators.required]]
        });
        this.elements = this.stripe.elements();
        // Only mount the element the first time
        if (!this.card) {
            this.card = this.elements.create('card', {
                style: this.cardStyle,
                hidePostalCode: true
            });
            this.card.mount(this.cardRef.nativeElement);
            this.card.on('change', this.cardHandler);
        }
    };
    PaymentComponent.prototype.paymentButtonBuilder = function () {
        var _this = this;
        this.onCanMakePaymentChangeSubscription = this.paymentService.onCanMakePaymentChanges().subscribe(function (canMakePaymentObject) {
            var prButton = _this.paymentService.stripePayment.elements().create('paymentRequestButton', {
                paymentRequest: _this.paymentService.stripePaymentRequest,
                style: _this.paymentRequestButtonStyle
            });
            if (canMakePaymentObject) {
                prButton.mount('#payment-request-button');
                _this.isVisibleBlockGoogleApplePay = true;
            }
            else {
                _this.isVisibleBlockGoogleApplePay = false;
            }
            _this.paymentService.paymentMethod();
        });
    };
    Object.defineProperty(PaymentComponent.prototype, "fullCardName", {
        get: function () {
            return this.payForm.get('cartName').value;
        },
        enumerable: true,
        configurable: true
    });
    PaymentComponent.prototype.onBuy = function (event) {
        this.handleChange({ complete: false });
        event.stopPropagation();
        event.preventDefault();
        this.paymentService.confirmCardPayment(this.card, this.fullCardName);
    };
    PaymentComponent.prototype.onClosePayBlock = function () {
        this.location.back();
        // this.closePayBlock.emit();
    };
    PaymentComponent.prototype.handleChange = function (e) {
        this.cardErrors = e.error ? e.error.message : '';
        this.isDisabledSubmitButton = !!(e.error || !e.complete);
    };
    PaymentComponent.prototype.isCheckDisabledSubmitButton = function () {
        return !this.isDisabledSubmitButton && !this.payForm.invalid;
    };
    PaymentComponent.prototype.ngOnDestroy = function () {
        if (this.onCanMakePaymentChangeSubscription) {
            this.onCanMakePaymentChangeSubscription.unsubscribe();
        }
        if (this.onSuccessPaymentChangeSubscription) {
            this.onSuccessPaymentChangeSubscription.unsubscribe();
        }
        if (this.onErrorPaymentChangeSubscription) {
            this.onErrorPaymentChangeSubscription.unsubscribe();
        }
    };
    return PaymentComponent;
}());
export { PaymentComponent };
