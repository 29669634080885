import { ElementRef, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ModalService } from '../modal/modal.service';
import { ProductEditorService } from '../product-editor/product-editor.service';
import { SearchType } from '../interfaces/search.interface';
import { MapSearchService } from '../map-search/map-search.service';
import { AppSearchService } from './search.service';
import { TranslateService } from '@ngx-translate/core';
var SearchHeaderComponent = /** @class */ (function () {
    function SearchHeaderComponent(elementRef, location, mapSearchService, searchService, modalService, productEditorService, translate) {
        this.elementRef = elementRef;
        this.mapSearchService = mapSearchService;
        this.searchService = searchService;
        this.modalService = modalService;
        this.productEditorService = productEditorService;
        this.translate = translate;
        this.changeEvent$ = new EventEmitter();
        this.searchValue = '';
        this.previousValue = '';
        this._w = window;
        this.locationSearch = decodeURIComponent(this._w.location.search.substr(5));
        this.location = location;
    }
    SearchHeaderComponent.prototype.ngOnInit = function () {
        this.setSearchPlaceholder();
    };
    SearchHeaderComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.searchValue = _this.searchResult.keyWord;
            _this.previousValue = _this.searchResult.keyWord;
            if (_this.userLocation && _this.previousValue === _this.searchValue.trim()) {
                _this.setFocus();
            }
        });
    };
    SearchHeaderComponent.prototype.setSearchPlaceholder = function () {
        var _a;
        this.searchPlaceholder = (_a = {},
            _a[SearchType.Item] = this.translate.instant('search.placeholder.product'),
            _a[SearchType.Market] = this.translate.instant('search.placeholder.market'),
            _a[SearchType.Seller] = this.translate.instant('search.placeholder.seller'),
            _a[SearchType.Sale] = this.translate.instant('search.placeholder.sale'),
            _a[SearchType.User] = this.translate.instant('search.placeholder.user'),
            _a[SearchType.Event] = this.translate.instant('search.placeholder.event'),
            _a[SearchType.Active] = this.translate.instant('search.placeholder.product'),
            _a);
    };
    SearchHeaderComponent.prototype.onMapClick = function () {
        this.previousValue !== this.searchValue.trim()
            && this.initSearch(this.searchValue.trim(), true);
        var isNotCached = false;
        var isMarket = false;
        if (this.isSellerProductsList()) {
            this.searchResult.title = this.searchService.results.items[0].sellerName;
            this.title = this.searchService.results.items[0].sellerName;
            this.changeSearchResult(SearchType.Item, this.searchService.results.items[0].sellerID);
            this.searchService.addToLocalCaching();
            isNotCached = true;
            isMarket = false;
        }
        else if (this.searchResult.data
            && Object.keys(this.searchResult.data).length
            && (this.searchResult.data.items)
            && this.isExistMarketName(this.searchResult.data.items)) {
            this.changeSearchResult(SearchType.Market);
            isNotCached = true;
            isMarket = true;
        }
        this.mapSearchService.showMap(this.searchResult.keyWord, this.locationSearch, isNotCached, isMarket);
    };
    SearchHeaderComponent.prototype.changeSearchResult = function (type, keyWord) {
        this.searchResult.type = type;
        this.searchService.type = type;
        if (keyWord) {
            this.searchService.keyWord = keyWord;
            this.searchResult.keyWord = keyWord;
        }
        this.mapSearchService.searchResult = this.searchResult;
    };
    SearchHeaderComponent.prototype.isSellerProductsList = function () {
        var data = this.searchResult.data;
        var availableData = data && Object.keys(data).length && (data.items || data.nearest_items);
        return availableData && (this.isExistSellerName(data.items) || this.isExistSellerName(data.nearest_items));
    };
    SearchHeaderComponent.prototype.isExistSellerName = function (items) {
        return items && items.length && items[0].sellerName + "'s items" === this.locationSearch;
    };
    SearchHeaderComponent.prototype.isExistMarketName = function (items) {
        return items && items.length && items[0]['market'] && items[0]['market'].market_title === this.locationSearch;
    };
    SearchHeaderComponent.prototype.onCancel = function () {
        var _this = this;
        var local = decodeURIComponent(this._w.location.pathname);
        if (local === '/users-list') {
            this.location.back();
        }
        else {
            this.location.replaceState('map-search');
            setTimeout(function () {
                _this.searchResult.type === 'event'
                    ? _this.productEditorService.cancelEventCreation()
                    : _this.location.back();
            });
        }
    };
    SearchHeaderComponent.prototype.isNotAvailableSorting = function () {
        var _this = this;
        return [SearchType.Market, SearchType.Event].some(function (type) { return _this.searchResult.type === type; })
            || decodeURIComponent(this._w.location.pathname) === '/users-list' && this.isAdminMode;
    };
    SearchHeaderComponent.prototype.isTypeForSortByPrice = function () {
        var _this = this;
        return [SearchType.Item, SearchType.Active, SearchType.Sale].some(function (type) { return _this.searchResult.type === type; });
    };
    SearchHeaderComponent.prototype.onFilterClick = function () {
        var _this = this;
        var modal_params = {
            idFilterButton: 'filterBtn',
            filter_data: this.search_filter,
            style: { borderRadius: '3px', width: '175px' }
        };
        this.modalService.filter_modal(modal_params).then(function (value) {
            _this.setFocus();
            _this.changeEvent$.emit({
                type: value,
                keyWord: _this.searchResult.keyWord,
                sortBy: 'unsorted'
            });
        });
    };
    SearchHeaderComponent.prototype.onSearch = function (event, value) {
        var elem = event.target;
        elem.blur();
        event.stopPropagation();
        value = value.trim();
        if (this.searchResult.type === 'user' && !this.previousValue && !value) {
            return;
        }
        this.initSearch(value);
    };
    SearchHeaderComponent.prototype.onSortChange = function (value) {
        this.changeEvent$.emit({ sortBy: value });
    };
    SearchHeaderComponent.prototype.initSearch = function (value, map) {
        this.previousValue = value;
        var obj = { keyWord: value };
        if (map)
            Object.assign(obj, { map: map });
        this.changeEvent$.emit(obj);
    };
    SearchHeaderComponent.prototype.setFocus = function () {
        var elem = this.elementRef.nativeElement.querySelector('input.search-header__input');
        elem && !this.searchResult.keyWord && elem.focus();
    };
    return SearchHeaderComponent;
}());
export { SearchHeaderComponent };
