import AppValues from '../../common/app.values';

export function isIOSPlatform(): boolean {
  return (
    [ 'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
      'MacIntel',
      'MacPPC',
      'Mac68K',
      'Mac',
      'Macintosh',
      'iOS'
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isValidFileSize(fileSize: number): boolean {
  return isIOSPlatform()
    ? fileSize <= AppValues.allowable_upload_image_size_ios
    : fileSize <= AppValues.allowable_upload_image_size;
}