import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

// this directive spies on `Ctrl+.` or `Cmd+.`

@Directive({
    selector: '[multiKey]'
})
export class MultiKeyDirective {
    @Output() fillFields$: EventEmitter<null> = new EventEmitter();

    @HostListener('keydown', ['$event'])
    onKeyDown(e: any) {

        if ( e.keyCode === 190 && (e.ctrlKey || e.metaKey) ) {
            this.fillFields$.emit();
        }
    }

    // constructor() {
    //     console.log('DIRECTIVE------------++++++++++++++++');
    // }

}
