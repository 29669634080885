import { Injector } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { fromPromise } from "rxjs/observable/fromPromise";
import { of } from "rxjs/observable/of";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { MapsAPILoader } from "@agm/core";
import { TranslateService } from "@ngx-translate/core";
import AppValues from "../common/app.values";
import { ModalService } from "../modal/modal.service";
import { GeoLocationService } from "./geolocation.service";
var GeoCoderService = /** @class */ (function () {
    function GeoCoderService(geoLocationService, injector, mapLoader) {
        this.geoLocationService = geoLocationService;
        this.injector = injector;
        this.mapLoader = mapLoader;
    }
    GeoCoderService.prototype.initGeocoder = function () {
        this.geocoder = new google.maps.Geocoder();
    };
    GeoCoderService.prototype.waitForMapsToLoad = function () {
        var _this = this;
        if (!this.geocoder) {
            return fromPromise(this.mapLoader.load())
                .pipe(tap(function () { return _this.initGeocoder(); }), map(function () { return true; }));
        }
        return of(true);
    };
    Object.defineProperty(GeoCoderService.prototype, "modalService", {
        get: function () {
            return this.injector.get(ModalService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeoCoderService.prototype, "translate", {
        get: function () {
            return this.injector.get(TranslateService);
        },
        enumerable: true,
        configurable: true
    });
    GeoCoderService.prototype.geocoderErrorModal = function (errorText) {
        var message = errorText;
        if (errorText.includes("ZERO_RESULTS")) {
            message = this.translate.instant('geocoder.noResults.error.message');
        }
        this.modalService.error({
            title: this.translate.instant('geocoder.noResults.error.title'),
            message: message,
            yesButtonText: this.translate.instant('geocoder.noResults.error.button')
        });
    };
    GeoCoderService.prototype.getFullAddressString = function (address) {
        return address.address1 + ' ' + address.address2 + ' '
            + address.city + ' ' + address.state + ' ' + address.country;
    };
    GeoCoderService.prototype.geocodeAddress = function (address) {
        var _this = this;
        return this.waitForMapsToLoad().pipe(filter(function (loaded) { return loaded; }), switchMap(function () {
            return new Observable(function (observer) {
                _this.geocoder.geocode({
                    'address': address,
                    'language': 'en'
                }, function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            observer.next({
                                latitude: results[0].geometry.location.lat(),
                                longitude: results[0].geometry.location.lng()
                            });
                        }
                        else {
                            _this.geocoderErrorModal(status);
                            observer.error(status);
                        }
                    }
                    else {
                        console.log('Error - ', results, ' & Status - ', status);
                        _this.geocoderErrorModal(status);
                        observer.error(status);
                    }
                    observer.complete();
                });
            });
        }));
    };
    GeoCoderService.prototype.reverseGeocodingAddress = function (location) {
        var _this = this;
        var latlng = {
            lat: location.latitude,
            lng: location.longitude
        };
        return this.waitForMapsToLoad().pipe(filter(function (loaded) { return loaded; }), switchMap(function () {
            return new Observable(function (observer) {
                _this.geocoder.geocode({
                    'location': latlng,
                    'language': 'en'
                }, function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            observer.next(results[0]);
                        }
                        else {
                            observer.error(_this.translate.instant('geocoder.map.noResults.error.message'));
                        }
                    }
                    else {
                        observer.error(_this.translate.instant('geocoder.map.noResults.failed.message') + status + ". " + _this.translate.instant('geocoder.map.noResults.failed.try'));
                    }
                    observer.complete();
                });
            });
        }));
    };
    /**
     * Address types and address component types
     * The types[] array in the result indicates the address type.
     * Examples of address types include a street address, a country, or a political entity.
     * There is also a types[] array in the address_components[], indicating the type of each part of the address.
     * Examples include street number or country. (Below is a full list of types.)
     * Addresses may have multiple types. The types may be considered 'tags'.
     * For example, many cities are tagged with the political and the locality type.
     * https://developers.google.com/maps/documentation/geocoding/overview#Types
     * @param {AddressInterface} user_address
     * @param {AddressComponentInterface[]} address_components
     * @return {AddressInterface}
     */
    GeoCoderService.prototype.getFormatGoogleAddress = function (user_address, address_components) {
        address_components.forEach(function (addr) {
            addr.types.forEach(function (t) {
                if (t === 'country') {
                    user_address.country = AppValues.getCountryName(addr.short_name);
                }
                if (t === 'postal_code') {
                    user_address.postCode = addr.long_name;
                }
                if (t === 'administrative_area_level_1') {
                    user_address.state = addr.short_name;
                }
                if (t === 'locality' || t === 'sublocality_level_1') {
                    user_address.city = addr.long_name;
                }
                if (t === 'neighborhood') {
                    user_address.address2 = addr.long_name;
                }
                if (t === 'street_number') {
                    user_address.address1 = addr.long_name + ' ';
                }
                if (t === 'route') {
                    user_address.address1 += addr.long_name;
                }
            });
        });
        return user_address;
    };
    return GeoCoderService;
}());
export { GeoCoderService };
