/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./goods-nav.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./inventory-nav-header.component";
var styles_InventoryNavHeaderComponent = [i0.styles];
var RenderType_InventoryNavHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InventoryNavHeaderComponent, data: {} });
export { RenderType_InventoryNavHeaderComponent as RenderType_InventoryNavHeaderComponent };
export function View_InventoryNavHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "nav", [["class", "app-header__container"], ["role", "goods-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "nav__link"], ["data-test-id", "inventoryLink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.location.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "navbar__center"], ["role", "inventory-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "navbar__right"], ["data-test-id", "nextLink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "active": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["class", "text-link"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 0, "span", [["class", "nav__link sprite sprite-map_callout_arrow_2x"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "navbar__right"; var currVal_2 = _ck(_v, 10, 0, _co.isActive); _ck(_v, 9, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categoryName; _ck(_v, 7, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("inventory.nav.next")); _ck(_v, 12, 0, currVal_3); }); }
export function View_InventoryNavHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "inventory-nav-header", [], null, null, null, View_InventoryNavHeaderComponent_0, RenderType_InventoryNavHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i4.InventoryNavHeaderComponent, [i2.Location], null, null)], null, null); }
var InventoryNavHeaderComponentNgFactory = i1.ɵccf("inventory-nav-header", i4.InventoryNavHeaderComponent, View_InventoryNavHeaderComponent_Host_0, { categoryName: "categoryName", isActive: "isActive" }, { createItemEvent$: "createItemEvent$" }, []);
export { InventoryNavHeaderComponentNgFactory as InventoryNavHeaderComponentNgFactory };
