/**
 * BiziBaza app
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CommonErrorResonse } from '../model/commonErrorResonse';
import { CompleteOrderElementsRequestBody } from '../model/completeOrderElementsRequestBody';
import { EmptyDict } from '../model/emptyDict';
import { OrdersResponse } from '../model/ordersResponse';
import { PaymentNotification } from '../model/paymentNotification';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrdersService {

    protected basePath = 'https://dev-backend.bizibaza.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Marks order elements as completed.
     * 
     * @param body List of unique identifiers of order elements.
     * @param Token Current session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public completeOrderElementsPost(body: CompleteOrderElementsRequestBody, Token: string, observe?: 'body', reportProgress?: boolean): Observable<EmptyDict>;
    public completeOrderElementsPost(body: CompleteOrderElementsRequestBody, Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmptyDict>>;
    public completeOrderElementsPost(body: CompleteOrderElementsRequestBody, Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmptyDict>>;
    public completeOrderElementsPost(body: CompleteOrderElementsRequestBody, Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling completeOrderElementsPost.');
        }

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling completeOrderElementsPost.');
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<EmptyDict>(`${this.basePath}/complete_order_elements`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns cart report.
     * 
     * @param payment_id Unique identifier of payment.
     * @param Token current session token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentNotificationGet(payment_id: string, Token: string, observe?: 'body', reportProgress?: boolean): Observable<PaymentNotification>;
    public getPaymentNotificationGet(payment_id: string, Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentNotification>>;
    public getPaymentNotificationGet(payment_id: string, Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentNotification>>;
    public getPaymentNotificationGet(payment_id: string, Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payment_id === null || payment_id === undefined) {
            throw new Error('Required parameter payment_id was null or undefined when calling getPaymentNotificationGet.');
        }

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling getPaymentNotificationGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (payment_id !== undefined && payment_id !== null) {
            queryParameters = queryParameters.set('payment_id', <any>payment_id);
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PaymentNotification>(`${this.basePath}/get_payment_notification`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns list of seller&#x27;s pending orders.
     * 
     * @param Token Current session token.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pendingOrdersGet(Token: string, observe?: 'body', reportProgress?: boolean): Observable<OrdersResponse>;
    public pendingOrdersGet(Token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdersResponse>>;
    public pendingOrdersGet(Token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdersResponse>>;
    public pendingOrdersGet(Token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling pendingOrdersGet.');
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrdersResponse>(`${this.basePath}/pending_orders`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
