import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { ModalService } from '../modal/modal.service';
import { UserService } from '../user/user.service';
import { GetDialogsService, ReadMessagesService, MessagingService } from '../../../swagger-gen__output_dir';
import { CreateMessageService } from '../../../swagger-gen__output_dir/api/createMessage.service';
import { TranslateService } from '@ngx-translate/core';
var ChatService = /** @class */ (function () {
    function ChatService(dataService, errorService, modalService, router, injector, readMsgService, msgFromDialogService, getDialogsService, createMessageService, translate) {
        this.dataService = dataService;
        this.errorService = errorService;
        this.modalService = modalService;
        this.router = router;
        this.injector = injector;
        this.readMsgService = readMsgService;
        this.msgFromDialogService = msgFromDialogService;
        this.getDialogsService = getDialogsService;
        this.createMessageService = createMessageService;
        this.translate = translate;
        this.chatEvent$ = new Subject();
        this.dialogEvent$ = new Subject();
        this.dialogsEvent$ = new Subject();
        this.searchKeyword = '';
    }
    /**
     * Starts polling for new dialogs after user has been logged
     */
    ChatService.prototype.init = function () {
        this.userSession = this.user_service.getUserSession();
        this.getGialogInformation(this.userSession);
    };
    ChatService.prototype.getGialogInformation = function (userSesion) {
        if (!userSesion)
            userSesion = this.user_service.getUserSession();
        var user = this.user_service.getUser();
        if (user && user.accessLevel !== 0) {
            userSesion.token && this._startPolling();
        }
        else
            this.stopPolling();
    };
    /**
     * Method for revers sorted by date
     * @param { FoundDialog[] } items
     */
    ChatService.prototype.sortItems = function (items) {
        return items.sort(function (itemA, itemB) {
            return itemB.last_message_date - itemA.last_message_date;
        });
    };
    Object.defineProperty(ChatService.prototype, "user_service", {
        /***
         * @desc This function creates 'user service' property on your service.
         * @return {UserService}
         */
        get: function () {
            return this.injector.get(UserService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatService.prototype, "searchChatKeyword", {
        set: function (keyword) {
            this.searchKeyword = keyword;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatService.prototype, "searchKeywordText", {
        get: function () {
            return this.searchKeyword;
        },
        enumerable: true,
        configurable: true
    });
    ChatService.prototype.getCurrentChat$ = function () {
        return this.chatEvent$.asObservable();
    };
    ChatService.prototype.setCurrentChat = function (chat) {
        this.currentChat = chat;
        this.chatEvent$.next(chat);
    };
    ChatService.prototype.getCurrentChat = function () {
        return this.currentChat;
    };
    /**
     * @desc Gets a list of dialogs for which the dialog title
     *       or text of any message containes setted keyword
     * @param {string} keyword
     * @returns {Observable<FoundDialog[] | []>}
     */
    ChatService.prototype.searchByKeyword = function (keyword) {
        var _this = this;
        var token = this.user_service.getToken();
        this.searchChatKeyword = keyword;
        if (!token) {
            return Observable.of([]);
        }
        return this.getDialogsService.getDialogsGet(token, keyword)
            .map(function (res) {
            _this.dialogs = _this.sortItems(res.dialogs);
            return _this.dialogs;
        })
            .catch(function (err) {
            return _this.errorService.handleHttpError(err);
        });
    };
    // Deprecated method
    //
    // chatForAnyGuestUser() {
    //     this.user_service.userAuthEvent$
    //         .subscribe((user: UserSessionInterface) => {
    //             this.userSession = user;
    //
    //             user.token && this._retrieveDialogs();
    //             this.dialog = { messages: [], dialogID: '' };
    //         });
    // }
    /**
     * Fires dialog polling requests in 10-second interval
     * @private
     */
    ChatService.prototype._startPolling = function () {
        this._retrieveDialogs();
        this.dialogsTimer = setInterval(this.userSession.token
            ? this._retrieveDialogs.bind(this)
            : false, 10000);
    };
    /**
     * Stops dialog polling
     */
    ChatService.prototype.clearDialogPolling = function () {
        clearInterval(this.chatTimer);
    };
    /**
     * Fetches dialogs.
     * @emits dialogsEvent$
     * @private
     */
    ChatService.prototype._retrieveDialogs = function () {
        var token = this.user_service.getToken();
        if (!token) {
            this.dialogsTimer && clearInterval(this.dialogsTimer);
            return;
        }
        else
            this._getDialogs(true);
    };
    ChatService.prototype._getDialogs = function (obs) {
        var _this = this;
        if (!this.searchKeywordText) {
            if (obs) {
                this.getDialogsRequest();
            }
            else {
                return new Observable(function (observer) {
                    _this.getDialogsRequest(observer);
                });
            }
        }
    };
    ChatService.prototype.getDialogsRequest = function (observer) {
        var _this = this;
        var token = this.user_service.getToken();
        this.dataService.getData('get_dialogs', { token: token })
            .subscribe(function (dialogs) {
            _this.dialogs = _this.sortItems(dialogs.dialogs);
            _this.dialogsEvent$.next(dialogs.dialogs);
            if (observer) {
                observer.next(_this.dialogs);
                observer.complete();
            }
        }, function (err) {
            if (!err.statusText)
                _this.stopPolling();
            else if (err.status === 403) {
                _this.errorService.handleError(err);
                _this.stopPolling();
            }
            else
                err.statusText && _this.errorService.handleError(err);
            observer && observer.error(err);
        });
    };
    ChatService.prototype.stopPolling = function () {
        clearInterval(this.dialogsTimer);
        return;
    };
    /**
     * Fetches messages of a certain dialog.
     * @param id
     * @returns {Observable<ReadMessagesResponseBody} | {}>}
     * @private
     */
    ChatService.prototype.retrieveChat = function (id) {
        var _this = this;
        var token = this.userSession.token;
        if (!token) {
            return Observable.of({});
        }
        return this.readMsgService.readMessagesPost({ dialog_id: id }, token)
            .map(function (res) {
            return _this._sortMessages(res.messages, id);
        })
            .catch(function (err) {
            return _this.errorService.handleHttpError(err);
        });
    };
    /**
     * @desc Assigns @isSentByMe field to each message in a dialog, so that the messages
     * could be marked as "mine/theirs" further with CSS styles.
     * @param messages
     * @param dialogID
     * @returns {ChatInterface}
     * @private
     */
    ChatService.prototype._sortMessages = function (messages, dialogID) {
        var _this = this;
        messages.forEach(function (msg) {
            return msg['isSentByMe'] = msg.sender_id === _this.userSession.ID;
        });
        this.dialog = {
            messages: messages,
            dialogID: dialogID,
        };
        return this.dialog;
    };
    /**
     * Returns dialogEvent$.
     * @returns {Observable<ChatInterface>}
     */
    ChatService.prototype.getDialog = function () {
        return this.dialogEvent$.asObservable();
    };
    /**
     * Returns dialogsEvent$.
     * @returns {Observable<FoundDialog[]>}
     */
    ChatService.prototype.getDialogs = function () {
        return this.dialogsEvent$.asObservable();
    };
    /**
     * Returns a certain dialog.
     * @returns {ChatInterface}
     */
    ChatService.prototype.getCurrentDialog = function () {
        return this.dialog;
    };
    /**
     * Returns dialogs.
     * @returns {FoundDialog[]}
     */
    ChatService.prototype.getCurrentDialogs = function () {
        return this.dialogs;
    };
    /**
     * Navigates to the Chat room page and fetches a certain dialog. Starts chat polling.
     * @param {FoundDialog} dialog
     */
    ChatService.prototype.showDialog = function (dialog) {
        var _this = this;
        this.dialogID = dialog.ID;
        this.setCurrentChat(dialog);
        this.modalService.showSpinner();
        this.retrieveChat(dialog.ID)
            .subscribe(function () {
            _this.modalService.close();
            _this.router.navigate(['/chat-room'], {
                queryParams: {
                    interlocutor: dialog.interlocutor_title
                }
            });
        }, function (err) {
            _this.modalService.close();
            _this.errorService.handleError(err);
        });
        this.chatTimer = setInterval(function () {
            _this.getMessagesFromDialog(dialog.ID)
                .subscribe(function (chat) { return _this.dialogEvent$.next(chat); }, function (err) { return _this.errorService.handleError(err); });
        }, 5000);
    };
    /**
     * show messages in one of dialogs
     * @param {string} id
     * @return {Observable<{messages: Message[]} | {}>}
     */
    ChatService.prototype.getMessagesFromDialog = function (id) {
        var _this = this;
        var token = this.userSession.token;
        if (!token) {
            this.chatTimer && clearInterval(this.chatTimer);
            return Observable.of({});
        }
        return this.msgFromDialogService.messageFromDialogGet(id, token)
            .map(function (res) {
            return _this._sortMessages(res.messages, id);
        })
            .catch(function (err) {
            return _this.errorService.handleHttpError(err);
        });
    };
    /**
     * Shows a dialog if exists, otherwise calls to create a new one.
     * @param interlocutorID
     * @param chatTitle
     */
    ChatService.prototype.goToDialog = function (interlocutorID) {
        var _this = this;
        if (!this.dialogs && !Object.keys(this.dialogs).length) {
            this._getDialogs(true).subscribe(function () {
                _this._goToDialog(interlocutorID);
            });
        }
        else
            this._goToDialog(interlocutorID);
    };
    ChatService.prototype._goToDialog = function (interlocutorID) {
        var chat = this.dialogs.find(function (dialog) {
            return dialog.subscribers.some(function (id) { return id === interlocutorID; });
        });
        chat ? this.showDialog(chat) : this._createDialog(interlocutorID);
    };
    /**
     * Posts to create a new dialog. Calls to show just created dialog.
     * @param interlocutorID
     * @param title
     * @returns {any}
     * @private
     */
    ChatService.prototype._createDialog = function (interlocutorID) {
        var _this = this;
        var token = this.userSession.token;
        if (!token)
            return;
        // Note: Title is not needed anymore. For dialog title use interlocutor_title.
        var body = {
            subscribers: [interlocutorID, this.userSession.ID],
            title: this.user_service.getUser().title
        };
        this.modalService.showSpinner();
        this.dataService.postData('create_dialog', body, { token: token })
            .subscribe(function (dialog) {
            _this.dialogs.push(dialog);
            _this.showDialog(dialog);
        }, function (err) { return _this.errorService.handleError(err); });
    };
    /**
     * Confirms user choice in removing a dialog.
     * @param dialog_id
     */
    ChatService.prototype.removeDialog = function (dialog_id) {
        var _this = this;
        this.modalService
            .warning({
            title: this.translate.instant('chatroom.removeDialogTitle'),
            yesButtonText: this.translate.instant('chatroom.confirmDialog'),
            noButtonText: this.translate.instant('chatroom.rejectDialog'),
            reverseButtons: true,
        })
            .then(function (action) { return action && _this._removeDialog(dialog_id); });
    };
    /**
     * Posts to remove a dialog.
     * @param dialog_id
     * @returns {any}
     * @private
     */
    ChatService.prototype._removeDialog = function (dialog_id) {
        var _this = this;
        var token = this.userSession.token;
        if (!token)
            return;
        this.modalService.showSpinner();
        this.dataService.postData('delete_dialog', { dialog_id: dialog_id }, { token: token })
            .subscribe(function () {
            _this.modalService.close();
            var index = _this.dialogs.findIndex(function (d) { return d.ID === dialog_id; });
            _this.dialogs.splice(index, 1);
            _this.dialogsEvent$.next(_this.dialogs);
        }, function (err) { return _this.errorService.handleError(err); });
    };
    /**
     * Posts a new message to the dialog.
     * @param message_text
     * @param dialogID
     * @returns {Observable<ChatInterface | {}>}
     */
    ChatService.prototype.sendMessage = function (message_text, dialogID) {
        var _this = this;
        var token = this.userSession.token;
        if (!token)
            return Observable.of({});
        var that = this;
        var body = {
            to_dialog: this.dialogID,
            message_text: message_text
        };
        this.modalService.showSpinner();
        return this.createMessageService.sendMessagePost(body, token)
            .map(function (message) {
            var isExistMsg = that.dialog.messages.some(function (msg) { return msg.ID === message.ID; });
            if (!isExistMsg) {
                that.dialog.messages.push(message);
            }
            that.modalService.close();
            return that._sortMessages(that.dialog.messages, that.dialog.dialogID);
        })
            .catch(function (err) {
            _this.errorService.handleError({
                status: err.status,
                statusText: _this.translate.instant('chatroom.errorSendMessage') + " \n " + err.statusText
            });
            return Observable.throwError(err);
        });
    };
    return ChatService;
}());
export { ChatService };
