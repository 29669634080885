import { Component, ElementRef }    from '@angular/core';


import { MarketInterface }          from '../../interfaces/market.interface';
import { ProductEditorService }     from '../../product-editor/product-editor.service';
import { SearchListClass }          from './search-list.class';


@Component({
    selector:       'event-search-list',
    styleUrls:      ['../search.sass'],
    templateUrl:    './market-search-list.html'
})

export class EventSearchComponent extends SearchListClass {

    constructor(
        public elementRef:              ElementRef,
        private productEditorService:   ProductEditorService,
    ) {
        super(elementRef);
    }



    onClick(item: {}): void {
        this.productEditorService.createEvent(item as MarketInterface);
    }

}
