
        <div class="component reviews-component">
            <reviews-header (changeEvent$)="onOptionsChange($event)"
                            (goToCreateReview$)="null"
                            [isAvailableFilter]="isAvailableFilter"
                            [isAvailableTabs]="isAvailableTabs"
                            [userTitle]="userTitle"
                            (backToHomeEvent$)="backToHome()"
                            [reviewsAllFilters]="reviewsAllFilters"
                            [reviewsAllFiltersSubject]="reviewsAllFiltersSubject"></reviews-header>
            <div [class]="isAvailableFooter && 'review-container'" [ngStyle]="heightContainer()">
                <div #container></div>
            </div>
        </div>
    