import {FullTransactionInterface, HistoryOrderInterface} from './analytics.interface';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Router} from '@angular/router';

import {OrdersAppService} from './orders.service';
import {AppFooterService} from '../app-footer/app-footer.service';
import AppValues from '../common/app.values';
import {ErrorInterface} from '../services/error.service';
import {BuyingHistoryResponse} from '../../../swagger-gen__output_dir/model/buyingHistoryResponse';
import {Transaction} from '../../../swagger-gen__output_dir/model/transaction';
import {SellingHistoryResponse} from '../../../swagger-gen__output_dir/model/sellingHistoryResponse';
import {Address, OrderElement} from '../../../swagger-gen__output_dir';
import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {ModalService} from '../modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {ScrollToConfigOptionsTarget}
    from '@nicky-lenaers/ngx-scroll-to/src/app/modules/scroll-to/scroll-to-config.interface';
import {AdapteeFullTransaction} from './adapteeFullTransactio.history';


@Component({
    selector: 'history',
    styleUrls: ['analytics.sass'],
    templateUrl: 'history.component.html'
})

export class HistoryComponent implements OnInit, OnDestroy {

    public elements: OrderElement[];
    public HUNDRED: number = AppValues.HUNDRED;
    public items: FullTransactionInterface[];
    public THOUSAND: number = AppValues.THOUSAND;
    public totalCost: number;
    public mode: 'seller' | 'buyer';
    public numberOfItems: number;
    public templateName: string;
    public location:   Location;
    public fullDateTimePattern: string = AppValues.fullDateTimePattern;


    private componentDestroyed$: Subject<boolean> = new Subject();

    public constructor(private ordersService:      OrdersAppService,
                       private appFooterService:   AppFooterService,
                       private scrollToService:    ScrollToService,
                       private modalService:       ModalService,
                       private translate:          TranslateService,
                       private router: Router,
                       location:                   Location) {
        this.location = location;
        this.mode = this.appFooterService.getMode();
        this.templateName = this.templateCompanyBuyerNameByMode(this.mode);
    }


    public ngOnInit(): void {
        this.ordersService.getDialogList();

        if (this.mode === 'buyer') {
            this.ordersService.getBuyingHistory()
                .takeUntil(this.componentDestroyed$)
                .subscribe((list: BuyingHistoryResponse) => {

                    this.items = this.orderTemplates(list.buying_transactions);

                    this._validateItems();
                }, (err: ErrorInterface) => err);
        }
        if (this.mode === 'seller') {
            this.ordersService.getSellingHistory()
                .takeUntil(this.componentDestroyed$)
                .subscribe((list: SellingHistoryResponse) => {

                    this.items = this.orderTemplates(list.selling_transactions);

                    this._validateItems();
                }, (err: ErrorInterface) => err);
        }
    }

    public orderTemplates(transactions: Transaction[]): FullTransactionInterface[] {
        return new AdapteeFullTransaction(transactions, this.ordersService).transactions;
    }

    /**
     * Text or placeholder for templates
     * @param {string} mode
     * @return {string}
     */
    public templateCompanyBuyerNameByMode(mode: 'seller' | 'buyer'): string {
        return (mode === 'seller') ? AppValues.buyer_name_header : AppValues.company_name_header;
    }


    /**
     * @desc toggle visibility item
     * (click than expand, click again than collapse)
     * @param {Transaction} history
     * @param {string} id
     * @private
     */
    public _toggleVisibility(history: Transaction, id: string): void {
        this.items.forEach((sc: Transaction) => {
            if (sc !== history) { sc['_isVisible'] = false; }
        });
        history['_isVisible'] = !history['_isVisible'];

        setTimeout(() => {
            if (history['_isVisible']) {
                this.triggerScrollTo(id);
            }
        });
    }


    public isPickUp(address: Address): boolean {
        return this.ordersService.isPickUp(address);
    }


    /**
     * @desc Show Product from History
     * @param {string} id
     * @param {number} qnt
     * @param {string} idOrder
     * @param {number} date
     * @param {number} price
     * @param {string} nameUnit
     */
    public showProduct(id: string, qnt: number, idOrder: string, date: number, price: number, nameUnit: string): void {
        if (!id) { return; }

        this.ordersService.showProductDetails(id, {
            readOnly: true,
            desiredQuantity: qnt,
            idOrder,
            date,
            price,
            nameUnit
        });
    }

    public goBack(): void {
        if (this.ordersService.isOpenedHistoryFromReview) {
            this.ordersService.isOpenedHistoryFromReview = false;
            this.router.navigate(['/reviews']);
            return;
        }

        this.router.navigate(['/orders']);
    }


    public contact(item: Transaction, elem: OrderElement, event: Event): void {
        event.stopPropagation();

        this.ordersService.openChat(
            this.mode === 'seller'
                ? item.buyer_id
                : elem.seller_id
        );
    }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how
     * to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied,
     * Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {Transaction | OrderElement} item
     * @return {string}
     */
    public trackByID(index: number, item: Transaction | OrderElement): string {
        return item.ID;
    }

    public ngOnDestroy(): void {
        this.ordersService.stopGetDialogPolling();
    }


    /**
     * @desc Сalculates the number of products in each purchase individually (numberOfItems)
     * and the value of all purchases for all time (totalCost)
     * @private
     */
    private _calculateTotal(): void {
        this.totalCost = 0;
        this.numberOfItems = 0;

        // this.totalCost = this.items.reduce((orderA: FullTransactionInterface, orderB: FullTransactionInterface) => {
        //     return orderA.transaction_total_price + orderB.transaction_total_price;
        // });
        this.items.forEach((h: FullTransactionInterface) => {
            this.totalCost += h.transaction_total_price;
        });
        this.numberOfItems = this.items.length;
    }

    /**
     * @desc Sort items by last purchase date
     * @param {Transaction[]} items
     * @private
     */
    private _sortItems(items: Transaction[]): void {
        items.sort((itemA: Transaction, itemB: Transaction) =>
            itemB.order_date.toString().localeCompare(itemA.order_date.toString())
        );
    }

    private scrollToOrder(): void {
        if (this.ordersService.getSelectedOrderID !== '') {
            this.items.forEach((order: FullTransactionInterface, index: number) => {
                order.elements.forEach((element: HistoryOrderInterface) => {
                    if (element.order_id === this.ordersService.getSelectedOrderID) {
                        this._toggleVisibility(order, 'historyItem_' + index);
                    }
                });
            });
        }
    }
    /**
     * @desc check items that it is not empty
     * @private
     */
    private _validateItems(): void {
        if (!Object.keys(this.items).length) {
            this.items = [];
            this.totalCost = 0;
            this.numberOfItems = 0;
        }
        if (Object.keys(this.items).length) {
            this._calculateTotal();
        }

        this._sortItems(this.items);

        this.scrollToOrder();
    }

    private triggerScrollTo(id: string): void {
        const config: ScrollToConfigOptionsTarget = {
            'container': 'custom-container',
            'target': id,
            'duration': 650,
            'easing': 'easeInCubic',
            'offset': 0
        };

        this.scrollToService.scrollTo(config);
    }
}
