import { ActivatedRoute }           from '@angular/router';
import {
    Component, ElementRef,
    OnInit, OnDestroy, Injector
} from '@angular/core';
import { DatePipe }                 from '@angular/common';
import { Subject }                  from 'rxjs';

import { ChatService }              from './chat.service';
import {
    ChatInterface
} from '../interfaces/dialog.interface';
import {Dialog, FoundDialog, Message} from '../../../swagger-gen__output_dir';
import {ErrorInterface, ErrorService} from '../services/error.service';
import {RedirectService} from '../services/redirect.service';
import {ModalService} from '../modal/modal.service';
import AppValues from '../common/app.values';
import {UserService} from '../user/user.service';
import {TranslateService} from "@ngx-translate/core";



@Component({
    selector:   'chat-room',
    styleUrls:  ['chat.sass'],
    providers:  [DatePipe],


    // animations: [slideInOutAnimation],
    // host:       { '[@slideInOutAnimation]': '' },

    template:   `
        <div class="component">

            <dialog-header [title]="interlocutor" (goBackEvent$)="goBack()"></dialog-header>

            <div class="component__container chat-room__container">

                <div class="chat-list no-cope-image">

                    <message-group
                            *ngFor="let group of messageGroups; trackBy: trackByDate"
                            [messageGroup]="group"
                            [formatFullDate]="formatFullDate"
                            [userID]="userID"></message-group>

                    <p *ngIf="currentChat?.interlocutor_anonymized" class="timeblock__time-messages" data-test-id="deleted-user-message">{{ "chatroom.deletedUserMessage" | translate}}</p>

                </div>

                <div class="chat-footer">
                    <span *ngIf="isSending">&nbsp;</span>

                    <safe-textarea
                        *ngIf="!isSending"
                        [isAutofocus]="true"
                        [className]="'chat-message-input'"
                        [name]="'message'"
                        [placeholder]="'Text'"
                        [rows]="1"
                        [value]="userInput"
                        [disabled]="currentChat?.interlocutor_anonymized"
                        [minMessageCharacters]="minMessageCharacters"
                        [maxMessageCharacters]="maxMessageCharacters"
                        (isValidEvent$)="checkIsValid($event)"
                        (keyup)="clickHandler($event)"
                        (changeEvent$)="changeEventHandler($event)" data-test-id="messageInput"></safe-textarea>

                    <button type="button" data-test-id="sendMessage"
                            [className]="!currentChat?.interlocutor_anonymized ? 'send-message' : 'deleted-user send-message'"
                            (click)="sendMessage()" [disabled]="!isValid || currentChat?.interlocutor_anonymized">{{ "chatroom.send" | translate }}</button>
                </div>

            </div>
        </div>
    `
})
export class ChatRoomComponent implements OnInit, OnDestroy {
    /**
     * @desc Composes message groups (messages grouped by date) and text area to write a new message.
     */
    public dialog:         ChatInterface;
    public currentChat:    Dialog;
    public interlocutor:   string;
    public isSending       = false;
    public isValid         = true;
    public messageGroups:  {
        messages:   Message[];
        date:       string;
    }[]             = [];
    public userInput:      string;
    public formatFullDate: string = AppValues.fullDatePattern;
    public minMessageCharacters: number = AppValues.MIN_MESSAGE_CHARACTERS;
    public maxMessageCharacters: number = AppValues.MAX_MESSAGE_CHARACTERS;

    private         componentDestroyed$:    Subject<boolean> = new Subject();
    private         THOUSAND                = 1000;

    constructor(
        private chatService:        ChatService,
        private datePipe:           DatePipe,
        private elementRef:         ElementRef,
        private route:              ActivatedRoute,
        private redirectService:    RedirectService,
        private errorService:       ErrorService,
        private modalService:       ModalService,
        private injector:           Injector,
        private translate:          TranslateService
    ) { }


    /**
     * @desc Calls to group messages, to scroll the page down. Gets the
     * current dialog from the service.
     */
    ngOnInit() {
        this._groupMessages( this.chatService.getCurrentDialog() );
        this._scrollDown();
        this.userInput = '';

        this.interlocutor = this.chatService.getCurrentChat().interlocutor_anonymized
            ? this.translate.instant('anonymized.user.name')
            : this.route.snapshot.queryParams['interlocutor'];

        this.chatService.getDialog()
            .takeUntil(this.componentDestroyed$)
            .subscribe((dialog: ChatInterface) => {
                this._groupMessages(dialog);
                this._scrollDown();
            });

        this.currentChat = this.chatService.getCurrentChat();
    }

    /**
     * List tracker. Watches list items by date.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {{messages: Message[]; date: string}} item
     * @return {string}
     */
    trackByDate(index: number, item: {
        messages:   Message[],
        date:       string;
    }): string {
        return item.date;
    }

    /**
     * Send message on pressing CTR keyL+Enter
     * @param event KeyboardEvent
     */
    public clickHandler(event: KeyboardEvent): void {
        if (this.isValid && event.keyCode === 13 && event.ctrlKey) {
            this.userInput = event.target['value'];
            this.sendMessage();
        }
    }

    public changeEventHandler($event: string[]): void {
        this.userInput = $event[1];
    }

    /**
     * Delegates sending a new message.
     */
    public sendMessage(): void {
        setTimeout(this.prepareAndSendMessage.bind(this));
    }

    public checkIsValid(isValid: boolean): void {
        this.isValid = isValid;
    }


    /**
     * @decs Sends a new message via the Service. Calls to group the messages and
     * to scroll the page down on response.
     * @private
     */
    private prepareAndSendMessage(): void {
        if (!this.userInput) return;

        const dialog: ChatInterface = this.addNewMessage(this.userInput);
        setTimeout(() => this.updateDialog(dialog));

        this.chatService.sendMessage(this.userInput.trim(), this.dialog.dialogID)
            .subscribe((chat: ChatInterface) => null, (err: ErrorInterface) => {
                this.updateDialog(this.removeNotSendingMessage(this.dialog));
            });
    }

    private updateDialog(dialog: ChatInterface): void {
        this.userInput  = '';
        this._groupMessages(dialog);
        this.dialog = this.chatService._sortMessages(dialog.messages, dialog.dialogID);
        this._scrollDown();
    }

    private removeNotSendingMessage(dialog: ChatInterface): ChatInterface {
        dialog.messages.filter((message: Message) => message.ID === '');

        return dialog;
    }
    private addNewMessage(message_text: string): ChatInterface {
        let dialog: ChatInterface = this.dialog;

        const newMessage: Message = {
            ID: "",
            date: Date.parse(new Date().toString()) / AppValues.THOUSAND,
            dialog_id: this.dialog.dialogID,
            read: true,
            sender_id: this.user_service.getUser().ID,
            sender_name: this.user_service.getUser().login,
            text: message_text.trim()
        };
        dialog.messages.push(newMessage);

        return dialog;
    }


    /**
     * Creates groups of messages by date.
     * @param dialog
     * @private
     */
    _groupMessages(dialog: ChatInterface): void {
        this.messageGroups  = [];
        this.dialog         = dialog;

        const messages      = this.dialog.messages;

        let currentDate: string;

        messages.forEach((msg: Message) => {
            this._formatTime(msg);

            if (msg['dateYMMMD'] !== currentDate) {
                currentDate = msg['dateYMMMD'];

                let group   = {
                    date:       msg['dateYMMMD'],
                    messages:   []
                };

                group.messages.push(msg);
                this.messageGroups.push(group as any);

            } else {
                let index = this.messageGroups.length - 1;
                this.messageGroups[index].messages.push(msg);
            }
        });
    }


    /**
     * Scrolls the page down in order to let the user see the latest message,
     * which appears at the bottom.
     * @private
     */
    _scrollDown(): void {
        setTimeout(() => {
            const elem = this.elementRef.nativeElement.querySelector('.chat-list');
            elem.scrollTop = elem.scrollHeight;
        });
    }


    /**
     * Transforms date fields from unix to human format with the date pipe.
     * @param msg
     * @private
     */
    _formatTime(msg: Message): void {
        let date = msg.date * this.THOUSAND;

        msg['dateYMMMD']    = this.datePipe.transform(date, AppValues.fullDatePattern);
        msg['dateHHmm']     = this.datePipe.transform(date, AppValues.timePattern);
    }

    goBack() {
        this.modalService.showSpinner();

        this.chatService.retrieveChat(this.dialog.dialogID)
            .subscribe(
               () => {
                   this.modalService.close();
                   this.redirectService.goBack();
               },
               (err: ErrorInterface) => {
                  this.modalService.close();
                  this.errorService.handleError(err);
               }
            );

    }

    /***
     * @desc This function creates 'user service' property on your service.
     * @return {UserService}
     */
    public get user_service(): UserService {
        return this.injector.get(UserService);
    }


    /**
     * Calls the service to stop the dialog polling when the page gets closed.
     */
    ngOnDestroy() {
        this.chatService.clearDialogPolling();
    }

}
