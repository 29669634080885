import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/of';

import { DataService }          from '../services/data.service';
import { ErrorInterface,
    ErrorService }              from '../services/error.service';
import { EventInterface }       from '../interfaces/market.interface';
import { ModalService }         from '../modal/modal.service';
import { UserService }          from '../user/user.service';
import {EventToCreate} from '../../../swagger-gen__output_dir/model/eventToCreate';
import {EventToUpdate} from '../../../swagger-gen__output_dir';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
/**
 * Serves requests to add\remove an event
 */
export class EventService {

    constructor(
        private dataService:            DataService,
        private errorService:           ErrorService,
        private modalService:           ModalService,
        private userService:            UserService,
        private translate:              TranslateService
    ) {}


    /**
     * Delegates request of adding an event to eventRequest.
     * @param body
     * @returns {Observable<{}>}
     */
    addEvent(body: EventToCreate): Observable<EventInterface | {}> {
        return this._eventRequest({body:  {event: body}, url: 'add_event'});
    }

    /**
     * Delegates request of update an event to eventRequest.
     * @param body
     * @returns {Observable<{}>}
     */
    updateEvent(body: EventToUpdate): Observable<EventInterface | {}> {
        return this._eventRequest({body, url: 'update_event'});
    }

    /**
     * Delegates request of add/update an event to DataService.
     * @param data
     * @returns {Observable<{}>}
     * @private
     */
    _eventRequest(data: {body: EventToUpdate | {event: EventToCreate}, url: string}): Observable<EventInterface | {}> {
        const token = this.userService.getUserSession().token;

        if (!token) return Observable.of({});

        this.modalService.showSpinner();

        return this.dataService.postData(data.url, data.body, {token})
            .do(() => {
                this.modalService.close();
            })
            .catch((err: ErrorInterface) => {
                return this.errorService.handleError(err);
            });
    }


    /**
     * Decorates `remove event` request with modal user confirmation.
     * @param body
     * @returns {Observable<R>|OperatorFunction<T, R|I>}
     */
    removeEvent(body: {}) {
        return Observable
            .fromPromise(
                this.modalService
                    .warning({
                        title:          this.translate.instant('product.editor.warning.remove.title'),
                        message:        this.translate.instant('product.editor.warning.remove.message'),
                        yesButtonText:  this.translate.instant('product.editor.warning.remove.confirm'),
                        noButtonText:   this.translate.instant('product.editor.warning.remove.reject'),
                        reverseButtons: true,
                    })
            )
            .switchMap((action: boolean) => action ? this._removeEvent(body) : Observable.of(false));
    }



    /**
     * Delegates request of removing an event to DataService.
     * @param body
     * @returns {Observable<{}>}
     */
    _removeEvent(body: {}) {
        const token = this.userService.getUserSession().token;

        if (!token) return Observable.throw({});

        return this.dataService.postData('delete_event', body, {token})
            .catch((err: ErrorInterface) => this.errorService.handleError(err))
            .map(() => true);
    }

}
