var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, QueryList, OnChanges, SimpleChanges, AfterViewInit, OnInit } from '@angular/core';
import { AgmInfoWindow } from '@agm/core';
import { MapSearchService } from './map-search.service';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { auditTime } from 'rxjs/operators';
var MapBoardComponent = /** @class */ (function (_super) {
    __extends(MapBoardComponent, _super);
    function MapBoardComponent(mapSearchService, translate) {
        var _this = _super.call(this) || this;
        _this.mapSearchService = mapSearchService;
        _this.translate = translate;
        _this.positionChangeEvent$ = new EventEmitter();
        _this.showDetailsEvent$ = new EventEmitter();
        _this.marketPin = '../assets/images/map_pin_market_icon.png';
        _this.sellerPin = '../assets/images/map_pin_user_icon.png';
        _this.mapStyles = [{
                "featureType": "poi.attraction",
                "stylers": [
                    { "visibility": "off" }
                ]
            },
            {
                "featureType": "poi.business",
                "stylers": [
                    { "visibility": "off" }
                ]
            },
            {
                "featureType": "poi.government",
                "stylers": [
                    { "visibility": "off" }
                ]
            },
            {
                "featureType": "poi.medical",
                "stylers": [
                    { "visibility": "off" }
                ]
            },
            {
                "featureType": "poi.park",
                "stylers": [
                    { "visibility": "off" }
                ]
            },
            {
                "featureType": "poi.place_of_worship",
                "stylers": [
                    { "visibility": "off" }
                ]
            },
            {
                "featureType": "poi.school",
                "stylers": [
                    { "visibility": "off" }
                ]
            },
            {
                "featureType": "poi.sports_complex",
                "stylers": [
                    { "visibility": "off" }
                ]
            }];
        return _this;
    }
    MapBoardComponent.prototype.ngOnInit = function () {
        this.timeDatesInfo = this.translate.instant("market.timeDates.info");
        this.subscribeOnCenterLocationChanges();
    };
    MapBoardComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.displayLocation = null;
        this.mapSearchService.resetCenterLocation();
    };
    /**
     * If no updates for @displayLocation, takes its previous value.
     * @param {SimpleChanges} changes
     */
    MapBoardComponent.prototype.ngOnChanges = function (changes) {
        if (!changes.displayLocation.currentValue) {
            this.displayLocation = changes.displayLocation.previousValue;
        }
    };
    /**
     * add data-test-id when the component is loaded
     */
    MapBoardComponent.prototype.ngAfterViewInit = function () {
        var container = document.getElementsByClassName("agm-map-container-inner")[0];
        if (container)
            container.setAttribute("data-test-id", "content-map");
    };
    /**
     * @emits showDetailsEvent$ on mapInfoWindow click to show a market or seller details.
     * @param {string} ID
     * @param {string} type
     */
    MapBoardComponent.prototype.showDetails = function (ID, type, data) {
        this.showDetailsEvent$.emit({
            ID: ID,
            type: type,
            currentPosition: this.currentPosition,
            data: data
        });
    };
    /**
     * This event is fired when the map becomes idle after panning or zooming.
     * @param {CenterLocationInterface} displayLocation
     */
    MapBoardComponent.prototype.mapReady = function (displayLocation) {
        var currentPosition = this.getCenter();
        if (currentPosition) {
            if (this._round(displayLocation.latitude) !== this._round(currentPosition.center.latitude)
                || this._round(displayLocation.longitude) !== this._round(currentPosition.center.longitude)) {
                this.positionChangeEvent$.emit(currentPosition);
            }
        }
        else {
            this.displayLocation = displayLocation;
            this.positionChangeEvent$.emit({ center: displayLocation });
        }
    };
    /**
     * @desc If the position of the map changed notably
     * @emits positionChangeEvent$.
     * @param {CenterLocationInterface} center
     */
    MapBoardComponent.prototype.onCenterChange = function (center) {
        this._createcurrentPositionObject();
        this.currentPosition = Object.assign(this.currentPosition, {
            center: {
                latitude: parseFloat(center['lat']),
                longitude: parseFloat(center['lng'])
            },
            zoom: this.data.position['zoom']
        });
        this.data = this.mapSearchService.getData();
        this.data.position['center'] = this.currentPosition.center;
    };
    MapBoardComponent.prototype.subscribeOnCenterLocationChanges = function () {
        var _this = this;
        this.trackSubscription(this.mapSearchService.centerLocationChanges.pipe(auditTime(100)).subscribe(function (centerLocation) {
            _this.positionChangeEvent$.emit(centerLocation);
        }));
    };
    /**
     * Method for get coordinates by center position
     * @returns {LocationInterface}
     */
    MapBoardComponent.prototype.getCenter = function () {
        return this.currentPosition;
    };
    MapBoardComponent.prototype._createcurrentPositionObject = function () {
        if (!this.currentPosition) {
            this.currentPosition = {
                center: {},
                zoom: this.data.position['zoom']
            };
        }
    };
    /**
     * Rounds the given number.
     * @param {number} x
     * @returns {number}
     * @private
     */
    MapBoardComponent.prototype._round = function (x) {
        return Math.round(x * 1000) / 1000;
    };
    /**
     * @emits positionChangeEvent$ on the map zoom change.
     * @param {number} zoom
     */
    MapBoardComponent.prototype.onZoomChange = function (zoom) {
        var center = this.getCenter() && this.getCenter().center;
        this.data = this.mapSearchService.getData();
        if (!center) {
            center = this.data.position['center'];
            this._createcurrentPositionObject();
        }
        this.currentPosition = Object.assign(this.currentPosition, {
            center: center,
            zoom: zoom
        });
        this.data.position['zoom'] !== zoom
            && this.positionChangeEvent$.emit(this.currentPosition);
    };
    /**
     * If you click on marker then close previous opened marker
     * @param infowindow
     */
    MapBoardComponent.prototype.clickedMarker = function (infowindow) {
        if (this.previous)
            this.previous.close();
        this.previous = infowindow;
    };
    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {MarketInterface | UserModel} item
     * @return {string}
     */
    MapBoardComponent.prototype.trackByID = function (index, item) {
        return item.ID;
    };
    return MapBoardComponent;
}(UnsubscribeOnDestroyAbsctractClass));
export { MapBoardComponent };
