import {Injectable} from '@angular/core';

import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';


import {MarketInterface} from '../interfaces/market.interface';
import {Router} from '@angular/router';
import {ItemsFromMarketResponse} from '../../../swagger-gen__output_dir/model/itemsFromMarketResponse';
import {Observable} from 'rxjs/Rx';
import {UserService} from '../user/user.service';
import {DataService} from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import {ModalService} from '../modal/modal.service';
import {SearchType, UrlBySearchNameInterface} from '../interfaces/search.interface';
import {CookieService} from '../services/cookie.service';
import {AppSearchService} from "../search/search.service";
import {Market} from "../../../swagger-gen__output_dir";
import {Subscription} from "rxjs/Subscription";

/**
   * Parameters for getSearchItemInMarket
   */
export interface GetSearchItemInMarketParams {

    /**
     * current session token
     */
    token: string;

    /**
     * market id
     */
    marketId: string;
}

@Injectable()
export class MarketService {



    market:     Market;
    list:       ItemsFromMarketResponse;

    constructor(
        private dataService: DataService,
        private errorService: ErrorService,
        private modalService: ModalService,
        private router:         Router,
        private searchService: AppSearchService,
        private cookieService: CookieService,
        private userService:    UserService
    ) {}


    public showDetails(market: Market): void {
        this.market = market;

        this.getSearchItemInMarketResponse(this.market.ID);
    }

    /**
   * @param params The `MarketService.GetSearchItemInMarketParams` containing the following parameters:
   *
   * - `token`: current session token
   *
   * - `market_id`: market id
   *
   * @return Success.
   */
  private getSearchItemInMarketResponse(marketId: string): Subscription | Observable<{}> {
      const token = this.userService.getUserSession().token || this.cookieService.getCookie('user');

      if (!token || !marketId) return Observable.of({});

      this.modalService.showSpinner();

      return this.dataService.getData(`search_item_in_market?market_id=${ marketId }`, {"token": token})
          .map((searchResult: ItemsFromMarketResponse) => {
              this.modalService.close();
              this.list = searchResult;

              this.cachingItemsForMarket(searchResult);

              return this.list;
          })
          .catch((err: ErrorInterface) => this.errorService.handleError(err))
          .subscribe(() => this.router.navigate(['/market'], {queryParams: {key: this.market.market_title}}));
    }

    private cachingItemsForMarket(searchResult: ItemsFromMarketResponse): void {
        this.searchService.setMarketProducts(searchResult, this.market.market_title);
        this.searchService.countSearchResult();

        const urlBySearchName: UrlBySearchNameInterface = this.getMarketUrl();
        this.searchService.addSearchDataToLocalCachingByUrl({url: urlBySearchName.fullUrl});

        const urlBySearchMapName: UrlBySearchNameInterface = this.searchService.getUrlBySearchName(this.market.market_title, '/map-search', SearchType.Market);
        this.searchService.addSearchDataToLocalCachingByUrl({
            url: urlBySearchMapName.fullUrl,
            searchType: SearchType.Market,
            searchResult: {markets: [this.market]}
        });
    }

    public getMarketUrl(): UrlBySearchNameInterface {
      return this.searchService.getUrlBySearchName(this.market.market_title);
    }

    public getShoppersFromMarket(): Market {
        return this.market;
    }

    public getItemsFromMarket(): ItemsFromMarketResponse {
        return this.list;
    }


}
