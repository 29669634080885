var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
/**
 * Checks if ulr has a protocol (http:// or https://).
 * In case false - adds 'https://' at the beginning of url.
 * @param {string} url
 * @returns {string}
 */
// export function normalizeUrl(url: string): string {
//    return AppValues.httpPattern.test(url) ? url : `https://${ url }`;
// }
export function fixedEncodeURIComponent(text) {
    return encodeURIComponent(text)
        .replace('%2C', ',')
        .replace(/[!'"/*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}
/**
 * @description Unsubscribes from all subscriptions in case list isn't empty.
 * @param { Subscription[] } subscriptions$
 */
export function unsubscribe(subscriptions$) {
    if (subscriptions$ && subscriptions$.length > 0) {
        subscriptions$.forEach(function (subscription) {
            subscription.unsubscribe();
        });
    }
}
/**
 * @description Completes all subjects in case list is defined.
 * @param { Subject<any>[] } subjects
 */
// tslint:disable-next-line: no-any
export function subjectsComplete(subjects$) {
    // tslint:disable-next-line: no-any
    subjects$.forEach(function (subject) {
        if (subject) {
            subject.complete();
        }
    });
}
var testParameter = function (name, filters) { return filters.some(function (filter) { return filter instanceof RegExp ? filter.test(name) : filter === name; }); };
var ɵ0 = testParameter;
var DATA_URL_DEFAULT_MIME_TYPE = 'text/plain';
var DATA_URL_DEFAULT_CHARSET = 'us-ascii';
export function normalizeDataURL(urlString, _a) {
    var stripHash = _a.stripHash;
    var match = /^data:(?<type>[^,]*?),(?<data>[^#]*?)(?:#(?<hash>.*))?$/.exec(urlString);
    // tslint:disable-next-line:indent
    if (!match) {
        // tslint:disable-next-line:indent
        throw new Error("Invalid URL: " + urlString);
    }
    var _b = match.groups, type = _b.type, data = _b.data, hash = _b.hash;
    var mediaType = type.split(';');
    hash = stripHash ? '' : hash;
    var isBase64 = false;
    if (mediaType[mediaType.length - 1] === 'base64') {
        mediaType.pop();
        isBase64 = true;
    }
    // Lowercase MIME type
    var mimeType = (mediaType.shift() || '').toLowerCase();
    var attributes = mediaType
        .map(function (attribute) {
        var _a = __read(attribute.split('=').map(function (string) { return string.trim(); }), 2), key = _a[0], _b = _a[1], value = _b === void 0 ? '' : _b;
        // Lowercase `charset`
        if (key === 'charset') {
            value = value.toLowerCase();
            if (value === DATA_URL_DEFAULT_CHARSET) {
                return '';
            }
        }
        return "" + key + (value ? "=" + value : '');
    })
        .filter(Boolean);
    var normalizedMediaType = __spread(attributes);
    if (isBase64) {
        normalizedMediaType.push('base64');
    }
    if (normalizedMediaType.length > 0 || (mimeType && mimeType !== DATA_URL_DEFAULT_MIME_TYPE)) {
        normalizedMediaType.unshift(mimeType);
    }
    return "data:" + normalizedMediaType.join(';') + "," + (isBase64 ? data.trim() : data) + (hash ? "#" + hash : '');
}
export function normalizeUrl(urlString, options) {
    var e_1, _a;
    options = __assign({ defaultProtocol: 'http:', normalizeProtocol: true, forceHttp: false, forceHttps: false, stripAuthentication: true, stripHash: false, stripTextFragment: true, stripWWW: true, removeQueryParameters: [/^utm_\w+/i], removeTrailingSlash: true, removeSingleSlash: true, removeDirectoryIndex: false, sortQueryParameters: true }, options);
    urlString = urlString.trim();
    // Data URL
    if (/^data:/i.test(urlString)) {
        return normalizeDataURL(urlString, options);
    }
    if (/^view-source:/i.test(urlString)) {
        throw new Error('`view-source:` is not supported as it is a non-standard protocol');
    }
    var hasRelativeProtocol = urlString.startsWith('//');
    var isRelativeUrl = !hasRelativeProtocol && /^\.*\//.test(urlString);
    // Prepend protocol
    if (!isRelativeUrl) {
        urlString = urlString.replace(/^(?!(?:\w+:)?\/\/)|^\/\//, options.defaultProtocol);
    }
    var urlObject = new URL(urlString);
    if (options.forceHttp && options.forceHttps) {
        throw new Error('The `forceHttp` and `forceHttps` options cannot be used together');
    }
    if (options.forceHttp && urlObject.protocol === 'https:') {
        urlObject.protocol = 'http:';
    }
    if (options.forceHttps && urlObject.protocol === 'http:') {
        urlObject.protocol = 'https:';
    }
    // Remove auth
    if (options.stripAuthentication) {
        urlObject.username = '';
        urlObject.password = '';
    }
    // Remove hash
    if (options.stripHash) {
        urlObject.hash = '';
    }
    else if (options.stripTextFragment) {
        urlObject.hash = urlObject.hash.replace(/#?:~:text.*?$/i, '');
    }
    // Remove duplicate slashes if not preceded by a protocol
    // NOTE: This could be implemented using a single negative lookbehind
    // regex, but we avoid that to maintain compatibility with older js engines
    // which do not have support for that feature.
    if (urlObject.pathname) {
        // TODO: Replace everything below with `urlObject.pathname = urlObject.pathname.replace(/(?<!\b[a-z][a-z\d+\-.]{1,50}:)\/{2,}/g, '/');` when Safari supports negative lookbehind.
        // Split the string by occurrences of this protocol regex, and perform
        // duplicate-slash replacement on the strings between those occurrences
        // (if any).
        var protocolRegex = /\b[a-z][a-z\d+\-.]{1,50}:\/\//g;
        var lastIndex = 0;
        var result = '';
        for (;;) {
            var match = protocolRegex.exec(urlObject.pathname);
            if (!match) {
                break;
            }
            var protocol = match[0];
            var protocolAtIndex = match.index;
            var intermediate = urlObject.pathname.slice(lastIndex, protocolAtIndex);
            result += intermediate.replace(/\/{2,}/g, '/');
            result += protocol;
            lastIndex = protocolAtIndex + protocol.length;
        }
        var remnant = urlObject.pathname.slice(lastIndex, urlObject.pathname.length);
        result += remnant.replace(/\/{2,}/g, '/');
        urlObject.pathname = result;
    }
    // Decode URI octets
    if (urlObject.pathname) {
        try {
            urlObject.pathname = decodeURI(urlObject.pathname);
        }
        catch (_b) { }
    }
    // Remove directory index
    if (options.removeDirectoryIndex === true) {
        options.removeDirectoryIndex = [/^index\.[a-z]+$/];
    }
    if (Array.isArray(options.removeDirectoryIndex) && options.removeDirectoryIndex.length > 0) {
        var pathComponents = urlObject.pathname.split('/');
        var lastComponent = pathComponents[pathComponents.length - 1];
        if (testParameter(lastComponent, options.removeDirectoryIndex)) {
            pathComponents = pathComponents.slice(0, -1);
            urlObject.pathname = pathComponents.slice(1).join('/') + '/';
        }
    }
    if (urlObject.hostname) {
        // Remove trailing dot
        urlObject.hostname = urlObject.hostname.replace(/\.$/, '');
        // Remove `www.`
        if (options.stripWWW && /^www\.(?!www\.)[a-z\-\d]{1,63}\.[a-z.\-\d]{2,63}$/.test(urlObject.hostname)) {
            // Each label should be max 63 at length (min: 1).
            // Source: https://en.wikipedia.org/wiki/Hostname#Restrictions_on_valid_host_names
            // Each TLD should be up to 63 characters long (min: 2).
            // It is technically possible to have a single character TLD, but none currently exist.
            urlObject.hostname = urlObject.hostname.replace(/^www\./, '');
        }
    }
    // Remove query unwanted parameters
    if (Array.isArray(options.removeQueryParameters)) {
        try {
            for (var _c = __values(__spread(urlObject.searchParams.keys())), _d = _c.next(); !_d.done; _d = _c.next()) {
                var key = _d.value;
                if (testParameter(key, options.removeQueryParameters)) {
                    urlObject.searchParams.delete(key);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    if (options.removeQueryParameters === true) {
        urlObject.search = '';
    }
    // Sort query parameters
    if (options.sortQueryParameters) {
        urlObject.searchParams.sort();
    }
    if (options.removeTrailingSlash) {
        urlObject.pathname = urlObject.pathname.replace(/\/$/, '');
    }
    var oldUrlString = urlString;
    // Take advantage of many of the Node `url` normalizations
    urlString = urlObject.toString();
    if (!options.removeSingleSlash && urlObject.pathname === '/' && !oldUrlString.endsWith('/') && urlObject.hash === '') {
        urlString = urlString.replace(/\/$/, '');
    }
    // Remove ending `/` unless removeSingleSlash is false
    if ((options.removeTrailingSlash || urlObject.pathname === '/') && urlObject.hash === '' && options.removeSingleSlash) {
        urlString = urlString.replace(/\/$/, '');
    }
    // Restore relative protocol, if applicable
    if (hasRelativeProtocol && !options.normalizeProtocol) {
        urlString = urlString.replace(/^http:\/\//, '//');
    }
    // Remove http/https
    if (options.stripProtocol) {
        urlString = urlString.replace(/^(?:https?:)?\/\//, '');
    }
    return urlString;
}
export { ɵ0 };
