var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef } from '@angular/core';
import { SearchListClass } from './search-list.class';
import { UserService } from '../../user/user.service';
import { Router } from '@angular/router';
import { ChatService } from '../../chat/chat.service';
var UsersListComponent = /** @class */ (function (_super) {
    __extends(UsersListComponent, _super);
    function UsersListComponent(elementRef, userService, router, chatService) {
        var _this = _super.call(this, elementRef) || this;
        _this.elementRef = elementRef;
        _this.userService = userService;
        _this.router = router;
        _this.chatService = chatService;
        return _this;
    }
    // Impersonation
    UsersListComponent.prototype.onClick = function (item) {
        var _this = this;
        this.userService.login_impersonated(item['ID']).subscribe(function () {
            _this.router.navigate(['/shopping-list']);
        });
    };
    return UsersListComponent;
}(SearchListClass));
export { UsersListComponent };
