import { Injector } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { PaymentIntentResponse, paymentRequest, StripeInterface, StripeCanMakePaymentResponse, elements, StripeError, } from './stripe.interface';
import { ModalService } from '../modal/modal.service';
import { WindowRefService } from '../services/window-ref.service';
import { Subject } from 'rxjs/Subject';
import AppValues from '../common/app.values';
import { ErrorInterface } from '../services/error.service';
import { PaymentsService } from '../../../swagger-gen__output_dir/api/payments.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { PayeeAccountView } from '../../../swagger-gen__output_dir/model/payeeAccountView';
import { UserService } from '../user/user.service';
import { UnsupportedDeviceService } from '../unsupported-info/unsupported-device.service';
import { getErrorType } from '../services/error.helpers';
var ClientPaymentService = /** @class */ (function () {
    function ClientPaymentService(modalService, injector, paymentsApiService, windowRef, translate) {
        this.modalService = modalService;
        this.injector = injector;
        this.paymentsApiService = paymentsApiService;
        this.windowRef = windowRef;
        this.translate = translate;
        this.canMakePaymentSubject = new Subject();
        this.successPaymentSubject = new Subject();
        this.errorPaymentSubject = new Subject();
        this.showModalUnsupportedAccountSubject = new Subject();
        this._window = windowRef.nativeWindow();
        this.stripe = this._window.Stripe(environment.STRIPE_PUBLISHABLE_KEY, { locale: 'en' });
        this.changePaymentRequest(this.cartService.total);
    }
    Object.defineProperty(ClientPaymentService.prototype, "stripePayment", {
        get: function () {
            return this.stripe;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientPaymentService.prototype, "stripePaymentRequest", {
        get: function () {
            return this.paymentRequest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientPaymentService.prototype, "totalAmount", {
        get: function () {
            return this.cartService.total;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientPaymentService.prototype, "cartService", {
        get: function () {
            return this.injector.get(CartService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientPaymentService.prototype, "userService", {
        get: function () {
            return this.injector.get(UserService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientPaymentService.prototype, "unsupportedDeviceService", {
        get: function () {
            return this.injector.get(UnsupportedDeviceService);
        },
        enumerable: true,
        configurable: true
    });
    ClientPaymentService.prototype.paymentRequestIntent = function () {
        return this.paymentsApiService.paymentsPaymentPost(this.userService.getToken(), AppValues.getDateNowISO8601());
    };
    ClientPaymentService.prototype.paymentsLoginLink = function () {
        return this.paymentsApiService.paymentsLoginLinkGet(this.userService.getToken());
    };
    ClientPaymentService.prototype.createPaymentRequest = function (amount) {
        if (amount === void 0) { amount = 1; }
        this.paymentRequest = this.stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Total',
                amount: amount,
            },
            requestPayerName: true,
            requestPayerEmail: false,
        });
    };
    ClientPaymentService.prototype.updatePaymentRequest = function (amount) {
        if (amount === void 0) { amount = 1; }
        this.paymentRequest.update({
            currency: 'usd',
            total: {
                label: 'Total',
                amount: amount,
            },
        });
    };
    ClientPaymentService.prototype.changePaymentRequest = function (amount) {
        var _this = this;
        if (amount === void 0) { amount = 1; }
        if (this.paymentRequest && amount !== 1) {
            this.updatePaymentRequest(amount);
        }
        else {
            this.createPaymentRequest(amount);
            this.paymentRequest.canMakePayment()
                .then(function (canMakePayment) {
                _this.canMakePaymentSubject.next(canMakePayment);
            });
        }
    };
    /**
     * @desc Callback when a payment method is created.
     */
    ClientPaymentService.prototype.paymentMethod = function () {
        var _this = this;
        this.paymentRequest.on('paymentmethod', function (eventPaymentMethod) {
            _this.confirmCardPaymentIntent(eventPaymentMethod);
        });
    };
    /**
     * @description Confirm the PaymentIntent with
     * the payment method returned from the payment request.
     * @param {paymentRequest.StripePaymentMethodPaymentResponse} eventPaymentMethod
     * @private
     */
    ClientPaymentService.prototype.confirmCardPaymentIntent = function (eventPaymentMethod) {
        var _this = this;
        this.stripe.confirmCardPayment(this.cartService.clientToken, {
            payment_method: eventPaymentMethod.paymentMethod.id,
        }).then(function (paymentIntent) {
            if (paymentIntent.error) {
                // Report to the browser that the payment failed.
                eventPaymentMethod.complete('fail');
                _this.errorPaymentModal(paymentIntent.error);
            }
            else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                eventPaymentMethod.complete('success');
                _this.paymentFlowWith3DSecure(paymentIntent);
            }
        });
    };
    /**
     * @desc Stripe.js handle the rest of the payment flow, including 3D Secure if needed.
     * @param {PaymentIntentResponse} paymentIntent
     */
    ClientPaymentService.prototype.paymentFlowWith3DSecure = function (paymentIntent) {
        var _this = this;
        if (paymentIntent.paymentIntent.status === "requires_action") {
            this.stripe.confirmCardPayment(this.cartService.clientToken).then(function (paymentResponse) {
                if (paymentResponse.error) {
                    _this.errorPaymentModal(paymentResponse.error);
                }
                else {
                    _this.successPaymentModal(paymentResponse);
                }
            });
        }
        else {
            // The payment has succeeded.
            this.successPaymentModal(paymentIntent);
        }
    };
    ClientPaymentService.prototype.onCanMakePaymentChanges = function () {
        return this.canMakePaymentSubject.asObservable();
    };
    ClientPaymentService.prototype.onSuccessPaymentChanges = function () {
        return this.successPaymentSubject.asObservable();
    };
    ClientPaymentService.prototype.onShowModalUnsupportedAccountChanges = function () {
        return this.showModalUnsupportedAccountSubject.asObservable();
    };
    ClientPaymentService.prototype.changePaymentResponse = function (paymentResponse) {
        this.successPaymentSubject.next(paymentResponse);
    };
    ClientPaymentService.prototype.changeShowStatusModalUnsupportedAccount = function (isShow) {
        this.showModalUnsupportedAccountSubject.next(isShow);
    };
    ClientPaymentService.prototype.onErrorPaymentChanges = function () {
        return this.errorPaymentSubject.asObservable();
    };
    ClientPaymentService.prototype.changeErrorPayment = function (error) {
        this.errorPaymentSubject.next(error);
    };
    /**
     * Payment with Card Element
     * @param {elements.Element} card
     * @param {string} name
     */
    ClientPaymentService.prototype.confirmCardPayment = function (card, name) {
        var _this = this;
        this.stripe.confirmCardPayment(this.cartService.clientToken, {
            payment_method: {
                card: card,
                billing_details: {
                    name: name
                },
            },
        }).then(function (result) {
            if (result.error) {
                _this.errorPaymentModal(result.error);
            }
            else {
                _this.successPaymentModal(result);
            }
        });
    };
    /**
     * @desc Get information about the status of the payee's account
     * to notify the merchant about the need to create and / or fill out the payee's account.
     * The user will be recognized by the authorization data.
     * If successful, the PayeeAccountView object will be returned.
     */
    ClientPaymentService.prototype.unsupportedAccount = function () {
        var _this = this;
        this.paymentsApiService.paymentsPayeeAccountGet(this.userService.getToken()).subscribe(function (payeeAccountView) {
            if (payeeAccountView.status === PayeeAccountView.StatusEnum.NotOnboarded ||
                payeeAccountView.status === PayeeAccountView.StatusEnum.NotCreated) {
                _this.changeShowStatusModalUnsupportedAccount(true);
                _this.createOnboardingLink(_this.userService.isImpersonation);
            }
        }, function (err) {
            _this.changeShowStatusModalUnsupportedAccount(false);
        });
    };
    /**
     * @desc UIR_PROFILE1 System shall show badge on Profile and Inventory list pages
     * of Buyer&Seller BiziBAZA account if Stripe account is in NOT_ABLE_TO_RECEIVE_TRANSFERS state.
     * Table 2.4.9 - Profile badge text for not Stripe account that is in NOT_ABLE_TO_RECEIVE_TRANSFERS state
     */
    ClientPaymentService.prototype.checkStatusOfStripeAccount = function () {
        var _this = this;
        this.paymentsApiService.paymentsPayeeAccountGet(this.userService.getToken()).subscribe(function (payeeAccountView) {
            if (payeeAccountView.status !== PayeeAccountView.StatusEnum.Enabled) {
                _this.unsupportedDeviceService.unsupportedAccount({
                    htmlText: _this.translate.instant('stripe.statusAccount.message'),
                    modalContainerClass: 'top-modal__container',
                    modalContentClass: 'top-modal__content'
                });
            }
        }, function (err) { return err; });
    };
    /**
     * @desc System shall show badge on Shopping list page
     * after login for Buyer&Seller BiziBAZA account
     * if Stripe account is in NOT_CREATED or NOT_ONBOARDED state.
     *
     * Create a payee account, if it has not been created yet,
     * and get a hyperlink to be redirected to fill it out.
     * The user will be recognized by the authorization data.
     * On success, an OnboardingLinkView object will be returned.
     * @private
     */
    ClientPaymentService.prototype.createOnboardingLink = function (isAdmin) {
        var _this = this;
        this.unsupportedDeviceService.unsupportedAccount({
            message: this.translate.instant('paymentsPayeeAccount.modal.message'),
            yesButtonText: this.translate.instant('paymentsPayeeAccount.modal.yesButton'),
            noButtonText: this.translate.instant(isAdmin ? 'settings.stop.impersonating' : 'paymentsPayeeAccount.modal.logoutButton'),
            modalContentClass: "modal__content__unsupported-account"
        }).then(function (action) {
            if (action) {
                _this.paymentsApiService.paymentsPayeeAccountPost(_this.userService.getToken())
                    .subscribe(function (payeeAccount) {
                    _this.reCreateOnboardingModal(isAdmin);
                    _this._window.open(payeeAccount.link, "_self");
                }, function (err) { return _this.showModalError(getErrorType(err)); });
            }
            else {
                _this.userService.logout().subscribe(function (res) { return res; });
            }
        });
    };
    ClientPaymentService.prototype.showModalError = function (errorText) {
        this.modalService.error({
            title: this.translate.instant('common.alert.title'),
            message: errorText,
            yesButtonText: this.translate.instant('common.ok')
        });
    };
    ClientPaymentService.prototype.reCreateOnboardingModal = function (isAdmin) {
        this.createOnboardingLink(isAdmin);
    };
    ClientPaymentService.prototype.successPaymentModal = function (successPayment) {
        var _this = this;
        this.modalService.success({
            title: this.translate.instant('payments.stripe.successPaymentModal.title'),
            message: this.translate.instant('payments.stripe.successPaymentModal.message'),
            yesButtonText: this.translate.instant('payments.stripe.successPaymentModal.yesButton')
        }).then(function (action) {
            _this.changePaymentResponseAndRefreshCart(successPayment);
        });
    };
    // TODO Need add tests after demo 30.07.2021
    ClientPaymentService.prototype.changePaymentResponseAndRefreshCart = function (successPayment) {
        var _this = this;
        this.cartService.getCart().subscribe(function (shoppingCart) {
            _this.cartService.resetAndRefreshAbandonedNotify();
            _this.changePaymentResponse(successPayment);
        });
    };
    ClientPaymentService.prototype.errorPaymentModal = function (error) {
        this.modalService.warning({
            title: this.translate.instant('payments.stripe.errorPaymentModal.title'),
            message: error.message,
            yesButtonText: this.translate.instant('payments.stripe.errorPaymentModal.yesButton')
        });
        this.changeErrorPayment(error);
    };
    return ClientPaymentService;
}());
export { ClientPaymentService };
