import { Injectable }               from '@angular/core';

import AppValues                    from '../common/app.values';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {CategoryInterface} from "../interfaces/category.interface";
import {Event} from "../../../swagger-gen__output_dir";
import {AvailableEventTotalQtyInterface, CartItemWithMarketInterface} from "../interfaces/cart.interface";


@Injectable()
export class ItemService {
    public category:       CategoryInterface[];
    public isExpired:      boolean;
    public isSale:         boolean;
    public isPrice:        number;
    public isUnitsName:    string;


    /**
     * @desc check if this product in expired
     * @param {SellingItemResponseBody | any} item
     * @returns {boolean}
     */
    public isExpiredFn(item: SellingItemResponseBody): boolean {
        return this.isExpired = item.validTill < AppValues.getDateNow();
    }

    /**
     * @desc check if this product is draft
     * @param {SellingItemResponseBody | any} item
     * @returns {boolean}
     */
    public isDraftFn(item: SellingItemResponseBody): boolean {
        const dateNow: number = AppValues.getDateNow();
        return item.valitFrom > dateNow || item.draft;
    }


    /**
     * @desc check if this product is on sale
     * @param {SellingItemResponseBody | any} item
     * @returns {boolean}
     */
    public isSaleFn(item: SellingItemResponseBody): boolean {
        const dateNow: number = AppValues.getDateNow();
        return this.isSale = item.saleIsOn
            && item.saleDateFrom < dateNow && item.saleDateTill > dateNow;
    }


    /**
     * @desc Set price for the items
     * @param {SellingItemResponseBody | any} item
     * @returns {number}
     */
    public itemPriceFn(item: SellingItemResponseBody): number {
        this.isSaleFn(item);
        return this.isPrice = this.isSale && item.salePrice ? item.salePrice : item.price;
    }


    /**
     * @desc Set UnitsName for items
     * @param {SellingItemResponseBody | any} item
     * @returns {string}
     */
    public priceUnitsNameFn(item: SellingItemResponseBody): string {
        this.isSaleFn(item);
        return this.isUnitsName = this.isSale && item.saleUnitName ? item.saleUnitName : item.priceUnitsName;
    }

    /**
     * cached all category and subcategory after
     * each request 'get_navigation_tree'
     * @param categoryList
     */
    public setCategory(categoryList: CategoryInterface[]): void {
        this.category = categoryList;
    }

    public availableEventTotalQty(product: CartItemWithMarketInterface, qty: number): AvailableEventTotalQtyInterface {
        let available_qty: number = 0;
        let eventTotalQty: number = 0;
        if (product.events.length) {
            product.events.forEach((productEvent: Event) => {
                eventTotalQty += productEvent.item.qty;
            });
            available_qty = product.qty - eventTotalQty;
        }
        return {
            available_qty,
            isAvailableQty: qty <= available_qty,
            isProductWithEvents: product.events.length > 0
        };
    }

}
