/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./market-header.component";
import * as i3 from "@angular/common";
var styles_MarketHeaderComponent = [i0.styles];
var RenderType_MarketHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MarketHeaderComponent, data: {} });
export { RenderType_MarketHeaderComponent as RenderType_MarketHeaderComponent };
export function View_MarketHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "nav", [["class", "app-header__container marker-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "nav__link"], ["data-test-id", "backLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "navbar__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["data-test-id", "marketTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "navbar__right"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.itemTitle; _ck(_v, 7, 0, currVal_0); }); }
export function View_MarketHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "market-header", [], null, null, null, View_MarketHeaderComponent_0, RenderType_MarketHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.MarketHeaderComponent, [i3.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MarketHeaderComponentNgFactory = i1.ɵccf("market-header", i2.MarketHeaderComponent, View_MarketHeaderComponent_Host_0, {}, { backEvent$: "backEvent$" }, []);
export { MarketHeaderComponentNgFactory as MarketHeaderComponentNgFactory };
