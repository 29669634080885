import { Component,
    ElementRef,
    Input, Output,
    EventEmitter }          from '@angular/core';

import { ModalService }     from '../../modal/modal.service';
import { EditableInterface }     from '../../interfaces/media-editor.interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector:   'media-editor',
    styleUrls:  ['media-editor.sass'],

    template:   `
        <div class="me__container">

            <img class="me__img" [src]="imageUrl">

            <input class="me__text" role="editable"
                    data-test-id="text"
                    [placeholder]="placeholder"
                    [value]="text"
                    type="text" [ngClass]="{ none : !text }"
                    (blur)="finishRenaming({event: $event, isInput: true})"
                    (focus)="startRenaming({event: $event, isInput: true})"/>

        </div>
    `
})
export class MediaEditorComponent {
    /**
     * Editable text node with an icon.
     * @desc Accepts inputs:
     * - imageUrl for the icon;
     * - text for the text node, represented as H3 tag;
     * - regExp to validate the text node content.
     * @emits changeEvent$ on blur.
     */

    @Input()    imageUrl:       string;
    @Input()    text:           string;
    @Input()    placeholder:    string;
    @Input()    regExp:         RegExp;
    @Input()    minMaxLength:   number[];
    @Input()    isEmpty:        boolean;
    @Input()    errorMessage:   string;
    @Output()   changeEvent$    = new EventEmitter<EditableInterface>();

    elementRef:     ElementRef;
    savedText:      string;
    modalService:   ModalService;
    translate:      TranslateService;
    textElem:       HTMLElement;
    message:    string;
    constructor(
        elementRef:     ElementRef,
        modalService:   ModalService,
        translate:      TranslateService,
    ) {
        this.elementRef     = elementRef;
        this.modalService   = modalService;
        this.translate      = translate;
    }


    /**
     * Fires on focus event, saves text content before it is touched.
     * @param event
     */
    startRenaming(params: {event: any, isInput?: boolean}) {
        params.event.preventDefault();
        params.event.stopPropagation();
        const elem = params.event.target as any;

        this.savedText = this.text;
        this.textElem  = this.elementRef.nativeElement.querySelector('[role="editable"]');

        if (!params.isInput) {
            this._moveCaret(elem);
        }
    }


    /**
     * Moves caret to the end of the line.
     * @private
     * @param elem
     */
    _moveCaret(elem: HTMLElement) {
        const range = document.createRange();
        range.selectNodeContents(elem);
        range.collapse(false);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }

    /**
     * * Fires on blur event.
     * @desc Returns if the text hasn't changed. Fires modal warning if Regex test fails and
     * recovers text content.
     * @emits changeEvent$ on correct text.
     * @param {Event} event
     * @param {string} message
     */
    finishRenaming(params: {event: any, message?: string, isInput?: boolean}) {
        params.event.preventDefault();
        params.event.stopPropagation();

        this.textElem.blur();

        let text: string;

        if (!params.isInput) {
            text = ( params.event.target['value'] || params.event.target['textContent'] || '').trim();
            if (text) {
                params.event.target['textContent'] = text;
            }
        } else {
            text = params.event.target['value'].trim();
        }

        if (text === this.savedText) return;

        if (this.isEmpty && !text ) {
            this.saveValue(text);
            return;
        }

        if (!this._isValid(text)) {
            this.modalService.error({
                title:          this.translate.instant('modal.alert.title'),
                message:        this.errorMessage 
                                    ? this.translate.instant(this.errorMessage) 
                                    : this.translate.instant('productEditor.error.invalidProductStringFieldValues', 
                                    {invalid_fields: params.message || 'Fields'}),
                yesButtonText:  this.translate.instant('modal.alert.confirm'),
            });

            this.savedText = text;
            this.textElem.innerText = this.savedText;

            return;
        }

        this.saveValue(text);
    }

    saveValue(value: string) {
        this.changeEvent$.emit({
            role: this.elementRef.nativeElement.getAttribute('role'),
            value: value
        });
        this.savedText = null;
    }


    /**
     * Does value match RegEx and length between minMaxValues?
     * @private
     * @param value
     * @returns {boolean}
     */
    _isValid(value: string): boolean {
        let isValid: boolean = this.regExp.test(value);
        if (isValid && this.minMaxLength) {
            isValid = value.length >= this.minMaxLength[0] && value.length <= this.minMaxLength[1]
        }
        return isValid;
    }
}
