import { UserModel } from "./user.interface";
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { Market } from '../../../swagger-gen__output_dir';
import { CenterLocationInterface } from './location.interface';


export interface SearchOptionsInterface {
    keyWord?:   string;
    sortBy?:    string;
    type?:      SearchType;
    map?:       boolean;
}

export interface SearchDataInterface {
    isComponentInit: boolean;
    newCoords?: CenterLocationInterface;
    isNotCached?: boolean;
    page?: number;
}


export interface SearchResultInterface {
    keyWord:    string;
    type:       SearchType;
    entryId?:   string;
    foundNum:   number;
    data:       SearchResponseInterface;
    marketList: boolean;
    title?:     string;
}


export interface SearchResponseInterface {
    items?:         SellingItemResponseBody[];
    nearest_items?: SellingItemResponseBody[];
    users?:         UserModel[];
    sellers?:       UserModel[];
    markets?:       Market[];
}

export interface MapSearchResultsContent {
   isComponentInit: boolean;
   query?: string;
   onlyMarkets?: boolean;
   onlySellers?: boolean;
}

export interface NestedMarket extends SellingItemResponseBody {
   distance?: number;
}

export interface SearchResultItems extends SellingItemResponseBody {
   nestedMarkets?: NestedMarket[];
}

export interface CachedSearchResultInterface extends SearchResultInterface{
    is_key_search: boolean;
    userLocation: CenterLocationInterface;
}

export interface CurrentSearchUrlInterface {
    root: string;
    navigationExtras?: { queryParams: { [key: string]: string } };
}
export interface UrlBySearchNameInterface {
    fullUrl: string;
    currentUrl: CurrentSearchUrlInterface;
}

export enum SearchType {
    Item = 'item',
    Active = 'active',
    Event = 'event',
    Market = 'market',
    Seller = 'seller',
    User = 'user',
    Sale = 'sale'
}

export enum RequestTypesDictionary {
    Item = 'search_item',
    Active = 'get_active_items',
    Event = 'search_market',
    Market = 'search_market',
    Seller = 'search_nearby',
    User = 'search_seller',
    Sale = 'search_item',
}