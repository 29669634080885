var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TranslateService } from '@ngx-translate/core';
import { DynamicComponent } from './dynamic.component';
var ModalDialogComponent = /** @class */ (function (_super) {
    __extends(ModalDialogComponent, _super);
    function ModalDialogComponent(translate) {
        var _this = _super.call(this) || this;
        _this.translate = translate;
        return _this;
    }
    ModalDialogComponent.prototype.onClick = function (action, locationBack) {
        this.context._close(locationBack);
        this.context.handler(action);
    };
    ModalDialogComponent.prototype.hideAll = function () {
        this.context.title === this.translate.instant('modal.search.title') && this.context._close();
    };
    return ModalDialogComponent;
}(DynamicComponent));
export { ModalDialogComponent };
