import {OnInit} from '@angular/core';
import {BlankComponent} from '../modal/blank.component';
import {UnsupportedOrientationComponent} from './unsupported-orientation.component';
import {UnsupportedAccountComponent} from './unsupported-account';

export enum UnsupportedModalStatusType {
    warning = 'warning',
    error = 'error',
    ok = 'ok'
}

export abstract class DynamicAbstractClass implements OnInit {
    public context: UnsupportedModalArgumentsComponent;
    public title: string = '';
    public modalContentClass: string = 'modal__content';
    public status: UnsupportedModalStatusType = UnsupportedModalStatusType.warning;


    public ngOnInit(): void {
        if (this.context) {
            this.status = this.context.status;
            this.title = this.context.title;
            if (this.context.modalContentClass) {
                this.modalContentClass = this.context.modalContentClass;
            }
        }
    }
}

export interface UnsupportedModalArgumentsComponent {
    status?: UnsupportedModalStatusType;
    message?: string;
    htmlText?: string;
    modalContainerClass?: string;
    modalContentClass?: string;
    title?: string;
    yesButtonText?: string;
    noButtonText?: string;
    lastButtonText?: string;
    tooLastButtonText?: string;
    bigTextBlock?: boolean;

    handler?(action: boolean | string): void;
    _close?(locationBack: boolean): void;
}

export interface UnsupportedModalCommandInterface {
    component:  typeof BlankComponent | typeof UnsupportedOrientationComponent | typeof UnsupportedAccountComponent;
    context?:   UnsupportedModalArgumentsInterface;

}
export interface UnsupportedModalArgumentsInterface extends UnsupportedModalArgumentsComponent{
    locationBack?:      boolean;
    component?:         typeof BlankComponent | typeof UnsupportedOrientationComponent | typeof UnsupportedAccountComponent;
}
