import { Component, EventEmitter,
    Output}                             from '@angular/core';

import {Location} from '@angular/common';


@Component({
    selector:   'profile-header',
    styleUrls:  ['../../assets/styles/app-header.sass'],
    template:   `
        <header class="app-header">
            <nav class="app-header__container">
                <div class="navbar__left">
                    <a (click)="location.back()" data-test-id="shoppingLink">
                        <i class="icon-custom_back_icon"></i>
                    </a>
                </div>

                <div class="navbar__center" role="profile">
                    <h4>{{ "profile.your.profile" | translate }}</h4>
                </div>

                <div class="navbar__right"></div>
            </nav>
        </header>
    `
})

export class ProfileHeaderComponent {
    public location: Location;
    constructor(location: Location) {
        this.location = location;
    }
}
