
        <header class="app-header">
            <nav class="app-header__container" role="cart">
                <div class="navbar__left">
                    <a data-test-id="shoppingLink" (click)="clickAnyButtons($event.target.name)"
                       class="nav__link" name="shopping_cart_cancel_button"><i class="icon-custom_back_icon"></i></a>
                </div>

                <div class="navbar__center nav__link relative" role="cart">
                    <i class="icon-cart_icon"></i>
                    <div class="badge"><span class="relative">{{count}}</span></div>
                </div>

                <div class="navbar__right"></div>
            </nav>
        </header>
    