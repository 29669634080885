/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./addr-editor-header.component";
var styles_AddrEditorHeaderComponent = [];
var RenderType_AddrEditorHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddrEditorHeaderComponent, data: {} });
export { RenderType_AddrEditorHeaderComponent as RenderType_AddrEditorHeaderComponent };
export function View_AddrEditorHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "nav", [["class", "app-header__container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "a", [["class", "nav__link"], ["data-test-id", "profileLink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["role", "addr"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "navbar__center"], ["role", "addr"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(11, 0, null, null, 4, "div", [["class", "navbar__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 3, "a", [["class", "nav__link"], ["data-test-id", "addNewAddressBtn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDone() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 2, "span", [["role", "addr"]], null, null, null, null, null)), (_l()(), i0.ɵted(14, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("addr.editor.header.cancel")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform("addr.editor.header.shipping.address")); _ck(_v, 9, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform("addr.editor.header.done")); _ck(_v, 14, 0, currVal_2); }); }
export function View_AddrEditorHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "addr-editor-header", [], null, null, null, View_AddrEditorHeaderComponent_0, RenderType_AddrEditorHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.AddrEditorHeaderComponent, [], null, null)], null, null); }
var AddrEditorHeaderComponentNgFactory = i0.ɵccf("addr-editor-header", i2.AddrEditorHeaderComponent, View_AddrEditorHeaderComponent_Host_0, {}, { submit: "submit", canDeactivate: "canDeactivate" }, []);
export { AddrEditorHeaderComponentNgFactory as AddrEditorHeaderComponentNgFactory };
