import {
    AfterContentInit, ChangeDetectionStrategy,
    Component, ElementRef, Injector, OnDestroy,
    OnInit
} from '@angular/core';
import { ActivatedRoute }               from '@angular/router';
import {Observable, Subject} from 'rxjs';

import { InventoryListService }         from './inventory-list.service';
import { ProductEditorService }         from '../product-editor/product-editor.service';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {ModalService} from '../modal/modal.service';

@Component({
    selector:   'inventory-list',
    styleUrls:  ['./inventory-list.sass'],

    template:   `
        <div class="component">
            <inventory-header></inventory-header>

            <div class="abstract-list__container component__container">
                <ul *ngIf="isArrayItems(items) && items?.length !== 0">
                    <div *ngFor="let item of items; let i = index; ">
                        <inventory-list-item
                            (showItemEvent$)="onShow(item)"
                            [item]="item"
                            [index]="i"
                            (removeItemEvent$)="onRemove(item)">

                        </inventory-list-item>
                    </div>
                </ul>
                <h3 *ngIf="isArrayItems(items) && items?.length === 0" class="empty-list">
                    {{ "inventoryList.empty" | translate }}
                </h3>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.Default
})

export class InventoryListComponent implements OnInit, AfterContentInit, OnDestroy {
    /**
     * @desc Fetches inventory list data via InventoryListService and
     * renders it agregating InventoryListItemComponents in its template.
     * Item click causes delegation to the service to showing the selected item.
     */
    private componentDestroyed$:    Subject<boolean> = new Subject();

    public category:           boolean;
    public inventoryList:      any;
    public items:              SellingItemResponseBody[] = [];
    public timeout             = null;
    public loadItems           = 3;
    public countOfItems        = 0;

    constructor(
        public elementRef:              ElementRef,
        private activatedRoute:         ActivatedRoute,
        private inventoryListService:   InventoryListService,
        private productEditorService:   ProductEditorService,
        private injector:               Injector,
    ) {
    }


    /**
     * ID tracker for ngFor directive.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {SellingItemResponseBody} item
     * @return {string}
     */
    // trackByID(index: number, item: SellingItemResponseBody): string { return item.ID; }

    public isArrayItems(items: any): boolean {
        return items && Array.isArray(items) && typeof items[0] === 'object';
    }


    /**
     * @desc Fetches inventory list data via InventoryListService.
     */
    ngOnInit() {
        this.category = true;

        this.inventoryListService.restoreList();
        this._takeUnitSubItem(this.inventoryListService.getInventItems()).subscribe((s) => {
            this.items = s;
        });

        // remove for fast performance
        // this.items = this._takeUnitSubItem(this.inventoryListService.getList());

        this.inventoryListService.startPolling(this.category);

    }

    // For fast view rendering
    // like as virtual loading
    generateList(items: SellingItemResponseBody[]) {
        this.items = items;
    }


     /**
     * @desc Fetches inventory list data via InventoryListService.
     */
    public ngAfterContentInit(): void {
        setTimeout(() => {
            this.updateItems();

        });
    }

    public updateItems(): void {
        this._takeUnitSubItem(this.inventoryListService.getList()).subscribe((s) => {
            this.items = s;
        });

        this.items = this.inventoryListService.getCurrentInventoryItems();
    }


    /**
     * Method for Subscribing Declaratively with takeUntil
     * @param method
     * @returns {Subscription}
     * @private
     */
    _takeUnitSubItem(method: any) {
        return method.takeUntil(this.componentDestroyed$)
            .map((items: SellingItemResponseBody[]) => {
                this.generateList(items);
                return items;
            });
    }

    /**
     * Delegates to Inventory List Service.
     * @param item
     */
    onShow(item: SellingItemResponseBody): void {
        this.productEditorService.showProductDetails(item);
    }



    /**
     * Delegates to Inventory List Service.
     * @param item
     */
    public onRemove(item: SellingItemResponseBody): void {
        this.inventoryListService.removeItemDialog()
            .subscribe((action: boolean) => {
                if (action) {
                    this.inventoryListService.removeProduct(action, item)
                        .subscribe((items: SellingItemResponseBody[]) => {
                            this.generateList(items);
                        });
                }
            });
    }

    public get modalService(): ModalService {
        return this.injector.get(ModalService);
    }


    /**
     * OnDestroy Inventory List Service.
     * Unsubscribe for inventoryList.
     */
    ngOnDestroy() {
        if (this.componentDestroyed$) {
            this.componentDestroyed$.unsubscribe();
        }
        this.category = false;
        this.inventoryListService.startPolling(this.category);
    }

}
