import { Response }  from '@angular/http';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { Router }                   from '@angular/router';

import { Observable }               from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import {Subject, Subscriber} from 'rxjs';

import { CookieService } from '../services/cookie.service';
import { DataService }                  from '../services/data.service';
import { GoogleAnalyticsService }       from '../services/google-analytics.service';
import { ModalService }                 from '../modal/modal.service';
import {
    AddressInterface, LoginResponseInterface,
    UserBaseInterface,
    UserLoginInterface,
    UserLoginResponseInterface,
    UserModel,
    UserModelWithLocationInterface,
    UserRequestModel,
    UserSessionInterface
} from '../interfaces/user.interface';
import { ErrorInterface, ErrorService } from '../services/error.service';
import {Address, GetCounterResponse} from '../../../swagger-gen__output_dir';
import AppValues from '../common/app.values';
import {TranslateService} from '@ngx-translate/core';
import {CartService} from '../cart/cart.service';
import {AppFooterService} from '../app-footer/app-footer.service';
import {HttpResponseBase} from "@angular/common/http/src/response";
import { getErrorType } from '../services/error.helpers';

@Injectable()
export class UserService {

    private redirectUrl:            string;
    private token:                  string;

    // tslint:disable-next-line:member-ordering
    private user:                   UserModelWithLocationInterface | any = {};
    // tslint:disable-next-line:member-ordering
    private adminUser:              UserModel | any = {};
    private adminToken:             string;
    private impersonationToken:     string;
    private userChanges$:                   Subject<UserModel> = new Subject<UserModel>();
    private loginGuestAutoActionSubject:    Subject<UserModel> = new Subject<UserModel>();
    private onLogoutUserSubject:            Subject<boolean> = new Subject<boolean>();
    private resetAbandonedItemsSubject:      Subject<{}> = new Subject<{}>();

    // tslint:disable-next-line:member-ordering
    public impersonation:           UserModel | any = {};
    public userAuthEvent$:          EventEmitter<{}>;
    public userSession:             UserSessionInterface | {} = {};
    public loggedUserFromComponent: boolean;
    public entryWithOldLoginGuest:  boolean;

    constructor(
        private cookieService:  CookieService,
        private dataService:    DataService,
        private errorService:   ErrorService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private modalService:   ModalService,
        private translate:      TranslateService,
        private injector:       Injector,
    ) {
        this.userAuthEvent$ = new EventEmitter();
    }

    /**
     * @desc Checks user mode and if it's 'guest' - show warning modal
     * @return {boolean}
     */
    public get isGuestMode(): boolean {
       const userMode: boolean = this.isGuestUser;
       userMode && this.errorService.handleError({
            status: 401,
            statusText: this.translate.instant('unauthorized.message.body')
        });

       return userMode;
    }

    public get isGuestUser(): boolean {
        return this.user.accessLevel === 0;
    }

    public get loginName(): string {
        return this.isGuestUser ? '' : this.getUser().login;
    }

    public isSellerMode(): boolean {
        return this.user.accessLevel > 1;
    }

    public isAdminMode(): boolean {
        return this.user.accessLevel === 3;
    }

    public get onUserChanges(): Observable<UserModel> {
       return this.userChanges$.asObservable();
    }
    public get onLoginGuestChanges(): Observable<UserModel> {
        return this.loginGuestAutoActionSubject.asObservable();
    }

    public onLogoutUserChanges(): Observable<boolean> {
        return this.onLogoutUserSubject.asObservable();
    }
    public onResetAbandonedItemsSubjectChanges(): Observable<{}> {
        return this.resetAbandonedItemsSubject.asObservable();
    }

    public login_guest(loginFromNotStartApp?: boolean): Observable<UserRequestModel | {}> {
        return this.dataService.postData('login_guest', {}).map((res: UserRequestModel) => {
            this.setCookies('guest', res.token);

            this._setNewUser(res, false);
            this.userAuthEvent$.emit(this.userSession);

            loginFromNotStartApp && this.router.navigate(['/shopping-list']);

            return res;
        }).catch((err: ErrorInterface) => {
            return this.modalService
            .warning({
                title: 'Error,',
                message: getErrorType(err),
                yesButtonText: 'Close',
            });
        });
    }

    public _login(fullUrl: string, data: {}, keepLogged: boolean, redirect_with_login?: boolean): Observable<UserLoginResponseInterface | ErrorInterface> {
        this.token = this.cookieService.getCookie('user');

        const request = fullUrl === 'login'
            ? this.dataService.postData(fullUrl, data, {token: this.token})
            : this.dataService.postData(fullUrl, data);

        return request.map((res: UserRequestModel) => {
            !redirect_with_login && this._reportLoginSuccess(res, keepLogged);

            let response: LoginResponseInterface = {
                logged: true,
                message: ''
            };

            // setTimeout(() =>
            // window.location.pathname !== '/cart-report' && this.router.navigate(['/shopping-list'])
            // );

            this.user = res.user;
            this.token = res.token;

            this.userChanges$.next(this.user);

            this._setNewUser(res, false);

            if (this.loggedUserFromComponent) {
                this.clearCookies();
            }

            this.cookieService.setCookie('type', '');
            keepLogged && this.setCookies(this.typeOfUser(), this.token);

            this.googleAnalyticsService.handleAccessLevelOfUser(this.user.accessLevel, this.user.login);

            this.emitUserAuthEvent(response.logged);

            // observer.next(response);
            // observer.complete();
            return response;

        }).catch((err: ErrorInterface) => {
            if (err.status === 0) { err.statusText = 'Check the Internet connection'; }

            this._reportLoginError(data, err);

            return Observable.throwError(err);

            // observer.error(err);
            // You don't need to call complete, because it won't
            // do anything anyway because you called error already.
        });
    }

    public loggedUserFromComponentSetter(status: boolean) {
        this.loggedUserFromComponent = status;
    }

    public _catchRequestError(err: ErrorInterface) {
        if (err.status === 0) { err.statusText = 'Check the Internet connection'; }

        return this.modalService.error({
            title: 'Error:',
            message: getErrorType(err),
            yesButtonText: 'Close',
        });
    }

    public login(creds: UserLoginInterface, keepLogged: boolean) {
        return this._login('login', creds, keepLogged);
    }

    public loginWithToken(isRedirectWithLogin?: boolean): Observable<UserLoginResponseInterface | ErrorInterface> {
        const user: string = this.cookieService.getCookie('user');
        const paymentToken: string = this.cookieService.getCookie('payment_token');

        if (!user && !paymentToken) {
            this.googleAnalyticsService.handleLogOut('logout', 'success', '');
            return Observable.of({logged: true, message: ''});
        }

      //   const keepLoggedCookie = this.cookieService.getCookie('keep_logged');

        const token: string = user ? user : paymentToken;

        const queryString: string = 'login_by_token';

        return this._login(
           queryString,
           { token: token || 'fakeToken' },
           this.entryWithOldLoginGuest,
           isRedirectWithLogin
        );
    }

    public logoutModal(event: MouseEvent): void {
        this.modalService
            .warning({
                title:          this.translate.instant('logout.modal.title'),
                message:        this.logoutTextMessage,
                yesButtonText:  this.translate.instant('logout.modal.yes'),
                noButtonText:   this.translate.instant('logout.modal.no'),
                reverseButtons: true,
            })
            .then((action: boolean) => {
                event && action && this.logout().subscribe((res: {}) => res, (err: ErrorInterface) => err);
                event = null;
            });
    }

    public deleteAccountModal(event: MouseEvent): void {
        this.modalService
        .warning({
            title:          this.translate.instant('common.alert.title'),
            message:        this.deleteAccountTextMessage,
            yesButtonText:  this.translate.instant('common.delete'),
            noButtonText:   this.translate.instant('common.cancel'),
        })
        .then((action: boolean) => {
            if (event && action) {
                this.deleteAccount().subscribe(
                    (res: {}) => this.deleteAccountSuccess(),
                    (err: ErrorInterface) => this.deleteAccountError(err));
            }
        });
    }
    private deleteAccountSuccess(): void {
        this.modalService.success({
            title: this.translate.instant('common.success.title'),
            message: this.translate.instant('settings.deleteAccount.success.message'),
            yesButtonText: this.translate.instant('common.ok'),
        }).then(() => this.handleLogoutSuccess());
    }
    private deleteAccountError(error: ErrorInterface): void {
        this.modalService.error({
            title: this.translate.instant('modal.error.title'),
            message: error.body_error_text || getErrorType(error),
            yesButtonText: this.translate.instant('common.cancel'),
        }).then(() => this.handleLogoutError(error, false));
    }

    public get logoutTextMessage(): string {
        if (this.isImpersonation) {
            return this.translate.instant('logout.impersonating.modal.body');
        }

        return this.translate.instant('logout.modal.body');
    }

    public get deleteAccountTextMessage(): string {
        return this.translate.instant('settings.deleteAccount.confirmation.message');
    }

    public logout(): Observable<{}> {
        return this.dataService.postData('logout', {}, {token: this.token})
            .map(
                () => {
                    this.handleLogoutSuccess();
                    return {};
                },
                (err: ErrorInterface) => {
                    this.handleLogoutError(err);
                    return err;
                }
            );
    }

    public deleteAccount(): Observable<{}> {
        return this.dataService.deleteData('user', {token: this.token})
        .map(
            () => {
                return {};
            },
            (err: ErrorInterface) => {
                return err;
            }
        );
    }

    private handleLogoutSuccess(): void {
        this.onLogoutUserSubject.next(true);

        this._reportLogoutSuccess(this.user, 'logout');
        this.cart_service.resetAbandonedItems();

        this.consequenceAfterLogout(true);
    }

    private handleLogoutError(err: ErrorInterface, logoutAnyway: boolean = true): void {
        this.onLogoutUserSubject.next(false);

        this._reportLogoutError(this.user, err);

        this.consequenceAfterLogout(true, logoutAnyway);
    }

    public consequenceAfterLogout(loginFromNotStartApp?: boolean, logoutAnyway: boolean = true): void {
        if (Object.keys(this.impersonation).length === 0) {
            if (logoutAnyway) {
                this.clearUser();
                this.login_guest(loginFromNotStartApp).subscribe((res: UserRequestModel) => {
                    this.loginGuestAutoActionSubject.next(res.user);
                });
            }
        } else this._logoutForImpersonation().subscribe();
    }

    /**
     * @desc This function creates router property on your service.
     *
     * The problem is that Router can async load some routes.
     * This is why it needs Http.
     * Your Http depends on Router and Router depends on Http.
     * Angular injector is not able to create any of these services.
     * I had similar problems and one of the solutions can be injecting
     * Injector instead of service and getting service afterwards.
     * @return {Router}
     */
    public get router(): Router {
        return this.injector.get(Router);
    }

     /***
     * @desc This function creates 'cart service' property on your service.
     * @return {CartService}
     */
    public get cart_service(): CartService {
        return this.injector.get(CartService);
    }

    public _logoutForImpersonation(): Observable<{}> {
        this._clearImpersonate(this.impersonation);
        this._setNewUser();

        this.userAuthEvent$.emit(this.userSession);
        // this.userAuthEventImpersonation$.emit(this.impersonation);

        this.router.navigate(['/users-list']);

        return Observable.of({});
    }

    public logoutFromAll(event: MouseEvent): void {
        this.modalService
            .warning({
                title:          this.translate.instant('logout.error.cancel.title'),
                message:        this.translate.instant('logout.error.cancel.message'),
                yesButtonText:  this.translate.instant('logout.error.cancel.confirm'),
                noButtonText:   this.translate.instant('logout.error.cancel.reject'),
            })
            .then((action: boolean) => {
                event && action && this._logoutFromAll();
                event = null;
            });
    }

    public _logoutFromAll(): void {
        this.dataService.postData('logout_from_all', {}, { token: this.token })
            .subscribe(
                () => {
                    // @ts-ignore
                    this._reportLogoutSuccess(this.user, 'logout_from_all');
                    this.clearUser();
                },
                (err: ErrorInterface) => {
                    // @ts-ignore
                    this._reportLogoutError(this.user, err);
                    this.clearUser(err);
                }
            );
    }

    public clearUser(err?: ErrorInterface): void {
        // @ts-ignore
        this.user = {};

        this.clearCookies();
        this.emitUserAuthEvent();

        err && this.errorService.handleError(err);
    }

    public typeOfUser(): string {
        return this.cookieService.getCookie('type');
    }

    public signup(user: UserBaseInterface): Observable<HttpResponseBase | {}> {

        return this.dataService.postData('sign_up', user, {token: this.token})
            .map((res: HttpResponseBase) => {
                this._reportSignupSuccess(user.login);

                const response: HttpResponseBase | {} = Object.assign({}, res);

                if (/^2/.test(String(res.status))) {
                    response['signed'] = true;
                    // @ts-ignore
                    this.user = user;

                    this.userChanges$.next(this.user);
                }
                return response;
            }, (err: ErrorInterface) => {
                return err;
            });

    }

    public login_impersonated(userId: string): Observable<null> {
        return new Observable((observer: Subscriber<null>) => {
            this.dataService.postData('login_impersonated', {'user_id': userId}, {token: this.token})
                .subscribe(
                    (res: UserRequestModel) => {
                        this._setNewUser(res, true);

                        this.userAuthEvent$.emit(this.userSession);
                        // this.userAuthEvent$.emit(this.impersonation);

                        observer.next();
                        observer.complete();
                    },
                    (err: ErrorInterface) => {
                        return this._catchRequestError(err);
                    }
                );
        });

    }

    public _setNewUser(result?: UserRequestModel, impersonation?: boolean): void {
        if (!result) { result = { token: this.adminToken, user: this.adminUser}; }

        if ( impersonation ) {
            this.impersonationToken = result.token;
            this.impersonation = result.user;
        } else {
            this.adminToken = result.token;
            this.adminUser = result.user;
        }

        this.token = result.token;
        this.user = result.user;
        this.userChanges$.next(this.user);

        this.userSession = {
                firstName: result.user.firstName,
                lastName: result.user.lastName,
                ID: result.user.ID,
                login: result.user.login,
                token: result.token
        };
    }

    public _clearObject(userObj: UserModel): void {
        for (const prop of Object.getOwnPropertyNames(userObj)) {
          delete userObj[prop];
        }
    }

    public get isImpersonation(): boolean {
        return !AppValues.isEmpty(this.impersonation) && this.impersonationToken !== '';
    }

    public _clearImpersonate( obj: UserModel): void {
        this.impersonationToken = '';
        this._clearObject(obj);
    }

    public _reportLogoutSuccess(data: UserLoginInterface, request: string): void {
        const value: string = data['login'] + ' ' + request;
        this.googleAnalyticsService.handleLogOut('logout', 'success', value);
    }

    public _reportLogoutError(data: UserLoginInterface, err: ErrorInterface): void {
        const description: string =  data['login'] + ' logout error ' + err.status + ' ' + getErrorType(err);
        this.googleAnalyticsService.handleErrorEvent( 'logout', 'logout', description);
    }

    public _reportLoginSuccess(response: UserRequestModel, keepLogged: boolean): void {
        if (response['user'].accessLevel !== 0) {
            const value: string = response['user'].login + ' '
                + (keepLogged && keepLogged === true ? 'login with token' : 'login');
            this.googleAnalyticsService.handleLogin('login', 'success', value);
        }
    }

    public _reportLoginError(data: {}, err: ErrorInterface): void {
        const description: string = (data['login'] ? 'login' : 'login with token')
            + ' error ' + err.status + ' ' + getErrorType(err);
        this.googleAnalyticsService.handleErrorEvent( 'login', 'login', description);
    }

    public _reportSignupSuccess(login: string): void {
        this.googleAnalyticsService.handleSignUp('sign_up', 'success', login);
    }

    public _reportSignupError(err: ErrorInterface): void {
        const description: string = 'sign_up error ' + err.status + ' ' + getErrorType(err);
        this.googleAnalyticsService.handleErrorEvent('sign_up', 'sign_up', description);
    }

    public changePassword(passObj: {}): Observable<{}> | Observable<never> {
        return this.dataService.postData('change_password', passObj, { token: this.token });
    }

    public resetPassword(email: string): Observable<{}> | Observable<never> {
        return this.dataService.postData('reset_password', { email }, { token: this.token });
    }

    public sendVerification(email: string): Observable<{}> | Observable<never> {
        return this.dataService.postData('resend_verification_email', { email }, { token: this.token });
    }

    public getTemporaryLoginLink(email: string): Observable<{}> | Observable<never> {
        return this.dataService.postData('remind_login', { email }, { token: this.token });
    }

    public emitUserAuthEvent(isLogged?: boolean): void {
        const user: UserModel = this.user;

        if (isLogged) {
            this.userSession = {
                firstName:  user.firstName,
                lastName:   user.lastName,
                ID:         user.ID,
                login:      user.login,
                token:      this.token
            };
        } else {
            this.userSession = {};
        }

        this.userAuthEvent$.emit(this.userSession);
    }

    public setRedirectUrl(url: string): void {
        this.redirectUrl = url;
    }

    public get getRedirectUrl(): string {
        return this.redirectUrl;
    }

    public getUser() {
        return this.user;
    }
    public get isUserAvailable(): boolean {
        return this.user && typeof this.user !== 'undefined';
    }

    public primaryAddress(): Address {
        const userAddresses: AddressInterface[] = this.getUser().addresses;

        return AppValues.getPrimaryAddress(userAddresses);
    }

    public updateUser(user: UserModel): void {
        this.user = user;
        this.userChanges$.next(this.user);
    }

    public getToken(): string {
        return this.userSession['token'] || this.cookieService.getCookie('user');
    }

    public getUserSession(): UserSessionInterface {
        return this.userSession;
    }

    private clearUserCookies(): void {
        this.cookieService.deleteCookie('user');
        this.cookieService.deleteCookie('type');
    }

    private clearCookies(): void {
        this.clearUserCookies();
    }

    private setCookies(type: string, token: string): void {
        this.cookieService.setCookie('type', type);
        this.cookieService.setCookie('user', token);
    }

}
