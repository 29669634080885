import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {Router, RoutesRecognized} from '@angular/router';
import 'rxjs/add/observable/throw';

import {AppSettings} from '../common/app.settings';
import {DataService} from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import {ModalService} from '../modal/modal.service';
import {
    CachedSearchResultInterface,
    CurrentSearchUrlInterface,
    SearchResponseInterface,
    SearchResultInterface,
    SearchType,
    UrlBySearchNameInterface
} from '../interfaces/search.interface';
import {ShoppingListItemInterface} from '../shopping-list/shopping-list.interface';
import {ItemsFromMarketResponse} from '../../../swagger-gen__output_dir/model/itemsFromMarketResponse';
import {UserService} from '../user/user.service';
import {CenterLocationInterface} from '../interfaces/location.interface';
import {GoogleAnalyticsService} from '../services/google-analytics.service';
import {SellingItemResponseBody} from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {
    CacheInterface,
    LocalCaching,
    LocalCachingCreatorMapping
} from '../local-caching/local-caching-factory';
import {GeoLocationService} from '../services/geolocation.service';
import {SearchService} from '../../../swagger-gen__output_dir/api/search.service';
import {fixedEncodeURIComponent} from "../common/app.helpers";
import {Market} from "../../../swagger-gen__output_dir";
import {TranslateService} from "@ngx-translate/core";
import {ProductDetailsService} from "../product-details/product-details.service";

@Injectable()
export class AppSearchService {

    foundNum: number = 0;
    isNew: boolean  = true;
    keyWord: string = '';
    entryId: string;
    results:        SearchResponseInterface = {};
    type            = SearchType.Item;
    market:         Market;
    isMarketList      = false;
    list:           ItemsFromMarketResponse;
    userLocation:   CenterLocationInterface;
    search_from:    string;
    is_key_search:  boolean;
    prevUrl:        string = '';

    private count:      number;
    private title: string;

    constructor(private dataService:        DataService,
                private googleAnalyticsService: GoogleAnalyticsService,
                private errorService:       ErrorService,
                private modalService:       ModalService,
                private productDetailsService: ProductDetailsService,
                private router:             Router,
                private userService:        UserService,
                private localCaching:       LocalCaching,
                private geoLocationService: GeoLocationService,
                private translate:          TranslateService,
                private searchService:      SearchService) {
        this.count = AppSettings.SEARCH_COUNT;

        this.geoLocationService.locationEvent$.subscribe((res: CenterLocationInterface) => {
            this.setUserLocation(res);
        });

        this.router.events
            .filter((evt: any) => evt instanceof RoutesRecognized).pairwise()
            .subscribe((events: RoutesRecognized[]) => {
                if (events[0].urlAfterRedirects && events[0].urlAfterRedirects !== '') {
                    this.prevUrl = events[0].urlAfterRedirects;
                }
            });
    }



    cachedData(): CachedSearchResultInterface {
        let cached: CachedSearchResultInterface = this.localCaching.getOneCache(LocalCachingCreatorMapping.ConcreteSearchCaching, {});

        if (Object.keys(cached).length > 0) {
         this.setSearchDataFromCache(cached);
        }

        return cached;
    }

    getLastSuccessSearchData(): CachedSearchResultInterface {
        let cachedList: CacheInterface[] = this.localCaching.getAllCache(LocalCachingCreatorMapping.ConcreteSearchCaching);

        // @ts-ignore
        let cachedData: CachedSearchResultInterface = cachedList.filter((cachedSearchResult: CachedSearchResultInterface) => cachedSearchResult.data['keyWord'] !== '')[0].data;

        this.setSearchDataFromCache(cachedData);

        return cachedData;
    }


    setSearchDataFromCache(cachedData: CachedSearchResultInterface): void {
        if (Object.keys(cachedData).length) {
            this.foundNum = cachedData.foundNum;
            this.keyWord = cachedData.keyWord;
            this.results = cachedData.data;
            this.type = cachedData.type;
            this.entryId = cachedData.entryId;
            this.isMarketList = cachedData.marketList;
            this.is_key_search = cachedData.is_key_search;
            this.userLocation = cachedData.userLocation;
        }
    }

    public getUniqSearchItemFromCache(url: string): any {
        const cacheItems: CacheInterface[] = this.localCaching.getAllCache(LocalCachingCreatorMapping.ConcreteSearchCaching);
        cacheItems.filter((cacheItem: CacheInterface) => cacheItem.url === url);
        if (cacheItems) {
            return cacheItems[0].data;
        }
        return {};
    }


    public clearResults(type: SearchType = SearchType.Item, isMarketList?: boolean) {
        this.foundNum = 0;
        this.keyWord = '';
        this.entryId = '';
        this.results = {};
        this.isMarketList = isMarketList || false;
        this.type = type;
    }


    getResults(type?: SearchType) {
        return {
            foundNum: this.foundNum,
            keyWord: this.keyWord,
            data: this.results,
            marketList: this.isMarketList,
            type: type || this.type,
            entryId: this.entryId,
            userLocation: this.userLocation,
            title: this.title
        };
    }

    public setTitle(title: string): void {
        this.title = title;
    }

    public isAdminMode(): boolean {
        return this.userService.isAdminMode();
    }

    /**
     * get userLocation method
     * @returns {CenterLocationInterface}
     */
    userGeolocation(): CenterLocationInterface {
        return this.userLocation;
    }

    public setUserLocation(coords: CenterLocationInterface): void {
        this.userLocation = coords;
    }


    setFalseMarketFlag(type?: SearchType, isMarketList?: boolean) {
        this.isMarketList = isMarketList || false;
        this.type = type;
    }

    public setMarketProducts(marketProducts: ItemsFromMarketResponse, marketName: string): void {
        this.results = marketProducts;
        this.keyWord = marketName;
        this.type = SearchType.Active;
        this.isMarketList = false;
        this.isNew = true;
        this.is_key_search = false;
    }

    getActiveItems(item: ShoppingListItemInterface) {
        this.results = {} as any;
        this.keyWord = item.name;
        this.entryId = item.subcategory_entry_id;
        this.type = SearchType.Active;
        this.isMarketList = false;
        this.isNew = true;

        this.productDetailsService.setProductDetailsFrom('shopping_list');

        this.modalService.showSpinner();


        const token = this.userService.getUserSession().token;
        if (!token || !item) return Observable.of({});

        return this.dataService.getData(`get_active_items?entry_id=${item.subcategory_entry_id}&page=1&count=${this.count}`, {token})
            .map((res: SearchResponseInterface) => {
                this.is_key_search = false;
                return res;
            })
            .subscribe((res) => {
                this._handleSearchResult(res);
                const urlBySearchName: UrlBySearchNameInterface = this.getUrlBySearchName(item.sub_category_name || item.name);
                this.addSearchDataToLocalCachingByUrl({url: urlBySearchName.fullUrl});

                this.router.navigate([urlBySearchName.currentUrl.root], urlBySearchName.currentUrl.navigationExtras);

            }, (err: ErrorInterface) => this.errorService.handleError(err));
    }


    public getUrlBySearchName(name: string, root: string = '/search', key: string = 'key'): UrlBySearchNameInterface {
        const currentUrl: CurrentSearchUrlInterface = {
            root: root,
            navigationExtras: {queryParams: {[key]: name}}
        };
        const newUrl: string = fixedEncodeURIComponent(name);

        return {
            fullUrl: window.location.origin + currentUrl.root + '?' + key + '=' + newUrl,
            currentUrl: currentUrl
        };
    }

    public addSearchDataToLocalCachingByUrl(data: {url: string, searchResult?: SearchResponseInterface, searchType?: SearchType}): void {
        if (data.searchResult) {
            this._handleSearchResult(data.searchResult);
        }
        if (data.searchType) {
            this.type = data.searchType;
        }
        this.addToLocalCaching(data.url);
    }


    getActiveItemsInMarket(market: Market, results: any) {
        this.results = {} as any;
        this.keyWord = market.market_title;
        this.type = SearchType.Active;
        this.isNew = true;
        this.isMarketList = true;

        const token = this.userService.getUserSession().token;

        if (!token || !market.ID) return Observable.of({});

        this.modalService.showSpinner();
        this._handleSearchResult(results);
        this.is_key_search = false;
        this.router.navigate(['/search'], {queryParams: {key: market.market_title}});
    }


    public setOptions(keyWord?: string, type?: SearchType, options: any = {}): void {
       if (options.isNew || keyWord !== this.keyWord || type !== this.type) {
            this.results = {} as any;
            this.keyWord = keyWord;
            this.type = type;
            this.isMarketList = false;
        }
    }


    public search(keyWord?: string, type?: SearchType, options: any = {}): Observable<SearchResultInterface> {
        this.setOptions(keyWord, type, options);

        this.isNew = options.isNew;
        this.modalService.showSpinner();

        return this.searchRequestHelper(options['offset'])
            .map((res: SearchResponseInterface) => this._handleSearchResult(res))
            .catch((err: ErrorInterface) => {
                if (err.status === 400) err.statusText = this.translate.instant('search.input.error');

                return this.errorService.handleError(err);
            });
    }

    private searchRequestHelper(offset = 1): Observable<SearchResponseInterface> {
        const token = this.userService.getUserSession().token;
        const latitude: number = this.getLatitude();
        const longitude: number = this.getLongitude();

        if (this.type === SearchType.Event || this.type === SearchType.Market) {
            return this.searchService.searchMarketGet(token, this.keyWord, latitude, longitude, 1, this.count);
        } else {
            return this.dataService.getData(this._defineQuery(offset), {token});
        }
    }

    selectBySearchType(init_search: boolean, result: SearchResultInterface | any) {
        if ( init_search === true && this.search_from && this.search_from !== '') {
            this.googleAnalyticsService.handleForSearch(result.keyWord);
            // if(this.is_key_search === true)
            this.googleAnalyticsService.handleForSearchByType(this.search_from, result.keyWord, result.foundNum);
        }
    }

    public getSearchFrom(): void {
        // window.location.search.indexOf('?key=') !== 0
        if (this.getResults().type !== SearchType.Active && window.location.pathname === '/search') this.search_from = 'key_word';
        else if (this.getResults().type === SearchType.Active && window.location.search.indexOf('?key=') === 0 ) this.search_from = 'shopping_list';
        else this.search_from = '';
    }

    /**
     * request generation
     * @param {number} offset
     * @returns {string}
     * @private
     */
    private _defineQuery(offset = 1): string {
        const latitude: number = this.getLatitude();
        const longitude: number = this.getLongitude();

        switch (this.type) {

            case SearchType.Seller:
                return `search?key_word=${this.keyWord}&type=${this.type}&lat=${latitude}&lon=${longitude}&count=${this.count}&offset=${offset}`;

            case SearchType.User:
                return `search?key_word=${this.keyWord}&type=${this.type}&lat=0&lon=0&count=${this.count}&offset=${offset}`;

            default:
                return `search?key_word=${this.keyWord}&type=${this.type}` +
                    `&lat=${latitude}&lon=${longitude}&count=${this.count}&offset=${offset}`;

        }
    }

    private getLatitude(): number {
        return !this.userLocation ? this.userService.getUser().latitude : this.userLocation.latitude;
    }
    private getLongitude(): number {
        return !this.userLocation ? this.userService.getUser().longitude : this.userLocation.longitude;
    }


    public _handleSearchResult(result: SearchResponseInterface): SearchResultInterface {
        this.modalService.close();

        // TODO: BIZ-2367: this._updateResult(this._filterZeroQty(result));
        this._updateResult(result);
        this.countSearchResult();

        let data_object = {
            foundNum: this.foundNum,
            keyWord: this.keyWord,
            data: this.results,
            type: this.type,
            marketList: this.isMarketList || false
        };

        this.selectBySearchType(true, data_object);

        return data_object;
    }

    public addToLocalCaching(currentUrl?: string): void {
        const data_object: CachedSearchResultInterface = {
            foundNum: this.foundNum,
            keyWord: this.keyWord,
            data: this.results,
            type: this.type,
            entryId: this.entryId,
            marketList: this.isMarketList || false,
            userLocation: this.userLocation,
            is_key_search: this.is_key_search,
            title: this.title
        };

        this.localCaching.setCache(LocalCachingCreatorMapping.ConcreteSearchCaching, data_object, currentUrl);
    }

    // tslint:disable-next-line: no-any
    public getLastSearchResult(): any {
        return this.localCaching.getOneCache(LocalCachingCreatorMapping.ConcreteSearchCaching, {});
    }


    _filterZeroQty(obj: SearchResponseInterface) {
        if (this.type !== 'item') return obj;

        for (let key in obj) if (obj.hasOwnProperty(key)) {
            obj[key] = obj[key].filter((item: SellingItemResponseBody) => item.current_quantity > 0);
        }

        return obj;
    }


    public countSearchResult(): void {
        this.foundNum = 0;
        const obj = this.results;

        for (let key in obj) if (obj.hasOwnProperty(key)) {
            this.foundNum += obj[key].length;
        }
    }


    _updateResult(res: SearchResponseInterface) {
        const obj = this.results;

        // the search query is new
        if (this.isNew || !Object.keys(obj).length) {
            this.results = res;
            return;
        }

        // enrich searchResult with newly fetched
        for (let key in obj) if (obj.hasOwnProperty(key)) {
            obj[key] = obj[key].concat(res[key]);
        }
    }

    get isTypeActive(): boolean {
        return this.type === 'active';
    }

}
