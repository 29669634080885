import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import { AppFooterService } from '../app-footer/app-footer.service';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { ModalService } from '../modal/modal.service';
import { ProductDetailsService } from '../product-details/product-details.service';
import { UserService } from '../user/user.service';
import { ChatService } from '../chat/chat.service';
import { AnalyticsService } from '../../../swagger-gen__output_dir';
import { OrdersService } from '../../../swagger-gen__output_dir/api/orders.service';
import AppValues from '../common/app.values';
var OrdersAppService = /** @class */ (function () {
    function OrdersAppService(appFooterService, chatService, dataService, errorService, modalService, productDetailsService, userService, analyticsService, ordersService) {
        this.appFooterService = appFooterService;
        this.chatService = chatService;
        this.dataService = dataService;
        this.errorService = errorService;
        this.modalService = modalService;
        this.productDetailsService = productDetailsService;
        this.userService = userService;
        this.analyticsService = analyticsService;
        this.ordersService = ordersService;
        this.isOpenedHistoryFromReview = false;
        this.selectedOrderId = '';
        this.mode = this.appFooterService.getMode();
    }
    OrdersAppService.prototype.getBuyingHistory = function () {
        var _this = this;
        var token = this.userService.getUserSession().token;
        this.modalService.showSpinner();
        if (token) {
            return this.analyticsService.getBuyingHistoryGet(token)
                .do(function (res) {
                _this.modalService.close();
                _this.buyhistory = res;
            })
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({});
    };
    OrdersAppService.prototype.getSellingHistory = function () {
        var _this = this;
        var token = this.userService.getUserSession().token;
        this.modalService.showSpinner();
        if (token) {
            return this.analyticsService.getSellingHistoryGet(token)
                .do(function (res) {
                _this.modalService.close();
                _this.buyhistory = res;
            })
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({});
    };
    OrdersAppService.prototype.getDuty = function () {
        var _this = this;
        var token = this.userService.getUserSession().token;
        this.modalService.showSpinner();
        if (token) {
            return this.ordersService.pendingOrdersGet(token)
                .map(function (res) {
                _this.modalService.close();
                _this.orders = res.orders;
                return _this.orders;
            })
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({});
    };
    OrdersAppService.prototype.sortItems = function (items) {
        return items.sort(function (itemA, itemB) {
            return new Date(itemB.order_datetime).getTime() - new Date(itemA.order_datetime).getTime();
        });
    };
    OrdersAppService.prototype.deliveryElements = function (orderId, orderElementsIds) {
        var _this = this;
        var token = this.userService.getUserSession().token;
        if (token) {
            // @ts-ignore
            return this.ordersService.completeOrderElementsPost({ order_id: orderId, order_elements_ids: orderElementsIds }, token)
                .map(function () {
                _this.orders = _this.removeOrder(_this.orders, orderId, orderElementsIds);
                return _this.orders;
            })
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({});
    };
    // tslint:disable-next-line:variable-name
    OrdersAppService.prototype.removeOrder = function (orders, order_id, order_elements_ids) {
        var _this = this;
        var changedOrders = this.setDeliveryFlag(this.orders, order_id, order_elements_ids);
        changedOrders = changedOrders.filter(function (o) { return !_this.isAllDeliveryItems(o); });
        return changedOrders;
    };
    OrdersAppService.prototype.isAllDeliveryItems = function (order) {
        var lengthOfElem = order.elements.length;
        var lengthOfCompletedElem = order.elements.filter(function (e) { return e.is_completed; }).length;
        return lengthOfElem === lengthOfCompletedElem;
    };
    // tslint:disable-next-line:variable-name
    OrdersAppService.prototype.setDeliveryFlag = function (orders, order_id, order_elements_ids) {
        var _this = this;
        var changedOrders = AppValues.deepCopy(orders);
        changedOrders.forEach(function (order) {
            if (order_id === order.order_id) {
                order.elements.forEach(function (e) {
                    order_elements_ids.forEach(function (id) {
                        if (id === e.ID) {
                            e.is_completed = true;
                        }
                    });
                });
                if (_this.isAllDeliveryItems(order)) {
                    order.is_completed = true;
                }
            }
        });
        return changedOrders;
    };
    OrdersAppService.prototype.showProductDetails = function (id, options) {
        this.productDetailsService.setProductDetailsFrom('analytics');
        return this.productDetailsService.showProductDetails(id, options)
            // tslint:disable-next-line:no-any
            .toPromise().then(function (res) { return res; }, function (err) { return err; });
    };
    OrdersAppService.prototype.openChat = function (buyerId) {
        this.chatService.goToDialog(buyerId);
    };
    OrdersAppService.prototype.getDialogList = function () {
        this.chatService.init();
    };
    OrdersAppService.prototype.stopGetDialogPolling = function () {
        this.chatService.stopPolling();
    };
    OrdersAppService.prototype.orderTotalPrice = function (order, discount) {
        var totalSum = 0;
        order.elements.forEach(function (transaction) {
            totalSum += (transaction.price) * transaction.qty;
        });
        return this.totalSum(totalSum, discount);
    };
    OrdersAppService.prototype.transactionDiscount = function (orders) {
        var totalSum = 0;
        orders.forEach(function (order) {
            totalSum += order.discount;
        });
        return totalSum;
    };
    OrdersAppService.prototype.transactionTotalPrice = function (orders, discount) {
        var _this = this;
        var totalSum = 0;
        orders.forEach(function (order) {
            totalSum += _this.orderTotalPrice(order, order.discount);
        });
        return this.totalSum(totalSum);
    };
    OrdersAppService.prototype.totalSum = function (sum, discount) {
        return sum - (discount || 0);
    };
    OrdersAppService.prototype.isPickUp = function (address) {
        return !AppValues.isEmpty(address);
    };
    OrdersAppService.prototype.formatPickUpAddress = function (address, emptyAddress) {
        if (emptyAddress === void 0) { emptyAddress = 'None'; }
        if (this.isPickUp(address)) {
            return AppValues.getStringAddress(address, ', ');
        }
        else {
            return emptyAddress;
        }
    };
    Object.defineProperty(OrdersAppService.prototype, "setSelectedOrderID", {
        set: function (orderId) {
            this.selectedOrderId = orderId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrdersAppService.prototype, "getSelectedOrderID", {
        get: function () {
            return this.selectedOrderId;
        },
        enumerable: true,
        configurable: true
    });
    return OrdersAppService;
}());
export { OrdersAppService };
