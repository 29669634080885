/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./message-group.component";
var styles_MessageGroupComponent = [i0.styles];
var RenderType_MessageGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageGroupComponent, data: {} });
export { RenderType_MessageGroupComponent as RenderType_MessageGroupComponent };
function View_MessageGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [["class", "timeblock__list-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "message__container"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h5", [["class", "time-message"], ["data-test-id", "time-message_"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "p", [["class", "text-message"], ["data-test-id", "text-message_"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "message__container"; var currVal_1 = (_v.context.$implicit["isSentByMe"] ? "msg-right" : "msg-left"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit["isSentByMe"] ? "my-message" : "interlocutor-message"); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit["dateHHmm"]; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.linkify(_v.context.$implicit.text); _ck(_v, 7, 0, currVal_4); }); }
export function View_MessageGroupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "section", [["class", "chat-list__timeblock"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "timeblock__time-messages"], ["data-test-id", "timeblock__time-messages_"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "timeblock__list-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageGroupComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.messageGroup.messages; var currVal_2 = _co.trackByID; _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.messageGroup.date, _co.formatFullDate)); _ck(_v, 3, 0, currVal_0); }); }
export function View_MessageGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "message-group", [], null, null, null, View_MessageGroupComponent_0, RenderType_MessageGroupComponent)), i1.ɵdid(1, 49152, null, 0, i3.MessageGroupComponent, [], null, null)], null, null); }
var MessageGroupComponentNgFactory = i1.ɵccf("message-group", i3.MessageGroupComponent, View_MessageGroupComponent_Host_0, { userID: "userID", messageGroup: "messageGroup", formatFullDate: "formatFullDate" }, {}, []);
export { MessageGroupComponentNgFactory as MessageGroupComponentNgFactory };
