import { NgModule, ModuleWithProviders }    from '@angular/core';
import { CommonModule }                     from '@angular/common';

import { BizipixBoardComponent }            from './bizipix-board.component';
import { BizipixEditorComponent }           from './bizipix-editor.component';
import { BizipixEditorService }             from './bizipix-editor.service';
import { EditorMenuComponent }              from './editor-menu.component';
import { ItemPickerComponent }              from './item-picker.component';
import { NgResizableModule }                from 'ngresizable';
import { PickerBlockComponent }             from './picker-block.component';
import { PickerCellComponent }              from './picker-cell.component';
import { SharedModule }                     from '../shared/shared.module';
import { TagComponent }                     from './tag.component';
import { UploadComponent }                  from './upload.component';
import {AngularDraggableModule} from "angular2-draggable";
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgResizableModule,
        AngularDraggableModule,
        TranslateModule,
    ],
    declarations: [
        BizipixBoardComponent,
        BizipixEditorComponent,
        EditorMenuComponent,
        ItemPickerComponent,
        PickerBlockComponent,
        PickerCellComponent,
        TagComponent,
        UploadComponent,
    ],
    entryComponents: [
        TagComponent,
    ],
    exports: [BizipixEditorComponent]
})
export class BizipixEditorModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: BizipixEditorModule,
            providers: [BizipixEditorService]
        };
    }
}
