
        <!--- Header for view Review page -->
        <header *ngIf="locationSearch !== '/create-review'" class="app-header reviews__app-header">
            <nav class="app-header__container">
                <button class="back-icon__container" (click)="backToHome()" data-test-id="backLink" type="button">
                    <i class="icon-custom_back_icon"></i>
                </button>

                <div class="navbar__center">
                    <h4 *ngIf="locationSearch === '/reviews'" data-test-id="headerTitle">{{ title }}</h4>
                    <h4 *ngIf="locationSearch === '/reviews-item'" data-test-id="headerTitle">{{ itemTitle }}</h4>
                </div>

                <button [ngClass]="'filter-icon__container'" (click)="showFilter()" data-test-id="showFilter" type="button">
                     <img [src]="'../../assets/images/svg_icons/filter.svg'" alt="show filter"/>
                </button>
            </nav>

            <div *ngIf="reviewsFilterList && reviewsFilterList.length && isAvailableTabs" class="review-header__list-switch-block text-center" data-test-id="reviewHeaderSwitchBlock">
                <button *ngFor="let type of reviewsFilterList; let i =  index;"
                        [attr.data-test-id]="'TypeBtn_' + type.title"
                        [ngClass]="type.active && 'review-list-active-btn'"
                        type="button"
                        (click)="toggleListState(type.value)"><span>{{type.title}}</span><span class="review-underline"></span></button>
            </div>

        </header>
        <!--- End header for view Review page -->

        <!--- Header for Create Review page -->
        <header *ngIf="locationSearch === '/create-review'" class="app-header create-reviews__app-header">
            <nav class="app-header__container">
                <div class="navbar__left">
                   <button (click)="location.back()" data-test-id="backLink" type="button">
                        <i class="icon-custom_back_icon"></i>
                   </button>
                </div>

                <h5 class="navbar__center" data-test-id="createReviewHeaderTitle">
                    {{ "review.add.review" | translate }} {{ createReviewHeaderTitle }}
                </h5>

                <div class="navbar__right">
                    <h4 class="nav__link" data-test-id="saveReview" (click)="onDone()" [ngClass]="{'disabled': disabled}">
                        {{ "review.text.save" | translate }}
                    </h4>
                </div>
            </nav>
        </header>
        <!--- End header for Create Review page -->
    