import {
    Component, ComponentRef,
    ComponentFactoryResolver,
    OnInit, ViewChild,
    ViewContainerRef, ElementRef, Injector, Type
} from '@angular/core';
import { Observable }      from 'rxjs';
import 'rxjs/add/observable/throw';

import { CenterLocationInterface }      from '../interfaces/location.interface';
import { EventSearchComponent }         from './search-lists/event-search.component';
import { GeoLocationService }           from '../services/geolocation.service';
import { GoogleAnalyticsService }       from '../services/google-analytics.service';
import { ItemSearchComponent }          from './search-lists/item-search.component';
import { MarketSearchComponent }        from './search-lists/market-search.component';
import { SearchListClass }              from './search-lists/search-list.class';
import {
    SearchOptionsInterface,
    SearchResultInterface,
    NestedMarket,
    SearchType,
} from '../interfaces/search.interface';
import { AppSearchService } from './search.service';
import { ModalService }                 from '../modal/modal.service';
import { SellerSearchComponent }        from './search-lists/user-search.component';
import { UsersListComponent }           from './search-lists/users-list.component';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { SearchResultItems } from '../interfaces/search.interface';
import { ItemService } from '../services/item.service';
import { ProductSearchListComponent } from './search-lists/product-search-list.component';
import { ModalFilterArgumentInterface } from '../interfaces/modal.interface';
import { ComponentFactory } from '@angular/core/src/linker/component_factory';
import { AppRouteValues } from "../common/app.route.values";
import { TranslateService } from '@ngx-translate/core';
import { ErrorInterface } from '../services/error.service';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import {ProductDetailsService} from "../product-details/product-details.service";


@Component({
    selector:   'search',
    styleUrls:  ['search.sass'],

    template:   `
        <div class="component">
            <search-header [search_filter]="searchListFilter" [userLocation]="userLocationForSearch"
                           [is_key_search]="is_key_search" [searchResult]="searchResult"
                           [isAdminMode]="isAdminMode()"
                           (changeEvent$)="onOptionsChange($event)"></search-header>

            <div class="search__container">
                <div #container></div>
            </div>
        </div>
    `
})


export class SearchStrategyComponent extends UnsubscribeOnDestroyAbsctractClass implements OnInit {
    @ViewChild('container', { read: ViewContainerRef })

    public container:                       ViewContainerRef;
    public searchResult:                    SearchResultInterface;
    public sortBy:                          string;
    public is_key_search:                   boolean;
    public _w:                              any;
    public userLocationForSearch:           CenterLocationInterface;
    public searchListFilter:                Array<Array<ModalFilterArgumentInterface>>;

    private componentRef:           ComponentRef<{}>;
    private locationPromise:        Promise<CenterLocationInterface>;
    private mappings: {[key: string]: Type<{}>} = {
        [SearchType.Active]:   ProductSearchListComponent,
        [SearchType.Event]:    EventSearchComponent,
        [SearchType.Item]:     ItemSearchComponent,
        [SearchType.Market]:   MarketSearchComponent,
        [SearchType.Seller]:   SellerSearchComponent,
        [SearchType.Sale]:     ItemSearchComponent,
        [SearchType.User]:     UsersListComponent
    };
    private local_pathname: string;
    private local_search: string;

    private mappers: { [key: string]: (r: SearchResultInterface) => {} } = {
       [SearchType.Active]: (r: SearchResultInterface) => this._mergeRusultItems(r.data.items),
       [SearchType.Item]: (r: SearchResultInterface) => {
          return {
              nearest: this._calcDistances(r.data.nearest_items, this.searchService.userLocation),
              other: this._calcDistances(r.data.items, this.searchService.userLocation)
          };
       },
       [SearchType.User]:      (r: SearchResultInterface) => r.data.users,
       [SearchType.Seller]:    (r: SearchResultInterface) => r.data.sellers,
       [SearchType.Sale]:  (r: SearchResultInterface) => {
          return {
              nearest: this._calcDistances(r.data.nearest_items, this.searchService.userLocation),
              other: this._calcDistances(r.data.items, this.searchService.userLocation)
          };
       }
    };

    constructor(private elementRef: ElementRef,
                private componentFactoryResolver: ComponentFactoryResolver,
                private geoLocationService: GeoLocationService,
                private googleAnalyticsService: GoogleAnalyticsService,
                private productDetailsService: ProductDetailsService,
                private injector: Injector,
                private modalService: ModalService,
                private translate: TranslateService,
                private searchService: AppSearchService) {
                    super();
                    this._w = window; // todo: pagination
                }

    public ngOnInit(): void {
        this.local_pathname = decodeURIComponent( this._w.location.pathname );
        this.local_search = decodeURIComponent( this._w.location.search );

        if (!this.local_search) {
            this.searchService.is_key_search = true;
        }

        this.userLocationForSearch = this.searchService.userGeolocation();
        this._setData(this.local_pathname);

        this.is_key_search = this.searchService.is_key_search;
        this.searchService.addToLocalCaching();

        this.searchListFilter = this.filters;
        this.changeFilterArguments(this.searchResult.type);
    }

    private get filters(): Array<Array<ModalFilterArgumentInterface>> {
        let filters = new Array;

        filters.push(this.searchFilter);

        return filters;
    }
    public get searchFilter(): Array<ModalFilterArgumentInterface> {
        return [
            {value: SearchType.Item, title: this.translate.instant('Product'), active: true},
            {value: SearchType.Sale, title: this.translate.instant('Sale'), active: false},
            {value: SearchType.Seller, title: this.translate.instant('Seller'), active: false},
            {value: SearchType.Market, title: this.translate.instant('Market'), active: false},
        ];
    }

    private _setData(pathname: string): void {
        const cachedData = Object.keys(this.searchService.cachedData());

        this._preparingData();

        if (!cachedData.length) {
            if (pathname === `/${AppRouteValues.usersList}`) {
                this._resetSearchResult(SearchType.User);
            } else if (pathname === `/${AppRouteValues.routeSearch}`) {
                this._search(this.searchResult.keyWord);
            } else {
                this._resetSearchResult(SearchType.Item);
            }
        } else {
            if (pathname === `/${AppRouteValues.usersList}`) {
                this._setSearchType(SearchType.User);
                this._search(this.searchResult.keyWord);
            } else {
                const uniqItemFromCache = this.searchService.getUniqSearchItemFromCache(window.location.href);
                const keyWord: string = this.searchService.getLastSearchResult().keyWord || '';
                const typeOfSearch: string = this.searchService.getLastSearchResult().type || SearchType.Item;

                if (pathname === `/${AppRouteValues.routeSearch}` && this.searchService.prevUrl.includes(AppRouteValues.routeProductDetails)) {
                    this.searchService.getLastSuccessSearchData();
                }
                if (!Object.keys(uniqItemFromCache).length && typeOfSearch === SearchType.Item) {
                    this._search(keyWord);
                    this.searchService.addToLocalCaching();
                } else {
                    this._preparingData();
                    this._renderResults();
                }

                this.searchService.getSearchFrom();
            }
        }
    }
    /**
     * Asynchronous method receive geolocation (coordinates) by the user and set in userLocation
     * @return {Promise<CenterLocationInterface>}
     */
    public async getGeolocation(): Promise<CenterLocationInterface> {
        this.modalService.showSpinner();

        await this._preparingData();

        this.locationPromise = new Promise<CenterLocationInterface>(resolve => {
            this.geoLocationService.getLocation(resolve, (error: ErrorInterface) => {
                this.trackSubscription(
                    this.geoLocationService.ipLookUp()
                    .subscribe(
                        (res: CenterLocationInterface) => this._preparingResults(res),
                        (err: ErrorInterface) => this._preparingResults(this.geoLocationService.getDefaultUserLocation(),
                    )),
                );
            });
        });

        this.locationPromise.then((location: CenterLocationInterface) => {
            const userLocation: CenterLocationInterface = {
                longitude: location['coords'].longitude,
                latitude: location['coords'].latitude
            };
            this._preparingResults(userLocation);
        });

        return this.locationPromise;
    }

    /**
     * get data from getResults and set into searchResult
     * @private
     */
    _preparingData() {
        this.searchResult = this.searchService.getResults();
    }

    /**
     * set user's coordinates and go to _renderResults's method
     * @param {{}} coords
     * @private
     */
    _preparingResults(coords: {}) {
        this.searchService.setUserLocation(coords);
        if (Object.keys(this.searchResult.data).length) {
            this._renderResults();
        }
        this.modalService.close();
    }

    private _renderResults(): void {
        this.container.remove();

        const type: SearchType = this.searchResult.type;
        const component: Type<{}> = this.mappings[type];
        let factory: ComponentFactory<{}> = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef   = this.container.createComponent(factory);

        let instance        = <SearchListClass> this.componentRef.instance;

        instance.items      = this._sortData(this._dataMapper(), this.sortBy);

        this._preparingAnalytic(this.searchResult);
        instance.search_from = this.searchService.search_from;
        this.searchService.addToLocalCaching();

        if (this.searchResult.keyWord !== '' && this.searchService.search_from && this.searchService.search_from !== '') {
            this.googleAnalyticsService.handleEvent('successful_search_by_' + this.searchService.search_from, 'search', 'search_from_' + this.searchService.search_from);
            this.googleAnalyticsService.handleVirtualPage('search_from_' + this.searchService.search_from + '_open');
        }
    }

    // TODO change request
    _dataMapper(): any {
        const r = this.searchResult;

        if (!Object.keys(r.data).length) return [];

        return this.mappers[r.type]
            ? this.mappers[r.type](r)
            : r.data.markets;
    }

    private _resetSearchResult(type: SearchType): void {
        this.searchResult = Object.assign({
            foundNum:   0,
            keyWord:    '',
            data:       {},
            type
        });
    }

    private _setSearchType(type: SearchType): void {
        this.searchResult = {...this.searchResult, type};
    }

    public onOptionsChange(options: SearchOptionsInterface): void {
        if (options.map) {
            if ( !this.searchService.userLocation ) {
                this.getGeolocation().then((res) => {
                    return;
                });
            }
            return;
        }

        this.container.remove();

        if (!options.sortBy && this.searchResult.type === SearchType.User) {
            this._search(options.keyWord);
            return;
        }

        if (options.type) {
            this.changeFilterArguments(options.type);

            this._setEmptyList(options.type);
        }

        if (options.keyWord) {
            this.searchService.is_key_search = true;
            this.googleAnalyticsService.handleForSearchByType('key_word_attempt', options.keyWord);
            this.searchResult.keyWord = options.keyWord;
            this._search(options.keyWord);
        }

        if (!options.type && options.keyWord === '') {
            this._setEmptyList(this.searchResult.type);
        }

        if (options.type && options.sortBy === 'unsorted') {
            this.sortBy = 'unsorted';
            let sortByElementRef = this.elementRef.nativeElement.querySelector('select[name="sortBy"]');

            if (sortByElementRef) {
                sortByElementRef.value = 'unsorted';
            }
        } else if (options.sortBy) {
            this._sortResult(options.sortBy);
        }
    }

    private changeFilterArguments(sortByValue: string) {
        this.searchListFilter.map((filters: Array<ModalFilterArgumentInterface>) => {

            let reseted_filters =  this.resetFilterByValue(filters, sortByValue);

            return this.setActiveFilterByValue(reseted_filters, sortByValue);
        });
    }
    private resetFilterByValue(filters: Array<ModalFilterArgumentInterface>, sortByValue: string): Array<ModalFilterArgumentInterface> {
        filters.forEach((filter: ModalFilterArgumentInterface) => {
            if (filter.value === sortByValue) {
                filters.forEach((f: ModalFilterArgumentInterface) => {
                    f.active = false;
                });
            }
        });

        return filters;
    }

    private setActiveFilterByValue(filters: Array<ModalFilterArgumentInterface>, sortByValue: string): Array<ModalFilterArgumentInterface> {
        filters.forEach((filter: ModalFilterArgumentInterface) => {
            if (filter.value === sortByValue) {
                filter.active = true;
            }
        });

        return filters;
    }

    /**
     * Clears all previously results
     * @param {string} type
     * @private
     */
    private _setEmptyList(type: SearchType): void {
        this._resetSearchResult(type);

        this.searchService.clearResults(this.searchResult.type);
    }

    _sortResult(sortBy: string) {
        this.sortBy = sortBy;
        this._renderResults();
    }

    /**
     * Method for returning array of parent items
     * @param {SearchResultItems[]} items
     * @returns {any[]}
     * @private
     */
    _mergeRusultItems(items: SearchResultItems[]) {
        let     allItem;
        const   itemsList = [...items];
        const   parentItems = itemsList.filter( i => i.is_parent);


        if (parentItems.length) {
            parentItems.forEach(item => {
                item.nestedMarkets = this._getNestedMarkets(itemsList, item);
            });
            allItem = parentItems;

        } else allItem = items;

        return allItem;
    }

    _getNestedMarkets(items: NestedMarket[], parent: NestedMarket) {
        const childMarkets = items.filter( i => parent.ID === i.parent_id);
        const nestedMarkets = this._calcDistances(childMarkets, this.searchService.userLocation);

        return nestedMarkets.sort((a, b) => {
            return a.distance - b.distance;
        });
    }

    _calcDistances(items: NestedMarket[], userLocation) {
        items.forEach(item => {
            if (item.loc) {
                item.distance = this.geoLocationService.getDistance(
                    userLocation.latitude,
                    userLocation.longitude,
                    item.loc.coordinates[1],
                    item.loc.coordinates[0],
                );
            }
        });
        return items;
    }

    _sortData(data: any, sortBy: string) {
        if (Array.isArray(data)) {
            return data = (!sortBy)
                ? data
                : this._sortArr(this._priceForSorting(data), sortBy);
        }

        for (let key in data) {
            data[key] = this._sortArr(this._priceForSorting(data[key]), sortBy);
        }

        return data;
    }

    /**
     * for sorting Selling Item by price
     * @param {SellingItemResponseBody[]} data
     * @return {SellingItemResponseBody[]}
     * @private
     */
    _priceForSorting(data: SellingItemResponseBody[]): SellingItemResponseBody[] {
        data.forEach((i: SellingItemResponseBody) => {
            if ( this.itemService.isSaleFn(i) ) {
                i.price = i.salePrice;
            }
        });
        return data;
    }

    /**
     * sorting method by the user desire
     * @param {[{}]} arr
     * @param {string} sortBy
     * @returns {any}
     * @private
     */
    _sortArr(arr: any[], sortBy: string) {
        let callback;

        if (!sortBy) return arr;

        switch (sortBy) {
            case 'rate':
                callback = (a, b) => b.rate - a.rate;
                break;

            case 'ascending':
                callback = (a, b) => a.price - b.price;
                break;

            case 'descending':
                callback = (a, b) => b.price - a.price;
                break;

            default:
                callback = () => true;
        }

        return arr.sort(callback).concat([]);
    }

    _search(keyWord: string) {
        this.trackSubscription(
            this.searchService.search(keyWord.trim(), this.searchResult.type, {isNew: true} as any)
                .subscribe((res: SearchResultInterface) => {
                    this.googleAnalyticsService.handleForSearch(keyWord);
                    this.searchResult = res;
                    this._renderResults();
                    this.googleAnalyticsService.handleViewSearchResults(keyWord);
                    this.productDetailsService.setProductDetailsFrom('search_page');
                }, (err) => {
                    this._resetSearchResult(this.searchResult.type);
                    return Observable.throwError(err);
            }),
        );
    }

    _preparingAnalytic(result: SearchResultInterface) {
        if ( result.foundNum === 0 ) return;

        this.searchService.getSearchFrom();
    }

    public isAdminMode(): boolean {
        return this.searchService.isAdminMode();
    }

    public get itemService(): ItemService {
        return this.injector.get(ItemService);
    }
}


// Will be useful for pagination

// fetchMore(offset: number) { return;
//     // this.searchService.search(this.keyWord, this.type, {offset} as any);
// }

// updateResults(res: SearchResultInterface|any) {
//     // this.noResults  = true;
//     // let res = this.searchService.getResults();
//
//     if (typeof res === 'undefined') {
//         this.keyWord        = '';
//         this.searchResult   = null;
//         this.type           = 'item';
//
//         return;
//     }
//
//     this.keyWord        = res.keyWord;
//     this.searchResult   = res.data;
//     this.type           = res.type;
//
//     if (Object.keys(res.data['items'] || {}).length ||
//         Object.keys(res.data['users'] || {}).length ||
//         Object.keys(res.data['markets'] || {}).length ||
//         Object.keys(res.data['nearest_items'] || {}).length) {
//         // this.noResults = false;
//     }
//
//     // this.numOfFound = (this.type === 'item')
//     //     ? this.searchResult.items.length + this.searchResult.nearest_items.length
//     //     : this.searchResult.markets.length;
// }
