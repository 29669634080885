import { ElementRef, AfterViewInit }        from '@angular/core';
import { Observable }                       from 'rxjs/Observable';
import { Router }                           from '@angular/router';
import { Subject }                          from 'rxjs';

import AppValues                            from '../../common/app.values';
import { ModalService }                     from '../../modal/modal.service';
import {ErrorInterface} from '../../services/error.service';
import {TranslateService} from '@ngx-translate/core';


export abstract class EmailPageAbstractClass implements AfterViewInit {

    public busy:               boolean;
    public email:              string = '';
    public emailErr:           boolean;

    protected componentDestroyed$:    Subject<boolean> = new Subject();
    protected elem:                   HTMLInputElement;
    protected successMsg:             string;


    constructor(
        protected elementRef:     ElementRef,
        protected modalService:   ModalService,
        protected router:         Router,
        protected translate:      TranslateService
    ) {}


    public ngAfterViewInit(): void {
        this.elem = this.elementRef.nativeElement;
        this.elem.querySelector('input[type="email"]')['focus']();
    }


    abstract _sendRequest(): Observable<any>;


    public onSend(event: Event): void {
        this.busy = true;
        this.modalService.showSpinner();

        this._sendRequest()
            .takeUntil(this.componentDestroyed$)
            .subscribe(
                () => this.onSuccess(),
                (err: ErrorInterface | any) => {
                    this.handleError(err.statusText || err);
                }
            );

        event.preventDefault();
    }


    public onSuccess(): void {
        this.modalService
            .success({
                title:          this.translate.instant('modal.success.title'),
                message:        this.successMsg,
                yesButtonText:  this.translate.instant('modal.success.confirm'),
            }).then((action: any) => {
                this.busy = false;
                this.router.navigate(['/login']);
        });
    }

    public onPaste(event: ClipboardEvent): void {
      let clipboardData: DataTransfer = event.clipboardData || window['clipboardData'];
      let pastedText: string = clipboardData.getData('text');

      this.onValidation(pastedText);
    }


    public onValidation(value: string): void {
        this.emailErr = !value.match(AppValues.emailPattern);
    }

    public blurField(event: Event): void {
        event.target['value'] = event.target['value'].trim();
        this.onValidation(event.target['value']);
    }


    public handleError(errMsg: ErrorInterface | string): void {
        this.email      = '';
        this.emailErr   = false;
        this.busy       = false;

        this.modalService
            .error({
                title:          this.translate.instant('email.send.error.title'),
                message:        errMsg['statusText'] || errMsg,
                yesButtonText:  this.translate.instant('email.send.error.confirm')
            })
            .then(() => this.elem.querySelector('input[type="email"]')['focus']());
    }

}
