var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import { Subject } from 'rxjs';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { ModalService } from '../modal/modal.service';
import { UserService } from '../user/user.service';
import { CookieService } from '../services/cookie.service';
import { UpdateShoppingListService } from '../../../swagger-gen__output_dir';
import { LocalCaching, LocalCachingCreatorMapping } from '../local-caching/local-caching-factory';
import AppValues from '../common/app.values';
import { tap } from 'rxjs/operators';
var ShoppingListService = /** @class */ (function () {
    function ShoppingListService(updateShoppingListService, cookieService, dataService, errorService, modalService, userService, localCaching) {
        this.updateShoppingListService = updateShoppingListService;
        this.cookieService = cookieService;
        this.dataService = dataService;
        this.errorService = errorService;
        this.modalService = modalService;
        this.userService = userService;
        this.localCaching = localCaching;
        this.list = [];
        this.activeList = [];
        this.inactiveList = [];
        this.suggestionsSearchActivating = new Subject();
    }
    ShoppingListService.prototype.getInactiveList = function () { return this.inactiveList; };
    ShoppingListService.prototype.getActiveList = function () { return this.activeList; };
    ShoppingListService.prototype.getCachedList = function () { return this.list; };
    ShoppingListService.prototype.setCachedList = function (list) { this.list = list; };
    ShoppingListService.prototype.getCachedItem = function () { return this.cachedItem.name || ''; };
    ShoppingListService.prototype.setCachedItem = function (item) {
        this.cachedItem = item;
    };
    /**
     * Get request get_user_shopping_list for shopping list
     * To check existence of token:
     * if there is a token then return empty object
     * if there is not a token then send request to server.
     * Success request - return object (shopping list of current user)
     * Nosuccess request - return message error.
     * @returns {Observable< ShoppingListInterface | {} >}
     */
    ShoppingListService.prototype.getList = function () {
        var _this = this;
        var token = this.userService.getUserSession().token
            || this.cookieService.getCookie('user');
        if (!token)
            return Observable.of({});
        this.modalService.showSpinner();
        return this.dataService.getData('get_user_shopping_list', { "token": token })
            .do(function () { return _this.cookieService.deleteCookie('payment'); })
            .map(function (res) {
            _this.modalService.close();
            _this.list = res.elements;
            return _this.list;
        })
            .catch(function (err) {
            return _this.errorService.handleError(err);
        });
    };
    /**
     * Get request get_item_suggestions_for_shopping_list for shopping list
     * To check existence of token:
     * if there is a token then return empty object
     * if there is not a token then send request to server.
     * Success request - return object (uggestionss for shopping list)
     * Nosuccess request - return message error.
     * @param {string} keyword
     * @returns {Observable< GetItemSuggestionsForShoppingListResponseBody | {} >}
     */
    ShoppingListService.prototype.getSuggestionsForShoppingList = function (keyword) {
        var token = this.userService.getUserSession().token;
        if (!token)
            return Observable.of({});
        var url = "get_item_suggestions_for_shopping_list?keyword=" + keyword;
        return this.dataService.getData(url, { token: token });
    };
    ShoppingListService.prototype.getAllSuggestionsForShoppingList = function () {
        var _this = this;
        this.getSuggestionsForShoppingList('')
            .subscribe(function (res) {
            _this.localCaching.setCache(LocalCachingCreatorMapping.ConcreteSuggestionsCaching, res);
        }, function () {
            _this.localCaching.setCache(LocalCachingCreatorMapping.ConcreteSuggestionsCaching, []);
        });
    };
    /**
     * Returns new custom item with the given name (text)
     * @param {string} text
     * @returns {ShoppingListItemInterface}
     */
    ShoppingListService.prototype.getBasicCustomItem = function (text) {
        return {
            name: text,
            subcategory_entry_id: '',
            is_active: true,
            is_custom: true
        };
    };
    /**
     * Fetches subcategories from subcategory entries of suggestions
     * @param {string} itemName
     * @return {SubcategoryInterface[]}
     */
    ShoppingListService.prototype.fetchSubcategories = function (itemName) {
        var _this = this;
        var subcategories = [];
        var suggestions = AppValues.deepCopy(this.localCaching.getAllCache(LocalCachingCreatorMapping.ConcreteSuggestionsCaching)[0].data);
        suggestions.suggestions = this._suggestionsFilterByName(suggestions.suggestions, itemName);
        suggestions.suggestions.forEach(function (suggestion) {
            var entries = _this.convertProductListToSubcategoryEntries(suggestion);
            entries.forEach(function (entry) {
                subcategories.push({
                    ID: suggestion.subcategory_entry_id,
                    sub_category_name: suggestion.subcategory_entry_descriptor,
                    sub_category_entries: [entry],
                });
            });
        });
        return subcategories;
    };
    ShoppingListService.prototype._suggestionsFilterByName = function (suggestions, itemName) {
        var _this = this;
        if (suggestions && suggestions.length !== 0) {
            return suggestions.filter(function (s) {
                return (_this.compareString(s.subcategory_entry_name, itemName) ||
                    _this.compareString(s.subcategory_entry_descriptor, itemName) ||
                    _this.compareString(s.subcategory_name, itemName) ||
                    _this.compareString(s.product_title, itemName));
            });
        }
        return [];
    };
    ShoppingListService.prototype.compareString = function (s, itemName) {
        var inputedText = itemName.toLocaleLowerCase();
        return s.split(' ').find(function (a) { return a.toLocaleLowerCase().startsWith(inputedText); }) || s.toLocaleLowerCase().includes(inputedText);
    };
    /**
     * Sorting method.
     * At the top of the list, there should always
     * be only active elements, and then not active.
     * @param {ShoppingListItemInterface[]} list
     * @returns {ShoppingListItemInterface[]}
     */
    ShoppingListService.prototype.sortList = function (list) {
        this.activeList = list.filter(function (item) { return item.is_active; });
        this.inactiveList = list.filter(function (item) { return !item.is_active; });
        this.list = __spread(this.activeList, this.inactiveList);
        return this.list;
    };
    /**
     * Update method (for delete, add, moved items)
     * Works only when there is a token
     * @param {ShoppingListItemInterface[]} list
     * @returns {any}
     */
    ShoppingListService.prototype.updateShoppingList = function (list) {
        var _this = this;
        var shoppingList = {
            elements: list,
        };
        var token = this.userService.getUserSession().token;
        if (token) {
            return this.updateShoppingListService.updateShoppingListPut(shoppingList, token)
                .pipe(tap(function (res) { return _this.setCachedList(res.elements); }))
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({ elements: [] });
    };
    /**
     * Method for add one new Item to Shopping List
     * @param {ShoppingListItemInterface} newItem
     * @param {ShoppingListItemInterface[]} list
     * @returns {ShoppingListItemInterface[]}
     */
    ShoppingListService.prototype.addItem = function (newItem, list) {
        list.push(newItem);
        return this.sortList(list);
    };
    /**
     * Method for add one new Item to Shopping List
     * @param {ShoppingListItemInterface} newItem
     * @param {ShoppingListItemInterface[]} list
     * @param {string} keyword
     * @returns {ShoppingListItemInterface[]}
     */
    ShoppingListService.prototype.addSuggestedItem = function (newItem, list, keyword) {
        if (this.cachedItem && keyword) {
            this.updateItemInList(list, this.cachedItem, this.getItemWithUpdates(this.cachedItem, 'name', keyword));
            return this.sortList(list);
        }
        if (this.cachedItem && !keyword) {
            list = this.removeItem(this.cachedItem, list);
        }
        return this.addItem(newItem, list);
    };
    /**
     * Method for remove one new Item to Shopping List
     * filter need for cleaner delete method
     * (and delete null, undefined or any item types)
     * @param {ShoppingListItemInterface} item
     * @param {ShoppingListItemInterface[]} list
     * @returns {ShoppingListItemInterface[]}
     */
    ShoppingListService.prototype.removeItem = function (item, list) {
        var index = this.getIndex(list, item);
        if (index === -1) {
            return list;
        }
        delete list[index];
        list = list.filter(function (n) { return typeof n === 'object'; });
        return this.sortList(list);
    };
    ShoppingListService.prototype.updateList = function (list) {
        return this.updateShoppingList(list);
    };
    /**
     * Updates the specified item's property with given value
     * @param {ShoppingListItemInterface} item
     * @param {string} property
     * @param {string | boolean | null} value
     * @returns {ShoppingListItemInterface}
     */
    ShoppingListService.prototype.getItemWithUpdates = function (item, property, value) {
        var _a;
        return Object.assign({}, item, (_a = {}, _a[property] = value, _a));
    };
    /**
     * Replaces the current item with updated item if it's found
     * @param {ShoppingListItemInterface[]} list
     * @param {ShoppingListItemInterface} currentItem
     * @param {ShoppingListItemInterface} updatedItem
     * @returns {void}
     */
    ShoppingListService.prototype.updateItemInList = function (list, currentItem, updatedItem) {
        var index = this.getIndex(list, currentItem);
        if (index !== -1) {
            list.splice(index, 1, updatedItem);
        }
    };
    /**
     * Finds the index of the given item in the list
     * @param {ShoppingListItemInterface[]} list
     * @param {ShoppingListItemInterface} item
     * @returns {number}
     */
    ShoppingListService.prototype.getIndex = function (list, item) {
        var _this = this;
        return list.findIndex(function (elem) { return _this.deepEqual(elem, item); });
    };
    /**
     * Temporary removes 'descriptor' property for each item
     * @param {ShoppingListItemInterface[]} list
     * @returns {ShoppingListItemInterface[]}
     */
    ShoppingListService.prototype.getListWithoutDescriptor = function (list) {
        return list.map(function (item) {
            return {
                name: item.name,
                is_active: item.is_active,
                is_custom: item.is_custom,
                subcategory_entry_id: item.subcategory_entry_id,
                sub_category_name: item.sub_category_name
            };
        });
    };
    /**
     * Returnes the result of comparing two items
     * @param {ShoppingListItemInterface} x
     * @param {ShoppingListItemInterface} y
     * @returns {boolean}
     */
    ShoppingListService.prototype.deepEqual = function (x, y) {
        var _this = this;
        if (!(x && y && typeof x === 'object' && typeof x === typeof y)) {
            return x === y;
        }
        var requiredFields = ['is_active', 'is_custom', 'name', 'subcategory_entry_id'];
        var xKeys = Object.keys(x);
        var yKeys = Object.keys(y);
        return this.isValidForComparison(requiredFields, xKeys) === this.isValidForComparison(requiredFields, yKeys) && requiredFields.every(function (key) { return _this.deepEqual(x[key], y[key]); });
    };
    /**
     * @desc Determines whether 2 objects are valid to be compared
     * @param requiredFields
     * @param objKeys
     * @returns true if valid for comparison
     */
    ShoppingListService.prototype.isValidForComparison = function (requiredFields, objKeys) {
        return objKeys.filter(function (key) { return requiredFields.includes(key); }).length === requiredFields.length;
    };
    /**
     * Converts suggestion product list to subcategory entry list.
     * @desc Formates subcategory entry list from product ID and subcategory_entry_name.
     *       Removes duplicates of subcategory entries with the same title.
     * @param {ItemSuggestionsForShoppingList} suggestion
     * @returns {SubcategoryEntryInterface}
     */
    ShoppingListService.prototype.convertProductListToSubcategoryEntries = function (suggestion) {
        var convertedList = [{
                ID: suggestion.product_id,
                subcategory_entry_name: suggestion.product_title,
                descriptor: suggestion.subcategory_entry_descriptor
            }];
        return convertedList;
    };
    return ShoppingListService;
}());
export { ShoppingListService };
