import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewContainerRef,
} from '@angular/core';

import {
    BizipixBoardSizesInterface,
    BizipixBoardTagInterface,
    BizipixItemsInterface,
    BizipixTagInterface,
    BizipixTagPositionInterface,
} from '../interfaces/bizipix.interface';
import { Position } from './position';
import { TagComponent } from './tag.component';
import AppValues from '../common/app.values';

@Component({
    selector: 'bizipix-board',
    styleUrls: ['./bizipix-editor.sass'],

    template: `
        <div #container class="bp__container">
            <div class="bp__image" (click)="clearActive()">
                <img
                    draggable="false"
                    [src]="currentBizipixBoard.image_url"
                    data-test-id="bizipixImage"
                    (load)="createFirstView()"
                    alt="bizipix image"
                />
            </div>
            <tag
                #tagContainer
                *ngFor="let tag of tags || currentBizipixBoard.tags"
                [context]="tag"
                [isClearActive]="isClearActive"
                [isToggleRemoveTag]="isToggleRemoveTag"
                [bizipix]="currentBizipixBoard"
                (allTagsContext$)="allTagsContext($event)"
            ></tag>
        </div>
    `,
})

/**
 * The area to create\edit and move\resize new tags.
 */
export class BizipixBoardComponent {
    @Input() public currentBizipixBoard: BizipixItemsInterface;
    @Input() public uploadNewBizipix: boolean;
    @Input() public isFirstLoadingIsComplete: boolean;

    @Output() public boardEvent$: EventEmitter<{}> = new EventEmitter();
    @Output() public changeBizipixInEditorEvent$: EventEmitter<{}> =
        new EventEmitter();

    public imageDimensions: BizipixBoardSizesInterface = { w: 0, h: 0 };
    public imageUrl: string;
    public limitations: {};
    public parentDimensions: BizipixBoardSizesInterface = { w: 0, h: 0 };
    public oldTags: BizipixBoardTagInterface[];
    public tags: BizipixBoardTagInterface[] = [];
    public viewportDimensions: BizipixBoardSizesInterface = { w: 0, h: 0 };
    public tagsContext: BizipixBoardTagInterface[] = [];
    public cloneCurrentBizipixBoard: BizipixItemsInterface[];
    public isToggleRemoveTag: boolean = false;
    public isClearActive: boolean = false;

    @ViewChild('container', { read: ViewContainerRef })
    public container: ViewContainerRef;
    @ViewChild('tagContainer') public tagContainer: TagComponent;
    @ViewChildren('tagContainer') public tagContainers: QueryList<TagComponent>;

    @HostListener('window:orientationchange', ['$event'])
    /**
     * Redraws the tags on viewport orientations change.
     */
    public resetView(): void {
        if (this.tags.length) {
            this.oldTags = Array.from(this.tags);
            this.tags = [];
            this._clearOldTags();
        } else {
            this.oldTags = [];
            this.tags = [];
        }
    }

    /**
     * Create First View base dimensions and rendering child TagComponents.
     */
    public createFirstView(): void {
        if (this.isFirstLoadingIsComplete === true && event.type === 'load') {
            this._renderingChild();
            this.isFirstLoadingIsComplete = false;
        }
    }

    /**
     * Updates view by calculating base dimensions and rendering child TagComponents.
     */
    public updateView(
        currentBizipixBoard?: BizipixItemsInterface,
        renderAnyway?: boolean,
    ): void {
        if (
            renderAnyway ||
            (currentBizipixBoard &&
                !this.isFirstLoadingIsComplete &&
                this.isTagsWereChanged(this.tagContainers))
        ) {
            this._renderingChild(currentBizipixBoard);
        }
    }

    public isTagsWereChanged(tagContainers: QueryList<TagComponent>): boolean {
        let isChanged: boolean = false;
        if (tagContainers && tagContainers['_results']) {
            tagContainers['_results'].forEach(
                (tagInContainer: TagComponent) => {
                    this.tags.forEach((tag: BizipixBoardTagInterface) => {
                        if (
                            !AppValues.isObjectEquals(
                                tag,
                                tagInContainer.context,
                            )
                        ) {
                            isChanged = true;
                        }
                    });
                },
            );
        }

        return isChanged;
    }
    /**
     * Creates an instance for TagComponent with a factory and injects its context.
     * @param tag
     * @private
     */
    public renderTag(tag: BizipixTagInterface): void {
        const tagEl: BizipixBoardTagInterface = this._setContext(tag);
        this.tags.push(tagEl);
    }

    // tslint:disable-next-line:no-any
    public allTagsContext(event: { tag: any; bizipix: any }): void {
        if (this.tagsContext.length) {
            // tslint:disable-next-line:no-any
            this.tagsContext.forEach((tagElem: any) => {
                const index: number = this.tagsContext.indexOf(tagElem);
                if (event.tag.context.item_id === tagElem.context.item_id) {
                    delete this.tagsContext[index];
                    this.tagsContext[index] = event.tag;
                }
            });
        } else {
            this.tagsContext.push(event.tag);
        }

        this.cloneCurrentBizipixBoard = event.bizipix;
    }

    /**
     * @desc Cleaning all tags for tagContainer
     */
    public removeComponent(): void {
        this.tags = [];
    }

    /**
     * Sets active class for the given TagComponent.
     * @param itemId
     */
    public setActive(itemId: string): void {
        this.clearActive();

        const resizableBlocks: NodeListOf<Element> =
            document.querySelectorAll('.ng-resizable');

        // @ts-ignore
        this.tags.find((tag: BizipixBoardTagInterface) => {
            const index: number = this.tags.indexOf(tag);
            if (tag.item_id === itemId && resizableBlocks.length) {
                resizableBlocks[index].classList.add('active');
            }
        });
        this.isClearActive = false;
    }

    /**
     * Clears active className for all the TagComponents.
     */
    public clearActive(): void {
        // tslint:disable-next-line:no-any
        this.tagContainers['_results'].forEach((tag: any) => {
            tag['elementRef'].nativeElement
                .querySelector('.ng-resizable')
                .classList.remove('active');

            tag['elementRef'].nativeElement.style.zIndex = '0 !important';
        });

        this.isClearActive = true;
        this.toggleRemoveTagMode(false);
    }

    /**
     * @emits boardEvent$ with removeTag command object:
     * {commandName: commandPayload}.
     * The commandName is the subscriber method's name to be called.
     * @param tagID
     */
    public removeTag(tagID: string): void {
        this.boardEvent$.emit({ removeTag: tagID });
    }

    /**
     * Sets new absolute coordinates for the tag in the BiziPix image object.
     * @param position
     */
    public moveTag(position: Position): void {
        position.x -= this.limitations['horizontalMargin'];

        const { x, y, width, height } = this._abs2rel(position);

        // tslint:disable:variable-name
        const top_left_x: number = x;
        const top_left_y: number = y;
        const bottom_right_x: number = x + width;
        const bottom_right_y: number = y + height;
        // tslint:enable:variable-name

        const tag: BizipixTagInterface = this.currentBizipixBoard.tags.find(
            (_tag: BizipixTagInterface) => _tag.item_id === position.item_id,
        );

        tag.tag_position = {
            top_left_x,
            top_left_y,
            bottom_right_x,
            bottom_right_y,
        };

        this.changeBizipixInEditorEvent$.emit({
            changedBizipix: true,
            updatedSelectedBixipix: this.currentBizipixBoard,
        });
    }

    /**
     * @emits boardEvent$ for rebindItem command object.
     * @param tagData
     */
    public rebindItem(tagData: BizipixTagInterface): void {
        this.boardEvent$.emit({ rebindItem: tagData });
    }

    // ----------------------------------------

    /**
     * Removes a tag from the BP image data object, as well as the corresponding TagComponent.
     * @param tagID
     */
    public deleteTag(tagID: string): void {
        const index: number = this.tags.findIndex(
            (tag: BizipixTagInterface) => tag.item_id === tagID,
        );

        this.currentBizipixBoard.tags.splice(index, 1);

        this.tagContainers['_results'][index][
            'elementRef'
        ].nativeElement.remove();
        this.tags.splice(index, 1);

        this.changeBizipixInEditorEvent$.emit({
            changedBizipix: true,
            updatedSelectedBixipix: this.currentBizipixBoard,
        });
    }

    /**
     * @emits boardEvent$ for savePix command object.
     */
    public save(): void {
        this.toggleRemoveTagMode(false);
        this.boardEvent$.emit({ savePix: this.currentBizipixBoard });
    }

    /**
     * @emits boardEvent$ for savePix command object.
     */
    public preSave(): void {
        this.toggleRemoveTagMode(false);
        this.boardEvent$.emit({ preSavePix: this.currentBizipixBoard });
    }

    /**
     * Calls #showTrash method for each of the TagComponents.
     * @param showTrashes
     * @private
     */
    public toggleRemoveTagMode(showTrashes: boolean): void {
        // tslint:disable-next-line:no-any
        this.tags.forEach((tag: any) => {
            const index: number = this.tags.indexOf(tag);
            this.tagContainer.showTrash(
                showTrashes,
                this.tagContainers['_results'][index],
            );
        });
        this.isToggleRemoveTag = !this.isToggleRemoveTag;
    }

    /**
     * Returns BiziPix image data object.
     * @returns {BizipixItemsInterface}
     */
    public getCurrentBizipix(): BizipixItemsInterface {
        return this.currentBizipixBoard;
    }

    /**
     * set new CurrentBizipix if necessary,
     * and call dimensions and rendering tag mathod
     * @param {BizipixItemsInterface} currentBizipixBoard
     * @private
     */
    private _renderingChild(currentBizipixBoard?: BizipixItemsInterface): void {
        if (currentBizipixBoard) {
            this.currentBizipixBoard = AppValues.deepCopy(currentBizipixBoard);
        }
        if (this.currentBizipixBoard) {
            setTimeout(() => this._preparingOfCurrentBizipixBoardTags());
        }
    }

    private _preparingOfCurrentBizipixBoardTags(): void {
        this._findDimentions();

        this.currentBizipixBoard.tags.length &&
            this.currentBizipixBoard.tags.forEach((tag: BizipixTagInterface) =>
                this.renderTag(tag),
            );
    }

    /**
     * Sets context of a TagComponent instance with tag data object, a callback and coordinates.
     * @param tag
     * @param tagEl
     * @private
     */
    private _setContext(
        tag: BizipixTagInterface,
        tagEl?: TagComponent,
    ): BizipixBoardTagInterface {
        return Object.assign(
            {},
            { callback: this._onTagEvent.bind(this) },
            this._setTagPosition(tag),
            tag,
        );
    }

    /**
     * Removes all the old components from the array, and deletes the array.
     * @private
     */
    private _clearOldTags(): void {
        // tslint:disable-next-line:forin
        for (const index in this.oldTags) {
            this.tagContainers['_results'][index]['elementRef'] &&
                this.tagContainers['_results'][index][
                    'elementRef'
                ].nativeElement.remove();
        }
        delete this.oldTags;
    }

    /**
     * Transforms relative tag coordinates into absolute x, y, width and height.
     * @param tag
     * @returns {Position}
     * @private
     */
    private _setTagPosition(tag: BizipixTagInterface): Position {
        if (!tag) {
            return;
        }

        const pos: BizipixTagPositionInterface = tag.tag_position;

        const x: number = pos.top_left_x;
        const y: number = pos.top_left_y;
        const width: number = pos.bottom_right_x - pos.top_left_x;
        const height: number = pos.bottom_right_y - pos.top_left_y;

        return this._rel2abs(new Position(x, y, width, height));
    }

    /**
     * Transforms relative coordinates into absolute.
     * @param position
     * @returns {Position}
     * @private
     */
    private _rel2abs(position: Position): Position {
        const { x, y, width, height } = position;

        const _x: number =
            x * this.imageDimensions.w + this.limitations['horizontalPadding'];
        const _y: number =
            y * this.imageDimensions.h + this.limitations['verticalPadding'];
        const _width: number = width * this.imageDimensions.w;
        const _height: number = height * this.imageDimensions.h;

        return new Position(_x, _y, _width, _height);
    }

    /**
     * Transforms absolute coordinates into relative.
     * @param position
     * @returns {Position}
     * @private
     */
    private _abs2rel(position: Position): Position {
        const { x, y, width, height } = position;

        const _x: number =
            (x - this.limitations['horizontalPadding']) /
            this.imageDimensions.w;
        const _y: number =
            (y - this.limitations['verticalPadding']) / this.imageDimensions.h;
        const _width: number = width / this.imageDimensions.w;
        const _height: number = height / this.imageDimensions.h;

        return new Position(_x, _y, _width, _height);
    }

    /**
     * Calculates base dimensions for the viewport, the board and the image.
     */
    private _findDimentions(): void {
        const port: HTMLElement = this.container.element.nativeElement;
        const elem: HTMLElement = port.querySelector('.bp__image img');

        this.viewportDimensions = {
            w: document.documentElement.clientWidth,
            h: document.documentElement.clientHeight,
        };

        this.parentDimensions = {
            w: port.clientWidth,
            h: port.clientHeight,
        };

        this.imageDimensions = {
            w: elem.clientWidth,
            h: elem.clientHeight,
        };

        this.limitations = {
            verticalPadding:
                0.5 * (this.viewportDimensions.h - this.imageDimensions.h),
            horizontalPadding:
                0.5 * (this.parentDimensions.w - this.imageDimensions.w),
            horizontalMargin:
                0.5 * (this.viewportDimensions.w - this.parentDimensions.w),
        };
    }

    // --------- Tag events -------------------------------
    /**
     * Event switch for the given command object {commandName: commandPayload}.
     * The commandName is the method's name to be called.
     * @param data
     */
    // tslint:disable-next-line:no-any
    private _onTagEvent(data: any): void {
        this[Object.keys(data)[0]](Object.values(data)[0]);
    }
}
