import {
    Component, Input, Output,
    ElementRef, EventEmitter, OnInit, Injector, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy
} from '@angular/core';
import { ItemService }                  from '../../services/item.service';
import { ProductDetailsService }        from '../../product-details/product-details.service';
import { ShoppingListInterface, ShoppingListItemInterface }         from '../../shopping-list/shopping-list.interface';
import { ShoppingListService }          from '../../shopping-list/shopping-list.service';
import { CartService } from '../../cart/cart.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Router } from '@angular/router';
import { SellingItemResponseBody } from '../../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { ErrorInterface, ErrorService } from '../../services/error.service';
import AppValues from '../../common/app.values';
import { Item, ShoppingCartElement, ShoppingCartResponseBody, ShoppingList } from '../../../../swagger-gen__output_dir';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../user/user.service';
import { ModalService } from '../../modal/modal.service';
import { CartAbstractClass } from '../../cart/cart-abstract-class';
import { ShopperDetailsService } from '../../shopper-details/shopper-details.service';
import { SellingItemCart } from '../../interfaces/cart.interface';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector:           'product-search-list',
    styleUrls:          ['../../../assets/styles/sprite.sass'],
    templateUrl:        './item-search-list.html',
 })

export class ProductSearchListComponent extends CartAbstractClass implements OnInit, OnChanges, OnDestroy {
    @Input() items:         SellingItemResponseBody[];
    @Input() shoppingList: ShoppingListItemInterface[];
    @Input() title: string;
    @Input() search_from = 'shopping_list';
    @Input() shoppingCartElements: ShoppingCartElement[];
    @Output() itemSelectEvent$      = new EventEmitter();
    @Output() addItemToSL$          = new EventEmitter<SellingItemResponseBody>();

    isSale:          boolean;
    isPrice:         number;
    isUnitsName:     string;

    protected isLocationBack: boolean = false;

    private subscription$: Subscription;

    constructor(
        private itemService:            ItemService,
        private shoppingListService:    ShoppingListService,
        private shopperDetailsService:  ShopperDetailsService,
        private injector:               Injector,
        private changeDetector:         ChangeDetectorRef,
        elementRef:                     ElementRef,
        cartService:                    CartService,
        userService:                    UserService,
        productDetailsService:          ProductDetailsService,
        googleAnalyticsService:         GoogleAnalyticsService,
        modalService:                   ModalService,
        errorService:                   ErrorService,
        translate:                      TranslateService,
    ) {
        super(elementRef, cartService, googleAnalyticsService, productDetailsService, userService, modalService, errorService, translate);
    }


    public ngOnInit(): void {
        this.shoppingList = this.shoppingListService.getCachedList();
        this.subscribeOnChangeCartConfirmationResponse();
        this._getCart();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.addInCartFlag();
    }

    public ngOnDestroy(): void {
        if (this.subscription$) {
         this.subscription$.unsubscribe();
        }
    }

    private _getCart(): void {
        this.subscription$ =
            this.cartService.getCart()
                .subscribe(
                    (cart: ShoppingCartResponseBody) => {
                        this.shoppingCartElements = cart.items;
                        this.addInCartFlag();
                    },
                    (err: ErrorInterface) => Observable.throw(err));
    }

    private subscribeOnChangeCartConfirmationResponse(): void {
        this.trackSubscription(
            this.cartService.onChangeCartConfirmationResponse.subscribe(() => {
                if (!this.changeDetector['destroyed']) {
                    this.changeDetector.detach();
                    this.changeDetector.detectChanges();
                }
            }));
    }

    /**
     * @desc Calls CartService and redirects back on response.
     */
    public removeFromCart(event: Event, item: Item): void {
        event.stopPropagation();
        this.showProductDetails(item, false);

        this.trackSubscription(
            this.cartService.removeItem(this.cartService.getItemID(item.ID), false)
                .subscribe((result: {}) => {
                    this.googleAnalyticsService.handleRemoveFromCart(item);
                    this.resetBusyFlag(true);
                    this.changeItemInCartValue(item, false);
                    this.changeCounts();
                }, (err: ErrorInterface) => this.resetBusyFlag(true)),
        );
    }

    /**
     * @desc Check is product exists in the cart
     * @param {SellingItemResponseBody} item
     * @returns {boolean} isExists
     */
    public existProductInCart(item: SellingItemResponseBody): boolean {
        return this.shoppingCartElements && this.shoppingCartElements.some((shoppingCartElement: ShoppingCartElement) => item.ID === shoppingCartElement.item.ID);
    }

    public addInCartFlag(): void {
        this.items && this.items.forEach((item: SellingItemResponseBody, index: number) => {
            (this.items[index] as SellingItemCart).isInCart = this.existProductInCart(item);
        });
    }

    /**
     * @desc Change item isInCart value
     * @param {SellingItemResponseBody} item
     * @param {boolean} value
     */
    public changeItemInCartValue(item: SellingItemResponseBody, value: boolean): void {
        let itemToChangeIndex: number;
        const itemToChange: SellingItemCart = this.items.find((itemToFind: SellingItemResponseBody, index: number) => {
            if (itemToFind.ID === item.ID) {
                itemToChangeIndex = index;
            }
            return itemToFind.ID === item.ID;
        }) as SellingItemCart;
        itemToChange.isInCart = value;
        this.items[itemToChangeIndex] = itemToChange;
    }

    /**
     * @desc Resets busy flag and throws to the console.
     * @param {boolean} isNotInCart
     */
    protected resetBusyFlag(isNotInCart: boolean = false) {
        this.busyCart = false;
    }

    /**
     * @desc If url param is non-empty string, calls self-titled service method.
     * @param {Event} event
     * @param url
     */
     showCertificate(event: Event, url: string): void {
        event.stopPropagation();

        if (url && (typeof url === 'string'))
            this.productDetailsService.showCertificate(url);
    }


    /**
     * @desc check if this product is on sale
     * @param {SellingItemResponseBody} item
     * @returns {boolean}
     */
    isSaleFn(item: SellingItemResponseBody) {
         return this.isSale = this.itemService.isSaleFn(item);
    }


    /**
     * @desc price for items
     * @param {SellingItemResponseBody} item
     * @returns {number}
     */
    itemPriceFn(item: SellingItemResponseBody) {
        return this.isPrice = this.itemService.itemPriceFn(item);
    }


    /**
     * @desc UnitsName for items
     * @param {SellingItemResponseBody} item
     * @returns {string}
     */
    priceUnitsNameFn(item: SellingItemResponseBody) {
        return this.isUnitsName = this.itemService.priceUnitsNameFn(item);
    }

    /**
     * @desc check if this product is in the basket (Shopping List)
     * @param {string} subcategoryEntryID
     * @returns {boolean}
     */
    isInSL(subcategoryEntryID: string): boolean {
        if (this.shoppingList.length === 0) return false;
        return this.shoppingList.findIndex((_item: ShoppingListItemInterface) => _item && _item.subcategory_entry_id === subcategoryEntryID) >= 0;
    }

    public handleShoppingListEvent(event: Event, item: SellingItemResponseBody): void {
        event.stopPropagation();
        if (this.isInSL(item.subcategoryEntryID)) {
            this.removeFromShoppingListEvent(item);
        } else {
            this.addToShoppingListEvent(item);
        }
    }

    /**
     * @desc add one product to Shopping List
     * @param {Event} event
     * @param {SellingItemResponseBody} item
     */
    public addToShoppingListEvent(item: SellingItemResponseBody): void {
        this.addToShoppingList(this.createShoppingListItem(item));
    }

    /**
     * @desc remove one product from Shopping List
     * @param {Event} event
     * @param {SellingItemResponseBody} item
     */
    public removeFromShoppingListEvent(item: SellingItemResponseBody): void {
        this.removeFromShoppingList(this.createShoppingListItem(item));
    }

    /**
     * @desc creates and returns shoppingListItem
     * @param {SellingItemResponseBody} item
     * @returns {ShoppingListItemInterface}
     */
    private createShoppingListItem(item: SellingItemResponseBody): ShoppingListItemInterface {
        return {
            name:                   item.subcategoryEntryName,
            subcategory_entry_id:   item.subcategoryEntryID,
            is_active:              true,
            is_custom:              false,
            descriptor:             item.subcategoryEntryName
        };
    }

    /**
     * @desc Adds item to shoppingList
     * @param {ShoppingListItemInterface} obj
     */
    private addToShoppingList(obj: ShoppingListItemInterface): void {
        const items: ShoppingListItemInterface[] = this.shoppingListService.addItem(obj, this.shoppingList);
        this.updateShoppingList(items);
    }

    /**
     * @desc Removes item from shoppingList
     * @param {ShoppingListItemInterface} obj
     */
    private removeFromShoppingList(obj: ShoppingListItemInterface): void {
        const items: ShoppingListItemInterface[] = this.shoppingListService.removeItem(obj, this.shoppingList);
        this.updateShoppingList(items);
    }

    /**
     * @desc Updates shoppingList
     * @param {ShoppingListItemInterface[]} items
     */
    private updateShoppingList(items: ShoppingListItemInterface[]): void {

        this.trackSubscription(
            this.shoppingListService.updateShoppingList(items)
                .subscribe(
                    (res: ShoppingList) => {
                        this.shoppingList = res.elements || [];
                        this.changeDetector.detectChanges();
                    },
                    (err: ErrorInterface) => {
                        // The error has already been processed in ShoppingListService
                        // and errorService.handleError(err) and the modal window was shown.
                        return null;
                    }
                ),
        );
    }


    /**
     * @desc if you want view one product
     * @param {{}} item
     */
    onClick(item: SellingItemResponseBody): Promise<SellingItemResponseBody> {
        this.productDetailsService.setProductDetailsFrom(this.search_from);
        let promise = this.showProductDetails(item, true).toPromise().then((res: SellingItemResponseBody) => res, (err) => err);
        return promise;
    }

    /***
     * @desc This function creates router property on your service.
     * @return {Router}
     */
    public get router(): Router {
        return this.injector.get(Router);
    }

    /**
     * @desc handle quantity choice
     * @param {HTMLInputElement} input
     * @param {number} qty
     */
    setQuantity(input: HTMLInputElement, id: string, qty: number) {
        setTimeout(() => {
            const formatedNumber = AppValues.NumberUSFormat({
                value: input.value,
                qty: qty,
                maxNumber: AppValues.PRODUCT_QTY_MAX,
                defaultValue: '',
                minimumFractionDigits: 0,
                maximumFractionDigits: 1
            });

            input.value = formatedNumber.formatedNumber;
            this.desiredQuantity[id] = formatedNumber.num;
        });
    }

    public showSellerDetails(event: Event, id: string): void {
        event.preventDefault();
        event.stopPropagation();
        this.shopperDetailsService.showDetails(id);
    }
}
