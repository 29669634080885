/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./product-search-list.component.ngfactory";
import * as i2 from "./product-search-list.component";
import * as i3 from "../../services/item.service";
import * as i4 from "../../shopping-list/shopping-list.service";
import * as i5 from "../../shopper-details/shopper-details.service";
import * as i6 from "../../cart/cart.service";
import * as i7 from "../../user/user.service";
import * as i8 from "../../product-details/product-details.service";
import * as i9 from "../../services/google-analytics.service";
import * as i10 from "../../modal/modal.service";
import * as i11 from "../../services/error.service";
import * as i12 from "@ngx-translate/core";
import * as i13 from "./item-search.component";
var styles_ItemSearchComponent = [];
var RenderType_ItemSearchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ItemSearchComponent, data: {} });
export { RenderType_ItemSearchComponent as RenderType_ItemSearchComponent };
export function View_ItemSearchComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "product-search-list", [], null, [[null, "addItemToSL$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addItemToSL$" === en)) {
        var pd_0 = (_co.addToShoppingListEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ProductSearchListComponent_0, i1.RenderType_ProductSearchListComponent)), i0.ɵdid(1, 770048, null, 0, i2.ProductSearchListComponent, [i3.ItemService, i4.ShoppingListService, i5.ShopperDetailsService, i0.Injector, i0.ChangeDetectorRef, i0.ElementRef, i6.CartService, i7.UserService, i8.ProductDetailsService, i9.GoogleAnalyticsService, i10.ModalService, i11.ErrorService, i12.TranslateService], { items: [0, "items"], shoppingList: [1, "shoppingList"], title: [2, "title"], search_from: [3, "search_from"], shoppingCartElements: [4, "shoppingCartElements"] }, { addItemToSL$: "addItemToSL$" }), i0.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 2, "product-search-list", [], null, [[null, "addItemToSL$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addItemToSL$" === en)) {
        var pd_0 = (_co.addToShoppingListEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ProductSearchListComponent_0, i1.RenderType_ProductSearchListComponent)), i0.ɵdid(4, 770048, null, 0, i2.ProductSearchListComponent, [i3.ItemService, i4.ShoppingListService, i5.ShopperDetailsService, i0.Injector, i0.ChangeDetectorRef, i0.ElementRef, i6.CartService, i7.UserService, i8.ProductDetailsService, i9.GoogleAnalyticsService, i10.ModalService, i11.ErrorService, i12.TranslateService], { items: [0, "items"], shoppingList: [1, "shoppingList"], title: [2, "title"], search_from: [3, "search_from"], shoppingCartElements: [4, "shoppingCartElements"] }, { addItemToSL$: "addItemToSL$" }), i0.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items["nearest"]; var currVal_1 = _co.shoppingList; var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 2).transform("product.search.list.title.nearest")); var currVal_3 = _co.search_from; var currVal_4 = _co.shoppingCartElements; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.items["other"]; var currVal_6 = _co.shoppingList; var currVal_7 = i0.ɵunv(_v, 4, 2, i0.ɵnov(_v, 5).transform("product.search.list.title.other")); var currVal_8 = _co.search_from; var currVal_9 = _co.shoppingCartElements; _ck(_v, 4, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_ItemSearchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "item-search-list", [], null, null, null, View_ItemSearchComponent_0, RenderType_ItemSearchComponent)), i0.ɵdid(1, 245760, null, 0, i13.ItemSearchComponent, [i4.ShoppingListService, i6.CartService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItemSearchComponentNgFactory = i0.ɵccf("item-search-list", i13.ItemSearchComponent, View_ItemSearchComponent_Host_0, { items: "items", search_from: "search_from", shoppingList: "shoppingList" }, {}, []);
export { ItemSearchComponentNgFactory as ItemSearchComponentNgFactory };
