/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bizipix-viewer.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angular2-draggable";
import * as i3 from "../shared/bizipix-menu/bizipix-menu.component.ngfactory";
import * as i4 from "../shared/bizipix-menu/bizipix-menu.component";
import * as i5 from "@angular/common";
import * as i6 from "./bizipix-viewer.component";
import * as i7 from "./bizipix-viewer.service";
var styles_BizipixViewerComponent = [i0.styles];
var RenderType_BizipixViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BizipixViewerComponent, data: {} });
export { RenderType_BizipixViewerComponent as RenderType_BizipixViewerComponent };
function View_BizipixViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ngx-dnd-container bp__cursor"], ["data-test-id", "cursor"], ["ngDraggable", ""]], null, [[null, "started"], [null, "touchmove"], [null, "mousemove"], [null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("started" === en)) {
        var pd_2 = (_co.findDimensions() !== false);
        ad = (pd_2 && ad);
    } if (("touchmove" === en)) {
        var pd_3 = (_co.onPositionChange($event) !== false);
        ad = (pd_3 && ad);
    } if (("mousemove" === en)) {
        var pd_4 = (_co.onPositionChange($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4931584, null, 0, i2.AngularDraggableDirective, [i1.ElementRef, i1.Renderer2], { bounds: [0, "bounds"], inBounds: [1, "inBounds"], ngDraggable: [2, "ngDraggable"] }, { started: "started" })], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 4); var currVal_1 = true; var currVal_2 = ""; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_BizipixViewerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "bizipix image"], ["data-test-id", "bizipixImage"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentBizipix.image_url; _ck(_v, 0, 0, currVal_0); }); }
function View_BizipixViewerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "bizipix instruction"], ["data-test-id", "bizipixImage"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = "../../assets/images/cart/no_image_icon.png"; _ck(_v, 0, 0, currVal_0); }); }
function View_BizipixViewerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bizipix-menu", [["class", "bizipix-menu"]], null, [[null, "selectedBizipixEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedBizipixEvent$" === en)) {
        var pd_0 = (_co.selectedBizipixEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_BizipixMenuComponent_0, i3.RenderType_BizipixMenuComponent)), i1.ɵdid(1, 49152, null, 0, i4.BizipixMenuComponent, [], { currentBizipix: [0, "currentBizipix"], imageData: [1, "imageData"], type: [2, "type"] }, { selectedBizipixEvent$: "selectedBizipixEvent$" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentBizipix; var currVal_1 = _co.imageData; var currVal_2 = _co.editable; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_BizipixViewerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 16777216, [[1, 3], ["container", 1]], null, 11, "div", [["class", "bp__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "bp__close"], ["data-test-id", "closeButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeViewer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(4, 0, [["myBounds", 1]], null, 6, "div", [["class", "bp__image"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BizipixViewerComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BizipixViewerComponent_2)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BizipixViewerComponent_3)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BizipixViewerComponent_4)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.imageData.length && _co.currentBizipix); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.imageData.length && _co.currentBizipix); _ck(_v, 8, 0, currVal_1); var currVal_2 = (!_co.imageData.length && !_co.currentBizipix); _ck(_v, 10, 0, currVal_2); var currVal_3 = (_co.imageData.length && _co.currentBizipix); _ck(_v, 12, 0, currVal_3); }, null); }
export function View_BizipixViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bizipix-viewer", [], null, null, null, View_BizipixViewerComponent_0, RenderType_BizipixViewerComponent)), i1.ɵdid(1, 245760, null, 0, i6.BizipixViewerComponent, [i7.BizipixViewerService, i1.ComponentFactoryResolver, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BizipixViewerComponentNgFactory = i1.ɵccf("bizipix-viewer", i6.BizipixViewerComponent, View_BizipixViewerComponent_Host_0, {}, {}, []);
export { BizipixViewerComponentNgFactory as BizipixViewerComponentNgFactory };
