/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../product-editor.sass.shim.ngstyle";
import * as i1 from "../../../assets/styles/sprite.sass.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./image-editor.component";
import * as i5 from "../../modal/modal.service";
import * as i6 from "@ngx-translate/core";
var styles_ImageEditorComponent = [i0.styles, i1.styles];
var RenderType_ImageEditorComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ImageEditorComponent, data: {} });
export { RenderType_ImageEditorComponent as RenderType_ImageEditorComponent };
function View_ImageEditorComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 0, "span", [["class", "sprite sprite-add_image_icon_2x"], ["data-test-id", "anImage"], ["role", "productImg"]], null, null, null, null, null))], null, null); }
export function View_ImageEditorComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 9, "section", [["class", "product-editor__title"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 4, "div", [["class", "image-container product-img"], ["data-test-id", "uploadImg"]], [[4, "background-image", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((!_co.disabled && _co.showUpload()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(2, 278528, null, 0, i3.NgClass, [i2.IterableDiffers, i2.KeyValueDiffers, i2.ElementRef, i2.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i2.ɵpod(3, { "background-image": 0 }), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ImageEditorComponent_1)), i2.ɵdid(5, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(6, 0, null, null, 1, "label", [["class", "input__label clearfix"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 0, "input", [["class", "input__text"], ["type", "text"]], [[8, "name", 0], [1, "data-test-id", 0], [8, "placeholder", 0], [8, "value", 0], [8, "disabled", 0]], [[null, "blur"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.finishRenaming({ event: $event, message: _co.message }) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.startRenaming({ event: $event }) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 0, "input", [["accept", "imageTypes"], ["data-test-id", "fileInp"], ["name", "photo"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onImgUpload($event.currentTarget) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 0, "span", [["role", "editable"], ["style", "display:none"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "image-container product-img"; var currVal_2 = _ck(_v, 3, 0, _co.imageUrl); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = !_co.imageUrl; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.imageUrl) + ")"); _ck(_v, 1, 0, currVal_0); var currVal_4 = (_co.dataTestId + "InnerContainer"); _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.placeholder; var currVal_6 = _co.dataTestId; var currVal_7 = _co.placeholder; var currVal_8 = _co.text; var currVal_9 = _co.disabled; _ck(_v, 7, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_ImageEditorComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "image-editor", [], null, null, null, View_ImageEditorComponent_0, RenderType_ImageEditorComponent)), i2.ɵdid(1, 49152, null, 0, i4.ImageEditorComponent, [i2.ElementRef, i5.ModalService, i6.TranslateService], null, null)], null, null); }
var ImageEditorComponentNgFactory = i2.ɵccf("image-editor", i4.ImageEditorComponent, View_ImageEditorComponent_Host_0, { imageUrl: "imageUrl", text: "text", placeholder: "placeholder", regExp: "regExp", minMaxLength: "minMaxLength", isEmpty: "isEmpty", errorMessage: "errorMessage", dataTestId: "dataTestId", disabled: "disabled", message: "message" }, { changeEvent$: "changeEvent$" }, []);
export { ImageEditorComponentNgFactory as ImageEditorComponentNgFactory };
