<div (appClickOutside)="isDelayPassed && onAddCustomItem()">
   <div class="new-item-title">
      <i class="icon-plus"></i>
      <input
         class="input__text"
         [formControl]="itemTitleControl"
         type="text"
         [placeholder]="isEditing ? 'Edit item title...' : 'New item title...'"
         (keyup.enter)="onAddCustomItem($event)"
         (focus)="setDelay()"
         (blur)="onBlur($event)"
         data-test-id="customItemInput"/>
   </div>

   <div *ngIf="isControlActivated" class="suggestions-container">
      <div *ngIf="isDataLoading">
         <div data-test-id="spinner" class="loader suggestions-loader">{{ "custom.item.suggestions.loading" | translate }}</div>
      </div>
      <div *ngIf="!isDataLoading" class="delimeter">
         <ul *ngIf="suggestions?.length" class="suggestions-list">
            <li
               *ngFor="let suggestion of suggestions; trackBy: trackByID; let i = index;"
               (click)="onSelectSuggestion(suggestion)"
            >
               <div [attr.data-test-id]="'suggestion_'+i">
                    <div class="sub_category">
                        <p>{{  suggestion.sub_category_name }}</p>
                        <div class="arrow-delimeter-container"><span class="arrow-delimeter sprite sprite-map_callout_arrow_2x"></span></div>
                         <span class="sub_category_entrie">
                            {{ suggestion.sub_category_entries[0].subcategory_entry_name }}
                        </span>
                    </div>
               </div>
            </li>
         </ul>
         <p
            *ngIf="!suggestions?.length && !isDataLoading"
            class="suggestion-item"
            data-test-id="item_comming_soon"
         >
            {{ "custom.item.suggestions.comming.soon" | translate }}
         </p>
      </div>
   </div>
</div>
