import { CommonModule }                     from '@angular/common';
import { NgModule, ModuleWithProviders }    from '@angular/core';
import { RouterModule }                     from '@angular/router';

import { ProductDetailsService }    from './product-details.service';
import { ProductDetailsComponent }  from './product-details.component';
import {ProductHeaderComponent }    from './product-header.component';
import { SharedModule }             from '../shared/shared.module';
import {TruncatePipe} from '../services/truncatePipe.service';
import {NgxTextOverflowClampModule} from 'ngx-text-overflow-clamp/dist';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        NgxTextOverflowClampModule
    ],
    declarations: [
        ProductDetailsComponent,
        ProductHeaderComponent,
        TruncatePipe
    ],
    exports: [
        ProductDetailsComponent,
        ProductHeaderComponent,
    ]
})
export class ProductDetailsModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ProductDetailsModule,
            providers: [ProductDetailsService, TruncatePipe]
        };
    }
}
