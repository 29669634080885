import { ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, OnInit, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalFilterArguments } from './review.interface';
import { AppReviewsService } from './reviews.service';
import { Observable } from 'rxjs/Rx';
import { UserService } from '../user/user.service';
import { Router } from '@angular/router';
import { ModalService } from '../modal/modal.service';
import { Location } from '@angular/common';
import { ReviewsUsersComponent } from './review-list/reviews-users';
import { ReviewsProductsComponent } from './review-list/reviews-products';
import { ReviewsPurchasesComponent } from './review-list/reviews-purchases';
import { AppFooterService } from '../app-footer/app-footer.service';
import { ReviewsListUsersComponent } from './review-list/reviews-list-users';
import { ReviewsListProductsComponent } from './review-list/reviews-list-products';
import { AdapterReview } from './adapter-review';
import AppValues from "../common/app.values";
import { TranslateService } from '@ngx-translate/core';
var ReviewsComponent = /** @class */ (function () {
    function ReviewsComponent(componentFactoryResolver, userService, reviewsService, footerService, modalService, router, translate, changeDetector, location) {
        var _a;
        this.componentFactoryResolver = componentFactoryResolver;
        this.userService = userService;
        this.reviewsService = reviewsService;
        this.footerService = footerService;
        this.modalService = modalService;
        this.router = router;
        this.translate = translate;
        this.changeDetector = changeDetector;
        this.mappings = (_a = {},
            _a[this.listFilter[0].value] = {
                instance: ReviewsUsersComponent,
                field: 'reviews_left_on_user'
            },
            _a[this.listFilter[1].value] = {
                instance: ReviewsProductsComponent,
                field: 'reviews_left_by_user'
            },
            _a[this.listFilter[2].value] = {
                instance: ReviewsPurchasesComponent,
                field: 'orders_to_review'
            },
            _a[this.listFilterProduct[0].value] = {
                instance: ReviewsListProductsComponent,
                field: 'product_review'
            },
            _a[this.listFilterSeller[0].value] = {
                instance: ReviewsListUsersComponent,
                field: 'seller_reviews'
            },
            _a[this.listFilterSeller[1].value] = {
                instance: ReviewsListUsersComponent,
                field: 'buyer_reviews'
            },
            _a);
        this.componentDestroyed$ = new Subject();
        this.selectedListFilter = this.listFilter[0].value;
        this.selectedPurchaseFilter = this.purchaseFilter[0].value;
        this.selectedRatingFilter = this.ratingFilter[0].value;
        this.isAvailableFilter = true;
        this.isAvailableFooter = true;
        this.isAvailableTabs = true;
        this.reviewsAllFiltersSubject = new Subject();
        this.location = location;
        this._w = window;
    }
    ReviewsComponent.prototype.ngOnInit = function () {
        this.itemUrl = new ItemReviewUrl().getItemUrl();
        this.userTitle = this.userService.getUser().title;
        this.locationReviews = decodeURIComponent(this._w.location.pathname);
        if (this.locationReviews === '/reviews-item') {
            this.setReviewListParamsAndRender();
        }
        else if (this.locationReviews === '/reviews') {
            this.reviewsAllFilters = this.filters;
            this.reviewsAllFiltersSubject.next(this.reviewsAllFilters);
            this.isAvailableFooter = true;
            this.checkIsAvailableFilter();
            this.setSelectedListFilter(this.listFilter);
            if (this.footerService.isSellerMode()) {
                this.getSellerReviews();
            }
            else {
                this.getBuyerReviews();
            }
        }
    };
    ReviewsComponent.prototype.setSelectedListFilter = function (listFilter) {
        var _this = this;
        listFilter.forEach(function (filter) {
            if (filter.active) {
                _this.selectedListFilter = filter.value;
            }
        });
    };
    ReviewsComponent.prototype.setReviewListParamsAndRender = function () {
        if (this.itemUrl[0] === 'sel') {
            this.reviewsAllFilters = this.filtersSeller;
            this.reviewItems = this.reviewsService.getUserReviews;
            this.selectedListFilter = this.listFilterSeller[0].value;
        }
        else if (this.itemUrl[0] === 'buy') {
            this.reviewsAllFilters = this.filtersBuyer;
            this.reviewItems = this.reviewsService.getUserReviews;
            this.selectedListFilter = this.listFilterBuyer[1].value;
        }
        else {
            this.reviewsAllFilters = this.filtersProduct;
            this.reviewItems = this.reviewsService.getProductReviews;
            this.selectedListFilter = this.listFilterProduct[0].value;
            this.isAvailableTabs = false;
        }
        this.reviewsAllFiltersSubject.next(this.reviewsAllFilters);
        this.isAvailableFooter = false;
        this.renderEditor();
    };
    ReviewsComponent.prototype.getSellerReviews = function () {
        var _this = this;
        this.modalService.showSpinner();
        this.reviewsService.getSellerAllReview()
            .subscribe(function (reviews) {
            _this.setReviews(reviews);
            _this.modalService.close();
        }, function (err) { return Observable.throwError(err); });
    };
    ReviewsComponent.prototype.getBuyerReviews = function () {
        var _this = this;
        this.modalService.showSpinner();
        this.reviewsService.getBuyerAllReview()
            .subscribe(function (reviews) {
            _this.setReviews(reviews);
            _this.modalService.close();
        }, function (err) { return Observable.throwError(err); });
    };
    ReviewsComponent.prototype.setReviews = function (reviews) {
        this.reviewItems = reviews;
        this.filteredItems = this.reviewItems[this.mappings[this.selectedListFilter].field];
        this.renderEditor();
        this.markReceivedeviews();
    };
    /**
     * Creates the Review List component.
     * @desc Starts from clearing the previous component, chooses a proper class according to the
     * desired type, renders chosen class and inject the context into it.
     * @private
     */
    ReviewsComponent.prototype.renderEditor = function (params) {
        this.container.remove();
        var component = this.mappings[this.selectedListFilter].instance;
        var factory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef = this.container.createComponent(factory);
        var instance = this.componentRef.instance;
        if (this.selectedListFilter === ModalFilterArguments.purchases) {
            this._filterResult(this.selectedPurchaseFilter);
        }
        else {
            this._filterResult(this.selectedRatingFilter);
        }
        this.filteredItems = this.sortItems(this.filteredItems);
        var selected_order_id;
        if (params && params.selected_order_id) {
            selected_order_id = params.selected_order_id;
        }
        instance.context = Object.assign({ callback: this._executeCommand.bind(this) }, { filteredItems: this.filteredItems }, { selected_order_id: selected_order_id });
    };
    ReviewsComponent.prototype.isAvailableHeaderFilter = function () {
        var _this = this;
        var isAvailable;
        this.reviewsAllFilters[0].forEach(function (f) {
            if (_this.selectedListFilter === 'purchases' && _this.selectedListFilter === f.value) {
                isAvailable = f.active;
            }
        });
        return !isAvailable;
    };
    /**
     * Dispatches the Product editor commands.
     * @param commandObject
     * @private
     */
    ReviewsComponent.prototype._executeCommand = function (commandObject) {
        this[Object.keys(commandObject)[0]](Object.values(commandObject)[0]);
    };
    /**
     * UIR_REVIEW_15: When the user clicks a buyer name in “Purchases” mode,
     * the system shall open the “Received as Buyer” tab of the user Reviews page.
     */
    ReviewsComponent.prototype.showUserReviews = function (params) {
        var _this = this;
        this.reviewsService.getUserReviewsAndRedirect({
            element: params.element,
            buyer_info: params.user_info,
            buyer_id: params.user_id,
            isCreating: false,
            is_buyer: params.is_buyer
        }).takeUntil(this.componentDestroyed$)
            .subscribe(function () {
            _this.modalService.close();
        }, function (err) { return null; });
    };
    /**
     * passage to the Request Creation page, a query that checks the ability to leave a comment (this.access.flag),
     * if product was not purchased then show warning
     */
    ReviewsComponent.prototype.addReview = function (params) {
        this.modalService.showSpinner();
        if (this.footerService.isSellerMode()) {
            this.prepareToAddReview(this.reviewsService.checkAccessToSendReview(params.buyer_id), params);
        }
        else {
            this.prepareToAddReview(this.reviewsService.checkAccessToSendReview(params.buyer_id, params.element.item_info.current_item_id), params);
        }
    };
    ReviewsComponent.prototype.backToHome = function () {
        this.reviewsService.backToHome();
    };
    ReviewsComponent.prototype.prepareToAddReview = function (createReviewMethod, params) {
        var _this = this;
        createReviewMethod.subscribe(function (access) {
            if (!access.flag) {
                return Observable.throwError(_this.modalService.warning({ title: _this.translate.instant('review.alert.title'), message: access.description, yesButtonText: _this.translate.instant('review.alert.confirm') }));
            }
            else {
                _this.getReviewsAndRedirectToCreateReview(params);
            }
        }, null);
    };
    ReviewsComponent.prototype.getReviewsAndRedirectToCreateReview = function (params) {
        var _this = this;
        if (this.footerService.isSellerMode()) {
            this.reviewsService.getUserReviewsAndRedirect({
                element: params.element,
                buyer_info: params.buyer_info,
                buyer_id: params.buyer_id,
                isCreating: true
            }).takeUntil(this.componentDestroyed$)
                .subscribe(function () {
                _this.modalService.close();
            }, function (err) { return null; });
        }
        else {
            this.reviewsService.getItemReviewAndRedirect({
                element: params.element,
                buyer_info: params.buyer_info,
                isCreating: true
            }).takeUntil(this.componentDestroyed$)
                .subscribe(function () {
                _this.modalService.close();
            }, function (err) { return null; });
        }
    };
    ReviewsComponent.prototype.showReviewedItem = function (element_id) {
        this.changeFilterArguments(this.listFilter[1].value);
        this._filterResult(this.ratingFilter[0].value);
        this.checkIsAvailableFilter();
        this.renderEditor({ selected_order_id: element_id });
    };
    ReviewsComponent.prototype.checkIsAvailableFilter = function () {
        this.isAvailableFilter = this.isAvailableHeaderFilter();
        this.changeDetector.detectChanges();
    };
    ReviewsComponent.prototype.markReceivedeviews = function () {
        if (this.selectedListFilter === this.listFilter[0].value) {
            this.reviewsService.markReviewsAsRead(this.reviewItems[this.mappings[this.selectedListFilter].field]);
        }
    };
    Object.defineProperty(ReviewsComponent.prototype, "filters", {
        get: function () {
            var filters = new Array;
            filters.push(this.listFilter);
            filters.push(this.ratingFilter);
            return filters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReviewsComponent.prototype, "filtersSeller", {
        get: function () {
            var filters = new Array;
            filters.push(this.listFilterSeller);
            filters.push(this.ratingFilter);
            return filters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReviewsComponent.prototype, "filtersBuyer", {
        get: function () {
            var filters = new Array;
            filters.push(this.listFilterBuyer);
            filters.push(this.ratingFilter);
            return filters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReviewsComponent.prototype, "filtersProduct", {
        get: function () {
            var filters = new Array;
            filters.push(this.listFilterProduct);
            filters.push(this.ratingFilter);
            return filters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReviewsComponent.prototype, "ratingFilter", {
        get: function () {
            return [
                { value: ModalFilterArguments.all, title: this.translate.instant('All'), active: true },
                { value: ModalFilterArguments.positive, title: this.translate.instant('Positive'), active: false },
                { value: ModalFilterArguments.neutral, title: this.translate.instant('Neutral'), active: false },
                { value: ModalFilterArguments.negative, title: this.translate.instant('Negative'), active: false }
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReviewsComponent.prototype, "purchaseFilter", {
        get: function () {
            return [
                { value: ModalFilterArguments.all, title: this.translate.instant('All'), active: true },
                { value: ModalFilterArguments.addReview, title: this.translate.instant('Add Review'), active: false },
                { value: ModalFilterArguments.reviewed, title: this.translate.instant('Reviewed'), active: false },
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReviewsComponent.prototype, "listFilter", {
        get: function () {
            return this.reviewsService.listFilterTabs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReviewsComponent.prototype, "listFilterSeller", {
        get: function () {
            return [
                { value: ModalFilterArguments.sellerReviews, title: this.translate.instant('review.filter.seller.recieved.seller'), active: true },
                { value: ModalFilterArguments.buyerReviews, title: this.translate.instant('review.filter.seller.recieved.buyer'), active: false },
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReviewsComponent.prototype, "listFilterBuyer", {
        get: function () {
            return [
                { value: ModalFilterArguments.sellerReviews, title: this.translate.instant('review.filter.buyer.recieved.seller'), active: false },
                { value: ModalFilterArguments.buyerReviews, title: this.translate.instant('review.filter.buyer.recieved.buyer'), active: true },
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReviewsComponent.prototype, "listFilterProduct", {
        get: function () {
            return [
                { value: ModalFilterArguments.product, title: '', active: true },
            ];
        },
        enumerable: true,
        configurable: true
    });
    ReviewsComponent.prototype.heightContainer = function () {
        var h = 60;
        if (this.isAvailableFooter) {
            h += 50;
        }
        if (this.isAvailableTabs) {
            h += 60;
        }
        return {
            'height': 'calc(100% - ' + h + 'px)',
            'max-height': 'calc(100% - ' + h + 'px)',
            'margin-bottom': 0
        };
    };
    /**
     * Method for revers sorted by date
     * @param reviews
     */
    ReviewsComponent.prototype.sortItems = function (reviews) {
        if (reviews && reviews.length) {
            if ("create_date" in reviews[0]) {
                return AdapterReview.convertReviewList(this.sortReviewsByDate(reviews));
            }
            if ("order_datetime" in reviews[0]) {
                return AdapterReview.convertOrderForReviewList(this.sortOrderReviewsByDate(reviews));
            }
            else
                return reviews;
        }
        else
            return reviews;
    };
    ReviewsComponent.prototype.sortOrderReviewsByDate = function (reviews) {
        return reviews.sort(function (orderA, orderB) {
            var orderDateTimeA = (Number(new Date(orderA.order_datetime)).toString());
            var orderDateTimeB = (Number(new Date(orderB.order_datetime)).toString());
            if (orderDateTimeB < orderDateTimeA) {
                return -1;
            }
            if (orderDateTimeB > orderDateTimeA) {
                return 1;
            }
            // if must be equal
            return 0;
        });
    };
    ReviewsComponent.prototype.sortReviewsByDate = function (reviews) {
        return reviews.sort(function (orderA, orderB) { return orderB.create_date.toString().localeCompare(orderA.create_date.toString()); });
    };
    ReviewsComponent.prototype.resetFilterByValue = function (filters, sortByValue) {
        filters.forEach(function (filter) {
            if (filter.value === sortByValue) {
                filters.forEach(function (f) {
                    f.active = false;
                });
            }
        });
        return filters;
    };
    ReviewsComponent.prototype.setActiveFilterByValue = function (filters, sortByValue) {
        var _this = this;
        filters.forEach(function (filter) {
            if (filter.value === sortByValue) {
                filter.active = true;
            }
            _this.ratingFilter.forEach(function (rating) {
                if (rating.value === sortByValue) {
                    _this.selectedRatingFilter = rating.value;
                }
            });
            _this.listFilter.forEach(function (rating) {
                if (rating.value === sortByValue) {
                    _this.selectedListFilter = rating.value;
                }
            });
            _this.purchaseFilter.forEach(function (rating) {
                if (rating.value === sortByValue) {
                    _this.selectedPurchaseFilter = rating.value;
                }
            });
            _this.listFilterSeller.forEach(function (rating) {
                if (rating.value === sortByValue) {
                    _this.selectedListFilter = rating.value;
                }
            });
        });
        return filters;
    };
    ReviewsComponent.prototype.changeFilterArguments = function (sortByValue) {
        var _this = this;
        this.reviewsAllFilters.map(function (filters) {
            var reseted_filters = _this.resetFilterByValue(filters, sortByValue);
            return _this.setActiveFilterByValue(reseted_filters, sortByValue);
        });
        this.reviewsAllFiltersSubject.next(this.reviewsAllFilters);
    };
    ReviewsComponent.prototype.onOptionsChange = function (options) {
        if (options.sortBy) {
            if (options.sortBy === ModalFilterArguments.purchases) {
                this.reviewsAllFilters[1] = this.purchaseFilter;
                this.changeFilterArguments(this.selectedPurchaseFilter);
            }
            if (options.sortBy === ModalFilterArguments.written || options.sortBy === ModalFilterArguments.received) {
                this.reviewsAllFilters[1] = this.ratingFilter;
                this.changeFilterArguments(this.selectedRatingFilter);
            }
            this.changeFilterArguments(options.sortBy);
            this.checkIsAvailableFilter();
            this._filterResult(options.sortBy);
            this.renderEditor();
        }
    };
    /**
     * Method for sort array by rating
     * @param sortBy
     * @private
     */
    ReviewsComponent.prototype._filterResult = function (sortBy) {
        var callback;
        if (!this.reviewItems)
            return;
        switch (sortBy) {
            case ModalFilterArguments.all:
                this.filteredItems = this.reviewItems[this.mappings[this.selectedListFilter].field];
                return;
            case ModalFilterArguments.positive:
                callback = function (a) { return a.stars >= 3.5; };
                break;
            case ModalFilterArguments.neutral:
                callback = function (a) { return a.stars < 3.5 && a.stars > 2.5; };
                break;
            case ModalFilterArguments.negative:
                callback = function (a) { return a.stars <= 2.5; };
                break;
            case this.listFilter[0].value:
                return;
            case this.listFilter[1].value:
                return;
            case this.listFilter[2].value:
                this.reviewsAllFilters[1] = this.purchaseFilter;
                this.changeFilterArguments(this.purchaseFilter[0].value);
                return;
            case ModalFilterArguments.addReview:
                callback = function (transaction) {
                    transaction.elements = transaction.elements.filter(function (order) { return !order.is_reviewed; });
                    return transaction;
                };
                break;
            case ModalFilterArguments.reviewed:
                callback = function (transaction) {
                    transaction.elements = transaction.elements.filter(function (order) { return order.is_reviewed; });
                    return transaction;
                };
                break;
            default:
                return;
        }
        this.filteredItems = AppValues.deepCopy(this.reviewItems)[this.mappings[this.selectedListFilter].field].filter(callback);
    };
    return ReviewsComponent;
}());
export { ReviewsComponent };
var ItemReviewUrl = /** @class */ (function () {
    function ItemReviewUrl() {
        this._w = window;
    }
    ItemReviewUrl.prototype.getItemUrl = function () {
        return decodeURIComponent(this._w.location.search).slice(this._w.location.search.indexOf('?') + 1).split('=');
    };
    ItemReviewUrl.prototype.getItemTitle = function () {
        return decodeURIComponent(this._w.location.search.substr(5));
    };
    ItemReviewUrl.prototype.getTitle = function (userTitle) {
        if (document.referrer.search(/inventory-list/i) ||
            document.referrer.search(/shopping-list/i)) {
            return 'Reviews';
        }
        else {
            return userTitle;
        }
    };
    return ItemReviewUrl;
}());
export { ItemReviewUrl };
