/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bizipix-editor.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./picker-block.component.ngfactory";
import * as i3 from "./picker-block.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./item-picker.component";
import * as i7 from "./bizipix-editor.service";
import * as i8 from "../services/geolocation.service";
import * as i9 from "../modal/modal.service";
import * as i10 from "../services/item.service";
var styles_ItemPickerComponent = [i0.styles];
var RenderType_ItemPickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemPickerComponent, data: {} });
export { RenderType_ItemPickerComponent as RenderType_ItemPickerComponent };
function View_ItemPickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "picker-block", [], [[1, "data-test-id", 0]], [[null, "itemSelectEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemSelectEvent$" === en)) {
        var pd_0 = (_co.onItemSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PickerBlockComponent_0, i2.RenderType_PickerBlockComponent)), i1.ɵdid(1, 49152, null, 0, i3.PickerBlockComponent, [], { item: [0, "item"], index: [1, "index"] }, { itemSelectEvent$: "itemSelectEvent$" })], function (_ck, _v) { var currVal_1 = _v.context.$implicit; var currVal_2 = _v.context.index; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = ("block-" + _v.context.index); _ck(_v, 0, 0, currVal_0); }); }
function View_ItemPickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemPickerComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.list; var currVal_1 = _co.trackByID; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ItemPickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "item-picker component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "nav", [["class", "app-header__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "navbar__left"], ["role", "itemPicker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["class", "nav__link"], ["data-test-id", "cancelLink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemSelect(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "navbar__center"], ["role", "itemPicker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "navbar__right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "item-picker__container component__container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemPickerComponent_1)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.list == null) ? null : _co.list.length); _ck(_v, 15, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("bizipix.item.picker.cancel")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("bizipix.item.picker.attach")); _ck(_v, 10, 0, currVal_1); }); }
export function View_ItemPickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "item-picker", [], null, null, null, View_ItemPickerComponent_0, RenderType_ItemPickerComponent)), i1.ɵdid(1, 245760, null, 0, i6.ItemPickerComponent, [i7.BizipixEditorService, i8.GeoLocationService, i9.ModalService, i10.ItemService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItemPickerComponentNgFactory = i1.ɵccf("item-picker", i6.ItemPickerComponent, View_ItemPickerComponent_Host_0, {}, { itemSelectEvent$: "itemSelectEvent$" }, []);
export { ItemPickerComponentNgFactory as ItemPickerComponentNgFactory };
