export class OperationSystemService {
    constructor() { }

    /**
     * EricL recommends testing for Android as a user agent also,
     * as the Chrome user agent string for tablets does not include "Mobi"
     * @return {boolean}
     */
    public isMobileDevice(): boolean {
        return /Mobi|Android/i.test(navigator.userAgent);
    }

    public isMobile(): RegExpMatchArray {
        return (this.regExpMatchArrayAndroid() || this.regExpMatchArrayBlackBerry() || this.regExpMatchArrayiOS() || this.regExpMatchArrayOpera() || this.regExpMatchArrayWindows());
    }

    public sumInnerWidthHeight(): number {
        return window.innerWidth + window.innerHeight;
    }

    public isDiffWindowParam(originalWindowParam: number): boolean {
        return Math.abs(originalWindowParam) <= Math.abs(this.sumInnerWidthHeight());
    }

    public regExpMatchArrayAndroid (): RegExpMatchArray {
        return navigator.userAgent.match(/Android/i) && navigator.userAgent.match(/Mobile/i);
    }
    public regExpMatchArrayBlackBerry (): RegExpMatchArray {
        return navigator.userAgent.match(/BlackBerry/i);
    }
    public regExpMatchArrayiOS (): RegExpMatchArray {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }
    public regExpMatchArrayOpera(): RegExpMatchArray {
         return navigator.userAgent.match(/Opera Mini/i);
    }
    public regExpMatchArrayWindows(): RegExpMatchArray {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    }
    public isOpera(): boolean {
        const isChromium: RegExpMatchArray = window['chrome'];
        const isOpera: boolean = navigator.userAgent.indexOf("OPR") > -1 || navigator.userAgent.indexOf("Opera") > -1;

        return isChromium !== null && isOpera === true;
    }
    public isEdge(): boolean {
        return /Edg/.test(navigator.userAgent);
    }
    public isChrome(): boolean {
        return navigator.userAgent.match(/chrome|chromium|crios/i) !== null;
    }
    public isSafari(): boolean {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
    public isInternetExplorer(): boolean {
        return /MSIE (\d+\.\d+);/.test(navigator.userAgent) || navigator.userAgent.indexOf("Trident/") > -1;
    }
    public isAvailableBrowser(): boolean {
        return (this.isOpera() || this.isEdge() || this.isInternetExplorer() || (!this.isChrome() && !this.isSafari()));
    }

}
