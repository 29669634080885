import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { ModalService } from '../modal/modal.service';
import {
    AfterBootResolverStrategy
} from './after-boot.strategy';
import { ErrorInterface } from '../services/error.service';
import { UserService } from '../user/user.service';
import { StorageService } from '../services/storage.service';
import { CookieService } from '../services/cookie.service';
import {ExpiredLoginLinkResolver} from './after-boot-strategy/expired-login-link-resolver';
import {InvalidSessionResolver} from './after-boot-strategy/invalid-session-resolver';
import {WasLoggedInResolver} from './after-boot-strategy/was-logged-in-resolver';

@Injectable()
export class AfterBootService {
    private isLoginLink: boolean = false;
    private loadingError: ErrorInterface;
    private location: Location;

    private startegy: AfterBootResolverStrategy;
    private userService: UserService;
    private wasUserLoggedIn: boolean = false;

    public constructor(
       private modalService: ModalService,
       private storageService: StorageService,
       private cookieService: CookieService,
       private injector: Injector
    ) {
       this.location = window.location;
    }

    public set isAlreadyLoggedIn(value: boolean) {
       this.wasUserLoggedIn = value;
    }

    public set isLoginLinkLaunch(value: boolean) {
       this.isLoginLink = value;
    }

    public set userServiceSave(service: UserService) {
       this.userService = service;
    }

    /**
     * @desc This function creates router property on your service.
     * @return {Router}
     */
    private get router(): Router {
        return this.injector.get(Router);
    }

    public set afterLoadingError(error: ErrorInterface) {
       this.loadingError = error;
    }

    private getStrategy(): AfterBootResolverStrategy {
       if (this.isLoginLink && this.loadingError
            && this.loadingError.status === 403) {
          this.isLoginLinkLaunch = false;
          this.loadingError = null;
          return new ExpiredLoginLinkResolver();
       }

       if (this.loadingError && this.loadingError.status === 403) {
          this.loadingError = null;
          return new InvalidSessionResolver();
       }

       if (this.wasUserLoggedIn) {
          return new WasLoggedInResolver();
       }
    }

    // tslint:disable-next-line:member-ordering
    public handleResolver(): void {
       this.startegy = this.getStrategy();

       if (this.startegy) {
          this.isAlreadyLoggedIn = this.startegy.resolveWarning(
             this.modalService,
             this.userService,
             this.router,
             this.wasUserLoggedIn
          );

          this.startegy = null;
       }

       this.clearPurchaseState();
    }

    private clearPurchaseState(): void {
       if (this.location.pathname !== '/cart-report') {
          const path: string = this.storageService.get('path');
          path && this.router.navigate([path]);

          this.cookieService.deleteCookie('payment_token');
          this.cookieService.deleteCookie('pay_key');
       }
       this.storageService.removePurchaseMemory();
    }
}
