/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./modal-image.component";
var styles_ModalImageComponent = [];
var RenderType_ModalImageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalImageComponent, data: {} });
export { RenderType_ModalImageComponent as RenderType_ModalImageComponent };
export function View_ModalImageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "modal__content"], ["data-test-id", "modal_image_content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "modal__img text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["data-test-id", "modal_image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "modal__btn"], ["data-test-id", "yesButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.context._close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context.message; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("modal.alert.confirm")); _ck(_v, 5, 0, currVal_1); }); }
export function View_ModalImageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "modal-image", [], null, null, null, View_ModalImageComponent_0, RenderType_ModalImageComponent)), i0.ɵdid(1, 49152, null, 0, i2.ModalImageComponent, [], null, null)], null, null); }
var ModalImageComponentNgFactory = i0.ɵccf("modal-image", i2.ModalImageComponent, View_ModalImageComponent_Host_0, {}, {}, []);
export { ModalImageComponentNgFactory as ModalImageComponentNgFactory };
