var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { MapSearchService } from './map-search.service';
import { MarketService } from '../market/market.service';
import { ShopperDetailsService } from '../shopper-details/shopper-details.service';
import { AppSearchService } from '../search/search.service';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
var MapSearchComponent = /** @class */ (function (_super) {
    __extends(MapSearchComponent, _super);
    function MapSearchComponent(mapSearchService, marketService, shopperDetailsService, searchService) {
        var _this = _super.call(this) || this;
        _this.mapSearchService = mapSearchService;
        _this.marketService = marketService;
        _this.shopperDetailsService = shopperDetailsService;
        _this.searchService = searchService;
        return _this;
    }
    /**
     * Gets prefetched search results from the Search service.
     */
    MapSearchComponent.prototype.ngOnInit = function () {
        var cachedData = this.searchService.cachedData();
        this.data = this.mapSearchService.getData();
        this.displayLocation = this.data.position['center'];
        this.searchNameForTitle = this.mapSearchService.getSearchName();
        if (!Object.keys(cachedData).length) {
            this.searchService.results = { markets: this.data.markets, sellers: this.data.sellers };
            this.searchService.addToLocalCaching();
        }
        else {
            this.updateDataOfMap(cachedData.data);
        }
    };
    /**
     * Sets current location equal to one, given by user profile.
     */
    MapSearchComponent.prototype.locate = function () {
        this.displayLocation = this.searchService.userGeolocation();
    };
    /**
     * Redirects 'show details' query to a specific service and saves the current map position.
     * @param obj
     */
    MapSearchComponent.prototype.showDetails = function (obj) {
        this.mapSearchService.savePosition(obj['currentPosition']);
        obj.type === 'market'
            ? this.marketService.showDetails(obj.data)
            : this.shopperDetailsService.showDetails(obj.ID);
    };
    /**
     * Pagination for markers.
     */
    MapSearchComponent.prototype.fetchMore = function (currentPosition) {
        var _this = this;
        this.displayLocation = currentPosition.center;
        this.data.position['center'] = currentPosition.center;
        this.data.position['center'].longitude = this.mapSearchService.getValidLongitude(currentPosition.center.longitude);
        this.data.position['center'].latitude = this.mapSearchService.getValidLatitude(currentPosition.center.latitude);
        if (currentPosition.zoom)
            this.data.position['zoom'] = currentPosition.zoom;
        this.trackSubscription(this.mapSearchService.fetchMore(currentPosition.center)
            .subscribe(function (searchResponse) {
            return _this.updateDataOfMap(searchResponse);
        }));
    };
    MapSearchComponent.prototype.updateDataOfMap = function (searchResponse) {
        this.searchService.results = searchResponse;
        this.data.markets = Object.assign(this.data.markets, searchResponse.markets);
        this.data.sellers = Object.assign(this.data.sellers, searchResponse.sellers);
    };
    return MapSearchComponent;
}(UnsubscribeOnDestroyAbsctractClass));
export { MapSearchComponent };
