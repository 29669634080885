import {Injectable, Injector} from '@angular/core';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import { UserService }          from '../user/user.service';
import { CartService } from '../cart/cart.service';
import { Observable } from 'rxjs';
import { GetCounterResponse } from '../../../swagger-gen__output_dir';



@Injectable()
export class AppHeaderService {
    /**
     * Serves interactions for the AppFooter and the included components.
     */
    public type: string;


    constructor(
        private cartService: CartService,
        private injector:    Injector,
    ) {
    }

    public get user_service(): UserService {
        return this.injector.get(UserService);
    }

    public get countsChanges(): Observable<GetCounterResponse> {
        return this.cartService.countsChanges;
    }
}
