import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appInnerHtmlClickHandler]'
})
export class InnerHtmlClickHandlerDirective {
  @HostListener('click', ['$event']) onClick($event: Event) {
      const target: HTMLElement = $event.target as HTMLElement;
      const attributes: NamedNodeMap = target.attributes;
      const redirectUrl: string = attributes && attributes['routerlink'] ? attributes['routerlink'].value : '';
      
      if (redirectUrl) {
          this.router.navigate([redirectUrl]);
      }
  }

  public constructor(private router: Router) {}
}
