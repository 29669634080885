/**
 * BiziBaza app
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CommonErrorResonse } from '../model/commonErrorResonse';
import { InlineResponse200 } from '../model/inlineResponse200';
import { ItemsFromMarketResponse } from '../model/itemsFromMarketResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MarketService {

    protected basePath = 'https://dev-backend.bizibaza.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get items from specific market
     * 
     * @param search_query Text for querying item
     * @param latitude User&#x27;s location latitude
     * @param longitude User&#x27;s location longitude
     * @param page Current page
     * @param count Number of records on the current page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchItemGet(search_query: string, latitude: number, longitude: number, page: number, count: number, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public searchItemGet(search_query: string, latitude: number, longitude: number, page: number, count: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public searchItemGet(search_query: string, latitude: number, longitude: number, page: number, count: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public searchItemGet(search_query: string, latitude: number, longitude: number, page: number, count: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (search_query === null || search_query === undefined) {
            throw new Error('Required parameter search_query was null or undefined when calling searchItemGet.');
        }

        if (latitude === null || latitude === undefined) {
            throw new Error('Required parameter latitude was null or undefined when calling searchItemGet.');
        }

        if (longitude === null || longitude === undefined) {
            throw new Error('Required parameter longitude was null or undefined when calling searchItemGet.');
        }

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling searchItemGet.');
        }

        if (count === null || count === undefined) {
            throw new Error('Required parameter count was null or undefined when calling searchItemGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (search_query !== undefined && search_query !== null) {
            queryParameters = queryParameters.set('search_query', <any>search_query);
        }
        if (latitude !== undefined && latitude !== null) {
            queryParameters = queryParameters.set('latitude', <any>latitude);
        }
        if (longitude !== undefined && longitude !== null) {
            queryParameters = queryParameters.set('longitude', <any>longitude);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/search_item`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get items from specific market
     * 
     * @param market_id market id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchItemInMarketGet(market_id: string, observe?: 'body', reportProgress?: boolean): Observable<ItemsFromMarketResponse>;
    public searchItemInMarketGet(market_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemsFromMarketResponse>>;
    public searchItemInMarketGet(market_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemsFromMarketResponse>>;
    public searchItemInMarketGet(market_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (market_id === null || market_id === undefined) {
            throw new Error('Required parameter market_id was null or undefined when calling searchItemInMarketGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (market_id !== undefined && market_id !== null) {
            queryParameters = queryParameters.set('market_id', <any>market_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ItemsFromMarketResponse>(`${this.basePath}/search_item_in_market`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
