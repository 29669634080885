/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./analytics.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./analytics-header.component.ngfactory";
import * as i4 from "./analytics-header.component";
import * as i5 from "../app-footer/app-footer.service";
import * as i6 from "@angular/common";
import * as i7 from "./analytics.component";
import * as i8 from "@angular/router";
var styles_AnalyticsComponent = [i0.styles];
var RenderType_AnalyticsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AnalyticsComponent, data: {} });
export { RenderType_AnalyticsComponent as RenderType_AnalyticsComponent };
function View_AnalyticsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "orders-menu__item"], ["data-test-id", "pending-orders"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("analytics.header.pendingOrders")); _ck(_v, 1, 0, currVal_0); }); }
export function View_AnalyticsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "component analytics-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "analytics-header", [], null, [[null, "goBackEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("goBackEvent$" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AnalyticsHeaderComponent_0, i3.RenderType_AnalyticsHeaderComponent)), i1.ɵdid(2, 49152, null, 0, i4.AnalyticsHeaderComponent, [i5.AppFooterService], { analyticsUrl: [0, "analyticsUrl"] }, { goBackEvent$: "goBackEvent$" }), (_l()(), i1.ɵeld(3, 0, null, null, 5, "ul", [["class", "orders-menu"], ["data-test-id", "orders-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "orders-menu__item"], ["data-test-id", "history"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalyticsComponent_1)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "/orders"; _ck(_v, 2, 0, currVal_0); var currVal_2 = (_co.mode === "seller"); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("analytics.header.history")); _ck(_v, 5, 0, currVal_1); }); }
export function View_AnalyticsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "analytics", [], null, null, null, View_AnalyticsComponent_0, RenderType_AnalyticsComponent)), i1.ɵdid(1, 49152, null, 0, i7.AnalyticsComponent, [i5.AppFooterService, i8.Router, i6.Location], null, null)], null, null); }
var AnalyticsComponentNgFactory = i1.ɵccf("analytics", i7.AnalyticsComponent, View_AnalyticsComponent_Host_0, {}, {}, []);
export { AnalyticsComponentNgFactory as AnalyticsComponentNgFactory };
