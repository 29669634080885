import {
    Component, OnInit,
    ViewContainerRef, ViewChild,
    ComponentRef,
    ComponentFactoryResolver
} from '@angular/core';
import { Location }                     from '@angular/common';
import { Subject }                      from 'rxjs';

import { EventInterface }               from '../interfaces/market.interface';
import { EventService }                 from './event.service';
import { ExistingEventEditorComponent } from './editor/existing-event-editor.component';
import { ExistingItemEditorComponent }  from './editor/existing-item-editor.component';
import { NewEventEditorComponent }      from './editor/new-event-editor.component';
import { NewItemEditorComponent }       from './editor/new-item-editor.component';
import { ProductEditorParentClass }     from './editor/product-editor-abstract.class';
import { ProductEditorService }         from './product-editor.service';
import { DateRange, SaveProduct} from '../interfaces/product.interface';
import { UnitInterface }                from '../interfaces/unit.interface';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {Event, EventToUpdate} from '../../../swagger-gen__output_dir';
import {EventToCreate} from '../../../swagger-gen__output_dir/model/eventToCreate';
import {ErrorInterface} from '../services/error.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector:       'product-editor-strategy',
    styleUrls:      ['./product-editor.sass'],
    template:       `<div #container></div>`
})
/**
 * Implements strategy to choose product editor for an event\item, new one or existing.
 */
export class ProductEditorStrategyComponent implements OnInit {
    @ViewChild('container', { read: ViewContainerRef })

    container:      ViewContainerRef;
    item:           SellingItemResponseBody;
    newEvent:       EventInterface;
    units:          UnitInterface[];
    defaultShippingTerms: string;


    private componentDestroyed$:    Subject<boolean> = new Subject();
    private componentRef:           ComponentRef<{}>;
    private mappings                = {
        'existingEvent':    ExistingEventEditorComponent,
        'existingItem':     ExistingItemEditorComponent,
        'newEvent':         NewEventEditorComponent,
        'newItem':          NewItemEditorComponent,
    };


    constructor(
        private componentFactoryResolver:   ComponentFactoryResolver,
        private eventService:               EventService,
        private location:                   Location,
        private productEditorService:       ProductEditorService,
        private translate:                  TranslateService,
    ) {}


    /**
     * @decs Gets item (or event item) object from the service, fetches units of quantity measure
     * via the service, renders the product editor on response.
     */
    ngOnInit() {
        this.item       = this.productEditorService.getItemAndNewEvent().item;
        this.newEvent   = this.productEditorService.getItemAndNewEvent().newEvent;

        this.productEditorService.getUnits()
            .takeUntil(this.componentDestroyed$)
            .subscribe((units: UnitInterface[]) => {
                this.units = Array.from(units);

                this._renderEditor(this.productEditorService.getType(),
                    (this.newEvent && this.newEvent.item) || this.item,
                    { from: this.item.valitFrom, to: this.item.validTill }
                );
            }, (err: ErrorInterface) => err);
    }


    /**
     * Creates the Product editor component.
     * @desc Starts from clearing the previous editor, chooses a proper class according to the
     * desired type, renders chosen class and inject the context into it.
     * @param {string} type
     * @param {SellingItemResponseBody} item
     * @param {ParantProductValidRange} validRange
     * @private
     */
    _renderEditor(type: string, item: SellingItemResponseBody, dateRange: DateRange): void {
        this.container.remove();

        let component       = this.mappings[type];

        this.productEditorService.set_is_creating_item(type);

        let factory         = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef   = this.container.createComponent(factory);

        let instance        = <ProductEditorParentClass> this.componentRef.instance;

        let preparatory_item_for_template = this.preparatory_item_for_template(item);

        instance.context    = Object.assign(
                 {  callback:           this._executeCommand.bind(this)},
                {  item:                preparatory_item_for_template},
                {  units:               this.units},
                {  dateRange:           dateRange}
            );
    }


    preparatory_item_for_template(item: SellingItemResponseBody): SellingItemResponseBody {
        // Event Total Qty for template was changed
        item.events.forEach((event: Event) => {
            if (event.item) {
                event.item.qty = item.qty;
            }
        });

        return item;
    }


    // ------------ Editor Commands ----------------
    /**
     * Dispatches the Product editor commands.
     * @param commandObject
     * @private
     */
    _executeCommand(commandObject: {}): void {
        this[Object.keys(commandObject)[0]](Object.values(commandObject)[0]);
    }


    /**
     * Executes 'showEvent' command by calling the renderer.
     * @param obj
     */
    showEvent(obj: {event: EventInterface}) {
        this.item = this.productEditorService.getItemAndNewEvent().item;

        this._renderEditor('existingEvent', obj.event.item,
            { from: this.item.valitFrom, to: this.item.validTill });
    }


    /**
     * Executes 'exitEventEditor' command by calling the renderer.
     */
    exitEventEditor() {
        this.item = this.productEditorService.getItemAndNewEvent().item;

        this._renderEditor('existingItem', this.item,
            { from: this.item.valitFrom, to: this.item.validTill });
    }


    /**
     * Leaves the editor page without saving.
     */
    cancelEventCreation() {
        this.location.back();
    }


    /**
     * Executes `saveItem` command by delegating saving to the service.
     * @param {SaveProduct} obj
     */
    saveItem(obj: SaveProduct): void {
        const newObj: SaveProduct = obj;

        if (typeof newObj.event !== 'undefined') {
           newObj.callback = newObj.event === null
               ? () => this.addEvent(null)
               : () => this.showEvent(newObj.event);
        }
        if (newObj.item.shipping_terms === '' ) {
            newObj.item.shipping_terms = this.productEditorService.defaultShippingTerms;
        }
        this.productEditorService.save(newObj);
    }


    /**
     * Executes `saveEvent` command by delegating saving to the service. Renders the editor
     * for the parent item  on response.
     */
    saveEvent(action?: string): void {
        this.productEditorService.updateItem(this.item)
            .takeUntil(this.componentDestroyed$)
            .subscribe((item: SellingItemResponseBody) => {
                this.newEvent = null;

                this.exitEventEditor();

                this.productEditorService.set_changed_item(item);

                let props = {msg: this._formatMsgForSuccessfullyEvent(action)};
                if (action && action === 'remove') {
                    props = Object.assign(props, {remove: true});
                }

                this.productEditorService.showSuccessMessage(props);
            }, (err: ErrorInterface) => err);
    }

    /**
     * Shows modal success message.
     * @private
     */
    _formatMsgForSuccessfullyEvent(action: string) {
        return (action === 'add_event')
            ? this.translate.instant('product.editor.event.success.create')
            : this.translate.instant('product.editor.event.success.update');

    }


    /**
     * Executes `updateEvent` command by updating the items' events.
     * Doesn't allow to update an event for an inactive item.
     */
    updateEvent(itemOfEvent: SellingItemResponseBody): void {
        if (!this._isItemActive()) return;

        const body = {
            ID:             itemOfEvent.ID,
            from_date:      itemOfEvent.valitFrom,
            price:          itemOfEvent.price,
            qty_for_event:  itemOfEvent.current_quantity,
            sale_from_date: itemOfEvent.saleDateFrom,
            sale_is_on:     itemOfEvent.saleIsOn,
            sale_price:     itemOfEvent.salePrice,
            sale_till_date: itemOfEvent.saleDateTill,
            till_date:      itemOfEvent.validTill,
        } as EventToUpdate;

        this.eventService.updateEvent(body)
            .takeUntil(this.componentDestroyed$)
            .subscribe((res: {}) => {
                this.saveEvent('update_event');
            }, (err: ErrorInterface) => err);
    }


    /**
     * Executes `addEvent` by requesting the service to start search for markets.
     * Doesn't allow to add an event for an inactive item.
     * @param _null
     */
    addEvent(_null: null) {
        if (!this._isItemActive() || !this._isAmountSufficient()) return;

        this.productEditorService.searchForMarkets();
    }


    /**
     * Executes `createEvent` command
     * @desc by requesting the Event service. Pushes new event object into the parent item's events array
     * on response. Then saves the event.
     * @param item
     */
    createEvent(item: SellingItemResponseBody): void {
        const body = {
            qty_for_event:  item.current_quantity,
            sale_is_on:     item.saleIsOn,
            sale_from_date: item.saleDateFrom,
            sale_price:     item.salePrice,
            price:          item.price,
            sale_till_date: item.saleDateTill,
            market_id:      this.newEvent ? this.newEvent.market_id : '',
            till_date:      item.validTill,
            item_id:        item.ID,
            from_date:      item.valitFrom,
        } as EventToCreate;

        this.eventService.addEvent(body)
            .takeUntil(this.componentDestroyed$)
            .subscribe((res: {}) => {
                this._pushNewEvent(item, res['new_event']);
                this.saveEvent('add_event');
            }, (err: ErrorInterface) => err);
    }


    /**
     * Executes `removeEvent`.
     * @desc Calls the service to remove an event. Removes the event from the item's event list and
     * calls the service to update the item on response.
     * @param index
     */
    removeEvent(index: number): void {
        const event = this.item.events[index];

        this.eventService.removeEvent({market_id: event['market_id'], parent_item_id: this.item.ID})
            .takeUntil(this.componentDestroyed$)
            .subscribe((action: boolean) => {
                if (action) {
                    this.item.events.splice(index, 1);
                    this._restoreQuantity(event);

                    this.saveEvent('remove');
                }
            }, (err: ErrorInterface) => err);
    }


    // --------------- Helper to serve the Editor commands execution  -------------------------------------
    /**
     * Creates event data strucnure and pushes in into the parent item's events array.
     * @param eventItem
     * @param res
     * @private
     */
    _pushNewEvent(eventItem: SellingItemResponseBody, res: {}) {
        const _eventItem = Object.assign(eventItem, {
            ID:         res ? res['item_id'] : '',
            saleIsOn:   eventItem.saleIsOn,
            draft:      false,
        });

        this.item.events.push(Object.assign(this.newEvent, {
            item:       _eventItem,
            market_id:  res ? res['market_id'] : '',
            item_id:    res ? res['item_id'] : ''
        }));
    }


    /**
     * Checks if the item is not in draft.
     * @returns {boolean}
     * @private
     */
    _isItemActive(): boolean {
        if (this.item.draft)
            this.productEditorService.showWarningMessage(this.translate.instant('product.editor.warning.add'));

        return !this.item.draft;
    }


    _isAmountSufficient() {
        if (this.item.qty <= 0) {
            this.productEditorService.showWarningMessage(this.translate.instant('product.editor.warning.suttisfy'));

            return false;
        }

        return true;
    }


    /**
     * Adds to the parent item's current qty the current quantity of removed event.
     * @param item
     * @private
     */
    _restoreQuantity(event: Event): void {
        this.item.current_quantity += event['item'].current_quantity;
    }

}
