
        <div class="component">
            <div *ngIf="item" class="details__container component__container">
                <product-header
                    [item]="item"
                    [options]="options"
                    [isInReportList]="isInReportList"
                    (toggleWatchList)="toggleWatchListStatus(item)"
                    (toggleFavList)="toggleFavListStatus()"
                    (quantityChange)="handleQuantityChange($event)"></product-header>

                    <ul class="details__info-container">
                    <li class="details__analytics__data" *ngIf="options?.readOnly">
                        <section>
                            <h5>{{ "product.details.order.code" | translate }} </h5>
                            <p>{{options.idOrder}}</p>
                        </section>
                        <section>
                            <h5>{{ "product.details.date" | translate }} </h5>
                            <h5>{{options.date * 1000 | date: 'MMM dd/yyyy h:mm a' }}</h5>
                        </section>
                    </li>
                    <button *ngIf="!options?.readOnly" class="details__reviews" data-test-id="showReviews" type="button"
                        (click)="showReviews(item.ID, item.title, ( item.photoURL || '../assets/images/cart/no_image_icon.png') )">
                        <h4>{{ "product.details.product.reviews" | translate }}</h4>

                        <div class="details__reviews__stars-container">
                            <rating ngDefaultControl data-test-id="detailRateStars" [rating]="item.rate"
                                    [float]="true"
                                    [readonly]="true"></rating>
                            <p data-test-id="detailRate">( {{reviewsTotal}} )</p>
                            <i class="icon-disclosure_indicator_icon" role="nav"></i>
                        </div>
                    </button>
                    <li class="details__sellername" (click)="showShopper(item.sellerID)" data-test-id="showSeller">
                        <div class="seller-img">
                            <span *ngIf="!item.sellerPictureURL" class="sprite sprite-no_image_icon"></span>
                            <img *ngIf="item.sellerPictureURL" class="details__img" [src]="item.sellerPictureURL" alt="seller picture">
                        </div>
                        <div class="seller-holder">
                            <h4><b>{{item.sellerName}}</b></h4>
                            <span class="details__productNo" *ngIf="item?.productNo">{{ "product.details.code" | translate }} {{item.productNo}}</span>
                            <button data-test-id="searchForShopper" (click)="searchFor($event, item.sellerID, item.sellerName)" class="link-green link-green--inline" type="button">
                                {{ "product.details.shopper.products" | translate }}
                            </button>
                        </div>
                        <i class="icon-disclosure_indicator_icon" role="nav"></i>
                    </li>
                    <li class="details__alert" *ngIf="item && !options?.readOnly">
                        <h5>{{ "product.details.price.alert" | translate }}</h5>
                        <div class="details__alert__switch">
                            <p>{{ "product.details.alert" | translate }} </p>
                            <input class="details__alert__qty" data-test-id="detailAlertQty"
                                   [value]="priceAlertValue" type="number" name="detailAlertQty"
                                   maxlength="5" min="0" step="0.01" placeholder="0"
                                   (blur)="setPriceAlert($event.target.value, $event.type)"
                                   (keyup.enter)="setPriceAlert($event.target.value, $event.type)"
                                   [disabled]="!isPriceAlertOn"
                                   (paste)="false">

                            <rect-switch data-test-id="priceAlertSwitch"
                                         [state]="isPriceAlertOn"
                                         (stateEvent$)="togglePriceAlert($event)"></rect-switch>
                        </div>
                    </li>
                    <li *ngIf="item && !options?.readOnly">
                        <h5>{{ "product.details.quantity" | translate }}</h5>
                        <p data-test-id="detailCurrentQty">{{ "product.details.available" | translate }} {{item.current_quantity | number}} {{item.qtyUnitName}}</p>
                    </li>
                    <li class="details__organic" [ngClass]="(item.organic && item.nonGMO) ? 'yes' : 'no'">
                        <div class="details__gmo">
                            <h5>{{ "product.details.non.gmo" | translate }}</h5>
                            <button data-test-id="showGMOCertificate" type="button"
                                 (click)="item.nonGMO && showCertificate(item.nonGMO_certificate_image)">
                                <i *ngIf="item.nonGMO" class="icon-certificate_icon"></i>
                                <p *ngIf="!item.nonGMO">{{ "product.details.no" | translate }}</p>
                            </button>
                        </div>
                        <div class="details__org">
                            <h5>{{ "product.details.organic" | translate }}</h5>
                            <button data-test-id="showOrganicCertificate" type="button"
                                 (click)="item.organic && showCertificate(item.organic_certificate_image)">
                                <i *ngIf="item.organic" class="icon-certificate_icon"></i>
                                <p *ngIf="!item.organic">{{ "product.details.no" | translate }}</p>
                            </button>
                        </div>
                    </li>
                    <li class="details__market-pick">
                        <h5>{{ "product.details.delivery.offered" | translate }}</h5>
                        <p>{{item.deliveryOffered ? "YES" : "NO" }}</p>
                    </li>
                    <li class="details__market-pick details__market-pick--with-content">
                        <div class="details__market-pick details__market-pick--nested">
                            <h5>{{ "product.details.pickup.offered" | translate }}</h5>
                            <p>{{item.marketPickOffered ? "YES" : "NO"}}</p>
                        </div>
                        <div>
                            <div>
                                <h5 class="details__pickup-title">{{ "product.details.market" | translate }} {{ (market === true) ? '' : 'N/A' }}</h5>
                                <div>
                                    <h5 *ngIf="item['market']" class="clip"> {{item['market'].market_title}}</h5>
                                    <h5 *ngFor="let event of item.events; trackBy: trackByID;" class="clip">{{event.title}}</h5>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h5>{{ "product.details.country.origin" | translate }}</h5>
                        <p>{{item.originCountry}}</p>
                    </li>
                    <li *ngIf="item.productionDate">
                        <h5>{{ "product.details.production.date" | translate }}</h5>
                        <p data-test-id="productionDate">{{item.productionDate * 1000 | date: formatNumericDate}}</p>
                    </li>
                    <li class="details__description">
                        <h5>{{ "product.details.description" | translate }}</h5>
                        <p>{{item.description}}</p>
                    </li>
                    <li class="details__description">
                        <h5>{{ "product.details.shipping.terms" | translate }}</h5>
                        <p>{{item.shipping_terms}}</p>
                    </li>
                </ul>
            </div>

            <div class="product-actions app__footer" *ngIf="item && !options?.readOnly">
                <footer *ngIf="isMarketAllowed" class="details__container__btn-group">
                    <button (click)="cartItemID ? removeFromCart($event, item) : addToCart($event, item)" class="cart" type="button"
                            [disabled]="busyCart" data-test-id="detailAddToCart">
                        <span *ngIf="!cartItemID && !busyCart">{{ "product.details.add.cart" | translate }}</span>
                        <span *ngIf="cartItemID && !busyCart">{{ "product.details.remove.cart" | translate }}</span>
                        <span *ngIf="busyCart"><i class="icon-spinner2"></i></span>
                    </button>
                    <button class="buy" data-test-id="detailBuyNow" type="button"
                            (click)="addToCart($event, item, true)">
                        {{ "product.details.buy.now" | translate }}
                    </button>
                </footer>
            </div>
        </div>
    