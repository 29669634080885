import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';
import { FormsModule }              from '@angular/forms';
import { RouterModule }             from '@angular/router';

import { MultiKeyDirective }            from './multi-key.directive';

import { BizipixMenuComponent }         from './bizipix-menu/bizipix-menu.component';
import { DateInputComponent }           from './date-input/date-input.component';
import { DraggableDirective }           from './draggable/draggable.directive';
import { ImgLoaderComponent }           from './img-loader/img-loader.component';
import { MediaEditorComponent }         from './media-editor/media-editor.component';
import { PasswordResetEditorComponent } from './password-reset-editor/password-reset-editor.component';
import { RatingComponent }              from './rating/rating.component';
import { RatingDisplayComponent }       from './rating-display/rating-display.component';
import { RectSwitchComponent }          from './rect-switch/rect-switch.component';
import { SafeTextareaComponent }        from './safe-textarea/safe-textarea.component';
import { StateButtonComponent }         from './state-button.component';
import { MapInfoComponent }             from './map-info/map-info.component';
import { AccordionComponent }           from './accordion/accordion.component';
import { MarketTableComponent }         from './market-table/market-table.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { FavWatchListComponent } from './fav-watch-list/fav-watch-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {PasswordFieldComponent} from './password-field/password-field.component';
import {CheckboxComponent} from "./checkbox-field/checkbox-field";


@NgModule({
    imports:      [
        CommonModule,
        RouterModule,
        FormsModule,
        TranslateModule
    ],
    declarations: [
        BizipixMenuComponent,
        CheckboxComponent,
        DateInputComponent,
        ImgLoaderComponent,
        MapInfoComponent,
        MultiKeyDirective,
        MediaEditorComponent,
        PasswordFieldComponent,
        PasswordResetEditorComponent,
        RatingComponent,
        RatingDisplayComponent,
        RectSwitchComponent,
        SafeTextareaComponent,
        StateButtonComponent,
        AccordionComponent,
        MarketTableComponent,
        ClickOutsideDirective,
        FavWatchListComponent,
        DraggableDirective
    ],
    exports:      [
        BizipixMenuComponent,
        CheckboxComponent,
        DateInputComponent,
        ImgLoaderComponent,
        MapInfoComponent,
        MultiKeyDirective,
        MediaEditorComponent,
        PasswordFieldComponent,
        PasswordResetEditorComponent,
        RatingComponent,
        RatingDisplayComponent,
        RectSwitchComponent,
        SafeTextareaComponent,
        StateButtonComponent,
        AccordionComponent,
        MarketTableComponent,
        ClickOutsideDirective,
        FavWatchListComponent,

        TranslateModule
    ]
})
export class SharedModule {
}
