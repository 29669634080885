
        <div class="il__container" [style.background-image]="'url('+imageUrl+')'">

            <div class="controls">
                <span (click)="showUpload()" class="control" data-test-id="uploadBtn">
                    <span class="sprite sprite-description_3x"></span>
                </span>
            </div>

            <div class="title">
                <h4 role="editable"
                    data-test-id="title"
                    contenteditable="true"
                    (blur)="finishRenaming({event: $event})"
                    (focus)="startRenaming({event: $event})">{{text}}</h4>
            </div>

            <input name="photo" (change)="onImgUpload($event.currentTarget)" type="file" accept="imageTypes" data-test-id="fileInp">
        </div>
    