        
        <picker-cell 
                [data]="item"
                [index]="index"
                (clickEvent$)="onItemClick($event)" role="parentItem"></picker-cell>
        
        <accordion class="abstract-list__li__footer" 
            [indexOfItem]="index"
            [title]="item['nestedMarkets'].length + ' markets found, nearest in '" 
            [label]="item['nestedMarkets'][0].distance + 'mi'" 
            *ngIf="item['is_parent'] && item['nestedMarkets'] && item['nestedMarkets'].length">
            <market-table (clickEvent$)="onItemClick($event)" [indexOfItem]="index" [items]="item['nestedMarkets']"></market-table>
        </accordion>
    