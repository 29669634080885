/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./product-editor-header.component";
var styles_ProductEditorHeaderComponent = [i0.styles];
var RenderType_ProductEditorHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductEditorHeaderComponent, data: {} });
export { RenderType_ProductEditorHeaderComponent as RenderType_ProductEditorHeaderComponent };
function View_ProductEditorHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "navbar__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_ProductEditorHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "nav", [["class", "app-header__container"], ["role", "product-editor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "a", [["class", "nav__link"], ["data-test-id", "cancelLink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEvent$.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductEditorHeaderComponent_1)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "navbar__right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "a", [["class", "nav__link"], ["data-test-id", "saveBtn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveEvent$.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "icon-ok"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("product.editor.header.cancel")); _ck(_v, 6, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("product.editor.header.save")); _ck(_v, 13, 0, currVal_2); }); }
export function View_ProductEditorHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "product-editor-header", [], null, null, null, View_ProductEditorHeaderComponent_0, RenderType_ProductEditorHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i4.ProductEditorHeaderComponent, [], null, null)], null, null); }
var ProductEditorHeaderComponentNgFactory = i1.ɵccf("product-editor-header", i4.ProductEditorHeaderComponent, View_ProductEditorHeaderComponent_Host_0, { title: "title" }, { closeEvent$: "closeEvent$", saveEvent$: "saveEvent$" }, []);
export { ProductEditorHeaderComponentNgFactory as ProductEditorHeaderComponentNgFactory };
