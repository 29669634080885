
        <div class="onoffswitch" [ngClass]="{'inactive': disabled }">
            <input type="checkbox" data-test-id="detailSwitchAlert" name="onoffswitch" class="onoffswitch-checkbox"
                    [id]="_id"
                    [checked]="state"
                    (change)="onChange($event)">
            
            <label tabindex="0" class="onoffswitch-label" [for]="_id" [attr.data-test-id]="dataTestId">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
            </label>
        </div>
    