/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-editor.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./media-editor.component";
import * as i4 from "../../modal/modal.service";
import * as i5 from "@ngx-translate/core";
var styles_MediaEditorComponent = [i0.styles];
var RenderType_MediaEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaEditorComponent, data: {} });
export { RenderType_MediaEditorComponent as RenderType_MediaEditorComponent };
export function View_MediaEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "me__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "me__img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "input", [["class", "me__text"], ["data-test-id", "text"], ["role", "editable"], ["type", "text"]], [[8, "placeholder", 0], [8, "value", 0]], [[null, "blur"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.finishRenaming({ event: $event, isInput: true }) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.startRenaming({ event: $event, isInput: true }) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { none: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_3 = "me__text"; var currVal_4 = _ck(_v, 4, 0, !_co.text); _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.placeholder; var currVal_2 = _co.text; _ck(_v, 2, 0, currVal_1, currVal_2); }); }
export function View_MediaEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "media-editor", [], null, null, null, View_MediaEditorComponent_0, RenderType_MediaEditorComponent)), i1.ɵdid(1, 49152, null, 0, i3.MediaEditorComponent, [i1.ElementRef, i4.ModalService, i5.TranslateService], null, null)], null, null); }
var MediaEditorComponentNgFactory = i1.ɵccf("media-editor", i3.MediaEditorComponent, View_MediaEditorComponent_Host_0, { imageUrl: "imageUrl", text: "text", placeholder: "placeholder", regExp: "regExp", minMaxLength: "minMaxLength", isEmpty: "isEmpty", errorMessage: "errorMessage" }, { changeEvent$: "changeEvent$" }, []);
export { MediaEditorComponentNgFactory as MediaEditorComponentNgFactory };
