var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalService } from '../../modal/modal.service';
import { ReviewsAbstractClass } from './reviews-abstract.class';
import { AppReviewsService } from '../reviews.service';
import AppValues from '../../common/app.values';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { AppFooterService } from '../../app-footer/app-footer.service';
var ReviewsPurchasesComponent = /** @class */ (function (_super) {
    __extends(ReviewsPurchasesComponent, _super);
    function ReviewsPurchasesComponent(modalService, footerService, reviewsService, scrollToService) {
        var _this = _super.call(this, modalService, reviewsService, scrollToService, footerService) || this;
        _this.HUNDRED = AppValues.HUNDRED;
        return _this;
    }
    ReviewsPurchasesComponent.prototype.addReview = function (element, buyer_info, buyer_id) {
        this.context.callback({ addReview: { element: element, buyer_info: buyer_info, buyer_id: buyer_id } });
    };
    ReviewsPurchasesComponent.prototype.showReviewedItem = function (element_id) {
        this.context.callback({ showReviewedItem: element_id });
    };
    ReviewsPurchasesComponent.prototype.showProduct = function (item) {
        this.reviewsService.showProductDetails(item.real_item_id);
    };
    ReviewsPurchasesComponent.prototype.showOrder = function (order_id) {
        this.reviewsService.goToSelectedOrderID(order_id);
    };
    ReviewsPurchasesComponent.prototype.showUserReviews = function (element, user_info, user_id, is_buyer) {
        this.context.callback({ showUserReviews: { element: element, user_info: user_info, user_id: user_id, is_buyer: is_buyer } });
    };
    return ReviewsPurchasesComponent;
}(ReviewsAbstractClass));
export { ReviewsPurchasesComponent };
