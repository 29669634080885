/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../carousel/carousel.component.ngfactory";
import * as i5 from "../carousel/carousel.component";
import * as i6 from "../carousel/carousel.service";
import * as i7 from "../services/google-analytics.service";
import * as i8 from "./shopping-header.component";
import * as i9 from "../search/search.service";
import * as i10 from "../local-caching/local-caching-factory";
var styles_ShoppingHeaderComponent = [i0.styles];
var RenderType_ShoppingHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShoppingHeaderComponent, data: {} });
export { RenderType_ShoppingHeaderComponent as RenderType_ShoppingHeaderComponent };
export function View_ShoppingHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "shopping-header__block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "nav", [["class", "app-header__container"], ["role", "shopping"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["data-test-id", "settingsLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "settings"], ["height", "25"], ["width", "25"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "navbar__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "navbar__right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "sortShoppingList"], ["data-test-id", "sortShoppingList"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sortShoppingList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "icon-sort-name-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["data-test-id", "searchLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSearchResults() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "icon-search_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "carousel", [], null, null, null, i4.View_CarouselComponent_0, i4.RenderType_CarouselComponent)), i1.ɵdid(17, 245760, null, 0, i5.CarouselComponent, [i6.CarouselService, i1.ElementRef, i7.GoogleAnalyticsService], null, null)], function (_ck, _v) { var currVal_0 = "/settings"; _ck(_v, 5, 0, currVal_0); _ck(_v, 17, 0); }, function (_ck, _v) { var currVal_1 = "../../assets/images/svg_icons/settings_icon.svg"; _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("shoppingList.title")); _ck(_v, 9, 0, currVal_2); }); }
export function View_ShoppingHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shopping-header", [], null, null, null, View_ShoppingHeaderComponent_0, RenderType_ShoppingHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i8.ShoppingHeaderComponent, [i2.Router, i9.AppSearchService, i10.LocalCaching, i3.TranslateService], null, null)], null, null); }
var ShoppingHeaderComponentNgFactory = i1.ɵccf("shopping-header", i8.ShoppingHeaderComponent, View_ShoppingHeaderComponent_Host_0, {}, { sortOrderByNameEvent$: "sortOrderByNameEvent$" }, []);
export { ShoppingHeaderComponentNgFactory as ShoppingHeaderComponentNgFactory };
