import { NgModule, ModuleWithProviders }    from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { RouterModule }                     from '@angular/router';

import { InventoryHeaderComponent }         from './inventory-header.component';
import { InventoryListService }             from './inventory-list.service';
import { InventoryListComponent }           from './inventory-list.component';
import { InventoryListItemComponent }       from './inventory-list-item.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
    ],
    declarations: [
        InventoryHeaderComponent,
        InventoryListComponent,
        InventoryListItemComponent
    ],
    exports: [InventoryListComponent]
})
export class InventoryListModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: InventoryListModule,
            providers: [InventoryListService]
        };
    }
}
