
        <div class="component">
            <inventory-nav-header *ngIf="categoryName"
                    (createItemEvent$)="createItem()"              
                    [categoryName]="categoryName"
                    [isActive]="chosenEntry"></inventory-nav-header>

            <div class="component__container">
                <ul class="subcat__list">
                    
                    <li class="subcat__title" [attr.data-test-id]="'subcategory_'+idx1"
                            [ngClass]="{'ok':   subcat['_isChosen']}"
                            *ngFor="let subcat of subcats; trackBy: trackByID; let idx1 = index"
                            (click)="toggleVisibility(subcat)">

                        <p><i class="icon-right-dir"
                                [ngClass]="{'active': subcat._isVisible}"></i>
                            
                            {{subcat.sub_category_name}}</p>

                        <ul *ngIf="subcat._isVisible" class="entry__list">

                            <li class="entry__title" [attr.data-test-id]="'entry_'+idx2"
                                    [ngClass]="{'ok': entry['_isChosen']}"
                                    *ngFor="let entry of subcat.sub_category_entries; trackBy: trackByID; let idx2 = index"
                                    (click)="toggleStatus(entry, $event)">

                                <p>{{entry.subcategory_entry_name}}</p>
                            </li>

                        </ul>

                    </li>
                </ul>
            </div>
        </div>
    