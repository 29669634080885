import {Component, Injector, OnInit} from '@angular/core';
import { Subject }                  from 'rxjs';

import AppValues                    from '../common/app.values';

import { AddressInterface,
    UserModel }                     from '../interfaces/user.interface';
import { EditableInterface }        from '../interfaces/media-editor.interface';
import { ProfileService }           from './profile.service';
import { ModalService }             from '../modal/modal.service';
import {Observable} from 'rxjs/Rx';
import {ErrorInterface} from '../services/error.service';
import {UserService} from '../user/user.service';
import {ClientPaymentService} from '../payment/payment.service';
import {ExternalLinkView} from '../../../swagger-gen__output_dir/model/externalLinkView';
import {TranslateService} from '@ngx-translate/core';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { getErrorType } from '../services/error.helpers';

@Component({
    selector:   'profile',
    styleUrls:  ['profile.sass'],

    template:   `
        <profile-header></profile-header>
        <div *ngIf="profile" class="profile__container">

            <img-loader role="title"
                        [imageUrl]="profile.imageURL || '../../assets/images/cart/no_image_icon.png'"
                        [text]="profile.title  || '--your title--'"
                        [regExp]="titlePattern"
                        (changeEvent$)="onDataChange($event)">
            </img-loader>

            <!--CONTACTS-->
            <div class="profile__subheader"><h4>Contacts</h4></div>
            <media-editor role="webPage" [attr.data-test-id]="'webpage'"
                          [imageUrl]="'../../assets/images/svg_icons/globe.svg'"
                          [text]="profile.webPage"
                          [placeholder]="'URL'"
                          [regExp]="webPagePattern"
                          [minMaxLength]="webPageMinMaxLength"
                          [isEmpty]="true"
                          [errorMessage]="'address.error.invalidWebPageLineFieldValues'"
                          (changeEvent$)="onDataChange($event)" (keypress)="eventHandler($event)">
            </media-editor>

            <media-editor role="email" [attr.data-test-id]="'email'"
                          [imageUrl]="'../../assets/images/svg_icons/at-symbol-svgrepo-com.svg'"
                          [text]="profile.email"
                          [regExp]="emailPattern"
                          [minMaxLength]="emailMinMaxLength"
                          [errorMessage]="'profile.editor.email.warning.message'"
                          (changeEvent$)="onDataChange($event)" (keypress)="eventHandler($event)">
            </media-editor>

            <!--Stripe-->
            <div class="profile__subheader" *ngIf="isSellerMode">
                <a (click)="onOpenStripeDashboard()" data-test-id="openStripeDashboard">
                    <section class="profile__stripe-container">
                        <img src="../../assets/images/stripe_wordmark_slate_lg.png" alt="stripe wordmark"/>
                    </section>
                </a>
            </div>

            <!--ADDRESSES-->
            <!-- TODO MULTIPLE ADDRESSES -->
            <div class="profile__subheader"><h4>{{ "profile.addresses" | translate }}</h4></div>

            <div>
                <div *ngFor="let a of profile.addresses; let i = index" class="addr" (click)="onAddressEdit(a, i)" data-test-id="profileAddressEdit">
                    <span class="icon-location"></span>

                    <h3 class="addr__text clip" data-test-id="profileAddress1">{{a.address1}}</h3>

                    <span class="icon-disclosure_indicator_icon"></span>

                </div>
            </div>

            <!--ADD ADDRESSES-->
            <div class="profile__add" (click)="onAddressEdit(null, -1)">
                <h4 data-test-id="newAddress">{{ "profile.add.new.address" | translate }}</h4>
            </div>

        </div>
    `,
})


export class ProfileComponent extends UnsubscribeOnDestroyAbsctractClass implements OnInit {
    /**
     * Represents Profile Screen.
     * @desc Includes editable text fields as MediaEditorComponents
     * and editable image as ImgLoaderComponent.
     * @listens to changeEvent$ from the editables and fires #onDataChange.
     */

    public emailPattern:       RegExp = AppValues.emailPattern;
    public emailMinMaxLength:  number[] = [AppValues.MINIMUM_EMAIL_LENGTH, AppValues.MAXIMUM_EMAIL_LENGTH];
    public profile:            UserModel;
    public webPagePattern:     RegExp = AppValues.webPagePattern;
    public webPageMinMaxLength: number[] = [AppValues.MINIMUM_WEBPAGE_LENGTH, AppValues.MAXIMUM_WEBPAGE_LENGTH];
    public titlePattern:       RegExp = AppValues.profileTitlePattern;
    public window:             Window;

    constructor(
        private modalService:           ModalService,
        private profileService:         ProfileService,
        private clientPaymentService:   ClientPaymentService,
        private injector:               Injector,
        public  translate:              TranslateService
    ) {
        super();
        this.window = window;
    }


    /**
     * Fetches Profile data via service.
     */
    public ngOnInit(): void {
        this.trackSubscription(
            this.profileService.getProfile()
                .subscribe((profile: UserModel) => this.profile = profile, 
                (err: ErrorInterface) => Observable.throwError(err)
            ),
        );
    }

    public get isSellerMode(): boolean {
        return this.injector.get(UserService).isSellerMode();
    }


    /**
     * Fires on address editing clicks.
     * @param addr Address object.
     * @param index Index of the object in profile.addresses array.
     */
    public onAddressEdit(addr: AddressInterface|null, index: number): void {
        this.profileService.onAddressEdit(addr, index);
    }

    public onOpenStripeDashboard(): void {
        this.trackSubscription(
            this.clientPaymentService.paymentsLoginLink()
                .subscribe(
                    (externalLinkView: ExternalLinkView) => this.window.open(externalLinkView.link, "_self"),
                    (error: ErrorInterface) => this._handlePaymentLoginError(error),
                    )
        );
    }

    /**
     * Fires when a MediaEditorComponent or ImgLoaderComponent emits.
     * @desc Chooses corresponding service method to handle profile depending on
     * which kind of data (img or text field) has changed.
     * @param obj Contains data edited.
     */
    public onDataChange(obj: EditableInterface): void {
        if (obj.imageFile) {
            this.trackSubscription(
                this.profileService.uploadImg(obj.imageFile)
                .subscribe((imageURL: string) => {
                    this._updateProfile(Object.assign(this.profile, {imageURL}));
                }, (err: string) => this.modalService.error({title: 'Error:', message: err, yesButtonText:  'Close'}))
            );

            return;
        }

        if (obj.role) {
            let newProfile = Object.assign(this.profile, {[obj.role]: obj.value});

            if (!this._isFieldCorrect(obj)) return;

            this._updateProfile(newProfile);
        }
    }

    public eventHandler(event): void {
        if (event.keyCode === 13 || event.code === "13") {
            event.preventDefault();
            event.target.blur();
        }
    }


    /**
     * Saves profile via Service. Updates Profile on response.
     * @private
     * @param profile
     */
    _updateProfile(profile: UserModel): void {
        this.trackSubscription(
            this.profileService.saveProfile(profile, true)
                .subscribe(
                    (res: UserModel) => this.profile = res, 
                    (error: string) => this.profileService.handleEmailUpdateErr(error),
                ),
        );
    }


    /**
     * Ensures that profile object has expected key and its value is of string type.
     * @private
     * @param obj
     * @returns {boolean|undefined} True if ok, undefined otherwise.
     */
    _isFieldCorrect(obj: EditableInterface) {
        if (!this.profile.hasOwnProperty(obj.role)) {
            window.console.error(`Unexpected field "${obj.role}"`);

        } else if (typeof obj.value !== 'string') {
            window.console.error(`Unexpected type for "${obj.role}" field`);

        } else {
            return true;
        }
    }

    _handlePaymentLoginError(error: ErrorInterface): void {
        this.modalService.error(
            {
                title: this.translate.instant('stripe.paymentsLoginLink.modal.title'),
                message: getErrorType(error),
                yesButtonText: this.translate.instant('stripe.paymentsLoginLink.modal.yesButton')
            });
    }
}
