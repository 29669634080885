import { Injectable }   from '@angular/core';
import { Observable }   from 'rxjs';
import { Subject }      from 'rxjs';
import { Router }       from '@angular/router';

import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';


import { DataService }              from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import { FavoriteListInterface }    from '../favorite-list/favorite-list.interface';
import { FavoriteListService }      from '../favorite-list/favorite-list.service';
import { ModalService }             from '../modal/modal.service';
import { UserService }              from '../user/user.service';
import AppValues from '../common/app.values';
import {UserModel} from "../interfaces/user.interface";
import {SearchResultInterface, SearchType, UrlBySearchNameInterface} from "../interfaces/search.interface";
import {AppSearchService} from "../search/search.service";
import {TranslateService} from "@ngx-translate/core";
import { AppRouteValues, createDirection } from '../common/app.route.values';


@Injectable()
export class ShopperDetailsService {

    public shopper:    UserModel;

    constructor(
        private dataService:            DataService,
        private errorService:           ErrorService,
        private favoriteListService:    FavoriteListService,
        private router:                 Router,
        private modalService:           ModalService,
        private userService:            UserService,
        private searchService:          AppSearchService,
        private translate:              TranslateService
    ) {}


    public showDetails(id: string): void  {
        let token: string = this.userService.getUserSession().token;
        if (!token) return;

        this.dataService.postData(`user_by_ids`, {users_ids: [id]}, {token})
            .subscribe(( shopper: {users: UserModel[]} ) => {
                this.shopper = shopper.users.filter(shp => shp['ID'] === id)[0];

                this.searchProductsBySeller(this.shopper);

            }, (err: ErrorInterface) => this.errorService.handleError(err));
    }

    private searchProductsBySeller(shopper: UserModel): void {
        this.searchService.search(shopper.ID, SearchType.Item, {isNew: true} as any)
            .subscribe((res: SearchResultInterface) => {
                this.searchService.is_key_search = false;
                const urlBySearchName: UrlBySearchNameInterface = this.searchService.getUrlBySearchName(`${shopper.title || shopper.firstName + ' ' + shopper.lastName}\'s items`);
                this.searchService.addSearchDataToLocalCachingByUrl({url: urlBySearchName.fullUrl});

                this.router.navigate([createDirection(AppRouteValues.shopperDetails)], {queryParams: {id: shopper.ID}});
            }, (errorText: string) => {
                this.modalService.error({
                    title: this.translate.instant('common.warning'),
                    message: errorText,
                    yesButtonText:  this.translate.instant('modal.warning.yesButton')
                });
            });
    }

    public searchFor(sellerId: string, sellerName: string = ''): void {
        if (!sellerId) return;

        const userName: string = sellerName || this.shopper.title || this.shopper.firstName + ' ' + this.shopper.lastName;
        const titleName: string = userName + '\'s' + ' ' + 'items';

        this.searchService.search(sellerId, SearchType.Item, {isNew: true})
            .subscribe(() => {
                this.searchService.is_key_search = false;
                this.router.navigate([createDirection(AppRouteValues.routeSearch)], {queryParams: {key: titleName}});
            }, (err: ErrorInterface) => Observable.throw(err));
    }

    public getShopper(): UserModel {
        return this.shopper;
    }

     /**
     * @desc If the item is in FavList, calls remove method of
     * the service; otherwise calls add method. On success updates User (favList object)
     * and FavList model.
     * @returns {Observable<R|T>}
     */
    public toggleFavListStatus(): Observable<FavoriteListInterface> {
        const id    = this.shopper['ID'];
        const user  = AppValues.deepCopy(this.userService.getUser());
        const list_users  = user.favList.users;

        const index_users = list_users.indexOf(id);

        let handler;

        if (index_users > -1) {
            handler = this.favoriteListService.removeUser(id);
            list_users.splice(index_users, 1);

        } else {
            handler = this.favoriteListService.addUser(id);
            list_users.push(id);
        }

        this.modalService.showSpinner();

        return handler
            .map((_list: FavoriteListInterface) => {
                this.modalService.close();
                this.favoriteListService.setList(_list);
            })

            .catch((err: ErrorInterface) => {
                // The error has already been processed in FavoriteListService
                // and errorService.handleError(err) and the modal window was shown.
                return err;
            });
    }

}

