
        <div class="component">
            <inventory-header></inventory-header>

            <div class="abstract-list__container component__container">
                <ul *ngIf="isArrayItems(items) && items?.length !== 0">
                    <div *ngFor="let item of items; let i = index; ">
                        <inventory-list-item
                            (showItemEvent$)="onShow(item)"
                            [item]="item"
                            [index]="i"
                            (removeItemEvent$)="onRemove(item)">

                        </inventory-list-item>
                    </div>
                </ul>
                <h3 *ngIf="isArrayItems(items) && items?.length === 0" class="empty-list">
                    {{ "inventoryList.empty" | translate }}
                </h3>
            </div>
        </div>
    