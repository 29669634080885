import { CommonModule }                     from '@angular/common';
import { FormsModule }                      from '@angular/forms';
import { NgModule, ModuleWithProviders }    from '@angular/core';
import { RouterModule }                     from '@angular/router';

import { CartService }                  from './cart.service';
import { CartComponent }                from './cart.component';
import { CartHeaderComponent }          from './cart-header.component';
import { CartReportHeaderComponent }    from './cart-report-header.component';
import { CartItemComponent }            from './cart-item.component';
import { CartReportComponent }          from './cart-report.component';
import { SharedModule }                 from '../shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../app.module';
import {AbandonedProduct} from './abandonedcart.class';
import {ClientPaymentService} from '../payment/payment.service';
import {HttpClient} from "@angular/common/http";



@NgModule({
    imports: [
        RouterModule,
        SharedModule,
        TranslateModule,
        CommonModule,
        FormsModule,
    ],
    declarations: [
        CartComponent,
        CartHeaderComponent,
        CartReportHeaderComponent,
        CartItemComponent,
        CartReportComponent,
    ],
    exports: [
        CartComponent,
        CartItemComponent,
        CartReportComponent
    ]
})
export class CartModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CartModule,
            providers: [CartService, ClientPaymentService, AbandonedProduct]
        };
    }
}
