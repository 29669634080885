/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "./password-field.component";
import * as i4 from "@ngx-translate/core";
var styles_PasswordFieldComponent = [];
var RenderType_PasswordFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PasswordFieldComponent, data: {} });
export { RenderType_PasswordFieldComponent as RenderType_PasswordFieldComponent };
function View_PasswordFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "icon-eye_outlined"], ["data-test-id", "passInvisible"]], null, null, null, null, null))], null, null); }
function View_PasswordFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "icon-watchlist_icon"], ["data-test-id", "passVisible"]], null, null, null, null, null))], null, null); }
export function View_PasswordFieldComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "label", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(3, 0, null, null, 10, "input", [["autocomplete", "current-password"], ["required", ""]], [[8, "id", 0], [8, "autofocus", 0], [1, "data-test-id", 0], [8, "placeholder", 0], [1, "name", 0], [1, "type", 0], [1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keyup"], [null, "blur"], [null, "ngModelChange"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup" === en)) {
        var pd_4 = (_co.onInputTrimed($event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (_co.onFieldBlurred($event) !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.value = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(5, { error: 0 }), i0.ɵdid(6, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(7, 16384, null, 0, i2.RequiredValidator, [], { required: [0, "required"] }, null), i0.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.RequiredValidator]), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(10, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"], options: [1, "options"] }, { update: "ngModelChange" }), i0.ɵpod(11, { standalone: 0 }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(13, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(14, 0, null, null, 4, "button", [["type", "button"]], [[8, "className", 0], [1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.changePassVisibility = !_co.isPassVisible) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PasswordFieldComponent_1)), i0.ɵdid(16, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PasswordFieldComponent_2)), i0.ɵdid(18, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.classLabel; _ck(_v, 1, 0, currVal_0); var currVal_15 = _co.classInput; var currVal_16 = _ck(_v, 5, 0, _co.isPassErr); _ck(_v, 4, 0, currVal_15, currVal_16); var currVal_17 = ""; _ck(_v, 7, 0, currVal_17); var currVal_18 = _co.value; var currVal_19 = _ck(_v, 11, 0, true); _ck(_v, 10, 0, currVal_18, currVal_19); var currVal_22 = !_co.isPassVisible; _ck(_v, 16, 0, currVal_22); var currVal_23 = _co.isPassVisible; _ck(_v, 18, 0, currVal_23); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.forInput; var currVal_2 = _co.autofocusField; var currVal_3 = _co.dataTestIdInput; var currVal_4 = _co.placeholderInput; var currVal_5 = _co.nameInput; var currVal_6 = (_co.isPassVisible ? "text" : "password"); var currVal_7 = (i0.ɵnov(_v, 7).required ? "" : null); var currVal_8 = i0.ɵnov(_v, 13).ngClassUntouched; var currVal_9 = i0.ɵnov(_v, 13).ngClassTouched; var currVal_10 = i0.ɵnov(_v, 13).ngClassPristine; var currVal_11 = i0.ɵnov(_v, 13).ngClassDirty; var currVal_12 = i0.ɵnov(_v, 13).ngClassValid; var currVal_13 = i0.ɵnov(_v, 13).ngClassInvalid; var currVal_14 = i0.ɵnov(_v, 13).ngClassPending; _ck(_v, 3, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); var currVal_20 = (_co.classShowPasswordPosition + " show_password"); var currVal_21 = (_co.dataTestIdInput + "_showPassword"); _ck(_v, 14, 0, currVal_20, currVal_21); }); }
export function View_PasswordFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "password-field", [], null, null, null, View_PasswordFieldComponent_0, RenderType_PasswordFieldComponent)), i0.ɵdid(1, 49152, null, 0, i3.PasswordFieldComponent, [i4.TranslateService], null, null)], null, null); }
var PasswordFieldComponentNgFactory = i0.ɵccf("password-field", i3.PasswordFieldComponent, View_PasswordFieldComponent_Host_0, { forInput: "forInput", classLabel: "classLabel", classInput: "classInput", classShowPasswordPosition: "classShowPasswordPosition", dataTestIdInput: "dataTestIdInput", isPassErr: "isPassErr", isPassVisible: "isPassVisible", autofocusField: "autofocusField", password: "password", placeholderInput: "placeholderInput", nameInput: "nameInput" }, { bluredField: "bluredField", keyUpField: "keyUpField" }, ["*"]);
export { PasswordFieldComponentNgFactory as PasswordFieldComponentNgFactory };
