import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Location }             from '@angular/common';

@Component({
    selector: 'market-header',
    styleUrls:  ['../../assets/styles/app-header.sass'],
    template: `
        <header class="app-header">
            <nav class="app-header__container marker-header">

                <div class="navbar__left">
                    <button type="button" (click)="backClick()" data-test-id="backLink"
                       class="nav__link"><i class="icon-custom_back_icon"></i></button>
                </div>

                <div class="navbar__center">
                    <h4 data-test-id="marketTitle">{{ itemTitle }}</h4>
                </div>

                <div class="navbar__right">
                </div>
            </nav>
        </header>
    `
})

export class MarketHeaderComponent implements OnInit {

    @Output() backEvent$    = new EventEmitter<boolean>();

    location:               Location;
    locationSearch:         string;
    itemTitle:              string;
    _w: any;

    constructor(
        location:       Location,
    ) {
        this._w         = window;
        this.location   = location;
    }


    ngOnInit() {
        this.locationSearch = decodeURIComponent( this._w.location.pathname );
        this.itemTitle      = decodeURIComponent( this._w.location.search.substr(5) );

    }

    backClick() {
        this.backEvent$.emit(false);
        this.location.back();
    }

}
