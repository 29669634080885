
        <div *ngIf="shopper" class="component">
            <div class="shopper-details">
                <header class="app-header">
                    <nav class="app-header__container shopper-details__header">

                        <span class="navbar__left">
                            <button (click)="backClick()" [attr.data-test-id]="'searchResultsLink'" type="button"
                               class="nav__link"><i class="icon-custom_back_icon"></i></button>
                        </span>

                        <div class="navbar__center">
                            <h4>{{ "shopper.details.seller" | translate }}</h4>
                        </div>

                        <button (click)="toggleFavListStatus()" data-test-id="toggleFLStatus" class="navbar__right" type="button">
                            <span data-test-id="addToFL" *ngIf="!shopper['shopperIsInFavList']"
                                  class="icon-favorites_filled"></span>

                            <span data-test-id="removeFromFL" *ngIf="shopper['shopperIsInFavList']"
                                  class="icon-favorites_filled active"></span>

                        </button>
                    </nav>
                </header>
                <div class="shopper-details__container">
                    <div class="shopper-details__img">
                        <agm-map
                            [latitude]='shopper.latitude'
                            [longitude]='shopper.longitude'
                            [zoom]='zoom'
                            [scrollwheel]='false'
                            [streetViewControl]='false'
                            [disableDefaultUI]='true'
                            [disableDoubleClickZoom]='true'
                            [mapDraggable]='false'
                            [zoomControl]='false'>

                            <agm-marker
                                (markerClick)="onMarkerClick(shopper)"
                                [latitude]='shopper.latitude'
                                [longitude]='shopper.longitude'
                                [markerDraggable]="false" [iconUrl]='shopperUrl'>
                            </agm-marker>

                        </agm-map>
                    </div>
                    <button type="button" class="shopper-details__content-user" (click)="showSellerReviews(shopper.ID, shopper.title, shopper.imageURL)">
                        <div *ngIf="!shopper.imageURL" class="image_col">
                            <span class="sprite sprite-no_image_icon" data-test-id="shopperImage"></span>
                        </div>
                        <div class="container-img">
                            <img *ngIf="shopper.imageURL" [src]="shopper.imageURL" data-test-id="shopperImage" alt="shopper profile url">
                        </div>
                        <div class="content_col">
                            <h4 data-test-id="ShopperName">
                                {{ shopper.title || shopper.firstName + ' ' + shopper.lastName}}
                            </h4>
                            <div class="shopper-details__reviews">
                                <rating [attr.data-test-id]="'ShopperRate'" [rating]="shopper.rate" [float]="true"
                                        [readonly]="true"></rating>
                                <p data-test-id="detailRate">( {{ shopper.voted }} )</p>
                            </div>
                        </div>
                    </button>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-phone"></span>
                        </div>

                        <h4 data-test-id="ShopperPhone">
                            <a *ngIf="!primaryShopperAddress.phone">{{ "shopper.details.phone.not.specified" | translate }}</a>
                            <a *ngIf="primaryShopperAddress.phone"
                               href="tel:{{ primaryShopperAddress.phone}}">{{ formatPhoneNumber(primaryShopperAddress) }}</a>
                        </h4>
                    </div>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-location"></span>
                        </div>

                        <h4 *ngIf="primaryShopperAddress"
                            data-test-id="ShopperAddress"
                            (click)="formatAndGoToAddress()">
                            <a>{{ commaSeparatedShopperAddress }}</a>
                        </h4>

                        <h4 *ngIf="shopper.addresses.length == 0"
                            data-test-id="ShopperAddress">
                            <a>{{ "shopper.details.not.specified" | translate }}</a>
                        </h4>
                    </div>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-globe"></span>
                        </div>

                        <h4 data-test-id="ShopperWebPage" (click)="goToAddress(shopper.webPage, 'webPage')">
                            <a>{{ shopper.webPage || 'Web page is not specified.'}}</a>
                        </h4>
                    </div>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-at"></span>
                        </div>

                        <h4 data-test-id="ShopperMail" (click)="goToAddress(shopper.email, 'mail')">
                            <a>{{ shopper.email || 'Email is not specified.'}}</a>
                        </h4>
                    </div>
                </div>
            </div>

            <div class="product-actions app__footer ">
                <footer class="details__container__btn-group shopper-details__footer">
                    <button data-test-id="goToChat" (click)="goToDialog()" class="chat-shopper" type="button">
                        {{ "shopper.details.contact.seller" | translate }}
                    </button>
                    <button data-test-id="searchForShopper" (click)="searchFor(shopper.ID)" class="shopper-items" type="button">
                        {{ "shopper.details.items.for.sale" | translate }}
                    </button>
                </footer>
            </div>
        </div>
    