import { Injector, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ChatService } from './chat.service';
import { UserService } from '../user/user.service';
import { ErrorService } from '../services/error.service';
var DialogsListComponent = /** @class */ (function () {
    function DialogsListComponent(chatService, injector, errorService) {
        this.chatService = chatService;
        this.injector = injector;
        this.errorService = errorService;
        this.searchQuery = '';
        this.timeout = null;
        this.componentDestroyed$ = new Subject();
    }
    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function.
     * @param {number} index
     * @param {FoundDialog} dialog
     * @return {string}
     */
    DialogsListComponent.prototype.trackByID = function (index, dialog) { return dialog.ID; };
    Object.defineProperty(DialogsListComponent.prototype, "user_service", {
        /***
         * @desc This function creates 'user service' property on your service.
         * @return {UserService}
         */
        get: function () {
            return this.injector.get(UserService);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Gets dialogs and subscribes their updates.
     */
    DialogsListComponent.prototype.ngOnInit = function () {
        var guestType = this.user_service.getUser().accessLevel === 0;
        if (guestType) {
            this.chatService.init();
            this.chatService._retrieveDialogs();
        }
        else {
            this.getNewDialogList();
            this._getDialogs();
            this.dialogs = this.sortAndFilterDialogs(this.chatService.getCurrentDialogs());
            this.chatService.init();
        }
    };
    DialogsListComponent.prototype._getDialogs = function () {
        var _this = this;
        this.chatService.getDialogs()
            .takeUntil(this.componentDestroyed$)
            .subscribe(function (dialogs) {
            _this.dialogs = _this.sortAndFilterDialogs(dialogs);
        });
    };
    DialogsListComponent.prototype.getNewDialogList = function () {
        this.chatService.getDialogsRequest();
    };
    /**
     * Gets up-to-date dialogs and calls to filter them.
     * @param event
     */
    DialogsListComponent.prototype.onSearch = function (event) {
        var _this = this;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(function () {
            _this.searchQuery = event.target['value'];
            _this.chatService.searchByKeyword(_this.searchQuery.trim())
                .subscribe(function (dialogs) { return _this.dialogs = _this.sortAndFilterDialogs(dialogs); }, function (error) {
                _this.clearSearch();
                _this.errorService.handleError(error);
            });
        }, 700);
    };
    /**
     * Vanishes search (filtering) results, gets unfiltered dialogs.
     */
    DialogsListComponent.prototype.clearSearch = function () {
        var _this = this;
        setTimeout(function () {
            _this.searchQuery = '';
            _this.chatService.searchChatKeyword = '';
            _this.getNewDialogList();
        });
    };
    DialogsListComponent.prototype.sortAndFilterDialogs = function (dialogs) {
        if (dialogs && Object.keys(dialogs).length) {
            return this.chatService.sortItems(dialogs).filter(function (dialog) { return dialog.messages.length !== 0; });
        }
        return [];
    };
    /**
     * Delegates to the Service navigation to a certain dialog.
     * @param {FoundDialog} dialog
     */
    DialogsListComponent.prototype.showDialog = function (dialog) {
        this.chatService.showDialog(dialog);
    };
    /**
     * Delegates to the service dialog removing.
     * @param id
     */
    DialogsListComponent.prototype.removeDialog = function (id) {
        this.chatService.removeDialog(id);
    };
    /**
     * Deprecated due to the exists end-point (see searchByKeyword() method in Chat service)
     * Filters dialogs according to the keyword looking through the messages and the titles.
     * @private
     */
    //  _filterDialogs(): void {
    //      if (!this.searchQuery) return;
    //      const query = this.searchQuery.trim().toUpperCase();
    //      this.dialogs = this.dialogs.filter((d: FoundDialog) => {
    //          return this._doesInclude(d.interlocutor_title, query)
    //              || this._doesInclude(d.last_message, query);
    //      });
    //  }
    //  Deprecated due to the exists end-point (see searchByKeyword() method in Chat service)
    //  _doesInclude(val: string, query: string): boolean {
    //      return val !== null && val.toUpperCase().includes(query);
    //  }
    DialogsListComponent.prototype.ngOnDestroy = function () {
        this.componentDestroyed$.complete();
        this.chatService.stopPolling();
    };
    return DialogsListComponent;
}());
export { DialogsListComponent };
