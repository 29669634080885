import { Component, EventEmitter, Input, Output }  from '@angular/core';


@Component({
    selector:   'product-editor-header',
    styleUrls:  ['../../assets/styles/app-header.sass'],
    template:   `
        <header class="app-header">
            <nav class="app-header__container" role="product-editor">
                <div class="navbar__left">
                    
                    <a class="nav__link" data-test-id="cancelLink" (click)="closeEvent$.emit()">  
                        <i class="icon-custom_back_icon"></i>
                        <p>{{ "product.editor.header.cancel" | translate }}</p>
                    </a>
                    
                </div>

                <div *ngIf="title" class="navbar__center">
                    <h4>{{ title }}</h4>
                </div>
                
                <!--SAVE-->
                <div class="navbar__right">
                    <a class="nav__link" data-test-id="saveBtn" (click)="saveEvent$.emit()">
                        <p>{{ "product.editor.header.save" | translate }}</p>
                        <i class="icon-ok"></i>
                    </a>
                </div>
                <!-- ----------- -->
            </nav>
        </header>
    `
})

/**
 * Markup for the Product editor header.
 * @emits closeEvent$ on close 'button' click.
 */
export class ProductEditorHeaderComponent {
    @Output() closeEvent$   = new EventEmitter();
    @Output() saveEvent$    = new EventEmitter();
    @Input()    title:      string;
}
