var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef } from '@angular/core';
import { ProductEditorService } from '../../product-editor/product-editor.service';
import { SearchListClass } from './search-list.class';
var EventSearchComponent = /** @class */ (function (_super) {
    __extends(EventSearchComponent, _super);
    function EventSearchComponent(elementRef, productEditorService) {
        var _this = _super.call(this, elementRef) || this;
        _this.elementRef = elementRef;
        _this.productEditorService = productEditorService;
        return _this;
    }
    EventSearchComponent.prototype.onClick = function (item) {
        this.productEditorService.createEvent(item);
    };
    return EventSearchComponent;
}(SearchListClass));
export { EventSearchComponent };
