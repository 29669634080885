import { Location } from "@angular/common";
import { AfterContentInit, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { Observable } from "rxjs/Observable";
import { TranslateService } from "@ngx-translate/core";
import AppValues from "../common/app.values";
import { ModalService } from "../modal/modal.service";
import { GeoCoderService } from "../services/geocoder.service";
import { GeoLocationService } from "../services/geolocation.service";
import { antiPatternMatcher } from "../user/helpers/_helpers";
import { ProfileService } from "./profile.service";
var AddressEditorComponent = /** @class */ (function () {
    function AddressEditorComponent(fb, profileService, modalService, geoLocationService, geoCoderService, translate, locationCommon) {
        this.fb = fb;
        this.profileService = profileService;
        this.modalService = modalService;
        this.geoLocationService = geoLocationService;
        this.geoCoderService = geoCoderService;
        this.translate = translate;
        this.locationCommon = locationCommon;
        this.countries = AppValues.countries;
        this.isPrimary = false;
        this.states = AppValues.states;
        this.location = {
            longitude: 0,
            latitude: 0
        };
        this.timeout = null;
        this.geocodingSubject = new Subject();
        this.isInProgressGeoCoding = false;
        this.isDeactivate = false;
    }
    AddressEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buildForm();
        this.geocodingSubject.asObservable().subscribe(function (isSuccess) {
            _this.isInProgressGeoCoding = !isSuccess;
        });
    };
    AddressEditorComponent.prototype.ngAfterContentInit = function () {
        this._setAddress();
    };
    AddressEditorComponent.prototype.ngOnDestroy = function () {
        if (this.countrySubscription) {
            this.countrySubscription.unsubscribe();
        }
    };
    AddressEditorComponent.prototype.locateMe = function () {
        var _this = this;
        if (!this.isInProgressGeoCoding) {
            this.geocodingSubject.next(false);
            this.modalService.showSpinner();
            this.hangeWithTimeOut().subscribe(function (l) {
                _this.modalService.close();
                _this.geocodingSubject.next(true);
            }, function (err) {
                _this.modalService.error({
                    title: _this.translate.instant('common.alert.title'),
                    message: err,
                    noButtonText: _this.translate.instant('common.ok')
                });
                _this.geocodingSubject.next(true);
            });
        }
    };
    AddressEditorComponent.prototype.blurField = function (event) {
        var controlName = event.target['name'];
        var controlValue = event.target['value'];
        controlValue = controlValue.trim();
        this.addrForm.controls[controlName].setValue(controlValue);
        this.addrForm.controls[controlName].markAsTouched();
    };
    AddressEditorComponent.prototype.hangeWithTimeOut = function () {
        var _this = this;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        return new Observable(function (observer) {
            _this.timeout = setTimeout(function () {
                _this.coordinatesToAddress()
                    .subscribe(function (location) {
                    observer.next(location);
                }, function (err) { return observer.error(err); });
            }, 1000);
        });
    };
    AddressEditorComponent.prototype.addressToCoordinates = function (address) {
        var _this = this;
        this.modalService.showSpinner();
        return new Observable(function (observer) {
            _this.geocodeAddress(address)
                .subscribe(function (location) {
                _this.location = location;
                _this.modalService.close();
                observer.next(location);
            }, function (err) {
                observer.error(err);
            });
        });
    };
    AddressEditorComponent.prototype.coordinatesToAddress = function () {
        var _this = this;
        this.isDeactivate = false;
        return new Observable(function (observer) {
            _this.reverseGeocodeAddress()
                .subscribe(function (location) {
                _this.addrObj.addr = _this.geoCoderService.getFormatGoogleAddress(_this.addrObj.addr, location.address_components);
                _this.isDeactivate = true;
                _this.location = {
                    longitude: _this.profileService.geoLocation.longitude,
                    latitude: _this.profileService.geoLocation.latitude
                };
                _this.addrObj.addr.firstName = _this.addrForm.controls['firstName'].value;
                _this.addrObj.addr.lastName = _this.addrForm.controls['lastName'].value;
                _this.addrForm.patchValue(_this.addrObj.addr);
                observer.next(location);
            }, function (err) {
                observer.error(err);
            });
        });
    };
    AddressEditorComponent.prototype.isDiffLocation = function (loc) {
        return (this.profileService.userProfile.longitude !== loc.longitude && this.profileService.userProfile.latitude !== loc.latitude) &&
            (this.profileService.geoLocation.longitude !== loc.longitude && this.profileService.geoLocation.latitude !== loc.latitude);
    };
    /**
     * Get coordinates (converted Address to Coordinates)
     * @param {string} address
     * @return {Observable<CenterLocationInterface>}
     */
    AddressEditorComponent.prototype.geocodeAddress = function (address) {
        var _this = this;
        return new Observable(function (observer) {
            _this.geoCoderService.geocodeAddress(address).subscribe(function (res) {
                _this.profileService.profileLocation = res;
                return observer.next(res);
            }, function (err) { return observer.error(err); });
        });
    };
    AddressEditorComponent.prototype.reverseGeocodeAddress = function () {
        var _this = this;
        var location = this.geoLocationService.location;
        if (!location) {
            return new Observable(function (observer) {
                _this.geoLocationService.geoLocationObserver().subscribe(function (userLocation) {
                    _this.geoCoderService.reverseGeocodingAddress(userLocation).subscribe(function (res) {
                        _this.profileService.profileLocation = userLocation;
                        observer.next(res);
                    }, function (err) { return observer.error(err); });
                }, function (err) { return observer.error(err); });
            });
        }
        else {
            return new Observable(function (observer) {
                _this.geoCoderService.reverseGeocodingAddress(location).subscribe(function (res) {
                    _this.profileService.profileLocation = location;
                    observer.next(res);
                }, function (err) { return observer.error(err); });
            });
        }
    };
    /**
     * @desc If the address fetched from ProfileService is substantial presets
     * the fields of the form and also presets @isPrimary checkbox.
     * @private
     */
    AddressEditorComponent.prototype._setAddress = function () {
        this.addrObj = this.profileService.getAddress;
        var addr = this.addrObj.addr;
        if (addr) {
            if (this.profileService.userProfile.addresses.length === 0) {
                this.isPrimary = true;
            }
            else {
                this.isPrimary = addr.isPrimary;
            }
            this.addrForm.patchValue(addr);
        }
    };
    AddressEditorComponent.prototype.findInvalidControls = function () {
        var controls = this.addrForm.controls;
        for (var name_1 in controls) {
            if (controls[name_1].invalid) {
                document.getElementsByName(name_1)[0].classList.add('error');
            }
        }
    };
    /**
     * @desc Checks the form validity. If valid, composes address object to
     * pass it to the ProfileService.
     */
    AddressEditorComponent.prototype.onSubmit = function () {
        var _this = this;
        var _a;
        var x = this.addrForm.value;
        var controls = this.addrForm.controls;
        for (var name_2 in controls) {
            this.addrForm.patchValue((_a = {}, _a[name_2] = controls[name_2].value.trim(), _a));
        }
        if (!this._isValid()) {
            this.findInvalidControls();
            this._showWarning();
            return;
        }
        var address = {
            firstName: x.firstName,
            lastName: x.lastName,
            address1: x.address1.trim(),
            address2: x.address2.trim(),
            city: x.city,
            state: x.state,
            state_abbreviation: x.state,
            postCode: x.postCode,
            phone: x.phone,
            country: x.country,
            isPrimary: this.isPrimary,
        };
        this.isDeactivate = true;
        this.profileService.changeProfile(address, this.isPrimary);
        if (!AppValues.isObjectEquals(this.profileService.getAddress.addr, address)) {
            this.addressToCoordinates(this.geoCoderService.getFullAddressString(address))
                .subscribe(function (coordFromAddr) {
                _this.profileService.submitAddress(address, coordFromAddr);
            }, function (err) { return err; });
        }
        else {
            this.profileService.submitAddress(address);
        }
    };
    /**
     * Returns form validity.
     * @returns {boolean}
     * @private
     */
    AddressEditorComponent.prototype._isValid = function () {
        return this.addrForm.valid;
    };
    /**
     * Shows modal error window.
     * @private
     */
    AddressEditorComponent.prototype._showWarning = function () {
        this.modalService
            .error({
            title: this.translate.instant('profile.editor.addr.warning.title'),
            message: this.translate.instant('profile.editor.addr.warning.message'),
            yesButtonText: this.translate.instant('profile.editor.addr.warning.confirm'),
        });
    };
    /**
     * @desc Returns true if the form is pristine or @isDeactivate flag
     * is truthy. Otherwise checks the fields for content. If the form is empty,
     * returns true, if not, shows modal warning dialog.
     */
    AddressEditorComponent.prototype.checkChanging = function () {
        var _this = this;
        if (this.addrForm.pristine || this.isDeactivate) {
            this.locationCommon.back();
        }
        else {
            var value = this.addrForm.value;
            for (var key in value)
                if (value[key] !== '') {
                    this.modalService
                        .warning({
                        title: this.translate.instant('profile.editor.cancel.warning.title'),
                        message: this.translate.instant('profile.editor.cancel.warning.message'),
                        yesButtonText: this.translate.instant('profile.editor.cancel.warning.confirm'),
                        noButtonText: this.translate.instant('profile.editor.cancel.warning.reject'),
                        reverseButtons: true,
                    })
                        .then(function (action) {
                        if (action) {
                            _this.isDeactivate = false;
                            _this.locationCommon.back();
                        }
                    });
                }
        }
    };
    /**
     * Builds the form group and subscribes on change country field.
     * @desc Standard fields presetting and validators appointment.
     */
    AddressEditorComponent.prototype.buildForm = function () {
        this.addrForm = this.fb.group({
            'firstName': [
                '', [Validators.required, Validators.pattern(AppValues.namePattern), Validators.minLength(2), Validators.maxLength(20)]
            ],
            'lastName': [
                '', [Validators.required, Validators.pattern(AppValues.namePattern), Validators.minLength(2), Validators.maxLength(20)]
            ],
            'phone': [
                '', [Validators.required, Validators.pattern(AppValues.phonePattern)]
            ],
            'address1': [
                '', [Validators.required, antiPatternMatcher(AppValues.charsAntiPattern), Validators.minLength(6), Validators.maxLength(100)]
            ],
            'address2': [
                '', [antiPatternMatcher(AppValues.charsAntiPattern), Validators.maxLength(100)]
            ],
            'city': [
                '', [Validators.required, Validators.pattern(AppValues.cityPattern), Validators.minLength(2)]
            ],
            'state': [
                '', [Validators.pattern(AppValues.statePattern)]
            ],
            'postCode': [
                '', [Validators.required, Validators.pattern(AppValues.codePattern), Validators.minLength(2)]
            ],
            'country': [
                '', [Validators.required]
            ]
        });
        this.subscribeToCountryChange();
    };
    /**
     * Dynamically adds/removes required validator.
     */
    AddressEditorComponent.prototype.subscribeToCountryChange = function () {
        var _this = this;
        this.countrySubscription = this.addrForm.get('country').valueChanges.subscribe(function (country) {
            var stateControl = _this.addrForm.get('state');
            if (country && country !== 'United States') {
                _this.addrForm.patchValue({ state: '' });
                stateControl.setValidators([Validators.pattern(AppValues.statePattern)]);
                stateControl.setErrors(null);
            }
            else {
                if (stateControl.value === '') {
                    stateControl.setErrors({ required: true });
                    stateControl.setValidators(Validators.required);
                }
            }
        });
    };
    return AddressEditorComponent;
}());
export { AddressEditorComponent };
