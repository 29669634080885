/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./dialogs-list-item.component";
var styles_DialogsListItemComponent = [i0.styles];
var RenderType_DialogsListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogsListItemComponent, data: {} });
export { RenderType_DialogsListItemComponent as RenderType_DialogsListItemComponent };
function View_DialogsListItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "badge"]], [[1, "aria-labelledby", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "relative"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("There are " + _co.dialog.unread_messages_count) + " unread messages"); _ck(_v, 0, 0, currVal_0); var currVal_1 = ("dialog_unread_message_count_" + _co.id); _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.dialog.unread_messages_count; _ck(_v, 2, 0, currVal_2); }); }
export function View_DialogsListItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "dialog-list__item"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "box box-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "dialog-name"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "last-message"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "box box-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h4", [["class", "time-last-message"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "relative unread_messages_count"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogsListItemComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "remove-button"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeDialog($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "icon-trash-empty"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.dialog.unread_messages_count; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("dialog-container_" + _co.id); _ck(_v, 1, 0, currVal_0); var currVal_1 = ("dialog-name_" + _co.id); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.dialog.interlocutor_title; _ck(_v, 4, 0, currVal_2); var currVal_3 = ("last-message_" + _co.id); _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.dialog.last_message; _ck(_v, 6, 0, currVal_4); var currVal_5 = ("time-last-message_" + _co.id); _ck(_v, 8, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), (_co.dialog.last_message_date * _co.THOUSAND), _co.format)); _ck(_v, 9, 0, currVal_6); var currVal_8 = ("removeItem_" + _co.id); _ck(_v, 14, 0, currVal_8); }); }
export function View_DialogsListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dialogs-list-item", [], null, null, null, View_DialogsListItemComponent_0, RenderType_DialogsListItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogsListItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogsListItemComponentNgFactory = i1.ɵccf("dialogs-list-item", i3.DialogsListItemComponent, View_DialogsListItemComponent_Host_0, { dialog: "dialog", id: "id" }, { removeEvent$: "removeEvent$", showEvent$: "showEvent$" }, []);
export { DialogsListItemComponentNgFactory as DialogsListItemComponentNgFactory };
