/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bizipix-editor.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/accordion/accordion.component.ngfactory";
import * as i3 from "../shared/accordion/accordion.component";
import * as i4 from "../shared/market-table/market-table.component.ngfactory";
import * as i5 from "../shared/market-table/market-table.component";
import * as i6 from "../services/item.service";
import * as i7 from "./picker-cell.component.ngfactory";
import * as i8 from "./picker-cell.component";
import * as i9 from "@angular/common";
import * as i10 from "./picker-block.component";
var styles_PickerBlockComponent = [i0.styles];
var RenderType_PickerBlockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PickerBlockComponent, data: {} });
export { RenderType_PickerBlockComponent as RenderType_PickerBlockComponent };
function View_PickerBlockComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "accordion", [["class", "abstract-list__li__footer"]], null, null, null, i2.View_AccordionComponent_0, i2.RenderType_AccordionComponent)), i1.ɵdid(1, 49152, null, 0, i3.AccordionComponent, [], { indexOfItem: [0, "indexOfItem"], title: [1, "title"], label: [2, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "market-table", [], null, [[null, "clickEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent$" === en)) {
        var pd_0 = (_co.onItemClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MarketTableComponent_0, i4.RenderType_MarketTableComponent)), i1.ɵdid(3, 114688, null, 0, i5.MarketTableComponent, [i6.ItemService], { items: [0, "items"], indexOfItem: [1, "indexOfItem"] }, { clickEvent$: "clickEvent$" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.index; var currVal_1 = (_co.item["nestedMarkets"].length + " markets found, nearest in "); var currVal_2 = (_co.item["nestedMarkets"][0].distance + "mi"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.item["nestedMarkets"]; var currVal_4 = _co.index; _ck(_v, 3, 0, currVal_3, currVal_4); }, null); }
export function View_PickerBlockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "picker-cell", [["role", "parentItem"]], null, [[null, "clickEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent$" === en)) {
        var pd_0 = (_co.onItemClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_PickerCellComponent_0, i7.RenderType_PickerCellComponent)), i1.ɵdid(1, 114688, null, 0, i8.PickerCellComponent, [i6.ItemService], { data: [0, "data"], index: [1, "index"] }, { clickEvent$: "clickEvent$" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PickerBlockComponent_1)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; var currVal_1 = _co.index; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ((_co.item["is_parent"] && _co.item["nestedMarkets"]) && _co.item["nestedMarkets"].length); _ck(_v, 3, 0, currVal_2); }, null); }
export function View_PickerBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "picker-block", [], null, null, null, View_PickerBlockComponent_0, RenderType_PickerBlockComponent)), i1.ɵdid(1, 49152, null, 0, i10.PickerBlockComponent, [], null, null)], null, null); }
var PickerBlockComponentNgFactory = i1.ɵccf("picker-block", i10.PickerBlockComponent, View_PickerBlockComponent_Host_0, { item: "item", index: "index" }, { itemSelectEvent$: "itemSelectEvent$" }, []);
export { PickerBlockComponentNgFactory as PickerBlockComponentNgFactory };
