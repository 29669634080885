
        <div class="map-info__container" (click)="showDetails(data.ID)">
            <div *ngIf="type === 'market'" class="market">
                <p class="title" data-test-id="marketTitle">{{data.market_title}}</p>

                <p data-test-id="marketSchedule" *ngIf="_w.location.pathname === '/map-search'">
                    <span data-test-id="timeDatesInfo">{{timeDatesInfo}}</span>
                </p>
            </div>

            <div *ngIf="type === 'seller'" class="seller">
                <div class="image__container" [style.background-image]="'url('+imageUrl+')'"></div>
                <div class="info">
                    <h3 data-test-id="sellerTitle">{{data.title}}</h3>
                    <div *ngIf="_w.location.pathname === '/map-search'">
                        <rating-display [dataTestId]="'map-info__seller-rate'" [rate]="data.rate"></rating-display>
                        <span>{{data.voted}}</span>
                    </div>
                </div>
            </div>

            <div class="link">
                <span class="sprite sprite-map_callout_arrow_2x"></span>
            </div>
        </div>
    