/**
 * BiziBaza app
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CommonErrorResonse } from '../model/commonErrorResonse';
import { SearchMarketResponseBody } from '../model/searchMarketResponseBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SearchService {

    protected basePath = 'https://dev-backend.bizibaza.com/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Returns markets found by given search string and sorted ascending by distance to given location.
     * @param Token Session token.
     * @param key_word String that should be used by search.
     * @param lat Latitude of user that requests markets to sort search results.
     * @param lon Longitude of user that requests markets to sort search results.
     * @param offset Number of page.
     * @param count Quantity of records per given page.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchMarketGet(Token: string, key_word: string, lat: number, lon: number, offset: number, count: number, observe?: 'body', reportProgress?: boolean): Observable<SearchMarketResponseBody>;
    public searchMarketGet(Token: string, key_word: string, lat: number, lon: number, offset: number, count: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SearchMarketResponseBody>>;
    public searchMarketGet(Token: string, key_word: string, lat: number, lon: number, offset: number, count: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SearchMarketResponseBody>>;
    public searchMarketGet(Token: string, key_word: string, lat: number, lon: number, offset: number, count: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Token === null || Token === undefined) {
            throw new Error('Required parameter Token was null or undefined when calling searchMarketGet.');
        }

        if (key_word === null || key_word === undefined) {
            throw new Error('Required parameter key_word was null or undefined when calling searchMarketGet.');
        }

        if (lat === null || lat === undefined) {
            throw new Error('Required parameter lat was null or undefined when calling searchMarketGet.');
        }

        if (lon === null || lon === undefined) {
            throw new Error('Required parameter lon was null or undefined when calling searchMarketGet.');
        }

        if (offset === null || offset === undefined) {
            throw new Error('Required parameter offset was null or undefined when calling searchMarketGet.');
        }

        if (count === null || count === undefined) {
            throw new Error('Required parameter count was null or undefined when calling searchMarketGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (key_word !== undefined && key_word !== null) {
            queryParameters = queryParameters.set('key_word', <any>key_word);
        }
        if (lat !== undefined && lat !== null) {
            queryParameters = queryParameters.set('lat', <any>lat);
        }
        if (lon !== undefined && lon !== null) {
            queryParameters = queryParameters.set('lon', <any>lon);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }

        let headers = this.defaultHeaders;
        if (Token !== undefined && Token !== null) {
            headers = headers.set('Token', String(Token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SearchMarketResponseBody>(`${this.basePath}/search_market`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
