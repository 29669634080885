import { Component }            from '@angular/core';
import { Router }               from '@angular/router';

import { BizipixEditorService } from '../bizipix-editor/bizipix-editor.service';
import { ModalService }         from '../modal/modal.service';


@Component({
    selector:   'inventory-header',
    styleUrls:  ['../../assets/styles/app-header.sass'],

    template:   `
         <header class="app-header">
            <nav class="app-header__container" role="inventory-list">
                <div class="navbar__left">
                    <button [routerLink]="'/settings'" data-test-id="settingsLink" type="button">
                        <img width="25" height="25" [src]="'../../assets/images/svg_icons/settings_icon.svg'" alt="settings">
                    </button>
                </div>

                <div class="navbar__center"></div>

                <div class="navbar__right inventory-list__menu">
                    <button (click)="goToSheetEditor()" data-test-id="sheetEditorLink" type="button"
                        class="nav__link sprite sprite-sheet_icon">
                    </button>

                    <button (click)="showBizipixEditor()" data-test-id="biziPixLink" type="button"
                       class="nav__link"><img width="40" height="35" [src]="'../../assets/images/svg_icons/shopix-active.svg'" alt="BiziPix"></button>
                </div>
            </nav>
        </header>
    `
})


export class InventoryHeaderComponent {
   constructor(
       private bizipixEditorService:    BizipixEditorService,
       private modalService:            ModalService,
       private router:                  Router,
   ) { }


    showBizipixEditor() {
        this.bizipixEditorService.editBizipix();
    }


    goToSheetEditor() {
        this.modalService.showSpinner();
        setTimeout(() => this.router.navigate(['/inventory-sheet']));
    }

}
