
        <label class="input__label input__select-style no-arrow clearfix">
            <select 
                *ngIf="units"
                #Select  
                [name]="_name" 
                [disabled]="isDisabled"
                [attr.data-test-id]="_name"
                [style.color]="Select.selectedIndex === 0 ? '#ddd' : 'inherit'"
                [class.disabled-field]="isDisabled"
                (change)="updateItem(_name, Select.value)"
                (change)="updateItem(_ID, Select.options[Select.selectedIndex].dataset.id)"
            >
                
                <option disabled selected value> {{ default }} </option>
                <option class="option"  *ngFor="let unit of units"
                        [ngValue]="unit.unit_name"
                        [attr.data-id]="unit.ID"
                        [attr.data-test-id]="unit.unit_name"
                        [selected]="unit.unit_name === value"
                        [disabled]="!unit.ID">
                    {{unit.unit_name}}</option>

            </select>
        </label>
    