import { NgModule }                     from '@angular/core';
import { CommonModule }                 from '@angular/common';
import { FormsModule }                  from '@angular/forms';
import { RouterModule }                 from '@angular/router';

import { EventSearchComponent }         from './search-lists/event-search.component';
import { GeoLocationService }           from '../services/geolocation.service';
import { ItemSearchComponent }          from './search-lists/item-search.component';
import { ItemSearchSublistComponent }   from './search-lists/item-search-sublist.component';
import { MarketSearchComponent }        from './search-lists/market-search.component';
import { SearchHeaderComponent }        from './search-header.component';
import { AppSearchService }                from './search.service';
import { SearchStrategyComponent }      from './search.component';
import { SharedModule }                 from '../shared/shared.module';
import { SellerSearchComponent }        from './search-lists/user-search.component';
import { UsersListComponent }           from './search-lists/users-list.component';
import {ProductSearchListComponent} from './search-lists/product-search-list.component';

@NgModule({
    imports:      [
        CommonModule,
        FormsModule,
        SharedModule,
        RouterModule,
    ],
    declarations: [
        EventSearchComponent,
        ItemSearchComponent,
        ItemSearchSublistComponent,
        MarketSearchComponent,
        SearchStrategyComponent,
        SearchHeaderComponent,
        SellerSearchComponent,
        UsersListComponent,
        ProductSearchListComponent
    ],
    entryComponents: [
        EventSearchComponent,
        ItemSearchComponent,
        ItemSearchSublistComponent,
        MarketSearchComponent,
        SellerSearchComponent,
        UsersListComponent,
        ProductSearchListComponent
    ],
    exports:      [ SearchStrategyComponent ],
    bootstrap: [SearchStrategyComponent],
    providers:    [ AppSearchService, GeoLocationService ]
})
export class SearchModule { }
