import { AfterContentInit, ElementRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { InventoryListService } from './inventory-list.service';
import { ProductEditorService } from '../product-editor/product-editor.service';
import { ModalService } from '../modal/modal.service';
var InventoryListComponent = /** @class */ (function () {
    function InventoryListComponent(elementRef, activatedRoute, inventoryListService, productEditorService, injector) {
        this.elementRef = elementRef;
        this.activatedRoute = activatedRoute;
        this.inventoryListService = inventoryListService;
        this.productEditorService = productEditorService;
        this.injector = injector;
        /**
         * @desc Fetches inventory list data via InventoryListService and
         * renders it agregating InventoryListItemComponents in its template.
         * Item click causes delegation to the service to showing the selected item.
         */
        this.componentDestroyed$ = new Subject();
        this.items = [];
        this.timeout = null;
        this.loadItems = 3;
        this.countOfItems = 0;
    }
    /**
     * ID tracker for ngFor directive.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {SellingItemResponseBody} item
     * @return {string}
     */
    // trackByID(index: number, item: SellingItemResponseBody): string { return item.ID; }
    InventoryListComponent.prototype.isArrayItems = function (items) {
        return items && Array.isArray(items) && typeof items[0] === 'object';
    };
    /**
     * @desc Fetches inventory list data via InventoryListService.
     */
    InventoryListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.category = true;
        this.inventoryListService.restoreList();
        this._takeUnitSubItem(this.inventoryListService.getInventItems()).subscribe(function (s) {
            _this.items = s;
        });
        // remove for fast performance
        // this.items = this._takeUnitSubItem(this.inventoryListService.getList());
        this.inventoryListService.startPolling(this.category);
    };
    // For fast view rendering
    // like as virtual loading
    InventoryListComponent.prototype.generateList = function (items) {
        this.items = items;
    };
    /**
    * @desc Fetches inventory list data via InventoryListService.
    */
    InventoryListComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.updateItems();
        });
    };
    InventoryListComponent.prototype.updateItems = function () {
        var _this = this;
        this._takeUnitSubItem(this.inventoryListService.getList()).subscribe(function (s) {
            _this.items = s;
        });
        this.items = this.inventoryListService.getCurrentInventoryItems();
    };
    /**
     * Method for Subscribing Declaratively with takeUntil
     * @param method
     * @returns {Subscription}
     * @private
     */
    InventoryListComponent.prototype._takeUnitSubItem = function (method) {
        var _this = this;
        return method.takeUntil(this.componentDestroyed$)
            .map(function (items) {
            _this.generateList(items);
            return items;
        });
    };
    /**
     * Delegates to Inventory List Service.
     * @param item
     */
    InventoryListComponent.prototype.onShow = function (item) {
        this.productEditorService.showProductDetails(item);
    };
    /**
     * Delegates to Inventory List Service.
     * @param item
     */
    InventoryListComponent.prototype.onRemove = function (item) {
        var _this = this;
        this.inventoryListService.removeItemDialog()
            .subscribe(function (action) {
            if (action) {
                _this.inventoryListService.removeProduct(action, item)
                    .subscribe(function (items) {
                    _this.generateList(items);
                });
            }
        });
    };
    Object.defineProperty(InventoryListComponent.prototype, "modalService", {
        get: function () {
            return this.injector.get(ModalService);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * OnDestroy Inventory List Service.
     * Unsubscribe for inventoryList.
     */
    InventoryListComponent.prototype.ngOnDestroy = function () {
        if (this.componentDestroyed$) {
            this.componentDestroyed$.unsubscribe();
        }
        this.category = false;
        this.inventoryListService.startPolling(this.category);
    };
    return InventoryListComponent;
}());
export { InventoryListComponent };
