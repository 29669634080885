import {GetItemSuggestionsForShoppingListResponseBody} from '../../../swagger-gen__output_dir';
import {CategoryInterface, NavTreeInterface} from '../interfaces/category.interface';
import AppValues from '../common/app.values';

export interface LocalCacheInterface {
    caching(data: {}, cache: CacheInterface[], currentUrl?: string): CacheInterface[];
    // getCache(cache: CacheInterface[]): CacheInterface[];
}
export interface CacheInterface {
    url: string;

    // tslint:disable-next-line: no-any
    data: any;
}

export enum LocalCachingCreatorMapping {
    ConcreteNavigationTreeCaching = 'ConcreteNavigationTreeCaching',
    ConcreteShortNavigationTreeCaching = 'ConcreteShortNavigationTreeCaching',
    ConcreteSearchCaching = 'ConcreteSearchCaching',
    ConcreteSuggestionsCaching = 'ConcreteSuggestionsCaching'
}


export class LocalCaching {

    private searchCache: CacheInterface[] = [];
    private suggestionCache: CacheInterface[] = [];
    private navigationTree: CacheInterface[] = [];
    private shortNavigationTree: CacheInterface[] = [];

    readonly mapping = {
        ConcreteSearchCaching: this.searchCache,
        ConcreteSuggestionsCaching: this.suggestionCache,
        ConcreteNavigationTreeCaching: this.navigationTree,
        ConcreteShortNavigationTreeCaching: this.shortNavigationTree,
    };

    public creatorMapping = {
        ConcreteNavigationTreeCaching: new ConcreteNavigationTreeCaching(),
        ConcreteShortNavigationTreeCaching: new ConcreteShortNavigationTreeCaching(),
        ConcreteSearchCaching: new ConcreteSearchCaching(),
        ConcreteSuggestionsCaching: new ConcreteSuggestionsCaching()
    };

    constructor() {}

    public isExistCache(creatorName: LocalCachingCreatorMapping) {
        const creator: CreatorLocalCaching = this.creatorMapping[creatorName];
        let cache;

        cache = this.mapping[creatorName];

        return (cache.length !== 0 && typeof cache.find((c: CacheInterface) => window.location.href.toUpperCase() === c.url.toUpperCase()) !== 'undefined');
    }
    public setCache(creatorName: LocalCachingCreatorMapping, data: {}, currentUrl?: string) {

        const creator: CreatorLocalCaching = this.creatorMapping[creatorName];

        let cached: CacheInterface[];

        cached = creator.setLocalCaching(data, this.mapping[creatorName], currentUrl);
        this.mapping[creatorName] = cached;
    }

    public getAllCache(creatorName: LocalCachingCreatorMapping): CacheInterface[] {
        const creator: CreatorLocalCaching = this.creatorMapping[creatorName];
        return this.mapping[creatorName];
    }

    // tslint:disable-next-line: no-any
    public getOneCache(creatorName: LocalCachingCreatorMapping, defaultValue: any): any {
        const creator: CreatorLocalCaching = this.creatorMapping[creatorName];
        let cached: CacheInterface[];

        cached = this.mapping[creatorName];

        let cacheList = cached.filter((c: CacheInterface) => window.location.href.toUpperCase() === c.url.toUpperCase());

        if (cacheList.length) {
            return cacheList[0].data;
        } else {
            return defaultValue;
        }
    }

    public clearAllCache(creatorName: LocalCachingCreatorMapping) {
        const creator: CreatorLocalCaching = this.creatorMapping[creatorName];
        this.mapping[creator.constructor.name] = [];
    }

    public clearOneCache(creatorName: LocalCachingCreatorMapping, url: string) {
        const creator: CreatorLocalCaching = this.creatorMapping[creatorName];
        let cached: CacheInterface[] = this.mapping[creator.constructor.name];

        this.mapping[creator.constructor.name] = cached.filter((c: CacheInterface) => url.toUpperCase() !== c.url.toUpperCase());
    }

}
/**
 * The Creator class declares a factory method that should return an object LocalCacheInterface
 * Creator subclasses typically provide an implementation of this method.
 */
export abstract class CreatorLocalCaching {

    public abstract factoryMethod(): LocalCacheInterface;

    public setLocalCaching(data: {}, cache: CacheInterface[], currentUrl?: string) {
        const product = this.factoryMethod();

        return product.caching(data, cache, currentUrl);
    }
}



export class ConcreteSearchCaching extends CreatorLocalCaching {
    public factoryMethod(): LocalCacheInterface {
        return new SearchCaching();
    }
}
export class ConcreteShortNavigationTreeCaching extends CreatorLocalCaching {
    public factoryMethod(): LocalCacheInterface {
        return new ShortNavigationTreeCaching();
    }
}
export class ConcreteNavigationTreeCaching extends CreatorLocalCaching {
    public factoryMethod(): LocalCacheInterface {
        return new NavigationTreeCaching();
    }
}
export class ConcreteSuggestionsCaching extends CreatorLocalCaching {
    public factoryMethod(): LocalCacheInterface {
        return new SuggestionsCaching();
    }
}

class ShortNavigationTreeCaching implements LocalCacheInterface {

    public caching(data: {}, cache: CacheInterface[] | undefined, currentUrl?: string) {
        let url: string = currentUrl || window.location.href;
        let field: {url: string, data: CategoryInterface[]};

        if (typeof cache === 'undefined') {
            cache = [];
        }

        if (url) {
            field = {url, data: data as CategoryInterface[]};
            cache.unshift(field);
        }

        return cache;
    }
}
class NavigationTreeCaching implements LocalCacheInterface {

    public caching(data: {}, cache: CacheInterface[] | undefined, currentUrl?: string) {
        let url: string = currentUrl || window.location.href;
        let field: {url: string, data: CategoryInterface[]};

        if (typeof cache === 'undefined') {
            cache = [];
        }

        if (url) {
            field = {url, data: data as CategoryInterface[]};
            cache.unshift(field);
        }

        return cache;
    }
}
class SuggestionsCaching implements LocalCacheInterface {

    public caching(data: {}, cache: CacheInterface[] | undefined, currentUrl?: string) {
        let url: string = currentUrl || window.location.href;
        let field: {url: string, data: GetItemSuggestionsForShoppingListResponseBody};

        if (typeof cache === 'undefined') {
            cache = [];
        }

        if (url) {
            field = {url, data: data as GetItemSuggestionsForShoppingListResponseBody};
            cache.unshift(field);
        }

        return cache;
    }
}
class SearchCaching implements LocalCacheInterface {

    public caching(data: {}, cache: CacheInterface[] | undefined, currentUrl?: string) {
        let url: string = currentUrl || window.location.href;

        if (typeof cache === 'undefined') {
            cache = [];
            const field = {url, data};
            cache.unshift(field);
            return cache;
        }

        // let fieldIndex = cache.findIndex((c: CacheInterface) => c.url.toUpperCase() === url.toUpperCase());

        if (typeof cache !== 'undefined') {
            const field = {url, data};
            cache.unshift(field);
        }

        return cache;
    }
}
