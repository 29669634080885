/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rect-switch.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./rect-switch.component";
var styles_RectSwitchComponent = [i0.styles];
var RenderType_RectSwitchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RectSwitchComponent, data: {} });
export { RenderType_RectSwitchComponent as RenderType_RectSwitchComponent };
export function View_RectSwitchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "onoffswitch"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "inactive": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["class", "onoffswitch-checkbox"], ["data-test-id", "detailSwitchAlert"], ["name", "onoffswitch"], ["type", "checkbox"]], [[8, "id", 0], [8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "label", [["class", "onoffswitch-label"], ["tabindex", "0"]], [[8, "htmlFor", 0], [1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "onoffswitch-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "onoffswitch-switch"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "onoffswitch"; var currVal_1 = _ck(_v, 2, 0, _co.disabled); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co._id; var currVal_3 = _co.state; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co._id; var currVal_5 = _co.dataTestId; _ck(_v, 4, 0, currVal_4, currVal_5); }); }
export function View_RectSwitchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rect-switch", [], null, null, null, View_RectSwitchComponent_0, RenderType_RectSwitchComponent)), i1.ɵdid(1, 49152, null, 0, i3.RectSwitchComponent, [], null, null)], null, null); }
var RectSwitchComponentNgFactory = i1.ɵccf("rect-switch", i3.RectSwitchComponent, View_RectSwitchComponent_Host_0, { dataTestId: "dataTestId", disabled: "disabled", state: "state" }, { stateEvent$: "stateEvent$" }, []);
export { RectSwitchComponentNgFactory as RectSwitchComponentNgFactory };
