var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef } from '@angular/core';
import { MarketService } from '../../market/market.service';
import { SearchListClass } from './search-list.class';
var MarketSearchComponent = /** @class */ (function (_super) {
    __extends(MarketSearchComponent, _super);
    function MarketSearchComponent(elementRef, marketService) {
        var _this = _super.call(this, elementRef) || this;
        _this.elementRef = elementRef;
        _this.marketService = marketService;
        return _this;
    }
    MarketSearchComponent.prototype.onClick = function (item) {
        this.marketService.showDetails(item);
    };
    return MarketSearchComponent;
}(SearchListClass));
export { MarketSearchComponent };
