import {
    AfterContentChecked,
    AfterViewInit, ChangeDetectorRef,
    Component, ElementRef, EventEmitter,
    Input, OnInit, Output
} from '@angular/core';
import 'rxjs/add/operator/switchMap';

import {
    AvailableEventTotalQtyInterface,
    CartItemTemplateInterface,
    ChangeProductInterface, 
    DeliveryChangeInterface
} from '../interfaces/cart.interface';
import { ItemService }                  from '../services/item.service';
import { ModalService }                 from '../modal/modal.service';
import parseInputFloat                  from '../common/parseInputFloat';
import { UserService } from '../user/user.service';
import {ShopperDetailsService} from '../shopper-details/shopper-details.service';
import {ProductDetailsService} from '../product-details/product-details.service';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {TranslateService} from '@ngx-translate/core';
import AppValues from '../common/app.values';
import {Address, Event} from '../../../swagger-gen__output_dir';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';


@Component({
    selector: 'cart-item',
    template: `
        <form [class]="'cart-item ' + (data.isItemWasChange ? 'backgroundYellow' : 'backgroundWhite')" #cartItemForm="ngForm" [attr.data-test-id]="'cartItem_' + testIndex">

            <div class="cart-item__header">
                <div
                   *ngIf="isExpired"
                   class="marker__expired"
                   [attr.data-test-id]="'expiredLabel_' + testIndex"
                ></div>

                <div class="cart-item__img-container">
                    <span *ngIf="!data.item.photoURL" [attr.data-test-id]="'cartItemImage_'+ testIndex"
                          class="sprite sprite-no_image_icon"></span>
                    <img *ngIf="data.item.photoURL" [attr.data-test-id]="'cartItemImage_'+ testIndex"
                         [src]="data.item.photoURL">
                </div>

                <div class="cart-item__title-container">
                    <div>
                        <h4 class="cart-item__title" (click)="showProductDetails(data.item.ID)"
                            [attr.data-test-id]="'cartItemTitle_' + testIndex">{{data.item.title}}</h4>
                        <span class="cart-item__productno"
                              [attr.data-test-id]="'cartItemProductNo_'+ testIndex">Item#{{data.item.productNo}}</span>
                        <h5 class="cart-item__seller-name" (click)="showSellerDetails(data.item.sellerID)"
                            [attr.data-test-id]="'cartItemSellerName_' + testIndex">{{data.item.sellerName}}</h5>
                    </div>
                    <h4 class="cart-item__price" [attr.data-test-id]="'cartItemPrice_' + testIndex">
                        {{ data.item['isItemPrice'] / 100 | currency:'USD'}} / {{ data.item['isItemUnitsName'] }}
                    </h4>
                </div>
            </div>

            <div class="cart-item__quantity">
                <div class="cart-item__qnt">
                    <h4>{{ 'shoppingCart.item.quantity' | translate }}</h4>

                    <div class="special-input-block">

                        <button [attr.data-test-id]="'cartItemQntDec_'+testIndex" (click)="decrement()">-</button>
                        <input class="input-result" type="text" name="quantity"
                               min="0" max="{{data.item.qty}}" minLength="1"
                               [attr.data-test-id]="'cartItemQnt_'+testIndex" [value]="quantity | number"
                               (blur)="onInput($event.target)"
                               (paste)="false"
                               (keypress)="keyPress($event)">
                        <button [attr.data-test-id]="'cartItemQntInc_'+testIndex" (click)="increment()">+</button>

                    </div>
                </div>
                <div class="cart-item__subtotal">
                    <h4>{{ 'common.SubTotal' | translate }}:</h4>
                    <div>
                        <p *ngIf="subtotal" [attr.data-test-id]="'cartItemSubtotal_'+ testIndex">
                            {{ subtotal / 100 | currency:'USD' }}</p>
                        <p *ngIf="!subtotal">--</p>
                    </div>
                </div>
                <div class="cart-item__expires">
                    <h4>{{ 'shoppingCart.item.expiresIn' | translate }}</h4>
                    <span [attr.data-test-id]="'cartItemExpiresIn_'+ testIndex" class="expire-time">
                        <i class="icon-clock_icon_shopping_cart"></i>
                        <p [ngClass]="expiresIn==='00:00' ? 'warning' : 'okay'">{{expiresIn}}</p>
                    </span>
                </div>
                <span *ngIf="expiresIn==='00:00'" class="info__msg error">{{ 'shoppingCart.item.expiresIn.tips' | translate }}</span>

                <div class="cart-item__btn-block">
                    <button (click)="remove()" class="button__lightgreen cart-remove"
                            [disabled]="busyRemoving"
                            [attr.data-test-id]="'cartRemoveItem_' + testIndex">
                        <i class="fa fa-remove"></i>{{ 'shoppingCart.item.removeFromCartButton' | translate }}
                    </button>
                    <button *ngIf="expiresIn==='00:00'" (click)="refresh()" class="button__lightgreen buy"
                            role="refresh" [attr.data-test-id]="'cartRefreshItem_' + testIndex">
                        <i class="fa fa-refresh"></i>{{ 'shoppingCart.item.refreshStatusButton' | translate }}
                    </button>
                </div>
            </div>

            <div class="cart-item__delivery">
                <ul class="cart-item__delivery-choice radiobtn--block">
                    <li (click)="onDeliveryToggle(US_DELIVERY, $event)"
                        [ngClass]="!data.item.deliveryOffered && 'cart-item__delivery-choice__disabled'"
                        [attr.data-test-id]="'cartItemShipping_' + testIndex">
                        <div>
                            <input name="deliveryOffered" type="radio" value="true"
                                   [id]="US_DELIVERY+'_'+testIndex"
                                   [checked]="deliveryOffered === US_DELIVERY">

                            <div class="check"></div>
                        </div>
                        <label [for]="US_DELIVERY">
                            <p>{{ 'common.delivery' | translate }}</p>
                            <small [attr.data-test-id]="'shipping_terms_info_' + testIndex">
                                {{data.item.shipping_terms || 'product.shippingTerms' | translate}}
                            </small>
                        </label>
                    </li>
                    <li (click)="onDeliveryToggle(PICK_UP, $event)"
                        [ngClass]="!data.item.marketPickOffered && 'cart-item__delivery-choice__disabled'"
                        [attr.data-test-id]="'cartItemPickUp_' + testIndex">
                        <div>
                            <input name="deliveryOffered" type="radio" value="false"
                                   [id]="PICK_UP+'_'+testIndex"
                                   [checked]="deliveryOffered === PICK_UP">

                            <div class="check"></div>
                        </div>
                        <label [for]="PICK_UP">
                            <p>{{ 'common.pickUp' | translate }}</p>
                            <small [attr.data-test-id]="'pickUpAddress_' + testIndex">{{ pickUpAddress }}</small>
                        </label>

                    </li>
                </ul>
            </div>

            <div class="cart-item__total">
                <div class="cart-item__shippingtotal">
                    <h4>{{ 'shoppingCart.totalBlock.shipping' | translate }}</h4>
                    <div>
                        <p *ngIf="shippingPrice" [attr.data-test-id]="'cartItemShippingPrice_'+ testIndex">
                            {{ shippingPrice | currency:'USD' }}</p>
                        <p *ngIf="!shippingPrice">{{ 0 | currency:'USD' }}</p>
                    </div>
                </div>
                <div class="cart-item__total-total">
                    <h4>{{ 'shoppingCart.totalBlock.totalCost' | translate }}</h4>
                    <div>
                        <p *ngIf="totalCost" [attr.data-test-id]="'cartItemTotalCost_'+ testIndex">
                            {{totalCost / 100 | currency:'USD' }}</p>
                        <p *ngIf="!totalCost">--</p>
                    </div>
                </div>
            </div>

        </form>
    `
})

export class CartItemComponent extends UnsubscribeOnDestroyAbsctractClass implements OnInit, AfterContentChecked, AfterViewInit {
    @Input() data:              CartItemTemplateInterface;
    @Input() expiresIn:         string;
    @Input() testIndex:         string;
    @Output() itemChange$:      EventEmitter<ChangeProductInterface> = new EventEmitter<ChangeProductInterface>();
    @Output() deliveryChange$:  EventEmitter<DeliveryChangeInterface> = new EventEmitter<DeliveryChangeInterface>();
    @Output() removeItem$:      EventEmitter<CartItemTemplateInterface> = new EventEmitter<CartItemTemplateInterface>();

    private qtyInput:   HTMLInputElement;

    public US_DELIVERY: string = AppValues.US_DELIVERY;
    public PICK_UP: string = AppValues.PICK_UP;

    public busyRemoving:               boolean = false;
    public deliveryOffered?:           string = '';
    public interval:                   any;
    public quantity:                   number;
    public shippingPrice:              number = 0;
    public subtotal:                   number;
    public touched:                    boolean = false;
    public totalCost:                  number;
    public timeout                     = null;
    public pickUpAddress:              string = '';
    public isExpired:                  boolean = false;

    public deliveryMethodID: {shipping: string, pickUp: string};

    constructor(
        public translate: TranslateService,
        private elementRef:     ElementRef,
        private itemService:    ItemService,
        private modalService:   ModalService,
        private userService:    UserService,
        private shopperDetailsService: ShopperDetailsService,
        private productDetailsService: ProductDetailsService,
        private changeDetector: ChangeDetectorRef
    ) {
        super();
        this.translate      = translate;
    }

    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
    }

    public ngOnInit(): void {
        this.deliveryOffered = this.data.delivery ? this.data.delivery.method : '';
        this.quantity = this.data.quantity;

        this._checkItemFn(this.data['item']);
        this._calculate({oninit: true});

        if (this.data.item) {
            this.deliveryMethodID = {
                shipping: 'shipping_' + this.data.item.ID,
                pickUp: 'deliveryPickUp_' + this.data.item.ID
            };
            this.pickUpAddress = this.getPickUpAddress(this.data);
            this.isExpired = this.itemService.isExpiredFn(this.data.item);
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        clearInterval(this.interval);
    }

    public ngAfterViewInit(): void {
        this.qtyInput = this.elementRef.nativeElement.querySelector('.input-result');
    }

    public increment(): void {
        const availableEventTotalQty: AvailableEventTotalQtyInterface = this.itemService.availableEventTotalQty(this.data.item, this.quantity);
        if (
            this.userService.isGuestMode
               || this.quantity === this.data.item.qty
               || ( availableEventTotalQty.isProductWithEvents && this.quantity >= availableEventTotalQty.available_qty)
        ) {
            this.showQuantityError(this.data.item.qty);
            return;
        }

        this.quantity += 1;
        this.emitItemChange();

        this.changeWithTimeOut(String(this.quantity)).then((val: string) => {
            this.qtyInput.value = val;
        });
    }


    public decrement(): void {
        if (this.userService.isGuestMode || this.quantity <= 1) {
            return;
        }

        this.quantity -= 1;
        this.emitItemChange();

        this.changeWithTimeOut(String(this.quantity)).then((val: string) => {
            this.qtyInput.value = val;
        });
    }

    /**
     * if option = true => deliveryOffered
     * if option = false => marketPickOffered
     * @param {boolean} option
     * @param {MouseEvent} event
     */
    public onDeliveryToggle(option: string, event: MouseEvent): void {
        event.preventDefault();

        if (option === this.US_DELIVERY && this.data.item.deliveryOffered
            || option === this.PICK_UP  && this.data.item.marketPickOffered) {
            this.changeDeliveryMethod(option);

        }
    }


    private changeDeliveryMethod(option: string): void {
        if (!this.data.delivery) {
            this.data.delivery = {
                method: ''
            };
        }
        this.data.delivery.method = option;
        this.deliveryOffered = option;

        this.deliveryChange$.emit({data: this.data, shipping: option});
    }

    public showProductDetails(id: string): Promise<any> {
        this.productDetailsService.setProductDetailsFrom('cart');
        return this.productDetailsService.showProductDetails(id).toPromise().then((res: any) => res, (err) => err);
    }

    public showSellerDetails(id: string): void {
        this.shopperDetailsService.showDetails(id);
    }


    /**
     * @desc check if this product is on sale
     * and set price and UnitsName
     * @param {SellingItemResponseBody | any} item
     * @returns {boolean}
     */
     private _checkItemFn(item: SellingItemResponseBody | any): void {
         item['isInOnSale']      = this.itemService.isSaleFn(item);
         item['isItemPrice']     = this.itemService.itemPriceFn(item);
         item['isItemUnitsName'] = this.itemService.priceUnitsNameFn(item);
    }


    private _calculate(params: {oninit?: boolean, withoutRequest?: boolean}) {
        this.touched = !params.oninit;

        this.subtotal = +this.quantity * this.data['item']['isItemPrice'];
        this.totalCost = this.deliveryOffered ? this.subtotal + this.shippingPrice : this.subtotal;

        if (this.subtotal === 0) {
            this.totalCost = 0;
        }

        this.emitItemChange(params.withoutRequest);
    }


    public remove(): void {
        this.busyRemoving = true;
        this.removeItem$.emit(this.data);
    }


    public refresh(): void {
        this.touched = true;
        this.emitItemChange();
    }


    private emitItemChange(withoutRequest?: boolean): void {
        this.itemChange$.emit({
            ID:         this.data.item.ID,
            product:    this.data.item,
            itemTotal:  this.totalCost,
            quantity:   Number(this.quantity),
            shipping:   this.deliveryOffered,
            touched:    this.touched,
            withoutRequest: withoutRequest
        });
    }


    /**
     * @desc  handle quantity choice
     * @param {HTMLInputElement} input
     */
    public onInput(input: HTMLInputElement): void {
        this.changeWithTimeOut(input.value).then((val: string) => {
            input.value = val;
        });
    }

    public changeWithTimeOut(value: string): Promise<string> {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        return new Promise(resolve => {
            this.timeout = setTimeout(() => {
                resolve(this.changeInputDate(value));
            }, 500);
        });
    }

    public changeInputDate(value: string): string {
        if (this.userService.isGuestMode) {
            return this.quantity.toString();
        }

        const formatedValue = this.calculateQty(value);

        this.quantity = formatedValue.num;

        this._calculate({oninit: false});
        this.emitItemChange();

        return formatedValue.formatedNumber;
    }

    /**
     * @desc  prevents producing any values outside regex on keys press
     * @param {KeyboardEvent} event
     */
    public keyPress(event: KeyboardEvent): void {
            const pattern = /[0-9]/;
            const inputChar = String.fromCharCode((event).charCode);
            if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }


    /**
     * @desc Handle shipping choice
     * @param {Event} event
     */
    public onChange(event: Event): void {
        this._calculate({withoutRequest: true});
    }


    private formatPickUpAddress(address: Address, empty_address: string = ''): string {
        if (!AppValues.isEmpty(address)) {
            return AppValues.getStringAddress(address, ', ');
        } else {
            return empty_address;
        }
    }


    private getPickUpAddress(data: CartItemTemplateInterface): string {
        if (data.item.market) {
            return 'Market: ' + data.item.market.market_title
                + ' (' + this.formatPickUpAddress(data.item.market.address) + ')';
        } else {
            return (!AppValues.isEmpty(data.seller_primary_address)) ? 'Seller: ' + this.formatPickUpAddress(data.seller_primary_address) : 'Seller: ';
        }
    }

    /**
     * @desc Calculates product's quantity based on the
     *       entered and this product available quantity.
     * @param {string} quantity
     * @private
     * @returns {number}
     */
    private calculateQty(quantity: string): {num: number, formatedNumber: string} {
       if (Number(parseInputFloat(quantity)) <= 1 || quantity === '') {
          return {num: 1, formatedNumber: '1'};
       }

       quantity = AppValues.removeMinusSymbolFromNumberFormat(quantity);
       quantity = quantity.split('.')[0];

       const qty: number = Math.round(Number(parseInputFloat(quantity)));
       const availableQty: number = this.data.item['current_quantity'];
       const qtyForReserve: number = qty - this.quantity;
       let available_qty: number = this.quantity + availableQty;

       const availableEventTotalQty: AvailableEventTotalQtyInterface = this.itemService.availableEventTotalQty(this.data.item, qty);

       if (availableEventTotalQty.isProductWithEvents) {
           available_qty = availableEventTotalQty.available_qty;
       }

       if (qty > this.quantity && qtyForReserve > availableQty) {
            this.showQuantityError(available_qty);

            return AppValues.NumberUSFormat({
                value: available_qty.toLocaleString('en-US'),
                qty: available_qty,
            });
       }

       return AppValues.NumberUSFormat({
            value: quantity,
            qty: available_qty,
        });

    }

    private showQuantityError(available_qty: number): void {
        this.modalService.error({
            title:          this.translate.instant("modal.error.title"),
            message:        this.translate.instant("shoppingCart.currentQuantity.error.message", {available_qty}),
            yesButtonText:  'Close',
        });
    }
}
