import {
    Component,
    ElementRef,
    EventEmitter,
    AfterViewInit,
    Input,
    Output, OnInit
} from '@angular/core';
import { Location } from '@angular/common';

import { ModalService } from '../modal/modal.service';
import { ProductEditorService } from '../product-editor/product-editor.service';
import {
    SearchOptionsInterface,
    SearchResultInterface,
    SearchType
} from '../interfaces/search.interface';
import { MapSearchService } from '../map-search/map-search.service';
import { CenterLocationInterface } from '../interfaces/location.interface';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { AppSearchService } from './search.service';
import { ModalArgumentsInterface, ModalFilterArgumentInterface } from '../interfaces/modal.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector:   'search-header',
    styleUrls:  ['search.sass', '../../assets/styles/app-header.sass'],
    template:   `
        <header class="app-header" role="search">
            <nav class="app-header__container">

                <div class="navbar__left">
                    <button (click)="onCancel()" type="button" data-test-id="shoppingLink">
                        <i class="icon-custom_back_icon"></i>
                    </button>
                </div>

                <div [ngClass]="locationSearch === 'event'? 'eventbar navbar__center' : 'navbar__center'">
                    <h4 *ngIf="locationSearch !== 'event'" data-test-id="searchKey">{{title || locationSearch}}</h4>

                    <label *ngIf="is_key_search === true" class="search-header__label">
                        <input class="search-header__input" type="text"
                           data-test-id="searchInp"
                           [(ngModel)]="searchValue"
                           [placeholder]="searchResult.type ? searchPlaceholder[searchResult.type] : ''"
                           (keyup.enter)="onSearch($event, searchValue)">
                    </label>
                </div>


                <div *ngIf="((locationSearch !== 'event') && (searchResult.marketList === true)) || (searchResult.type === 'user')" class="navbar__right"></div>

                <button
                   *ngIf="
                      (locationSearch !== 'event') && (searchResult.type !== 'user')
                      && (searchResult.marketList !== true)
                   "
                   type="button"
                   class="icon-container icon-25 map-header__button"
                   (click)="onMapClick()"
                   data-test-id="mapBtnTopContainer"
                >
                    <i class="icon-map_icon" data-test-id="mapBtnTop"></i>
                </button>

                <button *ngIf="!locationSearch && (searchResult.type !== 'user')"
                        type="button" class="search-header__button filter-icon__container"
                        data-test-id="filterBtn" (click)="onFilterClick()">
                    <img [src]="'../../assets/images/svg_icons/filter.svg'" alt="Filter button"/>
                </button>

                <!--<div *ngIf="locationSearch === 'event'" class="search-header__button"></div>-->
            </nav>
        </header>

        <section *ngIf="searchResult.keyWord" class="search-menu">
            <span data-test-id="resultsCount">Results: {{searchResult.foundNum}}</span>

            <!--<div class="search-menu__button" data-test-id="mapBtn"-->
                 <!--[style.display]="searchResult.foundNum ? 'inline-block' : 'none'"-->
                 <!--(click)="onMapClick()">-->
                <!--<div class="sprite sprite-map_icon_2x"></div>-->
            <!--</div>-->
            <select name="sortBy" id="" data-test-id="sortSelect"
                    *ngIf="!isNotAvailableSorting()"
                    [style.display]="searchResult.foundNum ? 'inline-block' : 'none'"
                    (change)="onSortChange($event.target.value)">
                <option disabled value="unsorted" selected="selected">Sort by: <i>(unsorted)</i></option>
                <option value="rate">Sort by rating</option>
                <option *ngIf="isTypeForSortByPrice()" value="ascending">Sort by price &#8679;</option>
                <option *ngIf="isTypeForSortByPrice()" value="descending">Sort by price &#8681;</option>
            </select>
        </section>
    `
})


export class SearchHeaderComponent implements OnInit, AfterViewInit {
    @Input() searchResult: SearchResultInterface;
    @Input() search_filter: Array<Array<ModalFilterArgumentInterface>>;
    @Input() is_key_search: boolean;
    @Input() userLocation: CenterLocationInterface;
    @Input() isAdminMode: boolean;
    @Output() changeEvent$ = new EventEmitter<SearchOptionsInterface>();

    public location: Location;
    public locationSearch: string;
    public title: string;
    public searchValue: string = '';
    public _w: any;
    public searchPlaceholder: {[key: string]: string};
    private previousValue: string = '';

    constructor(
        private elementRef: ElementRef,
        location: Location,
        private mapSearchService: MapSearchService,
        private searchService: AppSearchService,
        private modalService: ModalService,
        private productEditorService: ProductEditorService,
        private translate:    TranslateService,
    ) {
        this._w = window;
        this.locationSearch = decodeURIComponent( this._w.location.search.substr(5));
        this.location       = location;
    }

    public ngOnInit(): void {
        this.setSearchPlaceholder();
    }
    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.searchValue = this.searchResult.keyWord;
            this.previousValue = this.searchResult.keyWord;

            if (this.userLocation && this.previousValue === this.searchValue.trim()) {
                this.setFocus();
            }
        });
    }

    private setSearchPlaceholder(): void {
        this.searchPlaceholder = {
            [SearchType.Item]: this.translate.instant('search.placeholder.product'),
            [SearchType.Market]: this.translate.instant('search.placeholder.market'),
            [SearchType.Seller]: this.translate.instant('search.placeholder.seller'),
            [SearchType.Sale]: this.translate.instant('search.placeholder.sale'),
            [SearchType.User]: this.translate.instant('search.placeholder.user'),
            [SearchType.Event]: this.translate.instant('search.placeholder.event'),
            [SearchType.Active]: this.translate.instant('search.placeholder.product'),
        };
    }

    public onMapClick(): void {
        this.previousValue !== this.searchValue.trim()
        && this.initSearch(this.searchValue.trim(), true);

        let isNotCached: boolean = false;
        let isMarket: boolean = false;

        if (this.isSellerProductsList()) {
            this.searchResult.title = this.searchService.results.items[0].sellerName;
            this.title = this.searchService.results.items[0].sellerName;
            this.changeSearchResult(SearchType.Item, this.searchService.results.items[0].sellerID);
            this.searchService.addToLocalCaching();

            isNotCached = true;
            isMarket = false;
        } else if (this.searchResult.data
            && Object.keys(this.searchResult.data).length
            && (this.searchResult.data.items)
            && this.isExistMarketName(this.searchResult.data.items)) {

            this.changeSearchResult(SearchType.Market);

            isNotCached = true;
            isMarket = true;
        }

        this.mapSearchService.showMap(this.searchResult.keyWord, this.locationSearch, isNotCached, isMarket);
    }

    public changeSearchResult(type: SearchType, keyWord?: string): void {

        this.searchResult.type = type;
        this.searchService.type = type;

        if (keyWord) {
            this.searchService.keyWord = keyWord;
            this.searchResult.keyWord = keyWord;
        }
        this.mapSearchService.searchResult = this.searchResult;
    }

    private isSellerProductsList(): boolean {
        const data = this.searchResult.data;
        const availableData = data && Object.keys(data).length && (data.items || data.nearest_items);

        return availableData && (this.isExistSellerName(data.items) || this.isExistSellerName(data.nearest_items));
    }

    private isExistSellerName(items: SellingItemResponseBody[]): boolean {
        return items && items.length && items[0].sellerName + "'s items" === this.locationSearch;
    }
    private isExistMarketName(items: SellingItemResponseBody[]): boolean {
        return items && items.length && items[0]['market'] && items[0]['market'].market_title === this.locationSearch;
    }

    public onCancel(): void {
        let local = decodeURIComponent( this._w.location.pathname );

        if (local === '/users-list') {
            this.location.back();

        } else {
            this.location.replaceState('map-search');

            setTimeout(() => {
                this.searchResult.type === 'event'
                    ? this.productEditorService.cancelEventCreation()
                    : this.location.back();
            });
        }
    }

    public isNotAvailableSorting(): boolean {
        return [SearchType.Market, SearchType.Event].some((type: string) => this.searchResult.type === type)
            || decodeURIComponent( this._w.location.pathname ) === '/users-list' && this.isAdminMode;
    }

    public isTypeForSortByPrice(): boolean {
        return [SearchType.Item, SearchType.Active, SearchType.Sale].some((type: string) => this.searchResult.type === type);
    }

    public onFilterClick(): void {
        let modal_params: ModalArgumentsInterface = {
            idFilterButton: 'filterBtn',
            filter_data: this.search_filter,
            style: {borderRadius: '3px', width: '175px'}
        };

        this.modalService.filter_modal(modal_params).then((value: SearchType) => {
            this.setFocus();
            this.changeEvent$.emit({
               type: value,
               keyWord: this.searchResult.keyWord,
               sortBy: 'unsorted'
            });
        });
    }

    public onSearch(event: Event, value: string): void {
        const elem = (event.target as HTMLElement);
        elem.blur();
        event.stopPropagation();

        value = value.trim();

        if (this.searchResult.type === 'user' && !this.previousValue && !value) {
            return;
        }

        this.initSearch(value);
    }

    public onSortChange(value: string): void {
        this.changeEvent$.emit({ sortBy: value });
    }

    private initSearch(value: string, map?: boolean): void {
        this.previousValue = value;

        let obj = { keyWord: value };
        if (map)
            Object.assign(obj, {map});
        this.changeEvent$.emit(obj);
    }

    private setFocus(): void {
        const elem = this.elementRef.nativeElement.querySelector(
            'input.search-header__input'
        );
        elem && !this.searchResult.keyWord && elem.focus();
    }
}
