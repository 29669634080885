import {Injectable} from "@angular/core";
export enum AppRouteValues {
    routePayment = 'payment',
    routeCart = 'cart',
    routeSuccessOnboarding = 'success-onboarding',
    routeSettings = 'settings',
    routeCartReport = 'cart-report',
    routeProductDetails = 'product-details',
    routeSearch = 'search',
    inventoryList = 'inventory-list',
    shoppingList = 'shopping-list',
    usersList = 'users-list',
    shopperDetails = 'shopper-details'
}

export function createDirection(route: AppRouteValues): string {
    return '/' + route;
}
