var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalService } from '../../modal/modal.service';
import { ProductEditorParentClass } from './product-editor-abstract.class';
import { ProductEditorService } from '../product-editor.service';
import { TranslateService } from '@ngx-translate/core';
var ExistingEventEditorComponent = /** @class */ (function (_super) {
    __extends(ExistingEventEditorComponent, _super);
    function ExistingEventEditorComponent(modalService, productEditorService, translate) {
        var _this = _super.call(this, modalService, productEditorService, translate) || this;
        _this.isEventEditor = true;
        return _this;
    }
    ExistingEventEditorComponent.prototype.createTitleText = function () {
        this.title = this.translate.instant("editor.title.event");
    };
    /**
     * Calls the callback with `exitEventEditor` command object.
     */
    ExistingEventEditorComponent.prototype.onClose = function () {
        var _this = this;
        this.productEditorService.cancelEditing({ cansave: true, event: true }).subscribe(function (action) {
            if (action.type === 'save')
                _this.onSave();
            if (action.type === 'cancel')
                _this.context.callback({ exitEventEditor: null });
        });
    };
    /**
     * Checks the quantities correspondence.
     * @returns {boolean}
     * @private
     */
    ExistingEventEditorComponent.prototype._checkQuantities = function () {
        var doesSatisfy = this.item.qty >= this.item.current_quantity;
        if (!doesSatisfy)
            this._showWarning(this.translate.instant('event.editor.warning.qty'));
        return doesSatisfy;
    };
    /**
     * Checks the event data and calls the callback with `saveEvent` command object.
     */
    ExistingEventEditorComponent.prototype.onSave = function () {
        if (!this._isItemConsistent() || !this._checkQuantities())
            return;
        this.context.callback({ updateEvent: this.item });
    };
    return ExistingEventEditorComponent;
}(ProductEditorParentClass));
export { ExistingEventEditorComponent };
