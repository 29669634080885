import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';

import { ItemService } from '../../services/item.service';
import { SellingItemResponseBody } from '../../../../swagger-gen__output_dir/model/sellingItemResponseBody';

@Component({
  selector:         'market-table',
  templateUrl:      './market-table.html',
  styleUrls:        ['market-table.sass'],
})
export class MarketTableComponent implements OnInit {
    @Input() items: SellingItemResponseBody[] = [];
    @Input() indexOfItem: number;
    @Output() clickEvent$ = new EventEmitter();

    public prices: number[] = [];

    public constructor(public itemService: ItemService) {}

    public ngOnInit(): void {
        this.calculatePrices(this.items);
    }

    /**
     * @emits clickEvent$ on click.
     */
    onClick(item) {
        this.clickEvent$.emit(item);
    }

    /**
     * @desc Calculates price for each item in items list
     *       depending on if it's on sale
     * @param {SellingItemResponseBody[]} items
     */
    private calculatePrices(items: SellingItemResponseBody[]): void {
        items.forEach((item: SellingItemResponseBody) => {
            this.prices.push(this.itemService.itemPriceFn(item));
        });
    }
}
