import {
    OnInit,
    ViewChildren, QueryList
} from '@angular/core';

import AppValues                    from '../../common/app.values';
import { ModalService }             from '../../modal/modal.service';
import { ProductEditorService }     from '../product-editor.service';
import { TimePickerComponent }      from '../additional/time-picker.component';
import { UnitInterface }            from '../../interfaces/unit.interface';
import { SellingItemResponseBody }  from '../../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { DateRange, ProductEditorContext} from '../../interfaces/product.interface';
import { EditableInterface }        from '../../interfaces/media-editor.interface';
import { TranslateService }         from '@ngx-translate/core';

export abstract class ProductEditorParentClass implements OnInit {
    /**
     * A multi-field non-form data editor with presets for product creation and editing.
     */
    @ViewChildren(TimePickerComponent) timePickers: QueryList<TimePickerComponent>;

    public isEventEditor:   boolean = false;
    public isNewItem:       boolean = false;

    public context:        ProductEditorContext;
    public countries       = AppValues.countries;
    public title:          string;
    public item:           SellingItemResponseBody;
    public dateRange:      DateRange;
    public HOUR            = AppValues.ONE_HOUR;
    public HUNDRED         = AppValues.HUNDRED;
    public onlyNumberPattern = AppValues.onlyNumberPattern;

    public dateRangeFromLabel:     string;
    public dateRangeToLabel:       string;
    public validFromLabel:         string;
    public validToLabel:           string;

    // required fields for a new product being created
    public requiredFields: {[ key: string ]: string};
    public saleFields: {[ key: string ]: string};
    public priceFields: {[ key: string ]: string};
    public allNumberFields: {[ key: string ]: string};

    public units:          UnitInterface[];

    public modalService:           ModalService;
    public productEditorService:   ProductEditorService;
    public translate:              TranslateService;
    public timeDatesInfo:          string;
    public productionDateState:    boolean;


    constructor(
        modalService:           ModalService,
        productEditorService:   ProductEditorService,
        translate:              TranslateService,
    ) {
        this.modalService           = modalService;
        this.productEditorService   = productEditorService;
        this.translate              = translate;
    }


    /**
     * Fetches units of measure object and the product item if specified.
     */
    ngOnInit(): void {
        this.item = Object.assign({}, this.context.item);
        this.units = this.context.units;
        this.dateRange = this.context.dateRange;
        this.timeDatesInfo = this.translate.instant("market.timeDates.info");

        this.createFieldsText();
        this.createLabelText();
        this.createTitleText();
        this.updateProductionDateState(this.item.productionDate > 0);
    }

    createFieldsText() {
        this.requiredFields = {
            title:            this.translate.instant('product.editor.error.empty.title'),
            priceUnitsName:   this.translate.instant('product.editor.error.empty.priceUnits'),
            qty:              this.translate.instant('product.editor.error.empty.qty'),
            originCountry:    this.translate.instant('product.editor.error.empty.country')
        };
    
        this.saleFields = {
            salePrice:  this.translate.instant('product.editor.error.empty.salePrice')
        };
        this.priceFields = {
            price:      this.translate.instant('product.editor.error.empty.productPrice')
        };
        this.allNumberFields = {
            current_quantity: this.isEventEditor ? this.translate.instant('product.editor.error.empty.eventQty') : this.translate.instant('product.editor.error.empty.currentQty'),
            qty: this.translate.instant('product.editor.error.empty.totalQty'),
        };
    }

    createTitleText() {}

    createLabelText() {
        this.dateRangeFromLabel = this.getDatePipeTransform(this.dateRange.from);
        this.dateRangeToLabel = this.getDatePipeTransform(this.dateRange.to);

        if (this.item) {
            this.validFromLabel = this.getDatePipeTransform(this.item.valitFrom);
            this.validToLabel = this.getDatePipeTransform(this.item.validTill);
        }
    }

    getDatePipeTransform(date: number): string {
        return AppValues.datePipeTransform(new Date(date * 1000), 'MMM-dd-yyyy HH:mm');
    }


    /**
     * @desc Is being called on input fields events. Determines the type of event
     * and delegates for image uploading or item data update.
     * @param obj
     * @param fieldName
     */
    onDataChange(obj: EditableInterface, fieldName?: string): void {
        if (obj.imageFile) {

            this.productEditorService.uploadImg(obj.imageFile)
                .subscribe((imageURL: string) =>
                    this.updateItem(fieldName, imageURL)
                );

            return;
        }


        if (obj.role) {
            // if (typeof obj.value !== 'string' && typeof obj.value !== 'number')
            //     window.console.error(`Unexpected type for "${obj.role}" field`);

            if (obj.role === 'price') {
                this.updateItem(obj.role, +obj.value * this.HUNDRED || '');
            }

            this.updateItem(obj.role, obj.value);
        }
    }


    /**
     * @desc Validates the input; if invalid, resets the input and calls to display warning.
     * @param {Event} event
     * @param {number} minimumFractionDigits
     * @param {number} maximumFractionDigits
     * @return {boolean}
     */
    public onPriceChange(event: Event, minimumFractionDigits: number = 0, maximumFractionDigits: number = 0): boolean {
        const target = event.target;

        let formatedNumber = AppValues.NumberUSFormat({
                value: target['value'],
                minimumFractionDigits,
                maximumFractionDigits
            });

        const isValid   = this.onlyNumberPattern.test(formatedNumber.formatedNumber.trim()) && formatedNumber.formatedNumber.trim();
        if (isValid) {
            let value: number;

            if (target['name'].toLowerCase().includes('price')) {
                value = parseInt((formatedNumber.num * this.HUNDRED).toFixed(0), 0);
            } else {
                value = parseInt(formatedNumber.num.toFixed(0), 0);
            }
            if (formatedNumber.num > 99999999) {
                formatedNumber = AppValues.NumberUSFormat({
                    value: "99999999",
                    minimumFractionDigits,
                    maximumFractionDigits
                });
                target['value'] = formatedNumber.formatedNumber;
                this.updateItem(target['name'], 99999999);
                return this.invalidFieldScenario(target);
            }

            target['value'] = formatedNumber.formatedNumber;
            this.updateItem(target['name'], value);
            return true;

        } else {
            target['value'] = '';

            this.updateItem(target['name'], 0);

            return this.invalidFieldScenario(target);
        }
    }

    invalidFieldScenario(target: EventTarget): boolean {
        if (this.allPriceFields[target['name']]) {
            this._showWarning(this.translate.instant('productEditor.error.invalidProductPriceFieldValues', {invalid_fields: this.allPriceFields[target['name']]}));
            return false;
        }
        if (this.allNumberFields[target['name']]) {
            this._showWarning(this.translate.instant('productEditor.error.invalidProductQTYFieldValues', {invalid_fields: this.allNumberFields[target['name']]}));
            return false;
        } else {
            this._showWarning(target['dataset'].warning);
            return false;
        }
    }

    get allPriceFields(): {} {
        let fields = {};
        Object.assign(fields, this.saleFields);
        Object.assign(fields, this.priceFields);

        return fields;
    }


    /**
     * @desc Checks for value type, displays warning on error, updates item object if ok.
     * @param key
     * @param value
     */
    updateItem(key: string, value: string | number | boolean | Blob): boolean {
        const type = typeof value;

        if (type !== 'string' && type !== 'number' && type !== 'boolean') {
            this._showWarning(`Incorrect value type for '${key}' field.`);
            return false;

        } else if (typeof key !== 'string' || !key) {
            this._showWarning(`Unexpected key:  ${key}.`);
            return false;
        }
        if (key === 'saleIsOn' && !value) this.saleIsOffScenario();

        this.item[key] = value;

        this.createLabelText();

        this.productEditorService.set_changed_item(this.item);
        return true;
    }

    updateProductionState(value: boolean): void {
        this.item.productionDate = value ? AppValues.getDateNow() : null;
        this.updateProductionDateState(value);
    }

    saleIsOffScenario() {
         this.item.salePrice = 0;
         this.item.saleDateFrom = this.item.valitFrom;
         this.item.saleDateTill = this.item.validTill;
    }



    /**
     * @desc Checks that date TILL is at least one hour ahead of FROM, then
     * updates the item. Otherwise makes undo.
     * @param key
     * @param value
     */
    checkFromDate(key: string, value: number): void {
        const pair      = {
            'saleDateFrom': 'saleDateTill',
            'valitFrom':    'validTill'
        };

        const pairValue     = this.item[pair[key]];

        this.updateItem(key, value);

        if (pairValue - value < this.HOUR) {
            this._setInvalid(key, pair[key], true);
            return;
        }

        this._setInvalid(key, pair[key], false);
    }



    /**
     * @desc Same for #checkFromDate, but also displays warnings.
     * @param key
     * @param value
     */
    checkTillDate(key: string, value: number): void {
        const pair      = {
            'saleDateTill': 'saleDateFrom',
            'validTill':    'valitFrom',
        };

        const msgPart   = {
            'saleDateTill': this.translate.instant('productEditor.error.tooShortSalePeriod'),
            'validTill':    this.translate.instant('productEditor.error.tooShortPriceValidPeriod')
        };

        const pairValue     = this.item[pair[key]];

        this.updateItem(key, value);

        if (value - pairValue < this.HOUR) {
            this._showWarning(msgPart[key]);
            this._setInvalid(key, pair[key], true);
            return;
        }

        this._setInvalid(key, pair[key], false);
    }



    /**
     * Sets @isInvalid attribute for the coupled timepickers elements.
     * @param key
     * @param pair
     * @param isInvalid
     * @private
     */
    _setInvalid(key: string, pair: string, isInvalid: boolean): void {
        this.timePickers.toArray().forEach((p: TimePickerComponent) => {
            if (p.role === key || p.role === pair) p.isInvalid = isInvalid;
        });
    }



    /**
     * Uploads the image and updates certificate status on certificate event.
     * @param obj
     * @param type
     */
    certificateUpdate(obj: {}, type: string): void {
        const link      = {
            'organic':  'organic_certificate_image',
            'nonGMO':   'nonGMO_certificate_image'
        };

        if (obj.hasOwnProperty('imageFile')) {
            this.onDataChange(obj, link[type]);
            this.updateItem(type, true);

        } else {
            this.updateItem(type, false);
            this.updateItem(link[type], '');
        }
    }



    /**
     * @desc On submit action calls to check item consistence, delegates saving to the service.
     */
    onSave(): boolean | void {
        return this._isItemConsistent();
    }



    /**
     * @desc Checks required fields, shows warnings if item data is inconsistent.
     * @returns {boolean}
     * @private
     */
    _isItemConsistent(): boolean {
        this.updateUnits();

        const missedField       = this._checkFields(this.requiredFields);
        const missedPriceFields = this._checkFields(this.priceFields) || this.item.saleIsOn && this._checkFields(this.saleFields);
        const missedDelivery    = this._forgotDelivery();

        // check mandatory fields
        // if sale is on, price and units must be assigned
        if (missedField) {
            this._showWarning(missedField);
            return false;

        // check price fields
        } if (missedPriceFields) {
            this._showWarning(this.translate.instant('productEditor.error.invalidProductPriceFieldValues', {invalid_fields: missedPriceFields}));
            return false;

        // one of delivery ways must be chosen
        } else if (missedDelivery) {
            this._showWarning(this.translate.instant('product.editor.error.empty.delivery'));
            return false;
        }

        return true;
    }



    /**
     * Returns verbose field title if its value is empty.
     * @param fieldsObj
     * @returns {any}
     * @private
     */
    _checkFields(fieldsObj: {}): string {
        const keys  = Object.keys(fieldsObj);

        for (let i = 0; i < keys.length; i++) if (!this.item[ keys[i] ]) {
            return fieldsObj[ keys[i] ];
        }
    }



    /**
     * Returns true if no delivery method has been chosen.
     * @returns {boolean}
     * @private
     */
    _forgotDelivery(): boolean {
        return (this.item.deliveryOffered || this.item.marketPickOffered) ? false : true;
    }



    /**
     * Shows modal error window.
     * @param msg
     * @private
     */
    _showWarning(msg: string): void {
        this.modalService.error({
            title:          'Alert:',
            message:        msg,
            yesButtonText:  'Close',
        });
    }

    /**
     * Cancel editing handler.
     */
    abstract onClose(): void;


    /**
     * Updates read-only unit fields that depend on priceUnit.
     * @private
     */
    private updateUnits(): void {
        this.item.qtyUnitID = this.item.priceUnitsID;
        this.item.saleUnitID = this.item.priceUnitsID;

        this.item.qtyUnitName = this.item.priceUnitsName;
        this.item.saleUnitName = this.item.priceUnitsName;
    }

    private updateProductionDateState(state: boolean): void {
        this.productionDateState = state;
    }
}
