import { NgModule, ModuleWithProviders }    from '@angular/core';
import { CommonModule }                     from '@angular/common';

import {CacheNotificationService} from './cache-notification.service';
import {CacheNotificationComponent} from './cache-notification.component';
import {SafeHtmlPipe} from '../services/safe-html-pipe';
import {PrivacyPolicyComponent} from "./privacy-policy.component";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpLoaderFactory} from "../app.module";
import {Http} from "@angular/http";
import {BlankComponent} from "./blank.component";



@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [Http]
            }
        })
    ],
    declarations: [
        CacheNotificationComponent,
        PrivacyPolicyComponent,
        BlankComponent
    ],
    entryComponents: [
        CacheNotificationComponent,
        PrivacyPolicyComponent,
        BlankComponent
    ],
    exports: [
        CacheNotificationComponent,
    ]
})
export class CacheNotificationModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CacheNotificationModule,
            providers: [CacheNotificationService]
        };
    }
}
