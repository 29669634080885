import { Component }                    from '@angular/core';

import { EventInterface }               from '../../interfaces/market.interface';
import { ModalService }                 from '../../modal/modal.service';
import { ProductEditorParentClass }     from './product-editor-abstract.class';
import { ProductEditorService }         from '../product-editor.service';
import {
   SaveProduct,
   SafeProductEvent
} from '../../interfaces/product.interface';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector:       'existing-item-editor',
    styleUrls:      ['../product-editor.sass', '../../../assets/styles/sprite.sass'],
    templateUrl:    './product-editor.component.html'
})
/**
 * Represents editor for an existing item.
 * @extends ProductEditorParentClass.
 */
export class ExistingItemEditorComponent extends ProductEditorParentClass {

    modalService:           ModalService;
    productEditorService:   ProductEditorService;
    translate:              TranslateService;

    private eventActions: {
       [key: string]: (event: SafeProductEvent, index?: number) => void
    } = {
       showEvent: (event: SafeProductEvent) => {
           return this.context.callback({ showEvent: event });
       },
       addEvent: () => {
           return this.context.callback({ addEvent: null });
       },
       removeEvent: (event: SafeProductEvent, index: number) => {
           return this.context.callback({ removeEvent: index });
       }
    };

    constructor(
        modalService:           ModalService,
        productEditorService:   ProductEditorService,
        translate:              TranslateService
    ) {
        super(modalService, productEditorService, translate);
    }

    createTitleText(): void {
        this.title = this.translate.instant("editor.title.product");
    }


    /**
     * Delegates to the service to finish editing.
     */
    onClose(): void {
        this.productEditorService.cancelEditing({cansave: true}).subscribe((action: {type: string}) => {
             if (action.type === 'save')
               this.onSave();
        });
    }


    /**
     * @desc Calls the parent method to ensure if it is possible to save (data correctness).
     * Calls the callback with `saveItem` command object.
     * @param {boolean} saveBeforeAddingEvent
     */
    onSave(event?: {event: EventInterface}): void | boolean {
        if (!super.onSave()) return;

        const obj: SaveProduct = { item: this.item, event };
        this.context.callback({ saveItem: obj });
    }


    // ---------------- Event Widget commands ----------
    /**
     * Dispatches the Event Widget commands.
     * @param commandObject
     */
    onWidgetEvent(commandObject: {}): void {
        this[Object.keys(commandObject)[0]](Object.values(commandObject)[0]);
    }


    /**
     * Executes `removeEvent` command by calling the callback.
     * @param index
     */
    removeEvent(index: number): void {
        this.context.callback({removeEvent: index});
    }


    /**
     * Executes `addEvent` command by calling the callback.
     */
    addEvent() {
        if (this.item.current_quantity < 1) {
            this._showWarning(this.translate.instant('event.editor.warning.create'));
            return;
        }

        this.productEditorService.change_item
            ? this.addShowEventActionPrecondition(null, 'addEvent')
            : this.eventActions['addEvent'](null);
    }


    /**
     * @desc Calls warning dialog if fields values were updated
     * but user didn't save changes.
     */
    addShowEventActionPrecondition(event: SafeProductEvent, type: string) {
       this.modalService
          .warning({
              title: this.translate.instant('event.editor.warning.modal.title'),
              message: this.translate.instant('event.editor.warning.modal.message'),
              yesButtonText: this.translate.instant('event.editor.warning.modal.confirm'),
              noButtonText: this.translate.instant('event.editor.warning.modal.reject'),
              reverseButtons: true,
          }).then((action: boolean) => {
              if (action) {
                  this.onSave(event);
                  return;
              }

              this.eventActions[type](event);
          });
    }


    /**
     * Executes `showEvent` command by calling the callback.
     * @param obj
     */
    showEvent(obj: SafeProductEvent) {
        this.productEditorService.change_item
            ? this.addShowEventActionPrecondition(obj, 'showEvent')
            : this.eventActions['showEvent'](obj);
    }

}
