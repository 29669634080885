import {
    Component, OnInit, EventEmitter,
    Input, Output, Injector
} from '@angular/core';
import { Location }                             from '@angular/common';

import { ModalService }                         from '../modal/modal.service';
import {
    ModalArgumentsInterface,
    ModalFilterArgumentInterface,
} from '../interfaces/modal.interface';
import {ItemReviewUrl} from './reviews.component';
import {AppFooterService} from '../app-footer/app-footer.service';
import {Router} from '@angular/router';
import {AppReviewsService} from './reviews.service';
import {Subject} from "rxjs";
import {ModalFilterArguments, ReviewOptionsInterface} from "./review.interface";

@Component({
    selector: 'reviews-header',
    styleUrls:  ['../../assets/styles/app-header.sass', './filter.sass'],
    template: `
        <!--- Header for view Review page -->
        <header *ngIf="locationSearch !== '/create-review'" class="app-header reviews__app-header">
            <nav class="app-header__container">
                <button class="back-icon__container" (click)="backToHome()" data-test-id="backLink" type="button">
                    <i class="icon-custom_back_icon"></i>
                </button>

                <div class="navbar__center">
                    <h4 *ngIf="locationSearch === '/reviews'" data-test-id="headerTitle">{{ title }}</h4>
                    <h4 *ngIf="locationSearch === '/reviews-item'" data-test-id="headerTitle">{{ itemTitle }}</h4>
                </div>

                <button [ngClass]="'filter-icon__container'" (click)="showFilter()" data-test-id="showFilter" type="button">
                     <img [src]="'../../assets/images/svg_icons/filter.svg'" alt="show filter"/>
                </button>
            </nav>

            <div *ngIf="reviewsFilterList && reviewsFilterList.length && isAvailableTabs" class="review-header__list-switch-block text-center" data-test-id="reviewHeaderSwitchBlock">
                <button *ngFor="let type of reviewsFilterList; let i =  index;"
                        [attr.data-test-id]="'TypeBtn_' + type.title"
                        [ngClass]="type.active && 'review-list-active-btn'"
                        type="button"
                        (click)="toggleListState(type.value)"><span>{{type.title}}</span><span class="review-underline"></span></button>
            </div>

        </header>
        <!--- End header for view Review page -->

        <!--- Header for Create Review page -->
        <header *ngIf="locationSearch === '/create-review'" class="app-header create-reviews__app-header">
            <nav class="app-header__container">
                <div class="navbar__left">
                   <button (click)="location.back()" data-test-id="backLink" type="button">
                        <i class="icon-custom_back_icon"></i>
                   </button>
                </div>

                <h5 class="navbar__center" data-test-id="createReviewHeaderTitle">
                    {{ "review.add.review" | translate }} {{ createReviewHeaderTitle }}
                </h5>

                <div class="navbar__right">
                    <h4 class="nav__link" data-test-id="saveReview" (click)="onDone()" [ngClass]="{'disabled': disabled}">
                        {{ "review.text.save" | translate }}
                    </h4>
                </div>
            </nav>
        </header>
        <!--- End header for Create Review page -->
    `
})

export class ReviewsHeaderComponent implements OnInit {

    @Input()    disabled:                   boolean;
    @Output()   changeEvent$                = new EventEmitter<ReviewOptionsInterface>();
    @Output()   backToHomeEvent$            = new EventEmitter<null>();
    @Output()   submit                      = new EventEmitter<null>();
    @Output()   goToCreateReview$           = new EventEmitter<null>();
    @Input()    reviewsAllFilters:          Array<Array<ModalFilterArgumentInterface>>;
    @Input()    reviewsAllFiltersSubject: Subject<Array<Array<ModalFilterArgumentInterface>>> = new Subject<Array<Array<ModalFilterArgumentInterface>>>();
    @Input()    isAvailableFilter:          boolean;
    @Input()    isAvailableTabs:            boolean;
    @Input()    itemTitle:                  string;
    @Input()    userTitle:                  string;
    @Input()    createReviewHeaderTitle:    string;

    title:              string;
    locationSearch:     string;
    _w:                 Window;
    location:           Location;
    reviewsFilterList:          Array<ModalFilterArgumentInterface>;
    reviewsRaitingFilter:       Array<ModalFilterArgumentInterface>;

    constructor(
        private modalService:       ModalService,
        private injector:           Injector,
        private router:             Router,
        location:                   Location,
    ) {
        this._w = window;
        this.location = location;
    }


    ngOnInit() {
        this.locationSearch = decodeURIComponent(this._w.location.pathname);
        this.itemTitle = new ItemReviewUrl().getItemTitle();
        this.title = new ItemReviewUrl().getTitle(this.userTitle);

        this.reviewsAllFiltersSubject.subscribe((reviewsAllFilters: ModalFilterArgumentInterface[][]) => {
            if (reviewsAllFilters && reviewsAllFilters.length) {
                this.reviewsFilterList = reviewsAllFilters[0];
                this.reviewsRaitingFilter = reviewsAllFilters[1];
            }
        });

        if (this.reviewsAllFilters && this.reviewsAllFilters.length) {
            const isPurchaseActive = this.reviewsAllFilters[0].findIndex((f) => f.value === 'purchases' && f.active) > -1;

            this.reviewsFilterList = this.reviewsAllFilters[0];
            this.reviewsRaitingFilter = this.reviewsAllFilters[1];

            isPurchaseActive && this.toggleListState(ModalFilterArguments.purchases);
        }
    }

    public get app_footer_service(): AppFooterService {
        return this.injector.get(AppFooterService);
    }
    public get reviews_service(): AppReviewsService {
        return this.injector.get(AppReviewsService);
    }

    backToHome() {
        if (this.location.path() === '/reviews' && this.reviews_service.isFromSettings) {
            this.reviews_service.isOpenedReviewFromSettings = false;
            this.backToHomeEvent$.emit(null);
            this.location.back();
            return;
        }
        if (this.location.path().includes('reviews-item') || this.location.path().includes('create-review')) {
            this.location.back();
            return;
        }

        if (!this.app_footer_service.isSellerMode()) {
            this.navigateTo('/shopping-list');
        } else {
            this.navigateTo('/inventory-list');
        }
    }

    navigateTo(path: string) {
        this.backToHomeEvent$.emit(null);
        this.router.navigate([path]);
    }


    showFilter() {
        let modal_params: ModalArgumentsInterface = {
            idFilterButton: 'showFilter',
            filter_data: [this.reviewsRaitingFilter],
            style: {borderRadius: '3px', width: '175px'}
        };

        this.modalService.filter_modal(modal_params).then((value: ModalFilterArguments) => {
            this.changeEvent$.emit({sortBy: value});
        });
    }

    toggleListState(type: ModalFilterArguments) {
        this.changeEvent$.emit({sortBy: type});
    }

    /**
     * passage to the Request Creation page, a query that checks the ability to leave a comment (this.access.flag),
     * if product was not purchased then show warning
     */
    goToCreateReview(): void {
       this.goToCreateReview$.emit(null);
    }


    onDone() {
        if (!this.disabled) {
            this.submit.emit(null);
        }
    }
}
