<div class="abstract-list__container">

    <ul class="abstract-list__ul">

        <li class="abstract-list__li market-list__li" (click)="onClick(item)"
            [attr.data-id]="item['ID']"
            [attr.data-test-id]="'abstractListProduct_'+i"
            *ngFor="let item of items; trackBy: trackByID; let i=index;">

            <div class="line"><p>{{item.market_title}}</p></div>
            <div class="line"><p>{{item.address.address1}}</p></div>
            <div class="line"><p>{{item.address.city}}</p></div>
            <div class="line">
                <div>{{item.address.state}}</div>
                <div class="line__address">{{item.address.postCode}}</div>
            </div>

            <div class="go-to"><i class="icon-right-open"></i></div>
        </li>
    </ul>

</div>

