import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/switchMap';

import {CartService} from '../cart/cart.service';
import {GoogleAnalyticsService} from '../services/google-analytics.service';
import {PriceAlertInterface} from '../interfaces/alert.interface';
import {ProductDetailsService} from './product-details.service';
import {ProductDetailsViewInterface, ProductView} from '../interfaces/product.interface';
import {ShopperDetailsService} from '../shopper-details/shopper-details.service';
import {AppReviewsService} from '../reviews/reviews.service';
import {UserService} from '../user/user.service';
import {ModalService} from '../modal/modal.service';
import {Observable, Subject} from 'rxjs/Rx';
import {ErrorInterface, ErrorService} from '../services/error.service';
import AppValues from '../common/app.values';
import {Event as EventModel} from '../../../swagger-gen__output_dir/model/event';
import {ModalArgumentsInterface} from '../interfaces/modal.interface';
import {TranslateService} from '@ngx-translate/core';
import {Item, SellingItemResponseBody, ShoppingCartResponseBody} from '../../../swagger-gen__output_dir';
import {AppRouteValues, createDirection} from "../common/app.route.values";
import { CartAbstractClass } from '../cart/cart-abstract-class';


@Component({
    selector:   'product-details',
    styleUrls:  ['./product-details.sass'],
    template:   `
        <div class="component">
            <div *ngIf="item" class="details__container component__container">
                <product-header
                    [item]="item"
                    [options]="options"
                    [isInReportList]="isInReportList"
                    (toggleWatchList)="toggleWatchListStatus(item)"
                    (toggleFavList)="toggleFavListStatus()"
                    (quantityChange)="handleQuantityChange($event)"></product-header>

                    <ul class="details__info-container">
                    <li class="details__analytics__data" *ngIf="options?.readOnly">
                        <section>
                            <h5>{{ "product.details.order.code" | translate }} </h5>
                            <p>{{options.idOrder}}</p>
                        </section>
                        <section>
                            <h5>{{ "product.details.date" | translate }} </h5>
                            <h5>{{options.date * 1000 | date: 'MMM dd/yyyy h:mm a' }}</h5>
                        </section>
                    </li>
                    <button *ngIf="!options?.readOnly" class="details__reviews" data-test-id="showReviews" type="button"
                        (click)="showReviews(item.ID, item.title, ( item.photoURL || '../assets/images/cart/no_image_icon.png') )">
                        <h4>{{ "product.details.product.reviews" | translate }}</h4>

                        <div class="details__reviews__stars-container">
                            <rating ngDefaultControl data-test-id="detailRateStars" [rating]="item.rate"
                                    [float]="true"
                                    [readonly]="true"></rating>
                            <p data-test-id="detailRate">( {{reviewsTotal}} )</p>
                            <i class="icon-disclosure_indicator_icon" role="nav"></i>
                        </div>
                    </button>
                    <li class="details__sellername" (click)="showShopper(item.sellerID)" data-test-id="showSeller">
                        <div class="seller-img">
                            <span *ngIf="!item.sellerPictureURL" class="sprite sprite-no_image_icon"></span>
                            <img *ngIf="item.sellerPictureURL" class="details__img" [src]="item.sellerPictureURL" alt="seller picture">
                        </div>
                        <div class="seller-holder">
                            <h4><b>{{item.sellerName}}</b></h4>
                            <span class="details__productNo" *ngIf="item?.productNo">{{ "product.details.code" | translate }} {{item.productNo}}</span>
                            <button data-test-id="searchForShopper" (click)="searchFor($event, item.sellerID, item.sellerName)" class="link-green link-green--inline" type="button">
                                {{ "product.details.shopper.products" | translate }}
                            </button>
                        </div>
                        <i class="icon-disclosure_indicator_icon" role="nav"></i>
                    </li>
                    <li class="details__alert" *ngIf="item && !options?.readOnly">
                        <h5>{{ "product.details.price.alert" | translate }}</h5>
                        <div class="details__alert__switch">
                            <p>{{ "product.details.alert" | translate }} </p>
                            <input class="details__alert__qty" data-test-id="detailAlertQty"
                                   [value]="priceAlertValue" type="number" name="detailAlertQty"
                                   maxlength="5" min="0" step="0.01" placeholder="0"
                                   (blur)="setPriceAlert($event.target.value, $event.type)"
                                   (keyup.enter)="setPriceAlert($event.target.value, $event.type)"
                                   [disabled]="!isPriceAlertOn"
                                   (paste)="false">

                            <rect-switch data-test-id="priceAlertSwitch"
                                         [state]="isPriceAlertOn"
                                         (stateEvent$)="togglePriceAlert($event)"></rect-switch>
                        </div>
                    </li>
                    <li *ngIf="item && !options?.readOnly">
                        <h5>{{ "product.details.quantity" | translate }}</h5>
                        <p data-test-id="detailCurrentQty">{{ "product.details.available" | translate }} {{item.current_quantity | number}} {{item.qtyUnitName}}</p>
                    </li>
                    <li class="details__organic" [ngClass]="(item.organic && item.nonGMO) ? 'yes' : 'no'">
                        <div class="details__gmo">
                            <h5>{{ "product.details.non.gmo" | translate }}</h5>
                            <button data-test-id="showGMOCertificate" type="button"
                                 (click)="item.nonGMO && showCertificate(item.nonGMO_certificate_image)">
                                <i *ngIf="item.nonGMO" class="icon-certificate_icon"></i>
                                <p *ngIf="!item.nonGMO">{{ "product.details.no" | translate }}</p>
                            </button>
                        </div>
                        <div class="details__org">
                            <h5>{{ "product.details.organic" | translate }}</h5>
                            <button data-test-id="showOrganicCertificate" type="button"
                                 (click)="item.organic && showCertificate(item.organic_certificate_image)">
                                <i *ngIf="item.organic" class="icon-certificate_icon"></i>
                                <p *ngIf="!item.organic">{{ "product.details.no" | translate }}</p>
                            </button>
                        </div>
                    </li>
                    <li class="details__market-pick">
                        <h5>{{ "product.details.delivery.offered" | translate }}</h5>
                        <p>{{item.deliveryOffered ? "YES" : "NO" }}</p>
                    </li>
                    <li class="details__market-pick details__market-pick--with-content">
                        <div class="details__market-pick details__market-pick--nested">
                            <h5>{{ "product.details.pickup.offered" | translate }}</h5>
                            <p>{{item.marketPickOffered ? "YES" : "NO"}}</p>
                        </div>
                        <div>
                            <div>
                                <h5 class="details__pickup-title">{{ "product.details.market" | translate }} {{ (market === true) ? '' : 'N/A' }}</h5>
                                <div>
                                    <h5 *ngIf="item['market']" class="clip"> {{item['market'].market_title}}</h5>
                                    <h5 *ngFor="let event of item.events; trackBy: trackByID;" class="clip">{{event.title}}</h5>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h5>{{ "product.details.country.origin" | translate }}</h5>
                        <p>{{item.originCountry}}</p>
                    </li>
                    <li *ngIf="item.productionDate">
                        <h5>{{ "product.details.production.date" | translate }}</h5>
                        <p data-test-id="productionDate">{{item.productionDate * 1000 | date: formatNumericDate}}</p>
                    </li>
                    <li class="details__description">
                        <h5>{{ "product.details.description" | translate }}</h5>
                        <p>{{item.description}}</p>
                    </li>
                    <li class="details__description">
                        <h5>{{ "product.details.shipping.terms" | translate }}</h5>
                        <p>{{item.shipping_terms}}</p>
                    </li>
                </ul>
            </div>

            <div class="product-actions app__footer" *ngIf="item && !options?.readOnly">
                <footer *ngIf="isMarketAllowed" class="details__container__btn-group">
                    <button (click)="cartItemID ? removeFromCart($event, item) : addToCart($event, item)" class="cart" type="button"
                            [disabled]="busyCart" data-test-id="detailAddToCart">
                        <span *ngIf="!cartItemID && !busyCart">{{ "product.details.add.cart" | translate }}</span>
                        <span *ngIf="cartItemID && !busyCart">{{ "product.details.remove.cart" | translate }}</span>
                        <span *ngIf="busyCart"><i class="icon-spinner2"></i></span>
                    </button>
                    <button class="buy" data-test-id="detailBuyNow" type="button"
                            (click)="addToCart($event, item, true)">
                        {{ "product.details.buy.now" | translate }}
                    </button>
                </footer>
            </div>
        </div>
    `,
})

export class ProductDetailsComponent extends CartAbstractClass implements OnInit {

    public cartItemID          = '';
    public HUNDRED             = AppValues.HUNDRED;
    public isMarketAllowed     = false;
    public options:            ProductView;
    public priceAlert:         PriceAlertInterface;
    public priceAlertValue:    string|number;
    public market:             any;
    public userID:             string;
    public quantity:           number;
    public isInReportList     = false;
    public reviewsTotal:       number = 0;
    public isDialogueActive:   boolean;
    public readonly formatNumericDate: string = AppValues.allNumericDatePattern;

    protected isLocationBack: boolean = false;
    private routePay:               AppRouteValues;

    constructor(
        private router:         Router,
        private reviewsService: AppReviewsService,
        private shopperDetailsService:  ShopperDetailsService,
        elementRef:             ElementRef,
        cartService:            CartService,
        googleAnalyticsService: GoogleAnalyticsService,
        productDetailsService:  ProductDetailsService,
        userService:            UserService,
        modalService:           ModalService,
        errorService:           ErrorService,
        translate:              TranslateService,
    ) {
        super(elementRef, cartService, googleAnalyticsService, productDetailsService, userService, modalService, errorService, translate);
    }

    /**
     * @desc Fetches item data and calls further methods.
     */
    public ngOnInit(): void {
        this.item = this.productDetailsService.getItem();
        if (this.item) {
            this._preparingToViewComponent();
        } else this.productDetailsService.getProductEvent$.subscribe((product: ProductDetailsViewInterface) => {
            if (product) {
                this.item = product;
                this._preparingToViewComponent();
            }
        });

        this.buyNowHandler();

        this.trackSubscription(
            this.cartService.onSelectNextView.subscribe((route: AppRouteValues) =>
                setTimeout(() => {
                    if (this.userService.isImpersonation) {
                        this.modalService.warning({
                            title: this.translate.instant("modal.warning.title"),
                            message: this.translate.instant("common.impersonated.pay"),
                            yesButtonText: this.translate.instant("common.cancel"),
                        });
                    } else {
                        this.router.navigate([createDirection(this.routePay ? this.routePay : route)]);
                    }
                }),
            ),
        );
        this.subscribeOnChangeCartConfirmationResponse();
    }

    public handleQuantityChange(value: number): void {
        this.desiredQuantity[this.item.ID] = value;
    }

    private subscribeOnChangeCartConfirmationResponse(): void {
        this.trackSubscription(
            this.cartService.onChangeCartConfirmationResponse.subscribe(() => {
                if (this.isBuyNow) {
                    this.switchPayBlock();
                }
            })
        );
    }

    private buyNowHandler(): void {
        this.trackSubscription(
            this.onSelectBuyNow.subscribe((isBuyNow: boolean) => {
                this.isBuyNow = isBuyNow;

                if (!isBuyNow || (isBuyNow && !this.cartService.isEmptyCart)) {
                    this.routePay = AppRouteValues.routeCart;
                    return;
                }
                this.routePay = AppRouteValues.routePayment;
            }),
        );
    }

    public searchFor(event: Event, sellerId: string, sellerName?: string): void {
        event.stopPropagation();
        this.shopperDetailsService.searchFor(sellerId, sellerName);
    }

    private _instantBuyHandler(): void {
        if (this.options && this.options.isInstantBuy) {
            this.addToCartHandler(this.item, true);
            this.productDetailsService.setProductOptions({isInstantBuy: false});
        }
    }

    private _preparingToViewComponent(): void {
        this.market = this.isMarketList();
        this.options = this.productDetailsService.productOptions;

        const user = this.userService.getUser();
        this.userID = user.ID;

        this._setPriceAlertValue();
        this._getCart().subscribe(() => {
            if (!this.userService.isGuestUser) {
                this._instantBuyHandler();
                this._setListStatus();
            };
        });


        this.googleAnalyticsService.handleVirtualPage('product-details');
        this.googleAnalyticsService.handleVirtualPage('product-details_from_' + this.productDetailsService.getProductDetailsFrom());
        this.reviewsService.getReviewsTotal(this.item.ID).subscribe((reviews: number) => this.reviewsTotal = reviews);
    }


    public isMarketList(): boolean {
        return Boolean((this.item.events && this.item.events.length) || this.item['market']);
    }


    /**
     * @desc Injects additional keys to Item object (locally) to mark their status
     * towards Favorite and Watch lists.
     * @private
     */
    private _setListStatus(): void {
        const user = this.userService.getUser();

        this.trackSubscription(
            this.productDetailsService.isInReportList(this.item.ID)
                .subscribe((isInList: boolean) => this.isInReportList = isInList),
        );

        this.item.inFavList = user.favList.items.indexOf(this.item.ID) >= 0;

        this.checkItemInWatchList();
    }

    private checkItemInWatchList(isVisibleSpinner: boolean = false): void {
        this.trackSubscription(
            this.productDetailsService.isInWatchList(this.item.ID, isVisibleSpinner)
                .subscribe((isInList: boolean) => this.item.inWatchList = isInList),
        );
    }

    public switchPayBlock(): void {
        this.modalService.close();

        this.setRouteAfterAddingProduct();
    }


    /**
     * @desc Fetches priceAlert object. If it is consistent, sets priceAlertValue and
     * isPriceAlertOn; if not - makes them clear and false accordingly.
     * @private
     */
    private _setPriceAlertValue(): void {
        this.priceAlert = this.productDetailsService.getPriceAlert();
        const pa        = this.priceAlert;

        this.priceAlertValue    = (pa && pa.price && pa.price > 0) ? (pa.price / this.HUNDRED).toFixed(2) : '';
        this.isPriceAlertOn     = this.productDetailsService.isPriceAlertOn(this.priceAlert);
    }


    /**
     * @desc Fetches shopping cart data and sets class fields on response.
     * @private
     */
    private _getCart(): Observable<void> {
        const response$: Subject<void> = new Subject();
        this.trackSubscription(
            this.cartService.getCart()
                .subscribe((cart: ShoppingCartResponseBody) => {
                    this.cartItemID         = this.cartService.getItemID(this.item.ID);
                    this.isMarketAllowed    = true;
                    response$.next();
                }, (err: ErrorInterface) => {
                    response$.next();
                    this.errorService.handleError(err);
                }),
        );
        return response$.asObservable();
    }

    /**
     * @desc Sets isPriceAlertOn flag.
     * @param state
     * @private
     */
    public togglePriceAlert(state: boolean): void {
        this.isPriceAlertOn = state;

        if (state && this.userService.isGuestMode) {
            this.isPriceAlertOn = false;
            this._resetAlertValue();
            this.changeSwitchValue(false);
        }

        if (!state) {
            this.trackSubscription(
                // @ts-ignore
                this.productDetailsService.setPriceAlert(null)
                    .subscribe(() => this._resetAlertValue(), (err) => null)
            );
        }
    }

    private changeSwitchValue(checked: boolean): void {
        let element: HTMLElement = document.querySelectorAll('[data-test-id="priceAlertSwitch"] [data-test-id="detailSwitchAlert"]')[0] as HTMLElement;
        element['checked'] = checked;
    }


    /**
     * @desc If the value equals current priceAlert value, returns.
     * If not - calls #setPriceAlert of the Service.
     * @param {number} value
     * @param {string} eventType
     */
    public setPriceAlert(value: number, eventType: string): void {
        if (!this.canShowAlert(eventType)) {
            return;
        }

        const price: number = parseInt((value * this.HUNDRED).toFixed(0), 0);

        this.modalService.showSpinner();

        this.trackSubscription(
            // @ts-ignore
            this.productDetailsService.setPriceAlert(price)
                .subscribe((res: any) => {
                    !this.userService.isGuestUser && this.checkItemInWatchList(false);
                    this.priceAlert.price = value * this.HUNDRED;
                    this.priceAlertValue = (this.priceAlert.price / this.HUNDRED).toFixed(2);
                    this.modalService.close();
                },
                (err: ErrorInterface) => {
                    // The error has already been processed in WatchListService
                    // and errorService.handleError(err) and the modal window was shown
                    // So modalService.close() method not needed here.

                    if (err.status === 401) {
                        this.isPriceAlertOn = false;
                    }
                    this._resetAlertValue();
                }
            )
        );

    }

    /**
     * @desc Depending on user action determines whether the function is invoked before
     * an active dialogue has been closed and sets isDialogueActive flag accordingly.
     * @param {string} eventType
     * @return {boolean}
     */
    private canShowAlert(eventType: string): boolean {
        if (eventType === 'keyup' && !this.isDialogueActive) {
            this.isDialogueActive = true;
            return true;
        } else if (eventType === 'keyup' && this.isDialogueActive) {
            return false;
        } else if (eventType === 'blur' && this.isDialogueActive) {
            this.isDialogueActive = false;
            return false;
        } else {
            return true;
        }
    }

    /**
     * @desc reset alert qty
     * @private
     */
    private _resetAlertValue(): void {
        !this.userService.isGuestUser && this.checkItemInWatchList();
        this.priceAlertValue = '';
        this.elementRef.nativeElement.querySelector('.details__alert__qty').value = '';
    }


    /**
     * @desc If url param is non-empty string, calls self-titled service method.
     * @param url
     */
    public showCertificate(url: string): void {
        if (url && (typeof url === 'string'))
            this.productDetailsService.showCertificate(url);
    }

    protected getObjModalDeliveryMethod(item: SellingItemResponseBody): ModalArgumentsInterface {
        if (item.deliveryOffered && item.marketPickOffered) {
            return {
                title: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.title'),
                message: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.message'),
                yesButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.delivery'),
                noButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.pickUp'),
                lastButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.cancel'),
            };
        } else {
            const objModal: ModalArgumentsInterface = {
                title: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.title'),
                yesButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.pay'),
                noButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.cancel'),
            };

            if (item.deliveryOffered) {
                objModal.message = this.translate.instant('product.buyNow.modalSelectDeliveryMethod.delivery.message');
            }
            if (item.marketPickOffered) {
                objModal.message = this.translate.instant('product.buyNow.modalSelectDeliveryMethod.pickUp.message');
            }
            return objModal;
        }
    }

    /**
     * @desc go to Review view for one product
     * @param {string} ID
     * @param {string} title
     * @param {string} url
     */
    public showReviews(ID: string, title: string, url: string): void {
        if (!ID) return;

        this.trackSubscription(
            this.reviewsService.showReviewList({ID, title, url}, false)
                .subscribe( () => null, (err) => Observable.throw(err)),
        );
    }


    /**
     * @desc go to Seller Details view
     * @param {string} id
     */
    public showShopper(id: string): void {
        if (!id) return;

        this.shopperDetailsService.showDetails(id);
    }

    /**
     * @desc Calls CartService and not redirects back on response.
     */
    public removeFromCart(event: Event, item: Item): void {
        this.trackSubscription(
            this.cartService.removeItem(this.cartService.getItemID(item.ID), false)
            .subscribe((result: {}) => {
                this.googleAnalyticsService.handleRemoveFromCart(item.ID);
                this.resetBusyFlag(true);
                this.changeCounts();
            }, (err: ErrorInterface) => this.resetBusyFlag(true))
        );

        this.resetBusyFlag(true);
    }

    /**
     * @desc Resets busy flag and throws to the console.
     * @param {boolean} isNotInCart
     */
    protected resetBusyFlag(isNotInCart: boolean = false) {
        this.busyCart = false;
        this.cartItemID = isNotInCart ? '' : this.item.ID;
    }

    /**
     * @desc Toggles inWatchList status.
     */
    public toggleWatchListStatus(item: SellingItemResponseBody): void {
        if (item.inWatchList && this.isPriceAlertOn) {
            this.removingItemFromWatchList(item);
        } else {
            this.toggleWatchList(item);
        }
    }

    /**
     * @desc Toggles inFavList status.
     */
    public toggleFavListStatus(): void {
        this.trackSubscription(
            this.productDetailsService.toggleFavListStatus()
            .subscribe(() => {
                this.item.inFavList = !this.item.inFavList;

                this.item.inFavList && this.googleAnalyticsService.handleAddToWishlist(this.item);
            }, (err: ErrorInterface) => {
                // The error has already been processed in FavoriteListService
                // and errorService.handleError(err) and the modal window was shown.
                return err;
            }),
        );
    }

    private setRouteAfterAddingProduct(): void {
        this.cartService.selectNextViewSubject.next(this.routePay);
    }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {Event} item
     * @return {string}
     */
    public trackByID(index: number, item: EventModel): string {
        return item.market_id;
    }
}
