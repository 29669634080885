
        <div class="component">
            <user-header (submit)="changePassword()" [disabled]="busyChange"></user-header>
            <div class="password__container">
                <form novalidate [formGroup]="passwordForm" class="text-center">

                    <password-field [nameInput]="'currentPass'"
                                    [dataTestIdInput]="'oldPassword'"
                                    [classLabel]="'password__label'" [classInput]="'password__input'"
                                    [autofocusField]="true"
                                    [placeholderInput]=" 'common.currentPassword.placeholderInput' | translate"
                                    [isPassErr]="formErrors['currentPass']"
                                    (bluredField)="updatePasswordField($event, 'currentPass')"></password-field>

                    <password-field [nameInput]="'newPass'"
                                    [dataTestIdInput]="'newPassword'" [placeholderInput]=" 'common.newPassword.placeholderInput' | translate"
                                    [classLabel]="'password__label'" [classInput]="'password__input'"
                                    [isPassErr]="formErrors['newPass']"
                                    (bluredField)="updatePasswordField($event, 'newPass')"></password-field>

                    <password-field [nameInput]="'repeatPass'"
                                    [dataTestIdInput]="'repeatPassword'" [placeholderInput]=" 'common.repeatPassword.placeholderInput' | translate"
                                    [classLabel]="'password__label'" [classInput]="'password__input'"
                                    [isPassErr]="formErrors['repeatPass']"
                                    (bluredField)="updatePasswordField($event, 'repeatPass')"></password-field>

                    <div class="password__warning error"
                         *ngIf="(passwordForm.controls['repeatPass'].invalid
                                    && (passwordForm.controls['repeatPass'].dirty
                                    || passwordForm.controls['repeatPass'].touched)) || (passwordForm.controls['newPass'].invalid
                                    && (passwordForm.controls['newPass'].dirty
                                    || passwordForm.controls['newPass'].touched))">

                        <small *ngIf="(passwordForm.controls['newPass'].value && passwordForm.controls['repeatPass'].value) && passwordForm.controls['newPass'].hasError('mismatched') || passwordForm.controls['repeatPass'].hasError('mismatched')"
                               data-test-id="errorNewPassword">
                            {{ 'password.error.notMatch' | translate }}
                        </small>
                    </div>

                    <p class="input__info">
                        {{ 'signup.hint.password' | translate }}
                    </p>

                    <button class="button__bizibazagreen password__container__change-password-button"
                            data-test-id="changePassword" (click)="changePassword()"
                            [disabled]="busyChange"
                            [ngClass]="{'disabled': busyChange}">
                        {{ 'changePassword.button.text' | translate }}
                    </button>
                </form>
            </div>
        </div>
    