
        <header class="app-header">

            <nav class="app-header__container" role="goods-nav">

                <div class="navbar__left">
                    <a (click)="location.back()" data-test-id="inventoryLink"
                       class="nav__link"><i class="icon-custom_back_icon"></i></a>
                </div>

                <div class="navbar__center" role="inventory-nav">
                    <h4>{{categoryName}}</h4>
                </div>

                <div class="navbar__right" data-test-id="nextLink" 
                     [ngClass]="{'active': isActive}" (click)="onNext()">
                    <span class="text-link">
                        {{ "inventory.nav.next" | translate }}
                    </span>
                    <span class="nav__link sprite sprite-map_callout_arrow_2x"></span>
                </div>
            </nav>

        </header>
    