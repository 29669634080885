import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppFooterService } from '../app-footer/app-footer.service';
import { UserService } from '../user/user.service';
import { ProductDetailsService } from '../product-details/product-details.service';
import { AppReviewsService } from '../reviews/reviews.service';
import { ModalService } from '../modal/modal.service';
import { AppRouteValues, createDirection } from "../common/app.route.values";
var noRedirectPaths = [
    createDirection(AppRouteValues.routeCartReport),
    '/product-details/',
    '/login',
    '/signup',
    createDirection(AppRouteValues.routeCart),
    createDirection(AppRouteValues.routeSuccessOnboarding)
];
var RedirectService = /** @class */ (function () {
    function RedirectService(appFooterService, modalService, router, location, productDetailsService, userService, appReviewsService) {
        var _this = this;
        this.appFooterService = appFooterService;
        this.modalService = modalService;
        this.router = router;
        this.location = location;
        this.productDetailsService = productDetailsService;
        this.userService = userService;
        this.appReviewsService = appReviewsService;
        this.defaultRedirectUrl = '/';
        this.path = '';
        this.create_review_link = '';
        this.w = window;
        this.userService.userAuthEvent$
            .subscribe(function (user) {
            _this.checkRedirectPath();
            _this.path = _this.w.location.pathname;
            _this.check(user);
            if (Object.keys(user).length !== 0 && _this.link && _this.link !== '') {
                _this.dynamicLinktoProduct();
                _this.link = '';
            }
        });
    }
    RedirectService.prototype.createReviewLink = function () {
        var user = this.userService.userSession;
        this.path = this.w.location.pathname;
        this.check(user);
        if (Object.keys(user).length !== 0 && user.lastName === null) {
            this.redirectToLogin();
            return;
        }
        if (Object.keys(user).length !== 0 && user.lastName !== null) {
            this.preparingToCreateReview();
        }
        else
            this.checkRedirectPath();
    };
    RedirectService.prototype.preparingToCreateReview = function () {
        if (this.create_review_link.includes('create-review')) {
            var create_review = this.create_review_link.split('create-review?')[1];
            var order_id = create_review.split('=')[0];
            if (order_id === 'order_element_id') {
                this.router.navigate(['/shopping-list']);
                if (this.w.location.pathname === '/shopping-list') {
                    var create_review_id = create_review.split('=')[1];
                    this.dynamicLinktoCreateReview(create_review_id);
                }
            }
        }
    };
    RedirectService.prototype.redirectToLogin = function () {
        if (this.create_review_link.includes('create-review')) {
            this.router.navigate(['/login']);
        }
    };
    RedirectService.prototype.redirectingForLogedInUser = function (url) {
        if (url.includes('login')
            && this.userService.getUser().accessLevel !== 0) {
            this.goHome();
        }
    };
    RedirectService.prototype.check = function (user) {
        if (Object.keys(this.userService.impersonation).length !== 0) {
            this.impersonation_setter();
            this.impersonation = this.impersonation_getter;
            this.impersonationUser = user;
        }
    };
    Object.defineProperty(RedirectService.prototype, "impersonation_user", {
        get: function () {
            return this.impersonationUser;
        },
        enumerable: true,
        configurable: true
    });
    RedirectService.prototype.impersonation_setter = function () {
        this.impersonation = this.userService.impersonation;
    };
    Object.defineProperty(RedirectService.prototype, "impersonation_getter", {
        get: function () {
            return this.impersonation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RedirectService.prototype, "user", {
        get: function () {
            return this.userService.getUser();
        },
        enumerable: true,
        configurable: true
    });
    RedirectService.prototype.goBack = function () {
        if (this.router.url.includes('/chat-room')) {
            this.location.back();
            return;
        }
        this.appFooterService.getMode() === 'buyer'
            ? this.goTo('/shopping-list')
            : this.goTo('/inventory-list');
    };
    RedirectService.prototype.goHome = function () {
        this.goTo(this.defaultRedirectUrl);
    };
    RedirectService.prototype.goTo = function (url) {
        this.router.navigate([url]);
    };
    RedirectService.prototype.reloadWindow = function () {
        this.w.location.reload();
    };
    RedirectService.prototype.setRedirectUrl = function (url) {
        this.redirectUrl = url.replace(/\(.+\)/, '');
    };
    RedirectService.prototype.checkRedirectPath = function () {
        var ref = this.w.location.pathname;
        if (ref.split('/')[1] === 'product-details') {
            this.link = this.w.location.pathname;
            return;
        }
        if (ref.split('/')[1] === 'create-review' && this.w.location.search.split('=')[0] === '?order_element_id') {
            this.create_review_link = this.w.location.href;
            return;
        }
        var isNotGuest = this.user.accessLevel !== 0;
        var isAuthPage = ref === '/login' || ref === '/signup';
        var isNoSellerModeOpenSheet = (ref === '/inventory-sheet' && this.userService.isSellerMode());
        var firstStartAppCondition = this.firstStartApp
            && noRedirectPaths.findIndex(function (path) { return path === ref; }) === -1;
        if (isNoSellerModeOpenSheet) {
            !this.firstStartApp && this.goTo(ref);
            return;
        }
        else if (isNotGuest && isAuthPage || firstStartAppCondition) {
            this.goTo('/shopping-list');
        }
        else {
            !this.firstStartApp && this.goTo(ref);
        }
    };
    RedirectService.prototype.dynamicLinktoProduct = function () {
        var _this = this;
        var id_product = this.w.location.pathname
            .slice(1, this.w.location.pathname.length).split('/')[1];
        if (id_product) {
            return this.productDetailsService.showProductDetails(id_product)
                .toPromise().then(function () { return _this.link = ''; }, function () { return _this.link = ''; });
        }
    };
    RedirectService.prototype.dynamicLinktoCreateReview = function (order_element_id) {
        var _this = this;
        this.appReviewsService.linkToCreateReview(order_element_id).subscribe(function (res) {
            _this.create_review_link = '';
        }, function (error) {
            _this.router.navigate(['/shopping-list']);
            _this.create_review_link = '';
        });
    };
    return RedirectService;
}());
export { RedirectService };
