
        <header class="app-header">
            <nav class="app-header__container marker-header">

                <div class="navbar__left">
                    <button type="button" (click)="backClick()" data-test-id="backLink"
                       class="nav__link"><i class="icon-custom_back_icon"></i></button>
                </div>

                <div class="navbar__center">
                    <h4 data-test-id="marketTitle">{{ itemTitle }}</h4>
                </div>

                <div class="navbar__right">
                </div>
            </nav>
        </header>
    