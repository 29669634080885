/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./safe-textarea.component";
var styles_SafeTextareaComponent = [];
var RenderType_SafeTextareaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SafeTextareaComponent, data: {} });
export { RenderType_SafeTextareaComponent as RenderType_SafeTextareaComponent };
export function View_SafeTextareaComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "textarea", [["draggable", "false"]], [[1, "data-test-id", 0], [8, "autofocus", 0], [8, "className", 0], [8, "name", 0], [8, "placeholder", 0], [8, "rows", 0], [8, "value", 0], [8, "disabled", 0]], [[null, "keyup"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (_co.onBlur($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["        "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataTestId; var currVal_1 = _co.isAutofocus; var currVal_2 = _co.className; var currVal_3 = _co.name; var currVal_4 = _co.placeholder; var currVal_5 = _co.rows; var currVal_6 = _co.value; var currVal_7 = _co.disabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_SafeTextareaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "safe-textarea", [], null, null, null, View_SafeTextareaComponent_0, RenderType_SafeTextareaComponent)), i0.ɵdid(1, 573440, null, 0, i1.SafeTextareaComponent, [], null, null)], null, null); }
var SafeTextareaComponentNgFactory = i0.ɵccf("safe-textarea", i1.SafeTextareaComponent, View_SafeTextareaComponent_Host_0, { isAutofocus: "isAutofocus", className: "className", dataTestId: "dataTestId", name: "name", placeholder: "placeholder", rows: "rows", value: "value", disabled: "disabled", minMessageCharacters: "minMessageCharacters", maxMessageCharacters: "maxMessageCharacters" }, { changeEvent$: "changeEvent$", isValidEvent$: "isValidEvent$" }, []);
export { SafeTextareaComponentNgFactory as SafeTextareaComponentNgFactory };
