import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import { ShoppingListService }      from '../../shopping-list/shopping-list.service';
import { ShoppingListInterface, ShoppingListItemInterface } from '../../shopping-list/shopping-list.interface';
import { SellingItemResponseBody} from '../../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { ErrorInterface } from '../../services/error.service';
import { CartService } from '../../cart/cart.service';
import { ShoppingCartElement, ShoppingCartResponseBody } from '../../../../swagger-gen__output_dir';
import { Observable, Subscription } from 'rxjs';
import { unsubscribe } from '../../common/app.helpers';

@Component({
    selector:   'item-search-list',
    template:   `
        <product-search-list
            (addItemToSL$)="addToShoppingListEvent($event)"
            [items]="items['nearest']"
            [search_from]="search_from"
            [shoppingList]="shoppingList"
            [shoppingCartElements]="shoppingCartElements"
            [title]="'product.search.list.title.nearest' | translate"></product-search-list>

        <product-search-list
            (addItemToSL$)="addToShoppingListEvent($event)"
            [items]="items['other']"
            [search_from]="search_from"
            [shoppingList]="shoppingList"
            [shoppingCartElements]="shoppingCartElements"
            [title]="'product.search.list.title.other' | translate"></product-search-list>
    `
})

export class ItemSearchComponent implements OnInit, OnDestroy {
    @Input() items:         SellingItemResponseBody[];
    @Input() search_from:   string;
    @Input() shoppingList:  ShoppingListItemInterface[];

    public shoppingCartElements: ShoppingCartElement[];

    private subscriptions$: Subscription[] = [];

    constructor(
        private shoppingListService:    ShoppingListService,
        private cartService:            CartService,
        private changeDetector:         ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.shoppingList = this.shoppingListService.getCachedList();
        this._getCart();
        this.subscribeOnChangePaymentView();
    }

    public ngOnDestroy(): void {
        unsubscribe(this.subscriptions$);
    }

    private subscribeOnChangePaymentView(): void {
        this.subscriptions$.push(
            this.cartService.onChangePaymentView.subscribe(() => 
                this.changeDetector.detectChanges(),
            ),
        );
    }

    /**
     * @desc Fetches shopping cart data and sets class fields on response.
     * @private
     */
    private _getCart(): void {
        this.subscriptions$.push(
            this.cartService.getCart()
                .subscribe(
                    (cart: ShoppingCartResponseBody) => this.shoppingCartElements = cart.items, 
                    (err: ErrorInterface) => Observable.throw(err)),
        );
    }

    /**
     * @desc add one product to Shopping List
     * @param {SellingItemResponseBody} item
     */
    addToShoppingListEvent(item: SellingItemResponseBody): void {
        const newItem: ShoppingListItemInterface = {
            name:                   item.subcategoryEntryName,
            subcategory_entry_id:   item.subcategoryEntryID,
            is_active:              true,
            is_custom:              false,
            descriptor:             item.subcategoryEntryName
        };

        this.addToShoppingList(newItem);
    }

    addToShoppingList(obj: ShoppingListItemInterface) {
        const newItem = this.shoppingListService.addItem(obj, this.shoppingList);

        this.subscriptions$.push(
            this.shoppingListService.updateShoppingList(newItem)
                .subscribe(
                    (res: ShoppingListInterface) => this.shoppingList = res.elements,
                    (err: ErrorInterface) => {
                        // The error has already been processed in ShoppingListService
                        // and errorService.handleError(err) and the modal window was shown.
                        return null;
                    }
                ),
        );
    }
}
