
        <div class="component message-component">

            <dialogs-list-header></dialogs-list-header>

            <div class="component__container">
                <section class="search">
                    <label>
                        <input #searchInp class="search-header__input" type="text"
                               data-test-id="searchInp" placeholder="Search"
                               [value]="searchQuery"
                               (keyup)="onSearch($event)">

                    </label>
                    <span
                        class="nav__link text-button"
                        data-test-id="searchCancel"
                        (click)="clearSearch()"
                    >
                        Cancel
                    </span>
                </section>

                <h3 *ngIf="dialogs?.length === 0" class="empty-list">
                    {{ "chatroom.empty" | translate }}
                </h3>

                <div class="dialog-list" *ngIf="dialogs">

                    <dialogs-list-item
                        *ngFor="let dialog of dialogs; trackBy: trackByID; let idx = index"
                        [attr.data-test-id]="'dialog_'+idx"
                        [dialog]="dialog"
                        [id]="idx"
                        (removeEvent$)="removeDialog(dialog.ID)"
                        (showEvent$)="showDialog(dialog)"></dialogs-list-item>
                </div>
            </div>
        </div>
    