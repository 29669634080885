import {AfterViewInit, Component, ElementRef, Injector, Input} from '@angular/core';
import {Router} from '@angular/router';

import {AppFooterService, UserMode} from './app-footer.service';
import {CategoryInterface} from '../interfaces/category.interface';
import {AppSearchService} from '../search/search.service';
import {UserService} from '../user/user.service';
import {GetCounterResponse} from '../../../swagger-gen__output_dir';
import {ModalService} from '../modal/modal.service';
import {Observable} from 'rxjs/Rx';
import {ErrorInterface} from '../services/error.service';

@Component({
    selector:   'app-footer',
    styleUrls:  ['app-footer.sass'],
    templateUrl: './app-footer.component.html'
})


export class AppFooterComponent implements AfterViewInit {

    public isAdminAccessLevel: boolean;
    public counts:             GetCounterResponse;
    public isCategoryShown     = false;
    public isNavShown          = false;
    public mode:               UserMode;
    public readonly userMode:  typeof  UserMode =  UserMode;

    @Input() type:      string;

    private mainBtn:    HTMLDivElement;

    constructor(
        private appFooterService:   AppFooterService,
        private elementRef:         ElementRef,
        private router:             Router,
        private injector:           Injector,
        private userService:        UserService
    ) {
       this.subscribeOnAppFooterServiceChanges();
    }


    public ngAfterViewInit(): void {
        this.mainBtn = this.elementRef.nativeElement.querySelector('span[data-test-id="footerMainBtn"]');
    }


    private _setValues(mode?: UserMode): void {
        this.mode = mode || this.appFooterService.getMode();
        // const _accessLevel = this.appFooterService.getAccessLevel();
        // 3 its admin rights
        this.isAdminAccessLevel = this.userService.isAdminMode();
    }

    public getMode(): UserMode {
        return this.appFooterService.getMode();
    }


    public changeStatus(elem: EventTarget): void {
        if (!elem || elem['classList'].contains('active')) return;

        this.hideAll();

        this.mode = this.appFooterService.setMode(this.mode === UserMode.buyerMode ? UserMode.sellerMode : UserMode.buyerMode);

        this.injector.get(AppFooterService).setModeOriginator(this.injector.get(AppFooterService).getMode());

        // only seller allowed for inventory list
        if (this.mode === UserMode.buyerMode && elem['getAttribute']('data-path') === '/inventory-list') return;

        this.router.navigate([elem['getAttribute']('data-path')]);
    }


    public goTo(elem: HTMLElement): void {
        const tgt  = elem.closest('button');

        if (!tgt) return;

        const name = tgt.getAttribute('data-test-id');

        if (this.userService.isGuestMode && name !== 'cart') {
            return;
        }

        this.hideAll();

        if (name === 'users-list') this.search_service.is_key_search = true;

        if ( this.type === 'guest' && name === 'profile') return;
        else this.router.navigate(['/' + name]);
    }


    /***
     * @desc This function creates 'search service' property on your service.
     * @return {AppSearchService}
     */
    public get search_service(): AppSearchService {
        return this.injector.get(AppSearchService);
    }

    public get modalService(): ModalService {
        return this.injector.get(ModalService);
    }


    public showMainMenu(): void {
        this.counts = null;
        this.isNavShown    = false;

        if (this.isCategoryShown) {
            this.isCategoryShown = false;
        } else {
            this.modalService.showSpinner();
            // @ts-ignore
            this.appFooterService.getCounts()
                .subscribe((counts: GetCounterResponse) => {
                    this.counts = counts;
                    this.isCategoryShown = true;
                    this.modalService.close();
                }, (err: ErrorInterface) => Observable.throw(err));
        }
    }


    public showGoodsNav(): void {
        this.isCategoryShown = false;
        this.isNavShown = !this.isNavShown;
    }


    public onModeChange(category: CategoryInterface): void {
        if (this.mode === UserMode.sellerMode) {
            this.showNavigation(category);
        } else {
            this.modalService.showSpinner();
            this.appFooterService.getNavTree()
                .subscribe((categoryList: CategoryInterface[]) => {
                    this.modalService.close();

                    categoryList = categoryList.filter((categoryItem: CategoryInterface) => category.ID === categoryItem.ID);
                    this.showNavigation(categoryList[0]);
                }, (error: ErrorInterface) => this.modalService.close());
        }
    }

    private showNavigation(category: CategoryInterface): void {
        this.hideAll();
        this.appFooterService.showNavigation(category, this.mode === UserMode.sellerMode);
    }


    public hideAll(): void {
        this.isCategoryShown    = false;
        this.isNavShown         = false;
    }


    /**
     * @desc Subscribes on AppFooter Service changes
     * @private
     */
    private subscribeOnAppFooterServiceChanges(): void {
        this._setValues();

        this.appFooterService.getUsersData().subscribe(() => {
            this._setValues();
        });

        this.appFooterService.onModeChanges.subscribe((mode: UserMode) => {
            this._setValues(mode);
        });
    }

}
