import {Component, EventEmitter, Input, Output} from '@angular/core';



@Component({
    selector: 'checkbox-field',
    template: `
        <div [classList]="classContainer" [attr.data-test-id]="dataTestIdInput + '_container'"
             (click)="onChangeCheckboxValue()">
            <input name="keepLogged" type="checkbox" [checked]="isCheckboxON"
                   [value]="isCheckboxON" (change)="onChangeCheckboxValue()"
                   [classList]="classInput"
                   [attr.data-test-id]="dataTestIdInput" [attr.id]="forInput">
            <label [attr.for]="forInput" [classList]="classLabel"></label>
            <p>{{ textLabel }}</p>
        </div>
    `
})

export class CheckboxComponent {

    @Input() classContainer:            string = 'input__label-checkbox text-left squaredGreenCheckbox';
    @Input() classLabel:                string = '';
    @Input() classInput:                string = '';
    @Input() dataTestIdInput:           string;
    @Input() forInput:                  string = '';
    @Input() textLabel:                 string = '';
    @Input() isCheckboxON:              boolean;

    @Output() onChangeField = new EventEmitter<boolean>();


    public onChangeCheckboxValue(): void {
        this.isCheckboxON = !this.isCheckboxON;
        this.onChangeField.emit(this.isCheckboxON);
    }
}
