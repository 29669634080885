
        <div *ngIf="impersonation" class="impersonation-message-block">
            {{ "header.hint.impersonating" | translate }} {{ userNameImpersonation }}
        </div>
        <div
            [class]="
               (userName && type !== 'guest')
                  ? 'way-to-login-static-container real_user'
                  : 'way-to-login-static-container'
             " *ngIf="!impersonation && type"
        >
            <div class="user-name-header-block">
                <h4 aria-label="Hi!" class="clip user-name-header-block--name">
                {{ "header.hint.hi" | translate }}{{(userName && type !== 'guest') ? ", " + userName : ''}}!</h4>
                <h4 *ngIf="type === 'guest'">
                    <button
                        (click)="goTo('/login')"
                        class="nav__link"
                        type="button"
                        aria-label="Go to LogIn page"
                        data-test-id="login-link"
                    >
                    {{ "header.hint.login" | translate }}
                    </button>
                    {{ "header.hint.or" | translate }}
                    <button
                        (click)="goTo('/signup')"
                        class="nav__link"
                        type="button"
                        aria-label="Go to SignUp page"
                        data-test-id="signup-link"
                    >
                    {{ "header.hint.signup" | translate }}
                    </button>
                </h4>
            </div>


            <figure
                class="static-container__login__container-logo"
                (click)="goTo(createRootUrl())"
                data-test-id="goToRootPage"
                aria-label="Go to Root page"
            >
                <div class="login__logo">
                    <img height="25" width="100" src="assets/images/bizibaza-logo.svg" alt="bizibaza&reg;">
                </div>
            </figure>

            <div>
                <button
                    class="relative btn__round"
                    *ngIf="order_icon"
                    (click)="goTo('/orders')"
                    id="orderItemCounterHeader"
                    data-test-id="orderItemCounterHeaderButton"
                    type="button"
                    aria-label="Go to Order page"
                >
                    <div class="orders_icon"></div>
                    <div
                        *ngIf="isSeller && counts?.order_seller_counter"
                        class="badge"
                        [attr.aria-labelledby]="'There are ' + counts?.order_seller_counter + ' products in your order list'"
                        data-test-id="analyticsCounts"
                    >
                        <span data-test-id="orderItemCounterHeader"
                              class="relative">{{counts?.order_seller_counter}}</span>
                    </div>
                </button>

                <button
                    class="relative btn__round"
                    *ngIf="cart_icon"
                    (click)="goTo('/cart')"
                    id="cartItemCounterHeader"
                    data-test-id="cartItemCounterHeader"
                    aria-label="Go to Shopping Cart page"
                    type="button"
                >
                    <div class="icon-cart_icon"></div>
                    <div *ngIf="counts?.sc_counter" class="badge"
                         [attr.aria-labelledby]="'There are ' + counts?.sc_counter + ' products in your shopping cart'">
                        <span data-test-id="cartItemCounterHeader" class="relative">{{counts?.sc_counter}}</span>
                    </div>
                </button>
            </div>
        </div>
    