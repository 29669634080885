import { Injector } from '@angular/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import { UserService } from '../user/user.service';
import { CartService } from '../cart/cart.service';
var AppHeaderService = /** @class */ (function () {
    function AppHeaderService(cartService, injector) {
        this.cartService = cartService;
        this.injector = injector;
    }
    Object.defineProperty(AppHeaderService.prototype, "user_service", {
        get: function () {
            return this.injector.get(UserService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppHeaderService.prototype, "countsChanges", {
        get: function () {
            return this.cartService.countsChanges;
        },
        enumerable: true,
        configurable: true
    });
    return AppHeaderService;
}());
export { AppHeaderService };
