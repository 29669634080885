

<div class="component">

    <addr-editor-header (submit)="onSubmit()" (canDeactivate)="checkChanging()"></addr-editor-header>

    <div class="component__container">
        <div class="addr-editor__locate-container">
            <div class="addr-editor__locate" data-test-id="LocateMeButton" (click)="locateMe()">
                <i class="icon-location"></i>
                <h4>{{ "addr.editor.header.locate" | translate }}</h4>
            </div>
        </div>


        <div class="signup__main">

            <form [formGroup]="addrForm" (ngSubmit)="onSubmit()">

                <div class="signup__container__form">

                    <label class="input__label clearfix">
                        <input class="input__text" type="text"
                               [ngClass]="{ error: addrForm.get('firstName').touched && addrForm.get('firstName').invalid }"
                               data-test-id="firstNameInp"
                               (blur)="blurField($event)"
                               placeholder="First Name" name="firstName" required autofocus formControlName="firstName">
                    </label>

                    <label class="input__label clearfix">
                        <input class="input__text" type="text"
                               [ngClass]="{ error: addrForm.get('lastName').touched && addrForm.get('lastName').invalid }"
                               data-test-id="lastNameInp"
                               (blur)="blurField($event)"
                               placeholder="Last Name" name="lastName" required autofocus formControlName="lastName">
                    </label>

                    <label class="input__label clearfix">
                        <input class="input__text" type="text"
                               [ngClass]="{ error: addrForm.get('address1').touched && addrForm.get('address1').invalid }"
                               data-test-id="address1Inp"
                               (blur)="blurField($event)"
                               [placeholder]="'common.addressLine1.placeholder' | translate" name="address1" required formControlName="address1">
                    </label>

                    <label class="input__label clearfix">
                        <input class="input__text" type="text"
                               [ngClass]="{ error: addrForm.get('address2').touched && addrForm.get('address2').invalid }"
                               data-test-id="address2Inp"
                               (blur)="blurField($event)"
                               [placeholder]="'common.addressLine2.placeholder' | translate" name="address2" formControlName="address2">
                    </label>

                    <label class="input__label clearfix">
                        <input class="input__text" type="text" placeholder="City"
                               [ngClass]="{ error: addrForm.get('city').touched && addrForm.get('city').invalid }"
                               data-test-id="cityInp"
                               (blur)="blurField($event)"
                               name="city" required formControlName="city">
                    </label>

                    <fieldset data-test-id="stateFieldset" [disabled]="addrForm.get('country').value !== 'United States'">
                        <label class="input__label input__select-style clearfix select-block">
                            <p>State</p>
                            <select name="state" data-test-id="stateSelect"
                                    [ngClass]="{ error: addrForm.get('state').value==='State' }"
                                    formControlName="state">

                                <option *ngFor="let s of states; let i=index" dir="ltr"
                                        [ngValue]="s"
                                        [disabled]="i===0">{{s}}</option>
                            </select>
                        </label>
                    </fieldset>

                    <label class="input__label clearfix">
                        <input class="input__text" type="text" placeholder="Postal code"
                               [ngClass]="{ error: addrForm.get('postCode').touched && addrForm.get('postCode').invalid }"
                               data-test-id="codeInp"
                               (blur)="blurField($event)"
                               name="postCode" required formControlName="postCode">
                    </label>

                    <label class="input__label clearfix">
                        <input class="input__text" type="tel"
                               [ngClass]="{ error: addrForm.get('phone').touched && addrForm.get('phone').invalid }"
                               data-test-id="phoneInp"
                               (blur)="blurField($event)"
                               placeholder="Phone" name="phone" required formControlName="phone">
                    </label>

                    <label class="input__label input__select-style clearfix select-block">
                        <p>Country</p>
                        <select name="country" data-test-id="countrySelect"
                                [ngClass]="{ error: addrForm.get('country').value==='Country' }"
                                formControlName="country">

                            <option *ngFor="let c of countries;let i=index" dir="ltr"
                                    [ngValue]="c">{{c}}</option>
                        </select>
                    </label>

                    <label *ngIf="addrObj && !addrObj.isOnly" class="input__label-checkbox">
                        <input name="makePrimary" type="checkbox" #makePrimary
                               data-test-id="makePrimary"
                               [checked]="addrObj.addr && addrObj.addr.isPrimary"
                               (change)="isPrimary=makePrimary.checked"> <h3 name="makePrimary">{{ "addr.editor.header.make.primary" | translate }}</h3>
                    </label>
                </div>


            </form>
        </div>

    </div>

</div>

