import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, Observer, of } from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

import {
    BizipixInterface,
    BizipixItemsInterface,
} from '../interfaces/bizipix.interface';
import { DataService } from '../services/data.service';
import { ErrorInterface, ErrorService } from '../services/error.service';
import { ImageUploadService } from '../services/image-upload.service';
import { InventoryListService } from '../inventory-list/inventory-list.service';
import { ModalService } from '../modal/modal.service';
import { UserService } from '../user/user.service';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { SearchResultItems } from '../interfaces/search.interface';
import { ItemService } from '../services/item.service';
import { UserSessionInterface } from '../interfaces/user.interface';
import { TranslateService } from '@ngx-translate/core';
import { getErrorType } from '../services/error.helpers';
// import { stubInventory }            from './stub-inventory-list';
// import { stubBizipix }              from './stub-bizipix';

@Injectable()
export class BizipixEditorService {
    public imageData: BizipixItemsInterface[];
    public clonedBizipix: BizipixItemsInterface[];
    // tslint:disable-next-line:variable-name
    public available_amount: number;

    public constructor(
        private dataService: DataService,
        private errorService: ErrorService,
        private imageUploadService: ImageUploadService,
        private inventoryListService: InventoryListService,
        private location: Location,
        private modalService: ModalService,
        private router: Router,
        private userService: UserService,
        private itemService: ItemService,
        private translate: TranslateService,
    ) {}

    /**
     * @desc Delegates image uploading to ImageUploadService. Processes spinner and error handling.
     * @param img
     * @return Observable<string>
     */
    public uploadImg(img: Blob): Observable<string> {
        this.modalService.showSpinner();
        return this.imageUploadService
            .uploadImg(img, this.userService.getUserSession().token)
            .do(() => {
                this.modalService.close();
            })

            .catch((err: ErrorInterface) => {
                this.errorService.handleError(err);
                return Observable.throwError(getErrorType(err));
            });
    }

    public getUserSession(): UserSessionInterface {
        return this.userService.getUserSession();
    }

    /**
     * @desc Shows modal warning.
     * @return void
     */
    public showCreationWarning(): void {
        this.modalService.error({
            title:          this.translate.instant('bizipix.error.creation.title'),
            message:        this.translate.instant('bizipix.error.creation.message'),
            yesButtonText:  this.translate.instant('bizipix.error.creation.ok'),
        });
    }

    /**
     * @desc Returns BiziPix data object.
     * @return {BizipixItemsInterface}
     */
    public getImageData(): BizipixItemsInterface[] {
        return this.imageData;
    }
    public setImageData(imageData: BizipixItemsInterface[]): void {
        this.imageData = imageData;
    }

    /**
     * @desc Returns BiziPix data object.
     * @return {BizipixItemsInterface}
     */
    public get getClonedBizipix(): BizipixItemsInterface[] {
        return this.clonedBizipix;
    }

    public setClonedBizipix(bizipixData: BizipixItemsInterface[]): void {
        this.clonedBizipix = JSON.parse(JSON.stringify(bizipixData));
    }

    public get availableAmount(): number {
        return this.available_amount;
    }

    /**
     * @desc Creates a BiziPix object template and posts it to the server.
     * @param imageURL
     * @return Observable<{}>
     */
    public createBizipix(imageURL: string): Observable<{}> {
        const token: string = this.userService.getUserSession().token;

        if (!token) {
            return Observable.of({});
        }

        const pixObj: BizipixItemsInterface = {
            ID: '',
            image_url: imageURL,
            user_id: this.userService.getUserSession().ID,
            tags: [],
        };

        this.modalService.showSpinner();

        return this.dataService
            .postData('create_pix_image', { bizi_pix: pixObj }, { token })
            // tslint:disable-next-line:no-any
            .map((res: any) => {
                this.modalService.close();
                return res['bizi_pix'];
            })
            .catch((err: ErrorInterface) => {
                // TODO: remove after backend update and correct error response due ErrorInterface
                // err === {"error": "You can't create more that 5 BiziPixes."} now
                if (err.status === 400) {
                    return this.moreThanFiveBiziPixMessage();
                }
                if (err.status === 400) return this._createBizipixError(getErrorType(err));
                else return this.errorService.handleError(err);
            });
    }

    /**
     * @desc Fetches the BiziPix data object and redirects to the editor on response.
     * @return void
     */
    public editBizipix(): void {
        const token: string = this.userService.getUserSession().token;

        if (!token) {
            return;
        }

        this.modalService.showSpinner();

        this.dataService
            .getData(
                `get_pix_image?user_id=${this.userService.getUserSession().ID}`,
                { token },
            )
            .subscribe(
                (imageData: BizipixInterface) => {
                    this.imageData = imageData.bizi_pixes;
                    this.setClonedBizipix(imageData.bizi_pixes);

                    this.available_amount = imageData.available_amount;

                    this.modalService.close();
                    this.router.navigate(['/bizipix-editor']);
                },
                (err: ErrorInterface) => this.errorService.handleError(err),
            );
    }

    /**
     * @desc Shows quit modal warning and exits the editor if confirmed.
     * @return void
     */
    public closeEditor(params: {
        changed?: boolean;
        bizipix?: BizipixItemsInterface;
    }): void {
        if (params.changed) {
            this.modalService
                .warning({
                    title: this.translate.instant('bizipix.error.close.title'),
                    message: this.translate.instant('bizipix.error.close.message'),
                    yesButtonText: this.translate.instant('bizipix.error.close.ok'),
                    noButtonText: this.translate.instant('bizipix.error.close.no'),
                    reverseButtons: true,
                })
                .then((action: boolean) => {
                    if (action) {
                        this.exitEditor(action);
                        if (params.bizipix) {
                            this.savePix(params.bizipix);
                        }
                    } else {
                        this.exitEditor(true);
                    }
                });
        } else {
            this.exitEditor(true);
        }
    }

    /**
     * @desc Shows a modal confirmation dialog.
     * @return Promise<{}>
     */
    public changeBizipixInEditor(): Promise<{}> {
        return this.modalService
            .warning({
                title: this.translate.instant('bizipix.error.change.title'),
                message: this.translate.instant('bizipix.error.change.message'),
                yesButtonText: this.translate.instant('bizipix.error.change.ok'),
                noButtonText: this.translate.instant('bizipix.error.change.no'),
            });
    }

    public moreThanFiveBiziPixMessage(): Promise<{}> {
        return this.modalService
            .error({
                title:          this.translate.instant('bizipix.error.more.title'),
                message:        this.translate.instant('bizipix.error.more.message'),
                yesButtonText:  this.translate.instant('bizipix.error.more.ok'),
            });
    }

    /**
     * @desc Shows an error modal
     * @param {ErrorInterface} error
     * @return Observable<string>
     * @private
     */
    _createBizipixError(error: string): Observable<string> {
        this.modalService
            .error({
                title:          this.translate.instant('bizipix.error.add.title'),
                message:        error,
                yesButtonText:  this.translate.instant('bizipix.error.add.ok'),
            });
        return of(error);
    }

    /**
     * @desc Exits the editor if the input is truthy.
     * @return void
     * @param action
     */
    public exitEditor(action: boolean): void {
        action && this.location.back();
    }

    /**
     * @desc Shows a modal confirmation dialog.
     * @return Promise<{}>
     */
    public removeTag(): Promise<{}> {
        return this.modalService
            .warning({
                title:          this.translate.instant('bizipix.error.remove.tag.title'),
                message:        this.translate.instant('bizipix.error.remove.tag.message'),
                yesButtonText:  this.translate.instant('bizipix.error.remove.tag.ok'),
                noButtonText:   this.translate.instant('bizipix.error.remove.tag.no'),
            });
    }

    /**
     * @desc Shows a modal confirmation dialog on remove, proceeds if approved.
     * @return Observable<void>
     */
    public removeBizipix(): Promise<{}>  {
        return this.modalService
            .warning({
                title:          this.translate.instant('bizipix.error.delete.title'),
                message:        this.translate.instant('bizipix.error.delete.message'),
                yesButtonText:  this.translate.instant('bizipix.error.delete.ok'),
                noButtonText:   this.translate.instant('bizipix.error.delete.no'),
            });
    }

    /**
     * @desc Posts to the server to remove the BiziPix object. Redirects back on response.
     * @return void
     * @private
     */
    // tslint:disable-next-line:no-any
    public removePix(currentBizipix: BizipixItemsInterface): Observable<any> {
        const token: string = this.userService.getUserSession().token;

        if (!token) {
            return;
        }

        this.modalService.showSpinner();

        return this.dataService
            .postData(
                'remove_pix_image',
                { pix_id: currentBizipix.ID },
                { token },
            )
            .map(() => {
                this.imageData.forEach((bixipix: BizipixItemsInterface) => {
                    if (bixipix.ID === currentBizipix.ID) {
                        const index: number = this.imageData.indexOf(bixipix);
                        this.imageData.splice(index, 1);
                    }
                });

                if (!this.imageData.length) {
                    this.location.back();
                }

                this.modalService.close();
            })
            .catch((err: ErrorInterface) => this.errorService.handleError(err));
    }

    /**
     * @desc Posts to the server to update the BiziPix object. Redirects back on response.
     * @param {BizipixItemsInterface} imageData
     * @param {boolean} presave
     * @return void
     */
    public savePix(imageData: BizipixItemsInterface, presave?: boolean): void {
        const token: string = this.userService.getUserSession().token;

        if (!token) {
            return;
        }

        this.modalService.showSpinner();

        this.dataService
            .postData('update_pix_image', { bizi_pix: imageData }, { token })
            .subscribe(
                () => {
                    this._successfully(
                        this.translate.instant('bizipix.update.success')
                    );
                    if (presave === false) {
                        this.location.back();
                    }
                },
                (err: ErrorInterface) => this.errorService.handleError(err),
            );
    }

    /**
     * Shows modal success window.
     * @param {string} text
     * @return {Promise<{}>}
     * @private
     */
    public _successfully(text: string): Promise<{}> {
        return this.modalService.success({
            title: text,
            yesButtonText: this.translate.instant('bizipix.error.add.ok'),
        });
    }

    /**
     * @desc Returns Observable of the inventory list.
     * @return {Observable<SellingItemResponseBody[]>}
     */
    public getActiveInventoryItems(): Observable<SellingItemResponseBody[]> {
        return new Observable((observer: Observer<SellingItemResponseBody[]>) => {
            this.inventoryListService
                .getList()
                .subscribe((items: SellingItemResponseBody[]) => {
                    observer.next(
                        items.filter((item: SearchResultItems) => {
                            return (
                                !this.itemService.isDraftFn(item) &&
                                !this.itemService.isExpiredFn(item)
                            );
                        }),
                    );
                });
        });
    }
}
