
        <textarea 
            draggable="false"
            [attr.data-test-id]="dataTestId"
            [autofocus]="isAutofocus"
            [class]="className"
            [name]="name"
            [placeholder]="placeholder"
            [rows]="rows"
            [value]="value"
            [disabled]="disabled"
            (keyup)="onKeyUp($event)"
            (blur)="onBlur($event)">
        </textarea>
    