import {Injectable} from '@angular/core';
import { Subject }                  from 'rxjs/Subject';

import {BlankComponent} from '../modal/blank.component';
import {PrivacyPolicyComponent} from './privacy-policy.component';
import {Observable} from 'rxjs/Observable';
import {CacheNotificationCommandInterface} from "./cache-notification.interface";
import {StorageService} from "../services/storage.service";



@Injectable()
export class CacheNotificationService {

    private showModal$  = new Subject<CacheNotificationCommandInterface>();
    private currentModalType: typeof BlankComponent | typeof PrivacyPolicyComponent = BlankComponent;

    constructor(private storageService: StorageService) { }

    /**
     * Setter for type of currently displayed modal.
     * @param component
     */
    set modalType(component: typeof BlankComponent | typeof PrivacyPolicyComponent) {
        this.currentModalType = component;
    }


    /**
     * Getter for type of currently displayed modal.
     */
    get modalType(): typeof BlankComponent | typeof PrivacyPolicyComponent {
        return this.currentModalType;
    }

    public close(): void {
        this.showModal$.next({
            context:    {},
            component:  BlankComponent
        });
    }

    public privacyPolicy(): Promise<boolean | string> {
        const modalOptions: CacheNotificationCommandInterface = { component: PrivacyPolicyComponent, context: {}};

        if (!this.storageService.get('isShowPrivacyPolicyNotification')) {
            return this._createModal(modalOptions).then((isClose: boolean) => {
                this.storageService.set('isShowPrivacyPolicyNotification', "true");
                return isClose;
            });
        }
    }

    private _createModal(command: CacheNotificationCommandInterface): Promise<boolean | string> {
        let component = command && command.component ? command.component : BlankComponent;
        this.showModal$.next({
            component:       component,
            context: command.context
        });

        return new Promise(resolve => {
            command.context.handler = (arg: boolean | string) => resolve(arg) || false;
        });
    }

    public getCommand(): Observable<CacheNotificationCommandInterface> {
        return this.showModal$.asObservable();
    }

}
