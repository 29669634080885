import {
    AfterViewInit, Component,
    ElementRef, EventEmitter, Input,
    OnInit, Output,
}                                       from '@angular/core';
import { Subject }                      from 'rxjs';
import { Location }                     from '@angular/common';
import 'rxjs/add/operator/switchMap';

import { BizipixViewerService }         from '../bizipix-viewer/bizipix-viewer.service';
import { ModalService }                 from '../modal/modal.service';
import { ProductDetailsService }        from './product-details.service';
import {
    ProductDetailsOptionsInterface, ProductDetailsViewInterface,
} from '../interfaces/product.interface';
import { ItemService }               from '../services/item.service';
import parseInputFloat                  from '../common/parseInputFloat';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { ErrorInterface } from '../services/error.service';
import AppValues from '../common/app.values';
import { TranslateService } from '@ngx-translate/core';
import { NumberUSFormat } from '../interfaces/price-value.interface';

@Component({
    selector:   'product-header',
    styleUrls:  ['product-header.sass'],

    template:   `
        <div class="product-header" [style.background-image]="'url('+item.photoURL+')'">
            <div class="product-header__top">

                <span class="nav__link" *ngIf="item && !options?.readOnly">
                    <button (click)="location.back()" data-test-id="searchResultsLink" type="button">
                        <i class="icon-custom_back_icon"></i>
                    </button>
                </span>
                <span class="nav__link" *ngIf="item && options?.readOnly">
                    <button [routerLink]="'/history'" data-test-id="historyLink" type="button">
                        <i class="icon-custom_back_icon"></i>
                    </button>
                </span>

                <div class="product-header__top__right-block">
                    <div data-test-id="shareProductContainer">
                        <button (click)="shareProduct(item.dynamic_link)" *ngIf="!options?.readOnly" type="button">
                            <i data-test-id="shareProduct" class="icon-share"></i>
                        </button>
                        <span class="tooltiptext" id="myTooltip">{{ shareProductTip }}</span>
                    </div>

                    <button
                        [attr.data-state-active]="isInReportList"
                        (click)="checkReport(item.ID, $event)"
                        *ngIf="!options?.readOnly"
                        type="button"
                    >
                        <i [ngClass]="isInReportList ? 'icon-flag' : 'icon-flag-empty'" data-test-id="sendReport"></i>
                    </button>

                    <button
                        [attr.data-state-active]="item.inWatchList"
                        data-test-id="toggle-watchlist"
                        class="button-watchlist"
                        (click)="toggleWatchListStatus()"
                        *ngIf="!options?.readOnly"
                        type="button"
                    >
                        <i [ngClass]="item.inWatchList ? 'icon-eye_filled' : 'icon-eye_outlined'"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="product-header__bottom">
            <div class="product__title">
                <button data-test-id="BiziPix" (click)="showBizipix()" type="button">
                    <img width="61" height="46" [src]="'../../assets/images/svg_icons/shopix.svg'" alt="BiziPix">
                </button>
                <div id="product__title" data-test-id="detailTitle">{{item.title}}</div>
                <button
                    [attr.data-state-active]="item.inFavList"
                    data-test-id="toggle-favlist-status"
                    (click)="toggleFavListStatus()"
                    *ngIf="!options?.readOnly"
                    type="button"
                >
                    <i [ngClass]="item.inFavList ? 'icon-favorites_filled' : 'icon-favorites_outlined'"></i>
                </button>
            </div>

            <div class="product__calculator">
                <h3 class="product__calculator__price">{{ (options?.readOnly ? options?.price : price) / HUNDRED | currency:'USD'}}
                    / {{options?.readOnly ? options?.nameUnit : unitsName}}</h3>
                <h3 class="math-sign">&#215;</h3>
                <input type="text" class="product__qty" id="qtyInput" data-test-id="detailPriceQty"
                    min="0" placeholder="0" autofocus pattern="[0-9]*"
                    [attr.value]="getPriceQtyValue() ? (getPriceQtyValue() | number) : null"
                    [class.error]="qtyWarning"
                    [disabled]="options?.readOnly"
                    (input)="calculateCost($event.target)"
                    (paste)="false">
                <h3 class="math-sign">=</h3>
                <div class="product__err" [class.error]="qtyWarning">
                    <h3 #qtyOk *ngIf="cost || !qtyWarning" data-test-id="detailCost">
                        {{ (cost || 0) | currency:'USD'}}
                    </h3>
                    <span class="error-message" #qtyErr *ngIf="qtyWarning">{{qtyWarning}}</span>
                </div>
            </div>
        </div>
    `,
})

export class ProductHeaderComponent implements OnInit, AfterViewInit {
    /**
     * @desc Implements functionality:
     * - add/remove Watch/Favorite Lists;
     * - desired quantity calculation.
     * @listens item input.
     * @listens quantity input.
     * @emits quantityChange event.
     */
    @Input() public item:                  ProductDetailsViewInterface;
    @Input() public options:               ProductDetailsOptionsInterface;
    @Input() public quantity:              number;
    @Input() public isInReportList:        boolean;
    @Output() public quantityChange: EventEmitter<{}> = new EventEmitter<{}>();
    @Output() public toggleWatchList: EventEmitter<{}> = new EventEmitter<{}>();
    @Output() public toggleFavList: EventEmitter<{}> = new EventEmitter<{}>();


    public cost:               number;
    public HUNDRED:            number = 100;
    public location:           Location;
    public isSale:             boolean;
    public price:              number;
    public unitsName:          string;
    public currentTipStyles:   {};
    public qtyWarning:         string;
    public shareProductTip:    string;


    public constructor(
        private bizipixService:         BizipixViewerService,
        private elementRef:             ElementRef,
        private googleAnalyticsService: GoogleAnalyticsService,
        private productDetailsService:  ProductDetailsService,
        private modalService:           ModalService,
        private productService:         ItemService,
        private translate:              TranslateService,
        location:                       Location,
    ) {
        this.location = location;
        this.shareProductTip = this.translate.instant('productDetails.header.shareProductTip');
    }

    /**
     * @desc:
     * - Sets fallback photoURL
     * - Check if this product is on sale
     * - Set Price for items
     * - Set UnitsName for items
     */
    public ngOnInit(): void {
        this._checkOptionsFn();

        this.isInReportList = false;
        this.item.photoURL = this.item.photoURL || '../../assets/images/cart/no_image_icon.png';
    }

    public getPriceQtyValue(): number | undefined {
        if (this.quantity) {
            return this.quantity;
        }
        if (this.options.desiredQuantity) {
            return this.options.desiredQuantity;
        }
        return;
    }

    /**
     * @desc Lifecycle hook that is called after a component's view has been fully initialized.
     * If there is desiredQuantity and this view for Read Only then dispatch input product__qty
     */
    public ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.options.desiredQuantity) {
                this.elementRef.nativeElement.querySelector('.product__qty').dispatchEvent( new Event('input') );
            }
        });
    }

    /**
     * @desc Checks if quantity exceeds proposal; resets or calculates
     * cost according to that. If qty exceeds, clear the input and calls to show warning, if not -
     * calls to hide it.
     * @emits quantityChange event independently from excess
     * @param input
     */
    public calculateCost(input: HTMLInputElement): void {
        const value: NumberUSFormat = AppValues.NumberUSFormat({
                value: input.value,
                qty: Number(this.item.current_quantity),
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
            });
        const quantity: string = parseInputFloat(input.value);
        this.qtyWarning = '';
        // check that needed qty is less then available
        if (!this.options.readOnly && Number(quantity) > Number(this.item.current_quantity)) {
            this._resetQuantity(input);
            this.qtyWarning = this.translate.instant('productDetails.header.availableQuantityError.title', {current_quantity: this.item.current_quantity, qtyUnitName: this.item.qtyUnitName});
            return;
        } else if (Number(quantity) < 0) {
            this._resetQuantity(input);
            this.qtyWarning = this.translate.instant('productDetails.header.inputQuantity.negative.error');
            return;
        }
        this.quantityChange.emit(value.num);
        this.cost = Number(quantity) * this.price / this.HUNDRED;
        setTimeout(() => input.value = value.formatedNumber);
    }

    /**
     * @desc go to Bizipix view
     */
    public showBizipix(): void {
        this.bizipixService.showBizipix(this.item.sellerID);
    }


    /**
     * @desc Toggles inWatchList status.
     */
    public toggleWatchListStatus(): void {
        this.toggleWatchList.emit();
    }

    /**
     * @desc Toggles inFavList status.
     */
    public toggleFavListStatus(): void {
        this.toggleFavList.emit();
    }


    /***
     * Method for copying text to the clipboard
     * @param {string} dynamicLink
     */
    public shareProduct(dynamicLink: string): void {
        const animationDurationTime: number = 3;
        const fadeOutAnimation: number = animationDurationTime - 1.3;
        const selBox: HTMLTextAreaElement = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = dynamicLink;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');

        /**** View Message **/
        const container: Element = document.getElementsByClassName('tooltiptext')[0];
        container.setAttribute( 'class', 'tooltiptext get-share' );
        // container.setAttribute('style', 'transition: opacity ' + animationDurationTime + 's; animation: fadeOut ' + fadeOutAnimation + 's 1 cubic-bezier(1, -0.36, 1, -0.36)');

        setTimeout(() => container.setAttribute( 'class', 'tooltiptext' ), animationDurationTime * 1000);
        /**********  ********/

        document.body.removeChild(selBox);
    }


    public checkReport(itemID: string, event: MouseEvent): void {
        this.isInReportList ?
            this.modalService
              .success({
                  title:         this.translate.instant('productDetails.header.report.error.title'),
                  yesButtonText: this.translate.instant('productDetails.header.report.error.yesButton'),
              })
            : this.sendReport(itemID, event);
    }


    public sendReport(itemID: string, event: MouseEvent): void {
        this.modalService
          .dialog_question({
              title:          this.translate.instant('productDetails.header.sendReport.dialog.title'),
              yesButtonText:  this.translate.instant('productDetails.header.sendReport.dialog.yesButton'),
              noButtonText:   this.translate.instant('productDetails.header.sendReport.dialog.noButton'),
              reverseButtons: true,
          })
          .then((action: boolean) => {
              event && action && this._sendReport(itemID);
              event = null;
          });
    }


    private _sendReport(itemID: string ): void {
        this.productDetailsService.sendReport(itemID)
            .subscribe( () => {
               this._modalMethod(this.translate.instant('productDetails.header.sendReport.success'));
               this.isInReportList = true;
            }, (err: ErrorInterface) => null);
    }


    private _modalMethod(title: string): void {
        this.modalService.success({
                title,
                yesButtonText:  this.translate.instant('productDetails.header.report.error.yesButton'),
        });
    }

     /**
     * @desc Reset Quantity set to zero, if qty is more then available or negative number
     * @param {HTMLInputElement} input
     * @private
     */
    private _resetQuantity(input: HTMLInputElement): void {
        this.quantityChange.emit(0);
        this.cost = 0;
        input.focus();
        input.value = '';
        input.setAttribute('value', '');
    }

    /**
     * @desc check if this product is on sale
     * and set price and UnitsName
     * @param {SellingItemResponseBody | any} item
     * @private
     * @returns {boolean}
     */
    private _checkItemFn(item: SellingItemResponseBody | any): void {
         this.isSale         = this.productService.isSaleFn(item);
         this.price        = this.productService.itemPriceFn(item);
         this.unitsName    = this.productService.priceUnitsNameFn(item);
    }

    /**
     * @desc If you stay on History view and
     * go to one product - this product will be show as readOnly.
     * Calculate total cost for the product already been bought (from history).
     * Else: go to _checkItemFn method.
     * @private
     */
    private _checkOptionsFn(): void {
        if (this.options.readOnly) {
            this.price = this.options.price;
            this.cost =  this.price * this.options.desiredQuantity;
        } else { this._checkItemFn(this.item); }
    }
}
