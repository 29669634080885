var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef } from '@angular/core';
import { SearchListClass } from './search-list.class';
import { ShopperDetailsService } from '../../shopper-details/shopper-details.service';
var SellerSearchComponent = /** @class */ (function (_super) {
    __extends(SellerSearchComponent, _super);
    function SellerSearchComponent(elementRef, shopperDetailsService) {
        var _this = _super.call(this, elementRef) || this;
        _this.elementRef = elementRef;
        _this.shopperDetailsService = shopperDetailsService;
        return _this;
    }
    SellerSearchComponent.prototype.onClick = function (item) {
        this.shopperDetailsService.showDetails(item['ID']);
    };
    return SellerSearchComponent;
}(SearchListClass));
export { SellerSearchComponent };
