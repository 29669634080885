import {AfterViewInit, Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import {NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {Subscription} from 'rxjs';

import {RedirectService} from './services/redirect.service';
import {GoogleAnalyticsService} from './services/google-analytics.service';
import {UserService} from './user/user.service';
import {CheckTabStatusService} from './services/check-tab-status';
import {AppFooterService} from './app-footer/app-footer.service';
import {ShoppingListService} from './shopping-list/shopping-list.service';
import {AfterBootService} from './after-boot/after-boot.service';
import {UserModel, UserSessionInterface} from './interfaces/user.interface';
import {GetCounterResponse} from '../../swagger-gen__output_dir';
import {LocalCaching} from './local-caching/local-caching-factory';
import {Observable} from 'rxjs/Rx';
import {ErrorInterface} from './services/error.service';
import {GeoLocationService} from './services/geolocation.service';
import {TranslateService} from '@ngx-translate/core';
import {OperationSystemService} from './services/operation.system.service';
import {AppReviewsService} from './reviews/reviews.service';
import {UnsupportedDeviceService} from './unsupported-info/unsupported-device.service';
import {AppSettings} from './common/app.settings';
import {ClientPaymentService} from './payment/payment.service';
import {AppRouteValues, createDirection} from "./common/app.route.values";
import {CacheNotificationService} from "./cache-notification/cache-notification.service";
import AppValues from "./common/app.values";


declare var gtag: Function;
// declare let ga: Function;

// unknown is a type that was introduced in Typescript 3 and we are using Typescript 2.
// need for @sentry/browser
export type mixed = { [key: string]: any } | object | number | string | boolean | symbol | undefined | null | void;
declare global {
  type unknown = mixed;
}

@Component({
    selector: 'app-root',
    template: `
        <div [class]="impersonation === true ? 'app__container impersonation' : 'app__container'">
            <modal data-test-id="modal"></modal>
            <cache-notification-component data-test-id="cache-notification-component"></cache-notification-component>
            <unsupported-device-component data-test-id="unsupported-device"></unsupported-device-component>

            <div class="main__container">
                <app-header [class]="app_header__width" [cart_icon]="cart_icon" [order_icon]="order_icon" [counts]="counts" [impersonation]="impersonation" [userNameImpersonation]="userNameImpersonation" [userName]="userName" [type]="type"></app-header>
                <router-outlet></router-outlet>
            </div>

            <app-footer
                *ngIf="!hideFooter"
                [hidden]="isSearchActivated"
                [type]="type"
            ></app-footer>
        </div>
    `
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    public isSearchActivated = false;
    public hideFooter               = true;
    public cart_icon:               boolean;
    public order_icon:              boolean;
    public counts:                  GetCounterResponse;
    public app_header__width = '';
    public impersonation            = false;
    public userNameImpersonation:   string;
    public userName:                string;
    public type = 'guest';

    private subscriptions$: Subscription[] = [];
    private isUnsupportedAccount: boolean = false;

    private routesExcludedFromCaching: string[] = [
        '/shopping-list',
        '/inventory-list',
        '/product-editor'
    ];
    private checkStatusOfStripeAccountPaths: string[] = [
        '/inventory-list', 'profile'
    ];
    private noFooterPaths:  string[] = [
        '/about',
        '/faq',
        '/orders',
        '/addr-editor',
        '/bizipix-editor',
        '/bizipix-viewer',
        createDirection(AppRouteValues.routeCart),
        '/change-password',
        '/create-review',
        '/pending-orders',
        '/chat-room',
        '/dialogs',
        '/email-verification',
        '/goods-nav',
        '/history',
        '/inventory-nav',
        '/inventory-sheet',
        '/login',
        '/password-reset',
        '/login-link',
        '/product-details',
        '/product-editor',
        '/profile',
        createDirection(AppRouteValues.routeSettings),
        '/signup',
        '/success-onboarding',
        '/shopper-details',
        '/map-search',
        '/market',
        '/reviews-item',
    ];

    private noHeaderGuestPaths:  string[] = [
        '/bizipix-editor',
        '/bizipix-viewer'
    ];

    private noPathsForCart: string[] = [
        // '/signup',
        // '/login',
        createDirection(AppRouteValues.routeCart)
    ];

    constructor(
        private redirectService:        RedirectService,
        private router:                 Router,
        private googleAnalyticsService: GoogleAnalyticsService,
        private injector:               Injector,
        private shoppingListService:    ShoppingListService,
        private afterBootService:       AfterBootService,
        private localCaching:           LocalCaching,
        private operationSystem:        OperationSystemService,
        private translate:              TranslateService,
        private cacheNotificationService: CacheNotificationService
    ) { }

    /**
     * Prevent the use of the Landscaping app for mobile devices
     * @param event
     */
    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event?: any) {
        if (this.operationSystem.isAvailableBrowser()) {
            this.unsupported_device_service.unsupportedBrowser();
        } else {

            if (
                screen.availHeight < screen.availWidth
                && !this.isBizipixUrl() && this.operationSystem.isMobile()
            ) {
                this.unsupported_device_service.unsupportedOrientation();
            } else {
                this.unsupported_device_service.close();
            }
            this.unsupportedAccount();
        }
    }


    ngOnInit() {
        // this.googleAnalyticsService.initGtag(gtag);

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');
         // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use('en');


        window.console.info(
            `%c BiziBAZA Web v${AppSettings.APP_VERSION()}`,
            'background: rgba(142,195,79,0.5); font-size: 16px'
        );

        this.afterBootService.userServiceSave = this.user_service;

        this.geo_location_service.getGeolocation();

        this.subscribeOnSuggestionsSearchActivating();
        this.subscribeOnUserChanges();

        this._setGuestInfo();
        this._getNotificationCounts(this.router.url);

        this.shoppingListService.getAllSuggestionsForShoppingList();

        this.checkRoute(this.router.url, this.router.url);

        // Check status browser tab (open or close tab)
        setInterval(() => this.tab_service.newVisabilityTabMethod(), 500);
        setTimeout(() => {
            this.cacheNotificationService.privacyPolicy();
        }, 1000);

        this.router.events
            .filter((event) => event instanceof NavigationEnd)
            .subscribe((event: any) => {
                if (event instanceof NavigationEnd) {
                    this.redirectService.impersonation_setter();

                    this._setGuestInfo(event.url);
                    this._checkUserStatus();

                    this.redirectService.createReviewLink();
                    this.redirectService.redirectingForLogedInUser(event.url);

                    this.checkStatusOfStripeAccount(event.url);

                    this._scenarioAfterRedirects(event);

                    this._getNotificationCounts(event.url);

                    this.onOrientationChange();

                    this.googleAnalyticsService.handleScreen(event.url);
                    this.googleAnalyticsService.handlePageViews(event.url);
                }
        });

        this.routeScenario();
        this.impersonationRouteScenario();

        this.user_service.userAuthEvent$.subscribe((user: UserSessionInterface) => {
            if (user) this.unsupportedAccount();
        });

        this.user_service.onLoginGuestChanges.subscribe((user: UserModel) => {
            this.unsupported_device_service.close();
        });

        this.payments_service.onShowModalUnsupportedAccountChanges().subscribe((showModal: boolean) => {
            this.isUnsupportedAccount = showModal;
        });
        this.user_service.onLogoutUserChanges().subscribe((isLogout: boolean) => {
            this.isUnsupportedAccount = !isLogout;
        });
    }

    /**
     * If the admin chose impersonate from the list of users,
     * and then returned to the users-list page,
     * there should be a redirect to the settings page
     * where you can log out from the impersonate state
     */
    impersonationRouteScenario() {
        this.router.events
            .filter(e => e instanceof RoutesRecognized)
            .pairwise()
            .subscribe((event: any) => {

                // previous url events[0].url
                // current  url events[1].url
                 if (this.user_service.isImpersonation && event[1].url === '/users-list' && event[0].url !== '/users-list') {
                     this.router.navigate([createDirection(AppRouteValues.routeSettings)]);
                    return;
                }
        });
    }

    routeScenario() {
        this.router.events
            .filter(e => e instanceof RoutesRecognized)
            .pairwise()
            .subscribe((events: any) => {

                // previous url events[0].url
                // current  url events[1].url
                this.reviewUrlActionScenario(events[0].url, events[1].url);

            });
    }
    public reviewUrlActionScenario(previousUrl: string, currentUrl: string) {
        if (currentUrl === '/reviews') {
            if (previousUrl.includes('/product-details') || previousUrl === '/history' || previousUrl === createDirection(AppRouteValues.routeSettings)) {
                return;
            } else if (previousUrl.includes('reviews-item') || previousUrl.includes('create-review')) {
                this.reviews_service.selectPurchasesFilterTab();
            } else {
                this.reviews_service.backToHome();
            }
        }
    }

    public ngAfterViewInit(): void {
       this.afterBootService.handleResolver();

       setTimeout(() => this.onOrientationChange(), 1000);
    }

    /**
     * @desc System shall show badge on Shopping list page after
     * login for Buyer&Seller BiziBAZA account
     * if Stripe account is in NOT_CREATED or NOT_ONBOARDED state.
     * @private
     */
    public unsupportedAccount(): void {
        if ( !this.user_service.isGuestUser && this.user_service.isSellerMode()) {
            this.payments_service.unsupportedAccount();
        }

        if (this.isUnsupportedAccount) {
            this.unsupported_device_service.close();
        }
    }

    /**
     * @desc UIR_PROFILE1 System shall show badge on Profile and Inventory list pages
     * of Buyer&Seller BiziBAZA account if Stripe account
     * is in NOT_ABLE_TO_RECEIVE_TRANSFERS state.
     * @param {string} url
     */
    private checkStatusOfStripeAccount(url: string): void {
        if (this.checkStatusOfStripeAccountPaths.some(item => url.toLowerCase().includes(item) && this.user_service.isSellerMode())) {
            this.payments_service.checkStatusOfStripeAccount();
        }
    }

    public ngOnDestroy(): void {
       if (this.subscriptions$.length > 0) {
          this.subscriptions$.forEach((subscription: Subscription) => {
             subscription.unsubscribe();
          });
       }
    }

    _getNotificationCounts(url?: string): void {
        let hideCartForHeader: boolean;
        url && (hideCartForHeader = this.noPathsForCart.some(item => url.toLowerCase().includes(item)));

        if (hideCartForHeader || !url) {
            this.cart_icon = false;
        } else {
            this.cart_icon = true;
            // @ts-ignore
            this.app_footer_service.getCounts()
                .subscribe((counts: GetCounterResponse) => {
                    this.counts = counts;
                }, (err: ErrorInterface) => Observable.throwError(err));
        }
    }

    _scenarioAfterRedirects(event: any) {
        this.checkRoute(event.url, event.urlAfterRedirects);

        // if (this.routesExcludedFromCaching.some(item => event.url.toLowerCase().includes(item))) {
        //     this.localCaching.clearAllCache(new ConcreteSearchCaching());
        // }

        if (event.url === "/shopping-list" && this.redirectService.link && this.redirectService.link !== '') {
            this.redirectService.dynamicLinktoProduct();

            const page = this.redirectService.link.split('/')[1];
            this.checkRoute(this.redirectService.link, page);

            this.redirectService.link = '';

            return;
        }
        this.app_header__width = (event.url === "/inventory-sheet") ? "app_header__width_full" : "";
    }

    /***
     * @desc This function creates 'user service' property on your service.
     * @return {UserService}
     */
    public get user_service(): UserService {
        return this.injector.get(UserService);
    }

    public get tab_service(): CheckTabStatusService {
        return this.injector.get(CheckTabStatusService);
    }

    public get app_footer_service(): AppFooterService {
        return this.injector.get(AppFooterService);
    }
    public get geo_location_service(): GeoLocationService {
        return this.injector.get(GeoLocationService);
    }

    public get unsupported_device_service(): UnsupportedDeviceService {
        return this.injector.get(UnsupportedDeviceService);
    }

    public get reviews_service(): AppReviewsService {
        return this.injector.get(AppReviewsService);
    }

    public get payments_service(): ClientPaymentService {
        return this.injector.get(ClientPaymentService);
    }

    isBizipixUrl(): boolean {
        return (this.router.url === '/bizipix-editor' || this.router.url === '/bizipix-viewer');
    }
    _checkUserStatus() {
        if ( this.redirectService.impersonation_user ) {
            let impersonation_exist = (Object.keys(this.redirectService.impersonation_user).length !== 0);
            let impersonation = (Object.keys(this.redirectService.impersonation).length !== 0);

            if (impersonation_exist && impersonation) {
                this.userNameImpersonation = this.redirectService.impersonation_user.firstName;
                this.impersonation = true;

            } else this.impersonation = false;
        }
    }

    _setGuestInfo(url?: string) {
        if (url && this.noHeaderGuestPaths.some(
            item => url.toLowerCase().includes(item)
        )) {
            this.userName = undefined;
            this.type = undefined;
            this.order_icon = false;
            return;
        }

        this.setUserInfo(this.user_service.getUser());
    }

    checkRoute(url: string, urlAfterRedirects: string) {
        if (this.redirectService.link && this.redirectService.link !== '' && url.split('/')[1] === 'product-details') {
            url = url.split('/')[1];
            url = '/' + url;
        }
        this.hideFooter = this.noFooterPaths.some(item => url.toLowerCase().includes(item));
    }

    private subscribeOnSuggestionsSearchActivating() {
        this.subscriptions$.push(
           this.shoppingListService.suggestionsSearchActivating
              .subscribe((value: boolean) => {
                  this.isSearchActivated = value;
              })
        );
    }

    private subscribeOnUserChanges(): void {
        this.setUserInfo(this.user_service.getUser());

        this.subscriptions$.push(
           this.user_service.onUserChanges
              .subscribe((user: UserModel) => {
                 this.setUserInfo(user);
              })
        );
    }

    private setUserInfo(user: UserModel): void {
        if (!user.ID || user.accessLevel === 0) {
            this.userName = undefined;
            this.type = 'guest';
            this.order_icon = false;
            return;
        }

        this.userName = user.firstName;
        this.type = 'user';
        this.order_icon = true;
    }
}
