/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shopping-list.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "angular-sortablejs/dist/src/sortablejs.directive";
import * as i4 from "angular-sortablejs/dist/src/globals";
import * as i5 from "angular-sortablejs/dist/src/sortablejs.service";
import * as i6 from "./sub-list.component";
import * as i7 from "../search/search.service";
import * as i8 from "./shopping-list.service";
var styles_SubListComponent = [i0.styles];
var RenderType_SubListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubListComponent, data: {} });
export { RenderType_SubListComponent as RenderType_SubListComponent };
function View_SubListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "list__column item-title"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((("shoppingListProductName_" + _co.index) + "_") + _v.parent.context.index); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.descriptor; _ck(_v, 1, 0, currVal_1); }); }
function View_SubListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "list__column item-title custom-item"], ["type", "button"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startRenaming(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((("shoppingListItemTitle_" + _co.index) + "_") + _v.parent.context.index); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.name; _ck(_v, 1, 0, currVal_1); }); }
function View_SubListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "icon-ok"]], null, null, null, null, null))], null, null); }
function View_SubListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "icon-plus"]], null, null, null, null, null))], null, null); }
function View_SubListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "li", [], [[8, "className", 0], [1, "data-test-id", 0], [4, "cursor", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((!_v.context.$implicit.is_custom && _co.showActiveItems(_v.context.$implicit)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "handle"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubListComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubListComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "item__control"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["type", "button"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleItemStatus(_v.context.$implicit, _co.list, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubListComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubListComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["type", "button"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeItem(_v.context.$implicit, _co.list, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "icon-trash-empty"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = !_v.context.$implicit.is_custom; _ck(_v, 4, 0, currVal_4); var currVal_5 = _v.context.$implicit.is_custom; _ck(_v, 6, 0, currVal_5); var currVal_7 = (_co.className === "shopping-list__custom"); _ck(_v, 10, 0, currVal_7); var currVal_8 = (_co.className === "shopping-list__custom inactive"); _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.is_custom ? "customItem item" : "existingItem item"); var currVal_1 = ((("shoppingListProduct_" + _co.index) + "_") + _v.context.index); var currVal_2 = "default"; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = ((("sortableHandle_" + _co.index) + "_") + _v.context.index); _ck(_v, 1, 0, currVal_3); var currVal_6 = ((("statusBtn_" + _co.index) + "_") + _v.context.index); _ck(_v, 8, 0, currVal_6); var currVal_9 = ((("removeBtn_" + _co.index) + "_") + _v.context.index); _ck(_v, 13, 0, currVal_9); }); }
export function View_SubListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [["class", "shopping-list__ul"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(2, 737280, null, 0, i3.SortablejsDirective, [[2, i4.GLOBALS], i5.SortablejsService, i1.ElementRef, i1.NgZone, i1.Renderer2], { sortablejs: [0, "sortablejs"], inputOptions: [1, "inputOptions"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubListComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "shopping-list__ul"; var currVal_1 = _co.className; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.list; var currVal_3 = _co.dragAndDropEventOptions; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.list; var currVal_5 = _co.trackByID; _ck(_v, 4, 0, currVal_4, currVal_5); }, null); }
export function View_SubListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sub-list", [], null, null, null, View_SubListComponent_0, RenderType_SubListComponent)), i1.ɵdid(1, 49152, null, 0, i6.SubListComponent, [i7.AppSearchService, i8.ShoppingListService], null, null)], null, null); }
var SubListComponentNgFactory = i1.ɵccf("sub-list", i6.SubListComponent, View_SubListComponent_Host_0, { className: "className", list: "list", zone: "zone", index: "index" }, { itemUpdateEvent$: "itemUpdateEvent$", listUpdateEvent$: "listUpdateEvent$", startRenaming$: "startRenaming$" }, []);
export { SubListComponentNgFactory as SubListComponentNgFactory };
