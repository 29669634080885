var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/switchMap';
import { CartService } from '../cart/cart.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { ProductDetailsService } from './product-details.service';
import { ShopperDetailsService } from '../shopper-details/shopper-details.service';
import { AppReviewsService } from '../reviews/reviews.service';
import { UserService } from '../user/user.service';
import { ModalService } from '../modal/modal.service';
import { Observable, Subject } from 'rxjs/Rx';
import { ErrorService } from '../services/error.service';
import AppValues from '../common/app.values';
import { TranslateService } from '@ngx-translate/core';
import { AppRouteValues, createDirection } from "../common/app.route.values";
import { CartAbstractClass } from '../cart/cart-abstract-class';
var ProductDetailsComponent = /** @class */ (function (_super) {
    __extends(ProductDetailsComponent, _super);
    function ProductDetailsComponent(router, reviewsService, shopperDetailsService, elementRef, cartService, googleAnalyticsService, productDetailsService, userService, modalService, errorService, translate) {
        var _this = _super.call(this, elementRef, cartService, googleAnalyticsService, productDetailsService, userService, modalService, errorService, translate) || this;
        _this.router = router;
        _this.reviewsService = reviewsService;
        _this.shopperDetailsService = shopperDetailsService;
        _this.cartItemID = '';
        _this.HUNDRED = AppValues.HUNDRED;
        _this.isMarketAllowed = false;
        _this.isInReportList = false;
        _this.reviewsTotal = 0;
        _this.formatNumericDate = AppValues.allNumericDatePattern;
        _this.isLocationBack = false;
        return _this;
    }
    /**
     * @desc Fetches item data and calls further methods.
     */
    ProductDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.item = this.productDetailsService.getItem();
        if (this.item) {
            this._preparingToViewComponent();
        }
        else
            this.productDetailsService.getProductEvent$.subscribe(function (product) {
                if (product) {
                    _this.item = product;
                    _this._preparingToViewComponent();
                }
            });
        this.buyNowHandler();
        this.trackSubscription(this.cartService.onSelectNextView.subscribe(function (route) {
            return setTimeout(function () {
                if (_this.userService.isImpersonation) {
                    _this.modalService.warning({
                        title: _this.translate.instant("modal.warning.title"),
                        message: _this.translate.instant("common.impersonated.pay"),
                        yesButtonText: _this.translate.instant("common.cancel"),
                    });
                }
                else {
                    _this.router.navigate([createDirection(_this.routePay ? _this.routePay : route)]);
                }
            });
        }));
        this.subscribeOnChangeCartConfirmationResponse();
    };
    ProductDetailsComponent.prototype.handleQuantityChange = function (value) {
        this.desiredQuantity[this.item.ID] = value;
    };
    ProductDetailsComponent.prototype.subscribeOnChangeCartConfirmationResponse = function () {
        var _this = this;
        this.trackSubscription(this.cartService.onChangeCartConfirmationResponse.subscribe(function () {
            if (_this.isBuyNow) {
                _this.switchPayBlock();
            }
        }));
    };
    ProductDetailsComponent.prototype.buyNowHandler = function () {
        var _this = this;
        this.trackSubscription(this.onSelectBuyNow.subscribe(function (isBuyNow) {
            _this.isBuyNow = isBuyNow;
            if (!isBuyNow || (isBuyNow && !_this.cartService.isEmptyCart)) {
                _this.routePay = AppRouteValues.routeCart;
                return;
            }
            _this.routePay = AppRouteValues.routePayment;
        }));
    };
    ProductDetailsComponent.prototype.searchFor = function (event, sellerId, sellerName) {
        event.stopPropagation();
        this.shopperDetailsService.searchFor(sellerId, sellerName);
    };
    ProductDetailsComponent.prototype._instantBuyHandler = function () {
        if (this.options && this.options.isInstantBuy) {
            this.addToCartHandler(this.item, true);
            this.productDetailsService.setProductOptions({ isInstantBuy: false });
        }
    };
    ProductDetailsComponent.prototype._preparingToViewComponent = function () {
        var _this = this;
        this.market = this.isMarketList();
        this.options = this.productDetailsService.productOptions;
        var user = this.userService.getUser();
        this.userID = user.ID;
        this._setPriceAlertValue();
        this._getCart().subscribe(function () {
            if (!_this.userService.isGuestUser) {
                _this._instantBuyHandler();
                _this._setListStatus();
            }
            ;
        });
        this.googleAnalyticsService.handleVirtualPage('product-details');
        this.googleAnalyticsService.handleVirtualPage('product-details_from_' + this.productDetailsService.getProductDetailsFrom());
        this.reviewsService.getReviewsTotal(this.item.ID).subscribe(function (reviews) { return _this.reviewsTotal = reviews; });
    };
    ProductDetailsComponent.prototype.isMarketList = function () {
        return Boolean((this.item.events && this.item.events.length) || this.item['market']);
    };
    /**
     * @desc Injects additional keys to Item object (locally) to mark their status
     * towards Favorite and Watch lists.
     * @private
     */
    ProductDetailsComponent.prototype._setListStatus = function () {
        var _this = this;
        var user = this.userService.getUser();
        this.trackSubscription(this.productDetailsService.isInReportList(this.item.ID)
            .subscribe(function (isInList) { return _this.isInReportList = isInList; }));
        this.item.inFavList = user.favList.items.indexOf(this.item.ID) >= 0;
        this.checkItemInWatchList();
    };
    ProductDetailsComponent.prototype.checkItemInWatchList = function (isVisibleSpinner) {
        var _this = this;
        if (isVisibleSpinner === void 0) { isVisibleSpinner = false; }
        this.trackSubscription(this.productDetailsService.isInWatchList(this.item.ID, isVisibleSpinner)
            .subscribe(function (isInList) { return _this.item.inWatchList = isInList; }));
    };
    ProductDetailsComponent.prototype.switchPayBlock = function () {
        this.modalService.close();
        this.setRouteAfterAddingProduct();
    };
    /**
     * @desc Fetches priceAlert object. If it is consistent, sets priceAlertValue and
     * isPriceAlertOn; if not - makes them clear and false accordingly.
     * @private
     */
    ProductDetailsComponent.prototype._setPriceAlertValue = function () {
        this.priceAlert = this.productDetailsService.getPriceAlert();
        var pa = this.priceAlert;
        this.priceAlertValue = (pa && pa.price && pa.price > 0) ? (pa.price / this.HUNDRED).toFixed(2) : '';
        this.isPriceAlertOn = this.productDetailsService.isPriceAlertOn(this.priceAlert);
    };
    /**
     * @desc Fetches shopping cart data and sets class fields on response.
     * @private
     */
    ProductDetailsComponent.prototype._getCart = function () {
        var _this = this;
        var response$ = new Subject();
        this.trackSubscription(this.cartService.getCart()
            .subscribe(function (cart) {
            _this.cartItemID = _this.cartService.getItemID(_this.item.ID);
            _this.isMarketAllowed = true;
            response$.next();
        }, function (err) {
            response$.next();
            _this.errorService.handleError(err);
        }));
        return response$.asObservable();
    };
    /**
     * @desc Sets isPriceAlertOn flag.
     * @param state
     * @private
     */
    ProductDetailsComponent.prototype.togglePriceAlert = function (state) {
        var _this = this;
        this.isPriceAlertOn = state;
        if (state && this.userService.isGuestMode) {
            this.isPriceAlertOn = false;
            this._resetAlertValue();
            this.changeSwitchValue(false);
        }
        if (!state) {
            this.trackSubscription(
            // @ts-ignore
            this.productDetailsService.setPriceAlert(null)
                .subscribe(function () { return _this._resetAlertValue(); }, function (err) { return null; }));
        }
    };
    ProductDetailsComponent.prototype.changeSwitchValue = function (checked) {
        var element = document.querySelectorAll('[data-test-id="priceAlertSwitch"] [data-test-id="detailSwitchAlert"]')[0];
        element['checked'] = checked;
    };
    /**
     * @desc If the value equals current priceAlert value, returns.
     * If not - calls #setPriceAlert of the Service.
     * @param {number} value
     * @param {string} eventType
     */
    ProductDetailsComponent.prototype.setPriceAlert = function (value, eventType) {
        var _this = this;
        if (!this.canShowAlert(eventType)) {
            return;
        }
        var price = parseInt((value * this.HUNDRED).toFixed(0), 0);
        this.modalService.showSpinner();
        this.trackSubscription(
        // @ts-ignore
        this.productDetailsService.setPriceAlert(price)
            .subscribe(function (res) {
            !_this.userService.isGuestUser && _this.checkItemInWatchList(false);
            _this.priceAlert.price = value * _this.HUNDRED;
            _this.priceAlertValue = (_this.priceAlert.price / _this.HUNDRED).toFixed(2);
            _this.modalService.close();
        }, function (err) {
            // The error has already been processed in WatchListService
            // and errorService.handleError(err) and the modal window was shown
            // So modalService.close() method not needed here.
            if (err.status === 401) {
                _this.isPriceAlertOn = false;
            }
            _this._resetAlertValue();
        }));
    };
    /**
     * @desc Depending on user action determines whether the function is invoked before
     * an active dialogue has been closed and sets isDialogueActive flag accordingly.
     * @param {string} eventType
     * @return {boolean}
     */
    ProductDetailsComponent.prototype.canShowAlert = function (eventType) {
        if (eventType === 'keyup' && !this.isDialogueActive) {
            this.isDialogueActive = true;
            return true;
        }
        else if (eventType === 'keyup' && this.isDialogueActive) {
            return false;
        }
        else if (eventType === 'blur' && this.isDialogueActive) {
            this.isDialogueActive = false;
            return false;
        }
        else {
            return true;
        }
    };
    /**
     * @desc reset alert qty
     * @private
     */
    ProductDetailsComponent.prototype._resetAlertValue = function () {
        !this.userService.isGuestUser && this.checkItemInWatchList();
        this.priceAlertValue = '';
        this.elementRef.nativeElement.querySelector('.details__alert__qty').value = '';
    };
    /**
     * @desc If url param is non-empty string, calls self-titled service method.
     * @param url
     */
    ProductDetailsComponent.prototype.showCertificate = function (url) {
        if (url && (typeof url === 'string'))
            this.productDetailsService.showCertificate(url);
    };
    ProductDetailsComponent.prototype.getObjModalDeliveryMethod = function (item) {
        if (item.deliveryOffered && item.marketPickOffered) {
            return {
                title: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.title'),
                message: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.message'),
                yesButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.delivery'),
                noButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.pickUp'),
                lastButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.cancel'),
            };
        }
        else {
            var objModal = {
                title: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.title'),
                yesButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.pay'),
                noButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.cancel'),
            };
            if (item.deliveryOffered) {
                objModal.message = this.translate.instant('product.buyNow.modalSelectDeliveryMethod.delivery.message');
            }
            if (item.marketPickOffered) {
                objModal.message = this.translate.instant('product.buyNow.modalSelectDeliveryMethod.pickUp.message');
            }
            return objModal;
        }
    };
    /**
     * @desc go to Review view for one product
     * @param {string} ID
     * @param {string} title
     * @param {string} url
     */
    ProductDetailsComponent.prototype.showReviews = function (ID, title, url) {
        if (!ID)
            return;
        this.trackSubscription(this.reviewsService.showReviewList({ ID: ID, title: title, url: url }, false)
            .subscribe(function () { return null; }, function (err) { return Observable.throw(err); }));
    };
    /**
     * @desc go to Seller Details view
     * @param {string} id
     */
    ProductDetailsComponent.prototype.showShopper = function (id) {
        if (!id)
            return;
        this.shopperDetailsService.showDetails(id);
    };
    /**
     * @desc Calls CartService and not redirects back on response.
     */
    ProductDetailsComponent.prototype.removeFromCart = function (event, item) {
        var _this = this;
        this.trackSubscription(this.cartService.removeItem(this.cartService.getItemID(item.ID), false)
            .subscribe(function (result) {
            _this.googleAnalyticsService.handleRemoveFromCart(item.ID);
            _this.resetBusyFlag(true);
            _this.changeCounts();
        }, function (err) { return _this.resetBusyFlag(true); }));
        this.resetBusyFlag(true);
    };
    /**
     * @desc Resets busy flag and throws to the console.
     * @param {boolean} isNotInCart
     */
    ProductDetailsComponent.prototype.resetBusyFlag = function (isNotInCart) {
        if (isNotInCart === void 0) { isNotInCart = false; }
        this.busyCart = false;
        this.cartItemID = isNotInCart ? '' : this.item.ID;
    };
    /**
     * @desc Toggles inWatchList status.
     */
    ProductDetailsComponent.prototype.toggleWatchListStatus = function (item) {
        if (item.inWatchList && this.isPriceAlertOn) {
            this.removingItemFromWatchList(item);
        }
        else {
            this.toggleWatchList(item);
        }
    };
    /**
     * @desc Toggles inFavList status.
     */
    ProductDetailsComponent.prototype.toggleFavListStatus = function () {
        var _this = this;
        this.trackSubscription(this.productDetailsService.toggleFavListStatus()
            .subscribe(function () {
            _this.item.inFavList = !_this.item.inFavList;
            _this.item.inFavList && _this.googleAnalyticsService.handleAddToWishlist(_this.item);
        }, function (err) {
            // The error has already been processed in FavoriteListService
            // and errorService.handleError(err) and the modal window was shown.
            return err;
        }));
    };
    ProductDetailsComponent.prototype.setRouteAfterAddingProduct = function () {
        this.cartService.selectNextViewSubject.next(this.routePay);
    };
    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {Event} item
     * @return {string}
     */
    ProductDetailsComponent.prototype.trackByID = function (index, item) {
        return item.market_id;
    };
    return ProductDetailsComponent;
}(CartAbstractClass));
export { ProductDetailsComponent };
