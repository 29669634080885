import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs/Observable';
import { Router }               from '@angular/router';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';

import { DataService }          from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import { ImageUploadService }   from '../services/image-upload.service';
import { ModalService }         from '../modal/modal.service';
import { RedirectService }      from '../services/redirect.service';
import {
    UserModel,
    AddressInterface,
    UserSessionInterface}
    from '../interfaces/user.interface';
import { UserService }          from '../user/user.service';
import {userNotNullFields} from '../user/helpers/_helpers';
import {CenterLocationInterface} from '../interfaces/location.interface';
import {GeoLocationService} from '../services/geolocation.service';
import {GeoCoderService, ReverseGeocodingAddressInterface} from '../services/geocoder.service';
import AppValues from '../common/app.values';
import {Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { getErrorType } from '../services/error.helpers';

@Injectable()
export class ProfileService {
    /**
     * Profile Service class.
     * @decs Serves all the manipulations to get and update profile.
     */
    public location:                Location;

    private addr:           AddressInterface;
    private index:          number = 0;
    private profile:        UserModel;
    private userSession:    UserSessionInterface;
    private geolocation:    CenterLocationInterface = {
        longitude: 0,
        latitude: 0
    };


    constructor(
        private dataService:                DataService,
        private errorService:               ErrorService,
        private imageUploadService:         ImageUploadService,
        private modalService:               ModalService,
        private redirectService:            RedirectService,
        private router:                     Router,
        private userService:                UserService,
        private geoLocationService:         GeoLocationService,
        private translate:                  TranslateService,
        location: Location
    ) {
        this.location = location;
        this.setGeolocation();
    }

    setGeolocation() {
        if (this.geoLocationService.location) {
            this.geolocation = this.geoLocationService.location;
        } else {
            if (this.profile) {
                this.geolocation = {
                    longitude: this.profile.longitude || 0,
                    latitude: this.profile.latitude || 0
                };
            }
        }
    }

    /**
     * @desc Fetches profile data via DataService. Caches profile data into memory.
     * @returns Observable type of profile data|empty object depending on user token availability.
     */
    public getProfile(): Observable<UserModel | {}> {
        this.userSession = this.userService.getUserSession();
        let token = this.userSession.token;

        if (token) {
            let body = {
                'users_ids': [this.userSession.ID]
            };

            return this.dataService.postData('user_by_ids', body, {token})
                .map((res: any|{}) => {
                    this.profile = res.users[0];
                    this._sortAddresses();
                    return this.profile;
                })
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({});
    }

    public get geoLocation(): CenterLocationInterface {
        return this.geoLocationService.location;
    }

    public get userProfile(): UserModel {
        return this.profile;
    }



    /**
     * Redirects to Address Editor Component.
     * @param addr Address object from addresses array.
     * @param index of the Address object in the addresses array.
     */
    public onAddressEdit(addr, index): void {
        if (!addr) {
            addr = this.emptyAddress;
            addr.firstName = this.profile.firstName;
            addr.lastName = this.profile.lastName;
            addr.country = AppValues.countries[0];
        }
        this.addr   = addr;
        this.index  = index;

        this.router.navigate(['/addr-editor']);
    }

    public get emptyAddress(): AddressInterface {
        return {
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postCode: '',
            phone: '',
            country: '',
            isPrimary: false
        };
    }


    /**
     * @desc Returns Address object and the uniqueness boolean flag.
     * @returns {{addr: AddressInterface, isOnly: boolean}}
     */
    public get getAddress(): {addr: AddressInterface, isOnly: boolean } {
        return {
            addr:   this.addr,
            isOnly: this.profile.addresses.length === 0
        };
    }

     set profileLocation(location: CenterLocationInterface) {
        this.geolocation = location;
    }

    /**
     * @desc Delegates image uploading to ImageUploadService. Processes spinner and error handling.
     * @param img
     * @returns {Observable<string>}
     */
    public uploadImg(img: Blob): Observable<string> {
        this.modalService.showSpinner();

        return this.imageUploadService.uploadImg(img, this.userSession.token)
            .do(() => this.modalService.close())

            .catch((err: ErrorInterface|any) => {
                this.errorService.handleError(err);
                return Observable.throwError(getErrorType(err));
            });
    }


    /**
     * @desc Decorator for #_save method. Additionally shows spinner.
     * @param profile
     * @returns {Observable<UserModel>}
     */
    public saveProfile(profile: UserModel, isMainProfileInfo: boolean = false): Observable<UserModel | {}> {
        this.modalService.showSpinner();
        return this._save(profile, isMainProfileInfo);
    }


    /**
     * @desc Updates existingItem Address object, pushes newly created Address object into Arrdesses array.
     * Resets 'isPrimary' if updated|created has it true.
     * @param {AddressInterface} addr
     * @param {CenterLocationInterface} coordFromAddr
     * @return {Subscription}
     */
    public submitAddress(addr: AddressInterface, coordFromAddr?: CenterLocationInterface): void {
        let profile         = this.profile;

        if ((this.index === -1 && addr.isPrimary === true) ||
            (this.profile.addresses[this.index] && this.profile.addresses[this.index].isPrimary === true )) {
            profile = this.setCoordinatesToProfile(profile, coordFromAddr);
        }

        this._sortAddresses();

        this._save(profile).subscribe((res: UserModel | {}) => null, (err: string) => {
            this.modalService.error({title: 'Error:', message: err, yesButtonText:  'Close'});
        });
    }

    private setCoordinatesToProfile(profile: UserModel, coordFromAddr?: CenterLocationInterface): UserModel {
        if (coordFromAddr) {
            profile = this.changeProfileCoordinates(profile, coordFromAddr);
        } else if (this.geolocation) {
            profile = this.changeProfileCoordinates(profile, this.geolocation);
        }
        return profile;
    }


    public changeProfile(address: AddressInterface, isPrimary: boolean): void {
        const newprofile = AppValues.deepCopy(this.profile);
        if (isPrimary && newprofile.addresses.length !== 0) {
            newprofile.addresses.forEach((a: AddressInterface) => a.isPrimary = false);
        }
        if (typeof this.index !== 'undefined' && ~this.index) {
            newprofile.addresses[this.index] = address;

        } else {
            newprofile.addresses.push(address);
        }

        this.profile = newprofile;
    }

    changeProfileCoordinates(profile: any, location: CenterLocationInterface) {
        profile['loc'].coordinates[0] = location.latitude;
        profile['loc'].coordinates[1] = location.longitude;

        profile.longitude = location.longitude;
        profile.latitude = location.latitude;


        return profile;
    }


    /**
     * Sorts Addresses array so that the primary address appears the first.
     * @private
     */
    private _sortAddresses(): void {
        this.profile.addresses.sort((a: AddressInterface, b: AddressInterface) => {
            return (a.isPrimary === b.isPrimary) ? 0 : a.isPrimary ? -1 : 1;
        });
    }


    /**
     * Saves profile, closes spinner and redirects back to the Profile page.
     * @private
     * @returns {Observable<UserModel | {}>}
     * @param {UserModel} profile
     * @param {boolean} isMainProfileInfo
     */
    private _save(profile: UserModel, isMainProfileInfo?: boolean): Observable<UserModel | {}> {
        let token = this.userSession.token;
        if (!token) return Observable.of({});

        profile = userNotNullFields(profile);

        return this.dataService.postData('update_user', profile, {token})
            .map((res: UserModel) => {
                this.profile = res;
                this.userService.updateUser(res);

                this.modalService.close();

                if (!isMainProfileInfo) {
                    this.location.back();
                }

                return this.profile;
            })
            .catch((err: ErrorInterface) => {
                return this.errorService.handleError(err);
            });
    }



    public handleEmailUpdateErr(error: string): void {
        this.modalService
            .error({
                title: this.translate.instant('profile.editor.email.warning.title'),
                message: error,
                yesButtonText: this.translate.instant('profile.editor.email.warning.confirm'),
            });
    }

}
