/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./inventory-header.component";
import * as i4 from "../bizipix-editor/bizipix-editor.service";
import * as i5 from "../modal/modal.service";
var styles_InventoryHeaderComponent = [i0.styles];
var RenderType_InventoryHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InventoryHeaderComponent, data: {} });
export { RenderType_InventoryHeaderComponent as RenderType_InventoryHeaderComponent };
export function View_InventoryHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "nav", [["class", "app-header__container"], ["role", "inventory-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["data-test-id", "settingsLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "settings"], ["height", "25"], ["width", "25"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "navbar__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "navbar__right inventory-list__menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "button", [["class", "nav__link sprite sprite-sheet_icon"], ["data-test-id", "sheetEditorLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToSheetEditor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "nav__link"], ["data-test-id", "biziPixLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showBizipixEditor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "BiziPix"], ["height", "35"], ["width", "40"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "/settings"; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = "../../assets/images/svg_icons/settings_icon.svg"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "../../assets/images/svg_icons/shopix-active.svg"; _ck(_v, 10, 0, currVal_2); }); }
export function View_InventoryHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "inventory-header", [], null, null, null, View_InventoryHeaderComponent_0, RenderType_InventoryHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.InventoryHeaderComponent, [i4.BizipixEditorService, i5.ModalService, i2.Router], null, null)], null, null); }
var InventoryHeaderComponentNgFactory = i1.ɵccf("inventory-header", i3.InventoryHeaderComponent, View_InventoryHeaderComponent_Host_0, {}, {}, []);
export { InventoryHeaderComponentNgFactory as InventoryHeaderComponentNgFactory };
