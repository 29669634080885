import {Component, EventEmitter, Output} from '@angular/core';

import {Router} from '@angular/router';
import {AppSearchService} from '../search/search.service';
import {LocalCaching, LocalCachingCreatorMapping} from '../local-caching/local-caching-factory';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector:   'shopping-header',
    styleUrls:  ['../../assets/styles/app-header.sass'],
    template:   `
        <header class="app-header">
            <div class="shopping-header__block">
                <nav class="app-header__container" role="shopping">
                    <div class="navbar__left">
                        <button [routerLink]="'/settings'" data-test-id="settingsLink" type="button">
                            <img width="25" height="25" [src]="'../../assets/images/svg_icons/settings_icon.svg'" alt="settings">
                        </button>
                    </div>
                    <div class="navbar__center">
                        <h4>{{ "shoppingList.title" | translate }}</h4>
                    </div>
                    <div class="navbar__right">
                        <button type="button" (click)="sortShoppingList()" class="sortShoppingList" data-test-id="sortShoppingList">
                            <i class="icon-sort-name-up"></i>
                        </button>

                        <button type="button" (click)="clearSearchResults()" data-test-id="searchLink">
                            <i class="icon-search_icon"></i>
                        </button>
                    </div>
                </nav>
                <carousel></carousel>
            </div>
        </header>
    `
})

export class ShoppingHeaderComponent {
    @Output() sortOrderByNameEvent$  = new EventEmitter<boolean>();

    constructor(
        private router:                 Router,
        private searchService:          AppSearchService,
        private localCaching:           LocalCaching,
        private translate:              TranslateService
    ) { }


    clearSearchResults() {
        this.localCaching.clearAllCache(LocalCachingCreatorMapping.ConcreteSearchCaching);
        this.searchService.clearResults();

        setTimeout(() => {
            this.searchService.is_key_search = true;
            this.router.navigate(['/search']);
        });
    }

    sortShoppingList() {
        this.sortOrderByNameEvent$.emit(true);
    }

}
