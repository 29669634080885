import { CommonModule }                     from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule }                         from '@angular/core';
import { RouterModule }                     from '@angular/router';


import { AddressEditorComponent }           from './addr-editor.component';
import { AddrEditorHeaderComponent }        from './addr-editor-header.component';
import { ProfileService }                   from './profile.service';
import { ProfileHeaderComponent }           from './profile-header.component';
import { ProfileComponent }                 from './profile.component';
import { SharedModule }                     from '../shared/shared.module';
import { AgmCoreModule }                    from '@agm/core';
import { GeoLocationService }               from '../services/geolocation.service';
import { environment }                      from '../../environments/environment';



@NgModule({
    imports: [
        RouterModule,
        SharedModule,

        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AgmCoreModule.forRoot({
            apiKey: environment.GM_API_KEY
        })
    ],
    declarations: [
        AddressEditorComponent,
        AddrEditorHeaderComponent,
        ProfileHeaderComponent,
        ProfileComponent,
    ],
    exports: [
        AddressEditorComponent,
        ProfileComponent
    ],
    providers:    [ ProfileService, GeoLocationService ]
})
export class ProfileModule {}
