var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var LocalCachingCreatorMapping;
(function (LocalCachingCreatorMapping) {
    LocalCachingCreatorMapping["ConcreteNavigationTreeCaching"] = "ConcreteNavigationTreeCaching";
    LocalCachingCreatorMapping["ConcreteShortNavigationTreeCaching"] = "ConcreteShortNavigationTreeCaching";
    LocalCachingCreatorMapping["ConcreteSearchCaching"] = "ConcreteSearchCaching";
    LocalCachingCreatorMapping["ConcreteSuggestionsCaching"] = "ConcreteSuggestionsCaching";
})(LocalCachingCreatorMapping || (LocalCachingCreatorMapping = {}));
var LocalCaching = /** @class */ (function () {
    function LocalCaching() {
        this.searchCache = [];
        this.suggestionCache = [];
        this.navigationTree = [];
        this.shortNavigationTree = [];
        this.mapping = {
            ConcreteSearchCaching: this.searchCache,
            ConcreteSuggestionsCaching: this.suggestionCache,
            ConcreteNavigationTreeCaching: this.navigationTree,
            ConcreteShortNavigationTreeCaching: this.shortNavigationTree,
        };
        this.creatorMapping = {
            ConcreteNavigationTreeCaching: new ConcreteNavigationTreeCaching(),
            ConcreteShortNavigationTreeCaching: new ConcreteShortNavigationTreeCaching(),
            ConcreteSearchCaching: new ConcreteSearchCaching(),
            ConcreteSuggestionsCaching: new ConcreteSuggestionsCaching()
        };
    }
    LocalCaching.prototype.isExistCache = function (creatorName) {
        var creator = this.creatorMapping[creatorName];
        var cache;
        cache = this.mapping[creatorName];
        return (cache.length !== 0 && typeof cache.find(function (c) { return window.location.href.toUpperCase() === c.url.toUpperCase(); }) !== 'undefined');
    };
    LocalCaching.prototype.setCache = function (creatorName, data, currentUrl) {
        var creator = this.creatorMapping[creatorName];
        var cached;
        cached = creator.setLocalCaching(data, this.mapping[creatorName], currentUrl);
        this.mapping[creatorName] = cached;
    };
    LocalCaching.prototype.getAllCache = function (creatorName) {
        var creator = this.creatorMapping[creatorName];
        return this.mapping[creatorName];
    };
    // tslint:disable-next-line: no-any
    LocalCaching.prototype.getOneCache = function (creatorName, defaultValue) {
        var creator = this.creatorMapping[creatorName];
        var cached;
        cached = this.mapping[creatorName];
        var cacheList = cached.filter(function (c) { return window.location.href.toUpperCase() === c.url.toUpperCase(); });
        if (cacheList.length) {
            return cacheList[0].data;
        }
        else {
            return defaultValue;
        }
    };
    LocalCaching.prototype.clearAllCache = function (creatorName) {
        var creator = this.creatorMapping[creatorName];
        this.mapping[creator.constructor.name] = [];
    };
    LocalCaching.prototype.clearOneCache = function (creatorName, url) {
        var creator = this.creatorMapping[creatorName];
        var cached = this.mapping[creator.constructor.name];
        this.mapping[creator.constructor.name] = cached.filter(function (c) { return url.toUpperCase() !== c.url.toUpperCase(); });
    };
    return LocalCaching;
}());
export { LocalCaching };
/**
 * The Creator class declares a factory method that should return an object LocalCacheInterface
 * Creator subclasses typically provide an implementation of this method.
 */
var CreatorLocalCaching = /** @class */ (function () {
    function CreatorLocalCaching() {
    }
    CreatorLocalCaching.prototype.setLocalCaching = function (data, cache, currentUrl) {
        var product = this.factoryMethod();
        return product.caching(data, cache, currentUrl);
    };
    return CreatorLocalCaching;
}());
export { CreatorLocalCaching };
var ConcreteSearchCaching = /** @class */ (function (_super) {
    __extends(ConcreteSearchCaching, _super);
    function ConcreteSearchCaching() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConcreteSearchCaching.prototype.factoryMethod = function () {
        return new SearchCaching();
    };
    return ConcreteSearchCaching;
}(CreatorLocalCaching));
export { ConcreteSearchCaching };
var ConcreteShortNavigationTreeCaching = /** @class */ (function (_super) {
    __extends(ConcreteShortNavigationTreeCaching, _super);
    function ConcreteShortNavigationTreeCaching() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConcreteShortNavigationTreeCaching.prototype.factoryMethod = function () {
        return new ShortNavigationTreeCaching();
    };
    return ConcreteShortNavigationTreeCaching;
}(CreatorLocalCaching));
export { ConcreteShortNavigationTreeCaching };
var ConcreteNavigationTreeCaching = /** @class */ (function (_super) {
    __extends(ConcreteNavigationTreeCaching, _super);
    function ConcreteNavigationTreeCaching() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConcreteNavigationTreeCaching.prototype.factoryMethod = function () {
        return new NavigationTreeCaching();
    };
    return ConcreteNavigationTreeCaching;
}(CreatorLocalCaching));
export { ConcreteNavigationTreeCaching };
var ConcreteSuggestionsCaching = /** @class */ (function (_super) {
    __extends(ConcreteSuggestionsCaching, _super);
    function ConcreteSuggestionsCaching() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConcreteSuggestionsCaching.prototype.factoryMethod = function () {
        return new SuggestionsCaching();
    };
    return ConcreteSuggestionsCaching;
}(CreatorLocalCaching));
export { ConcreteSuggestionsCaching };
var ShortNavigationTreeCaching = /** @class */ (function () {
    function ShortNavigationTreeCaching() {
    }
    ShortNavigationTreeCaching.prototype.caching = function (data, cache, currentUrl) {
        var url = currentUrl || window.location.href;
        var field;
        if (typeof cache === 'undefined') {
            cache = [];
        }
        if (url) {
            field = { url: url, data: data };
            cache.unshift(field);
        }
        return cache;
    };
    return ShortNavigationTreeCaching;
}());
var NavigationTreeCaching = /** @class */ (function () {
    function NavigationTreeCaching() {
    }
    NavigationTreeCaching.prototype.caching = function (data, cache, currentUrl) {
        var url = currentUrl || window.location.href;
        var field;
        if (typeof cache === 'undefined') {
            cache = [];
        }
        if (url) {
            field = { url: url, data: data };
            cache.unshift(field);
        }
        return cache;
    };
    return NavigationTreeCaching;
}());
var SuggestionsCaching = /** @class */ (function () {
    function SuggestionsCaching() {
    }
    SuggestionsCaching.prototype.caching = function (data, cache, currentUrl) {
        var url = currentUrl || window.location.href;
        var field;
        if (typeof cache === 'undefined') {
            cache = [];
        }
        if (url) {
            field = { url: url, data: data };
            cache.unshift(field);
        }
        return cache;
    };
    return SuggestionsCaching;
}());
var SearchCaching = /** @class */ (function () {
    function SearchCaching() {
    }
    SearchCaching.prototype.caching = function (data, cache, currentUrl) {
        var url = currentUrl || window.location.href;
        if (typeof cache === 'undefined') {
            cache = [];
            var field = { url: url, data: data };
            cache.unshift(field);
            return cache;
        }
        // let fieldIndex = cache.findIndex((c: CacheInterface) => c.url.toUpperCase() === url.toUpperCase());
        if (typeof cache !== 'undefined') {
            var field = { url: url, data: data };
            cache.unshift(field);
        }
        return cache;
    };
    return SearchCaching;
}());
