var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { EventEmitter, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { Subject } from 'rxjs';
import { CookieService } from '../services/cookie.service';
import { DataService } from '../services/data.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { ModalService } from '../modal/modal.service';
import { ErrorService } from '../services/error.service';
import { Address } from '../../../swagger-gen__output_dir';
import AppValues from '../common/app.values';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '../cart/cart.service';
import { getErrorType } from '../services/error.helpers';
var UserService = /** @class */ (function () {
    function UserService(cookieService, dataService, errorService, googleAnalyticsService, modalService, translate, injector) {
        this.cookieService = cookieService;
        this.dataService = dataService;
        this.errorService = errorService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.modalService = modalService;
        this.translate = translate;
        this.injector = injector;
        // tslint:disable-next-line:member-ordering
        this.user = {};
        // tslint:disable-next-line:member-ordering
        this.adminUser = {};
        this.userChanges$ = new Subject();
        this.loginGuestAutoActionSubject = new Subject();
        this.onLogoutUserSubject = new Subject();
        this.resetAbandonedItemsSubject = new Subject();
        // tslint:disable-next-line:member-ordering
        this.impersonation = {};
        this.userSession = {};
        this.userAuthEvent$ = new EventEmitter();
    }
    Object.defineProperty(UserService.prototype, "isGuestMode", {
        /**
         * @desc Checks user mode and if it's 'guest' - show warning modal
         * @return {boolean}
         */
        get: function () {
            var userMode = this.isGuestUser;
            userMode && this.errorService.handleError({
                status: 401,
                statusText: this.translate.instant('unauthorized.message.body')
            });
            return userMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "isGuestUser", {
        get: function () {
            return this.user.accessLevel === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "loginName", {
        get: function () {
            return this.isGuestUser ? '' : this.getUser().login;
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype.isSellerMode = function () {
        return this.user.accessLevel > 1;
    };
    UserService.prototype.isAdminMode = function () {
        return this.user.accessLevel === 3;
    };
    Object.defineProperty(UserService.prototype, "onUserChanges", {
        get: function () {
            return this.userChanges$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "onLoginGuestChanges", {
        get: function () {
            return this.loginGuestAutoActionSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype.onLogoutUserChanges = function () {
        return this.onLogoutUserSubject.asObservable();
    };
    UserService.prototype.onResetAbandonedItemsSubjectChanges = function () {
        return this.resetAbandonedItemsSubject.asObservable();
    };
    UserService.prototype.login_guest = function (loginFromNotStartApp) {
        var _this = this;
        return this.dataService.postData('login_guest', {}).map(function (res) {
            _this.setCookies('guest', res.token);
            _this._setNewUser(res, false);
            _this.userAuthEvent$.emit(_this.userSession);
            loginFromNotStartApp && _this.router.navigate(['/shopping-list']);
            return res;
        }).catch(function (err) {
            return _this.modalService
                .warning({
                title: 'Error,',
                message: getErrorType(err),
                yesButtonText: 'Close',
            });
        });
    };
    UserService.prototype._login = function (fullUrl, data, keepLogged, redirect_with_login) {
        var _this = this;
        this.token = this.cookieService.getCookie('user');
        var request = fullUrl === 'login'
            ? this.dataService.postData(fullUrl, data, { token: this.token })
            : this.dataService.postData(fullUrl, data);
        return request.map(function (res) {
            !redirect_with_login && _this._reportLoginSuccess(res, keepLogged);
            var response = {
                logged: true,
                message: ''
            };
            // setTimeout(() =>
            // window.location.pathname !== '/cart-report' && this.router.navigate(['/shopping-list'])
            // );
            _this.user = res.user;
            _this.token = res.token;
            _this.userChanges$.next(_this.user);
            _this._setNewUser(res, false);
            if (_this.loggedUserFromComponent) {
                _this.clearCookies();
            }
            _this.cookieService.setCookie('type', '');
            keepLogged && _this.setCookies(_this.typeOfUser(), _this.token);
            _this.googleAnalyticsService.handleAccessLevelOfUser(_this.user.accessLevel, _this.user.login);
            _this.emitUserAuthEvent(response.logged);
            // observer.next(response);
            // observer.complete();
            return response;
        }).catch(function (err) {
            if (err.status === 0) {
                err.statusText = 'Check the Internet connection';
            }
            _this._reportLoginError(data, err);
            return Observable.throwError(err);
            // observer.error(err);
            // You don't need to call complete, because it won't
            // do anything anyway because you called error already.
        });
    };
    UserService.prototype.loggedUserFromComponentSetter = function (status) {
        this.loggedUserFromComponent = status;
    };
    UserService.prototype._catchRequestError = function (err) {
        if (err.status === 0) {
            err.statusText = 'Check the Internet connection';
        }
        return this.modalService.error({
            title: 'Error:',
            message: getErrorType(err),
            yesButtonText: 'Close',
        });
    };
    UserService.prototype.login = function (creds, keepLogged) {
        return this._login('login', creds, keepLogged);
    };
    UserService.prototype.loginWithToken = function (isRedirectWithLogin) {
        var user = this.cookieService.getCookie('user');
        var paymentToken = this.cookieService.getCookie('payment_token');
        if (!user && !paymentToken) {
            this.googleAnalyticsService.handleLogOut('logout', 'success', '');
            return Observable.of({ logged: true, message: '' });
        }
        //   const keepLoggedCookie = this.cookieService.getCookie('keep_logged');
        var token = user ? user : paymentToken;
        var queryString = 'login_by_token';
        return this._login(queryString, { token: token || 'fakeToken' }, this.entryWithOldLoginGuest, isRedirectWithLogin);
    };
    UserService.prototype.logoutModal = function (event) {
        var _this = this;
        this.modalService
            .warning({
            title: this.translate.instant('logout.modal.title'),
            message: this.logoutTextMessage,
            yesButtonText: this.translate.instant('logout.modal.yes'),
            noButtonText: this.translate.instant('logout.modal.no'),
            reverseButtons: true,
        })
            .then(function (action) {
            event && action && _this.logout().subscribe(function (res) { return res; }, function (err) { return err; });
            event = null;
        });
    };
    UserService.prototype.deleteAccountModal = function (event) {
        var _this = this;
        this.modalService
            .warning({
            title: this.translate.instant('common.alert.title'),
            message: this.deleteAccountTextMessage,
            yesButtonText: this.translate.instant('common.delete'),
            noButtonText: this.translate.instant('common.cancel'),
        })
            .then(function (action) {
            if (event && action) {
                _this.deleteAccount().subscribe(function (res) { return _this.deleteAccountSuccess(); }, function (err) { return _this.deleteAccountError(err); });
            }
        });
    };
    UserService.prototype.deleteAccountSuccess = function () {
        var _this = this;
        this.modalService.success({
            title: this.translate.instant('common.success.title'),
            message: this.translate.instant('settings.deleteAccount.success.message'),
            yesButtonText: this.translate.instant('common.ok'),
        }).then(function () { return _this.handleLogoutSuccess(); });
    };
    UserService.prototype.deleteAccountError = function (error) {
        var _this = this;
        this.modalService.error({
            title: this.translate.instant('modal.error.title'),
            message: error.body_error_text || getErrorType(error),
            yesButtonText: this.translate.instant('common.cancel'),
        }).then(function () { return _this.handleLogoutError(error, false); });
    };
    Object.defineProperty(UserService.prototype, "logoutTextMessage", {
        get: function () {
            if (this.isImpersonation) {
                return this.translate.instant('logout.impersonating.modal.body');
            }
            return this.translate.instant('logout.modal.body');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "deleteAccountTextMessage", {
        get: function () {
            return this.translate.instant('settings.deleteAccount.confirmation.message');
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype.logout = function () {
        var _this = this;
        return this.dataService.postData('logout', {}, { token: this.token })
            .map(function () {
            _this.handleLogoutSuccess();
            return {};
        }, function (err) {
            _this.handleLogoutError(err);
            return err;
        });
    };
    UserService.prototype.deleteAccount = function () {
        return this.dataService.deleteData('user', { token: this.token })
            .map(function () {
            return {};
        }, function (err) {
            return err;
        });
    };
    UserService.prototype.handleLogoutSuccess = function () {
        this.onLogoutUserSubject.next(true);
        this._reportLogoutSuccess(this.user, 'logout');
        this.cart_service.resetAbandonedItems();
        this.consequenceAfterLogout(true);
    };
    UserService.prototype.handleLogoutError = function (err, logoutAnyway) {
        if (logoutAnyway === void 0) { logoutAnyway = true; }
        this.onLogoutUserSubject.next(false);
        this._reportLogoutError(this.user, err);
        this.consequenceAfterLogout(true, logoutAnyway);
    };
    UserService.prototype.consequenceAfterLogout = function (loginFromNotStartApp, logoutAnyway) {
        var _this = this;
        if (logoutAnyway === void 0) { logoutAnyway = true; }
        if (Object.keys(this.impersonation).length === 0) {
            if (logoutAnyway) {
                this.clearUser();
                this.login_guest(loginFromNotStartApp).subscribe(function (res) {
                    _this.loginGuestAutoActionSubject.next(res.user);
                });
            }
        }
        else
            this._logoutForImpersonation().subscribe();
    };
    Object.defineProperty(UserService.prototype, "router", {
        /**
         * @desc This function creates router property on your service.
         *
         * The problem is that Router can async load some routes.
         * This is why it needs Http.
         * Your Http depends on Router and Router depends on Http.
         * Angular injector is not able to create any of these services.
         * I had similar problems and one of the solutions can be injecting
         * Injector instead of service and getting service afterwards.
         * @return {Router}
         */
        get: function () {
            return this.injector.get(Router);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "cart_service", {
        /***
        * @desc This function creates 'cart service' property on your service.
        * @return {CartService}
        */
        get: function () {
            return this.injector.get(CartService);
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype._logoutForImpersonation = function () {
        this._clearImpersonate(this.impersonation);
        this._setNewUser();
        this.userAuthEvent$.emit(this.userSession);
        // this.userAuthEventImpersonation$.emit(this.impersonation);
        this.router.navigate(['/users-list']);
        return Observable.of({});
    };
    UserService.prototype.logoutFromAll = function (event) {
        var _this = this;
        this.modalService
            .warning({
            title: this.translate.instant('logout.error.cancel.title'),
            message: this.translate.instant('logout.error.cancel.message'),
            yesButtonText: this.translate.instant('logout.error.cancel.confirm'),
            noButtonText: this.translate.instant('logout.error.cancel.reject'),
        })
            .then(function (action) {
            event && action && _this._logoutFromAll();
            event = null;
        });
    };
    UserService.prototype._logoutFromAll = function () {
        var _this = this;
        this.dataService.postData('logout_from_all', {}, { token: this.token })
            .subscribe(function () {
            // @ts-ignore
            _this._reportLogoutSuccess(_this.user, 'logout_from_all');
            _this.clearUser();
        }, function (err) {
            // @ts-ignore
            _this._reportLogoutError(_this.user, err);
            _this.clearUser(err);
        });
    };
    UserService.prototype.clearUser = function (err) {
        // @ts-ignore
        this.user = {};
        this.clearCookies();
        this.emitUserAuthEvent();
        err && this.errorService.handleError(err);
    };
    UserService.prototype.typeOfUser = function () {
        return this.cookieService.getCookie('type');
    };
    UserService.prototype.signup = function (user) {
        var _this = this;
        return this.dataService.postData('sign_up', user, { token: this.token })
            .map(function (res) {
            _this._reportSignupSuccess(user.login);
            var response = Object.assign({}, res);
            if (/^2/.test(String(res.status))) {
                response['signed'] = true;
                // @ts-ignore
                _this.user = user;
                _this.userChanges$.next(_this.user);
            }
            return response;
        }, function (err) {
            return err;
        });
    };
    UserService.prototype.login_impersonated = function (userId) {
        var _this = this;
        return new Observable(function (observer) {
            _this.dataService.postData('login_impersonated', { 'user_id': userId }, { token: _this.token })
                .subscribe(function (res) {
                _this._setNewUser(res, true);
                _this.userAuthEvent$.emit(_this.userSession);
                // this.userAuthEvent$.emit(this.impersonation);
                observer.next();
                observer.complete();
            }, function (err) {
                return _this._catchRequestError(err);
            });
        });
    };
    UserService.prototype._setNewUser = function (result, impersonation) {
        if (!result) {
            result = { token: this.adminToken, user: this.adminUser };
        }
        if (impersonation) {
            this.impersonationToken = result.token;
            this.impersonation = result.user;
        }
        else {
            this.adminToken = result.token;
            this.adminUser = result.user;
        }
        this.token = result.token;
        this.user = result.user;
        this.userChanges$.next(this.user);
        this.userSession = {
            firstName: result.user.firstName,
            lastName: result.user.lastName,
            ID: result.user.ID,
            login: result.user.login,
            token: result.token
        };
    };
    UserService.prototype._clearObject = function (userObj) {
        var e_1, _a;
        try {
            for (var _b = __values(Object.getOwnPropertyNames(userObj)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var prop = _c.value;
                delete userObj[prop];
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    Object.defineProperty(UserService.prototype, "isImpersonation", {
        get: function () {
            return !AppValues.isEmpty(this.impersonation) && this.impersonationToken !== '';
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype._clearImpersonate = function (obj) {
        this.impersonationToken = '';
        this._clearObject(obj);
    };
    UserService.prototype._reportLogoutSuccess = function (data, request) {
        var value = data['login'] + ' ' + request;
        this.googleAnalyticsService.handleLogOut('logout', 'success', value);
    };
    UserService.prototype._reportLogoutError = function (data, err) {
        var description = data['login'] + ' logout error ' + err.status + ' ' + getErrorType(err);
        this.googleAnalyticsService.handleErrorEvent('logout', 'logout', description);
    };
    UserService.prototype._reportLoginSuccess = function (response, keepLogged) {
        if (response['user'].accessLevel !== 0) {
            var value = response['user'].login + ' '
                + (keepLogged && keepLogged === true ? 'login with token' : 'login');
            this.googleAnalyticsService.handleLogin('login', 'success', value);
        }
    };
    UserService.prototype._reportLoginError = function (data, err) {
        var description = (data['login'] ? 'login' : 'login with token')
            + ' error ' + err.status + ' ' + getErrorType(err);
        this.googleAnalyticsService.handleErrorEvent('login', 'login', description);
    };
    UserService.prototype._reportSignupSuccess = function (login) {
        this.googleAnalyticsService.handleSignUp('sign_up', 'success', login);
    };
    UserService.prototype._reportSignupError = function (err) {
        var description = 'sign_up error ' + err.status + ' ' + getErrorType(err);
        this.googleAnalyticsService.handleErrorEvent('sign_up', 'sign_up', description);
    };
    UserService.prototype.changePassword = function (passObj) {
        return this.dataService.postData('change_password', passObj, { token: this.token });
    };
    UserService.prototype.resetPassword = function (email) {
        return this.dataService.postData('reset_password', { email: email }, { token: this.token });
    };
    UserService.prototype.sendVerification = function (email) {
        return this.dataService.postData('resend_verification_email', { email: email }, { token: this.token });
    };
    UserService.prototype.getTemporaryLoginLink = function (email) {
        return this.dataService.postData('remind_login', { email: email }, { token: this.token });
    };
    UserService.prototype.emitUserAuthEvent = function (isLogged) {
        var user = this.user;
        if (isLogged) {
            this.userSession = {
                firstName: user.firstName,
                lastName: user.lastName,
                ID: user.ID,
                login: user.login,
                token: this.token
            };
        }
        else {
            this.userSession = {};
        }
        this.userAuthEvent$.emit(this.userSession);
    };
    UserService.prototype.setRedirectUrl = function (url) {
        this.redirectUrl = url;
    };
    Object.defineProperty(UserService.prototype, "getRedirectUrl", {
        get: function () {
            return this.redirectUrl;
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype.getUser = function () {
        return this.user;
    };
    Object.defineProperty(UserService.prototype, "isUserAvailable", {
        get: function () {
            return this.user && typeof this.user !== 'undefined';
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype.primaryAddress = function () {
        var userAddresses = this.getUser().addresses;
        return AppValues.getPrimaryAddress(userAddresses);
    };
    UserService.prototype.updateUser = function (user) {
        this.user = user;
        this.userChanges$.next(this.user);
    };
    UserService.prototype.getToken = function () {
        return this.userSession['token'] || this.cookieService.getCookie('user');
    };
    UserService.prototype.getUserSession = function () {
        return this.userSession;
    };
    UserService.prototype.clearUserCookies = function () {
        this.cookieService.deleteCookie('user');
        this.cookieService.deleteCookie('type');
    };
    UserService.prototype.clearCookies = function () {
        this.clearUserCookies();
    };
    UserService.prototype.setCookies = function (type, token) {
        this.cookieService.setCookie('type', type);
        this.cookieService.setCookie('user', token);
    };
    return UserService;
}());
export { UserService };
