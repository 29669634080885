import { Injectable, Injector } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { Router } from '@angular/router';

import { CookieService } from '../services/cookie.service';
import { RedirectService } from '../services/redirect.service';
import { UserService } from '../user/user.service';
import { ChatService } from '../chat/chat.service';
import { AfterBootService } from '../after-boot/after-boot.service';
import { ErrorInterface } from '../services/error.service';
import { StorageService } from '../services/storage.service';
import {GeoLocationService} from '../services/geolocation.service';
import {UserLoginResponseInterface} from "../interfaces/user.interface";

@Injectable()
export class AppLoadService {
    public location;

    private hasNoToken = false;

    constructor(
       private cookieService: CookieService,
       private afterBootService: AfterBootService,
       private injector: Injector,
       private storageService: StorageService
    ) {
       this.location = window.location;
    }

        // initializeApp(): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         // console.log(`initializeApp:: inside promise`);
    //
    //         setTimeout(() => {
    //             // console.log(`initializeApp:: inside setTimeout`);
    //             // doing something
    //
    //             resolve();
    //         }, 3000);
    //     });
    // }
    initializeApp(): Promise<any> {
        let promise;

        this.isLoginLink();
        this.checkPurchaseMemory();

        const user = this.cookieService.getCookie('user');
        const type = this.cookieService.getCookie('type');
        this.redirect_service.firstStartApp = true;

        this.geo_location_service.getGeolocation();

        if (!user && !type) {
            promise = this.user_service.login_guest().toPromise().then((res: any) => {
                this._changeRoute();
                return res;
            });
        } else {
            // get guest token data from cookie and to continue
            // login with token request
            this.user_service.entryWithOldLoginGuest = true;

            promise = this.user_service.loginWithToken().toPromise().then(
                (res: UserLoginResponseInterface) => {
                    if (this.user_service.getUser().accessLevel === 0) {
                        this.cookieService.setCookie('type', 'guest');
                    }

                    this.hasNoToken && this.cookieService.deleteCookie('user');
                    this.hasNoToken = false;

                    this._changeRoute();

                    return res;
                }).catch((err: ErrorInterface) => {
                    if (err.status === 403) {
                        return new Promise((resolve, reject) => {
                            this.user_service.login_guest(true).toPromise()
                               .then((res: any) => {
                                   this.afterBootService.afterLoadingError = err;
                                   resolve(res);
                               }).catch((error: ErrorInterface) => {
                                   this.user_service.clearUser();
                                   reject(error);
                               });
                        });
                    }

                    return err;
                });
        }

        return promise;
    }

    _changeRoute() {
        this.redirect_service.checkRedirectPath();
        this.redirect_service.firstStartApp = false;
    }

    /**
     * @desc This function creates router property on your service.
     * @return {Router}
     */
    public get router(): Router {
        return this.injector.get(Router);
    }

    /**
     * @desc This function creates 'redirect service' property on your service.
     * @return {Router}
     */
    public get redirect_service(): RedirectService {
        return this.injector.get(RedirectService);
    }

    /**
     * @desc This function creates 'user service' property on your service.
     * @return {UserService}
     */
    public get user_service(): UserService {
        return this.injector.get(UserService);
    }

    /**
     * @desc This function creates 'GeoLocationService' property on your service.
     * @return {GeoLocationService}
     */
    public get geo_location_service(): GeoLocationService {
        return this.injector.get(GeoLocationService);
    }

     /**
     * @desc This function creates 'chat service' property on your service.
     * @return {ChatService}
     */
    public get chat_service(): ChatService {
        return this.injector.get(ChatService);
    }

     /**
     * Checks if window location search containes '?token=' substring.
     * @desc In case true - saves to cookie token from location
     *       search and type as 'user'.
     * @private
     */
    private isLoginLink(): void {
        const url: string = this.location.search;
        if (url.includes('?token=')) {
            this.afterBootService.isLoginLinkLaunch = true;

            const token: string = decodeURIComponent(url.split('?token=')[1]);
            const userType: string = this.cookieService.getCookie('type');

            if (this.cookieService.getCookie('user') && userType !== 'guest') {
                this.afterBootService.isAlreadyLoggedIn = true;
            } else {
                this.cookieService.setCookie('user', token);
                this.cookieService.setCookie('type', '');
            }
        }
    }

    public checkPurchaseMemory(): void {
        const storedUser: string = this.storageService.get('user');
        if (storedUser && !this.cookieService.getCookie('user')) {
           this.cookieService.setCookie('user', storedUser);
           this.hasNoToken = true;
        }
    }
}
