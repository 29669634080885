
        <ul *ngIf="filteredItems" class="search__container" id="custom-container">
            <div *ngFor="let purchase of filteredItems; let p =  index;">
                <li class="review-list__purchase" *ngFor="let order of purchase.elements; let o =  index;"
                    [attr.data-test-id]="'reviewItem_'+p+'_'+o">
                    <div class="first-product-info-row">
                        <div *ngIf="order.item_info.photoURL" class="review-img">
                            <img [attr.data-test-id]="'reviewItemImg_'+i" [src]="order.item_info.photoURL"/>
                        </div>
                        <div *ngIf="!order.item_info.photoURL" class="review-img">
                            <span [attr.data-test-id]="'reviewItemImg_'+i"
                              class="sprite sprite-no_image_icon"></span>
                        </div>
                        <div>
                            <p class="product-title" [attr.data-test-id]="'productTitle_'+p+'_'+o" (click)="showProduct(order.item_info)">{{order.item_info.title}}</p>
                            <p class="product-total" [attr.data-test-id]="'productTotal_'+p+'_'+o">Total: {{order.price * order.qty / HUNDRED | currency: 'USD'}}</p>

                            <p class="product-each" [attr.data-test-id]="'productEach_'+p+'_'+o">{{order.price / HUNDRED | currency: 'USD'}} / {{order.uom}} x{{order.qty | number}}</p>
                            <p class="product-order">Order code:
                                <span class="product-order-code" [attr.data-test-id]="'productOrderCode_'+p+'_'+o"
                                      (click)="showOrder(purchase.order_id)">{{purchase.order_id}}
                                </span>
                            </p>

                        </div>
                    </div>

                    <div class="second-product-info-row">
                        <p *ngIf="isSeller" (click)="!order.buyer_info.anonymized && showUserReviews(order, purchase.buyer_info, purchase.buyer_id, true); $event.stopPropagation();"
                        [className]="order.buyer_info.anonymized ? 'no-decoration buyer-name' : 'buyer-name'" [attr.data-test-id]="'BuyerName_'+p+'_'+o">{{order.buyer_info.anonymized ? ('anonymized.user.name' | translate) : order.buyer_info.title}}</p>
                        <p *ngIf="!isSeller" (click)="!order.seller_info.anonymized && showUserReviews(order, purchase.seller_info, purchase.seller_info, true); $event.stopPropagation();" [className]="order.seller_info.anonymized ? 'no-decoration product-company-name' : 'product-company-name'" [attr.data-test-id]="'productCompany_'+p+'_'+o">{{order.seller_info.anonymized ? ('anonymized.user.name' | translate) : order.seller_info.title}}</p>

                        <p class="product-date" [attr.data-test-id]="'productDate_'+p+'_'+o">
                            {{purchase.datetime }}</p>
                    </div>

                    <button *ngIf="!order.is_reviewed"
                            [attr.data-test-id]="'addReviewOnProduct_'+p+'_'+o"
                            (click)="addReview(order, purchase.buyer_info, purchase.buyer_id); $event.stopPropagation();"
                            class="button__bizibazagreen">
                        {{ "review.purchase.add" | translate }}
                    </button>

                    <button *ngIf="order.is_reviewed"
                            [attr.data-test-id]="'productReviewed_'+p+'_'+o"
                            (click)="showReviewedItem(order.ID); $event.stopPropagation();"
                            class="button__transparent__bizibazagreen">
                            {{ "review.purchase.reviewed" | translate }}
                    </button>
                </li>
            </div>
        </ul>
    