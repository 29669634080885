export default function parseInputFloat(value: string): string {
    // strip all non-numeric characters to avoid NaN
    value = value.replace(/[^\d.-]/g, '');
    let last = value.slice(-1);
    let len  = value.length;

    if (!Number.isInteger(+last as any)) {
        return value.slice(0, len - 1);

    } else if (last === '.') {
        let cut = value.slice(0, len - 1);

        if (cut.includes('.')) return cut;
    }

    return value;
}
