/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./modal.component";
import * as i3 from "./modal.service";
var styles_ModalComponent = [];
var RenderType_ModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
export function View_ModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { container: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["container", 1]], null, 2, "div", [["class", "modal__container"], ["tabindex", "1"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "modal__invisible": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal__container"; var currVal_1 = _ck(_v, 3, 0, !_co.isVisible); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i0.ɵdid(1, 114688, null, 0, i2.ModalComponent, [i0.ComponentFactoryResolver, i3.ModalService, i1.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i0.ɵccf("modal", i2.ModalComponent, View_ModalComponent_Host_0, {}, {}, []);
export { ModalComponentNgFactory as ModalComponentNgFactory };
