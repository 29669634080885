
        <div class="time-picker" [ngClass]="{'invalid': isInvalid}">
            <!--<div class="time-picker" [ngClass]="{'invalid': isInvalid, 'blank': !time}">-->

            <!--MONTH-->
            <select #monthSelect [attr.data-test-id]="dataTestId + '__monthSelect'" [ngClass]="className"
                    (change)="update('month', monthSelect.value)">

                <option class="option" *ngFor="let _month of _months"
                        [ngValue]="_month"
                        [selected]="_month === month">
                    {{_month}}
                </option>
            </select>

            <!--DAY-->
            <select #daySelect [attr.data-test-id]="dataTestId + '__daySelect'" [ngClass]="className"
                    (change)="update('day', daySelect.value)">

                <option class="option" *ngFor="let _day of _days"
                        [ngValue]="_day"
                        [selected]="_day == day">
                    {{_day}}
                </option>
            </select>

            <!--YEAR-->
            <select #yearSelect [attr.data-test-id]="dataTestId + '__yearSelect'" [ngClass]="className"
                    (change)="update('year', yearSelect.value)">

                <option class="option" *ngFor="let _year of _years"
                        [ngValue]="_year"
                        [selected]="_year === year">
                    {{_year}}
                </option>
            </select>

            <span *ngIf="hasTimeSection">
                &nbsp;
                &nbsp;

                <!--HOURS-->
                <select #hourSelect [attr.data-test-id]="dataTestId + '__hourSelect'" [ngClass]="className"
                        (change)="update('hours', hourSelect.value)">

                    <option class="option" *ngFor="let _hour of _hours"
                            [ngValue]="_hour"
                            [selected]="_hour == hours">
                        {{_hour}}</option>
                </select>
            
                <b>:</b>

                <!--MINUTES-->
                <select #minuteSelect [attr.data-test-id]="dataTestId + '__minuteSelect'" [ngClass]="className"
                        (change)="update('minutes', minuteSelect.value)">

                    <option class="option" *ngFor="let _minute of _minutes"
                            [ngValue]="_minute"
                            [selected]="_minute == minutes">
                        {{_minute}}</option>
                </select>
            </span>
        </div>
    