
        <div class="component">

            <favorite-list-header></favorite-list-header>

            <div class="component__container favorite-list__container">

                <h3 *ngIf="items?.length === 0 && users?.length === 0" class="empty-list">
                    {{ "favorite.list.empty" | translate }}
                </h3>

                <app-fav-watch-list
                   [items]="items"
                   [type]="'fav'"
                   (showItemInfo$)="showItemInfo($event)"
                   (removeItem$)="removeItem($event)"
                ></app-fav-watch-list>

                <ul *ngIf="users" class="favorite-list__ul-users">
                    <li class="fw-list__li text-center" (click)="showUser(user.ID)"
                        *ngFor="let user of users; trackBy: trackByID; let i = index;"
                        attr.data-id="{{user.ID}}" [attr.data-test-id]="'favListUItem_'+i">
                        <div class="fw-list__img-container" [attr.data-test-id]="'favListUItemImage_'+i">
                            <span *ngIf="!user.imageURL" class="sprite sprite-no_image_icon"></span>
                            <img *ngIf="user.imageURL" [src]="user.imageURL" [alt]="user.title">
                        </div>
                        <div class="fw-list__product__container text-left">
                            <h3 class="abstract-list__product__title" [attr.data-test-id]="'favListUName_'+i">
                                {{user.title || user.firstName + ' ' + user.lastName}}</h3>

                            <div class="abstract-list__product-reviews">
                                <rating [attr.data-test-id]="'favListURate_'+i" [rating]="user.rate" [float]="true"
                                        [readonly]="true"></rating>
                            </div>
                        </div>
                        <span class="fw-list__item-controls" [attr.data-test-id]="'favListURemoveItem_'+i"
                              (click)="removeUser(user.ID);$event.stopPropagation()">
                           <i class="icon-trash-empty"></i>
                        </span>
                    </li>
                </ul>

            </div>
        </div>
    