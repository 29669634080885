/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./analytics-header.component";
import * as i6 from "../app-footer/app-footer.service";
var styles_AnalyticsHeaderComponent = [i0.styles];
var RenderType_AnalyticsHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AnalyticsHeaderComponent, data: {} });
export { RenderType_AnalyticsHeaderComponent as RenderType_AnalyticsHeaderComponent };
function View_AnalyticsHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "nav", [["class", "app-header__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "nav__link"], ["data-test-id", "shoppingLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "navbar__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h4", [["data-test-id", "analytics"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "navbar__right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.mode === "buyer") ? "/shopping-list" : "/inventory-list"); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("analytics.header.orders")); _ck(_v, 7, 0, currVal_1); }); }
function View_AnalyticsHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "nav", [["class", "app-header__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "nav__link"], ["data-test-id", "analyticLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "navbar__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h4", [["data-test-id", "pending-orders"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "navbar__right"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "/orders"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("analytics.header.pendingOrders")); _ck(_v, 7, 0, currVal_1); }); }
function View_AnalyticsHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "nav", [["class", "app-header__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "nav__link"], ["data-test-id", "analyticLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "navbar__center"], ["role", "history"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h4", [["data-test-id", "history"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "navbar__right"], ["role", "history"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("analytics.header.history")); _ck(_v, 6, 0, currVal_0); }); }
export function View_AnalyticsHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "header", [["class", "app-header analytics-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalyticsHeaderComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalyticsHeaderComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalyticsHeaderComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.analyticsUrl === "/orders"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.analyticsUrl === "/pending-orders"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.analyticsUrl === "/history"); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_AnalyticsHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "analytics-header", [], null, null, null, View_AnalyticsHeaderComponent_0, RenderType_AnalyticsHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i5.AnalyticsHeaderComponent, [i6.AppFooterService], null, null)], null, null); }
var AnalyticsHeaderComponentNgFactory = i1.ɵccf("analytics-header", i5.AnalyticsHeaderComponent, View_AnalyticsHeaderComponent_Host_0, { analyticsUrl: "analyticsUrl" }, { goBackEvent$: "goBackEvent$" }, []);
export { AnalyticsHeaderComponentNgFactory as AnalyticsHeaderComponentNgFactory };
