
        <div #container class="bp__container">
            <div class="bp__image" (click)="clearActive()">
                <img
                    draggable="false"
                    [src]="currentBizipixBoard.image_url"
                    data-test-id="bizipixImage"
                    (load)="createFirstView()"
                    alt="bizipix image"
                />
            </div>
            <tag
                #tagContainer
                *ngFor="let tag of tags || currentBizipixBoard.tags"
                [context]="tag"
                [isClearActive]="isClearActive"
                [isToggleRemoveTag]="isToggleRemoveTag"
                [bizipix]="currentBizipixBoard"
                (allTagsContext$)="allTagsContext($event)"
            ></tag>
        </div>
    