
        <div class="product-header" [style.background-image]="'url('+item.photoURL+')'">
            <div class="product-header__top">

                <span class="nav__link" *ngIf="item && !options?.readOnly">
                    <button (click)="location.back()" data-test-id="searchResultsLink" type="button">
                        <i class="icon-custom_back_icon"></i>
                    </button>
                </span>
                <span class="nav__link" *ngIf="item && options?.readOnly">
                    <button [routerLink]="'/history'" data-test-id="historyLink" type="button">
                        <i class="icon-custom_back_icon"></i>
                    </button>
                </span>

                <div class="product-header__top__right-block">
                    <div data-test-id="shareProductContainer">
                        <button (click)="shareProduct(item.dynamic_link)" *ngIf="!options?.readOnly" type="button">
                            <i data-test-id="shareProduct" class="icon-share"></i>
                        </button>
                        <span class="tooltiptext" id="myTooltip">{{ shareProductTip }}</span>
                    </div>

                    <button
                        [attr.data-state-active]="isInReportList"
                        (click)="checkReport(item.ID, $event)"
                        *ngIf="!options?.readOnly"
                        type="button"
                    >
                        <i [ngClass]="isInReportList ? 'icon-flag' : 'icon-flag-empty'" data-test-id="sendReport"></i>
                    </button>

                    <button
                        [attr.data-state-active]="item.inWatchList"
                        data-test-id="toggle-watchlist"
                        class="button-watchlist"
                        (click)="toggleWatchListStatus()"
                        *ngIf="!options?.readOnly"
                        type="button"
                    >
                        <i [ngClass]="item.inWatchList ? 'icon-eye_filled' : 'icon-eye_outlined'"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="product-header__bottom">
            <div class="product__title">
                <button data-test-id="BiziPix" (click)="showBizipix()" type="button">
                    <img width="61" height="46" [src]="'../../assets/images/svg_icons/shopix.svg'" alt="BiziPix">
                </button>
                <div id="product__title" data-test-id="detailTitle">{{item.title}}</div>
                <button
                    [attr.data-state-active]="item.inFavList"
                    data-test-id="toggle-favlist-status"
                    (click)="toggleFavListStatus()"
                    *ngIf="!options?.readOnly"
                    type="button"
                >
                    <i [ngClass]="item.inFavList ? 'icon-favorites_filled' : 'icon-favorites_outlined'"></i>
                </button>
            </div>

            <div class="product__calculator">
                <h3 class="product__calculator__price">{{ (options?.readOnly ? options?.price : price) / HUNDRED | currency:'USD'}}
                    / {{options?.readOnly ? options?.nameUnit : unitsName}}</h3>
                <h3 class="math-sign">&#215;</h3>
                <input type="text" class="product__qty" id="qtyInput" data-test-id="detailPriceQty"
                    min="0" placeholder="0" autofocus pattern="[0-9]*"
                    [attr.value]="getPriceQtyValue() ? (getPriceQtyValue() | number) : null"
                    [class.error]="qtyWarning"
                    [disabled]="options?.readOnly"
                    (input)="calculateCost($event.target)"
                    (paste)="false">
                <h3 class="math-sign">=</h3>
                <div class="product__err" [class.error]="qtyWarning">
                    <h3 #qtyOk *ngIf="cost || !qtyWarning" data-test-id="detailCost">
                        {{ (cost || 0) | currency:'USD'}}
                    </h3>
                    <span class="error-message" #qtyErr *ngIf="qtyWarning">{{qtyWarning}}</span>
                </div>
            </div>
        </div>
    