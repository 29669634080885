import {Component} from '@angular/core';
import {ModalService} from '../../modal/modal.service';
import {AppReviewsService} from '../reviews.service';
import {CreateReviewsAbstractClass} from './create-review-abstract.class';
import {Observable} from 'rxjs/Rx';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector:       'create-review-product',
    styleUrls:      ['.././reviews.sass'],
    templateUrl:    './create-review.html'
})

export class CreateReviewProductComponent extends CreateReviewsAbstractClass {

    constructor(modalService:       ModalService,
                reviewsService:     AppReviewsService,
                translate:          TranslateService) {
        super(modalService, reviewsService, translate);
    }

    showAvailableReviews(): void {
        // Deprecated now (21.10.20)

        // if (!ID) return;
        //
        // this.reviewsService.showReviewList({ID, title, url, order_element_id}, false)
        //     .subscribe( (res) => null, (err) => Observable.throw(err));
    }

    /**
     * @desc Checks the form validity. If valid, composes address object to
     * pass it to the ReviewService.
     */
    save() {
        this.context.callback({createReviewProduct: null});
    }
}
