var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { ElementRef, OnInit } from '@angular/core';
import AppValues from '../common/app.values';
import { CartService } from './cart.service';
import { CartItemInterface, CartItemTemplateInterface, CartTemplateInterface, ChangeProductInterface, } from '../interfaces/cart.interface';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { ModalService } from '../modal/modal.service';
import { UserService } from '../user/user.service';
import { RedirectService } from '../services/redirect.service';
import { AppliedCouponView, EmptyDict, ShoppingCartResponseBody } from '../../../swagger-gen__output_dir';
import { Observable } from 'rxjs/Rx';
import { ChatService } from '../chat/chat.service';
import { ItemService } from '../services/item.service';
import { OperationSystemService } from '../services/operation.system.service';
import { TranslateService } from '@ngx-translate/core';
import { AppRouteValues, createDirection } from "../common/app.route.values";
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { getErrorType } from '../services/error.helpers';
var CartComponent = /** @class */ (function (_super) {
    __extends(CartComponent, _super);
    function CartComponent(cartService, itemService, googleAnalyticsService, modalService, userService, redirectService, chatService, OSService, translate, elementRef) {
        var _this = _super.call(this) || this;
        _this.cartService = cartService;
        _this.itemService = itemService;
        _this.googleAnalyticsService = googleAnalyticsService;
        _this.modalService = modalService;
        _this.userService = userService;
        _this.redirectService = redirectService;
        _this.chatService = chatService;
        _this.OSService = OSService;
        _this.busyBuying = true;
        _this.busySaving = false;
        _this.coupon_code = '';
        _this.couponValidation = '';
        _this.canActivateCoupon = false;
        _this._canDeactivate = true;
        _this.THOUSAND = 1000;
        _this._pad = AppValues._pad;
        _this.total = 0;
        _this.itemTotalArray = [];
        _this.warning = '';
        _this.timeout = null;
        _this.countdown_timer = 15;
        _this.sumInnerWidthHeight = _this.OSService.sumInnerWidthHeight();
        _this.chatService.init();
        _this.elem = elementRef.nativeElement;
        _this.TIME_OFFSET_SEC = new Date().getTimezoneOffset() * 60;
        _this.translate = translate;
        return _this;
    }
    /**
     * Task:
     * To allow user see the valid status of the coupon by clicking out of
     * the coupon field/hiding the keyboard and without clicking "enter" button
     *
     * Solution:
     * Triggering open status is easy using onclick or onfocus event,
     * but on closing keyboard onblur event is not fired (because cursor remains in input/textarea).
     * So I found solution by detecting window height which is significantly changed on keyboard open/close.
     * When virtual keyboard is open, it fires windows resize event.
     * So you can check if the sum of windows width and height changed to detect keyboard is open or not.
     * @param event
     */
    CartComponent.prototype.onResize = function (event) {
        var _this = this;
        var isMobileDevice = this.OSService.isMobileDevice();
        setTimeout(function () {
            if (isMobileDevice && _this.sumInnerWidthHeight && _this.coupon_code.length >= 1) {
                if (_this.OSService.isDiffWindowParam(_this.sumInnerWidthHeight)) {
                    _this.validationCoupon(event);
                }
            }
        }, 300);
    };
    CartComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initCartValues();
        this.trackSubscription(this.cartService.onChangeTotalAmount.subscribe(function (total) {
            if (_this.total !== total) {
                _this.changeBusyBuying(total === 0);
            }
            _this.total = total;
        }));
    };
    CartComponent.prototype.changeBusyBuying = function (isAvailable) {
        var _this = this;
        setTimeout(function () { return _this.busyBuying = isAvailable; });
    };
    CartComponent.prototype.initCartValues = function () {
        this.isDisabledPayment = this.userService.isImpersonation;
        this.expiresInTimerID = setInterval(this.expiresInTimer.bind(this), 1000);
        this.prepareCart();
    };
    CartComponent.prototype.isSelectedDeliveryMethodForCart = function () {
        return this.cart.items.every(function (item) { return item.delivery !== null; });
    };
    CartComponent.prototype.calculateTotal = function () {
        var _this = this;
        this.numberOfItems = 0;
        var total;
        if (!this.cart.discount) {
            total = this.itemTotalArray.reduce(function (sum, current) { return sum + current; }, 0);
        }
        else {
            total = this.cart.discount.total;
        }
        this.itemTotalArray.forEach(function (ttl) {
            if (ttl > 0) {
                _this.numberOfItems++;
            }
        });
        this.cartService.setTotal(total);
    };
    CartComponent.prototype.prepareCart = function () {
        var _this = this;
        this.trackSubscription(this.cartService.getCart()
            .subscribe(function (cart) {
            cart.items = cart.items ? cart.items : [];
            _this.cart = cart;
            _this.cart = _this.cartService.initAdditionalCartItemParams(_this.cart);
            _this.cartService.setCartMemento = _this.cart.items;
            if (cart && cart.discount) {
                _this.coupon_code = cart.discount.coupon_code;
                _this._validOrInvalidValue(cart.discount.is_valid);
            }
            _this.itemTotalArray = Array(_this.cart.items.length);
            _this.calculateTotal();
            _this.cartService.resetAndRefreshAbandonedNotify();
        }, function (err) { return Observable.throwError(err); }));
    };
    CartComponent.prototype.subtotalBySeller = function () {
        var _this = this;
        var coupon = {};
        coupon.coupon_code = this.cart.discount ? this.cart.discount.coupon_code : '';
        coupon.subtotal = 0;
        coupon.discount_absolute_value = 0;
        coupon.total = 0;
        coupon.seller_subtotal_discount_list = [];
        coupon.is_valid = this.cart.discount ? this.cart.discount.is_valid : false;
        var uniqueSellers = __spread(new Set(this.cart.items.filter(function (item) { return item.item.sellerID; }).map(function (item) { return item.item.sellerID; })));
        uniqueSellers.forEach(function (id) {
            var itemsBySeller = _this.cart.items.filter(function (i) { return i.item.sellerID === id; });
            var subtotal = 0;
            var total = 0;
            if (itemsBySeller.length > 1) {
                itemsBySeller.forEach(function (i) {
                    subtotal += i.quantity * _this.itemService.itemPriceFn(i.item);
                    total += i.quantity * _this.itemService.itemPriceFn(i.item);
                });
            }
            else {
                subtotal = itemsBySeller[0].quantity * _this.itemService.itemPriceFn(itemsBySeller[0].item);
                total = itemsBySeller[0].quantity * _this.itemService.itemPriceFn(itemsBySeller[0].item);
            }
            coupon.seller_subtotal_discount_list.push({
                sellerID: itemsBySeller[0].item.sellerID,
                sellerName: itemsBySeller[0].item.sellerName,
                subtotal: subtotal,
                total: total,
                discount_absolute_value: 0,
            });
        });
        coupon.seller_subtotal_discount_list.forEach(function (s) {
            coupon.subtotal += s.subtotal;
            coupon.total += s.total;
        });
        this.cart.discount = coupon;
        this.calculateTotal();
    };
    CartComponent.prototype.removeCouponCode = function () {
        this.resetCoupon();
    };
    /**
     * During the initialization of the Child component,
     * this method is called with the parameters passed about each item.
     * @param event
     */
    CartComponent.prototype.handleChange = function (event) {
        var cartItems = this.cart.items;
        var index = -1;
        var item;
        cartItems.forEach(function (i, idx) {
            if (i.item.ID !== event.ID)
                return;
            index = idx;
            item = i;
            Object.assign(i, {
                shipping: event.shipping,
                quantity: event.quantity,
            });
        });
        this.itemTotalArray[index] = event.itemTotal;
        if (event.touched && !event.withoutRequest) {
            this.save(item);
            this._canDeactivate = false;
        }
        if (this.cart.discount && this.cart.discount.coupon_code !== '' && this.cart.discount.is_valid) {
            this.trackSubscription(this.applyCoupon(this.coupon_code).subscribe(function (is_valid) { return null; }));
        }
        else {
            this.subtotalBySeller();
        }
        this.cartService.setCartMemento = this.cart.items;
        setTimeout(this.calculateTotal.bind(this));
    };
    CartComponent.prototype.removeItem = function (data) {
        var _this = this;
        this.trackSubscription(this.cartService.removeItem(data.ID)
            .subscribe(function () {
            _this.googleAnalyticsService.handleRemoveFromCart(data.item);
            _this.initCartValues();
            _this.cartService.resetAndRefreshAbandonedNotify();
        }));
    };
    CartComponent.prototype.deliveryChange = function (params) {
        var _this = this;
        this.trackSubscription(this.cartService.confirmationShoppingCart().subscribe(function (res) {
            if (params.shipping === AppValues.US_DELIVERY && params.data.item.deliveryOffered) {
                _this.showRedirectDialog(params.data);
            }
        }, function (err) { return null; }));
    };
    CartComponent.prototype.showRedirectDialog = function (data) {
        var _this = this;
        this.modalService.warning({
            title: this.translate.instant("shoppingCart.deliveryTerms.warning.title"),
            message: this.translate.instant("shoppingCart.deliveryTerms.warning.message"),
            yesButtonText: this.translate.instant("common.yes"),
            noButtonText: this.translate.instant("common.no"),
            reverseButtons: true,
        }).then(function (action) {
            if (action) {
                _this.contactSeller(data.item.sellerID);
            }
        });
    };
    CartComponent.prototype.contactSeller = function (sellerID) {
        this.chatService.goToDialog(sellerID);
    };
    CartComponent.prototype.save = function (item) {
        var _this = this;
        this.busySaving = true;
        var part = { items: [item] };
        this.trackSubscription(this.cartService.save(part)
            .subscribe(function (shoppingCart) {
            _this.busySaving = false;
            _this._canDeactivate = false;
            _this.cart.items = shoppingCart.elements.sc_elements;
            _this.cartService.setCartMemento = _this.cart.items;
        }, function (err) { return _this._canDeactivate = true; }));
    };
    /**
     * @example Test code to get human readable time:
     * {ms = 1507290089 * 1000; const d = new Date(ms); console.log(d.getHours(), d.getMinutes(), d.getSeconds())}
     */
    CartComponent.prototype.expiresInTimer = function () {
        var _this = this;
        var cart = this.cart;
        if (!cart || !cart.items)
            return;
        cart.items.forEach(function (item) {
            var time_to_sec = Math.floor(_this.countdown_timer * 60);
            // for debugging purpose
            // const time_to_sec = Math.floor(1 * 60) + this.TIME_OFFSET_SEC;
            item['_expiresIn'] = item.addedAt + time_to_sec - Math.round(Date.now() / _this.THOUSAND);
            var expiresIn = item['_expiresIn'];
            if (expiresIn > 0) {
                var min = (expiresIn / 60) | 0;
                var sec = (expiresIn % 60) | 0;
                // for debugging purpose
                // let hrs = (expiresIn / 3600) | 0;
                // console.log(`${this._pad(hrs)} : ${this._pad(min)} : ${this._pad(sec)}`)
                item['_expiresInFormatted'] = _this._pad(min) + " : " + _this._pad(sec);
            }
            else {
                item['_expiresInFormatted'] = '00:00';
            }
        });
    };
    CartComponent.prototype.onFocus = function (event) {
        this.canActivateCoupon = true;
        if (this.userService.isGuestMode) {
            event.preventDefault();
            // @ts-ignore
            event.target.blur();
        }
    };
    /**
     * check the entered coupon when pressing 'Enter'
     * @param event
     */
    CartComponent.prototype.onKeyUpInput = function (event) {
        this.coupon_code = this.formatCouponCode(event.target.value);
        if (event.keyCode === 13 || event.key === 'Enter') {
            this.validationCoupon(event);
        }
    };
    CartComponent.prototype.onBlurInput = function (event) {
        if (this.canActivateCoupon) {
            this.coupon_code = this.formatCouponCode(event.target.value);
            this.validationCoupon(event);
        }
    };
    /**
     * @desc all symbols must be capitalized
     * @param {string} coupon
     * @return {string}
     */
    CartComponent.prototype.formatCouponCode = function (coupon) {
        return coupon.toUpperCase();
    };
    CartComponent.prototype.validationCoupon = function (event) {
        var _this = this;
        event.preventDefault();
        event.target.blur();
        this.canActivateCoupon = false;
        if (this.userService.isGuestMode) {
            this.resetCouponAndValidation();
            return false;
        }
        this.coupon_code.trim();
        if (this.coupon_code.length === 0) {
            this.resetCoupon();
            return false;
        }
        if (this.coupon_code.length < 6) {
            this._validOrInvalidValue(false);
            this.invalidCouponAction();
            this.modalService.error({
                title: this.translate.instant("shoppingCart.coupon.warning.title"),
                message: this.translate.instant("shoppingCart.coupon.warning.message"),
                yesButtonText: 'Close',
            });
        }
        else {
            this.trackSubscription(this.applyCoupon(this.coupon_code).subscribe(function (is_valid) { return _this._validOrInvalidValue(is_valid); }));
        }
    };
    CartComponent.prototype.invalidCouponAction = function () {
        this.subtotalBySeller();
        this.calculateTotal();
    };
    CartComponent.prototype.resetCoupon = function () {
        var _this = this;
        this.modalService.showSpinner();
        this.trackSubscription(this.cartService.applyCouponDelete().subscribe(function (res) {
            _this.resetCouponAndValidation();
            _this.subtotalBySeller();
            _this.modalService.close();
        }, function (err) { return null; }));
    };
    CartComponent.prototype.resetCouponAndValidation = function () {
        this.couponValidation = '';
        this.warning = '';
        this.coupon_code = '';
        this.cart.discount.coupon_code = '';
    };
    /**
     * Method for subscribe to applyCoupon request
     * @param {string} coupon_code
     * @returns {Subscription}
     * @private
     */
    CartComponent.prototype.applyCoupon = function (coupon_code) {
        var _this = this;
        return new Observable(function (observer) {
            _this.cartService.applyCoupon(coupon_code)
                .subscribe(function (res) {
                _this.cart.discount = CouponAdapterClass.adapteForAppliedCouponExtendedView(Object.assign(res, { coupon_code: coupon_code }), true);
                _this._validOrInvalidValue(true);
                _this.calculateTotal();
                observer.next(true);
            }, function (err) {
                _this.cart.discount = CouponAdapterClass.adapteForAppliedCouponExtendedView(Object.assign(_this.cart.discount, { coupon_code: coupon_code }), false);
                _this._validOrInvalidValue(false);
                _this.invalidCouponAction();
                _this.modalService.error({
                    title: (err.status === 400)
                        ? _this.translate.instant("shoppingCart.error.title")
                        : _this.translate.instant("applyCoupon.warning.title"),
                    message: getErrorType(err),
                    yesButtonText: _this.translate.instant("modal.warning.yesButton"),
                });
                observer.next(false);
            });
        });
    };
    /**
     * Method for set value and view on template them
     * @param {boolean} isValid
     * @private
     */
    CartComponent.prototype._validOrInvalidValue = function (isValid) {
        var type = isValid ? 'valid' : 'invalid';
        this.couponValidation = type.charAt(0).toUpperCase() + type.substr(1);
        this.warning = type;
    };
    CartComponent.prototype.onBuy = function () {
        if (this.isDisabledPayment) {
            this.modalService.warning({
                title: this.translate.instant("modal.warning.title"),
                message: this.translate.instant("common.impersonated.pay"),
                yesButtonText: 'Cancel'
            });
            return;
        }
        if (!this.isSelectedDeliveryMethodForCart()) {
            this.modalService.warning({
                title: this.translate.instant("modal.warning.title"),
                message: this.translate.instant("shoppingCart.error.deliveryMethod"),
                yesButtonText: 'Cancel'
            });
            return;
        }
        this.checkCouponAndPurchase();
    };
    CartComponent.prototype.checkCouponAndPurchase = function () {
        var _this = this;
        if (!this.cartService.isValidCart())
            return;
        this.trackSubscription(this.cartService.getCart()
            .subscribe(function (cart) {
            _this.cart = cart;
            _this.cart.items = _this.cartService.changingOfCartItemPrices(_this.cartService.getCartMemento, cart.items);
            if (_this.cartService.isChangingOfCartItemPrices(_this.cart)) {
                _this.modalService.warning({
                    title: _this.translate.instant('common.alert.title'),
                    message: _this.translate.instant('shoppingCart.CartItemPriceWasChange.warning.text'),
                    yesButtonText: _this.translate.instant('common.ok')
                });
                _this.cartService.setCartMemento = _this.cart.items;
                return;
            }
            if (_this.coupon_code) {
                _this.applyCoupon(_this.coupon_code).subscribe(function (is_valid) {
                    is_valid && _this.purchaseCart();
                });
            }
            else {
                _this.purchaseCart();
            }
        }));
    };
    CartComponent.prototype.purchaseCart = function () {
        var _this = this;
        this.modalService.showSpinner();
        this.trackSubscription(this.cartService.onChangePaymentView
            .subscribe(function (paymentView) { return _this.switchPayBlock(); }));
        this.googleAnalyticsService.handleClickButton('cart_buy_button' + '_pressed', 'purchase', 'click');
        this.cartService.onConfirmationShoppingCartAndBuy({ isPayment: true, isPayFromCart: true });
    };
    CartComponent.prototype.switchPayBlock = function () {
        this.prepareCart();
        this.modalService.close();
        this.redirectService.goTo(createDirection(AppRouteValues.routePayment));
    };
    CartComponent.prototype.clickAnyButtons = function (name) {
        this.googleAnalyticsService.handleClickButton(name + '_pressed', 'purchase', 'click');
        this.redirectService.goBack();
    };
    CartComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.chatService.stopPolling();
        clearInterval(this.expiresInTimerID);
    };
    return CartComponent;
}(UnsubscribeOnDestroyAbsctractClass));
export { CartComponent };
var CouponAdapterClass = /** @class */ (function () {
    function CouponAdapterClass() {
    }
    CouponAdapterClass.adapteForAppliedCouponExtendedView = function (discount, is_valid) {
        return {
            is_valid: is_valid, coupon_code: discount.coupon_code,
            subtotal: discount.subtotal, discount_absolute_value: discount.discount_absolute_value,
            total: discount.total, seller_subtotal_discount_list: discount.seller_subtotal_discount_list
        };
    };
    return CouponAdapterClass;
}());
export { CouponAdapterClass };
