import { Component, OnInit }            from '@angular/core';

import {
    SubcategoryEntryInterface,
    SubcategoryInterface,
    CategoryBasicInfo, SubcategoryViewModelInterface
} from '../interfaces/category.interface';
import { GoodsNavService } from './goods-nav.service';
// import { slideInOutAnimation }          from '../common/animations/animations';


@Component({
    selector:   'inventory-nav',
    styleUrls:  ['goods-nav.sass'],

    // animations: [slideInOutAnimation],
    // host:       { '[@slideInOutAnimation]': '' },

    template:   `
        <div class="component">
            <inventory-nav-header *ngIf="categoryName"
                    (createItemEvent$)="createItem()"              
                    [categoryName]="categoryName"
                    [isActive]="chosenEntry"></inventory-nav-header>

            <div class="component__container">
                <ul class="subcat__list">
                    
                    <li class="subcat__title" [attr.data-test-id]="'subcategory_'+idx1"
                            [ngClass]="{'ok':   subcat['_isChosen']}"
                            *ngFor="let subcat of subcats; trackBy: trackByID; let idx1 = index"
                            (click)="toggleVisibility(subcat)">

                        <p><i class="icon-right-dir"
                                [ngClass]="{'active': subcat._isVisible}"></i>
                            
                            {{subcat.sub_category_name}}</p>

                        <ul *ngIf="subcat._isVisible" class="entry__list">

                            <li class="entry__title" [attr.data-test-id]="'entry_'+idx2"
                                    [ngClass]="{'ok': entry['_isChosen']}"
                                    *ngFor="let entry of subcat.sub_category_entries; trackBy: trackByID; let idx2 = index"
                                    (click)="toggleStatus(entry, $event)">

                                <p>{{entry.subcategory_entry_name}}</p>
                            </li>

                        </ul>

                    </li>
                </ul>
            </div>
        </div>
    `
})
export class InventoryNavComponent implements OnInit {
    /**
     * @desc Fetches navigation tree data from GoodsNavService and
     * renders category list.
     */

    categoryName:   string;
    isChosenEntry:  boolean;
    chosenEntry:    SubcategoryEntryInterface;
    subcats:        SubcategoryViewModelInterface[];

    constructor(
        private goodsNavService:        GoodsNavService,
    ) { }


    /**
     * Fetches navigation tree data from GoodsNavService.
     */
    ngOnInit(): void {
        this.isChosenEntry  = false;
        let { category }    = this.goodsNavService.getCategories();

        this.categoryName   = category.category_name;
        this.subcats = this.goodsNavService.adapter(category.sub_categories);

        this.unset_toggle_status();
    }

    unset_toggle_status(){
        this.subcats.forEach((subcat: SubcategoryViewModelInterface) => {
            subcat.sub_category_entries.forEach((ent: SubcategoryEntryInterface) => ent['_isChosen'] = false);
        });
    }


    /**
     * @desc Toggles @_isChosen flag for clicked entry. Just before that all the other
     * entries are being reset their flags.
     * @param entry
     * @param event
     */
    toggleStatus(entry: SubcategoryEntryInterface, event: MouseEvent): void {
        event.stopPropagation();

        this.chosenEntry    = null;

        this.unset_toggle_status();

        if (!entry['_isChosen']) {
            entry['_isChosen']  = true;
            this.chosenEntry    = entry;
        }

        this.chosenEntry
            ? this.isChosenEntry = true
            : this.isChosenEntry = false;
    }



    toggleVisibility(subcat: SubcategoryViewModelInterface) {
        // this.subcats.forEach((sc: SubcategoryInterface) => {
        //     if (sc !== subcat) sc['_isVisible'] = false;
        // });

        subcat._isVisible = !subcat._isVisible;
    }


    createItem() {
        const obj: CategoryBasicInfo = {
            subcategoryEntryID:     this.chosenEntry.ID,
            subcategoryEntryName:   this.chosenEntry.subcategory_entry_name,
            descriptor:             this.chosenEntry.descriptor
        };

        this.goodsNavService.createItem(obj);
    }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {SubcategoryInterface | SubcategoryEntryInterface} item
     * @return {string}
     */
    trackByID(index: number, item: SubcategoryInterface | SubcategoryEntryInterface): string {
        return item.ID;
    }

}
