
            <div class="bizipix-menu__container" *ngIf="imageData && currentBizipix">
                <div class="bizipix-menu__container__addnewbizipix" *ngIf="type">
                    <button class="bizipix-menu__addnewbizipix icon-25" data-test-id="addBizipix" (click)="addNewBizipix($event)"></button>
                </div>


                <div class="bizipix-menu__image-container" *ngFor="let bizipix of imageData; trackBy: trackByID; let i = index;">
                    <img [attr.data-test-id]="'bizipix_' + i" [src]="bizipix.image_url" [class]="(currentBizipix.ID == bizipix.ID) ? 'current-bizipix' : ''"
                         draggable="false" (click)="selectBizipix($event, bizipix)" [alt]="'BiziPix ' + i">
                </div>
            </div>
    