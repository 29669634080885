
        <div class="component map-component">
            <div class="map-search__legend">
                <span>
                    <img src="../../assets/images/map_pin_market_icon.png"/>
                    <i>{{ "map.search.market" | translate }}</i>
                </span>
                <span>
                    <img src="../../assets/images/map_pin_user_icon.png"/>
                    <i>{{ "map.search.seller" | translate }}</i>
                </span>
            </div>
            <map-search-header [type]="data.type" [searchName]="searchNameForTitle" (locateEvent$)="locate()"></map-search-header>
            <map-board [displayLocation]="displayLocation" [data]="data"
                (showDetailsEvent$)="showDetails($event)"
                (positionChangeEvent$)="fetchMore($event)"></map-board>
        </div>
    