/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./map-search-header.component.ngfactory";
import * as i3 from "./map-search-header.component";
import * as i4 from "@angular/common";
import * as i5 from "./map-board.component.ngfactory";
import * as i6 from "./map-board.component";
import * as i7 from "./map-search.service";
import * as i8 from "./map-search.component";
import * as i9 from "../market/market.service";
import * as i10 from "../shopper-details/shopper-details.service";
import * as i11 from "../search/search.service";
var styles_MapSearchComponent = [];
var RenderType_MapSearchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapSearchComponent, data: {} });
export { RenderType_MapSearchComponent as RenderType_MapSearchComponent };
export function View_MapSearchComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "component map-component"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "map-search__legend"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["src", "../../assets/images/map_pin_market_icon.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "i", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "img", [["src", "../../assets/images/map_pin_user_icon.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "i", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(12, 0, null, null, 1, "map-search-header", [], null, [[null, "locateEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("locateEvent$" === en)) {
        var pd_0 = (_co.locate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MapSearchHeaderComponent_0, i2.RenderType_MapSearchHeaderComponent)), i0.ɵdid(13, 49152, null, 0, i3.MapSearchHeaderComponent, [i4.Location, i1.TranslateService], { type: [0, "type"], searchName: [1, "searchName"] }, { locateEvent$: "locateEvent$" }), (_l()(), i0.ɵeld(14, 0, null, null, 1, "map-board", [], null, [[null, "showDetailsEvent$"], [null, "positionChangeEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showDetailsEvent$" === en)) {
        var pd_0 = (_co.showDetails($event) !== false);
        ad = (pd_0 && ad);
    } if (("positionChangeEvent$" === en)) {
        var pd_1 = (_co.fetchMore($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_MapBoardComponent_0, i5.RenderType_MapBoardComponent)), i0.ɵdid(15, 4964352, null, 0, i6.MapBoardComponent, [i7.MapSearchService, i1.TranslateService], { data: [0, "data"], displayLocation: [1, "displayLocation"] }, { positionChangeEvent$: "positionChangeEvent$", showDetailsEvent$: "showDetailsEvent$" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data.type; var currVal_3 = _co.searchNameForTitle; _ck(_v, 13, 0, currVal_2, currVal_3); var currVal_4 = _co.data; var currVal_5 = _co.displayLocation; _ck(_v, 15, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("map.search.market")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("map.search.seller")); _ck(_v, 10, 0, currVal_1); }); }
export function View_MapSearchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "map-search", [], null, null, null, View_MapSearchComponent_0, RenderType_MapSearchComponent)), i0.ɵdid(1, 245760, null, 0, i8.MapSearchComponent, [i7.MapSearchService, i9.MarketService, i10.ShopperDetailsService, i11.AppSearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapSearchComponentNgFactory = i0.ɵccf("map-search", i8.MapSearchComponent, View_MapSearchComponent_Host_0, {}, {}, []);
export { MapSearchComponentNgFactory as MapSearchComponentNgFactory };
