import { ModuleWithProviders, NgModule }    from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { RouterModule }                     from '@angular/router';

import { AppFooterComponent }               from './app-footer.component';
import { AppFooterMenuComponent }           from './app-footer-menu.component';
import { AppFooterService }                 from './app-footer.service';
import { CategorySelectorComponent }        from './category-selector.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        AppFooterComponent,
        AppFooterMenuComponent,
        CategorySelectorComponent
    ],
    exports: [AppFooterComponent]
})
export class AppFooterModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppFooterModule,
            providers: [AppFooterService]
        };
    }
}
