/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../shopping-list.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/click-outside.directive";
import * as i5 from "@angular/forms";
import * as i6 from "./custom-item-suggestions.component";
import * as i7 from "../shopping-list.service";
var styles_CustomItemSuggestionsComponent = [i0.styles];
var RenderType_CustomItemSuggestionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomItemSuggestionsComponent, data: {} });
export { RenderType_CustomItemSuggestionsComponent as RenderType_CustomItemSuggestionsComponent };
function View_CustomItemSuggestionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "loader suggestions-loader"], ["data-test-id", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("custom.item.suggestions.loading")); _ck(_v, 2, 0, currVal_0); }); }
function View_CustomItemSuggestionsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectSuggestion(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "sub_category"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "arrow-delimeter-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "arrow-delimeter sprite sprite-map_callout_arrow_2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "sub_category_entrie"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ("suggestion_" + _v.context.index); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.sub_category_name; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.sub_category_entries[0].subcategory_entry_name; _ck(_v, 8, 0, currVal_2); }); }
function View_CustomItemSuggestionsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "suggestions-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomItemSuggestionsComponent_5)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.suggestions; var currVal_1 = _co.trackByID; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CustomItemSuggestionsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "suggestion-item"], ["data-test-id", "item_comming_soon"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("custom.item.suggestions.comming.soon")); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomItemSuggestionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "delimeter"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomItemSuggestionsComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomItemSuggestionsComponent_6)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.suggestions == null) ? null : _co.suggestions.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!((_co.suggestions == null) ? null : _co.suggestions.length) && !_co.isDataLoading); _ck(_v, 4, 0, currVal_1); }, null); }
function View_CustomItemSuggestionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "suggestions-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomItemSuggestionsComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomItemSuggestionsComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDataLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isDataLoading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CustomItemSuggestionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, [[null, "appClickOutside"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("appClickOutside" === en)) {
        var pd_1 = ((_co.isDelayPassed && _co.onAddCustomItem()) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i4.ClickOutsideDirective, [i1.ElementRef], null, { appClickOutside: "appClickOutside" }), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "new-item-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "input", [["class", "input__text"], ["data-test-id", "customItemInput"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keyup.enter"], [null, "focus"], [null, "blur"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup.enter" === en)) {
        var pd_4 = (_co.onAddCustomItem($event) !== false);
        ad = (pd_4 && ad);
    } if (("focus" === en)) {
        var pd_5 = (_co.setDelay() !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (_co.onBlur($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(7, 540672, null, 0, i5.FormControlDirective, [[8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR], [2, i5.ɵangular_packages_forms_forms_j]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.NgControl, null, [i5.FormControlDirective]), i1.ɵdid(9, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomItemSuggestionsComponent_1)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.itemTitleControl; _ck(_v, 7, 0, currVal_8); var currVal_9 = _co.isControlActivated; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isEditing ? "Edit item title..." : "New item title..."); var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CustomItemSuggestionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-item-suggestions", [], null, null, null, View_CustomItemSuggestionsComponent_0, RenderType_CustomItemSuggestionsComponent)), i1.ɵdid(1, 770048, null, 0, i6.CustomItemSuggestionsComponent, [i7.ShoppingListService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomItemSuggestionsComponentNgFactory = i1.ɵccf("app-custom-item-suggestions", i6.CustomItemSuggestionsComponent, View_CustomItemSuggestionsComponent_Host_0, { isEditing: "isEditing" }, { controlStateChange$: "controlStateChange$", selectSuggestion$: "selectSuggestion$", useEnteredValue$: "useEnteredValue$", emitEnteredValue$: "emitEnteredValue$", resetControlState$: "resetControlState$" }, []);
export { CustomItemSuggestionsComponentNgFactory as CustomItemSuggestionsComponentNgFactory };
