import { CommonModule }                     from '@angular/common';
import { NgModule, ModuleWithProviders }    from '@angular/core';
import { RouterModule }                     from '@angular/router';

import { ChatService }                      from './chat.service';
import { ChatRoomComponent }                from './chat-room.component';
import { DialogHeaderComponent }            from './dialog-header.component';
import { DialogsListComponent }             from './dialogs-list.component';
import { DialogsListItemComponent }         from './dialogs-list-item.component';
import { DialogsListHeaderComponent }       from './dialogs-list-header.component';
import { MessageGroupComponent }            from './message-group.component';
import { SharedModule }                     from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        SharedModule
    ],
    declarations: [
        ChatRoomComponent,
        DialogHeaderComponent,
        DialogsListComponent,
        DialogsListItemComponent,
        DialogsListHeaderComponent,
        MessageGroupComponent
    ],
    exports: [
        ChatRoomComponent,
        DialogsListComponent
    ]
})
export class ChatModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule:   ChatModule,
            providers:  [ ChatService ]
        };
    }
}
