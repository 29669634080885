import { NgModule, ModuleWithProviders }    from '@angular/core';
import { CommonModule }                     from '@angular/common';

import { BlankComponent}            from './blank.component';
import { ModalCategorySelectorComponent }   from './modal-category-selector.component';
import { ModalComponent }           from './modal.component';
import { ModalDialogComponent}      from './modal-dialog.component';
import { ModalImageComponent }      from './modal-image.component';
import { ModalService }             from './modal.service';
import { ModalSpinnerComponent}     from './modal-spinner.component';
import {ModalFilterComponent} from './modal-filter.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    imports: [CommonModule, TranslateModule],
    declarations: [
        BlankComponent,
        ModalCategorySelectorComponent,
        ModalComponent,
        ModalDialogComponent,
        ModalImageComponent,
        ModalSpinnerComponent,
        ModalFilterComponent,
    ],
    entryComponents: [
        BlankComponent,
        ModalCategorySelectorComponent,
        ModalDialogComponent,
        ModalImageComponent,
        ModalSpinnerComponent,
        ModalFilterComponent,
    ],
    exports: [
        ModalComponent,
    ]
})
export class ModalModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ModalModule,
            providers: [ModalService]
        };
    }
}
