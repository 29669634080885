import { Component, ElementRef }    from '@angular/core';


import { SearchListClass }          from './search-list.class';
import { ShopperDetailsService }    from '../../shopper-details/shopper-details.service';
import {UserService} from '../../user/user.service';
import {Router} from '@angular/router';
import {ChatService} from '../../chat/chat.service';


@Component({
    selector:       'users-list',
    templateUrl:    './users-list.html'
})

export class UsersListComponent extends SearchListClass {

    constructor(
        public elementRef:              ElementRef,
        private userService:            UserService,
        private router:                 Router,
        private chatService: ChatService,
    ) {
        super(elementRef);
    }



    // Impersonation
    onClick(item: {}): void {
        this.userService.login_impersonated(item['ID']).subscribe(() => {
            this.router.navigate(['/shopping-list']);
        });
    }

}
