/**
 * A simple object creator for tag geometry.
 */

export class Position {
    constructor(
        public x:           number,
        public y:           number,
        public width:       number,
        public height:      number,
        public item_id?:    string
    ) { }
}
