import { CommonModule }                     from '@angular/common';
import {FormsModule, ReactiveFormsModule}                      from '@angular/forms';
import { NgModule, ModuleWithProviders }    from '@angular/core';
import { RouterModule }                     from '@angular/router';

import { AboutComponent }                   from './about.component';
import { FAQComponent }                     from './faq.component';
import { SettingsComponent }                from './settings.component';
import { SettingsHeaderComponent }          from './settings-header.component';
import { SharedModule }                     from '../shared/shared.module';



@NgModule({
    imports: [
        RouterModule,
        SharedModule,

        CommonModule,
        ReactiveFormsModule,
        FormsModule
    ],
    declarations: [
        AboutComponent,
        FAQComponent,
        SettingsComponent,
        SettingsHeaderComponent
    ],
    exports: [
        SettingsComponent,
    ]
})
export class SettingsModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SettingsModule,
        };
    }
}
