import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import { Subscriber } from 'rxjs';

import { AppSettings } from '../common/app.settings';
import { DataService } from '../services/data.service';
import { ModalService } from '../modal/modal.service';

import loadImage from 'blueimp-load-image';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../user/user.service';
import heic2any from 'heic2any';

@Injectable()
export class ImageUploadService {
    /**
     * @desc Mediates between the backend and AWS to upload an image file.
     * @param dataService
     */

    public constructor(
        private dataService:    DataService,
        private modalService:   ModalService,
        private translate:      TranslateService,
        private userService:    UserService,
    ) {}


    /**
     * @desc Starts image uploading. When gets uploading credentials for AWS,
     * redirects call to #_postImage method.
     * @param img
     * @param token
     * @returns {Observable<string>}
     */
    public uploadImg(img: Blob, token: string): Observable<string> {
        return new Observable((observer: Subscriber<string>) => {
          if (/\.(heic|heif)$/i.test(img['name'])) {
            heic2any({
              blob: img,
              toType: 'image/jpeg',
              quality: 0.8,
            })
              .then((result: Blob) => {
                this.hasValidImageType(result, observer) &&
                this.imageProcessing(observer, result, token);
              })
              .catch((error: Error) => {
                console.error(error);
                observer.complete();
              });
          } else {
            this.hasValidImageType(img, observer) &&
            this.imageProcessing(observer, img, token);
          }
        });
      }
    /**
     * @desc Starts image uploading. When gets uploading credentials for AWS,
     * redirects call to #_postImage method.
     * @param img
     * @param token
     * @returns {Observable<string>}
     */
    private imageProcessing(
       observer: Subscriber<string>,
       img: Blob,
       token: string,
    ): void {
       const onProcessImage: Observable<File>
           = this.getResetOrientationObservable(img);

       onProcessImage.subscribe((imageWithResetOrientation: File) => {
          this.hasValidImageSize(imageWithResetOrientation, observer)
             && this.dataService.getData('get_link_for_upload', { token })
                  .switchMap((res: any) => {
                      return this._postImage(imageWithResetOrientation, res);
                  }).subscribe((res: string) => {
                      observer.next(res);
                      observer.complete();
                  });
             //    For Local testing
                // this.hasValidImageSize(imageWithResetOrientation, observer)
                //   && this.dataService.getData(
                //    `get_pix_image?user_id=${this.userService.getUserSession().ID}`,
                //    { token }
                // )
                //    .switchMap((res: any) => {
                //       res = {
                //           "upload_url": {
                //               "url": "https://bizibazapics.s3.amazonaws.com",
                //               "fields": {
                //                   "acl": "public-read",
                //                  "Content-Type": "image/jpeg",
                //                   "key": "SA1/114123583420120720181118.jpg",
                //                   "AWSAccessKeyId": "AKIAIV6NM546AVLG5IQA",
                //                   "policy": "eyJleHBpcmF0aW9uIjogIjIwMTgtMTEtMTdUMTM6MjQ6MDNaIiwgImNvbmRpdGlvbnMiOiBbeyJhY2wiOiAicHVibGljLXJlYWQifSw geyJDb250ZW50LVR5cGUiOiAiaW1hZ2UvanBlZyJ9LCB7ImJ1Y2tldCI6ICJiaXppYmF6YXBpY3MifSwgeyJrZXkiOiAieXVsZW5rYTQ  1LzQ1MTM0MzAzMTQxMzE3MTEyMDE4NzQwNi5qcGcifV19",
                //                   "signature": "CBcUkFWNN+XMyWXwXX22Srv+bsU="
                //                }
                //           },
                //           "download_url": "https://bizibazapics.s3.amazonaws.com/SA1/114123583420120720181118.jpg"
                //       };

                //       return this._postImage(img, res);
                //    }).subscribe(res => {
                //         observer.next(res);
                //         observer.complete();
                //    });
       });
    }


    /**
     * @desc Returns observable of resetOrientation() method subscribtion.
     * @param {Blob} img
     * @param {Subscriber<string>} observer
     * @returns {Observable <File>}
     */
    private getResetOrientationObservable(img: Blob): Observable <File> {
       return Observable.create(
          (onProcessImageSubject: Subscriber<File>) => {
             this.resetOrientation(img, (processedImageSrc: string) => {
               onProcessImageSubject.next(
                  this.dataURItoFile(processedImageSrc, img as File),
               );
               onProcessImageSubject.complete();
             });
          },
       );
    }


    /**
     * @desc Converts uri of original uploaded image to File format
     * @param {string} dataURI
     * @param {File} originalFile
     * @returns {File}
     */
    private dataURItoFile(dataURI: string, originalFile: File): File {
       const byteString: string = window.atob(dataURI);
       const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
       const int8Array: Uint8Array = new Uint8Array(arrayBuffer);

       for (let i = 0; i < byteString.length; i++) {
         int8Array[i] = byteString.charCodeAt(i);
       }

       const convertedFile: any = new Blob([int8Array], { type: originalFile.type });
       convertedFile.lastModifiedDate = originalFile.lastModified;
       convertedFile.name = originalFile.name;

       return convertedFile as File;
    }


    /**
     * Fixes image's EXIF orientation.
     * @desc Transforms the canvas according to the specified Exif orientation,
     *       which can be an integer in the range of 1 to 8 or the boolean value true.
     *       There are 8 possible EXIF orientation values, numbered 1 to 8:
     *         1 - 0 degrees – the correct orientation, no adjustment is required.
     *         2 - 0 degrees, mirrored – image has been flipped back-to-front.
     *         3 - 180 degrees – image is upside down.
     *         4 - 180 degrees, mirrored – image is upside down and flipped back-to-front.
     *         5 - 90 degrees – image is on its side.
     *         6 - 90 degrees, mirrored – image is on its side and flipped back-to-front.
     *         7 - 270 degrees – image is on its far side.
     *         8 - 270 degrees, mirrored – image is on its far side and flipped back-to-front.
     * @param {Blob} image
     * @param callback
     */
    private resetOrientation(image: Blob, callback): void {
       loadImage.parseMetaData(image,
         (data) => {
             const orientation: number = data.exif && data.exif[0x0112]
                 ? data.exif[0x0112]
                 : 0;

             loadImage(image,
                 (canvas) => {
                     const base64data: string = canvas.toDataURL('image/jpeg');
                     callback(base64data.replace(/^data\:image\/\w+\;base64\,/, ''));
                 }, {
                     canvas: true,
                     orientation,
                 },
             );
         },
      );
    }


    /**
     * @desc Tests image's type and calls validateImage() method in case it's invalid.
     * @param {Blob} file
     * @param {Subscriber<string>} observer
     * @returns {boolean}
     */
    private hasValidImageType(
        file: Blob,
        observer: Subscriber<string>,
    ): boolean {
        return /^image/.test(file['type'])
            ? true
            : this.validateImage(this.translate.instant('modal.error.validateImage.message'), observer);
    }


    /**
     * @desc Tests image's size and calls validateImage() method in case it's invalid.
     * @param {Blob} file
     * @param {Subscriber<string>} observer
     * @returns {boolean}
     */
    private hasValidImageSize(
        file: Blob,
        observer: Subscriber<string>,
    ): boolean {
        return file['size'] > 7000000
            ? this.validateImage(this.translate.instant('modal.error.uploadImage.message'), observer)
            : true;
    }


    private validateImage(
        msg: string,
        observer: Subscriber<string>,
    ): boolean {
        setTimeout(() => this.modalService.error({
            title:          this.translate.instant('modal.error.uploadImage.title'),
            message:        msg,
            yesButtonText:  this.translate.instant('modal.error.uploadImage.confirm'),
        }));

        observer.next('');
        observer.complete();

        return false;
    }


    /**
     * @desc Composes FormData from AWS credentials and image file. Sends it via DataService.
     * @param img Image blob.
     * @param awsCreds Object of specific data, needed to upload an image to AWS.
     * @returns {Observable<string>} Observable type of new Profile image url on success.
     */
    private _postImage(img: Blob, awsCreds: Response | any) {
        const f     = Object.assign({}, awsCreds['upload_url']['fields'], {file: img});
        const data  = new FormData();

        for (const key in f) {
            data.append(key, f[key]);
        }

        return this.dataService.uploadToAWS(data, {'Cache-Control': 'no-cache'})
            .map(() => AppSettings.AWS_UPLOAD_URL + f['key']);

        // For Local testing
        // let token = this.userService.getUserSession().token;
        // return this.dataService.testPost(`user_by_ids`, {users_ids: ["572cd31b095c7630d781dc33"]}, { token })
        //     .map(() => AppSettings.AWS_UPLOAD_URL + f['key']);
    }

}
