var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter, OnInit, Injector, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { ProductDetailsService } from '../../product-details/product-details.service';
import { ShoppingListItemInterface } from '../../shopping-list/shopping-list.interface';
import { ShoppingListService } from '../../shopping-list/shopping-list.service';
import { CartService } from '../../cart/cart.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Router } from '@angular/router';
import { ErrorService } from '../../services/error.service';
import AppValues from '../../common/app.values';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../user/user.service';
import { ModalService } from '../../modal/modal.service';
import { CartAbstractClass } from '../../cart/cart-abstract-class';
import { ShopperDetailsService } from '../../shopper-details/shopper-details.service';
import { Observable } from 'rxjs';
var ProductSearchListComponent = /** @class */ (function (_super) {
    __extends(ProductSearchListComponent, _super);
    function ProductSearchListComponent(itemService, shoppingListService, shopperDetailsService, injector, changeDetector, elementRef, cartService, userService, productDetailsService, googleAnalyticsService, modalService, errorService, translate) {
        var _this = _super.call(this, elementRef, cartService, googleAnalyticsService, productDetailsService, userService, modalService, errorService, translate) || this;
        _this.itemService = itemService;
        _this.shoppingListService = shoppingListService;
        _this.shopperDetailsService = shopperDetailsService;
        _this.injector = injector;
        _this.changeDetector = changeDetector;
        _this.search_from = 'shopping_list';
        _this.itemSelectEvent$ = new EventEmitter();
        _this.addItemToSL$ = new EventEmitter();
        _this.isLocationBack = false;
        return _this;
    }
    ProductSearchListComponent.prototype.ngOnInit = function () {
        this.shoppingList = this.shoppingListService.getCachedList();
        this.subscribeOnChangeCartConfirmationResponse();
        this._getCart();
    };
    ProductSearchListComponent.prototype.ngOnChanges = function (changes) {
        this.addInCartFlag();
    };
    ProductSearchListComponent.prototype.ngOnDestroy = function () {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    };
    ProductSearchListComponent.prototype._getCart = function () {
        var _this = this;
        this.subscription$ =
            this.cartService.getCart()
                .subscribe(function (cart) {
                _this.shoppingCartElements = cart.items;
                _this.addInCartFlag();
            }, function (err) { return Observable.throw(err); });
    };
    ProductSearchListComponent.prototype.subscribeOnChangeCartConfirmationResponse = function () {
        var _this = this;
        this.trackSubscription(this.cartService.onChangeCartConfirmationResponse.subscribe(function () {
            if (!_this.changeDetector['destroyed']) {
                _this.changeDetector.detach();
                _this.changeDetector.detectChanges();
            }
        }));
    };
    /**
     * @desc Calls CartService and redirects back on response.
     */
    ProductSearchListComponent.prototype.removeFromCart = function (event, item) {
        var _this = this;
        event.stopPropagation();
        this.showProductDetails(item, false);
        this.trackSubscription(this.cartService.removeItem(this.cartService.getItemID(item.ID), false)
            .subscribe(function (result) {
            _this.googleAnalyticsService.handleRemoveFromCart(item);
            _this.resetBusyFlag(true);
            _this.changeItemInCartValue(item, false);
            _this.changeCounts();
        }, function (err) { return _this.resetBusyFlag(true); }));
    };
    /**
     * @desc Check is product exists in the cart
     * @param {SellingItemResponseBody} item
     * @returns {boolean} isExists
     */
    ProductSearchListComponent.prototype.existProductInCart = function (item) {
        return this.shoppingCartElements && this.shoppingCartElements.some(function (shoppingCartElement) { return item.ID === shoppingCartElement.item.ID; });
    };
    ProductSearchListComponent.prototype.addInCartFlag = function () {
        var _this = this;
        this.items && this.items.forEach(function (item, index) {
            _this.items[index].isInCart = _this.existProductInCart(item);
        });
    };
    /**
     * @desc Change item isInCart value
     * @param {SellingItemResponseBody} item
     * @param {boolean} value
     */
    ProductSearchListComponent.prototype.changeItemInCartValue = function (item, value) {
        var itemToChangeIndex;
        var itemToChange = this.items.find(function (itemToFind, index) {
            if (itemToFind.ID === item.ID) {
                itemToChangeIndex = index;
            }
            return itemToFind.ID === item.ID;
        });
        itemToChange.isInCart = value;
        this.items[itemToChangeIndex] = itemToChange;
    };
    /**
     * @desc Resets busy flag and throws to the console.
     * @param {boolean} isNotInCart
     */
    ProductSearchListComponent.prototype.resetBusyFlag = function (isNotInCart) {
        if (isNotInCart === void 0) { isNotInCart = false; }
        this.busyCart = false;
    };
    /**
     * @desc If url param is non-empty string, calls self-titled service method.
     * @param {Event} event
     * @param url
     */
    ProductSearchListComponent.prototype.showCertificate = function (event, url) {
        event.stopPropagation();
        if (url && (typeof url === 'string'))
            this.productDetailsService.showCertificate(url);
    };
    /**
     * @desc check if this product is on sale
     * @param {SellingItemResponseBody} item
     * @returns {boolean}
     */
    ProductSearchListComponent.prototype.isSaleFn = function (item) {
        return this.isSale = this.itemService.isSaleFn(item);
    };
    /**
     * @desc price for items
     * @param {SellingItemResponseBody} item
     * @returns {number}
     */
    ProductSearchListComponent.prototype.itemPriceFn = function (item) {
        return this.isPrice = this.itemService.itemPriceFn(item);
    };
    /**
     * @desc UnitsName for items
     * @param {SellingItemResponseBody} item
     * @returns {string}
     */
    ProductSearchListComponent.prototype.priceUnitsNameFn = function (item) {
        return this.isUnitsName = this.itemService.priceUnitsNameFn(item);
    };
    /**
     * @desc check if this product is in the basket (Shopping List)
     * @param {string} subcategoryEntryID
     * @returns {boolean}
     */
    ProductSearchListComponent.prototype.isInSL = function (subcategoryEntryID) {
        if (this.shoppingList.length === 0)
            return false;
        return this.shoppingList.findIndex(function (_item) { return _item && _item.subcategory_entry_id === subcategoryEntryID; }) >= 0;
    };
    ProductSearchListComponent.prototype.handleShoppingListEvent = function (event, item) {
        event.stopPropagation();
        if (this.isInSL(item.subcategoryEntryID)) {
            this.removeFromShoppingListEvent(item);
        }
        else {
            this.addToShoppingListEvent(item);
        }
    };
    /**
     * @desc add one product to Shopping List
     * @param {Event} event
     * @param {SellingItemResponseBody} item
     */
    ProductSearchListComponent.prototype.addToShoppingListEvent = function (item) {
        this.addToShoppingList(this.createShoppingListItem(item));
    };
    /**
     * @desc remove one product from Shopping List
     * @param {Event} event
     * @param {SellingItemResponseBody} item
     */
    ProductSearchListComponent.prototype.removeFromShoppingListEvent = function (item) {
        this.removeFromShoppingList(this.createShoppingListItem(item));
    };
    /**
     * @desc creates and returns shoppingListItem
     * @param {SellingItemResponseBody} item
     * @returns {ShoppingListItemInterface}
     */
    ProductSearchListComponent.prototype.createShoppingListItem = function (item) {
        return {
            name: item.subcategoryEntryName,
            subcategory_entry_id: item.subcategoryEntryID,
            is_active: true,
            is_custom: false,
            descriptor: item.subcategoryEntryName
        };
    };
    /**
     * @desc Adds item to shoppingList
     * @param {ShoppingListItemInterface} obj
     */
    ProductSearchListComponent.prototype.addToShoppingList = function (obj) {
        var items = this.shoppingListService.addItem(obj, this.shoppingList);
        this.updateShoppingList(items);
    };
    /**
     * @desc Removes item from shoppingList
     * @param {ShoppingListItemInterface} obj
     */
    ProductSearchListComponent.prototype.removeFromShoppingList = function (obj) {
        var items = this.shoppingListService.removeItem(obj, this.shoppingList);
        this.updateShoppingList(items);
    };
    /**
     * @desc Updates shoppingList
     * @param {ShoppingListItemInterface[]} items
     */
    ProductSearchListComponent.prototype.updateShoppingList = function (items) {
        var _this = this;
        this.trackSubscription(this.shoppingListService.updateShoppingList(items)
            .subscribe(function (res) {
            _this.shoppingList = res.elements || [];
            _this.changeDetector.detectChanges();
        }, function (err) {
            // The error has already been processed in ShoppingListService
            // and errorService.handleError(err) and the modal window was shown.
            return null;
        }));
    };
    /**
     * @desc if you want view one product
     * @param {{}} item
     */
    ProductSearchListComponent.prototype.onClick = function (item) {
        this.productDetailsService.setProductDetailsFrom(this.search_from);
        var promise = this.showProductDetails(item, true).toPromise().then(function (res) { return res; }, function (err) { return err; });
        return promise;
    };
    Object.defineProperty(ProductSearchListComponent.prototype, "router", {
        /***
         * @desc This function creates router property on your service.
         * @return {Router}
         */
        get: function () {
            return this.injector.get(Router);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @desc handle quantity choice
     * @param {HTMLInputElement} input
     * @param {number} qty
     */
    ProductSearchListComponent.prototype.setQuantity = function (input, id, qty) {
        var _this = this;
        setTimeout(function () {
            var formatedNumber = AppValues.NumberUSFormat({
                value: input.value,
                qty: qty,
                maxNumber: AppValues.PRODUCT_QTY_MAX,
                defaultValue: '',
                minimumFractionDigits: 0,
                maximumFractionDigits: 1
            });
            input.value = formatedNumber.formatedNumber;
            _this.desiredQuantity[id] = formatedNumber.num;
        });
    };
    ProductSearchListComponent.prototype.showSellerDetails = function (event, id) {
        event.preventDefault();
        event.stopPropagation();
        this.shopperDetailsService.showDetails(id);
    };
    return ProductSearchListComponent;
}(CartAbstractClass));
export { ProductSearchListComponent };
