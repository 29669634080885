
        <span (mouseleave)="resetHovered()"
              class="rating"
              [class.disabled]="disabled"
              [class.readonly]="readonly"
              [class.rating-write]="ratingWrite"
              tabindex="0"
              role="slider"
              aria-valuemin="0"
              [attr.aria-valuemax]="ratingRange.length"
              [attr.aria-valuenow]="rating">
            <span *ngFor="let item of ratingRange; let i = index">
                <i [attr.data-test-id]="'detailRateStars'+i" (mouseenter)="setHovered(item)"
                   (mousemove)="changeHovered($event)"
                   (click)="rate(item)"
                   [attr.data-icon]="fullIcon"
                   class="{{ iconClass }} half{{ calculateWidth(item) }}">{{ emptyIcon }}</i>
                <!--[title]="titles[i] || item"-->
            </span>
        </span>
    