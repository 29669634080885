import { Injectable }               from '@angular/core';
import { Location }                 from '@angular/common';
import { Router }                   from '@angular/router';

import {BizipixInterface, BizipixItemsInterface} from '../interfaces/bizipix.interface';
import { DataService }              from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import { GoogleAnalyticsService }   from '../services/google-analytics.service';
import { ModalService }             from '../modal/modal.service';
import { ProductDetailsService }    from '../product-details/product-details.service';
import { UserService }              from '../user/user.service';
import {TranslateService} from '@ngx-translate/core';



@Injectable()
export class BizipixViewerService {
    /**
     * Serves interactions for BiziPix Viewer page.
     */

    private imageData:  BizipixItemsInterface[];
    private sellerID:   string;

    constructor(
        private dataService:            DataService,
        private errorService:           ErrorService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private location:               Location,
        private modalService:           ModalService,
        private productDetailsService:  ProductDetailsService,
        private router:                 Router,
        private userService:            UserService,
        private translate:              TranslateService,
    ) { }


    /**
     * @desc Fetches BiziPix data for a certain seller. Navigates to the viewer page on response.
     * @param sellerID
     */
    showBizipix(sellerID: string): void {
        const token = this.userService.getUserSession().token;

        if (!token) return;

        this.modalService.showSpinner();

        this.dataService.getData(`get_pix_image?user_id=${sellerID}`, {token})
            .subscribe(
                (imageData: BizipixInterface) => this._checkImageData(imageData, sellerID),
                        (err: ErrorInterface) => this.errorService.handleError(err)
            );
    }


    /**
     * If image data object is empty, returns back, otherwise -- go to the viewer.
     * @param {{bizi_pixes: BizipixItemsInterface[]}} imageData
     * @param {string} sellerID
     * @private
     */
    _checkImageData(imageData: BizipixInterface, sellerID: string): void {
        console.count('handleForBiziPixViewer');
        if (!Object.keys(imageData.bizi_pixes).length) {
            this.modalService.warning({
                title:          this.translate.instant('bizipix.warning.title'),
                message:        this.translate.instant('bizipix.warning.message'),
                yesButtonText:  this.translate.instant('bizipix.warning.yesButton'),
            });

            return;
        } else this.googleAnalyticsService.handleForBiziPixViewer('viewer', sellerID);

        this.modalService.close();

        this.imageData = imageData.bizi_pixes;
        this.router.navigate(['/bizipix-viewer']);
    }


    /**
     * Displays orientation warning with modals.
     * Deprecated now
     */
    showOrientationWarning(): void {
        this.modalService.warning({
            title:          'Warning:',
            message:        'BiziPix works correctly only in landscape mode!',
            yesButtonText:  'Close',
        });
    }

    /** For call the GA event everytime when BiziPix view is show **/
    // _setSellerIDForCurrentBiziPix(sellerID: string) {
    //     this.sellerID = sellerID;
    // }
    //
    // _prepareBeforeGoogleAnalytics(type: string) {
    //     this.googleAnalyticsService.handleForBiziPixViewer('viewer', this.sellerID);
    // }

    openPageDetection(name: string) {
        this.googleAnalyticsService.handleVirtualPage(name);
    }

    /**
     * Returns BiziPix object.
     * @returns {BizipixItemsInterface[]}
     */
    getImageData(): BizipixItemsInterface[] {
        return this.imageData;
    }


    /**
     * Delegates to show product details.
     * @param id
     */
    showProductDetails(id: string): Promise<any> {
        this.productDetailsService.setProductDetailsFrom('bizipix');
        // this.productDetailsService.showProductDetails(id);
        let promise = this.productDetailsService.showProductDetails(id).toPromise().then((res: any) => res, (err) => err);
        return promise;
    }


    /**
     * Simply calls the Router to go back.
     */
    closeViewer(): void {
        this.location.back();
    }

}
