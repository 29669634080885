import {
    Component, OnInit,
    ViewChild, ViewContainerRef,
    ComponentFactoryResolver,
    ComponentRef
}                                   from '@angular/core';


import {UnsupportedDeviceService} from './unsupported-device.service';
import {UnsupportedOrientationComponent} from './unsupported-orientation.component';
import {BlankComponent} from '../modal/blank.component';
import {DynamicAbstractClass, UnsupportedModalCommandInterface} from './dynamic-abstract-class';
import {TranslateService} from '@ngx-translate/core';
import {ComponentFactory} from '@angular/core/src/linker/component_factory';


@Component({
    selector:   'unsupported-device-component',
    template:   `
        <div [class]="modalContainerClass" #container tabindex="1"
                [ngClass]="{'modal__invisible': !isVisible}">
        </div>
    `
})

export class UnsupportedDeviceComponent implements OnInit {
    @ViewChild('container', { read: ViewContainerRef })

    public container:           ViewContainerRef;
    public isVisible:           boolean;
    private componentRef:       ComponentRef<{}>;
    public modalContainerClass: string = 'modal__container';


    constructor(
        private componentFactoryResolver:   ComponentFactoryResolver,
        private unsupportedDeviceService:   UnsupportedDeviceService,
        private translate: TranslateService,
    ) { }


    public ngOnInit() {
        this.unsupportedDeviceService.getCommand()
            .subscribe((command: UnsupportedModalCommandInterface) => this._execute(command));
    }

    private _execute(command: UnsupportedModalCommandInterface) {
        this.unsupportedDeviceService.modalType = command.component;

        this.close();

        if(command.context.modalContainerClass) {
            this.modalContainerClass = command.context.modalContainerClass;
        }

        if (command.component && command.component !== BlankComponent) {
            this._renderModal(command);
        }
    }

    private _renderModal(command: UnsupportedModalCommandInterface) {
        command = this.translationText(command);
        let componentType: typeof BlankComponent = command.component;

        let factory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(componentType);
        this.componentRef   = this.container.createComponent(factory);

        let instance: DynamicAbstractClass = <DynamicAbstractClass> this.componentRef.instance;
        instance.context    = Object.assign(command.context, {_close: this.close.bind(this)});

        this._handleKBD();
    }

    private translationText(command: UnsupportedModalCommandInterface) {

        if (command.component === UnsupportedOrientationComponent) {
            command.context.title = !command.context || !command.context.title ? this.orientationTitle() : command.context.title;
            command.context.message = (!command.context || !command.context.message) && !command.context.htmlText ? this.orientationMessage() : command.context.message;
        }

        return command;
    }

    private orientationTitle():   string {
        return this.translateText("unsupported.device.orientation.title");
    }
    private orientationMessage(): string {
        return this.translateText("unsupported.device.orientation.message");
    }

    private translateText(term: string): string {
        return this.translate.instant(term);
    }

    /**
     * work with Keyboard Input element
     * @private
     */
    private _handleKBD() {
        this.isVisible = true;
        document.addEventListener('keydown', this._processKDB.bind(this));
    }

    /**
     * close modal and remove container
     * @private
     */
    public close() {
        this.isVisible = false;
        this.container.remove();

        document.removeEventListener('keydown', this._processKDB.bind(this));
    }

    /**
     * work with Keyboard Input element
     * @param e
     * @private
     */
    private _processKDB(e: any) {
        if (e.keyCode === 9) {
            setTimeout(() => this.container.element.nativeElement.focus());
        }
    }

}
