import {Component, EventEmitter, Input, Output} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';



@Component({
    selector: 'password-field',
    template: `
        <label [ngClass]="classLabel">
            <ng-content></ng-content>
            <input [ngClass]="{ error: isPassErr }" [class]="classInput"
                   [id]="forInput"
                   (keyup)="onInputTrimed($event)" (blur)="onFieldBlurred($event)"
                   [autofocus]="autofocusField"
                   [attr.data-test-id]="dataTestIdInput" autocomplete="current-password"
                   [placeholder]="placeholderInput" [attr.name]="nameInput" required [(ngModel)]="value"
                   [attr.type]="isPassVisible ? 'text' : 'password'"
                   [ngModelOptions]="{standalone: true}">

            <button (click)="changePassVisibility = !isPassVisible" [class]="classShowPasswordPosition + ' show_password'"
                 type="button" [attr.data-test-id]="dataTestIdInput + '_showPassword'">
                <span *ngIf="!isPassVisible"
                      data-test-id="passInvisible"
                      class="icon-eye_outlined"></span>

                <span *ngIf="isPassVisible"
                      data-test-id="passVisible"
                      class="icon-watchlist_icon"></span>
            </button>
        </label>
    `
})

export class PasswordFieldComponent {

    constructor (private translate: TranslateService) {}

    @Input() forInput:                  string = '';
    @Input() classLabel:                string = 'input__label clearfix';
    @Input() classInput:                string = 'input__pass';
    @Input() classShowPasswordPosition: string = 'show_password__position';

    @Input() dataTestIdInput:   string;
    @Input() isPassErr:         boolean;
    @Input() isPassVisible:     boolean = false;
    @Input() autofocusField:    boolean = false;
    @Input() password:          string = '';
    @Input() placeholderInput:  string = this.translate.instant('common.password.placeholderInput');
    @Input() nameInput:         string;

    @Output() bluredField = new EventEmitter<FocusEvent>();
    @Output() keyUpField = new EventEmitter<Event>();


    // get accessor
    get value(): string {
        return this.password;
    }

    // set accessor including call the onchange callback
    set value(v: string) {
        if (v !== this.password) {
            this.password = v;
        }
    }

    set changePassVisibility(isPassVisible: boolean) {
        this.isPassVisible = isPassVisible;
    }

    public onFieldBlurred(event: FocusEvent): void {
        this.bluredField.emit(event);
    }

    public onInputTrimed(event: Event): void {
        event.target['value'] = event.target['value'].trim();
        this.keyUpField.emit(event);
    }
}
