var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { ModalService } from '../modal/modal.service';
import { AppSearchService } from '../search/search.service';
import { RequestTypesDictionary, SearchType } from '../interfaces/search.interface';
import { UserService } from '../user/user.service';
import { GeoLocationService } from '../services/geolocation.service';
import { LocalCaching, LocalCachingCreatorMapping } from '../local-caching/local-caching-factory';
import { WindowRefService } from "../services/window-ref.service";
import AppValues from '../common/app.values';
var MapSearchService = /** @class */ (function () {
    function MapSearchService(dataService, errorService, geoLocationService, modalService, router, searchService, userService, localCaching, windowRefService) {
        this.dataService = dataService;
        this.errorService = errorService;
        this.geoLocationService = geoLocationService;
        this.modalService = modalService;
        this.router = router;
        this.searchService = searchService;
        this.userService = userService;
        this.localCaching = localCaching;
        this.windowRefService = windowRefService;
        this.dataSubject = new Subject();
        this.userSession = {};
        this.centerLocationChanges$ = new Subject();
        this.requestTypesDictionary = {
            sale: function () { return RequestTypesDictionary.Sale; },
            item: function () { return RequestTypesDictionary.Item; },
            active: function () { return RequestTypesDictionary.Active; },
            event: function () { return RequestTypesDictionary.Event; },
            market: function () { return RequestTypesDictionary.Market; },
            user: function () { return RequestTypesDictionary.User; },
            seller: function () { return RequestTypesDictionary.Seller; },
        };
    }
    Object.defineProperty(MapSearchService.prototype, "centerLocationChanges", {
        get: function () {
            return this.centerLocationChanges$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * * Gets prefetched search results from Search service, gets current location and location
     * from user's profile.
     * @param keyWord (from Search Component)
     * @param headertextSearchName (from Search Header Component)
     * @param isNotCached
     * @param isMarket
     */
    MapSearchService.prototype.showMap = function (keyWord, headertextSearchName, isNotCached, isMarket) {
        if (headertextSearchName === void 0) { headertextSearchName = ''; }
        if (isNotCached === void 0) { isNotCached = false; }
        if (isMarket === void 0) { isMarket = false; }
        this.modalService.showSpinner();
        this._reset();
        this.searchName = headertextSearchName || keyWord;
        if (isMarket) {
            this.searchResult.type = SearchType.Market;
        }
        var userCoord = this.searchService.userGeolocation();
        this.userLocation = {
            longitude: userCoord.longitude,
            latitude: userCoord.latitude
        };
        this.search({ isComponentInit: true, isNotCached: isNotCached });
    };
    MapSearchService.prototype.resetCenterLocation = function () {
        this.segmentCenterLocation = null;
    };
    /**
     * Method return SearchName for Map-search-header
     * @returns {string}
     */
    MapSearchService.prototype.getSearchName = function () {
        return this.searchName;
    };
    /**
     * Returns search data object.
     * @returns {{}}
     */
    MapSearchService.prototype.getData = function () {
        return {
            markets: this.markets,
            sellers: this.sellers,
            type: this.searchResult.type,
            userLocation: this.userLocation,
            position: this.position
        };
    };
    /**
     * Saves map position: center and zoom.
     * @param {{}} position
     */
    MapSearchService.prototype.savePosition = function (position) {
        this.position = position;
    };
    /**
     * Returns Observable of search query results. It is for pagination.
     * @returns {Observable<{}>}
     */
    MapSearchService.prototype.fetchMore = function (displayLocation) {
        this.search({ isComponentInit: false, newCoords: displayLocation });
        return this.dataSubject.asObservable();
    };
    MapSearchService.prototype._reset = function () {
        this.page = 0;
        this.markets = [];
        this.sellers = [];
        this.searchResult = this.searchService.getResults();
    };
    /**
     * Returns Array with coordinates of sellers and markets center locations.
     * @returns {CenterLocationInterface[]}
     */
    MapSearchService.prototype.calculateMinMaxLocationValues = function () {
        var _this = this;
        var coordsArray = [];
        this.markets && this.markets.forEach(function (market) {
            return coordsArray.push(_this.createCenterLocation(market.market_latitude, market.market_longitude));
        });
        this.sellers && this.sellers.forEach(function (seller) {
            return coordsArray.push(_this.createCenterLocation(seller.latitude, seller.longitude));
        });
        return coordsArray;
    };
    /**
     * Returns Object with coordinates.
     * @param {number} lat
     * @param {number} lng
     * @returns {CenterLocationInterface}
     */
    MapSearchService.prototype.createCenterLocation = function (lat, lng) {
        return { lat: lat, lng: lng };
    };
    /**
     * Returns Object with coordinates between several CenterLocation points.
     * @param {CenterLocationInterface[]} points
     * @returns {CenterLocationInterface}
     */
    MapSearchService.prototype.createSegmentCoordsArray = function (points) {
        var minLat = 0;
        var minLng = 0;
        var maxLat = 0;
        var maxLng = 0;
        points.forEach(function (point) {
            if (!maxLat || point.lat >= maxLat) {
                maxLat = point.lat;
            }
            if (!minLat || point.lat <= minLat) {
                minLat = point.lat;
            }
            if (!maxLng || point.lng >= maxLng) {
                maxLng = point.lng;
            }
            if (!minLng || point.lng <= minLng) {
                minLng = point.lng;
            }
        });
        return [minLat, minLng, maxLat, maxLng];
    };
    MapSearchService.prototype.findCenterOfLongestSegment = function (coords) {
        return {
            latitude: (coords[0] + coords[2]) / 2,
            longitude: (coords[1] + coords[3]) / 2,
        };
    };
    /**
     * convert radius from Miles to Meters (for next requests)
     * @param {number} radiusInMi
     * @returns {number}
     * @private
     */
    MapSearchService.prototype._calculateRadius = function (radiusInMi) {
        return Math.round(radiusInMi / 0.00062137);
    };
    /**
     * Defines request type. By default set up 'search_nearby'.
     * @param {string} type
     * @private
     */
    MapSearchService.prototype.getRequestType = function (type) {
        return this.requestTypesDictionary[type]
            ? this.requestTypesDictionary[type]()
            : "search_nearby";
    };
    /**
     * Composes basic part of query.
     * @param {SearchResultInterface} results
     * @private
     * @returns {string} url query string
     */
    MapSearchService.prototype.getKeywordQuery = function (results) {
        var requestType = this.getRequestType(results.type);
        var query = requestType + "?search_query=" + results.keyWord + "&";
        if (requestType === RequestTypesDictionary.Item && results.type !== SearchType.Active) {
            return requestType + "?search_query=" + results.keyWord + "&type=" + results.type + "&";
        }
        if (results.type === SearchType.Active) {
            return requestType + "?entry_id=" + results.entryId;
        }
        return query;
    };
    MapSearchService.prototype.getSearchResultMap = function () {
        var _this = this;
        var cached = this.localCaching.getAllCache(LocalCachingCreatorMapping.ConcreteSearchCaching);
        var cache;
        cached.forEach(function (c) {
            if (c['url'] === _this.windowRefService.nativeWindow().location.href) {
                cache = c;
            }
        });
        if (cached && cache) {
            this.searchService.cachedData();
            return this.searchService.getResults(cache.data['type']);
        }
        else {
            return this.searchService.getResults();
        }
    };
    /**
     * Chooses a search query string according to the query type. Delegates query call.
     * @param data
     * @private
     */
    MapSearchService.prototype.search = function (data) {
        data.page = data.page ? data.page : 0;
        var baseQuery, radius;
        var searchResult;
        if (!data.isNotCached) {
            searchResult = this.getSearchResultMap();
        }
        else {
            this._openMapSearchComponent(true);
            return;
        }
        this.setNewCoordinates(data.newCoords);
        if (!searchResult.keyWord) {
            data.page = 0;
            radius = this._calculateRadius(100);
            baseQuery = "search_nearby?";
        }
        else {
            this.searchMapByKeywordHandler(data);
            radius = this._calculateRadius(999999);
            baseQuery = this.getKeywordQuery(searchResult);
        }
        var queryUrl = this.concatSearchQueryUrl(baseQuery, radius, data.page, !!searchResult.entryId);
        if (this.querySearchUrl !== queryUrl) {
            this.querySearchUrl = queryUrl;
            this._fetch(queryUrl, data.isComponentInit);
        }
    };
    MapSearchService.prototype.concatSearchQueryUrl = function (baseQuery, radius, page, isSearchByEntryId) {
        var responseUrl = baseQuery;
        if (!isSearchByEntryId) {
            responseUrl = responseUrl + "latitude=" + this.userLocation.latitude + "&longitude=" + this.userLocation.longitude + "&radius=" + radius;
        }
        return responseUrl + "&page=" + page + "&count=100";
    };
    MapSearchService.prototype.searchMapByKeywordHandler = function (data) {
        var minMaxLocationValues = this.calculateMinMaxLocationValues();
        if (minMaxLocationValues.length > 0) {
            var segmentCoordsArray = this.createSegmentCoordsArray(minMaxLocationValues);
            if (!this.segmentCenterLocation ||
                (data.newCoords &&
                    this.position.center.latitude !== data.newCoords.latitude &&
                    this.position.center.longitude !== data.newCoords.longitude)) {
                if (!this.segmentCenterLocation) {
                    this.segmentCenterLocation = this.findCenterOfLongestSegment(segmentCoordsArray);
                    data.newCoords = this.segmentCenterLocation;
                }
                this.userLocation = data.newCoords;
                var newPosition = { center: data.newCoords, zoom: this.calculateZoomLevel(segmentCoordsArray) };
                this.savePosition(newPosition);
                this.emitCenterLocationChanges(newPosition);
            }
        }
    };
    MapSearchService.prototype.emitCenterLocationChanges = function (newPosition) {
        this.centerLocationChanges$.next(newPosition);
    };
    /**
    * calculate zoom level between corner points of rectangle of all search result markers
    * @param {number[]} bounds
    * @return {number} zoom level
    * @private
    */
    MapSearchService.prototype.calculateZoomLevel = function (bounds) {
        var mapDim = {
            height: window.innerHeight,
            width: this.calculateMapDimensionWidth()
        };
        var worldDim = {
            height: AppValues.MAP_DEFAULT_DIMENSION,
            width: AppValues.MAP_DEFAULT_DIMENSION
        };
        var zoomMin = AppValues.MINIMUM_MAP_ZOOM;
        var zoomMax = AppValues.MAXIMUM_MAP_ZOOM;
        var latFraction = (this.calculateRadians(bounds[2]) - this.calculateRadians(bounds[0])) / Math.PI;
        var lngDiff = bounds[3] - bounds[1];
        var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;
        var latZoom = this.calculateZoom(mapDim.height, worldDim.height, latFraction);
        var lngZoom = this.calculateZoom(mapDim.width, worldDim.width, lngFraction);
        var finalZoom = Math.min(latZoom, lngZoom, zoomMax);
        return Math.max(finalZoom, zoomMin);
    };
    /**
    * Choose map width depending on responsive app width
    * between max app width and window width for phones
    * @return {number} width in px
    * @private
    */
    MapSearchService.prototype.calculateMapDimensionWidth = function () {
        return window.innerWidth <= AppValues.SCREEN_DEFAULT_WIDTH
            ? window.innerWidth
            : AppValues.SCREEN_DEFAULT_WIDTH;
    };
    /**
    * convert value of lattitude to radians value
    * @param {number} value
    * @return {number} in radians
    * @private
    */
    MapSearchService.prototype.calculateRadians = function (value) {
        var sin = Math.sin(value * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    };
    /**
    * calculate necessary zoom level to display all markers
    * depending on window dimension
    * @param {number} mapPx window height or width
    * @param {number} worldPx default google map world dimension
    * @param {number} fraction This makes the calculation of the
    * fractions for the bounds more complicated
    * for latitude than for longitude. I used a formula from Wikipedia
    * to calculate the latitude fraction
    * https://en.wikipedia.org/wiki/Mercator_projection
    * @return {number} zoom level
    * @private
    */
    MapSearchService.prototype.calculateZoom = function (mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    };
    /**
     * if there is a change in coordinates - write new coordinates
     * @param {CenterLocationInterface} newCoords
     * @private
     */
    MapSearchService.prototype.setNewCoordinates = function (newCoords) {
        var userCoord = newCoords
            ? newCoords
            : this.searchService.getResults().userLocation;
        this.userLocation = {
            longitude: userCoord.longitude,
            latitude: userCoord.latitude
        };
    };
    /**
     * Makes search query via Data service.
     * @param query
     * @param isComponentInit
     * @private
     */
    MapSearchService.prototype._fetch = function (query, isComponentInit) {
        if (this.searchResult.type === 'seller') {
            this.fetchResults({ isComponentInit: isComponentInit, query: query, onlySellers: true });
            return;
        }
        if (this.searchResult.type === 'market') {
            this.fetchResults({ isComponentInit: isComponentInit, query: query, onlyMarkets: true });
            return;
        }
        this._requestGetData({ isComponentInit: isComponentInit, query: query });
    };
    /**
     * Handles function call depend on if keyword is empty or not.
     * @desc For 'seller' and 'market' type in case not empty keyword
     *       calls _handleResults() method, since the list of displayed
     *       markets and sellers doesn't change and not depend on user location.
     *       Otherwise - fetches new data.
     * @param {MapSearchResultsContent} content
     * @private
     */
    MapSearchService.prototype.fetchResults = function (content) {
        this.searchResult = this.searchService.getResults();
        if (this.searchResult.keyWord) {
            return this._handleResults(this.searchResult.data, content);
        }
        this._requestGetData(content);
    };
    /**
     * Request-method for receive items (item, market or seller type's) for map
     * @param {MapSearchResultsContent} content
     * @returns {Subscription}
     * @private
     */
    MapSearchService.prototype._requestGetData = function (content) {
        var _this = this;
        this.userSession = this.userService.getUserSession();
        var token = this.userSession.token;
        return this.dataService.getData(content.query, { token: token })
            .subscribe(function (res) { return _this._handleResults(res, content); }, function (err) { return _this.errorService.handleError(err); });
    };
    /**
     * Request-method for receive users from usersIds for displaying on the map
     * @param {string[]} usersIds
     * @returns {Subscription}
     * @private
     */
    MapSearchService.prototype._requestGetSellers = function (usersIds) {
        var _this = this;
        this.userSession = this.userService.getUserSession();
        var token = this.userSession.token;
        var response$ = new Subject();
        this.dataService.postData("user_by_ids", { users_ids: usersIds }, { token: token })
            .subscribe(function (res) {
            _this.sellers = res.users;
            response$.next();
        }, function (err) {
            _this.errorService.handleError(err);
            response$.next();
        });
        return response$.asObservable();
    };
    /**
     * @desc When search result is empty, shows 'no results' warning the first query; otherwise simply
     * hides the spinner. Enriches existing result with the fresh one. Redirects to the map page pn the first query.
     * @emits dataSubject event on further result chunks (pagination).
     * @param {SearchResponseInterface} res
     * @param {MapSearchResultsContent} content
     * @private
     */
    MapSearchService.prototype._handleResults = function (res, content) {
        res.sellers = content.onlyMarkets ? [] : res.sellers;
        res.markets = content.onlySellers ? [] : res.markets;
        if (res.items) {
            this._searchByCategoryHandler(res, content);
        }
        else if (res.nearest_items) {
            this._searchByNearestItemsHandler(res, content);
        }
        else {
            this._searchByKeywordHandler(res, content);
        }
    };
    MapSearchService.prototype._searchByCategoryHandler = function (res, content) {
        var _this = this;
        var sellersId = [];
        res.items.forEach(function (item) {
            if (!sellersId.includes(item.sellerID) && item.events.length === 0) {
                sellersId.push(item.sellerID);
            }
            item.events.forEach(function (event) {
                return _this.markets.push(__assign({}, event.item.market));
            });
        });
        this._requestGetSellers(sellersId).subscribe(function () {
            return _this._openMapHandler(content.isComponentInit);
        });
    };
    MapSearchService.prototype._searchByNearestItemsHandler = function (res, content) {
        var _this = this;
        res.nearest_items.forEach(function (item) { return _this.markets.push(item.market); });
        this._openMapHandler(content.isComponentInit);
    };
    MapSearchService.prototype._searchByKeywordHandler = function (res, content) {
        this.markets = this.concatList(this.markets, res, 'markets');
        this.sellers = this.concatList(this.sellers, res, 'sellers');
        this._openMapHandler(content.isComponentInit);
    };
    MapSearchService.prototype._openMapHandler = function (isComponentInit) {
        this._openMapSearchComponent(isComponentInit);
        this.dataSubject.next({ markets: this.markets, sellers: this.sellers });
    };
    /**
     * Removes null and undefined objects from list.
     * Merges cleared list and original.
     * Removes repeated items from merged list.
     * @param {T[]} list
     * @param res
     * @param {string} type
     * @returns {T[]}
     * @private
     */
    MapSearchService.prototype.concatList = function (list, res, type) {
        if (!Array.isArray(list))
            return [];
        var cleanList = res[type].filter(function (obj) {
            return obj !== null && obj !== undefined && typeof obj === 'object'
                && obj.toString() === '[object Object]';
        });
        return list.concat(cleanList).filter(function (obj, pos, arr) {
            return arr.map(function (mapObj) { return mapObj['ID']; }).indexOf(obj['ID']) === pos;
        });
    };
    /**
     * set currently geoPosition and go to map-search page
     * @private
     */
    MapSearchService.prototype._openMapSearchComponent = function (isComponentInit) {
        var _a;
        var currentCoord = this.userLocation
            ? this.userLocation
            : this._getDefaultUserLocation();
        this.savePosition({ center: currentCoord, zoom: this.position ? this.position.zoom : AppValues.DEFAULT_MAP_ZOOM });
        this.zoom = this.position.zoom;
        this.modalService.close();
        isComponentInit && this.router.navigate(['/map-search'], { queryParams: (_a = {}, _a[this.searchResult.type] = this.searchResult.keyWord, _a) });
    };
    /**
     * Method for receive user coordinates from server
     * (coordinates specified when registering the user)
     * @returns {CenterLocationInterface}
     * @private
     */
    MapSearchService.prototype._getDefaultUserLocation = function () {
        return this.defaultUserLocation
            = this.geoLocationService.getDefaultUserLocation();
    };
    MapSearchService.prototype.getValidLongitude = function (longitude) {
        return this.geoLocationService.getValidLongitude(longitude);
    };
    MapSearchService.prototype.getValidLatitude = function (latitude) {
        return this.geoLocationService.getValidLatitude(latitude);
    };
    return MapSearchService;
}());
export { MapSearchService };
