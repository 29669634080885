import { EventEmitter, Injectable } from '@angular/core';
import { Observable }           from 'rxjs/Observable';
import { Router }               from '@angular/router';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import { Subject } from 'rxjs/Subject';

import { DataService }                      from '../services/data.service';
import { ErrorInterface, ErrorService } from '../services/error.service';
import { EventInterface, MarketInterface }  from '../interfaces/market.interface';
import { ImageUploadService }               from '../services/image-upload.service';
import { InventoryListService }             from '../inventory-list/inventory-list.service';
import { ModalService }                     from '../modal/modal.service';
import { ProductEditorAbstractClass }       from './product-editor.abstract.class';
import { ProductEditorType, SaveProduct } from '../interfaces/product.interface';
import { AppSearchService } from '../search/search.service';
import { UserService }                      from '../user/user.service';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { ModalArgumentsInterface } from '../interfaces/modal.interface';
import { TranslateService } from '@ngx-translate/core';
import { SearchType } from '../interfaces/search.interface';

export interface CancelEditingAction {
    type: string | 'save' | 'draft' | 'cancel';
}


@Injectable()
export class ProductEditorService extends ProductEditorAbstractClass {
    /**
     * Performs interactions for product item creation and editing including presentation on events.
     */

    public defaultShippingTerms: string;
    private primevalItem: Subject<SellingItemResponseBody> = new Subject<SellingItemResponseBody>();
    // tslint:disable-next-line:member-ordering
    public primevalItem$: Observable<SellingItemResponseBody> = this.primevalItem.asObservable();

    private newEvent:   EventInterface;
    private itemWasChanged: boolean = false;
    private type:       ProductEditorType;

    private typeActions: {
       [key: string]: () => Observable<CancelEditingAction>,
    } = {
       save: () => {
           return Observable.of({type: 'save'});
       },
       draft: () => {
           return Observable.of({type: 'draft'});
       },
       cancel: () => {
           return Observable.of({type: 'cancel'});
       },
    };

    public constructor(
        protected dataService:            DataService,
        protected errorService:           ErrorService,
        protected imageUploadService:     ImageUploadService,
        protected inventoryListService:   InventoryListService,
        protected modalService:           ModalService,
        protected router:                 Router,
        protected searchService:          AppSearchService,
        protected userService:            UserService,
        protected translate:              TranslateService,
    ) {
        super(
            dataService, errorService,
            imageUploadService, modalService,
            router, userService);
    }


    public _onCreateItemSuccess(): void {
        this.type = ProductEditorType.newItem;

        this._runItemSubject();
        this.router.navigate(['/product-editor']);
    }


    /**
     * Assigns user info to the item object.
     * @param item
     * @private
     */
    public _createItem(item: SellingItemResponseBody): void {
        this.type = ProductEditorType.newItem;
    }


    /**
     * @returns {SellingItemResponseBody}
     */
    public getItemAndNewEvent() {
        return {
            item:       this.item,
            newEvent:   this.newEvent,
        };
    }


    /**
     * @desc Confirms user choice. Calls item removal for newly created and navigates to
     * the Inventory list on leave.
     */
    public cancelEditing(param?: {cansave?: boolean, event?: boolean}): Observable<CancelEditingAction>  {
        if (this.itemWasChanged) {
            if (param) {
                this.itemWasChanged = false;
                return Observable
                    .fromPromise(
                        this.modalService
                            .warning(this.checkItemTypeForModal()),
                    ).switchMap((action: boolean | 'last') => {
                        if (action === 'last') {
                            return this.typeActions['save']();
                        }
                        if (action && !param.event) {
                           this.checkItemType();
                        }
                        if (!action) {
                            return this.typeActions['draft']();
                        } else {
                            return this.typeActions['cancel']();
                        }
                    });
            } else {
                this.modalService.warning(this.checkItemTypeForModal())
                    .then((action: boolean) => {
                        action && this.checkItemType();
                    });
            }
        } else {
            if (!param) {
                this.checkItemType();
            } else if (param && param.event) {
                return this.typeActions['cancel']();
            } else {
                this.checkItemType();
                return this.typeActions['cancel']();
            }
        }
    }

    public _runInventoryList() {
        this.inventoryListService._retrieveInventoryList();
        this.router.navigate(['/inventory-list']);
    }

    public checkObjectChanges(object1: {}, object2: {}) {
        this.change_item = !this.deepEqual(object1, object2);
    }

    public set change_item(changed: boolean) {
        this.itemWasChanged = changed;
    }

    public get change_item(): boolean {
        return this.itemWasChanged;
    }

    public deepEqual(a, b) {
        if (a === b) {
            return true;
        }

        if (a === null || typeof(a) !== 'object' ||
            b === null || typeof(b) !== 'object') {
            return false;
        }

        let propertiesInA: number = 0;
        let propertiesInB: number = 0;
        for (const property in a) {
            propertiesInA += 1;
        }
        for (const property in b) {
            propertiesInB += 1;
            if (!(property in a) || !this.deepEqual(a[property], b[property])) {
                return false;
            }
        }
        return propertiesInA === propertiesInB;
    }


    /**
     * Calls on cancel item editing
     * @desc 1. If item's type is 'newItem' (template),
     *       removes this item from seller inventory list.
     *       2. Otherwise fetch seller's inventory list.
     * @private
     */
    private checkItemType(): void {
       this.type === ProductEditorType.newItem
           ? this._deleteItem()
           : this._runInventoryList();
    }

    private checkItemTypeForModal(): ModalArgumentsInterface {
        const modalArguments: ModalArgumentsInterface = {
            title: this.translate.instant('product.editor.alert.cancel.title'),
            message: this.translate.instant('product.editor.alert.cancel.message'),
            yesButtonText: this.translate.instant('product.editor.alert.cancel.action.discard'),
            noButtonText: this.translate.instant('product.editor.alert.cancel.action.no'),
        };

        if (this.type !== ProductEditorType.newItem) {
            Object.assign(modalArguments, {lastButtonText: this.translate.instant('product.editor.alert.cancel.action.save')});
        }

        return modalArguments;
    }


    /**
     * Posts to remove an item template form the database.
     * @private
     */
    public _deleteItem() {
        const token: string = this.userService.getUserSession().token;

        if (!this.item && !token) { return; }

        this.dataService.postData('delete_item', {item_id: this.item.ID}, {token})
            .subscribe(
                () => {
                    this.item = null;
                    this._runInventoryList();
                },
                (err: ErrorInterface) => {
                    this._runInventoryList();
                    this.errorService.handleError(err);
                },
            );
    }


    /**
     * Posts the item data.
     * @param {SaveProduct} obj
     * @returns {any}
     */
    public save(obj: SaveProduct): void {
        this.updateItem(obj.item)
            .switchMap((res: SellingItemResponseBody) => {
                this.newEvent = null;
                this.item = res;
                this.set_changed_item(res);
                return this.inventoryListService.fetchList(this.userService.getUserSession().ID);
            })
            .subscribe(
                () => this._onSuccess(obj),
                (err: string) => {
                    this.modalService.error({
                        title:          this.translate.instant('addItemToScPost.error.modal.title'),
                        message:        err,
                        yesButtonText:  this.translate.instant('addItemToScPost.error.modal.button'),
                    });
                    return err;
                },
            );
    }

    public set_is_creating_item(type: string) {
        return super.set_is_creating_item(type);
    }

    /**
     * Calls the DataService with `update item` request.
     * @param item
     * @returns {any}
     */
    public updateItem(item: SellingItemResponseBody): Observable<SellingItemResponseBody> {
        this.newEvent       = null;
        return super.updateItem(item);
    }

    /**
     * Displays success messages on creation or update.
     * @private
     */
    public _onSuccess(obj: SaveProduct): void {
        const msg = this.type === ProductEditorType.newItem
            ? this.translate.instant('product.editor.item.success.create')
            : this.translate.instant('product.editor.item.success.update');

        this.showSuccessMessage({msg, obj});
    }

    /**
     * Displays a success message.
     * @param msg
     */
    public showSuccessMessage(prop: {msg: string, obj?: SaveProduct, remove?: boolean}) {
        this.modalService.success({
            title:          'Success:',
            message:        prop.msg,
            yesButtonText:  'Close',
        }).then(() => {
            if (prop.obj && typeof prop.obj.event !== 'undefined') {
               prop.obj.callback();
               return;
            }

            if ( !prop.remove ) {
                this._runInventoryList();
            }
        });
    }


    /**
     * Navigates to the Product editor page to edit the item.
     * @param item
     */
    public showProductDetails(item: SellingItemResponseBody): void {
        this.item = item;
        this.type = ProductEditorType.existingItem;

        this._runItemSubject();

        this.router.navigate(['/product-editor']);
    }
    public _runItemSubject() {
        if (this.getType() === ProductEditorType.existingItem || ProductEditorType.newItem) {
            this.primevalItem$.subscribe((item) => {
                this.checkObjectChanges(item, this.item);
            });
        }
    }

    public set_changed_item(item: SellingItemResponseBody) {
        this.primevalItem.next(item);
    }


    /**
     * Cancels event editing, returns to the item page.
     */
    public cancelEventCreation(): void {
        this.newEvent = null;

        this.showProductDetails(this.item);
    }


    /**
     * Returns type, describing weather it is an event or an item, new or existing.
     * @returns {ProductEditorType}
     */
    public getType(): ProductEditorType {
        return this.type;
    }


    /**
     * Redefines the item's events.
     * @param events
     */
    // updateItemEvents(events: EventInterface[]): void {
    //     this.item.events = events;
    // }


    /**
     * Calls the Search service to look for markets.
     * @todo the service signature will be changed when it is refactored.
     * @param item
     */
    public searchForMarkets(item?: SellingItemResponseBody): void {
        this.searchService.clearResults(SearchType.Event);

        this.searchService.is_key_search = true;
        this.router.navigate(['/search'], {queryParams: {key: 'event'}});
    }


    /**
     * Cooks a new item object for a certain Market and calls the editor.
     * @param market
     */
    public createEvent(market: MarketInterface): void {
        if (this._isMarketDuplication(market.ID)) { return; }

        const item = Object.assign({}, this.item,
            {
                events: [],
                current_quantity: this._suggestCurrentQty(this.item.qty),
                loc: {
                    coordinates: [
                        market.market_longitude,
                        market.market_latitude,
                    ],
                    type: 'Point',
                },
                market,
                valitFrom: this.item.valitFrom,
                validTill: this.item.validTill,
            },
        );

        delete item.market_id;

        this.newEvent = {
            title:      market.market_title,
            market_id:  market.ID,
            item,
            item_id:    '',
        } as any;

        this.type = ProductEditorType.newEvent;
        this.router.navigate(['/product-editor']);
    }


    /**
     * Returns true if the item is already represented on the selected market.
     * @param id
     * @returns {boolean}
     * @private
     */
    public _isMarketDuplication(id: string): boolean {
        if (~this.item.events.findIndex((event: EventInterface) => event.market_id === id)) {
            this.showWarningMessage('This item has already been presented on this market!');

            return true;
        }

        return false;
    }


    public _suggestCurrentQty(qty: number|string) {
        const engagedQty = this.item.events.reduce((sum, evt) => {
            return sum + evt['item'].current_quantity;
        }, 0);

        return !engagedQty ? Math.floor(+qty / 2) : Math.floor((+this.item.qty - +engagedQty) / 2);
    }

}
