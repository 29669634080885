import { Component }            from '@angular/core';

@Component({
    selector: 'about',
    styleUrls:  ['settings.sass'],
    template: `
        <div class="component about-component">
            <settings-header></settings-header>
            <div class="about__container">
                <h4>Introduction</h4>
                <p>
                    This User Agreement, the <a>User Privacy Notice</a>, the <a>Mobile Devices Terms</a>, and all
                    policies posted on
                    our sites set out the terms on which BiziBAZA offers you access to and use of our sites, services,
                    applications and tools (collectively "Services"). You can find an overview of our policies
                    <a>here</a>. All
                    policies, the Mobile Devices Terms, and the User Privacy Notice are incorporated into this User
                    Agreement. You agree to comply with all of the above when accessing or using our Services.
                    The entity you are contracting with is 111TECHS.COM LLC., 3527 Mt. Diablo Blvd #129., Lafayette, CA
                    94549.
                </p>
                <p class="important-text">
                    Please be advised that this User Agreement contains provisions that govern how claims you and we
                    have
                    against each other are resolved (see Disclaimer of Warranties; Limitation of Liability and Legal
                    Disputes provisions below). It also contains an Agreement to Arbitrate, which will, with limited
                    exception, require you to submit claims you have against us to binding and final arbitration, unless
                    you opt out of the Agreement to Arbitrate (see <a>Legal Disputes, Section B</a> ("Agreement to
                    Arbitrate")). Unless you opt out: (1) you will only be permitted to pursue claims against us on an
                    individual basis, not as a plaintiff or class member in any class or representative action or
                    proceeding and (2) you will only be permitted to seek relief (including monetary, injunctive, and
                    declaratory relief) on an individual basis.
                </p>
                <h4>About BiziBAZA&reg;</h4>
                <p>
                    BiziBAZA is a marketplace that allows users to offer, sell and buy just about anything in a variety
                    of pricing formats and locations. The actual contract for sale is directly between the seller and
                    buyer. BiziBAZA&reg; is not a traditional retailer.
                </p>
                <p>
                    While we may provide pricing, shipping, listing, and other guidance in our Services, such guidance
                    is solely informational and you may decide to follow it or not. Also, while we may help facilitate
                    the resolution of disputes through various programs, BiziBAZA&reg; has no control over and does not
                    guarantee the existence, quality, safety or legality of items advertised; the truth or accuracy of
                    users' content or listings; the ability of sellers to sell items; the ability of buyers to pay for
                    items; or that a buyer or seller will actually complete a transaction or return an item.
                </p>

                <h4>Using BiziBAZA&reg;</h4>
                <p>In connection with using or accessing the Services you will not:</p>
                <ul>
                    <li>post, list or upload content or
                        items in inappropriate categories or areas on our sites;
                    </li>
                    <li>breach or circumvent any laws, third-party
                        rights or our systems, policies, or determinations of your account status;
                    </li>
                    <li>use our Services if you
                        are not able to form legally binding contracts (for example if you are under 18), or are
                        temporarily
                        or indefinitely suspended from using our sites, services, applications or tools;
                    </li>
                    <li>fail to pay for
                        items purchased by you, unless you have a valid reason as set out in an BiziBAZA&reg; policy, for
                        example, the seller has materially changed the item's description after you purchase, a clear
                        typographical error is made, or you cannot contact the seller:
                    </li>
                    <ul>
                        <li>fail to deliver items sold by you,
                            unless you have a valid reason as set out in an BiziBAZA&reg; policy, for example, the buyer
                            fails to
                            comply with the posted terms in your listing or you cannot contact the buyer;
                        </li>
                        <li>Manipulate the price
                            of any item or interfere with any other user's listings;
                        </li>
                        <li>post false, inaccurate, misleading,
                            defamatory, or libelous content;
                        </li>
                        <li>take any action that may undermine the feedback or ratings systems
                            (see <a>about our Feedback policies</a>);
                        </li>
                        <li>transfer your BiziBAZA&reg; account (including Feedback) and user ID
                            to another party without our consent;
                        </li>
                        <li>distribute or post spam, unsolicited or bulk electronic
                            communications, chain letters, or pyramid schemes;
                        </li>
                        <li>distribute viruses or any other technologies that
                            may harm BiziBAZA&reg;, or the interests or property of users;
                        </li>
                        <li>use any robot, spider, scraper, or other
                            automated means to access our Services for any purpose;
                        </li>
                        <li>bypass our robot exclusion headers,
                            interfere with the working of our Services, or impose an unreasonable or disproportionately
                            large
                            load on our infrastructure;
                        </li>
                        <li>export or re-export any BiziBAZA&reg; application or tool except in
                            compliance with the export control laws of any relevant jurisdictions and in accordance with
                            posted
                            rules and restrictions;
                        </li>
                        <li>reproduce, perform, display, distribute, reverse engineer, or prepare
                            derivative works from content that belongs to or is licensed to BiziBAZA&reg;, or that comes
                            from the
                            Services and belongs to another BiziBAZA&reg; user or to a third party including works covered
                            by any
                            copyrights, trademark, patent, or other intellectual property right, except with prior
                            express
                            permission of BiziBAZA&reg; and/or any other party holding the right to license such use;
                        </li>
                        <li>commercialize
                            any BiziBAZA&reg; application or any information or software associated with such
                            application;
                        </li>
                        <li>harvest
                            or otherwise collect information about users without their consent;
                        </li>
                        <li>or circumvent any
                            technical
                            measures we use to provide the Services.
                        </li>
                    </ul>
                </ul>

                <p>
                    If we believe you are abusing BiziBAZA&reg; in any way, we may, in our sole discretion and without
                    limiting other remedies, limit, suspend, or terminate your user account(s) and access to our
                    Services, delay or remove hosted content, remove any special status associated with your
                    account(s), remove and demote listings, reduce or eliminate any discounts, and take technical
                    and/or legal steps to prevent you from using our Services.
                    We may cancel unconfirmed accounts or accounts that have been inactive for a long time or modify
                    or discontinue our Services. Additionally, we reserve the right to refuse or terminate all or
                    part of our Services to anyone for any reason at our discretion.
                </p>

                <h4>Policy Enforcement</h4>
                <p>
                    When a buyer or seller issue arises, we may consider the user's performance history and the specific
                    circumstances in applying our policies. We may choose to be more lenient with policy enforcement in
                    an
                    effort to do the right thing for both buyers and sellers.
                </p>

                <h4>Fees</h4>
                <p>
                    The fees we charge for using our Services are listed on our <a>Standard Selling Fees page</a>. We
                    may
                    change
                    our
                    seller fees from time to time by posting the changes on the BiziBAZA&reg; site 14 days in advance, but
                    with
                    no advance notice required for temporary promotions or any changes that result in the reduction of
                    fees.
                    You must have a payment method on file when selling on BiziBAZA&reg; and pay all fees and applicable
                    taxes
                    associated with our Services by the payment due date. If your payment method fails or your account
                    is
                    past due, we may collect fees owed by charging other payment methods on file with us, retaining
                    collection agencies and legal counsel, and, for accounts over 180 days past due, requesting that
                    PayPal
                    deduct the amount owed from your PayPal account balance. In addition, you will be subject to <a>late
                    fees</a>.
                    BiziBAZA&reg;, or the collection agencies we retain, may also report information about your account to
                    credit bureaus, and as a result, late payments, missed payments, or other defaults on your account
                    may
                    be reflected in your credit report. If you wish to dispute the information BiziBAZA&reg; reported to a
                    credit bureau (i.e., Experian, Equifax or TransUnion) please contact us at BiziBAZA&reg;, C/O Global
                    Collections, 3527 Mt. Diablo Blvd #129., Lafayette, CA 94549. If you wish to dispute the information
                    a
                    collection agency reported to a credit bureau regarding your BiziBAZA&reg; account, you must contact the
                    collection agency directly.
                </p>
                <h4>Listing Conditions</h4>
                <p>
                    When listing an item, you agree to comply with BiziBAZA&reg; <a>rules for listing</a> and <a>Selling
                    Practices
                    policy</a>
                    and that:
                </p>
                <ul>
                    <li>
                        You are responsible for the accuracy and content of the listing and item offered;
                    </li>
                    <li>Your listing
                        may not be immediately searchable by keyword or category for several hours (or up to 24 hours in
                        some
                        circumstances).
                    </li>
                    <li>BiziBAZA&reg; can't guarantee exact listing durations;</li>
                    <li>Content that violates any of
                        BiziBAZA&reg; policies may be deleted at BiziBAZA&reg;'s discretion;
                    </li>
                    <li>We strive to create a marketplace where
                        buyers find what they are looking for.
                    </li>
                    <li>Therefore, the appearance or placement of listings in search
                        and
                        browse results will depend on a variety of factors, including, but not limited to:
                    </li>
                    <ul>
                        <li>buyer's location,
                            search query, browsing site, and history;
                        </li>
                        <li>item's location, listing format, price and shipping cost,
                            terms of service, end time, history, and relevance to the user query; seller's history,
                            including
                            listing practices, Detailed Seller Ratings, BiziBAZA&reg; compliance, Feedback, and defect rate;
                        </li>
                        <li>and
                            number
                            of listings matching the buyer's query. Accordingly, to drive a positive user experience, a
                            listing
                            may
                            not appear in some search and browse results regardless of the sort order chosen by the
                            buyer.
                        </li>
                    </ul>
                </ul>

                <p>Some advanced listing upgrades will only be visible on certain Services. </p>
                <p>BiziBAZA&reg; <a>Duplicate Listing Policy</a> may also affect whether your listing appears in search
                    results.
                </p>
                <p>
                    Metatags and URL links that are included in a listing may be removed or altered so as to not affect
                    third-party search engineresults.
                </p>
                <p>
                    We may provide you with optional recommendations to consider when creating your listings. Such
                    recommendations may be based on the aggregated sales and performance history of similar sold and
                    current
                    listings; results will vary for individual listings. To drive the recommendations experience,
                    you agree
                    that we may display the sales and performance history of your individual listings to other
                    sellers.
                </p>
                <h4>Purchase Conditions</h4>
                <p>When buying an item, you agree to the <a>rules for buyers</a> and that:</p>
                <ul>
                    <li>You are responsible for reading the full item listing before making a commitment to buy.</li>
                    <li>You enter into a legally binding contract to purchase an item when you commit to buy an item.
                    </li>
                </ul>

                <h4>International Buying and Selling; Translation</h4>
                <p>
                    Many of our Services are accessible to international sellers
                    and buyers. We may offer certain programs, tools, and site experiences of particular interest to
                    international sellers and buyers, such as estimated local currency conversion and international
                    shipping
                    calculation tools. Sellers and buyers are responsible for complying with all laws and regulations
                    applicable
                    to the international sale, purchase, and shipment of items. If you purchase an item on an BiziBAZA&reg;
                    site
                    other than BiziBAZA&reg;.com, you are subject to the User Agreement of that other BiziBAZA&reg; site with
                    respect to
                    that particular purchase.
                </p>
                <p>
                    You may list your items directly on one or more of BiziBAZA&reg;'s international
                    sites. Additionally, you may direct us to display your listing on BiziBAZA&reg; sites other than the
                    original
                    BiziBAZA&reg; listing site, including where you choose to list an item with an international shipping
                    option
                    (such as worldwide shipping or the Global Shipping Program): the appearance of your listings on
                    these
                    sites
                    is not guaranteed. When you sell your items internationally, you agree to be subject to that other
                    site's
                    User Agreement, policies, and the <a>BiziBAZA&reg; International Selling Policy</a>. You may restrict
                    international
                    buyers from purchasing your items if you explicitly exclude international shipping from your
                    listings
                    and
                    apply an exclusion list to your listings.
                </p>
                <p>
                    You authorize us to use automated tools to translate your
                    BiziBAZA&reg; content and member-to-member communications, in whole or in part, into local languages
                    where
                    such
                    translation solutions are available. We may provide you with tools which will enable you to
                    translate
                    content at your request. The accuracy and availability of any translation are not guaranteed.
                </p>
                <h4>Content</h4>
                <p>
                    When providing us with content or causing content to be posted using our Services, you grant us a
                    non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable (through multiple
                    tiers)
                    right
                    to exercise any and all copyright, publicity, trademark, and database rights and other intellectual
                    property
                    rights you have in the content, in any media known now or developed in the future. Further, to the
                    fullest
                    extent permitted under applicable law, you waive your moral rights and promise not to assert such
                    rights
                    or
                    any other intellectual property or publicity rights against us, our sublicensees, or our assignees.
                </p>
                <p>
                    You
                    represent and warrant that none of the following infringe any rights mentioned in the preceding
                    paragraph:
                    your provision of content to us, your causing content to be posted using the Services, and use of
                    any
                    such
                    content (including of works derived from it) by us, our users, or others in contract with us that is
                    done in
                    connection with the Services and in compliance with this User Agreement.
                </p>
                <p>
                    We may offer catalogs including
                    stock images, descriptions and product specifications that are provided by third-parties (including
                    users).
                    You may use catalog content solely in connection with your BiziBAZA&reg; listings. The permission to use
                    catalog
                    content is subject to modification or revocation at any time at BiziBAZA&reg;'s sole discretion.
                </p>
                <p>
                    While we try to
                    offer reliable data, we cannot promise that the catalogs will always be available, accurate, and
                    up-to-date,
                    and you agree that you will not hold our catalog providers or us responsible for inaccuracies. The
                    catalog
                    may include copyrighted, trademarked or other proprietary materials. You agree not to remove any
                    copyright,
                    proprietary or identification markings included within the catalogs or create any derivative works
                    based
                    on
                    catalog content (other than by including them in your listings).
                </p>
                <h4>Notice for Claims of Intellectual Property Violations and Copyright Infringement Pursuant to Section
                    512(c) of Title 17 of the United States Code
                </h4>
                <p>
                    We respond to notices of alleged copyright infringement under the United States Digital Millennium
                    Copyright Act. BiziBAZA&reg;'s works to ensure that listed items and content on our site or in our apps
                    do
                    not infringe upon the copyright, trademark, or other intellectual property rights of third parties.
                    If
                    you believe that your intellectual property rights have been infringed, please notify our team
                    through
                    and we will investigate.
                </p>


                <h4>Holds</h4>
                <p>
                    To protect BiziBAZA&reg; from risk of liability for your actions as a seller, BiziBAZA&reg; has at times
                    recommended, and may continue to recommend, that PayPal restrict access to funds in a seller's
                    PayPal
                    account based on certain factors, including, but not limited to, selling history, seller
                    performance,
                    returns, riskiness of the listing category, transaction value, or the filing of an BiziBAZA&reg; Money
                    Back
                    Guarantee case. This may result in PayPal restricting funds in your PayPal account.
                </p>

                <h4>Authorization to Contact You; Recording Calls</h4>
                <p>
                    BiziBAZA&reg; may contact you using autodialed or prerecorded calls and text messages, at any telephone
                    number that you have provided us, to: (i) notify you regarding your account; (ii) troubleshoot
                    problems
                    with your account; (iii) resolve a dispute; (iv) collect a debt; (v) poll your opinions through
                    surveys
                    or questionnaires; or (vi) as otherwise necessary to service your account or enforce this User
                    Agreement, our policies, applicable law, or any other agreement we may have with you. BiziBAZA&reg; may
                    also
                    contact you using autodialed or prerecorded calls and text messages for marketing purposes (e.g.,
                    offers
                    and promotions), if you consent to such communications. As described in our User Privacy Notice,
                    BiziBAZA&reg; may collect other telephone numbers for you and may place manual non-marketing calls to
                    any of
                    those numbers and autodialed non-marketing calls to any landline. Standard telephone minute and text
                    charges may apply and may include overage fees if you have exceeded your plan limits. If you do not
                    wish
                    to receive such communications, you may change your communications preference at any time.
                </p>
                <p>
                    BiziBAZA&reg; may
                    share your telephone number with its authorized service providers as stated in our User Privacy
                    Notice.
                    These service providers may contact you using autodialed or prerecorded calls and text messages,
                    only as
                    authorized by BiziBAZA&reg; to carry out the purposes we have identified above.</p>
                <p>
                    BiziBAZA&reg;may, without further notice or warning and in its discretion, monitor or record telephone
                    conversations
                    you or anyone acting on your behalf has with BiziBAZA&reg; or its agents for quality control and
                    training
                    purposes or for its own protection.
                </p>
                <h5>Privacy of Others; Marketing</h5>
                <p>
                    If BiziBAZA&reg; provides you with information about another user, you agree you will use the
                    information
                    only for the purposes it is provided to you. You may not disclose, sell, rent, or distribute a
                    user's
                    information to a third party for purposes unrelated to the Services. Additionally, you may not use
                    information for marketing purposes, via electronic or other means, unless you obtain the consent of
                    the
                    specific user to do so.
                </p>
                <h4>Additional Terms</h4>
                <h5>Returns and Cancellations</h5>
                <p>
                    Sellers can create rules to automate replacements, returns, and refunds under certain circumstances.
                    When an
                    item is returned, to refund the buyer, you (as seller) authorize BiziBAZA&reg; to instruct PayPal to
                    remove
                    the
                    refund amount (in same or other currency) from your PayPal account, place the amount on your
                    invoice,
                    and/or
                    charge your payment method on file.
                </p>
                <p>The cost of return shipping for an item that is not as described is the seller's responsibility.</p>
                <p>You (as seller) authorize BiziBAZA&reg; to place the return shipping label cost on your invoice, subject
                    to
                    your
                    automatic payment method on file when:</p>
                <ul>
                    <li>An BiziBAZA&reg;-generated return shipping label is used, and the seller is responsible for its
                        cost;
                    </li>
                    <li>Returns have been automated; and/or</li>
                    <li>You fail to send your buyer a return shipping label and,
                        instead, an BiziBAZA&reg;-generated shipping label is used.
                    </li>
                </ul>
                <p>
                    When a transaction is cancelled, to refund the
                    buyer, you (as seller) authorize BiziBAZA&reg; to instruct PayPal to remove the refund amount (in same
                    or
                    other currency) from your PayPal account. See our <a>Cancellation Policy</a> for more details.
                </p>
                <p>
                    Information, as
                    well as listing and order information, by BiziBAZA&reg; to Pitney Bowes Inc., and by Pitney Bowes Inc.
                    to
                    its affiliates, service providers, and other third parties (such as customs and revenue authorities,
                    as
                    well as other government agencies), in connection with the processing, export and customs clearance,
                    and
                    international transportation of any item. BiziBAZA&reg; does not control the privacy policies of Pitney
                    Bowes Inc., its affiliates, or its service providers, and you are subject to the privacy policies of
                    those parties, as applicable.
                </p>
                <p>
                    You may prevent your listings from being made available to international
                    buyers through the program by adjusting your account settings within My BiziBAZA&reg;. Additional
                    information about the program, including policies governing feedback, the handling of lost, damaged,
                    and
                    undeliverable items, returns, and the resolution of buyer protection claims for items that you sell
                    through the program, can be found on the <a>Global Shipping Program Seller Policies and Frequently
                    Asked
                    Questions page</a>.
                </p>
                <h5>BiziBAZA&reg; Money Back Guarantee</h5>
                <p>
                    Most BiziBAZA&reg; sales go smoothly, but if there's a
                    problem with a purchase, the BiziBAZA&reg; Money Back Guarantee helps buyers and sellers communicate and
                    resolve issues. You agree to comply with the policy and permit us to make a final decision on any
                    BiziBAZA&reg; Money Back Guarantee case.
                </p>
                <p>
                    If you (as seller), choose to reimburse a buyer, or are required to
                    reimburse a buyer or BiziBAZA&reg; under the BiziBAZA&reg; Money Back Guarantee, you authorize BiziBAZA&reg; to
                    request that PayPal remove the reimbursement amount (in same or other currency) from your PayPal
                    account, place the amount on your invoice, and/or charge your payment method on file. If we cannot
                    get
                    reimbursement from you, we may collect the outstanding sums using other collection mechanisms,
                    including
                    retaining collection agencies.
                </p>
                <p>
                    We may suspend the <a>BiziBAZA&reg; Money Back Guarantee</a> in whole or in part without notice if we
                    suspect
                    abuse
                    or interference with the proper working of the policy.
                </p>
                <h4>Disclaimer of Warranties; Limitation of Liability</h4>
                <p>
                    We try to keep our Services safe, secure, and functioning properly, but we cannot guarantee the
                    continuous operation of or access to our Services. Bid update and other notification functionality
                    in
                    BiziBAZA&reg;'s applications may not occur in real time. Such functionality is subject to delays beyond
                    BiziBAZA&reg;'s control.
                </p>
                <p>
                    You agree that you are making use of our Services at your own risk, and that they are being provided
                    to
                    you
                    on an "AS IS" and "AS AVAILABLE" basis. Accordingly, to the extent permitted by applicable law, we
                    exclude
                    all express or implied warranties, terms and conditions including, but not limited to, implied
                    warranties of
                    merchantability, fitness for a particular purpose, and non-infringement.
                </p>
                <p>
                    In addition, to the extent permitted by applicable law, we (including our parent, subsidiaries, and
                    affiliates, and our and their officers, directors, agents and employees) are not liable, and you
                    agree
                    not
                    to hold us responsible, for any damages or losses (including, but not limited to, loss of money,
                    goodwill or
                    reputation, profits, or other intangible losses or any special, indirect, or consequential damages)
                    resulting directly or indirectly from:
                </p>
                <ul>
                    <li>your use of or your inability to use our Services;</li>
                    <li>pricing, shipping, format, or other guidance provided by BiziBAZA&reg;;</li>
                    <li>delays or disruptions in our Services;</li>
                    <li>viruses or other malicious software obtained by accessing or linking to our Services;</li>
                    <li>glitches, bugs, errors, or inaccuracies of any kind in our Services; damage to your hardware
                        device
                        from
                        the use of any BiziBAZA&reg; Service;
                    </li>
                    <li>the content, actions, or inactions of third parties, including items listed using our Services
                        or
                        the
                        destruction of allegedly fake items;
                    </li>
                    <li>a suspension or other action taken with respect to your account or breach of the Abusing
                        BiziBAZA&reg;
                        Section above;
                    </li>
                    <li>the duration or manner in which your listings appear in search results as set out in the Listing
                        Conditions Section above;
                    </li>
                    <li>or your need to modify practices, content, or behavior or your loss of or inability to do
                        business,
                        as a
                        result of changes to this User Agreement or our policies.
                    </li>
                </ul>
                <p>
                    Some jurisdictions do not allow the disclaimer of warranties or exclusion of damages, so such
                    disclaimers
                    and
                    exclusions may not apply to you. Regardless of the previous paragraphs, if we are found to be
                    liable,
                    our
                    liability to you or to any third party is limited to the greater of (a) any amounts due under the
                    BiziBAZA&reg;
                    Money Back Guarantee up to the price the item sold for on BiziBAZA&reg; (including any applicable sales
                    tax)
                    and
                    its original shipping costs, (b) the amount of fees in dispute not to exceed the total fees, which
                    you
                    paid
                    to us in the 12 months prior to the action giving rise to the liability, or (c) $100.
                </p>

                <h4>Release</h4>
                <p>
                    If you have a dispute with one or more users, you release us (and our affiliates and subsidiaries,
                    and
                    our
                    and their respective officers, directors, employees and agents) from claims, demands and damages
                    (actual
                    and
                    consequential) of every kind and nature, known and unknown, arising out of or in any way connected
                    with
                    such
                    disputes.
                    <b>In entering into this release you expressly waive any protections (whether statutory or
                        otherwise)
                        that
                        would otherwise limit the coverage of this release to include only those claims which you may
                        know
                        or
                        suspect to exist in your favor at the time of agreeing to this release.</b>
                </p>
                <h4>Indemnity</h4>
                <p>
                    You will indemnify and hold us (and our affiliates and subsidiaries, and our and their respective
                    officers,
                    directors, employees, agents) harmless from any claim or demand, including reasonable legal fees,
                    made
                    by
                    any third party due to or arising out of your breach of this Agreement, your improper use of
                    BiziBAZA&reg;'s
                    Services or your breach of any law or the rights of a third party.
                </p>
                <h4>Legal Disputes</h4>
                <h5>PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND WILL HAVE A SUBSTANTIAL IMPACT ON HOW
                    CLAIMS
                    YOU AND BiziBAZA&reg; HAVE AGAINST EACH OTHER ARE RESOLVED.</h5>
                <p class="important-text">
                    You and BiziBAZA&reg; agree that any claim or dispute at law or equity that has arisen or may arise
                    between
                    us
                    relating in any way to or arising out of this or previous versions of the BiziBAZA&reg; User Agreement,
                    your
                    use
                    of or access to the Services, or any products or services sold or purchased through the Services,
                    will
                    be
                    resolved in accordance with the provisions set forth in this Legal Disputes Section.
                </p>
                <h5>A. Applicable</h5>
                <p>
                    Law You agree that, except to the extent inconsistent with or preempted by federal law, the laws of
                    the
                    State of Utah, without regard to principles of conflict of laws, will govern the User Agreement and
                    any
                    claim or dispute that has arisen or may arise between you and BiziBAZA&reg;, except as otherwise stated
                    in
                    the
                    User Agreement.
                </p>
                <h5>B. Agreement to Arbitrate</h5>
                <p>
                    <b>
                        You and BiziBAZA&reg; each agree that any and all disputes or claims that have arisen
                        or may arise between you and BiziBAZA&reg; relating in any way to or arising out of this or previous
                        versions of the User Agreement, your use of or access to BiziBAZA&reg;'s Services, or any products
                        or
                        services sold, offered, or purchased through BiziBAZA&reg;'s Services shall be resolved exclusively
                        through
                        final and binding arbitration, rather than in court. Alternatively, you may assert your claims
                        in small
                        claims court, if your claims qualify and so long as the matter remains in such court and
                        advances only
                        on an individual (non-class, non-representative) basis.</b> The Federal Arbitration Act governs
                    the interpretation and enforcement of this Agreement to Arbitrate.
                </p>
                <ul class="numeric-list">
                    <li><b>Prohibition of Class and Representative</b>Actions and Non-Individualized Relief YOU AND
                        BiziBAZA&reg;
                        AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS
                        A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
                        UNLESS BOTH YOU AND BiziBAZA&reg; AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE
                        THAN ONE PERSON'S OR PARTY'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
                        CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF
                        (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY
                        SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S
                        INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER USERS.
                    </li>
                    <li><b>Arbitration Procedures</b>
                        <p>Arbitration is more informal than a lawsuit in court. Arbitration
                        uses a neutral arbitrator instead of a judge or jury, and court review of an arbitration award
                        is very limited. However, an arbitrator can award the same damages and relief on an individual
                        basis that a court can award to an individual. An arbitrator should apply the terms of the User
                        Agreement as a court would. All issues are for the arbitrator to decide, except that issues
                        relating to arbitrability, the scope or enforceability of this Agreement to Arbitrate, or the
                        interpretation of Section 1 of this Agreement to Arbitrate ("Prohibition of Class and
                        Representative Actions and Non-Individualized Relief"), shall be for a court of competent
                            jurisdiction to decide.</p>
                        <p>The arbitration will be conducted by the American Arbitration Association ("AAA") under its
                            rules and procedures, including the AAA's Consumer Arbitration Rules (as applicable), as
                            modified by this Agreement to Arbitrate. The AAA's rules are available at <a target="_blank" href="https://www.adr.org/">www.adr.org</a> or by
                            calling the AAA at 1-800-778-7879. The use of the word "arbitrator" in this provision shall
                            not be construed to prohibit more than one arbitrator from presiding over an arbitration:
                            rather, the AAA's rules will govern the number of arbitrators that may preside over an
                            arbitration conducted under this Agreement to Arbitrate.</p>
                        <p>A party who intends to seek arbitration must first send to the other, by certified mail, a
                            completed form Notice of Dispute ("Notice"). You may download a form Notice <a>here</a>. The Notice
                            to BiziBAZA&reg; should be sent to BiziBAZA&reg; Inc., Attn: Litigation Department, Re: Notice of
                            Dispute, 583 W. BiziBAZA&reg; Way, Draper, UT 84020. BiziBAZA&reg; will send any Notice to you to
                            the physical address we have on file associated with your BiziBAZA&reg; account; it is your
                            responsibility to keep your physical address up to date. All information called for in the
                            Notice must be provided, including a description of the nature and basis of the claims the
                            party is asserting and the relief sought.</p>
                        <p>If you and BiziBAZA&reg; are unable to resolve the claims described in the Notice within 30 days
                            after the Notice is sent, you or BiziBAZA&reg; may initiate arbitration proceedings. A form for
                            initiating arbitration proceedings is available on the AAA's site at www.adr.org. In
                            addition to filing this form with the AAA in accordance with its rules and procedures, the
                            party initiating the arbitration must mail a copy of the completed form to the opposing
                            party. You may send a copy to BiziBAZA&reg; at the following address: BiziBAZA&reg;, Inc. c/o CT
                            Corporation System, 1108 E South Union Ave., Midvale, UT 84047. In the event BiziBAZA&reg;
                            initiates an arbitration against you, it will send a copy of the completed form to the
                            physical address we have on file associated with your BiziBAZA&reg; account. Any settlement
                            offer made by you or BiziBAZA&reg; shall not be disclosed to the arbitrator.</p>
                        <p>The arbitration hearing shall be held in the county in which you reside or at another
                            mutually agreed location. If the value of the relief sought is $10,000 or less, you or
                            BiziBAZA&reg; may elect to have the arbitration conducted by telephone or based solely on
                            written submissions, which election shall be binding on you and BiziBAZA&reg; subject to the
                            arbitrator's discretion to require an in-person hearing, if the circumstances warrant. In
                            cases where an in-person hearing is held, you and/or BiziBAZA&reg; may attend by telephone,
                            unless the arbitrator requires otherwise.</p>
                        The arbitrator will decide the substance of all claims in accordance with applicable law,
                            including recognized principles of equity, and will honor all claims of privilege recognized
                            by law. The arbitrator shall not be bound by rulings in prior arbitrations involving
                            different users, but is bound by rulings in prior arbitrations involving the same BiziBAZA&reg;
                            user to the extent required by applicable law. The arbitrator's award shall be final and
                            binding and judgment on the award rendered by the arbitrator may be entered in any court
                            having jurisdiction thereof.
                    </li>
                    <li><b>Costs of Arbitration</b>Payment of all filing, administration and arbitrator fees will be
                        governed by the AAA's rules, unless otherwise stated in this Agreement to Arbitrate. If the
                        value of the relief sought is $10,000 or less, at your request, BiziBAZA&reg; will pay all filing,
                        administration, and arbitrator fees associated with the arbitration. Any request for payment of
                        fees by BiziBAZA&reg; should be submitted by mail to the AAA along with your Demand for Arbitration
                        and BiziBAZA&reg; will make arrangements to pay all necessary fees directly to the AAA. In the event
                        the arbitrator determines the claim(s) you assert in the arbitration to be frivolous, you agree
                        to reimburse BiziBAZA&reg; for all fees associated with the arbitration paid by BiziBAZA&reg; on your
                        behalf that you otherwise would be obligated to pay under the AAA's rules.
                    </li>
                    <li><b>Severability</b>With the exception of any of the provisions in Section 1 of this Agreement to
                        Arbitrate ("Prohibition of Class and Representative Actions and Non-Individualized Relief"), if
                        an arbitrator or court decides that any part of this Agreement to Arbitrate is invalid or
                        unenforceable, the other parts of this Agreement to Arbitrate shall still apply. If an
                        arbitrator or court decides that any of the provisions in Section 1 of this Agreement to
                        Arbitrate ("Prohibition of Class and Representative Actions and Non-Individualized Relief") is
                        invalid or unenforceable, then the entirety of this Agreement to Arbitrate shall be null and
                        void. The remainder of the Agreement and its Legal Disputes Section will continue to apply.
                    </li>
                    <li><b>Opt-Out Procedures IF YOU ARE A NEW BiziBAZA&reg; USER, YOU CAN CHOOSE TO REJECT THIS AGREEMENT
                        TO ARBITRATE ("OPT-OUT") BY MAILING US A WRITTEN OPT-OUT NOTICE ("OPT-OUT NOTICE"). THE OPT-OUT
                        NOTICE MUST BE POSTMARKED NO LATER THAN 30 DAYS AFTER THE DATE YOU ACCEPT THE USER AGREEMENT FOR
                        THE FIRST TIME. YOU MUST MAIL THE OPT-OUT NOTICE TO BiziBAZA&reg; INC., ATTN: LITIGATION DEPARTMENT,
                        RE: OPT-OUT NOTICE, 583 WEST BiziBAZA&reg; WAY, DRAPER, UT 84020.</b>
                        <p>
                        For your convenience, we are providing an <a>Opt-Out Notice form</a> you must complete and mail to
                            opt out of the Agreement to Arbitrate. You must complete the Opt-Out Notice form by
                            providing the information called for in the form, including your name, address (including
                            street address, city, state and zip code), and the user ID(s) and email address(es)
                            associated with the BiziBAZA&reg; account(s) to which the opt-out applies. You must sign the
                            Opt-Out Notice for it to be effective. This procedure is the only way you can opt out of the
                            Agreement to Arbitrate. If you opt out of the Agreement to Arbitrate, all other parts of the
                            Agreement and its Legal Disputes Section will continue to apply to you. Opting out of this
                            Agreement to Arbitrate has no effect on any previous, other, or future arbitration
                            agreements that you may have with us.
                        </p>
                    </li>
                    <li><b>Future Amendments to the Agreement to Arbitrate</b> Notwithstanding any provision in the User
                        Agreement to the contrary, you and we agree that if we make any amendment to this Agreement to
                        Arbitrate (other than an amendment to any notice address or site link provided herein) in the
                        future, that amendment shall not apply to any claim that was filed in a legal proceeding against
                        BiziBAZA&reg; prior to the effective date of the amendment. The amendment shall apply to all other
                        disputes or claims governed by the Agreement to Arbitrate that have arisen or may arise between
                        you and BiziBAZA&reg;. We will notify you of amendments to this Agreement to Arbitrate by posting
                        the amended terms on www.BiziBAZA&reg;.com at least 30 days before the effective date of the
                        amendments and by providing notice through the BiziBAZA&reg; Message Center and/or by email. If you
                        do not agree to these amended terms, you may close your account within the 30 day period and you
                        will not be bound by the amended terms.
                    </li>
                </ul>
                <h5>C. Judicial Forum for Legal Disputes</h5>
                <p>Unless you and we agree otherwise, in the event that the
                    Agreement to Arbitrate above is found not to apply to you or to a particular claim or dispute,either
                    as a result of your decision to opt out of the Agreement to Arbitrate or as a result of adecision by
                    the arbitrator or a court order, you agree that any claim or dispute that has arisen ormay arise
                    between you and BiziBAZA&reg; must be resolved exclusively by a state or federal court locatedin Salt
                    Lake County, Utah. You and BiziBAZA&reg; agree to submit to the personal jurisdiction of thecourts
                    located within Salt Lake County, Utah for the purpose of litigating all such claims ordisputes.
                </p>
                <h4>General</h4>
                <p>111TECHS.COM LLC., 3527 Mt. Diablo Blvd #129., Lafayette, CA 94549. Except as otherwise provided in this Agreement, if any provision of this User Agreement is held to be invalid, void or for any reason unenforceable, such provision shall be struck out and shall not affect the validity and enforceability of the remaining provisions. In our sole discretion, we may assign this User Agreement, by providing notice of such assignment in accordance with the Notices Section.</p>
                <p>Headings are for reference purposes only and do not limit the scope or extent of such Section. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against all breaches of this User Agreement.</p>
                <p>We may amend this User Agreement at any time by posting the amended terms on www.BiziBAZA&reg;.com. Our right to amend the User Agreement includes the right to modify, add to, or remove terms in the User Agreement. We will provide you 30 days' notice by posting the amended terms. Additionally, we will notify you through the BiziBAZA&reg; Message Center and/or by email. Your continued access or use of our Services constitutes your acceptance of the amended terms. We may also ask you to acknowledge your acceptance of the User Agreement through an electronic click-through. This User Agreement may not otherwise be amended except through mutual agreement by you and an BiziBAZA&reg; representative who intends to amend this User Agreement and is duly authorized to agree to such an amendment.</p>
                <p>The policies posted on our sites may be changed from time to time. Changes take effect when we post them on the BiziBAZA&reg; site. If you create or use an account on behalf of a business entity, you represent that you are authorized to act on behalf of such business and bind the business to this User Agreement. Such account is owned and controlled by the business entity. No agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by this User Agreement.</p>
                <p>The User Agreement, the User Privacy Notice, and all polices set forth the entire understanding and agreement between you and BiziBAZA&reg; and supersede all prior understandings and agreements of the parties. The following Sections survive any termination of this User Agreement: Fees, Content, Disclaimer of Warranties; Limitation of Liability; Indemnity, and Legal Disputes.</p>
                <p>If you are a California resident, in accordance with Cal. Civ. Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.</p>            
                <p>Pursuant to 815 ILCS 414/1.5(c), for transactions involving tickets to events in Illinois, buyers and sellers may elect to submit complaints against one another to the American Arbitration Association ("AAA") under its rules and procedures. The AAA's rules are available at <a target="_blank" href="https://www.adr.org/">www.adr.org</a>. Such complaints shall be decided by an independent arbitrator in accordance with this User Agreement. Buyers and sellers further agree to submit to the jurisdiction of the State of Illinois for complaints involving a ticketed event held in Illinois.</p>
            </div>
        </div>
    `
})

export class AboutComponent { }
