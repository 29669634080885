

    <ul
       class="shopping-list__ul"
       [ngClass]="className"
       [sortablejs]="list"
       [sortablejsOptions]="dragAndDropEventOptions">

            <li
                *ngFor="let item of list; trackBy: trackByID; let i = index;"
                (click)="!item.is_custom && showActiveItems(item)"
                [className]="item.is_custom ? 'customItem item' : 'existingItem item'"
                [attr.data-test-id]="'shoppingListProduct_'+ index + '_' + i"
                [style.cursor]="'default'">

                <span class="handle" [attr.data-test-id]="'sortableHandle_'+ index + '_' + i"><i class="icon-menu"></i></span>

                <!--TITLE-->
                <p *ngIf="!item.is_custom" class="list__column item-title"
                   [attr.data-test-id]="'shoppingListProductName_' + index + '_' + i">{{item.descriptor}}</p>

                <button type="button" *ngIf="item.is_custom" class="list__column item-title custom-item"
                   [attr.data-test-id]="'shoppingListItemTitle_' + index + '_' + i"
                   (click)="startRenaming(item)"
                >
                   {{item.name}}
                </button>

                <!--CONTROLS-->
                <div class="item__control">
                    <button type="button" (click)="toggleItemStatus(item, list, $event)" [attr.data-test-id]="'statusBtn_' + index + '_' + i">
                        <i *ngIf="className === 'shopping-list__custom'" class="icon-ok"></i>
                        <i *ngIf="className === 'shopping-list__custom inactive'" class="icon-plus"></i></button>

                    <button type="button" (click)="removeItem(item, list, $event)" [attr.data-test-id]="'removeBtn_' + index + '_' + i">
                        <i class="icon-trash-empty"></i></button>
                </div>
            </li>
        </ul>
    