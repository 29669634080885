import {
    Component, Input, Output,
    EventEmitter, OnInit
}                           from '@angular/core';

import { UnitInterface }    from '../../interfaces/unit.interface';




@Component({
    selector:   'quantity-select',
    styleUrls:  ['../product-editor.sass'],

    template:   `
        <label class="input__label input__select-style no-arrow clearfix">
            <select 
                *ngIf="units"
                #Select  
                [name]="_name" 
                [disabled]="isDisabled"
                [attr.data-test-id]="_name"
                [style.color]="Select.selectedIndex === 0 ? '#ddd' : 'inherit'"
                [class.disabled-field]="isDisabled"
                (change)="updateItem(_name, Select.value)"
                (change)="updateItem(_ID, Select.options[Select.selectedIndex].dataset.id)"
            >
                
                <option disabled selected value> {{ default }} </option>
                <option class="option"  *ngFor="let unit of units"
                        [ngValue]="unit.unit_name"
                        [attr.data-id]="unit.ID"
                        [attr.data-test-id]="unit.unit_name"
                        [selected]="unit.unit_name === value"
                        [disabled]="!unit.ID">
                    {{unit.unit_name}}</option>

            </select>
        </label>
    `
})
export class QuantitySelectComponent implements  OnInit {
    /**
     * Widget to set/update item quantity unit value and quantity unit ID.
     * @emits two changeEvent$: on unit value and its ID.
     */

    @Input()    isDisabled = false;
    @Input()    name:           string;
    @Input()    units:          UnitInterface[];
    @Input()    default:        string;
    @Input()    value:          string;
    @Output()   changeEvent$    = new EventEmitter();



    _ID:        string;
    _name:      string;



    /**
     * The ID and name initial preset.
     */
    ngOnInit() {
        this._ID    = this.name + 'ID';
        this._name  = this.name + 'Name';
    }



    /**
     * @emits changeEvent$ on change.
     * @param key
     * @param value
     */
    updateItem(key: string, value: string): void {
        this.changeEvent$.emit([key, value]);
    }

}
