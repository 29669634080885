import { Injectable }               from '@angular/core';
import { Router }                   from '@angular/router';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';

import {
    CategoryInterface,
    CategoryBasicInfo, SubcategoryInterface, SubcategoryEntryInterface, SubcategoryViewModelInterface
} from '../interfaces/category.interface';
import { ErrorInterface, ErrorService } from '../services/error.service';
import { ProductEditorService } from '../product-editor/product-editor.service';
import {Tag} from '../bizipix-editor/tag.component';
import {BizipixTagInterface} from '../interfaces/bizipix.interface';


@Injectable()
export class GoodsNavService {

    category:   CategoryInterface;
    isSeller:   boolean;
    private isSheet:    boolean;
    private itemBillet: CategoryBasicInfo;

    constructor(
        private errorService:           ErrorService,
        private productEditorService:   ProductEditorService,
        private router:                 Router,
    ) { }



    showNavigation(category: CategoryInterface, isSeller: boolean, isSheet?: boolean) {
        this.isSheet    = isSheet;
        this.category   = category;
        this.isSeller   = isSeller;

        isSeller
            ? this.router.navigate(['/inventory-nav'])
            : this.router.navigate(['/goods-nav']);
    }


    createItem(obj: CategoryBasicInfo) {

        if (this.isSheet) {
            this.itemBillet = obj;
            this.router.navigate(['/inventory-sheet/create-inventory']);

        } else this.productEditorService.createItem(obj);
    }


    getItemBillet(): CategoryBasicInfo {
        return this.itemBillet;
    }



    getCategories() {
        return {
            category:   this.category,
            isSeller:   this.isSeller
        };
    }

    adapter(subcats: SubcategoryInterface[]): SubcategoryViewModelInterface[] {
        let newsubcats: SubcategoryViewModelInterface[] = new Array;
        subcats.forEach((s: SubcategoryInterface) => {
            newsubcats.push(new SubcategoryViewModel(s.ID, s.sub_category_name, s.sub_category_entries, false));
        });

        return newsubcats;
    }



    errorHandler(error: ErrorInterface) {
        this.errorService.handleError(error);
    }

}


export class SubcategoryViewModel {
    constructor(
        public ID: string,
        public sub_category_name: string,
        public sub_category_entries: SubcategoryEntryInterface[],
        public _isVisible: boolean
    ) { }
}
