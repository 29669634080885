import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { UnitInterface } from './unit.interface';
import { EventInterface } from './market.interface';
import { Event, ItemToCreate } from '../../../swagger-gen__output_dir';

export interface SafeProductEvent {
   event: EventInterface;
}

export interface SaveProduct {
   item: SellingItemResponseBody;
   event?: SafeProductEvent;
   callback?: () => void;
}

export interface ProductView {
    readOnly?: boolean;
    isInstantBuy?: boolean;
    desiredQuantity?: number;
    idOrder?: string;
    date?: number;
    price?: number;
    nameUnit?: string;
    item?: SellingItemResponseBody;
}

export interface ProductDetailsOptionsInterface {
    readOnly?:          boolean;
    desiredQuantity?:   number;
    idOrder?:           string;
    date?:              number;
    price?:             number;
    nameUnit?:          string;
}

export interface ProductDetailsViewInterface extends SellingItemResponseBody {
    isInWatchList: boolean;
    isInFavList: boolean;
}

export interface ProductReport {
   ID: string;
   item_id: string;
   reporter_id: string;
   reporter_name: string;
   date_time: string;
}

export interface ProductReports {
   reports: ProductReport[];
}

export interface ProductEditorContextCallback {
   saveItem?: SaveProduct;
   createEvent?: SellingItemResponseBody;
   removeEvent?: number;
   updateEvent?: SellingItemResponseBody;
   showEvent?: SafeProductEvent;
   exitEventEditor?: null;
   addEvent?: null;
   cancelEventCreation?: null;
   eventCallback?: Function;
}

export interface ProductEditorContext {
   item: SellingItemResponseBody;
   units: UnitInterface[];
   dateRange: DateRange;

   callback?({}: ProductEditorContextCallback);
}

export interface DateRange {
    from: number;
    to: number;
}
export interface ProductEditorItemToCreate extends ItemToCreate {
    events?: Event[];
}

export enum ProductEditorType {
   newItem = 'newItem',
   existingItem = 'existingItem',
   existingEvent = 'existingEvent',
   newEvent = 'newEvent',
}
