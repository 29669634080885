
        <div class="component">

            <watch-list-header></watch-list-header>

            <div class="component__container watch-list__container">

                <h3 *ngIf="!items?.length" class="empty-list">{{ "watchlist.empty" | translate }}</h3>

                <app-fav-watch-list
                   [items]="items"
                   [type]="'watch'"
                   (showItemInfo$)="showItemInfo($event)"
                   (removeItem$)="removeItem($event)"
                ></app-fav-watch-list>
            </div>
        </div>
    