
        <product-search-list
            (addItemToSL$)="addToShoppingListEvent($event)"
            [items]="items['nearest']"
            [search_from]="search_from"
            [shoppingList]="shoppingList"
            [shoppingCartElements]="shoppingCartElements"
            [title]="'product.search.list.title.nearest' | translate"></product-search-list>

        <product-search-list
            (addItemToSL$)="addToShoppingListEvent($event)"
            [items]="items['other']"
            [search_from]="search_from"
            [shoppingList]="shoppingList"
            [shoppingCartElements]="shoppingCartElements"
            [title]="'product.search.list.title.other' | translate"></product-search-list>
    