import {
    Component, OnInit,
    ViewChild, ViewContainerRef,
    ComponentFactoryResolver,
    ComponentRef, ElementRef,
    OnDestroy
}                                   from '@angular/core';


import { BizipixCardComponent }     from './bizipix-card.component';
import { BizipixItemsInterface,
    BizipixTagInterface }           from '../interfaces/bizipix.interface';
import { BizipixViewerService }     from './bizipix-viewer.service';



@Component({
    selector:   'bizipix-viewer',
    styleUrls:  ['bizipix-viewer.sass'],

    template:   `
        <div #container class="bp__container">

            <div class="bp__close" (click)="closeViewer()" data-test-id="closeButton">&times;</div>

            <div class="bp__image" #myBounds>
                 <div *ngIf="imageData.length && currentBizipix" class="ngx-dnd-container bp__cursor"
                 ngDraggable (started)="findDimensions()" (touchmove)="onPositionChange($event)" (mousemove)="onPositionChange($event)"
                 [bounds]="myBounds" [inBounds]="true"  data-test-id="cursor"></div>

                <img *ngIf="imageData.length && currentBizipix" [src]="currentBizipix.image_url"
                     data-test-id="bizipixImage" alt="bizipix image">
                <img *ngIf="!imageData.length && !currentBizipix" [src]="'../../assets/images/cart/no_image_icon.png'"
                     data-test-id="bizipixImage" alt="bizipix instruction">
            </div>

             <bizipix-menu *ngIf="imageData.length && currentBizipix" class="bizipix-menu" [type]="editable" [currentBizipix]="currentBizipix" [imageData]="imageData"
                          (selectedBizipixEvent$)="selectedBizipixEvent($event)"></bizipix-menu>


        </div>
    `
})


export class BizipixViewerComponent implements OnInit, OnDestroy {
    /**
     * Viewer component for BiziPix feature.
     * @decs Displays a "Bizipix" image on the back. The user moves the circle cursor. When circle
     * comes to some product area, compiles a card component in.
     */
    CARD_HEIGHT         = 145;
    CARD_WIDTH          = 155;

    card:               BizipixCardComponent;
    currentTag:         BizipixTagInterface;
    imageData:          BizipixItemsInterface[];
    currentBizipix:     BizipixItemsInterface;
    imageDimensions     = {w: 0, h: 0};
    limitations:        {};
    editable            = false;
    parentDimensions    = {w: 0, h: 0};
    viewportDimensions  = {w: 0, h: 0};

    private componentRef: ComponentRef<{}>;


    @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;



    constructor(
        private bizipixService:             BizipixViewerService,
        private componentFactoryResolver:   ComponentFactoryResolver,
        private elementRef:                 ElementRef
    ) { }


    /**
     * Gets BiziPix image object from the service. Calls to check the orientation.
     */
    ngOnInit(): void {
        this.imageData = this.bizipixService.getImageData();

        if (this.imageData.length) {
            this.currentBizipix = this.imageData[0];
            this.bizipixService.openPageDetection('bizipix_open');
        }

        /** For call the GA event everytime when BiziPix view is show **/
        // this.bizipixService.prepareBeforeGoogleAnalytics('viewer');
    }


    /**
     * Determines the dimensions for the viewport, component host and the image.
     */
    findDimensions(): void {
        const elem = this.elementRef.nativeElement.querySelector('.bp__image img');
        const port = this.elementRef.nativeElement.querySelector('.bp__container');

        this.viewportDimensions = {
            w: document.documentElement.clientWidth,
            h: document.documentElement.clientHeight
        };

        this.parentDimensions = {
            w: parseFloat(port.clientWidth),
            h: parseFloat(port.clientHeight)
        };

        this.imageDimensions = {
            w: parseFloat(elem.clientWidth),
            h: parseFloat(elem.clientHeight)
        };

        this.limitations = {
            bottom:             this.viewportDimensions.h - this.CARD_HEIGHT,
            verticalPadding:    0.5 * (this.viewportDimensions.h - this.imageDimensions.h),
            horizontalPadding:  0.5 * (this.parentDimensions.w - this.imageDimensions.w),
            horizontalMargin:   0.5 * (this.viewportDimensions.w - this.parentDimensions.w)
        };
    }


    /**
     * @desc event after click on bizipixMenuComponent
     * @param {{previouslyBizipix: BizipixInterface; currentBizipix: BizipixInterface; imageData: BizipixInterface}} event
     */
    selectedBizipixEvent(event: { previouslyBizipix: BizipixItemsInterface, currentBizipix: BizipixItemsInterface, imageData: BizipixItemsInterface }): void {
        this.currentBizipix = event.currentBizipix;
        this._removeAllCards();
    }


    /**
     * @desc Fires on the BiziPix cursor move. Calls to check for intersection, if any -- calls
     * to render the card.
     * @param {MouseEvent} event
     */
    onPositionChange(event: MouseEvent | TouchEvent): void {
        const touchEvent: TouchEvent = (event as unknown as TouchEvent);
        let coordinates: {x: number, y: number} = this._getCenterCoordinates(event as MouseEvent);
        if (touchEvent.touches) {
            const touch: Touch = touchEvent.touches[0];
            coordinates = this._getCenterCoordinates(touch);
        }

        if (this.limitations) {
            for (let i = 0; i < this.currentBizipix.tags.length; i++) {
                if (this._doesIntersect(coordinates as {x: number, y: number}, this.currentBizipix.tags[i])) {
                    this._showCard(this.currentBizipix.tags[i]);
                    return;
                }
            }
        }
    }

    _getCenterCoordinates(event: MouseEvent | Touch): {x: number, y: number} {
        return {x: event.pageX, y: event.pageY};
    }


    /**
     * @desc Dynamically compiles a card component into the component. Passes the tag data
     * to the card component.
     * @param tag
     * @private
     */
    _showCard(tag: BizipixTagInterface | null): void {
        if (this.currentTag === tag) return;
        this.currentTag     = tag;

        const factory       = this.componentFactoryResolver.resolveComponentFactory(BizipixCardComponent);
        this.componentRef   = this.container.createComponent(factory);


        this.card           = <BizipixCardComponent> this.componentRef.instance;
        this.card.context   = Object.assign(
            {},
            tag,
            {callback: this.bizipixService.showProductDetails.bind(this.bizipixService)},
            this._setCardPosition(tag)
        );

        this._removeNoActiveCards(tag.item_id);
    }


    /**
     * Get all generated of bizipixCard elements
     * @returns {NodeListOf<Element>}
     * @private
     */
    _getAllBizipixCard(): NodeListOf<Element> {
        return this.elementRef.nativeElement.querySelectorAll('bizipix-card');
    }


    /**
     * Removes unrelated card components.
     * @param cardID
     * @private
     */
    _removeNoActiveCards(cardID?: string): void {
        setTimeout(() => {
            let cards = this._getAllBizipixCard();

            Array.from(cards).forEach((card: HTMLElement) => {
                if (card.dataset.item_id !== cardID) card.remove();
            });
        });
    }


    /**
     * @desc Remove and destroy BizipixCard Component
     * @private
     */
    _removeAllCards(): void {
        setTimeout(() => {
            this.container.clear();
        });
    }


    /**
     * Calculates the card position, considering dimensions and limitations.
     * @param tag
     * @returns {{left: number, bottom: any}}
     * @private
     */
    _setCardPosition(tag: BizipixTagInterface | null): {left: number, bottom: any} {
        if (!tag) return;

        const pos = tag.tag_position;
        const rightStop = this.viewportDimensions.w - this.limitations['horizontalMargin'] - this.CARD_WIDTH;

        let relativeLeft    = pos.top_left_x + (pos.bottom_right_x - pos.top_left_x) / 2;
        let relativeBottom  = 1 - (pos.top_left_y + ((pos.bottom_right_y - pos.top_left_y) / 2));

        let left    = relativeLeft * this.imageDimensions.w + this.limitations['horizontalPadding'] - this.CARD_WIDTH / 2;
        let bottom  = relativeBottom * this.imageDimensions.h + this.limitations['verticalPadding'];

        if (left < 0) left = 0;
        if (left > rightStop) left = rightStop;
        if (bottom > this.limitations['bottom']) bottom  = this.limitations['bottom'];

        return {left, bottom};
    }


    /**
     * Checks if the BiziPix cursor intersects any of product areas.
     * @param coords
     * @param tag
     * @returns {boolean}
     * @private
     */
    _doesIntersect(coords: {x: number, y: number}, tag: BizipixTagInterface): boolean {
        let {x, y} = this._calculateRelativeCoordinates(coords);

        const pos = tag.tag_position;

        return x > pos.top_left_x && x < pos.bottom_right_x
            && y < pos.bottom_right_y && y > pos.top_left_y;
    }


    /**
     * Transforms absolute coordinates to relative.
     * @param coords
     * @returns {{x: any, y: any}}
     * @private
     */
    _calculateRelativeCoordinates(coords: {x: number, y: number}): {x: number, y: number} {
        let x, y;

        x = (coords.x - this.limitations['horizontalPadding']
            - this.limitations['horizontalMargin']) / this.imageDimensions.w;

        y = (coords.y - this.limitations['verticalPadding']) / this.imageDimensions.h;

        return {x, y};
    }


    /**
     * Calls the service to close the viewer.
     */
    closeViewer(): void {
        this.bizipixService.closeViewer();
    }


    /**
     * @desc destroy BizipixViewerComponent
     */
    ngOnDestroy() {
        this.componentRef && this.componentRef.destroy();
    }

}
