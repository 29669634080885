/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../product-editor.sass.shim.ngstyle";
import * as i1 from "../../../assets/styles/sprite.sass.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./event-widget.component";
var styles_EventWidgetComponent = [i0.styles, i1.styles];
var RenderType_EventWidgetComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_EventWidgetComponent, data: {} });
export { RenderType_EventWidgetComponent as RenderType_EventWidgetComponent };
function View_EventWidgetComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "section", [["class", "product-editor__fields event-widget"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "h3", [["class", "event-widget__title"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEventClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(2, null, [" ", " "])), (_l()(), i2.ɵeld(3, 0, null, null, 1, "span", [["class", "event-widget__remove"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-trash-empty"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveClick(_v.context.index, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = ("eventTitle_" + _v.context.index); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = ("removeEvent_" + _v.context.index); _ck(_v, 4, 0, currVal_2); }); }
export function View_EventWidgetComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "section", [["class", "product-editor__fields"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 3, "span", [["class", "product-editor__add-event"], ["data-test-id", "addEventElem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Add Event"])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-plus-circle"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_EventWidgetComponent_1)), i2.ɵdid(7, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.events; var currVal_1 = _co.trackByID; _ck(_v, 7, 0, currVal_0, currVal_1); }, null); }
export function View_EventWidgetComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "event-widget", [], null, null, null, View_EventWidgetComponent_0, RenderType_EventWidgetComponent)), i2.ɵdid(1, 49152, null, 0, i4.EventWidgetComponent, [], null, null)], null, null); }
var EventWidgetComponentNgFactory = i2.ɵccf("event-widget", i4.EventWidgetComponent, View_EventWidgetComponent_Host_0, { events: "events" }, { eventWidgetEvent$: "eventWidgetEvent$" }, []);
export { EventWidgetComponentNgFactory as EventWidgetComponentNgFactory };
