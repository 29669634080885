import {
    Component,
    EventEmitter, Input,
    OnInit, Output
}                                       from '@angular/core';

import { MarketInterface }              from '../../interfaces/market.interface';
import { UserModel }                    from '../../interfaces/user.interface';


@Component({
    selector:   'map-info',
    styleUrls:  ['../../map-search/map-search.sass'],

    template:   `
        <div class="map-info__container" (click)="showDetails(data.ID)">
            <div *ngIf="type === 'market'" class="market">
                <p class="title" data-test-id="marketTitle">{{data.market_title}}</p>

                <p data-test-id="marketSchedule" *ngIf="_w.location.pathname === '/map-search'">
                    <span data-test-id="timeDatesInfo">{{timeDatesInfo}}</span>
                </p>
            </div>

            <div *ngIf="type === 'seller'" class="seller">
                <div class="image__container" [style.background-image]="'url('+imageUrl+')'"></div>
                <div class="info">
                    <h3 data-test-id="sellerTitle">{{data.title}}</h3>
                    <div *ngIf="_w.location.pathname === '/map-search'">
                        <rating-display [dataTestId]="'map-info__seller-rate'" [rate]="data.rate"></rating-display>
                        <span>{{data.voted}}</span>
                    </div>
                </div>
            </div>

            <div class="link">
                <span class="sprite sprite-map_callout_arrow_2x"></span>
            </div>
        </div>
    `
})

/**
 * A presenter component for marker or seller brief info card.
 */
export class MapInfoComponent implements OnInit {
    @Input()    data:           MarketInterface | UserModel | any;
    @Input()    timeDatesInfo:  string;
    @Output()   clickEvent$     = new EventEmitter();

    imageUrl:   string;
    type:       string;
    _w: any;

    /**
     * Defines user image in seller case.
     */
    ngOnInit() {
        this._w = window;
        this.type = this.data['login'] ? 'seller' : 'market';
        this.imageUrl = this.data['imageURL'] || '../assets/images/no_user_photo_icon@2x.png';
    }

    /**
     * @emits clickEvent$ on the card click.
     * @param {string} id
     */
    showDetails(id: string): void {
        this.clickEvent$.emit(id);
    }
}
