
        <div class="product-editor__switch-field" role="certificate" [attr.data-test-id]="dataTestId">
            <h4>
                <ng-content></ng-content>
            </h4>

            <div *ngIf="isStatus" data-test-id="showCertificate"
                 (click)="showCertificate(imageUrl)">

                <div class="sprite sprite-certificate_icon_2x"></div>
            </div>

            <input name="photo" (change)="onImgUpload($event.currentTarget)" type="file" accept=".jpg, .jpeg, .png, .tif, .gif, .bmp,  .heic, .heif" data-test-id="fileInp">
            <rect-switch data-test-id="switch"
                         [disabled]="disabled"
                         [state]="isStatus"
                         (stateEvent$)="changeStatus($event)"></rect-switch>
        </div>
    