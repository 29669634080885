
        <header class="app-header">
            <div class="map-header__block">
                <nav class="app-header__container">
                    <div class="navbar__left">
                        <button (click)="goBack()" type="button" data-test-id="backLink"
                            class="nav__link"><i class="icon-custom_back_icon"></i>
                            <!--<img src="../../assets/images/map_callout_arrow@2x.png" alt="Back">-->
                        </button>
                    </div>

                    <div class="navbar__center map-search-header-navbar_center">
                        <h4
                           *ngIf="searchName === '' && (type === 'item' || type === 'sale')"
                           data-test-id="mapSearchHeaderTitle"
                        >
                           {{ "map.search.map" | translate }}
                        </h4>
                        <h4
                           *ngIf="searchName === '' && (type === 'market' || type === 'event')"
                           data-test-id="marketsMapSearchHeaderTitle"
                        >
                        {{ "map.search.markets.map" | translate }}
                        </h4>
                        <h4
                           *ngIf="searchName !== ''"
                           data-test-id="keywordMapSearchHeaderTitle"
                        >
                        {{ "map.search.map.for" | translate }} {{searchName}}
                        </h4>
                        <h4
                           *ngIf="searchName === '' && (
                              type === 'user' || type === 'seller'
                           )"
                           data-test-id="sellersMapSearchHeaderTitle"
                        >
                        {{ "map.search.sellers.map" | translate }}
                        </h4>
                    </div>

                    <div class="navbar__right" role="map">
                        <button type="button" (click)="onClick()" data-test-id="searchLink">
                            <i class="icon-location-address"></i>
                        </button>
                    </div>
                </nav>
            </div>
        </header>
    