var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter, OnInit, Injector, } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { ProductDetailsService } from '../../product-details/product-details.service';
import { SearchListClass } from './search-list.class';
import { ShoppingListService } from '../../shopping-list/shopping-list.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Router } from '@angular/router';
import { ErrorService } from '../../services/error.service';
import AppValues from '../../common/app.values';
var ItemSearchSublistComponent = /** @class */ (function (_super) {
    __extends(ItemSearchSublistComponent, _super);
    function ItemSearchSublistComponent(elementRef, googleAnalyticsService, productDetailsService, itemService, shoppingListService, injector) {
        var _this = _super.call(this, elementRef) || this;
        _this.elementRef = elementRef;
        _this.googleAnalyticsService = googleAnalyticsService;
        _this.productDetailsService = productDetailsService;
        _this.itemService = itemService;
        _this.shoppingListService = shoppingListService;
        _this.injector = injector;
        _this.search_from = 'shopping_list';
        _this.itemSelectEvent$ = new EventEmitter();
        _this.addItemToSL$ = new EventEmitter();
        _this.desiredQuantity = 0;
        return _this;
    }
    ItemSearchSublistComponent.prototype.ngOnInit = function () {
        this.shoppingList = this.shoppingListService.getCachedList();
    };
    /**
     * @desc check if this product is on sale
     * @param {SellingItemResponseBody} item
     * @returns {boolean}
     */
    ItemSearchSublistComponent.prototype.isSaleFn = function (item) {
        return this.isSale = this.itemService.isSaleFn(item);
    };
    /**
     * @desc price for items
     * @param {SellingItemResponseBody} item
     * @returns {number}
     */
    ItemSearchSublistComponent.prototype.itemPriceFn = function (item) {
        return this.isPrice = this.itemService.itemPriceFn(item);
    };
    /**
     * @desc UnitsName for items
     * @param {SellingItemResponseBody} item
     * @returns {string}
     */
    ItemSearchSublistComponent.prototype.priceUnitsNameFn = function (item) {
        return this.isUnitsName = this.itemService.priceUnitsNameFn(item);
    };
    /**
     * @desc check if this product is in the basket (Shopping List)
     * @param {string} subcategoryEntryID
     * @returns {boolean}
     */
    ItemSearchSublistComponent.prototype.isInSL = function (subcategoryEntryID) {
        if (!this.shoppingList)
            return false;
        return !!~this.shoppingList.findIndex(function (_item) { return _item.subcategory_entry_id === subcategoryEntryID; });
    };
    /**
     * @desc add one product to Shopping List
     * @param {Event} event
     * @param {SellingItemResponseBody} item
     */
    ItemSearchSublistComponent.prototype.addToShoppingListEvent = function (item) {
        this.addItemToSL$.emit(item);
    };
    /**
     * @desc if you want view one product
     * @param {SellingItemResponseBody} item
     */
    ItemSearchSublistComponent.prototype.onClick = function (item) {
        this.productDetailsService.setProductDetailsFrom(this.search_from);
        var promise = this.productDetailsService.showProductDetails(item['ID'], { desiredQuantity: this.desiredQuantity, item: item }, false).toPromise().then(function (res) { return res; }, function (err) { return err; });
        return promise;
    };
    ItemSearchSublistComponent.prototype.handleShoppingListEvent = function (event, item) {
        event.stopPropagation();
        if (!this.isInSL(item.subcategoryEntryID)) {
            this.addToShoppingListEvent(item);
        }
    };
    Object.defineProperty(ItemSearchSublistComponent.prototype, "router", {
        /***
         * @desc This function creates router property on your service.
         * @return {Router}
         */
        get: function () {
            return this.injector.get(Router);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemSearchSublistComponent.prototype, "errorService", {
        /***
         * @desc This function creates router property on your service.
         * @return {Router}
         */
        get: function () {
            return this.injector.get(ErrorService);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @desc handle quantity choice
     * @param {HTMLInputElement} input
     * @param {number} qty
     */
    ItemSearchSublistComponent.prototype.setQuantity = function (input, qty) {
        var _this = this;
        setTimeout(function () {
            var formatedNumber = AppValues.NumberUSFormat({
                value: input.value,
                qty: qty,
                maxNumber: AppValues.PRODUCT_QTY_MAX,
                defaultValue: '',
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
            });
            input.value = formatedNumber.formatedNumber;
            _this.desiredQuantity = formatedNumber.num;
        });
    };
    return ItemSearchSublistComponent;
}(SearchListClass));
export { ItemSearchSublistComponent };
