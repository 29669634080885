import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector:   'safe-textarea',
    template:   `
        <textarea 
            draggable="false"
            [attr.data-test-id]="dataTestId"
            [autofocus]="isAutofocus"
            [class]="className"
            [name]="name"
            [placeholder]="placeholder"
            [rows]="rows"
            [value]="value"
            [disabled]="disabled"
            (keyup)="onKeyUp($event)"
            (blur)="onBlur($event)">
        </textarea>
    `
})
export class SafeTextareaComponent implements OnChanges {
    @Input() isAutofocus: boolean;
    @Input() className: string;
    @Input() dataTestId: string;
    @Input() name: string;
    @Input() placeholder: string;
    @Input() rows: string;
    @Input() value: string;
    @Input() disabled: boolean;
    @Input() minMessageCharacters: number = 0;
    @Input() maxMessageCharacters?: number;

    @Output() private changeEvent$ = new EventEmitter();
    @Output() private isValidEvent$: EventEmitter<boolean> = new EventEmitter();

    private isValid: boolean;

    public ngOnChanges(changes: SimpleChanges): void {
        const value = changes.value;

        if (value.previousValue !== value.currentValue) {
            this.value = value.currentValue;
            this.checkIsValidHandler();
        }
    }

    public onBlur(event: Event): void {
        this.changeEvent$.emit([this.name, event.target['value'].trim()]);
    }

    public onKeyUp(event: Event): void {
        this.value = event.target['value'];
        this.checkIsValidHandler();
    }

    private checkIsValidHandler(): void {
        this.checkIsValid();
        this.emitValidEvent();
    }

    private checkIsValid(): void {
        this.isValid = 
            this.checkMinLength() && 
            this.checkMaxLength();
    }

    private checkMinLength(): boolean {
        return this.value.trim().length >= this.minMessageCharacters;
    }

    private checkMaxLength(): boolean {
        return this.maxMessageCharacters 
                ? this.value.trim().length <= this.maxMessageCharacters 
                : false;
    }

    private emitValidEvent(): void {
        this.isValidEvent$.emit(this.isValid);
    }
}
