import {Component, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

import {ModalService} from '../modal/modal.service';
import {
    elements, PaymentIntentResponse, StripeCanMakePaymentResponse, StripeError, StripeInterface, TokenResponse
} from './stripe.interface';
import {ClientPaymentService} from './payment.service';
import {Subscription} from 'rxjs/Subscription';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Location} from "@angular/common";
import {AppRouteValues, createDirection} from "../common/app.route.values";

declare var Stripe: any;

@Component({
    selector: 'app-stripe',
    templateUrl: './payment-component.html',
    providers: [ClientPaymentService]
})
export class PaymentComponent implements OnInit, OnDestroy {

    private stripe: StripeInterface;
    private onCanMakePaymentChangeSubscription: Subscription;
    private onSuccessPaymentChangeSubscription: Subscription;
    private onErrorPaymentChangeSubscription: Subscription;

    public amount: number;
    public card: elements.Element;
    public cardErrors: string;
    public cardHandler: () => void = this.handleChange.bind(this);
    public elements: elements.Elements;
    public isDisabledSubmitButton: boolean = true;
    public isVisibleBlockGoogleApplePay: boolean = false;
    public payForm: FormGroup;
    public location: Location;

    @ViewChild('button') buttonRef: ElementRef;
    @ViewChild('card') cardRef: ElementRef;

    private cardStyle: elements.ElementsOptionsStyle = {
        base: {
            iconColor: '#9EBB4F',
            fontFamily: '"Avenir", sans-serif',
            lineHeight: '40px',
            fontWeight: 300,
            fontSize: '18px',
            '::placeholder': {
                color: '#CFD7E0'
            }
        }
    };
    private paymentRequestButtonStyle: elements.ElementsOptionsStyle = {
        paymentRequestButton: {
            type: 'buy',
            theme: 'light-outline',
            height: '40px',
        }
    };

    constructor(private formBuilder:        FormBuilder,
                private modalService:       ModalService,
                private paymentService:     ClientPaymentService,
                private router:             Router,
                public  translate:          TranslateService,
                location: Location) {
        this.location = location;
    }


    public ngOnInit(): void {
        this.stripe = this.paymentService.stripePayment;
        this.amount = this.paymentService.totalAmount;

        this.cardBuilder();

        this.onSuccessPaymentChangeSubscription = this.paymentService.onSuccessPaymentChanges().subscribe((successPayment: PaymentIntentResponse) => {
            this.router.navigate([createDirection(AppRouteValues.routeCartReport)]);
        });
        this.onErrorPaymentChangeSubscription = this.paymentService.onErrorPaymentChanges().subscribe((error: StripeError) => {
            this.handleChange({error: error});
        });

        this.paymentButtonBuilder();
    }

    private cardBuilder(): void {
        this.payForm = this.formBuilder.group({
            cartName: ['', [Validators.required]]
        });

        this.elements = this.stripe.elements();

        // Only mount the element the first time
        if (!this.card) {
            this.card = this.elements.create('card', {
                style: this.cardStyle,
                hidePostalCode: true
            });
            this.card.mount(this.cardRef.nativeElement);
            this.card.on('change', this.cardHandler);
        }
    }

    private paymentButtonBuilder(): void {
        this.onCanMakePaymentChangeSubscription = this.paymentService.onCanMakePaymentChanges().subscribe((canMakePaymentObject: StripeCanMakePaymentResponse) => {
            let prButton: elements.Element = this.paymentService.stripePayment.elements().create('paymentRequestButton', {
                paymentRequest: this.paymentService.stripePaymentRequest,
                style: this.paymentRequestButtonStyle
            });
            if (canMakePaymentObject) {
                prButton.mount('#payment-request-button');

                this.isVisibleBlockGoogleApplePay = true;
            } else {
                this.isVisibleBlockGoogleApplePay = false;
            }

            this.paymentService.paymentMethod();
        });
    }

    public get fullCardName(): string {
        return this.payForm.get('cartName').value;
    }

    public onBuy(event: Event): void {
        this.handleChange({complete: false});
        event.stopPropagation();
        event.preventDefault();

        this.paymentService.confirmCardPayment(this.card, this.fullCardName);
    }

    public onClosePayBlock(): void {
        this.location.back();
        // this.closePayBlock.emit();
    }
    public handleChange(e: TokenResponse): void {
        this.cardErrors = e.error ? e.error.message : '';
        this.isDisabledSubmitButton = !!(e.error || !e.complete);
    }

    public isCheckDisabledSubmitButton(): boolean {
        return !this.isDisabledSubmitButton && !this.payForm.invalid;
    }

    public ngOnDestroy(): void {
        if (this.onCanMakePaymentChangeSubscription) {
            this.onCanMakePaymentChangeSubscription.unsubscribe();
        }
        if (this.onSuccessPaymentChangeSubscription) {
            this.onSuccessPaymentChangeSubscription.unsubscribe();
        }
        if (this.onErrorPaymentChangeSubscription) {
            this.onErrorPaymentChangeSubscription.unsubscribe();
        }
    }

    // At the time of the prototype
    // May need this
    // private createToken(): void {
    //     const form: any = document.getElementById('payment-form');
    //     form.addEventListener('submit', (event: any) => {
    //         event.preventDefault();
    //
    //         this.paymentService.createToken(this.card).then((result: TokenResponse) => {
    //             if (result.error) {
    //                 // Inform the customer that there was an error.
    //                 const errorElement = document.getElementById('card-errors');
    //                 errorElement.textContent = result.error.message;
    //             } else {
    //                 // Send the token to your server.
    //                 this.stripeTokenHandler(result.token);
    //             }
    //         });
    //     });
    // }
    // private stripeTokenHandler(token: Token): void {
    //     // Insert the token ID into the form so it gets submitted to the server
    //     const form: any = document.getElementById('payment-form');
    //     const hiddenInput = document.createElement('input');
    //     hiddenInput.setAttribute('type', 'hidden');
    //     hiddenInput.setAttribute('name', 'stripeToken');
    //     hiddenInput.setAttribute('value', token.id);
    //     form.appendChild(hiddenInput);
    //
    //     // Submit the form
    //     form.submit();
    // }
}
