import { CommonModule }                     from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule }    from '@angular/core';
import { RouterModule } from '@angular/router';

import { EmailVerificationComponent }       from './email-verification.component';
import { EmailVerificationRoutingModule }   from './email-verification-routing.module';
import { SharedModule }                     from '../shared/shared.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        RouterModule,
        SharedModule,
    ],
    declarations: [
        EmailVerificationComponent
    ],
})
export class EmailVerificationModule {}
