var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalService } from '../../modal/modal.service';
import { ProductEditorParentClass } from './product-editor-abstract.class';
import { ProductEditorService } from '../product-editor.service';
import { TranslateService } from '@ngx-translate/core';
var ExistingItemEditorComponent = /** @class */ (function (_super) {
    __extends(ExistingItemEditorComponent, _super);
    function ExistingItemEditorComponent(modalService, productEditorService, translate) {
        var _this = _super.call(this, modalService, productEditorService, translate) || this;
        _this.eventActions = {
            showEvent: function (event) {
                return _this.context.callback({ showEvent: event });
            },
            addEvent: function () {
                return _this.context.callback({ addEvent: null });
            },
            removeEvent: function (event, index) {
                return _this.context.callback({ removeEvent: index });
            }
        };
        return _this;
    }
    ExistingItemEditorComponent.prototype.createTitleText = function () {
        this.title = this.translate.instant("editor.title.product");
    };
    /**
     * Delegates to the service to finish editing.
     */
    ExistingItemEditorComponent.prototype.onClose = function () {
        var _this = this;
        this.productEditorService.cancelEditing({ cansave: true }).subscribe(function (action) {
            if (action.type === 'save')
                _this.onSave();
        });
    };
    /**
     * @desc Calls the parent method to ensure if it is possible to save (data correctness).
     * Calls the callback with `saveItem` command object.
     * @param {boolean} saveBeforeAddingEvent
     */
    ExistingItemEditorComponent.prototype.onSave = function (event) {
        if (!_super.prototype.onSave.call(this))
            return;
        var obj = { item: this.item, event: event };
        this.context.callback({ saveItem: obj });
    };
    // ---------------- Event Widget commands ----------
    /**
     * Dispatches the Event Widget commands.
     * @param commandObject
     */
    ExistingItemEditorComponent.prototype.onWidgetEvent = function (commandObject) {
        this[Object.keys(commandObject)[0]](Object.values(commandObject)[0]);
    };
    /**
     * Executes `removeEvent` command by calling the callback.
     * @param index
     */
    ExistingItemEditorComponent.prototype.removeEvent = function (index) {
        this.context.callback({ removeEvent: index });
    };
    /**
     * Executes `addEvent` command by calling the callback.
     */
    ExistingItemEditorComponent.prototype.addEvent = function () {
        if (this.item.current_quantity < 1) {
            this._showWarning(this.translate.instant('event.editor.warning.create'));
            return;
        }
        this.productEditorService.change_item
            ? this.addShowEventActionPrecondition(null, 'addEvent')
            : this.eventActions['addEvent'](null);
    };
    /**
     * @desc Calls warning dialog if fields values were updated
     * but user didn't save changes.
     */
    ExistingItemEditorComponent.prototype.addShowEventActionPrecondition = function (event, type) {
        var _this = this;
        this.modalService
            .warning({
            title: this.translate.instant('event.editor.warning.modal.title'),
            message: this.translate.instant('event.editor.warning.modal.message'),
            yesButtonText: this.translate.instant('event.editor.warning.modal.confirm'),
            noButtonText: this.translate.instant('event.editor.warning.modal.reject'),
            reverseButtons: true,
        }).then(function (action) {
            if (action) {
                _this.onSave(event);
                return;
            }
            _this.eventActions[type](event);
        });
    };
    /**
     * Executes `showEvent` command by calling the callback.
     * @param obj
     */
    ExistingItemEditorComponent.prototype.showEvent = function (obj) {
        this.productEditorService.change_item
            ? this.addShowEventActionPrecondition(obj, 'showEvent')
            : this.eventActions['showEvent'](obj);
    };
    return ExistingItemEditorComponent;
}(ProductEditorParentClass));
export { ExistingItemEditorComponent };
