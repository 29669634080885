import {SellingItemResponseBody} from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {FavoriteWatchProductListViewInterface} from "./favorite-list.interface";

export class AdapterFavoriteProductsView {
    private adaptee: SellingItemResponseBody[];

    constructor(adaptee: SellingItemResponseBody[]) {
        this.adaptee = adaptee;
    }

    public initProduct(): FavoriteWatchProductListViewInterface[] {
        return this.adaptee.map((product: SellingItemResponseBody) => {
            return Object.assign(product, {
                isInOnSale: false, isExpired: false,
                productPrice: 0, productUnitsName: ''
            });
        });
    }
}
