import {Component, OnInit, EventEmitter,
        Input, Output}                          from '@angular/core';
import { Location }                             from '@angular/common';

import { GoogleAnalyticsService }               from '../services/google-analytics.service';
import { SearchOptionsInterface }               from '../interfaces/search.interface';


@Component({
    selector: 'user-header',
    styleUrls:  ['../../assets/styles/app-header.sass'],
    template: `
        <!--- Header for view Login page -->
        <header *ngIf="locationSearch == '/login'" class="app-header">

            <nav class="app-header__container" role="login">
                <div class="navbar__left"></div>

                <h4 class="navbar__center text-center" role="login">{{ "user.header.login" | translate }}</h4>

                <div class="navbar__right" role="login"></div>
            </nav>

        </header>
        <!--- End header for view Login page -->


        <!--- Header for view Signup page -->
        <header *ngIf="locationSearch == '/signup'" class="app-header">
            <nav class="app-header__container signup-header" role="signup">
                <div class="navbar__left">
                    <button class="nav__link" [routerLink]="['/login']" (click)="clickAnyButtons($event, 'sign_up')"
                       name="signup_cancel_button" data-test-id="loginLink" type="button">{{ "user.header.cancel" | translate }}</button>
                </div>

                <div class="navbar__center">
                    <h4>{{ "user.header.signup" | translate }}</h4>
                </div>

                <div class="navbar__right"></div>
            </nav>
        </header>
        <!--- End header for view Signup page -->


        <!--- Header for view Change Password page -->
        <header *ngIf="locationSearch == '/change-password'" class="app-header change-password__header">
            <nav class="app-header__container">
                <div class="navbar__left">
                   <button (click)="location.back()" data-test-id="backLink" type="button"
                      class="nav__link"><i class="icon-custom_back_icon"></i></button>
                </div>

                <h4 class="navbar__center" data-test-id="changePasswordTitle">
                   Change Password
                </h4>

                <div class="navbar__right">
                </div>
            </nav>
        </header>
        <!--- End header for view Change Password page -->


        <!--- Header for view Password Reset page -->
        <header
            *ngIf="
               locationSearch == '/password-reset'
               || locationSearch == '/email-verification'
               || locationSearch == '/login-link'
            " class="app-header change-password__header"
        >
            <nav class="app-header__container" role="reset">

                <div class="navbar__left" role="reset">
                    <button [routerLink]="['/login']" (click)="clickAnyButtons($event, 'forgot_password')" type="button"
                       name="forgot_password_cancel_button" class="nav__link" data-test-id="loginLink">
                        <div class="nav__link">
                            <i class="icon-custom_back_icon"></i>
                        </div>

                        <p>Log In</p>
                    </button>
                </div>

                <div class="navbar__center" role="reset">
                    <span class="nav__link sprite sprite-bizi_baza_logo_2x"></span>
                </div>

                <div class="navbar__right" role="reset"></div>
            </nav>
        </header>
        <!--- End header for view Password Reset page -->

    `
})

export class UserHeaderComponent implements OnInit {

    @Input()    disabled:       boolean;
    @Output()   changeEvent$    = new EventEmitter<SearchOptionsInterface>();
    @Output()   submit          = new EventEmitter<null>();

    public location:           Location;
    public locationSearch:     string;
    public count:              number;

    constructor(
        location:                           Location,
        private googleAnalyticsService:     GoogleAnalyticsService
    ) {
        this.location = location;
        this.count = 0;
    }



    public ngOnInit(): void {
        this.locationSearch = decodeURIComponent( window.location.pathname );
    }

    public clickAnyButtons(event: Event, event_category: string): void {
        const name = event.currentTarget['name'];
        this.googleAnalyticsService.handleClickButton(name + '_pressed', event_category, 'click');
    }
}
