import {Component} from '@angular/core';
import {ReviewInterfaceItem} from '../review.interface';
import {ModalService} from '../../modal/modal.service';
import {AppReviewsService} from '../reviews.service';
import {ReviewsAbstractClass} from './reviews-abstract.class';
import AppValues from '../../common/app.values';
import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {AppFooterService} from '../../app-footer/app-footer.service';

@Component({
    selector: 'reviews-users',
    styleUrls:  ['../reviews.sass'],
    template: `        
       <ul *ngIf="filteredItems" class="search__container" id="custom-container">
            <li class="review-list__product" *ngFor="let item of filteredItems; trackBy: trackByID; let i =  index;"
                [attr.data-test-id]="'reviewItem_'+i" [id]="item.order_element.ID">
                <img *ngIf="item.stars >= 4" [attr.data-test-id]="'like-review_'+i"
                     src="../../../assets/images/svg_icons/positive.svg"/>
                <img *ngIf="item.stars < 4 && item.stars > 2" [attr.data-test-id]="'neutralReview_'+i"
                     src="../../../assets/images/svg_icons/neutral.svg"/>
                <img *ngIf="item.stars <= 2" [attr.data-test-id]="'dislike-review_'+i"
                     src="../../../assets/images/svg_icons/negative.svg"/>

                <div class="review-product__container-info">

                    <div class="first-product-info-row">
                        <div *ngIf="item.order_element.item_info.photoURL" class="review-img">
                            <img [attr.data-test-id]="'reviewItemImg_'+i" [src]="item.order_element.item_info.photoURL"/>
                        </div>
                        <div *ngIf="!item.order_element.item_info.photoURL" class="review-img">
                            <span [attr.data-test-id]="'reviewItemImg_'+i"
                              class="sprite sprite-no_image_icon"></span>
                        </div>
                        <div>
                            <p class="product-title" [attr.data-test-id]="'productTitle_'+i">{{item.order_element.item_info.title}}</p>
                            <p class="product-company-name" [attr.data-test-id]="'productCompany_'+i">{{item.sender_name}}</p>
                        </div>
                    </div>

                    <div class="second-product-info-row">
                        <p class="review-comment" [attr.data-test-id]="'comment_'+i">{{item.text}}</p>
                        <p class="product-date" [attr.data-test-id]="'productDate_'+i">
                            {{item.datetime }}</p>
                    </div>
                
                </div>
            </li>
        </ul>
    `
})

export class ReviewsUsersComponent extends ReviewsAbstractClass {

    constructor(
        modalService:           ModalService,
        footerService:          AppFooterService,
        reviewsService:         AppReviewsService,
        scrollToService:        ScrollToService
    ) {
        super(modalService, reviewsService, scrollToService, footerService);
    }
}
