
        <header class="app-header">
            <div class="shopping-header__block">
                <nav class="app-header__container" role="shopping">
                    <div class="navbar__left">
                        <button [routerLink]="'/settings'" data-test-id="settingsLink" type="button">
                            <img width="25" height="25" [src]="'../../assets/images/svg_icons/settings_icon.svg'" alt="settings">
                        </button>
                    </div>
                    <div class="navbar__center">
                        <h4>{{ "shoppingList.title" | translate }}</h4>
                    </div>
                    <div class="navbar__right">
                        <button type="button" (click)="sortShoppingList()" class="sortShoppingList" data-test-id="sortShoppingList">
                            <i class="icon-sort-name-up"></i>
                        </button>

                        <button type="button" (click)="clearSearchResults()" data-test-id="searchLink">
                            <i class="icon-search_icon"></i>
                        </button>
                    </div>
                </nav>
                <carousel></carousel>
            </div>
        </header>
    