import { NgModule }                     from '@angular/core';
import { CommonModule }                 from '@angular/common';
import { FormsModule }                  from '@angular/forms';
import { RouterModule }                 from '@angular/router';

import { AgmCoreModule }                from '@agm/core';
import { MapBoardComponent }            from './map-board.component';
import { MapSearchComponent }           from './map-search.component';
import { MapSearchHeaderComponent }     from './map-search-header.component';
import { MapSearchService }             from './map-search.service';
import { SharedModule }                 from '../shared/shared.module';
import { environment }                  from '../../environments/environment';

@NgModule({
    imports:      [
        CommonModule,
        FormsModule,
        RouterModule,
        SharedModule,

        AgmCoreModule.forRoot({
            apiKey: environment.GM_API_KEY
        }),
    ],
    declarations: [
        MapBoardComponent,
        MapSearchComponent,
        MapSearchHeaderComponent,
    ],
    exports:      [ MapSearchComponent ],
    providers:    [ MapSearchService ]
})
export class MapSearchModule { }
