import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { ModalService } from '../modal/modal.service';
import { UserService } from '../user/user.service';
import AppValues from '../common/app.values';
var FavoriteListService = /** @class */ (function () {
    function FavoriteListService(dataService, errorService, modalService, userService) {
        this.dataService = dataService;
        this.errorService = errorService;
        this.modalService = modalService;
        this.userService = userService;
    }
    FavoriteListService.prototype.getList = function () {
        var _this = this;
        var token = this.userService.getUserSession().token;
        this.modalService.showSpinner();
        if (token) {
            return this.dataService.getData('favorite_list', { token: token })
                .map(function (res) {
                _this.modalService.close();
                _this.setList(res);
                return _this.list;
            })
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({});
    };
    FavoriteListService.prototype.getUser = function () {
        return AppValues.deepCopy(this.userService.getUser());
    };
    FavoriteListService.prototype.setList = function (list) {
        this.list = list;
    };
    FavoriteListService.prototype.addItem = function (id) {
        // @ts-ignore
        var list = this.getUser().favList.items;
        return this.addToList(id, list, 'items');
    };
    FavoriteListService.prototype.addUser = function (id) {
        // @ts-ignore
        var list = this.getUser().favList.users;
        return this.addToList(id, list, 'users');
    };
    FavoriteListService.prototype.addToList = function (id, list, field) {
        var obj = { items_ids: [id],
            users_ids: [id] };
        list.push(id);
        return this._editList('add_to_fl', obj, list, field);
    };
    FavoriteListService.prototype.removeItem = function (id) {
        // @ts-ignore
        var list = this.getUser().favList.items;
        return this.removeFromList(id, list, 'items');
    };
    FavoriteListService.prototype.removeUser = function (id) {
        // @ts-ignore
        var list = this.getUser().favList.users;
        return this.removeFromList(id, list, 'users');
    };
    FavoriteListService.prototype.removeFromList = function (id, list, field) {
        var obj = { items_ids: [id],
            users_ids: [id] };
        list.splice(list.indexOf(id), 1);
        return this._editList('delete_from_fl', obj, list, field);
    };
    FavoriteListService.prototype._editList = function (url, item, list, field) {
        var _this = this;
        var token = this.userService.getUserSession().token;
        var user = this.userService.getUser();
        if (token) {
            this.modalService.showSpinner();
            return this.dataService.postData(url, item, { token: token })
                .do(function (_list) {
                var _a;
                _this.modalService.close();
                user.favList = Object.assign(user.favList, (_a = {}, _a[field] = list, _a));
                _this.userService.updateUser(user);
                _this.list = _list;
            })
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({});
    };
    return FavoriteListService;
}());
export { FavoriteListService };
