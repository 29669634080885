import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Address, SellingItemResponseBody, ShoppingCartElementToAdd, ShoppingCartResponseBody } from '../../../swagger-gen__output_dir';
import { AppRouteValues } from '../common/app.route.values';
import AppValues from '../common/app.values';
import { ModalArgumentsInterface } from '../interfaces/modal.interface';
import { ProductDetailsViewInterface } from '../interfaces/product.interface';
import { ModalService } from '../modal/modal.service';
import { ProductDetailsService } from '../product-details/product-details.service';
import { ErrorInterface, ErrorService } from '../services/error.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { UserService } from '../user/user.service';
import { CartService } from './cart.service';

export class CartAbstractClass extends UnsubscribeOnDestroyAbsctractClass {
    public busyCart:          boolean = false;
    public isPriceAlertOn:    boolean = false;
    public item:              ProductDetailsViewInterface;
    public desiredQuantity:   {[key: string]: number} = {};
    protected isBuyNow:       boolean = false;
    protected isLocationBack: boolean;

    protected buyNowSubject:          Subject<boolean> = new Subject();

    public get onSelectBuyNow(): Observable<boolean> {
        return this.buyNowSubject.asObservable();
    }

    public constructor(
        protected elementRef:             ElementRef,
        protected cartService:            CartService,
        protected googleAnalyticsService: GoogleAnalyticsService,
        protected productDetailsService:  ProductDetailsService,
        protected userService:            UserService,
        protected modalService:           ModalService,
        protected errorService:           ErrorService,
        protected translate:              TranslateService,
    ) {
        super();
        this.subjects$.push(this.buyNowSubject);
    }

    public changeCounts(): void {
        const token: string = this.userService.getUserSession().token;

        if (token) {
            this.cartService.changeCounts(token);
        }
    }

    /**
     * @desc Calls self-titled service method. On response redirects back
     * or goes to the shopping cart (depending from where it has been called:
     * 'Add to cart' or 'Buy now')
     * @param shouldBuyNow
     */
    public addToCart(event: Event, item: SellingItemResponseBody, shouldBuyNow: boolean = false): void {
        event.stopPropagation();
        this.addToCartHandler(item, shouldBuyNow);
    }

    /**
     * @desc Toggles inWatchList status.
     */
    public toggleWatchListStatus(item: SellingItemResponseBody): void {
        if (item.inWatchList && this.isPriceAlertOn) {
            this.removingItemFromWatchList(item);
        } else {
            this.toggleWatchList(item);
        }
    }

    /**
     * @desc Sets isPriceAlertOn flag.
     * @param state
     * @private
     */
    public togglePriceAlert(state: boolean): void { }

    /**
     * @desc If it is the only item in the shopping cart -- buy immediately,
     * otherwise ask user: purchase everything now or redirect to the cart.
     * @public
     */
    public _addProductIntoShoppingCart(item: SellingItemResponseBody, items: ShoppingCartElementToAdd[]): void {
        this.busyCart = true;

        this.resetBusyFlag();
        this.googleAnalyticsService.handleVirtualPage('add_to_cart');
        this.googleAnalyticsService.handleClickButton('purchase_from_buy_now_attempt', 'purchase', 'click');
        this.addItemInit(item, items);
    }

    protected addToCartHandler(item: SellingItemResponseBody, shouldBuyNow: boolean): void {
        this.buyNowSubject.next(shouldBuyNow);
        this.showProductDetails(item, shouldBuyNow);
        this.trackSubscription(
            this.checkSoldOutProduct(item).subscribe((isSoldOut: boolean) => {
                if (isSoldOut) {
                    this.soldOutProductFlow(item);
                    return;
                }

                this.buyStrategy(item, shouldBuyNow);
            }, (error: ErrorInterface) => this.errorService.handleError(error)),
        );
    }

    protected addItemInit(item: SellingItemResponseBody, items: ShoppingCartElementToAdd[]): void {
        const isPayment: boolean = (this.isBuyNow && this.cartService.isEmptyCart);
        this.trackSubscription(
            this.cartService.addItem({shoppingCartElements: items, sellerID: item.sellerID, showNotification: !this.isBuyNow, isLocationBack: this.isLocationBack})
            .subscribe(
                () => this.proceedAddToCart(item, isPayment),
                (error: ErrorInterface) => this.proceedAddToCartError(item, error),
            ),
        );
    }

    protected changeItemInCartValue(item: SellingItemResponseBody, value: boolean): void { }

    /**
     * @desc Asks the user: purchase everything now or redirect to the cart.
     * @protected
     */
    protected notEmptyCartHandler(item: SellingItemResponseBody, items: ShoppingCartElementToAdd[]): void {
       this.modalService.warning({
           title: this.translate.instant('buyNow.haveProductsInCart.message.title'),
           message: this.translate.instant('buyNow.haveProductsInCart.message.text'),
           noButtonText: this.translate.instant('buyNow.haveProductsInCart.message.cancel'),
           yesButtonText: this.translate.instant('buyNow.haveProductsInCart.message.goToCart'),
           reverseButtons: true,
       }).then((action: boolean) => {
           if (action) {
               this.googleAnalyticsService.handleAddToCartFromFunnel(item, this.productDetailsService.getProductDetailsFrom());
               this._addProductIntoShoppingCart(item, items);
           }
       });
    }

    /**
     * @desc Creates ShoppingCartElementToAdd from item
     * @param {SellingItemResponseBody} item
     * @param {string} deliveryMethod
     * @protected
     * @returns {ShoppingCartElementToAdd[]}
     */
    protected prepareObjectItemToShoppingCartBody(item: SellingItemResponseBody, deliveryMethod?: string): ShoppingCartElementToAdd[] {
        const items: ShoppingCartElementToAdd[] = [
            {
                item_id: item.ID,
                unit_of_measure_id: item.qtyUnitID,
                quantity: this.desiredQuantity[item.ID] || 1,
            },
        ];
        const deliveryAddress: Address = deliveryMethod === AppValues.US_DELIVERY && this.userService.primaryAddress();

        if (deliveryMethod) {
            items[0].delivery = this.cartService.createDeliveryObj(deliveryMethod, deliveryAddress);
        }

        return items;
    }

    /**
     * @desc Creates ModalArguments object from item
     * @param {SellingItemResponseBody} item
     * @protected
     * @returns {ModalArgumentsInterface}
     */
    protected getObjModalDeliveryMethod(item: SellingItemResponseBody): ModalArgumentsInterface {
        if (item.deliveryOffered && item.marketPickOffered) {
            return {
                title: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.title'),
                message: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.message'),
                yesButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.delivery'),
                noButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.pickUp'),
                lastButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.cancel'),
            };
        } else {
            const modalArguments: ModalArgumentsInterface = {
                title: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.title'),
                yesButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.pay'),
                noButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.cancel'),
            };

            if (item.deliveryOffered) {
                modalArguments.message = this.translate.instant('product.buyNow.modalSelectDeliveryMethod.delivery.message');
            }
            if (item.marketPickOffered) {
                modalArguments.message = this.translate.instant('product.buyNow.modalSelectDeliveryMethod.pickUp.message');
            }
            return modalArguments;
        }
    }

    /**
     * @desc Proceeds adding item to cart depending on options
     * @param {boolean} isBuyNow
     * @param {SellingItemResponseBody} item
     * @param {ShoppingCartElementToAdd[]} items
     * @protected
     */
    protected addToCartStrategy(isBuyNow: boolean, item: SellingItemResponseBody, items: ShoppingCartElementToAdd[]): void {
        const objModalDeliveryMethod: ModalArgumentsInterface = this.getObjModalDeliveryMethod(item);
        if (!isBuyNow) {
            this.googleAnalyticsService.handleClickButton('add_item_to_cart_item_button_pressed', 'purchase', 'click');
            this.googleAnalyticsService.handleAddToCart(item);
            this.googleAnalyticsService.handleAddToCartFromFunnel(item, this.productDetailsService.getProductDetailsFrom());

            this._addProductIntoShoppingCart(item, this.prepareObjectItemToShoppingCartBody(item));
        } else {
            if (!this.cartService.isEmptyCart || this.cartService.isCartWithoutDelivery) {
                this.notEmptyCartHandler(item, items);
            } else {
                this.selectDeliveryMethod(item, objModalDeliveryMethod);
            }
        }
    }

    /**
     * @desc Selects delivery method and proceed adding to shopping cart
     * @param {SellingItemResponseBody} item
     * @param {ModalArgumentsInterface} objModalDeliveryMethod
     * @protected
     */
    protected selectDeliveryMethod(item: SellingItemResponseBody, objModalDeliveryMethod: ModalArgumentsInterface): void {
        this.modalService.success(objModalDeliveryMethod).then((action: boolean | 'last') => {
            let deliveryMethod: string;

            if (action === 'last') { return; }

            if (item.deliveryOffered && item.marketPickOffered) {
                deliveryMethod = action ? AppValues.US_DELIVERY : AppValues.PICK_UP;
            } else {
                if (!action) {
                    this.resetBusyFlag(true);
                    return;
                }

                if (item.deliveryOffered && action) {
                    deliveryMethod = AppValues.US_DELIVERY;
                }
                if (item.marketPickOffered && action) {
                    deliveryMethod = AppValues.PICK_UP;
                }
            }

            this._addProductIntoShoppingCart(item, this.prepareObjectItemToShoppingCartBody(item, deliveryMethod));
        });
    }

    /**
     * @desc Toggles item inWathList value
     * @param {SellingItemResponseBody} item
     * @protected
     */
    protected toggleWatchList(item: SellingItemResponseBody): void {
        this.trackSubscription(
            this.productDetailsService.toggleWatchListStatus(item.ID)
            .subscribe(() => {
                item.inWatchList = !item.inWatchList;
                item.inWatchList && this.googleAnalyticsService.handleAddToWishlist(this.item);

                if (this.isPriceAlertOn) {
                    this.togglePriceAlert(false);
                }
            }, (err: ErrorInterface) => {
                // The error has already been processed in WatchListService
                // and errorService.handleError(err) and the modal window was shown.
                return err;
            }),
        );
    }

    /**
     * @desc Removes item from watchList
     * @param {SellingItemResponseBody} item
     * @protected
     */
    protected removingItemFromWatchList(item: SellingItemResponseBody): void {
        this.productDetailsService.removingItemFromWatchList().then((action: boolean) => {
            if (action && item.inWatchList) {
                this.toggleWatchList(item);
            }
        });
    }

    /**
     * @desc Proceeds item add to card with buyNow value
     * @param {boolean} isBuyNow
     * @param {SellingItemResponseBody} item
     * @param {ShoppingCartElementToAdd[]} items
     * @protected
     */
    protected addToCartAndBuy(isBuyNow: boolean, item: SellingItemResponseBody, items: ShoppingCartElementToAdd[]): void {
        this.trackSubscription(
            this.cartService.getCart()
            .subscribe(
                () => this.addToCartStrategy(isBuyNow, item, items),
                (err: ErrorInterface) => Observable.throw(err),
            ),
        );
    }

    /**
     * @desc Proceeds item add to card with buyNow value
     * @param {SellingItemResponseBody} item
     * @param {boolean} isBuyNow
     * @protected
     */
    protected buyStrategy(item: SellingItemResponseBody, isBuyNow: boolean): void {
        if (isBuyNow) {
            if (this.userService.isGuestMode) {
                this.productDetailsService.setProductOptions({isInstantBuy: true});
                this.userService.setRedirectUrl(`${AppRouteValues.routeProductDetails}/${item.ID}`)
                return;
            }
            if (!this.desiredQuantity[item.ID] && item.current_quantity) {
                this.modalDesiredQuantity();
                return;
            }
        }
        this.addToCartAndBuy(isBuyNow, item, this.prepareObjectItemToShoppingCartBody(item));
    }

    protected modalDesiredQuantity(): void {
        this.modalService.warning(
            {
                title: this.translate.instant('product.buyNow.modalDesiredQuantity.title'),
                message: this.translate.instant('product.buyNow.modalDesiredQuantity'),
                noButtonText: this.translate.instant('product.buyNow.modalDesiredQuantity.button'),
            });
    }

    protected soldOutProductMessage(): void {
        this.modalService.warning(
            {
                title: this.translate.instant('addItemToScPost.error.modal.title'),
                message: this.translate.instant('shoppingCart.error.productIsSoldOut'),
                yesButtonText: this.translate.instant('common.ok'),
            });
    }

    protected proposeToAddSoldOutProductIntoWatchList(item: SellingItemResponseBody): void {
        this.modalService.warning(
            {
                title: this.translate.instant('addItemToScPost.error.modal.title'),
                message: this.translate.instant('shoppingCart.proposeToAddSoldOutProductIntoWatchList.message'),
                yesButtonText: this.translate.instant('shoppingCart.error.productIsSoldOut.yes'),
                noButtonText: this.translate.instant('shoppingCart.error.productIsSoldOut.no'),
                reverseButtons: true,
            }).then((action: boolean) => {
            if (action) {
                this.toggleWatchListStatus(item);
            }
        });
    }

    protected soldOutProductFlow(item: SellingItemResponseBody): void {
        if (item.inWatchList || this.userService.isGuestMode) {
            this.soldOutProductMessage();
        } else {
            this.proposeToAddSoldOutProductIntoWatchList(item);
        }
    }

    protected showProductDetails(item: SellingItemResponseBody, withRedirect: boolean): Observable<SellingItemResponseBody> {
        return this.productDetailsService.showProductDetails(item.ID, {desiredQuantity: this.desiredQuantity[item.ID]}, withRedirect);
    }

    protected checkSoldOutProduct(item: SellingItemResponseBody): Observable<boolean | ErrorInterface> {
        return new Observable((observer) => {
            this.trackSubscription(
                this.productDetailsService.getProduct(item.ID).subscribe((product: SellingItemResponseBody) => {
                    observer.next(product.current_quantity === 0);
                    observer.complete();
                }, (error: ErrorInterface) => {
                    observer.error(error);
                    observer.complete();
                }),
            );
        });
    }

    protected resetBusyFlag(isNotInCart: boolean = false) { }

    private proceedAddToCart(item: SellingItemResponseBody, isPayment: boolean): void {
        this.cartService.onConfirmationShoppingCartAndBuy({isPayment, isPayFromCart: false});
        this.changeItemInCartValue(item, true);
        this.changeCounts();
    }

    private proceedAddToCartError(item: SellingItemResponseBody, error: ErrorInterface): void {
        if (error.statusText === this.translate.instant('shoppingCart.error.productIsSoldOut')) {
            this.soldOutProductFlow(item);
        }

        this.resetBusyFlag(true);
    }
}
