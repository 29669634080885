import {Injectable} from '@angular/core';
import {PushNotificationsService} from './push.notification.service';

export enum TabStatus {
    hidden = 'hidden',
    visible = 'visible',
    focus = 'focus',
    blur = 'blur'
}
@Injectable()
export class CheckTabStatusService {
    public tab: string;
    public tabStatus: TabStatus;
    public myInterval: any;
    public title: string;
    public original = document.title;
    public timeout;

    constructor(private _notificationService: PushNotificationsService) {
        this.tab = '';
        this.timerHandler(TabStatus.focus);
        this.title = document.title;
    }

    /**
     * main visibility API function
     * use visibility API to check if current tab is active or not
     * @return {(c) => boolean}
     * @private
     */
    _isVisibilityTab = (function () {
        let stateKey, eventKey, keys = {
            hidden: 'visibilitychange',
            webkitHidden: 'webkitvisibilitychange',
            mozHidden: 'mozvisibilitychange',
            msHidden: 'msvisibilitychange'
        };
        for (stateKey in keys) {
            if (stateKey in document) {
                eventKey = keys[stateKey];
                break;
            }
        }
        return function (c) {
            if (c) document.addEventListener(eventKey, c);
            return !document[stateKey];
        };
    })();

    /**
     * check if current tab is active or not
     */
    public visibilityTab() {
        this._isVisibilityTab(() => {
            document.title = !this._isVisibilityTab ? this.tab = 'Visible' : this.tab = 'Not visible';
        });
    }

    public browserNotification(res) {
        let data: Array<any> = [];
        data.push({
            'title': 'TAB STATUS',
            'alertContent': res
        });
        this._notificationService.generateNotification(data);
    }


    checkStatusOfTab() {
        // Active
        window.addEventListener('focus', () => {
            this.startTimer();
        });

        // Inactive
        window.addEventListener('blur', () => {
            this.stopTimer();
        });
    }

    public timerHandler(status: TabStatus): void {
        this.tabStatus = status || TabStatus.visible;
    }

    /**
     * Start timer
     */
    startTimer() {
        this.myInterval = window.setInterval(() => {
            this.timerHandler(TabStatus.focus);
        }, 500);
    }

    /**
     * Stop timer
     */
    stopTimer() {
        this.timerHandler(TabStatus.blur);
        // this.browserNotification('blur');
        window.clearInterval(this.myInterval);
    }

//////////////////////////////////////////////////////
    /**
     * The Page Visibility API (on MDN) now allows us to more accurately detect when a page is hidden to the user.
     * Current browser support:
     *** Chrome 13+
     *** Internet Explorer 10+
     *** Firefox 10+
     *** Opera 12.10+
     */
    newVisabilityTabMethod() {
        let hidden = 'hidden';

        // Standards:
        if (hidden in document)
            document.addEventListener('visibilitychange', onchange);
        else if ((hidden = 'mozHidden') in document)
            document.addEventListener('mozvisibilitychange', onchange);
        else if ((hidden = 'webkitHidden') in document)
            document.addEventListener('webkitvisibilitychange', onchange);
        else if ((hidden = 'msHidden') in document)
            document.addEventListener('msvisibilitychange', onchange);
        // IE 9 and lower:
        else if ('onfocusin' in document) document['onfocusin'] = document['onfocusout'] = onchange;
            // All others:
        else window.onpageshow = window.onpagehide
                = window.onfocus = window.onblur = onchange;

        function onchange(evt) {
            let v = 'visible', h = 'hidden',
                evtMap = {
                    focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h
                };

            evt = evt || window.event;
            if (evt.type in evtMap)
                document.body.className = evtMap[evt.type];
            else
                document.body.className = this[hidden] ? TabStatus.hidden : TabStatus.visible;
        }

        this.timerHandler(TabStatus[document.body.className]);

        /* set the initial state (but only if browser supports the Page Visibility API) */
        if (document[hidden] !== undefined)
            onchange({type: document[hidden] ? TabStatus.blur : TabStatus.focus});
    }


    /**
     * Blinking Tab function
     * @param {string} message
     */
    blinkTab(message: string) {
        let timeoutId;

        if (this.tabStatus === TabStatus.hidden) timeoutId = this._startBlinkTab(timeoutId, message);
        else if (this.tabStatus === TabStatus.visible) this._clearBlinkTab(timeoutId); /* stop changing title on moving the mouse */
    }

    /**
     * function to set title back to original
     * @param timeoutId
     * @private
     */
    _clearBlinkTab(timeoutId) {
        clearInterval(timeoutId);
        document.title = this.title;
        timeoutId = null;
    }

    /**
     * function to BLINK browser tab
     * @param timeoutId
     * @param {string} message
     * @private
     */
    _startBlinkTab(timeoutId, message?: string) {
        if (this.tabStatus === TabStatus.visible) this._clearBlinkTab(timeoutId); /* stop changing title on moving the mouse */
        else document.title = document.title === message ? this.title : message;
    }

}
