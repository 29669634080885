import {
    AfterViewInit,
    Component, OnInit, Output, QueryList, ViewChildren
} from '@angular/core';
import { AgmInfoWindow }    from '@agm/core';
import { Location }         from '@angular/common';

import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';


import { CenterLocationInterface }  from '../interfaces/location.interface';
import { MarketService }            from './market.service';
import { ModalService }             from '../modal/modal.service';
import { ItemsFromMarketResponse }  from '../../../swagger-gen__output_dir/model/itemsFromMarketResponse';
import {AppSearchService} from '../search/search.service';
import { UserService }              from '../user/user.service';
import { AddressInterface } from '../interfaces/user.interface';
import AppValues from '../common/app.values';
import { normalizeUrl } from '../common/app.helpers';
import {GoogleMapService} from '../services/google-map.service';
import {TranslateService} from '@ngx-translate/core';
import {Market} from "../../../swagger-gen__output_dir";
import { SearchType } from '../interfaces/search.interface';

@Component({
    selector: 'market',
    styleUrls: ['../shopper-details/shopper-details.sass'],
    template: `
        <div class="component">
            <div class="shopper-details">
                <market-header (backEvent$)="backOptionsChange($event)"></market-header>
                <div class="shopper-details__container" *ngIf="market">
                    <div class="shopper-details__img">
                        <agm-map
                            [latitude]='market.market_latitude'
                            [longitude]='market.market_longitude'
                            [zoom]='zoom'
                            [scrollwheel]="false"
                            [streetViewControl]="false"
                            [disableDefaultUI]='true'
                            [disableDoubleClickZoom]='true'
                            [mapDraggable]="false"
                            [zoomControl]='false'>

                            <agm-marker (markerClick)="goToGoogleMaps(market.market_latitude, market.market_longitude, market.address)"
                                [latitude]='market.market_latitude'
                                [longitude]='market.market_longitude'
                                [markerDraggable]="false" [iconUrl]='markerUrl'>
                            </agm-marker>

                        </agm-map>
                    </div>
                    <div class="shopper-details__content-user">
                        <div *ngIf="!market.market_picture_url" class="image_col">
                            <span data-test-id="marketImage"
                              class="sprite sprite-no_image_icon"></span>
                        </div>
                        <div *ngIf="market.market_picture_url" class="container-img">
                            <img data-test-id="marketImage"
                             [src]="market.market_picture_url"/>
                        </div>
                        <div class="content_col">
                            <h4 data-test-id="MarketName">
                                {{ market.market_title }}
                            </h4>
                            <div class="shopper-details__reviews">
                                <span>{{timeDatesInfo}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="market.address" class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-phone"></span>
                        </div>

                        <h4 data-test-id="MarketPhone">
                            <a *ngIf="!market.address.phone">{{ "market.phone.not.specified" | translate }}</a>
                            <a *ngIf="market.address.phone" href="tel:{{ market.address.phone}}">{{ formatPhoneNumber(market) }}</a>
                        </h4>
                    </div>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-location"></span>
                        </div>

                        <h4 *ngIf="market.address.address1" data-test-id="MarketAddress1"
                        (click)="goToAddress(market.address.address1 + ' ' + market.address.city + ' ' + market.address.state + ' ' + market.address.postCode, 'address')">
                            <a>{{ market.address.address1 }}, {{ market.address.city }}, {{ market.address.state }}, {{ market.address.postCode }}</a>
                        </h4>
                        <h4 *ngIf="!market.address.address1" data-test-id="MarketAddress1">
                            <a>{{ "market.text.not.specified" | translate }}</a>
                        </h4>
                    </div>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-globe"></span>
                        </div>

                        <h4 data-test-id="MarketWebPage" (click)="goToAddress(market['market_web_page'], 'webPage')">
                            <a target="_blank">{{ market['market_web_page'] || 'Web page is not specified.'}}</a>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="items-of-market-list product-actions app__footer">
                <button (click)="goToItemList(market)" data-test-id="searchFromMarket" class="shopper-items">{{list.items.length ? list.items.length : "No"}} {{ "market.text.products.found" | translate }}</button>
            </div>
        </div>
    `
})

export class MarketComponent implements OnInit, AfterViewInit {

    @ViewChildren(AgmInfoWindow) infoWins: QueryList<AgmInfoWindow>;
    @Output() data: Market[];

    public href:           string;
    public market:         Market;
    public markerUrl       = '../assets/images/map_pin_market_icon.png';
    public location:       Location;
    public list:           ItemsFromMarketResponse;
    public userLocation:   CenterLocationInterface;
    public _w:             Window;
    public zoom:           number = 12;
    public timeDatesInfo:  string;


    constructor(private marketService:      MarketService,
                private modalService:       ModalService,
                private searchService:      AppSearchService,
                private userService:        UserService,
                private googleMapService:   GoogleMapService,
                private translate:          TranslateService,
                location:                   Location
    ) {
        this.location  = location;
        this._w        = window;
        this.timeDatesInfo = this.translate.instant("market.timeDates.info");
    }

    ngOnInit() {
        this.market     = this.marketService.getShoppersFromMarket();
        this.list       = this.marketService.getItemsFromMarket();
    }

    ngAfterViewInit() {
        const triggerElement = document.getElementsByClassName('agm-map-container-inner')[0];
        if ( triggerElement ) triggerElement.setAttribute('data-test-id', 'content-map');
    }

    goToAddress(item: string, type: string) {
        if (item) {

            const textObj = {
                'webPage': [
                    `Open '${item}'?`,
                    'Open',
                ],
                'mail': [
                    `Send mail to '${item}' ?`,
                    'Send',
                ],
                'address': [
                    `Open '${item}' in maps?`,
                    'Open'
                ]
            };

            this.modalService
                .warning({
                    message:        textObj[type][0],
                    yesButtonText:  textObj[type][1],
                    noButtonText:   'Cancel',
                })
                .then((action: boolean) => action && this.send(item, type));
        } else return;
    }



    send(item, type) {
        const textObj = {
            'webPage':  `${item}`,
            'mail':     `mailto: ${item}`
        };

        if (type === 'address') this.goToGoogleMaps(this.market.market_latitude, this.market.market_longitude, item);

        if (type !== 'address') {

            this.href = normalizeUrl(textObj[type]);

            if (type === 'webPage') this._w.open(this.href);
            else this._w.location.assign(this.href);
        }

        if (!textObj[type] || textObj[type] === "") return;

    }


    public goToItemList(market: Market): void {
        this.searchService.getActiveItemsInMarket(market, this.list);
    }

    public backOptionsChange(option: boolean): void {
        this.searchService.setFalseMarketFlag(SearchType.Market, option);
    }

    public goToGoogleMaps(market_latitude: any, market_longitude: any, nameAddress?: AddressInterface|string): void {

        // Deprecated based on the testing meeting 8/12
        // let coordinates: CenterLocationInterface;
        // const userGeolocation = this.searchService.userGeolocation();
        // const defaultUserGeolocation = this.userService.getUser().loc.coordinates;
        //
        // userGeolocation ? (coordinates = userGeolocation) : (coordinates = defaultUserGeolocation);

        let coorditate_to = {latitude: market_latitude, longitude: market_longitude };

        if (nameAddress) {
            coorditate_to = Object.assign(coorditate_to, {nameAddress});
        }

        this.googleMapService.goToGoogleMaps(
            {
                to: coorditate_to,
            }
        );
    }

    formatPhoneNumber(market: Market) {
        if (market.address.phone) {
            return AppValues.formatPhoneNumber(market.address.phone);
        }
    }

}
