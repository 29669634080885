
        <header class="app-header wf-list-header">
            <nav class="app-header__container" role="watch">
                
                <div class="navbar__left">
                    <a (click)="goBack()" data-test-id="shoppingLink"
                       class="nav__link"><i class="icon-custom_back_icon"></i></a>
                </div>

                <div class="navbar__center" role="watch">
                    <h4>{{ "watchlist.title" | translate }}</h4>
                </div>

                <div class="navbar__right" role="watch"></div>
            </nav>
        </header>
    