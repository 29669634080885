/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./checkbox-field";
var styles_CheckboxComponent = [];
var RenderType_CheckboxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
export function View_CheckboxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], [[8, "classList", 0], [1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onChangeCheckboxValue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [["name", "keepLogged"], ["type", "checkbox"]], [[8, "checked", 0], [8, "value", 0], [8, "classList", 0], [1, "data-test-id", 0], [1, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChangeCheckboxValue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "label", [], [[1, "for", 0], [8, "classList", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.classContainer; var currVal_1 = (_co.dataTestIdInput + "_container"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.isCheckboxON; var currVal_3 = _co.isCheckboxON; var currVal_4 = _co.classInput; var currVal_5 = _co.dataTestIdInput; var currVal_6 = _co.forInput; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.forInput; var currVal_8 = _co.classLabel; _ck(_v, 2, 0, currVal_7, currVal_8); var currVal_9 = _co.textLabel; _ck(_v, 4, 0, currVal_9); }); }
export function View_CheckboxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "checkbox-field", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i0.ɵdid(1, 49152, null, 0, i1.CheckboxComponent, [], null, null)], null, null); }
var CheckboxComponentNgFactory = i0.ɵccf("checkbox-field", i1.CheckboxComponent, View_CheckboxComponent_Host_0, { classContainer: "classContainer", classLabel: "classLabel", classInput: "classInput", dataTestIdInput: "dataTestIdInput", forInput: "forInput", textLabel: "textLabel", isCheckboxON: "isCheckboxON" }, { onChangeField: "onChangeField" }, []);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
