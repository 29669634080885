<div id="stripe-component" class="component">
    <script src="https://js.stripe.com/v3/"></script>
    <header class="app-header">
        <nav class="app-header__container" role="stripe">
            <button class="navbar__left" data-test-id="backLink" (click)="onClosePayBlock()" type="button">
                <span class="nav__link" name="shopping_cart_cancel_button"><i class="icon-custom_back_icon"></i></span>
                <span>{{ 'stripeComponent.shoppingCartBackButton' | translate }}</span>
            </button>

            <div class="navbar__center nav__link relative" role="stripe">
            </div>
        </nav>
    </header>
    <div class="app-payment">

        <div [class]="isVisibleBlockGoogleApplePay ? 'visibleBlockGoogleApplePay blockGoogleApplePay' : 'blockGoogleApplePay'" id="blockGoogleApplePay" data-test-id="blockGoogleApplePay">
            <div id="payment-request-button"></div>
            <div class="separator-stripe-form">
                <hr>
                <p class="separator-stripe-form__Text">{{ 'stripeComponent.orPayWithCard.title' | translate }}</p>
            </div>
        </div>

        <div class="stripe-form">
            <form novalidate (ngSubmit)="onBuy($event)" [formGroup]="payForm" id="payment-form">
                <div id="card_info">
                    <span class="stripe-form__label">{{ 'stripeComponent.cardInformation.label' | translate }}</span>
                    <div id="card-element" class="field" #card></div>
                    <!--<div id="cardNumber-element" class="field" #cardNumber></div>-->
                    <span class="stripe-form__error" data-test-id="payFormCartErrors">{{cardErrors}}</span>
                </div>
                <div id="name_info">
                    <span class="stripe-form__label">{{ 'stripeComponent.nameOnCard.label' | translate }}</span>
                    <input type="text" formControlName="cartName" placeholder="John Doe"
                        class="input__text" data-test-id="PayFormCartName">
                </div>
                <button data-test-id="PayByCartNumberButton" class="button__bizibazagreen" type="submit" [disabled]="!isCheckDisabledSubmitButton()">
                    {{ translate.instant('stripeComponent.payButton', {amount: amount / 100 | currency:'USD':true}) }}
                </button>
            </form>
        </div>
    </div>
</div>
