import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarouselService } from './carousel.service';
import { CarouselComponent } from './carousel.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        CarouselComponent
    ],
    exports: [CarouselComponent]
})
export class CarouselModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CarouselModule,
            providers: [CarouselService]
        };
    }
}
