import { Observable }   from 'rxjs/Observable';
import { Injectable }   from '@angular/core';
import { Router }       from '@angular/router';


import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';

import { AppFooterService }                 from '../app-footer/app-footer.service';
import {
    GetItemReviewsInterface, ModalFilterArgumentInterface, ModalFilterArguments,
    ReviewInterfaceItem, ReviewProductInterface, SelectedOrder
} from './review.interface';
import { DataService }                      from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import { UserService }                      from '../user/user.service';
import { UserModel }                        from '../interfaces/user.interface';
import {GetUserReviewsResponseBody, OrderElement, Review, ReviewsService} from '../../../swagger-gen__output_dir';
import {ReviewsResponseBody} from '../../../swagger-gen__output_dir/model/reviewsResponseBody';
import {ProductDetailsService} from '../product-details/product-details.service';
import {SellingItemResponseBody} from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {UserInfo} from '../../../swagger-gen__output_dir/model/userInfo';
import {MarkReviewsAsReadResponseBody} from '../../../swagger-gen__output_dir/model/markReviewsAsReadResponseBody';
import {CreateReviewOnProductRequestBody} from '../../../swagger-gen__output_dir/model/createReviewOnProductRequestBody';
import {CreateReviewOnBuyerRequestBody} from '../../../swagger-gen__output_dir/model/createReviewOnBuyerRequestBody';
import {OrdersAppService} from '../analytics/orders.service';
import {OrderForReviews} from '../../../swagger-gen__output_dir/model/orderForReviews';
import {OrderElementForReviews} from '../../../swagger-gen__output_dir/model/orderElementForReviews';
import {AccessToSendReviewResponse} from '../../../swagger-gen__output_dir/model/accessToSendReviewResponse';


@Injectable()
export class AppReviewsService {

    items:               ReviewInterfaceItem;
    listFilterTabs = [
        {value: ModalFilterArguments.received, title: 'Received', active: true},
        {value: ModalFilterArguments.written, title: 'Written', active: false},
        {value: ModalFilterArguments.purchases, title: 'Purchases', active: false}
    ];

    list_of_reviews:     ReviewsResponseBody;
    product_reviews:     ReviewProductInterface = {product_review: []};
    user_reviews:        GetUserReviewsResponseBody = {seller_reviews: [], buyer_reviews: []};
    mode:       string;
    photoURL:   string;
    user:       UserModel;

    public isFromSettings: boolean = false;

    private selected_order: SelectedOrder;

    constructor(
        private appFooterService:       AppFooterService,
        private dataService:            DataService,
        private reviewsServiceClient:   ReviewsService,
        private errorService:           ErrorService,
        private router:                 Router,
        private userService:            UserService,
        private productDetailsService:  ProductDetailsService,
        private ordersAppService:       OrdersAppService,
    ) {
        this.mode = this.appFooterService.getMode();
    }


    createProductReview(body: CreateReviewOnProductRequestBody): Observable<Review> {
        let token = this.userService.getUserSession().token;
        if (!token) return;

        return new Observable(observer => {
            this.checkAccessToSendReview(this.userService.getUser().ID, body.product_id)
                .subscribe((access: AccessToSendReviewResponse) => {
                    if (!access.flag) {
                        return Observable.throwError(this.errorService.handleWarning(access.description));
                    } else {
                        return this.reviewsServiceClient.createReviewPost(body, token)
                            .subscribe((res) => {
                                observer.next(res);
                            }, (err: ErrorInterface) => observer.error(this.errorService.handleError(err)));
                    }
                });
        });
    }
    createBuyerReview(body: CreateReviewOnBuyerRequestBody): Observable<Review> {
        let token = this.userService.getUserSession().token;
        if (!token) return;

        return new Observable(observer => {
            this.checkAccessToSendReview(body.buyer_id)
                .subscribe((access: AccessToSendReviewResponse) => {
                    if (!access.flag) {
                        return Observable.throwError(this.errorService.handleWarning(access.description));
                    } else {
                        return this.reviewsServiceClient.buyerReviewPost(body, token)
                            .subscribe((res) => {
                                observer.next(res);
                            }, (err: ErrorInterface) => observer.error(this.errorService.handleError(err)));
                    }
                });
        });
    }

    linkToCreateReview(order_element_id: string) {
        return new Observable(observer => {
            this.getBuyerReviewRequest()
                .subscribe(
                    (list: ReviewsResponseBody) => {

                        let existingOrder: OrderForReviews;
                        let existingOrderElement: OrderElementForReviews;

                        list.orders_to_review.forEach((order: OrderForReviews) => {
                            order.elements.forEach((e: OrderElementForReviews) => {
                                if (e.ID === order_element_id) {
                                    existingOrder = order;
                                    existingOrderElement = existingOrder.elements.find((el: OrderElementForReviews) => el.ID === order_element_id);
                                }
                            });
                        })


                        if (existingOrderElement && existingOrderElement.ID === order_element_id) {

                            if (existingOrderElement.is_reviewed) {
                                observer.error('is_possible_to_send_review.description');
                                observer.complete();
                            } else {
                                this.getItemReviewAndRedirect({
                                    element: existingOrderElement,
                                    buyer_info: existingOrder.buyer_info,
                                    isCreating: true
                                }).subscribe((res) => {
                                    observer.next();
                                    observer.complete();
                                }, (error) => observer.error(error))
                            }
                        } else {
                            observer.error('not.exist');
                            observer.complete();
                        }
                    }, (error) => observer.error(error));
                 });

    }

    getBuyerAllReview(buyerObj?: any): Observable<ReviewsResponseBody | {} | ErrorInterface> {
        return this.getBuyerReviewRequest()
            .map((_list: ReviewsResponseBody) => {
                this.list_of_reviews = _list;

                if (buyerObj) {
                    this.router.navigate(['/reviews-item'], {queryParams: {buy: buyerObj.buyer}});
                }
                return this.list_of_reviews;
            })
            .catch((err: ErrorInterface) => null);
    }

    getSellerAllReview(buyerObj?: any): Observable<ReviewsResponseBody | {} | ErrorInterface>  {
        return this.getSellerReviewRequest()
            .map((_list: ReviewsResponseBody) => {
                this.list_of_reviews = _list;

                if (buyerObj) {
                    this.router.navigate(['/reviews-item'], {queryParams: {sel: buyerObj.title}});
                }

                return this.list_of_reviews;
            })
            .catch((err: ErrorInterface) => null);

    }

    /**
     * checks the ability to leave a comment, whether this product was purchased,
     * whether requests on this product were previously posted
     * @param {string} item_id
     * @param {string} buyer_id
     * @return {Observable<AccessToSendReviewResponse>}
     */
    checkAccessToSendReview(buyer_id: string, item_id?: string): Observable<AccessToSendReviewResponse> {
        let is_item_review: number;

        if (item_id) {
            is_item_review = 1;
        } else {
            is_item_review = 0;
        }

        return this.reviewsServiceClient.isPossibleToSendReviewGet(buyer_id, is_item_review, this.userService.getUserSession().token, item_id)
            .do((access: AccessToSendReviewResponse) => {
                return access;
            }).catch( (err: ErrorInterface) => {
                return this.errorService.handleError(err)
            });
    }

    /**
     * FR_REVIEW_6
     * System shall notify user about new (unread) received reviews
     * @param {Review[]} reviews
     * @return {Observable<MarkReviewsAsReadResponseBody>}
     */
    markReviewsAsRead(reviews: Review[]): Observable<MarkReviewsAsReadResponseBody> {
        let reviewIDList: Array<string> = [];
        let token = this.userService.getUserSession().token;
        if (!token) return;

        reviews.forEach((review: Review) => {
            reviewIDList.push(review.ID);
        });

        this.reviewsServiceClient.markReviewsAsReadPost({reviews: reviewIDList}, token)
            .subscribe(null, (error: ErrorInterface) => this.errorService.handleError(error));
    }

    /**
     * Get all reviews left for selected seller and navigate to reviews-item view
     * Using when user from shopper details page go to review-item
     * @return {Observable<GetUserReviewsResponseBody>}
     */
    getUserReviewsAndRedirect(params: {
        buyerObj?: SelectedOrder,
        isCreating: boolean,
        element?: OrderElement,
        buyer_info?: UserInfo, buyer_id?: string, is_buyer?: boolean}): Observable<GetUserReviewsResponseBody> {

        this.selectedStrategy(params);

        let token = this.userService.getUserSession().token;
        if (!token) return;

        return this.reviewsServiceClient.getUserReviewsGet(this.selected_order.ID, token)
         .map((reviews: GetUserReviewsResponseBody) => {
             this.setUserReviews = reviews;

             if (params.isCreating) {
                 this.router.navigate(['/create-review'], { queryParams: {buyer: this.selected_order.title}});
             } else if (params.is_buyer) {
                 this.router.navigate(['/reviews-item'], {queryParams: {buy: this.selected_order.title}});
             } else {
                 this.router.navigate(['/reviews-item'], {queryParams: {sel: this.selected_order.title}});
             }

             return this.user_reviews;
         }).catch((error: ErrorInterface) => {
                return this.errorService.handleError(error);
        });
    }

    /**
     * Get all reviews left for selected product and navigate to reviews-item view
     * Using when user from product details page go to review-item
     * @return {Observable<Review[]>}
     */
    getItemReviewAndRedirect(params: {
        buyerObj?: SelectedOrder,
        isCreating: boolean,
        element?: OrderElement,
        buyer_info?: UserInfo}): Observable<ReviewProductInterface> {
        this.selectedStrategy(params);

        return this.getItemReview(this.selected_order.ID)
            .map((list: GetItemReviewsInterface) => {
                this.setProductReviews = list;

                if (params.isCreating) {
                    this.router.navigate(['/create-review'], { queryParams: {product: this.selected_order.title}});
                } else {
                    this.router.navigate(['/reviews-item'], { queryParams: { key: this.selected_order.title }});
                }
                return this.product_reviews;
            })
            .catch((err: ErrorInterface) => this.errorService.handleError(err));
    }

    /**
     * All reviews left for specific product
     * Using when user from product details page go to review-item
     * @param {string} item_id
     * @return {Observable<GetItemReviewsInterface>}
     */
    getItemReview(item_id: string): Observable<GetItemReviewsInterface> {
        let token = this.userService.getUserSession().token;
        if (!token) return;

        return this.dataService.getData(`get_item_review?item_id=${item_id}&offset=0&limit=120`, {token});
    }
    /**
     * Gets reviews total
     * @param item_id
     * @returns reviews total
     */
    getReviewsTotal(item_id: string): Observable<number> {
        return this.getItemReview(item_id).map((review: GetItemReviewsInterface) => {
            return review.reviews.length;
        })
        .catch((err: ErrorInterface) => this.errorService.handleError(err));
    }



    getSellerReviewRequest(): Observable<ReviewsResponseBody | {} | ErrorInterface> {
        let token = this.userService.getUserSession().token;
        if (!token) return Observable.of({});

        return this.reviewsServiceClient.getSellerReviewGet(token)
            .do((list: ReviewsResponseBody) => list)
            .catch((err: ErrorInterface) => this.errorService.handleError(err));
    }

    getBuyerReviewRequest(): Observable<ReviewsResponseBody | {} | ErrorInterface> {
        let token = this.userService.getUserSession().token;
        if (!token) return Observable.of({});

        return this.reviewsServiceClient.buyerReviewGet(token)
            .do((list: ReviewsResponseBody) => list)
            .catch((err: ErrorInterface) => this.errorService.handleError(err));
    }


    showReviewList(buyerObj: SelectedOrder, isCreating: boolean): Observable<ReviewProductInterface> {
        return this.getItemReviewAndRedirect({buyerObj, isCreating});
        // Deprecated
        // return this.checkAccessToSendReview(buyerObj)
        //     .switchMap(() => this.getItemReview(buyerObj));
    }
    showProductDetails(id: string): Promise<SellingItemResponseBody> {
        return this.productDetailsService.showProductDetails(id).toPromise().then((res: SellingItemResponseBody) => res, (err) => err);
    }





    getListReviews(): ReviewsResponseBody {
        return this.list_of_reviews;
    }

    getList(): ReviewsResponseBody {
        return this.list_of_reviews;
    }

    set setProductReviews(review: GetItemReviewsInterface) {
        this.product_reviews = { product_review: review.reviews};
    }
    get getProductReviews(): ReviewProductInterface {
        return this.product_reviews;
    }

    get selectedOrder(): SelectedOrder {
        return this.selected_order;
    }

    set setUserReviews(reviews: GetUserReviewsResponseBody) {
        this.user_reviews = reviews;
    }
    get getUserReviews(): GetUserReviewsResponseBody {
        return this.user_reviews;
    }

    set isOpenedReviewFromSettings(isFromSettingsPage: boolean) {
        this.isFromSettings = isFromSettingsPage;
    }


    selectedStrategy(params: { buyerObj?: SelectedOrder, element?: OrderElement, isCreating: boolean, buyer_info?: UserInfo, buyer_id?: string}) {
        if (params.buyerObj) {
            this.setSelectedProduct(params.buyerObj);
        }
        if (params.element && params.buyer_info) {
            this.setSelectedOrder(params.element, params.buyer_info, params.buyer_id);
        }
    }

    setSelectedOrder(element: OrderElement, buyer_info: UserInfo, buyer_id?: string) {
        if (this.appFooterService.isSellerMode()) {
            this.selected_order = {
                ID: buyer_id || buyer_info.ID,
                title: buyer_info.title,
                url: (buyer_info.imageURL || '../assets/images/cart/no_image_icon.png'),
                order_element_id: element.ID
            };
        } else {
            this.selected_order = {
                ID: element.item_info.current_item_id,
                title: element.item_info.title,
                url: (element.item_info.photoURL || '../assets/images/cart/no_image_icon.png'),
                order_element_id: element.ID || ''
            };
        }
    }

    setSelectedProduct(obj: SelectedOrder) {
        this.selected_order = {
            ID: obj.ID,
            title: obj.title,
            url: (obj.url || '../assets/images/cart/no_image_icon.png'),
            order_element_id: obj.order_element_id || ''
        };
    }

    goToSelectedOrderID(order_id: string) {
        this.ordersAppService.setSelectedOrderID = order_id;
        this.ordersAppService.isOpenedHistoryFromReview = true;
        this.router.navigate(['/history']);
    }


    public backToHome() {
        this.selectFilterTab('received');
    }

    public selectPurchasesFilterTab() {
        this.selectFilterTab('purchases');
    }

    private selectFilterTab(filterName: 'received' | 'written' | 'purchases') {
        this.listFilterTabs.forEach((filter: ModalFilterArgumentInterface, n: number) => {
            this.listFilterTabs[n].active = false;

            if (filter.value === filterName) {
                this.listFilterTabs[n].active = true;
            }
        });
    }

}
