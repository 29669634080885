import { Injectable }       from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
}                           from '@angular/router';
import { CookieService }    from './cookie.service';
import { UserService }      from '../user/user.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router:         Router,
        private cookieService:  CookieService,
        private userService:    UserService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // console.log('AuthGuard', !!this.cookieService.getCookie('user') ? true : false);

        let token = this.userService.getUserSession().token;
        let userToken = this.cookieService.getCookie('user');

        if ( userToken || token) return true;

        this.router.navigate(['/shopping-list']);

        return false;
    }

}
