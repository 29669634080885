import { CommonModule }                     from '@angular/common';
import {FormsModule, ReactiveFormsModule}                      from '@angular/forms';
import { NgModule, ModuleWithProviders }    from '@angular/core';
import { RouterModule }                     from '@angular/router';

import { CreateReviewComponent }            from './create-reviews.component';
import {AppReviewsService}                  from './reviews.service';
import { ReviewsComponent }                 from './reviews.component';
import { ReviewsHeaderComponent }           from './reviews-header.component';
import { SharedModule }                     from '../shared/shared.module';
import {ReviewsProductsComponent} from './review-list/reviews-products';
import {ReviewsUsersComponent} from './review-list/reviews-users';
import {ReviewsPurchasesComponent} from './review-list/reviews-purchases';
import {CreateReviewProductComponent} from './create-review/create-review-product';
import {CreateReviewBuyerComponent} from './create-review/create-review-seller';
import {ReviewsListUsersComponent} from './review-list/reviews-list-users';
import {ReviewsListProductsComponent} from './review-list/reviews-list-products';



@NgModule({
    imports: [
        RouterModule,
        SharedModule,

        CommonModule,
        ReactiveFormsModule,
        FormsModule
    ],
    declarations: [
        ReviewsComponent,
        ReviewsHeaderComponent,
        ReviewsProductsComponent,
        ReviewsPurchasesComponent,
        ReviewsUsersComponent,
        ReviewsListUsersComponent,
        ReviewsListProductsComponent,

        CreateReviewComponent,
        CreateReviewProductComponent,
        CreateReviewBuyerComponent
    ],
    exports: [
        CreateReviewComponent,
        ReviewsComponent,
    ],
    entryComponents: [
        ReviewsComponent,
        ReviewsHeaderComponent,
        ReviewsProductsComponent,
        ReviewsPurchasesComponent,
        ReviewsUsersComponent,
        ReviewsListUsersComponent,
        ReviewsListProductsComponent,

        CreateReviewComponent,
        CreateReviewProductComponent,
        CreateReviewBuyerComponent
    ],
    providers:    [ AppReviewsService ]
})
export class ReviewsModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ReviewsModule,
            providers: [AppReviewsService]
        };
    }
}
