import { Component }        from '@angular/core';
import { Router }           from '@angular/router';

import { AppFooterService } from '../app-footer/app-footer.service';
import {Location} from '@angular/common';



@Component({
    selector: 'analytics',
    styleUrls:  ['analytics.sass'],
    template: `
        <div class="component analytics-component">
            <analytics-header [analyticsUrl]="'/orders'" (goBackEvent$)="goBack()"></analytics-header>
            <ul class="orders-menu" data-test-id="orders-menu">
                <button class="orders-menu__item" data-test-id="history" type="button" (click)="goTo($event.target)">
                    {{ "analytics.header.history" | translate }}
                </button>
                <button *ngIf="mode === 'seller'" type="button" class="orders-menu__item" data-test-id="pending-orders"
                        (click)="goTo($event.target)">
                    {{ "analytics.header.pendingOrders" | translate }}
                </button>
            </ul>
        </div>
    `
})

export class AnalyticsComponent {

    mode:               string;
    location:   Location;

    constructor(
        private appFooterService:   AppFooterService,
        private router:             Router,
        location:                   Location
    ) {
        this.location = location;
        this.mode = this.appFooterService.getMode();
    }

    goTo(elem: HTMLElement) {
        const tgt  = elem.closest('button[data-test-id]');

        if (!tgt) return;

        const name = tgt.getAttribute('data-test-id');

        this.router.navigate(['/' + name]);
    }

    goBack() {
        this.location.back();
    }
}
