import { Injectable }           from '@angular/core';

import { environment }          from '../../environments/environment';
import { UserLoginInterface }   from '../interfaces/user.interface';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {PaymentNotification} from '../../../swagger-gen__output_dir/model/paymentNotification';
import {ShoppingCartResponseBody} from '../../../swagger-gen__output_dir/model/shoppingCartResponseBody';
import {Advertisement} from '../../../swagger-gen__output_dir/model/advertisement';

declare var gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
    public gt = gtag;
    public GA_TRACKING_ID:  string = environment.GA_TRACKING_ID;
    // initGtag(gtag: Function) { gtag = gtag; }

    handleEvent(eventName: string, eventCategory: string, eventLabel: string) {
         this.gt('event', eventName, {
                'event_category':   eventCategory,
                'event_label':      eventLabel
            });
    }
    handleErrorEvent(eventName: string, eventCategory: string, eventLabel: string) {
         this.gt('event', eventName + '_failure ', {
                'event_category':   eventCategory,
                'event_label':      eventLabel
            });
    }

    handleClickUserLoginButton(eventCategory: string, eventAction: string, eventValue: UserLoginInterface) {
        let report = '';
        eventValue.login.length !== 0 ? report = report.concat('User ' + eventValue.login + ' has entered login') : report = report.concat('User has not entered login');
        eventValue.password.length !== 0 ? report = report.concat(' and entered password') : report = report.concat(' and NOT entered password');

        this.gt('event', eventAction,
            {
            'event_category':   eventCategory,
            'event_label':      'login_button_pressed ' + report,
            });
        this.handlePageViews('/login_button');
        console.count('login_button_pressed');
    }

    handleClickButton(eventAction: string, eventCategory: string, eventLable: string) {
        this.gt('event', eventAction, { 'event_category':   eventCategory , 'event_label': eventLable });
    }

    handleViewSearchResults(search_term: string): void {
        this.gt('event', 'view_search_results', {
            search_term
        });
    }

    handleFieldTouched(eventLabel: string) {
        this.gt('event', 'field_touched',
            {
                'event_category': 'field',
                'event_label': eventLabel,
            });
    }

    handleLogin(eventCategory: string, eventLabel: string, eventValue: string) {
        this.gt('event', 'login',
            {
            'event_category':   eventCategory,
            'event_label':      eventLabel + ' user: ' + eventValue,
            }
        );
    }
    handleSignUp(eventCategory: string, eventLabel: string, eventValue: string) {
        this.gt('event', 'sign_up',
            {
            'event_category':   eventCategory,
            'event_label':      eventLabel + ' user: ' + eventValue,
            }
        );
        // this.gt('event', 'signup_form_complete', { 'event_callback': eventValue});
    }

    handleLogOut(eventCategory: string, eventLabel: string, eventValue: string) {
        this.gt('event', 'logout',
            {
            'event_category':   eventCategory,
            'event_label':      eventLabel + ' user: ' + eventValue,
            }
        );
    }

    handleAccessLevelOfUser(access_level: number|string, user_login: string) {
        switch (access_level) {
            case 0:
                access_level = 'guest';
                break;

            case 1:
                access_level = 'shopper';
                break;

            case 2:
                access_level = 'seller & shopper';
                break;

            case 3:
                access_level = 'admin';
                break;

            default:
                return access_level;
        }
        this.gt('event', access_level, { 'event_category':   'access_level_of_user' , 'event_label': user_login });
    }

    handleException(event_name: string, description: string, fatal: boolean) {
        this.gt('event', 'exception',
            {
            'description':    description,
            'fatal':          fatal
            }
        );

        this.gt('event', event_name.concat('_failure'),
            {
            'event_category':   'exception',
            'event_label':      description,
            }
        );

    }

    handleScreen(url: string) {
        let page_name = url.slice(1).replace('-', '_');

        this.gt('event', 'screen_view', {
            'app_name': 'BiziBAZA',
            'screen_name': page_name.concat('_screen_show'),
            'app_version': environment.version,
        });
        this.gt('event', 'app_version', {
            'event_category': 'app_version',
            'event_label': environment.version,
            'event_value': environment.version,
        });
        this.gt('set', 'app_version', environment.version);
        this.gt('set', 'dimension1', environment.version);
        this.gt('send', 'pageview');
    }
    handlePageViews(url: string) {
        let page_title = url.slice(1).replace('-', ' ');

        this.gt('config', this.GA_TRACKING_ID, {
            'page_title': page_title,
            'page_location': environment.apiUrl,
            'page_path': url,
            'app_version': environment.version,
        });
    }
    handleVirtualPage(url: string) {
        this.gt('config', this.GA_TRACKING_ID, {
            'page_path': '/virtualpage/' + url
        });
    }

     handleForSearch(keyword: string) {
        this.gt('event', 'search', { 'search_term': keyword });
        this.gt('config', this.GA_TRACKING_ID, {'page_path': 'search' + '?q=' + keyword});
     }

     handleForSearchByType(type: string, key_word: string, quantity_of_results?: number) {
         quantity_of_results && this.gt('event', 'search_by_' + type, {
             'event_category':  'search_type',
             'event_label':     key_word,
             'event_value':     quantity_of_results
         });
         !quantity_of_results && this.gt('event', 'search_by_' + type, {
             'event_category':  'search_type',
             'event_label':     key_word
         });
     }
     handleForBiziPixViewer(type: string, sellerID: string){
        this.gt('event', 'bizipix_' + type, {
            'event_category':  'bizipix',
            'event_label':     'sellerID: ' + sellerID,
        });
     }

    /** Track additions to and removals from shopping cart **/
    /**
     * To track the addition of a product to a shopping cart, send an add_to_cart event with the product information:
     * @param {SellingItemResponseBody} item
     * @param {string} from
     */
     handleAddToCart(item: SellingItemResponseBody) {
         this.gt('event', 'add_to_cart', {
             "items": [
                 {
                     'value': item.price,
                     'currency': 'USD',
                     'items': [{id: item.ID, name: item.title, price: item.price, category: item.subcategoryEntryName}]
                 }]
         });
         this.handleVirtualPage('add_to_cart');
     }

     handleAddToCartFromFunnel(item: SellingItemResponseBody, funnel_name: string) {
         this.gt('event', 'add_to_cart_from_' + funnel_name, {
             "items": [
                 {
                     'value': item.price,
                     'currency': 'USD',
                     'items': [{id: item.ID, name: item.title, price: item.price, category: item.subcategoryEntryName}]
                 }]
         });
         this.handleVirtualPage('add_to_cart_from_' + funnel_name);
     }
     /**
     * To track the removal of a product from a shopping cart, send an remove_from_cart event with the product information
     * @param {SellingItemResponseBody} item
     */
    handleRemoveFromCart(item: any) {
        this.gt('event', 'remove_from_cart', {
            'value': item.price,
            'currency': 'USD',
            "items": [{ id: item.ID, name: item.title, category: item['subcategoryEntryName'], price: item.price }]
        });
    }

    /**
     * a user adds items to wishlist
     * @param {SellingItemResponseBody} item
     */
     handleAddToWishlist(item: SellingItemResponseBody) {
         this.gt('event', 'add_to_wishlist', {
             "items": [
                 {
                     'value': item.price,
                     'currency': 'USD',
                     'items': [{id: item.ID, name: item.title, price: item.price, category: item.subcategoryEntryName}]
                 }]
         });
     }


    /**
     * a user completes a purchase
     * @param {PaymentNotification} report
     */
    handlePurchase(report: PaymentNotification) {
        let items = new Array();
        const report_elements = report.elements;

        for (const element in report_elements) {
            const item_elements = report_elements[element].elements;

            for (const item in item_elements) {
                items[element] = new Object();

                items[element]['item_id'] = item_elements[item].item_info.real_item_id;
                items[element]['item_name'] = item_elements[item].item_info.title;
                items[element]['price'] = item_elements[item].item_info.price / 100 || 0;
                items[element]['quantity'] = item_elements[item].qty;
                items[element]['index'] = element;
            }

            this.gt('event', 'purchase', {
                value: report_elements[element]['price'] / 100 || 0,
                currency: report['currency'] || 'USD',
                items: items,
                transaction_id: report_elements[element]['order_id']
            });
        }
        // let coupon = '';
        // if(report.report['coupon']) coupon = report.report['coupon'];
    }

    /**
     * Track product impressions
     * To track product impressions, send a view_item_list with the product information:
     */
    handleViewItemList(cart: ShoppingCartResponseBody){
        this.gt('event', 'view_item_list', {
          "items": cart.items
            // [{
            //   "id": "P12345",
            //   "name": "Android Warhol T-Shirt",
            //   "list_name": "Search Results",
            //   "brand": "Google",
            //   "category": "Apparel/T-Shirts",
            //   "variant": "Black",
            //   "list_position": 1,
            //   "quantity": 2,
            //   "price": 2
            // },
            // {
            //   "id": "P67890",
            //   "name": "Flame challenge TShirt",
            //   "list_name": "Search Results",
            //   "brand": "MyBrand",
            //   "category": "Apparel/T-Shirts",
            //   "variant": "Red",
            //   "list_position": 2,
            //   "quantity": 1,
            //   "price": 3
            // }]
        });
    }




    handleTrackProductDetailsView(item: SellingItemResponseBody) {
        this.gt('event', 'view_item', {
            "items": [{id: item.ID, name: item.title, category: item.subcategoryEntryName, price: item.price}]
        });
    }

    /**********************************************************/
    /**
     * Track promotion impressions
     * To track promotion impressions, send a view_promotion event with promotion information
     * @param {Advertisement} ad
     */
    handleViewPromotion(ad: Advertisement) {
        this.gt('event', 'view_promotion', {
            "promotions": [
                {
                  "id":     ad.ID,
                  "name":   ad.seller.title
                }
            ]});
    }
    /**
     * Track promotion clicks
     * To track a promotion click, send a select_content event and provide the promotion:
     * @param {Advertisement} ad
     */
    handleSelectContent(ad: Advertisement) {
        this.gt('event', 'select_content', {
            "promotions": [
                {
                  "id":     ad.ID,
                  "name":   ad.seller.title
                }
              ]
            });
    }
    /**********************************************************/
}
