import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AddEventService } from './api/addEvent.service';
import { AdvertisementService } from './api/advertisement.service';
import { AnalyticsService } from './api/analytics.service';
import { ChangePasswordHandlerService } from './api/changePasswordHandler.service';
import { CountersService } from './api/counters.service';
import { CouponsService } from './api/coupons.service';
import { CreateDialogService } from './api/createDialog.service';
import { CreateMessageService } from './api/createMessage.service';
import { CreatePixImageService } from './api/createPixImage.service';
import { DefaultService } from './api/default.service';
import { GetBBShareService } from './api/getBBShare.service';
import { GetCustomItemsForAdminService } from './api/getCustomItemsForAdmin.service';
import { GetDialogsService } from './api/getDialogs.service';
import { GetItemSuggestionsForShoppingListService } from './api/getItemSuggestionsForShoppingList.service';
import { GetPixImageService } from './api/getPixImage.service';
import { GetUserShoppingListService } from './api/getUserShoppingList.service';
import { LoginByTokenService } from './api/loginByToken.service';
import { LoginGuestService } from './api/loginGuest.service';
import { LoginImpersonatedService } from './api/loginImpersonated.service';
import { MarketService } from './api/market.service';
import { MessagingService } from './api/messaging.service';
import { OrdersService } from './api/orders.service';
import { PaymentsService } from './api/payments.service';
import { ReadMessagesService } from './api/readMessages.service';
import { RegisterForPushNotificationsService } from './api/registerForPushNotifications.service';
import { RemindLoginService } from './api/remindLogin.service';
import { ResendVerificationEmailService } from './api/resendVerificationEmail.service';
import { ResetPasswordHandlerService } from './api/resetPasswordHandler.service';
import { ReviewsService } from './api/reviews.service';
import { SearchService } from './api/search.service';
import { SearchItemHandlerService } from './api/searchItemHandler.service';
import { SellingItemService } from './api/sellingItem.service';
import { ShoppingCartService } from './api/shoppingCart.service';
import { UpdateEventService } from './api/updateEvent.service';
import { UpdateItemService } from './api/updateItem.service';
import { UpdateShoppingListService } from './api/updateShoppingList.service';
import { UpdateUserService } from './api/updateUser.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AddEventService,
    AdvertisementService,
    AnalyticsService,
    ChangePasswordHandlerService,
    CountersService,
    CouponsService,
    CreateDialogService,
    CreateMessageService,
    CreatePixImageService,
    DefaultService,
    GetBBShareService,
    GetCustomItemsForAdminService,
    GetDialogsService,
    GetItemSuggestionsForShoppingListService,
    GetPixImageService,
    GetUserShoppingListService,
    LoginByTokenService,
    LoginGuestService,
    LoginImpersonatedService,
    MarketService,
    MessagingService,
    OrdersService,
    PaymentsService,
    ReadMessagesService,
    RegisterForPushNotificationsService,
    RemindLoginService,
    ResendVerificationEmailService,
    ResetPasswordHandlerService,
    ReviewsService,
    SearchService,
    SearchItemHandlerService,
    SellingItemService,
    ShoppingCartService,
    UpdateEventService,
    UpdateItemService,
    UpdateShoppingListService,
    UpdateUserService,
    UsersService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
