
        <label [ngClass]="classLabel">
            <ng-content></ng-content>
            <input [ngClass]="{ error: isPassErr }" [class]="classInput"
                   [id]="forInput"
                   (keyup)="onInputTrimed($event)" (blur)="onFieldBlurred($event)"
                   [autofocus]="autofocusField"
                   [attr.data-test-id]="dataTestIdInput" autocomplete="current-password"
                   [placeholder]="placeholderInput" [attr.name]="nameInput" required [(ngModel)]="value"
                   [attr.type]="isPassVisible ? 'text' : 'password'"
                   [ngModelOptions]="{standalone: true}">

            <button (click)="changePassVisibility = !isPassVisible" [class]="classShowPasswordPosition + ' show_password'"
                 type="button" [attr.data-test-id]="dataTestIdInput + '_showPassword'">
                <span *ngIf="!isPassVisible"
                      data-test-id="passInvisible"
                      class="icon-eye_outlined"></span>

                <span *ngIf="isPassVisible"
                      data-test-id="passVisible"
                      class="icon-watchlist_icon"></span>
            </button>
        </label>
    