import {
    Component, ViewChildren,
    EventEmitter, Input,
    Output, QueryList,
    OnChanges, SimpleChanges,
    AfterViewInit, OnInit
} from '@angular/core';
import { AgmInfoWindow } from '@agm/core';

import { CenterLocationInterface, LocationInterface } from '../interfaces/location.interface';
import { MarketInterface } from '../interfaces/market.interface';
import { UserModel } from '../interfaces/user.interface';
import { MapSearchService } from './map-search.service';
import {TranslateService} from '@ngx-translate/core';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { auditTime } from 'rxjs/operators';


@Component({
    selector:   'map-board',
    styleUrls:  ['./map-search.sass'],

    template:   `
        <div class="component__container component__container-map-board">
            <agm-map class="map-board__map"
                (centerChange)="onCenterChange($event)"
                (idle)="mapReady(displayLocation)"
                [styles]="mapStyles"
                (zoomChange)="onZoomChange($event)"
                [latitude]='displayLocation.latitude'
                [longitude]='displayLocation.longitude'
                [zoom]='data.position["zoom"]'
                [scrollwheel]="false">

                <agm-marker *ngFor="let m of data.markets; trackBy: trackByID; let i = index"
                    (markerClick)="clickedMarker(infowindow)"
                    [latitude]="m.market_latitude"
                    [longitude]="m.market_longitude"
                    [iconUrl]="marketPin"
                    [markerDraggable]="false">

                    <agm-info-window class="marker__market" #infowindow>
                        <map-info [data]="m" [timeDatesInfo]="timeDatesInfo" (clickEvent$)="showDetails($event, 'market', m)"></map-info>
                    </agm-info-window>
                </agm-marker>

                <agm-marker *ngFor="let s of data.sellers; trackBy: trackByID; let i = index"
                    (markerClick)="clickedMarker(infowindow)"
                    [latitude]="s.latitude"
                    [longitude]="s.longitude"
                    [iconUrl]="sellerPin"
                    [markerDraggable]="false">

                    <agm-info-window class="marker__seller" #infowindow>
                        <map-info [data]="s" (clickEvent$)="showDetails($event, 'seller', s)"></map-info>
                    </agm-info-window>
                </agm-marker>
            </agm-map>
        </div>
    `
})

/**
 * A presenter component for Google maps.
 */
export class MapBoardComponent extends UnsubscribeOnDestroyAbsctractClass implements OnInit, OnChanges, AfterViewInit {

    @ViewChildren(AgmInfoWindow) infoWins: QueryList<AgmInfoWindow>;

    @Input() data: {
        markets:        MarketInterface[];
        sellers:        UserModel[];
        position:       {};
        type:           string;
        userLocation:   CenterLocationInterface;
    };
    @Input() displayLocation:       CenterLocationInterface;

    @Output() positionChangeEvent$  = new EventEmitter();
    @Output() showDetailsEvent$     = new EventEmitter();

    public timeDatesInfo:          string;
    public currentPosition:        LocationInterface;
    public marketPin               = '../assets/images/map_pin_market_icon.png';
    public sellerPin               = '../assets/images/map_pin_user_icon.png';
    public previous;
    public mapStyles = [{
        "featureType": "poi.attraction",
        "stylers": [
            {"visibility": "off"}
        ]
    },
        {
            "featureType": "poi.business",
            "stylers": [
                {"visibility": "off"}
            ]
        },
        {
            "featureType": "poi.government",
            "stylers": [
                {"visibility": "off"}
            ]
        },
        {
            "featureType": "poi.medical",
            "stylers": [
                {"visibility": "off"}
            ]
        },
        {
            "featureType": "poi.park",
            "stylers": [
                {"visibility": "off"}
            ]
        },
        {
            "featureType": "poi.place_of_worship",
            "stylers": [
                {"visibility": "off"}
            ]
        },
        {
            "featureType": "poi.school",
            "stylers": [
                {"visibility": "off"}
            ]
        },
        {
            "featureType": "poi.sports_complex",
            "stylers": [
                {"visibility": "off"}
            ]
        }];

    constructor(
        private mapSearchService: MapSearchService,
        private translate: TranslateService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.timeDatesInfo = this.translate.instant("market.timeDates.info");
        this.subscribeOnCenterLocationChanges();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.displayLocation = null;
        this.mapSearchService.resetCenterLocation();
    }

    /**
     * If no updates for @displayLocation, takes its previous value.
     * @param {SimpleChanges} changes
     */
    ngOnChanges(changes: SimpleChanges) {
        if (!changes.displayLocation.currentValue) {
            this.displayLocation = changes.displayLocation.previousValue;
        }
    }

    /**
     * add data-test-id when the component is loaded
     */
    ngAfterViewInit () {
        const container = document.getElementsByClassName("agm-map-container-inner")[0];

        if (container) container.setAttribute("data-test-id", "content-map");
    }

    /**
     * @emits showDetailsEvent$ on mapInfoWindow click to show a market or seller details.
     * @param {string} ID
     * @param {string} type
     */
    showDetails(ID: string, type: string, data: any): void {
        this.showDetailsEvent$.emit({
           ID,
           type,
           currentPosition: this.currentPosition,
           data
        });
    }

    /**
     * This event is fired when the map becomes idle after panning or zooming.
     * @param {CenterLocationInterface} displayLocation
     */
    mapReady(displayLocation: CenterLocationInterface) {
        let currentPosition = this.getCenter();

        if ( currentPosition ) {
            if (this._round(displayLocation.latitude) !== this._round(currentPosition.center.latitude)
                || this._round(displayLocation.longitude) !== this._round(currentPosition.center.longitude)) {
               this.positionChangeEvent$.emit(currentPosition);
            }
        } else {
            this.displayLocation = displayLocation;
            this.positionChangeEvent$.emit({center: displayLocation});
        }
    }

    /**
     * @desc If the position of the map changed notably
     * @emits positionChangeEvent$.
     * @param {CenterLocationInterface} center
     */
    onCenterChange(center: CenterLocationInterface): void {
        this._createcurrentPositionObject();

        this.currentPosition = Object.assign(this.currentPosition, {
            center: {
                latitude: parseFloat(center['lat']),
                longitude: parseFloat(center['lng'])
            },
            zoom: this.data.position['zoom']
        });

        this.data = this.mapSearchService.getData() as any;

        this.data.position['center'] = this.currentPosition.center;
    }

    private subscribeOnCenterLocationChanges(): void {
        this.trackSubscription(
            this.mapSearchService.centerLocationChanges.pipe(auditTime(100)).subscribe(
                (centerLocation: LocationInterface) => {
                    this.positionChangeEvent$.emit(centerLocation);
            }),
        );
    }

    /**
     * Method for get coordinates by center position
     * @returns {LocationInterface}
     */
    private getCenter(): LocationInterface {
        return this.currentPosition;
    }

    _createcurrentPositionObject() {
        if (!this.currentPosition) {
            this.currentPosition = {
               center: {},
               zoom: this.data.position['zoom']
            };
        }
    }

    /**
     * Rounds the given number.
     * @param {number} x
     * @returns {number}
     * @private
     */
    _round(x: number): number {
        return Math.round(x * 1000) / 1000;
    }

    /**
     * @emits positionChangeEvent$ on the map zoom change.
     * @param {number} zoom
     */
    onZoomChange(zoom: number): void {
        let center: CenterLocationInterface  = this.getCenter() && this.getCenter().center;
        this.data = this.mapSearchService.getData() as any;

        if (!center) {
            center = this.data.position['center'];
            this._createcurrentPositionObject();
        }

        this.currentPosition = Object.assign(this.currentPosition, {
           center,
           zoom
        });

        this.data.position['zoom'] !== zoom
            && this.positionChangeEvent$.emit(this.currentPosition);
    }

    /**
     * If you click on marker then close previous opened marker
     * @param infowindow
     */
    clickedMarker(infowindow) {
        if (this.previous) this.previous.close();
        this.previous = infowindow;
     }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {MarketInterface | UserModel} item
     * @return {string}
     */
    trackByID(index: number, item: MarketInterface | UserModel): string {
        return item.ID;
    }
}
