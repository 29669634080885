import { AgmCoreModule }                    from '@agm/core';
import { CommonModule }                     from '@angular/common';
import { NgModule, ModuleWithProviders }    from '@angular/core';
import { RouterModule }                     from '@angular/router';

import { ShopperDetailsService }    from './shopper-details.service';
import { ShopperDetailsComponent }  from './shopper-details.component';
import { SharedModule }             from '../shared/shared.module';
import { environment }              from '../../environments/environment';

@NgModule({
    imports: [
        AgmCoreModule.forRoot({
            apiKey: environment.GM_API_KEY
        }),
        CommonModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        ShopperDetailsComponent,
    ],
    exports: [
        ShopperDetailsComponent,
    ]
})
export class ShopperDetailsModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShopperDetailsModule,
            providers: [ShopperDetailsService]
        };
    }
}
