import {
    OnInit,
} from '@angular/core';

import { ModalService }             from '../../modal/modal.service';
import {AppReviewsService}          from '../reviews.service';
import {Order, OrderElement, Review} from '../../../../swagger-gen__output_dir';
import {UserInfo} from '../../../../swagger-gen__output_dir/model/userInfo';
import {AppFooterService} from '../../app-footer/app-footer.service';
import {ScrollToConfigOptionsTarget} from "@nicky-lenaers/ngx-scroll-to/src/app/modules/scroll-to/scroll-to-config.interface";
import {ScrollToService} from "@nicky-lenaers/ngx-scroll-to";

export interface ReviewsContext {
   filteredItems:           Review[] | Order[];
   selected_order_id:       string;

   callback?({}: ReviewsContextCallback);
}
export interface ReviewsContextCallback {
    addReview?: {element: OrderElement, buyer_info: UserInfo, buyer_id: string};
    showUserReviews?: {element: OrderElement, user_info: UserInfo, user_id: string, is_buyer: boolean};
    showReviewedItem?: string;
}

export abstract class ReviewsAbstractClass implements OnInit {
    context:            ReviewsContext;
    filteredItems:      Review[] | Order[];
    isSeller:           boolean;

    modalService:       ModalService;
    reviewsService:     AppReviewsService;
    scrollToService:    ScrollToService;
    footerService:      AppFooterService;


    constructor(
        modalService:           ModalService,
        reviewsService:         AppReviewsService,
        scrollToService:        ScrollToService,
        footerService:          AppFooterService,
    ) {
        this.modalService       = modalService;
        this.reviewsService     = reviewsService;
        this.scrollToService    = scrollToService;
        this.footerService      = footerService;
    }


    /**
     * Fetches the review item if specified.
     */
    ngOnInit(): void {
        if (this.context) {
            this.filteredItems = this.context.filteredItems;

            if (this.context.selected_order_id && this.context.selected_order_id !== '') {
                this.triggerScrollTo(this.context.selected_order_id);
            }
        }

        this.isSeller = this.footerService.isSellerMode();
    }

    /**
     * UIR_REVIEW_13:
     * When user clicks Reviewed item in the “Purchases” mode,
     * system show corresponding review.
     * @param {string} id
     */
    public triggerScrollTo(id: string): void {
        let config: ScrollToConfigOptionsTarget = {
            "container": 'custom-container',
            "target": id,
            "duration": 149,
            "easing": 'easeInCubic',
            "offset": 0
        };

        setTimeout(() => this.scrollToService.scrollTo(config));
    }


}
