<div class="abstract-list__container">
    <h4 *ngIf="items && items.length && title" class="search-sublist__header text-center">{{title}}</h4>

    <ul *ngIf="items && items.length" class="abstract-list__ul">

        <li class="abstract-list__li text-center"
            [attr.data-id]="item['_id'] || item['ID']"
            [attr.data-test-id]="'user_'+i"
            *ngFor="let item of items; trackBy: trackByID; let i = index;">

            <div class="abstract-list__li__box">
                <div class="abstract-list__img-container">
                    <img *ngIf="item['imageURL']" [src]="item['imageURL']" >
                    <div *ngIf="!item['imageURL']" class="sprite sprite-no_image_icon"></div>
                </div>

                <div class="abstract-list__product__container text-left">
                    <h4 class="abstract-list__product__title"
                        [attr.data-test-id]="'userTitle_'+i">
                        {{item['title']}}
                    </h4>
                    <h5 class="abstract-list__product__title"
                        [attr.data-test-id]="'userEmail_'+i">
                        <a href="mailto: {{item['email']}}">{{item['email']}}</a>
                    </h5>
                 </div>

                <div class="abstract-list__product__open">
                    <button class="impersonation-button" (click)="onClick(item)">{{ "users.list.impersonation" | translate }}</button>
                </div>

            </div>
        </li>
    </ul>
</div>
