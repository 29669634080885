import {AfterViewInit,
    Component, Input}               from '@angular/core';

import { MarketInterface }          from '../../interfaces/market.interface';


@Component({
    selector:   'event-section',
    styleUrls:  ['../product-editor.sass'],

    template:   `
        <section class="product-editor__fields">
            <div class="product-editor__market">
                
                <div class="market-title">
                    <h3 data-test-id="marketTitle">{{market.market_title}}</h3>
                    <p><a [href]="market.market_web_page" target="_blank" data-test-id="marketWebPage">{{market.market_web_page}}</a></p>
                    
                    <p data-test-id="marketSchedule">
                        <span data-test-id="timeDatesInfo">{{timeDatesInfo}}</span>
                    </p>
                </div>
                
                <div class="market-map">
                    <agm-map
                        [disableDefaultUI]='true'
                        [disableDoubleClickZoom]='true'
                        [latitude]='market.market_latitude'
                        [longitude]='market.market_longitude'
                        [scrollwheel]="false"
                        [streetViewControl]="false"
                        [mapDraggable]="false"
                        [zoom]='zoom'
                        [zoomControl]='false'>

                        <agm-marker
                            [latitude]='market.market_latitude'
                            [longitude]='market.market_longitude'
                            [markerDraggable]="false">
                        </agm-marker>
                    </agm-map>
                </div>
                
            </div>
        </section>
    `
})
/**
 * A markup component representing market info.
 */
export class EventSectionComponent implements AfterViewInit {
    @Input()    market:         MarketInterface;
    @Input()    timeDatesInfo:  string;

    public zoom: number = 8;

    public ngAfterViewInit(): void {
        document.getElementsByClassName("agm-map-container-inner")[0].setAttribute("data-test-id", "content-map");
    }
}
