/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modal-spinner.component";
var styles_ModalSpinnerComponent = [];
var RenderType_ModalSpinnerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalSpinnerComponent, data: {} });
export { RenderType_ModalSpinnerComponent as RenderType_ModalSpinnerComponent };
export function View_ModalSpinnerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "loader"], ["data-test-id", "spinner"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Loading..."]))], null, null); }
export function View_ModalSpinnerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "modal-spinner", [], null, null, null, View_ModalSpinnerComponent_0, RenderType_ModalSpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i1.ModalSpinnerComponent, [], null, null)], null, null); }
var ModalSpinnerComponentNgFactory = i0.ɵccf("modal-spinner", i1.ModalSpinnerComponent, View_ModalSpinnerComponent_Host_0, {}, {}, []);
export { ModalSpinnerComponentNgFactory as ModalSpinnerComponentNgFactory };
