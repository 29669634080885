import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';


import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';

import {AppFooterService} from '../app-footer/app-footer.service';
import {DataService} from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import {ModalService} from '../modal/modal.service';
import {ProductDetailsService} from '../product-details/product-details.service';
import {ProductDetailsOptionsInterface} from '../interfaces/product.interface';
import {UserService} from '../user/user.service';
import {ChatService} from '../chat/chat.service';
import {AnalyticsService, OrderElement, SellingItemResponseBody} from '../../../swagger-gen__output_dir';
import {SellingHistoryResponse} from '../../../swagger-gen__output_dir/model/sellingHistoryResponse';
import {BuyingHistoryResponse} from '../../../swagger-gen__output_dir/model/buyingHistoryResponse';
import {OrdersService} from '../../../swagger-gen__output_dir/api/orders.service';
import {OrdersResponse} from '../../../swagger-gen__output_dir/model/ordersResponse';
import {Order} from '../../../swagger-gen__output_dir/model/order';
import AppValues from '../common/app.values';
import {FullOrderInterface} from './analytics.interface';
import {PickUpAddress} from '../../../swagger-gen__output_dir/model/pickUpAddress';


@Injectable()
export class OrdersAppService {

    public buyhistory:         BuyingHistoryResponse|SellingHistoryResponse;
    public orders:             Order[];
    public options:            ProductDetailsOptionsInterface;
    public mode:               string;

    public isOpenedHistoryFromReview:  boolean = false;

    private selectedOrderId:    string = '';

    public constructor(
        private appFooterService:       AppFooterService,
        private chatService:            ChatService,
        private dataService:            DataService,
        private errorService:           ErrorService,
        private modalService:           ModalService,
        private productDetailsService:  ProductDetailsService,
        private userService:            UserService,
        private analyticsService:       AnalyticsService,
        private ordersService:          OrdersService,
    ) {
        this.mode = this.appFooterService.getMode();
    }


    public getBuyingHistory(): Observable<BuyingHistoryResponse> {
        const token: string = this.userService.getUserSession().token;
        this.modalService.showSpinner();

        if (token) {
            return this.analyticsService.getBuyingHistoryGet(token)
                .do((res: BuyingHistoryResponse) => {
                    this.modalService.close();
                    this.buyhistory = res;
                })
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({});
    }


    public getSellingHistory(): Observable<SellingHistoryResponse> {
        const token: string = this.userService.getUserSession().token;
        this.modalService.showSpinner();

        if (token) {
            return this.analyticsService.getSellingHistoryGet(token)
                .do((res: SellingHistoryResponse) => {
                    this.modalService.close();
                    this.buyhistory = res;
                })
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({});
    }


    public getDuty(): Observable<OrdersResponse | {}> {
        const token: string = this.userService.getUserSession().token;
        this.modalService.showSpinner();

        if (token) {
            return this.ordersService.pendingOrdersGet(token)
                .map((res: OrdersResponse) => {
                    this.modalService.close();
                    this.orders = res.orders;

                    return this.orders;
                })
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({});
    }


    public sortItems(items: FullOrderInterface[]): FullOrderInterface[] {
        return items.sort((itemA: FullOrderInterface, itemB: FullOrderInterface) =>
            new Date(itemB.order_datetime).getTime() - new Date(itemA.order_datetime).getTime()
        );
    }


    public deliveryElements(orderId: string, orderElementsIds: string[]): Observable<{}> | Observable<Order[]> |  Observable<never> {
        const token: string = this.userService.getUserSession().token;

        if (token) {
            // @ts-ignore
            return this.ordersService.completeOrderElementsPost({order_id: orderId, order_elements_ids: orderElementsIds}, token)
                .map(() => {
                    this.orders = this.removeOrder(this.orders, orderId, orderElementsIds);
                    return this.orders;
                })
                .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({});
    }

    // tslint:disable-next-line:variable-name
    public removeOrder(orders: Order[], order_id: string, order_elements_ids: string[]): Order[] {
        let changedOrders: Order[] = this.setDeliveryFlag(this.orders, order_id, order_elements_ids);

        changedOrders = changedOrders.filter((o: Order) => !this.isAllDeliveryItems(o));

        return changedOrders;
    }

    public isAllDeliveryItems(order: Order): boolean {
        const lengthOfElem: number = order.elements.length;
        const lengthOfCompletedElem: number = order.elements.filter((e: OrderElement) => e.is_completed).length;

        return lengthOfElem === lengthOfCompletedElem;
    }

    // tslint:disable-next-line:variable-name
    public setDeliveryFlag(orders: Order[], order_id: string, order_elements_ids: string[]): Order[] {
        const changedOrders: Order[] = AppValues.deepCopy(orders);
        changedOrders.forEach((order: Order) => {
            if (order_id === order.order_id) {

                order.elements.forEach((e: OrderElement) => {
                    order_elements_ids.forEach((id: string) => {
                        if (id === e.ID) {
                            e.is_completed = true;
                        }
                    });
                });

                if (this.isAllDeliveryItems(order)) {
                    order.is_completed = true;
                }
            }
        });

        return changedOrders;
    }


    public showProductDetails(id: string, options: ProductDetailsOptionsInterface): Promise<SellingItemResponseBody> {
        this.productDetailsService.setProductDetailsFrom('analytics');
        return this.productDetailsService.showProductDetails(id, options)
            // tslint:disable-next-line:no-any
            .toPromise().then((res: any) => res, (err: any) => err);
    }

    public openChat(buyerId: string): void {
        this.chatService.goToDialog(buyerId);
    }

    public getDialogList(): void {
        this.chatService.init();
    }

    public stopGetDialogPolling(): void {
        this.chatService.stopPolling();
    }

    public orderTotalPrice(order: Order, discount?: number ): number {
        let totalSum: number = 0;

        order.elements.forEach((transaction: OrderElement) => {
            totalSum += (transaction.price) * transaction.qty;
        });
        return this.totalSum(totalSum, discount);
    }

    public transactionDiscount(orders: Order[]): number {
        let totalSum: number = 0;

        orders.forEach((order: Order) => {
            totalSum += order.discount;
        });

        return totalSum;
    }

    public transactionTotalPrice(orders: Order[], discount?: number): number {
        let totalSum: number = 0;

        orders.forEach((order: Order) => {
            totalSum += this.orderTotalPrice(order, order.discount);
        });

        return this.totalSum(totalSum);
    }

    public totalSum(sum: number, discount?: number): number {
        return sum - (discount || 0);
    }

    public isPickUp(address: PickUpAddress): boolean {
        return !AppValues.isEmpty(address);
    }
    public formatPickUpAddress(address: PickUpAddress, emptyAddress: string = 'None'): string {
        if (this.isPickUp(address)) {
            return AppValues.getStringAddress(address, ', ');
        } else {
            return emptyAddress;
        }
    }

    public set setSelectedOrderID(orderId: string) {
        this.selectedOrderId = orderId;
    }

    public get getSelectedOrderID(): string {
        return this.selectedOrderId;
    }

}
