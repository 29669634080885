import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import AppValues from '../common/app.values';

@Component({
    selector: 'bizipix-card',
    styleUrls: ['bizipix-viewer.sass'],

    template: `
        <div class="layout">
            <div
                class="card"
                (click)="context.callback(context.item_id)"
                data-test-id="bizipixCard"
            >
                <header data-test-id="bizipixCardHeader">
                    {{ 'bizipix.viewer.title.buy' | translate }}
                </header>

                <main>
                    <div
                        class="price"
                        data-test-id="productPricePerUnit"
                        [ngClass]="{ 'text-small': isLongPriceText }"
                    >
                        <b
                            ><span
                                class="price__value"
                                data-test-id="productPrice"
                                >{{
                                    context.item_price | currency : 'USD'
                                }}</span
                            >/{{ context.item_price_units }}</b
                        >
                    </div>

                    <div class="title" data-test-id="productTitle">
                        {{ context.item_title }}
                    </div>
                </main>

                <footer></footer>
                <div class="e-box">
                    <span class="sprite sprite-e_2x"></span>
                </div>
            </div>
        </div>
    `,
})
export class BizipixCardComponent implements OnInit, AfterViewInit {
    /**
     * An ascending description card, appearing on a product hover.
     */

    public card: HTMLElement;
    // tslint:disable-next-line:no-any
    public context: any;
    public isLongPriceText: boolean;

    public constructor(
        private elementRef: ElementRef,
        private translate: TranslateService,
    ) {}

    /**
     * Sets position to the card.
     */
    public ngOnInit(): void {
        const card: HTMLElement = this.elementRef.nativeElement;
        this.card = card;

        card.dataset.item_id = this.context.item_id;

        card.style.bottom = this.context.bottom + 'px';
        card.style.left = this.context.left + 'px';
        card.style.position = 'absolute';

        this.context.item_price = (this.context.item_price / 100).toFixed(2);
        this.checkIsLongPriceText();
    }

    /**
     * Corrects the bottom coordinate on the flight depending on the card's height.
     */
    public ngAfterViewInit(): void {
        const bottom: number = parseFloat(this.card.style.bottom);
        const height: number = parseFloat(window.getComputedStyle(this.card).height);
        this.card.style.bottom = `${bottom + 145 - height}px`;
    }

    public checkIsLongPriceText(): void {
        this.isLongPriceText =
            this.context.item_price.toString().length +
                this.context.item_price_units.toString().length >
            AppValues.LONG_POPUP_TEXT_LENGTH;
    }
}
