var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable, Subject } from 'rxjs';
import { AppRouteValues } from '../common/app.route.values';
import AppValues from '../common/app.values';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
var CartAbstractClass = /** @class */ (function (_super) {
    __extends(CartAbstractClass, _super);
    function CartAbstractClass(elementRef, cartService, googleAnalyticsService, productDetailsService, userService, modalService, errorService, translate) {
        var _this = _super.call(this) || this;
        _this.elementRef = elementRef;
        _this.cartService = cartService;
        _this.googleAnalyticsService = googleAnalyticsService;
        _this.productDetailsService = productDetailsService;
        _this.userService = userService;
        _this.modalService = modalService;
        _this.errorService = errorService;
        _this.translate = translate;
        _this.busyCart = false;
        _this.isPriceAlertOn = false;
        _this.desiredQuantity = {};
        _this.isBuyNow = false;
        _this.buyNowSubject = new Subject();
        _this.subjects$.push(_this.buyNowSubject);
        return _this;
    }
    Object.defineProperty(CartAbstractClass.prototype, "onSelectBuyNow", {
        get: function () {
            return this.buyNowSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CartAbstractClass.prototype.changeCounts = function () {
        var token = this.userService.getUserSession().token;
        if (token) {
            this.cartService.changeCounts(token);
        }
    };
    /**
     * @desc Calls self-titled service method. On response redirects back
     * or goes to the shopping cart (depending from where it has been called:
     * 'Add to cart' or 'Buy now')
     * @param shouldBuyNow
     */
    CartAbstractClass.prototype.addToCart = function (event, item, shouldBuyNow) {
        if (shouldBuyNow === void 0) { shouldBuyNow = false; }
        event.stopPropagation();
        this.addToCartHandler(item, shouldBuyNow);
    };
    /**
     * @desc Toggles inWatchList status.
     */
    CartAbstractClass.prototype.toggleWatchListStatus = function (item) {
        if (item.inWatchList && this.isPriceAlertOn) {
            this.removingItemFromWatchList(item);
        }
        else {
            this.toggleWatchList(item);
        }
    };
    /**
     * @desc Sets isPriceAlertOn flag.
     * @param state
     * @private
     */
    CartAbstractClass.prototype.togglePriceAlert = function (state) { };
    /**
     * @desc If it is the only item in the shopping cart -- buy immediately,
     * otherwise ask user: purchase everything now or redirect to the cart.
     * @public
     */
    CartAbstractClass.prototype._addProductIntoShoppingCart = function (item, items) {
        this.busyCart = true;
        this.resetBusyFlag();
        this.googleAnalyticsService.handleVirtualPage('add_to_cart');
        this.googleAnalyticsService.handleClickButton('purchase_from_buy_now_attempt', 'purchase', 'click');
        this.addItemInit(item, items);
    };
    CartAbstractClass.prototype.addToCartHandler = function (item, shouldBuyNow) {
        var _this = this;
        this.buyNowSubject.next(shouldBuyNow);
        this.showProductDetails(item, shouldBuyNow);
        this.trackSubscription(this.checkSoldOutProduct(item).subscribe(function (isSoldOut) {
            if (isSoldOut) {
                _this.soldOutProductFlow(item);
                return;
            }
            _this.buyStrategy(item, shouldBuyNow);
        }, function (error) { return _this.errorService.handleError(error); }));
    };
    CartAbstractClass.prototype.addItemInit = function (item, items) {
        var _this = this;
        var isPayment = (this.isBuyNow && this.cartService.isEmptyCart);
        this.trackSubscription(this.cartService.addItem({ shoppingCartElements: items, sellerID: item.sellerID, showNotification: !this.isBuyNow, isLocationBack: this.isLocationBack })
            .subscribe(function () { return _this.proceedAddToCart(item, isPayment); }, function (error) { return _this.proceedAddToCartError(item, error); }));
    };
    CartAbstractClass.prototype.changeItemInCartValue = function (item, value) { };
    /**
     * @desc Asks the user: purchase everything now or redirect to the cart.
     * @protected
     */
    CartAbstractClass.prototype.notEmptyCartHandler = function (item, items) {
        var _this = this;
        this.modalService.warning({
            title: this.translate.instant('buyNow.haveProductsInCart.message.title'),
            message: this.translate.instant('buyNow.haveProductsInCart.message.text'),
            noButtonText: this.translate.instant('buyNow.haveProductsInCart.message.cancel'),
            yesButtonText: this.translate.instant('buyNow.haveProductsInCart.message.goToCart'),
            reverseButtons: true,
        }).then(function (action) {
            if (action) {
                _this.googleAnalyticsService.handleAddToCartFromFunnel(item, _this.productDetailsService.getProductDetailsFrom());
                _this._addProductIntoShoppingCart(item, items);
            }
        });
    };
    /**
     * @desc Creates ShoppingCartElementToAdd from item
     * @param {SellingItemResponseBody} item
     * @param {string} deliveryMethod
     * @protected
     * @returns {ShoppingCartElementToAdd[]}
     */
    CartAbstractClass.prototype.prepareObjectItemToShoppingCartBody = function (item, deliveryMethod) {
        var items = [
            {
                item_id: item.ID,
                unit_of_measure_id: item.qtyUnitID,
                quantity: this.desiredQuantity[item.ID] || 1,
            },
        ];
        var deliveryAddress = deliveryMethod === AppValues.US_DELIVERY && this.userService.primaryAddress();
        if (deliveryMethod) {
            items[0].delivery = this.cartService.createDeliveryObj(deliveryMethod, deliveryAddress);
        }
        return items;
    };
    /**
     * @desc Creates ModalArguments object from item
     * @param {SellingItemResponseBody} item
     * @protected
     * @returns {ModalArgumentsInterface}
     */
    CartAbstractClass.prototype.getObjModalDeliveryMethod = function (item) {
        if (item.deliveryOffered && item.marketPickOffered) {
            return {
                title: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.title'),
                message: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.message'),
                yesButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.delivery'),
                noButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.bothDelivery.pickUp'),
                lastButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.cancel'),
            };
        }
        else {
            var modalArguments = {
                title: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.title'),
                yesButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.pay'),
                noButtonText: this.translate.instant('product.buyNow.modalSelectDeliveryMethod.oneDelivery.cancel'),
            };
            if (item.deliveryOffered) {
                modalArguments.message = this.translate.instant('product.buyNow.modalSelectDeliveryMethod.delivery.message');
            }
            if (item.marketPickOffered) {
                modalArguments.message = this.translate.instant('product.buyNow.modalSelectDeliveryMethod.pickUp.message');
            }
            return modalArguments;
        }
    };
    /**
     * @desc Proceeds adding item to cart depending on options
     * @param {boolean} isBuyNow
     * @param {SellingItemResponseBody} item
     * @param {ShoppingCartElementToAdd[]} items
     * @protected
     */
    CartAbstractClass.prototype.addToCartStrategy = function (isBuyNow, item, items) {
        var objModalDeliveryMethod = this.getObjModalDeliveryMethod(item);
        if (!isBuyNow) {
            this.googleAnalyticsService.handleClickButton('add_item_to_cart_item_button_pressed', 'purchase', 'click');
            this.googleAnalyticsService.handleAddToCart(item);
            this.googleAnalyticsService.handleAddToCartFromFunnel(item, this.productDetailsService.getProductDetailsFrom());
            this._addProductIntoShoppingCart(item, this.prepareObjectItemToShoppingCartBody(item));
        }
        else {
            if (!this.cartService.isEmptyCart || this.cartService.isCartWithoutDelivery) {
                this.notEmptyCartHandler(item, items);
            }
            else {
                this.selectDeliveryMethod(item, objModalDeliveryMethod);
            }
        }
    };
    /**
     * @desc Selects delivery method and proceed adding to shopping cart
     * @param {SellingItemResponseBody} item
     * @param {ModalArgumentsInterface} objModalDeliveryMethod
     * @protected
     */
    CartAbstractClass.prototype.selectDeliveryMethod = function (item, objModalDeliveryMethod) {
        var _this = this;
        this.modalService.success(objModalDeliveryMethod).then(function (action) {
            var deliveryMethod;
            if (action === 'last') {
                return;
            }
            if (item.deliveryOffered && item.marketPickOffered) {
                deliveryMethod = action ? AppValues.US_DELIVERY : AppValues.PICK_UP;
            }
            else {
                if (!action) {
                    _this.resetBusyFlag(true);
                    return;
                }
                if (item.deliveryOffered && action) {
                    deliveryMethod = AppValues.US_DELIVERY;
                }
                if (item.marketPickOffered && action) {
                    deliveryMethod = AppValues.PICK_UP;
                }
            }
            _this._addProductIntoShoppingCart(item, _this.prepareObjectItemToShoppingCartBody(item, deliveryMethod));
        });
    };
    /**
     * @desc Toggles item inWathList value
     * @param {SellingItemResponseBody} item
     * @protected
     */
    CartAbstractClass.prototype.toggleWatchList = function (item) {
        var _this = this;
        this.trackSubscription(this.productDetailsService.toggleWatchListStatus(item.ID)
            .subscribe(function () {
            item.inWatchList = !item.inWatchList;
            item.inWatchList && _this.googleAnalyticsService.handleAddToWishlist(_this.item);
            if (_this.isPriceAlertOn) {
                _this.togglePriceAlert(false);
            }
        }, function (err) {
            // The error has already been processed in WatchListService
            // and errorService.handleError(err) and the modal window was shown.
            return err;
        }));
    };
    /**
     * @desc Removes item from watchList
     * @param {SellingItemResponseBody} item
     * @protected
     */
    CartAbstractClass.prototype.removingItemFromWatchList = function (item) {
        var _this = this;
        this.productDetailsService.removingItemFromWatchList().then(function (action) {
            if (action && item.inWatchList) {
                _this.toggleWatchList(item);
            }
        });
    };
    /**
     * @desc Proceeds item add to card with buyNow value
     * @param {boolean} isBuyNow
     * @param {SellingItemResponseBody} item
     * @param {ShoppingCartElementToAdd[]} items
     * @protected
     */
    CartAbstractClass.prototype.addToCartAndBuy = function (isBuyNow, item, items) {
        var _this = this;
        this.trackSubscription(this.cartService.getCart()
            .subscribe(function () { return _this.addToCartStrategy(isBuyNow, item, items); }, function (err) { return Observable.throw(err); }));
    };
    /**
     * @desc Proceeds item add to card with buyNow value
     * @param {SellingItemResponseBody} item
     * @param {boolean} isBuyNow
     * @protected
     */
    CartAbstractClass.prototype.buyStrategy = function (item, isBuyNow) {
        if (isBuyNow) {
            if (this.userService.isGuestMode) {
                this.productDetailsService.setProductOptions({ isInstantBuy: true });
                this.userService.setRedirectUrl(AppRouteValues.routeProductDetails + "/" + item.ID);
                return;
            }
            if (!this.desiredQuantity[item.ID] && item.current_quantity) {
                this.modalDesiredQuantity();
                return;
            }
        }
        this.addToCartAndBuy(isBuyNow, item, this.prepareObjectItemToShoppingCartBody(item));
    };
    CartAbstractClass.prototype.modalDesiredQuantity = function () {
        this.modalService.warning({
            title: this.translate.instant('product.buyNow.modalDesiredQuantity.title'),
            message: this.translate.instant('product.buyNow.modalDesiredQuantity'),
            noButtonText: this.translate.instant('product.buyNow.modalDesiredQuantity.button'),
        });
    };
    CartAbstractClass.prototype.soldOutProductMessage = function () {
        this.modalService.warning({
            title: this.translate.instant('addItemToScPost.error.modal.title'),
            message: this.translate.instant('shoppingCart.error.productIsSoldOut'),
            yesButtonText: this.translate.instant('common.ok'),
        });
    };
    CartAbstractClass.prototype.proposeToAddSoldOutProductIntoWatchList = function (item) {
        var _this = this;
        this.modalService.warning({
            title: this.translate.instant('addItemToScPost.error.modal.title'),
            message: this.translate.instant('shoppingCart.proposeToAddSoldOutProductIntoWatchList.message'),
            yesButtonText: this.translate.instant('shoppingCart.error.productIsSoldOut.yes'),
            noButtonText: this.translate.instant('shoppingCart.error.productIsSoldOut.no'),
            reverseButtons: true,
        }).then(function (action) {
            if (action) {
                _this.toggleWatchListStatus(item);
            }
        });
    };
    CartAbstractClass.prototype.soldOutProductFlow = function (item) {
        if (item.inWatchList || this.userService.isGuestMode) {
            this.soldOutProductMessage();
        }
        else {
            this.proposeToAddSoldOutProductIntoWatchList(item);
        }
    };
    CartAbstractClass.prototype.showProductDetails = function (item, withRedirect) {
        return this.productDetailsService.showProductDetails(item.ID, { desiredQuantity: this.desiredQuantity[item.ID] }, withRedirect);
    };
    CartAbstractClass.prototype.checkSoldOutProduct = function (item) {
        var _this = this;
        return new Observable(function (observer) {
            _this.trackSubscription(_this.productDetailsService.getProduct(item.ID).subscribe(function (product) {
                observer.next(product.current_quantity === 0);
                observer.complete();
            }, function (error) {
                observer.error(error);
                observer.complete();
            }));
        });
    };
    CartAbstractClass.prototype.resetBusyFlag = function (isNotInCart) {
        if (isNotInCart === void 0) { isNotInCart = false; }
    };
    CartAbstractClass.prototype.proceedAddToCart = function (item, isPayment) {
        this.cartService.onConfirmationShoppingCartAndBuy({ isPayment: isPayment, isPayFromCart: false });
        this.changeItemInCartValue(item, true);
        this.changeCounts();
    };
    CartAbstractClass.prototype.proceedAddToCartError = function (item, error) {
        if (error.statusText === this.translate.instant('shoppingCart.error.productIsSoldOut')) {
            this.soldOutProductFlow(item);
        }
        this.resetBusyFlag(true);
    };
    return CartAbstractClass;
}(UnsubscribeOnDestroyAbsctractClass));
export { CartAbstractClass };
