import { Component, OnInit }        from '@angular/core';
import { Subject }                  from 'rxjs';

import { ProductDetailsService }    from '../product-details/product-details.service';
import { ItemService }           from '../services/item.service';
import { WatchListItemInterface }   from './watch-list.interface';
import { WatchListService }         from './watch-list.service';
import { FavWatchListCommands } from '../interfaces/fav-watch-list.inreface';
import {AdapterFavoriteProductsView} from "../favorite-list/adapter-favorite-products-view";
import { FavoriteWatchProductListViewInterface } from '../favorite-list/favorite-list.interface';
import {PriceAlertInterface} from "../interfaces/alert.interface";
import {ErrorInterface, ErrorService} from "../services/error.service";


@Component({
    selector: 'watch-list',
    template: `
        <div class="component">

            <watch-list-header></watch-list-header>

            <div class="component__container watch-list__container">

                <h3 *ngIf="!items?.length" class="empty-list">{{ "watchlist.empty" | translate }}</h3>

                <app-fav-watch-list
                   [items]="items"
                   [type]="'watch'"
                   (showItemInfo$)="showItemInfo($event)"
                   (removeItem$)="removeItem($event)"
                ></app-fav-watch-list>
            </div>
        </div>
    `
})

export class WatchListComponent implements OnInit, FavWatchListCommands {

    private componentDestroyed$:    Subject<boolean> = new Subject();

    items:          FavoriteWatchProductListViewInterface[];
    list:           WatchListItemInterface[];


    constructor(
        private itemService:            ItemService,
        private productDetailsService:  ProductDetailsService,
        private watchService:           WatchListService,
        private errorService:           ErrorService
    ) { }


    ngOnInit() {
        this.watchService.getList()
            .takeUntil(this.componentDestroyed$)
            .subscribe((list: WatchListItemInterface) => {
                this.items = list.items ? new AdapterFavoriteProductsView(list.items).initProduct() : [];

                if (this.items && this.items.length !== 0) {
                    this.items.forEach((item: FavoriteWatchProductListViewInterface) => this._checkItemFn(item));
                }
            });
    }


    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {FavoriteWatchProductListViewInterface} item
     * @return {string}
     */
    trackByID(index: number, item: FavoriteWatchProductListViewInterface): string { return item.ID; }


    /**
     * @desc check if this product is on sale
     * and set price and UnitsName
     * @param {FavoriteWatchProductListViewInterface} item
     * @returns {boolean}
     */
    _checkItemFn(item: FavoriteWatchProductListViewInterface) {
         item.isInOnSale        = this.itemService.isSaleFn(item);
         item.productPrice      = this.itemService.itemPriceFn(item);
         item.productUnitsName  = this.itemService.priceUnitsNameFn(item);
    }


     /**
      * @desc Go to one Product Detail view
      * @param {string} id
     */
    showItemInfo(id: string) {
        return this.productDetailsService.showProductDetails(id)
            .toPromise().then();
    }


    /**
     * @desc remove some item (product) into list
     * @param {string} id
     */
    removeItem(id: string) {
        this.productDetailsService.getProductPriceAlert(id)
            .subscribe((priceAlert: PriceAlertInterface) => {
                if (this.productDetailsService.isPriceAlertOn(priceAlert)) {
                    this.removeProductWithPriceAlert(id);
                } else {
                    this.removeProductAndFilterList(id);
                }
            }, (error: ErrorInterface) => this.errorService.handleError(error));
    }
    removeProductWithPriceAlert(id: string): void {
        this.watchService.removingItemFromWatchList().then((action: boolean) => {
            if (action) {
                this.removeProductAndFilterList(id);
            }
        });
    }
    removeProductAndFilterList(id: string): void {
        this.watchService.removeItem(id)
            .subscribe(() => {
                this.items = this.items.filter(itm => itm['ID'] !== id);
            }, (error: ErrorInterface) => this.errorService.handleError(error));
    }

}
