import {
    Component, OnInit,
    Output, EventEmitter, OnDestroy,
} from '@angular/core';
import { Subject }                  from 'rxjs';


import { BizipixEditorService }     from './bizipix-editor.service';
import { GeoLocationService }       from '../services/geolocation.service';
import { ModalService } from '../modal/modal.service';
import { ItemService } from '../services/item.service';
import AppValues from '../common/app.values';
import { Event } from '../../../swagger-gen__output_dir';
import { SearchResultItems } from '../interfaces/search.interface';

@Component({
    selector:   'item-picker',
    styleUrls:  ['bizipix-editor.sass'],

    template:   `
        <div class="item-picker component">

            <header class="app-header">
                <nav class="app-header__container">
                    <div class="navbar__left" role="itemPicker">
                        <a class="nav__link" data-test-id="cancelLink" (click)="onItemSelect(null)">
                            <span>{{ "bizipix.item.picker.cancel" | translate }}</span>
                        </a>
                    </div>

                    <div class="navbar__center" role="itemPicker">
                        <h4>{{ "bizipix.item.picker.attach" | translate }}</h4>
                    </div>

                    <div class="navbar__right"></div>
                </nav>
            </header>


            <div class="item-picker__container component__container">
                <div *ngIf="list?.length">

                    <picker-block
                            *ngFor="let item of list; trackBy: trackByID; let i = index"
                            [attr.data-test-id]="'block-'+i"
                            [index]="i"
                            [item]="item"
                            (itemSelectEvent$)="onItemSelect($event)">

                    </picker-block>

                </div>
            </div>
        </div>
    `
})


/**
 * The list of the seller inventory.
 * @desc Represents only valid items and their event clones (if valid).
 */
export class ItemPickerComponent implements OnInit, OnDestroy {
    @Output()   itemSelectEvent$    = new EventEmitter();

    list:   SearchResultItems[];
    private componentDestroyed$:    Subject<boolean> = new Subject();
    private location: any;


    constructor(
        private bizipixService:     BizipixEditorService,
        private geoLocationService: GeoLocationService,
        private modalService:       ModalService,
        private itemService:        ItemService
    ) { }


    /**
     * Fetches inventory list and calls filter on response.
     */

    ngOnInit() {
        this.location = this.geoLocationService.location;
        this.getInventoryList();
    }

    private getInventoryList() {
        this.modalService.showSpinner();

        this.bizipixService.getActiveInventoryItems()
            .takeUntil(this.componentDestroyed$)
            .subscribe((items: SearchResultItems[]) => {
                this.filterExpired(AppValues.deepCopy(items));
                this.modalService.close();
            });
    }


    /**
     * Filters out expired and draft items, and expired event items for valid ones.
     * @param items
     * @private
     */
    private filterExpired(items: SearchResultItems[]): void {
        this.list = items;

        this.list.forEach((item: SearchResultItems) => {
            // TODO: Temporary decision -> should be returned after backend changes
            // const events: MarketEvent[] = this.temporaryFilterEvents(item);
            const events: Event[] = this.itemService.isExpiredFn(item)
               ? []
               : item.events;

            item.events = events;

            item.nestedMarkets = events.map(i => i['item']);

            item.nestedMarkets.forEach(market => {
                market.distance = this.geoLocationService.getDistance(
                    this.location.latitude,
                    this.location.longitude,
                    market.loc.coordinates[1],
                    market.loc.coordinates[0],
                );
            });
        });
    }


    /**
     * @emits itemSelectEvent$ when an item has been selected.
     * @param item
     */
    onItemSelect(item: SearchResultItems | null): void {
        this.itemSelectEvent$.emit(item);
    }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {SearchResultItems} item
     * @return {string}
     */
    trackByID(index: number, item: SearchResultItems): string {
        return item.ID;
    }



    /**
     * @desc Removes market's events that is expired or have till date less then todays
     * @param {SearchResultItems} item
     * @returns {Event[]}
     */
    private filterEvents(item: SearchResultItems): Event[] {
       const isNotExpired: boolean = !this.itemService.isExpiredFn(item);

       if (!isNotExpired) { return []; }

       return item.events.filter((event: Event) => {
          const hasTillDate = event['item'].market.market_till_date
              ? this.compareDates(event)
              : true;

          return isNotExpired && hasTillDate;
       });
    }


    /**
     * @desc Compares market's till date with todays
     * @param {Event} event
     * @returns {boolean}
     */
    private compareDates(event: Event): boolean {
       return AppValues.convertStringToTimestamp(
          event['item'].market.market_till_date
       ) > AppValues.getDateNow();
    }

    ngOnDestroy() {
        this.componentDestroyed$.complete();
    }

}
