import {
    Directive,
    ElementRef,
    Output,
    EventEmitter,
    HostListener
} from '@angular/core';
 
@Directive({
    selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
    public constructor(private _elementRef: ElementRef) { }
 
    @Output() public appClickOutside = new EventEmitter();
 
    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        !clickedInside && this.appClickOutside.emit(null);
    }
}
