
        <div class="component__container component__container-map-board">
            <agm-map class="map-board__map"
                (centerChange)="onCenterChange($event)"
                (idle)="mapReady(displayLocation)"
                [styles]="mapStyles"
                (zoomChange)="onZoomChange($event)"
                [latitude]='displayLocation.latitude'
                [longitude]='displayLocation.longitude'
                [zoom]='data.position["zoom"]'
                [scrollwheel]="false">

                <agm-marker *ngFor="let m of data.markets; trackBy: trackByID; let i = index"
                    (markerClick)="clickedMarker(infowindow)"
                    [latitude]="m.market_latitude"
                    [longitude]="m.market_longitude"
                    [iconUrl]="marketPin"
                    [markerDraggable]="false">

                    <agm-info-window class="marker__market" #infowindow>
                        <map-info [data]="m" [timeDatesInfo]="timeDatesInfo" (clickEvent$)="showDetails($event, 'market', m)"></map-info>
                    </agm-info-window>
                </agm-marker>

                <agm-marker *ngFor="let s of data.sellers; trackBy: trackByID; let i = index"
                    (markerClick)="clickedMarker(infowindow)"
                    [latitude]="s.latitude"
                    [longitude]="s.longitude"
                    [iconUrl]="sellerPin"
                    [markerDraggable]="false">

                    <agm-info-window class="marker__seller" #infowindow>
                        <map-info [data]="s" (clickEvent$)="showDetails($event, 'seller', s)"></map-info>
                    </agm-info-window>
                </agm-marker>
            </agm-map>
        </div>
    