import {SellingItemResponseBody} from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {ProductDetailsViewInterface} from '../interfaces/product.interface';

export class AdapterProductView {
    private adaptee: SellingItemResponseBody;

    constructor(adaptee: SellingItemResponseBody) {
        this.adaptee = adaptee;
    }

    public initProduct(): ProductDetailsViewInterface {
        return Object.assign(this.adaptee, {isInWatchList: false, isInFavList: false});
    }
}
