import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule }         from '@angular/common';
import { FormsModule, ReactiveFormsModule }          from '@angular/forms';
import { RouterModule }         from '@angular/router';


import { CarouselModule }           from '../carousel/carousel.module';
// import { SortablejsModule } from 'angular-sortablejs';
import { ShoppingListComponent }    from './shopping-list.component';
import { ShoppingHeaderComponent }  from './shopping-header.component';
import { ShoppingListService }      from './shopping-list.service';
import { SubListComponent }         from './sub-list.component';
import { SwipeModule }              from '../swipe-directive/swipe.module';
import { CustomItemSuggestionsComponent } from './custom-item-suggestions/custom-item-suggestions.component';
import { SharedModule } from '../shared/shared.module';
import {SortablejsModule} from "angular-sortablejs";

@NgModule({
    imports:      [
        CommonModule,
        CarouselModule.forRoot(),
        RouterModule,
        SortablejsModule,
        SwipeModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [
        SubListComponent,
        ShoppingListComponent,
        ShoppingHeaderComponent,
        CustomItemSuggestionsComponent
    ],
    exports:      [
        ShoppingListComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers:    [ ShoppingListService ]
})
export class ShoppingListModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ShoppingListModule,
            providers: [ShoppingListService]
        };
    }
}
