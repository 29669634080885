import { Router } from '@angular/router';
import {AfterBootResolverStrategy, execute} from '../after-boot.strategy';
import {ModalService} from '../../modal/modal.service';
import {UserService} from '../../user/user.service';
import { TranslateService } from '@ngx-translate/core';

export class ExpiredLoginLinkResolver implements AfterBootResolverStrategy {
    constructor (private translate?: TranslateService) {}

   public message: string = this.translate ? this.translate.instant('deepLink.error.expiredLink') : '';

   public resolveWarning(
       service: ModalService,
       userService: UserService,
       router: Router,
       wasUserLoggedIn: boolean
   ): boolean {
       if (wasUserLoggedIn) {
           return wasUserLoggedIn;
       }

       execute(service, this.message);
       router && router.navigate(['/login']);

       return false;
   }
}
