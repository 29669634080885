/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../reviews/filter.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal-filter.component";
var styles_ModalFilterComponent = [i0.styles];
var RenderType_ModalFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalFilterComponent, data: {} });
export { RenderType_ModalFilterComponent as RenderType_ModalFilterComponent };
function View_ModalFilterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-filter__close-btn"], ["data-test-id", "modal-filter__close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "icon-cancel"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.closeButtonPosition(); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ModalFilterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], [[2, "check", null], [1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.onSortChange(_v.context.$implicit.value);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.active; var currVal_1 = _v.context.$implicit.value; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 1, 0, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_3); }); }
function View_ModalFilterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [], [[4, "display", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalFilterComponent_4)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isArrays(_co.context) ? "filter__popup__category many-filters" : "filter__popup__category"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (!_v.context.$implicit.length && "none"); _ck(_v, 0, 0, currVal_0); }); }
function View_ModalFilterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-filter__content"], ["data-test-id", "modal-filter__content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [], [[4, "display", null]], null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalFilterComponent_3)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.positionStartModal(); _ck(_v, 1, 0, currVal_0); var currVal_2 = "modal__dialog"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.styleFilterModal(); _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.context.filter_data; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.context && !_co.context.filter_data.length) && "none"); _ck(_v, 2, 0, currVal_1); }); }
export function View_ModalFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalFilterComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalFilterComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.context && _co.context.idFilterButton); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.context; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ModalFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "modal-dialog", [], null, null, null, View_ModalFilterComponent_0, RenderType_ModalFilterComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalFilterComponent, [], null, null)], null, null); }
var ModalFilterComponentNgFactory = i1.ɵccf("modal-dialog", i3.ModalFilterComponent, View_ModalFilterComponent_Host_0, {}, {}, []);
export { ModalFilterComponentNgFactory as ModalFilterComponentNgFactory };
