
        <section class="product-editor__fields">
            <span class="product-editor__add-event" (click)="onAddClick()" data-test-id="addEventElem">
                <h4>Add Event</h4>
                <i class="icon-plus-circle"></i>
            </span>
        </section>

        <div>
            <section class="product-editor__fields event-widget" *ngFor="let event of events; trackBy: trackByID; let i = index">
                <h3 
                    class="event-widget__title"
                    (click)="onEventClick(event)"
                    [attr.data-test-id]="'eventTitle_'+i"
                >
                    {{event.title}}
                </h3>
                <span class="event-widget__remove">
                    <i
                        class="icon-trash-empty" 
                        (click)="onRemoveClick(i, $event)" 
                        [attr.data-test-id]="'removeEvent_'+i"
                    ></i>
                </span>
            </section>
        </div>
    