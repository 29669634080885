import { Component } from '@angular/core';
import {DynamicAbstractClass} from './dynamic-abstract-class';



@Component({
    selector:   'unsupported-account-component',
    template:   `
        <div [class]="modalContentClass">
            <div [ngClass]="'modal__dialog__unsupported-account'">
                <div *ngIf="context && context.htmlText" [innerHTML]="context.htmlText | sanitizeHtml"></div>
                
                <p *ngIf="context.message" class="modal__msg" data-test-id="modalMessage">{{context.message}}</p>
                
                <div class="modal__btns__row">
                    <button class="modal__btn" data-test-id="unsupportedModalYesButton"
                            *ngIf="context && context.yesButtonText"
                            (click)="onClick(true, context.locationBack)">{{context.yesButtonText}}</button>
                    <button class="modal__btn" data-test-id="unsupportedModalNoButton"
                            *ngIf="context && context.noButtonText"
                            (click)="onClick(false)">{{context.noButtonText}}</button>
                    <button class="modal__btn modal__btn-last" data-test-id="unsupportedModalLastButton"
                            *ngIf="context && context.lastButtonText"
                            (click)="onClick('last')">{{context.lastButtonText}}</button>
                </div>
            </div>
        </div>
    `
})
export class UnsupportedAccountComponent extends DynamicAbstractClass {

    constructor() {
        super();
    }

    onClick(action: boolean | string, locationBack?: boolean) {
        this.context.handler(action);
    }
}
