var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { Observable } from 'rxjs/Observable';
var PushNotificationsService = /** @class */ (function () {
    function PushNotificationsService() {
        this.permission = this.isNotificationsApiSupported() ? 'default' : 'denied';
    }
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
     * Feature-detecting the requestPermission() promise
     * Above we said that we had to check whether the browser supports
     * the promise version of Notification.requestPermission().
     */
    PushNotificationsService.prototype.requestPermission = function () {
        var _this = this;
        if (this.isNotificationsApiSupported()) {
            try {
                Notification.requestPermission()
                    .then(function (status) { return _this.getNotificationPermission(status); });
            }
            catch (error) {
                // Safari doesn't return a promise for requestPermissions and it
                // throws a TypeError. It takes a callback as the first argument
                // instead.
                if (error instanceof TypeError) {
                    Notification.requestPermission(function (status) { return _this.getNotificationPermission(status); });
                }
                else {
                    throw error;
                }
            }
        }
    };
    PushNotificationsService.prototype.getNotificationPermission = function (status) {
        this.registerServiceWorker(status);
        return this.permission = status;
    };
    PushNotificationsService.prototype.create = function (title, options) {
        var _this = this;
        return new Observable(function (obs) {
            if (!(_this.isNotificationsApiSupported())
                || !(_this.isServiceWorkerRegistrationSupported())) {
                obs.complete();
            }
            if (_this.permission !== 'granted') {
                obs.complete();
            }
            _this.showNotification({ obs: obs, title: title, options: options });
        });
    };
    PushNotificationsService.prototype.generateNotification = function (source) {
        var _this = this;
        source.forEach(function (item) {
            var options = {
                body: item.alertContent,
                tag: item.abandonedItemID,
                icon: "../../../assets/images/IconApp29x29@2x.png",
                vibrate: [100, 50, 100],
                data: {
                    url: item.navigateTo
                },
            };
            var notify = _this.create(item.title, options).subscribe();
        });
    };
    PushNotificationsService.prototype.isNotificationsApiSupported = function () {
        return 'Notification' in window;
    };
    PushNotificationsService.prototype.isServiceWorkerSupported = function () {
        return 'serviceWorker' in navigator;
    };
    PushNotificationsService.prototype.isServiceWorkerRegistrationSupported = function () {
        return 'ServiceWorkerRegistration' in window;
    };
    PushNotificationsService.prototype.registerServiceWorker = function (status) {
        if (status === 'granted' && this.isServiceWorkerSupported()) {
            return navigator.serviceWorker.register('/service_worker.js').then(function (reg) {
                return navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
                    var subscribeOptions = {
                        userVisibleOnly: true,
                        applicationServerKey: "BAvUxhcBoYW-_FFTK-FY8AbLfW5SzFHC7vAjs97wbRyhu0DrEDHNrXgEkb5xjifXX7mhwDXJpErZ0OcTZMKAX-k"
                    };
                    return serviceWorkerRegistration.pushManager.subscribe(subscribeOptions);
                }).then(function (subscription) {
                    if (subscription) {
                        navigator.serviceWorker.getRegistrations().then(function (registrations) {
                            var e_1, _a;
                            try {
                                for (var registrations_1 = __values(registrations), registrations_1_1 = registrations_1.next(); !registrations_1_1.done; registrations_1_1 = registrations_1.next()) {
                                    var registration = registrations_1_1.value;
                                    registration.unregister();
                                }
                            }
                            catch (e_1_1) { e_1 = { error: e_1_1 }; }
                            finally {
                                try {
                                    if (registrations_1_1 && !registrations_1_1.done && (_a = registrations_1.return)) _a.call(registrations_1);
                                }
                                finally { if (e_1) throw e_1.error; }
                            }
                        });
                    }
                });
            })
                .catch(function (err) {
                console.log('Notifications are not available in this environment: ' + err);
            });
        }
    };
    PushNotificationsService.prototype.showNotification = function (content) {
        try {
            this.handlerNotificationApi(content);
        }
        catch (err) {
            this.handlerServiceWorkerRegistrationApi(content);
        }
    };
    PushNotificationsService.prototype.handlerNotificationApi = function (content) {
        var _notify = new Notification(content.title, content.options);
        _notify.onshow = function (e) {
            return content.obs.next({ notification: _notify, event: e });
        };
        _notify.onclick = function (e) {
            return content.obs.next({ notification: _notify, event: e });
        };
        _notify.onerror = function (e) {
            return content.obs.error({ notification: _notify, event: e });
        };
        _notify.onclose = function () {
            return content.obs.complete();
        };
    };
    PushNotificationsService.prototype.handlerServiceWorkerRegistrationApi = function (content) {
        try {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistration()
                    .then(function (reg) {
                    console.log('serviceWorker.showNotification');
                    reg.showNotification(content.title, content.options);
                    content.obs.complete();
                })
                    .catch(function (err) {
                    console.log('serviceWorker.getRegistration ERROR: ' + err);
                });
            }
        }
        catch (err) {
            console.log('serviceWorker ERROR: ' + err);
        }
    };
    return PushNotificationsService;
}());
export { PushNotificationsService };
