    
        <div>
            <div *ngIf="context && context.idFilterButton" class="modal-filter__close-btn" data-test-id="modal-filter__close-btn" (click)="hideAll()" [ngStyle]="closeButtonPosition()"><span class="icon-cancel"></span></div>
            
            <div class="modal-filter__content" *ngIf="context" data-test-id="modal-filter__content" (click)="hideAll()" [ngStyle]="positionStartModal()">
                <div [ngClass]="'modal__dialog'" [ngStyle]="styleFilterModal()" [style.display]="context && !context.filter_data.length && 'none'">
                    <ul *ngFor="let filter of context.filter_data; let idx1 = index"
                        [style.display]="!filter.length && 'none'"
                        [ngClass]="isArrays(context) ? 'filter__popup__category many-filters' : 'filter__popup__category'">
                        <li *ngFor="let type of filter; let idx2 = index" [class.check]="type.active"
                            [attr.data-test-id]="type.value" (click)="onSortChange(type.value); $event.stopPropagation()">
                            <p [attr.data-test-id]="type.title">{{type.title}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    