/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-header.component";
import * as i4 from "../services/push.notification.service";
import * as i5 from "../app-footer/app-footer.service";
import * as i6 from "./app-header.service";
var styles_AppHeaderComponent = [];
var RenderType_AppHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppHeaderComponent, data: {} });
export { RenderType_AppHeaderComponent as RenderType_AppHeaderComponent };
function View_AppHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "impersonation-message-block"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("header.hint.impersonating")); var currVal_1 = _co.userNameImpersonation; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_AppHeaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Go to LogIn page"], ["class", "nav__link"], ["data-test-id", "login-link"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo("/login") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(4, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Go to SignUp page"], ["class", "nav__link"], ["data-test-id", "signup-link"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo("/signup") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("header.hint.login")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("header.hint.or")); _ck(_v, 4, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("header.hint.signup")); _ck(_v, 7, 0, currVal_2); }); }
function View_AppHeaderComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "badge"], ["data-test-id", "analyticsCounts"]], [[1, "aria-labelledby", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "relative"], ["data-test-id", "orderItemCounterHeader"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("There are " + ((_co.counts == null) ? null : _co.counts.order_seller_counter)) + " products in your order list"); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.counts == null) ? null : _co.counts.order_seller_counter); _ck(_v, 2, 0, currVal_1); }); }
function View_AppHeaderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["aria-label", "Go to Order page"], ["class", "relative btn__round"], ["data-test-id", "orderItemCounterHeaderButton"], ["id", "orderItemCounterHeader"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo("/orders") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "orders_icon"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppHeaderComponent_5)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isSeller && ((_co.counts == null) ? null : _co.counts.order_seller_counter)); _ck(_v, 3, 0, currVal_0); }, null); }
function View_AppHeaderComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "badge"]], [[1, "aria-labelledby", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "relative"], ["data-test-id", "cartItemCounterHeader"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("There are " + ((_co.counts == null) ? null : _co.counts.sc_counter)) + " products in your shopping cart"); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.counts == null) ? null : _co.counts.sc_counter); _ck(_v, 2, 0, currVal_1); }); }
function View_AppHeaderComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["aria-label", "Go to Shopping Cart page"], ["class", "relative btn__round"], ["data-test-id", "cartItemCounterHeader"], ["id", "cartItemCounterHeader"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo("/cart") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "icon-cart_icon"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppHeaderComponent_7)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.counts == null) ? null : _co.counts.sc_counter); _ck(_v, 3, 0, currVal_0); }, null); }
function View_AppHeaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "user-name-header-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h4", [["aria-label", "Hi!"], ["class", "clip user-name-header-block--name"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", "", "!"])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppHeaderComponent_3)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "figure", [["aria-label", "Go to Root page"], ["class", "static-container__login__container-logo"], ["data-test-id", "goToRootPage"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo(_co.createRootUrl()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "login__logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "img", [["alt", "bizibaza\u00AE"], ["height", "25"], ["src", "assets/images/bizibaza-logo.svg"], ["width", "100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppHeaderComponent_4)), i0.ɵdid(12, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppHeaderComponent_6)), i0.ɵdid(14, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.type === "guest"); _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.order_icon; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.cart_icon; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.userName && (_co.type !== "guest")) ? "way-to-login-static-container real_user" : "way-to-login-static-container"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("header.hint.hi")); var currVal_2 = ((_co.userName && (_co.type !== "guest")) ? (", " + _co.userName) : ""); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_AppHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppHeaderComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppHeaderComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.impersonation; _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.impersonation && _co.type); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AppHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_AppHeaderComponent_0, RenderType_AppHeaderComponent)), i0.ɵdid(1, 245760, null, 0, i3.AppHeaderComponent, [i0.Injector, i4.PushNotificationsService, i5.AppFooterService, i6.AppHeaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppHeaderComponentNgFactory = i0.ɵccf("app-header", i3.AppHeaderComponent, View_AppHeaderComponent_Host_0, { cart_icon: "cart_icon", order_icon: "order_icon", counts: "counts", userName: "userName", type: "type", impersonation: "impersonation", userNameImpersonation: "userNameImpersonation" }, {}, []);
export { AppHeaderComponentNgFactory as AppHeaderComponentNgFactory };
