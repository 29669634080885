/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../product-editor.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./quantity-select.component";
var styles_QuantitySelectComponent = [i0.styles];
var RenderType_QuantitySelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuantitySelectComponent, data: {} });
export { RenderType_QuantitySelectComponent as RenderType_QuantitySelectComponent };
function View_QuantitySelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [["class", "option"]], [[1, "data-id", 0], [1, "data-test-id", 0], [8, "selected", 0], [8, "disabled", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_r, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, [" ", ""]))], function (_ck, _v) { var currVal_4 = _v.context.$implicit.unit_name; _ck(_v, 1, 0, currVal_4); var currVal_5 = _v.context.$implicit.unit_name; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.ID; var currVal_1 = _v.context.$implicit.unit_name; var currVal_2 = (_v.context.$implicit.unit_name === _co.value); var currVal_3 = !_v.context.$implicit.ID; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = _v.context.$implicit.unit_name; _ck(_v, 3, 0, currVal_6); }); }
function View_QuantitySelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["Select", 1]], null, 6, "select", [], [[8, "name", 0], [8, "disabled", 0], [1, "data-test-id", 0], [4, "color", null], [2, "disabled-field", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.updateItem(_co._name, i1.ɵnov(_v, 0).value) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.updateItem(_co._ID, i1.ɵnov(_v, 0).options[i1.ɵnov(_v, 0).selectedIndex].dataset.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "option", [["disabled", ""], ["selected", ""], ["value", ""]], null, null, null, null, null)), i1.ɵdid(2, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(3, 147456, null, 0, i2.ɵangular_packages_forms_forms_r, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuantitySelectComponent_2)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = ""; _ck(_v, 2, 0, currVal_5); var currVal_6 = ""; _ck(_v, 3, 0, currVal_6); var currVal_8 = _co.units; _ck(_v, 6, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._name; var currVal_1 = _co.isDisabled; var currVal_2 = _co._name; var currVal_3 = ((i1.ɵnov(_v, 0).selectedIndex === 0) ? "#ddd" : "inherit"); var currVal_4 = _co.isDisabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_7 = _co.default; _ck(_v, 4, 0, currVal_7); }); }
export function View_QuantitySelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "label", [["class", "input__label input__select-style no-arrow clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuantitySelectComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.units; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_QuantitySelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "quantity-select", [], null, null, null, View_QuantitySelectComponent_0, RenderType_QuantitySelectComponent)), i1.ɵdid(1, 114688, null, 0, i4.QuantitySelectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuantitySelectComponentNgFactory = i1.ɵccf("quantity-select", i4.QuantitySelectComponent, View_QuantitySelectComponent_Host_0, { isDisabled: "isDisabled", name: "name", units: "units", default: "default", value: "value" }, { changeEvent$: "changeEvent$" }, []);
export { QuantitySelectComponentNgFactory as QuantitySelectComponentNgFactory };
