import { EventEmitter, OnChanges, OnInit, SimpleChanges, QueryList } from '@angular/core';
import AppValues from '../../common/app.values';
var TimePickerComponent = /** @class */ (function () {
    function TimePickerComponent() {
        /**
         * A simple timepicker widget based on select elements.
         * @emits changeEvent$ on any field change.
         */
        this.hasTimeSection = true;
        this.changeEvent$ = new EventEmitter();
        this._pad = AppValues._pad;
        this._days = Array.from({ length: 31 }, function (e, i) { return i + 1; }).map(this._pad);
        this._months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        this._hours = Array.from({ length: 24 }, function (e, i) { return i; }).map(this._pad);
        this._minutes = Array.from({ length: 60 }, function (e, i) { return i; }).map(this._pad);
        this.THOUSAND = 1000;
        // Deprecated
        // setBlank() {
        //     this.selects.toArray().forEach((s: HTMLSelectElement) => s.nativeElement.selectedIndex = -1);
        // }
    }
    /**
     * Delegates initial setting.
     */
    TimePickerComponent.prototype.ngOnInit = function () {
        this._setDate();
        this._defineRanges();
    };
    /**
     * Re-initializes the widget if time data changed.
     * @param changes
     */
    TimePickerComponent.prototype.ngOnChanges = function (changes) {
        if (changes.time.previousValue !== changes.time.currentValue) {
            this.ngOnInit();
        }
    };
    /**
     * Retrieves date particles from unix date format.
     * @private
     */
    TimePickerComponent.prototype._setDate = function () {
        var date = new Date(this.time * this.THOUSAND);
        this.year = date.getFullYear();
        this.month = this._months[date.getMonth()];
        this.day = this._pad(date.getDate());
        this.hours = this._pad(date.getHours());
        this.minutes = this._pad(date.getMinutes());
    };
    /**
     * Defines range for the year field: 2 behind, 7 ahead.
     * @private
     */
    TimePickerComponent.prototype._defineRanges = function () {
        var start = this.year - 5;
        this._years = Array.from({ length: 11 }, function (e, i) { return i + start; });
    };
    /**
     * Composes date particles and calls to covert it into unix time format.
     * @param key {string}
     * @param value {string}
     */
    TimePickerComponent.prototype.update = function (key, value) {
        var _a;
        var timeObj = Object.assign({
            day: this.day,
            month: this.month,
            year: this.year,
            hours: this.hours,
            minutes: this.minutes
        }, (_a = {},
            _a[key] = value,
            _a));
        var timeMS = Date.parse(this._getTimeString(timeObj)) / this.THOUSAND;
        this.changeEvent$.emit({
            role: this.role,
            value: timeMS
        });
    };
    /**
     * Composes time object into parser acceptable form.
     * @param t {{}}
     * @returns {string}
     * @private
     */
    TimePickerComponent.prototype._getTimeString = function (t) {
        // YYYY-MM-DDTHH:mm:ss.sssZ
        // Z = +-hh:mm
        var timeZone = new Date().getTimezoneOffset();
        var sign = (timeZone > 0) ? '-' : '+';
        var month = this._pad(this._months.findIndex(function (e) { return e === t['month']; }) + 1);
        var hours = this._pad(Math.floor(Math.abs(timeZone) / 60));
        var minutes = this._pad(Math.abs(timeZone) % 60);
        var timeZoneString = sign + (hours + ":" + minutes);
        return t['year'] + "-" + month + "-" + t['day'] + "T" + t['hours'] + ":" + t['minutes'] + ":00.000";
    };
    return TimePickerComponent;
}());
export { TimePickerComponent };
