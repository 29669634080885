var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalService } from '../../modal/modal.service';
import { AppReviewsService } from '../reviews.service';
import { CreateReviewsAbstractClass } from './create-review-abstract.class';
import { TranslateService } from '@ngx-translate/core';
var CreateReviewBuyerComponent = /** @class */ (function (_super) {
    __extends(CreateReviewBuyerComponent, _super);
    function CreateReviewBuyerComponent(modalService, reviewsService, translate) {
        return _super.call(this, modalService, reviewsService, translate) || this;
    }
    /**
     *
     * @param ID
     * @param buyer
     * @param title
     * @param url
     */
    CreateReviewBuyerComponent.prototype.showAvailableReviews = function () {
        // Deprecated now (21.10.20)
        // if (!ID) return;
        //
        // this.reviewsService.getUserReviewsAndRedirect({buyerObj: {ID: ID, title: title, url: url}, isCreating: false})
        //     .subscribe(null, (error: string) => {
        //         this.modalService.error({title: 'Error:', message: error, yesButtonText: 'Close'});
        //     });
    };
    /**
    * @desc Checks the form validity. If valid, composes address object to
    * pass it to the ReviewService.
    */
    CreateReviewBuyerComponent.prototype.save = function () {
        this.context.callback({ createReviewSeller: null });
    };
    return CreateReviewBuyerComponent;
}(CreateReviewsAbstractClass));
export { CreateReviewBuyerComponent };
