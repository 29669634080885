import { Component, OnInit }                from '@angular/core';

import {
    SubcategoryViewModelInterface, SubcategoryEntryInterface,
    SubcategoryInterface
} from '../interfaces/category.interface';

import { GoodsNavService }                  from './goods-nav.service';
import { ShoppingListService }              from '../shopping-list/shopping-list.service';
import { ShoppingListItemInterface }        from '../shopping-list/shopping-list.interface';
import {ErrorInterface} from '../services/error.service';

// import { slideInOutAnimation }          from '../common/animations/animations';


@Component({
    selector:   'goods-nav',
    styleUrls:  ['goods-nav.sass'],

    // animations: [slideInOutAnimation],
    // host:       { '[@slideInOutAnimation]': '' },

    template:   `
        <div class="component">
            <goods-nav-header *ngIf="categoryName" [categoryName]="categoryName"></goods-nav-header>

            <div class="component__container">
                <ul class="subcat__list">
                    <li class="subcat__title" [attr.data-test-id]="'subcategory_'+idx1"
                        [ngClass]="{
                            'ok':   subcat['_isInShoppingList'],
                            'busy': subcat['_isBusy']
                        }"
                        *ngFor="let subcat of subcats; trackBy: trackByID; let idx1 = index"
                        (click)="toggleVisibility(subcat)">

                        <p><i class="icon-right-dir"
                              [ngClass]="{'active': subcat._isVisible}"></i>

                            {{subcat.sub_category_name}}</p>

                        <ul *ngIf="subcat._isVisible" class="entry__list">

                            <li class="entry__title" [attr.data-test-id]="'entry_'+idx2"
                                [ngClass]="{
                                    'ok':   entry['_isInShoppingList'],
                                    'busy': entry['_isBusy']
                                }"
                                *ngFor="let entry of subcat.sub_category_entries; trackBy: trackByID; let idx2 = index"
                                (click)="toggleStatus(entry, subcats, $event, idx1)">

                                <p>{{entry.subcategory_entry_name}}</p>
                            </li>

                        </ul>

                    </li>
                </ul>
            </div>
        </div>
    `
})
export class GoodsNavComponent implements OnInit {

    public categoryName:   string;
    public subcats:        SubcategoryViewModelInterface[];
    public timeout         = null;

    constructor(
        private goodsNavService:        GoodsNavService,
        private shoppingListService:    ShoppingListService,
    ) { }



    public ngOnInit(): void {
        let { category }    = this.goodsNavService.getCategories();

        this.categoryName   = category.category_name;
        this.subcats = this.goodsNavService.adapter(category.sub_categories);

        this.updateShoppingStatus();
    }

    /**
     * If select item, and this item is in Shopping List -
     * item will be removed from shopping list of current user.
     * If select item, and there is no item in current Shopping List -
     * item will be added to shopping list of current user.
     * flag _isBusy - for display spinner.
     * @param {SubcategoryEntryInterface} entry
     * @param {ShoppingListItemInterface[]} subcats
     * @param {MouseEvent} event
     * @param {number} i
     */
    public toggleStatus(entry: SubcategoryEntryInterface, subcats: ShoppingListItemInterface[], event: MouseEvent, i: number): void {
        event.stopPropagation();
        entry['_isBusy'] = true;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.updateShoppingList(entry, subcats, i);

        }, 500);
    }

    private updateShoppingList(entry: SubcategoryEntryInterface, subcats: ShoppingListItemInterface[], i: number): void {
        let newList: ShoppingListItemInterface[];
        const shoppingList: ShoppingListItemInterface[] = this.shoppingListService.list;
        const newItem: ShoppingListItemInterface = {
            name: entry.subcategory_entry_name,
            subcategory_entry_id: entry.ID,
            is_active: true,
            is_custom: false,
            descriptor: entry.subcategory_entry_name,
            sub_category_name: subcats[i].sub_category_name
        };

        if (entry['_isInShoppingList']) {
            newList = this.shoppingListService.removeItem(newItem, shoppingList);

        } else {
            newList = this.shoppingListService.addItem(newItem, shoppingList);

        }

        this.shoppingListService.updateList(newList).subscribe(
            (res: { elements: ShoppingListItemInterface[] }) => {
                entry['_isBusy'] = false;

                this.shoppingListService.setCachedList(res.elements);
                this.updateShoppingStatus();
            },
            (err: ErrorInterface) => this.goodsNavService.errorHandler(err));
    }


    /**
     * This method check all items in subcategory's list
     * and find items which existence in shopping list of current user
     */
    public updateShoppingStatus(): void {
        const shoppingList      = this.shoppingListService.getCachedList();
        const shoppingListIDs   = [];

        shoppingList.forEach((i: ShoppingListItemInterface) => shoppingListIDs.push(i.subcategory_entry_id));

        this.subcats.forEach((s: SubcategoryViewModelInterface) => {

            s.sub_category_entries.forEach((e: SubcategoryEntryInterface) => {
                const index = shoppingListIDs.findIndex((id: string) => id === e.ID);
                e['_isInShoppingList'] = index > -1;
            });

            const index = s.sub_category_entries.findIndex((e: SubcategoryEntryInterface) =>
                e['_isInShoppingList']
            );

            s['_isInShoppingList'] = index > -1;
        });
    }



    public toggleVisibility(subcat: SubcategoryViewModelInterface): void {
        this.subcats.forEach((sc: SubcategoryViewModelInterface) => {
            if (sc !== subcat) sc._isVisible = false;
        });

        subcat._isVisible = !subcat._isVisible;
    }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {SubcategoryInterface | SubcategoryEntryInterface} item
     * @return {string}
     */
    public trackByID(index: number, item: SubcategoryInterface | SubcategoryEntryInterface): string {
        return item.ID;
    }

}
