import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import { Subject }                  from 'rxjs';

import { ChatService }              from './chat.service';
import { UserService } from '../user/user.service';
import {Dialog, FoundDialog} from '../../../swagger-gen__output_dir';
import { ErrorInterface, ErrorService } from '../services/error.service';

@Component({
    selector:   'dialogs-list',
    styleUrls:  ['chat.sass'],

    // animations: [slideInOutAnimation],
    // host:       { '[@slideInOutAnimation]': '' },

    template:   `
        <div class="component message-component">

            <dialogs-list-header></dialogs-list-header>

            <div class="component__container">
                <section class="search">
                    <label>
                        <input #searchInp class="search-header__input" type="text"
                               data-test-id="searchInp" placeholder="Search"
                               [value]="searchQuery"
                               (keyup)="onSearch($event)">

                    </label>
                    <span
                        class="nav__link text-button"
                        data-test-id="searchCancel"
                        (click)="clearSearch()"
                    >
                        Cancel
                    </span>
                </section>

                <h3 *ngIf="dialogs?.length === 0" class="empty-list">
                    {{ "chatroom.empty" | translate }}
                </h3>

                <div class="dialog-list" *ngIf="dialogs">

                    <dialogs-list-item
                        *ngFor="let dialog of dialogs; trackBy: trackByID; let idx = index"
                        [attr.data-test-id]="'dialog_'+idx"
                        [dialog]="dialog"
                        [id]="idx"
                        (removeEvent$)="removeDialog(dialog.ID)"
                        (showEvent$)="showDialog(dialog)"></dialogs-list-item>
                </div>
            </div>
        </div>
    `
})
export class DialogsListComponent implements OnInit, OnDestroy {
    /**
     * @desc Renders the list of dialogs. Allows show and remove operations.
     */
    public filteredItems:           FoundDialog[];
    public dialogs:                 FoundDialog[];
    public searchQuery              = '';
    public timeout                  = null;

    private componentDestroyed$:    Subject<boolean> = new Subject();

    constructor(
        private chatService:    ChatService,
        private injector:       Injector,
        private errorService:   ErrorService
    ) { }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function.
     * @param {number} index
     * @param {FoundDialog} dialog
     * @return {string}
     */
    public trackByID(index: number, dialog: FoundDialog): string { return dialog.ID; }

    /***
     * @desc This function creates 'user service' property on your service.
     * @return {UserService}
     */
    public get user_service(): UserService {
        return this.injector.get(UserService);
    }

    /**
     * Gets dialogs and subscribes their updates.
     */
    public ngOnInit(): void {
        let guestType = this.user_service.getUser().accessLevel === 0;

        if (guestType) {
            this.chatService.init();
            this.chatService._retrieveDialogs();
        } else {
            this.getNewDialogList();
            this._getDialogs();
            this.dialogs = this.sortAndFilterDialogs(this.chatService.getCurrentDialogs());
            this.chatService.init();
        }

    }

    private _getDialogs(): void {
        this.chatService.getDialogs()
            .takeUntil(this.componentDestroyed$)
            .subscribe((dialogs: FoundDialog[]) => {
                this.dialogs = this.sortAndFilterDialogs(dialogs);
            });
    }

    private getNewDialogList(): void {
        this.chatService.getDialogsRequest();
    }

    /**
     * Gets up-to-date dialogs and calls to filter them.
     * @param event
     */
    public onSearch(event: KeyboardEvent): void {
         if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {

            this.searchQuery = event.target['value'];

            this.chatService.searchByKeyword(this.searchQuery.trim())
               .subscribe(
                   (dialogs: FoundDialog[]) => this.dialogs = this.sortAndFilterDialogs(dialogs),
                   (error: ErrorInterface) => {
                      this.clearSearch();
                      this.errorService.handleError(error);
                   }
               );
        }, 700);
    }

    /**
     * Vanishes search (filtering) results, gets unfiltered dialogs.
     */
    public clearSearch(): void {
        setTimeout(() => {
            this.searchQuery    = '';
            this.chatService.searchChatKeyword = '';
            this.getNewDialogList();
        });
    }

    private sortAndFilterDialogs(dialogs: Array<FoundDialog>): Array<FoundDialog>  {
        if (dialogs && Object.keys(dialogs).length) {
            return this.chatService.sortItems(dialogs).filter((dialog: FoundDialog) => dialog.messages.length !== 0);
        }
        return [];
    }

    /**
     * Delegates to the Service navigation to a certain dialog.
     * @param {FoundDialog} dialog
     */
    public showDialog(dialog: FoundDialog | Dialog): void {
        this.chatService.showDialog(dialog);
    }

    /**
     * Delegates to the service dialog removing.
     * @param id
     */
    public removeDialog(id: string): void {
        this.chatService.removeDialog(id);
    }

    /**
     * Deprecated due to the exists end-point (see searchByKeyword() method in Chat service)
     * Filters dialogs according to the keyword looking through the messages and the titles.
     * @private
     */
    //  _filterDialogs(): void {
    //      if (!this.searchQuery) return;
    //      const query = this.searchQuery.trim().toUpperCase();

    //      this.dialogs = this.dialogs.filter((d: FoundDialog) => {
    //          return this._doesInclude(d.interlocutor_title, query)
    //              || this._doesInclude(d.last_message, query);
    //      });
    //  }

    //  Deprecated due to the exists end-point (see searchByKeyword() method in Chat service)
    //  _doesInclude(val: string, query: string): boolean {
    //      return val !== null && val.toUpperCase().includes(query);
    //  }

    public ngOnDestroy(): void {
        this.componentDestroyed$.complete();
        this.chatService.stopPolling();
    }

}
