import { Component, Input, Output, EventEmitter } from '@angular/core';

import { FavWatchListCommands } from '../../interfaces/fav-watch-list.inreface';
import { FavoriteWatchProductListViewInterface } from '../../favorite-list/favorite-list.interface';

@Component({
  selector: 'app-fav-watch-list',
  templateUrl: './fav-watch-list.component.html'
})
export class FavWatchListComponent implements FavWatchListCommands {
   @Input() public items: FavoriteWatchProductListViewInterface[];
   @Input() public type: string;
   @Output() private showItemInfo$: EventEmitter<string> = new EventEmitter<string>();
   @Output() private removeItem$: EventEmitter<string> = new EventEmitter<string>();

   /**
    * @desc Emits showItemInfo$ event on list item click.
    * @param {string} id
    */
   public showItemInfo(id: string): void {
     this.showItemInfo$.emit(id);
   }

    /**
     * @desc Emits removeItem$ event on 'remove' button click.
     * @param {string} id
     */
   public removeItem(id: string): void {
     this.removeItem$.emit(id);
   }

   /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {SellingItemResponseBody} items
     * @return {string[]}
     */
    trackByID(index: number, items: FavoriteWatchProductListViewInterface): string { return items.ID; }
}
