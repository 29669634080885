import { NgModule, ModuleWithProviders }    from '@angular/core';
import { CommonModule }                     from '@angular/common';

import { BizipixCardComponent }             from './bizipix-card.component';
import { BizipixViewerService }             from './bizipix-viewer.service';
import { BizipixViewerComponent }           from './bizipix-viewer.component';
import { SharedModule }                     from '../shared/shared.module';
import {AngularDraggableModule} from "angular2-draggable";



@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AngularDraggableModule
    ],
    declarations: [
        BizipixCardComponent,
        BizipixViewerComponent,
    ],
    entryComponents: [
        BizipixCardComponent,
    ],
    exports: [BizipixViewerComponent]
})
export class BizipixViewerModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: BizipixViewerModule,
            providers: [BizipixViewerService]
        };
    }
}
