import { TranslateService } from '@ngx-translate/core';
import { CookieService } from '../services/cookie.service';
import { PushNotificationsService } from '../services/push.notification.service';
import { Injector } from '@angular/core';
import AppValues from '../common/app.values';
import { CheckTabStatusService, TabStatus } from '../services/check-tab-status';
import { Observable } from "rxjs/Rx";
var AbandonedProduct = /** @class */ (function () {
    function AbandonedProduct(injector, checkTabStatusService) {
        this.injector = injector;
        this.checkTabStatusService = checkTabStatusService;
    }
    AbandonedProduct.prototype.createrOfAbandonedProductList = function (items, isUpdateNow) {
        var _this = this;
        if (isUpdateNow === void 0) { isUpdateNow = false; }
        return items.map(function (product) { return _this.createrOfAbandonedProductObject({ element: product, isUpdateNow: isUpdateNow }); });
    };
    /**
     * AbandenedProductObject where notification times in minutes
     * @param data
     * @return {NotificationAbandonedProduct}
     */
    AbandonedProduct.prototype.createrOfAbandonedProductObject = function (data) {
        var createTime = data.isUpdateNow ? AppValues.getDateNow() : data.element.addedAt;
        return {
            ID: data.element.ID,
            item: data.element.item,
            createTime: createTime,
            isShowNotification: false,
            notificationTime: [10]
        };
    };
    AbandonedProduct.prototype.updateAbandonedProducts = function (items, isUpdateNow) {
        var _this = this;
        if (isUpdateNow === void 0) { isUpdateNow = false; }
        var abandonedProductList = this.createrOfAbandonedProductList(items, isUpdateNow);
        return abandonedProductList.map(function (notifyInfo) {
            _this.cookieService.setCookie('abandonedItemCreateTime_' + notifyInfo.ID, notifyInfo.createTime);
            _this.cookieService.setCookie('abandonedItemNotificationTime_' + notifyInfo.ID, notifyInfo.notificationTime.join(','));
            _this.cookieService.setCookie('abandonedItemIsShowNotification_' + notifyInfo.ID, notifyInfo.isShowNotification);
            return notifyInfo.ID;
        });
    };
    AbandonedProduct.prototype.showNotificationAbandonedProduct = function (idProduct) {
        this.cookieService.setCookie('abandonedItemIsShowNotification_' + idProduct, 'true');
    };
    AbandonedProduct.prototype.isShowNotificationAbandonedProduct = function (idProduct) {
        var abandonedItemIsShowNotification = this.cookieService.getCookie('abandonedItemIsShowNotification_' + idProduct);
        return abandonedItemIsShowNotification === 'true';
    };
    AbandonedProduct.prototype.prepareParametersForPushNotification = function (windowLocation, abandonedItemID) {
        var PNMessages = [{
                title: this.translate.instant("notification.local.abandonedCart.title"),
                alertContent: this.translate.instant("notification.local.abandonedCart.body"),
                navigateTo: windowLocation.origin + '/cart',
                abandonedItemID: abandonedItemID
            }];
        this.pushNotificationService.generateNotification(PNMessages);
    };
    AbandonedProduct.prototype.createrOfAbandonedItemsIDs = function () {
        var abandonedItemsIDs = new Array;
        var allCookies = this.cookieService.getAllCookies();
        allCookies.forEach(function (c) {
            if (c.includes('abandonedItemNotificationTime_')) {
                var id = c.split('_').pop().split('=')[0];
                abandonedItemsIDs.push(id);
            }
        });
        return abandonedItemsIDs;
    };
    AbandonedProduct.prototype.removeOneAbandonedItem = function (ID, abandonedItemsIDs) {
        this.removeOneAbandonedItemFromStage(ID);
        return abandonedItemsIDs.filter(function (id) { return id !== ID; });
    };
    AbandonedProduct.prototype.removeOneAbandonedItemFromStage = function (ID) {
        this.cookieService.deleteCookie('abandonedItemNotificationTime_' + ID);
        this.cookieService.deleteCookie('abandonedItemCreateTime_' + ID);
        this.cookieService.deleteCookie('abandonedItemIsShowNotification_' + ID);
    };
    AbandonedProduct.prototype.getAllAbandonedItemIDs = function () {
        return this.cookieService.getAllMatchingCookiesName('abandonedItem');
    };
    AbandonedProduct.prototype.removeAllAbandonedItemsFromStage = function () {
        var _this = this;
        this.getAllAbandonedItemIDs().forEach(function (c) {
            _this.removeOneAbandonedItemFromStage(c.split('_')[1]);
        });
    };
    /**
     * @param itemId
     * @param windowLocation
     */
    AbandonedProduct.prototype.checkExpirationOfProduct = function (itemId, windowLocation) {
        var _this = this;
        var timestamp_now = AppValues.getDateNow();
        var abandonedItemNotificationTime = this.cookieService.getCookie('abandonedItemNotificationTime_' + itemId).split(',');
        if (abandonedItemNotificationTime.length) {
            abandonedItemNotificationTime.forEach(function (time) {
                var abandonedItemCreateTime = parseInt(_this.cookieService.getCookie('abandonedItemCreateTime_' + itemId), 0) || 0;
                var abandonedItemTime = new Date((timestamp_now - (abandonedItemCreateTime - 10)) * 1000).getMinutes();
                var isHiddenTab = _this.checkTabStatusService.tabStatus === TabStatus.hidden;
                console.log((abandonedItemTime >= parseInt(time, 0)) + '; isHiddenTab: ' + isHiddenTab + '; isShowNotificationAbandonedProduct(itemId): ' + _this.isShowNotificationAbandonedProduct(itemId));
                if (abandonedItemTime >= parseInt(time, 0) && isHiddenTab && !_this.isShowNotificationAbandonedProduct(itemId)) {
                    _this.showNotificationAbandonedProduct(itemId);
                    _this.prepareParametersForPushNotification(windowLocation, itemId);
                    return Observable.of(true);
                }
                return Observable.of(false);
            });
        }
        return Observable.of(false);
    };
    AbandonedProduct.prototype.changeTabStatus = function (status) {
        this.checkTabStatusService.timerHandler(status);
    };
    Object.defineProperty(AbandonedProduct.prototype, "pushNotificationService", {
        get: function () {
            return this.injector.get(PushNotificationsService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbandonedProduct.prototype, "translate", {
        get: function () {
            return this.injector.get(TranslateService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbandonedProduct.prototype, "cookieService", {
        get: function () {
            return this.injector.get(CookieService);
        },
        enumerable: true,
        configurable: true
    });
    AbandonedProduct.prototype.abandonedItemsTabNotify = function () {
        this.checkTabStatusService.blinkTab(this.translate.instant("notification.local.abandonedCart.blinkingTab.text"));
    };
    return AbandonedProduct;
}());
export { AbandonedProduct };
