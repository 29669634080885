import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    public set(key: string, value: string) {
        return localStorage.setItem(key, value);
    }

    public setPurchaseMemory(token: string) {
        this.set('path', '/cart');
        this.set('user', token);
    }
    public removePurchaseMemory(): void {
        this.removeItem('path');
        this.removeItem('user');
    }

    public get(key: string) {
        return localStorage.getItem(key);
    }

    public clear(): void {
        localStorage.clear();
    }
    public removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    /**
     * Temporary unneeded.
     */
    //  public setObject(name: string, value: {}) {
    //      let keys    = name.split('.');
    //      let raw     = localStorage.getItem( [keys[0]] as any );

    //      let data    = raw ? JSON.parse( raw ) : {};
    //      let d       = data;

    //      if (keys.length === 1) {
    //          localStorage.setItem(name, JSON.stringify(value));
    //          return data;
    //      }

    //      for (let i = 1; i < keys.length - 1; i++) {
    //          let key = keys[i];
    //          if (typeof d[key] === 'undefined') d[key] = {};

    //          d = d[key];
    //      }

    //      d[keys.pop()] = value;

    //      localStorage.setItem(keys[0], JSON.stringify(data));
    //      return data;
    //  }

    //  public getObject(name: string) {
    //      let keys    = name.split('.');
    //      let raw     = localStorage.getItem( [keys[0]] as any );

    //      if (typeof raw !== 'string') return null;
    //      let data    = JSON.parse( raw );

    //      if (keys.length > 1)  for (let i = 1; i < keys.length; i++) {
    //          let key = keys[i];
    //          if (typeof data[key] === 'undefined') return null;

    //          data = data[key];
    //      }

    //      return data;
    //  }

    //  public remove(name: string) {
    //      let data = this.getObject(name);

    //      localStorage.removeItem(name);
    //      return data;
    //  }
}
