import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { ImageUploadService } from '../services/image-upload.service';
import { InventoryListService } from '../inventory-list/inventory-list.service';
import { ModalService } from '../modal/modal.service';
import { UserService } from '../user/user.service';
import { ItemService } from '../services/item.service';
import { TranslateService } from '@ngx-translate/core';
import { getErrorType } from '../services/error.helpers';
// import { stubInventory }            from './stub-inventory-list';
// import { stubBizipix }              from './stub-bizipix';
var BizipixEditorService = /** @class */ (function () {
    function BizipixEditorService(dataService, errorService, imageUploadService, inventoryListService, location, modalService, router, userService, itemService, translate) {
        this.dataService = dataService;
        this.errorService = errorService;
        this.imageUploadService = imageUploadService;
        this.inventoryListService = inventoryListService;
        this.location = location;
        this.modalService = modalService;
        this.router = router;
        this.userService = userService;
        this.itemService = itemService;
        this.translate = translate;
    }
    /**
     * @desc Delegates image uploading to ImageUploadService. Processes spinner and error handling.
     * @param img
     * @return Observable<string>
     */
    BizipixEditorService.prototype.uploadImg = function (img) {
        var _this = this;
        this.modalService.showSpinner();
        return this.imageUploadService
            .uploadImg(img, this.userService.getUserSession().token)
            .do(function () {
            _this.modalService.close();
        })
            .catch(function (err) {
            _this.errorService.handleError(err);
            return Observable.throwError(getErrorType(err));
        });
    };
    BizipixEditorService.prototype.getUserSession = function () {
        return this.userService.getUserSession();
    };
    /**
     * @desc Shows modal warning.
     * @return void
     */
    BizipixEditorService.prototype.showCreationWarning = function () {
        this.modalService.error({
            title: this.translate.instant('bizipix.error.creation.title'),
            message: this.translate.instant('bizipix.error.creation.message'),
            yesButtonText: this.translate.instant('bizipix.error.creation.ok'),
        });
    };
    /**
     * @desc Returns BiziPix data object.
     * @return {BizipixItemsInterface}
     */
    BizipixEditorService.prototype.getImageData = function () {
        return this.imageData;
    };
    BizipixEditorService.prototype.setImageData = function (imageData) {
        this.imageData = imageData;
    };
    Object.defineProperty(BizipixEditorService.prototype, "getClonedBizipix", {
        /**
         * @desc Returns BiziPix data object.
         * @return {BizipixItemsInterface}
         */
        get: function () {
            return this.clonedBizipix;
        },
        enumerable: true,
        configurable: true
    });
    BizipixEditorService.prototype.setClonedBizipix = function (bizipixData) {
        this.clonedBizipix = JSON.parse(JSON.stringify(bizipixData));
    };
    Object.defineProperty(BizipixEditorService.prototype, "availableAmount", {
        get: function () {
            return this.available_amount;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @desc Creates a BiziPix object template and posts it to the server.
     * @param imageURL
     * @return Observable<{}>
     */
    BizipixEditorService.prototype.createBizipix = function (imageURL) {
        var _this = this;
        var token = this.userService.getUserSession().token;
        if (!token) {
            return Observable.of({});
        }
        var pixObj = {
            ID: '',
            image_url: imageURL,
            user_id: this.userService.getUserSession().ID,
            tags: [],
        };
        this.modalService.showSpinner();
        return this.dataService
            .postData('create_pix_image', { bizi_pix: pixObj }, { token: token })
            // tslint:disable-next-line:no-any
            .map(function (res) {
            _this.modalService.close();
            return res['bizi_pix'];
        })
            .catch(function (err) {
            // TODO: remove after backend update and correct error response due ErrorInterface
            // err === {"error": "You can't create more that 5 BiziPixes."} now
            if (err.status === 400) {
                return _this.moreThanFiveBiziPixMessage();
            }
            if (err.status === 400)
                return _this._createBizipixError(getErrorType(err));
            else
                return _this.errorService.handleError(err);
        });
    };
    /**
     * @desc Fetches the BiziPix data object and redirects to the editor on response.
     * @return void
     */
    BizipixEditorService.prototype.editBizipix = function () {
        var _this = this;
        var token = this.userService.getUserSession().token;
        if (!token) {
            return;
        }
        this.modalService.showSpinner();
        this.dataService
            .getData("get_pix_image?user_id=" + this.userService.getUserSession().ID, { token: token })
            .subscribe(function (imageData) {
            _this.imageData = imageData.bizi_pixes;
            _this.setClonedBizipix(imageData.bizi_pixes);
            _this.available_amount = imageData.available_amount;
            _this.modalService.close();
            _this.router.navigate(['/bizipix-editor']);
        }, function (err) { return _this.errorService.handleError(err); });
    };
    /**
     * @desc Shows quit modal warning and exits the editor if confirmed.
     * @return void
     */
    BizipixEditorService.prototype.closeEditor = function (params) {
        var _this = this;
        if (params.changed) {
            this.modalService
                .warning({
                title: this.translate.instant('bizipix.error.close.title'),
                message: this.translate.instant('bizipix.error.close.message'),
                yesButtonText: this.translate.instant('bizipix.error.close.ok'),
                noButtonText: this.translate.instant('bizipix.error.close.no'),
                reverseButtons: true,
            })
                .then(function (action) {
                if (action) {
                    _this.exitEditor(action);
                    if (params.bizipix) {
                        _this.savePix(params.bizipix);
                    }
                }
                else {
                    _this.exitEditor(true);
                }
            });
        }
        else {
            this.exitEditor(true);
        }
    };
    /**
     * @desc Shows a modal confirmation dialog.
     * @return Promise<{}>
     */
    BizipixEditorService.prototype.changeBizipixInEditor = function () {
        return this.modalService
            .warning({
            title: this.translate.instant('bizipix.error.change.title'),
            message: this.translate.instant('bizipix.error.change.message'),
            yesButtonText: this.translate.instant('bizipix.error.change.ok'),
            noButtonText: this.translate.instant('bizipix.error.change.no'),
        });
    };
    BizipixEditorService.prototype.moreThanFiveBiziPixMessage = function () {
        return this.modalService
            .error({
            title: this.translate.instant('bizipix.error.more.title'),
            message: this.translate.instant('bizipix.error.more.message'),
            yesButtonText: this.translate.instant('bizipix.error.more.ok'),
        });
    };
    /**
     * @desc Shows an error modal
     * @param {ErrorInterface} error
     * @return Observable<string>
     * @private
     */
    BizipixEditorService.prototype._createBizipixError = function (error) {
        this.modalService
            .error({
            title: this.translate.instant('bizipix.error.add.title'),
            message: error,
            yesButtonText: this.translate.instant('bizipix.error.add.ok'),
        });
        return of(error);
    };
    /**
     * @desc Exits the editor if the input is truthy.
     * @return void
     * @param action
     */
    BizipixEditorService.prototype.exitEditor = function (action) {
        action && this.location.back();
    };
    /**
     * @desc Shows a modal confirmation dialog.
     * @return Promise<{}>
     */
    BizipixEditorService.prototype.removeTag = function () {
        return this.modalService
            .warning({
            title: this.translate.instant('bizipix.error.remove.tag.title'),
            message: this.translate.instant('bizipix.error.remove.tag.message'),
            yesButtonText: this.translate.instant('bizipix.error.remove.tag.ok'),
            noButtonText: this.translate.instant('bizipix.error.remove.tag.no'),
        });
    };
    /**
     * @desc Shows a modal confirmation dialog on remove, proceeds if approved.
     * @return Observable<void>
     */
    BizipixEditorService.prototype.removeBizipix = function () {
        return this.modalService
            .warning({
            title: this.translate.instant('bizipix.error.delete.title'),
            message: this.translate.instant('bizipix.error.delete.message'),
            yesButtonText: this.translate.instant('bizipix.error.delete.ok'),
            noButtonText: this.translate.instant('bizipix.error.delete.no'),
        });
    };
    /**
     * @desc Posts to the server to remove the BiziPix object. Redirects back on response.
     * @return void
     * @private
     */
    // tslint:disable-next-line:no-any
    BizipixEditorService.prototype.removePix = function (currentBizipix) {
        var _this = this;
        var token = this.userService.getUserSession().token;
        if (!token) {
            return;
        }
        this.modalService.showSpinner();
        return this.dataService
            .postData('remove_pix_image', { pix_id: currentBizipix.ID }, { token: token })
            .map(function () {
            _this.imageData.forEach(function (bixipix) {
                if (bixipix.ID === currentBizipix.ID) {
                    var index = _this.imageData.indexOf(bixipix);
                    _this.imageData.splice(index, 1);
                }
            });
            if (!_this.imageData.length) {
                _this.location.back();
            }
            _this.modalService.close();
        })
            .catch(function (err) { return _this.errorService.handleError(err); });
    };
    /**
     * @desc Posts to the server to update the BiziPix object. Redirects back on response.
     * @param {BizipixItemsInterface} imageData
     * @param {boolean} presave
     * @return void
     */
    BizipixEditorService.prototype.savePix = function (imageData, presave) {
        var _this = this;
        var token = this.userService.getUserSession().token;
        if (!token) {
            return;
        }
        this.modalService.showSpinner();
        this.dataService
            .postData('update_pix_image', { bizi_pix: imageData }, { token: token })
            .subscribe(function () {
            _this._successfully(_this.translate.instant('bizipix.update.success'));
            if (presave === false) {
                _this.location.back();
            }
        }, function (err) { return _this.errorService.handleError(err); });
    };
    /**
     * Shows modal success window.
     * @param {string} text
     * @return {Promise<{}>}
     * @private
     */
    BizipixEditorService.prototype._successfully = function (text) {
        return this.modalService.success({
            title: text,
            yesButtonText: this.translate.instant('bizipix.error.add.ok'),
        });
    };
    /**
     * @desc Returns Observable of the inventory list.
     * @return {Observable<SellingItemResponseBody[]>}
     */
    BizipixEditorService.prototype.getActiveInventoryItems = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.inventoryListService
                .getList()
                .subscribe(function (items) {
                observer.next(items.filter(function (item) {
                    return (!_this.itemService.isDraftFn(item) &&
                        !_this.itemService.isExpiredFn(item));
                }));
            });
        });
    };
    return BizipixEditorService;
}());
export { BizipixEditorService };
