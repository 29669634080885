var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalService } from '../../modal/modal.service';
import { AppReviewsService } from '../reviews.service';
import { ReviewsAbstractClass } from './reviews-abstract.class';
import AppValues from '../../common/app.values';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { AppFooterService } from '../../app-footer/app-footer.service';
var ReviewsListProductsComponent = /** @class */ (function (_super) {
    __extends(ReviewsListProductsComponent, _super);
    function ReviewsListProductsComponent(modalService, footerService, reviewsService, scrollToService) {
        var _this = _super.call(this, modalService, reviewsService, scrollToService, footerService) || this;
        _this.THOUSAND = AppValues.THOUSAND;
        _this.fullDatePattern = AppValues.fullDatePattern;
        return _this;
    }
    return ReviewsListProductsComponent;
}(ReviewsAbstractClass));
export { ReviewsListProductsComponent };
