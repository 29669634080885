/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "../shared/rating/rating.component.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "../shared/rating/rating.component";
import * as i6 from "./favorite-list-header.component.ngfactory";
import * as i7 from "./favorite-list-header.component";
import * as i8 from "../services/redirect.service";
import * as i9 from "../shared/fav-watch-list/fav-watch-list.component.ngfactory";
import * as i10 from "../shared/fav-watch-list/fav-watch-list.component";
import * as i11 from "./favorite-list.component";
import * as i12 from "./favorite-list.service";
import * as i13 from "../product-details/product-details.service";
import * as i14 from "../services/item.service";
import * as i15 from "../shopper-details/shopper-details.service";
var styles_FavoriteListComponent = [];
var RenderType_FavoriteListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FavoriteListComponent, data: {} });
export { RenderType_FavoriteListComponent as RenderType_FavoriteListComponent };
function View_FavoriteListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h3", [["class", "empty-list"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("favorite.list.empty")); _ck(_v, 1, 0, currVal_0); }); }
function View_FavoriteListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "sprite sprite-no_image_icon"]], null, null, null, null, null))], null, null); }
function View_FavoriteListComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.imageURL; var currVal_1 = _v.parent.context.$implicit.title; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FavoriteListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "li", [["class", "fw-list__li text-center"]], [[1, "data-id", 0], [1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUser(_v.context.$implicit.ID) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "fw-list__img-container"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FavoriteListComponent_4)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FavoriteListComponent_5)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "fw-list__product__container text-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h3", [["class", "abstract-list__product__title"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "abstract-list__product-reviews"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "rating", [], [[1, "data-test-id", 0]], [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_RatingComponent_0, i3.RenderType_RatingComponent)), i0.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.RatingComponent]), i0.ɵprd(5120, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i5.RatingComponent]), i0.ɵdid(13, 114688, null, 0, i5.RatingComponent, [], { readonly: [0, "readonly"], float: [1, "float"], rating: [2, "rating"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 1, "span", [["class", "fw-list__item-controls"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.removeUser(_v.context.$implicit.ID);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "i", [["class", "icon-trash-empty"]], null, null, null, null, null))], function (_ck, _v) { var currVal_3 = !_v.context.$implicit.imageURL; _ck(_v, 3, 0, currVal_3); var currVal_4 = _v.context.$implicit.imageURL; _ck(_v, 5, 0, currVal_4); var currVal_8 = true; var currVal_9 = true; var currVal_10 = _v.context.$implicit.rate; _ck(_v, 13, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.ID, ""); var currVal_1 = ("favListUItem_" + _v.context.index); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = ("favListUItemImage_" + _v.context.index); _ck(_v, 1, 0, currVal_2); var currVal_5 = ("favListUName_" + _v.context.index); _ck(_v, 7, 0, currVal_5); var currVal_6 = (_v.context.$implicit.title || ((_v.context.$implicit.firstName + " ") + _v.context.$implicit.lastName)); _ck(_v, 8, 0, currVal_6); var currVal_7 = ("favListURate_" + _v.context.index); _ck(_v, 10, 0, currVal_7); var currVal_11 = ("favListURemoveItem_" + _v.context.index); _ck(_v, 14, 0, currVal_11); }); }
function View_FavoriteListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "favorite-list__ul-users"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FavoriteListComponent_3)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.users; var currVal_1 = _co.trackByID; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_FavoriteListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "component"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "favorite-list-header", [], null, null, null, i6.View_FavoriteListHeaderComponent_0, i6.RenderType_FavoriteListHeaderComponent)), i0.ɵdid(2, 49152, null, 0, i7.FavoriteListHeaderComponent, [i8.RedirectService], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "component__container favorite-list__container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FavoriteListComponent_1)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-fav-watch-list", [], null, [[null, "showItemInfo$"], [null, "removeItem$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showItemInfo$" === en)) {
        var pd_0 = (_co.showItemInfo($event) !== false);
        ad = (pd_0 && ad);
    } if (("removeItem$" === en)) {
        var pd_1 = (_co.removeItem($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_FavWatchListComponent_0, i9.RenderType_FavWatchListComponent)), i0.ɵdid(7, 49152, null, 0, i10.FavWatchListComponent, [], { items: [0, "items"], type: [1, "type"] }, { showItemInfo$: "showItemInfo$", removeItem$: "removeItem$" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FavoriteListComponent_2)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.items == null) ? null : _co.items.length) === 0) && (((_co.users == null) ? null : _co.users.length) === 0)); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.items; var currVal_2 = "fav"; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = _co.users; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_FavoriteListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "favorite-list", [], null, null, null, View_FavoriteListComponent_0, RenderType_FavoriteListComponent)), i0.ɵdid(1, 114688, null, 0, i11.FavoriteListComponent, [i12.FavoriteListService, i13.ProductDetailsService, i14.ItemService, i15.ShopperDetailsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FavoriteListComponentNgFactory = i0.ɵccf("favorite-list", i11.FavoriteListComponent, View_FavoriteListComponent_Host_0, {}, {}, []);
export { FavoriteListComponentNgFactory as FavoriteListComponentNgFactory };
