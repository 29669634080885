/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./switch.component";
var styles_SwitchComponent = [];
var RenderType_SwitchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SwitchComponent, data: {} });
export { RenderType_SwitchComponent as RenderType_SwitchComponent };
export function View_SwitchComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "switch__container text-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleState($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "switch__button left-item"], ["data-test-id", "SwitchShopper"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["buyer"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "button", [["class", "switch__button right-item"], ["data-test-id", "SwitchShopperSeller"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["buyer & seller"]))], null, null); }
export function View_SwitchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "switch", [], null, null, null, View_SwitchComponent_0, RenderType_SwitchComponent)), i0.ɵdid(1, 4243456, null, 0, i1.SwitchComponent, [i0.ElementRef], null, null)], null, null); }
var SwitchComponentNgFactory = i0.ɵccf("switch", i1.SwitchComponent, View_SwitchComponent_Host_0, {}, { type: "type" }, []);
export { SwitchComponentNgFactory as SwitchComponentNgFactory };
