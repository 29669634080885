/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./not-found.component";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "not-found__container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Page not found"]))], null, null); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i1.NotFoundComponent, [], null, null)], null, null); }
var NotFoundComponentNgFactory = i0.ɵccf("ng-component", i1.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
