
        <div id="app-footer-menu" [class]="isAdminAccessLevel ? 'popup-nav-admin' : ''">

            <button class="popup-nav__item" [attr.data-test-id]="'dialogs'" type="button"
                    (click)="onClick($event.target)">
                <div>
                    <h5>Messages</h5>
                    <div class="relative btn__round">
                        <i class="icon-email"></i>
                        <div *ngIf="counts?.ms_counter" class="badge">
                            <span data-test-id="messageCounter" class="relative">
                              {{ counts?.ms_counter }}
                            </span>
                        </div>
                    </div>
                </div>
            </button>
            <button class="popup-nav__item" [attr.data-test-id]="'profile'" type="button"
                    (click)="onClick($event.target)">
                <div>
                    <h5>Profile</h5>
                    <div class="btn__round">
                        <div class="icon-profile_icon"></div>
                    </div>
                </div>
            </button>
            <button class="popup-nav__item" [attr.data-test-id]="'watchlist'" type="button"
                    (click)="onClick($event.target)">
                <div>
                    <h5>
                        Watch List
                        <span
                            *ngIf="counts?.wl_counter"
                            data-test-id="watchListTitleCounter"
                        >
                            ({{ counts.wl_counter }})
                        </span>
                    </h5>
                    <div class="relative btn__round">
                        <div class="icon-watchlist_icon"></div>
                    </div>
                </div>
            </button>
            <button class="popup-nav__item" [attr.data-test-id]="'cart'" type="button" (click)="onClick($event.target)">
                <div>
                    <h5>Cart</h5>
                    <div class="relative btn__round">
                        <div class="icon-cart_icon"></div>
                        <div *ngIf="counts?.sc_counter" class="badge">
                            <span data-test-id="cartItemCounter" class="relative">
                              {{ counts?.sc_counter }}
                            </span>
                        </div>
                    </div>
                </div>
            </button>
            <button class="popup-nav__item" [attr.data-test-id]="'orders'" type="button"
                    (click)="onClick($event.target)">
                <div>
                    <h5>
                        Orders
                        <span
                            *ngIf="mode !== 'seller' && counts?.order_buyer_counter"
                            data-test-id="ordersBuyerTitleCounter"
                        >
                            ({{ counts.order_buyer_counter }})
                        </span>
                    </h5>
                    <div class="relative btn__round">
                        <div class="orders_icon"></div>
                        <div *ngIf="mode === 'seller' && counts?.order_seller_counter" class="badge">
                            <span data-test-id="orderItemCounter" class="relative">
                               {{ counts.order_seller_counter }}
                            </span>
                        </div>
                    </div>
                </div>
            </button>
            <button class="popup-nav__item" [attr.data-test-id]="'favorites'" type="button"
                    (click)="onClick($event.target)">
                <div>
                    <h5>
                        Favorites
                        <span
                            *ngIf="counts?.fl_counter"
                            data-test-id="favoritesTitleCounter"
                        >
                            ({{ counts.fl_counter }})
                        </span>
                    </h5>
                    <div class="relative btn__round">
                        <div class="icon-favorites_filled"></div>
                    </div>
                </div>
            </button>
            <button
                *ngIf="isAdminAccessLevel === true"
                class="popup-nav__item"
                [attr.data-test-id]="'users-list'"
                type="button" (click)="onClick($event.target)"
            >
                <div>
                    <h5>Users</h5>
                    <div class="btn__round">
                        <div class="popup-nav__item__users"></div>
                    </div>
                </div>
            </button>
            <button class="popup-nav__item" [attr.data-test-id]="'reviews'" type="button"
                    (click)="onClick($event.currentTarget)">
                <div>
                    <h5>Reviews</h5>
                    <div class="relative btn__round">
                        <div class="icon-star"></div>
                        <div *ngIf="mode==='seller' ? counts?.rw_s_counter : counts?.rw_b_counter" class="badge">
                            <span data-test-id="reviewsListCounter" class="relative">
                              {{ mode === 'seller' ? counts?.rw_s_counter : counts?.rw_b_counter }}
                            </span>
                        </div>
                    </div>
                </div>
            </button>
        </div>
    