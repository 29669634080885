import {
    ChangeDetectionStrategy,
    Component, Input, Output,
    ElementRef, EventEmitter, OnInit, Injector,
} from '@angular/core';
import { ItemService }                  from '../../services/item.service';
import { ProductDetailsService }        from '../../product-details/product-details.service';
import { SearchListClass }              from './search-list.class';
import { ShoppingListItemInterface }    from '../../shopping-list/shopping-list.interface';
import { ShoppingListService }          from '../../shopping-list/shopping-list.service';
import { GoogleAnalyticsService }       from '../../services/google-analytics.service';
import { Router }                       from '@angular/router';
import { SellingItemResponseBody }      from '../../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import { ErrorService }                 from '../../services/error.service';
import AppValues                        from '../../common/app.values';

@Component({
    selector:           'item-search-sublist',
    changeDetection:    ChangeDetectionStrategy.OnPush,
    styleUrls:          ['../../../assets/styles/sprite.sass'],
    templateUrl:        './item-search-list.html'
})

export class ItemSearchSublistComponent extends SearchListClass implements OnInit {

    @Input() shoppingList: ShoppingListItemInterface[];
    @Input() title: string;
    @Input() search_from = 'shopping_list';
    @Output() itemSelectEvent$      = new EventEmitter();
    @Output() addItemToSL$          = new EventEmitter<SellingItemResponseBody>();

    private desiredQuantity = 0;

    isSale:        boolean;
    isPrice:       number;
    isUnitsName:   string;



    constructor(
        public elementRef:              ElementRef,
        private googleAnalyticsService: GoogleAnalyticsService,
        private productDetailsService:  ProductDetailsService,
        private itemService:            ItemService,
        private shoppingListService:    ShoppingListService,
        private injector:               Injector,
    ) { super(elementRef); }


    ngOnInit() {
        this.shoppingList = this.shoppingListService.getCachedList();
    }

    /**
     * @desc check if this product is on sale
     * @param {SellingItemResponseBody} item
     * @returns {boolean}
     */
    isSaleFn(item: SellingItemResponseBody) {
         return this.isSale = this.itemService.isSaleFn(item);
    }


    /**
     * @desc price for items
     * @param {SellingItemResponseBody} item
     * @returns {number}
     */
    itemPriceFn(item: SellingItemResponseBody) {
        return this.isPrice = this.itemService.itemPriceFn(item);
    }


    /**
     * @desc UnitsName for items
     * @param {SellingItemResponseBody} item
     * @returns {string}
     */
    priceUnitsNameFn(item: SellingItemResponseBody) {
        return this.isUnitsName = this.itemService.priceUnitsNameFn(item);
    }

    /**
     * @desc check if this product is in the basket (Shopping List)
     * @param {string} subcategoryEntryID
     * @returns {boolean}
     */
    isInSL(subcategoryEntryID: string): boolean {
        if (!this.shoppingList) return false;
        return !!~this.shoppingList.findIndex((_item: ShoppingListItemInterface) => _item.subcategory_entry_id === subcategoryEntryID);
    }

    /**
     * @desc add one product to Shopping List
     * @param {Event} event
     * @param {SellingItemResponseBody} item
     */
    addToShoppingListEvent(item: SellingItemResponseBody): void {
        this.addItemToSL$.emit(item);
    }


    /**
     * @desc if you want view one product
     * @param {SellingItemResponseBody} item
     */
    onClick(item: SellingItemResponseBody): Promise<any> {
        this.productDetailsService.setProductDetailsFrom(this.search_from);
        let promise = this.productDetailsService.showProductDetails(item['ID'], {desiredQuantity: this.desiredQuantity, item}, false).toPromise().then((res: any) => res, (err) => err);
        return promise;
    }

    public handleShoppingListEvent(event: Event, item: SellingItemResponseBody): void {
        event.stopPropagation();
        if (!this.isInSL(item.subcategoryEntryID)) {
            this.addToShoppingListEvent(item);
        }
    }

    /***
     * @desc This function creates router property on your service.
     * @return {Router}
     */
    public get router(): Router {
        return this.injector.get(Router);
    }

    /***
     * @desc This function creates router property on your service.
     * @return {Router}
     */
    public get errorService(): ErrorService {
        return this.injector.get(ErrorService);
    }

    /**
     * @desc handle quantity choice
     * @param {HTMLInputElement} input
     * @param {number} qty
     */
    setQuantity(input: HTMLInputElement, qty: number) {
        setTimeout(() => {
            const formatedNumber = AppValues.NumberUSFormat({
                value: input.value,
                qty: qty,
                maxNumber: AppValues.PRODUCT_QTY_MAX,
                defaultValue: '',
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
            });

            input.value = formatedNumber.formatedNumber;
            this.desiredQuantity = formatedNumber.num;
        });
    }

}
