import {
    Component, EventEmitter,
    Input, OnInit, Output
} from '@angular/core';

import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {ItemService} from '../services/item.service';


@Component({
    selector:   'picker-cell',
    styleUrls:  ['bizipix-editor.sass'],

    template:   `
        <div class="clearfix picker__cell" [attr.data-test-id]="'pickerCell_' + index" (click)="onClick()">

            <div class="left picker__img">
                <div *ngIf="!data['photoURL']" class="sprite sprite-no_image_icon"></div>
                <img *ngIf="data['photoURL']" src="{{data['photoURL']}}">
            </div>

            <section class="picker__info">
                <h3 class="title" data-test-id="pickerCellTitle">
                    {{data.title}}
                </h3>

                <h4 [attr.data-test-id]="'productPrice_' + index">{{productPrice / HUNDRED | currency:'USD'}}&nbsp;&nbsp;
                    <span *ngIf="title">({{title}})</span>
                </h4>

            </section>
        </div>
    `
})


/**
 * Represent an inventory item.
 */
export class PickerCellComponent implements OnInit{
    @Input()    data:       SellingItemResponseBody;
    @Input()    title:      string;
    @Input()    index:      number;
    @Output()   clickEvent$ = new EventEmitter();

    public productPrice: number;
    public HUNDRED = 100;

    constructor ( private itemService: ItemService ) { }

    public ngOnInit() {
        this.productPrice = this.itemService.itemPriceFn(this.data);
    }

    /**
     * @emits clickEvent$ on click.
     */
    public onClick() {
        this.clickEvent$.emit(this.data);
    }

}
