import {ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Subject} from 'rxjs';

import {
    ModalFilterArgumentInterface, ModalFilterArguments,
    OrderForReviewListInterface,
    ReviewListInterface,
    ReviewOptionsInterface,
    ReviewProductInterface
} from './review.interface';
import {AppReviewsService} from './reviews.service';
import {Observable} from 'rxjs/Rx';
import {ErrorInterface} from '../services/error.service';
import {UserService} from '../user/user.service';
import {Router} from '@angular/router';
import {ModalService} from '../modal/modal.service';
import {Location} from '@angular/common';
import {ReviewsUsersComponent} from './review-list/reviews-users';
import {ReviewsProductsComponent} from './review-list/reviews-products';
import {ReviewsAbstractClass} from './review-list/reviews-abstract.class';
import {ReviewsPurchasesComponent} from './review-list/reviews-purchases';
import {Review} from '../../../swagger-gen__output_dir/model/review';
import {ReviewsResponseBody} from '../../../swagger-gen__output_dir/model/reviewsResponseBody';
import {AppFooterService} from '../app-footer/app-footer.service';
import {UserInfo} from '../../../swagger-gen__output_dir/model/userInfo';
import {GetUserReviewsResponseBody, OrderElement} from '../../../swagger-gen__output_dir';
import {OrderForReviews} from '../../../swagger-gen__output_dir/model/orderForReviews';
import {ReviewsListUsersComponent} from './review-list/reviews-list-users';
import {ReviewsListProductsComponent} from './review-list/reviews-list-products';
import {AccessToSendReviewResponse} from '../../../swagger-gen__output_dir/model/accessToSendReviewResponse';
import {AdapterReview} from './adapter-review';
import AppValues from "../common/app.values";
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'reviews',
    styleUrls:  ['reviews.sass'],
    template: `
        <div class="component reviews-component">
            <reviews-header (changeEvent$)="onOptionsChange($event)"
                            (goToCreateReview$)="null"
                            [isAvailableFilter]="isAvailableFilter"
                            [isAvailableTabs]="isAvailableTabs"
                            [userTitle]="userTitle"
                            (backToHomeEvent$)="backToHome()"
                            [reviewsAllFilters]="reviewsAllFilters"
                            [reviewsAllFiltersSubject]="reviewsAllFiltersSubject"></reviews-header>
            <div [class]="isAvailableFooter && 'review-container'" [ngStyle]="heightContainer()">
                <div #container></div>
            </div>
        </div>
    `
})

export class ReviewsComponent implements OnInit {
    @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

    private mappings                = {
        [this.listFilter[0].value]:    {
            instance: ReviewsUsersComponent,
            field: 'reviews_left_on_user'
        },
        [this.listFilter[1].value]:
        {
            instance: ReviewsProductsComponent,
            field: 'reviews_left_by_user'
        },
        [this.listFilter[2].value]:    {
            instance: ReviewsPurchasesComponent,
            field: 'orders_to_review'
        },
        [this.listFilterProduct[0].value]: {
            instance: ReviewsListProductsComponent,
            field: 'product_review'
        },
        [this.listFilterSeller[0].value]: {
            instance: ReviewsListUsersComponent,
            field: 'seller_reviews'
        },
        [this.listFilterSeller[1].value]: {
            instance: ReviewsListUsersComponent,
            field: 'buyer_reviews'
        },
    };
    private componentDestroyed$:    Subject<boolean> = new Subject();
    private componentRef:           ComponentRef<{}>;
    private itemUrl:                string[];
    private filteredItems:          Array<any>;
    private location:               Location;
    private locationReviews:        string;
    private selectedListFilter:     string = this.listFilter[0].value;
    private selectedPurchaseFilter: string = this.purchaseFilter[0].value;
    private selectedRatingFilter:   string = this.ratingFilter[0].value;
    private reviewItems:            ReviewsResponseBody | ReviewProductInterface | GetUserReviewsResponseBody;
    private _w:                     Window;

    public reviewsAllFilters:      Array<Array<ModalFilterArgumentInterface>>;
    public isAvailableFilter:      boolean = true;
    public isAvailableFooter:      boolean = true;
    public isAvailableTabs:        boolean = true;
    public reviewsAllFiltersSubject: Subject<Array<Array<ModalFilterArgumentInterface>>> = new Subject<Array<Array<ModalFilterArgumentInterface>>>();
    public userTitle:              string;

    constructor(
        private componentFactoryResolver:   ComponentFactoryResolver,
        private userService:        UserService,
        private reviewsService:     AppReviewsService,
        private footerService:      AppFooterService,
        private modalService:       ModalService,
        private router:             Router,
        private translate:          TranslateService,
        private changeDetector:     ChangeDetectorRef,
        location:                   Location,
    ) {
        this.location = location;
        this._w = window;
    }

    public ngOnInit(): void {
        this.itemUrl = new ItemReviewUrl().getItemUrl();
        this.userTitle = this.userService.getUser().title;

        this.locationReviews = decodeURIComponent(this._w.location.pathname);

        if (this.locationReviews === '/reviews-item') {
            this.setReviewListParamsAndRender();

        } else if (this.locationReviews === '/reviews') {
            this.reviewsAllFilters = this.filters;
            this.reviewsAllFiltersSubject.next(this.reviewsAllFilters);
            this.isAvailableFooter = true;

            this.checkIsAvailableFilter();
            this.setSelectedListFilter(this.listFilter);

            if (this.footerService.isSellerMode()) {
                 this.getSellerReviews();
            } else {
                this.getBuyerReviews();
            }
        }
    }

    private setSelectedListFilter(listFilter: ModalFilterArgumentInterface[]): void {
        listFilter.forEach((filter: ModalFilterArgumentInterface) => {
            if (filter.active) {
                this.selectedListFilter = filter.value;
            }
        });
    }

    private setReviewListParamsAndRender(): void {
        if (this.itemUrl[0] === 'sel') {
            this.reviewsAllFilters = this.filtersSeller;
            this.reviewItems = this.reviewsService.getUserReviews;
            this.selectedListFilter = this.listFilterSeller[0].value;

        } else if (this.itemUrl[0] === 'buy') {
            this.reviewsAllFilters = this.filtersBuyer;
            this.reviewItems = this.reviewsService.getUserReviews;
            this.selectedListFilter = this.listFilterBuyer[1].value;
        } else {
            this.reviewsAllFilters = this.filtersProduct;
            this.reviewItems = this.reviewsService.getProductReviews;
            this.selectedListFilter = this.listFilterProduct[0].value;
            this.isAvailableTabs = false;
        }

        this.reviewsAllFiltersSubject.next(this.reviewsAllFilters);
        this.isAvailableFooter = false;
        this.renderEditor();
    }

    private getSellerReviews(): void {
        this.modalService.showSpinner();

        this.reviewsService.getSellerAllReview()
            .subscribe((reviews: ReviewsResponseBody) => {
                this.setReviews(reviews);

                this.modalService.close();
            }, (err: ErrorInterface) => Observable.throwError(err));
    }
    private getBuyerReviews(): void {
        this.modalService.showSpinner();

        this.reviewsService.getBuyerAllReview()
            .subscribe((reviews: ReviewsResponseBody) => {
                this.setReviews(reviews);

                this.modalService.close();
            }, (err: ErrorInterface) => Observable.throwError(err));
    }

    private setReviews(reviews: ReviewsResponseBody | ReviewProductInterface | GetUserReviewsResponseBody): void {
        this.reviewItems = reviews;
        this.filteredItems = this.reviewItems[this.mappings[this.selectedListFilter].field];
        this.renderEditor();
        this.markReceivedeviews();
    }

    /**
     * Creates the Review List component.
     * @desc Starts from clearing the previous component, chooses a proper class according to the
     * desired type, renders chosen class and inject the context into it.
     * @private
     */
     public renderEditor(params?: {selected_order_id?: string}): void {
        this.container.remove();

        let component       = this.mappings[this.selectedListFilter].instance;

        let factory         = this.componentFactoryResolver.resolveComponentFactory(component);
        this.componentRef   = this.container.createComponent(factory);

        let instance        = <ReviewsAbstractClass> this.componentRef.instance;

        if (this.selectedListFilter === ModalFilterArguments.purchases) {
            this._filterResult(this.selectedPurchaseFilter);
        } else {
            this._filterResult(this.selectedRatingFilter);
        }
        this.filteredItems = this.sortItems(this.filteredItems);

        let selected_order_id: string;
        if (params && params.selected_order_id) {
            selected_order_id = params.selected_order_id;
        }

        instance.context    = Object.assign(
                 {  callback:           this._executeCommand.bind(this)},
               {  filteredItems:      this.filteredItems},
               {  selected_order_id }
            );
    }

    private isAvailableHeaderFilter(): boolean {
        let isAvailable: boolean;

        this.reviewsAllFilters[0].forEach((f: ModalFilterArgumentInterface) => {

            if (this.selectedListFilter === 'purchases' && this.selectedListFilter === f.value) {
                isAvailable = f.active;
            }
        });

        return !isAvailable;
    }

    /**
     * Dispatches the Product editor commands.
     * @param commandObject
     * @private
     */
    private _executeCommand(commandObject: {}): void {
        this[Object.keys(commandObject)[0]](Object.values(commandObject)[0]);
    }

    /**
     * UIR_REVIEW_15: When the user clicks a buyer name in “Purchases” mode,
     * the system shall open the “Received as Buyer” tab of the user Reviews page.
     */
    public showUserReviews(params: {element: OrderElement, user_info: UserInfo, user_id: string, is_buyer: boolean}): void {

        this.reviewsService.getUserReviewsAndRedirect({
                element: params.element,
                buyer_info: params.user_info,
                buyer_id: params.user_id,
                isCreating: false,
                is_buyer: params.is_buyer
            }).takeUntil(this.componentDestroyed$)
                .subscribe(() => {
                    this.modalService.close();
                }, (err: ErrorInterface) => null);
    }

    /**
     * passage to the Request Creation page, a query that checks the ability to leave a comment (this.access.flag),
     * if product was not purchased then show warning
     */
    public addReview(params: {element: OrderElement, buyer_info: UserInfo, buyer_id: string}): void {
        this.modalService.showSpinner();

        if (this.footerService.isSellerMode()) {
            this.prepareToAddReview(this.reviewsService.checkAccessToSendReview(params.buyer_id), params);

        } else {
            this.prepareToAddReview(this.reviewsService.checkAccessToSendReview(
                params.buyer_id, params.element.item_info.current_item_id), params);
        }
    }

    public backToHome(): void {
        this.reviewsService.backToHome();
    }

    private prepareToAddReview(createReviewMethod: Observable<AccessToSendReviewResponse>,
                       params: {element: OrderElement, buyer_info: UserInfo, buyer_id: string}): void {
        createReviewMethod.subscribe((access: AccessToSendReviewResponse) => {

             if (!access.flag) {
                 return Observable.throwError(
                     this.modalService.warning(
                         {title: this.translate.instant('review.alert.title'), message: access.description, yesButtonText: this.translate.instant('review.alert.confirm')}));
             } else {
                 this.getReviewsAndRedirectToCreateReview(params);
             }

        }, null);
    }

    private getReviewsAndRedirectToCreateReview(params: {element: OrderElement, buyer_info: UserInfo, buyer_id: string}): void {

        if (this.footerService.isSellerMode()) {

            this.reviewsService.getUserReviewsAndRedirect({
                element: params.element,
                buyer_info: params.buyer_info,
                buyer_id: params.buyer_id,
                isCreating: true
            }).takeUntil(this.componentDestroyed$)
                .subscribe(() => {
                    this.modalService.close();
                }, (err: ErrorInterface) => null);

        } else {
            this.reviewsService.getItemReviewAndRedirect({
                element: params.element,
                buyer_info: params.buyer_info,
                isCreating: true
            }).takeUntil(this.componentDestroyed$)
                .subscribe(() => {
                    this.modalService.close();
                }, (err: ErrorInterface) => null);
        }
    }

    public showReviewedItem(element_id: string): void {
        this.changeFilterArguments(this.listFilter[1].value);
        this._filterResult(this.ratingFilter[0].value);
        this.checkIsAvailableFilter();
        this.renderEditor({selected_order_id: element_id});
    }

    private checkIsAvailableFilter(): void {
        this.isAvailableFilter = this.isAvailableHeaderFilter();
        this.changeDetector.detectChanges();
    }

    private markReceivedeviews(): void {
         if (this.selectedListFilter === this.listFilter[0].value) {
            this.reviewsService.markReviewsAsRead(this.reviewItems[this.mappings[this.selectedListFilter].field]);
        }

    }

    private get filters(): Array<Array<ModalFilterArgumentInterface>> {
        let filters = new Array;

        filters.push(this.listFilter);
        filters.push(this.ratingFilter);

        return filters;
    }

    private get filtersSeller(): Array<Array<ModalFilterArgumentInterface>> {
        let filters = new Array;

        filters.push(this.listFilterSeller);
        filters.push(this.ratingFilter);

        return filters;
    }
    private get filtersBuyer(): Array<Array<ModalFilterArgumentInterface>> {
        let filters = new Array;

        filters.push(this.listFilterBuyer);
        filters.push(this.ratingFilter);

        return filters;
    }
    private get filtersProduct(): Array<Array<ModalFilterArgumentInterface>> {
        let filters = new Array;

        filters.push(this.listFilterProduct);
        filters.push(this.ratingFilter);

        return filters;
    }

    private get ratingFilter(): Array<ModalFilterArgumentInterface> {
        return [
            {value: ModalFilterArguments.all, title: this.translate.instant('All'), active: true},
            {value: ModalFilterArguments.positive, title: this.translate.instant('Positive'), active: false},
            {value: ModalFilterArguments.neutral, title: this.translate.instant('Neutral'), active: false},
            {value: ModalFilterArguments.negative, title: this.translate.instant('Negative'), active: false}
        ];
    }
    private get purchaseFilter(): Array<ModalFilterArgumentInterface> {
        return [
            {value: ModalFilterArguments.all, title: this.translate.instant('All'), active: true},
            {value: ModalFilterArguments.addReview, title: this.translate.instant('Add Review'), active: false},
            {value: ModalFilterArguments.reviewed, title: this.translate.instant('Reviewed'), active: false},
        ];
    }

    private get listFilter(): Array<ModalFilterArgumentInterface> {
        return this.reviewsService.listFilterTabs;
    }

    private get listFilterSeller(): Array<ModalFilterArgumentInterface> {
        return [
            {value: ModalFilterArguments.sellerReviews, title: this.translate.instant('review.filter.seller.recieved.seller'), active: true},
            {value: ModalFilterArguments.buyerReviews, title: this.translate.instant('review.filter.seller.recieved.buyer'), active: false},
        ];
    }

    private get listFilterBuyer(): Array<ModalFilterArgumentInterface> {
        return [
            {value: ModalFilterArguments.sellerReviews, title: this.translate.instant('review.filter.buyer.recieved.seller'), active: false},
            {value: ModalFilterArguments.buyerReviews, title: this.translate.instant('review.filter.buyer.recieved.buyer'), active: true},
        ];
    }
    private get listFilterProduct(): Array<ModalFilterArgumentInterface> {
        return [
            {value: ModalFilterArguments.product, title: '', active: true},
        ];
    }

    public heightContainer(): { "max-height": string; "margin-bottom": 0; height: string } {
        let h: number = 60;

        if (this.isAvailableFooter) {
            h += 50;
        }
        if (this.isAvailableTabs) {
            h += 60;
        }

        return {
            'height': 'calc(100% - ' + h + 'px)',
            'max-height': 'calc(100% - ' + h + 'px)',
            'margin-bottom': 0
        };
    }

    /**
     * Method for revers sorted by date
     * @param reviews
     */
    private sortItems(reviews: Array<any>): Array<OrderForReviewListInterface> | Array<ReviewListInterface> | Array<any> {
        if (reviews && reviews.length) {
            if ("create_date" in reviews[0]) {
                return AdapterReview.convertReviewList(this.sortReviewsByDate(reviews));

            }
            if ("order_datetime" in reviews[0]) {
                return AdapterReview.convertOrderForReviewList(this.sortOrderReviewsByDate(reviews));

            } else return reviews;
        } else return reviews;
    }

    private sortOrderReviewsByDate(reviews: OrderForReviews[]): OrderForReviews[] {
        return reviews.sort((orderA: OrderForReviews, orderB: OrderForReviews) => {
            const orderDateTimeA = (Number(new Date(orderA.order_datetime)).toString());
            const orderDateTimeB = (Number(new Date(orderB.order_datetime)).toString());

            if (orderDateTimeB < orderDateTimeA) {
                return -1;
            }
            if (orderDateTimeB > orderDateTimeA) {
                return 1;
            }
            // if must be equal
            return 0;
        });
    }
    private sortReviewsByDate(reviews: Review[]): Review[] {
        return reviews.sort((orderA: Review, orderB: Review) => orderB.create_date.toString().localeCompare(orderA.create_date.toString()));
    }


    private resetFilterByValue(filters: Array<ModalFilterArgumentInterface>, sortByValue: string): Array<ModalFilterArgumentInterface> {
        filters.forEach((filter: ModalFilterArgumentInterface) => {
            if (filter.value === sortByValue) {
                filters.forEach((f: ModalFilterArgumentInterface) => {
                    f.active = false;
                });
            }
        });

        return filters;
    }

    private setActiveFilterByValue(filters: Array<ModalFilterArgumentInterface>, sortByValue: string): Array<ModalFilterArgumentInterface> {
        filters.forEach((filter: ModalFilterArgumentInterface) => {
            if (filter.value === sortByValue) {
                filter.active = true;
            }
            this.ratingFilter.forEach((rating: ModalFilterArgumentInterface) => {
                if (rating.value === sortByValue) {
                    this.selectedRatingFilter = rating.value;
                }
            });
            this.listFilter.forEach((rating: ModalFilterArgumentInterface) => {
                if (rating.value === sortByValue) {
                    this.selectedListFilter = rating.value;
                }
            });
            this.purchaseFilter.forEach((rating: ModalFilterArgumentInterface) => {
                 if (rating.value === sortByValue) {
                     this.selectedPurchaseFilter = rating.value;
                 }
            });
            this.listFilterSeller.forEach((rating: ModalFilterArgumentInterface) => {
                if (rating.value === sortByValue) {
                    this.selectedListFilter = rating.value;
                }
            });
        });

        return filters;
    }

    private changeFilterArguments(sortByValue: string): void {
        this.reviewsAllFilters.map((filters: Array<ModalFilterArgumentInterface>) => {

            let reseted_filters = this.resetFilterByValue(filters, sortByValue);

            return this.setActiveFilterByValue(reseted_filters, sortByValue);
        });
        this.reviewsAllFiltersSubject.next(this.reviewsAllFilters);
    }


    public onOptionsChange(options: ReviewOptionsInterface): void {
        if (options.sortBy) {
            if (options.sortBy === ModalFilterArguments.purchases) {
                this.reviewsAllFilters[1] = this.purchaseFilter;
                this.changeFilterArguments(this.selectedPurchaseFilter);
            }
            if (options.sortBy === ModalFilterArguments.written || options.sortBy === ModalFilterArguments.received) {
                this.reviewsAllFilters[1] = this.ratingFilter;
                this.changeFilterArguments(this.selectedRatingFilter);
            }

            this.changeFilterArguments(options.sortBy);
            this.checkIsAvailableFilter();
            this._filterResult(options.sortBy);
            this.renderEditor();
        }
    }


    /**
     * Method for sort array by rating
     * @param sortBy
     * @private
     */
    private _filterResult(sortBy: string): void {
        let callback;

        if (!this.reviewItems) return;

        switch (sortBy) {
            case ModalFilterArguments.all:
                this.filteredItems = this.reviewItems[this.mappings[this.selectedListFilter].field];
                return;

            case ModalFilterArguments.positive:
                callback = (a) => a.stars >= 3.5;
                break;

            case ModalFilterArguments.neutral:
                callback = (a) => a.stars < 3.5 && a.stars > 2.5;
                break;

            case ModalFilterArguments.negative:
                callback = (a) => a.stars <= 2.5;
                break;

            case this.listFilter[0].value:
                return;

            case this.listFilter[1].value:
                return;

            case this.listFilter[2].value:
                this.reviewsAllFilters[1] = this.purchaseFilter;
                this.changeFilterArguments(this.purchaseFilter[0].value);
                return;

            case ModalFilterArguments.addReview:
                callback = (transaction) => {
                    transaction.elements = transaction.elements.filter((order) => !order.is_reviewed);
                    return transaction;
                };
                break;

            case ModalFilterArguments.reviewed:
                callback = (transaction) => {
                    transaction.elements = transaction.elements.filter((order) => order.is_reviewed);
                    return transaction;
                };
                break;

            default:
                return;
        }

        this.filteredItems = AppValues.deepCopy(this.reviewItems)[this.mappings[this.selectedListFilter].field].filter(callback);
    }


    /**
     * Deprecated Now
     * Shows modal error window.
     * @private
     */
    // private _showWarning(description: string) {
    //     this.modalService.warning({
    //         title:          'You cannot send review:',
    //         message:        description,
    //         yesButtonText:  'Close',
    //     });
    // }

}

export class ItemReviewUrl {
    public _w: Window;
    constructor() {
        this._w = window;
    }
    getItemUrl(): string[] {
        return decodeURIComponent(this._w.location.search).slice(this._w.location.search.indexOf('?') + 1).split('=');
    }
    getItemTitle(): string {
        return decodeURIComponent( this._w.location.search.substr(5) );
    }

    getTitle(userTitle: string): string {
        if (document.referrer.search(/inventory-list/i) ||
            document.referrer.search(/shopping-list/i)) {
            return 'Reviews';
        } else {
            return userTitle;
        }
    }

}
