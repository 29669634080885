/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./watch-list-header.component.ngfactory";
import * as i3 from "./watch-list-header.component";
import * as i4 from "../services/redirect.service";
import * as i5 from "@angular/common";
import * as i6 from "../shared/fav-watch-list/fav-watch-list.component.ngfactory";
import * as i7 from "../shared/fav-watch-list/fav-watch-list.component";
import * as i8 from "./watch-list.component";
import * as i9 from "../services/item.service";
import * as i10 from "../product-details/product-details.service";
import * as i11 from "./watch-list.service";
import * as i12 from "../services/error.service";
var styles_WatchListComponent = [];
var RenderType_WatchListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WatchListComponent, data: {} });
export { RenderType_WatchListComponent as RenderType_WatchListComponent };
function View_WatchListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h3", [["class", "empty-list"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("watchlist.empty")); _ck(_v, 1, 0, currVal_0); }); }
export function View_WatchListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "component"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "watch-list-header", [], null, null, null, i2.View_WatchListHeaderComponent_0, i2.RenderType_WatchListHeaderComponent)), i0.ɵdid(2, 49152, null, 0, i3.WatchListHeaderComponent, [i4.RedirectService], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "component__container watch-list__container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WatchListComponent_1)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-fav-watch-list", [], null, [[null, "showItemInfo$"], [null, "removeItem$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showItemInfo$" === en)) {
        var pd_0 = (_co.showItemInfo($event) !== false);
        ad = (pd_0 && ad);
    } if (("removeItem$" === en)) {
        var pd_1 = (_co.removeItem($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_FavWatchListComponent_0, i6.RenderType_FavWatchListComponent)), i0.ɵdid(7, 49152, null, 0, i7.FavWatchListComponent, [], { items: [0, "items"], type: [1, "type"] }, { showItemInfo$: "showItemInfo$", removeItem$: "removeItem$" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_co.items == null) ? null : _co.items.length); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.items; var currVal_2 = "watch"; _ck(_v, 7, 0, currVal_1, currVal_2); }, null); }
export function View_WatchListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "watch-list", [], null, null, null, View_WatchListComponent_0, RenderType_WatchListComponent)), i0.ɵdid(1, 114688, null, 0, i8.WatchListComponent, [i9.ItemService, i10.ProductDetailsService, i11.WatchListService, i12.ErrorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WatchListComponentNgFactory = i0.ɵccf("watch-list", i8.WatchListComponent, View_WatchListComponent_Host_0, {}, {}, []);
export { WatchListComponentNgFactory as WatchListComponentNgFactory };
