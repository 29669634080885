import { ElementRef, Input }    from '@angular/core';
import { SellingItemResponseBody } from '../../../../swagger-gen__output_dir/model/sellingItemResponseBody';


export abstract class SearchListClass {
    @Input() items: SellingItemResponseBody[];
    @Input() title: string;
    @Input() search_from: string;


    constructor( public elementRef:  ElementRef ) { }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {SellingItemResponseBody} item
     * @return {string}
     */
    trackByID(index: number, item: SellingItemResponseBody): string { return item.ID; }

    abstract onClick(item: SellingItemResponseBody): void;

}
