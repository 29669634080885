import { Component }            from '@angular/core';

@Component({
    selector: 'faq',
    styleUrls:  ['settings.sass'],
    template: `
        <div class="component about-component">
            <settings-header></settings-header>
            <iframe class="faq__container" src="https://docs.google.com/document/d/e/2PACX-1vS5hpHX3IJ5w1LFf1pkV-QYjp7LqQ-NSwLpHWzeeNgESjWu2w1s_kAal_SVdf2mMOy3PaVagRtWXV9T/pub?embedded=true"></iframe>
        </div>
    `
})

export class FAQComponent { }
