import { NgModule, ModuleWithProviders }    from '@angular/core';
import { CommonModule }                     from '@angular/common';

import {UnsupportedDeviceService} from './unsupported-device.service';
import {UnsupportedOrientationComponent} from './unsupported-orientation.component';
import {UnsupportedDeviceComponent} from './unsupported-device.component';
import {SafeHtmlPipe} from '../services/safe-html-pipe';
import {UnsupportedAccountComponent} from './unsupported-account';



@NgModule({
    imports: [CommonModule],
    declarations: [
        UnsupportedDeviceComponent,
        UnsupportedOrientationComponent,
        UnsupportedAccountComponent,
        SafeHtmlPipe
    ],
    entryComponents: [
        UnsupportedOrientationComponent,
        UnsupportedAccountComponent
    ],
    exports: [
        UnsupportedDeviceComponent,
    ]
})
export class UnsupportedDeviceModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: UnsupportedDeviceModule,
            providers: [UnsupportedDeviceService]
        };
    }
}
