import {
    Component,
    Input, Output,
    EventEmitter, OnChanges,
    OnInit, SimpleChanges,
    ViewChildren, QueryList
}                               from '@angular/core';

import AppValues                from '../../common/app.values';
import { EditableInterface }     from '../../interfaces/media-editor.interface';

@Component({
    selector:   'time-picker',
    styleUrls:  ['time-picker.sass'],

    template:   `
        <div class="time-picker" [ngClass]="{'invalid': isInvalid}">
            <!--<div class="time-picker" [ngClass]="{'invalid': isInvalid, 'blank': !time}">-->

            <!--MONTH-->
            <select #monthSelect [attr.data-test-id]="dataTestId + '__monthSelect'" [ngClass]="className"
                    (change)="update('month', monthSelect.value)">

                <option class="option" *ngFor="let _month of _months"
                        [ngValue]="_month"
                        [selected]="_month === month">
                    {{_month}}
                </option>
            </select>

            <!--DAY-->
            <select #daySelect [attr.data-test-id]="dataTestId + '__daySelect'" [ngClass]="className"
                    (change)="update('day', daySelect.value)">

                <option class="option" *ngFor="let _day of _days"
                        [ngValue]="_day"
                        [selected]="_day == day">
                    {{_day}}
                </option>
            </select>

            <!--YEAR-->
            <select #yearSelect [attr.data-test-id]="dataTestId + '__yearSelect'" [ngClass]="className"
                    (change)="update('year', yearSelect.value)">

                <option class="option" *ngFor="let _year of _years"
                        [ngValue]="_year"
                        [selected]="_year === year">
                    {{_year}}
                </option>
            </select>

            <span *ngIf="hasTimeSection">
                &nbsp;
                &nbsp;

                <!--HOURS-->
                <select #hourSelect [attr.data-test-id]="dataTestId + '__hourSelect'" [ngClass]="className"
                        (change)="update('hours', hourSelect.value)">

                    <option class="option" *ngFor="let _hour of _hours"
                            [ngValue]="_hour"
                            [selected]="_hour == hours">
                        {{_hour}}</option>
                </select>
            
                <b>:</b>

                <!--MINUTES-->
                <select #minuteSelect [attr.data-test-id]="dataTestId + '__minuteSelect'" [ngClass]="className"
                        (change)="update('minutes', minuteSelect.value)">

                    <option class="option" *ngFor="let _minute of _minutes"
                            [ngValue]="_minute"
                            [selected]="_minute == minutes">
                        {{_minute}}</option>
                </select>
            </span>
        </div>
    `
})
export class TimePickerComponent implements OnInit, OnChanges {
    /**
     * A simple timepicker widget based on select elements.
     * @emits changeEvent$ on any field change.
     */

    @Input()    className:      string;
    @Input()    dataTestId:     string;
    @Input()    role:           string;
    @Input()    time:           number;
    @Input()    hasTimeSection = true;
    @Output()   changeEvent$    = new EventEmitter<EditableInterface>();

    @ViewChildren('daySelect, monthSelect, yearSelect, hourSelect, minuteSelect') selects: QueryList<HTMLSelectElement>;

    _pad            = AppValues._pad;
    day:            number | string;
    isInvalid:      boolean;
    hours:          number | string;
    minutes:        number | string;
    month:          string;
    year:           number;

    _days           = Array.from({length: 31}, (e, i) => i + 1).map(this._pad);
    _months         = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    _years:         number[];

    _hours          = Array.from({length: 24}, (e, i) => i).map(this._pad);
    _minutes        = Array.from({length: 60}, (e, i) => i).map(this._pad);

    private THOUSAND    = 1000;

    /**
     * Delegates initial setting.
     */
    ngOnInit() {
        this._setDate();
        this._defineRanges();
    }

    /**
     * Re-initializes the widget if time data changed.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {
        if (changes.time.previousValue !== changes.time.currentValue) {
            this.ngOnInit();
        }
    }

    /**
     * Retrieves date particles from unix date format.
     * @private
     */
    _setDate(): void {
        const date  = new Date(this.time * this.THOUSAND);

        this.year       = date.getFullYear();
        this.month      = this._months[date.getMonth()];
        this.day        = this._pad(date.getDate());

        this.hours      = this._pad(date.getHours());
        this.minutes    = this._pad(date.getMinutes());
    }

    /**
     * Defines range for the year field: 2 behind, 7 ahead.
     * @private
     */
    _defineRanges(): void {
        const start     = this.year - 5;
        this._years     = Array.from({length: 11}, (e, i) => i + start);
    }

    /**
     * Composes date particles and calls to covert it into unix time format.
     * @param key {string}
     * @param value {string}
     */
    update(key: string, value: string): void {
        const timeObj   = Object.assign({
            day:        this.day,
            month:      this.month,
            year:       this.year,
            hours:      this.hours,
            minutes:    this.minutes
        }, {
            [key]:      value
        });

        const timeMS = Date.parse( this._getTimeString(timeObj) ) / this.THOUSAND;

        this.changeEvent$.emit({
            role:   this.role,
            value:   timeMS
        });
    }

    /**
     * Composes time object into parser acceptable form.
     * @param t {{}}
     * @returns {string}
     * @private
     */
    _getTimeString(t: {}): string {
        // YYYY-MM-DDTHH:mm:ss.sssZ
        // Z = +-hh:mm

        const timeZone  = new Date().getTimezoneOffset();
        const sign      = (timeZone > 0) ? '-' : '+';

        const month     = this._pad(this._months.findIndex(e => e === t['month']) + 1);
        const hours     = this._pad(Math.floor( Math.abs(timeZone) / 60));
        const minutes   = this._pad(Math.abs(timeZone) % 60);

        const timeZoneString = sign + `${hours}:${minutes}`;

        return `${t['year']}-${month}-${t['day']}T${t['hours']}:${t['minutes']}:00.000`;
    }

    // Deprecated
    // setBlank() {
    //     this.selects.toArray().forEach((s: HTMLSelectElement) => s.nativeElement.selectedIndex = -1);
    // }

}
