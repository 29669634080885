import { Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ModalService } from '../modal/modal.service';
import { AfterBootService } from '../after-boot/after-boot.service';
import { TranslateService } from '@ngx-translate/core';
import { getErrorType } from './error.helpers';
var ErrorService = /** @class */ (function () {
    function ErrorService(modalService, afterBootService, translate, injector) {
        this.modalService = modalService;
        this.afterBootService = afterBootService;
        this.translate = translate;
        this.injector = injector;
    }
    ErrorService._extractPaypalMsg = function (_error) {
        var msg = _error['_body'];
        if (!msg || typeof msg !== 'string')
            return false;
        var idx = msg.lastIndexOf('PaypalError');
        return ~idx ? msg.substr(idx) : false;
    };
    ErrorService.prototype.handleHttpError = function (error) {
        // condition for '/change_password'
        var errorObj;
        var body_error_text;
        errorObj = {
            status: error.status,
            statusText: this._extractError(error) || getErrorType(error) || '',
        };
        if (typeof error['_body'] !== "undefined" && getErrorType(error) !== '') {
            var body = JSON.parse(error['_body']);
            if (body && body.error !== '') {
                body_error_text = body.error;
                errorObj['body_error_text'] = body_error_text;
            }
        }
        if (error.status === 0)
            errorObj.statusText = 'Check the Internet connection';
        var paypalError = ErrorService._extractPaypalMsg(error);
        return Observable.throwError(paypalError || errorObj);
    };
    ErrorService.prototype.handleError = function (err) {
        var message = typeof err === 'string' ? err : getErrorType(err);
        if (message && message.includes('Guest User have no')) {
            message = this.unauthorizedMsg;
        }
        if (err.status === 0) {
            message = 'Check the Internet connection';
            this.modalService.close();
            return Observable.throwError(getErrorType(err));
        }
        if (err.status === 403) {
            this.afterBootService.afterLoadingError = err;
            this.afterBootService.handleResolver();
            return Observable.throwError(getErrorType(err));
        }
        if (err.status === 401 || (typeof err === 'string' &&
            err === this.unauthorizedMsg)) {
            this.unauthorizedModal();
            return Observable.throwError(getErrorType(err));
        }
        this.modalService.error({
            title: 'Error:',
            message: message,
            yesButtonText: 'Close',
        });
        return Observable.throwError(getErrorType(err));
    };
    Object.defineProperty(ErrorService.prototype, "unauthorizedMsg", {
        get: function () {
            return this.translate.instant('unauthorized.message.body');
        },
        enumerable: true,
        configurable: true
    });
    ErrorService.prototype.unauthorizedModal = function () {
        var _this = this;
        this.modalService.warning({
            title: this.translate.instant('unauthorized.message.title'),
            message: this.unauthorizedMsg,
            noButtonText: this.translate.instant('unauthorized.message.actionBtn'),
            yesButtonText: this.translate.instant('unauthorized.message.closeBtn'),
        }).then(function (action) {
            !action && _this.router.navigate(['/login']);
        });
    };
    ErrorService.prototype.handleWarning = function (err) {
        this.modalService.warning({
            title: this.translate.instant('modal.alert.title'),
            message: err,
            yesButtonText: this.translate.instant('modal.alert.confirm'),
        });
        return Observable.throwError(err);
    };
    Object.defineProperty(ErrorService.prototype, "router", {
        /***
     * @desc This function creates router property on your service.
     * @return {Router}
     */
        get: function () {
            return this.injector.get(Router);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ErrorService.prototype, "Location", {
        /***
         * @desc This function creates Location property on your service.
         * @return {Location}
         */
        get: function () {
            return this.injector.get(Location);
        },
        enumerable: true,
        configurable: true
    });
    ErrorService.prototype._extractError = function (err) {
        return err.error && err.error[Object.keys(err.error)[0]];
    };
    return ErrorService;
}());
export { ErrorService };
