import {
    Component,
    Output,
    Input,
    EventEmitter
}               from '@angular/core';

// import { SortablejsOptions  }           from 'angular-sortablejs';

import { AppSearchService }                from '../search/search.service';
import {
    ShoppingListInterface,
    ShoppingListItemInterface,
}         from './shopping-list.interface';
import { ShoppingListService }          from './shopping-list.service';


@Component({
    selector: 'sub-list',
    styleUrls:  ['shopping-list.sass'],

    template: `

    <ul
       class="shopping-list__ul"
       [ngClass]="className"
       [sortablejs]="list"
       [sortablejsOptions]="dragAndDropEventOptions">

            <li
                *ngFor="let item of list; trackBy: trackByID; let i = index;"
                (click)="!item.is_custom && showActiveItems(item)"
                [className]="item.is_custom ? 'customItem item' : 'existingItem item'"
                [attr.data-test-id]="'shoppingListProduct_'+ index + '_' + i"
                [style.cursor]="'default'">

                <span class="handle" [attr.data-test-id]="'sortableHandle_'+ index + '_' + i"><i class="icon-menu"></i></span>

                <!--TITLE-->
                <p *ngIf="!item.is_custom" class="list__column item-title"
                   [attr.data-test-id]="'shoppingListProductName_' + index + '_' + i">{{item.descriptor}}</p>

                <button type="button" *ngIf="item.is_custom" class="list__column item-title custom-item"
                   [attr.data-test-id]="'shoppingListItemTitle_' + index + '_' + i"
                   (click)="startRenaming(item)"
                >
                   {{item.name}}
                </button>

                <!--CONTROLS-->
                <div class="item__control">
                    <button type="button" (click)="toggleItemStatus(item, list, $event)" [attr.data-test-id]="'statusBtn_' + index + '_' + i">
                        <i *ngIf="className === 'shopping-list__custom'" class="icon-ok"></i>
                        <i *ngIf="className === 'shopping-list__custom inactive'" class="icon-plus"></i></button>

                    <button type="button" (click)="removeItem(item, list, $event)" [attr.data-test-id]="'removeBtn_' + index + '_' + i">
                        <i class="icon-trash-empty"></i></button>
                </div>
            </li>
        </ul>
    `
})
export class SubListComponent {
    @Input()    className:          string;
    @Input()    list:               ShoppingListItemInterface[];
    @Input()    zone:               string;
    @Input()    index:              number;
    @Output()   itemUpdateEvent$    = new EventEmitter<any>();
    @Output()   listUpdateEvent$    = new EventEmitter<ShoppingListInterface|{}>();
    @Output()   startRenaming$    = new EventEmitter<ShoppingListItemInterface>();

    toggleList: ShoppingListItemInterface[];

    public dragAndDropEventOptions = {
      onUpdate: () => {
         this.listUpdateEvent$.emit(this.list);
      },
      onStart: (event: (event: CustomEvent) => void) => { },
      onEnd: (event: (event: CustomEvent) => void) => { },
      scroll: true,
      handle: '.handle'
    };

    constructor(
        private searchService: AppSearchService,
        private shoppingListService: ShoppingListService
    ) {}


    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {ShoppingListItemInterface} item
     * @return {string}
     */
    trackByID(index: number, item: ShoppingListItemInterface): string {
        return item.subcategory_entry_id;
    }


    showActiveItems(item: ShoppingListItemInterface) {
        item.subcategory_entry_id && this.searchService.getActiveItems(item);
    }


    removeItem(
        item: ShoppingListItemInterface,
        list: ShoppingListItemInterface[],
        event: Event
    ): void {
        event.stopPropagation();

        this.list = this.shoppingListService.removeItem(item, list);

        this.itemUpdateEvent$.emit(this.list);
    }


    toggleItemStatus(
        item: ShoppingListItemInterface,
        list: ShoppingListItemInterface[],
        event: Event
    ): void {
        event.stopPropagation();

        const updatedItem: ShoppingListItemInterface
          = this.shoppingListService.getItemWithUpdates(
              item,
              'is_active',
              !item.is_active
           );

        this.shoppingListService.updateItemInList(list, item, updatedItem);

        this.toggleList = list;

        this.itemUpdateEvent$.emit(this.toggleList);
    }


    startRenaming(item: ShoppingListItemInterface) {
       this.startRenaming$.emit(item);
    }
}
