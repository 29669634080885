<div>
   <user-header></user-header>
   <div class="reset__container">
      <div class="reset__container__content">
         <label class="input__label clearfix">
            <input
               [class.error]="emailErr && email"
               data-test-id="emailInput"
               class="input__text"
               type="email"
               placeholder="Email"
               name="email"
               required
               [(ngModel)]="email"
               (blur)="blurField($event)"
               (keyup)="onValidation($event.target.value)"
            >
         </label>
         <button
            type="submit"
            class="button__lightgreen reset__button"
            data-test-id="resetBtn"
            [disabled]="busy || emailErr || !email"
            (click)="onSend($event)"
         >
            {{ "signup.login.send" | translate }}
         </button>
      </div>
      <div
         role="mailField"
         class="reset__container__content"
      >
         <p class="text-center" data-test-id="forgotLogIn.descriptionText">
             {{ descriptionText }}
         </p>
         <p class="text-center" data-test-id="forgotLogIn.descriptionTextAboutLink">
            {{ descriptionTextAboutLink }}
            <a
               class="link__inline"
               href="mailto:support@BiziBAZA.com">
               support@BiziBAZA.com
            </a>.
         </p>
      </div>
   </div>
</div>
