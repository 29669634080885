import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import {Subject} from 'rxjs';


import {DataService} from '../services/data.service';
import {ErrorInterface, ErrorService} from '../services/error.service';
import {ModalService} from '../modal/modal.service';
import {UserService} from '../user/user.service';

import {ShoppingListInterface, ShoppingListItemInterface} from './shopping-list.interface';
import {SubcategoryEntryInterface, SubcategoryInterface, } from '../interfaces/category.interface';
import {CookieService} from '../services/cookie.service';
import {
    GetItemSuggestionsForShoppingListResponseBody,
    ItemSuggestionsForShoppingList,
    ShoppingList,
    ShoppingListElement,
    UpdateShoppingListService
} from '../../../swagger-gen__output_dir';
import {
    ConcreteSuggestionsCaching,
    LocalCaching,
    LocalCachingCreatorMapping
} from '../local-caching/local-caching-factory';
import AppValues from '../common/app.values';
import { tap } from 'rxjs/operators';


@Injectable()
export class ShoppingListService {

    public list:           ShoppingListItemInterface[] = [];
    public activeList:     ShoppingListItemInterface[] = [];
    public inactiveList:   ShoppingListItemInterface[] = [];
    public suggestionsSearchActivating: Subject<boolean> = new Subject<boolean>();

    private cachedItem: ShoppingListItemInterface | null;

    constructor(
       private updateShoppingListService: UpdateShoppingListService,
       private cookieService:  CookieService,
       private dataService: DataService,
       private errorService: ErrorService,
       private modalService: ModalService,
       private userService: UserService,
       private localCaching: LocalCaching
    ) { }

    public getInactiveList() { return this.inactiveList; }

    public getActiveList() { return this.activeList; }

    public getCachedList() { return this.list; }

    public setCachedList(list: ShoppingListElement[]) { this.list = list; }

    public getCachedItem() { return this.cachedItem.name || ''; }

    public setCachedItem(item: ShoppingListItemInterface): void {
        this.cachedItem = item;
    }

    /**
     * Get request get_user_shopping_list for shopping list
     * To check existence of token:
     * if there is a token then return empty object
     * if there is not a token then send request to server.
     * Success request - return object (shopping list of current user)
     * Nosuccess request - return message error.
     * @returns {Observable< ShoppingListInterface | {} >}
     */
    public getList(): Observable< ShoppingListInterface | {} > {
        let token = this.userService.getUserSession().token
            || this.cookieService.getCookie('user');

        if (!token) return Observable.of({});

        this.modalService.showSpinner();

        return this.dataService.getData('get_user_shopping_list', { "token": token })
            .do(() => this.cookieService.deleteCookie('payment'))
            .map((res: ShoppingListInterface) => {
                this.modalService.close();

                this.list = res.elements;

                return this.list;
            })
            .catch((err: ErrorInterface) => {
                return this.errorService.handleError(err);
            });
    }

    /**
     * Get request get_item_suggestions_for_shopping_list for shopping list
     * To check existence of token:
     * if there is a token then return empty object
     * if there is not a token then send request to server.
     * Success request - return object (uggestionss for shopping list)
     * Nosuccess request - return message error.
     * @param {string} keyword
     * @returns {Observable< GetItemSuggestionsForShoppingListResponseBody | {} >}
     */
    public getSuggestionsForShoppingList(
       keyword: string
    ): Observable<GetItemSuggestionsForShoppingListResponseBody | {}> {
        let token = this.userService.getUserSession().token;

        if (!token) return Observable.of({});

        const url  = `get_item_suggestions_for_shopping_list?keyword=${keyword}`;

        return this.dataService.getData(url, { token });
    }

    public getAllSuggestionsForShoppingList() {
       this.getSuggestionsForShoppingList('')
           .subscribe(
              (res: GetItemSuggestionsForShoppingListResponseBody) => {
                  this.localCaching.setCache(LocalCachingCreatorMapping.ConcreteSuggestionsCaching, res);
              },
              () => {
                  this.localCaching.setCache(LocalCachingCreatorMapping.ConcreteSuggestionsCaching, []);
              }
           );
    }

    /**
     * Returns new custom item with the given name (text)
     * @param {string} text
     * @returns {ShoppingListItemInterface}
     */
    public getBasicCustomItem(text: string): ShoppingListItemInterface {
       return {
            name:                       text,
            subcategory_entry_id:       '',
            is_active:                  true,
            is_custom:                  true
        };
    }

    /**
     * Fetches subcategories from subcategory entries of suggestions
     * @param {string} itemName
     * @return {SubcategoryInterface[]}
     */
    public fetchSubcategories(
        itemName: string
    ): SubcategoryInterface[] {
       const subcategories: SubcategoryInterface[] = [];

       const suggestions = AppValues.deepCopy(this.localCaching.getAllCache(LocalCachingCreatorMapping.ConcreteSuggestionsCaching)[0].data) as GetItemSuggestionsForShoppingListResponseBody;

       suggestions.suggestions = this._suggestionsFilterByName(suggestions.suggestions, itemName);

       suggestions.suggestions.forEach(
          (suggestion: ItemSuggestionsForShoppingList) => {
             const entries: SubcategoryEntryInterface[]
                = this.convertProductListToSubcategoryEntries(suggestion);

             entries.forEach((entry: SubcategoryEntryInterface) => {
                subcategories.push({
                   ID:                   suggestion.subcategory_entry_id,
                   sub_category_name:    suggestion.subcategory_entry_descriptor,
                   sub_category_entries: [ entry ],
                });
             });
          }
       );

       return subcategories;
    }


    private _suggestionsFilterByName(suggestions: ItemSuggestionsForShoppingList[], itemName: string): ItemSuggestionsForShoppingList[] {
        if (suggestions && suggestions.length !== 0) {
            return suggestions.filter((s: ItemSuggestionsForShoppingList) => {
                return (
                    this.compareString(s.subcategory_entry_name, itemName) ||
                    this.compareString(s.subcategory_entry_descriptor, itemName) ||
                    this.compareString(s.subcategory_name, itemName) ||
                    this.compareString(s.product_title, itemName));
            });
        }
        return [];
    }

    private compareString(s: string, itemName: string) {
        const inputedText = itemName.toLocaleLowerCase();
        return s.split(' ').find((a: string) => a.toLocaleLowerCase().startsWith(inputedText)) || s.toLocaleLowerCase().includes(inputedText);
    }


    /**
     * Sorting method.
     * At the top of the list, there should always
     * be only active elements, and then not active.
     * @param {ShoppingListItemInterface[]} list
     * @returns {ShoppingListItemInterface[]}
     */
    public sortList(list: ShoppingListItemInterface[]): ShoppingListItemInterface[] {
        this.activeList = list.filter((item: ShoppingListItemInterface) => item.is_active);
        this.inactiveList = list.filter((item: ShoppingListItemInterface) => !item.is_active);
        this.list = [...this.activeList, ...this.inactiveList];

        return this.list;
    }

    /**
     * Update method (for delete, add, moved items)
     * Works only when there is a token
     * @param {ShoppingListItemInterface[]} list
     * @returns {any}
     */
    public updateShoppingList(list: ShoppingListItemInterface[]): Observable<ShoppingList> {
        const shoppingList: ShoppingList  =  {
            elements: list,
        };

        let token = this.userService.getUserSession().token;

        if (token) {
            return  this.updateShoppingListService.updateShoppingListPut(shoppingList, token)
                        .pipe(tap((res: ShoppingList) => this.setCachedList(res.elements)))
                        .catch((err: ErrorInterface) => this.errorService.handleError(err));
        }

        return Observable.of({elements: []});
    }

    /**
     * Method for add one new Item to Shopping List
     * @param {ShoppingListItemInterface} newItem
     * @param {ShoppingListItemInterface[]} list
     * @returns {ShoppingListItemInterface[]}
     */
    public addItem(
       newItem: ShoppingListItemInterface,
       list: ShoppingListItemInterface[]
    ): ShoppingListItemInterface[] {
        list.push(newItem);
        return this.sortList(list);
    }

    /**
     * Method for add one new Item to Shopping List
     * @param {ShoppingListItemInterface} newItem
     * @param {ShoppingListItemInterface[]} list
     * @param {string} keyword
     * @returns {ShoppingListItemInterface[]}
     */
    public addSuggestedItem(
       newItem: ShoppingListItemInterface,
       list: ShoppingListItemInterface[],
       keyword?: string
    ): ShoppingListItemInterface[] {
        if (this.cachedItem && keyword ) {
            this.updateItemInList(
                list,
                this.cachedItem,
                this.getItemWithUpdates(this.cachedItem, 'name', keyword)
            );

            return this.sortList(list);
        }

        if (this.cachedItem && !keyword) {
            list = this.removeItem(this.cachedItem, list);
        }

        return this.addItem(newItem, list);
    }

    /**
     * Method for remove one new Item to Shopping List
     * filter need for cleaner delete method
     * (and delete null, undefined or any item types)
     * @param {ShoppingListItemInterface} item
     * @param {ShoppingListItemInterface[]} list
     * @returns {ShoppingListItemInterface[]}
     */
    public removeItem(
       item: ShoppingListItemInterface,
       list: ShoppingListItemInterface[]
    ): ShoppingListItemInterface[] {
      const index = this.getIndex(list, item);

      if (index === -1) { return list; }

      delete list[index];

      list = list.filter((n) => typeof n === 'object');

      return this.sortList(list);
    }

    public updateList(list: ShoppingListItemInterface[]): Observable<{ elements: ShoppingListItemInterface[] }> {
        return this.updateShoppingList(list);
    }

    /**
     * Updates the specified item's property with given value
     * @param {ShoppingListItemInterface} item
     * @param {string} property
     * @param {string | boolean | null} value
     * @returns {ShoppingListItemInterface}
     */
    public getItemWithUpdates(
       item: ShoppingListItemInterface,
       property: string,
       value: string | boolean | null
    ): ShoppingListItemInterface {
       return Object.assign({}, item, { [property]: value });
    }

    /**
     * Replaces the current item with updated item if it's found
     * @param {ShoppingListItemInterface[]} list
     * @param {ShoppingListItemInterface} currentItem
     * @param {ShoppingListItemInterface} updatedItem
     * @returns {void}
     */
    public updateItemInList(
       list: ShoppingListItemInterface[],
       currentItem: ShoppingListItemInterface,
       updatedItem: ShoppingListItemInterface
    ): void {
       const index: number = this.getIndex(list, currentItem);
       if (index !== -1) {
          list.splice(index, 1, updatedItem);
       }
    }

    /**
     * Finds the index of the given item in the list
     * @param {ShoppingListItemInterface[]} list
     * @param {ShoppingListItemInterface} item
     * @returns {number}
     */
    private getIndex(
       list: ShoppingListItemInterface[],
       item: ShoppingListItemInterface
    ): number {
       return list.findIndex(
          (elem: ShoppingListItemInterface) => this.deepEqual(elem, item)
       );
    }

    /**
     * Temporary removes 'descriptor' property for each item
     * @param {ShoppingListItemInterface[]} list
     * @returns {ShoppingListItemInterface[]}
     */
    private getListWithoutDescriptor(
       list: ShoppingListItemInterface[]
    ): ShoppingListItemInterface[] {
       return list.map((item: ShoppingListItemInterface) => {
           return {
               name:                   item.name,
               is_active:              item.is_active,
               is_custom:              item.is_custom,
               subcategory_entry_id:   item.subcategory_entry_id,
               sub_category_name:      item.sub_category_name
           };
        });
    }

    /**
     * Returnes the result of comparing two items
     * @param {ShoppingListItemInterface} x
     * @param {ShoppingListItemInterface} y
     * @returns {boolean}
     */
    private deepEqual(
       x: ShoppingListItemInterface,
       y: ShoppingListItemInterface
    ): boolean {
       if (!(x && y && typeof x === 'object' && typeof x === typeof y)) {
          return x === y;
       }

       const requiredFields = ['is_active', 'is_custom', 'name', 'subcategory_entry_id'];
       const xKeys: string[] = Object.keys(x);
       const yKeys: string[] = Object.keys(y);

       return this.isValidForComparison(requiredFields, xKeys) === this.isValidForComparison(requiredFields, yKeys) && requiredFields.every(
            (key: string) => this.deepEqual(x[key], y[key])
          );
    }

    /**
     * @desc Determines whether 2 objects are valid to be compared
     * @param requiredFields
     * @param objKeys
     * @returns true if valid for comparison
     */
    private isValidForComparison(requiredFields: string[], objKeys: string[]): boolean {
        return objKeys.filter(key => requiredFields.includes(key)).length === requiredFields.length;
    }

    /**
     * Converts suggestion product list to subcategory entry list.
     * @desc Formates subcategory entry list from product ID and subcategory_entry_name.
     *       Removes duplicates of subcategory entries with the same title.
     * @param {ItemSuggestionsForShoppingList} suggestion
     * @returns {SubcategoryEntryInterface}
     */
    private convertProductListToSubcategoryEntries(
       suggestion: ItemSuggestionsForShoppingList
    ): SubcategoryEntryInterface[] {
        const convertedList: SubcategoryEntryInterface[]
          = [{
                ID: suggestion.product_id,
                subcategory_entry_name: suggestion.product_title,
                descriptor: suggestion.subcategory_entry_descriptor
             }];

       return convertedList;
    }

}
