import { Router } from '@angular/router';

import { ModalService } from '../modal/modal.service';
import { UserService } from '../user/user.service';

export interface AfterBootResolverStrategy {
   message: string;

   resolveWarning(
       service: ModalService,
       ...params: (UserService | Router | boolean)[]
   ): boolean;
}

export function execute(
    service: ModalService,
    message: string,
    callback?: Function
): void {
    setTimeout(() => {
       service.warning({ title: 'Warning', message, yesButtonText:  'Ok' });
       callback && callback();
    }, 1500);
}
