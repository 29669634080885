
        <header class="app-header" role="search">
            <nav class="app-header__container">

                <div class="navbar__left">
                    <button (click)="onCancel()" type="button" data-test-id="shoppingLink">
                        <i class="icon-custom_back_icon"></i>
                    </button>
                </div>

                <div [ngClass]="locationSearch === 'event'? 'eventbar navbar__center' : 'navbar__center'">
                    <h4 *ngIf="locationSearch !== 'event'" data-test-id="searchKey">{{title || locationSearch}}</h4>

                    <label *ngIf="is_key_search === true" class="search-header__label">
                        <input class="search-header__input" type="text"
                           data-test-id="searchInp"
                           [(ngModel)]="searchValue"
                           [placeholder]="searchResult.type ? searchPlaceholder[searchResult.type] : ''"
                           (keyup.enter)="onSearch($event, searchValue)">
                    </label>
                </div>


                <div *ngIf="((locationSearch !== 'event') && (searchResult.marketList === true)) || (searchResult.type === 'user')" class="navbar__right"></div>

                <button
                   *ngIf="
                      (locationSearch !== 'event') && (searchResult.type !== 'user')
                      && (searchResult.marketList !== true)
                   "
                   type="button"
                   class="icon-container icon-25 map-header__button"
                   (click)="onMapClick()"
                   data-test-id="mapBtnTopContainer"
                >
                    <i class="icon-map_icon" data-test-id="mapBtnTop"></i>
                </button>

                <button *ngIf="!locationSearch && (searchResult.type !== 'user')"
                        type="button" class="search-header__button filter-icon__container"
                        data-test-id="filterBtn" (click)="onFilterClick()">
                    <img [src]="'../../assets/images/svg_icons/filter.svg'" alt="Filter button"/>
                </button>

                <!--<div *ngIf="locationSearch === 'event'" class="search-header__button"></div>-->
            </nav>
        </header>

        <section *ngIf="searchResult.keyWord" class="search-menu">
            <span data-test-id="resultsCount">Results: {{searchResult.foundNum}}</span>

            <!--<div class="search-menu__button" data-test-id="mapBtn"-->
                 <!--[style.display]="searchResult.foundNum ? 'inline-block' : 'none'"-->
                 <!--(click)="onMapClick()">-->
                <!--<div class="sprite sprite-map_icon_2x"></div>-->
            <!--</div>-->
            <select name="sortBy" id="" data-test-id="sortSelect"
                    *ngIf="!isNotAvailableSorting()"
                    [style.display]="searchResult.foundNum ? 'inline-block' : 'none'"
                    (change)="onSortChange($event.target.value)">
                <option disabled value="unsorted" selected="selected">Sort by: <i>(unsorted)</i></option>
                <option value="rate">Sort by rating</option>
                <option *ngIf="isTypeForSortByPrice()" value="ascending">Sort by price &#8679;</option>
                <option *ngIf="isTypeForSortByPrice()" value="descending">Sort by price &#8681;</option>
            </select>
        </section>
    