var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalService } from '../../modal/modal.service';
import { AppReviewsService } from '../reviews.service';
import { CreateReviewsAbstractClass } from './create-review-abstract.class';
import { TranslateService } from '@ngx-translate/core';
var CreateReviewProductComponent = /** @class */ (function (_super) {
    __extends(CreateReviewProductComponent, _super);
    function CreateReviewProductComponent(modalService, reviewsService, translate) {
        return _super.call(this, modalService, reviewsService, translate) || this;
    }
    CreateReviewProductComponent.prototype.showAvailableReviews = function () {
        // Deprecated now (21.10.20)
        // if (!ID) return;
        //
        // this.reviewsService.showReviewList({ID, title, url, order_element_id}, false)
        //     .subscribe( (res) => null, (err) => Observable.throw(err));
    };
    /**
     * @desc Checks the form validity. If valid, composes address object to
     * pass it to the ReviewService.
     */
    CreateReviewProductComponent.prototype.save = function () {
        this.context.callback({ createReviewProduct: null });
    };
    return CreateReviewProductComponent;
}(CreateReviewsAbstractClass));
export { CreateReviewProductComponent };
