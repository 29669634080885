
        <div [classList]="classContainer" [attr.data-test-id]="dataTestIdInput + '_container'"
             (click)="onChangeCheckboxValue()">
            <input name="keepLogged" type="checkbox" [checked]="isCheckboxON"
                   [value]="isCheckboxON" (change)="onChangeCheckboxValue()"
                   [classList]="classInput"
                   [attr.data-test-id]="dataTestIdInput" [attr.id]="forInput">
            <label [attr.for]="forInput" [classList]="classLabel"></label>
            <p>{{ textLabel }}</p>
        </div>
    