/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bizipix-editor.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tag.component.ngfactory";
import * as i3 from "./tag.component";
import * as i4 from "@angular/common";
import * as i5 from "./bizipix-board.component";
var styles_BizipixBoardComponent = [i0.styles];
var RenderType_BizipixBoardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BizipixBoardComponent, data: {} });
export { RenderType_BizipixBoardComponent as RenderType_BizipixBoardComponent };
function View_BizipixBoardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tag", [], null, [[null, "allTagsContext$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("allTagsContext$" === en)) {
        var pd_0 = (_co.allTagsContext($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TagComponent_0, i2.RenderType_TagComponent)), i1.ɵdid(1, 1163264, [[2, 4], [3, 4], ["tagContainer", 4]], 0, i3.TagComponent, [i1.ElementRef], { context: [0, "context"], bizipix: [1, "bizipix"], isToggleRemoveTag: [2, "isToggleRemoveTag"], isClearActive: [3, "isClearActive"] }, { allTagsContext$: "allTagsContext$" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.currentBizipixBoard; var currVal_2 = _co.isToggleRemoveTag; var currVal_3 = _co.isClearActive; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_BizipixBoardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), i1.ɵqud(671088640, 2, { tagContainer: 0 }), i1.ɵqud(671088640, 3, { tagContainers: 1 }), (_l()(), i1.ɵeld(3, 16777216, [[1, 3], ["container", 1]], null, 4, "div", [["class", "bp__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "bp__image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearActive() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "bizipix image"], ["data-test-id", "bizipixImage"], ["draggable", "false"]], [[8, "src", 4]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.createFirstView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BizipixBoardComponent_1)), i1.ɵdid(7, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.tags || _co.currentBizipixBoard.tags); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentBizipixBoard.image_url; _ck(_v, 5, 0, currVal_0); }); }
export function View_BizipixBoardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bizipix-board", [], null, [["window", "orientationchange"]], function (_v, en, $event) { var ad = true; if (("window:orientationchange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).resetView($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BizipixBoardComponent_0, RenderType_BizipixBoardComponent)), i1.ɵdid(1, 49152, null, 0, i5.BizipixBoardComponent, [], null, null)], null, null); }
var BizipixBoardComponentNgFactory = i1.ɵccf("bizipix-board", i5.BizipixBoardComponent, View_BizipixBoardComponent_Host_0, { currentBizipixBoard: "currentBizipixBoard", uploadNewBizipix: "uploadNewBizipix", isFirstLoadingIsComplete: "isFirstLoadingIsComplete" }, { boardEvent$: "boardEvent$", changeBizipixInEditorEvent$: "changeBizipixInEditorEvent$" }, []);
export { BizipixBoardComponentNgFactory as BizipixBoardComponentNgFactory };
