/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./watch-list-header.component";
import * as i4 from "../services/redirect.service";
var styles_WatchListHeaderComponent = [i0.styles];
var RenderType_WatchListHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WatchListHeaderComponent, data: {} });
export { RenderType_WatchListHeaderComponent as RenderType_WatchListHeaderComponent };
export function View_WatchListHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "header", [["class", "app-header wf-list-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "nav", [["class", "app-header__container"], ["role", "watch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "nav__link"], ["data-test-id", "shoppingLink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "navbar__center"], ["role", "watch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "navbar__right"], ["role", "watch"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("watchlist.title")); _ck(_v, 7, 0, currVal_0); }); }
export function View_WatchListHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "watch-list-header", [], null, null, null, View_WatchListHeaderComponent_0, RenderType_WatchListHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.WatchListHeaderComponent, [i4.RedirectService], null, null)], null, null); }
var WatchListHeaderComponentNgFactory = i1.ɵccf("watch-list-header", i3.WatchListHeaderComponent, View_WatchListHeaderComponent_Host_0, {}, {}, []);
export { WatchListHeaderComponentNgFactory as WatchListHeaderComponentNgFactory };
