
        <user-header></user-header>
        <div class="reset__container">

            <form class="reset__container__content"
                  #resetForm="ngForm" (ngSubmit)="onSend($event)">

                <label class="input__label clearfix">
                    <input [ngClass]="{ error: emailErr && email }"
                           data-test-id="emailInput"
                           class="input__text" type="email"
                           placeholder="Email" name="email" required
                           [(ngModel)]="email"
                           (paste)="onPaste($event)"
                           (blur)="blurField($event)"
                           (keyup)="onValidation($event.target.value)">
                </label>

                <button role="reset" type="submit"
                        class="button__lightgreen reset__button"
                        data-test-id="resetBtn"
                        [disabled]="busy || emailErr || !email">
                    {{ resetBtnText }}
                </button>
            </form>

            <div role="mailField" class="reset__container__content">
                <p class="text-center" data-test-id="passwordReset.descriptionText">
                    {{ descriptionText }}
                </p>
                <p class="text-center" data-test-id="passwordReset.descriptionTextAboutLink">
                    {{ descriptionTextAboutLink }}
                    <a class="link__inline"
                       href="mailto:support@BiziBAZA.com">support@BiziBAZA.com</a>.
                </p>    
            </div>
        </div>
    