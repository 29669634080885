import {Component, EventEmitter, Input, Output} from '@angular/core';

import { AppFooterService }     from '../app-footer/app-footer.service';
import {Location} from '@angular/common';
import {SearchOptionsInterface} from '../interfaces/search.interface';


@Component({
    selector: 'analytics-header',
    styleUrls:  ['../../assets/styles/app-header.sass'],
    template: `
        <header class="app-header analytics-header">
            <nav class="app-header__container" *ngIf="analyticsUrl === '/orders'">
                <div class="navbar__left">
                    <button type="button" [routerLink]="mode === 'buyer' ? '/shopping-list' : '/inventory-list'" data-test-id="shoppingLink"
                       class="nav__link"><i class="icon-custom_back_icon"></i></button>
                </div>

                <div class="navbar__center">
                    <h4 data-test-id="analytics">{{ "analytics.header.orders" | translate }}</h4>
                </div>

                <div class="navbar__right"></div>
            </nav>
            <nav class="app-header__container" *ngIf="analyticsUrl === '/pending-orders'">
                <div class="navbar__left">
                    <button type="button" [routerLink]="'/orders'" data-test-id="analyticLink"
                            class="nav__link"><i class="icon-custom_back_icon"></i></button>
                </div>

                <div class="navbar__center">
                    <h4 data-test-id="pending-orders">{{ "analytics.header.pendingOrders" | translate }}</h4>
                </div>

                <div class="navbar__right"></div>
            </nav>
             <nav class="app-header__container" *ngIf="analyticsUrl === '/history'">
                <div class="navbar__left">
                    <button type="button" (click)="goBack()" data-test-id="analyticLink"
                       class="nav__link"><i class="icon-custom_back_icon"></i></button>
                </div>

                <div class="navbar__center" role="history">
                    <!--<h4 data-test-id="sellerHistory" *ngIf="mode==='seller'">Seller History</h4>-->
                    <!--<h4 data-test-id="buyerHistory" *ngIf="mode==='buyer'">Buyer History</h4>-->
                    <h4 data-test-id="history">{{ "analytics.header.history" | translate }}</h4>
                </div>

                <div class="navbar__right" role="history"></div>
            </nav>
        </header>
    `
})

export class AnalyticsHeaderComponent {

    @Input() analyticsUrl: string;
    @Output() goBackEvent$ = new EventEmitter<null>();

    mode:       string;

    constructor(
        private appFooterService:   AppFooterService,
    ) {
        this.mode = this.appFooterService.getMode();
    }

    goBack() {
        this.goBackEvent$.emit(null);
    }
}
