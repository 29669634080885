/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./img-loader.sass.shim.ngstyle";
import * as i1 from "../../../assets/styles/sprite.sass.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "./img-loader.component";
import * as i4 from "../../modal/modal.service";
import * as i5 from "@ngx-translate/core";
var styles_ImgLoaderComponent = [i0.styles, i1.styles];
var RenderType_ImgLoaderComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ImgLoaderComponent, data: {} });
export { RenderType_ImgLoaderComponent as RenderType_ImgLoaderComponent };
export function View_ImgLoaderComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 7, "div", [["class", "il__container"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "span", [["class", "control"], ["data-test-id", "uploadBtn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUpload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 0, "span", [["class", "sprite sprite-description_3x"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "h4", [["contenteditable", "true"], ["data-test-id", "title"], ["role", "editable"]], null, [[null, "blur"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.finishRenaming({ event: $event }) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.startRenaming({ event: $event }) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(6, null, ["", ""])), (_l()(), i2.ɵeld(7, 0, null, null, 0, "input", [["accept", "imageTypes"], ["data-test-id", "fileInp"], ["name", "photo"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onImgUpload($event.currentTarget) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.imageUrl) + ")"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.text; _ck(_v, 6, 0, currVal_1); }); }
export function View_ImgLoaderComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "img-loader", [], null, null, null, View_ImgLoaderComponent_0, RenderType_ImgLoaderComponent)), i2.ɵdid(1, 49152, null, 0, i3.ImgLoaderComponent, [i2.ElementRef, i4.ModalService, i5.TranslateService], null, null)], null, null); }
var ImgLoaderComponentNgFactory = i2.ɵccf("img-loader", i3.ImgLoaderComponent, View_ImgLoaderComponent_Host_0, { imageUrl: "imageUrl", text: "text", placeholder: "placeholder", regExp: "regExp", minMaxLength: "minMaxLength", isEmpty: "isEmpty", errorMessage: "errorMessage" }, { changeEvent$: "changeEvent$" }, []);
export { ImgLoaderComponentNgFactory as ImgLoaderComponentNgFactory };
