/**
* The Originator holds some important state that may change over time. It also
 * defines a method for saving the state inside a memento and another method for
 * restoring the state from it.
 */
export class Originator {
    private state: string;

    constructor(state: string) {
        this.state = state;
    }

    /**
     * The Originator's business logic may affect its internal state. Therefore,
     * the client should backup the state before launching methods of the
     * business logic via the save() method.
     * @param {string} state
     */
    public setState(state: string) {
        this.state = state;
    }

    /**
     * Saves the current state inside a memento
     * @return {Memento}
     */
    public save(): Memento {
        return new ConcreteMemento(this.state);
    }

    /**
     * Restores the Originator's state from a memento object
     * @param {Memento} memento
     */
    public restore(memento: Memento): void {
        this.state = memento.getState();
    }
}

/**
 * The Memento interface provides a way to retrieve
 * the memento's metadata, such as creation date or name.
 */
export interface Memento {
    getState(): string;

    getName(): string;

    getDate(): string;
}

/**
 * The Concrete Memento contains the infrastructure for storing the Originator's state
 */
export class ConcreteMemento implements Memento {
    private state: string;

    private date: string;

    constructor(state: string) {
        this.state = state;
        this.date = new Date().toISOString().slice(0, 19).replace('T', ' ');
    }

    /**
     * The Originator uses this method when restoring its state
     */
    public getState(): string {
        return this.state;
    }

    public getDate(): string {
        return this.date;
    }
    public getName(): string {
        return `${this.date} / (${this.state.substr(0, 9)}...)`;
    }
}

/**
 * The Caretaker doesn't depend on the Concrete Memento class. Therefore, it
 * doesn't have access to the originator's state, stored inside the memento. It
 * works with all mementos via the base Memento interface.
 */
export class Caretaker {
    private mementos: Memento[] = [];

    private originator: Originator;

    constructor(originator: Originator) {
        this.originator = originator;
    }

    public backup(): void {
        this.mementos.push(this.originator.save());
    }

    public undo(): void {
        if (!this.mementos.length) {
            return;
        }
        const memento = this.mementos.pop();

        this.originator.restore(memento);
    }

    public showHistory() {
        return this.mementos;
    }
}
