/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./bizipix-menu.component";
var styles_BizipixMenuComponent = [];
var RenderType_BizipixMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BizipixMenuComponent, data: {} });
export { RenderType_BizipixMenuComponent as RenderType_BizipixMenuComponent };
function View_BizipixMenuComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "bizipix-menu__container__addnewbizipix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "button", [["class", "bizipix-menu__addnewbizipix icon-25"], ["data-test-id", "addBizipix"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNewBizipix($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_BizipixMenuComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "bizipix-menu__image-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["draggable", "false"]], [[1, "data-test-id", 0], [8, "src", 4], [8, "className", 0], [8, "alt", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectBizipix($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("bizipix_" + _v.context.index); var currVal_1 = _v.context.$implicit.image_url; var currVal_2 = ((_co.currentBizipix.ID == _v.context.$implicit.ID) ? "current-bizipix" : ""); var currVal_3 = ("BiziPix " + _v.context.index); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_BizipixMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "bizipix-menu__container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BizipixMenuComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BizipixMenuComponent_3)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.imageData; var currVal_2 = _co.trackByID; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_BizipixMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_BizipixMenuComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.imageData && _co.currentBizipix); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BizipixMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "bizipix-menu", [], null, null, null, View_BizipixMenuComponent_0, RenderType_BizipixMenuComponent)), i0.ɵdid(1, 49152, null, 0, i2.BizipixMenuComponent, [], null, null)], null, null); }
var BizipixMenuComponentNgFactory = i0.ɵccf("bizipix-menu", i2.BizipixMenuComponent, View_BizipixMenuComponent_Host_0, { currentBizipix: "currentBizipix", imageData: "imageData", type: "type" }, { selectedBizipixEvent$: "selectedBizipixEvent$", createNewBizipixEvent$: "createNewBizipixEvent$" }, []);
export { BizipixMenuComponentNgFactory as BizipixMenuComponentNgFactory };
