var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import AppValues from '../common/app.values';
import { ProfileService } from './profile.service';
import { ModalService } from '../modal/modal.service';
import { Observable } from 'rxjs/Rx';
import { UserService } from '../user/user.service';
import { ClientPaymentService } from '../payment/payment.service';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { getErrorType } from '../services/error.helpers';
var ProfileComponent = /** @class */ (function (_super) {
    __extends(ProfileComponent, _super);
    function ProfileComponent(modalService, profileService, clientPaymentService, injector, translate) {
        var _this = _super.call(this) || this;
        _this.modalService = modalService;
        _this.profileService = profileService;
        _this.clientPaymentService = clientPaymentService;
        _this.injector = injector;
        _this.translate = translate;
        /**
         * Represents Profile Screen.
         * @desc Includes editable text fields as MediaEditorComponents
         * and editable image as ImgLoaderComponent.
         * @listens to changeEvent$ from the editables and fires #onDataChange.
         */
        _this.emailPattern = AppValues.emailPattern;
        _this.emailMinMaxLength = [AppValues.MINIMUM_EMAIL_LENGTH, AppValues.MAXIMUM_EMAIL_LENGTH];
        _this.webPagePattern = AppValues.webPagePattern;
        _this.webPageMinMaxLength = [AppValues.MINIMUM_WEBPAGE_LENGTH, AppValues.MAXIMUM_WEBPAGE_LENGTH];
        _this.titlePattern = AppValues.profileTitlePattern;
        _this.window = window;
        return _this;
    }
    /**
     * Fetches Profile data via service.
     */
    ProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.trackSubscription(this.profileService.getProfile()
            .subscribe(function (profile) { return _this.profile = profile; }, function (err) { return Observable.throwError(err); }));
    };
    Object.defineProperty(ProfileComponent.prototype, "isSellerMode", {
        get: function () {
            return this.injector.get(UserService).isSellerMode();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Fires on address editing clicks.
     * @param addr Address object.
     * @param index Index of the object in profile.addresses array.
     */
    ProfileComponent.prototype.onAddressEdit = function (addr, index) {
        this.profileService.onAddressEdit(addr, index);
    };
    ProfileComponent.prototype.onOpenStripeDashboard = function () {
        var _this = this;
        this.trackSubscription(this.clientPaymentService.paymentsLoginLink()
            .subscribe(function (externalLinkView) { return _this.window.open(externalLinkView.link, "_self"); }, function (error) { return _this._handlePaymentLoginError(error); }));
    };
    /**
     * Fires when a MediaEditorComponent or ImgLoaderComponent emits.
     * @desc Chooses corresponding service method to handle profile depending on
     * which kind of data (img or text field) has changed.
     * @param obj Contains data edited.
     */
    ProfileComponent.prototype.onDataChange = function (obj) {
        var _this = this;
        var _a;
        if (obj.imageFile) {
            this.trackSubscription(this.profileService.uploadImg(obj.imageFile)
                .subscribe(function (imageURL) {
                _this._updateProfile(Object.assign(_this.profile, { imageURL: imageURL }));
            }, function (err) { return _this.modalService.error({ title: 'Error:', message: err, yesButtonText: 'Close' }); }));
            return;
        }
        if (obj.role) {
            var newProfile = Object.assign(this.profile, (_a = {}, _a[obj.role] = obj.value, _a));
            if (!this._isFieldCorrect(obj))
                return;
            this._updateProfile(newProfile);
        }
    };
    ProfileComponent.prototype.eventHandler = function (event) {
        if (event.keyCode === 13 || event.code === "13") {
            event.preventDefault();
            event.target.blur();
        }
    };
    /**
     * Saves profile via Service. Updates Profile on response.
     * @private
     * @param profile
     */
    ProfileComponent.prototype._updateProfile = function (profile) {
        var _this = this;
        this.trackSubscription(this.profileService.saveProfile(profile, true)
            .subscribe(function (res) { return _this.profile = res; }, function (error) { return _this.profileService.handleEmailUpdateErr(error); }));
    };
    /**
     * Ensures that profile object has expected key and its value is of string type.
     * @private
     * @param obj
     * @returns {boolean|undefined} True if ok, undefined otherwise.
     */
    ProfileComponent.prototype._isFieldCorrect = function (obj) {
        if (!this.profile.hasOwnProperty(obj.role)) {
            window.console.error("Unexpected field \"" + obj.role + "\"");
        }
        else if (typeof obj.value !== 'string') {
            window.console.error("Unexpected type for \"" + obj.role + "\" field");
        }
        else {
            return true;
        }
    };
    ProfileComponent.prototype._handlePaymentLoginError = function (error) {
        this.modalService.error({
            title: this.translate.instant('stripe.paymentsLoginLink.modal.title'),
            message: getErrorType(error),
            yesButtonText: this.translate.instant('stripe.paymentsLoginLink.modal.yesButton')
        });
    };
    return ProfileComponent;
}(UnsubscribeOnDestroyAbsctractClass));
export { ProfileComponent };
