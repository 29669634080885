var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import { BizipixBoardComponent } from './bizipix-board.component';
import { BizipixEditorService } from './bizipix-editor.service';
import { ModalService } from '../modal/modal.service';
import { Tag } from './tag.component';
import { ItemService } from '../services/item.service';
import AppValues from '../common/app.values';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { isValidFileSize } from '../product-editor/additional/user-platform.helpers';
var BizipixEditorComponent = /** @class */ (function (_super) {
    __extends(BizipixEditorComponent, _super);
    function BizipixEditorComponent(bizipixService, modalService, itemService, translate) {
        var _this = _super.call(this) || this;
        _this.bizipixService = bizipixService;
        _this.modalService = modalService;
        _this.itemService = itemService;
        _this.translate = translate;
        _this.isChangedBizipix = false;
        _this.editable = true;
        _this.isToggleRemoveTag = false;
        _this.imageTypes = AppValues.imageTypes;
        _this.timeout = false;
        _this.delta = 200;
        return _this;
    }
    /**
     * @desc Fetches BiziPix image data object from service. Shows the upload menu if
     * no object has been fetched.
     * @return void
     */
    BizipixEditorComponent.prototype.ngOnInit = function () {
        this._setOfInitialValues();
        this.isFirstLoadingIsComplete = true;
        this.selectedBixipix = AppValues.deepCopy(this.currentBizipix);
        this._checkResizeWindow();
    };
    BizipixEditorComponent.prototype.getInstructionImage = function () {
        if (screen.availHeight > screen.availWidth) {
            return '../assets/images/svg_icons/mobile_bizipix_svg.svg';
        }
        else {
            return '../assets/images/svg_icons/desktop_bizipix_svg.svg';
        }
    };
    BizipixEditorComponent.prototype.resizeEnd = function (self) {
        var data = new Date().getTime();
        if (data - self.realtime < self.delta) {
            setTimeout(function () { return self.resizeEnd(self); }, self.delta);
        }
        else {
            self.timeout = false;
            self.updateSelectedBizipix(self.currentBizipix);
            self.isReloading = false;
        }
    };
    /**
     * Called from BixipixEditorComponent (Output) decorator's selectedBizipixEvent$
     * Apply pattern strategy for a definition of necessary action
     * @desc Called when the user selects BiziPix. Setting current BiziPix.
     * If this pix has no changes (modified coordinates of tags or new tags), then change the pix to the one selected by the user.
     * And if there are changes on the current pixel, we call the method changeBizipixInEditor() and ask the user for confirmation of the save.
     * @param {{currentBizipix: BizipixItemsInterface; isAddNewBizipix?: boolean}} event
     */
    BizipixEditorComponent.prototype.addOrChangeBizipixStrategyEvent = function (event) {
        this.board.isFirstLoadingIsComplete = true;
        if (!this.isChangedBizipix && event.currentBizipix) {
            this.updateSelectedBizipix(event.currentBizipix);
        }
        if (this.isChangedBizipix) {
            this._changeBizipixInEditor(event);
        }
        if (event.isAddNewBizipix) {
            if (this.bizipixesData.length < this.bizipixService.availableAmount) {
                this.fileInput.nativeElement.click();
                this.createNewBizipix = true;
            }
            else
                this.bizipixService.moreThanFiveBiziPixMessage();
        }
    };
    /**
     * Private Method for remove tagComponent and update view for current Bizipix
     * @param {BizipixItemsInterface} currentBizipix
     * @private
     */
    BizipixEditorComponent.prototype.updateSelectedBizipix = function (currentBizipix) {
        this._updateSelectedBizipixWithoutReset(currentBizipix, true);
        this.isFirstLoadingIsComplete = true;
        this._resetChangedBizipixValue();
    };
    /**
     * Set isChangedBizipix
     * @desc Called from BizipixMenuComponent (Output) decorator's changeCurrentBizipixEvent$
     * @param {{isChangedBizipix: boolean}} event
     */
    BizipixEditorComponent.prototype.changeCurrentBizipixEvent = function (event) {
        this.isChangedBizipix = event.changedBizipix;
    };
    /**
     * Called from UploadComponent (Output) decorator's fileUploadEvent$
     * @desc Checks for payload, which assumes to be a picture blob. If no payload returned, closes the editor.
     * Delegates to the service to upload the picture. On response delegates the service to create a
     * new BiziPix image if no, otherwise updates the image URL.
     * @param {{payload: Blob | boolean; currentInput: EventTarget}} event
     * @return void
     */
    BizipixEditorComponent.prototype.onImageUpload = function (event) {
        var _this = this;
        if (!event.payload) {
            this.isBlank &&
                !this.bizipixesData &&
                this.bizipixService.exitEditor(true);
            this.isBlank = false;
            return;
        }
        this.isBlank = false;
        if (typeof event.payload !== 'boolean' && event.payload.size && !isValidFileSize(event.payload.size)) {
            this.modalService.error({
                title: this.translate.instant('modal.error.uploadImage.title'),
                message: this.translate.instant('modal.error.uploadImage.message'),
                yesButtonText: this.translate.instant('modal.error.uploadImage.confirm'),
            });
            return;
        }
        ;
        this.bizipixService
            .uploadImg(event.payload)
            .switchMap(function (imageURL) {
            // this.isReloading = true;
            return Observable.of(imageURL);
        })
            .subscribe(function (imageURL) {
            _this.isReloading = true;
            if (imageURL) {
                if (_this.createNewBizipix ||
                    !_this.bizipixesData ||
                    !Object.keys(_this.bizipixesData).length) {
                    return _this.bizipixService
                        .createBizipix(imageURL)
                        .subscribe(function (newBizpix) {
                        _this._resetChangedBizipixValue();
                        _this.currentBizipix = newBizpix;
                        _this._praparationForImageUpload(newBizpix, _this.createNewBizipix);
                    });
                }
                else {
                    _this._resetChangedBizipixValue();
                    _this.currentBizipix.image_url = imageURL;
                    _this._praparationForImageUpload(_this.currentBizipix, _this.createNewBizipix);
                }
            }
            else {
                _this.isReloading = false;
            }
        }, function (err) { return (_this.isReloading = false); });
        this.resetInput(event.currentInput);
    };
    /**
     * @desc Called when the user chooses an item via the item picker. If the chosen item is already bind
     * to some of the tags, calls the service to show a warning. Then calls to update existingItem tag or
     * create a new one.
     * @param item
     * @return void
     */
    BizipixEditorComponent.prototype.onItemSelect = function (item) {
        this.isPickerMode = false;
        if (!item) {
            return;
        }
        if (
        // tslint:disable-next-line:no-bitwise
        ~this.currentBizipix.tags.findIndex(function (tag) { return item.ID === tag.item_id; })) {
            this.bizipixService.showCreationWarning();
            return;
        }
        this.isReloading = true;
        this.isChangedBizipix = true;
        this.editedTag ? this._updateItem(item) : this._createTag(item);
        this.editedTag = null;
        // this._updateSelectedBizipix(this.currentBizipix);
    };
    // ---------- Editor events-----------------------------------
    /**
     * Dispatches to the editor commands (events on the editor menu operations) by calling
     * the appropriate method.
     * @param param
     */
    BizipixEditorComponent.prototype.onEditorEvent = function (param) {
        this[param]();
    };
    /**
     * Delegates to close the editor.
     */
    BizipixEditorComponent.prototype.close = function () {
        var params = { changed: this.isChangedBizipix };
        if (this.isChangedBizipix && typeof this.board !== 'undefined') {
            params = Object.assign(params, {
                bizipix: this.board.getCurrentBizipix(),
            });
        }
        this.bizipixService.closeEditor(params);
    };
    /**
     * Sets the @isBlank attribute, responsible for rendering the upload menu.
     */
    BizipixEditorComponent.prototype.upload = function () {
        this.fileInput.nativeElement.click();
        this._changeBizipix();
    };
    /**
     * Sets the @isPickerMode attribute, responsible for rendering the item picker.
     */
    BizipixEditorComponent.prototype.create = function () {
        var _this = this;
        this.bizipixService
            .getActiveInventoryItems()
            .subscribe(function (items) {
            if (!_this.bizipixesData.length) {
                _this.bizipixEditorWarning('bizipix.editor.alert.noBiziPixImages');
                return;
            }
            if (!items.length) {
                _this.bizipixEditorWarning('bizipix.editor.alert.noProductsAdded');
                return;
            }
            else {
                _this.isPickerMode = true;
            }
        });
    };
    /**
     * Delegates to remove BiziPix.
     */
    BizipixEditorComponent.prototype.remove = function () {
        var _this = this;
        if (this.isBoardExist()) {
            this.bizipixService.removeBizipix().then(function (action) {
                if (action) {
                    _this.bizipixService
                        .removePix(_this.currentBizipix)
                        .subscribe(function () {
                        _this._setOfInitialValues();
                        _this.updateSelectedBizipix(_this.currentBizipix);
                        _this.board.updateView(_this.currentBizipix, true);
                    });
                }
            });
        }
        else {
            this.bizipixEditorWarning('bizipix.editor.alert.noBiziPixImages');
        }
    };
    /**
     * Calls #toggleRemoveTagMode of the board.
     */
    BizipixEditorComponent.prototype.removeTags = function () {
        if (this.isBoardExist()) {
            this.board.toggleRemoveTagMode(!this.isToggleRemoveTag);
        }
    };
    /**
     * Calls #save of the board.
     */
    BizipixEditorComponent.prototype.save = function () {
        this.isBoardExist() && this.board.save();
    };
    /**
     * Calls #preSave of the board.
     */
    BizipixEditorComponent.prototype.preSave = function () {
        this.isBoardExist() && this.board.preSave();
    };
    /**
     * Shows the picker and appoint the tag to be rebind.
     * @param tagData
     */
    BizipixEditorComponent.prototype.rebindItem = function (tagData) {
        var newtag = this.adapter(tagData, 'TagContext');
        this.isPickerMode = true;
        this.editedTag = newtag;
    };
    // ----------- Board events ---------------------------
    /**
     * Dispatches the board commands (events) by calling the appropriate method.
     * @param event
     */
    // tslint:disable-next-line:no-any
    BizipixEditorComponent.prototype.onBoardEvent = function (event) {
        this[Object.keys(event)[0]](Object.values(event)[0]);
    };
    /**
     * Delegates to the service to remove a tag. Switches off 'remove tag' mode
     * for all the TagComponent.
     * @param tagID
     */
    BizipixEditorComponent.prototype.removeTag = function (tagID) {
        var _this = this;
        this.bizipixService.removeTag().then(function (action) {
            if (action === true) {
                _this.board.deleteTag(tagID);
                _this.board.toggleRemoveTagMode(true);
                _this.isChangedBizipix = true;
            }
        });
    };
    /**
     * Delegates from BiziPixBoard Component
     */
    BizipixEditorComponent.prototype.savePix = function () {
        this._saving();
    };
    /**
     * Delegates from BiziPixBoard Component
     */
    BizipixEditorComponent.prototype.preSavePix = function () {
        var presave = true;
        this._saving(presave);
    };
    BizipixEditorComponent.prototype.bizipixEditorWarning = function (term) {
        this.modalService.warning({
            title: this.translate.instant('bizipix.editor.alert.title'),
            message: this.translate.instant(term),
            yesButtonText: this.translate.instant('bizipix.editor.alert.close'),
        });
    };
    BizipixEditorComponent.prototype._checkResizeWindow = function () {
        var _this = this;
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.trackSubscription(this.resizeObservable$.subscribe(function (event) {
            _this.isReloading = true;
            _this.realtime = new Date().getTime();
            if (_this.timeout === false) {
                _this.timeout = true;
                setTimeout(function () { return _this.resizeEnd(_this); }, _this.delta);
            }
        }));
    };
    BizipixEditorComponent.prototype._setOfInitialValues = function () {
        this.bizipixesData = this.bizipixService.getImageData();
        if (!this.bizipixesData || !Object.keys(this.bizipixesData).length) {
            this.isBlank = true;
        }
        else {
            this.currentBizipix = this.bizipixesData[0];
        }
    };
    /**
     * Actions after answer by changeBizipixInEditor modal
     * @param {{currentBizipix: BizipixItemsInterface; isAddNewBizipix?: boolean}} event
     * @private
     */
    BizipixEditorComponent.prototype._changeBizipixInEditor = function (event) {
        var _this = this;
        this.bizipixService.changeBizipixInEditor().then(function (action) {
            if (action === true) {
                _this.selectedBixipix = AppValues.deepCopy(_this.currentBizipix);
                if (event.currentBizipix && !event.isAddNewBizipix) {
                    _this.board.save();
                    _this.changeSelectedBixipix(event.currentBizipix);
                    _this.setInitialBizipixesData();
                    _this.updateSelectedBizipix(event.currentBizipix);
                }
                if (event.isAddNewBizipix) {
                    _this.board.preSave();
                    _this.createNewBizipix = true;
                }
            }
            else {
                if (_this.selectedBixipix &&
                    !AppValues.deepEqual(_this.selectedBixipix, _this.currentBizipix)) {
                    // this._updateSelectedBizipix(this.selectedBixipix);
                    if (_this.isBoardExist()) {
                        _this.board.removeComponent();
                    }
                    _this.bizipixWasChangedAndNotSaved(event.currentBizipix);
                    _this.updateSelectedBizipix(event.currentBizipix);
                }
                else {
                    _this.changeSelectedBixipix(event.currentBizipix);
                    _this.setInitialBizipixesData();
                    _this.updateSelectedBizipix(event.currentBizipix);
                }
            }
        });
    };
    BizipixEditorComponent.prototype.bizipixWasChangedAndNotSaved = function (currentBizipix) {
        this.board.resetView();
        var imageData = this.changeImageDataSelectedBixipix();
        this.changeSelectedBixipix(currentBizipix);
        this.setInitialBizipixesData(this.selectedBixipix);
        this.isFirstLoadingIsComplete = false;
        this.isChangedBizipix = false;
        this.bizipixService.setImageData(imageData);
    };
    BizipixEditorComponent.prototype.changeImageDataSelectedBixipix = function () {
        var _this = this;
        var imageData = this.bizipixService.imageData;
        imageData.map(function (bp) {
            if (_this.selectedBixipix && bp.ID === _this.selectedBixipix.ID) {
                bp.tags = AppValues.deepCopy(_this.selectedBixipix.tags);
            }
        });
        return imageData;
    };
    BizipixEditorComponent.prototype.changeSelectedBixipix = function (currentBizipix) {
        if (currentBizipix && this.currentBizipix.ID !== currentBizipix.ID) {
            this.selectedBixipix = AppValues.deepCopy(currentBizipix);
        }
    };
    /**
     * Set initial Bizipixed Data and current Bizipix
     * for reset values to initial state.
     */
    BizipixEditorComponent.prototype.setInitialBizipixesData = function (selectedBixiPix) {
        var _this = this;
        var clonedBizipix = AppValues.deepCopy(this.bizipixService.getClonedBizipix);
        if (selectedBixiPix) {
            this.currentBizipix = AppValues.deepCopy(selectedBixiPix);
        }
        clonedBizipix.forEach(function (bizipix) {
            if (_this.currentBizipix.ID === bizipix.ID) {
                _this.currentBizipix = AppValues.deepCopy(bizipix);
            }
        });
        this.bizipixesData = [];
        this.bizipixesData = clonedBizipix;
    };
    BizipixEditorComponent.prototype._resetChangedBizipixValue = function () {
        this.isChangedBizipix = false;
    };
    BizipixEditorComponent.prototype._updateSelectedBizipixWithoutReset = function (currentBizipix, isSetSelectedBizipix) {
        var _this = this;
        if (this.isBoardExist()) {
            this.board.removeComponent();
        }
        this._setOfInitialValues();
        this.currentBizipix = currentBizipix;
        setTimeout(function () {
            if (isSetSelectedBizipix) {
                _this.selectedBixipix = AppValues.deepCopy(currentBizipix);
            }
            else {
                _this.board.updateView(_this.currentBizipix);
            }
        });
    };
    /**
     * @desc the method set flags needed for changing status a bizipix
     * @private
     */
    BizipixEditorComponent.prototype._changeBizipix = function () {
        this.isBlank = false;
        this.createNewBizipix = false;
    };
    BizipixEditorComponent.prototype.resetInput = function (event) {
        event['value'] = '';
        if (!/safari/i.test(navigator.userAgent)) {
            event['type'] = '';
            event['type'] = 'file';
        }
    };
    BizipixEditorComponent.prototype._praparationForImageUpload = function (newBizpix, createNewBizipixEvent) {
        var _this = this;
        this.isPickerMode = false;
        this._setStrategyForChangingBizipix(newBizpix, createNewBizipixEvent);
        setTimeout(function () {
            _this.isReloading = false;
        });
    };
    /**
     * The method sets the flags and depending on this the necessary strategy (updating or creating) is selected
     * @param {BizipixItemsInterface} imageData
     * @param {boolean} createNewBizipix
     * @private
     */
    BizipixEditorComponent.prototype._setStrategyForChangingBizipix = function (imageData, createNewBizipix) {
        if (createNewBizipix === true ||
            !this.bizipixesData ||
            !Object.keys(this.bizipixesData).length) {
            this.bizipixesData.push(imageData);
            this.isChangedBizipix = false;
            this.uploadNewBizipix = false;
        }
        else {
            this.isChangedBizipix = true;
            this.uploadNewBizipix = true;
        }
    };
    /**
     * Creates a new tag object.
     * @param item
     * @return void
     * @private
     */
    BizipixEditorComponent.prototype._createTag = function (item) {
        this.currentBizipix.tags.push({
            item_title: item.title,
            item_price_units: this.itemService.priceUnitsNameFn(item),
            item_id: item.ID,
            item_price: this.itemService.itemPriceFn(item),
            ID: '',
            tag_position: {
                bottom_right_x: 0.65,
                bottom_right_y: 0.65,
                top_left_x: 0.35,
                top_left_y: 0.35,
            },
        });
        this.prepareToUpdateBizipixView();
    };
    /**
     * Updates the tag object under edition.
     * @param item
     * @return void
     * @private
     */
    BizipixEditorComponent.prototype._updateItem = function (item) {
        var _this = this;
        this.currentBizipix.tags.forEach(function (tag) {
            if (_this.editedTag.item_id === tag.item_id) {
                _this.editedTag.item_id = item.ID;
                _this.editedTag.item_title = item.title;
                _this.editedTag.item_price = _this.itemService.itemPriceFn(item);
                _this.editedTag.item_price_units =
                    _this.itemService.priceUnitsNameFn(item);
                tag = Object.assign(tag, _this.editedTag);
            }
        });
        this.prepareToUpdateBizipixView();
    };
    BizipixEditorComponent.prototype.prepareToUpdateBizipixView = function () {
        var _this = this;
        this.isChangedBizipix = true;
        setTimeout(function () {
            _this._updateSelectedBizipixWithoutReset(_this.currentBizipix, false);
            _this.isReloading = false;
        });
    };
    BizipixEditorComponent.prototype.adapter = function (tagData, type) {
        if (tagData['callback'] || type === 'TagContext') {
            var newtag = new Tag(tagData.ID, tagData.item_id, tagData.item_title, tagData.item_price, tagData.item_price_units, tagData.tag_position);
            return newtag;
        }
    };
    /**
     * @desc Delegates to the service to save the pix.
     * @param {boolean} presave
     * @private
     */
    BizipixEditorComponent.prototype._saving = function (presave) {
        presave
            ? this.bizipixService.savePix(this.board.getCurrentBizipix(), presave)
            : this.bizipixService.savePix(this.board.getCurrentBizipix());
        this._resetChangedBizipixValue();
        this.uploadNewBizipix = false;
    };
    BizipixEditorComponent.prototype.isBoardExist = function () {
        return typeof this.board !== 'undefined';
    };
    return BizipixEditorComponent;
}(UnsubscribeOnDestroyAbsctractClass));
export { BizipixEditorComponent };
