import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
var PasswordFieldComponent = /** @class */ (function () {
    function PasswordFieldComponent(translate) {
        this.translate = translate;
        this.forInput = '';
        this.classLabel = 'input__label clearfix';
        this.classInput = 'input__pass';
        this.classShowPasswordPosition = 'show_password__position';
        this.isPassVisible = false;
        this.autofocusField = false;
        this.password = '';
        this.placeholderInput = this.translate.instant('common.password.placeholderInput');
        this.bluredField = new EventEmitter();
        this.keyUpField = new EventEmitter();
    }
    Object.defineProperty(PasswordFieldComponent.prototype, "value", {
        // get accessor
        get: function () {
            return this.password;
        },
        // set accessor including call the onchange callback
        set: function (v) {
            if (v !== this.password) {
                this.password = v;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PasswordFieldComponent.prototype, "changePassVisibility", {
        set: function (isPassVisible) {
            this.isPassVisible = isPassVisible;
        },
        enumerable: true,
        configurable: true
    });
    PasswordFieldComponent.prototype.onFieldBlurred = function (event) {
        this.bluredField.emit(event);
    };
    PasswordFieldComponent.prototype.onInputTrimed = function (event) {
        event.target['value'] = event.target['value'].trim();
        this.keyUpField.emit(event);
    };
    return PasswordFieldComponent;
}());
export { PasswordFieldComponent };
