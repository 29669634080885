
        <div class="tag-container">
            <div class="ng-resizable dots" [attr.data-test-id]="'tag_' + context.item_id" [id]="context.item_id"
                 ngDraggable [handle]="content" (started)="onFocus()" (stopped)="onStop()"

                 ngResizable
                 (resizeStart)="onStart()"
                 (resizeEnd)="onEnding()"
                 [directions]="['bottom', 'top', 'left', 'right', 'top-left', 'bottom-left', 'top-right', 'bottom-right']"
                 [minWidth]="20" [minHeight]="20"
                 [width]="context.width - DOUBLE_MARGIN" [height]="context.height - DOUBLE_MARGIN"
                 [x]="0" [y]="0">

                <div #content class="ng-resizable__content">{{context.item_title}}</div>

            </div>

            <div *ngIf="isTrash" #trash class="tag__trash" (click)="onRemove()">
                <i class="icon-trash-empty" [attr.data-test-id]="'tag_trash_' + context.item_id"></i>
            </div>
        </div>
    