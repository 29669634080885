import { Component, OnInit }    from '@angular/core';
import { Location }             from '@angular/common';


@Component({
    selector: 'settings-header',
    styleUrls:  ['../../assets/styles/app-header.sass'],
    template: `
        <header class="app-header settings__app-header" [ngClass]="{'about-header' : locationSettings==='/about' }">
            <nav class="app-header__container">

                <div class="navbar__left">
                    <button (click)="location.back()" class="nav__home-link" data-test-id="backLink" type="button">
                        <span *ngIf="locationSettings ==='/settings'" class="icon-home"></span>
                        <span
                           *ngIf="locationSettings ==='/about' || locationSettings ==='/faq'"
                           role="arrow"
                        ><i class="icon-custom_back_icon"></i></span>
                    </button>
                </div>

                <div class="navbar__center">
                    <h4 *ngIf="locationSettings ==='/settings'"  data-test-id="settingsTitle">{{ "settings.header.settings" | translate }}</h4>
                    <h4 *ngIf="locationSettings ==='/about'"     data-test-id="aboutTitle">{{ "settings.header.about" | translate }}</h4>
                    <h4 *ngIf="locationSettings ==='/faq'"       data-test-id="faqTitle">{{ "settings.header.faq" | translate }}</h4>
                </div>

                <div class="navbar__right">
                </div>

            </nav>
        </header>
    `
})

export class SettingsHeaderComponent implements OnInit {

    location:               Location;
    locationSettings:       string;

    constructor(
        location:           Location,
    ) {
        this.location = location;

    }
    ngOnInit() {
        this.locationSettings = decodeURIComponent( window.location.pathname);
    }

}
