import { Subject } from 'rxjs/Subject';
var ModalService = /** @class */ (function () {
    function ModalService() {
        /**
         * @example
         * - show an image:
         * this.modalService.showImage("https://bizibazapics.s3.amazonaws.com/SA1/148136152005080520165418.jpg");
         *
         * - close the modal window
         * this.modalService.close();
         *
         * - show warning and handle users choice
         * this.modalService.
         *      .warning({
         *           title:          'Hello!',
         *           message:        'Do you like this modal?',
         *           yesButtonText:  'Yes',
         *           noButtonText:   'Probably',
         *       })
         * .then(action => console.log('User said: ', action));
         */
        this.showModal$ = new Subject();
        this.currentModalType = 'blank';
    }
    Object.defineProperty(ModalService.prototype, "modalType", {
        /**
         * Getter for type of currently displayed modal.
         * @returns {string}
         */
        get: function () {
            return this.currentModalType;
        },
        /**
         * Setter for type of currently displayed modal.
         * @param type
         */
        set: function (type) {
            this.currentModalType = type;
        },
        enumerable: true,
        configurable: true
    });
    ModalService.prototype.close = function () {
        this.showModal$.next({
            context: {},
            type: 'blank'
        });
    };
    ModalService.prototype.success = function (options) {
        return this._showDialog(Object.assign(options, { status: 'ok' }));
    };
    ModalService.prototype.warning = function (options) {
        return this._showDialog(Object.assign(options, { status: 'warning' }));
    };
    ModalService.prototype.error = function (options) {
        return this._showDialog(Object.assign(options, { status: 'error' }));
    };
    ModalService.prototype.dialog_question = function (options) {
        return this._showDialog(Object.assign(options, { status: 'dialog_question' }));
    };
    ModalService.prototype.filter_modal = function (options) {
        return this._showFilter(Object.assign(options, { status: 'filter_modal' }));
    };
    ModalService.prototype._showDialog = function (context) {
        this.showModal$.next({
            type: 'dialog',
            context: context
        });
        return new Promise(function (resolve) {
            context.handler = function (arg) { return resolve(arg); };
        });
    };
    ModalService.prototype._showFilter = function (context) {
        this.showModal$.next({
            type: 'filter',
            context: context
        });
        return new Promise(function (resolve) {
            context.handler = function (arg) { return resolve(arg); };
        });
    };
    ModalService.prototype.showCategorySelector = function (categories) {
        var context = {
            data: categories,
            handler: null
        };
        this.showModal$.next({
            type: 'category',
            context: context
        });
        return new Promise(function (resolve) {
            context.handler = function (arg) { return resolve(arg); };
        });
    };
    ModalService.prototype.showImage = function (url) {
        this.showModal$.next({
            type: 'image',
            context: {
                message: url
            }
        });
    };
    ModalService.prototype.showSpinner = function () {
        this.showModal$.next({
            type: 'spinner',
            context: {}
        });
    };
    ModalService.prototype.showUpload = function () {
        this.showModal$.next({
            type: 'upload',
            context: {}
        });
    };
    ModalService.prototype.getCommand = function () {
        return this.showModal$.asObservable();
    };
    return ModalService;
}());
export { ModalService };
