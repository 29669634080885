
        <li class="inventory-list__item"
            [attr.data-id]="item['ID']"
            [attr.data-test-id]="'inventoryItem-'+index"
            (click)="showItem(); $event.stopPropagation()">

            <div
               *ngIf="isSale"
               class="marker__sale"
               [attr.data-test-id]="'saleOnLabel_'+index"
            ></div>
            <div
                *ngIf="isSoldOut"
                class="marker__soldout"
                [attr.data-test-id]="'soldOutLabel_'+index"
            ></div>

            <div class="inventory-list__img">
                <span *ngIf="!item['photoURL']" class="sprite sprite-no_image_icon"></span>
                <img *ngIf="item['photoURL']" [src]="item['photoURL']" [attr.alt]="item['title'] || item['market_title'] + 'product image'">
            </div>

            <section [ngClass]="{'expired': isExpired}">
                <h3 class="title"
                    [attr.data-test-id]="'inventoryItemTitle_'+index">
                    {{item['title'] || item['market_title']}}
                </h3>

                <h4 class="currency" [attr.data-test-id]="'inventoryItemPrice_'+index">{{isPrice / 100 | currency:'USD'}} / {{isUnitsName}}</h4>
                <h4 class="quantity" [attr.data-test-id]="'inventoryItemQuantity_'+index">{{item['qty'] | number}} {{item['qtyUnitName']}}</h4>

                <div
                   *ngIf="item.draft"
                   class="marker__draft"
                   [attr.data-test-id]="'draftLabel_'+index"
                ></div>
                <div
                   *ngIf="isExpired"
                   class="sprite sprite-expired_2x"
                   [attr.data-test-id]="'expiredLabel_'+index"
                ></div>

            </section>

            <div class="inventory-list__remove" [attr.data-test-id]="'removeItem_'+index"
                    (click)="removeItem(); $event.stopPropagation()">

                <i class="icon-trash-empty"></i>
            </div>
        </li>
    