
        <div class="component">

            <dialog-header [title]="interlocutor" (goBackEvent$)="goBack()"></dialog-header>

            <div class="component__container chat-room__container">

                <div class="chat-list no-cope-image">

                    <message-group
                            *ngFor="let group of messageGroups; trackBy: trackByDate"
                            [messageGroup]="group"
                            [formatFullDate]="formatFullDate"
                            [userID]="userID"></message-group>

                    <p *ngIf="currentChat?.interlocutor_anonymized" class="timeblock__time-messages" data-test-id="deleted-user-message">{{ "chatroom.deletedUserMessage" | translate}}</p>

                </div>

                <div class="chat-footer">
                    <span *ngIf="isSending">&nbsp;</span>

                    <safe-textarea
                        *ngIf="!isSending"
                        [isAutofocus]="true"
                        [className]="'chat-message-input'"
                        [name]="'message'"
                        [placeholder]="'Text'"
                        [rows]="1"
                        [value]="userInput"
                        [disabled]="currentChat?.interlocutor_anonymized"
                        [minMessageCharacters]="minMessageCharacters"
                        [maxMessageCharacters]="maxMessageCharacters"
                        (isValidEvent$)="checkIsValid($event)"
                        (keyup)="clickHandler($event)"
                        (changeEvent$)="changeEventHandler($event)" data-test-id="messageInput"></safe-textarea>

                    <button type="button" data-test-id="sendMessage"
                            [className]="!currentChat?.interlocutor_anonymized ? 'send-message' : 'deleted-user send-message'"
                            (click)="sendMessage()" [disabled]="!isValid || currentChat?.interlocutor_anonymized">{{ "chatroom.send" | translate }}</button>
                </div>

            </div>
        </div>
    