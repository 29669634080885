
        <header class="app-header">
            <nav class="app-header__container" role="cart-report">
                
                <div class="navbar__left">
                    <a routerLink="/shopping-list" data-test-id="shoppingLink"
                       class="nav__link"><i class="icon-custom_back_icon"></i></a>
                </div>
    
                <div class="navbar__center">
                    <h4>{{ "header.cart.report" | translate }}</h4>
                </div>
    
                <div class="navbar__right">
                </div>
            </nav>
        </header>
    