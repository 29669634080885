/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./user-header.component";
import * as i6 from "../services/google-analytics.service";
var styles_UserHeaderComponent = [i0.styles];
var RenderType_UserHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserHeaderComponent, data: {} });
export { RenderType_UserHeaderComponent as RenderType_UserHeaderComponent };
function View_UserHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "nav", [["class", "app-header__container"], ["role", "login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h4", [["class", "navbar__center text-center"], ["role", "login"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "navbar__right"], ["role", "login"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("user.header.login")); _ck(_v, 4, 0, currVal_0); }); }
function View_UserHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "nav", [["class", "app-header__container signup-header"], ["role", "signup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["class", "nav__link"], ["data-test-id", "loginLink"], ["name", "signup_cancel_button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.clickAnyButtons($event, "sign_up") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "navbar__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "navbar__right"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 5, 0, "/login"); _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("user.header.cancel")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("user.header.signup")); _ck(_v, 10, 0, currVal_2); }); }
function View_UserHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "header", [["class", "app-header change-password__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "nav", [["class", "app-header__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "nav__link"], ["data-test-id", "backLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.location.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [["class", "navbar__center"], ["data-test-id", "changePasswordTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Change Password "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "navbar__right"]], null, null, null, null, null))], null, null); }
function View_UserHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "header", [["class", "app-header change-password__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "nav", [["class", "app-header__container"], ["role", "reset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "navbar__left"], ["role", "reset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "button", [["class", "nav__link"], ["data-test-id", "loginLink"], ["name", "forgot_password_cancel_button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.clickAnyButtons($event, "forgot_password") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "nav__link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Log In"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "navbar__center"], ["role", "reset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "nav__link sprite sprite-bizi_baza_logo_2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "navbar__right"], ["role", "reset"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 5, 0, "/login"); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_UserHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserHeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserHeaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserHeaderComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserHeaderComponent_4)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.locationSearch == "/login"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.locationSearch == "/signup"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.locationSearch == "/change-password"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (((_co.locationSearch == "/password-reset") || (_co.locationSearch == "/email-verification")) || (_co.locationSearch == "/login-link")); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_UserHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-header", [], null, null, null, View_UserHeaderComponent_0, RenderType_UserHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i5.UserHeaderComponent, [i4.Location, i6.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserHeaderComponentNgFactory = i1.ɵccf("user-header", i5.UserHeaderComponent, View_UserHeaderComponent_Host_0, { disabled: "disabled" }, { changeEvent$: "changeEvent$", submit: "submit" }, []);
export { UserHeaderComponentNgFactory as UserHeaderComponentNgFactory };
