/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reviews.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./reviews-header.component.ngfactory";
import * as i3 from "./reviews-header.component";
import * as i4 from "../modal/modal.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./reviews.component";
import * as i8 from "../user/user.service";
import * as i9 from "./reviews.service";
import * as i10 from "../app-footer/app-footer.service";
import * as i11 from "@ngx-translate/core";
var styles_ReviewsComponent = [i0.styles];
var RenderType_ReviewsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReviewsComponent, data: {} });
export { RenderType_ReviewsComponent as RenderType_ReviewsComponent };
export function View_ReviewsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "component reviews-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "reviews-header", [], null, [[null, "changeEvent$"], [null, "goToCreateReview$"], [null, "backToHomeEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changeEvent$" === en)) {
        var pd_0 = (_co.onOptionsChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("goToCreateReview$" === en)) {
        var pd_1 = (null !== false);
        ad = (pd_1 && ad);
    } if (("backToHomeEvent$" === en)) {
        var pd_2 = (_co.backToHome() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ReviewsHeaderComponent_0, i2.RenderType_ReviewsHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i3.ReviewsHeaderComponent, [i4.ModalService, i1.Injector, i5.Router, i6.Location], { reviewsAllFilters: [0, "reviewsAllFilters"], reviewsAllFiltersSubject: [1, "reviewsAllFiltersSubject"], isAvailableFilter: [2, "isAvailableFilter"], isAvailableTabs: [3, "isAvailableTabs"], userTitle: [4, "userTitle"] }, { changeEvent$: "changeEvent$", backToHomeEvent$: "backToHomeEvent$", goToCreateReview$: "goToCreateReview$" }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i6.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(6, 16777216, [[1, 3], ["container", 1]], null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reviewsAllFilters; var currVal_1 = _co.reviewsAllFiltersSubject; var currVal_2 = _co.isAvailableFilter; var currVal_3 = _co.isAvailableTabs; var currVal_4 = _co.userTitle; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = _co.heightContainer(); _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = (_co.isAvailableFooter && "review-container"); _ck(_v, 4, 0, currVal_5); }); }
export function View_ReviewsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reviews", [], null, null, null, View_ReviewsComponent_0, RenderType_ReviewsComponent)), i1.ɵdid(1, 114688, null, 0, i7.ReviewsComponent, [i1.ComponentFactoryResolver, i8.UserService, i9.AppReviewsService, i10.AppFooterService, i4.ModalService, i5.Router, i11.TranslateService, i1.ChangeDetectorRef, i6.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReviewsComponentNgFactory = i1.ɵccf("reviews", i7.ReviewsComponent, View_ReviewsComponent_Host_0, {}, {}, []);
export { ReviewsComponentNgFactory as ReviewsComponentNgFactory };
