
        <div class="component analytics-component">
            <analytics-header [analyticsUrl]="'/orders'" (goBackEvent$)="goBack()"></analytics-header>
            <ul class="orders-menu" data-test-id="orders-menu">
                <button class="orders-menu__item" data-test-id="history" type="button" (click)="goTo($event.target)">
                    {{ "analytics.header.history" | translate }}
                </button>
                <button *ngIf="mode === 'seller'" type="button" class="orders-menu__item" data-test-id="pending-orders"
                        (click)="goTo($event.target)">
                    {{ "analytics.header.pendingOrders" | translate }}
                </button>
            </ul>
        </div>
    