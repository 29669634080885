import { Injectable }               from '@angular/core';
import { Observable }               from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

import { AppSettings }              from '../common/app.settings';
import { environment }              from '../../environments/environment';
import { ErrorService }             from './error.service';
import {
    HttpClient,
    HttpHeaders
} from "@angular/common/http";
import {HttpErrorResponse, HttpResponseBase} from "@angular/common/http/src/response";
import {Response} from "@angular/http";


@Injectable()
export class DataService {

    private delayTime   = 0;

    constructor(
        private errorService:   ErrorService,
        private http:           HttpClient,
    ) {
        if (environment['envName'] !== 'prod') {
            this.delayTime = 300;
        }
    }


    public getData(url: string|Request, headers: {} = {}): Observable<any> {
        if (this._isInvalidUrl(url)) {
            return this._badUrlError(url) ;
        }

        const queryString: string = AppSettings.BASE_URL + url;
        // window.console.info(`REQUEST: ${queryString}`);

        return this.http
            .get(queryString, { headers: this.getHeaders(headers) })
            .retry(1)
            .delay(this.delayTime)
            .map((res: HttpResponseBase) => this._logger(res, queryString))
            .map(this._extractData)
            .catch((err: HttpErrorResponse) => this.errorService.handleHttpError(err));
    }


    public postData(url: string, body: {}, headers: {} = {}): Observable<any> {
        if (this._isInvalidUrl(url)) {
            return this._badUrlError(url) ;
        }

        const queryString: string   = AppSettings.BASE_URL + url;
        const _body: string         = JSON.stringify(body);

        return this._postData(queryString, _body, headers);
    }

    public putData(url: string, body: {}, headers: {}) {
        if (this._isInvalidUrl(url)) {
            return this._badUrlError(url) ;
        }

        const queryString: string = AppSettings.BASE_URL + url;
        const _body: string       = JSON.stringify(body);

        return this.http
            .put(queryString, _body, {
                headers: this.getHeaders(headers)
            })
            .retry(1)
            .delay(this.delayTime)
            .map((res: HttpResponseBase) => this._logger(res, url))
            .map(this._extractData)
            // .map((res: Response) => {
            //     console.log('DataService#POST.response: ', res);
            //     return res;
            // })
            .catch((err: HttpErrorResponse) => this.errorService.handleHttpError(err));
    }

    public deleteData(url: string, headers: {}) {
        if (this._isInvalidUrl(url)) {
            return this._badUrlError(url) ;
        }

        const queryString: string = AppSettings.BASE_URL + url;

        return this.http
            .delete(queryString, {
                headers: this.getHeaders(headers)
            })
            .retry(1)
            .delay(this.delayTime)
            .map((res: HttpResponseBase) => this._logger(res, url))
            .map(this._extractData)
            .catch((err: HttpErrorResponse) => this.errorService.handleHttpError(err));
    }

    public uploadToAWS(body: {}, headers: {} = {}) {
        const url = AppSettings.AWS_UPLOAD_URL;
        return this._postData(url, body, headers);
    }

    public testPost(url: string, body: {}, headers: {} = {}) {
        return this.postData(url, body, headers);
    }

    /**
     * @desc Needed after migration to Swagger 3.0
     * @param headers
     * @returns {Headers}
     * @private
     */
    private getHeaders(headers: {}): HttpHeaders {
        const _headers = new HttpHeaders(headers);
        _headers.append('Accept', 'application/json');

        return _headers;
    }

    private _postData(url: string, body: {}, headers: {}) {
        return this.http
            .post(url, body, {
                headers: this.getHeaders(headers)
            })
            .retry(1)
            .delay(this.delayTime)
            .map((res: HttpResponseBase) => this._logger(res, url))
            .map(this._extractData)
            // .map((res: Response) => {
            //     console.log('DataService#POST.response: ', res);
            //     return res;
            // })
            .catch((err: HttpErrorResponse) => this.errorService.handleHttpError(err));
    }

    public getLocalData(url: string): any {
        const queryString = AppSettings.DOMAIN_URL + url;

        return this.http
            .get(queryString)
            .map((res: HttpResponseBase) => this._logger(res, queryString))
            .map(this._extractData)
            .catch((err: HttpErrorResponse) => this.errorService.handleHttpError(err));
    }

    private _extractData(res: HttpResponseBase): HttpResponseBase | {} {
        return res || {};
    }

    private _logger(data: HttpResponseBase, url: string): HttpResponseBase {
        // console.group();
        //     console.log('DataService Request: ', url);
        //     console.log('DataService Response: ', data);
        // console.groupEnd();

        return data;
    }

    private _isInvalidUrl(url: string | Request) {
        return url === '' || typeof url === 'undefined' || typeof url !== 'string';
    }

    private _badUrlError(url: string | Request): Observable<never> {
        return this.errorService.handleHttpError({
            status: 404,
            statusText: `Bad url string: ${url}`
        } as HttpErrorResponse);
    }
}
