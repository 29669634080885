/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../search.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./event-search.component";
import * as i4 from "../../product-editor/product-editor.service";
var styles_EventSearchComponent = [i0.styles];
var RenderType_EventSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventSearchComponent, data: {} });
export { RenderType_EventSearchComponent as RenderType_EventSearchComponent };
function View_EventSearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "li", [["class", "abstract-list__li market-list__li"]], [[1, "data-id", 0], [1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "line__address"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "go-to"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "icon-right-open"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit["ID"]; var currVal_1 = ("abstractListProduct_" + _v.context.index); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.market_title; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.address.address1; _ck(_v, 6, 0, currVal_3); var currVal_4 = _v.context.$implicit.address.city; _ck(_v, 9, 0, currVal_4); var currVal_5 = _v.context.$implicit.address.state; _ck(_v, 12, 0, currVal_5); var currVal_6 = _v.context.$implicit.address.postCode; _ck(_v, 14, 0, currVal_6); }); }
export function View_EventSearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "abstract-list__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "abstract-list__ul"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventSearchComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; var currVal_1 = _co.trackByID; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_EventSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "event-search-list", [], null, null, null, View_EventSearchComponent_0, RenderType_EventSearchComponent)), i1.ɵdid(1, 49152, null, 0, i3.EventSearchComponent, [i1.ElementRef, i4.ProductEditorService], null, null)], null, null); }
var EventSearchComponentNgFactory = i1.ɵccf("event-search-list", i3.EventSearchComponent, View_EventSearchComponent_Host_0, { items: "items", title: "title", search_from: "search_from" }, {}, []);
export { EventSearchComponentNgFactory as EventSearchComponentNgFactory };
