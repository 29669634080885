<div class="component reviews-component">
    <reviews-header (submit)="save()" [disabled]="busy" [createReviewHeaderTitle]="title"></reviews-header>

    <div class="reviews__container create-reviews__container">
        <div class="reviews-component__header" [style.background-image]="'url('+ selected_order.url +')'">
            <div class="review-header__bottom">
                <div class="review-header__content">
                    <h4 class="title">{{title}} {{ "review.create.name" | translate }}</h4>
                    <h4 (click)="showAvailableReviews()" data-test-id="showSellerReviews" class="count-review">{{selected_order.title}}
                        <span data-test-id="countOfList"> ( {{count_list}} )</span></h4>
                </div>
            </div>
        </div>
        <form [formGroup]="context.reviewForm" (ngSubmit)="save()">
           <rating
              class="review__starInput"
              data-test-id="reviewStars"
              [rating]="stars"
              [ratingWrite]="true"
              required
              formControlName="stars"
           ></rating>
           <textarea
              id="review"
              class="review__textarea"
              placeholder="Your review on ..."
              name="text"
              required
              autofocus
              draggable="false"
              data-test-id="reviewInputCom"
              formControlName="text"
              (keypress)="resizeReviewTextarea($event)"
              (keyup)="resizeReviewTextarea($event)"
              (click)="resizeReviewTextarea($event)"
              (keydown)="resizeReviewTextarea($event)"
              [attr.maxLength]="textareaLength"
           ></textarea>
            <span class="countdown" data-test-id="CharactersCountdown">{{ translate.instant("review.countdown", {countdown: textareaLength - context.reviewForm.value.text.length}) }}</span>
        </form>
    </div>
</div>
