import {FullTransactionInterface} from './analytics.interface';
import {Order, OrderElement, Transaction} from '../../../swagger-gen__output_dir';
import {OrdersAppService} from './orders.service';

export class AdapteeFullTransaction {
    public transactions: FullTransactionInterface[];
    public constructor(transactions: Transaction[], ordersService: OrdersAppService) {
        // @ts-ignore
        this.transactions = transactions.map((transaction: Transaction) => {

            transaction['transaction_total_price'] = ordersService.transactionTotalPrice(transaction.elements);
            transaction['transaction_discount'] = ordersService.transactionDiscount(transaction.elements);

            transaction.elements.forEach((o: Order) => {
                o.elements.forEach((transactionElement: OrderElement) => {
                    transactionElement['pick_up_address_str']
                        = ordersService.formatPickUpAddress(transactionElement.pick_up_address);
                    transactionElement['provider_address_name']
                        = ordersService.formatPickUpAddress(transactionElement.pick_up_address);
                });
            });

            return transaction;
        });
    }

}
