import {AfterViewInit, Component, ElementRef, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'switch',
    template: `
        <div class="switch__container text-center" (click)="toggleState($event)">
            <button data-test-id="SwitchShopper" class="switch__button left-item">buyer</button>
            <button data-test-id="SwitchShopperSeller" class="switch__button right-item">buyer &amp; seller</button>
        </div>
    `
})

export class SwitchComponent implements AfterViewInit {

    elem: Element;
    buttons: NodeListOf<Element>;

    @Output() type: EventEmitter<string> = new EventEmitter<string>();

    constructor(private elementRef: ElementRef) { }

    ngAfterViewInit() {
        this.elem = this.elementRef.nativeElement;

        this.buttons = this.elem.querySelectorAll('.switch__button');
        this.buttons[1].classList.add('active');
    }

    toggleState(event: Event) {
        const className = 'active';
        let target = (event.target as any);
        let isActive = target.classList.contains('active');

        if (!isActive) {
            let text = target.textContent.match(/\w+$/i);
            if (text) {
                this.type.emit(text[0]);

                (this.buttons as any).forEach((item: Element) => {
                    item.classList.toggle(className);
                });
            }
        }
    }

}
