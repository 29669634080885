import { Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { GoodsNavService } from '../goods-nav/goods-nav.service';
import { ModalService } from '../modal/modal.service';
import { UserService } from '../user/user.service';
import { ItemService } from '../services/item.service';
import AppValues from '../common/app.values';
import { LocalCaching, LocalCachingCreatorMapping } from '../local-caching/local-caching-factory';
import { CountersService } from '../../../swagger-gen__output_dir/api/counters.service';
import { Caretaker, Originator } from '../services/memento.service';
export var UserMode;
(function (UserMode) {
    UserMode["buyerMode"] = "buyer";
    UserMode["sellerMode"] = "seller";
})(UserMode || (UserMode = {}));
export var CategoryTreeTypes;
(function (CategoryTreeTypes) {
    CategoryTreeTypes["shortNavTree"] = "get_short_navigation_tree";
    CategoryTreeTypes["fullNavTree"] = "get_navigation_tree";
})(CategoryTreeTypes || (CategoryTreeTypes = {}));
var AppFooterService = /** @class */ (function () {
    function AppFooterService(dataService, countersService, errorService, goodsNavService, modalService, itemService, localCaching, injector) {
        this.dataService = dataService;
        this.countersService = countersService;
        this.errorService = errorService;
        this.goodsNavService = goodsNavService;
        this.modalService = modalService;
        this.itemService = itemService;
        this.localCaching = localCaching;
        this.injector = injector;
        this.mode = UserMode.buyerMode;
        this.modeChanges$ = new Subject();
        this.navigationTreeCategories = [];
        this.shortNavigationTreeCategories = [];
        /** For using LocalCaching class
        * instance fields - Creators subclasses typically provide an implementation of this method.
        * categories fields:
        * get_navigation_tree - contained in all categories contained in the proposals
        * and with which they can create products. Using for seller mode.
        * get_short_navigation_tree - only sub categories with which products have already
        * been created are saved. Using for shopper mode.
        **/
        this.appFooterMapping = {
            get_navigation_tree: {
                instance: LocalCachingCreatorMapping.ConcreteNavigationTreeCaching,
                categories: this.navigationTreeCategories
            },
            get_short_navigation_tree: {
                instance: LocalCachingCreatorMapping.ConcreteShortNavigationTreeCaching,
                categories: this.shortNavigationTreeCategories
            }
        };
        this.subscribeOnUserAuthEvent();
        this.modeOriginator = new Originator(this.mode);
        this.modeCaretaker = new Caretaker(this.modeOriginator);
    }
    Object.defineProperty(AppFooterService.prototype, "user_service", {
        get: function () {
            return this.injector.get(UserService);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * save Mode into Momento
     * @param {string} mode
     */
    AppFooterService.prototype.setModeOriginator = function (mode) {
        this.modeOriginator.setState(mode);
        this.modeCaretaker.backup();
    };
    AppFooterService.prototype.removeModeOriginator = function () {
        this.modeCaretaker.undo();
    };
    /**
     * return new Observable wrapper to AppFooterComponent's constructor
     * @return {Observable<null>}
     */
    AppFooterService.prototype.getUsersData = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.user_service.userAuthEvent$
                .subscribe(function () {
                _this.setMode(UserMode.buyerMode);
                observer.next();
                observer.complete();
            });
        });
    };
    Object.defineProperty(AppFooterService.prototype, "onModeChanges", {
        /**
         * @desc Returns subscription on user mode changes as Observable
         * @return {Observable<string>}
         */
        get: function () {
            return this.modeChanges$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Delegates call to GoodsNavService.
     * @param category
     * @param isSeller
     * @param isSheet
     */
    AppFooterService.prototype.showNavigation = function (category, isSeller, isSheet) {
        this.goodsNavService.showNavigation(category, isSeller, isSheet);
    };
    /**
     * Setter for AppFooter mode (seller\buyer).
     * @returns {string}
     */
    AppFooterService.prototype.getMode = function () { return this.mode; };
    AppFooterService.prototype.isSellerMode = function () {
        return this.getMode() === UserMode.sellerMode;
    };
    AppFooterService.prototype.getAccessLevel = function () {
        this.accessLevel = this.user_service.getUser().accessLevel;
        return this.accessLevel;
    };
    /**
     * Getter for AppFooter mode (seller\buyer).
     * @param mode
     */
    AppFooterService.prototype.setMode = function (mode) {
        var _accessLevel = this.getAccessLevel();
        this.mode = _accessLevel > 1 ? mode : UserMode.buyerMode;
        this.modeChanges$.next(this.mode);
        return this.mode;
    };
    AppFooterService.prototype.getCounts = function () {
        var _this = this;
        var token = this.user_service.getUserSession().token;
        if (token) {
            return this.countersService.getCountersForUserGet(token)
                .do(function (counters) {
                _this.counters = counters;
                return counters;
            })
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({});
    };
    /**
     * @desc Decides to query for short or full navigation tree data
     * via DataService. Sorts fetched subcategories and their entries,
     * calling #_sortResponse.
     * @returns {Observable<R|T>}
     * @param isFullNavTree
     */
    AppFooterService.prototype.getNavTree = function (isFullNavTree) {
        var url = (isFullNavTree)
            ? CategoryTreeTypes.fullNavTree
            : CategoryTreeTypes.shortNavTree;
        return this.setExistingCategory(this.appFooterMapping[url].instance, url);
    };
    AppFooterService.prototype.getCategories = function (url) {
        var _this = this;
        var token = this.user_service.getToken();
        if (!token) {
            return Observable.of([]);
        }
        return this.dataService.getData(url, { token: token })
            .map(function (response) {
            _this.categories = _this._sortResponse(response['categories']);
            _this.itemService.setCategory(_this.categories);
            _this.localCaching.setCache(_this.appFooterMapping[url].instance, _this.categories);
            _this.appFooterMapping[url].categories = _this.categories;
            return _this.categories;
        })
            .catch(function (err) { return _this.errorService.handleError(err); });
    };
    AppFooterService.prototype.clearShortNavigationTree = function () {
        this.appFooterMapping[CategoryTreeTypes.shortNavTree].categories = [];
        this.localCaching.clearAllCache(this.appFooterMapping[CategoryTreeTypes.shortNavTree].instance);
    };
    AppFooterService.prototype.setExistingCategory = function (creatorName, url) {
        if (url === CategoryTreeTypes.shortNavTree) {
            return this.getCategories(url);
        }
        if (url === CategoryTreeTypes.fullNavTree) {
            if (this.appFooterMapping[url].categories.length === 0) {
                return this.getFullNavTree(creatorName);
            }
            else {
                this.categories = this.appFooterMapping[url].categories;
                return Observable.of(this.categories);
            }
        }
    };
    AppFooterService.prototype.getFullNavTree = function (creatorName) {
        if (this.localCaching.isExistCache(creatorName)) {
            this.categories = this.localCaching.getOneCache(creatorName, []);
            return Observable.of(this.categories);
        }
        else {
            return this.getCategories(CategoryTreeTypes.fullNavTree);
        }
    };
    AppFooterService.prototype.getCachedTree = function () {
        return this.categories;
    };
    /**
     * @desc Iterating over each of the category, sorts containing
     *       subcategories and their entries by their names alphabetically.
     *
     *       According to meeting solution on 4/28/2020, we should sort
     *       just sub categories and sub categories entries.
     *       Categories must be unsorted!
     * @param categories
     * @returns {CategoryInterface[]}
     * @private
     */
    AppFooterService.prototype._sortResponse = function (categories) {
        categories.forEach(function (cat) {
            AppValues.sortItems(cat.sub_categories, 'sub_category_name');
            cat.sub_categories.forEach(function (sc) {
                AppValues.sortItems(sc.sub_category_entries, 'subcategory_entry_name');
            });
        });
        return categories;
    };
    /**
     * @desc Subscribes on UserAuthEvent$
     * @private
     */
    AppFooterService.prototype.subscribeOnUserAuthEvent = function () {
        var _this = this;
        this.user_service.userAuthEvent$
            .subscribe(function () {
            _this.setMode(UserMode.buyerMode);
        });
    };
    return AppFooterService;
}());
export { AppFooterService };
