import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit
} from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators
} from "@angular/forms";

import { Subject } from "rxjs";
import { Observable } from "rxjs/Observable";

import { TranslateService } from "@ngx-translate/core";

import { environment } from "../../environments/environment";
import AppValues from "../common/app.values";
import { CenterLocationInterface } from "../interfaces/location.interface";
import { DOMElement } from "../interfaces/misc.interface";
import {
    SignupFormInterface,
    UserBaseInterface,
    ValidationSignupFormI
} from "../interfaces/user.interface";
import { ModalService } from "../modal/modal.service";
import { ErrorInterface } from "../services/error.service";
import { GeoCoderService } from "../services/geocoder.service";
import { GeoLocationService } from "../services/geolocation.service";
import { GoogleAnalyticsService } from "../services/google-analytics.service";
import {
    antiPatternMatcher,
    duplicate,
    duplicateLogin,
    duplicatePassword
} from "./helpers/_helpers";
import { UserService } from "./user.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { getErrorType } from "../services/error.helpers";

@Component({
    selector:       'signup',
    // animations:     [fadeInAnimation],
    // host:           { '[@fadeInAnimation]': '' },

    templateUrl:    './helpers/signup.component.html'
})
export class SignupComponent implements OnInit, AfterViewInit {

    public accessLevel =  2;

    public formErrors = {
        'firstName':    '',
        'lastName':     '',
        'title':        '',
        'login':        '',
        'login2':       '',
        'password':     '',
        'password2':    '',
        'email':        '',
        'phone':        '',
        'address1':     '',
        'address2':     '',
        'city':         '',
        'state':        '',
        'postCode':     '',
        'country':      '',
        'checkboxTerms': '',
        'checkboxYearsOld': ''
    };

    public formValues: SignupFormInterface;

    public validationMessages: ValidationSignupFormI;

    public busy:                   boolean;
    public checkingAnaliticsObj    = {};
    public componentDestroyed$:    Subject<boolean> = new Subject();
    public containerElem:          HTMLElement;
    public countries =             AppValues.countries;
    public countryValue:           string;
    public countryErr =            false;
    public countrySelect:          any;
    public defaultCountry          = 'United States';
    public elem:                   DOMElement;
    public firstCountryChanging    = false;
    public headerElem:             HTMLElement;
    public location =              {longitude: 0, latitude: 0} as CenterLocationInterface;
    public modalSubject            = new Subject<boolean>();
    public signupForm:             FormGroup;
    public states =                AppValues.states;
    public stateErr =              false;
    public stateSelect:            any;
    public isSuccess =             false;
    public warningMsg:             string;
    public signupSuccessText: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant('signup.successReport.body.text'))
    public termConditionsLink =    `${environment.apiUrl.split('/api/v1/')[0]}/terms-and-conditions`;

    private primevalItem = new Subject<SignupFormInterface>();
    public primevalItem$ = this.primevalItem.asObservable();
    public change_item: boolean = false;

    constructor(
        private fb:                         FormBuilder,
        private elementRef:                 ElementRef,
        private geoLocationService:         GeoLocationService,
        private geoCoderService:            GeoCoderService,
        private googleAnalyticsService:     GoogleAnalyticsService,
        private modalService:               ModalService,
        private userService:                UserService,
        private sanitizer:                  DomSanitizer,
        public  translate:                  TranslateService
    ) { }

    private setSignupFormValidationMessages () {
        this.validationMessages = {
            firstName: {
                required: this.translate.instant('signup.firstName.required'),
                pattern: this.translate.instant('signup.firstName.pattern'),
            },
            lastName: {
                required: this.translate.instant('signup.lastName.required'),
                pattern: this.translate.instant('signup.lastName.pattern'),
            },
            title: {
                pattern: this.translate.instant('signup.title.pattern'),
                placeholder: this.isSellerType ? this.translate.instant("signup.title.seller.placeholder") : this.translate.instant("signup.title.buyer.placeholder"),
                maxlength: this.translate.instant('signup.title.error.maxlength', {userTypeName: AppValues.getPlaceholderCompanyBuyerName(this.accessLevel)})
            },
            login: {
                required: this.translate.instant('signup.login.required'),
                pattern: this.translate.instant("signup.login.hint"),
                maxlength: this.translate.instant("signup.login.hint"),
                used: this.translate.instant('signup.error.loginIsAlreadyInUse'),
                mismatched: this.translate.instant('signup.login.mismatched'),
                placeholder: this.translate.instant("signup.login.placeholder")
            },
            login2: {
                required: this.translate.instant('signup.login2.required'),
                pattern: this.translate.instant("signup.login.hint"),
                maxlength: this.translate.instant("signup.login.hint"),
                used: this.translate.instant('signup.error.loginIsAlreadyInUse'),
                mismatched: this.translate.instant('signup.login.mismatched'),
                placeholder: this.translate.instant("signup.login2.placeholder")
            },
            password: {
                required: this.translate.instant("signup.password.error.required"),
                pattern: this.translate.instant("signup.hint.password"),
                mismatched: this.translate.instant("password.error.notMatch")
            },
            password2: {
                required: this.translate.instant("signup.password2.error.required"),
                pattern: this.translate.instant("signup.hint.password"),
                mismatched: this.translate.instant('password.error.notMatch')
            },
            email: {
                required: this.translate.instant('signup.email.error.required'),
                pattern: this.translate.instant('signup.hint.email'),
                used: this.translate.instant('signup.email.error.used')
            },
            phone: {
                required: this.translate.instant('signup.phone.required'),
                pattern: this.translate.instant('signup.phone.pattern'),
            },
            address1: {
                required: this.translate.instant('signup.address1.required'),
                pattern: this.translate.instant('signup.address1.pattern'),
            },
            address2: {
                pattern: this.translate.instant('signup.address2.pattern')
            },
            city: {
                required: this.translate.instant('signup.city.required'),
                pattern: this.translate.instant('signup.city.pattern')
            },
            state: {
                required: this.translate.instant('signup.state.required'),
                pattern: this.translate.instant('signup.state.pattern')
            },
            postCode: {
                required: this.translate.instant("signup.error.postalCode.required"),
                pattern: this.translate.instant('signup.postCode.pattern')
            },
            country: {
                required: this.translate.instant('signup.country.required'),
                pattern: this.translate.instant('signup.country.pattern')
            },
            checkboxTerms: {
                required: this.translate.instant('signup.checkboxTerms.required')
            },
            checkboxYearsOld: {
                required: this.translate.instant('signup.checkboxYearsOld.required')
            }
        };
    }

    public ngOnInit(): void {
        this.setSignupFormValidationMessages();
        this.buildForm();
        this.countryValue = this.signupForm.controls['country'].value;
        this.elem = this.elementRef.nativeElement;

        this.geoLocationService.locationEvent$.subscribe((res) => {
            this.location = res;
        });

        this.primevalItem$.subscribe(item => {
            this.checkObjectChanges(item, this.formValues);
        });

        this.formValues = this.userControlsValue;
        this.set_changed_item(this.formValues);
        setTimeout(() => this.setSignupFormValidationMessages(), 1);
    }

    get userControlsValue() {
        return this.adapterUserValue(this.signupForm.controls);
    }

    get isSellerType(): boolean {
        return this.accessLevel === 2;
    }

    adapterUserValue(controls: any): SignupFormInterface {
        let signupForm = <SignupFormInterface> new Object;

        for (const key in controls) {
            signupForm[key] = controls[key].value;
        }

        return signupForm;
    }

    public ngAfterViewInit(): void {
        let el  = this.elem;
        let inp = el.querySelector('input[name="firstName"]');
        inp && inp.focus();

        this.containerElem  = el.querySelector('.signup__container');
        this.headerElem     = el.querySelector('.signup__header');

        // prettifying the selects
        this.countrySelect = el.querySelector('select[name="country"]');
        this.stateSelect   = el.querySelector('select[name="state"]');

        let o = this.countrySelect.querySelectorAll('option');
        let s = this.stateSelect.querySelectorAll('option');

        if (s) {
            o[0].setAttribute('disabled', true);
            o[0].setAttribute('selected', true);

            s[0].setAttribute('disabled', true);
            s[0].setAttribute('selected', true);
        }
    }

    _signupAdditionalGA(data?: {}) {
        if ( data ) {
            for (const key in this.signupForm.controls) {
                if (this.checkingAnaliticsObj[key] === '') this.checkingAnaliticsObj[key] = data[key];

                if (this.checkingAnaliticsObj[key] && this.checkingAnaliticsObj[key] !== '') {
                    this._sendGAEvents(key);
                }
            }
        } else this._sendGAEvents('countryIsNotUSA');
    }

    _sendGAEvents(key: string) {
        this.googleAnalyticsService.handleEvent('signup_additional', 'signup', key);
        this.googleAnalyticsService.handleVirtualPage('signup_field_' + key);

        delete this.checkingAnaliticsObj[key];
    }


    public updatePasswordField(event: Event, formControlName: string): void {
        let someFormControl: AbstractControl = this.signupForm.get(formControlName);

        someFormControl.setValue(event.target['value']);
        this.signupForm.controls[formControlName].updateValueAndValidity();
        this.blurField(event, formControlName);

        if (event.target['value']) {
            someFormControl.markAsDirty();
            someFormControl.markAsTouched();
        } else {
            someFormControl.markAsPristine();
            someFormControl.markAsUntouched();
        }
    }

    buildForm(): void {
        this.signupForm = this.fb.group({
            'firstName': [
                '', [Validators.required, Validators.pattern(AppValues.namePattern), Validators.minLength(1), Validators.maxLength(20)]
            ],
            'lastName': [
                '', [Validators.required, Validators.pattern(AppValues.namePattern), Validators.minLength(1), Validators.maxLength(20)]
            ],
            'title': [
                '',
                [
                   Validators.required,
                   Validators.minLength(1),
                   Validators.maxLength(100)
                ]
            ],
            'login': [
                '', [Validators.required, Validators.pattern(AppValues.loginPattern), /* antiPatternMatcher(AppValues.charsAntiPattern), */ Validators.minLength(1), Validators.maxLength(100)]
            ],
            'login2': [
                '', [Validators.required, Validators.pattern(AppValues.loginPattern), /* antiPatternMatcher(AppValues.charsAntiPattern), */ Validators.minLength(1), duplicateLogin, Validators.maxLength(100)]
            ],
            'password': [
                '', [Validators.required, Validators.pattern(AppValues.passwordPattern)]
            ],
            'password2': [
                '', [Validators.required, Validators.pattern(AppValues.passwordPattern), duplicatePassword]
            ],
            'email': [
                '', [Validators.required, Validators.pattern(AppValues.emailPattern), /* Validators.pattern(AppValues.spaceTrimPattern) */ ]
            ],
            'phone': [
                '', [Validators.required, Validators.pattern(AppValues.phonePattern)]
            ],
            'address1': [
                '', [Validators.required, antiPatternMatcher(AppValues.charsAntiPattern), Validators.minLength(6), Validators.maxLength(100)]
            ],
            'address2': [
                '', [antiPatternMatcher(AppValues.charsAntiPattern), Validators.maxLength(100)]
            ],
            'city': [
                '', [Validators.required, Validators.pattern(AppValues.cityPattern), Validators.minLength(2)]
            ],
            'state': [
                '', [Validators.pattern(AppValues.statePattern)]
            ],
            'postCode': [
                '', [Validators.required, Validators.pattern(AppValues.codePattern), Validators.minLength(2)]
            ],
            // country validation declined to work. reason unknown
            // Performed custom, see `onCountryChange()`
            'country': [
                this.defaultCountry, // [Validators.required]
            ],
            'checkboxTerms': [
                '', [Validators.required]
            ],
            'checkboxYearsOld': [
                '', [Validators.required]
            ]
        });

        this.busy = true;

        if (this.signupForm.controls) {
            for (const key in this.signupForm.controls) {
                this.checkingAnaliticsObj[key] = '';
            }
        }

        this.signupForm.valueChanges
            .subscribe((data: SignupFormInterface) => {
                this.onValueChanged(data);
                this.set_changed_item(data);
            });

        this.onValueChanged(); // (re)set validation messages now
    }

    public blurField(event: Event, name: string): void {
        let new_value: string = event.currentTarget['value'].trim();
        event.currentTarget['value'] = new_value;
        event.preventDefault();

        this.signupForm.controls[name].setValue(new_value);
        this.signupForm.controls[name].markAsTouched();

        if (name === 'login' || name === 'login2') {
            this.checkValidityLogin();
        }

        let new_data: {[key: string]: string} = this.signupForm.value;
        new_data[name] = new_value;
        this.onValueChanged(new_data);
    }

    private checkValidityLogin(): void {
        if (this.signupForm.controls['login'].dirty
            && this.signupForm.controls['login2'].dirty) {

            this.signupForm.controls['login'].updateValueAndValidity();
            this.signupForm.controls['login2'].updateValueAndValidity();
        }
    }


    onCountryChange(e: Event) {

        let c = this.signupForm.value.country;
        this.countrySelect.classList.remove('error');

        this.countryErr = false;
        this.formErrors.country = '';

        /** Will be call if its a first (and once) changing country field **/
        if (this.firstCountryChanging === false && this.countryValue === this.defaultCountry && this.countryValue !== c) {
            this.firstCountryChanging = true;
            this.checkingAnaliticsObj['countryIsNotUSA'] = c;
            this._signupAdditionalGA();
        }
        /** **/

        if (c === 'Country') {
            this.countryErr = true;
            this.formErrors.country = 'Country required!';
            this.countrySelect.classList.add('error');
        }
    }


    onStateChange(e: Event) {
        let s = this.signupForm.value.state;
        this.stateSelect.classList.remove('error');

        this.stateErr = false;
        this.formErrors.state = '';

        if (s === 'State') {
            this.stateErr = true;
            this.formErrors.state = 'Country required!';
            this.stateSelect.classList.add('error');
        }
    }

    onValueChanged(data?: any) {
        if (!this.signupForm) { return; }

        if (this.signupForm && data) {
            duplicate(this.signupForm.controls);
            this._signupAdditionalGA(data);
            this.busy = false;
        }

        this.warningMsg = '';
        const form = this.signupForm;

        for (const field in this.formErrors) {
            if (field === 'country' || field === 'state') continue;
            // clear previous error message (if any)
            this.formErrors[field] = '';
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];

                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    } // eofn


    onSubmit(event: Event) {
        if (event) {
            event.preventDefault();
        }
        this.modalService.showSpinner();

        this._hideSpinner();
        this.validateManually();
    }


    switchAccessLevel(type: string) {
        switch (type) {
            case 'buyer':
                this.accessLevel = 1;
                break;

            case 'seller':
                this.accessLevel = 2;
                break;

            default:
                this.accessLevel = 2;
        }

        this.setSignupFormValidationMessages();
    }


    validateManually() {
        let x       = this.signupForm.value;
        let get_form = document.querySelectorAll('form')[0];
        let error_field_count = 0;
        let error_field_name = '';

        this.modalService.showSpinner();

        for (let i = 0; i < get_form.length; i++) {
            let name_field = this.signupForm.controls[get_form[i].name];

            if (name_field && (name_field.errors && Object.keys(name_field.errors).length !== 0) && (error_field_count === 0)) {
                error_field_name = get_form[i].name;
                error_field_count++;
            }
        }

        if (error_field_name !== '') {
            this.toggleWarning(error_field_name);
            return;

        } else {
            let user: UserBaseInterface = {
                login:          x.login,
                password:       x.password,
                firstName:      x.firstName,
                lastName:       x.lastName,
                latitude:       this.location.latitude  || 0,
                longitude:      this.location.longitude || 0,
                accessLevel:    this.accessLevel,
                email:          x.email.trim(),
                title:          x.title.trim(),
                webPage:        x.webPage || '',
                imageURL:       '',
                addresses:      [
                    {
                        firstName:  x.firstName,
                        lastName:   x.lastName,
                        address1:   x.address1.trim(),
                        address2:   x.address2.trim(),
                        city:       x.city,
                        postCode:   x.postCode,
                        country:    x.country,
                        isPrimary:  true,
                        state:      x.state,
                        phone:      x.phone

                    }
                ]
            };

            this.addressToCoordinates(this.geoCoderService.getFullAddressString(user.addresses[0])).subscribe((centerLocation: CenterLocationInterface) => {
                    this.prepareUser(user, centerLocation);

            }, (errConvert: string) => {

                if (!this.location) {
                    return new Observable(observer => {
                        this.geoLocationService.geoLocationObserver().subscribe((userLocation: CenterLocationInterface) => {
                            this.location = userLocation;

                            this.prepareUser(user, userLocation);

                        }, (errorLocation: string) => this.modalService.error({message: errorLocation}));
                    });
                } else {
                    this.prepareUser(user, this.location);
                }
            });
        }
    }

    private prepareUser(user: UserBaseInterface, userLocation: CenterLocationInterface) {
        user.latitude = userLocation.latitude;
        user.longitude = userLocation.longitude;

        this.submitUser(user);
    }

    addressToCoordinates(address: string) {
        return new Observable(observer => {
            this.geocodeAddress(address)
                .subscribe((location: CenterLocationInterface) => {
                        this.location = location;
                        observer.next(location);
                    }, (err: string) => observer.error(err)
                );
        });
    }

    geocodeAddress(address: string): Observable<CenterLocationInterface> {
        return new Observable(observer => {
            this.geoCoderService.geocodeAddress(address).subscribe((res: CenterLocationInterface) => {
                this.location = res;

                return observer.next(res);
            }, (err: string) => observer.error(err));
        });
    }


    // TODO need types
    private submitUser(user: UserBaseInterface): void  {
        this.userService
            .signup(user)
            .takeUntil(this.componentDestroyed$)
            .subscribe(
                (response: any) => {
                    this._hideSpinner();
                    this.isSuccess = true;
                },
                (err: ErrorInterface) => {
                    this._hideSpinner();
                    this.userService._reportSignupError({status: err.status, statusText: getErrorType(err)});

                    this.toggleWarning(err.status, getErrorType(err) || JSON.parse(err['_body']).error);
                });
    }

    _hideSpinner() {
        this.modalService.close();
    }


    toggleWarning(key?: string | number, msg?: string) {
        this.onCountryChange(null);
        this.onStateChange(null);

        (key || msg) && this._checkErrorMethod(key, msg);

        this.modalService.error({
            title: this.translate.instant('signup.error.title'),
            message: this.warningMsg,
            yesButtonText: 'Close',
        }).then((action: any) => {
            if (/(?:PayPal)/i.test(msg)) {
                this.elem.querySelector('input[name="ppEmail"]').focus();
            }
            return action;
        });
    }

    _checkErrorMethod(key?: number|string, msg?: string) {
        let x = this.signupForm.value;

        if (typeof key === 'number') {
            switch (key) {
                case 0:
                    this.warningMsg = 'Check the Internet connection';
                    break;

                case 400:
                    this.warningMsg = msg;
                    break;

                case 500:
                    this.warningMsg = msg;
                    break;

                case 501:
                    this.warningMsg = this.validationMessages.login.used;
                    this.formErrors.login = this.warningMsg;
                    break;

                case 503:
                    this.warningMsg = this.validationMessages.email.used;
                    this.formErrors.email = this.warningMsg;
                    break;

                default:
                    return;
            }
        } else if (typeof key === 'string') {
            let name_field = this.signupForm.controls[key];
            let error_type = Object.keys(name_field.errors)[0];

                switch (key) {
                    case 'title':
                        this.warningMsg = error_type === 'required'
                           ? `${
                                 AppValues.getPlaceholderCompanyBuyerName(this.accessLevel)
                              } is required.`
                           : this.validationMessages.title[error_type];
                        this.formErrors.title = this.warningMsg;
                        break;

                    case 'email':
                            this.warningMsg = this.validationMessages.email[error_type];
                            this.formErrors.email = this.warningMsg;

                        break;

                    case 'login':
                    case 'login2':
                        this.warningMsg = this.validationMessages[key][error_type];

                        if (x.login && !x.login2) {
                            this.warningMsg = this.validationMessages['login2'].required;
                        } else if (x.login2 && !x.login) {
                            this.warningMsg = this.validationMessages['login'].required;
                        } else if (error_type === 'mismatched') {
                            x.login = '';
                            x.login2 = '';
                        }
                        break;

                    case 'password':
                    case 'password2':
                        this.warningMsg = this.validationMessages[key][error_type];

                        if (x.password && !x.password2) {
                            this.warningMsg = this.validationMessages['password2'].required;
                        } else if (x.password2 && !x.password) {
                            this.warningMsg = this.validationMessages['password'].required;
                        } else if (error_type === 'mismatched') {
                            x.password = '';
                            x.password2 = '';
                        }
                        break;

                    case 'checkboxTerms':
                    case 'checkboxYearsOld':
                        const value = this.signupForm.controls[key].value;
                         if (value === false || value === '') {
                            this.warningMsg = this.validationMessages[key].required;
                        }
                        break;

                    case 'other':
                        this.warningMsg = this.translate.instant('signup.error.fields.message');
                        break;

                    default:
                        if (key) this.warningMsg = this.validationMessages[key][error_type];
                        return;
                }
            }
    }


    canDeactivate() {
        if (this.isSuccess) return true;

        if (this.signupForm) {
            if (this.change_item) {
                this.modalService
                    .warning({
                        title: this.translate.instant('signup.error.cancel.title'),
                        message: this.translate.instant('signup.error.cancel.message'),
                        yesButtonText: this.translate.instant('signup.error.cancel.confirm'),
                        noButtonText: this.translate.instant('signup.error.cancel.reject'),
                        reverseButtons: true,
                    })
                    .then((action: boolean) => this.modalSubject.next(action));

                return this.modalSubject.asObservable();
            }
        }

        return true;
    }

    checkObjectChanges(object1: {}, object2: {}) {
        if (AppValues.deepEqual(object1, object2)) {
            this.change_item = false;
        } else this.change_item = true;
    }

    set_changed_item(item: SignupFormInterface) {
        this.primevalItem.next(item);
    }

    clickAnyButtons(name: string) {
       this.googleAnalyticsService.handleClickButton(name + '_pressed', 'sign_up', 'click');
    }
}
