import { Component, ElementRef }    from '@angular/core';

import AppValues                    from '../../common/app.values';
import { MediaEditorComponent }     from '../media-editor/media-editor.component';
import { ModalService }             from '../../modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import { isValidFileSize } from '../../product-editor/additional/user-platform.helpers';



@Component({
    selector:   'img-loader',
    styleUrls:  ['img-loader.sass', '../../../assets/styles/sprite.sass'],

    template:   `
        <div class="il__container" [style.background-image]="'url('+imageUrl+')'">

            <div class="controls">
                <span (click)="showUpload()" class="control" data-test-id="uploadBtn">
                    <span class="sprite sprite-description_3x"></span>
                </span>
            </div>

            <div class="title">
                <h4 role="editable"
                    data-test-id="title"
                    contenteditable="true"
                    (blur)="finishRenaming({event: $event})"
                    (focus)="startRenaming({event: $event})">{{text}}</h4>
            </div>

            <input name="photo" (change)="onImgUpload($event.currentTarget)" type="file" accept="imageTypes" data-test-id="fileInp">
        </div>
    `
})
export class ImgLoaderComponent extends MediaEditorComponent {
    /**
     * Widget of editable text node and replaceable background image.
     * @extends MediaEditorComponent.
     * @desc Accepts inputs:
     * - imageUrl for the icon;
     * - text for the text node, represented as H3 tag;
     * @emits changeEvent$ when text or imageUrl has changed.
     */
    fileInput:  HTMLInputElement;
    message:    string;
    public imageTypes: string = AppValues.imageTypes;

    constructor(
        elementRef:     ElementRef,
        modalService:   ModalService,
        translate:      TranslateService,
    ) {
        super(elementRef, modalService, translate);
    }

    /**
     * Simulates click on the file input, to show browser file select dialog.
     */
    showUpload() {
        this.fileInput = this.elementRef.nativeElement.querySelector('input[name="photo"]');
        this.fileInput.click();
    }


    /**
     * Handles image upload.
     * @emits changeEvent$ on proper image file.
     */
    onImgUpload(event: EventTarget) {
        if (this.fileInput.files.length) {
            let file = this.fileInput.files[0];

            if (!isValidFileSize(file.size)) {
                this.modalService.error({
                    title: this.translate.instant('modal.error.uploadImage.title'),
                    message: this.translate.instant('modal.error.uploadImage.message'),
                    yesButtonText: this.translate.instant('modal.error.uploadImage.confirm'),
                });
                return false;
            }
            this.changeEvent$.emit({imageFile: file});

            setTimeout(() => {
                this.resetInput(event);
            });
        }
    }

    resetInput(event: EventTarget) {
        event['value'] = '';

        if (!/safari/i.test(navigator.userAgent)) {
            event['type'] = '';
            event['type'] = 'file';
        }
    }


   //  /**
   //   * Checks if the value doesn't contains inappropriate symbols
   //   * @private
   //   * @param value
   //   * @returns {boolean}
   //   */
   //  _isValid(value: string): boolean {
   //      return !AppValues.charsAntiPattern.test(value);
   //  }

}
