import { NgModule, ModuleWithProviders }    from '@angular/core';
// import { BrowserAnimationsModule }          from '@angular/platform-browser/animations';import { PathLocationStrategy, LocationStrategy }   from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule }                     from '@angular/common';
import { SharedModule }                     from '../shared/shared.module';
import { RouterModule }                     from '@angular/router';

import { EmailVerificationComponent }       from './email-verification.component';
import { UserService }                      from './user.service';
import { LoginComponent }                   from './login.component';
import { PasswordComponent }                from './password.component';
import { PasswordResetComponent }           from './password-reset.component';
import { SignupComponent }                  from './signup.component';
import { SwitchComponent }                  from './switch.component';
import { UserHeaderComponent }              from './user-header.component';
import { LoginLinkComponent }               from './login-link/login-link.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpLoaderFactory} from "../app.module";
import {Http} from "@angular/http";
import { InnerHtmlClickHandlerDirective } from '../common/directives/inner-html-click-handler.directive';


@NgModule({
    imports: [
        // BrowserAnimationsModule,
        RouterModule,
        SharedModule,

        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule
    ],
    declarations: [
        InnerHtmlClickHandlerDirective,
        EmailVerificationComponent,
        LoginComponent,
        PasswordComponent,
        PasswordResetComponent,
        SignupComponent,
        SwitchComponent,
        UserHeaderComponent,
        LoginLinkComponent
    ],
    exports: [
        EmailVerificationComponent,
        LoginComponent,
        PasswordComponent,
        PasswordResetComponent,
        SignupComponent,
        SwitchComponent,
        LoginLinkComponent
    ]
})
export class UserModule {
    static forRoot(): any {
        return {
            ngModule: UserModule,
            providers: [UserService]
        };
    }
}
