import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import { DataService } from '../services/data.service';
import { ErrorService } from '../services/error.service';
import { ImageUploadService } from '../services/image-upload.service';
import { ModalService } from '../modal/modal.service';
import { RedirectService } from '../services/redirect.service';
import { UserService } from '../user/user.service';
import { userNotNullFields } from '../user/helpers/_helpers';
import { GeoLocationService } from '../services/geolocation.service';
import AppValues from '../common/app.values';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { getErrorType } from '../services/error.helpers';
var ProfileService = /** @class */ (function () {
    function ProfileService(dataService, errorService, imageUploadService, modalService, redirectService, router, userService, geoLocationService, translate, location) {
        this.dataService = dataService;
        this.errorService = errorService;
        this.imageUploadService = imageUploadService;
        this.modalService = modalService;
        this.redirectService = redirectService;
        this.router = router;
        this.userService = userService;
        this.geoLocationService = geoLocationService;
        this.translate = translate;
        this.index = 0;
        this.geolocation = {
            longitude: 0,
            latitude: 0
        };
        this.location = location;
        this.setGeolocation();
    }
    ProfileService.prototype.setGeolocation = function () {
        if (this.geoLocationService.location) {
            this.geolocation = this.geoLocationService.location;
        }
        else {
            if (this.profile) {
                this.geolocation = {
                    longitude: this.profile.longitude || 0,
                    latitude: this.profile.latitude || 0
                };
            }
        }
    };
    /**
     * @desc Fetches profile data via DataService. Caches profile data into memory.
     * @returns Observable type of profile data|empty object depending on user token availability.
     */
    ProfileService.prototype.getProfile = function () {
        var _this = this;
        this.userSession = this.userService.getUserSession();
        var token = this.userSession.token;
        if (token) {
            var body = {
                'users_ids': [this.userSession.ID]
            };
            return this.dataService.postData('user_by_ids', body, { token: token })
                .map(function (res) {
                _this.profile = res.users[0];
                _this._sortAddresses();
                return _this.profile;
            })
                .catch(function (err) { return _this.errorService.handleError(err); });
        }
        return Observable.of({});
    };
    Object.defineProperty(ProfileService.prototype, "geoLocation", {
        get: function () {
            return this.geoLocationService.location;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileService.prototype, "userProfile", {
        get: function () {
            return this.profile;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Redirects to Address Editor Component.
     * @param addr Address object from addresses array.
     * @param index of the Address object in the addresses array.
     */
    ProfileService.prototype.onAddressEdit = function (addr, index) {
        if (!addr) {
            addr = this.emptyAddress;
            addr.firstName = this.profile.firstName;
            addr.lastName = this.profile.lastName;
            addr.country = AppValues.countries[0];
        }
        this.addr = addr;
        this.index = index;
        this.router.navigate(['/addr-editor']);
    };
    Object.defineProperty(ProfileService.prototype, "emptyAddress", {
        get: function () {
            return {
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                postCode: '',
                phone: '',
                country: '',
                isPrimary: false
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileService.prototype, "getAddress", {
        /**
         * @desc Returns Address object and the uniqueness boolean flag.
         * @returns {{addr: AddressInterface, isOnly: boolean}}
         */
        get: function () {
            return {
                addr: this.addr,
                isOnly: this.profile.addresses.length === 0
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProfileService.prototype, "profileLocation", {
        set: function (location) {
            this.geolocation = location;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @desc Delegates image uploading to ImageUploadService. Processes spinner and error handling.
     * @param img
     * @returns {Observable<string>}
     */
    ProfileService.prototype.uploadImg = function (img) {
        var _this = this;
        this.modalService.showSpinner();
        return this.imageUploadService.uploadImg(img, this.userSession.token)
            .do(function () { return _this.modalService.close(); })
            .catch(function (err) {
            _this.errorService.handleError(err);
            return Observable.throwError(getErrorType(err));
        });
    };
    /**
     * @desc Decorator for #_save method. Additionally shows spinner.
     * @param profile
     * @returns {Observable<UserModel>}
     */
    ProfileService.prototype.saveProfile = function (profile, isMainProfileInfo) {
        if (isMainProfileInfo === void 0) { isMainProfileInfo = false; }
        this.modalService.showSpinner();
        return this._save(profile, isMainProfileInfo);
    };
    /**
     * @desc Updates existingItem Address object, pushes newly created Address object into Arrdesses array.
     * Resets 'isPrimary' if updated|created has it true.
     * @param {AddressInterface} addr
     * @param {CenterLocationInterface} coordFromAddr
     * @return {Subscription}
     */
    ProfileService.prototype.submitAddress = function (addr, coordFromAddr) {
        var _this = this;
        var profile = this.profile;
        if ((this.index === -1 && addr.isPrimary === true) ||
            (this.profile.addresses[this.index] && this.profile.addresses[this.index].isPrimary === true)) {
            profile = this.setCoordinatesToProfile(profile, coordFromAddr);
        }
        this._sortAddresses();
        this._save(profile).subscribe(function (res) { return null; }, function (err) {
            _this.modalService.error({ title: 'Error:', message: err, yesButtonText: 'Close' });
        });
    };
    ProfileService.prototype.setCoordinatesToProfile = function (profile, coordFromAddr) {
        if (coordFromAddr) {
            profile = this.changeProfileCoordinates(profile, coordFromAddr);
        }
        else if (this.geolocation) {
            profile = this.changeProfileCoordinates(profile, this.geolocation);
        }
        return profile;
    };
    ProfileService.prototype.changeProfile = function (address, isPrimary) {
        var newprofile = AppValues.deepCopy(this.profile);
        if (isPrimary && newprofile.addresses.length !== 0) {
            newprofile.addresses.forEach(function (a) { return a.isPrimary = false; });
        }
        if (typeof this.index !== 'undefined' && ~this.index) {
            newprofile.addresses[this.index] = address;
        }
        else {
            newprofile.addresses.push(address);
        }
        this.profile = newprofile;
    };
    ProfileService.prototype.changeProfileCoordinates = function (profile, location) {
        profile['loc'].coordinates[0] = location.latitude;
        profile['loc'].coordinates[1] = location.longitude;
        profile.longitude = location.longitude;
        profile.latitude = location.latitude;
        return profile;
    };
    /**
     * Sorts Addresses array so that the primary address appears the first.
     * @private
     */
    ProfileService.prototype._sortAddresses = function () {
        this.profile.addresses.sort(function (a, b) {
            return (a.isPrimary === b.isPrimary) ? 0 : a.isPrimary ? -1 : 1;
        });
    };
    /**
     * Saves profile, closes spinner and redirects back to the Profile page.
     * @private
     * @returns {Observable<UserModel | {}>}
     * @param {UserModel} profile
     * @param {boolean} isMainProfileInfo
     */
    ProfileService.prototype._save = function (profile, isMainProfileInfo) {
        var _this = this;
        var token = this.userSession.token;
        if (!token)
            return Observable.of({});
        profile = userNotNullFields(profile);
        return this.dataService.postData('update_user', profile, { token: token })
            .map(function (res) {
            _this.profile = res;
            _this.userService.updateUser(res);
            _this.modalService.close();
            if (!isMainProfileInfo) {
                _this.location.back();
            }
            return _this.profile;
        })
            .catch(function (err) {
            return _this.errorService.handleError(err);
        });
    };
    ProfileService.prototype.handleEmailUpdateErr = function (error) {
        this.modalService
            .error({
            title: this.translate.instant('profile.editor.email.warning.title'),
            message: error,
            yesButtonText: this.translate.instant('profile.editor.email.warning.confirm'),
        });
    };
    return ProfileService;
}());
export { ProfileService };
