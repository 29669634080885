import { Injector } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { Router } from '@angular/router';
import { CookieService } from '../services/cookie.service';
import { RedirectService } from '../services/redirect.service';
import { UserService } from '../user/user.service';
import { ChatService } from '../chat/chat.service';
import { AfterBootService } from '../after-boot/after-boot.service';
import { StorageService } from '../services/storage.service';
import { GeoLocationService } from '../services/geolocation.service';
var AppLoadService = /** @class */ (function () {
    function AppLoadService(cookieService, afterBootService, injector, storageService) {
        this.cookieService = cookieService;
        this.afterBootService = afterBootService;
        this.injector = injector;
        this.storageService = storageService;
        this.hasNoToken = false;
        this.location = window.location;
    }
    // initializeApp(): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         // console.log(`initializeApp:: inside promise`);
    //
    //         setTimeout(() => {
    //             // console.log(`initializeApp:: inside setTimeout`);
    //             // doing something
    //
    //             resolve();
    //         }, 3000);
    //     });
    // }
    AppLoadService.prototype.initializeApp = function () {
        var _this = this;
        var promise;
        this.isLoginLink();
        this.checkPurchaseMemory();
        var user = this.cookieService.getCookie('user');
        var type = this.cookieService.getCookie('type');
        this.redirect_service.firstStartApp = true;
        this.geo_location_service.getGeolocation();
        if (!user && !type) {
            promise = this.user_service.login_guest().toPromise().then(function (res) {
                _this._changeRoute();
                return res;
            });
        }
        else {
            // get guest token data from cookie and to continue
            // login with token request
            this.user_service.entryWithOldLoginGuest = true;
            promise = this.user_service.loginWithToken().toPromise().then(function (res) {
                if (_this.user_service.getUser().accessLevel === 0) {
                    _this.cookieService.setCookie('type', 'guest');
                }
                _this.hasNoToken && _this.cookieService.deleteCookie('user');
                _this.hasNoToken = false;
                _this._changeRoute();
                return res;
            }).catch(function (err) {
                if (err.status === 403) {
                    return new Promise(function (resolve, reject) {
                        _this.user_service.login_guest(true).toPromise()
                            .then(function (res) {
                            _this.afterBootService.afterLoadingError = err;
                            resolve(res);
                        }).catch(function (error) {
                            _this.user_service.clearUser();
                            reject(error);
                        });
                    });
                }
                return err;
            });
        }
        return promise;
    };
    AppLoadService.prototype._changeRoute = function () {
        this.redirect_service.checkRedirectPath();
        this.redirect_service.firstStartApp = false;
    };
    Object.defineProperty(AppLoadService.prototype, "router", {
        /**
         * @desc This function creates router property on your service.
         * @return {Router}
         */
        get: function () {
            return this.injector.get(Router);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppLoadService.prototype, "redirect_service", {
        /**
         * @desc This function creates 'redirect service' property on your service.
         * @return {Router}
         */
        get: function () {
            return this.injector.get(RedirectService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppLoadService.prototype, "user_service", {
        /**
         * @desc This function creates 'user service' property on your service.
         * @return {UserService}
         */
        get: function () {
            return this.injector.get(UserService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppLoadService.prototype, "geo_location_service", {
        /**
         * @desc This function creates 'GeoLocationService' property on your service.
         * @return {GeoLocationService}
         */
        get: function () {
            return this.injector.get(GeoLocationService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppLoadService.prototype, "chat_service", {
        /**
        * @desc This function creates 'chat service' property on your service.
        * @return {ChatService}
        */
        get: function () {
            return this.injector.get(ChatService);
        },
        enumerable: true,
        configurable: true
    });
    /**
    * Checks if window location search containes '?token=' substring.
    * @desc In case true - saves to cookie token from location
    *       search and type as 'user'.
    * @private
    */
    AppLoadService.prototype.isLoginLink = function () {
        var url = this.location.search;
        if (url.includes('?token=')) {
            this.afterBootService.isLoginLinkLaunch = true;
            var token = decodeURIComponent(url.split('?token=')[1]);
            var userType = this.cookieService.getCookie('type');
            if (this.cookieService.getCookie('user') && userType !== 'guest') {
                this.afterBootService.isAlreadyLoggedIn = true;
            }
            else {
                this.cookieService.setCookie('user', token);
                this.cookieService.setCookie('type', '');
            }
        }
    };
    AppLoadService.prototype.checkPurchaseMemory = function () {
        var storedUser = this.storageService.get('user');
        if (storedUser && !this.cookieService.getCookie('user')) {
            this.cookieService.setCookie('user', storedUser);
            this.hasNoToken = true;
        }
    };
    return AppLoadService;
}());
export { AppLoadService };
