import {
    AfterContentChecked, HostListener,
    OnInit,
} from '@angular/core';

import { ModalService }             from '../../modal/modal.service';
import {AppReviewsService}          from '../reviews.service';
import {OrderElement, Review} from '../../../../swagger-gen__output_dir';
import {ReviewProductInterface, SelectedOrder} from '../review.interface';
import {FormGroup} from '@angular/forms';
import {Event} from '@angular/router';
import {GetUserReviewsResponseBody} from '../../../../swagger-gen__output_dir/model/getUserReviewsResponseBody';
import {TranslateService} from '@ngx-translate/core';

export interface CreateReviewsContext {
   selected_order:      SelectedOrder;
   review_list:         Review[];
   customerStatus:      string;
   customerID:          string;
   reviewForm:          FormGroup;
   title:               string;
   textareaLength:      number;
   product_review_list: ReviewProductInterface;
   user_review_list:    GetUserReviewsResponseBody;

   callback?({}: CreateReviewsContextCallback);
}
export interface CreateReviewsContextCallback {
    addReview?: OrderElement;
    showAvailableReviews?: any;
    createReviewSeller?: null;
    createReviewProduct?: null;
}

export abstract class CreateReviewsAbstractClass implements OnInit, AfterContentChecked {
    public context: CreateReviewsContext;

    public modalService: ModalService;
    public reviewsService: AppReviewsService;
    public translate: TranslateService;

    public busy = true;
    public stars = 0;
    public text = '';
    public textareaLength: number;

    public title: string;

    public product_review_list: ReviewProductInterface;
    public user_review_list: GetUserReviewsResponseBody;
    public count_list: number;
    public selected_order: SelectedOrder;

    // For mobile and android devices
    @HostListener('window:keydown', ['$event'])
    keyEvent(event) {
        this.possibleInputLength(event);
    }

    constructor(
        modalService:           ModalService,
        reviewsService:         AppReviewsService,
        translateService:       TranslateService
    ) {
        this.modalService       = modalService;
        this.reviewsService     = reviewsService;
        this.translate          = translateService;
    }


    /**
     * Fetches the review item if specified.
     */
    public ngOnInit(): void {
        if (this.context.product_review_list) {
            this.product_review_list = this.context.product_review_list;
            this.count_list = this.getCountOfReview(this.context.product_review_list);
        }
        if (this.context.user_review_list) {
            this.user_review_list = this.context.user_review_list;
            this.count_list = this.getCountOfReview(this.context.user_review_list);
        }
        this.title = this.context.title;
        this.textareaLength = this.context.textareaLength;
        this.selected_order = this.context.selected_order;
    }

    private getCountOfReview(obj: GetUserReviewsResponseBody | ReviewProductInterface): number {
        let counts: number = 0;

        Object.keys(obj).forEach((key: string) => {
            counts += obj[key].length;
        });
        return counts;
    }
    public ngAfterContentChecked() {
        this.busy = !this._isValid();
    }

    public resizeReviewTextarea(e: any): void {
        this.possibleInputLength(e);

        let textarea = document.getElementById("review");
        textarea.style.height = textarea.scrollHeight + 'px';
    }
    /**
     * Check on possible input length
     * @param e
     * @private
     */
    public possibleInputLength(e: any) {
        let reviewFormText = this.context.reviewForm.value.text;

        if (reviewFormText.length >= this.textareaLength) {
            this.context.reviewForm.controls['text'].setValue(reviewFormText.slice(0, this.textareaLength));
        }
    }
    /**
     * Returns form validity.
     * @returns {boolean}
     * @private
     */
    private _isValid(): boolean {
        return this.context.reviewForm.valid && this.context.reviewForm.value.text.trim() !== '';
    }

     /**
      * Deprecated Now
     * Shows modal error window.
     * @private
     */
    // private _showWarningLenght() {
    //      this.modalService.error({
    //          title:          'review.create.reviewBody.length.warning.title',
    //          message:        'review.create.reviewBody.length.warning.message',
    //          yesButtonText:  'Close',
    //      });
    // }

}
