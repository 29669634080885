/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./message-group.component.ngfactory";
import * as i3 from "./message-group.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../shared/safe-textarea/safe-textarea.component.ngfactory";
import * as i6 from "../shared/safe-textarea/safe-textarea.component";
import * as i7 from "./dialog-header.component.ngfactory";
import * as i8 from "./dialog-header.component";
import * as i9 from "@angular/common";
import * as i10 from "./chat-room.component";
import * as i11 from "./chat.service";
import * as i12 from "@angular/router";
import * as i13 from "../services/redirect.service";
import * as i14 from "../services/error.service";
import * as i15 from "../modal/modal.service";
var styles_ChatRoomComponent = [i0.styles];
var RenderType_ChatRoomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatRoomComponent, data: {} });
export { RenderType_ChatRoomComponent as RenderType_ChatRoomComponent };
function View_ChatRoomComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "message-group", [], null, null, null, i2.View_MessageGroupComponent_0, i2.RenderType_MessageGroupComponent)), i1.ɵdid(1, 49152, null, 0, i3.MessageGroupComponent, [], { userID: [0, "userID"], messageGroup: [1, "messageGroup"], formatFullDate: [2, "formatFullDate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userID; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.formatFullDate; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ChatRoomComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "timeblock__time-messages"], ["data-test-id", "deleted-user-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("chatroom.deletedUserMessage")); _ck(_v, 1, 0, currVal_0); }); }
function View_ChatRoomComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"]))], null, null); }
function View_ChatRoomComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "safe-textarea", [["data-test-id", "messageInput"]], null, [[null, "isValidEvent$"], [null, "keyup"], [null, "changeEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isValidEvent$" === en)) {
        var pd_0 = (_co.checkIsValid($event) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.clickHandler($event) !== false);
        ad = (pd_1 && ad);
    } if (("changeEvent$" === en)) {
        var pd_2 = (_co.changeEventHandler($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_SafeTextareaComponent_0, i5.RenderType_SafeTextareaComponent)), i1.ɵdid(1, 573440, null, 0, i6.SafeTextareaComponent, [], { isAutofocus: [0, "isAutofocus"], className: [1, "className"], name: [2, "name"], placeholder: [3, "placeholder"], rows: [4, "rows"], value: [5, "value"], disabled: [6, "disabled"], minMessageCharacters: [7, "minMessageCharacters"], maxMessageCharacters: [8, "maxMessageCharacters"] }, { changeEvent$: "changeEvent$", isValidEvent$: "isValidEvent$" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = "chat-message-input"; var currVal_2 = "message"; var currVal_3 = "Text"; var currVal_4 = 1; var currVal_5 = _co.userInput; var currVal_6 = ((_co.currentChat == null) ? null : _co.currentChat.interlocutor_anonymized); var currVal_7 = _co.minMessageCharacters; var currVal_8 = _co.maxMessageCharacters; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_ChatRoomComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dialog-header", [], null, [[null, "goBackEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("goBackEvent$" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DialogHeaderComponent_0, i7.RenderType_DialogHeaderComponent)), i1.ɵdid(2, 49152, null, 0, i8.DialogHeaderComponent, [], { title: [0, "title"] }, { goBackEvent$: "goBackEvent$" }), (_l()(), i1.ɵeld(3, 0, null, null, 13, "div", [["class", "component__container chat-room__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "chat-list no-cope-image"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatRoomComponent_1)), i1.ɵdid(6, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatRoomComponent_2)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "chat-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatRoomComponent_3)), i1.ɵdid(11, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatRoomComponent_4)), i1.ɵdid(13, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["data-test-id", "sendMessage"], ["type", "button"]], [[8, "className", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.interlocutor; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.messageGroups; var currVal_2 = _co.trackByDate; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = ((_co.currentChat == null) ? null : _co.currentChat.interlocutor_anonymized); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.isSending; _ck(_v, 11, 0, currVal_4); var currVal_5 = !_co.isSending; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = (!((_co.currentChat == null) ? null : _co.currentChat.interlocutor_anonymized) ? "send-message" : "deleted-user send-message"); var currVal_7 = (!_co.isValid || ((_co.currentChat == null) ? null : _co.currentChat.interlocutor_anonymized)); _ck(_v, 14, 0, currVal_6, currVal_7); var currVal_8 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("chatroom.send")); _ck(_v, 15, 0, currVal_8); }); }
export function View_ChatRoomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "chat-room", [], null, null, null, View_ChatRoomComponent_0, RenderType_ChatRoomComponent)), i1.ɵprd(512, null, i9.DatePipe, i9.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(2, 245760, null, 0, i10.ChatRoomComponent, [i11.ChatService, i9.DatePipe, i1.ElementRef, i12.ActivatedRoute, i13.RedirectService, i14.ErrorService, i15.ModalService, i1.Injector, i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ChatRoomComponentNgFactory = i1.ɵccf("chat-room", i10.ChatRoomComponent, View_ChatRoomComponent_Host_0, {}, {}, []);
export { ChatRoomComponentNgFactory as ChatRoomComponentNgFactory };
