
        <div #container class="bp__container">

            <div class="bp__close" (click)="closeViewer()" data-test-id="closeButton">&times;</div>

            <div class="bp__image" #myBounds>
                 <div *ngIf="imageData.length && currentBizipix" class="ngx-dnd-container bp__cursor"
                 ngDraggable (started)="findDimensions()" (touchmove)="onPositionChange($event)" (mousemove)="onPositionChange($event)"
                 [bounds]="myBounds" [inBounds]="true"  data-test-id="cursor"></div>

                <img *ngIf="imageData.length && currentBizipix" [src]="currentBizipix.image_url"
                     data-test-id="bizipixImage" alt="bizipix image">
                <img *ngIf="!imageData.length && !currentBizipix" [src]="'../../assets/images/cart/no_image_icon.png'"
                     data-test-id="bizipixImage" alt="bizipix instruction">
            </div>

             <bizipix-menu *ngIf="imageData.length && currentBizipix" class="bizipix-menu" [type]="editable" [currentBizipix]="currentBizipix" [imageData]="imageData"
                          (selectedBizipixEvent$)="selectedBizipixEvent($event)"></bizipix-menu>


        </div>
    