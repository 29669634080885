import {
    Component,
    ElementRef, Injector, OnInit, ViewChild
} from '@angular/core';
import 'rxjs/add/operator/takeUntil';

import {
    ShoppingListInterface,
    ShoppingListItemInterface
}                                   from './shopping-list.interface';
import { ShoppingListService }      from './shopping-list.service';
import { ErrorInterface, ErrorService } from '../services/error.service';

import { SubcategoryInterface } from '../interfaces/category.interface';
import { UserModel } from '../interfaces/user.interface';
import { UserService } from '../user/user.service';
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';


@Component({
    selector: 'shopping-list',
    template: `
        <div class="component">
            <shopping-header
                *ngIf="!isSearchActivated"
                (sortOrderByNameEvent$)="sortOrderByDescriptor()"
            ></shopping-header>

            <div
                class="component__container"
                [class.shopping-list__container_search-activated]="isSearchActivated"
                [class.shopping-list__container]="!isSearchActivated"
                #scrollContainer
            >
                <div>
                   <app-custom-item-suggestions
                      [isEditing]="isEditing"
                      (controlStateChange$)="onInputChange($event)"
                      (selectSuggestion$)="addSuggestionItem($event)"
                      (useEnteredValue$)="addCustomItem($event)"
                      (emitEnteredValue$)="checkIsWelcomeVisible($event)"
                      (resetControlState$)="resetInput()"
                   ></app-custom-item-suggestions>
                </div>
                <div [hidden]="isSearchActivated">
                   <sub-list
                       [index]="0"
                       [list]="activeList"
                       [className]="'shopping-list__custom'"
                       (itemUpdateEvent$)="updatePositionItem($event, inactiveList)"
                       (listUpdateEvent$)="updatePositionItem($event, inactiveList)"
                       (startRenaming$)="onStartRenaming($event)">
                   </sub-list>
                   <sub-list
                       class="inactive"
                       [className]="'shopping-list__custom inactive'"
                       [index]="1"
                       [list]="inactiveList"
                       (itemUpdateEvent$)="updatePositionItem($event, activeList)"
                       (listUpdateEvent$)="updatePositionItem($event, activeList)"
                       (startRenaming$)="onStartRenaming($event)">
                   </sub-list>
                </div>
                <div *ngIf="isWelcomeVisible" class="welcome" data-test-id="welcome-block">
                    <img src="../../assets/images/mobile_greeting_message.svg" alt="Welcome to BiziBAZA&reg;! Shopping for Life&reg;">
                </div>
            </div>
        </div>
    `
})
export class ShoppingListComponent extends UnsubscribeOnDestroyAbsctractClass implements OnInit {
    @ViewChild('scrollContainer') private scrollContainer: ElementRef;

    public isSearchActivated = false;
    public isEditing = false;
    public isWelcomeVisible: boolean = true;
    public initialValue = '';
    public suggestions = [];

    activeList:                 ShoppingListItemInterface[] = [];
    inactiveList:               ShoppingListItemInterface[] = [];
    list:                       ShoppingListItemInterface[] = [];


    constructor(
        private shoppingListService: ShoppingListService,
        private errorService:        ErrorService,
        private injector:            Injector,
    ) {
        super();
    }


    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {ShoppingListItemInterface} item
     * @return {string}
     */
    trackByID(index: number, item: ShoppingListItemInterface): string {
        return item.subcategory_entry_id;
    }


    public ngOnInit(): void {
        this.shoppingListService.suggestionsSearchActivating.next(false);

        this.prepareShoppingList();

        this.user_service.onLoginGuestChanges.subscribe((user: UserModel) => {
            this.prepareShoppingList();
        });
    }


     /***
     * @desc This function creates 'user service' property on your service.
     * @return {UserService}
     */
    public get user_service(): UserService {
        return this.injector.get(UserService);
    }

    private prepareShoppingList(): void {
        this.trackSubscription(
            this.shoppingListService.getList()
                .subscribe(
                    (list: ShoppingListItemInterface[]) => {
                        this.sortedList(list);
                        this.checkIsWelcomeVisible();
                    }, 
                    (err: ErrorInterface) => this.errorService.handleError(err),
                ),
        );
    }


    onInputChange(value: boolean): void {
       this.isSearchActivated = value;
       this.shoppingListService.suggestionsSearchActivating.next(value);
       this.checkIsWelcomeVisible();
    }


    onStartRenaming(item: ShoppingListItemInterface): void {
       this.shoppingListService.setCachedItem(
          this.shoppingListService.getBasicCustomItem(item.name)
       );
       this.isEditing = true;
    }


    private sortedList(list: ShoppingListItemInterface[]): void {
        this.list = this.shoppingListService.sortList(list);
        this.inactiveList = this.shoppingListService.getInactiveList();
        this.activeList = this.shoppingListService.getActiveList();
    }


    private descriptorFieldToShoppingListItem(item: ShoppingListItemInterface): ShoppingListItemInterface {
        if (item.is_custom) {
            if (!item.descriptor) {
                item.descriptor = item.name;
            } else {
                delete item.descriptor;
            }
        }
        return item;
    }

    private localeCompareByDescriptor(list: ShoppingListItemInterface[]): ShoppingListItemInterface[] {
        list.map((item: ShoppingListItemInterface) => this.descriptorFieldToShoppingListItem(item));
        list.sort(
            (itemA: ShoppingListItemInterface, itemB: ShoppingListItemInterface) =>
                itemA.descriptor.localeCompare(itemB.descriptor)
        );
        return list;
    }

    /**
     * If user wants sorting current list in alphabet order -
     * this method will help with this.
     * Method sorts separately active items
     * and inactive items in alphabet order.
     * Then union all new lists and updated shopping list with request.
     */
    sortOrderByDescriptor(): void {
        if (this.activeList && this.activeList.length) {
            this.activeList = this.localeCompareByDescriptor(this.activeList);
        }
        if (this.inactiveList && this.inactiveList.length) {
            this.inactiveList = this.localeCompareByDescriptor(this.inactiveList);
        }

        this.list = [...this.activeList, ...this.inactiveList];
        this.list.map((item: ShoppingListItemInterface) => this.descriptorFieldToShoppingListItem(item));

        this.shoppingListService.updateShoppingList(this.list)
            .subscribe(
                (res: ShoppingListInterface) => this.shoppingListService.setCachedList(res.elements),
                (err: ErrorInterface) => this.errorService.handleError(err)
            );
    }


    /**
     * After drag & drop item - call this method
     * get from component active items and inactive items.
     * Then union all new lists and updated shopping list with request.
     * @param {ShoppingListItemInterface[]} list
     * @param {ShoppingListItemInterface[]} item
     */
    updatePositionItem(list: ShoppingListItemInterface[], items: ShoppingListItemInterface[]): void {
        this.sortedList([...list, ...items]);
        this.checkIsWelcomeVisible();
        this.shoppingListService.updateShoppingList(this.list)
            .subscribe(
                (res: ShoppingListInterface) => this.shoppingListService.setCachedList(res.elements),
                (err: ErrorInterface) => this.errorService.handleError(err)
            );
    }


    addCustomItem(value: string): void {
       this.addItem(this.shoppingListService.getBasicCustomItem(value), value);
    }

    addSuggestionItem(suggestion: SubcategoryInterface): void {
       const itemInList: ShoppingListItemInterface = this.list.find(
          (item: ShoppingListItemInterface) => item.subcategory_entry_id === suggestion.ID
       );
       itemInList
         ? this.errorService.handleWarning(
            `You have already added '${
               suggestion.sub_category_name
            }' to your shopping list`
           )
         : this.addItem({
              name: suggestion.sub_category_name,
              subcategory_entry_id:  suggestion.ID,
              is_active: true,
              is_custom: false,
              descriptor: suggestion.sub_category_name,
           });
    }


    public resetInput(): void {
       this.initialValue = '';
       this.isEditing = false;
       this.shoppingListService.suggestionsSearchActivating.next(false);
    }

    public checkIsWelcomeVisible(value?: string): void {
        if (value) {
            this.isWelcomeVisible = value.length === 0;
        } else if (!this.isSearchActivated) {
            this.isWelcomeVisible = [...this.list, ...this.inactiveList, ...this.activeList].length === 0;
        } else {
            this.isWelcomeVisible = false;
        }
    }

    /**
     * Should be called after adding new item to shopping list.
     * Scrolls shopping list from its last item to input field.
     */
    private scrollToInputField(): void {
        this.scrollContainer.nativeElement.scrollIntoView();
    }


    private addItem(item: ShoppingListItemInterface, keyword: string = ''): void {
       const list: ShoppingListItemInterface[]
         = this.shoppingListService.addSuggestedItem(item, this.list, keyword);

       this.shoppingListService.updateShoppingList(list)
          .subscribe(
             (res: ShoppingListInterface) => {
                 this.sortedList(res.elements);
                 this.shoppingListService.setCachedItem(null);
                 this.scrollToInputField();
             },
             (err: ErrorInterface) => this.errorService.handleError(err)
          );
    }
}
