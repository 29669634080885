import { Component }        from '@angular/core';
import { RedirectService }  from '../services/redirect.service';


@Component({
    selector:   'dialogs-list-header',
    styleUrls:  ['../../assets/styles/app-header.sass'],
    template:   `
        <header class="app-header">

            <nav class="app-header__container" role="goods-nav">

                <span class="navbar__left">
                    <a (click)="goBack()" data-test-id="shoppingLink"
                       class="nav__link"><i class="icon-custom_back_icon"></i></a>
                </span>
                
                <div class="navbar__center" role="goods-nav">
                    <h4>{{ "chatroom.messages" | translate }}</h4>
                </div>

                <span class="navbar__right"></span>
            </nav>

        </header>
    `
})


export class DialogsListHeaderComponent {
    /**
     * Markup for the Dialogs page header.
     */

    constructor(
        private redirectService:    RedirectService,
    ) { }



    goBack() {
        this.redirectService.goBack();
    }
}
