import { Component } from '@angular/core';
import {CacheNotificationDynamicAbstractClass} from './dynamic-abstract-class';
import {AppSettings} from './../common/app.settings';

@Component({
    selector:   'privacy-policy-component',
    template:   `
        <div [class]="modalContentClass">
            <div [ngClass]="'cache-modal__content__header'">
                <button class="cache-modal__close-btn" data-test-id="notificationPrivacyPolicyCloseBtn"
                        (click)="onClick(true)"><span class="icon-cancel"></span></button>
            </div>
            <div [ngClass]="'cache-modal__content__dialog'">
                <p [ngClass]="'cache-modal__content__dialog__privacy-policy-text'"
                data-test-id="notificationPrivacyPolicy">{{"notification.privacyPolicy.text.part1" | translate}} <a data-test-id="privacyPolicyLink" href="{{privacyPolicyLink()}}" target="_blank" rel="noopener noreferrer">{{"notification.privacyPolicy.text.part2" | translate}}</a> {{"notification.privacyPolicy.text.part3" | translate}}
                </p>
            </div>
        </div>
    `
})
export class PrivacyPolicyComponent extends CacheNotificationDynamicAbstractClass {

    constructor() {
        super();
    }

    public privacyPolicyLink(): string {
        return AppSettings.APP_LANDING_PAGE() + '/terms-and-conditions';
    }

    public onClick(action: boolean | string, locationBack?: boolean): void {
        this.context._close && this.context._close(locationBack);
        this.context.handler && this.context.handler(action);
    }
}
