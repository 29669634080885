
        <div class="component analytics-component" id="pending-orders">
            <analytics-header [analyticsUrl]="'/pending-orders'" (goBackEvent$)="goBack()"></analytics-header>
            <div class="analytics__container" id="custom-container">
                <h3 *ngIf="!orders?.length" class="empty-list">Empty</h3>
                <ul class="duties-list" *ngIf="orders">
                    <li [attr.data-test-id]="'dutyItem_'+idx1" [id]="'dutyItem_'+idx1"
                        *ngFor="let item of orders; let idx1 = index;"
                        (click)="_toggleVisibility(item, 'dutyItem_'+idx1)">
                        <div class="analytics-item">
                            <div class="duties-title">
                                <h5 [attr.data-test-id]="'date_'+idx1">Date:
                                    {{item.order_datetime_str}}</h5>
                                <h5 [attr.data-test-id]="'idOrder_nameItem_'+idx1">
                                    Order: {{item.elements[0].item_info.title}}
                                </h5>
                                <h5 [attr.data-test-id]="'idOrder_buyerNameItem_'+idx1" class="analytics__name">
                                    {{template_buyer_name}}: {{item.buyer_info.title}}
                                </h5>
                            </div>
                            <div class="analytics-delivery">
                                <span *ngIf="!item.is_completed" [attr.data-test-id]="'deliveryAllItems_'+idx1"
                                  (click)="$event.stopPropagation(); deliveryAllItems(item);"
                                  class="icon-crr_icon"></span>
                                <span *ngIf="item.is_completed" [attr.data-test-id]="'deliveryAllItems_'+idx1"
                                          class="analytics-details__shipping analytics-details__delivered">Delivered</span>
                            </div>
                            <div class="analytics-item__dir">
                                <i [attr.data-test-id]="'rightDirItem_'+idx1" class="icon-right-dir"
                                   [ngClass]="{'active': item['_isVisible']}"></i>
                            </div>
                            <div class="total-discount__block">
                                <div class="analytics-title">
                                <h5 [attr.data-test-id]="'TotalItem_'+idx1">Total: {{item.order_total_price / HUNDRED | currency: 'USD' :true}}</h5>
                                </div>
                                <div class="analytics-title analytics-item__discount">
                                    <h5 *ngIf="item.discount && item.discount !== 0" [attr.data-test-id]="'DiscountItem_'+idx1">Discount: {{item.discount / HUNDRED | currency: 'USD' :true}}</h5>
                                 </div>
                            </div>
                        </div>

                        <div *ngIf="item['_isVisible']" class="analytics-item__details">
                            <div *ngFor="let product of item.elements; let idx2 = index;">
                                <div class="analytics-details__name">
                                    <div class="analytics__img-container">
                                    <span *ngIf="!product.item_info.photoURL"
                                          [attr.data-test-id]="'imgItem_'+idx1+'_'+idx2"
                                          class="sprite sprite-no_image_icon"></span>
                                        <img *ngIf="product.item_info.photoURL"
                                             [attr.data-test-id]="'imgItem_'+idx1+'_'+idx2"
                                             [src]="product.item_info.photoURL"/>
                                    </div>

                                    <div class="analytics__product-details">
                                        <h4 [attr.data-test-id]="'nameItem_'+idx1+'_'+idx2" class="title">
                                            {{product.item_info.title}}</h4>
                                        <h5 class="analytics__product-details__price-by-uom">
                                            <strong [attr.data-test-id]="'costItem_'+idx1+'_'+idx2">
                                                {{product.price / HUNDRED | currency: 'USD' :true}}/{{product.uom}}
                                            </strong>
                                        </h5>
                                        <section>
                                            <h5 class="buyer">{{template_buyer_name}}: </h5>
                                            <h5 [attr.data-test-id]="'buyerNameItem_'+idx1+'_'+idx2"
                                                class="analytics__name">{{item.buyer_info.title}}</h5>
                                        </section>
                                        <h5 class="analytics__name"
                                            [attr.data-test-id]="'orderCodeItem_'+idx1 + '_' + idx2">
                                            Order code: {{item.order_id}}
                                        </h5>
                                    </div>

                                    <div class="analytics-details__buttons-block">
                                        <span *ngIf="!product.is_completed" [attr.data-test-id]="'deliveryItem_'+idx1+'_'+idx2"
                                          (click)="$event.stopPropagation(); deliveryOneItem(item.order_id, [product.ID]);"
                                          class="icon-crr_icon">
                                        </span>
                                        <span *ngIf="product.is_completed" [attr.data-test-id]="'deliveryItem_'+idx1+'_'+idx2"
                                          class="analytics-details__shipping analytics-details__delivered">Delivered</span>
                                        <i [attr.data-test-id]="'contactButton_'+idx1+'_'+idx2" (click)="contact(item.buyer_id, $event)"
                                                 class="analytics-details__buttons-block__message icon-message_icon"></i>
                                    </div>
                                </div>
                                <div class="analytics-details__qnt">

                                    <div class="buyer_information_duty">
                                        <p class="qnt-title" [attr.data-test-id]="'itemFrom_'+idx1 + '_' + idx2">From:</p>
                                        <p [attr.data-test-id]="'itemAddressFrom_'+idx1+'_'+idx2" class="qnt">
                                            {{ product.provider_address_name }}</p>
                                    </div>

                                    <p class="qnt-title">Quantity:</p>
                                    <p [attr.data-test-id]="'qntItem_'+idx1+'_'+idx2" class="qnt">{{product.qty | number}}</p>
                                    <p class="total-title">Total cost:</p>
                                    <p [attr.data-test-id]="'totalCostItem_'+idx1+'_'+idx2" class="total">
                                        <strong>{{(product.price * product.qty) / HUNDRED | currency: 'USD' :true}}</strong>
                                    </p>

                                    <div *ngIf="isPickUp(product.shipping_address)" class="buyer_information_duty">
                                        <p class="qnt-title">Delivery:</p>
                                        <div class="qnt">
                                            <p [attr.data-test-id]="'contactBuyer_'+idx1+'_'+idx2" class="qnt">{{product.shipping_terms}}</p>
                                        </div>

                                        <p class="total-title">Buyer phone:</p>
                                        <p [attr.data-test-id]="'buyer_primary_phone_'+idx1+'_'+idx2" class="total">
                                            {{ item.buyer_primary_phone }}</p>
                                    </div>
                                    <div *ngIf="!isPickUp(product.shipping_address)" class="buyer_information_duty">
                                        <p class="qnt-title">Pick up:</p>
                                        <p [attr.data-test-id]="'buyer_address_'+idx1+'_'+idx2" class="qnt">
                                            {{ product.pick_up_address_str }}</p>
                                        <p class="total-title">Buyer phone:</p>
                                        <p [attr.data-test-id]="'buyer_primary_phone_'+idx1+'_'+idx2" class="total">
                                            {{ item.buyer_primary_phone }}</p>
                                    </div>
                                    <!--<p class="market-title">Market:</p>
                                    <p [attr.data-test-id]="'marketItem_'+idx1" class="uty-wrapper__market">{{item.marker}}</p>-->

                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    