<div #container class="component product-editor">

    <product-editor-header [title]="title" (closeEvent$)="onClose()" (saveEvent$)="onSave()"></product-editor-header>

    <div *ngIf="item" class="component__container product-editor__container">

        <h3
            class="product-editor__subcategory"
            data-test-id="subcategoryEntryName"
        >
            {{ item.subcategoryEntryName }}
        </h3>

        <image-editor data-test-id="productNameContainer" class="product" role="title"
                [text]="item.title" [dataTestId]="'productName'" [message]="'Product names'"
                [placeholder]="'product.editor.placeholder.productItem' | translate"
                [imageUrl]="item.photoURL || (isEventEditor ? '../../assets/images/cart/no_image_icon.png' : '')"
                [disabled]="isEventEditor"
                (changeEvent$)="onDataChange($event, 'photoURL')">
        </image-editor>

        <image-editor
            class="product"
            role="sellerName"
            [text]="item.sellerName"
            [dataTestId]="'sellerName'"
            [placeholder]="'product.editor.placeholder.companyName' | translate"
            [disabled]="true"
            [imageUrl]="item.sellerPictureURL || '../../assets/images/cart/no_image_icon.png'"
        >
        </image-editor>


<!--MARKET SECTION        -->
        <event-section *ngIf="item.market" [timeDatesInfo]="timeDatesInfo" [market]="item.market"></event-section>


        <section class="product-editor__fields">
<!--DESCRIPTION-->
            <div class="product-editor__description">
                <div class="point-container">
                    <i role="description" class="icon-pencil"></i>
                </div>

                <label class="input__label clearfix">
                    <safe-textarea
                            [className]="'product-editor__textarea'"
                            [name]="'description'"
                            [dataTestId]="'description'"
                            [placeholder]="'product.editor.placeholder.description' | translate"
                            [rows]="3"
                            [value]="item.description"
                            [disabled]="isEventEditor"
                            (changeEvent$)="updateItem($event[0], $event[1])"></safe-textarea>
                </label>
            </div>

<!--SELLING PRICE-->
            <div class="product-editor__input-field">
                <div class="point-container">
                    <i role="sellingPrice" class="icon-dollar-1"></i>
                </div>

                <label class="input__label clearfix">
                    <input class="input__text" type="text" data-test-id="sellingPriceInp"
                            [placeholder]="'product.editor.placeholder.sellingPrice' | translate" name="price"
                            [value]="item.price ? ((+item.price / HUNDRED).toFixed(2) | number:'.2-2') : ''"
                            (blur)="onPriceChange($event, 2, 2)">
                </label>
            </div>

<!--UNITS            -->
            <div class="product-editor__input-field">
                <div class="point-container">
                    <div role="unitsSellingPrice" class="icon-shop"></div>
                </div>

                <quantity-select
                    [name]="'priceUnits'"
                    [units]="units"
                    [default]="'product.editor.placeholder.unitsAvailable' | translate"
                    [isDisabled]="isEventEditor"
                    [class.price-unit]="isEventEditor"
                    [value]="item.priceUnitsName"
                    (changeEvent$)="updateItem($event[0], $event[1])"
                ></quantity-select>

            </div>
        </section>



        <section class="product-editor__dataform">

<!--VALID FROM            -->
            <div class="time-picker__container">
                <h4>{{ "product.editor.price.valid.from" | translate }}</h4>
                <time-picker [dataTestId]="'valitFrom'"
                        [time]="item.valitFrom"
                        [role]="'valitFrom'"
                        (changeEvent$)="checkFromDate($event.role, $event.value)"></time-picker>
                <p *ngIf="isEventEditor" class="validRangeMsg" data-test-id="ValidRangeMsg"> Valid range: {{ dateRangeFromLabel }} - {{ dateRangeToLabel }}</p>
            </div>

<!--VALID TILL            -->
            <div class="time-picker__container">
                <h4>{{ "product.editor.price.valid.till" | translate }}</h4>
                <time-picker #validTill  [dataTestId]="'validTill'"
                        [time]="item.validTill"
                        [role]="'validTill'"
                        (changeEvent$)="checkTillDate($event.role, $event.value)"></time-picker>
                <p *ngIf="isEventEditor" class="validRangeMsg"> {{ "product.editor.alert.valid.range" | translate }} {{ dateRangeFromLabel }} - {{ dateRangeToLabel }}</p>

            </div>
        </section>



        <section class="product-editor__fields">
<!--TOTAL QUANTITY (ex Available qty)  -->
            <div class="product-editor__input-field">
                <div class="point-container">
                    <i role="availableQty" class="icon-beer"></i>
                </div>
                <label class="input__label available-qty clearfix">
                    <input
                        class="input__text"
                        type="text"
                        data-test-id="availableQuantityInp"
                        [placeholder]="'product.editor.placeholder.totalQty' | translate"
                        name="qty"
                        [disabled]="isEventEditor"
                        [value]="(item.qty ? item.qty : '0') | number"
                        (blur)="onPriceChange($event)">
                </label>
            </div>

<!--TOTAL QUANTITY UNITS-->
            <div class="product-editor__input-field product-editor__input-field__price-unit">
                <div class="point-container">
                    <i class="icon-shop"></i>
                </div>
                <div
                  class="price-unit"
                  data-test-id="qtyUnit"
                >
                  {{ item.priceUnitsName || 'Units' }}
                </div>
            </div>
        </section>


<!--CURRENT \ EVENT QUANTITY -->
        <section class="product-editor__fields" data-test-id="currentQtyField" *ngIf="!isNewItem">
            <div class="product-editor__input-field">
                <div class="point-container">
                    <i role="availableQty" class="icon-shop"></i>
                </div>
                <label class="input__label clearfix" [ngClass]="isEventEditor ? 'event-qty' : 'current-qty'">
                    <input class="input__text"
                           type="text"
                           data-test-id="currentQuantityInp"
                           [placeholder]="isEventEditor ? 'Event Qty' : 'Current Qty'"
                           [disabled]="!isEventEditor"
                           name="current_quantity"
                           [value]="item.current_quantity ? (item.current_quantity | number) : '0'"
                           (blur)="onPriceChange($event)">
                </label>
            </div>

<!--CURRENT \ EVENT QUANTITY UNITS-->
            <!-- <div *ngIf="!isEventEditor" class="product-editor__input-field">
                <div class="point-container">
                    <div role="unitsSellingPrice" class="sprite sprite-units_2x"></div>
                </div>

                <quantity-select
                    [name]="'qtyUnit'"
                    [units]="units"
                    [default]="'Units'"
                    [value]="item.qtyUnitName"
                    (changeEvent$)="updateItem($event[0], $event[1])"></quantity-select>

            </div> -->
        </section>


<!--INVENTORY CALCULATOR        -->
        <!--<section class="product-editor__fields">-->
            <!--<div class="product-editor__calculator">-->
                <!--<img role="unitsSellingPrice" src="../../assets/images/product-editor/scales_icon@3x.png"/>-->
                <!--<h4>Inventory Calculator (N/A)</h4>-->
            <!--</div>-->
        <!--</section>-->



<!--PRODUCT CODE-->
        <section class="product-editor__fields">
            <div class="product-editor__input-field">
                <div class="point-container">
                    <i role="productCodeImg" class="icon-hashtag"></i>
                </div>
                <label class="input__label clearfix">
                    <input
                        class="input__text"
                        type="text"
                        data-test-id="productCodeInp"
                        [placeholder]="'product.editor.placeholder.productCode' | translate"
                        name="productCode"
                        [value]="item.productNo"
                        [disabled]="isEventEditor"
                        (blur)="updateItem('productNo', $event.target.value)">
                </label>
            </div>
        </section>



        <section *ngIf="!isEventEditor" class="product-editor__fields">
<!--US DELIVERY-->
            <div class="product-editor__switch-field">
                <h4>{{ "product.editor.us.delivery.offered" | translate }}</h4>
                <rect-switch [dataTestId]="'deliveryOfferedSwitch'"
                                [disabled]="item.market"
                                [state]="item.deliveryOffered"
                                (stateEvent$)="updateItem('deliveryOffered', $event)"></rect-switch>
            </div>

<!--SHIPPING TERMS-->
            <div class="product-editor__description">
                <div class="point-container">
                    <span id="shipping_terms">
                        <i role="shipping_terms" class="icon-truck-1"></i>
                    </span>
                </div>

                <label class="input__label clearfix">
                    <safe-textarea
                            [className]="'product-editor__textarea'"
                            [name]="'shipping_terms'"
                            [dataTestId]="'shipping_terms'"
                            [placeholder]="'product.editor.placeholder.contactSeller' | translate"
                            [rows]="3"
                            [value]="item.shipping_terms"
                            (changeEvent$)="updateItem($event[0], $event[1])"></safe-textarea>
                </label>
            </div>

<!--MARKET PICK UP -->
            <div class="product-editor__switch-field">
                <h4>{{ "product.editor.pickup.offered" | translate }}</h4>
                <rect-switch [dataTestId]="'pickUpOfferedSwitch'"
                                [disabled]="item.market"
                                [state]="item.marketPickOffered"
                                (stateEvent$)="updateItem('marketPickOffered', $event)"></rect-switch>
            </div>
        </section>


<!--ORGANIC / GMO-->
        <section *ngIf="!isEventEditor" class="product-editor__fields">
            <certificate [dataTestId]="'organicSwitch'"
                    [disabled]="item.market"
                    [isStatus]="item.organic"
                    [imageUrl]="item.organic_certificate_image"
                    (changeEvent$)="certificateUpdate($event, 'organic')">

                    {{ "product.editor.organic" | translate  }}
            </certificate>

            <certificate [dataTestId]="'nonGmoSwitch'"
                    [disabled]="item.market"
                    [isStatus]="item.nonGMO"
                    [imageUrl]="item.nonGMO_certificate_image"
                    (changeEvent$)="certificateUpdate($event, 'nonGMO')">

                    {{ "product.editor.non.gmo" | translate  }}
            </certificate>
        </section>


<!--PRODUCTION DATE-->
        <section *ngIf="!isEventEditor" class="product-editor__data-product">
            <div class="time-picker__container height-auto">
                <div class="product-editor__switch-field">
                    <h4>{{ "product.editor.production.date" | translate  }}</h4>
                    <rect-switch [dataTestId]="'productionDate'"
                                    [disabled]="item.market"
                                    [state]="productionDateState"
                                    (stateEvent$)="updateProductionState(!productionDateState)"></rect-switch>
                </div>
                <time-picker
                   *ngIf="productionDateState"
                   #productionDate
                   [dataTestId]="'productionDate'"
                   [time]="item.productionDate"
                   [role]="'productionDate'"
                   [hasTimeSection]="false"
                   (changeEvent$)="this.updateItem($event.role, $event.value)"
                ></time-picker>
            </div>
        </section>


<!--COUNTRY OF ORIGIN        -->
        <section *ngIf="!isEventEditor" class="product-editor__country">
            <div class="product-editor__select-field">
                <h4>{{ "product.editor.country.origin" | translate  }}*</h4>

                <label class="input__label input__select-style clearfix">
                    <select #countrySelect name="country" data-test-id="countrySelect"
                            [ngClass]="item.originCountry ? 'color__black' : 'color__gray'"
                            [value]="item.originCountry"
                            (change)="updateItem('originCountry', countrySelect.value)">

                        <option *ngFor="let c of countries" dir="ltr"
                                [ngValue]="c">{{c}}</option>
                    </select>
                </label>
            </div>
        </section>


<!--EVENT WIDGET-->
        <event-widget
            *ngIf="!isNewItem && !isEventEditor"
            [events]="item.events"
            (eventWidgetEvent$)="onWidgetEvent($event)"
        ></event-widget>


<!--SALE        -->
        <section class="product-editor__fields">
            <div class="product-editor__switch-field">
                <h4>{{ "product.editor.sale.on" | translate  }}</h4>
                <rect-switch [dataTestId]="'saleIsOnSwitch'"
                             [state]="item.saleIsOn"
                             (stateEvent$)="updateItem('saleIsOn', $event)"></rect-switch>
            </div>

    <!--SALE PRICE-->
            <div class="product-editor__input-field">
                <div class="point-container">
                    <div role="salePrice" class="sprite sprite-sale-price_2x"></div>
                </div>
                <label class="input__label clearfix">
                    <input class="input__text" type="text" data-test-id="salePriceInp"
                           [placeholder]="'product.editor.placeholder.salePrice' | translate" name="salePrice"
                           [value]="item.salePrice ? ((+item.salePrice / HUNDRED).toFixed(2) | number:'.2-2') : ''"
                           (blur)="onPriceChange($event, 2, 2)">
                </label>
            </div>

    <!--SALE UNITS-->
            <div class="product-editor__input-field product-editor__input-field__price-unit">
                <div class="point-container">
                    <div role="unitsSalePrice" class="icon-shop"></div>
                </div>
                <div
                  class="price-unit"
                  data-test-id="saleUnit"
                >
                  {{ item.priceUnitsName || 'Units' }}
                </div>
            </div>

    <!--SALE FROM-->
            <div class="time-picker__container">
                <h4>{{ "product.editor.sale.from" | translate  }}</h4>
                <time-picker #saleDateFrom [dataTestId]="'saleDateFrom'"
                             [time]="item.saleDateFrom"
                             [role]="'saleDateFrom'"
                             (changeEvent$)="checkFromDate($event.role, $event.value)"></time-picker>
                <p class="validRangeMsg" data-test-id="SaleValidRangeMsg"> {{ "product.editor.alert.valid.range" | translate  }} {{ validFromLabel }} - {{ validToLabel }}</p>
            </div>

    <!--SALE TILL-->
            <div class="time-picker__container">
                <h4>{{ "product.editor.sale.till" | translate  }}</h4>
                <time-picker #saleDateTill [dataTestId]="'saleDateTill'"
                             [time]="item.saleDateTill"
                             [role]="'saleDateTill'"
                             (changeEvent$)="checkTillDate($event.role, $event.value)"></time-picker>
                <p class="validRangeMsg"> {{ "product.editor.alert.valid.range" | translate  }} {{ validFromLabel}} - {{ validToLabel }}</p>
            </div>
        </section>


<!--IS ACTIVE-->
        <section *ngIf="!isEventEditor" class="product-editor__fields position-fixed_holder">
            <div class="product-editor__switch-field position-fixed_bottom">
                <h4>{{ "product.editor.active" | translate  }}</h4>
                <rect-switch [dataTestId]="'activeSwitch'"
                                [state]="!item.draft"
                                (stateEvent$)="updateItem('draft', !$event)"></rect-switch>
            </div>
        </section>

    </div>

</div>
