import {Injectable} from '@angular/core';
import { Subject }                  from 'rxjs/Subject';

import {BlankComponent} from '../modal/blank.component';
import {UnsupportedOrientationComponent} from './unsupported-orientation.component';
import {
    UnsupportedModalArgumentsInterface, UnsupportedModalCommandInterface,
    UnsupportedModalStatusType
} from './dynamic-abstract-class';
import {Observable} from 'rxjs/Observable';
import {UnsupportedAccountComponent} from './unsupported-account';
import AppValues from "../common/app.values";
import {TranslateService} from "@ngx-translate/core";



@Injectable()
export class UnsupportedDeviceService {

    private showModal$  = new Subject<UnsupportedModalCommandInterface>();
    private currentModalType: typeof BlankComponent = BlankComponent;
    constructor(private translate: TranslateService) { }


    /**
     * Setter for type of currently displayed modal.
     * @param type
     */
    set modalType(component: typeof BlankComponent) {
        this.currentModalType = component;
    }


    /**
     * Getter for type of currently displayed modal.
     * @returns {string}
     */
    get modalType(): typeof BlankComponent {
        return this.currentModalType;
    }

    public close() {
        this.showModal$.next({
            context:    {},
            component:       BlankComponent
        });
    }

    public unsupportedOrientation(options?: UnsupportedModalArgumentsInterface): Promise<boolean | string> {
        const modalOptions: UnsupportedModalArgumentsInterface = { status: UnsupportedModalStatusType.warning, component: UnsupportedOrientationComponent};

        return this._createModal(modalOptions, options);
    }
    public unsupportedAccount(options?: UnsupportedModalArgumentsInterface): Promise<boolean | string> {
        const modalOptions: UnsupportedModalArgumentsInterface = { status: UnsupportedModalStatusType.warning, component: UnsupportedAccountComponent};

        return this._createModal(modalOptions, options);
    }
    public unsupportedBrowser(options?: UnsupportedModalArgumentsInterface): Promise<boolean | string> {
        const modalOptions: UnsupportedModalArgumentsInterface = {
            status: UnsupportedModalStatusType.warning,
            component: UnsupportedOrientationComponent,
            message: this.translate.instant('unsupportedBrowser.message', {browser_name: AppValues.currentBrowserName(), supported_browsers_list: AppValues.supported_browsers})
        };

        return this._createModal(modalOptions, options);
    }

    private _createModal(context: UnsupportedModalArgumentsInterface, options?: UnsupportedModalArgumentsInterface): Promise<boolean | string> {
        if (options) {
            context = Object.assign(context, options);
        }

        let component = context && context.component ? context.component : BlankComponent;
        this.showModal$.next({
            component:       component,
            context
        });

        return new Promise(resolve => {
            context.handler = (arg: boolean | string) => resolve(arg);
        });
    }

    public getCommand(): Observable<UnsupportedModalCommandInterface> {
        return this.showModal$.asObservable();
    }

}
