import {Component} from '@angular/core';
import {ModalService} from '../../modal/modal.service';
import {AppReviewsService} from '../reviews.service';
import {CreateReviewsAbstractClass} from './create-review-abstract.class';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector:       'create-review-seller',
    styleUrls:      ['.././reviews.sass'],
    templateUrl:    './create-review.html'
})

export class CreateReviewBuyerComponent extends CreateReviewsAbstractClass {

    constructor(modalService:       ModalService,
                reviewsService:     AppReviewsService,
                translate:          TranslateService) {
        super(modalService, reviewsService, translate);
    }

    /**
     *
     * @param ID
     * @param buyer
     * @param title
     * @param url
     */
    showAvailableReviews(): void {
        // Deprecated now (21.10.20)

        // if (!ID) return;
        //
        // this.reviewsService.getUserReviewsAndRedirect({buyerObj: {ID: ID, title: title, url: url}, isCreating: false})
        //     .subscribe(null, (error: string) => {
        //         this.modalService.error({title: 'Error:', message: error, yesButtonText: 'Close'});
        //     });
    }
     /**
     * @desc Checks the form validity. If valid, composes address object to
     * pass it to the ReviewService.
     */
    save() {
        this.context.callback({createReviewSeller: null});
    }
}
