import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, ElementRef, HostListener, OnChanges,
    OnDestroy, OnInit, SimpleChanges
} from '@angular/core';
import { Subject }    from 'rxjs';

import AppValues                from '../common/app.values';
import { CartService }          from './cart.service';
import {
    AvailableEventTotalQtyInterface,
    CartItemInterface,
    CartItemTemplateInterface,
    CartItemWithMarketInterface,
    CartTemplateInterface,
    ChangeProductInterface,
} from '../interfaces/cart.interface';
import { DOMElement }               from '../interfaces/misc.interface';
import { GoogleAnalyticsService }   from '../services/google-analytics.service';
import { ModalService }             from '../modal/modal.service';
import { ErrorInterface }            from '../services/error.service';
import { UserService } from '../user/user.service';
import { RedirectService } from '../services/redirect.service';
import {AppliedCouponView, EmptyDict, Event, ShoppingCartResponseBody} from '../../../swagger-gen__output_dir';
import {DiscountsCouponObject} from '../../../swagger-gen__output_dir/model/discountsCouponObject';
import {Observable} from 'rxjs/Rx';
import {ChatService} from '../chat/chat.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ItemService} from '../services/item.service';
import {OperationSystemService} from '../services/operation.system.service';
import {TranslateService} from '@ngx-translate/core';
import {AppliedCouponExtendedView} from '../../../swagger-gen__output_dir/model/appliedCouponExtendedView';
import {UpdateAddInfoFromSCResponseBody} from '../../../swagger-gen__output_dir/model/updateAddInfoFromSCResponseBody';
import {ShoppingCartElement} from '../../../swagger-gen__output_dir/model/shoppingCartElement';
import {PaymentView} from '../../../swagger-gen__output_dir/model/paymentView';
import Timer = NodeJS.Timer;
import {AppRouteValues, createDirection} from "../common/app.route.values";
import {Subscription} from "rxjs/Subscription";
import { UnsubscribeOnDestroyAbsctractClass } from '../shared/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { getErrorType } from '../services/error.helpers';

@Component({
    selector: 'cart',
    template: `
        <div class="component">
            <cart-header 
                (googleAnalyticClickButtonsEvent$)="clickAnyButtons($event)"
                [count]="cart?.items?.length"
            ></cart-header>

            <div [class]="(cart && cart.items?.length) ? 'cart__container component__container' : 'empty-cart__container component__container'">

                <h3 *ngIf="(!cart || !cart.items || cart.items.length === 0)" class="empty-list">{{ "cart.text.empty" | translate }}</h3>

                <div *ngIf="cart && cart.items && cart.items.length" class="cart-item__container">
                    <cart-item
                        *ngFor="let item of cart.items; let i = index;"
                        [testIndex]="i"
                        [data]="item"
                        [expiresIn]="item['_expiresInFormatted']"
                        (itemChange$)="handleChange($event)"
                        (removeItem$)="removeItem($event)"
                        (deliveryChange$)="deliveryChange($event)">
                    </cart-item>
                    <div id="coupon-block" *ngIf="cart && cart.items && cart.items.length">
                        <div class="cart-item">
                            <div *ngIf="!isDisabledPayment" [class]="'cart-item__coupon ' + warning">
                                <input type="text" name="coupon" data-test-id="coupon-input"
                                       minlength="6" maxlength="30" [value]="coupon_code"
                                       (focus)="onFocus($event)"
                                       (keypress)="onKeyUpInput($event)"
                                       (input)="onKeyUpInput($event)" placeholder="Enter discount coupon code"
                                       (blur)="onBlurInput($event)"
                                       (paste)="true">
                                <div class="cart-item__coupon__actions" *ngIf="coupon_code.length > 0">
                                     <button (click)="removeCouponCode()"
                                        class="remove-coupon-code-button"
                                        data-test-id="RemoveCouponCode">
                                    <div class="icon-cancel-circled-2"></div>
                                    </button>
                                    <span data-test-id="coupon-warning">
                                        <i [class]="warning === 'valid' ? 'icon-ok' : ''"></i>
                                        <i [class]="warning === 'invalid' ? 'icon-attention-alt' : ''"></i>
                                        {{couponValidation}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="cart?.discount && cart?.discount?.seller_subtotal_discount_list">
                            <div class="cart-item" *ngFor="let discount of cart.discount.seller_subtotal_discount_list; let idx1 = index" [attr.data-test-id]="'cartCouponDiscount_'+ idx1">
                                <div class="cart-item__coupon-applied">
                                    <div class="cart-item__subtotal-with-coupon cart_coupon_bold_text">
                                        <h4>{{ "cart.text.seller" | translate }}</h4>
                                        <div class="cart-item__subtotal__sellerName">
                                            <p [attr.data-test-id]="'cartDiscountItemSellerName_'+ idx1">
                                                {{ discount.sellerName }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cart-item__coupon-applied">
                                    <div class="cart-item__discount-absolute-value">
                                        <h4>{{ "cart.text.seller.subtotal" | translate }}</h4>
                                        <div>
                                            <p [attr.data-test-id]="'cartDiscountItemSellerSubtotal_'+ idx1">
                                                {{discount.subtotal / 100 | currency:'USD' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cart-item__coupon-applied">
                                    <div class="cart-item__discount-absolute-value">
                                        <h4>{{ "cart.text.discount" | translate }}</h4>
                                        <div>
                                            <p [class]="discount.discount_absolute_value > 0 ? '' : 'warning'"
                                               [attr.data-test-id]="'cartDiscountItem_'+ idx1">
                                                {{discount.discount_absolute_value / 100 | currency:'USD' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="cart?.discount" class="cart-item cart_coupon_bold_text">
                            <div class="cart-item__coupon-applied">
                                <div class="cart-item__subtotal-with-coupon">
                                    <h4>{{ "cart.text.subtotal" | translate }}</h4>
                                    <div>
                                        <p [attr.data-test-id]="'cartItemSubtotalWithCoupon'">
                                            {{ cart?.discount?.subtotal / 100 | currency:'USD' }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="cart-item__coupon-applied">
                                <div class="cart-item__discount-absolute-value">
                                    <h4>{{ "cart.text.discount" | translate }}</h4>
                                    <div>
                                        <p [attr.data-test-id]="'cartItemDiscountAbsoluteValue'">
                                            {{cart?.discount?.discount_absolute_value / 100 | currency:'USD' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="cart-item__discount-information-msg" data-test-id="perSellerMinimumPriceRequirement">{{ 'shoppinCart.cells.perSellerMinimumPriceRequirement' | translate }}</h4>
                    </div>
                </div>
            </div>


            <div data-test-id="cart-footer" class="cart__footer" *ngIf="cart">

                <div class="cart__total">
                    <div>
                        <div class="cart__total__title">
                            <span class="box-left">{{ "cart.text.total" | translate }}</span>
                            <small data-test-id="numberOfItems">{{numberOfItems | number}} {{ "cart.text.items" | translate }}</small>
                        </div>

                        <h4 data-test-id="cartTotal">{{ cartService.total / 100 | currency:'USD'}}</h4>

                        <button
                            type="button"
                            class="navbar__right nav__link"
                            role="cart"
                            data-test-id="payBtnContainer"
                            (click)="onBuy()"
                            [disabled]="cart.items?.length === 0"
                        >
                            <i class="icon-pay_icon" data-test-id="payBtn"></i>
                        </button>
                    </div>

                </div>

             </div>
        </div>
    `
})

export class CartComponent extends UnsubscribeOnDestroyAbsctractClass implements OnInit {

    public translate:          TranslateService;
    public busyBuying          = true;
    public busySaving          = false;
    public isDisabledPayment:  boolean;
    public coupon_code: string = '';
    public couponValidation    = '';
    public canActivateCoupon:    boolean = false;
    public _canDeactivate      = true;
    public cart:               ShoppingCartResponseBody;
    public THOUSAND            = 1000;
    public numberOfItems:      number;
    public _pad                = AppValues._pad;
    public total: number = 0;
    public itemTotalArray: number[] = [];
    public warning             = '';
    public sumInnerWidthHeight: number;
    public timeout:             Timer = null;

    private expiresInTimerID:       number;
    private elem:                   DOMElement;
    private TIME_OFFSET_SEC:        number;
    private countdown_timer         = 15;

    /**
     * Task:
     * To allow user see the valid status of the coupon by clicking out of
     * the coupon field/hiding the keyboard and without clicking "enter" button
     *
     * Solution:
     * Triggering open status is easy using onclick or onfocus event,
     * but on closing keyboard onblur event is not fired (because cursor remains in input/textarea).
     * So I found solution by detecting window height which is significantly changed on keyboard open/close.
     * When virtual keyboard is open, it fires windows resize event.
     * So you can check if the sum of windows width and height changed to detect keyboard is open or not.
     * @param event
     */
    @HostListener('window:resize', ['$event'])
    public onResize(event): void {
        const isMobileDevice = this.OSService.isMobileDevice();

        setTimeout(() => {
            if (isMobileDevice && this.sumInnerWidthHeight && this.coupon_code.length >= 1) {
                if (this.OSService.isDiffWindowParam(this.sumInnerWidthHeight)) {
                    this.validationCoupon(event);
                }
            }
        }, 300);
    }


    constructor(
        public cartService:                CartService,
        private itemService:                ItemService,
        private googleAnalyticsService:     GoogleAnalyticsService,
        private modalService:               ModalService,
        private userService:                UserService,
        private redirectService:            RedirectService,
        private chatService:                ChatService,
        private OSService:                  OperationSystemService,

        translate:                  TranslateService,
        elementRef:                         ElementRef
    ) {
        super();
        this.sumInnerWidthHeight = this.OSService.sumInnerWidthHeight();
        this.chatService.init();
        this.elem               = elementRef.nativeElement;
        this.TIME_OFFSET_SEC    = new Date().getTimezoneOffset() * 60;
        this.translate = translate;
    }

    public ngOnInit(): void {
        this.initCartValues();

        this.trackSubscription(
            this.cartService.onChangeTotalAmount.subscribe((total: number) => {
                if (this.total !== total) {
                    this.changeBusyBuying(total === 0);
                }
                this.total = total;
            })
        );
    }
    private changeBusyBuying(isAvailable: boolean): void {
        setTimeout(() => this.busyBuying = isAvailable);
    }

    private initCartValues(): void {
        this.isDisabledPayment = this.userService.isImpersonation;
        this.expiresInTimerID = setInterval(this.expiresInTimer.bind(this), 1000);

        this.prepareCart();
    }

    isSelectedDeliveryMethodForCart(): boolean {
        return this.cart.items.every((item: CartItemTemplateInterface) => item.delivery !== null);
    }


    private calculateTotal(): void {
        this.numberOfItems = 0;
        let total: number;

        if (!this.cart.discount) {
            total = this.itemTotalArray.reduce((sum: number, current: number) => sum + current, 0);
        } else {
            total = this.cart.discount.total;
        }

        this.itemTotalArray.forEach((ttl: number) => {
            if (ttl > 0) {
                this.numberOfItems++;
            }
        });

        this.cartService.setTotal(total);
    }

    private prepareCart(): void {
        this.trackSubscription(
            this.cartService.getCart()
            .subscribe((cart: CartTemplateInterface) => {
                cart.items = cart.items ? cart.items : [];
                this.cart = cart;

                this.cart = this.cartService.initAdditionalCartItemParams(this.cart);

                this.cartService.setCartMemento = this.cart.items;

                if (cart && cart.discount) {
                    this.coupon_code = cart.discount.coupon_code;
                    this._validOrInvalidValue(cart.discount.is_valid);
                }

                this.itemTotalArray = Array(this.cart.items.length);

                this.calculateTotal();
                this.cartService.resetAndRefreshAbandonedNotify();

            }, (err: ErrorInterface) => Observable.throwError(err))
        );
    }


    subtotalBySeller() {
        const coupon = {} as AppliedCouponExtendedView;
        coupon.coupon_code = this.cart.discount ? this.cart.discount.coupon_code : '';
        coupon.subtotal = 0;
        coupon.discount_absolute_value = 0;
        coupon.total = 0;
        coupon.seller_subtotal_discount_list = [];
        coupon.is_valid = this.cart.discount ? this.cart.discount.is_valid : false;

        const uniqueSellers = [...new Set(this.cart.items.filter(item => item.item.sellerID).map(item => item.item.sellerID))];

        uniqueSellers.forEach((id: string) => {
            const itemsBySeller = this.cart.items.filter((i: CartItemInterface) => i.item.sellerID === id);

            let subtotal = 0;
            let total = 0;

            if (itemsBySeller.length > 1) {

                itemsBySeller.forEach((i: CartItemInterface) => {

                    subtotal += i.quantity * this.itemService.itemPriceFn(i.item);
                    total += i.quantity * this.itemService.itemPriceFn(i.item);
                });
            } else {
                subtotal = itemsBySeller[0].quantity * this.itemService.itemPriceFn(itemsBySeller[0].item);
                total = itemsBySeller[0].quantity * this.itemService.itemPriceFn(itemsBySeller[0].item);
            }

            coupon.seller_subtotal_discount_list.push({
                    sellerID: itemsBySeller[0].item.sellerID,
                    sellerName: itemsBySeller[0].item.sellerName,
                    subtotal: subtotal,
                    total: total,
                    discount_absolute_value: 0,
                });
        });

        coupon.seller_subtotal_discount_list.forEach((s: DiscountsCouponObject) => {
            coupon.subtotal += s.subtotal;
            coupon.total += s.total;
        });

        this.cart.discount = coupon;
        this.calculateTotal();
    }

    removeCouponCode() {
        this.resetCoupon();
    }

    /**
     * During the initialization of the Child component,
     * this method is called with the parameters passed about each item.
     * @param event
     */
    public handleChange(event: ChangeProductInterface): void {
        const cartItems = this.cart.items;

        let index       = -1;
        let item;

        cartItems.forEach((i: CartItemInterface, idx: number) => {
            if (i.item.ID !== event.ID) return;

            index   = idx;
            item    = i;

            Object.assign(i, {
                shipping: event.shipping,
                quantity: event.quantity,
            });
        });

        this.itemTotalArray[index] = event.itemTotal;
        if (event.touched && !event.withoutRequest) {
            this.save(item);
            this._canDeactivate = false;
        }
        if (this.cart.discount && this.cart.discount.coupon_code !== '' && this.cart.discount.is_valid) {
            this.trackSubscription(
                this.applyCoupon(this.coupon_code).subscribe((is_valid: boolean) => null)
            );
        } else {
            this.subtotalBySeller();
        }

        this.cartService.setCartMemento = this.cart.items;

        setTimeout(this.calculateTotal.bind(this));
    }

    public removeItem(data: ShoppingCartElement): void {
        this.trackSubscription(
            this.cartService.removeItem(data.ID)
                .subscribe(() => {
                    this.googleAnalyticsService.handleRemoveFromCart(data.item);
                    this.initCartValues();
                    this.cartService.resetAndRefreshAbandonedNotify();
                })
        );
    }

    public deliveryChange(params: {data: ShoppingCartElement, shipping: string}): void {
        this.trackSubscription(
            this.cartService.confirmationShoppingCart().subscribe((res) => {
                if (params.shipping === AppValues.US_DELIVERY && params.data.item.deliveryOffered) {
                    this.showRedirectDialog(params.data);
                }
            }, (err: ErrorInterface) => null)
        );
    }

    public showRedirectDialog(data: ShoppingCartElement): void {
        this.modalService.warning({
            title: this.translate.instant("shoppingCart.deliveryTerms.warning.title"),
            message: this.translate.instant("shoppingCart.deliveryTerms.warning.message"),
            yesButtonText: this.translate.instant("common.yes"),
            noButtonText: this.translate.instant("common.no"),
            reverseButtons: true,
        }).then((action: any) => {
            if (action) {
                this.contactSeller(data.item.sellerID);
            }
        });
    }

    public contactSeller(sellerID: string) {
        this.chatService.goToDialog(sellerID);
    }


    private save(item: CartItemInterface): void {
        this.busySaving = true;
        const part      = {items: [item]};

        this.trackSubscription(
            this.cartService.save(part)
                .subscribe((shoppingCart: UpdateAddInfoFromSCResponseBody) => {
                    this.busySaving     = false;
                    this._canDeactivate = false;
                    this.cart.items     = shoppingCart.elements.sc_elements;

                    this.cartService.setCartMemento = this.cart.items;
                }, (err: ErrorInterface) => this._canDeactivate = true)
        );
    }


    /**
     * @example Test code to get human readable time:
     * {ms = 1507290089 * 1000; const d = new Date(ms); console.log(d.getHours(), d.getMinutes(), d.getSeconds())}
     */
    expiresInTimer() {
        let cart = this.cart;
        if (!cart || !cart.items) return;

        cart.items.forEach((item: CartItemInterface) => {

            const time_to_sec = Math.floor(this.countdown_timer * 60);

            // for debugging purpose
            // const time_to_sec = Math.floor(1 * 60) + this.TIME_OFFSET_SEC;

            item['_expiresIn'] = item.addedAt + time_to_sec - Math.round(Date.now() / this.THOUSAND);

            let expiresIn = item['_expiresIn'];

            if (expiresIn > 0) {
                let min = (expiresIn / 60) | 0;
                let sec = (expiresIn % 60) | 0;

                // for debugging purpose
                // let hrs = (expiresIn / 3600) | 0;
                // console.log(`${this._pad(hrs)} : ${this._pad(min)} : ${this._pad(sec)}`)

                item['_expiresInFormatted'] = `${this._pad(min)} : ${this._pad(sec)}`;

            } else {
                item['_expiresInFormatted'] = '00:00';
            }
        });
    }


    public onFocus(event: FocusEvent): void {
        this.canActivateCoupon = true;
        if (this.userService.isGuestMode) {
            event.preventDefault();
            // @ts-ignore
            event.target.blur();
        }
    }


    /**
     * check the entered coupon when pressing 'Enter'
     * @param event
     */
    onKeyUpInput(event) {
        this.coupon_code  = this.formatCouponCode(event.target.value);

        if (event.keyCode === 13 || event.key === 'Enter') {
            this.validationCoupon(event);
        }
    }

    onBlurInput(event) {
        if (this.canActivateCoupon) {
            this.coupon_code = this.formatCouponCode(event.target.value);

            this.validationCoupon(event);
        }
    }

    /**
     * @desc all symbols must be capitalized
     * @param {string} coupon
     * @return {string}
     */
    formatCouponCode(coupon: string): string {
        return coupon.toUpperCase();
    }

    validationCoupon(event) {
        event.preventDefault();
        event.target.blur();
        this.canActivateCoupon = false;

        if (this.userService.isGuestMode) {
            this.resetCouponAndValidation();
            return false;
        }

        this.coupon_code.trim();

        if (this.coupon_code.length === 0) {
            this.resetCoupon();
            return false;
        }


        if (this.coupon_code.length < 6) {
            this._validOrInvalidValue(false);
            this.invalidCouponAction();

            this.modalService.error({
                title: this.translate.instant("shoppingCart.coupon.warning.title"),
                message: this.translate.instant("shoppingCart.coupon.warning.message"),
                yesButtonText: 'Close',
            });

        } else {
            this.trackSubscription(
                this.applyCoupon(this.coupon_code).subscribe((is_valid: boolean) => this._validOrInvalidValue(is_valid))
            );
        }
    }

    invalidCouponAction() {
        this.subtotalBySeller();
        this.calculateTotal();
    }

    private resetCoupon(): void {
        this.modalService.showSpinner();

        this.trackSubscription(
            this.cartService.applyCouponDelete().subscribe((res: EmptyDict) => {
                this.resetCouponAndValidation();
    
                this.subtotalBySeller();
    
                this.modalService.close();
            }, (err: ErrorInterface) => null)
        );
    }

    private resetCouponAndValidation(): void {
        this.couponValidation = '';
        this.warning = '';
        this.coupon_code = '';
        this.cart.discount.coupon_code = '';
    }


    /**
     * Method for subscribe to applyCoupon request
     * @param {string} coupon_code
     * @returns {Subscription}
     * @private
     */
    private applyCoupon(coupon_code: string): Observable<boolean> {
        return new Observable(observer => {

            this.cartService.applyCoupon(coupon_code)
                .subscribe((res: AppliedCouponView) => {
                    this.cart.discount = CouponAdapterClass.adapteForAppliedCouponExtendedView(Object.assign(res, {coupon_code: coupon_code}), true);

                    this._validOrInvalidValue(true);
                    this.calculateTotal();
                    observer.next(true);

                }, (err: HttpErrorResponse) => {
                    this.cart.discount = CouponAdapterClass.adapteForAppliedCouponExtendedView(Object.assign(this.cart.discount, {coupon_code: coupon_code}), false);

                    this._validOrInvalidValue(false);
                    this.invalidCouponAction();

                    this.modalService.error({
                        title: (err.status === 400)
                            ? this.translate.instant("shoppingCart.error.title")
                            : this.translate.instant("applyCoupon.warning.title"),
                        message: getErrorType(err),
                        yesButtonText: this.translate.instant("modal.warning.yesButton"),
                    });
                    observer.next(false);
                });
        });
    }

    /**
     * Method for set value and view on template them
     * @param {boolean} isValid
     * @private
     */
    _validOrInvalidValue(isValid: boolean) {
        const type: string = isValid ? 'valid' : 'invalid';
        this.couponValidation = type.charAt(0).toUpperCase() + type.substr(1);
        this.warning = type;
    }

    onBuy() {
        if (this.isDisabledPayment) {
            this.modalService.warning({
                title: this.translate.instant("modal.warning.title"),
                message: this.translate.instant("common.impersonated.pay"),
                yesButtonText: 'Cancel'
            });
            return;
        }

        if (!this.isSelectedDeliveryMethodForCart()) {
             this.modalService.warning({
                title: this.translate.instant("modal.warning.title"),
                message:  this.translate.instant("shoppingCart.error.deliveryMethod"),
                yesButtonText: 'Cancel'
            });
            return;
        }

        this.checkCouponAndPurchase();
    }

    private checkCouponAndPurchase(): void {
        if (!this.cartService.isValidCart()) return;

        this.trackSubscription(
            this.cartService.getCart()
                .subscribe((cart: CartTemplateInterface) => {
                    this.cart = cart;
                    this.cart.items = this.cartService.changingOfCartItemPrices(this.cartService.getCartMemento, cart.items);

                    if (this.cartService.isChangingOfCartItemPrices(this.cart)) {
                        this.modalService.warning({
                            title: this.translate.instant('common.alert.title'),
                            message: this.translate.instant('shoppingCart.CartItemPriceWasChange.warning.text'),
                            yesButtonText: this.translate.instant('common.ok')
                        });
                        this.cartService.setCartMemento = this.cart.items;
                        return;
                    }

                    if (this.coupon_code) {
                        this.applyCoupon(this.coupon_code).subscribe((is_valid: boolean) => {
                            is_valid && this.purchaseCart();
                        });
                    } else {
                        this.purchaseCart();
                    }
                })
        );
    }
    private purchaseCart(): void {
        this.modalService.showSpinner();
        this.trackSubscription(
            this.cartService.onChangePaymentView
                .subscribe((paymentView: null) => this.switchPayBlock())
        );

        this.googleAnalyticsService.handleClickButton('cart_buy_button' + '_pressed', 'purchase', 'click');
        this.cartService.onConfirmationShoppingCartAndBuy({isPayment: true, isPayFromCart: true});
    }

    public switchPayBlock(): void {
        this.prepareCart();
        this.modalService.close();
        this.redirectService.goTo(createDirection(AppRouteValues.routePayment));
    }

    clickAnyButtons(name: string) {
        this.googleAnalyticsService.handleClickButton(name + '_pressed', 'purchase', 'click');
        this.redirectService.goBack();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.chatService.stopPolling();
        clearInterval(this.expiresInTimerID);
    }

}
export class CouponAdapterClass {
    static adapteForAppliedCouponExtendedView(discount: AppliedCouponView, is_valid: boolean): AppliedCouponExtendedView {
        return {
                is_valid, coupon_code: discount.coupon_code,
                subtotal: discount.subtotal, discount_absolute_value: discount.discount_absolute_value,
                total: discount.total, seller_subtotal_discount_list: discount.seller_subtotal_discount_list
            };
    }
}
