import {
    Component, EventEmitter,
    Input, Output
}                           from '@angular/core';

import { EventInterface }   from '../../interfaces/market.interface';

@Component({
    selector:   'event-widget',
    styleUrls:  ['../product-editor.sass', '../../../assets/styles/sprite.sass'],

    template:   `
        <section class="product-editor__fields">
            <span class="product-editor__add-event" (click)="onAddClick()" data-test-id="addEventElem">
                <h4>Add Event</h4>
                <i class="icon-plus-circle"></i>
            </span>
        </section>

        <div>
            <section class="product-editor__fields event-widget" *ngFor="let event of events; trackBy: trackByID; let i = index">
                <h3 
                    class="event-widget__title"
                    (click)="onEventClick(event)"
                    [attr.data-test-id]="'eventTitle_'+i"
                >
                    {{event.title}}
                </h3>
                <span class="event-widget__remove">
                    <i
                        class="icon-trash-empty" 
                        (click)="onRemoveClick(i, $event)" 
                        [attr.data-test-id]="'removeEvent_'+i"
                    ></i>
                </span>
            </section>
        </div>
    `
})
/**
 * Represents events list and `add\remove event` handlers.
 */
export class EventWidgetComponent {
    @Input()    events:             EventInterface[];
    @Output()   eventWidgetEvent$   = new EventEmitter();


    /**
     * @emits eventWidgetEvent$ of `addEvent` command.
     */
    onAddClick(): void {
        this.eventWidgetEvent$.emit({addEvent: null});
    }


    /**
     * @emits eventWidgetEvent$ of `showEvent` command.
     */
    onEventClick(event: EventInterface): void {
        this.eventWidgetEvent$.emit({showEvent: {event}});
    }


    /**
     * @emits eventWidgetEvent$ of `removeEvent` command.
     */
    onRemoveClick(index: number, event: Event): void {
        event.stopPropagation();

        this.eventWidgetEvent$.emit({removeEvent: index});
    }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {EventInterface} item
     * @return {string}
     */
    trackByID(index: number, item: EventInterface): string {
        return item.item_id;
    }

}
