import {
    Injectable,
    Injector
} from "@angular/core";

import { Observable } from "rxjs/Observable";
import { fromPromise } from "rxjs/observable/fromPromise";
import { of } from "rxjs/observable/of";
import {
    filter,
    map,
    switchMap,
    tap
} from "rxjs/operators";

import { MapsAPILoader } from "@agm/core";
import { TranslateService } from "@ngx-translate/core";

import { Address } from "../../../swagger-gen__output_dir";
import AppValues from "../common/app.values";
import { CenterLocationInterface } from "../interfaces/location.interface";
import { AddressInterface } from "../interfaces/user.interface";
import { ModalService } from "../modal/modal.service";
import { GeoLocationService } from "./geolocation.service";

declare var google: any;
export interface ReverseGeocodingAddressInterface {
    address_components: AddressComponentInterface[];
    formatted_address: string;
    geometry: {
        location: {
            lat(): number,
            lng(): number
        },
        location_type: string,
        viewport: {
            south: number,
            west: number,
            north: number,
            east: number
        }
    };
    place_id: string;
    types: string[];
}
export interface AddressComponentInterface {
    long_name: string;
    short_name: string;
    types: string[];
}

@Injectable()
export class GeoCoderService {
    private geocoder: any;

    constructor(private geoLocationService: GeoLocationService,
                private injector: Injector,
                private mapLoader: MapsAPILoader) {

    }

    private initGeocoder() {
        this.geocoder = new google.maps.Geocoder();
    }

    private waitForMapsToLoad(): Observable<boolean> {
        if (!this.geocoder) {
            return fromPromise(this.mapLoader.load())
                .pipe(
                    tap(() => this.initGeocoder()),
                    map(() => true)
                );
        }
        return of(true);
    }

    private get modalService(): ModalService {
        return this.injector.get(ModalService);
    }
    private get translate(): TranslateService {
        return this.injector.get(TranslateService);
    }


    private geocoderErrorModal(errorText: string): void {
        let message: string = errorText;
        
        if (errorText.includes("ZERO_RESULTS")) {
            message = this.translate.instant('geocoder.noResults.error.message');
        }

        this.modalService.error({
            title: this.translate.instant('geocoder.noResults.error.title'), 
            message: message, 
            yesButtonText: this.translate.instant('geocoder.noResults.error.button')
        });
    }

    getFullAddressString(address: Address): string {
        return address.address1 + ' ' + address.address2 + ' '
            + address.city + ' ' + address.state + ' ' + address.country;
    }


    geocodeAddress(address: string): Observable<CenterLocationInterface> {
        return this.waitForMapsToLoad().pipe(
            filter(loaded => loaded),
            switchMap(() => {
                return new Observable(observer => {
                    this.geocoder.geocode({
                        'address': address,
                        'language': 'en'
                    }, (results: ReverseGeocodingAddressInterface[], status: string) => {

                        if (status === google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                observer.next({
                                    latitude: results[0].geometry.location.lat(),
                                    longitude: results[0].geometry.location.lng()
                                });
                            } else {
                                this.geocoderErrorModal(status);
                                observer.error(status);
                            }
                        } else {
                            console.log('Error - ', results, ' & Status - ', status);
                            this.geocoderErrorModal(status);
                            observer.error(status);
                        }
                        observer.complete();
                    });
                });
            })
        );
    }

    reverseGeocodingAddress(location: CenterLocationInterface): Observable<ReverseGeocodingAddressInterface> {
        let latlng = {
            lat: location.latitude,
            lng: location.longitude
        };

        return this.waitForMapsToLoad().pipe(
            filter(loaded => loaded),
            switchMap(() => {
                return new Observable(observer => {
                    this.geocoder.geocode({
                        'location': latlng,
                        'language': 'en'
                    }, (results: ReverseGeocodingAddressInterface[], status: string) => {
                        if (status === google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                observer.next(results[0]);
                            } else {
                                observer.error(this.translate.instant('geocoder.map.noResults.error.message'));
                            }
                        } else {
                            observer.error(this.translate.instant('geocoder.map.noResults.failed.message') + status + ". " + this.translate.instant('geocoder.map.noResults.failed.try'));
                        }
                        observer.complete();
                    });
                });
            })
        );
    }

    /**
     * Address types and address component types
     * The types[] array in the result indicates the address type.
     * Examples of address types include a street address, a country, or a political entity.
     * There is also a types[] array in the address_components[], indicating the type of each part of the address.
     * Examples include street number or country. (Below is a full list of types.)
     * Addresses may have multiple types. The types may be considered 'tags'.
     * For example, many cities are tagged with the political and the locality type.
     * https://developers.google.com/maps/documentation/geocoding/overview#Types
     * @param {AddressInterface} user_address
     * @param {AddressComponentInterface[]} address_components
     * @return {AddressInterface}
     */
    public getFormatGoogleAddress(user_address: AddressInterface, address_components: AddressComponentInterface[]): AddressInterface {
        address_components.forEach((addr: AddressComponentInterface) => {
            addr.types.forEach((t: string) => {
                if (t === 'country') {
                    user_address.country = AppValues.getCountryName(addr.short_name);
                }
                if (t === 'postal_code') {
                    user_address.postCode = addr.long_name;
                }
                if (t === 'administrative_area_level_1') {
                    user_address.state = addr.short_name;
                }
                if (t === 'locality' || t === 'sublocality_level_1' ) {
                    user_address.city = addr.long_name;
                }
                if (t === 'neighborhood') {
                    user_address.address2 = addr.long_name;
                }

                if (t === 'street_number') {
                    user_address.address1 = addr.long_name + ' ';
                }
                if (t === 'route') {
                    user_address.address1 += addr.long_name;
                }
            });
        });

        return user_address;
    }

}
