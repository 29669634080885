/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inventory-list.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./inventory-list-item.component.ngfactory";
import * as i3 from "./inventory-list-item.component";
import * as i4 from "../services/item.service";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./inventory-header.component.ngfactory";
import * as i8 from "./inventory-header.component";
import * as i9 from "../bizipix-editor/bizipix-editor.service";
import * as i10 from "../modal/modal.service";
import * as i11 from "@angular/router";
import * as i12 from "./inventory-list.component";
import * as i13 from "./inventory-list.service";
import * as i14 from "../product-editor/product-editor.service";
var styles_InventoryListComponent = [i0.styles];
var RenderType_InventoryListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InventoryListComponent, data: {} });
export { RenderType_InventoryListComponent as RenderType_InventoryListComponent };
function View_InventoryListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "inventory-list-item", [], null, [[null, "showItemEvent$"], [null, "removeItemEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showItemEvent$" === en)) {
        var pd_0 = (_co.onShow(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("removeItemEvent$" === en)) {
        var pd_1 = (_co.onRemove(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_InventoryListItemComponent_0, i2.RenderType_InventoryListItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.InventoryListItemComponent, [i4.ItemService], { item: [0, "item"], index: [1, "index"] }, { removeItemEvent$: "removeItemEvent$", showItemEvent$: "showItemEvent$" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.index; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InventoryListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InventoryListComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InventoryListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "empty-list"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("inventoryList.empty")); _ck(_v, 1, 0, currVal_0); }); }
export function View_InventoryListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "inventory-header", [], null, null, null, i7.View_InventoryHeaderComponent_0, i7.RenderType_InventoryHeaderComponent)), i1.ɵdid(2, 49152, null, 0, i8.InventoryHeaderComponent, [i9.BizipixEditorService, i10.ModalService, i11.Router], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "abstract-list__container component__container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InventoryListComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InventoryListComponent_3)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isArrayItems(_co.items) && (((_co.items == null) ? null : _co.items.length) !== 0)); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.isArrayItems(_co.items) && (((_co.items == null) ? null : _co.items.length) === 0)); _ck(_v, 7, 0, currVal_1); }, null); }
export function View_InventoryListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "inventory-list", [], null, null, null, View_InventoryListComponent_0, RenderType_InventoryListComponent)), i1.ɵdid(1, 1294336, null, 0, i12.InventoryListComponent, [i1.ElementRef, i11.ActivatedRoute, i13.InventoryListService, i14.ProductEditorService, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InventoryListComponentNgFactory = i1.ɵccf("inventory-list", i12.InventoryListComponent, View_InventoryListComponent_Host_0, {}, {}, []);
export { InventoryListComponentNgFactory as InventoryListComponentNgFactory };
