import { Component }                    from '@angular/core';

import { ModalService }                 from '../../modal/modal.service';
import { ProductEditorParentClass }     from './product-editor-abstract.class';
import { ProductEditorService }         from '../product-editor.service';
import { SaveProduct }                  from '../../interfaces/product.interface';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector:       'new-item-editor',
    styleUrls:      ['../product-editor.sass', '../../../assets/styles/sprite.sass'],
    templateUrl:    './product-editor.component.html'
})
/**
 * Represents editor for a brand new item.
 * @extends ProductEditorParentClass.
 */
export class NewItemEditorComponent extends ProductEditorParentClass {

    isNewItem = true;
    productEditorService:   ProductEditorService;

    constructor(
        modalService:           ModalService,
        productEditorService:   ProductEditorService,
        translate:              TranslateService,
    ) {
        super(modalService, productEditorService, translate);
    }

    createTitleText(): void {
        this.title = this.translate.instant("editor.title.product");
    }


    /**
     * Calls the service to cancel editing.
     */
    onClose(): void {
        this.productEditorService.cancelEditing();
    }


    /**
     * If the parent's self-called method returns true, calls the callback with `saveItem` command object.
     */
    onSave(): void | boolean {
        if (!super.onSave()) return;

        this.item.current_quantity = this.item.qty;

        const obj: SaveProduct = { item: this.item };
        this.context.callback({ saveItem: obj });
    }

}
