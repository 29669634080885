/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rating.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./rating.component";
var styles_RatingComponent = [i0.styles];
var RenderType_RatingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RatingComponent, data: {} });
export { RenderType_RatingComponent as RenderType_RatingComponent };
function View_RatingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [], [[1, "data-test-id", 0], [1, "data-icon", 0], [8, "className", 0]], [[null, "mouseenter"], [null, "mousemove"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.setHovered(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mousemove" === en)) {
        var pd_1 = (_co.changeHovered($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.rate(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("detailRateStars" + _v.context.index); var currVal_1 = _co.fullIcon; var currVal_2 = i1.ɵinlineInterpolate(2, "", _co.iconClass, " half", _co.calculateWidth(_v.context.$implicit), ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.emptyIcon; _ck(_v, 2, 0, currVal_3); }); }
export function View_RatingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["aria-valuemin", "0"], ["class", "rating"], ["role", "slider"], ["tabindex", "0"]], [[2, "disabled", null], [2, "readonly", null], [2, "rating-write", null], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0]], [[null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseleave" === en)) {
        var pd_0 = (_co.resetHovered() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RatingComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.ratingRange; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.readonly; var currVal_2 = _co.ratingWrite; var currVal_3 = _co.ratingRange.length; var currVal_4 = _co.rating; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_RatingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "rating", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_RatingComponent_0, RenderType_RatingComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.RatingComponent]), i1.ɵprd(5120, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.RatingComponent]), i1.ɵdid(3, 114688, null, 0, i4.RatingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var RatingComponentNgFactory = i1.ɵccf("rating", i4.RatingComponent, View_RatingComponent_Host_0, { iconClass: "iconClass", fullIcon: "fullIcon", emptyIcon: "emptyIcon", readonly: "readonly", disabled: "disabled", required: "required", float: "float", titles: "titles", ratingWrite: "ratingWrite", rating: "rating", max: "max" }, { onHover: "onHover", onLeave: "onLeave" }, []);
export { RatingComponentNgFactory as RatingComponentNgFactory };
