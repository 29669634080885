
        <div class="component">
            <user-header></user-header>

            <div class="component__container">
                <div class="form__container">

                    <form #loginForm="ngForm" (ngSubmit)="login($event)">

                        <div role="mainForm" class="login__container__content">
                            <figure class="login__container-logo">
                                <div class="login__logo">
                                <img height="58" width="232" src="assets/images/bizibaza-logo.svg" alt="bizibaza&reg;"> 
                                </div>
                            </figure>

                            <label class="input__label clearfix">
                                <input [ngClass]="{ error: loginErr && !username }"
                                       (keyup)="onInputTrimed($event, $event.target.name)"
                                       (blur)="bluredField($event, $event.target.name)"
                                       data-test-id="username" autocapitalize="none" autocomplete="username"
                                       class="input__text" type="text"
                                       [placeholder]="'login.placeholder' | translate" name="username" required autofocus [(ngModel)]="username">
                            </label>

                            <password-field [password]="password" [nameInput]="'password'"
                                            [dataTestIdInput]="'password'"
                                            [isPassErr]="passErr"
                                            (keyUpField)="onPasswordFieldBlurred($event)"
                                            (bluredField)="onPasswordFieldBlurred($event)"></password-field>

                            <checkbox-field [forInput]="'keepLogged'" [dataTestIdInput]="'keepLogged'"
                                            [isCheckboxON]="keepLogged" (onChangeField)="onChangeKeepLogin()"
                                            [textLabel]="'login.keepMeLoggedIn.text' | translate"></checkbox-field>

                            <button class="button__lightgreen login__button" type="submit"
                                    data-test-id="loginBtn"
                                    [disabled]="busy || !password || !username">
                                {{"loginForm.submitButton.text" | translate}}
                            </button>

                            <p>
                                {{ "signup.account.text" | translate }}
                                <button
                                    class="nav__link" type="button"
                                    [routerLink]="'/signup'"
                                    data-test-id="signupBtn"
                                >
                                    {{ "signup.account.link" | translate }}
                                </button>
                            </p>
                        </div>
                    </form>

                </div>
                <p class="resend-email">
                    <button
                        class="nav__link" type="button"
                        [routerLink]="'/email-verification'"
                        data-test-id="resendBtn"
                    >
                        {{ "forgotEmailVerification.button.text" | translate }}
                    </button>
                </p>
                <div class="login__footer">
                    <button
                        #loginLink
                        class="button__default"
                        [routerLink]="['/login-link']"
                        name="login_link_button"
                        data-test-id="loginLinkBtn" type="button"
                        (click)="onClickAnyButtons($event.target.name, 'login_link')"
                    >
                        {{ "forgotLogIn.button.text" | translate }}
                    </button>

                    <button
                        class="button__default"
                        [routerLink]="['/password-reset']"
                        name="forgot_password_button"
                        data-test-id="forgotBtn" type="button"
                        (click)="onClickAnyButtons($event.target.name, 'forgot_password')"
                        role="forgotLink"
                    >
                        {{ "forgotPassword.button.text" | translate }}
                    </button>
                </div>

            </div>
        </div>
    