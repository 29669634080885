import { ElementRef, Injector, OnInit } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { UserService } from './user.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { Router } from '@angular/router';
import { AppFooterService } from "../app-footer/app-footer.service";
import { TranslateService } from "@ngx-translate/core";
import { AppRouteValues } from '../common/app.route.values';
import { getErrorType } from '../services/error.helpers';
// import { fadeInAnimation }                  from '../common/animations/animations';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(elementRef, googleAnalyticsService, modalService, userService, injector, translate) {
        this.elementRef = elementRef;
        this.googleAnalyticsService = googleAnalyticsService;
        this.modalService = modalService;
        this.userService = userService;
        this.injector = injector;
        this.translate = translate;
        this.busy = false;
        this.count_of_event = {
            username: 0,
            password: 0
        };
        this.keepLogged = true;
        this.password = '';
        this.username = '';
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.elem = this.elementRef.nativeElement;
        this.redirectUrl = this.userService.getRedirectUrl;
    };
    LoginComponent.prototype.onChangeKeepLogin = function () {
        this.keepLogged = !this.keepLogged;
    };
    LoginComponent.prototype.bluredField = function (event, name) {
        var obj_key = Object.keys(this.count_of_event);
        if ((this.username !== '' || this.password !== '') &&
            (event.currentTarget.form[0].value === this.username || event.currentTarget.form[1].value === this.password)) {
            for (var i = 0; i < obj_key.length; i++) {
                if (this.count_of_event[obj_key[i]] === 0) {
                    this.googleAnalyticsService.handleVirtualPage('filled_' + obj_key[i]);
                    this.count_of_event[obj_key[i]]++;
                }
            }
        }
        else if (event.currentTarget.value !== '' && (event.currentTarget.form[0] !== this.username || event.currentTarget.form[1] !== this.password)) {
            for (var i = 0; i < obj_key.length; i++) {
                if (obj_key[i] === name && this.count_of_event[name] === 0) {
                    this.googleAnalyticsService.handleVirtualPage('filled_' + name);
                    this.count_of_event[name]++;
                }
            }
        }
        else
            return;
    };
    LoginComponent.prototype.onInputTrimed = function (event, name) {
        this[name] = this[name].trim();
        this.bluredField(event, name);
    };
    LoginComponent.prototype.onClickAnyButtons = function (name, category) {
        this.googleAnalyticsService.handleClickButton(name + '_pressed', category, 'click');
    };
    LoginComponent.prototype.login = function (event) {
        var _this = this;
        this.busy = true;
        this.resetFlags();
        this.modalService.showSpinner();
        var creds = {
            login: this.username,
            password: this.password
        };
        this.googleAnalyticsService.handleClickUserLoginButton('login', 'login_button_pressed', creds);
        this.userService.loggedUserFromComponentSetter(true);
        this.userService
            .login(creds, this.keepLogged)
            .subscribe(function () {
            _this.app_footer_service.clearShortNavigationTree();
            _this.app_footer_service.getNavTree(false).subscribe(function () {
                _this._hideSpinner();
                _this.router.navigate([_this.getAfterLoginRedirectLink()]).then(function () {
                    _this.userService.setRedirectUrl('');
                })
                    .catch(function (err) {
                    _this.handleLoginError(err);
                });
            });
        }, function (err) {
            _this._hideSpinner();
            _this.handleLoginError(err);
        });
        event && event.preventDefault();
        return false;
    };
    Object.defineProperty(LoginComponent.prototype, "router", {
        /**
         * @desc This function creates router property on your service.
         *
         * The problem is that Router can async load some routes.
         * This is why it needs Http.
         * Your Http depends on Router and Router depends on Http.
         * Angular injector is not able to create any of these services.
         * I had similar problems and one of the solutions can be injecting
         * Injector instead of service and getting service afterwards.
         * @return {Router}
         */
        get: function () {
            return this.injector.get(Router);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "app_footer_service", {
        get: function () {
            return this.injector.get(AppFooterService);
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.getAfterLoginRedirectLink = function () {
        return "/" + (this.redirectUrl || AppRouteValues.shoppingList);
    };
    LoginComponent.prototype._hideSpinner = function () {
        this.busy = false;
        this.modalService.close();
    };
    LoginComponent.prototype.handleLoginError = function (err) {
        var message, errMsg = getErrorType(err);
        if (/^user/i.test(errMsg)) {
            message = "User " + this.username + " doesn't exist";
            this.loginErr = true;
            this.username = '';
            this.elem.querySelector('input[name="username"]').focus();
        }
        else if (/password/i.test(errMsg)) {
            this.modalService.error({
                title: 'Error:',
                message: errMsg,
                yesButtonText: 'Close',
            });
            message = this.translate.instant('login.error.givenPasswordIsNotTheSameAsCurrentOne');
            this.passErr = true;
            this.password = '';
            this.elem.querySelector('input[name="password"]').focus();
        }
        this.modalService.error({
            title: this.translate.instant('login.error.unable.title'),
            message: message || errMsg,
            yesButtonText: this.translate.instant('login.error.unable.confirm'),
        });
    };
    LoginComponent.prototype.onPasswordFieldBlurred = function (event) {
        this[event.target['name']] = event.target['value'];
        this.bluredField(event, event.target['name']);
    };
    LoginComponent.prototype.resetFlags = function () {
        this.loginErr = false;
        this.passErr = false;
    };
    return LoginComponent;
}());
export { LoginComponent };
