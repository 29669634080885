export const environment = {
    production: true,
    envName: 'prod',
    fullEnvName: 'production',
    landingUrl: 'https://bizibaza.com',
    apiUrl: 'https://bizibaza.com/api/v1/',
    version: require('../../package.json').version,
    GA_TRACKING_ID: 'UA-133173929-1',
    GM_API_KEY: 'AIzaSyBj15wlUiB5atVoS8_HYrsk4VliVS9-HEk',
    STRIPE_PUBLISHABLE_KEY: 'pk_live_51IdjV4AUJAsgvAn1Y41j97iLVbXbeKxUYy7djdWTgrCOumejCLg5A14B7aClPpJQzHVQCuavUsKxte4dD1LI7sY200zUmEpvM4',
    projectName: require('../../package.json').name,
};
