import { Component,
    EventEmitter, Input,
    Output }                from '@angular/core';
import {Location} from '@angular/common';


@Component({
    selector:   'inventory-nav-header',
    styleUrls:  ['goods-nav.sass'],

    template:   `
        <header class="app-header">

            <nav class="app-header__container" role="goods-nav">

                <div class="navbar__left">
                    <a (click)="location.back()" data-test-id="inventoryLink"
                       class="nav__link"><i class="icon-custom_back_icon"></i></a>
                </div>

                <div class="navbar__center" role="inventory-nav">
                    <h4>{{categoryName}}</h4>
                </div>

                <div class="navbar__right" data-test-id="nextLink" 
                     [ngClass]="{'active': isActive}" (click)="onNext()">
                    <span class="text-link">
                        {{ "inventory.nav.next" | translate }}
                    </span>
                    <span class="nav__link sprite sprite-map_callout_arrow_2x"></span>
                </div>
            </nav>

        </header>
    `
})


export class InventoryNavHeaderComponent {
    @Input()    categoryName:       string;
    @Input()    isActive:           boolean;
    @Output()   createItemEvent$    = new EventEmitter();

    location:   Location;

    constructor( location: Location) {
        this.location = location;
    }

    onNext() {
        this.isActive && this.createItemEvent$.emit();
    }

}
