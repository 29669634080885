import { OnInit, QueryList } from '@angular/core';
import AppValues from '../../common/app.values';
import { TimePickerComponent } from '../additional/time-picker.component';
var ProductEditorParentClass = /** @class */ (function () {
    function ProductEditorParentClass(modalService, productEditorService, translate) {
        this.isEventEditor = false;
        this.isNewItem = false;
        this.countries = AppValues.countries;
        this.HOUR = AppValues.ONE_HOUR;
        this.HUNDRED = AppValues.HUNDRED;
        this.onlyNumberPattern = AppValues.onlyNumberPattern;
        this.modalService = modalService;
        this.productEditorService = productEditorService;
        this.translate = translate;
    }
    /**
     * Fetches units of measure object and the product item if specified.
     */
    ProductEditorParentClass.prototype.ngOnInit = function () {
        this.item = Object.assign({}, this.context.item);
        this.units = this.context.units;
        this.dateRange = this.context.dateRange;
        this.timeDatesInfo = this.translate.instant("market.timeDates.info");
        this.createFieldsText();
        this.createLabelText();
        this.createTitleText();
        this.updateProductionDateState(this.item.productionDate > 0);
    };
    ProductEditorParentClass.prototype.createFieldsText = function () {
        this.requiredFields = {
            title: this.translate.instant('product.editor.error.empty.title'),
            priceUnitsName: this.translate.instant('product.editor.error.empty.priceUnits'),
            qty: this.translate.instant('product.editor.error.empty.qty'),
            originCountry: this.translate.instant('product.editor.error.empty.country')
        };
        this.saleFields = {
            salePrice: this.translate.instant('product.editor.error.empty.salePrice')
        };
        this.priceFields = {
            price: this.translate.instant('product.editor.error.empty.productPrice')
        };
        this.allNumberFields = {
            current_quantity: this.isEventEditor ? this.translate.instant('product.editor.error.empty.eventQty') : this.translate.instant('product.editor.error.empty.currentQty'),
            qty: this.translate.instant('product.editor.error.empty.totalQty'),
        };
    };
    ProductEditorParentClass.prototype.createTitleText = function () { };
    ProductEditorParentClass.prototype.createLabelText = function () {
        this.dateRangeFromLabel = this.getDatePipeTransform(this.dateRange.from);
        this.dateRangeToLabel = this.getDatePipeTransform(this.dateRange.to);
        if (this.item) {
            this.validFromLabel = this.getDatePipeTransform(this.item.valitFrom);
            this.validToLabel = this.getDatePipeTransform(this.item.validTill);
        }
    };
    ProductEditorParentClass.prototype.getDatePipeTransform = function (date) {
        return AppValues.datePipeTransform(new Date(date * 1000), 'MMM-dd-yyyy HH:mm');
    };
    /**
     * @desc Is being called on input fields events. Determines the type of event
     * and delegates for image uploading or item data update.
     * @param obj
     * @param fieldName
     */
    ProductEditorParentClass.prototype.onDataChange = function (obj, fieldName) {
        var _this = this;
        if (obj.imageFile) {
            this.productEditorService.uploadImg(obj.imageFile)
                .subscribe(function (imageURL) {
                return _this.updateItem(fieldName, imageURL);
            });
            return;
        }
        if (obj.role) {
            // if (typeof obj.value !== 'string' && typeof obj.value !== 'number')
            //     window.console.error(`Unexpected type for "${obj.role}" field`);
            if (obj.role === 'price') {
                this.updateItem(obj.role, +obj.value * this.HUNDRED || '');
            }
            this.updateItem(obj.role, obj.value);
        }
    };
    /**
     * @desc Validates the input; if invalid, resets the input and calls to display warning.
     * @param {Event} event
     * @param {number} minimumFractionDigits
     * @param {number} maximumFractionDigits
     * @return {boolean}
     */
    ProductEditorParentClass.prototype.onPriceChange = function (event, minimumFractionDigits, maximumFractionDigits) {
        if (minimumFractionDigits === void 0) { minimumFractionDigits = 0; }
        if (maximumFractionDigits === void 0) { maximumFractionDigits = 0; }
        var target = event.target;
        var formatedNumber = AppValues.NumberUSFormat({
            value: target['value'],
            minimumFractionDigits: minimumFractionDigits,
            maximumFractionDigits: maximumFractionDigits
        });
        var isValid = this.onlyNumberPattern.test(formatedNumber.formatedNumber.trim()) && formatedNumber.formatedNumber.trim();
        if (isValid) {
            var value = void 0;
            if (target['name'].toLowerCase().includes('price')) {
                value = parseInt((formatedNumber.num * this.HUNDRED).toFixed(0), 0);
            }
            else {
                value = parseInt(formatedNumber.num.toFixed(0), 0);
            }
            if (formatedNumber.num > 99999999) {
                formatedNumber = AppValues.NumberUSFormat({
                    value: "99999999",
                    minimumFractionDigits: minimumFractionDigits,
                    maximumFractionDigits: maximumFractionDigits
                });
                target['value'] = formatedNumber.formatedNumber;
                this.updateItem(target['name'], 99999999);
                return this.invalidFieldScenario(target);
            }
            target['value'] = formatedNumber.formatedNumber;
            this.updateItem(target['name'], value);
            return true;
        }
        else {
            target['value'] = '';
            this.updateItem(target['name'], 0);
            return this.invalidFieldScenario(target);
        }
    };
    ProductEditorParentClass.prototype.invalidFieldScenario = function (target) {
        if (this.allPriceFields[target['name']]) {
            this._showWarning(this.translate.instant('productEditor.error.invalidProductPriceFieldValues', { invalid_fields: this.allPriceFields[target['name']] }));
            return false;
        }
        if (this.allNumberFields[target['name']]) {
            this._showWarning(this.translate.instant('productEditor.error.invalidProductQTYFieldValues', { invalid_fields: this.allNumberFields[target['name']] }));
            return false;
        }
        else {
            this._showWarning(target['dataset'].warning);
            return false;
        }
    };
    Object.defineProperty(ProductEditorParentClass.prototype, "allPriceFields", {
        get: function () {
            var fields = {};
            Object.assign(fields, this.saleFields);
            Object.assign(fields, this.priceFields);
            return fields;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @desc Checks for value type, displays warning on error, updates item object if ok.
     * @param key
     * @param value
     */
    ProductEditorParentClass.prototype.updateItem = function (key, value) {
        var type = typeof value;
        if (type !== 'string' && type !== 'number' && type !== 'boolean') {
            this._showWarning("Incorrect value type for '" + key + "' field.");
            return false;
        }
        else if (typeof key !== 'string' || !key) {
            this._showWarning("Unexpected key:  " + key + ".");
            return false;
        }
        if (key === 'saleIsOn' && !value)
            this.saleIsOffScenario();
        this.item[key] = value;
        this.createLabelText();
        this.productEditorService.set_changed_item(this.item);
        return true;
    };
    ProductEditorParentClass.prototype.updateProductionState = function (value) {
        this.item.productionDate = value ? AppValues.getDateNow() : null;
        this.updateProductionDateState(value);
    };
    ProductEditorParentClass.prototype.saleIsOffScenario = function () {
        this.item.salePrice = 0;
        this.item.saleDateFrom = this.item.valitFrom;
        this.item.saleDateTill = this.item.validTill;
    };
    /**
     * @desc Checks that date TILL is at least one hour ahead of FROM, then
     * updates the item. Otherwise makes undo.
     * @param key
     * @param value
     */
    ProductEditorParentClass.prototype.checkFromDate = function (key, value) {
        var pair = {
            'saleDateFrom': 'saleDateTill',
            'valitFrom': 'validTill'
        };
        var pairValue = this.item[pair[key]];
        this.updateItem(key, value);
        if (pairValue - value < this.HOUR) {
            this._setInvalid(key, pair[key], true);
            return;
        }
        this._setInvalid(key, pair[key], false);
    };
    /**
     * @desc Same for #checkFromDate, but also displays warnings.
     * @param key
     * @param value
     */
    ProductEditorParentClass.prototype.checkTillDate = function (key, value) {
        var pair = {
            'saleDateTill': 'saleDateFrom',
            'validTill': 'valitFrom',
        };
        var msgPart = {
            'saleDateTill': this.translate.instant('productEditor.error.tooShortSalePeriod'),
            'validTill': this.translate.instant('productEditor.error.tooShortPriceValidPeriod')
        };
        var pairValue = this.item[pair[key]];
        this.updateItem(key, value);
        if (value - pairValue < this.HOUR) {
            this._showWarning(msgPart[key]);
            this._setInvalid(key, pair[key], true);
            return;
        }
        this._setInvalid(key, pair[key], false);
    };
    /**
     * Sets @isInvalid attribute for the coupled timepickers elements.
     * @param key
     * @param pair
     * @param isInvalid
     * @private
     */
    ProductEditorParentClass.prototype._setInvalid = function (key, pair, isInvalid) {
        this.timePickers.toArray().forEach(function (p) {
            if (p.role === key || p.role === pair)
                p.isInvalid = isInvalid;
        });
    };
    /**
     * Uploads the image and updates certificate status on certificate event.
     * @param obj
     * @param type
     */
    ProductEditorParentClass.prototype.certificateUpdate = function (obj, type) {
        var link = {
            'organic': 'organic_certificate_image',
            'nonGMO': 'nonGMO_certificate_image'
        };
        if (obj.hasOwnProperty('imageFile')) {
            this.onDataChange(obj, link[type]);
            this.updateItem(type, true);
        }
        else {
            this.updateItem(type, false);
            this.updateItem(link[type], '');
        }
    };
    /**
     * @desc On submit action calls to check item consistence, delegates saving to the service.
     */
    ProductEditorParentClass.prototype.onSave = function () {
        return this._isItemConsistent();
    };
    /**
     * @desc Checks required fields, shows warnings if item data is inconsistent.
     * @returns {boolean}
     * @private
     */
    ProductEditorParentClass.prototype._isItemConsistent = function () {
        this.updateUnits();
        var missedField = this._checkFields(this.requiredFields);
        var missedPriceFields = this._checkFields(this.priceFields) || this.item.saleIsOn && this._checkFields(this.saleFields);
        var missedDelivery = this._forgotDelivery();
        // check mandatory fields
        // if sale is on, price and units must be assigned
        if (missedField) {
            this._showWarning(missedField);
            return false;
            // check price fields
        }
        if (missedPriceFields) {
            this._showWarning(this.translate.instant('productEditor.error.invalidProductPriceFieldValues', { invalid_fields: missedPriceFields }));
            return false;
            // one of delivery ways must be chosen
        }
        else if (missedDelivery) {
            this._showWarning(this.translate.instant('product.editor.error.empty.delivery'));
            return false;
        }
        return true;
    };
    /**
     * Returns verbose field title if its value is empty.
     * @param fieldsObj
     * @returns {any}
     * @private
     */
    ProductEditorParentClass.prototype._checkFields = function (fieldsObj) {
        var keys = Object.keys(fieldsObj);
        for (var i = 0; i < keys.length; i++)
            if (!this.item[keys[i]]) {
                return fieldsObj[keys[i]];
            }
    };
    /**
     * Returns true if no delivery method has been chosen.
     * @returns {boolean}
     * @private
     */
    ProductEditorParentClass.prototype._forgotDelivery = function () {
        return (this.item.deliveryOffered || this.item.marketPickOffered) ? false : true;
    };
    /**
     * Shows modal error window.
     * @param msg
     * @private
     */
    ProductEditorParentClass.prototype._showWarning = function (msg) {
        this.modalService.error({
            title: 'Alert:',
            message: msg,
            yesButtonText: 'Close',
        });
    };
    /**
     * Updates read-only unit fields that depend on priceUnit.
     * @private
     */
    ProductEditorParentClass.prototype.updateUnits = function () {
        this.item.qtyUnitID = this.item.priceUnitsID;
        this.item.saleUnitID = this.item.priceUnitsID;
        this.item.qtyUnitName = this.item.priceUnitsName;
        this.item.saleUnitName = this.item.priceUnitsName;
    };
    ProductEditorParentClass.prototype.updateProductionDateState = function (state) {
        this.productionDateState = state;
    };
    return ProductEditorParentClass;
}());
export { ProductEditorParentClass };
