import { Component, OnInit }        from '@angular/core';
import { Subject }                  from 'rxjs';

import {
    FavoriteWatchProductListViewInterface,
    FavoriteListInterface,
    UserFavoriteListInterface
} from './favorite-list.interface';
import { FavoriteListService}       from './favorite-list.service';
import { ProductDetailsService }    from '../product-details/product-details.service';
import { ItemService }           from '../services/item.service';
import { ShopperDetailsService }    from '../shopper-details/shopper-details.service';
import { FavWatchListCommands } from '../interfaces/fav-watch-list.inreface';
import { SellingItemResponseBody } from '../../../swagger-gen__output_dir/model/sellingItemResponseBody';
import {ErrorInterface} from '../services/error.service';
import {AdapterFavoriteProductsView} from './adapter-favorite-products-view';


@Component({
    selector: 'favorite-list',
    template: `
        <div class="component">

            <favorite-list-header></favorite-list-header>

            <div class="component__container favorite-list__container">

                <h3 *ngIf="items?.length === 0 && users?.length === 0" class="empty-list">
                    {{ "favorite.list.empty" | translate }}
                </h3>

                <app-fav-watch-list
                   [items]="items"
                   [type]="'fav'"
                   (showItemInfo$)="showItemInfo($event)"
                   (removeItem$)="removeItem($event)"
                ></app-fav-watch-list>

                <ul *ngIf="users" class="favorite-list__ul-users">
                    <li class="fw-list__li text-center" (click)="showUser(user.ID)"
                        *ngFor="let user of users; trackBy: trackByID; let i = index;"
                        attr.data-id="{{user.ID}}" [attr.data-test-id]="'favListUItem_'+i">
                        <div class="fw-list__img-container" [attr.data-test-id]="'favListUItemImage_'+i">
                            <span *ngIf="!user.imageURL" class="sprite sprite-no_image_icon"></span>
                            <img *ngIf="user.imageURL" [src]="user.imageURL" [alt]="user.title">
                        </div>
                        <div class="fw-list__product__container text-left">
                            <h3 class="abstract-list__product__title" [attr.data-test-id]="'favListUName_'+i">
                                {{user.title || user.firstName + ' ' + user.lastName}}</h3>

                            <div class="abstract-list__product-reviews">
                                <rating [attr.data-test-id]="'favListURate_'+i" [rating]="user.rate" [float]="true"
                                        [readonly]="true"></rating>
                            </div>
                        </div>
                        <span class="fw-list__item-controls" [attr.data-test-id]="'favListURemoveItem_'+i"
                              (click)="removeUser(user.ID);$event.stopPropagation()">
                           <i class="icon-trash-empty"></i>
                        </span>
                    </li>
                </ul>

            </div>
        </div>
    `
})

export class FavoriteListComponent implements OnInit, FavWatchListCommands {

    private componentDestroyed$:    Subject<boolean> = new Subject();

    public items:          FavoriteWatchProductListViewInterface[];
    public users:          UserFavoriteListInterface[];


    constructor(
        private favService:             FavoriteListService,
        private productDetailsService:  ProductDetailsService,
        private productService:         ItemService,
        private shopperDetailsService:  ShopperDetailsService,
    ) { }


    ngOnInit() {
        this.favService.getList()
            .takeUntil(this.componentDestroyed$)
            .subscribe((list: FavoriteListInterface) => {
                this.items = list.items ? new AdapterFavoriteProductsView(list.items).initProduct() : [];
                this.users = list.users || [];

                if (this.items && this.items.length !== 0) {
                    this.items.forEach((item: FavoriteWatchProductListViewInterface) => this._checkItemFn(item));
                }
            }, (err: ErrorInterface) => {
                this.items = [];
                this.users = [];
            });
    }

    /**
     * Id tracker for the list.
     * TrackByFunction
     * This will cause it to enable the dev state his identity in the iterable for the Differ to track.
     * This will prevent the whole DOM from being constantly destroyed and re-created.
     * An optional function passed into the NgForOf directive that defines how to track changes for items in an iterable.
     * The function takes the iteration index and item ID. When supplied, Angular tracks changes by the return value of the function
     * @param {number} index
     * @param {UserFavoriteListInterface} items
     * @return {string[]}
     */
    trackByID(index: number, items: UserFavoriteListInterface): string { return items.ID; }


    /**
     * @desc check if this product is on sale
     * and set price and UnitsName
     * @param {SellingItemResponseBody | any} item
     * @returns {boolean}
     */
    private _checkItemFn(item: FavoriteWatchProductListViewInterface): void {
         item.isInOnSale        = this.productService.isSaleFn(item);
         item.productPrice      = this.productService.itemPriceFn(item);
         item.productUnitsName  = this.productService.priceUnitsNameFn(item);
         item.isExpired         = this.productService.isExpiredFn(item);
    }


    /**
     * @desc go to one Product Detail view
     * @param {Event} event
     */
    showItemInfo(id: string) {
        this.productDetailsService.setProductDetailsFrom('favorite');
        this.productDetailsService.showProductDetails(id).subscribe((res: SellingItemResponseBody) => null, (err: string) => null);
    }


    /**
     * @desc go to one User Detail view
     * @param {string} id
     */
    showUser(id: string) {
        this.shopperDetailsService.showDetails(id);
    }


    /**
     * @desc remove some item (or product) into list
     * @param {string} id
     */
    removeItem(id: string) {
        this.favService.removeItem(id)
            .subscribe(() => {
                this.filterList(id);
            });
    }

    /**
     * @desc remove some user into list
     * @param {string} id
     */
    removeUser(id: string) {
        this.favService.removeUser(id)
            .subscribe(() => {
                this.filterList(id);
            });
    }

    /**
     * remove product or user id from lists
     * @param {string} id
     */
    filterList(id: string) {
        this.items = this.items.filter(itm => itm['ID'] !== id);
        this.users = this.users.filter(itm => itm['ID'] !== id);
    }

}
