/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bizipix-editor.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./picker-cell.component";
import * as i4 from "../services/item.service";
var styles_PickerCellComponent = [i0.styles];
var RenderType_PickerCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PickerCellComponent, data: {} });
export { RenderType_PickerCellComponent as RenderType_PickerCellComponent };
function View_PickerCellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "sprite sprite-no_image_icon"]], null, null, null, null, null))], null, null); }
function View_PickerCellComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.data["photoURL"], ""); _ck(_v, 0, 0, currVal_0); }); }
function View_PickerCellComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_PickerCellComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "clearfix picker__cell"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "left picker__img"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PickerCellComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PickerCellComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "section", [["class", "picker__info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [["class", "title"], ["data-test-id", "pickerCellTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "h4", [], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", "\u00A0\u00A0 "])), i1.ɵppd(12, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PickerCellComponent_3)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.data["photoURL"]; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.data["photoURL"]; _ck(_v, 6, 0, currVal_2); var currVal_6 = _co.title; _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("pickerCell_" + _co.index); _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.data.title; _ck(_v, 9, 0, currVal_3); var currVal_4 = ("productPrice_" + _co.index); _ck(_v, 10, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), (_co.productPrice / _co.HUNDRED), "USD")); _ck(_v, 11, 0, currVal_5); }); }
export function View_PickerCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "picker-cell", [], null, null, null, View_PickerCellComponent_0, RenderType_PickerCellComponent)), i1.ɵdid(1, 114688, null, 0, i3.PickerCellComponent, [i4.ItemService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PickerCellComponentNgFactory = i1.ɵccf("picker-cell", i3.PickerCellComponent, View_PickerCellComponent_Host_0, { data: "data", title: "title", index: "index" }, { clickEvent$: "clickEvent$" }, []);
export { PickerCellComponentNgFactory as PickerCellComponentNgFactory };
