import { Injectable }           from '@angular/core';
import { Router} from '@angular/router';
import { Location } from '@angular/common';

import { AppFooterService }     from '../app-footer/app-footer.service';
import { UserService }          from '../user/user.service';
import { UserSessionInterface, UserModel } from '../interfaces/user.interface';
import { ProductDetailsService } from '../product-details/product-details.service';
import {AppReviewsService} from '../reviews/reviews.service';
import {ModalService} from '../modal/modal.service';
import {AppRouteValues, createDirection} from "../common/app.route.values";


const noRedirectPaths: string[] = [
    createDirection(AppRouteValues.routeCartReport),
    '/product-details/',
    '/login',
    '/signup',
    createDirection(AppRouteValues.routeCart),
    createDirection(AppRouteValues.routeSuccessOnboarding)
];

@Injectable()
export class RedirectService {

    private defaultRedirectUrl  = '/';
    private path                = '';
    private redirectUrl:        string;

    public impersonation:       UserModel | {};
    public impersonationUser:   UserSessionInterface;
    public link:                string;
    public create_review_link:  string = '';
    public firstStartApp:       boolean;

    public w: any;

    constructor(
        private appFooterService:       AppFooterService,
        private modalService:           ModalService,
        private router:                 Router,
        private location:               Location,
        private productDetailsService:  ProductDetailsService,
        private userService:            UserService,
        private appReviewsService:      AppReviewsService
    ) {
        this.w = window;

        this.userService.userAuthEvent$
            .subscribe((user: UserSessionInterface) => {
                this.checkRedirectPath();
                this.path = this.w.location.pathname;
                this.check(user);

                if (Object.keys(user).length !== 0 && this.link && this.link !== '') {
                    this.dynamicLinktoProduct();
                    this.link = '';
                }
            });
    }
    createReviewLink() {
        let user: UserSessionInterface = this.userService.userSession;
        this.path = this.w.location.pathname;
        this.check(user);

        if (Object.keys(user).length !== 0 && user.lastName === null) {
            this.redirectToLogin();
            return;
        }
        if (Object.keys(user).length !== 0 && user.lastName !== null) {
                this.preparingToCreateReview();
        } else this.checkRedirectPath();
    }

    preparingToCreateReview() {
        if (this.create_review_link.includes('create-review')) {
            let create_review = this.create_review_link.split('create-review?')[1];
            let order_id = create_review.split('=')[0];

            if (order_id === 'order_element_id') {
                this.router.navigate(['/shopping-list']);

                if (this.w.location.pathname === '/shopping-list') {

                    let create_review_id = create_review.split('=')[1];
                    this.dynamicLinktoCreateReview(create_review_id);
                }
            }
        }
    }

    redirectToLogin() {
        if (this.create_review_link.includes('create-review')) {
            this.router.navigate(['/login']);
        }
    }

    redirectingForLogedInUser(url: string): void {
        if (url.includes('login')
            && this.userService.getUser().accessLevel !== 0) {
            this.goHome();
        }
    }


    check(user: UserSessionInterface) {
        if (Object.keys(this.userService.impersonation).length !== 0) {
            this.impersonation_setter();
            this.impersonation = this.impersonation_getter;
            this.impersonationUser = user;
        }
    }

    get impersonation_user() {
        return this.impersonationUser;
    }

    impersonation_setter() {
        this.impersonation = this.userService.impersonation;
    }

    get impersonation_getter() {
        return this.impersonation;
    }

    get user() {
        return this.userService.getUser();
    }

    goBack() {
        if (this.router.url.includes('/chat-room')) {
           this.location.back();
           return;
        }

        this.appFooterService.getMode() === 'buyer'
            ? this.goTo('/shopping-list')
            : this.goTo('/inventory-list');
    }

    goHome() {
        this.goTo(this.defaultRedirectUrl);
    }

    goTo(url: string | {}) {
        this.router.navigate([url]);
    }

    public reloadWindow(): void {
        this.w.location.reload();
    }

    setRedirectUrl(url: string) {
        this.redirectUrl = url.replace(/\(.+\)/, '');
    }

    checkRedirectPath() {
        let ref: string = this.w.location.pathname;

        if (ref.split('/')[1] === 'product-details') {
            this.link = this.w.location.pathname;
            return;
        }


        if (ref.split('/')[1] === 'create-review' && this.w.location.search.split('=')[0] === '?order_element_id') {
            this.create_review_link = this.w.location.href;
            return;
        }

        const isNotGuest: boolean = this.user.accessLevel !== 0;
        const isAuthPage: boolean = ref === '/login' || ref === '/signup';

        const isNoSellerModeOpenSheet: boolean = (ref === '/inventory-sheet' && this.userService.isSellerMode());
        const firstStartAppCondition: boolean = this.firstStartApp
            && noRedirectPaths.findIndex(path => path === ref) === -1;

        if (isNoSellerModeOpenSheet) {
            !this.firstStartApp && this.goTo(ref);
            return;
        } else if (isNotGuest && isAuthPage || firstStartAppCondition) {
            this.goTo('/shopping-list');
        } else {
           !this.firstStartApp && this.goTo(ref);
        }
    }

    dynamicLinktoProduct() {
        let id_product = this.w.location.pathname
            .slice(1, this.w.location.pathname.length).split('/')[1];

        if (id_product) {
            return this.productDetailsService.showProductDetails(id_product)
                .toPromise().then(
                    () => this.link = '',
                    () => this.link = ''
                );
        }

    }
    dynamicLinktoCreateReview(order_element_id: string) {
        this.appReviewsService.linkToCreateReview(order_element_id).subscribe((res) => {
            this.create_review_link = '';
        }, (error) => {
            this.router.navigate(['/shopping-list']);
            this.create_review_link = '';
        });
    }

}
