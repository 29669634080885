/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "./filter.sass.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./reviews-header.component";
import * as i6 from "../modal/modal.service";
import * as i7 from "@angular/router";
var styles_ReviewsHeaderComponent = [i0.styles, i1.styles];
var RenderType_ReviewsHeaderComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ReviewsHeaderComponent, data: {} });
export { RenderType_ReviewsHeaderComponent as RenderType_ReviewsHeaderComponent };
function View_ReviewsHeaderComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "h4", [["data-test-id", "headerTitle"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ReviewsHeaderComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "h4", [["data-test-id", "headerTitle"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.itemTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_ReviewsHeaderComponent_5(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "button", [["type", "button"]], [[1, "data-test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleListState(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(1, 278528, null, 0, i3.NgClass, [i2.IterableDiffers, i2.KeyValueDiffers, i2.ElementRef, i2.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i2.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i2.ɵted(3, null, ["", ""])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "span", [["class", "review-underline"]], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = (_v.context.$implicit.active && "review-list-active-btn"); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ("TypeBtn_" + _v.context.$implicit.title); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_2); }); }
function View_ReviewsHeaderComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "review-header__list-switch-block text-center"], ["data-test-id", "reviewHeaderSwitchBlock"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ReviewsHeaderComponent_5)), i2.ɵdid(2, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reviewsFilterList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ReviewsHeaderComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 13, "header", [["class", "app-header reviews__app-header"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 10, "nav", [["class", "app-header__container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "button", [["class", "back-icon__container"], ["data-test-id", "backLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 4, "div", [["class", "navbar__center"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ReviewsHeaderComponent_2)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ReviewsHeaderComponent_3)), i2.ɵdid(8, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(9, 0, null, null, 2, "button", [["data-test-id", "showFilter"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showFilter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(10, 278528, null, 0, i3.NgClass, [i2.IterableDiffers, i2.KeyValueDiffers, i2.ElementRef, i2.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i2.ɵeld(11, 0, null, null, 0, "img", [["alt", "show filter"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ReviewsHeaderComponent_4)), i2.ɵdid(13, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.locationSearch === "/reviews"); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.locationSearch === "/reviews-item"); _ck(_v, 8, 0, currVal_1); var currVal_2 = "filter-icon__container"; _ck(_v, 10, 0, currVal_2); var currVal_4 = ((_co.reviewsFilterList && _co.reviewsFilterList.length) && _co.isAvailableTabs); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_3 = "../../assets/images/svg_icons/filter.svg"; _ck(_v, 11, 0, currVal_3); }); }
function View_ReviewsHeaderComponent_6(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 13, "header", [["class", "app-header create-reviews__app-header"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 12, "nav", [["class", "app-header__container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 2, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "button", [["data-test-id", "backLink"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.location.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 2, "h5", [["class", "navbar__center"], ["data-test-id", "createReviewHeaderTitle"]], null, null, null, null, null)), (_l()(), i2.ɵted(6, null, [" ", " ", " "])), i2.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(8, 0, null, null, 5, "div", [["class", "navbar__right"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 4, "h4", [["class", "nav__link"], ["data-test-id", "saveReview"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDone() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(10, 278528, null, 0, i3.NgClass, [i2.IterableDiffers, i2.KeyValueDiffers, i2.ElementRef, i2.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i2.ɵpod(11, { "disabled": 0 }), (_l()(), i2.ɵted(12, null, [" ", " "])), i2.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i2.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = "nav__link"; var currVal_3 = _ck(_v, 11, 0, _co.disabled); _ck(_v, 10, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵunv(_v, 6, 0, i2.ɵnov(_v, 7).transform("review.add.review")); var currVal_1 = _co.createReviewHeaderTitle; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_4 = i2.ɵunv(_v, 12, 0, i2.ɵnov(_v, 13).transform("review.text.save")); _ck(_v, 12, 0, currVal_4); }); }
export function View_ReviewsHeaderComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_ReviewsHeaderComponent_1)), i2.ɵdid(1, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ReviewsHeaderComponent_6)), i2.ɵdid(3, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.locationSearch !== "/create-review"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.locationSearch === "/create-review"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ReviewsHeaderComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "reviews-header", [], null, null, null, View_ReviewsHeaderComponent_0, RenderType_ReviewsHeaderComponent)), i2.ɵdid(1, 114688, null, 0, i5.ReviewsHeaderComponent, [i6.ModalService, i2.Injector, i7.Router, i3.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReviewsHeaderComponentNgFactory = i2.ɵccf("reviews-header", i5.ReviewsHeaderComponent, View_ReviewsHeaderComponent_Host_0, { disabled: "disabled", reviewsAllFilters: "reviewsAllFilters", reviewsAllFiltersSubject: "reviewsAllFiltersSubject", isAvailableFilter: "isAvailableFilter", isAvailableTabs: "isAvailableTabs", itemTitle: "itemTitle", userTitle: "userTitle", createReviewHeaderTitle: "createReviewHeaderTitle" }, { changeEvent$: "changeEvent$", backToHomeEvent$: "backToHomeEvent$", submit: "submit", goToCreateReview$: "goToCreateReview$" }, []);
export { ReviewsHeaderComponentNgFactory as ReviewsHeaderComponentNgFactory };
