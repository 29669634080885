import { NgModule }                     from '@angular/core';
import { CommonModule }                 from '@angular/common';
import { RouterModule }                 from '@angular/router';
import { SharedModule }                 from '../shared/shared.module';

import { FavoriteListComponent }        from './favorite-list.component';
import { FavoriteListHeaderComponent }  from './favorite-list-header.component';
import { FavoriteListService }          from './favorite-list.service';
import { ItemService }               from '../services/item.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports:      [
        CommonModule,
        RouterModule,
        SharedModule,
        TranslateModule,
    ],
    declarations: [
        FavoriteListComponent,
        FavoriteListHeaderComponent
    ],
    exports:      [
        FavoriteListComponent,
        CommonModule,
    ],
    providers:    [ FavoriteListService, ItemService ]
})
export class FavoriteListModule { }
