
        <div class="item-picker component">

            <header class="app-header">
                <nav class="app-header__container">
                    <div class="navbar__left" role="itemPicker">
                        <a class="nav__link" data-test-id="cancelLink" (click)="onItemSelect(null)">
                            <span>{{ "bizipix.item.picker.cancel" | translate }}</span>
                        </a>
                    </div>

                    <div class="navbar__center" role="itemPicker">
                        <h4>{{ "bizipix.item.picker.attach" | translate }}</h4>
                    </div>

                    <div class="navbar__right"></div>
                </nav>
            </header>


            <div class="item-picker__container component__container">
                <div *ngIf="list?.length">

                    <picker-block
                            *ngFor="let item of list; trackBy: trackByID; let i = index"
                            [attr.data-test-id]="'block-'+i"
                            [index]="i"
                            [item]="item"
                            (itemSelectEvent$)="onItemSelect($event)">

                    </picker-block>

                </div>
            </div>
        </div>
    