/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./cart-report-header.component";
var styles_CartReportHeaderComponent = [i0.styles];
var RenderType_CartReportHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CartReportHeaderComponent, data: {} });
export { RenderType_CartReportHeaderComponent as RenderType_CartReportHeaderComponent };
export function View_CartReportHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "nav", [["class", "app-header__container"], ["role", "cart-report"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "nav__link"], ["data-test-id", "shoppingLink"], ["routerLink", "/shopping-list"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "navbar__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "navbar__right"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/shopping-list"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("header.cart.report")); _ck(_v, 8, 0, currVal_3); }); }
export function View_CartReportHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cart-report-header", [], null, null, null, View_CartReportHeaderComponent_0, RenderType_CartReportHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i5.CartReportHeaderComponent, [], null, null)], null, null); }
var CartReportHeaderComponentNgFactory = i1.ɵccf("cart-report-header", i5.CartReportHeaderComponent, View_CartReportHeaderComponent_Host_0, {}, {}, []);
export { CartReportHeaderComponentNgFactory as CartReportHeaderComponentNgFactory };
