
        <profile-header></profile-header>
        <div *ngIf="profile" class="profile__container">

            <img-loader role="title"
                        [imageUrl]="profile.imageURL || '../../assets/images/cart/no_image_icon.png'"
                        [text]="profile.title  || '--your title--'"
                        [regExp]="titlePattern"
                        (changeEvent$)="onDataChange($event)">
            </img-loader>

            <!--CONTACTS-->
            <div class="profile__subheader"><h4>Contacts</h4></div>
            <media-editor role="webPage" [attr.data-test-id]="'webpage'"
                          [imageUrl]="'../../assets/images/svg_icons/globe.svg'"
                          [text]="profile.webPage"
                          [placeholder]="'URL'"
                          [regExp]="webPagePattern"
                          [minMaxLength]="webPageMinMaxLength"
                          [isEmpty]="true"
                          [errorMessage]="'address.error.invalidWebPageLineFieldValues'"
                          (changeEvent$)="onDataChange($event)" (keypress)="eventHandler($event)">
            </media-editor>

            <media-editor role="email" [attr.data-test-id]="'email'"
                          [imageUrl]="'../../assets/images/svg_icons/at-symbol-svgrepo-com.svg'"
                          [text]="profile.email"
                          [regExp]="emailPattern"
                          [minMaxLength]="emailMinMaxLength"
                          [errorMessage]="'profile.editor.email.warning.message'"
                          (changeEvent$)="onDataChange($event)" (keypress)="eventHandler($event)">
            </media-editor>

            <!--Stripe-->
            <div class="profile__subheader" *ngIf="isSellerMode">
                <a (click)="onOpenStripeDashboard()" data-test-id="openStripeDashboard">
                    <section class="profile__stripe-container">
                        <img src="../../assets/images/stripe_wordmark_slate_lg.png" alt="stripe wordmark"/>
                    </section>
                </a>
            </div>

            <!--ADDRESSES-->
            <!-- TODO MULTIPLE ADDRESSES -->
            <div class="profile__subheader"><h4>{{ "profile.addresses" | translate }}</h4></div>

            <div>
                <div *ngFor="let a of profile.addresses; let i = index" class="addr" (click)="onAddressEdit(a, i)" data-test-id="profileAddressEdit">
                    <span class="icon-location"></span>

                    <h3 class="addr__text clip" data-test-id="profileAddress1">{{a.address1}}</h3>

                    <span class="icon-disclosure_indicator_icon"></span>

                </div>
            </div>

            <!--ADD ADDRESSES-->
            <div class="profile__add" (click)="onAddressEdit(null, -1)">
                <h4 data-test-id="newAddress">{{ "profile.add.new.address" | translate }}</h4>
            </div>

        </div>
    