import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import {
   PushNotification,
   NotificationContent
} from '../interfaces/push-notifications.interface';
import {PushNotificationMessageI} from '../cart/cart-report.interface';

@Injectable()
export class PushNotificationsService {
    public permission: Permission;

    constructor() {
        this.permission = this.isNotificationsApiSupported() ? 'default' : 'denied';
    }

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
     * Feature-detecting the requestPermission() promise
     * Above we said that we had to check whether the browser supports
     * the promise version of Notification.requestPermission().
     */
    requestPermission(): void {
        if (this.isNotificationsApiSupported()) {

            try {
                Notification.requestPermission()
                    .then((status: Permission) => this.getNotificationPermission(status));
            } catch (error) {
                // Safari doesn't return a promise for requestPermissions and it
                // throws a TypeError. It takes a callback as the first argument
                // instead.
                if (error instanceof TypeError) {
                    Notification.requestPermission((status: Permission) => this.getNotificationPermission(status));
                } else {
                    throw error;
                }
            }
        }
    }

    getNotificationPermission(status: Permission): Permission {
        this.registerServiceWorker(status);
        return this.permission = status;
    }


    create(title: string, options?: PushNotification): any {
        return new Observable(obs => {
            if (!(this.isNotificationsApiSupported())
               || !(this.isServiceWorkerRegistrationSupported())) {
                obs.complete();
            }
            if (this.permission !== 'granted') {
                obs.complete();
            }

            this.showNotification({ obs, title, options });
        });
    }

    public generateNotification(source: Array<PushNotificationMessageI>): void {
        source.forEach((item) => {
            let options = {
                body: item.alertContent,
                tag : item.abandonedItemID,
                icon: "../../../assets/images/IconApp29x29@2x.png",
                vibrate: [100, 50, 100],
                data: {
                 url: item.navigateTo
               },
            };
            let notify = this.create(item.title, options).subscribe();
        });
    }

    private isNotificationsApiSupported(): boolean {
        return 'Notification' in window;
    }

    private isServiceWorkerSupported(): boolean {
       return 'serviceWorker' in navigator;
    }

    private isServiceWorkerRegistrationSupported(): boolean {
       return 'ServiceWorkerRegistration' in window;
    }

    public registerServiceWorker(status: Permission) {
       if (status === 'granted' && this.isServiceWorkerSupported()) {

           return navigator.serviceWorker.register('/service_worker.js').then(function (reg) {
               return navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {

                   const subscribeOptions = {
                      userVisibleOnly: true,
                      applicationServerKey: "BAvUxhcBoYW-_FFTK-FY8AbLfW5SzFHC7vAjs97wbRyhu0DrEDHNrXgEkb5xjifXX7mhwDXJpErZ0OcTZMKAX-k"
                    };

                return serviceWorkerRegistration.pushManager.subscribe(subscribeOptions);
               }).then(function (subscription) {
                   if (subscription) {
                       navigator.serviceWorker.getRegistrations().then(function(registrations) {
                            for (let registration of registrations) { registration.unregister(); }
                       });
                   }
               });
          })
            .catch((err) => {
               console.log('Notifications are not available in this environment: ' + err);
            });
       }
    }

    private showNotification(content: NotificationContent): void {
       try {
          this.handlerNotificationApi(content);
       } catch (err) {
          this.handlerServiceWorkerRegistrationApi(content);
       }
    }

    private handlerNotificationApi(
       content: NotificationContent
    ): any {
       let _notify = new Notification(content.title, content.options);

       _notify.onshow = function(e) {
           return content.obs.next({ notification: _notify, event: e });
       };
       _notify.onclick = function(e) {
           return content.obs.next({ notification: _notify, event: e });
       };
       _notify.onerror = function(e) {
           return content.obs.error({ notification: _notify, event: e });
       };
       _notify.onclose = function() {
           return content.obs.complete();
       };
    }

    private handlerServiceWorkerRegistrationApi(
       content: NotificationContent
    ): any {
       try {
          if ('serviceWorker' in navigator) {
             navigator.serviceWorker.getRegistration()
               .then((reg: ServiceWorkerRegistration) => {
                   console.log('serviceWorker.showNotification');
                  reg.showNotification(content.title, content.options);
                  content.obs.complete();
               })
               .catch(err => {
                  console.log('serviceWorker.getRegistration ERROR: ' + err);
               });
            }
       } catch (err) {
          console.log('serviceWorker ERROR: ' + err);
       }
    }
}

export declare type Permission = 'denied' | 'granted' | 'default';
