import { Component, ElementRef }            from '@angular/core';
import { Observable }                       from 'rxjs/Observable';
import { Router }                           from '@angular/router';

import { ModalService }                     from '../modal/modal.service';
import { UserService }                      from './user.service';
import { EmailPageAbstractClass }           from './helpers/email-page.abstract.class';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector:   'password-reset',

    template:   `
        <user-header></user-header>
        <div class="reset__container">

            <form class="reset__container__content"
                  #resetForm="ngForm" (ngSubmit)="onSend($event)">

                <label class="input__label clearfix">
                    <input [ngClass]="{ error: emailErr && email }"
                           data-test-id="resendEmailInput"
                           class="input__text" type="email"
                           placeholder="Email" name="email" required
                           [(ngModel)]="email"
                           (blur)="blurField($event)"
                           (keyup)="onValidation($event.target.value)">
                </label>

                <button role="reset" type="submit"
                        class="button__lightgreen reset__button"
                        data-test-id="resendBtn"
                        [disabled]="busy || emailErr || !email">
                    Send
                </button>
            </form>

            <div role="mailField" class="reset__container__content">
                <p class="text-center">
                    {{ "email.verification.enter.valid.email" | translate }}
                </p>
                <p class="text-center">
                    {{ "email.verification.check.your.spam" | translate }}
                </p>
                <p class="text-center">
                    {{ "email.verification.please.contact" | translate }} <a
                    class="link__inline"
                    href="mailto:support@BiziBAZA.com">support@BiziBAZA.com</a>.
                </p>
            </div>

        </div>
    `
})

export class EmailVerificationComponent extends EmailPageAbstractClass {

    public email: string = '';
    protected successMsg    = this.translate.instant("emailVerification.success");

    constructor(
        protected elementRef:     ElementRef,
        protected modalService:   ModalService,
        protected router:         Router,
        protected translate:      TranslateService,
        private userService:      UserService
    ) {
        super(elementRef, modalService, router, translate);
    }


    _sendRequest(): Observable<any> {
        return this.userService.sendVerification(this.email.trim());
    }

}
