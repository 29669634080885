
        <div class="component">
            <goods-nav-header *ngIf="categoryName" [categoryName]="categoryName"></goods-nav-header>

            <div class="component__container">
                <ul class="subcat__list">
                    <li class="subcat__title" [attr.data-test-id]="'subcategory_'+idx1"
                        [ngClass]="{
                            'ok':   subcat['_isInShoppingList'],
                            'busy': subcat['_isBusy']
                        }"
                        *ngFor="let subcat of subcats; trackBy: trackByID; let idx1 = index"
                        (click)="toggleVisibility(subcat)">

                        <p><i class="icon-right-dir"
                              [ngClass]="{'active': subcat._isVisible}"></i>

                            {{subcat.sub_category_name}}</p>

                        <ul *ngIf="subcat._isVisible" class="entry__list">

                            <li class="entry__title" [attr.data-test-id]="'entry_'+idx2"
                                [ngClass]="{
                                    'ok':   entry['_isInShoppingList'],
                                    'busy': entry['_isBusy']
                                }"
                                *ngFor="let entry of subcat.sub_category_entries; trackBy: trackByID; let idx2 = index"
                                (click)="toggleStatus(entry, subcats, $event, idx1)">

                                <p>{{entry.subcategory_entry_name}}</p>
                            </li>

                        </ul>

                    </li>
                </ul>
            </div>
        </div>
    