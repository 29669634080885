
        <div class="component">
            <div class="shopper-details">
                <market-header (backEvent$)="backOptionsChange($event)"></market-header>
                <div class="shopper-details__container" *ngIf="market">
                    <div class="shopper-details__img">
                        <agm-map
                            [latitude]='market.market_latitude'
                            [longitude]='market.market_longitude'
                            [zoom]='zoom'
                            [scrollwheel]="false"
                            [streetViewControl]="false"
                            [disableDefaultUI]='true'
                            [disableDoubleClickZoom]='true'
                            [mapDraggable]="false"
                            [zoomControl]='false'>

                            <agm-marker (markerClick)="goToGoogleMaps(market.market_latitude, market.market_longitude, market.address)"
                                [latitude]='market.market_latitude'
                                [longitude]='market.market_longitude'
                                [markerDraggable]="false" [iconUrl]='markerUrl'>
                            </agm-marker>

                        </agm-map>
                    </div>
                    <div class="shopper-details__content-user">
                        <div *ngIf="!market.market_picture_url" class="image_col">
                            <span data-test-id="marketImage"
                              class="sprite sprite-no_image_icon"></span>
                        </div>
                        <div *ngIf="market.market_picture_url" class="container-img">
                            <img data-test-id="marketImage"
                             [src]="market.market_picture_url"/>
                        </div>
                        <div class="content_col">
                            <h4 data-test-id="MarketName">
                                {{ market.market_title }}
                            </h4>
                            <div class="shopper-details__reviews">
                                <span>{{timeDatesInfo}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="market.address" class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-phone"></span>
                        </div>

                        <h4 data-test-id="MarketPhone">
                            <a *ngIf="!market.address.phone">{{ "market.phone.not.specified" | translate }}</a>
                            <a *ngIf="market.address.phone" href="tel:{{ market.address.phone}}">{{ formatPhoneNumber(market) }}</a>
                        </h4>
                    </div>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-location"></span>
                        </div>

                        <h4 *ngIf="market.address.address1" data-test-id="MarketAddress1"
                        (click)="goToAddress(market.address.address1 + ' ' + market.address.city + ' ' + market.address.state + ' ' + market.address.postCode, 'address')">
                            <a>{{ market.address.address1 }}, {{ market.address.city }}, {{ market.address.state }}, {{ market.address.postCode }}</a>
                        </h4>
                        <h4 *ngIf="!market.address.address1" data-test-id="MarketAddress1">
                            <a>{{ "market.text.not.specified" | translate }}</a>
                        </h4>
                    </div>
                    <div class="shopper-details__content">
                        <div class="content_col_point">
                            <span class="icon-globe"></span>
                        </div>

                        <h4 data-test-id="MarketWebPage" (click)="goToAddress(market['market_web_page'], 'webPage')">
                            <a target="_blank">{{ market['market_web_page'] || 'Web page is not specified.'}}</a>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="items-of-market-list product-actions app__footer">
                <button (click)="goToItemList(market)" data-test-id="searchFromMarket" class="shopper-items">{{list.items.length ? list.items.length : "No"}} {{ "market.text.products.found" | translate }}</button>
            </div>
        </div>
    