/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reviews.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./create-reviews.component";
import * as i3 from "@angular/forms";
import * as i4 from "./reviews.service";
import * as i5 from "../modal/modal.service";
import * as i6 from "../app-footer/app-footer.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/router";
import * as i9 from "@angular/common";
var styles_CreateReviewComponent = [i0.styles];
var RenderType_CreateReviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateReviewComponent, data: {} });
export { RenderType_CreateReviewComponent as RenderType_CreateReviewComponent };
export function View_CreateReviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 16777216, [[1, 3], ["container", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_CreateReviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reviews", [], null, null, null, View_CreateReviewComponent_0, RenderType_CreateReviewComponent)), i1.ɵdid(1, 114688, null, 0, i2.CreateReviewComponent, [i1.ComponentFactoryResolver, i3.FormBuilder, i4.AppReviewsService, i5.ModalService, i6.AppFooterService, i7.TranslateService, i8.Router, i9.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateReviewComponentNgFactory = i1.ɵccf("reviews", i2.CreateReviewComponent, View_CreateReviewComponent_Host_0, {}, {}, []);
export { CreateReviewComponentNgFactory as CreateReviewComponentNgFactory };
