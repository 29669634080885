/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./users-list.component";
import * as i4 from "../../user/user.service";
import * as i5 from "@angular/router";
import * as i6 from "../../chat/chat.service";
var styles_UsersListComponent = [];
var RenderType_UsersListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UsersListComponent, data: {} });
export { RenderType_UsersListComponent as RenderType_UsersListComponent };
function View_UsersListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h4", [["class", "search-sublist__header text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_UsersListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit["imageURL"]; _ck(_v, 0, 0, currVal_0); }); }
function View_UsersListComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "sprite sprite-no_image_icon"]], null, null, null, null, null))], null, null); }
function View_UsersListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "li", [["class", "abstract-list__li text-center"]], [[1, "data-id", 0], [1, "data-test-id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "abstract-list__li__box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "abstract-list__img-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersListComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersListComponent_5)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 5, "div", [["class", "abstract-list__product__container text-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "h4", [["class", "abstract-list__product__title"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", " "])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "h5", [["class", "abstract-list__product__title"]], [[1, "data-test-id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), (_l()(), i0.ɵeld(13, 0, null, null, 3, "div", [["class", "abstract-list__product__open"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "button", [["class", "impersonation-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = _v.context.$implicit["imageURL"]; _ck(_v, 4, 0, currVal_2); var currVal_3 = !_v.context.$implicit["imageURL"]; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit["_id"] || _v.context.$implicit["ID"]); var currVal_1 = ("user_" + _v.context.index); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = ("userTitle_" + _v.context.index); _ck(_v, 8, 0, currVal_4); var currVal_5 = _v.context.$implicit["title"]; _ck(_v, 9, 0, currVal_5); var currVal_6 = ("userEmail_" + _v.context.index); _ck(_v, 10, 0, currVal_6); var currVal_7 = i0.ɵinlineInterpolate(1, "mailto: ", _v.context.$implicit["email"], ""); _ck(_v, 11, 0, currVal_7); var currVal_8 = _v.context.$implicit["email"]; _ck(_v, 12, 0, currVal_8); var currVal_9 = i0.ɵunv(_v, 15, 0, i0.ɵnov(_v, 16).transform("users.list.impersonation")); _ck(_v, 15, 0, currVal_9); }); }
function View_UsersListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "abstract-list__ul"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersListComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; var currVal_1 = _co.trackByID; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_UsersListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "abstract-list__container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersListComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UsersListComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.items && _co.items.length) && _co.title); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.items && _co.items.length); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_UsersListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "users-list", [], null, null, null, View_UsersListComponent_0, RenderType_UsersListComponent)), i0.ɵdid(1, 49152, null, 0, i3.UsersListComponent, [i0.ElementRef, i4.UserService, i5.Router, i6.ChatService], null, null)], null, null); }
var UsersListComponentNgFactory = i0.ɵccf("users-list", i3.UsersListComponent, View_UsersListComponent_Host_0, { items: "items", title: "title", search_from: "search_from" }, {}, []);
export { UsersListComponentNgFactory as UsersListComponentNgFactory };
