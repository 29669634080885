import { Component, ElementRef }    from '@angular/core';


import { SearchListClass }          from './search-list.class';
import { ShopperDetailsService }    from '../../shopper-details/shopper-details.service';


@Component({
    selector:       'user-search-list',
    templateUrl:    './item-search-list.html'
})

export class SellerSearchComponent extends SearchListClass {

    constructor(
        public elementRef:              ElementRef,
        private shopperDetailsService:  ShopperDetailsService,
    ) {
        super(elementRef);
    }



    onClick(item: {}): void {
        this.shopperDetailsService.showDetails(item['ID']);
    }

}
