/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dialog-header.component";
var styles_DialogHeaderComponent = [i0.styles];
var RenderType_DialogHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogHeaderComponent, data: {} });
export { RenderType_DialogHeaderComponent as RenderType_DialogHeaderComponent };
export function View_DialogHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "nav", [["class", "app-header__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "nav__link"], ["data-test-id", "dialogsLink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "navbar__center"], ["role", "chat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["class", "clip"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "navbar__right"], ["role", "chat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "icon-user-circle-o"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 7, 0, currVal_0); }); }
export function View_DialogHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dialog-header", [], null, null, null, View_DialogHeaderComponent_0, RenderType_DialogHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i2.DialogHeaderComponent, [], null, null)], null, null); }
var DialogHeaderComponentNgFactory = i1.ɵccf("dialog-header", i2.DialogHeaderComponent, View_DialogHeaderComponent_Host_0, { title: "title" }, { goBackEvent$: "goBackEvent$" }, []);
export { DialogHeaderComponentNgFactory as DialogHeaderComponentNgFactory };
