import {
    Component, OnInit,
    ElementRef, Output, Input, EventEmitter, AfterContentInit, HostListener
} from '@angular/core';
import { Position }             from './position';

export class Tag {
    constructor(
        public ID: string,
        public item_id: string,
        public item_title: string,
        public item_price: number,
        public item_price_units: string,
        public tag_position: {
            top_left_x: number,
            top_left_y: number,
            bottom_right_x: number,
            bottom_right_y: number,
        }
    ) { }
}


@Component({
    selector:   'tag',
    styleUrls:  ['./bizipix-editor.sass'],

    template:   `
        <div class="tag-container">
            <div class="ng-resizable dots" [attr.data-test-id]="'tag_' + context.item_id" [id]="context.item_id"
                 ngDraggable [handle]="content" (started)="onFocus()" (stopped)="onStop()"

                 ngResizable
                 (resizeStart)="onStart()"
                 (resizeEnd)="onEnding()"
                 [directions]="['bottom', 'top', 'left', 'right', 'top-left', 'bottom-left', 'top-right', 'bottom-right']"
                 [minWidth]="20" [minHeight]="20"
                 [width]="context.width - DOUBLE_MARGIN" [height]="context.height - DOUBLE_MARGIN"
                 [x]="0" [y]="0">

                <div #content class="ng-resizable__content">{{context.item_title}}</div>

            </div>

            <div *ngIf="isTrash" #trash class="tag__trash" (click)="onRemove()">
                <i class="icon-trash-empty" [attr.data-test-id]="'tag_trash_' + context.item_id"></i>
            </div>
        </div>
    `
})


/**
 * Resizable and draggable tag element.
 */
export class TagComponent implements OnInit, AfterContentInit {
    public DOUBLE_MARGIN            = 20;
    public isTrash:                 boolean;
    public MARGIN                   = 10;
    public isDown:                  boolean = false;
    public offset:                  [number, number] = [0, 0];

    private clickFlag:      boolean;
    private isBeingResized: boolean;
    private tag:            HTMLElement;

    @Input() context: any;
    @Input() bizipix: any;
    @Input() isToggleRemoveTag: boolean;
    @Input() isClearActive: boolean;
    /** For development **/
    @Output() allTagsContext$ = new EventEmitter<any>();


    constructor(private elementRef: ElementRef) {}


    /**
     * Sets position for the component container.
     */
    public ngOnInit(): void {
        this.tag = this.elementRef.nativeElement;

        this.tag.style.top = this.context.y + this.MARGIN + 'px';
        this.tag.style.left = this.context.x + this.MARGIN + 'px';
        this.tag.style.width = this.context.width - this.DOUBLE_MARGIN + 'px';
        this.tag.style.height = this.context.height - this.DOUBLE_MARGIN + 'px';

        this.tag.style.position = 'absolute';
    }

    /**
     * Called after inserting the component into the page (once)
     */
    public ngAfterContentInit(): void {
        this.allTagsContext$.emit({tag: this, bizipix: this.bizipix});
    }

    /**
     * Fires on the element click.
     * @decs Traces double clicks and delegates if any, otherwise passes #setActive
     * command to the callback.
     */
    public onFocus(): void {
        if (this.clickFlag) {
            this._onDoubleClick();
            return;
        }

        this.clickFlag = true;
        setTimeout(() => this.clickFlag = false, 300);
        this.context.callback({setActive: this.context.item_id});
    }


    /**
     * Passes #rebindItem command to the callback.
     * @private
     */
    private _onDoubleClick(): void {
        this.context.callback({rebindItem: this.context});
    }


    /**
     * @desc Fires when resizing ends. Determines weather it was resizing or moving action,
     * delegates if moving.
     */
    public onEnding(): void {
        if (!this.isBeingResized) return;

        this.onStop();

        this.isBeingResized = false;
    }


    /**
     * Returns current relative coordinates.
     * @returns {Position}
     * @private
     */
    public getPosition(): Position {
        let rect = this.elementRef.nativeElement.querySelector('.ng-resizable').getBoundingClientRect();

        return new Position(
            rect.left   - this.MARGIN,
            rect.top    - this.MARGIN,
            rect.width  + this.DOUBLE_MARGIN,
            rect.height + this.DOUBLE_MARGIN,

            this.context['item_id']
        );
    }


    /**
     * Passes #moveTag command to the callback.
     */
    public onStop(): void {
        this.isDown = false;
        this.context.callback({moveTag: this.getPosition()});
    }


    /**
     * Fires on moving action start. Sets @isBeingResized.
     */
    public onStart(): void {
        this.isBeingResized = true;
    }


    /**
     * Passes @removeTag to the callback.
     */
    public onRemove(): void {
        this.context.callback({removeTag: this.context.item_id});
    }


    /**
     * Sets @isTrash flag, responsible for the trash icon rendering.
     * @param param
     */
    public showTrash(param: boolean, tag?: any): void {
        this.isTrash = param;
        tag.isTrash = this.isTrash;

        setTimeout(() => param && this.adjustTrashPosition(tag));
    }

     /**
     * Adjusts the position for the trash icon.
     * @private
     */
    public adjustTrashPosition(tag?: any): void {
        let element = tag['elementRef'].nativeElement;
        let trash   = element.querySelector('.tag__trash');
        let style   = window.getComputedStyle(element.querySelector('.ng-resizable'));

        trash['style'].transform    = style.transform;
        trash['style'].top          = style.top;
        trash['style'].left         = style.left;
        trash['style'].width        = style.width;
        trash['style'].height       = style.height;
    }
}
