import { Component } from '@angular/core';
import {DynamicAbstractClass} from './dynamic-abstract-class';



@Component({
    selector:   'unsupported-orientation-component',
    template:   `
        <div [class]="modalContentClass">
            <div [ngClass]="'modal__dialog'">

                <h2 class="modal__header" data-test-id="unsupportedModalHeader"
                    [ngClass]="{
                        'modal__header-ok':         status === 'ok',
                        'modal__header-warning':    status === 'warning',
                        'modal__header-error':      status === 'error'
                    }">{{title}}</h2>

                <p *ngIf="context && context.message" class="modal__msg" data-test-id="unsupportedModalMessage">{{context.message}}</p>

                <div class="modal__btns__row">
                    <button class="modal__btn" data-test-id="unsupportedModalYesButton"
                            *ngIf="context && context.yesButtonText"
                            (click)="onClick(true, context.locationBack)">{{context.yesButtonText}}</button>
                    <button class="modal__btn" data-test-id="unsupportedModalNoButton"
                            *ngIf="context && context.noButtonText"
                            (click)="onClick(false)">{{context.noButtonText}}</button>
                    <button class="modal__btn modal__btn-last" data-test-id="unsupportedModalLastButton"
                            *ngIf="context && context.lastButtonText"
                            (click)="onClick('last')">{{context.lastButtonText}}</button>
                    <button class="modal__btn modal__btn-last" data-test-id="unsupportedModaltooLastButtonText"
                            *ngIf="context && context.tooLastButtonText"
                            (click)="onClick('tooLast')">{{context.tooLastButtonText}}</button>
                </div>
            </div>
        </div>
    `
})
export class UnsupportedOrientationComponent extends DynamicAbstractClass {

    constructor() {
        super();
    }

    onClick(action: boolean | string, locationBack?: boolean) {
        this.context._close(locationBack);
        if (this.context.handler) {
            this.context.handler(action);
        }
    }
}
