
        <header class="app-header">
            
            <nav class="app-header__container" role="goods-nav">
                
                <div class="navbar__left">
                    <a routerLink="/shopping-list" data-test-id="shoppingLink" 
                       class="nav__link"><i class="icon-custom_back_icon"></i></a>
                </div>

                <div class="navbar__center" role="goods-nav">
                    <h4>{{categoryName}}</h4>
                </div>

                <div class="navbar__right"></div>
            </nav>
            
        </header>
    