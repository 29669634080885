/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./time-picker.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./time-picker.component";
var styles_TimePickerComponent = [i0.styles];
var RenderType_TimePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimePickerComponent, data: {} });
export { RenderType_TimePickerComponent as RenderType_TimePickerComponent };
function View_TimePickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [["class", "option"]], [[8, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_r, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit === _co.month); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); }); }
function View_TimePickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [["class", "option"]], [[8, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_r, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit == _co.day); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); }); }
function View_TimePickerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [["class", "option"]], [[8, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_r, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit === _co.year); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); }); }
function View_TimePickerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [["class", "option"]], [[8, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_r, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, [" ", ""]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit == _co.hours); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); }); }
function View_TimePickerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [["class", "option"]], [[8, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_r, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, [" ", ""]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit == _co.minutes); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); }); }
function View_TimePickerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 \u00A0 "])), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["hourSelect", 1]], null, 3, "select", [], [[1, "data-test-id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.update("hours", i1.ɵnov(_v, 2).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_5)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [":"])), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["minuteSelect", 1]], null, 3, "select", [], [[1, "data-test-id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.update("minutes", i1.ɵnov(_v, 8).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_6)), i1.ɵdid(11, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.className; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co._hours; _ck(_v, 5, 0, currVal_2); var currVal_4 = _co.className; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co._minutes; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dataTestId + "__hourSelect"); _ck(_v, 2, 0, currVal_0); var currVal_3 = (_co.dataTestId + "__minuteSelect"); _ck(_v, 8, 0, currVal_3); }); }
export function View_TimePickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { selects: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "time-picker"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "invalid": 0 }), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["monthSelect", 1]], null, 3, "select", [], [[1, "data-test-id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.update("month", i1.ɵnov(_v, 4).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["daySelect", 1]], null, 3, "select", [], [[1, "data-test-id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.update("day", i1.ɵnov(_v, 8).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_2)), i1.ɵdid(11, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, [[1, 0], ["yearSelect", 1]], null, 3, "select", [], [[1, "data-test-id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.update("year", i1.ɵnov(_v, 12).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_3)), i1.ɵdid(15, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_4)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "time-picker"; var currVal_1 = _ck(_v, 3, 0, _co.isInvalid); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.className; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co._months; _ck(_v, 7, 0, currVal_4); var currVal_6 = _co.className; _ck(_v, 9, 0, currVal_6); var currVal_7 = _co._days; _ck(_v, 11, 0, currVal_7); var currVal_9 = _co.className; _ck(_v, 13, 0, currVal_9); var currVal_10 = _co._years; _ck(_v, 15, 0, currVal_10); var currVal_11 = _co.hasTimeSection; _ck(_v, 17, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.dataTestId + "__monthSelect"); _ck(_v, 4, 0, currVal_2); var currVal_5 = (_co.dataTestId + "__daySelect"); _ck(_v, 8, 0, currVal_5); var currVal_8 = (_co.dataTestId + "__yearSelect"); _ck(_v, 12, 0, currVal_8); }); }
export function View_TimePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "time-picker", [], null, null, null, View_TimePickerComponent_0, RenderType_TimePickerComponent)), i1.ɵdid(1, 638976, null, 0, i4.TimePickerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimePickerComponentNgFactory = i1.ɵccf("time-picker", i4.TimePickerComponent, View_TimePickerComponent_Host_0, { className: "className", dataTestId: "dataTestId", role: "role", time: "time", hasTimeSection: "hasTimeSection" }, { changeEvent$: "changeEvent$" }, []);
export { TimePickerComponentNgFactory as TimePickerComponentNgFactory };
