<table class="market-table">
        <colgroup>
            <col width=25%>
            <col width=35%>
            <col width=20%>
            <col width=20%>
        </colgroup>
        <thead class="market-table__header">
            <tr>
                <th class="market-table__header__dis"></th>
                <th class="market-table__header__title">Market</th>
                <th class="market-table__header__price">$/{{itemService.isUnitsName}}</th>
                <th class="market-table__header__qti">QTY</th>
            </tr>
        </thead>

        <tbody class="market-table__body">
            <tr *ngFor="let item of items; let i = index;"
                (click)="onClick(item)"
                [attr.data-test-id]="'block-' + i"
                class="market-table__item">
                <td [attr.data-test-id]="'distance_' + indexOfItem + '_' + i" > {{item['distance']}} mi</td>
                <td [attr.data-test-id]="'marketName_' + indexOfItem + '_' + i"> {{item['market']['market_title']}} </td>
                <td class="market-table__item__price" [attr.data-test-id]="'itemPricePerEach_' + indexOfItem + '_' + i"> {{prices[i] / 100 | currency:'USD':true}} </td>
                <td class="market-table__item__qti" [attr.data-test-id]="'itemQty_' + '_'+ indexOfItem + '_' + i"> {{item.current_quantity | number}} </td>
            </tr>
        </tbody>

    </table>
