import { Component, ElementRef }    from '@angular/core';


import { MarketService }            from '../../market/market.service';
import { SearchListClass }          from './search-list.class';
import {Market} from "../../../../swagger-gen__output_dir";


@Component({
    selector:       'market-search-list',
    styleUrls:      ['../search.sass'],
    templateUrl:    './market-search-list.html'
})

export class MarketSearchComponent extends SearchListClass {

    constructor(
        public elementRef:              ElementRef,
        private marketService:          MarketService,
    ) {
        super(elementRef);
    }



    public onClick(item: Market): void {
        this.marketService.showDetails(item);
    }

}
