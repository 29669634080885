<div class="component analytics-component" id="history">
    <analytics-header [analyticsUrl]="'/history'" (goBackEvent$)="goBack()"></analytics-header>
    <div class="analytics__container" id="custom-container">
        <h3 *ngIf="!items?.length || !items" class="empty-list">{{ "history.item.empty" | translate }}</h3>
        <ul class="history-list" *ngIf="items?.length || items">
            <li *ngFor="let item of items; trackBy: trackByID; let idx1 = index"
                [attr.data-test-id]="'historyItem_'+idx1" [id]="'historyItem_'+idx1"
                (click)="_toggleVisibility(item, 'historyItem_'+idx1)">
                <div class="analytics-item">
                    <div class="analytics-title">
                        <h5 [attr.data-test-id]="'date_'+idx1">{{ "history.item.date" | translate }}
                            {{item.order_date * THOUSAND | date: fullDateTimePattern }}</h5>
                        <h5 *ngIf="!item.elements?.length"
                            [attr.data-test-id]="'idOrder_'+idx1">{{ "history.item.orderId" | translate }} {{item.order_id}}</h5>
                        <h5
                            *ngIf="item.elements?.length"
                            [attr.data-test-id]="'idOrder_nameItem_'+idx1"
                        >
                        {{ "history.item.order" | translate }} {{item.elements[0].elements[0].item_info.title}}
                        </h5>
                        <h5
                            class="analytics__name"
                            *ngIf="mode === 'seller'"
                            [attr.data-test-id]="'idOrder_buyerNameItem_'+idx1"
                        >
                            {{templateName}}: {{item.buyer_info.title}}
                        </h5>
                        <h5
                            class="analytics__name"
                            *ngIf="mode === 'buyer' && item.elements?.length"
                            [attr.data-test-id]="'idOrder_companyNameItem_'+idx1"
                        >
                            {{templateName}}: {{item.elements[0].seller_info.title}}
                        </h5>
                    </div>
                    <div class="analytics-item__dir">
                        <i [attr.data-test-id]="'rightDirItem_'+idx1" class="icon-right-dir"
                           [ngClass]="{'active': item['_isVisible']}"></i>
                    </div>

                    <div class="total-discount__block">
                        <div class="analytics-title">
                            <h5 [attr.data-test-id]="'TotalItem_'+idx1">
                                {{ "history.item.total" | translate }} {{item.transaction_total_price / HUNDRED | currency: item.currency }}</h5>
                        </div>
                        <div *ngIf="mode === 'seller'" class="analytics-title analytics-item__discount">
                            <h5 *ngIf="item.transaction_discount !== 0"
                                [attr.data-test-id]="'DiscountItem_'+idx1">
                                {{ "history.item.discount" | translate }} {{item.transaction_discount / HUNDRED | currency: item.currency }}</h5>
                        </div>
                        <div *ngIf="mode === 'buyer'" class="analytics-title analytics-item__discount">
                            <h5 *ngIf="item.discount !== 0" [attr.data-test-id]="'DiscountItem_'+idx1">
                                {{ "history.item.saved" | translate }} {{item.discount / HUNDRED | currency: item.currency }}</h5>
                        </div>
                    </div>
                </div>

                <div *ngIf="item['_isVisible'] && item.elements?.length">
                    <div *ngFor="let elem of item.elements; trackBy: trackByID; let idx2 = index"
                         class="analytics-item__details">
                        <div *ngFor="let product of elem.elements; trackBy: trackByID; let idx3 = index"
                             [attr.data-test-id]="'showProductButton_'+idx1+'_'+idx3"
                             (click)="showProduct(product.item_info.real_item_id, product.qty, elem.order_id, item.order_date, product.price, product.uom )">
                            <div class="analytics-details__name">
                                <div class="analytics__img-container">
                                        <span *ngIf="!product.item_info.photoURL" [attr.data-test-id]="'imgItem_'+idx1"
                                              class="sprite sprite-no_image_icon"></span>
                                    <img *ngIf="product.item_info.photoURL"
                                         [attr.data-test-id]="'imgItem_'+idx1"
                                         [src]="product.item_info.photoURL"/>
                                </div>

                                <div class="analytics__product-details">
                                    <h4
                                        class="analytics__name"
                                        [attr.data-test-id]="'nameItem_'+idx1">{{product.item_info.title}}</h4>
                                    <h5 class="analytics__product-details__price-by-uom">
                                        <strong
                                            [attr.data-test-id]="'costItem_'+idx1+'_'+idx3">{{product.price / HUNDRED | currency: item.currency }}
                                            /{{product.uom}}</strong>
                                    </h5>
                                    <h5
                                        class="analytics__name"
                                        *ngIf="mode === 'seller'"
                                        [attr.data-test-id]="'buyerNameItem_'+idx1 + '_' + idx2"
                                    >
                                        {{templateName}}: {{item.buyer_info.title}}
                                    </h5>
                                    <h5
                                        class="analytics__name"
                                        *ngIf="mode === 'buyer' && item.elements?.length"
                                        [attr.data-test-id]="'sellerNameItem_'+idx1 + '_' + idx2"
                                    >
                                        {{templateName}}: {{elem.seller_info.title}}
                                    </h5>
                                    <h5 class="analytics__name"
                                        [attr.data-test-id]="'orderCodeItem_'+idx1 + '_' + idx2">
                                        {{ "history.item.orderCode" | translate }} {{elem.order_id}}
                                    </h5>
                                </div>

                                <div class="analytics-details__buttons-block">
                                    <i [attr.data-test-id]="'contactButton_'+idx1"
                                    (click)="contact(item, product, $event)"
                                    class="analytics-details__buttons-block__message icon-message_icon"></i>
                                </div>
                            </div>

                            <div class="analytics-details__addresses">
                                <div class="analytics-details__addresses__delivery">
                                    <p class="address-title"
                                       [attr.data-test-id]="'itemFrom_'+idx1 + '_' + idx2">{{ "history.item.from" | translate }}</p>
                                    <div>
                                        <p [attr.data-test-id]="'itemAddressFrom_'+idx1 + '_' + idx2"
                                           class="address">{{ product.provider_address_name }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="analytics-details__qnt">
                                <p [attr.data-test-id]="'qntItem_'+idx1" class="qnt-title">{{ "history.item.quantity" | translate }}</p>
                                <p [attr.data-test-id]="'totalCostItem_'+idx1" class="qnt-item"><span
                                    class="math-sign">&#215; {{product.qty | number}}</span>
                                <p class="history__total-cost">
                                    <strong
                                        [attr.data-test-id]="'history-subtotal-cost_'+idx1 + '_' + idx2">{{(product.price * product.qty) / HUNDRED | currency: item.currency }}</strong>
                                </p>
                            </div>
                            <div class="analytics-details__addresses">
                                <div *ngIf="isPickUp(product.shipping_address)"
                                     class="analytics-details__addresses__delivery">
                                    <p class="address-title">{{ "history.item.delivery" | translate }}</p>
                                    <div>
                                        <p [attr.data-test-id]="'contactBuyer_'+idx1  + '_' + idx2"
                                           class="address">{{product.shipping_terms}}</p>
                                    </div>
                                </div>
                                <div *ngIf="!isPickUp(product.shipping_address)"
                                     class="analytics-details__addresses__delivery">
                                    <p class="address-title">{{ "history.item.pickup" | translate }}</p>
                                    <p [attr.data-test-id]="'buyer_address_'+idx1 + '_' + idx2"
                                       class="address">{{ product.pick_up_address_str }}</p>
                                </div>
                                <div *ngIf="mode === 'seller'" class="analytics-details__addresses__delivery">
                                    <p class="address-title">{{ "history.item.buyer.phone" | translate }}</p>
                                    <p [attr.data-test-id]="'buyer_primary_phone_'+idx1  + '_' + idx2"
                                       class="address">{{ item.buyer_primary_phone }}</p>
                                </div>
                                <div *ngIf="mode === 'buyer'" class="analytics-details__addresses__delivery">
                                    <p class="address-title">{{ "history.item.seller.phone" | translate }}</p>
                                    <p [attr.data-test-id]="'seller_primary_phone_'+idx1  + '_' + idx2"
                                       class="address">{{ product.seller_primary_phone }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="cart__total">
        <div>
            <div class="cart__total__title">
                <span class="box-left">{{ "history.item.cart.total" | translate }}</span>
                <small data-test-id="qntItems">{{numberOfItems || 0 }} {{ "history.item.cart.items" | translate }}</small>
            </div>

            <h4 data-test-id="total">{{totalCost / HUNDRED || 0 | currency:'USD'}}</h4>
        </div>
    </div>
</div>
