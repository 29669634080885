/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../assets/styles/app-header.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./cart-header.component";
var styles_CartHeaderComponent = [i0.styles];
var RenderType_CartHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CartHeaderComponent, data: {} });
export { RenderType_CartHeaderComponent as RenderType_CartHeaderComponent };
export function View_CartHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "header", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "nav", [["class", "app-header__container"], ["role", "cart"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "navbar__left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "nav__link"], ["data-test-id", "shoppingLink"], ["name", "shopping_cart_cancel_button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickAnyButtons($event.target.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-custom_back_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "navbar__center nav__link relative"], ["role", "cart"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "icon-cart_icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "badge"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "relative"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "navbar__right"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.count; _ck(_v, 9, 0, currVal_0); }); }
export function View_CartHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cart-header", [], null, null, null, View_CartHeaderComponent_0, RenderType_CartHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i2.CartHeaderComponent, [], null, null)], null, null); }
var CartHeaderComponentNgFactory = i1.ɵccf("cart-header", i2.CartHeaderComponent, View_CartHeaderComponent_Host_0, { count: "count" }, { googleAnalyticClickButtonsEvent$: "googleAnalyticClickButtonsEvent$" }, []);
export { CartHeaderComponentNgFactory as CartHeaderComponentNgFactory };
