/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./dialogs-list-item.component.ngfactory";
import * as i4 from "./dialogs-list-item.component";
import * as i5 from "@angular/common";
import * as i6 from "./dialogs-list-header.component.ngfactory";
import * as i7 from "./dialogs-list-header.component";
import * as i8 from "../services/redirect.service";
import * as i9 from "./dialogs-list.component";
import * as i10 from "./chat.service";
import * as i11 from "../services/error.service";
var styles_DialogsListComponent = [i0.styles];
var RenderType_DialogsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogsListComponent, data: {} });
export { RenderType_DialogsListComponent as RenderType_DialogsListComponent };
function View_DialogsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "empty-list"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("chatroom.empty")); _ck(_v, 1, 0, currVal_0); }); }
function View_DialogsListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dialogs-list-item", [], [[1, "data-test-id", 0]], [[null, "removeEvent$"], [null, "showEvent$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("removeEvent$" === en)) {
        var pd_0 = (_co.removeDialog(_v.context.$implicit.ID) !== false);
        ad = (pd_0 && ad);
    } if (("showEvent$" === en)) {
        var pd_1 = (_co.showDialog(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_DialogsListItemComponent_0, i3.RenderType_DialogsListItemComponent)), i1.ɵdid(1, 114688, null, 0, i4.DialogsListItemComponent, [], { dialog: [0, "dialog"], id: [1, "id"] }, { removeEvent$: "removeEvent$", showEvent$: "showEvent$" })], function (_ck, _v) { var currVal_1 = _v.context.$implicit; var currVal_2 = _v.context.index; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = ("dialog_" + _v.context.index); _ck(_v, 0, 0, currVal_0); }); }
function View_DialogsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dialog-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogsListComponent_3)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dialogs; var currVal_1 = _co.trackByID; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DialogsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "component message-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dialogs-list-header", [], null, null, null, i6.View_DialogsListHeaderComponent_0, i6.RenderType_DialogsListHeaderComponent)), i1.ɵdid(2, 49152, null, 0, i7.DialogsListHeaderComponent, [i8.RedirectService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "component__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "section", [["class", "search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [["searchInp", 1]], null, 0, "input", [["class", "search-header__input"], ["data-test-id", "searchInp"], ["placeholder", "Search"], ["type", "text"]], [[8, "value", 0]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.onSearch($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "nav__link text-button"], ["data-test-id", "searchCancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogsListComponent_1)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogsListComponent_2)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.dialogs == null) ? null : _co.dialogs.length) === 0); _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.dialogs; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchQuery; _ck(_v, 6, 0, currVal_0); }); }
export function View_DialogsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dialogs-list", [], null, null, null, View_DialogsListComponent_0, RenderType_DialogsListComponent)), i1.ɵdid(1, 245760, null, 0, i9.DialogsListComponent, [i10.ChatService, i1.Injector, i11.ErrorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogsListComponentNgFactory = i1.ɵccf("dialogs-list", i9.DialogsListComponent, View_DialogsListComponent_Host_0, {}, {}, []);
export { DialogsListComponentNgFactory as DialogsListComponentNgFactory };
