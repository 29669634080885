
        <section class="product-editor__title">

            <div class="image-container product-img" data-test-id="uploadImg"
                 [ngClass]="{'background-image': imageUrl}"
                 (click)="!disabled && showUpload()"
                 [style.background-image]="'url('+imageUrl+')'">

                <span *ngIf="!imageUrl" role="productImg" data-test-id="anImage" class="sprite sprite-add_image_icon_2x"></span>
            </div>

            <label class="input__label clearfix" [attr.data-test-id]="dataTestId + 'InnerContainer'">
                <input class="input__text" type="text" [name]="placeholder"
                       [attr.data-test-id]="dataTestId"
                       [placeholder]="placeholder"
                       [value]="text"
                       [disabled]="disabled"
                       (blur)="finishRenaming({event: $event, message: message})"
                       (focus)="startRenaming({event: $event})">
            </label>

            <input name="photo" (change)="onImgUpload($event.currentTarget)" type="file" accept="imageTypes" data-test-id="fileInp">
            <span role="editable" style="display:none"></span>

        </section>
    