import { Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { environment } from '../../environments/environment';
import { AppFooterService, UserMode } from '../app-footer/app-footer.service';
import { AppReviewsService } from '../reviews/reviews.service';
import { AppSettings } from '../common/app.settings';
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(router, userService, injector) {
        this.router = router;
        this.userService = userService;
        this.injector = injector;
        this.impersonationLogOut = false;
        this.bizibazaAboutLink = '';
        this.bizibazaFaqLink = '';
    }
    SettingsComponent.prototype.ngOnInit = function () {
        this.version = AppSettings.APP_VERSION();
        var type = this.userService.typeOfUser();
        var baseUrl = environment.apiUrl.split('/api/v1/')[0];
        if (Object.keys(this.userService.impersonation).length !== 0) {
            this.impersonationLogOut = true;
        }
        this.guest = type === 'guest';
        this.admin = this.userService.isAdminMode();
        this.loginName = this.userService.loginName;
        this.bizibazaAboutLink = baseUrl + "/about-us";
        this.bizibazaFaqLink = baseUrl + "/faq";
        this.mementoMode();
    };
    SettingsComponent.prototype.mementoMode = function () {
        var history = this.injector.get(AppFooterService).modeCaretaker.showHistory();
        if (history.length >= 1) {
            this.injector.get(AppFooterService).removeModeOriginator();
        }
        else {
            this.injector.get(AppFooterService).setModeOriginator(this.injector.get(AppFooterService).getMode());
        }
        this.userMode = this.injector.get(AppFooterService).getMode();
    };
    SettingsComponent.prototype.changePassword = function () {
        this.router.navigate(['/change-password']);
    };
    SettingsComponent.prototype.updateProfile = function () {
        this.router.navigate(['/profile']);
    };
    SettingsComponent.prototype.checkReviews = function (isSellerReview) {
        if (!this.userService.isGuestMode) {
            if (isSellerReview) {
                this.injector.get(AppFooterService).setMode(UserMode.sellerMode);
            }
            else {
                this.injector.get(AppFooterService).setMode(UserMode.buyerMode);
            }
            this.appReviewsService.isOpenedReviewFromSettings = true;
            this.router.navigate(['/reviews']);
        }
    };
    SettingsComponent.prototype.logOut = function (event) {
        this.userService.logoutModal(event);
    };
    SettingsComponent.prototype.deleteAccount = function (event) {
        this.userService.deleteAccountModal(event);
    };
    SettingsComponent.prototype.goTo = function (location) {
        window.open(this[location], '_blank');
    };
    Object.defineProperty(SettingsComponent.prototype, "counters", {
        get: function () {
            return this.injector.get(AppFooterService).counters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsComponent.prototype, "appReviewsService", {
        get: function () {
            return this.injector.get(AppReviewsService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsComponent.prototype, "isSellerAccessOfUser", {
        get: function () {
            return this.userService.isSellerMode();
        },
        enumerable: true,
        configurable: true
    });
    return SettingsComponent;
}());
export { SettingsComponent };
